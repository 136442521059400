import { gql, useQuery } from "@apollo/client";
import {
  CashBandName,
  EquityBandName,
  MONTHS_IN_A_YEAR,
} from "@asmbl/shared/constants";
import { exchangeFromTo } from "@asmbl/shared/currency";
import { add, multiply, preferredPrice, zero } from "@asmbl/shared/money";
import { Ensure } from "@asmbl/shared/types";
import { Box, Theme, Typography, makeStyles } from "@material-ui/core";
import { memo, useCallback, useMemo, useState } from "react";
import {
  GetAdjustedPosition,
  GetAdjustedPositionVariables,
  GetAllOfferData,
} from "../../__generated__/graphql";
import {
  BENEFITS_PACKAGE_FIELDS,
  OFFER_CONFIG_FIELDS,
  POSITION_FIELDS_MINIMAL,
  VALUATION_FIELDS,
} from "../../fragments";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import {
  getAnnualBenefitsValue,
  getAnnualCashCompensation,
  getAnnualEquityValue,
  getOneTimeComp,
} from "../../models/Offer";
import { GRAY_4, GRAY_6, GREEN_2, WHITE } from "../../theme";
import { ApprovalIcon } from "../AssembleIcons/Brand/ApprovalIcon";
import { CheckIcon } from "../AssembleIcons/Brand/CheckIcon";
import { CompensationIcon } from "../AssembleIcons/Brand/CompensationIcon";
import { PositionIcon } from "../AssembleIcons/Brand/PositionIcon";
import { ProfileIcon } from "../AssembleIcons/Brand/ProfileIcon";
import { useAuth } from "../Auth/AuthContext";
import { useCurrencies } from "../CurrenciesContext";
import { NoOfferSettingsConfigured } from "../EmptyState/EmptyState";
import { useLocations } from "../LocationsContext";
import Loading from "../Settings/Loading";
import { UnsavedChangesWarning } from "../UnsavedChangesWarning/UnsavedChangesWarning";
import Step from "../Workflow/Step";
import Approval from "./Approval/Approval";
import Candidate from "./Candidate";
import { CandidateLoadingBoundary } from "./Candidate/CandidateLoadingBoundary";
import Compensation from "./Compensation";
import Confirmation from "./Confirmation";
import Intro from "./Intro";
import Positions from "./Positions";
import { HAS_SEEN_OFFERS } from "./constants";
import { ChangeHandler, OfferData } from "./types";

type StyleProps = {
  transitioning: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
  },
  content: {
    flex: 1,
    padding: `${theme.spacing(12)}px ${theme.spacing(5)}px`,
    position: "relative",
  },
  sidebar: {
    backgroundColor: WHITE,
    boxShadow: `1px 0px 0px ${GRAY_6}`,
    flexShrink: 0,
    height: "100%",
    left: "81px",
    marginRight: "1px",
    padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
    position: "fixed",
    width: theme.spacing(31),
    zIndex: 10,
  },
  transition: {
    marginTop: (p: StyleProps) => (p.transitioning ? "40px" : "0px"),
    opacity: (p: StyleProps) => (p.transitioning ? 0 : 1),
    transition: (p: StyleProps) => (p.transitioning ? "none" : "all 350ms"),
  },
}));

type Props = {
  data: OfferData;
  allOfferData: Ensure<GetAllOfferData, "compStructure" | "valuation">;
  onChange: ChangeHandler;
  onSubmit: () => Promise<number | undefined>;
  hasUnsavedChanges: boolean;
  initialStep?: number;
  requireApproval?: boolean;
};

export const OfferGeneration = (
  props: Omit<Props, "allOfferData">
): JSX.Element => {
  const auth = useAuth();

  const [hasSeenOffers] = useLocalStorage(HAS_SEEN_OFFERS, false);

  const { data: allOfferData, loading } =
    useQuery<GetAllOfferData>(GET_ALL_OFFER_DATA);

  if (auth.isLoading || loading) return <Loading />;

  if (allOfferData == null) return <Box>An error has occurred</Box>;

  // In the internal_server_error case valuation is not missing
  if (allOfferData.valuation?.valuation == null) {
    return <NoOfferSettingsConfigured />;
  }

  if (allOfferData.compStructure == null) {
    return <Box>Company must have a compensation structure</Box>;
  }

  return (
    <GenerateOffer
      {...props}
      initialStep={hasSeenOffers ? 1 : 0}
      allOfferData={{
        ...allOfferData,
        valuation: allOfferData.valuation,
        compStructure: allOfferData.compStructure,
      }}
    />
  );
};

const GenerateOffer = memo(function GenerateOffer({
  data,
  allOfferData,
  onChange,
  onSubmit,
  hasUnsavedChanges,
  initialStep = 0,
  requireApproval = false,
}: Props) {
  const [transitioning, setTransitioning] = useState<boolean>(false);
  const classes = useStyles({ transitioning });
  const { markets } = useLocations();
  const { currencies, defaultCurrency: valuationCurrency } = useCurrencies();

  const [step, setStep] = useState<number>(initialStep);
  const updateStep = useCallback((updatedStep: number) => {
    setTransitioning(true);

    setTimeout(() => {
      window.scrollTo(0, 0);
      setStep(updatedStep);
      setTransitioning(false);
    }, 150);
  }, []);

  const showCurrentEquityValue =
    allOfferData.offerConfig?.showCurrentEquityValue ?? true;

  const showEquityInValuationCurrency =
    allOfferData.offerConfig?.equityCashInValuationCurrency === true;

  const hasIllustrativeOutcomes =
    (allOfferData.offerConfig?.exitOutcomes.length ?? 0) > 0;

  const localCurrency = data.currency;

  const salaryAmount = data.cash[CashBandName.SALARY];
  const spotBonusAmount = data.cash[CashBandName.SPOT_BONUS];
  const signingBonusAmount = data.cash[CashBandName.SIGNING_BONUS];
  const relocationBonusOfficeAmount =
    data.cash[CashBandName.RELOCATION_BONUS_OFFICE];
  const relocationBonusRemoteAmount =
    data.cash[CashBandName.RELOCATION_BONUS_REMOTE];
  const equityAmount = data.equity[EquityBandName.INITIAL_EQUITY_GRANT];
  const benefitsAmount = data.benefitsPackage;

  const { data: positionData } = useQuery<
    GetAdjustedPosition,
    GetAdjustedPositionVariables
  >(position_query, {
    variables: {
      cashCurrencyCode: data.currency.code,
      equityCurrencyCode: showEquityInValuationCurrency
        ? valuationCurrency.code
        : data.currency.code,
      positionId: Number(data.position?.id),
      locationGroupId: data.locationGroup?.id,
      marketId: Number(data.market?.id),
    },
    skip: data.position === undefined || data.market === undefined,
  });

  const {
    organization,
    positions,
    compStructure,
    valuation,
    benefitsPackages,
  } = allOfferData;

  const safeVestingMonths =
    compStructure.vestingMonths === 0
      ? MONTHS_IN_A_YEAR
      : compStructure.vestingMonths;

  const annualCashComp = useMemo(
    () =>
      getAnnualCashCompensation(
        data.cash,
        compStructure,
        localCurrency,
        positionData?.position?.type
      ),
    [compStructure, data.cash, localCurrency, positionData?.position?.type]
  );

  const annualEquityComp = useMemo(
    () =>
      getAnnualEquityValue(
        showEquityInValuationCurrency,
        valuationCurrency,
        localCurrency,
        { vestingMonths: safeVestingMonths },
        { salary: salaryAmount, equity: equityAmount },
        valuation
      ),
    [
      showEquityInValuationCurrency,
      valuationCurrency,
      localCurrency,
      safeVestingMonths,
      salaryAmount,
      equityAmount,
      valuation,
    ]
  );

  const annualBenefitsComp = useMemo(
    () => ({
      inLocalCurrency: getAnnualBenefitsValue(currencies, {
        benefitsPackage: benefitsAmount,
        currency: localCurrency,
      }),
      inBenefitsCurrency:
        benefitsAmount?.totalValue ?? zero(localCurrency.code),
    }),
    [benefitsAmount, currencies, localCurrency]
  );

  const totalEquityValue = useMemo(
    () => ({
      inLocalCurrency: multiply(
        annualEquityComp.inLocalCurrency,
        safeVestingMonths / MONTHS_IN_A_YEAR
      ),
      inValuationCurrency: multiply(
        annualEquityComp.inValuationCurrency,
        safeVestingMonths / MONTHS_IN_A_YEAR
      ),
    }),

    [
      annualEquityComp.inLocalCurrency,
      annualEquityComp.inValuationCurrency,
      safeVestingMonths,
    ]
  );

  const oneTimeComp = useMemo(
    () =>
      getOneTimeComp(localCurrency, {
        salary: salaryAmount,
        spotBonus: spotBonusAmount,
        signingBonus: signingBonusAmount,
        relocationBonusOffice: relocationBonusOfficeAmount,
        relocationBonusRemote: relocationBonusRemoteAmount,
      }),
    [
      localCurrency,
      relocationBonusOfficeAmount,
      relocationBonusRemoteAmount,
      salaryAmount,
      signingBonusAmount,
      spotBonusAmount,
    ]
  );

  const annualTotalComp = useMemo(
    () =>
      add(
        add(annualCashComp, annualBenefitsComp.inLocalCurrency),
        // if we are not showing the current equity value - then we should
        // not add the equity value into the total comp or else the equity
        // value could be calculated via Annual cash OTE - benefits value =>
        // equity value
        showCurrentEquityValue
          ? annualEquityComp.inLocalCurrency
          : zero(localCurrency.code)
      ),
    [
      annualBenefitsComp,
      annualCashComp,
      annualEquityComp.inLocalCurrency,
      localCurrency.code,
      showCurrentEquityValue,
    ]
  );

  const computedOfferedComp = useMemo(
    () => ({
      annual: {
        cash: annualCashComp,
        benefits: annualBenefitsComp,
        equity: annualEquityComp,
        total: annualTotalComp,
      },
      fullyVested: totalEquityValue,
      oneTimeComp,
    }),
    [
      annualBenefitsComp,
      annualCashComp,
      annualEquityComp,
      annualTotalComp,
      oneTimeComp,
      totalEquityValue,
    ]
  );

  // if we are showing the equity in the valuation currency, then we should
  // show the price per unit in the valuation currency as well
  const pricePerUnit = useMemo(
    () =>
      showEquityInValuationCurrency
        ? preferredPrice(valuation.fdso, valuation.valuation)
        : exchangeFromTo(
            preferredPrice(valuation.fdso, valuation.valuation),
            valuationCurrency,
            localCurrency
          ),
    [
      localCurrency,
      showEquityInValuationCurrency,
      valuation.fdso,
      valuation.valuation,
      valuationCurrency,
    ]
  );

  const renderOfferStep = () => {
    switch (step) {
      case 0:
        return <Intro currentStep={step} onNext={() => updateStep(1)} />;
      case 1:
        return (
          <Positions
            currentStep={step}
            data={data}
            handleChange={onChange}
            positions={positions}
            onNext={() => updateStep(2)}
            markets={markets}
          />
        );
      case 2:
        return (
          <CandidateLoadingBoundary>
            {(candidates, hasAtsIntegration) => (
              <Candidate
                currentStep={step}
                data={data}
                candidates={candidates}
                handleChange={onChange}
                onNext={() => updateStep(3)}
                onBack={() => updateStep(1)}
                positionName={positionData?.position?.name}
                hasAtsIntegration={hasAtsIntegration}
                hasIllustrativeOutcomes={hasIllustrativeOutcomes}
              />
            )}
          </CandidateLoadingBoundary>
        );
      case 3:
        return (
          <Compensation
            compStructure={compStructure}
            data={data}
            handleChange={onChange}
            onNext={() => (requireApproval ? updateStep(4) : updateStep(5))}
            onBack={() => updateStep(2)}
            position={positionData?.position ?? undefined}
            market={data.market}
            locationGroup={data.locationGroup}
            benefitsPackages={benefitsPackages}
            showEquityInValuationCurrency={showEquityInValuationCurrency}
            canRecruitersViewAnonymizedStatistics={
              organization.permissionSettings
                .canRecruitersViewAnonymizedStatistics
            }
            computedOfferedComp={computedOfferedComp}
            localCurrency={localCurrency}
            valuationCurrency={valuationCurrency}
            showCurrentEquityValue={showCurrentEquityValue}
            pricePerUnit={pricePerUnit}
          />
        );
      case 4:
        return (
          <Approval
            currentStep={step}
            data={data}
            handleChange={onChange}
            onNext={() => updateStep(5)}
            onBack={() => updateStep(3)}
          />
        );
      case 5:
        return (
          <Confirmation
            compStructure={compStructure}
            currentStep={step}
            data={data}
            onSubmit={onSubmit}
            onBack={() => (requireApproval ? updateStep(4) : updateStep(3))}
            position={positionData?.position ?? undefined}
            benefitsPackages={benefitsPackages}
            requireApproval={requireApproval}
            organization={organization}
            showEquityInValuationCurrency={showEquityInValuationCurrency}
            computedOfferedComp={computedOfferedComp}
            showCurrentEquityValue={showCurrentEquityValue}
            localCurrency={localCurrency}
            valuationCurrency={valuationCurrency}
            pricePerUnit={pricePerUnit}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box className={classes.container}>
      <UnsavedChangesWarning pageEdited={hasUnsavedChanges} />
      <Box className={classes.sidebar}>
        <Box mb={2} ml={2}>
          <Typography variant="overline" style={{ color: GRAY_4 }}>
            Create an Offer
          </Typography>
        </Box>
        <Step
          active={step === 1}
          icon={
            step > 1 ? <CheckIcon color={GREEN_2} /> : <PositionIcon inherit />
          }
          onClick={() => (step > 1 ? updateStep(1) : null)}
          prevStep={step > 1}
        >
          Position
        </Step>
        <Step
          active={step === 2}
          icon={
            step > 2 ? <CheckIcon color={GREEN_2} /> : <ProfileIcon inherit />
          }
          onClick={() => (step > 2 ? updateStep(2) : null)}
          prevStep={step > 2}
        >
          Candidate Details
        </Step>
        <Step
          active={step === 3}
          icon={
            step > 3 ? (
              <CheckIcon color={GREEN_2} />
            ) : (
              <CompensationIcon inherit />
            )
          }
          onClick={() => (step > 3 ? updateStep(3) : null)}
          prevStep={step > 3}
        >
          Compensation
        </Step>
        {requireApproval && (
          <Step
            active={step === 4}
            icon={
              step > 4 ? (
                <CheckIcon color={GREEN_2} />
              ) : (
                <ApprovalIcon inherit />
              )
            }
            onClick={() => (step > 4 ? updateStep(4) : null)}
            prevStep={step > 4}
          >
            Approval
          </Step>
        )}
        <Step
          active={step === 5}
          icon={<CheckIcon inherit />}
          onClick={() => (step > 5 ? updateStep(5) : null)}
          prevStep={step > 5}
        >
          Confirm
        </Step>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.transition}>{renderOfferStep()}</Box>
      </Box>
    </Box>
  );
});

const position_query = gql`
  ${Compensation.fragments.position}
  ${Confirmation.fragments.position}
  query GetAdjustedPosition(
    $positionId: Int!
    $cashCurrencyCode: CurrencyCode!
    $equityCurrencyCode: CurrencyCode!
    $marketId: Int!
    $locationGroupId: Int
  ) {
    position(id: $positionId) {
      id
      name
      ...Compensation_position
      ...Confirmation_position
    }
  }
`;

const GET_ALL_OFFER_DATA = gql`
  ${POSITION_FIELDS_MINIMAL}
  ${OFFER_CONFIG_FIELDS}
  ${BENEFITS_PACKAGE_FIELDS}
  ${VALUATION_FIELDS}
  query GetAllOfferData {
    organization {
      id
      name
      hasAtsIntegration
      permissionSettings {
        id
        canRecruitersViewAnonymizedStatistics
      }
    }
    positions {
      ...PositionFieldsMinimal
      jobCodes
    }
    compStructure {
      id
      bandPointTypes
      cashBandTypes
      equityBandTypes
      allBandTypes
      availableCashBandTypes
      availableEquityBandTypes
      businessStructure
      companyDescription
      equityGrantTypes
      showPercentOwnership
      showValuation
      vestingCliff
      vestingMonths
      levels
      equityGrantMethod
      allowHourlyEmployees
      employmentHoursPerWeek
      employmentWeeksPerYear
    }
    valuation {
      ...ValuationFields
    }
    offerConfig {
      ...OfferConfigFields
    }
    benefitsPackages {
      ...BenefitsPackageFields
    }
  }
`;
