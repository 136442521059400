import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import FormHeader from "../../../components/Settings/FormHeader";
import { HRISLocationsLinkOpen } from "../../../components/Settings/Locations/HRISLocationsLinkOpen";
import { LocationsTableViewLoadingBoundary } from "../../../components/Settings/Locations/LocationsTable/LocationsTableViewLoadingBoundary";
import { PageContainer } from "../PageContainer";
import { MarketsLoadingBoundary } from "./MarketsLoadingBoundary";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
export function Markets(): JSX.Element {
  const classes = useStyles();

  return (
    <PageContainer>
      <div className={classes.header}>
        <FormHeader mb={0} header="Markets & Locations" />
        <HRISLocationsLinkOpen />
      </div>
      <MarketsLoadingBoundary />
      <LocationsTableViewLoadingBoundary />
    </PageContainer>
  );
}
Markets.fragments = {
  market: gql`
    fragment Markets_market on Market {
      id
      name
    }
  `,
};
