import {
  ScalarDictionaryWithCustom as FlatFileRecord,
  IDataHookResponse,
} from "@flatfile/react";
import {
  ActualVariableCashCompType,
  ActualVariableCompImportButton_employee as Employee,
  ImportActualVariableCompInput,
} from "src/__generated__/graphql";
import { matchEmployee, validateDate } from "../../../models/csv/EmployeeCSV";

type TypedRow = {
  employeeNumber: string | number;
  employeeId: string | number;
  paymentDate: string;
  type: string;
  subType: string | null | undefined;
  value: string | number;
  currencyCode: string;
  provenance: string;
  email: string;
};

export function recordValidator(employees: Employee[]) {
  return function validateRecord(record: FlatFileRecord): IDataHookResponse {
    const [, employeeMatchingInfo] = matchEmployee(record, employees);

    return {
      ...employeeMatchingInfo,
      ...validateDate(record, "paymentDate"),
    };
  };
}

export function parseData(
  validData: unknown[],
  employees: Employee[]
): ImportActualVariableCompInput[] {
  const sanitizedData = validData.map((untypedRow) => {
    const row = convertUntypedRow(untypedRow as FlatFileRecord);

    const [employee] = matchEmployee(row, employees);
    const employeeId = employee?.id;

    if (employeeId == null) {
      throw new Error("Employee Not Found");
    }

    if (
      row.type !== ActualVariableCashCompType.BONUS &&
      row.type !== ActualVariableCashCompType.COMMISSION
    ) {
      throw new Error(
        "Type is incorrect. Must be one of 'COMMISSION' or 'BONUS'"
      );
    }

    return {
      employeeId,
      paymentDate: formatDate(new Date(row.paymentDate)),
      type: row.type as unknown as ActualVariableCashCompType,
      subType: row.subType,
      value: Number(row.value),
      currencyCode: row.currencyCode,
      provenance: "CSV",
    };
  });

  // we cannot use the record validator for this validation because we need a
  // reference to all the records to compare against each other since this
  // validation isn't on a per-record basis

  const inputSet = new Set();

  sanitizedData.forEach((input) => {
    const uniqueKey = `${input.employeeId}${input.type}${input.subType ?? ""}${
      input.paymentDate
    }`;

    if (inputSet.has(uniqueKey)) {
      throw new Error(
        "Inputs must have a unique combination of employee, type, subType, and paymentDate"
      );
    } else {
      inputSet.add(uniqueKey);
    }
  });

  return sanitizedData;
}

/**
 * Returns a date formatted as YYYY-MM-DD
 */
export function formatDate(date: Date): string {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1; // getUTCMonth is zero-indexed
  const day = date.getUTCDate();

  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  return [year, formattedMonth, formattedDay].join("-");
}

/**
 * Converts a FlatFileRecord to a strongly-typed object, throwing an error if
 * any of the fields are null or undefined.
 */
export function convertUntypedRow(row: FlatFileRecord): TypedRow {
  const object = {
    employeeNumber: row.employeeNumber,
    employeeId: row.employeeId,
    paymentDate: row.paymentDate,
    type: row.type,
    subType: row.subType === "" ? null : row.subType,
    value: row.value,
    currencyCode: row.currency,
    email: row.email,
  };

  return object as TypedRow;
}
