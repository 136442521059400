import { gql } from "@apollo/client";
import { PeerNavigation } from "../../components/PeerNavigation";
import { PositionPeerNavigation_position } from "../../__generated__/graphql";

export function PositionPeerNavigation({
  position,
}: {
  position: PositionPeerNavigation_position;
}): JSX.Element {
  const sortedPeerPositions = position.ladder.positions
    .slice()
    .sort((a, b) => a.level - b.level);

  return (
    <PeerNavigation
      selectedId={position.id}
      allRecords={sortedPeerPositions}
      recordUrlPrefix="/positions"
      noSort
    />
  );
}

PositionPeerNavigation.fragments = {
  position: gql`
    fragment PositionPeerNavigation_position on Position {
      id
      ladder {
        id
        positions {
          id
          name
          level
        }
      }
    }
  `,
};
