import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { contramap } from "@asmbl/shared/sort";
import {
  makeStyles,
  Tooltip,
  TooltipProps,
  Typography,
} from "@material-ui/core";
import { CalendarIcon } from "src/components/AssembleIcons/Brand/CalendarIcon";
import { ClockIcon } from "src/components/AssembleIcons/Brand/ClockIcon";
import { LevelIcon } from "src/components/AssembleIcons/Brand/LevelIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import {
  PositionFieldsMinimal as Position,
  PositionType,
} from "../../../__generated__/graphql";
import { GRAY_4, GRAY_5 } from "../../../theme";
import { AssembleAutocomplete } from "./AssembleAutocomplete";
import { AutocompleteTableCell } from "./AutocompleteTableCell";
import { GroupHeader } from "./GroupHeader";

interface Props {
  positions: Position[];
  value: Position | null;
  className?: string;
  label?: string;
  onChange(position: Position | null): unknown;
  variant?: "normal" | "table";
  disableClearable?: boolean;
  disabled?: boolean;
  classes?: {
    input?: string;
    tableCell?: string;
  };
  colSpan?: number;
  explanatoryTooltip?:
    | {
        title: string;
        placement: TooltipProps["placement"];
        body: string;
        ctaLabel: string;
        ctaUrl: string;
        disabled: boolean;
        placeholder?: string;
        newTab?: boolean;
      }
    | undefined;
}

const useStyles = makeStyles((theme) => ({
  departmentLabel: {
    color: GRAY_5,
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: 700,
  },
  ladderLabel: {
    color: GRAY_4,
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: 700,
  },
  optionWrapper: {
    width: "100%",
  },
  option: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  optionNameIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  optionName: {
    fontSize: "0.8125rem",
    flexShrink: 1,
  },
  optionLevel: {
    fontSize: "0.8125rem",
    color: GRAY_4,
    flexShrink: 0,
    marginLeft: theme.spacing(1),
  },
  optionLevelIcon: {
    marginRight: theme.spacing(1),
  },
}));

type StringTuple = [string, string];

export function PositionAutocomplete({
  positions,
  value,
  onChange,
  className,
  classes,
  label = "Position",
  variant = "normal",
  colSpan = 1,
  disableClearable,
  disabled,
  explanatoryTooltip = undefined,
}: Props): JSX.Element {
  const componentClasses = useStyles();

  const Component =
    variant === "table" ? AutocompleteTableCell : AssembleAutocomplete;

  const positionsSortedByGroup = positions
    .slice()
    .sort(contramap((p) => p.level))
    .sort(contramap((p) => p.ladder.name))
    .sort(contramap((p) => p.ladder.department.name));

  return (
    <Component<Position, StringTuple>
      explanatoryTooltip={explanatoryTooltip} // used in AutocompleteTableCell
      className={className}
      options={positionsSortedByGroup}
      value={value}
      label={label}
      colSpan={colSpan}
      classes={classes}
      disabled={disabled}
      placeholder="Select a position"
      noOptionsText="We can't find any positions matching your query."
      onChange={onChange}
      getOptionLabel={(position) => position.name}
      getOptionGroup={(position) => [
        position.ladder.name,
        position.ladder.department.name,
      ]}
      encodeGroup={(tuple) => JSON.stringify(tuple)}
      decodeGroup={(encodedTuple) => JSON.parse(encodedTuple) as StringTuple}
      disableClearable={disableClearable}
      renderOption={(option) => (
        <PositionOption
          key={option.id}
          option={option}
          classes={{
            wrapper: componentClasses.optionWrapper,
            root: componentClasses.option,
            name: componentClasses.optionName,
            nameIcon: componentClasses.optionNameIcon,
            level: componentClasses.optionLevel,
            levelIcon: componentClasses.optionLevelIcon,
          }}
        />
      )}
      renderGroup={([ladder, department]) => (
        <PositionGroup
          key={`${department}-${ladder}`}
          ladder={ladder}
          department={department}
          classes={{
            ladder: componentClasses.ladderLabel,
            department: componentClasses.departmentLabel,
          }}
        />
      )}
    />
  );
}

function PositionGroup(props: {
  department: string;
  ladder: string;
  classes: {
    department: string;
    ladder: string;
  };
}): JSX.Element {
  return (
    <GroupHeader>
      <Typography className={props.classes.department}>
        {props.department}
        {" / "}
        <Typography component="span" className={props.classes.ladder}>
          {props.ladder}
        </Typography>
      </Typography>
    </GroupHeader>
  );
}

function PositionOption(props: {
  option: Position;
  classes: {
    wrapper: string;
    root: string;
    name: string;
    nameIcon: string;
    level: string;
    levelIcon: string;
  };
}): JSX.Element {
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const showHourly =
    compStructure?.allowHourlyEmployees === true &&
    isEnabled(FeatureFlag.HourlyEmployees);

  return (
    <div className={props.classes.wrapper}>
      <Tooltip title={props.option.name}>
        <span className={props.classes.root}>
          <div className={props.classes.nameIcon}>
            {showHourly && (
              <>
                {props.option.type === PositionType.ANNUAL && (
                  <CalendarIcon height="16px" width="16px" />
                )}
                {props.option.type === PositionType.HOURLY && (
                  <ClockIcon height="16px" width="16px" />
                )}
              </>
            )}
            <AssembleTypography noWrap className={props.classes.name}>
              {props.option.name}
            </AssembleTypography>
          </div>
          <AssembleTypography noWrap className={props.classes.level}>
            <LevelIcon className={props.classes.levelIcon} inline inherit />
            {props.option.level}
          </AssembleTypography>
        </span>
      </Tooltip>
    </div>
  );
}
