import { ISettings } from "@flatfile/adapter";
import { CompStructure } from "src/__generated__/graphql";

export const getFlatfileDataUpload = (
  compStructure: CompStructure
): ISettings => {
  const uploadFields = compStructure.allBandTypes.flatMap((bandType) => {
    return compStructure.bandPointTypes.map((bandPoint) => {
      return `${bandType} - ${bandPoint}`;
    });
  });

  return {
    title: "Upload Data",
    type: "comp_data",
    fields: [
      {
        label: "Position",
        key: "position",
      },
      {
        label: "Level",
        key: "level",
      },
      {
        label: "Job Code",
        key: "jobCode",
      },
      {
        label: "Currency Code",
        key: "currencyCode",
        validators: [
          {
            validate: "required",
          },
        ],
      },
      ...uploadFields.map((uploadField) => ({
        label: uploadField,
        key: uploadField,
      })),
    ],
  };
};
