import { gql } from "@apollo/client";
import { getFormattedMonthAndDay } from "@asmbl/shared/time";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { PhaseProgressBar_compCycle } from "src/__generated__/graphql";
import { CheckIcon } from "src/components/AssembleIcons/Small/CheckIcon";
import { PushPinIcon } from "src/components/AssembleIcons/Small/PushPinIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { ExplanatoryTooltip } from "src/components/ExplanatoryTooltip";
import {
  GRAY_1,
  GRAY_4,
  GRAY_5,
  GRAY_6,
  GRAY_8,
  RED,
  theme,
  WHITE,
} from "src/theme";
import { PhaseTooltip } from "./PhaseTooltip";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: WHITE,
    // this is to prevent double border when the late banner is not present
    marginTop: "-1px",
    borderTop: `1px solid ${GRAY_6}`,
    borderBottom: `1px solid ${GRAY_6}`,
    height: theme.spacing(5),
  },
  phaseContainer: {
    display: "flex",
    columnGap: theme.spacing(1),
    padding: theme.spacing(1.25, 2, 1.25, 4),
    position: "relative",
    "&:after": {
      left: "100%",
      top: "50%",
      border: "solid transparent",
      content: "''",
      height: 0,
      width: 0,
      position: "absolute",
      pointerEvents: "none",
      borderColor: "rgba(255, 255, 255, 0)",
      borderLeftColor: "#ffffff",
      borderWidth: "19px",
      marginTop: "-19px",
      zIndex: 2,
    },
    "&:before": {
      left: "100%",
      top: "50%",
      border: "solid transparent",
      content: "''",
      height: 0,
      width: 0,
      position: "absolute",
      pointerEvents: "none",
      borderColor: "rgba(218, 234, 249, 0)",
      borderLeftColor: GRAY_6,
      borderWidth: "20px",
      marginTop: "-20px",
      zIndex: 2,
    },
  },
  lastPhase: {
    borderRight: `1px solid ${GRAY_6}`,
    "&:before": {
      content: "none",
    },
    "&:after": {
      content: "none",
    },
  },
  firstPhase: {
    borderLeft: `1px solid ${GRAY_6}`,
    paddingLeft: theme.spacing(2),
  },
  previousPhase: {
    backgroundColor: GRAY_8,
    paddingTop: `calc(${theme.spacing(1.25)}px - 1px)`,
    paddingBottom: `calc(${theme.spacing(1.25)}px - 1px)`,
    "&:after": {
      borderLeftColor: GRAY_8,
    },
  },
  phaseOrderContainer: {
    display: "flex",
    width: "20px",
    height: "20px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "40px",
    border: `1px solid ${GRAY_6}`,
    color: GRAY_4,
    fontSize: ".75rem",
  },
  previousPhaseOrderContainer: {
    backgroundColor: WHITE,
  },
  currentPhaseOrderContainer: {
    borderColor: GRAY_1,
    color: GRAY_1,
    "--mask": `linear-gradient(${GRAY_1}, ${GRAY_1}) padding-box, conic-gradient(${GRAY_1} var(--p, 66%), transparent 0%) border-box`,
    mask: "var(--mask)",
  },
  dateContainer: {
    lineHeight: "155%",
    textAlign: "center",
    fontWeight: 500,
  },
}));

type Props = {
  compCycle: PhaseProgressBar_compCycle;
};

export const PhaseProgressBar = ({ compCycle }: Props) => {
  const classes = useStyles();
  const phases = compCycle.phases;
  const currentPhase = compCycle.currentPhase;
  const hasCurrentPhase = currentPhase != null;
  const myPhase = phases.find((phase) => phase.isMyPhase);
  return (
    <div className={classes.root} data-cy="phase-progress-bar">
      <>
        {phases.map((phase, index) => (
          <ExplanatoryTooltip
            key={phase.phaseOrder}
            body={
              <PhaseTooltip
                phase={phase}
                currentPhaseOrder={currentPhase?.phaseOrder}
                nextPhaseStart={phases[index + 1]?.startDate}
                myPhase={myPhase?.phaseOrder}
              />
            }
          >
            <div
              key={phase.phaseOrder}
              className={clsx(classes.phaseContainer, {
                [classes.previousPhase]:
                  hasCurrentPhase && currentPhase.phaseOrder > phase.phaseOrder,
                [classes.lastPhase]:
                  phases[index] === phases[phases.length - 1],
                [classes.firstPhase]: index === 0,
              })}
            >
              <div
                className={clsx(classes.phaseOrderContainer, {
                  [classes.previousPhaseOrderContainer]:
                    hasCurrentPhase &&
                    currentPhase.phaseOrder > phase.phaseOrder,
                  [classes.currentPhaseOrderContainer]:
                    hasCurrentPhase && currentPhase.id === phase.id,
                })}
              >
                {hasCurrentPhase &&
                currentPhase.phaseOrder > phase.phaseOrder ? (
                  <CheckIcon color={GRAY_5} />
                ) : phases[index].isMyPhase ? (
                  <PushPinIcon color={RED} />
                ) : (
                  phase.phaseOrder
                )}
              </div>
              <div className={classes.dateContainer}>
                <AssembleTypography
                  textColor={
                    currentPhase && currentPhase.id === phase.id
                      ? GRAY_1
                      : GRAY_4
                  }
                  variant="productTooltip"
                >
                  {getFormattedMonthAndDay(new Date(phase.startDate))}
                </AssembleTypography>
              </div>
            </div>
          </ExplanatoryTooltip>
        ))}
      </>
    </div>
  );
};

const COMP_CYCLE_PHASE = gql`
  fragment PhaseProgressBar_compCyclePhase on CompCyclePhase2 {
    id
    phaseOrder
    startDate
    isMyPhase
    compCyclePhaseAssignments {
      id
      phaseId
      status
      assigneeId
    }
  }
`;

PhaseProgressBar.fragments = {
  compCycle: gql`
    ${COMP_CYCLE_PHASE}
    fragment PhaseProgressBar_compCycle on CompCycle2 {
      id
      phases {
        id
        ...PhaseProgressBar_compCyclePhase
      }
      currentPhase {
        id
        phaseOrder
      }
    }
  `,
};
