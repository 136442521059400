import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { useTrack } from "../../../../analytics";
import { ExplanatoryTooltip } from "../../../../components/ExplanatoryTooltip";
import { SaveButton } from "../../../../components/Form/SaveButton";

const SESSION_KEY = "allocate-budgets-has-published-once";
const TRUE = "true";

const useStyles = makeStyles((theme) => ({
  disabled: {},
  button: {
    height: "2rem",
    width: "6rem",

    boxShadow: "0px 1px 3px rgba(65, 54, 241, 0.5)",

    "&$disabled": {
      boxShadow: "0px 1px 3px rgba(219, 221, 224, 0.5)",
    },
  },
  modalTitle: {
    paddingBottom: theme.spacing(2),
  },
  emoji: {
    display: "inline-block",
    marginRight: "0.5rem",
  },
}));

type Props = {
  onPublish: () => Promise<unknown>;
  disabled: boolean;
};

export function PublishBudgetButton({
  onPublish,
  disabled: hasOverages,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const publishedOnce = sessionStorage.getItem(SESSION_KEY) === TRUE;

  const handleClick = () => {
    setLoading(true);
    trackEvent({ object: "Publish Budget", action: "Clicked" });
    return onPublish()
      .finally(() => {
        setLoading(false);
        setIsModalOpen(true);
        return true;
      })
      .then(() => true);
  };

  const disabled = loading || hasOverages;

  const closeModal = () => {
    setIsModalOpen(false);
    sessionStorage.setItem(SESSION_KEY, TRUE);
  };

  return (
    // There are two states where we want to disable the button:
    // 1. when the button has just been pushed and we're waiting for the publish
    //    query to finish
    // 2. when there are budget overages
    //
    // We only want to show the tooltip if it's disabled because of budget
    // overages, not if it's just been pressed and it's loading.
    <>
      <ConditionalToolTip
        disabled={hasOverages}
        hasBeenPublished={!publishedOnce}
      >
        <SaveButton
          onSave={handleClick}
          disabled={disabled}
          labels={{
            default: "Publish",
            processing: "Publishing...",
            success: "Published",
          }}
          hideEndIcon={loading ? true : false}
          className={clsx(classes.button, { [classes.disabled]: disabled })}
        />
      </ConditionalToolTip>
      <Dialog open={isModalOpen && !publishedOnce}>
        <DialogContent>
          <Typography variant="h4" className={classes.modalTitle}>
            <span className={classes.emoji}>🛫</span>
            Your allocations are on their way to your team!
          </Typography>
          <Typography variant="body1">
            If you’re not done, you can continue allocating budgets now or come
            back and finish up later. Don’t forget to let your people know
            you’ve sent them budgets.&nbsp;
            <span className={classes.emoji}>😀</span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={closeModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function ConditionalToolTip({
  disabled,
  hasBeenPublished,
  children,
}: {
  disabled: boolean;
  hasBeenPublished: boolean;
  children: JSX.Element;
}): JSX.Element {
  if (disabled) {
    return (
      <ExplanatoryTooltip
        title="Publishing Errors"
        body="We're unable to publish budget allocations with errors."
        width="max-content"
        hideArrow
      >
        <span>{children}</span>
      </ExplanatoryTooltip>
    );
  }

  if (!hasBeenPublished) {
    return (
      <ExplanatoryTooltip
        title="Unpublished changes"
        body={
          "Unpublished changes are only seen by members of your team with " +
          "Full Access permissions. When published, allocations are public " +
          "and your team can use them to request comp changes."
        }
        width="fit-content"
        hideArrow
      >
        <span>{children}</span>
      </ExplanatoryTooltip>
    );
  }
  return children;
}
