import { ISettings } from "@flatfile/adapter";
import { INTEGER_VALIDATOR } from "./Validators";

export const OneTimeBonusFields: ISettings["fields"] = [
  {
    label: "One-Time Bonus ($)",
    key: "bonus",
    validators: [
      {
        validate: "regex_matches",
        regex: INTEGER_VALIDATOR,
        error: "Must be a whole number",
      },
    ],
  },
  {
    label: "One-Time Bonus Notes",
    key: "bonusNotes",
  },
];
