import { gql, useQuery } from "@apollo/client";
import { LinearProgress } from "@material-ui/core";
import {
  OrganizationHierarchyLoadingBoundaryQuery,
  OrganizationHierarchyLoadingBoundaryQueryVariables,
} from "src/__generated__/graphql";
import { NoOrgHierarchy } from "../EmptyState/EmptyState";
import { ErrorView } from "../ErrorView";
import { OrganizationHierarchy } from "./OrganizationHierarchy";
import { findMissingNodes } from "./shared/utils";

export function OrganizationHierarchyLoadingBoundary(): JSX.Element | null {
  const { data, loading, error } = useQuery<
    OrganizationHierarchyLoadingBoundaryQuery,
    OrganizationHierarchyLoadingBoundaryQueryVariables
  >(OrganizationHierarchyLoadingBoundary.query, {
    variables: {
      includeInactive: true,
    },
  });

  if (!data) {
    return loading ? <LinearProgress /> : <ErrorView text={error?.message} />;
  }

  const hasInactiveManagers = findMissingNodes(data.employee2s.employees);

  if (hasInactiveManagers.length > 0) {
    const affectedIds = hasInactiveManagers.map((emp) => emp.id).join(", ");
    return <NoOrgHierarchy missingCopy={affectedIds} />;
  }

  return <OrganizationHierarchy data={data.employee2s.employees} />;
}

OrganizationHierarchyLoadingBoundary.query = gql`
  ${OrganizationHierarchy.fragments.employee}
  query OrganizationHierarchyLoadingBoundaryQuery($includeInactive: Boolean) {
    employee2s(includeInactive: $includeInactive) {
      employees {
        id
        ...OrganizationHierarchy_employee
      }
    }
  }
`;
