import { makeStyles, Typography } from "@material-ui/core";
import { ExplanatoryTooltip } from "../../../../components/ExplanatoryTooltip";
import { GRAY_5 } from "../../../../theme";

const useStyles = makeStyles(() => ({
  dogEarWrapper: {
    display: "block",
    position: "absolute",
    top: "0px",
    left: "0px",
    height: "0.75rem",
    width: "0.75rem",
    zIndex: 1,
    transform: "rotate(270deg)",
    background: "linear-gradient(148.88deg, #FAA01A 29.51%, #FFB628 85.88%)",
    clipPath: "polygon(0 0, 100% 0, 100% 100%)",
  },
  dogEarIcon: {
    height: "0.5rem",
    width: "0.5rem",
    transform: "rotate(270deg)",
    display: "inline-flex",
    verticalAlign: "middle",
    background: "linear-gradient(148.88deg, #FAA01A 29.51%, #FFB628 85.88%)",
    clipPath: "polygon(0 0, 100% 0, 100% 100%)",
  },
  tooltipText: {
    fontSize: "0.875rem",
    fontWeight: "normal",
    lineHeight: "1.55",
    letterSpacing: "-0.25px",
    color: GRAY_5,
  },
}));

export function AllocateBudgetsDogEarWithTooltip(): JSX.Element {
  const classes = useStyles();

  return (
    <ExplanatoryTooltip
      title="Unpublished Changes"
      width="370px"
      placement="top"
      hideArrow
      body={
        <Typography className={classes.tooltipText}>
          This&nbsp;
          <i>
            <div className={classes.dogEarIcon} />
          </i>
          &nbsp;indicates a budget change that hasn't been sent to your comp
          team. You can send them to your team by clicking the "Publish" button.
        </Typography>
      }
    >
      <div className={classes.dogEarWrapper} />
    </ExplanatoryTooltip>
  );
}
