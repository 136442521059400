import { Button, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { SearchBar } from "../../../components/Search/SearchBar";
import { PURPLE_1 } from "../../../theme";
import { MarketEditor } from "./MarketEditor";

const useStyles = makeStyles((theme) => ({
  bar: {
    display: "flex",
    justifyContent: "space-between",
    marginBlock: theme.spacing(2.5),
  },
  button: {
    "& .MuiButtonBase-root": {
      "&:hover": {
        boxShadow: `0 0 0 3px ${PURPLE_1}`,
      },
    },
  },
}));

type Props = {
  searchTerm: string;
  onSearchTermChange: (value: string) => unknown;
};

export function MarketsActions({
  searchTerm,
  onSearchTermChange,
}: Props): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <>
      {open && <MarketEditor onClose={() => setOpen(false)} />}
      <div className={classes.bar}>
        <SearchBar
          searchTerm={searchTerm}
          handleSearchChange={onSearchTermChange}
          placeholder="Search for a market&hellip;"
          width="wide"
        />
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setOpen(true)}
        >
          New Market
        </Button>
      </div>
    </>
  );
}
