import { gql, useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { useTrack } from "src/analytics";
import { CsvDownloader } from "src/components/CsvExport/CsvDownloader";
import { CsvExport } from "src/components/CsvExport/CsvExport";
import {
  CostToMoveAnalysisQueryVariables,
  CostToMoveExportQuery,
} from "src/__generated__/graphql";

type Props = {
  filter: { [key: string]: number[] | null | undefined };
  targetBandPoint: string;
};

export function CostToMoveExport({
  filter,
  targetBandPoint,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const [loading, setLoading] = useState<boolean>(false);
  const [exportCSV] = useLazyQuery<
    CostToMoveExportQuery,
    CostToMoveAnalysisQueryVariables
  >(CostToMoveExport.query, { variables: { filter, targetBandPoint } });

  const fetchCSV = async () => {
    setLoading(true);
    const response = await exportCSV();
    const result = response.data?.costToMoveAnalysis.employeeExport;
    setLoading(false);
    trackEvent({ object: "Band Placement Report", action: "Exported" });

    if (result == null) {
      throw new Error();
    }

    return result;
  };

  return (
    <CsvDownloader fetch={fetchCSV} filename="CostToMoveAnalysis">
      {(onDownload) => (
        <CsvExport variant="icon" onClick={onDownload} loading={loading} />
      )}
    </CsvDownloader>
  );
}

CostToMoveExport.query = gql`
  query CostToMoveExportQuery(
    $targetBandPoint: String!
    $filter: GetEmployeesInput
  ) {
    costToMoveAnalysis(target: $targetBandPoint, filter: $filter) {
      employeeExport
    }
  }
`;
