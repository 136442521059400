import { useMutation, useQuery } from "@apollo/client";
import fastDeepEqual from "fast-deep-equal";
import {
  GetEquitySettings,
  GetPermissionSettings,
  UpdatePermissionSettings,
  UpdatePermissionSettingsVariables,
  UpsertCompStructure,
  UpsertCompStructureVariables,
  UpsertOfferConfig,
  UpsertOfferConfigVariables,
} from "../../__generated__/graphql";
import Loading from "../../components/Settings/Loading";
import {
  OfferContentsData,
  OfferContentsForm,
} from "../../components/Settings/OfferContents/OfferContentsForm";
import { areCustomFieldsValid } from "../../models/Offer";
import {
  UPDATE_ORG_PERMISSION_SETTINGS,
  UPSERT_COMP_STRUCTURE,
  UPSERT_OFFER_CONFIG,
} from "../../mutations";
import {
  GET_EQUITY_SETTINGS,
  GET_ORG_PERMISSION_SETTINGS,
} from "../../queries";
import { PageContainer } from "./PageContainer";

const OfferContents = (): JSX.Element | null => {
  const { data: equitySettingsData, loading: equitySettingsDataLoading } =
    useQuery<GetEquitySettings>(GET_EQUITY_SETTINGS);

  const { data: permissionData, loading: permissionDataLoading } =
    useQuery<GetPermissionSettings>(GET_ORG_PERMISSION_SETTINGS);

  const [updateCompStructure, { loading: compUpdating }] = useMutation<
    UpsertCompStructure,
    UpsertCompStructureVariables
  >(UPSERT_COMP_STRUCTURE, {
    refetchQueries: [{ query: GET_EQUITY_SETTINGS }],
  });

  const [updateOfferConfig, { loading: offerConfigUpdating }] = useMutation<
    UpsertOfferConfig,
    UpsertOfferConfigVariables
  >(UPSERT_OFFER_CONFIG, {
    refetchQueries: [{ query: GET_EQUITY_SETTINGS }],
  });

  const [updatePermissionSettings] = useMutation<
    UpdatePermissionSettings,
    UpdatePermissionSettingsVariables
  >(UPDATE_ORG_PERMISSION_SETTINGS);

  const isOfferConfigDataDirty = (
    initialFormData: OfferContentsData,
    newFormData: OfferContentsData
  ) => {
    return !fastDeepEqual(
      getOfferConfigInputVariables(initialFormData),
      getOfferConfigInputVariables(newFormData)
    );
  };

  const saveFormData = async (
    initialFormData: OfferContentsData,
    newFormData: OfferContentsData
  ) => {
    // Don't allow saving if any custom fields are invalid
    if (!areCustomFieldsValid(newFormData.customFields)) {
      return;
    }
    const compStructureMutation = updateCompStructure({
      variables: getCompStructureInputVariables(newFormData),
    });

    const offerConfigMutation = isOfferConfigDataDirty(
      initialFormData,
      newFormData
    )
      ? updateOfferConfig({
          variables: getOfferConfigInputVariables(newFormData),
        })
      : null;

    const permissionSettingsMutation =
      permissionData != null && newFormData.allowAnonymousDataAccess != null
        ? updatePermissionSettings({
            variables: {
              data: {
                canRecruitersViewAnonymizedStatistics:
                  newFormData.allowAnonymousDataAccess,
              },
            },
            optimisticResponse: {
              updatePermissionSettings: {
                __typename: "PermissionSettings",
                id: permissionData.organization.permissionSettings.id,
                teamCompensationAccess:
                  permissionData.organization.permissionSettings
                    .teamCompensationAccess,
                canRecruitersViewAnonymizedStatistics:
                  newFormData.allowAnonymousDataAccess,
                employeeBandAccess:
                  permissionData.organization.permissionSettings
                    .employeeBandAccess,
              },
            },
          })
        : null;

    return Promise.all([
      compStructureMutation,
      offerConfigMutation,
      permissionSettingsMutation,
    ]);
  };

  if (equitySettingsDataLoading || permissionDataLoading) return <Loading />;
  if (!equitySettingsData?.compStructure) return null;

  const { compStructure, offerConfig } = equitySettingsData;
  const isSaving = compUpdating || offerConfigUpdating;

  return (
    <PageContainer
      header="Offer Contents"
      description="Control what information is shown in Illustrative Offers."
      centerContent
    >
      <OfferContentsForm
        allowAnonymousDataAccess={
          permissionData?.organization.permissionSettings
            .canRecruitersViewAnonymizedStatistics
        }
        compStructure={compStructure}
        offerConfig={offerConfig}
        onSave={saveFormData}
        isSaving={isSaving}
      />
    </PageContainer>
  );
};

function getCompStructureInputVariables(
  data: OfferContentsData
): UpsertCompStructureVariables {
  return {
    data: {
      companyDescription: data.companyDescription,
      showPercentOwnership: data.showPercentOwnership,
      showValuation: data.showValuation,
    },
  };
}

function getOfferConfigInputVariables(
  data: OfferContentsData
): UpsertOfferConfigVariables {
  const {
    showEquityInformation,
    showFdso,
    showStage,
    showCurrentEquityValue,
    showAnnualizedEquity,
    requireApproval,
    defaultWelcomeMessage,
    defaultClosingMessage,
    equityFootnote,
    equityCashInValuationCurrency,
    showSharePriceFootnote,
    sharePriceLabel,
    customFields: keyedCustomFields,
  } = data;
  //Strip off `uniqueKey` and `__typename` from custom fields
  const customFields = keyedCustomFields?.map(
    ({ name, variant, options, description }) => ({
      name,
      options,
      variant,
      description,
    })
  );

  return {
    data: {
      showEquityInformation,
      showFdso,
      showStage,
      showCurrentEquityValue,
      showAnnualizedEquity,
      requireApproval,
      defaultWelcomeMessage,
      defaultClosingMessage,
      equityFootnote,
      equityCashInValuationCurrency,
      showSharePriceFootnote,
      sharePriceLabel,
      customFields,
    },
  };
}

export default OfferContents;
