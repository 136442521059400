import { sendEmailVerification } from "@firebase/auth";
import { Box, Button, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { authInstance } from "../firebase";
import { useInterval } from "../hooks/useInterval";

export function EmailVerification(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  // Periodically check if email is verified so user doesn't need to refresh.
  useInterval(async () => {
    await authInstance.currentUser?.reload();
    if (authInstance.currentUser?.emailVerified ?? false) {
      window.location.reload();
    }
  }, 3000);

  const user = authInstance.currentUser;
  const name = user?.displayName ?? "";
  const formattedName = name === "" ? "" : ` ${name}`;

  const startVerification = async () => {
    try {
      if (user !== null) {
        await sendEmailVerification(user);
        enqueueSnackbar("Check your email for a link to verify your account.", {
          variant: "success",
        });
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        enqueueSnackbar(e.message, { variant: "error" });
      }
    }
  };

  return (
    <Box m={4}>
      <Typography variant="h5">Welcome{formattedName}!</Typography>
      <Typography>
        We've sent a verification email to {user?.email}. Click the link in the
        email to continue.
      </Typography>
      <Box m={2} />
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={startVerification}
      >
        Resend verification email
      </Button>
      <Box m={2} />
      <Button
        variant="outlined"
        size="small"
        onClick={async () => await authInstance.signOut()}
      >
        Sign out
      </Button>
    </Box>
  );
}
