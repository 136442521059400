import { makeStyles } from "@material-ui/core";
import { ReactNode } from "react";
import { WHITE } from "../../../theme";

interface Props {
  children: ReactNode;
}

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: "20px 0 10px 0",
    display: "flex",
    alignItems: "center",
    height: "50px",
    position: "sticky",
    zIndex: 1,
    top: "0",
    backgroundColor: WHITE,
  },
}));

export function GroupHeader({ children }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <li role="separator" className={classes.root}>
      {children}
    </li>
  );
}
