import { gql } from "@apollo/client";
import { formatCurrency } from "@asmbl/shared/money";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import {
  CurrencyCode,
  PositionType,
  PositionVisualization_position,
} from "../__generated__/graphql";
import {
  ADJUSTED_CASH_BAND_FIELDS,
  ADJUSTED_EQUITY_BAND_FIELDS,
} from "../fragments";
import {
  AdjustedBand,
  PointGroup,
  pointGroups,
  positionBands,
} from "../models/Band";
import { AdjustedBandPoint } from "../models/BandPoint";
import { useExcludedBandNames } from "../pages/LadderDetail/ExcludedBandNameContext";
import { APP_FONTS } from "../theme";
import { getBandColors } from "../utils";
import { useCurrencies } from "./CurrenciesContext";
import { CompensationEmptyWarning } from "./EmptyState/CompensationEmptyWarning";

type PositionVisualizationProps = {
  position: PositionVisualization_position;
  empty: boolean;
};

export function PositionVisualization({
  position,
  empty,
}: PositionVisualizationProps): JSX.Element {
  const { excludedBandNames } = useExcludedBandNames();
  const { selectedCurrency } = useCurrencies();

  const allBandNames = positionBands<AdjustedBand>(position, new Set()).map(
    (band) => band.name
  );

  const pgs = pointGroups<AdjustedBandPoint>(position, excludedBandNames);

  const canViewComp =
    position.adjustedCashBands !== null ||
    position.adjustedEquityBands !== null;

  return (
    <>
      <ReactApexChart
        key={selectedCurrency.code}
        options={getChartOptions(pgs, allBandNames, selectedCurrency.code)}
        series={getChartData(
          position.type,
          positionBands<AdjustedBand>(position, excludedBandNames)
        )}
        type="bar"
        height={250}
      />
      {empty && <CompensationEmptyWarning noAccess={!canViewComp} />}
    </>
  );
}

PositionVisualization.fragments = {
  position: gql`
    ${ADJUSTED_CASH_BAND_FIELDS}
    ${ADJUSTED_EQUITY_BAND_FIELDS}
    fragment PositionVisualization_position on Position {
      type
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedCashBandFields
      }
      adjustedEquityBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedEquityBandFields
      }
    }
  `,
};

function getChartData(type: PositionType, bands: AdjustedBand[]) {
  return bands.map((band) => ({
    name: band.name,
    data: band.bandPoints.map((point) =>
      type === PositionType.HOURLY
        ? point.hourlyCashEquivalent.value
        : point.annualCashEquivalent.value
    ),
  }));
}

function getChartOptions(
  pgs: PointGroup<AdjustedBandPoint>[],
  bandTypes: string[],
  currencyCode: CurrencyCode
): ApexOptions {
  return {
    labels: bandTypes,
    dataLabels: { enabled: false },
    chart: {
      type: "bar",
      stacked: true,
      toolbar: { show: false },
      parentHeightOffset: 0,
      fontFamily: APP_FONTS,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: pgs.map((pointGroup) => pointGroup.name),
      labels: {
        formatter: function (value: string) {
          const label = formatCurrency(
            { value: Number(value), currency: currencyCode },
            {
              notation: "compact",
              currency: currencyCode,
              minimumSignificantDigits: 1,
              maximumSignificantDigits: 4,
            }
          );

          return label;
        },
      },
      position: "top",
    },
    colors: getChartColors(bandTypes, pgs.at(0)),
    tooltip: {
      theme: "light",
      y: {
        formatter: (value: number) =>
          formatCurrency({ value, currency: currencyCode }),
      },
    },
    legend: { show: false },
    stroke: { show: false },
    states: {
      normal: {
        filter: { type: "none" },
      },
      hover: {
        filter: { type: "lighten", value: 0.01 },
      },
      active: {
        filter: { type: "lighten", value: 0.01 },
      },
    },
  };
}

function getChartColors(
  allBandNames: string[],
  pg: PointGroup<{ name: string; bandName: string }> | undefined
) {
  const colorMap = new Map<string, string>();

  allBandNames.forEach((name, index) =>
    colorMap.set(name, getBandColors(index))
  );

  return pg?.bandPoints.map((point) => colorMap.get(point.bandName)) ?? [];
}
