import { Box, makeStyles } from "@material-ui/core";
import { ReactNode } from "react";
import { WHITE } from "../../theme";
import { Eyebrow } from "../Form/Eyebrow";
import { LoadingSpinner } from "../LoadingSpinner";

type Props = {
  title: string;
  children: ReactNode;
  loading?: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    minHeight: "270px",
  },
  title: {
    height: "40px",
    display: "flex",
    alignItems: "center",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: WHITE,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));

export function VisualizationCard({
  title,
  children,
  loading = false,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Box className={classes.title}>
            <Eyebrow label={title} />
          </Box>
          <Box className={classes.content}>{children}</Box>
        </>
      )}
    </Box>
  );
}
