import { Route, Routes } from "react-router-dom";
import { useTrack } from "src/analytics";
import { useAuth } from "src/components/Auth/AuthContext";
import { NoFeatureAccess } from "src/components/EmptyState/EmptyState";
import { Redirect } from "../../components/Redirect";
import { Compare } from "./Bands/Compare";
import { CorrectionCostToMovePageLoadingBoundary } from "./CorrectionCostToMove/CorrectionCostToMovePageLoadingBoundary";
import { CostToMovePageLoadingBoundary } from "./CostToMove/CostToMovePageLoadingBoundary";
import { InsightsHeader } from "./InsightsHeader";

/**
 * This component handles the top level navigation for Insights. If the user has
 * permissions to view multiple Insights pages, we display a page header with a
 * navigation bar. Otherwise, we redirect directly to the page the user has
 * access to, with no excess UI chrome.
 */
export const InsightsRouter = (): JSX.Element => {
  const { permissions } = useAuth();
  const { Track } = useTrack({ area: "Insights" });

  const ChildComponents = [
    Compare,
    CostToMovePageLoadingBoundary,
    CorrectionCostToMovePageLoadingBoundary,
  ] as const;
  const AccessibleChildComponents = ChildComponents.filter((Component) =>
    Component.canAccess(permissions)
  );

  if (AccessibleChildComponents.length === 0) {
    return <NoFeatureAccess feature="Insights" />;
  }

  return (
    <Track>
      <Routes>
        <Route
          path="/"
          element={<Redirect to={AccessibleChildComponents[0].path} />}
        />
        <Route
          path="*"
          element={
            AccessibleChildComponents.length === 1 ? <></> : <InsightsHeader />
          }
        />
      </Routes>
      <CanduBar />
      <Routes>
        {AccessibleChildComponents.map((Component) => (
          <Route
            key={Component.path}
            path={Component.path}
            element={<Component />}
          />
        ))}
      </Routes>
    </Track>
  );
};
function CanduBar() {
  return <div id="candu-bar" />;
}
