import { gql } from "@apollo/client";
import { makeStyles, MuiThemeProvider } from "@material-ui/core";
import { HierarchyNode, select } from "d3";
import { OrgChart } from "d3-org-chart";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useIntercom } from "react-use-intercom";
import { CompCycleOrganizationChart_employee as Employee } from "src/__generated__/graphql";
import { useTrack } from "src/analytics";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { COMP_CYCLE_HIERARCHY_CONFIGURATION_SIDEBAR_WIDTH } from "src/pages/CompCycle/HierarchyConfiguration/CompCycleHierarchyConfigurationSidebar";
import { GRAY_5, SIDEBAR_WIDTH, theme } from "src/theme";
import { pixelsToNumber, remToPixels } from "src/utils";
import { useAuth } from "../Auth/AuthContext";
import {
  CompCycleData,
  CompCyclePhaseData,
} from "../CompCycle/CompCycleWizard/types";
import {
  EMPLOYEE_NODE_HEIGHT,
  EMPLOYEE_NODE_WIDTH,
  getDeepestNode,
  sanitizeData,
} from "../OrganizationHierarchy/shared";
import {
  SelectionMode,
  useCompCycleOrganizationChartSelectionData,
  useCompCycleOrganizationChartViewsData,
} from "./CompCycleOrganizationChartContext";
import { EmployeeCard } from "./EmployeeCard";
import { EmployeeCardButton } from "./EmployeeCardButton";

/**
 * This component is a wrapper around the d3-org-chart library.
 * It is used to render the organization chart.
 *
 * I can currently only see us using this to display the organization
 * meaning the nodes we are going to use will be Employee nodes.
 * Therefore I can assume that the node will be based off of the
 * employee fragment defined below.
 */
const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
  },
}));

type Props<T extends Employee> = {
  data: T[];
  wizardData: CompCycleData;
  chart: OrgChart<Employee>;
};

export function CompCycleOrganizationChart<T extends Employee>({
  data,
  wizardData,
  chart,
}: Props<T>): JSX.Element {
  const { trackEvent } = useTrack();
  const { organization } = useAuth();
  const intercom = useIntercom();

  const classes = useStyles();
  const { width, height } = useWindowDimensions();
  const [firstDraw, setFirstDraw] = useState<boolean>(true);
  const d3Container = useRef<null | HTMLDivElement>(null);

  const { chartState, setPageEditState } =
    useCompCycleOrganizationChartViewsData();

  const {
    selectionMode,
    selectedEmployees,
    selectedLayers,
    layerDepth,
    setSelectedLayers,
    setLayerDepth,
    setLayerData,
    setSelectedEmployees,
    setEmployeeData,
  } = useCompCycleOrganizationChartSelectionData();

  // by default, the data that we are getting back from the graphQL query
  // is not immutable, therefore `Object.isExtensible` will return `false`
  // this is a problem with the charting library as it needs to be able to
  // modify the objects so that it can add the necessary properties to it
  // this is why we are creating a new array of objects that are extensible
  const extensibleData = useMemo(() => {
    const sanitizedData = sanitizeData(data).map((employee) => ({
      ...employee,
    }));

    const deepestNode = getDeepestNode(sanitizedData);

    const syntheticRoot = sanitizedData.find(
      ({ parentId }) => parentId == null
    );

    // the synthetic root is the root node that we create to ensure
    // an absolute node, we know it exists but we are just adding
    // this fallback check (mostly to please TS)
    if (syntheticRoot == null) {
      return sanitizedData;
    }

    const organizationHeader =
      organization?.name != null ? organization.name : "Organization";

    // we are then expanding the deepest node so that the chart will initially
    // render with all the layers exposed

    return [
      { ...syntheticRoot, displayName: organizationHeader } as T,
      ...sanitizedData
        .filter(({ parentId }) => parentId != null)
        .map((employee) => ({
          ...employee,
          _expanded: deepestNode.id === employee.id ? true : false,
        })),
    ];
  }, [data, organization]);

  const sidebarWidth =
    remToPixels(COMP_CYCLE_HIERARCHY_CONFIGURATION_SIDEBAR_WIDTH) +
    pixelsToNumber(SIDEBAR_WIDTH);

  const chartWidth = width - sidebarWidth;

  const connectorColor = () => {
    // update connector line color
    select(d3Container.current).selectAll("path").style("stroke", GRAY_5);
  };

  useEffect(() => {
    if (d3Container.current != null) {
      chart
        .container(d3Container.current as unknown as string)
        .data(extensibleData)
        .compact(false)
        .nodeWidth(() => EMPLOYEE_NODE_WIDTH)
        .nodeHeight(() => EMPLOYEE_NODE_HEIGHT)
        .svgHeight(height)
        .svgWidth(chartWidth)
        .buttonContent((params) => {
          return ReactDOMServer.renderToStaticMarkup(
            <MuiThemeProvider theme={theme}>
              <EmployeeCardButton {...params} />
            </MuiThemeProvider>
          );
        })
        .nodeContent((node) => {
          const {
            depth,
            data: { parentId, id },
          } = node;

          const isSyntheticNode = parentId == null;

          const isNodeAssignedToPhase = wizardData.phasesData.some(
            ({ assigneeIds }) => assigneeIds.includes(id)
          );

          const isNodeAssigned =
            isNodeAssignedToPhase ||
            selectedEmployees.has(id) ||
            (node.parent?.children == null &&
              !isSyntheticNode &&
              !selectedEmployees.has(parentId));

          const isNodeSelected =
            selectionMode === SelectionMode.Layers
              ? selectedLayers[depth] === true
              : isNodeAssigned;

          return ReactDOMServer.renderToStaticMarkup(
            <MuiThemeProvider theme={theme}>
              <EmployeeCard
                node={node}
                isNodeSelected={isNodeSelected}
                isNodeAssigned={isNodeAssigned}
                isSyntheticNode={isSyntheticNode}
              />
            </MuiThemeProvider>
          );
        })
        .linkUpdate(connectorColor)
        .onNodeClick((node: HierarchyNode<Employee>) => {
          if (node.data.parentId == null) return;

          const nodeId = node.data.id;
          const state = chart.getChartState();
          const { allNodes } = state;
          const currentNode = allNodes.find(
            (node) => node.id?.toString() === nodeId.toString()
          );

          if (currentNode != null && chartState === "edit") {
            if (
              selectionMode === SelectionMode.Layers &&
              isNodeSelectable(
                currentNode.depth,
                wizardData.phasesData,
                layerDepth
              )
            ) {
              setSelectedLayers(currentNode.depth.toString());
              setPageEditState(true);

              trackEvent({
                object: "Phases Org Chart Layer",
                action: "Selected",
                layer: currentNode.depth.toString(),
              });
              intercom.trackEvent("Phases Org Chart Layer Selected", {
                layer: currentNode.depth.toString(),
              });
            } else if (selectionMode === SelectionMode.Managers) {
              const isNodeAssigned =
                layerDepth !== currentNode.depth &&
                selectedEmployees.has(currentNode.data.id);
              const updatedEmployees = new Set(selectedEmployees);

              isNodeAssigned
                ? updatedEmployees.delete(currentNode.data.id)
                : updatedEmployees.add(currentNode.data.id);

              setSelectedEmployees(updatedEmployees);
              setPageEditState(true);

              trackEvent({
                object: "Phases Org Chart Manager",
                action: "Selected",
                manager: currentNode.data.id,
              });
            }
          }
        })
        .render();

      // set the depth of the chart (how many layers of management)
      // so other components can use this data
      if (firstDraw) {
        const state = chart.getChartState();
        setLayerDepth(Math.max(...state.allNodes.map((node) => node.depth)));
      }

      // set the layer data of the chart (which employee is on which layer)
      // and the employee data
      // so other components can use this data
      if (firstDraw) {
        const tempLayerData: { [key: number]: number[] | undefined } = {};
        const state = chart.getChartState();
        const nodes = state.allNodes;

        nodes.forEach((node) => {
          const currLayer = tempLayerData[node.depth];

          if (currLayer) {
            tempLayerData[node.depth] = [...currLayer, node.data.id];
          } else {
            tempLayerData[node.depth] = [node.data.id];
          }
        });
        // only track employees that have reports and can be assigned to a phase
        setEmployeeData(
          extensibleData.filter((emp) => emp._totalSubordinates > 0)
        );
        setLayerData(tempLayerData);
      }

      setFirstDraw(false);
    }
  }, [
    chart,
    wizardData.phasesData,
    chartWidth,
    extensibleData,
    firstDraw,
    selectedLayers,
    selectionMode,
    width,
    chartState,
    intercom,
    layerDepth,
    height,
    d3Container,
    selectedEmployees,
    setLayerData,
    trackEvent,
    setLayerDepth,
    setSelectedLayers,
    setPageEditState,
    setEmployeeData,
    setSelectedEmployees,
    data,
  ]);

  return <div className={classes.container} ref={d3Container} />;
}

CompCycleOrganizationChart.fragments = {
  employee: gql`
    fragment CompCycleOrganizationChart_employee on Employee2 {
      id
      parentId: managerId
      displayName
      _directSubordinates: directReportsCount
      _totalSubordinates: totalReportsCount
      activeEmployment {
        id
        jobTitle
      }
    }
  `,
};

function isNodeSelectable(
  depth: number,
  phases: CompCyclePhaseData[],
  layerDepth: number
): boolean {
  return (
    layerDepth !== depth && !phases.some(({ layers }) => layers.includes(depth))
  );
}
