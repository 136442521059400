import { getFormattedMonthAndDay } from "@asmbl/shared/time";
import { makeStyles } from "@material-ui/core";
import { ArrowRightIcon } from "src/components/AssembleIcons/Small/ArrowRightIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { BLUE_3 } from "src/theme";
import { PhaseProgressBar_compCyclePhase as CompCyclePhase } from "src/__generated__/graphql";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  link: {
    color: BLUE_3,
    display: "flex",
    textDecoration: "none",
    alignItems: "center",
    gap: theme.spacing(1),
    alignSelf: "flex-end",
  },
}));

type Props = {
  phase: CompCyclePhase;
  currentPhaseOrder: number | undefined;
  nextPhaseStart: GraphQL_DateTime | undefined;
  myPhase: number | undefined;
};

export const PhaseTooltip = ({
  phase,
  currentPhaseOrder,
  nextPhaseStart,
  myPhase,
}: Props) => {
  const classes = useStyles();
  const getBodyText = () => {
    if (myPhase == null || currentPhaseOrder == null) return "";
    if (myPhase === currentPhaseOrder) {
      return "It's time to make your comp recommendations.";
    }
    if (myPhase < currentPhaseOrder) {
      return `Your turn to review and request changes happened in Phase ${myPhase}.`;
    }
    if (myPhase > currentPhaseOrder) {
      return `Your turn to review and request changes will happen in Phase ${myPhase}.`;
    }
  };
  return (
    <div className={classes.body} data-cy="phase-tooltip">
      <AssembleTypography variant="tooltipHeader">
        Phase {phase.phaseOrder}{" "}
        {nextPhaseStart != null
          ? `| Ends ${getFormattedMonthAndDay(nextPhaseStart)}`
          : ""}
      </AssembleTypography>
      {getBodyText()}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://help.assemble.inc/en/articles/8688656-taking-action-during-your-phase-as-a-manager"
        className={classes.link}
      >
        Learn More
        <ArrowRightIcon color={BLUE_3} />
      </a>
    </div>
  );
};
