import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const CalendarIcon = ({
  height = "12px",
  width = "12px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Calendar"
      viewBox="0 0 12 12"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6155_4336)">
        <path
          className="stroke"
          d="M10.0909 2H1.90909C1.68316 2 1.5 2.18316 1.5 2.40909V10.5909C1.5 10.8168 1.68316 11 1.90909 11H10.0909C10.3168 11 10.5 10.8168 10.5 10.5909V2.40909C10.5 2.18316 10.3168 2 10.0909 2Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="stroke"
          d="M8 0.75V1.75"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="stroke"
          d="M4 0.75V2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="stroke"
          d="M1.5 5H10.5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6155_4336">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </ColorableSVG>
);
