import { makeStyles, Typography, TypographyProps } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import { GRAY_1, GRAY_2, GRAY_5 } from "../theme";

/* We'd like to define more custom font variations, but until we upgrade to
 * Material V5, we are restricted to the defined set. As we discover more
 * variants, we can add them here. */
const ASSEMBLE_VARIANTS = {
  productButtonSmall: {
    fontSize: "0.8125rem",
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: "-0.25px",
  },
  productButtonMedium: {
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: "-0.25px",
  },
  productButtonLarge: {
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: "-0.25px",
  },
  productLongForm: {
    fontSize: "1.125rem",
    fontWeight: 400,
    lineHeight: 1.55,
    letterSpacing: "-0.25px",
  },
  productParagraphLarge: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.4,
    letterSpacing: "-0.25px",
  },
  productParagraphMedium: {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.4,
    letterSpacing: "-0.25px",
  },
  productParagraphSmall: {
    fontSize: "0.8125rem",
    fontWeight: 400,
    lineHeight: 1.1375,
    letterSpacing: "-0.25px",
  },
  productSmall: {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.55,
    letterSpacing: "-0.25px",
  },
  productSmallSemiBold: {
    fontSize: "0.875rem",
    fontWeight: 450,
    lineHeight: 1.4,
    letterSpacing: "-0.15px",
  },
  productSmallBold: {
    fontSize: "0.875rem",
    fontWeight: 600,
    lineHeight: 1.4,
    letterSpacing: "-0.25px",
  },
  productSmaller: {
    fontSize: "0.8125rem",
    letterSpacing: "-0.25px",
  },
  productSmallerBold: {
    fontSize: "0.8125rem",
    letterSpacing: "-0.25px",
    fontWeight: 500,
  },
  productTabHeader: {
    fontSize: "0.6875rem",
    fontWeight: 400,
    lineHeight: 1.4,
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  productTabSubheader: {
    fontSize: "2.25rem",
    fontWeight: 400,
    lineHeight: 1.4,
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  productExtraSmall: {
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: 1.55,
    letterSpacing: "-0.25px",
  },
  productExtraSmallSemiBold: {
    fontSize: "0.75rem",
    fontWeight: 450,
    lineHeight: 1.55,
    letterSpacing: "-0.25px",
  },
  productEyebrow: {
    fontSize: "0.75rem",
    fontWeight: 700,
    lineHeight: 1.4,
    textTransform: "uppercase",
  },
  productEyebrowMedium: {
    fontSize: "0.6875rem",
    fontWeight: 700,
    lineHeight: 1.4,
    textTransform: "uppercase",
  },
  productEyebrowSmall: {
    fontSize: "0.625rem",
    fontWeight: 700,
    lineHeight: 1.4,
    textTransform: "uppercase",
  },
  productDialogBody: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.4,
    letterSpacing: "-0.25px",
  },
  productHeader: {
    fontWeight: 500,
    size: "1rem",
    lineHeight: 1.4,
    letterSpacing: "-0.25px",
  },
  productMicrocopy: {
    fontSize: "0.75rem",
    fontWeight: 450,
    lineHeight: 1.4,
    letterSpacing: "-0.15px",
  },
  productSectionHeader: {
    fontWeight: 500,
    size: "1.125rem",
    lineHeight: 1.575,
    letterSpacing: "-0.25px",
  },
  productTableHeader: {
    fontWeight: 700,
    fontSize: "0.6875rem",
    lineHeight: 1.1,
    textTransform: "uppercase",
  },
  productTableChipSmall: {
    fontWeight: 500,
    fontSize: "0.6875rem",
    lineHeight: 1.55,
    letterSpacing: "-0.5px",
  },
  productTableChip: {
    fontWeight: 500,
    fontSize: "0.8125rem",
    lineHeight: 1.55,
    letterSpacing: "-0.5px",
  },
  productTableContent: {
    fontWeight: 400,
    size: "0.875rem",
    lineHeight: 1.4,
  },
  productTooltip: {
    fontWeight: 400,
    size: "0.8125rem",
    lineHeight: 1.1375,
    letterSpacing: "-0.5px",
  },
  productSummaryHeader: {
    fontWeight: 500,
    size: "0.875rem",
    lineHeight: 1.225,
    letterSpacing: "-0.25px",
  },
  productSummaryValue: {
    fontWeight: 700,
    size: "0.875rem",
    lineHeight: 1.225,
    letterSpacing: "-0.25px",
  },
  productPargraphLarge: {
    fontSize: "1rem",
    fontWeight: 450,
    lineHeight: 1.4,
    letterSpacing: "-0.25px",
  },
  sidebarTitle: {
    fontWeight: 500,
    size: "1rem",
    lineHeight: 1.4,
    letterSpacing: "-0.25px",
  },
  sidebarSubheader: {
    fontSize: "0.75rem",
    color: GRAY_2,
    fontWeight: 500,
    lineHeight: 1.05,
    letterSpacing: "-0.15px",
  },
  formDescription: {
    fontSize: "0.875rem",
    color: GRAY_2,
    fontWeight: 400,
    letterSpacing: "-0.25px",
  },
  formSubheader: {
    fontSize: "1.125rem",
    color: GRAY_1,
    fontWeight: 700,
    lineHeight: 1.4,
    letterSpacing: "-0.25px",
  },
  portalH35: {
    // Not currently labelled in Figma, this is somewhere between H3 and H4
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: "-1px",
  },
  smallTag: {
    fontSize: "0.625rem",
    fontWeight: 700,
    lineHeight: 1.4,
  },
  tooltipHeader: {
    color: GRAY_5,
    textTransform: "uppercase",
    fontSize: "0.625rem",
    fontWeight: "bold",
  },
} as const;

export type AssembleTypographyVariant = keyof typeof ASSEMBLE_VARIANTS;

export const useAssembleTypography = makeStyles(() => ASSEMBLE_VARIANTS);

export type AssembleTypographyProps = Omit<TypographyProps, "variant"> & {
  variant?: Variant | AssembleTypographyVariant;
  textColor?: string;
  fontWeight?: number;
};

export function AssembleTypography({
  variant,
  className = "",
  display = "block",
  children,
  textColor,
  fontWeight,
  ...rest
}: AssembleTypographyProps): JSX.Element {
  const typography = useAssembleTypography();

  const isAssembleVariant = Object.keys(typography).includes(variant ?? "");

  return (
    <Typography
      component="span"
      variant={isAssembleVariant ? "inherit" : (variant as Variant)}
      display={display}
      className={`${
        isAssembleVariant
          ? typography[variant as AssembleTypographyVariant]
          : ""
      } ${className}`}
      style={{ color: textColor, fontWeight }}
      {...rest}
    >
      {children}
    </Typography>
  );
}
