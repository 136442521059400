import { useMutation, useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { SaveButton } from "../../components/Form/SaveButton";
import Loading from "../../components/Settings/Loading";
import { StructureForm } from "../../components/Settings/StructureForm";
import { UPSERT_COMP_STRUCTURE } from "../../mutations";
import { GET_BUSINESS_STRUCTURE_SETTINGS } from "../../queries";
import {
  BusinessStructure,
  GetBusinessStructureSettings,
  UpsertCompStructure,
  UpsertCompStructureVariables,
} from "../../__generated__/graphql";
import { PageContainer } from "./PageContainer";

export const Business = (): JSX.Element => {
  const { data, loading } = useQuery<GetBusinessStructureSettings>(
    GET_BUSINESS_STRUCTURE_SETTINGS
  );

  const [compare, setCompare] = useState<BusinessStructure | undefined>();
  const [formData, setFormData] = useState<BusinessStructure | undefined>();

  useEffect(() => {
    setCompare(data?.compStructure?.businessStructure ?? undefined);
    setFormData(data?.compStructure?.businessStructure ?? undefined);
  }, [data]);

  const [updateCompStructure] = useMutation<
    UpsertCompStructure,
    UpsertCompStructureVariables
  >(UPSERT_COMP_STRUCTURE, {
    variables: {
      data: {
        businessStructure: formData,
      },
    },
    refetchQueries: [{ query: GET_BUSINESS_STRUCTURE_SETTINGS }],
  });

  const updateData = async () => {
    await updateCompStructure();
    setCompare(formData);
    return true;
  };

  if (loading || data === undefined) return <Loading />;

  return (
    <PageContainer
      header={`${data.organization.name}'s Business Structure`}
      description={`Select which type of business entity ${data.organization.name} operates under.`}
      centerContent
    >
      <>
        <StructureForm data={formData} handleChange={setFormData} />
        <Box mt={3} />
        <Box display="flex" justifyContent="flex-end">
          <SaveButton disabled={compare === formData} onSave={updateData} />
        </Box>
      </>
    </PageContainer>
  );
};
