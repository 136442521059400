import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  CashBandInput,
  CreatePosition,
  CreatePositionVariables,
  CurrencyCode,
  DeletePosition,
  DeletePositionVariables,
  EmplaceBands,
  EmplaceBandsVariables,
  EquityBandInput,
  ImportLevelingCodes,
  ImportLevelingCodesInput,
  ImportLevelingCodesVariables,
  UpdatePosition,
  UpdatePositionVariables,
} from "../__generated__/graphql";
import {
  ADJUSTED_CASH_BAND_FIELDS,
  ADJUSTED_EQUITY_BAND_FIELDS,
} from "../fragments";

type PositionInput = {
  name: string;
  description: string | null;
  level: number;
  jobCodes: string[];
};

const CREATE_POSITION = gql`
  mutation CreatePosition(
    $name: String!
    $description: String
    $level: Int!
    $ladderId: Int!
    $jobCodes: [String!]!
  ) {
    createOnePosition(
      data: {
        name: $name
        description: $description
        level: $level
        ladderId: $ladderId
        jobCodes: $jobCodes
      }
    ) {
      id
      name
      description
      level
      jobCodes
      createdAt
      updatedAt
      ladder {
        id
        name
        positions {
          id
          name
        }
      }
    }
  }
`;

export function useCreatePosition(
  ladderId: number
): (position: PositionInput) => Promise<boolean> {
  const [createPosition] = useMutation<CreatePosition, CreatePositionVariables>(
    CREATE_POSITION
  );

  return useCallback(
    async (position: PositionInput) => {
      await createPosition({
        variables: {
          ...position,
          ladderId,
        },
      });
      return true;
    },
    [createPosition, ladderId]
  );
}

const UPDATE_POSITION = gql`
  mutation UpdatePosition(
    $positionId: Int!
    $positionName: String
    $positionDescription: String
    $positionLevel: Int
    $positionJobCodes: [String!]!
  ) {
    updateOnePosition(
      id: $positionId
      data: {
        name: $positionName
        description: $positionDescription
        level: $positionLevel
        jobCodes: $positionJobCodes
      }
    ) {
      id
      name
      description
      level
      jobCodes
    }
  }
`;

export function useUpdatePosition(
  positionId: number
): (position: PositionInput) => Promise<boolean> {
  const [updatePosition] = useMutation<UpdatePosition, UpdatePositionVariables>(
    UPDATE_POSITION
  );

  return useCallback(
    async (position: PositionInput) => {
      await updatePosition({
        variables: {
          positionId,
          positionName: position.name,
          positionDescription: position.description,
          positionLevel: position.level,
          positionJobCodes: position.jobCodes,
        },
      });
      return true;
    },
    [updatePosition, positionId]
  );
}

/* Delete position actually returns the deleted position,
   with its ladder still attached. We can take advantage
   of this to update the cache. */
const DELETE_POSITION = gql`
  mutation DeletePosition($positionId: Int!) {
    deleteOnePosition(id: $positionId) {
      id
      name
      ladder {
        id
        positions {
          id
          name
        }
      }
    }
  }
`;

export function useDeletePosition(positionId: number): () => Promise<boolean> {
  const [deletePosition] = useMutation<DeletePosition, DeletePositionVariables>(
    DELETE_POSITION,
    { variables: { positionId } }
  );

  return useCallback(async () => {
    await deletePosition();
    return true;
  }, [deletePosition]);
}

/*
 * Normally, we expect mutations to fully update the client cache with any data
 * that they modify. However, it's not feasible to update every combination of
 * currency- and location-adjusted bands, which each have their own object in
 * cache. Instead, we will handle the data that the PositionDetail page needs
 * immediately after the mutation, and let queries on _other_ pages update via
 * their nextFetchPolicy behavior when we navigate back to their pages.
 *
 * For the PositionDetail page, we need to update the unadjusted bands, and the
 * adjusted bands for the currently selected currency and location.
 */
export const EMPLACE_BANDS = gql`
  ${ADJUSTED_CASH_BAND_FIELDS}
  ${ADJUSTED_EQUITY_BAND_FIELDS}
  mutation EmplaceBands(
    $cashBands: [CashBandInput!]!
    $equityBands: [EquityBandInput!]!
    $positionId: Int!
    $currencyCode: CurrencyCode!
    $marketId: Int!
    $locationGroupId: Int
  ) {
    # Multiple mutations occur sequentially, so we only care about the response
    # of the last one.
    emplaceCashBands(
      data: { bands: $cashBands, positionId: $positionId, marketId: $marketId }
    ) {
      id
    }
    emplaceEquityBands(
      data: {
        bands: $equityBands
        positionId: $positionId
        marketId: $marketId
      }
    ) {
      id
      unadjustedCashBands(marketId: $marketId) {
        id
        ...AdjustedCashBandFields
      }
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedCashBandFields
      }
      unadjustedEquityBands(marketId: $marketId) {
        id
        ...AdjustedEquityBandFields
      }
      adjustedEquityBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedEquityBandFields
      }
    }
  }
`;

export function useEmplaceBands(
  positionId: number,
  currencyCode: CurrencyCode,
  marketId: number,
  locationGroupId: number | null
): (
  cashBands: CashBandInput[],
  equityBands: EquityBandInput[]
) => Promise<unknown> {
  const [emplaceBands] = useMutation<EmplaceBands, EmplaceBandsVariables>(
    EMPLACE_BANDS
  );

  return useCallback(
    (cashBands: CashBandInput[], equityBands: EquityBandInput[]) =>
      emplaceBands({
        variables: {
          positionId,
          cashBands,
          equityBands,
          currencyCode,
          marketId,
          locationGroupId,
        },
      }),
    [emplaceBands, positionId, currencyCode, marketId, locationGroupId]
  );
}

const ImportLevelingCodesMutation = gql`
  mutation ImportLevelingCodes($data: [ImportLevelingCodesInput!]!) {
    importLevelingCodes(data: $data) {
      entryCount
      organization {
        id
        lastLevelingCodeUploadDate
        levelingCodeMappings {
          positionId
          levelingCode
        }
      }
    }
  }
`;

export function useImportLevelingCodeUpload() {
  const [levelingCodeUpload] = useMutation<
    ImportLevelingCodes,
    ImportLevelingCodesVariables
  >(ImportLevelingCodesMutation);

  const upload = useCallback(
    async (data: ImportLevelingCodesInput[]) => {
      await levelingCodeUpload({ variables: { data } });
    },
    [levelingCodeUpload]
  );

  return upload;
}
