import { Money } from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import { getSimpleCashLabel } from "../../models/Currency";
import { AssembleTypography } from "../AssembleTypography";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "10rem",
    textAlign: "center",
  },
}));

export function CompCardContent({
  label,
  value,
  description,
}: {
  label?: string;
  value: string | number | Money;
  description?: string;
}) {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      {label && (
        <AssembleTypography variant="productParagraphLarge">
          {label}
        </AssembleTypography>
      )}
      <AssembleTypography data-cy={`${label}-card-value`} variant="portalH35">
        {typeof value === "string"
          ? value
          : typeof value === "number"
            ? formatNumeral(value)
            : getSimpleCashLabel(value)}
      </AssembleTypography>
      {description && (
        <AssembleTypography
          data-cy={`${label}-card-description`}
          variant="formDescription"
        >
          {description}
        </AssembleTypography>
      )}
    </div>
  );
}
