import { gql } from "@apollo/client";
import { Currency } from "@asmbl/shared/currency";
import { moneyComparator, zero } from "@asmbl/shared/money";
import { Noun } from "@asmbl/shared/permissions";
import { caseInsensitiveComparator, contramap } from "@asmbl/shared/sort";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  LiveLocationsProvider_market as Market,
  CompareTable_position as Position,
} from "src/__generated__/graphql";
import { EmptyImportIcon } from "src/components/AssembleIcons/Brand/EmptyImportIcon";
import { AssembleLink } from "src/components/AssembleLink";
import { AssembleTypography } from "src/components/AssembleTypography";
import { useAuth } from "src/components/Auth/AuthContext";
import BandVisualization from "src/components/BandVisualization/BandVisualization";
import BandVisualization2 from "src/components/BandVisualization/BandVisualization2";
import BandVisualizationHeader from "src/components/BandVisualization/BandVisualizationHeader";
import BandVisualizationHeader2 from "src/components/BandVisualization/BandVisualizationHeader2";
import { useCurrencies } from "src/components/CurrenciesContext";
import {
  LocationSelection,
  useLocations,
} from "src/components/LocationsContext";
import {
  Order,
  SortableTableHeaderCell,
  useSort,
} from "src/components/SortableTable";
import { DV_BAND_COLORS } from "src/constants";
import {
  ADJUSTED_CASH_BAND_FIELDS,
  ADJUSTED_EQUITY_BAND_FIELDS,
} from "src/fragments";
import {
  totalCompBand,
  totalCompMax,
  totalCompMin,
  totalEquityBand,
} from "src/models/Band";
import { GRAY_3, GRAY_6, WHITE } from "src/theme";
import { Range, getTotalCompRange } from "src/utils";
import {
  ColoredFullLadder,
  GetLadders_marketDataSet,
  SelectedCompType,
} from "./SelectedLaddersLoader";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowY: "auto",
    flex: 1,
    borderRadius: "0 0 5px 5px",
    borderBottom: "none",
  },
  bandHeaderCell: {
    background: WHITE,
    borderLeft: `1px solid ${GRAY_6}`,
  },
  bandVisualizationHeaderCell: {
    top: "40px",
    padding: 0,
    height: "14px",
  },
  bandVisualizationCell: {
    backgroundColor: WHITE,
    borderLeft: `1px solid ${GRAY_6}`,
  },
  emptyStateContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    color: GRAY_3,
    gap: theme.spacing(3),
  },
  marketDataEmptyText: {
    maxWidth: 400,
  },
  tableRoot: {
    height: "100%",
  },
}));

export type CompareTableProps = {
  positions: Position[];
  selectedLadders: ColoredFullLadder[];
  selectedCurrency: Currency;
  excludedBandNames: Set<string>;
  marketDataSets: GetLadders_marketDataSet[];
  selectedCompType: SelectedCompType;
  hasMarketData: boolean;
};

export const MARKET_DATA_COMP_TYPE_MAPPING = {
  Salary: "SALARY",
  "Total Cash": "TOTAL",
  "Total Grant Value": "EQUITY",
  "Annual Grant Value": "EQUITY",
} as const;

export function CompareTable({
  positions,
  selectedLadders,
  selectedCurrency,
  excludedBandNames,
  marketDataSets,
  selectedCompType,
  hasMarketData,
}: CompareTableProps): JSX.Element {
  const classes = useStyles();
  const { markets, selectedLocation } = useLocations();
  const { permissions } = useAuth();
  const hasDataAccess = permissions.canViewGlobal(Noun.MarketData);

  const {
    sortedArray: sortedPositions,
    order,
    orderBy,
    handleRequestSort,
  } = useSort<Position>(positions, "ladder", "asc", {
    adjustedCashBands: contramap(
      (b) =>
        totalCompMax(totalCompBand(b, excludedBandNames)) ??
        zero(selectedCurrency.code),
      moneyComparator
    ),
    ladder: ladderComparator,
  });

  const marketDataCompType = MARKET_DATA_COMP_TYPE_MAPPING[selectedCompType];

  const marketDataSamples: number[] = marketDataSets
    .flatMap((marketDataSet) =>
      marketDataSet.marketDataSamples
        .filter(
          (s) =>
            positions.find((position) =>
              position.jobCodes.includes(s.jobCode)
            ) && s.compType === marketDataCompType
        )
        .flatMap((s) =>
          s.marketDataPoints.map((p) => p.value).filter((v) => v !== null)
        )
    )
    .filter((sample): sample is number => sample != null);

  const marketDataRange = {
    min: Math.min(...marketDataSamples),
    max: Math.max(...marketDataSamples),
  };

  const totalCompRange = getTotalCompRange(
    positions.map((p) => {
      const band =
        selectedCompType === "Total Grant Value"
          ? totalEquityBand(p)
          : totalCompBand(p, excludedBandNames);

      return {
        totalCompMin: totalCompMin(band) ?? zero(selectedCurrency.code),
        totalCompMax: totalCompMax(band) ?? zero(selectedCurrency.code),
      };
    })
  );

  return (
    <TableContainer
      className={classes.tableContainer}
      component={Paper}
      elevation={0}
    >
      <Table classes={{ root: classes.tableRoot }} stickyHeader>
        <TableHead>
          <CompareTableHeader
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
          />
          <TableRow>
            {getMarketLocationGroupCellContent({
              markets,
              selectedLocation,
            }) !== null && (
              <TableCell
                className={classes.bandVisualizationHeaderCell}
                colSpan={1}
              />
            )}
            <TableCell
              className={classes.bandVisualizationHeaderCell}
              colSpan={3}
            />
            <TableCell
              className={classes.bandVisualizationHeaderCell}
              colSpan={1}
            >
              {hasDataAccess ? (
                <BandVisualizationHeader2
                  totalCompRange={totalCompRange}
                  height={14}
                  selectedCurrency={selectedCurrency}
                  marketDataRange={marketDataRange}
                />
              ) : (
                <BandVisualizationHeader
                  totalCompRange={totalCompRange}
                  height={14}
                  selectedCurrency={selectedCurrency}
                />
              )}
            </TableCell>
            <TableCell
              className={classes.bandVisualizationHeaderCell}
              colSpan={1}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedPositions.length === 0 ? (
            <TableRow>
              <TableCell align="center" colSpan={8}>
                {hasDataAccess ? (
                  <div className={classes.emptyStateContainer}>
                    <EmptyImportIcon />
                    <AssembleTypography
                      className={classes.marketDataEmptyText}
                      variant="productParagraphLarge"
                    >
                      Market Data is now in Insights.
                      <br />
                      {hasMarketData
                        ? "Select a ladder from above to view market data."
                        : "Visit Data Management in Settings to add your market datasets to compare against your compensation bands."}
                    </AssembleTypography>
                    {hasMarketData ? null : (
                      <AssembleLink to="/settings/data">
                        <Button variant="outlined">Manage Data</Button>
                      </AssembleLink>
                    )}
                  </div>
                ) : (
                  <Typography variant="h5" color="textSecondary" align="center">
                    Select something from the search bar above.
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          ) : (
            sortedPositions.map((position, index) => (
              <CompareTableRow
                key={position.id}
                index={index}
                position={position}
                totalCompRange={totalCompRange}
                marketDataRange={marketDataRange}
                selectedLadders={selectedLadders}
                marketDataSets={marketDataSets}
                selectedCompType={selectedCompType}
                isBottomOfPage={
                  sortedPositions.length > 4 &&
                  sortedPositions.length - index - 1 < 3
                }
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

type CompareTableHeaderProps = {
  order: Order;
  orderBy: keyof Position;
  handleRequestSort: (property: keyof Position) => void;
};

function CompareTableHeader({
  order,
  orderBy,
  handleRequestSort,
}: CompareTableHeaderProps) {
  const classes = useStyles();
  const { selectedLocation, markets } = useLocations();

  const marketAndLocationGroupCellContent = getMarketLocationGroupCellContent({
    markets,
    selectedLocation,
  })?.header;

  return (
    <TableRow>
      {marketAndLocationGroupCellContent !== undefined && (
        <TableCell variant="head" colSpan={1}>
          <AssembleTypography variant="productTableHeader" noWrap>
            {
              getMarketLocationGroupCellContent({ markets, selectedLocation })
                ?.header
            }
          </AssembleTypography>
        </TableCell>
      )}
      <SortableTableHeaderCell
        cellTitle="Position"
        orderByField="name"
        order={order}
        isSelected={orderBy === "name"}
        handleRequestSort={handleRequestSort}
        width="25%"
        noWrap
      />
      <SortableTableHeaderCell
        cellTitle="Ladder"
        orderByField="ladder"
        order={order}
        isSelected={orderBy === "ladder"}
        handleRequestSort={handleRequestSort}
        width="15%"
        noWrap
      />
      <SortableTableHeaderCell
        cellTitle="Level"
        orderByField="level"
        order={order}
        isSelected={orderBy === "level"}
        handleRequestSort={handleRequestSort}
        width="5%"
        noWrap
      />
      <SortableTableHeaderCell
        className={classes.bandHeaderCell}
        variant="head"
        width="60%"
        cellTitle="Bands"
        orderByField="adjustedCashBands"
        order={order}
        isSelected={orderBy === "adjustedCashBands"}
        handleRequestSort={handleRequestSort}
        noWrap
      />
    </TableRow>
  );
}

type CompareTableRowProps = {
  index: number;
  position: Position;
  totalCompRange: Range;
  marketDataRange: Range;
  selectedLadders: ColoredFullLadder[];
  marketDataSets: GetLadders_marketDataSet[];
  selectedCompType: SelectedCompType;
  isBottomOfPage: boolean;
};

function CompareTableRow({
  index,
  position,
  totalCompRange,
  marketDataRange,
  selectedLadders,
  marketDataSets,
  selectedCompType,
  isBottomOfPage,
}: CompareTableRowProps) {
  const classes = useStyles();
  const { selectedLocation, markets } = useLocations();
  const { selectedCurrency } = useCurrencies();

  const getPositionGroupingColor = (position: Position) => {
    return (
      selectedLadders.find((ladder) => ladder.ladder.id === position.ladder.id)
        ?.color ?? DV_BAND_COLORS[0]
    );
  };

  const marketAndLocationGroupCellContent = getMarketLocationGroupCellContent({
    markets,
    selectedLocation,
  })?.value;

  return (
    <TableRow>
      {marketAndLocationGroupCellContent !== undefined && (
        <TableCell
          component="th"
          scope="row"
          style={{
            borderBottom: "none",
            borderRight: `1px solid ${GRAY_6}`,
          }}
        >
          {index === 0 && (
            <AssembleTypography variant="productTableContent">
              {marketAndLocationGroupCellContent}
            </AssembleTypography>
          )}
        </TableCell>
      )}

      <TableCell
        component="th"
        scope="row"
        style={
          marketAndLocationGroupCellContent !== undefined
            ? {}
            : { borderLeft: `1px solid ${getPositionGroupingColor(position)}` }
        }
      >
        <AssembleLink to={`/positions/${position.id}`} newTab display="inline">
          {position.name}
        </AssembleLink>
      </TableCell>
      <TableCell component="th" scope="row">
        <AssembleTypography variant="productTableContent">
          {position.ladder.name}
        </AssembleTypography>
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        <AssembleTypography variant="productTableContent">
          {position.level}
        </AssembleTypography>
      </TableCell>
      <TableCell
        className={classes.bandVisualizationCell}
        align="left"
        padding="none"
      >
        <BandVisualization2
          position={position}
          totalCompRange={totalCompRange}
          marketDataRange={marketDataRange}
          height={91}
          color={getPositionGroupingColor(position)}
          selectedCurrency={selectedCurrency}
          marketDataSets={marketDataSets}
          selectedCompType={selectedCompType}
          isBottomOfPage={isBottomOfPage}
        />
      </TableCell>
    </TableRow>
  );
}

function getMarketLocationGroupCellContent({
  markets,
  selectedLocation,
}: {
  markets: Market[];
  selectedLocation: LocationSelection | null;
}): {
  header: string;
  value: string;
} | null {
  const [selectedMarket, selectedLocationGroup] = selectedLocation ?? [];
  const multipleMarkets = markets.length > 1;

  const locationGroupName =
    selectedLocationGroup?.name ?? "Source Compensation";
  const marketName = selectedMarket?.name;
  const noLocationGroups = selectedMarket?.locationGroups.length === 0;

  // we should never hit the case where there is no market or no locationGroups
  if (marketName === undefined || noLocationGroups) {
    return null;
  }

  // if we have multiple markets then we will render both
  if (multipleMarkets) {
    return {
      header: "Market / Location Group",
      value: `${marketName} / ${locationGroupName}`,
    };
  }

  return {
    header: "Location Group",
    value: locationGroupName,
  };
}

CompareTable.fragments = {
  position: gql`
    ${BandVisualization.fragments.position ?? ""}
    ${BandVisualization2.fragments.position ?? ""}
    ${ADJUSTED_CASH_BAND_FIELDS}
    ${ADJUSTED_EQUITY_BAND_FIELDS}
    fragment CompareTable_position on Position {
      id
      name
      level
      ladder {
        id
        name
      }
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
      }
      adjustedEquityBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
      }
      ...BandVisualization_position
      ...BandVisualization2_position
    }
  `,
};

function ladderComparator(a: Position, b: Position) {
  if (a.ladder.id === b.ladder.id) {
    return b.level - a.level;
  }
  return caseInsensitiveComparator(a.ladder.name, b.ladder.name);
}
