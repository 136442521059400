import { ISettings } from "@flatfile/adapter";
import { DECIMAL_VALIDATOR } from "./Validators";

export const VariableCompIdentifierFields: ISettings["fields"] = [
  {
    label: "Active At",
    description:
      "The date that this compensation became active.\nFormat: YYYY-MM-DD",
    key: "activeAt",
    validators: [
      { validate: "required" },
      {
        validate: "regex_matches",
        regex: "^\\d{4}-\\d{2}-\\d{2}$",
        error: "Must be in format YYYY-MM-DD",
      },
    ],
  },
  {
    label: "Type",
    key: "variableCompType",
    description: "The type of variable comp you want to upload.",
    type: "select",
    options: ["Commission", "Recurring Bonus"].map((code) => ({
      label: code,
      value: code,
    })),
    validators: [{ validate: "required" }],
  },
  {
    label: "Unit",
    key: "compUnit",
    description: "The unit of the variable comp you want to upload.",
    type: "select",
    options: ["Percent of Salary", "Cash Value"].map((code) => ({
      label: code,
      value: code,
    })),
    validators: [{ validate: "required" }],
  },
  {
    label: "Value",
    key: "variableCompValue",
    description: "The value of the variable comp you want to upload.",
    validators: [
      { validate: "required" },
      {
        validate: "regex_matches",
        regex: DECIMAL_VALIDATOR,
        error: "Must be a number",
      },
    ],
  },
];
