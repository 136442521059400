import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import { authAPIKey, authDomain, authProjectID } from "./env";

const app = firebase.initializeApp({
  apiKey: authAPIKey(),
  authDomain: authDomain(),
  projectId: authProjectID(),
});
const auth = getAuth(app);

export const authInstance = auth;
