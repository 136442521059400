import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const LevelIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Level"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M3 19V20"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        className="stroke"
        d="M8 14V20"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        className="stroke"
        d="M14 9V20"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        className="stroke"
        d="M20 4V20"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        className="stroke"
        d="M14 8L12 10"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        className="stroke"
        d="M14 8L16 10"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </ColorableSVG>
);
