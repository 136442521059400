import { Divider, IconButton, makeStyles, Typography } from "@material-ui/core";
import { GRAY_4, PURPLE_2, WHITE } from "../theme";
import { CloseDrawerIcon } from "./AssembleIcons/Brand/CloseDrawerIcon";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: WHITE,
    padding: theme.spacing(3),
  },
  iconButton: {
    height: "24px",
  },
  divider: {
    margin: theme.spacing(0, 3),
  },
}));

type DrawerHeaderProps = {
  title: string;
  onClose: () => unknown;
};

export function DrawerHeader({
  title,
  onClose,
}: DrawerHeaderProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h6">{title}</Typography>
        <IconButton
          onClick={onClose}
          size="small"
          className={classes.iconButton}
          id="close-activity-drawer-icon"
        >
          <CloseDrawerIcon
            width={"24px"}
            height={"24px"}
            color={GRAY_4}
            hoverColor={PURPLE_2}
          />
        </IconButton>
      </div>
      <Divider className={classes.divider} />
    </>
  );
}
