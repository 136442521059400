import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const PoliciesIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Policies"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M13.4299 2.75931C13.7716 2.87267 14 3.17793 14 3.52097V8.5392C14 9.94176 13.4619 11.2806 12.5117 12.3179C12.0339 12.8402 11.4294 13.247 10.7873 13.576L8.00469 15L5.21741 13.5752C4.57456 13.2462 3.96924 12.8402 3.49062 12.3171C2.53962 11.2799 2 9.94028 2 8.53624V3.52097C2 3.17793 2.22836 2.87267 2.57013 2.75931L7.71767 1.04557C7.90068 0.984811 8.1001 0.984811 8.28233 1.04557L13.4299 2.75931Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M6 7.5431L7.30708 9L10 6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
