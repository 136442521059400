import { makeStyles } from "@material-ui/core";
import { GRAY_1, GRAY_6, PURPLE_1 } from "src/theme";

export const useTableStyles = makeStyles(() => ({
  row: {
    "&:hover .MuiTypography-body1": {
      color: PURPLE_1,
    },
    "& > td:first-child": {
      borderLeft: "solid 1px transparent",
      borderTop: "solid 1px transparent",
    },
    "& > td:last-child": {
      borderRight: "solid 1px transparent",
    },
    "& > .MuiTableCell-root": {
      borderBottomColor: `${GRAY_6} !important`,
    },
    "&:hover": {
      color: `${PURPLE_1} !important`,
      "& > .MuiTableCell-root": {
        borderBottomColor: `${PURPLE_1} !important`,
        borderTop: `solid 1px ${PURPLE_1}`,
      },
      "& > td:first-child": {
        borderLeft: `solid 1px ${PURPLE_1}`,
      },
      "& > td:last-child": {
        borderRight: `solid 1px ${PURPLE_1}`,
      },
    },
  },
  link: {
    color: GRAY_1,
    "&:hover": {
      color: PURPLE_1,
    },
  },
}));
