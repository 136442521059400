import { Box, makeStyles, Typography } from "@material-ui/core";
import { ReactNode } from "react";
import { GRAY_6, GRAY_8, WHITE } from "../../theme";

type Props = {
  title: string;
  actionBar?: JSX.Element;
  children: ReactNode;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    minHeight: "270px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: GRAY_8,
    border: `1px solid ${GRAY_6}`,
    borderBottom: "none",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    paddingLeft: theme.spacing(2),
  },
  titleText: {
    fontWeight: 500,
  },
  filterContainer: {
    display: "flex",
    backgroundColor: WHITE,
    borderTopRightRadius: "5px",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    paddingRight: theme.spacing(2),
  },
}));

export function FilterableCard({
  title,
  actionBar,
  children,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>
        <Typography className={classes.titleText}>{title}</Typography>
        <Box className={classes.filterContainer}>{actionBar}</Box>
      </Box>
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
}
