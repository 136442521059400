import { exchangeFromTo } from "@asmbl/shared/currency";
import { add, isZero, Money, multiply, ratio, zero } from "@asmbl/shared/money";
import { CompUnit, Currency } from "src/__generated__/graphql";
import { useCompStructure } from "src/components/CompStructureContext";
import { RecItemWithSubject } from "src/models/CompRecommendation";
import { getAccurateSimpleCashLabel } from "../../../models/Currency";
import { useCurrencies } from "../../CurrenciesContext";
import { BudgetDetail } from "./BudgetDetail";

type Props = {
  budgetType: string;
  compCycleBudget?: Money | null;
  valueUsed: Money;
  items: RecItemWithSubject[];
};

export function BudgetCashDetail2({
  budgetType,
  compCycleBudget,
  valueUsed,
  items,
}: Props): JSX.Element {
  const { currencies, defaultCurrency } = useCurrencies();
  const { compStructure } = useCompStructure();
  const workingHoursPerYear = compStructure?.workingHoursPerYear ?? 0;
  const compCycleCurrency =
    (compCycleBudget && currencies.get(compCycleBudget.currency)) ??
    defaultCurrency;

  const budgetSpendCurrency = currencies.get(valueUsed.currency);

  const zer0 = zero(
    items.at(0)?.recommendedCashValue?.currency ?? compCycleCurrency.code
  );

  // annualize item cash values and add them together
  const incremental: Money = items
    .filter((i) => i.recommendedCashValue != null)
    .map(
      (i) =>
        (i.unitType === CompUnit.HOURLY_CASH
          ? multiply(i.recommendedCashValue ?? zer0, workingHoursPerYear)
          : i.recommendedCashValue) ?? zer0
    )
    .reduce((prev, i) => add(prev, i), zer0);

  const itemSpendCurrency = currencies.get(incremental.currency);

  const used = add(
    exchangeFromTo(
      valueUsed,
      budgetSpendCurrency as Currency,
      compCycleCurrency
    ),
    exchangeFromTo(
      incremental,
      itemSpendCurrency as Currency,
      compCycleCurrency
    )
  );

  const allocated = compCycleBudget ?? null;

  const percentUsed =
    allocated === null || isZero(allocated)
      ? null
      : ratio(used, allocated) * 100;

  return (
    <BudgetDetail
      budgetType={budgetType}
      percentUsed={percentUsed}
      usedOfAllocated={
        allocated ? (
          <>
            {getAccurateSimpleCashLabel(used)} of{" "}
            {getAccurateSimpleCashLabel(allocated)}
          </>
        ) : (
          getAccurateSimpleCashLabel(used)
        )
      }
    />
  );
}
