import { makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { memo, ReactNode } from "react";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_4, GRAY_5, PURPLE_1, WHITE } from "../../../theme";

const useStyles = makeStyles((theme: Theme) => ({
  sidebarButton: {
    alignItems: "center",
    background: WHITE,
    border: "0.5px solid transparent",
    borderRadius: "5px",
    color: GRAY_4,
    cursor: (p: StyleProps) => (p.prevStep ? "pointer" : "default"),
    display: "flex",
    outline: "none",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    textAlign: "left",
    transition: "all 300ms",
    width: "100%",
    "&:hover": {
      border: (p: StyleProps) =>
        p.prevStep ? `0.5px solid ${GRAY_5}` : "0.5px solid transparent",
      boxShadow: (p: StyleProps) =>
        p.prevStep ? "0px 1px 4px rgba(10, 36, 64, 0.08)" : "none",
      color: (p: StyleProps) => (p.prevStep ? PURPLE_1 : GRAY_4),
    },
  },
  sidebarButtonActive: {
    color: "black",

    "&:hover": {
      border: "0.5px solid transparent",
      boxShadow: "none",
      color: "black !important",
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    columnGap: theme.spacing(1),
  },
}));

type StepProps = {
  active?: boolean;
  children: ReactNode;
  description?: string;
  icon: JSX.Element;
  onClick?: () => void;
  prevStep?: boolean;
};

type StyleProps = {
  prevStep: boolean;
};

const Step = ({
  active = false,
  children,
  icon,
  onClick,
  prevStep = false,
}: StepProps) => {
  const classes = useStyles({ prevStep });

  return (
    <button
      onClick={onClick}
      className={clsx(classes.sidebarButton, {
        [classes.sidebarButtonActive]: active,
      })}
    >
      <div className={classes.content}>
        {icon}
        <AssembleTypography variant="productSmall">
          {children}
        </AssembleTypography>
      </div>
    </button>
  );
};

export default memo(Step);
