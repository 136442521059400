import { gql } from "@apollo/client";
import { formatNumeral } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import { CompCard } from "src/components/Cards/CompCard";
import { CompCardContent } from "src/components/Cards/CompCardContent";
import { MultiCard } from "src/components/Cards/MultiCard";
import { CostToMove_costToMoveAnalysis as CostToMoveAnalysis } from "src/__generated__/graphql";
import { CostToMoveDepartmentsTable } from "./CostToMoveDepartmentsTable";
import { CostToMoveEmployeesTable } from "./CostToMoveEmployeesTable";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    padding: theme.spacing(2),
  },
  topLine: {
    display: "flex",
    justifyContent: "stretch",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    marginBlock: theme.spacing(2),
  },
  topLineCard: {
    flex: "1 0",
  },
  downloadContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  filterAndSelector: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

type Props = {
  data: CostToMoveAnalysis;
  filter: { [key: string]: number[] | null | undefined };
  targetBandPoint: string;
};

export function CostToMove({
  data,
  filter,
  targetBandPoint,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <div className={classes.topLine}>
        <CompCard
          className={classes.topLineCard}
          label="Annual Headcount Spend"
          value={data.totalSpend}
          description="Base Salary"
        />
        <CompCard
          className={classes.topLineCard}
          label="Average Compa-Ratio"
          value={`${
            data.averageTotalComparatio === null
              ? "N/A"
              : `${Math.round(data.averageTotalComparatio * 100)}%`
          }`}
          description="all active, leveled employees"
        />
        <MultiCard className={classes.topLineCard}>
          <CompCardContent
            label="Population Below Bandpoint"
            value={`${data.affectedEmployeeCount} (${formatNumeral(
              data.affectedEmployeeCount / data.totalEmployeeCount,
              {
                style: "percent",
                minimumSignificantDigits: 2,
                maximumSignificantDigits: 3,
              }
            )})`}
            description={`out of ${data.totalEmployeeCount} employees`}
          />
          <CompCardContent
            label="Cost to Move"
            value={data.costToMove}
            description="to bandpoint"
          />
          <CompCardContent
            label="Average Compa-Ratio"
            value={`${
              data.averageAffectedComparatio === null
                ? "N/A"
                : `${Math.round(data.averageAffectedComparatio * 100)}%`
            }`}
            description="employees below bandpoint"
          />
        </MultiCard>
      </div>

      {data.affectedEmployeeCount > 0 && data.byDepartment.length > 0 && (
        <>
          <CostToMoveDepartmentsTable byDepartment={data.byDepartment} />
          <br />
        </>
      )}
      <CostToMoveEmployeesTable
        employeeCTMs={data.employees}
        filter={filter}
        targetBandPoint={targetBandPoint}
      />
    </>
  );
}

CostToMove.fragments = {
  costToMoveAnalysis: gql`
    ${CostToMoveEmployeesTable.fragments.employeeCostToMove}
    ${CostToMoveDepartmentsTable.fragments.departmentCostToMove}
    fragment CostToMove_costToMoveAnalysis on CostToMoveAnalysis {
      totalSpend
      costToMove
      averageTotalComparatio
      averageAffectedComparatio
      affectedEmployeeCount
      totalEmployeeCount
      employees {
        ...CostToMoveEmployeesTable_employeeCostToMove
      }
      byDepartment {
        ...CostToMoveDepartmentsTable_departmentCostToMove
      }
    }
  `,
};
