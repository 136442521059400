import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const PerformanceIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Performance"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M8.10164 10.0016L9.59808 10.9587C9.78514 11.0863 10.0345 10.8949 9.97219 10.7035L9.53573 8.9808C9.53573 8.85319 9.53573 8.78939 9.59808 8.72559L10.9075 7.57714C11.0945 7.44953 10.9698 7.13052 10.7828 7.13052L9.03692 7.00291C8.91221 7.00291 8.84986 6.93911 8.84986 6.81151L8.22635 5.15263C8.16399 5.02503 8.03929 4.96123 7.91459 5.02503C7.85224 5.02503 7.78988 5.08883 7.78988 5.15263L7.16637 6.81151C7.10402 6.87531 7.04166 6.93911 6.97931 7.00291L5.23347 7.13052C4.98406 7.13052 4.92171 7.44953 5.10876 7.57714L6.3558 8.72559C6.41815 8.78939 6.4805 8.917 6.41815 8.9808L6.04404 10.5759C5.98169 10.8311 6.23109 11.0863 6.4805 10.8949L7.85224 10.0016C7.97694 9.93784 8.03929 9.93784 8.10164 10.0016Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        className="stroke"
        x="1.75"
        y="1.75"
        width="12.5"
        height="12.5"
        rx="1.25"
        strokeWidth="1.5"
      />
    </svg>
  </ColorableSVG>
);
