import { gql } from "@apollo/client";
import { CashBandName } from "@asmbl/shared/constants";
import { formatCurrency } from "@asmbl/shared/money";
import { formatNumeral, mapMaybe } from "@asmbl/shared/utils";
import { cx } from "@emotion/css";
import { Divider, makeStyles, Typography } from "@material-ui/core";
import {
  AlternateForms_cashBandPoint as CashBandPoint,
  AlternateForms_equityBandPoint as EquityBandPoint,
  AlternateForms_position as Position,
  PositionType,
} from "../../__generated__/graphql";
import { isBandPointDefined } from "../../models/BandPoint";
import { currencySymbol, getSimpleCashLabel } from "../../models/Currency";
import { BLUE_1 } from "../../theme";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "right",
  },
  alternatesRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignContent: "flex-end",
    alignItems: "right",
  },
  disabledText: {
    color: BLUE_1,
    opacity: 0.2,
  },
  cashAndUnitsText: {
    whiteSpace: "nowrap",
  },
}));

type Props = {
  position: Position;
  bandPoint: EquityBandPoint | CashBandPoint;
  disabled?: boolean;
};

export function AlternateForms({
  position,
  bandPoint,
  disabled = false,
}: Props): JSX.Element | null {
  const classes = useStyles();
  if (bandPoint.__typename === "AdjustedCashBandPoint") {
    // if we are dealing with cash band points then we only
    // annualize hourly salary band points
    if (
      position.type === PositionType.HOURLY &&
      bandPoint.bandName === CashBandName.SALARY
    ) {
      return (
        <div
          className={cx(
            classes.root,
            disabled ? classes.disabledText : undefined
          )}
        >
          {bandPoint.value.annualRate !== null && (
            <div className={classes.alternatesRow}>
              {formatCurrency(bandPoint.value.annualRate)}
              {" / year"}
            </div>
          )}
        </div>
      );
    }

    return null;
  }

  const isDefined = isBandPointDefined(bandPoint);

  const annual = isDefined
    ? `${getSimpleCashLabel(bandPoint.annualCashEquivalent)} / year`
    : `${currencySymbol(bandPoint.annualCashEquivalent.currency)} - / year`;
  const total = isDefined
    ? `${getSimpleCashLabel(bandPoint.totalGrossValue)} total`
    : `${currencySymbol(bandPoint.totalGrossValue.currency)} - total`;

  const units =
    bandPoint.totalUnits === null || !isDefined
      ? null
      : `${formatNumeral(bandPoint.totalUnits, {
          maximumFractionDigits: 0,
        })} total units`;
  const percent =
    bandPoint.totalPercentOwnership === null ||
    !isDefined ||
    (bandPoint.totalPercentOwnership.decimalValue ?? 0) < 0.001
      ? null
      : formatNumeral(bandPoint.totalPercentOwnership.decimalValue ?? 0, {
          style: "percent",
          maximumFractionDigits: 3,
        });

  const vest = `Vesting period: ${formatNumeral(
    bandPoint.vestingMonths
  )} months`;

  const alternates: string[] = mapMaybe(
    (() => {
      switch (bandPoint.value.__typename) {
        case "CashValue":
          return [annual, units, percent];
        case "UnitValue":
          return [annual, total, percent];
        case "PercentValue":
          return [annual, total, units];
      }
    })(),
    (x) => x
  );

  return (
    <div
      className={cx(classes.root, disabled ? classes.disabledText : undefined)}
    >
      <div className={classes.alternatesRow}>
        {alternates.flatMap((alt, index) => [
          <div key={alt} className={classes.cashAndUnitsText}>
            <Typography variant="body2">{alt}</Typography>
          </div>,
          index !== alternates.length - 1 ? (
            <Divider
              key={alt + "-divider"}
              orientation="vertical"
              flexItem
              variant="middle"
            />
          ) : null,
        ])}
      </div>
      <div>{vest}</div>
    </div>
  );
}

AlternateForms.fragments = {
  position: gql`
    fragment AlternateForms_position on Position {
      id
      type
    }
  `,
  cashBandPoint: gql`
    fragment AlternateForms_cashBandPoint on AdjustedCashBandPoint {
      __typename
      id
      name
      bandName
      value {
        __typename
        ... on CashValue {
          currencyCode
          annualRate
        }
      }
    }
  `,
  equityBandPoint: gql`
    fragment AlternateForms_equityBandPoint on AdjustedEquityBandPoint {
      id
      name
      bandName
      value {
        __typename
        ... on CashValue {
          currencyCode
          annualRate
        }
        ... on UnitValue {
          unitValue
        }
        ... on PercentValue {
          decimalValue
        }
      }
      annualCashEquivalent
      totalGrossValue
      totalUnits
      totalPercentOwnership {
        decimalValue
      }
      vestingMonths
    }
  `,
};
