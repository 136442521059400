import { gql } from "@apollo/client";
import { partition } from "@asmbl/shared/utils";
import { makeStyles, Typography } from "@material-ui/core";
import { ChevronRightIcon } from "src/components/AssembleIcons/Brand/ChevronRightIcon";
import { CompRecommendationStatus } from "../../../__generated__/graphql";
import { UserAvatar } from "../../../components/UserAvatar";
import { GRAY_4, GRAY_6, GRAY_7, PURPLE_1, WHITE } from "../../../theme";
import { ApproverCardDetail } from "./ApproverCardDetail";
import { CompCycleReviewerWithStatus } from "./CompCycleReviewPhases";
import { LateTag } from "./LateTag";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: "88px",
    background: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px",
    padding: theme.spacing(3),
    position: "relative",

    "&:hover .MuiTypography-root, &:active .MuiTypography-root": {
      color: PURPLE_1,
    },
  },

  closedCycleRoot: {
    display: "flex",
    alignItems: "center",
    height: "88px",
    background: GRAY_7,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px",
    padding: theme.spacing(3),
    position: "relative",
  },
  managerAvatar: {
    height: "48px",
    width: "48px",
  },
  managerPosition: {
    fontWeight: 400,
  },
  detailsContainer: {
    display: "flex",
    height: "100%",
    marginRight: theme.spacing(3),
  },
  verticalDivider: {
    width: "1px",
    height: theme.spacing(6),
    background: GRAY_6,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
  },
  warningIcon: {
    display: "flex",
    margin: theme.spacing(2),
  },
  card: {
    position: "relative",
    overflow: "hidden",
  },
  spacing: {
    margin: theme.spacing(1),
  },
  name: {
    flex: 1,
  },
  linearProgress: {
    height: "0.25rem",
  },
  lateTag: {
    position: "absolute",
    top: theme.spacing(0.5),
    left: theme.spacing(1),
  },
}));

type Props = {
  approver: CompCycleReviewerWithStatus;
  isActiveCompCycle: boolean;
  isPreviousPhase?: boolean;
};

export function PhaseApproverCard({
  approver,
  isActiveCompCycle,
  isPreviousPhase = false,
}: Props): JSX.Element {
  const classes = useStyles();

  const [directReports, indirectReports] = partition(
    approver.assignees,
    (assignee) => assignee.subject.managerId === approver.id
  );

  const recs = directReports.filter(
    (assignee) =>
      (assignee.compRecommendation !== null &&
        assignee.compRecommendation.latestSubmittedItems.length > 0) ||
      assignee.compRecommendation?.reviewStatus ===
        CompRecommendationStatus.REVIEWED
  );
  const recsWithReviews = indirectReports.filter(
    (assignee) =>
      // employee comp rec is not awaiting review, or
      assignee.compRecommendation !== null &&
      // manager authored the latest submitted items & review (i.e. edited rec)
      ((assignee.compRecommendation.latestSubmittedItems.at(0) !== undefined &&
        assignee.compRecommendation.latestSubmittedItems.at(0)?.author.id ===
          approver.user?.id &&
        assignee.compRecommendation.latestSubmittedReviews.at(0)?.authorId ===
          approver.user?.id) ||
        assignee.compRecommendation.reviewStatus ===
          CompRecommendationStatus.REVIEWED)
  );

  const isAwaitingReviews =
    approver.status === CompRecommendationStatus.NEEDS_REVIEW;

  return (
    <div className={classes.card}>
      <div
        className={isActiveCompCycle ? classes.root : classes.closedCycleRoot}
      >
        {isPreviousPhase &&
        (recs.length < directReports.length ||
          recsWithReviews.length < indirectReports.length) ? (
          <span className={classes.lateTag}>
            <LateTag />
          </span>
        ) : null}
        <UserAvatar
          photoURL={approver.user?.photoURL}
          displayName={approver.displayName}
          grayscale={!isActiveCompCycle}
          size="mediumSmall"
        />

        <div className={classes.spacing} />
        <div className={classes.name}>
          <Typography
            variant="h6"
            color={
              isActiveCompCycle && isAwaitingReviews
                ? "textPrimary"
                : "textSecondary"
            }
          >
            {approver.displayName}
          </Typography>
          <Typography
            className={classes.managerPosition}
            variant="h6"
            color="textSecondary"
          >
            {approver.activeEmployment?.jobTitle ?? ""}
          </Typography>
        </div>
        <ApproverCardDetail
          label="Requested"
          recsTotal={recs.length}
          reportsTotal={directReports.length}
          isValueTextColorPrimary={isAwaitingReviews}
          tooltipBody="This is the # of compensation requests this person needs to make for the people who roll up to them."
        />
        <ApproverCardDetail
          label="Reviewed"
          recsTotal={recsWithReviews.length}
          reportsTotal={indirectReports.length}
          isValueTextColorPrimary={isAwaitingReviews}
          tooltipBody="These are the compensation requests of the indirect reports that have been submitted to this person to review."
        />
        <div className={classes.verticalDivider} />
        <ChevronRightIcon color={GRAY_4} height="24px" width="24px" />
      </div>
    </div>
  );
}

PhaseApproverCard.fragments = {
  reviewer: gql`
    fragment PhaseApproverCard_employee on Employee2 {
      id
      displayName
      activeEmployment {
        id
        jobTitle
      }
      location {
        id
        name
      }
      user {
        id
        photoURL
      }
    }
  `,
};
