import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const BandPointIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Band Point"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M14 4.5H2C1.72386 4.5 1.5 4.72386 1.5 5V11C1.5 11.2761 1.72386 11.5 2 11.5H14C14.2761 11.5 14.5 11.2761 14.5 11V5C14.5 4.72386 14.2761 4.5 14 4.5Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M6 2.66663V13.3333"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </ColorableSVG>
);
