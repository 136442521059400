import { createContext, useContext, useState } from "react";
import { noop } from "src/test-helpers";

export type GuidancePopulation = {
  name: string;
  selected: boolean;
  eligibilityRules: string[];
  guidances: string[];
  budgets: string[];
};

export const MultipleMeritMatricesContext = createContext<{
  totalEligible: number;
  selectedMatrix: GuidancePopulation | null;
  setSelectedMatrix: (index: number) => void;
  addRule: (rule: string) => void;
  removeRule: (index: number) => void;
  addGuidance: (guidance: string) => void;
  removeGuidance: (index: number) => void;
  addMeritBudget: (budget: string) => void;
  removeMeritBudget: (index: number) => void;
  createMatrix: () => void;
  meritMatrices: GuidancePopulation[];
}>({
  totalEligible: 0,
  selectedMatrix: null,
  addRule: noop,
  removeRule: noop,
  addGuidance: noop,
  removeGuidance: noop,
  addMeritBudget: noop,
  removeMeritBudget: noop,
  setSelectedMatrix: noop,
  createMatrix: noop,
  meritMatrices: [],
});

export function useMultipleMeritMatrices() {
  return useContext(MultipleMeritMatricesContext);
}

export function MultipleMeritMatricesProvider({
  children,
  initValue,
}: {
  children: React.ReactNode;
  initValue?: { meritMatrices?: GuidancePopulation[]; totalEligible?: number };
}): JSX.Element {
  const [meritMatrices, setMeritMatrices] = useState<GuidancePopulation[]>(
    initValue?.meritMatrices ?? [
      {
        name: "Guidance Population 1",
        selected: true,
        eligibilityRules: [],
        budgets: [],
        guidances: [],
      },
    ]
  );

  const selectedMatrix =
    meritMatrices.find((matrix) => matrix.selected) ?? null;

  const setSelectedMatrix = (index: number) => {
    setMeritMatrices((prev) => {
      const newMatrices = [
        ...prev.map((matrix) => ({ ...matrix, selected: false })),
      ];
      newMatrices[index] = { ...newMatrices[index], selected: true };
      return newMatrices;
    });
  };

  const createMatrix = () => {
    setMeritMatrices((prev) => [
      ...prev.map((matrix) => ({ ...matrix, selected: false })),
      {
        name: `Guidance Population ${prev.length + 1}`,
        selected: true,
        eligibilityRules: [],
        budgets: [],
        guidances: [],
      },
    ]);
  };

  const addRule = (rule: string) => {
    setMeritMatrices((prev) => {
      if (selectedMatrix == null) return prev;

      const newMatrix = {
        ...selectedMatrix,
        eligibilityRules: [...selectedMatrix.eligibilityRules, rule],
      };

      return prev.map((matrix) =>
        matrix === selectedMatrix ? newMatrix : matrix
      );
    });
  };

  const removeRule = (index: number) => {
    setMeritMatrices((prev) => {
      if (selectedMatrix == null) return prev;

      const newMatrix = {
        ...selectedMatrix,
        eligibilityRules: selectedMatrix.eligibilityRules.filter(
          (_, i) => i !== index
        ),
      };

      return prev.map((matrix) =>
        matrix === selectedMatrix ? newMatrix : matrix
      );
    });
  };

  const addGuidance = (guidance: string) => {
    setMeritMatrices((prev) => {
      if (selectedMatrix == null) return prev;

      const newMatrix = {
        ...selectedMatrix,
        guidances: [...selectedMatrix.guidances, guidance],
      };

      return prev.map((matrix) =>
        matrix === selectedMatrix ? newMatrix : matrix
      );
    });
  };

  const removeGuidance = (index: number) => {
    setMeritMatrices((prev) => {
      if (selectedMatrix == null) return prev;

      const newMatrix = {
        ...selectedMatrix,
        guidances: selectedMatrix.guidances.filter((_, i) => i !== index),
      };

      return prev.map((matrix) =>
        matrix === selectedMatrix ? newMatrix : matrix
      );
    });
  };

  const addMeritBudget = (budget: string) => {
    setMeritMatrices((prev) => {
      if (selectedMatrix == null) return prev;

      const newMatrix = {
        ...selectedMatrix,
        budgets: [...selectedMatrix.budgets, budget],
      };

      return prev.map((matrix) =>
        matrix === selectedMatrix ? newMatrix : matrix
      );
    });
  };

  const removeMeritBudget = (index: number) => {
    setMeritMatrices((prev) => {
      if (selectedMatrix == null) return prev;

      const newMatrix = {
        ...selectedMatrix,
        budgets: selectedMatrix.budgets.filter((_, i) => i !== index),
      };

      return prev.map((matrix) =>
        matrix === selectedMatrix ? newMatrix : matrix
      );
    });
  };

  return (
    <MultipleMeritMatricesContext.Provider
      value={{
        selectedMatrix,
        addRule,
        removeRule,
        addGuidance,
        removeGuidance,
        addMeritBudget,
        removeMeritBudget,
        setSelectedMatrix,
        createMatrix,
        meritMatrices,
        totalEligible: initValue?.totalEligible ?? 0,
      }}
    >
      {children}
    </MultipleMeritMatricesContext.Provider>
  );
}
