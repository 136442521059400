import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const BenefitsIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Benefits"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M15.1875 5.625H2.8125C2.50184 5.625 2.25 5.87684 2.25 6.1875V8.4375C2.25 8.74816 2.50184 9 2.8125 9H15.1875C15.4982 9 15.75 8.74816 15.75 8.4375V6.1875C15.75 5.87684 15.4982 5.625 15.1875 5.625Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M14.625 9V14.0625C14.625 14.2117 14.5657 14.3548 14.4602 14.4602C14.3548 14.5657 14.2117 14.625 14.0625 14.625H3.9375C3.78832 14.625 3.64524 14.5657 3.53975 14.4602C3.43426 14.3548 3.375 14.2117 3.375 14.0625V9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M9 5.625V14.625"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M12.1818 4.8295C11.3863 5.625 8.99978 5.625 8.99978 5.625C8.99978 5.625 8.99978 3.23851 9.79527 2.44302C10.1118 2.12685 10.5409 1.94931 10.9883 1.94944C11.4357 1.94957 11.8647 2.12735 12.1811 2.4437C12.4974 2.76005 12.6752 3.18907 12.6753 3.63646C12.6755 4.08385 12.4979 4.51297 12.1818 4.8295V4.8295ZM5.8178 4.8295C6.61329 5.625 8.99978 5.625 8.99978 5.625C8.99978 5.625 8.99978 3.23851 8.20428 2.44302C7.88775 2.12685 7.45863 1.94931 7.01124 1.94944C6.56385 1.94957 6.13483 2.12735 5.81848 2.4437C5.50213 2.76005 5.32435 3.18907 5.32422 3.63646C5.32409 4.08385 5.50163 4.51297 5.8178 4.8295V4.8295Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
