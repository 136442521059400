import { AssembleTypography } from "src/components/AssembleTypography";
import { MONOSPACED_FONT } from "../../../../theme";
import { StyledBlockButton } from "../StyledBlockButton/StyledBlockButton";

const CODE = "code-block";

export type CodeBlockProps = {
  editorState: Draft.EditorState;
  onClick: () => unknown;
};

export const CodeBlock = (props: CodeBlockProps): JSX.Element => {
  const { editorState, onClick } = props;

  const startKey = editorState.getSelection().getStartKey();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(startKey)
    .getType();

  const active = blockType === CODE;

  return (
    <StyledBlockButton active={active} onClick={onClick}>
      <AssembleTypography
        style={{
          fontSize: "18px",
          fontWeight: "lighter",
          fontFamily: MONOSPACED_FONT,
        }}
      >
        Code
      </AssembleTypography>
    </StyledBlockButton>
  );
};
