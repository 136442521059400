import { gql } from "@apollo/client";
import { useCallback, useState } from "react";
import { useUpsertOffer } from "../../mutations/Offer";
import {
  OfferEditCompStructure as CompStructure,
  OfferEditValuation as Valuation,
} from "../../__generated__/graphql";
import { OfferGeneration } from "./OfferGeneration";
import { ChangeHandler, OfferData } from "./types";

type Props = {
  offerData: OfferData;
  valuation: Valuation | null;
  compStructure: CompStructure | null;
};

export function OfferEdit({
  offerData: initialState,
  valuation,
  compStructure,
}: Props): JSX.Element {
  const [data, setData] = useState<OfferData>(initialState);
  const [offerSubmitted, setOfferSubmitted] = useState(false);

  const onChange: ChangeHandler = useCallback((id, value) => {
    setData((prevData) => ({ ...prevData, [id]: value }));
  }, []);

  /**
   * This will maintain the state of Offer Approval from when the offer was
   * created. In other words, if this offer was created, and then the
   * organization enabled offer approvals, you still won't see any Approval
   * step when you edit this offer.
   *
   * Realistically this shouldn't be a problem. Only one organization currently
   * uses Offer Approvals.
   */
  const requireApproval = [
    data.approverName,
    data.decisionMakerName,
    data.managerName,
  ].some((x) => x !== undefined);

  const upsertOffer = useUpsertOffer(compStructure, valuation);

  const onSubmit = useCallback(async () => {
    const offerId = await upsertOffer(data);
    setOfferSubmitted(true);
    return offerId;
  }, [upsertOffer, data]);

  return (
    <OfferGeneration
      data={data}
      onChange={onChange}
      onSubmit={onSubmit}
      initialStep={1}
      requireApproval={requireApproval}
      // If you haven't submitted the offer yet, we'll count it as an unsaved
      // change.
      hasUnsavedChanges={!offerSubmitted}
    />
  );
}

OfferEdit.fragments = {
  offerConfig: gql`
    fragment OfferEditOfferConfig on OfferConfig {
      defaultWelcomeMessage
      defaultClosingMessage
      requireApproval
      customFields {
        name
        description
        variant
        options
      }
    }
  `,
  valuation: gql`
    fragment OfferEditValuation on Valuation {
      fdso
      valuation
    }
  `,
  compStructure: gql`
    fragment OfferEditCompStructure on CompStructure {
      id
      cashBandTypes
      equityBandTypes
      equityGrantMethod
    }
  `,
};
