import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const FlagIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Flag"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill"
        d="M5 21C4.71667 21 4.479 20.904 4.287 20.712C4.09567 20.5207 4 20.2833 4 20V4C4 3.71667 4.09567 3.479 4.287 3.287C4.479 3.09567 4.71667 3 5 3H12.175C12.4083 3 12.6167 3.075 12.8 3.225C12.9833 3.375 13.1 3.56667 13.15 3.8L13.4 5H19C19.2833 5 19.5207 5.09567 19.712 5.287C19.904 5.479 20 5.71667 20 6V14C20 14.2833 19.904 14.5207 19.712 14.712C19.5207 14.904 19.2833 15 19 15H12.825C12.5917 15 12.3833 14.925 12.2 14.775C12.0167 14.625 11.9 14.4333 11.85 14.2L11.6 13H6V20C6 20.2833 5.90433 20.5207 5.713 20.712C5.521 20.904 5.28333 21 5 21ZM13.65 13H18V7H11.75L11.35 5H6V11H13.25L13.65 13Z"
      />
    </svg>
  </ColorableSVG>
);
