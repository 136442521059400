import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { formatCurrency, Money, ratio } from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";

export function getPercentageChange(
  newValue: Money,
  currentValue: Money
): string {
  return formatNumeral(ratio(newValue, currentValue), {
    maximumFractionDigits: 2,
    style: "percent",
  });
}

export function calculateUnitCount(
  defaultCurrency: Currency,
  pricePerShare: Money,
  cashValue: Money,
  currency: Currency
): number {
  const absoluteCash = exchangeFromTo(cashValue, currency, defaultCurrency);

  return pricePerShare.value !== 0
    ? Math.round((absoluteCash.value / pricePerShare.value) * 100) / 100
    : 0;
}

export function exchangeRate(
  fromCurrency: Currency,
  toCurrency: Currency
): string {
  const fromBase: Money = {
    value: 1,
    currency: fromCurrency.code,
  };

  const fromFormatted = formatCurrency(fromBase, {
    currencyDisplay: "code",
    minimumFractionDigits: 0,
  });
  const toFormatted = formatCurrency(
    exchangeFromTo(fromBase, fromCurrency, toCurrency),
    { currencyDisplay: "code", minimumFractionDigits: 0 }
  );

  return `${fromFormatted} = ${toFormatted}`;
}
