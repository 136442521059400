import { makeStyles, TextField } from "@material-ui/core";
import { useState } from "react";
import { GRAY_6, GRAY_8 } from "../../theme";
import { AssembleButton } from "../AssembleButton/AssembleButton";

type Props = {
  note: string | null;
  buttonTitle: string;
  onSave: (newNote: string) => unknown;
  collapsedPlaceHolderText?: string;
  expandedPlaceHolderText?: string;
};

const useStyles = makeStyles((theme) => ({
  textFieldContainer: {
    backgroundColor: GRAY_8,
    boxShadow: `0px -1px 0px ${GRAY_6}, 0px -1px 4px rgba(10, 36, 64, 0.1)`,
    borderRadius: theme.spacing(0, 0, 1.25, 1.25),
    padding: theme.spacing(1.25, 1.25, 0, 1.25),
  },
  textFieldInputRoot: {
    display: "flex",
    flexDirection: "column",
  },
  textFieldMultiline: {
    padding: "10px",
  },
  textFieldInputBox: {
    paddingTop: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export function CompNoteModalTextfield({
  note,
  buttonTitle,
  collapsedPlaceHolderText = "Provide any reasoning you think will help give others context or insight now or in the future...",
  expandedPlaceHolderText = `i.e. "Margaret's merit adjustment falls outside the range of our recommended percentage." Or, "Our company policy prohibits skipping a level in a promotion cycle unless a recommendation from the department head was given."`,
  onSave,
}: Props): JSX.Element {
  const classes = useStyles();
  const [newNote, setNewNote] = useState(note ?? "");
  const [numberOfRows, setNumberOfRows] = useState(1);
  const handleClick = () => {
    onSave(newNote);
  };

  const placeholderText =
    numberOfRows === 1 ? collapsedPlaceHolderText : expandedPlaceHolderText;

  return (
    <div className={classes.textFieldContainer}>
      <TextField
        name="description"
        variant="outlined"
        fullWidth
        autoFocus
        inputProps={{ maxLength: 400 }}
        label={`${newNote.length}/400`}
        multiline
        placeholder={placeholderText}
        onFocus={() => setNumberOfRows(5)}
        minRows={numberOfRows}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          classes: {
            root: classes.textFieldInputRoot,
            multiline: classes.textFieldMultiline,
          },
        }}
        defaultValue={note}
        onChange={(e) => setNewNote(e.target.value.toString())}
      />
      {numberOfRows === 1 ? (
        <></>
      ) : (
        <div className={classes.textFieldInputBox}>
          <AssembleButton
            variant="contained"
            label={buttonTitle}
            onClick={handleClick}
            size="medium"
          />
        </div>
      )}
    </div>
  );
}
