import { Box, TextField } from "@material-ui/core";
import { memo, useState } from "react";
import { AssembleButton } from "../../AssembleButton/AssembleButton";
import Section from "../../Workflow/Section";
import { useStyles } from "../style";
import { ChangeHandler, OfferData } from "../types";
import { CandidateHeaderDescription } from "./CandidateHeaderDescription";
import { CandidateInformation } from "./CandidateInformation";
import { CandidateInfoSidebar } from "./CandidateInfoSidebar";
import { AtsCandidate } from "./CandidateLoadingBoundary";

export type Props = {
  currentStep: number;
  data: OfferData;
  candidates: AtsCandidate[] | undefined;
  handleChange: ChangeHandler;
  onNext: () => unknown;
  onBack: () => unknown;
  positionName?: string;
  hasAtsIntegration: boolean | undefined;
  hasIllustrativeOutcomes: boolean | undefined;
};

const CandidateDetails = ({
  currentStep,
  data,
  candidates,
  handleChange,
  onNext,
  onBack,
  positionName,
  hasAtsIntegration,
  hasIllustrativeOutcomes,
}: Props) => {
  const classes = useStyles();
  const [manualEntry, setManualEntry] = useState<boolean>(
    hasAtsIntegration === undefined ? true : !hasAtsIntegration
  );

  // -- Email validation check -----
  const isValidEmail = data.candidateEmail?.match(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );

  const formIncomplete =
    data.firstName === undefined ||
    data.lastName === undefined ||
    data.candidateEmail === undefined ||
    data.welcomeMessage === undefined ||
    data.closingMessage === undefined ||
    !isValidEmail;

  return (
    <Box className={classes.content}>
      <Section
        id="Section-Candidate"
        active={currentStep === 2}
        header="Tell us about your candidate and the offer."
        description={
          <CandidateHeaderDescription
            positionName={positionName}
            marketName={data.market?.name}
            locationGroupName={data.locationGroup?.name}
            manualEntry={manualEntry}
            setManualEntry={setManualEntry}
            hasAtsIntegration={hasAtsIntegration}
          />
        }
      >
        <CandidateInformation
          data={data}
          candidates={candidates}
          handleChange={handleChange}
          defaultPositionName={positionName}
          manualEntry={manualEntry}
          setManualEntry={setManualEntry}
        />
        <Box className={classes.fullWidth} mb={4}>
          <TextField
            fullWidth
            id="message"
            label="Welcome Message"
            inputProps={{ maxLength: 1000 }}
            multiline
            onChange={(e) => handleChange("welcomeMessage", e.target.value)}
            minRows={6}
            variant="outlined"
            value={data.welcomeMessage ?? ""}
          />
        </Box>
        <Box className={classes.fullWidth} mb={4}>
          <TextField
            fullWidth
            id="closingMessage"
            label="Closing Message"
            inputProps={{ maxLength: 1000 }}
            multiline
            onChange={(e) => handleChange("closingMessage", e.target.value)}
            minRows={6}
            variant="outlined"
            value={data.closingMessage ?? ""}
          />
        </Box>
        {hasIllustrativeOutcomes === true && (
          <Box className={classes.fullWidth} mb={4}>
            <TextField
              fullWidth
              id="outcomeDescription"
              label="Illustrative Outcomes Description (Optional)"
              inputProps={{ maxLength: 1000 }}
              multiline
              onChange={(e) =>
                handleChange("outcomeDescription", e.target.value)
              }
              minRows={6}
              variant="outlined"
              value={data.outcomeDescription ?? ""}
            />
          </Box>
        )}
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <AssembleButton
            onClick={onBack}
            variant="outlined"
            label="Back"
            size="medium"
          />
          <AssembleButton
            disabled={formIncomplete}
            onClick={onNext}
            variant="contained"
            label="Next"
            size="medium"
          />
        </Box>
      </Section>
      {!manualEntry && (
        <CandidateInfoSidebar data={data} setManualEntry={setManualEntry} />
      )}
    </Box>
  );
};

export default memo(CandidateDetails);
