import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  LevelEmployees,
  LevelEmployeesInput,
  LevelEmployeesVariables,
  UpdateEmployment,
  UpdateEmploymentVariables,
} from "../__generated__/graphql";
import { UPDATE_EMPLOYMENT } from "src/mutations";

const BULK_LEVEL_EMPLOYEES = gql`
  mutation LevelEmployees($data: [LevelEmployeesInput!]!) {
    levelEmployees(data: $data) {
      id
      employeeId
      positionId

      employee {
        id
      }

      position {
        id
      }
    }
  }
`;

export function useLevelEmployees(): (
  levelEmployeesInput: LevelEmployeesInput[]
) => Promise<void> {
  const [bulkLevelChanges] = useMutation<
    LevelEmployees,
    LevelEmployeesVariables
  >(BULK_LEVEL_EMPLOYEES);

  return useCallback(
    async (levelEmployeesInput: LevelEmployeesInput[]) => {
      await bulkLevelChanges({
        variables: {
          data: levelEmployeesInput,
        },
      });
    },
    [bulkLevelChanges]
  );
}

export const useUpdateEmployment = () => {
  const [updateEmployment] = useMutation<
    UpdateEmployment,
    UpdateEmploymentVariables
  >(UPDATE_EMPLOYMENT, {
    update(cache, { data }) {
      if (data == null) {
        return;
      }
      const employment = data.updateOneEmployment;

      cache.modify({
        id: cache.identify({
          __typename: "Employment",
          id: employment.id,
        }),
        fields: {
          positionId(existingPositionId: number | null) {
            return employment.positionId ?? existingPositionId;
          },
        },
      });
      cache.modify({
        id: cache.identify({
          __typename: "Employment2",
          id: employment.id,
        }),
        fields: {
          positionId(existingPositionId: number | null) {
            return employment.positionId ?? existingPositionId;
          },
        },
      });
    },
  });
  return updateEmployment;
};
