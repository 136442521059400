/** This *unauthenticated* page is an interactive offer outcome simulator.
 * When candidates are provided an offer, instead of including particular
 * illustrative outcomes in the letter, candidates can come to this page
 * to enter details at whatever valuation they want.
 *
 * Initially, this is planned to be very minimal. We expect only one (or
 * a couple) customer(s) to use this, and so we are not yet providing many
 * customization options.
 *
 * To keep this portable to Webflow or Netflify, this page may choose to
 * duplicate code rather than share it with the rest of the application.
 */

import { formatCurrency } from "@asmbl/shared/money";
import { makeStyles, Slider } from "@material-ui/core";
import { useEffect, useState } from "react";
import { CheckIcon } from "src/components/AssembleIcons/Small/CheckIcon";
import { WHITE } from "src/theme";
import CalculatorIllustration from "../assets/svgs/illustrations/calculator.svg?react";
import FullColorLogo from "../assets/svgs/illustrations/logo-full-color.svg?react";
import Triangle from "../assets/svgs/triangle.svg?react";
import { useCurrencies } from "../components/CurrenciesContext";
import { NumberInput } from "../components/Form/NumberInput";
import { currencySymbol } from "../models/Currency";
/**
 * Theme Helpers
 */

function spacing(n: number) {
  return `${n * 10}px`;
}

const COLOR = {
  BORDER: "#E0DFE9",
  PRIMARY: "#2881FF",
  ACCENT: "#16D1BB",
  NOTE: "#88A4BE",
  BACKGROUND: "#F8FAFE",
  INPUT_BACKGROUND: "#F7FBFF",
} as const;

const SHADOW = "0px 40px 90px rgba(10, 36, 64, 0.1)";

/**
 * Styles
 */
const useStyles = makeStyles(() => ({
  page: {
    padding: spacing(1),
    textAlign: "center",

    "& h3": {
      fontSize: "28px",
      letterSpacing: "-1px",
    },

    "& h4": {
      letterSpacing: "2px",
      color: COLOR.ACCENT,
      textTransform: "uppercase",
      marginBlockStart: spacing(2),
      marginBlockEnd: spacing(0.5),
    },

    "& .MuiOutlinedInput-root": {
      background: COLOR.INPUT_BACKGROUND,
      boxShadow: "none",
      marginBlockEnd: spacing(1),

      "&.Mui-focused, &:hover": {
        borderColor: COLOR.ACCENT,
      },
    },

    "& input": {
      padding: spacing(1),
    },

    "& .MuiSlider-thumb": {
      background: "white",
      border: "1px solid #BDD3E5",
      boxShadow: "none",
    },

    "& .MuiSlider-mark": {
      display: "none",
    },
  },
  logo: {
    width: "12.5rem",
  },
  main: {
    width: `calc(100% - ${spacing(2)})`,
    maxWidth: "80rem",
    margin: "auto",
    marginBlockEnd: spacing(12),
  },
  calculator: {
    background: "white",
    border: `1px solid ${COLOR.BORDER}`,
    boxShadow: SHADOW,
    padding: spacing(1),
    paddingBlockStart: spacing(10),
    textAlign: "center",
  },
  header: {
    fontSize: "36px",
    letterSpacing: "-1px",
    marginBlockEnd: 0,
  },
  subheader: {
    maxWidth: "40rem",
    marginInline: "auto",
    marginBlockStart: 0,
    marginBlockEnd: spacing(6),
    color: COLOR.NOTE,
  },

  form: {
    maxWidth: "28rem",
    marginInline: "auto",
    marginBottom: spacing(5),
    textAlign: "left",

    "& h4:not(:first-child)": {
      marginBlockStart: spacing(5),
    },
    "& label": {
      display: "block",
      fontSize: "14px",
      letterSpacing: "-0.25px",
      color: COLOR.NOTE,
      marginBlockStart: spacing(2),
      marginBlockEnd: spacing(1),
    },
  },

  adornment: {
    color: COLOR.ACCENT,
    marginInlineEnd: spacing(0.5),
  },

  calculateButton: {
    color: "white",
    background: COLOR.PRIMARY,
    border: "none",
    cursor: "pointer",
    borderRadius: spacing(4),
    height: spacing(5),
    display: "block",
    margin: "2rem auto",
    marginBlockEnd: spacing(10),
    padding: "0 1rem",
    fontFamily: "Circular",
    fontWeight: 700,

    "&:hover": {
      boxShadow: "0px 10px 30px rgba(40, 129, 255, 0.28)",
    },
  },

  results: {
    background: COLOR.BACKGROUND,
    borderTop: `1px solid ${COLOR.BORDER}`,
    margin: spacing(-1),
    paddingBlock: spacing(6),
    overflow: "hidden",
    visibility: "visible",
    maxHeight: spacing(200),
    transition: "all 350ms ease-in",

    "@media(min-width: 75rem)": {
      paddingInline: spacing(6),
    },

    "&$resultsHidden": {
      visibility: "hidden",
      maxHeight: 0,
      padding: 0,
    },
  },

  resultsHidden: {},

  resultsFlex: {
    marginBlockStart: spacing(6),

    "@media(min-width: 60rem)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      "& $resultSection": {
        height: "11rem",
      },

      "& $triangle": {
        transform: "rotate(0deg)",
      },
    },

    "@media(max-width: 60rem)": {
      "& p": {
        margin: 0,
      },
    },
  },

  resultSection: {
    width: "19rem",
    maxWidth: "85%",
    textAlign: "left",
    margin: "auto",
    position: "relative",
  },

  resultDescription: {
    fontSize: "12px",
    fontWeight: 500,
    marginBlockStart: 0,

    "& i": {
      fontWeight: 400,
    },
  },

  resultProjected: {
    position: "absolute",
    top: 0,
    right: 0,
    fontWeight: 700,
    letterSpacing: "2px",
    "@media(min-width: 60rem)": {
      marginBlockStart: spacing(2),
    },
  },

  slider: {
    marginBlockStart: spacing(2),
    color: COLOR.PRIMARY,

    "& .MuiSlider-markLabel": {
      color: COLOR.NOTE,
    },
  },

  triangle: {
    transform: "rotate(90deg)",
    marginBlockStart: spacing(-6),
    marginBlockEnd: spacing(-3),
  },

  resultNumber: {
    fontSize: "40px",
    fontWeight: "bold",
    letterSpacing: "-1px",
    color: "#34333A",
  },

  filters: {
    padding: 0,
    margin: 0,
    marginBlockStart: spacing(5),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width: 25rem)": {
      flexDirection: "column",
    },

    "& li": {
      padding: 0,
      margin: 0,
      listStyle: "none",
      cursor: "pointer",
    },
  },

  filterButton: {
    border: "none",
    background: "none",
    display: "flex",
    alignItems: "center",
    fontFamily: "Circular",
    fontSize: "12px",
    fontWeight: 700,
    letterSpacing: "2px",
    textTransform: "uppercase",
    margin: spacing(1),

    cursor: "pointer",

    color: COLOR.NOTE,
    "& $icon": {
      color: COLOR.NOTE,
    },

    "&$selected": {
      color: COLOR.PRIMARY,
      "& $icon": {
        color: "white",
      },
    },
  },

  filterIconWrapper: {
    borderRadius: "50%",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    height: "1.5rem",
    width: "1.5rem",
    border: `1px solid ${COLOR.NOTE}`,
    background: "none",
    marginInlineEnd: spacing(1),

    "$selected &": {
      borderColor: COLOR.PRIMARY,
      background: COLOR.PRIMARY,
    },
  },

  selected: {},
  icon: {},

  disclaimer: {
    color: COLOR.NOTE,
    width: "90%",
    maxWidth: "60rem",
    marginBlockStart: spacing(7),
    marginBlockEnd: spacing(4),
    marginInline: "auto",
  },
}));

/**
 * OfferOutcomeCalculator page
 */
export function OfferOutcomeCalculator(): JSX.Element {
  const classes = useStyles();
  const { defaultCurrencyCode } = useCurrencies();

  const VESTING_YEARS = 4;

  const [currentValuation, setCurrentValuation] = useState<number | null>(null);
  const [projectedValuation, setProjectedValuation] = useState<number | null>(
    null
  );
  const maximumProjectedValuation = (currentValuation ?? 1000000) * 20;
  const [salary, setSalary] = useState<number | null>(null);
  const [grantValue, setGrantValue] = useState<number | null>(null);
  const [bonus, setBonus] = useState<number | null>(null);

  const [hideResults, setHideResults] = useState(true);
  useEffect(() => {
    if (hideResults) {
      return;
    }
    setTimeout(() => {
      document
        .getElementById("results")
        ?.scrollIntoView({ behavior: "smooth" });
    }, 350);
  }, [hideResults]);
  const [components, setComponents] = useState<{
    salary: boolean;
    equity: boolean;
    bonus: boolean;
  }>({
    salary: true,
    equity: true,
    bonus: true,
  });

  const annualCompComponents = {
    salary: salary ?? 0,
    equity:
      currentValuation === 0 || currentValuation === null
        ? 0
        : ((grantValue ?? 0) * ((projectedValuation ?? 0) / currentValuation)) /
          VESTING_YEARS,
    bonus: bonus ?? 0,
  };
  const annualComp =
    (components.salary ? annualCompComponents.salary : 0) +
    (components.equity ? annualCompComponents.equity : 0) +
    (components.bonus ? annualCompComponents.bonus : 0);

  const cumulativeCompComponents = {
    salary: (salary ?? 0) * VESTING_YEARS,
    equity:
      currentValuation === 0 || currentValuation === null
        ? 0
        : (grantValue ?? 0) * ((projectedValuation ?? 0) / currentValuation),
    bonus: (bonus ?? 0) * VESTING_YEARS,
  };
  const cumulativeComp =
    (components.salary ? cumulativeCompComponents.salary : 0) +
    (components.equity ? cumulativeCompComponents.equity : 0) +
    (components.bonus ? cumulativeCompComponents.bonus : 0);

  return (
    <div className={classes.page}>
      <h1>
        <a href="https://www.assemble.inc">
          <FullColorLogo aria-label="Assemble" className={classes.logo} />
        </a>
      </h1>
      <main className={classes.main}>
        <section className={classes.calculator}>
          <CalculatorIllustration />
          <h2 className={classes.header}>Illustrative Offer Calculator</h2>
          <p className={classes.subheader}>
            This tool is for people like yourself to understand the offers
            you've received so you can make an informed decision and ensure
            you're being treated fairly and equitably.
          </p>
          <h3>Enter the equity & compensation details:</h3>
          <form className={classes.form} onSubmit={(e) => e.preventDefault()}>
            <h4>Company Equity Info</h4>
            <label htmlFor="current-valuation">
              Company Valuation as of Today
            </label>
            <NumberInput
              id="current-valuation"
              name="current-valuation"
              value={currentValuation}
              onValueChange={(value) => {
                setCurrentValuation(value.floatValue ?? null);
                setProjectedValuation(value.floatValue ?? null);
              }}
              startAdornment={
                <span className={classes.adornment}>
                  {currencySymbol(defaultCurrencyCode)}&nbsp;
                </span>
              }
              fullWidth
              allowNegative={false}
            />
            <h4>Compensation Info</h4>
            <label htmlFor="salary">Salary</label>
            <NumberInput
              id="salary"
              name="salary"
              value={salary}
              onValueChange={(value) => setSalary(value.floatValue ?? null)}
              startAdornment={<span className={classes.adornment}>$</span>}
              fullWidth
              allowNegative={false}
            />
            <label htmlFor="grant-value">RSU Grant Value (today)</label>
            <NumberInput
              id="grant-value"
              name="grant-value"
              value={grantValue}
              onValueChange={(value) => setGrantValue(value.floatValue ?? null)}
              startAdornment={<span className={classes.adornment}>$</span>}
              fullWidth
              allowNegative={false}
            />
            <label htmlFor="bonus">
              Recurring Bonus (if none, leave empty)
            </label>
            <NumberInput
              id="bonus"
              name="bonus"
              value={bonus}
              onValueChange={(value) => setBonus(value.floatValue ?? null)}
              startAdornment={<span className={classes.adornment}>$</span>}
              fullWidth
              allowNegative={false}
            />

            <button
              type="button"
              className={classes.calculateButton}
              onClick={() => setHideResults(false)}
            >
              Calculate Compensation
            </button>
          </form>

          <div
            id="results"
            className={`${classes.results} ${
              hideResults ? classes.resultsHidden : ""
            }`}
          >
            <h3>What's your compensation package&nbsp;worth?*</h3>

            <div className={classes.resultsFlex}>
              <div className={classes.resultSection}>
                <h4>Proj. Valuation</h4>

                <div className={classes.resultProjected}>
                  {formatCurrency(
                    {
                      value: projectedValuation ?? 0,
                      currency: defaultCurrencyCode,
                    },
                    {
                      maximumSignificantDigits: 3,
                      notation: "compact",
                    }
                  )}
                </div>

                <p className={classes.resultDescription}>
                  Projected value upon a future exit.
                </p>

                <Slider
                  classes={{ root: classes.slider }}
                  value={projectedValuation ?? 0}
                  min={0}
                  max={maximumProjectedValuation}
                  step={Math.pow(
                    10,
                    Math.floor(Math.log10(currentValuation ?? 1000000) - 1)
                  )}
                  onChange={(_, value) =>
                    setProjectedValuation(
                      Array.isArray(value) ? value[0] : value
                    )
                  }
                  marks={[
                    { value: 0, label: "$0" },
                    {
                      value: maximumProjectedValuation,
                      label: formatCurrency(
                        {
                          value: maximumProjectedValuation,
                          currency: defaultCurrencyCode,
                        },
                        { maximumSignificantDigits: 3, notation: "compact" }
                      ),
                    },
                  ]}
                />
              </div>
              <Triangle className={classes.triangle} />
              <div className={classes.resultSection}>
                <h4>Annualized Comp.</h4>

                <p className={classes.resultDescription}>
                  Your compensation each year. <i>(Raises not considered)</i>
                </p>

                <div className={classes.resultNumber}>
                  {formatCurrency(
                    {
                      value: Math.floor(annualComp),
                      currency: defaultCurrencyCode,
                    },
                    { maximumFractionDigits: 0 }
                  )}
                </div>
              </div>
              <Triangle className={classes.triangle} />

              <div className={classes.resultSection}>
                <h4>Cumulative Comp.</h4>

                <p className={classes.resultDescription}>
                  Total compensation over {VESTING_YEARS} years.{" "}
                  <i>(Raises not considered)</i>
                </p>

                <div className={classes.resultNumber}>
                  {formatCurrency(
                    {
                      value: Math.floor(cumulativeComp),
                      currency: defaultCurrencyCode,
                    },
                    { maximumFractionDigits: 0 }
                  )}
                </div>
              </div>
            </div>

            <ul className={classes.filters}>
              <li>
                <button
                  type="button"
                  className={`
                    ${classes.filterButton}
                    ${components.salary ? classes.selected : ""}
                  `}
                  onClick={() =>
                    setComponents((c) => ({ ...c, salary: !c.salary }))
                  }
                >
                  <div className={classes.filterIconWrapper}>
                    <CheckIcon
                      inline
                      color={components.salary ? WHITE : COLOR.NOTE}
                    />
                  </div>{" "}
                  Salary
                </button>
              </li>
              <li className={components.equity ? classes.selected : ""}>
                <button
                  type="button"
                  className={`
                    ${classes.filterButton}
                    ${components.equity ? classes.selected : ""}
                  `}
                  onClick={() =>
                    setComponents((c) => ({ ...c, equity: !c.equity }))
                  }
                >
                  <div className={classes.filterIconWrapper}>
                    <CheckIcon
                      inline
                      color={components.equity ? WHITE : COLOR.NOTE}
                    />
                  </div>{" "}
                  Equity
                </button>
              </li>
              {bonus !== null && bonus > 0 && (
                <li className={components.bonus ? classes.selected : ""}>
                  <button
                    type="button"
                    className={`
                    ${classes.filterButton}
                    ${components.bonus ? classes.selected : ""}
                  `}
                    onClick={() =>
                      setComponents((c) => ({ ...c, bonus: !c.bonus }))
                    }
                  >
                    <div className={classes.filterIconWrapper}>
                      <CheckIcon
                        inline
                        color={components.equity ? WHITE : COLOR.NOTE}
                      />
                    </div>{" "}
                    Bonus
                  </button>
                </li>
              )}
            </ul>

            <p className={classes.disclaimer}>
              *All figures are for illustrative purposes only. This tool is not
              a guarantee of anything; it is only to help you estimate the
              potential value of your equity over time. This tool doesn't
              account for taxes, and we cannot give you financial advice on that
              matter; for that, you should consult with a&nbsp;specialist.
            </p>
          </div>
        </section>
      </main>
    </div>
  );
}
