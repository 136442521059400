import { gql } from "@apollo/client";
import { Divider, makeStyles } from "@material-ui/core";
import { ChevronRightIcon } from "src/components/AssembleIcons/Brand/ChevronRightIcon";
import { AssembleLink } from "../../../components/AssembleLink";
import { AssembleTypography } from "../../../components/AssembleTypography";
import { ClickPropagationBoundary } from "../../../components/ClickPropagationBoundary";
import {
  GRAY_1,
  GRAY_3,
  GRAY_4,
  GRAY_6,
  GRAY_7,
  PURPLE_1,
  WHITE,
} from "../../../theme";
import { MarketCard_market as Market } from "../../../__generated__/graphql";
import { MarketCardActions } from "./MarketCardActions";

type Props = {
  market: Market;
  isOnlyMarket: boolean;
};

const useStyles = makeStyles((theme) => ({
  listItem: {
    listStyle: "none",
    padding: 0,

    borderRadius: "8px",
    border: `1px solid ${GRAY_6}`,

    "& a": {
      outline: "none",
    },

    "&:focus, &:focus-visible, &:focus-within, &:target": {
      color: PURPLE_1,
      outline: "none",
      borderRadius: "8px",
      border: `1px solid ${PURPLE_1}`,
      boxShadow: "0 0 0 3px rgba(100, 91, 255, 0.3)",
    },
  },
  link: {
    cursor: "pointer",
    height: "5rem",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "8px",
    padding: theme.spacing(2),
    background: WHITE,
    color: GRAY_1,

    "&:hover": {
      color: PURPLE_1,
    },

    "$listItem:focus &, $listItem:focus-visible &, $listItem:focus-within &, $listItem:target &":
      {
        color: PURPLE_1,
        backgroundColor: GRAY_7,
      },
  },
  metadataWrapper: {
    display: "flex",
    gap: theme.spacing(3),
  },
  metadata: {
    display: "inline-block",
    textAlign: "right",
  },
  metadataEyebrow: {
    marginBottom: theme.spacing(0.5),
    color: GRAY_3,
    "$listItem:hover &, listItem:focus &, $listItem:focus-visible &, $listItem:focus-within &, $listItem:target &":
      {
        color: PURPLE_1,
      },
  },
  chevron: {
    color: GRAY_4,
    "$listItem:hover &, $listItem:focus &, $listItem:focus-visible &, $listItem:focus-within &, $listItem:target &":
      {
        color: PURPLE_1,
      },
  },
  kebab: {
    paddingTop: "4px",
  },
}));

export function MarketCard({ market, isOnlyMarket }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <li className={classes.listItem}>
      <AssembleLink
        underline="none"
        whitespace={false}
        color="inherit"
        to={`/settings/markets/${market.id}/locations`}
        className={classes.link}
      >
        <AssembleTypography variant="productLongForm">
          {market.name}
        </AssembleTypography>
        <div className={classes.metadataWrapper}>
          <div className={classes.metadata}>
            <AssembleTypography
              variant="productEyebrowSmall"
              className={classes.metadataEyebrow}
            >
              Location Groups
            </AssembleTypography>
            <AssembleTypography>
              {market.locationGroups.length}
            </AssembleTypography>
          </div>
          <div className={classes.metadata}>
            <AssembleTypography
              variant="productEyebrowSmall"
              className={classes.metadataEyebrow}
            >
              Local Currency
            </AssembleTypography>
            <AssembleTypography>{market.currencyCode}</AssembleTypography>
          </div>
          <Divider orientation="vertical" flexItem />
          <ClickPropagationBoundary>
            <div className={classes.kebab}>
              <MarketCardActions market={market} isOnlyMarket={isOnlyMarket} />
            </div>
          </ClickPropagationBoundary>
          <ChevronRightIcon
            className={classes.chevron}
            inherit
            width={"24px"}
            height={"42px"}
          />
        </div>
      </AssembleLink>
    </li>
  );
}

MarketCard.fragments = {
  market: gql`
    ${MarketCardActions.fragments.market}
    fragment MarketCard_market on Market {
      ...MarketCardActions_market
      id
      name
      currencyCode
      locationGroups {
        id
      }
    }
  `,
};
