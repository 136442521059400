import { gql, useQuery } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Money } from "@asmbl/shared/money";
import { GetOfferStatisticsData } from "../../../__generated__/graphql";
import { LoadingSpinner } from "../../LoadingSpinner";
import { LocationGroup, Position } from "../types";
import { CompStatistics } from "./CompStatistics";

type Props = {
  selectedCurrencyCode: CurrencyCode;
  position: Position;
  locationGroup: LocationGroup;
  annualOrHourlySalary: Money | undefined;
  salaryCompaRatio: number | null;
};

export function CompStatisticsLoadingBoundary({
  selectedCurrencyCode,
  position,
  locationGroup,
  annualOrHourlySalary,
  salaryCompaRatio,
}: Props): JSX.Element | null {
  const {
    data: offerStatistics,
    loading,
    error,
  } = useQuery<GetOfferStatisticsData>(CompStatisticsLoadingBoundary.query, {
    variables: {
      currencyCode: selectedCurrencyCode,
      positionId: position.id,
      locationGroupId: locationGroup.id,
    },
  });

  if (offerStatistics == null) {
    if (loading) {
      return <LoadingSpinner />;
    } else {
      return <div>{error?.message ?? "An error has occurred"}</div>;
    }
  }

  if (
    offerStatistics.anonymizedCompensation.positionAndLocationGroup
      .cashStatistics == null
  ) {
    return (
      <div>Not enough data points available to provide anonymous data.</div>
    );
  }

  return (
    <CompStatistics
      selectedCurrencyCode={selectedCurrencyCode}
      position={position}
      locationGroup={locationGroup}
      annualOrHourlySalary={annualOrHourlySalary}
      salaryCompaRatio={salaryCompaRatio}
      offerStatistics={offerStatistics.anonymizedCompensation}
    />
  );
}

CompStatisticsLoadingBoundary.query = gql`
  ${CompStatistics.fragments.compStatistics}
  query GetOfferStatisticsData(
    $currencyCode: String!
    $positionId: Int!
    $locationGroupId: Int!
  ) {
    anonymizedCompensation(
      currencyCode: $currencyCode
      positionId: $positionId
      locationGroupId: $locationGroupId
    ) {
      ...CompStatistics_anonymizedCompensation
    }
  }
`;
