import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const ListOrderedIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="List (Ordered)"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M10.125 12H20.2499"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M10.125 6H20.2499"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M10.1249 18H20.2499"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M3.75 5.625L5.25 4.875V10.1244"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M3.85314 14.3016C3.92441 14.133 4.03016 13.9811 4.16359 13.8558C4.29703 13.7305 4.4552 13.6344 4.62795 13.5739C4.8007 13.5133 4.98421 13.4895 5.1667 13.5041C5.34918 13.5187 5.5266 13.5712 5.68756 13.6584C5.84852 13.7456 5.98947 13.8655 6.10134 14.0104C6.21322 14.1553 6.29355 14.322 6.33718 14.4998C6.38081 14.6776 6.38677 14.8625 6.35468 15.0428C6.32259 15.223 6.25316 15.3945 6.15084 15.5463L3.75 18.75H6.375"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
