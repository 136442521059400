import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const LevelIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Level"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          className="stroke"
          d="M2 12.6667V13.3334"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          className="stroke"
          d="M5.33325 9.33331V13.3333"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          className="stroke"
          d="M9.33325 6V13.3333"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          className="stroke"
          d="M13.3333 2.66669V13.3334"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          className="stroke"
          d="M9.33333 5.66669L8 7.00002"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          className="stroke"
          d="M9.33325 5.66669L10.6666 7.00002"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </ColorableSVG>
);
