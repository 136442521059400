import { gql } from "@apollo/client";
import { CashBandName, CurrencyCode } from "@asmbl/shared/constants";
import { Money, zero } from "@asmbl/shared/money";
import { round } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import { memo } from "react";
import { InfoIcon } from "src/components/AssembleIcons/Brand/InfoIcon";
import { InfoBanner } from "src/components/InfoBanner";
import { getPayCashEquivalent } from "src/models/CashCompensation";
import {
  Subcomponent_band as CashBand,
  CashCompType,
  Subcomponent_cash as CashCompensation,
  CompUnit,
} from "../../../__generated__/graphql";
import NoBand from "../../../assets/svgs/illustrations/empty-compensation.svg?react";
import { isBandPointDefined } from "../../../models/BandPoint";
import { getSimpleCashLabel } from "../../../models/Currency";
import { getCompaRatioNew } from "../../../models/Employee";
import { GRAY_1, GRAY_3, GRAY_4, WHITE, theme } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";
import { useCurrencies } from "../../CurrenciesContext";
import { NoCashInfo } from "../../NoCashInfo";
import SubcomponentSlider from "./SubcomponentSlider";

const useStyles = makeStyles(() => ({
  mainText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  bandName: {
    display: "flex",
    flexDirection: "row",
    color: GRAY_1,
    alignItems: "center",
    gap: "0.3rem",
  },
  subtext: {
    color: GRAY_3,
  },
  missingBand: {
    marginTop: "0.5rem",
    color: GRAY_4,
    display: "flex",
    alignItems: "center",
    gap: "0.375rem",
  },
  icon: {
    height: "18px",
    width: "18px",
  },
  sliderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    columnGap: theme.spacing(1.5),
  },
  slider: { marginTop: "0.5rem", width: "100%" },
  info: { marginTop: "0.25rem" },
  banner: { paddingTop: theme.spacing(2) },
  tooltipIcon: { verticalAlign: "middle", marginBottom: "2px" },
}));

/**
 * Normally we would rely on the `currencyCode` in `cash` to format the band
 * points. Since `cash` can be undefined, we need to pass in a `currency`
 * parameter separately.
 */
type Props = {
  type: CashCompType;
  cash: CashCompensation | undefined;
  band: CashBand | undefined;
  currency?: CurrencyCode;
};

const SubcomponentMemo = memo(function Subcomponent({
  type,
  cash,
  band,
  currency,
}: Props): JSX.Element {
  const classes = useStyles();
  const { defaultCurrencyCode } = useCurrencies();

  const isSalary = type === CashCompType.SALARY;
  const isHourly = cash?.unit === CompUnit.HOURLY_CASH;

  // filter here for band points that are defined
  const compaRatio =
    isSalary && cash && band ? getCompaRatioNew([cash], [band]) : undefined;

  // the server backfills the bandPoints with null values, so in the case of hourly pay,
  // we want to set the salary comp as one defined point if the others are null
  const bandPoints =
    isSalary && isHourly
      ? band?.bandPoints.filter((b) => isBandPointDefined(b))
      : band?.bandPoints;

  const cashValue: Money = cash
    ? getPayCashEquivalent(cash)
    : zero(currency ?? defaultCurrencyCode);

  return (
    <>
      <div className={classes.mainText}>
        <div className={classes.bandName}>
          <AssembleTypography variant="productSmallSemiBold">
            {`${CashBandName[type]}: ${
              cash
                ? getSimpleCashLabel(cashValue, false, isSalary && isHourly)
                : "No Data"
            }`}
          </AssembleTypography>
        </div>
        {isSalary ? (
          <AssembleTypography
            variant="productExtraSmallSemiBold"
            className={classes.subtext}
          >
            {compaRatio !== undefined &&
              `Compa-ratio ${round(100 * compaRatio)}%`}
          </AssembleTypography>
        ) : (
          <AssembleTypography
            variant="productExtraSmallSemiBold"
            className={classes.subtext}
          >
            {cash && `${round(cash.percentOfSalary)}% of salary`}
          </AssembleTypography>
        )}
      </div>

      {band != null && band.bandPoints.some(isBandPointDefined) ? (
        <>
          <div className={classes.sliderContainer}>
            <div className={classes.slider}>
              <SubcomponentSlider
                value={cashValue}
                bandPoints={bandPoints ?? []}
                isHourly={isHourly}
              />
            </div>
            {!cash && (
              <div className={classes.info}>
                <NoCashInfo type={type} />
              </div>
            )}
          </div>
          {!cash && (
            <div className={classes.banner}>
              <InfoBanner level="warning">
                There's no data for some compensation elements. Hover over&nbsp;
                <InfoIcon
                  className={classes.tooltipIcon}
                  color={WHITE}
                  inline
                  width="12px"
                  height="12px"
                />
                next to each element for more info
              </InfoBanner>
            </div>
          )}
        </>
      ) : (
        <div className={classes.missingBand}>
          <NoBand className={classes.icon} />
          <AssembleTypography variant="productExtraSmallSemiBold">
            We don't have any band information
          </AssembleTypography>
        </div>
      )}
    </>
  );
});

export const Subcomponent = Object.assign(SubcomponentMemo, {
  fragments: {
    cash: gql`
      fragment Subcomponent_cash on CashCompensation2 {
        type
        annualCashEquivalent
        hourlyCashEquivalent
        unit
        percentOfSalary
      }
    `,
    band: gql`
      ${SubcomponentSlider.fragments.bandPoint}
      fragment Subcomponent_band on AdjustedCashBand {
        name
        bandPoints {
          ...SubcomponentSlider_bandPoint
        }
      }
    `,
  },
});
