import {
  CashBandType,
  EquityGrantMethod,
  MONTHS_IN_A_YEAR,
} from "@asmbl/shared/constants";
import {
  Currency,
  exchangeFromTo,
  hourlyToAnnual,
} from "@asmbl/shared/currency";
import {
  add,
  exerciseCost,
  formatCurrency,
  isZero,
  roundMoney,
  zero,
} from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import { formatNumber } from "accounting";
import { ApexOptions } from "apexcharts";
import clsx from "clsx";
import { memo, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { GetOffer, PositionType } from "../../__generated__/graphql";
import { CashBandCandidateDisplayName, CashBandName } from "../../constants";
import { getSimpleCashLabel } from "../../models/Currency";
import { exchangeRate } from "../../models/Money";
import { getAnnualBenefitsValue, hasStrikePrice } from "../../models/Offer";
import { APP_FONTS, BLUE_2, GRAY_1, GRAY_2, GRAY_4, GRAY_5 } from "../../theme";
import { NonNull } from "../../utils";
import { useCurrencies } from "../CurrenciesContext";
import Header from "./Header";
import { CompBreakdown } from "./OfferFrames";
import { globalStyles } from "./style";

type CompStructure = NonNull<GetOffer["compStructure"]>;
type Offer = GetOffer["offer"];
type OfferConfig = NonNull<GetOffer["offerConfig"]>;
type Valuation = NonNull<GetOffer["valuation"]>;

export type OfferProps = {
  data: CompBreakdown;
  offer: Offer;
  config: OfferConfig;
  valuation: Valuation;
  compStructure: CompStructure;
  localCurrency: Currency;
  equityCurrency: Currency;
};

type InfoRowProps = {
  label: React.ReactNode;
  value: React.ReactNode;
};

const useStyles = makeStyles(() => ({
  light: {
    fontWeight: 100,
  },
  small: {
    fontSize: "14px",
  },
}));

const Offer = ({
  data,
  offer,
  config,
  valuation,
  compStructure,
  localCurrency,
  equityCurrency,
}: OfferProps) => {
  const glClasses = globalStyles();
  const classes = useStyles();
  const { currencies } = useCurrencies();
  const color = config.brandColorPrimary ?? BLUE_2;
  const candidateName = offer.candidate.candidateName;
  const { vestingCliff, vestingMonths } = compStructure;

  const showExerciseCost = compStructure.equityGrantTypes.every(hasStrikePrice);
  const vestingYears = formatNumeral(vestingMonths / MONTHS_IN_A_YEAR);
  const equityFootnote =
    config.equityFootnote !== null && config.equityFootnote !== ""
      ? config.equityFootnote
      : `Equity vests over a ${vestingYears}-year timespan, with a ${vestingCliff}-month cliff.`;

  const benefitsCurrency =
    currencies.get(offer.benefitsPackage?.currencyCode ?? localCurrency.code) ??
    localCurrency;
  const foreignCurrencies = [equityCurrency, benefitsCurrency].filter(
    (c) => c.code !== localCurrency.code
  );

  /*
   * Build the Pie Chart series. Ordering matches the order of the line items
   */
  const seriesData: number[] = [];
  const colors: string[] = [];

  // Fixed cash (Salary) comes first
  data.cash[CashBandType.FIXED_CASH].forEach((fixed) => {
    if (fixed.money.value > 0) {
      seriesData.unshift(fixed.money.value);
      colors.unshift(fixed.name === CashBandName.SALARY ? GRAY_2 : GRAY_4);
    }
  });

  // Variable cash (Commission, Recurring Bonus)
  const variableCash = data.cash[CashBandType.VARIABLE_CASH]
    .map((comp) => comp.money)
    .reduce(add, zero(data.maxProjected.currency));

  if (variableCash.value > 0) {
    seriesData.unshift(variableCash.value);
    colors.unshift(GRAY_5);
  }

  // Equity
  if (config.showAnnualizedEquity && data.equity) {
    seriesData.unshift(
      exchangeFromTo(data.equity.money.annual, equityCurrency, localCurrency)
        .value
    );
    colors.unshift(color);
  }

  // Benefits
  const benefitsValueInOfferCurrency = useMemo(
    () =>
      getAnnualBenefitsValue(currencies, {
        currency: localCurrency,
        benefitsPackage: offer.benefitsPackage,
      }),
    [currencies, localCurrency, offer.benefitsPackage]
  );

  if (!isZero(benefitsValueInOfferCurrency)) {
    seriesData.unshift(benefitsValueInOfferCurrency.value);
    colors.unshift(GRAY_1);
  }

  const options: ApexOptions = {
    chart: { fontFamily: APP_FONTS },
    series: seriesData,
    colors: colors,
    dataLabels: { enabled: false },
    legend: { show: false },
    tooltip: { enabled: false },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
  };

  const total = add(
    config.showAnnualizedEquity ? data.totalComp : data.totalCash,
    benefitsValueInOfferCurrency
  );

  const showHourlyLabels = offer.position?.type === PositionType.HOURLY;

  return (
    <Box pt={10} mt={10} style={{ width: "100%" }}>
      <Header
        color={color}
        description={offer.title ?? ""}
        headline={candidateName}
        subHead="Compensation Details"
      />
      <Grid container>
        <Grid item xs={6}>
          <Typography className={glClasses.header}>
            Total Annual Compensation
          </Typography>
          {Object.values(data.cash[CashBandType.FIXED_CASH]).map((comp) =>
            showHourlyLabels && comp.name === CashBandName.SALARY ? (
              <>
                <InfoRow
                  key={comp.name}
                  label={
                    <span>
                      {CashBandCandidateDisplayName[comp.name]}{" "}
                      <span className={classes.light}>(Hourly)</span>
                    </span>
                  }
                  value={
                    <span
                      style={{
                        color: GRAY_2,
                      }}
                    >
                      {getSimpleCashLabel(comp.money)}{" "}
                      <span className={clsx(glClasses.gray4, classes.small)}>
                        / hour
                      </span>
                    </span>
                  }
                />
                <InfoRow
                  key={comp.name}
                  label={
                    <span>
                      {CashBandCandidateDisplayName[comp.name]}{" "}
                      <span className={classes.light}>(Annual)</span>
                      <span style={{ color }}>*</span>
                    </span>
                  }
                  value={
                    <span
                      style={{
                        color: GRAY_2,
                      }}
                    >
                      {getSimpleCashLabel(
                        hourlyToAnnual(
                          compStructure.employmentHoursPerWeek *
                            compStructure.employmentWeeksPerYear,
                          comp.money
                        )
                      )}{" "}
                      <span className={clsx(glClasses.gray4, classes.small)}>
                        / year
                      </span>
                    </span>
                  }
                />
              </>
            ) : (
              <InfoRow
                key={comp.name}
                label={CashBandCandidateDisplayName[comp.name]}
                value={
                  <span
                    style={{
                      color:
                        comp.name === CashBandName.SALARY ? GRAY_2 : GRAY_4,
                    }}
                  >
                    {getSimpleCashLabel(comp.money)}
                  </span>
                }
              />
            )
          )}

          {variableCash.value > 0 && (
            <InfoRow
              label={
                <span>
                  Variable Cash{" "}
                  <span style={{ color }}>{showHourlyLabels ? "**" : "*"}</span>
                </span>
              }
              value={
                <span className={glClasses.gray5}>
                  {getSimpleCashLabel(variableCash)}
                </span>
              }
            />
          )}

          {data.equity && (
            <InfoRow
              label={
                <span>
                  Equity{" "}
                  <span className={classes.light}>
                    {compStructure.equityGrantMethod ===
                      EquityGrantMethod.UNITS &&
                      `(${formatNumeral(data.equity.units, {
                        maximumFractionDigits: 0,
                      })} Units) `}
                    {config.showAnnualizedEquity &&
                      `(${getSimpleCashLabel(data.equity.money.total)} total) `}
                  </span>
                  <span style={{ color }}>
                    {" "}
                    {showHourlyLabels ? "***" : "**"}
                  </span>
                </span>
              }
              value={
                config.showAnnualizedEquity ? (
                  <span>
                    <span style={{ color }}>
                      {getSimpleCashLabel(data.equity.money.annual)}{" "}
                    </span>
                    <span className={clsx(glClasses.gray4, classes.small)}>
                      / year
                    </span>
                  </span>
                ) : null
              }
            />
          )}

          {offer.benefitsPackage &&
            !isZero(offer.benefitsPackage.totalValue) && (
              <InfoRow
                label="Est. Benefits Value"
                value={
                  <span style={{ color: GRAY_1 }}>
                    {getSimpleCashLabel(offer.benefitsPackage.totalValue)}
                  </span>
                }
              />
            )}

          <Box className={glClasses.divider} />

          <InfoRow
            label={
              <>
                Total&nbsp;
                {equityCurrency.code !== localCurrency.code && (
                  <span style={{ color }}> ***</span>
                )}
              </>
            }
            value={
              // if equityCurrency & localCurrency are different, then we will
              // round total value to the nearest 500 and show approximation.
              equityCurrency.code !== localCurrency.code
                ? `~${getSimpleCashLabel(roundMoney(total, 500))}`
                : getSimpleCashLabel(total)
            }
          />
          <Box mt={5}>
            {showHourlyLabels && (
              <Box mb={2}>
                <Typography className={glClasses.footnote}>
                  <span style={{ color }}>* </span>Annualized salary is an
                  estimate of annual take home based on a{" "}
                  {formatNumber(
                    compStructure.employmentHoursPerWeek *
                      compStructure.employmentWeeksPerYear
                  )}{" "}
                  hour work year.
                </Typography>
              </Box>
            )}
            {variableCash.value > 0 && (
              <Box mb={2}>
                <Typography className={glClasses.footnote}>
                  <span style={{ color }}>
                    {showHourlyLabels ? "**" : "*"}{" "}
                  </span>
                  Represents the target amounts and is not guaranteed. Actual
                  payment may be contingent on performance, managerial
                  discretion, and other factors.
                </Typography>
              </Box>
            )}
            {data.equity && (
              <Box mb={2}>
                <Typography className={glClasses.footnote}>
                  <span style={{ color }}>
                    {showHourlyLabels ? "***" : "**"}{" "}
                  </span>
                  {equityFootnote}
                  {showExerciseCost && (
                    <>
                      {" Estimated cost to exercise "}(
                      {formatCurrency(valuation.strikePrice)}
                      {"/option): "}
                      {getSimpleCashLabel(
                        exerciseCost(data.equity.units, valuation.strikePrice)
                      )}
                      .
                    </>
                  )}
                </Typography>
              </Box>
            )}
            {equityCurrency.code !== localCurrency.code && (
              <Typography className={glClasses.footnote}>
                <span style={{ color }}>*** </span>
                {`Total is estimated using the following exchange rate(s):
                ${foreignCurrencies
                  .map((foreignCurrency) =>
                    exchangeRate(foreignCurrency, localCurrency)
                  )
                  .join("; ")}; rounded to the nearest ${
                  localCurrency.code
                } 500.`}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box pl={18}>
            {seriesData.length > 0 && (
              <ReactApexChart
                options={options}
                series={options.series}
                type="donut"
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(Offer);

const InfoRow = ({ label, value }: InfoRowProps) => {
  const classes = globalStyles();

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      mb={3}
    >
      <Typography className={`${classes.label} ${classes.gray4}`}>
        {label}
      </Typography>
      <Typography className={`${classes.label} ${classes.gray2}`}>
        {value}
      </Typography>
    </Box>
  );
};
