import { gql } from "@apollo/client";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { contramap } from "@asmbl/shared/sort";
import {
  CurrencyCode,
  CondensedTableCurrentTargetCashCell2_participant as Participant,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { getTotalCash } from "../../../../models/CashCompensation";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableCurrentTargetCashCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const totalCash = getTotalCash(
    employee.subject.activeCashCompensation
  )?.annualTotal;
  return (
    <AssembleTruncatedTypography align="right">
      {totalCash ? getSimpleCashLabel(totalCash) : "-"}
    </AssembleTruncatedTypography>
  );
}

CondensedTableCurrentTargetCashCell2.fragments = {
  participant: gql`
    fragment CondensedTableCurrentTargetCashCell2_participant on CompCycleParticipant {
      subject {
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          annualCashEquivalent
          hourlyCashEquivalent
          unit
          percentOfSalary
        }
      }
    }
  `,
};

CondensedTableCurrentTargetCashCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.CURRENT_TARGET_CASH
);
CondensedTableCurrentTargetCashCell2.id = ColumnIds.CURRENT_TARGET_CASH;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableCurrentTargetCashCell2,
  Header: CondensedTableCurrentTargetCashCell2.Header,
  id: CondensedTableCurrentTargetCashCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableCurrentTargetCashCell2.column = column;
CondensedTableCurrentTargetCashCell2.ordering = ({
  currencies,
  defaultCurrencyCode,
}: {
  currencies: Map<CurrencyCode, Currency>;
  defaultCurrencyCode: CurrencyCode;
}) =>
  contramap((e: Participant) => {
    const totalCash = getTotalCash(e.subject.activeCashCompensation);

    if (totalCash == null) {
      return 0;
    }

    const currentCurrency = currencies.get(totalCash.currency);
    const defaultCurrency = currencies.get(defaultCurrencyCode);

    if (currentCurrency == null || defaultCurrency == null) {
      return 0;
    }

    return exchangeFromTo(
      totalCash.annualTotal,
      currentCurrency,
      defaultCurrency
    ).value;
  });
