import { add, Money, zero } from "@asmbl/shared/money";
import { CashBandName } from "../constants";
import { DV_GREEN, GRAY_4, GRAY_6, RED } from "../theme";

export const SUPPORTED_COMPENSATION_TYPES = [
  CashBandName.SALARY,
  CashBandName.RECURRING_BONUS,
  CashBandName.COMMISSION,
];

export function isSupportedCompensationType(type: string): boolean {
  return SUPPORTED_COMPENSATION_TYPES.includes(type as CashBandName);
}

export function getDataPointForChart(
  excludedBandNames: Set<string>,
  dataPoint: {
    salary: Money | null;
    commission: Money | null;
    bonus: Money | null;
  }
): Money | null {
  let current: Money | null = null;

  if (dataPoint.salary != null && !excludedBandNames.has(CashBandName.SALARY)) {
    current = dataPoint.salary;
  }

  if (
    dataPoint.commission != null &&
    !excludedBandNames.has(CashBandName.COMMISSION)
  ) {
    current = add(
      current ?? zero(dataPoint.commission.currency),
      dataPoint.commission
    );
  }

  if (
    dataPoint.bonus != null &&
    !excludedBandNames.has(CashBandName.RECURRING_BONUS)
  ) {
    current = add(current ?? zero(dataPoint.bonus.currency), dataPoint.bonus);
  }

  return current;
}

export function getDataPointFill(band: number[], dataPoint: number): string {
  const min = Math.min(...band);
  const max = Math.max(...band);

  return dataPoint < min ? DV_GREEN : dataPoint > max ? RED : GRAY_4;
}

export function enoughCompData<
  T extends {
    positionAndLocationGroup: { employeeStatistics: { count: number } };
  },
  U extends { anonymizedCompensationEmployeeThreshold: number },
>(data: T, permissionSettings: U): boolean {
  return (
    data.positionAndLocationGroup.employeeStatistics.count >=
    permissionSettings.anonymizedCompensationEmployeeThreshold
  );
}

export const AnonymizedCompensationCartesianGridProps = {
  stroke: GRAY_6,
  horizontalPoints: [1000],
  // creates an array of 500 numbers in increments of 30 (to match the
  // `tickAmount` from the apexchart bar chart)
  verticalPoints: Array.from({ length: 500 }, (_, i) => i * 30),
  strokeDasharray: "2",
};
