import { gql } from "@apollo/client";
import { Typography } from "@material-ui/core";
import { useMemo } from "react";
import { GRAY_2 } from "../../../theme";
import { LocationMappingInfoBanner_locations as Location } from "../../../__generated__/graphql";
import { AssembleLink } from "../../AssembleLink";
import { DismissableInfoBanner } from "../../InfoBanner";

type Props = { locations: Location[] };
export function LocationMappingInfoBanner({
  locations,
}: Props): JSX.Element | null {
  const hasUnmappedLocations = useMemo(
    () => locations.some((location) => location.locationGroup === null),
    [locations]
  );

  if (!hasUnmappedLocations) {
    return null;
  }

  return (
    <DismissableInfoBanner>
      <Typography variant="body2" style={{ color: GRAY_2 }}>
        You have HRIS locations that are new or unmapped (
        <AssembleLink
          to="/settings/markets#locations"
          whitespace={false}
          variant="body2"
        >
          listed here
        </AssembleLink>
        ). You’ll need to map them so we can effectively level the people in
        your organization. You can either map them 1 of 2 ways:
      </Typography>
      <ul>
        <li>
          <Typography variant="body2" style={{ color: GRAY_2 }}>
            Edit an existing Location Group and map the appropriate locations to
            it.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" style={{ color: GRAY_2 }}>
            Create a new Location Group and map the appropriate locations to it.
          </Typography>
        </li>
      </ul>
    </DismissableInfoBanner>
  );
}

LocationMappingInfoBanner.fragments = {
  locations: gql`
    fragment LocationMappingInfoBanner_locations on Location {
      id
      locationGroup {
        id
      }
    }
  `,
};
