import { gql } from "@apollo/client";
import { TableCell } from "@material-ui/core";
import { CompaRatioCell_employee as Employee } from "../../__generated__/graphql";
import { getCompaRatioNew } from "../../models/Employee";
import { AssembleTruncatedTypography } from "../AssembleTruncatedTypography";

interface Props {
  employee: Employee;
  textColor?: string;
  className?: string;
}

export function CompaRatioCell({
  employee,
  textColor = "inherit",
  className,
}: Props): JSX.Element {
  const compaRatio = getCompaRatioNew(
    employee.activeCashCompensation,
    employee.adjustedCashBands
  );

  return (
    <TableCell className={className} align="right">
      <AssembleTruncatedTypography textColor={textColor}>
        {compaRatio !== undefined ? `${(compaRatio * 100).toFixed(0)}%` : "-"}
      </AssembleTruncatedTypography>
    </TableCell>
  );
}

CompaRatioCell.fragments = {
  employee: gql`
    fragment CompaRatioCell_employee on Employee2 {
      id
      activeEmployment {
        salary
        payRate
        payPeriod
      }
      activeCashCompensation {
        employeeId
        type
        activeAt
        annualCashEquivalent
        hourlyCashEquivalent
        unit
      }
      # This is purposefully not queried for from 'activeCashCompensation' so
      # that (in ReportsTable) we don't double-query the cash bands for each
      # employee: once under # 'activeCashCompensation' and another time
      # over 'Employee'.
      adjustedCashBands {
        id
        name
        bandPoints {
          value {
            ... on CashValue {
              annualRate
              hourlyRate
              currencyCode
            }
          }
        }
      }
    }
  `,
};
