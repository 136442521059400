import { makeStyles, TableBody, TableRow } from "@material-ui/core";
import { NoResultsIcon } from "src/components/AssembleIcons/Brand/NoResultsIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { WireTableCell } from "src/components/Table/WireTable";
import { GRAY_4 } from "src/theme";

const useStyles = makeStyles((theme) => ({
  emptyTable: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

export const CorrectionCostToMoveEmptyTable = () => {
  const classes = useStyles();

  return (
    <TableBody>
      <TableRow>
        <WireTableCell colSpan={7} rowSpan={2}>
          <div className={classes.emptyTable}>
            <NoResultsIcon color={GRAY_4} width="48px" height="48px" />
            <AssembleTypography
              variant="productParagraphLarge"
              textColor={GRAY_4}
            >
              We couldn't find any employees matching these filters.
            </AssembleTypography>
          </div>
        </WireTableCell>
      </TableRow>
    </TableBody>
  );
};
