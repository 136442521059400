import { gql, useQuery } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import {
  CompCycleSubmitChangesButtonLoadingBoundaryQuery,
  CompCycleSubmitChangesButtonLoadingBoundaryQueryVariables,
} from "../../../__generated__/graphql";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { CompCycleSubmitChangesButton } from "./CompCycleSubmitChangesButton";

import { useFeatureFlags } from "src/components/FeatureContext";
import { usePagination } from "./Contexts/PaginationContext";
import { useTableFilterContext } from "./Contexts/TableFilterContext";

type Props = {
  compCycleId: number;
  nameSearch: string | null;
};

export function CompCycleSubmitChangesButtonLoadingBoundary({
  compCycleId,
  nameSearch,
}: Props): JSX.Element | null {
  const { pageSize, currentPage, sortBy, sortDir } = usePagination();
  const { filter, actingManagerEmployeeId } = useTableFilterContext();
  const { isEnabled } = useFeatureFlags();

  const shouldPaginate = isEnabled(FeatureFlag.ClientPlanTable030124);

  const { data, loading } = useQuery<
    CompCycleSubmitChangesButtonLoadingBoundaryQuery,
    CompCycleSubmitChangesButtonLoadingBoundaryQueryVariables
  >(CompCycleSubmitChangesButtonLoadingBoundary.query, {
    variables: {
      compCycleId,
      filter: { ...filter, displayName: nameSearch },
      skipEligibility: true,
      limit: shouldPaginate ? pageSize : undefined,
      offset: shouldPaginate ? currentPage * pageSize : undefined,
      sort: shouldPaginate ? { sortBy, sortDir } : undefined,
      actingManagerEmployeeId,
    },
  });

  if (!data || !data.compCycle2 || !data.whoami) {
    return loading ? <LoadingSpinner /> : <>An error has occurred</>;
  }

  return (
    <CompCycleSubmitChangesButton
      compCycleId={compCycleId}
      currentEmployee={data.whoami.user.employee ?? null}
      participants={data.compCycle2.participants.participants}
    />
  );
}

CompCycleSubmitChangesButtonLoadingBoundary.query = gql`
  ${CompCycleSubmitChangesButton.fragments.participant}
  ${CompCycleSubmitChangesButton.fragments.currentEmployee}
  query CompCycleSubmitChangesButtonLoadingBoundaryQuery(
    $compCycleId: Int!
    $filter: GetParticipantsInput
    $skipEligibility: Boolean
    $limit: Int
    $offset: Int
    $sort: Sort
    $actingManagerEmployeeId: Int
  ) {
    whoami {
      user {
        employee {
          id
          ...CompCycleSubmitChangesButton_currentEmployee
        }
      }
    }
    compCycle2(id: $compCycleId) {
      id
      participants(
        input: $filter
        limit: $limit
        offset: $offset
        sort: $sort
      ) {
        count # match the table boundary query for caching
        participants {
          subjectId
          compCycleId
          ...CompCycleSubmitChangesButton_participant
        }
      }
    }
  }
`;
