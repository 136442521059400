import { useLazyQuery } from "@apollo/client";
import { useTrack } from "../../analytics";
import { GET_RECOMMENDATIONS_CSV_EXPORT } from "../../queries";
import { GetCompRecommendationsCsvExport } from "../../__generated__/graphql";
import { AssembleButtonProps } from "../AssembleButton/AssembleButton";
import { CsvDownloader } from "./CsvDownloader";
import { CsvExport } from "./CsvExport";

type CsvExportProps =
  | {
      variant: "icon";
      compCycleId: number;
      compCycleName: string;
    }
  | {
      variant: "button";
      compCycleId: number;
      compCycleName: string;
      buttonProps?: AssembleButtonProps;
    };

export function CompCycleCsvExportWrapper({
  compCycleId,
  compCycleName,
  variant,
  ...rest
}: CsvExportProps): JSX.Element {
  const { trackEvent } = useTrack();
  const [getCompCycleExport, { loading }] =
    useLazyQuery<GetCompRecommendationsCsvExport>(
      GET_RECOMMENDATIONS_CSV_EXPORT,
      {
        variables: { compCycleId },
        fetchPolicy: "no-cache",
      }
    );

  const handleClick = async () => {
    trackEvent({ object: "Comp Cycle", action: "Exported" });
    return getCompCycleExport().then(
      (result) => result.data?.exportCompRecommendationsToCsv.data
    );
  };

  return (
    <CsvDownloader
      errorMessage="There are no reviews to export."
      fetch={handleClick}
      filename={compCycleName}
    >
      {(onDownload) => (
        <CsvExport
          variant={variant}
          onClick={onDownload}
          loading={loading}
          {...rest}
        />
      )}
    </CsvDownloader>
  );
}
