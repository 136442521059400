import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";
import { CurrencyForm } from "../../components/Currency/CurrenciesForm";
import Loading from "../../components/Settings/Loading";
import { EMPLACE_ALL_CURRENCIES } from "../../mutations";
import { GET_CURRENCIES_SETTINGS } from "../../queries";
import {
  CurrencyInput,
  EmplaceAllCurrencies,
  EmplaceAllCurrenciesVariables,
  GetCurrenciesSettings,
} from "../../__generated__/graphql";
import { PageContainer } from "./PageContainer";

const CurrencyData = (): JSX.Element => {
  const { data, loading } = useQuery<GetCurrenciesSettings>(
    GET_CURRENCIES_SETTINGS
  );

  const [emplace] = useMutation<
    EmplaceAllCurrencies,
    EmplaceAllCurrenciesVariables
  >(EMPLACE_ALL_CURRENCIES, {
    refetchQueries: [{ query: GET_CURRENCIES_SETTINGS }],
  });

  const onSubmit = useCallback(
    async (currencies: CurrencyInput[]) => {
      await emplace({ variables: { currencies } });
      return true;
    },
    [emplace]
  );

  if (loading) {
    return <Loading />;
  }
  if (!data) {
    return <>An Error occurred</>;
  }

  return (
    <PageContainer
      header={`${data.organization.name}'s Currency Settings`}
      description={
        <>
          Your default currency is the currency you use as your base for
          compensation.
        </>
      }
      centerContent
    >
      <CurrencyForm currencies={data.currencies} onSubmit={onSubmit} />
    </PageContainer>
  );
};

export default CurrencyData;
