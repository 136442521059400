import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const PositionIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Position"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M20.25 6.375H3.75C3.33579 6.375 3 6.71079 3 7.125V19.125C3 19.5392 3.33579 19.875 3.75 19.875H20.25C20.6642 19.875 21 19.5392 21 19.125V7.125C21 6.71079 20.6642 6.375 20.25 6.375Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M21.0011 11.4665C18.2658 13.049 15.1605 13.88 12.0004 13.875C8.84075 13.88 5.736 13.0493 3.00098 11.4673M15.7504 6.375V4.5C15.7504 4.10217 15.5923 3.72064 15.311 3.43934C15.0297 3.15804 14.6482 3 14.2504 3H9.75036C9.35254 3 8.97101 3.15804 8.6897 3.43934C8.4084 3.72064 8.25036 4.10217 8.25036 4.5V6.375H15.7504Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M10.875 10.5H13.125"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
