import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { CashCompType } from "src/__generated__/graphql";
import { Order } from "src/components/SortableTable";

export enum SortByEnum {
  displayName = "displayName",
  position = "position",
  department = "department",
  ladder = "ladder",
  level = "level",
  tenure = "tenure",
  location = "location",
  manager = "manager",
  totalCash = "totalCash",
  compaRatio = "compaRatio",
  equityCount = "equityCount",
  jobTitle = "jobTitle",
}

export type SortBy = keyof typeof SortByEnum;

export type TableCompType = {
  [CashCompType.SALARY]: boolean;
  [CashCompType.COMMISSION]: boolean;
  [CashCompType.RECURRING_BONUS]: boolean;
};

export const compTypeToExclusion = (compType: TableCompType) =>
  Object.entries(compType).reduce(
    (acc, [key, value]) => {
      if (value === false) {
        acc.push(key as keyof TableCompType);
      }
      return acc;
    },
    [] as (keyof TableCompType)[]
  );

type TableContextType = {
  offset: number;
  limit: number;
  sortBy: SortBy;
  sortDir: Order;
  searchQuery: string;
  prevSearchQuery: string;
  compType: TableCompType;
  setOffset: (offset: number) => void;
  setLimit: (limit: number) => void;
  setSortBy: (sortBy: SortBy) => void;
  setSortDir: (sortDir: Order) => void;
  setSearchQuery: (displayName: string) => void;
  handleSort: (newSortBy: SortBy, newSortDir: Order | undefined) => void;
  setCompType: (compType: TableCompType) => void;
  refetch: () => void;
  setRefetch: (fn: () => void) => void;
};

export const TableContext = createContext<TableContextType>({
  offset: 0,
  limit: 25,
  sortBy: "displayName",
  sortDir: "asc",
  searchQuery: "",
  prevSearchQuery: "",
  compType: {
    [CashCompType.SALARY]: true,
    [CashCompType.COMMISSION]: true,
    [CashCompType.RECURRING_BONUS]: true,
  },
  setOffset: () => {
    // empty default
  },
  setLimit: () => {
    // empty default
  },
  setSortBy: () => {
    // empty default
  },
  setSortDir: () => {
    // empty default
  },
  setSearchQuery: () => {
    // empty default
  },
  handleSort: () => {
    // empty default
  },
  setCompType: () => {
    // empty default
  },
  refetch: () => {
    // empty default
  },
  setRefetch: () => {
    // empty default
  },
});

export const TableContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  // Pagination
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const [compType, setCompType] = useState({
    [CashCompType.SALARY]: true,
    [CashCompType.COMMISSION]: true,
    [CashCompType.RECURRING_BONUS]: true,
  });

  // Sorting
  const [sortBy, setSortBy] = useState<SortBy>(SortByEnum.displayName);
  const [sortDir, setSortDir] = useState<Order>("asc");

  // Filtering
  const [prevSearchQuery, setPrevSearchQuery] = useState("");
  const [searchQuery, handleSearch] = useState("");

  // Refetching
  const [refetch, setRefetch] = useState(() => () => {
    // empty default
  });

  const setSearchQuery = useCallback(
    (searchQuery: string) => {
      setOffset(0);
      handleSearch(searchQuery);
      setPrevSearchQuery(searchQuery);
    },
    [handleSearch, setOffset]
  );

  const handleSort = (newSortBy: SortBy, newSortDir: Order | undefined) => {
    newSortDir ? setSortDir(newSortDir) : setSortDir("asc");
    if (newSortDir) {
      setSortDir(newSortDir);
    } else if (newSortBy === sortBy) {
      setSortDir(sortDir === "asc" ? "desc" : "asc");
    } else {
      setSortDir("asc");
    }
    setSortBy(newSortBy);
    setOffset(0);
  };

  return (
    <TableContext.Provider
      value={{
        offset,
        limit,
        sortBy,
        sortDir,
        searchQuery,
        prevSearchQuery,
        compType,
        setOffset,
        setLimit,
        setSortBy,
        setSortDir,
        setSearchQuery,
        handleSort,
        setCompType,
        refetch,
        setRefetch,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export const useReportsTableContext = (): TableContextType => {
  return useContext(TableContext);
};
