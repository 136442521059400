import { gql } from "@apollo/client";
import { Verb } from "@asmbl/shared/permissions";
import { Button, makeStyles } from "@material-ui/core";
import { useState } from "react";
import {
  AccessControlSubheader_userRecord as User,
  Noun,
} from "../../../__generated__/graphql";
import { AccessBoundary } from "../../AccessBoundary";
import { AssembleTypography } from "../../AssembleTypography";
import { UserInviteModalLoadingBoundary } from "./UserInvite/UserInviteModalLoadingBoundary";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

type Props = { users: User[]; setLatestUserInvitationId: (id: number) => void };

export function AccessControlSubheader({
  users,
  setLatestUserInvitationId,
}: Props): JSX.Element {
  const classes = useStyles();
  const [isUserInviteModalOpen, setIsUserInviteModalOpen] = useState(false);
  const toggleUserInviteModal = () =>
    setIsUserInviteModalOpen((previous) => !previous);

  return (
    <>
      <UserInviteModalLoadingBoundary
        isOpen={isUserInviteModalOpen}
        toggleUserInviteModal={toggleUserInviteModal}
        setLatestUserInvitationId={setLatestUserInvitationId}
      />
      <div className={classes.root}>
        <AssembleTypography variant="productHeader">
          {users.length} employees
        </AssembleTypography>
        <AccessBoundary
          scope="global"
          verb={Verb.Edit}
          noun={Noun.AccessControl}
        >
          <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={toggleUserInviteModal}
          >
            Invite a Collaborator
          </Button>
        </AccessBoundary>
      </div>
    </>
  );
}

AccessControlSubheader.fragments = {
  users: gql`
    fragment AccessControlSubheader_userRecord on User {
      id
    }
  `,
};
