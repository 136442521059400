import { Button, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { GRAY_4, PURPLE_2, RED } from "../../theme";
import { AssembleDialog } from "../AssembleDialog/AssembleDialog";
import { DeleteIcon } from "../AssembleIcons/Brand/DeleteIcon";

const useStyles = makeStyles(() => ({
  deleteButton: {
    color: RED,
    "&:hover": {
      color: RED,
    },
  },
}));

export interface DeleteButtonProps {
  className?: string;
  buttonType: "button" | "icon";
  objectName: string;
  tooltipObjectName?: string;
  size?: "small" | "medium";
  text?: string;
  onDelete: () => unknown;
}

export function DeleteButton({
  className = "",
  buttonType,
  objectName,
  tooltipObjectName,
  size,
  text,
  onDelete,
}: DeleteButtonProps): JSX.Element {
  const classes = useStyles();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteDialogLoading, setIsDeleteDialogLoading] = useState(false);

  const handleDeleteDialogOpen = () => setIsDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => setIsDeleteDialogOpen(false);

  const handleDelete = async () => {
    setIsDeleteDialogLoading(true);
    await onDelete();
    setIsDeleteDialogLoading(false);
    setIsDeleteDialogOpen(false);
  };

  return (
    <>
      {buttonType === "icon" ? (
        <Tooltip
          title={`Delete ${tooltipObjectName ?? objectName}`}
          placement="top"
        >
          <IconButton
            size={size}
            onClick={handleDeleteDialogOpen}
            className={className}
          >
            <DeleteIcon
              width={"24px"}
              height={"24px"}
              color={GRAY_4}
              hoverColor={PURPLE_2}
            />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          className={`${className} ${classes.deleteButton}`}
          size={size}
          onClick={handleDeleteDialogOpen}
        >
          Delete {objectName}
        </Button>
      )}

      {isDeleteDialogOpen && (
        <AssembleDialog
          onCancel={handleDeleteDialogClose}
          onConfirm={handleDelete}
          title={`Are you sure you want to delete this ${objectName}?`}
          text={text ?? ""}
          cancelButtonText="Cancel"
          confirmButtonText="Yes, Delete"
          confirmButtonVariant="negative"
          disabled={isDeleteDialogLoading}
        />
      )}
    </>
  );
}
