import { gql } from "@apollo/client";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from "@material-ui/core";
import clsx from "clsx";
import { AssembleTypography } from "../../../../components/AssembleTypography";
import { CompCycleDisplay } from "../../../../components/CompCycle/types";
import { CompComponentDisplay } from "../../../../models/Budget";
import { GRAY_6, GRAY_8, WHITE } from "../../../../theme";
import {
  Equity_compCycleBudget as CompCycleBudget,
  Equity_compCycleData as CompCycleData,
  Equity_manager as Manager,
  Equity_reports as Report,
  Equity_valuation as Valuation,
} from "../../../../__generated__/graphql";
import { Equity as EquityType } from "../BudgetsVsRequests";
import { ReportingEquityTier } from "./ReportingEquityTier";

type Props = {
  rootBudget: CompCycleBudget | null;
  manager: Manager | null;
  reports: Report[];
  currentValuation: Valuation;
  organizationName: string;
  varianceDisplay: CompCycleDisplay;
  equityDisplay: EquityType;
  compCycleData: CompCycleData;
};

const useStyles = makeStyles(() => ({
  root: {
    "& td, & th": {
      borderBottom: `1px solid ${GRAY_6}`,
      borderRight: `1px solid ${GRAY_6}`,
    },
    paddingBottom: "100px",
  },
  header: {
    backgroundColor: WHITE,
    height: "2rem",
    borderTop: `1px solid ${GRAY_6}`,
  },
  subheader: {
    backgroundColor: GRAY_8,
    height: "2rem",
  },
  groupedHeader: {
    "th&": {
      borderRight: `1px solid ${GRAY_6}`,
    },
  },
  name: {
    width: "20%",
  },
  directAndIndirect: {
    width: "5%",
  },
  totalGrant: {
    width: "70%",
  },
  totalGrantSubheader: {
    width: "23.33%",
  },
  scrollContainer: {
    overflowX: "auto",
    zIndex: 1,
    height: "100%",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  secondHeader: {
    top: "2rem",
    boxShadow: "0px 1px 5px rgba(10, 36, 64, 0.1)",
  },
}));

export function Equity({
  rootBudget,
  manager,
  reports,
  currentValuation,
  organizationName,
  varianceDisplay,
  equityDisplay,
  compCycleData,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.scrollContainer}>
      <Table className={classes.root}>
        {/* Main header */}
        <TableHead className={classes.stickyHeader}>
          <TableCell
            className={clsx(
              classes.header,
              classes.groupedHeader,
              classes.name
            )}
            variant="head"
          >
            <AssembleTypography variant="productTableHeader">
              Name
            </AssembleTypography>
          </TableCell>

          <TableCell
            className={clsx(
              classes.header,
              classes.groupedHeader,
              classes.directAndIndirect
            )}
            variant="head"
          >
            <AssembleTypography variant="productTableHeader">
              Direct
            </AssembleTypography>
          </TableCell>

          <TableCell
            className={clsx(classes.header, classes.directAndIndirect)}
            variant="head"
          >
            <AssembleTypography variant="productTableHeader">
              Indirect
            </AssembleTypography>
          </TableCell>

          <TableCell
            className={clsx(
              classes.header,
              classes.groupedHeader,
              classes.totalGrant
            )}
            colSpan={3}
            variant="head"
            align="left"
          >
            <AssembleTypography variant="productTableHeader">
              {CompComponentDisplay.equity}
            </AssembleTypography>
          </TableCell>
        </TableHead>

        {/* Subheader */}
        <TableHead className={clsx(classes.stickyHeader, classes.secondHeader)}>
          {/* Name & title */}
          <TableCell
            className={clsx(
              classes.subheader,
              classes.groupedHeader,
              classes.name
            )}
            variant="head"
            align="left"
          />

          {/* Direct reports count */}
          <TableCell
            className={clsx(
              classes.subheader,
              classes.groupedHeader,
              classes.directAndIndirect
            )}
            variant="head"
            align="left"
          />

          {/* Indirect reports count */}
          <TableCell
            className={clsx(classes.subheader, classes.directAndIndirect)}
            variant="head"
            align="left"
          />

          {/* Total grant */}
          <TableCell
            className={clsx(
              classes.subheader,
              classes.groupedHeader,
              classes.totalGrantSubheader
            )}
            variant="head"
            align="left"
          >
            <AssembleTypography variant="productTableHeader">
              Budget
            </AssembleTypography>
          </TableCell>
          <TableCell
            className={clsx(
              classes.subheader,
              classes.groupedHeader,
              classes.totalGrantSubheader
            )}
            variant="head"
            align="left"
          >
            <AssembleTypography variant="productTableHeader">
              Request
            </AssembleTypography>
          </TableCell>
          <TableCell
            className={clsx(classes.subheader, classes.totalGrantSubheader)}
            variant="head"
            align="left"
          >
            <AssembleTypography variant="productTableHeader">
              Usage
            </AssembleTypography>
          </TableCell>
        </TableHead>

        <TableBody>
          <ReportingEquityTier
            rootBudget={rootBudget}
            manager={manager}
            reports={reports}
            organizationName={organizationName}
            varianceDisplay={varianceDisplay}
            currentValuation={currentValuation}
            equityDisplay={equityDisplay}
            compCycleData={compCycleData}
            indentation={[]}
          />
        </TableBody>
      </Table>
    </div>
  );
}

Equity.fragments = {
  compCycleBudget: gql`
    ${ReportingEquityTier.fragments.compCycleBudget}
    fragment Equity_compCycleBudget on CompCycleBudget {
      compCycleId
      employeeId
      ...ReportingEquityTier_compCycleBudget
    }
  `,
  manager: gql`
    ${ReportingEquityTier.fragments.manager}
    fragment Equity_manager on Employee {
      id
      ...ReportingEquityTier_manager
    }
  `,
  reports: gql`
    ${ReportingEquityTier.fragments.reports}
    fragment Equity_reports on Employee {
      id
      ...ReportingEquityTier_reports
    }
  `,
  valuation: gql`
    ${ReportingEquityTier.fragments.valuation}
    fragment Equity_valuation on Valuation {
      id
      ...ReportingEquityTier_valuation
    }
  `,
  compCycleData: gql`
    ${ReportingEquityTier.fragments.compCycleData}
    fragment Equity_compCycleData on CompCycle {
      id
      ...ReportingEquityTier_compCycleData
    }
  `,
};
