import { Button, ButtonProps, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { GRAY_5 } from "../../../../../theme";

type Props = {
  label: string;
  additionalClassName?: string;
  onClick?: () => unknown;
  props?: ButtonProps;
};

const useStyles = makeStyles(() => ({
  button: {
    height: "2rem",
    minWidth: "5rem",
    maxWidth: "5rem",
    borderRadius: "4px",
    boxShadow: "0px 1px 3px rgba(10, 36, 64, 0.1)",
    border: `1px solid ${GRAY_5}`,
  },
}));

export const BaseButton = ({
  onClick,
  label,
  props,
  additionalClassName,
}: Props) => {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      className={clsx(classes.button, additionalClassName)}
      {...props}
    >
      {label}
    </Button>
  );
};
