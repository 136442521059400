import { gql } from "@apollo/client";
import { Money } from "@asmbl/shared/money";
import {
  Collapse,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
} from "@material-ui/core";
import clsx from "clsx";
import { memo, useState } from "react";
import { Cell, Row } from "react-table";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { useTrack } from "../../../../analytics";
import { GRAY_6, GRAY_9, theme } from "../../../../theme";
import {
  EquityTableChartRow_equityHoldings as EquityHoldings,
  TotalEquityTable_equityGrant as Grant,
} from "../../../../__generated__/graphql";
import { WireTableCell } from "../../../Table/WireTable/WireTableCell";
import { EquityGrantTimelineChart } from "../EquityGrantTimelineChart";
import { EquityChartVestingSummary } from "./EquityChartVestingSummary";
import { EquityGrantInfoSummary } from "./EquityGrantInfoSummary";
import { C } from "./TotalEquityTable";

const useStyles = makeStyles({
  open: {},
  row: {
    background: GRAY_9,
  },
  chartContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  equityInfoContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  chartCell: {
    paddingTop: 0,
    paddingBottom: 0,
    border: "none",
    width: "100%",
  },
  chartBorder: {
    borderBottom: `2px solid ${GRAY_6}`,
  },
  cell: {
    maxHeight: theme.spacing(7),
  },
  emptyCellHeader: {
    height: "2rem",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  icon: {
    transition: "transform 350ms",
    width: "1rem",
    height: "1rem",
    "$open &": {
      transform: "rotate(-180deg)",
    },
  },
});

type Props = {
  row: Row<Grant>;
  index: number;
  columns: C[];
  sharePrice: Money | undefined;
  valuationCurrency: EquityHoldings["valuationCurrency"];
};

const MemoEquityTableChartRow = memo(function EquityTableChartRow({
  row,
  index,
  columns,
  sharePrice,
  valuationCurrency,
}: Props): JSX.Element {
  const classes = useStyles();
  const { trackEvent } = useTrack();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (!open) {
      trackEvent({
        area: "Employee Portal",
        subArea: "Portal Home",
        object: "Equity Grant Table Row",
        action: "Expanded",
      });
    }
    setOpen(!open);
  };
  const rowKey = row.getRowProps().key;

  return (
    <>
      <TableRow
        data-cy={`total-equity-table-row-${index}`}
        {...row.getRowProps()}
        key={rowKey}
        className={clsx({
          [classes.row]: open,
        })}
      >
        {row.cells.map((cell: Cell<Grant>) => {
          const cellProps = cell.getCellProps();
          const col =
            columns.find((c) => c.id === cell.column.id) ?? columns[0];

          return (
            <WireTableCell
              {...cellProps}
              key={cellProps.key}
              align={col.align}
              className={classes.cell}
            >
              {cell.render("Cell", { sharePrice })}
            </WireTableCell>
          );
        })}
        <TableCell key={`icon-cell-${rowKey}`}>
          <IconButton
            data-cy={`total-equity-table-row-${index}-expand-button`}
            onClick={handleClick}
            className={clsx({
              [classes.open]: open,
            })}
          >
            <ChevronDownIcon inherit className={classes.icon} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow
        className={clsx({
          [classes.row]: open,
        })}
        key={`graph-row-${row.id}}`}
      >
        <TableCell
          colSpan={columns.length + 1}
          className={clsx(classes.chartCell, {
            [classes.chartBorder]: open,
          })}
        >
          <Collapse
            in={open}
            data-cy={`total-equity-table-row-${index}-expanded-content`}
            unmountOnExit
          >
            <div className={classes.equityInfoContainer}>
              <EquityGrantInfoSummary
                equityGrant={row.original}
                sharePrice={sharePrice}
                valuationCurrency={valuationCurrency}
              />
              <div className={classes.chartContainer}>
                <EquityChartVestingSummary
                  equityGrant={row.original}
                  sharePrice={sharePrice}
                />
                <EquityGrantTimelineChart
                  equityGrant={row.original}
                  key={`timeline-chart-${row.id}}`}
                  sharePrice={sharePrice}
                />
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
});

export const EquityTableChartRow = Object.assign(MemoEquityTableChartRow, {
  fragments: {
    equityGrant: gql`
      ${EquityGrantTimelineChart.fragments.equityGrant}
      ${EquityChartVestingSummary.fragments.equityGrant}
      ${EquityGrantInfoSummary.fragments.equityGrant}
      fragment EquityTableChartRow_equityGrant on EquityGrant {
        id
        ...EquityGrantTimelineChart_equityGrant
        ...EquityChartVestingSummary_equityGrant
        ...EquityGrantInfoSummary_equityGrant
      }
    `,
    equityHoldings: gql`
      ${EquityGrantInfoSummary.fragments.equityHoldings}
      fragment EquityTableChartRow_equityHoldings on EquityHoldings {
        id
        ...EquityGrantInfoSummary_equityHoldings
      }
    `,
  },
});
