import { cx } from "@emotion/css";
import { makeStyles } from "@material-ui/core";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  variant?: Variant;
  className?: string;
};

type Variant = "paper" | "default";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: "70rem",
    breakInside: "avoid",
  },
  paper: {
    background: theme.palette.background.paper,
  },
  default: {
    background: theme.palette.background.default,
  },
  inner: {
    width: "100%",
    maxWidth: "70rem",
    margin: "0 auto",
  },
}));

export function CenteredContent({
  children,
  variant = "paper",
  className,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, classes[variant], className)}>
      <div className={classes.inner}>{children}</div>
    </div>
  );
}
