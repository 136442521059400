import { plainDateToLocaleString } from "@asmbl/shared/time";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useIntercom } from "react-use-intercom";
import { useTrack } from "src/analytics";
import { PlusIcon } from "src/components/AssembleIcons/Brand/PlusIcon";
import {
  CompCycleDataChangeHandler,
  CompCyclePhaseData,
} from "src/components/CompCycle/CompCycleWizard/types";
import { useCompCycleOrganizationChartViewsData } from "src/components/CompCycleOrganizationChart/CompCycleOrganizationChartContext";
import { getCalendarStart } from "src/components/CompCycleOrganizationChart/PhaseSelectionButtonBar";
import { GRAY_1, GRAY_6, GRAY_7, PURPLE_1, WHITE } from "src/theme";

const useStyles = makeStyles((theme) => ({
  phaseCard: {
    display: "flex",
    height: theme.spacing(12),
    width: theme.spacing(19),
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(1.5, 2, 2),
    gap: theme.spacing(2),
    flexShrink: 0,
    borderRadius: "8px",
    border: `1px solid ${GRAY_6}`,

    "&:hover": {
      cursor: "pointer",
      border: `1px solid ${PURPLE_1}`,
    },

    "& $addIcon": {
      color: PURPLE_1,
    },

    "&:hover $addIconContainer": {
      backgroundColor: PURPLE_1,
    },

    "&:hover $addIcon": {
      color: WHITE,
    },
  },
  phaseCardHeader: {
    color: GRAY_1,
    display: "flex",
    justifyContent: "space-between",
  },
  phaseAddCard: {
    backgroundColor: GRAY_7,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addIconContainer: {
    display: "flex",
    backgroundColor: WHITE,
    "&:hover": {
      backgroundColor: GRAY_7,
    },
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: theme.spacing(8),
    padding: theme.spacing(0, 1),
    alignItems: "center",
    justifyContent: "center",
  },
  addIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  disabledOuter: { cursor: "not-allowed" },
  disabledInner: { pointerEvents: "none" },
}));

type Props = {
  phases: CompCyclePhaseData[];
  handleChange: CompCycleDataChangeHandler;
};

export function AddPhaseSidebarCard({
  phases,
  handleChange,
}: Props): JSX.Element {
  const classes = useStyles();
  const { trackEvent } = useTrack();
  const intercom = useIntercom();
  const { chartState, setChartState } =
    useCompCycleOrganizationChartViewsData();
  const disabled = chartState === "edit";
  const onClick = () => {
    const currentPhase = phases.at(-1);

    trackEvent({
      object: "Add Phase Card",
      action: "Clicked",
      currentPhaseCount: phases.length,
    });

    intercom.trackEvent("Add Phase Card Clicked", {
      currentPhaseCount: phases.length,
    });

    handleChange("phasesData", [
      ...phases,
      {
        phaseOrder: phases.length + 1,
        startDate: plainDateToLocaleString(
          getCalendarStart(currentPhase?.startDate, new Date())
        ),
        assigneeIds: [],
        layers: [],
      },
    ]);

    setChartState("edit");
  };

  return (
    <div
      className={clsx({ [classes.disabledOuter]: disabled })}
      data-intercom-target="create-phase-card"
    >
      <div
        className={clsx(classes.phaseCard, classes.phaseAddCard, {
          [classes.disabledInner]: disabled,
        })}
        onClick={onClick}
        data-cy="add-phase-card"
      >
        <div className={classes.phaseCardHeader}>
          <div className={classes.addIconContainer}>
            <PlusIcon
              className={classes.addIcon}
              width="48px"
              height="48px"
              inherit
            />
          </div>
        </div>
      </div>
    </div>
  );
}
