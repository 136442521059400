import { gql } from "@apollo/client";
import { contramap } from "@asmbl/shared/sort";
import { CondensedTablePerfRatingCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { XLG_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};
export function CondensedTablePerfRatingCell2({
  row: { original: employee },
}: Props): JSX.Element {
  return (
    <AssembleTruncatedTypography lines={2}>
      {employee.perfRating ?? "-"}
    </AssembleTruncatedTypography>
  );
}

CondensedTablePerfRatingCell2.fragments = {
  participant: gql`
    fragment CondensedTablePerfRatingCell2_participant on CompCycleParticipant {
      perfRating
    }
  `,
};

CondensedTablePerfRatingCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.PERF_RATING
);
CondensedTablePerfRatingCell2.id = ColumnIds.PERF_RATING;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTablePerfRatingCell2,
  Header: CondensedTablePerfRatingCell2.Header,
  id: CondensedTablePerfRatingCell2.id,
  width: XLG_COL_WIDTH,
};
CondensedTablePerfRatingCell2.column = column;
CondensedTablePerfRatingCell2.ordering = () =>
  contramap((e: Participant) => e.perfRating ?? "");
