import { Box, makeStyles } from "@material-ui/core";
import treeConnectorL from "../../assets/svgs/tree-connector/tree-connector-l.svg";
import treeConnectorPipe from "../../assets/svgs/tree-connector/tree-connector-pipe.svg";
import treeConnectorT from "../../assets/svgs/tree-connector/tree-connector-t.svg";
import { ChipCheckbox } from "./ChipCheckbox";

interface Props {
  isCollapsible: boolean;
  collapsed: boolean;
  indentation: { isLastChild: boolean }[];
  selected: boolean;
  indeterminate: boolean;
  label: string;
  category: string;
  hint: JSX.Element;
  onClick: () => unknown;
  onCollapseExpand: () => unknown;
  decorator?: JSX.Element;
}

const useStyles = makeStyles(() => ({
  row: {
    marginBottom: ({
      isLastDisplayedChildOfParent,
    }: {
      isLastDisplayedChildOfParent: boolean;
    }) => (isLastDisplayedChildOfParent ? "20px" : "10px"),
  },
  connector: {
    height: "80px",
    width: "9px",
    display: "inline-block",
    verticalAlign: "middle",
    background: "no-repeat",
    margin: "-10px 0 -10px 10px",
  },

  connectorL: {
    background: `no-repeat url(${treeConnectorL})`,
  },
  connectorPipe: {
    background: `no-repeat url(${treeConnectorPipe})`,
  },
  connectorT: {
    background: `no-repeat url(${treeConnectorT})`,
  },
}));

export function TreeRow({
  isCollapsible,
  collapsed,
  indentation,
  label,
  category,
  hint,
  selected,
  indeterminate,
  onClick,
  onCollapseExpand,
  decorator,
}: Props): JSX.Element {
  const levels = indentation.length;
  const isLastDisplayedChildOfParent =
    levels > 0 &&
    indentation[levels - 1].isLastChild &&
    (!isCollapsible || collapsed);

  const classes = useStyles({ isLastDisplayedChildOfParent });

  return (
    <Box className={classes.row}>
      {indentation.map(({ isLastChild }, i) => {
        let className: string;
        if (i === levels - 1) {
          // Last level of indentation; this connects to the child.
          className = isLastChild ? classes.connectorL : classes.connectorT;
        } else {
          className = isLastChild ? "" : classes.connectorPipe;
        }
        return <div key={i} className={`${classes.connector} ${className}`} />;
      })}
      <ChipCheckbox
        selected={selected}
        indeterminate={indeterminate}
        isCollapsible={isCollapsible}
        collapsed={collapsed}
        onClick={onClick}
        onCollapseExpand={onCollapseExpand}
        label={label}
        category={category}
        hint={hint}
        decorator={decorator}
      />
    </Box>
  );
}
