import { gql } from "@apollo/client";
import { Money, moneyComparator } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { TableBody, TableRow } from "@material-ui/core";
import { CostToMoveDepartmentsTable_departmentCostToMove as DepartmentCostToMove } from "src/__generated__/graphql";
import { useSort } from "src/components/SortableTable";
import {
  WireTable,
  WireTableCell,
  WireTableHead,
  WireTableHeaderCell,
} from "src/components/Table/WireTable";
import { getSimpleCashLabel } from "src/models/Currency";

type Props = {
  byDepartment: DepartmentCostToMove[];
};

type Row = {
  id: number;
  name: string;
  totalSpend: Money;
  costToMove: Money;
  affectedEmployeeCount: number;
  totalEmployeeCount: number;
};

export function CostToMoveDepartmentsTable({
  byDepartment,
}: Props): JSX.Element | null {
  const data: Row[] = byDepartment
    .map((d) => ({
      id: d.department.id,
      name: d.department.name,
      totalSpend: d.costToMoveAnalysis.totalSpend,
      costToMove: d.costToMoveAnalysis.costToMove,
      affectedEmployeeCount: d.costToMoveAnalysis.affectedEmployeeCount,
      totalEmployeeCount: d.costToMoveAnalysis.totalEmployeeCount,
    }))
    .filter((d) => d.affectedEmployeeCount > 0);

  const {
    sortedArray: sortedDepartments,
    order,
    orderBy,
    handleRequestSort,
  } = useSort<Row>(data, "costToMove", "desc", {
    totalSpend: contramap((d: Row) => d.totalSpend, moneyComparator),
    costToMove: contramap((d: Row) => d.costToMove, moneyComparator),
  });

  if (data.length === 0) return null;

  return (
    <WireTable>
      <WireTableHead>
        <WireTableHeaderCell
          handleRequestSort={handleRequestSort}
          cellTitle="Department"
          orderByField="name"
          order={order}
          isSelected={orderBy === "name"}
        />
        <WireTableHeaderCell
          handleRequestSort={handleRequestSort}
          cellTitle="Total Spend"
          orderByField="totalSpend"
          order={order}
          isSelected={orderBy === "totalSpend"}
        />
        <WireTableHeaderCell
          handleRequestSort={handleRequestSort}
          cellTitle="Cost to Bandpoint"
          orderByField="costToMove"
          order={order}
          isSelected={orderBy === "costToMove"}
        />
        <WireTableHeaderCell
          handleRequestSort={handleRequestSort}
          cellTitle="Impacted Employees"
          orderByField="affectedEmployeeCount"
          order={order}
          isSelected={orderBy === "affectedEmployeeCount"}
        />
        <WireTableHeaderCell
          handleRequestSort={handleRequestSort}
          cellTitle="Total Employees"
          orderByField="totalEmployeeCount"
          order={order}
          isSelected={orderBy === "totalEmployeeCount"}
        />
      </WireTableHead>
      <TableBody>
        {sortedDepartments.map((d) => (
          <TableRow key={d.id}>
            <WireTableCell>{d.name}</WireTableCell>
            <WireTableCell align="right">
              {getSimpleCashLabel(d.totalSpend)}
            </WireTableCell>
            <WireTableCell align="right">
              {getSimpleCashLabel(d.costToMove)}
            </WireTableCell>
            <WireTableCell align="right">
              {d.affectedEmployeeCount}
            </WireTableCell>
            <WireTableCell align="right">{d.totalEmployeeCount}</WireTableCell>
          </TableRow>
        ))}
      </TableBody>
    </WireTable>
  );
}

CostToMoveDepartmentsTable.fragments = {
  departmentCostToMove: gql`
    fragment CostToMoveDepartmentsTable_departmentCostToMove on DepartmentCostToMove {
      department {
        id
        name
      }
      costToMoveAnalysis {
        totalSpend
        costToMove
        affectedEmployeeCount
        totalEmployeeCount
      }
    }
  `,
};
