import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { memo } from "react";
import { GREEN_2, RED, WHITE } from "../../theme";
import { AssembleButton } from "../AssembleButton/AssembleButton";

type StyleProps = {
  confirmButtonColor: string;
};

type ConfirmButtonVariant = "positive" | "negative";
type ConfirmButtonProperties = { color: string };

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: "420px",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "space-between",
  },
  confirmButton: ({ confirmButtonColor }: StyleProps) => ({
    backgroundColor: confirmButtonColor,
    border: `1px solid ${confirmButtonColor}`,
    color: WHITE,
    "&:hover": {
      backgroundColor: confirmButtonColor,
      border: `1px solid ${confirmButtonColor}`,
      color: WHITE,
    },
  }),
}));

const BUTTON_MAP: Record<ConfirmButtonVariant, ConfirmButtonProperties> = {
  positive: {
    color: GREEN_2,
  },
  negative: {
    color: RED,
  },
} as const;

type AssembleDialogProps = {
  onCancel: () => unknown;
  onConfirm: () => unknown;
  title: string;
  text: string;
  cancelButtonText: string;
  confirmButtonText: string;
  confirmButtonVariant: ConfirmButtonVariant;
  disabled?: boolean;
};

export const AssembleDialog = memo(function AssembleDialog(
  props: AssembleDialogProps
) {
  const {
    onCancel,
    onConfirm,
    title,
    text,
    cancelButtonText,
    confirmButtonText,
    confirmButtonVariant,
    disabled,
  } = props;
  const variant = BUTTON_MAP[confirmButtonVariant];
  const classes = useStyles({ confirmButtonColor: variant.color });

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      PaperProps={{
        className: classes.dialogPaper,
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <AssembleButton
          onClick={onCancel}
          size="medium"
          variant="outlined"
          label={cancelButtonText}
        />
        <AssembleButton
          className={classes.confirmButton}
          autoFocus
          size="medium"
          variant="success"
          disabled={disabled}
          onClick={onConfirm}
          label={confirmButtonText}
        />
      </DialogActions>
    </Dialog>
  );
});
