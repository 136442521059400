import { mapMaybe } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import { OrgChart } from "d3-org-chart";
import { useState } from "react";
import { useIntercom } from "react-use-intercom";
import { useTrack } from "src/analytics";
import { PhaseModal } from "src/pages/CompCycle/HierarchyConfiguration/PhaseModal";
import { GRAY_6, WHITE } from "src/theme";
import { CompCycleOrganizationChart_employee as Employee } from "src/__generated__/graphql";
import { AssembleButton } from "../AssembleButton/AssembleButton";
import {
  CompCycleDataChangeHandler,
  CompCyclePhaseData,
} from "../CompCycle/CompCycleWizard/types";
import {
  SelectionMode,
  useCompCycleOrganizationChartSelectionData,
  useCompCycleOrganizationChartViewsData,
} from "./CompCycleOrganizationChartContext";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    bottom: theme.spacing(3),
    right: "50%",
    borderRadius: "5px",

    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(1.5),

    border: `1px solid ${GRAY_6}`,
    boxShadow: "0px 8px 16px 0px rgba(10, 36, 64, 0.15)",
    background: WHITE,
    padding: theme.spacing(3),
  },
}));

type ManagerButtonBarProps = {
  handleChange: CompCycleDataChangeHandler;
  phases: CompCyclePhaseData[];
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  handleModalCancel: () => void;
  handleModalClose: () => void;
};

type LayersButtonBarProps = ManagerButtonBarProps & {
  chart: OrgChart<Employee>;
};

type Props = {
  chart: OrgChart<Employee>;
  handleChange: CompCycleDataChangeHandler;
  phases: CompCyclePhaseData[];
};

type ButtonBarModalProps = {
  buttonTitle: string;
  handleModalClose: (date: string, phaseOrder: number) => void;
  handleModalCancel: () => void;
  isModalOpen: boolean;
  currentPhase: CompCyclePhaseData | undefined;
  noSelections: boolean;
  handleModalOpen: () => void;
};

export function PhaseSelectionButtonBar({
  chart,
  handleChange,
  phases,
}: Props): JSX.Element | null {
  const { chartState, setChartState } =
    useCompCycleOrganizationChartViewsData();
  const { selectionMode, clearChartSelections } =
    useCompCycleOrganizationChartSelectionData();
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (chartState === "view") return null;
  const handleModalCancel = () => setIsModalOpen(false);
  const handleModalClose = () => {
    setChartState("view");
    clearChartSelections();
    setIsModalOpen(false);
  };
  return (
    <>
      {selectionMode === SelectionMode.Layers ? (
        <PhaseLayerSelectionButtonBar
          chart={chart}
          handleChange={handleChange}
          phases={phases}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleModalCancel={handleModalCancel}
          handleModalClose={handleModalClose}
        />
      ) : (
        <PhaseManagerSelectionButtonBar
          handleChange={handleChange}
          phases={phases}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleModalCancel={handleModalCancel}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  );
}

function ButtonBarModal({
  buttonTitle,
  isModalOpen,
  currentPhase,
  noSelections,
  handleModalClose,
  handleModalCancel,
  handleModalOpen,
}: ButtonBarModalProps): JSX.Element | null {
  const classes = useStyles();
  const { clearChartSelections } = useCompCycleOrganizationChartSelectionData();
  return (
    <>
      <PhaseModal
        handleModalClose={handleModalClose}
        handleModalCancel={handleModalCancel}
        open={isModalOpen}
        date={currentPhase?.startDate ?? ""}
        currentPhase={currentPhase}
      />

      <div className={classes.root}>
        <AssembleButton
          size="small"
          variant="outlined"
          label="Clear Selection"
          disabled={noSelections}
          onClick={clearChartSelections}
        />

        <AssembleButton
          size="small"
          variant="contained"
          label={buttonTitle}
          disabled={noSelections}
          onClick={handleModalOpen}
          data-intercom-target="button-create-phase-with-layers"
        />
      </div>
    </>
  );
}

function PhaseLayerSelectionButtonBar({
  chart,
  handleChange,
  phases,
  isModalOpen,
  setIsModalOpen,
  handleModalCancel,
  handleModalClose,
}: LayersButtonBarProps): JSX.Element | null {
  const { trackEvent } = useTrack();
  const intercom = useIntercom();

  const { selectedLayers, selectionMode } =
    useCompCycleOrganizationChartSelectionData();

  const currentPhase = phases.at(-1);
  const restPhases = phases.slice(0, -1);

  const onModalClose = (date: string, phaseOrder: number) => {
    const layers = mapMaybe(Object.entries(selectedLayers), ([key, value]) => {
      if (value === true) return parseInt(key);
    });

    const state = chart.getChartState();
    const { allNodes } = state;

    const assigneeIds = mapMaybe([...allNodes], (node) => {
      if (layers.includes(node.depth)) {
        return node.data.id;
      }
    });

    handleChange("phasesData", [
      ...restPhases,
      {
        layers,
        assigneeIds,
        phaseOrder: phaseOrder,
        startDate: date,
      },
    ]);

    handleModalClose();
  };

  const handleModalOpen = () => {
    const selectedLayersArr = mapMaybe(
      Object.entries(selectedLayers),
      ([key, value]) => {
        if (value === true) return parseInt(key);
      }
    );

    const layersSelected = selectedLayersArr.join(", ");

    trackEvent({
      object: "Create Phase Button",
      action: "Clicked",
      selectionMode,
      layersCount: selectedLayersArr.length,
      layersSelected,
      currentPhaseOrder: currentPhase?.phaseOrder,
    });

    intercom.trackEvent("'Create Phase with Selected Layers' Button Clicked", {
      layersCount: selectedLayersArr.length,
      layersSelected,
      currentPhaseOrder: currentPhase?.phaseOrder,
    });

    setIsModalOpen(true);
  };

  const noSelections = Object.values(selectedLayers).every(
    (value) => value === false
  );

  return (
    <ButtonBarModal
      buttonTitle="Create Phase with Selected Layers"
      handleModalCancel={handleModalCancel}
      handleModalClose={onModalClose}
      isModalOpen={isModalOpen}
      currentPhase={currentPhase}
      noSelections={noSelections}
      handleModalOpen={handleModalOpen}
    />
  );
}

function PhaseManagerSelectionButtonBar({
  handleChange,
  phases,
  isModalOpen,
  setIsModalOpen,
  handleModalCancel,
  handleModalClose,
}: ManagerButtonBarProps): JSX.Element | null {
  const { trackEvent } = useTrack();
  const { selectionMode, selectedEmployees } =
    useCompCycleOrganizationChartSelectionData();

  const currentPhase = phases.at(-1);
  const restPhases = phases.slice(0, -1);

  const onModalClose = (date: string, phaseOrder: number) => {
    const assigneeIds = Array.from(selectedEmployees);

    handleChange("phasesData", [
      ...restPhases,
      {
        assigneeIds,
        layers: [],
        phaseOrder: phaseOrder,
        startDate: date,
      },
    ]);

    handleModalClose();
  };

  const handleModalOpen = () => {
    trackEvent({
      object: "Create Phase Button",
      action: "Clicked",
      selectionMode,
      numberOfManagers: selectedEmployees.size,
      currentPhaseOrder: currentPhase?.phaseOrder,
    });

    setIsModalOpen(true);
  };

  const noSelections = selectedEmployees.size === 0;

  return (
    <ButtonBarModal
      buttonTitle="Create Phase with Selected Managers"
      handleModalCancel={handleModalCancel}
      handleModalClose={onModalClose}
      isModalOpen={isModalOpen}
      currentPhase={currentPhase}
      noSelections={noSelections}
      handleModalOpen={handleModalOpen}
    />
  );
}

// If a phase exists, set the next available phase start date to the day after
// otherwise set it to today
export function getCalendarStart(
  currentPhaseDate: GraphQL_DateTime | undefined,
  selectedDate: Date
): Date {
  if (currentPhaseDate === undefined) {
    return selectedDate;
  }

  const nextDay = new Date(currentPhaseDate);
  nextDay.setDate(nextDay.getDate() + 1);
  return new Date(nextDay.getTime() + nextDay.getTimezoneOffset() * 60000);
}
