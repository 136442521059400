import { Typography } from "@material-ui/core";
import { forwardRef, Ref } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  LinkTabProps,
  useStyles,
} from "../../pages/CompCycle/Navigation/NavigationTabBar";

export const LinkTab = forwardRef(function LinkTab(
  { title, route, hidden = false, ...rest }: LinkTabProps,
  ref: Ref<HTMLAnchorElement>
): JSX.Element {
  const classes = useStyles();

  if (hidden) {
    return <></>;
  }

  return (
    <RouterLink ref={ref} to={route} target={undefined} className={classes.tab}>
      <Typography
        component="span"
        display="inline"
        color="textPrimary"
        classes={{
          root: classes.tabHeaderRoot,
        }}
        {...rest}
      >
        {title}
      </Typography>
    </RouterLink>
  );
});
