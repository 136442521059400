import { add, Money, moneyComparator, zero } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { CashBandName } from "src/constants";
import {
  AdjustedCashBandFields,
  AdjustedEquityBandFields,
  BandPlacement,
} from "../__generated__/graphql";
import { bandComparator, Range } from "../utils";
import { AdjustedBandPoint, isBandPointDefined } from "./BandPoint";

export type AdjustedBand = (
  | Omit<AdjustedCashBandFields, "__typename">
  | Omit<AdjustedEquityBandFields, "__typename">
) & {
  bandPoints: AdjustedBandPoint[];
};

export const BandPlacementDisplay: Record<BandPlacement, string> = {
  ABOVE: "Above Band",
  BELOW: "Below Band",
  INSIDE: "Within Band",
};

export function positionBands<B extends { name: string }>(
  position: {
    adjustedCashBands: B[] | null;
    adjustedEquityBands: B[] | null;
  },
  excludedBandNames: Set<string>
): B[] {
  return [
    ...(position.adjustedCashBands ?? []),
    ...(position.adjustedEquityBands ?? []),
  ]
    .filter((band) => !excludedBandNames.has(band.name))
    .sort(bandComparator);
}

/**
 * A TotalCompBand is the result of adding various comp band components. This
 * is calculated on the fly by the client, since Foundations and Insights both
 * allow the user to dynamically enable and disable viewing comp components.
 */
export type TotalCompBand = {
  bandPoints: {
    name: string;
    /**
     * The sum of all the band points, treating undefined values as 0.
     */
    annualCashEquivalent: Money;
    /**
     * The sum of all the band points, returning null if all points are
     * undefined. If any point is defined, remaining undefined values are
     * treated as 0.
     */
    nullableAnnualCashEquivalent: Money | null;
    hourlyCashEquivalent: Money;
    nullableHourlyCashEquivalent: Money | null;
  }[];
};

/**
 * Calculate the total comp band by adding all the band points together by name
 *
 * This assumes that all AdjustedBands provided have been adjusted to the same
 * location and currency.
 */
export function totalCompBand(
  position: {
    adjustedCashBands: AdjustedBand[] | null;
    adjustedEquityBands: AdjustedBand[] | null;
  },
  excludedBandNames: Set<string>
): TotalCompBand {
  const bands = positionBands(position, excludedBandNames);

  const bandPointNames = getBandPointNames(bands);

  return {
    bandPoints: bandPointNames
      .map((name) => {
        const bandPoints = bands.flatMap((band) =>
          band.bandPoints.filter((bandPoint) => bandPoint.name === name)
        );
        const value: Money = bandPoints
          .map((bp) => bp.annualCashEquivalent)
          /* We found a band point name, so there is at least one point.
           * We can safely skip the initial accumulator value. */
          .reduce(add);

        const hourlyValue: Money = bandPoints
          .map((bp) => bp.hourlyCashEquivalent)
          .reduce(add);

        const nullableValue: Money | null = bandPoints.some(isBandPointDefined)
          ? value
          : null;

        const nullableHourlyValue: Money | null = bandPoints.some(
          isBandPointDefined
        )
          ? hourlyValue
          : null;

        return {
          name,
          annualCashEquivalent: value,
          hourlyCashEquivalent: hourlyValue,
          nullableAnnualCashEquivalent: nullableValue,
          nullableHourlyCashEquivalent: nullableHourlyValue,
        };
      })
      .sort(contramap((bp) => bp.annualCashEquivalent, moneyComparator)),
  };
}

export function totalCompMin(band: {
  bandPoints: { annualCashEquivalent: Money }[];
}): Money | undefined {
  return band.bandPoints.at(0)?.annualCashEquivalent;
}
export function totalCompMax(band: {
  bandPoints: { annualCashEquivalent: Money }[];
}): Money | undefined {
  return band.bandPoints.at(-1)?.annualCashEquivalent;
}

export function totalCompMinHourly(band: {
  bandPoints: { hourlyCashEquivalent: Money }[];
}): Money | undefined {
  return band.bandPoints.at(0)?.hourlyCashEquivalent;
}
export function totalCompMaxHourly(band: {
  bandPoints: { hourlyCashEquivalent: Money }[];
}): Money | undefined {
  return band.bandPoints.at(-1)?.hourlyCashEquivalent;
}

/**
 * computeSeries is used to generate the horizontal axis for the Ladder Detail,
 * and similar on Insights. It ensures that there is at least a minimum visible
 * range on the chart, even if the band is very small.
 */
export function computeSeries(
  totalCompBand: TotalCompBand,
  totalCompSegment: number,
  hourly = false
): number[] {
  const getCompMin = hourly ? totalCompMinHourly : totalCompMin;
  const getCompMax = hourly ? totalCompMaxHourly : totalCompMax;

  const min = getCompMin(totalCompBand)?.value ?? 0;
  const max = getCompMax(totalCompBand)?.value ?? 0;
  const totalCompDiff = max - min;

  const isPointTooSmall = max > 0 && totalCompDiff < totalCompSegment;
  return isPointTooSmall
    ? [min - totalCompSegment / 2, max + totalCompSegment / 2]
    : [min, max];
}

/**
 * PointGroups transposes a list of bands into a list of band points. Whereas
 * bands are accessed by name first, then by the points within the band, point
 * groups start with the band points (e.g. Min, Mid, Max) and then each point
 * contains each comp component value for that point.
 *
 * This is typically used in the PositionDetail page, where we show a full
 * comparison of the band points for the band.
 */
export type PointGroup<BP> = {
  name: string;
  bandPoints: BP[];
};

export function pointGroups<BP extends { name: string }>(
  position: {
    adjustedCashBands: { name: string; bandPoints: BP[] }[] | null;
    adjustedEquityBands: { name: string; bandPoints: BP[] }[] | null;
  },
  excludedBandNames: Set<string>
): PointGroup<BP>[] {
  const bands = positionBands(position, excludedBandNames);

  const bandPointNames = [
    ...new Set(
      bands
        .flatMap<BP>((band) => band.bandPoints)
        .map((bandPoint) => bandPoint.name)
    ),
  ];

  return bandPointNames.map((name) => {
    const bandPoints = bands.flatMap((band) =>
      band.bandPoints.filter((bandPoint) => bandPoint.name === name)
    );

    return {
      name,
      bandPoints,
    };
  });
}

export function getBandPointNames(bands: AdjustedBand[] | undefined): string[] {
  return bands
    ? [
        ...new Set(
          bands
            .flatMap<AdjustedBandPoint>((band) => band.bandPoints)
            .map((bandPoint) => bandPoint.name)
        ),
      ]
    : [];
}

/*
This is used in the AdjustableCompSlider and serves the same purpose
 as calling Band's computeSeries(totalCompBand(), totalCompSegment)
 functions like in the y-series of the BandVisualization component,
 but focuses the functions on the bandpoints and range to
 avoid passing down additional params to the NewCompSlider
*/
export const calculateBandPosition = (
  band: AdjustedBandPoint[],
  compRange: Range
): {
  bandMin: number;
  bandMax: number;
  barWidth: number;
  xOffset: number;
  totalSegment: number;
} => {
  const bandMin = totalCompMin({ bandPoints: band })?.value ?? 0;
  const bandMax = totalCompMax({ bandPoints: band })?.value ?? 0;

  const totalCompDiff = bandMax - bandMin;
  const totalSegment = (compRange.max - compRange.min) / 100;

  const isPointTooSmall = bandMax > 0 && totalCompDiff < totalSegment;
  const [adjustedMin, adjustedMax] = isPointTooSmall
    ? [bandMin - totalSegment / 2, bandMax + totalSegment / 2]
    : [bandMin, bandMax];

  const bandSegment = (adjustedMax - adjustedMin) / 100;
  const barWidth = (bandSegment / totalSegment) * 100;
  const xOffset = (adjustedMin - compRange.min) / totalSegment;
  return { barWidth, xOffset, bandMin, bandMax, totalSegment };
};

/**
 * Calculate the total equity band by adding all the band points together
 * by name using the totalGrossValue field instead of annualCashEquivalent
 *
 * This assumes that all AdjustedEquityBandFields provided have been adjusted to the same
 * location and currency.
 */
export function totalEquityBand(position: {
  adjustedEquityBands: AdjustedEquityBandFields[] | null;
}): TotalCompBand {
  const bands = position.adjustedEquityBands ?? [];

  const bandPointNames = getBandPointNames(bands);

  return {
    bandPoints: bandPointNames
      .map((name) => {
        const bandPoints = bands.flatMap((band) =>
          band.bandPoints.filter((bandPoint) => bandPoint.name === name)
        );
        const value: Money = bandPoints
          .map((bp) => bp.totalGrossValue)
          /* We found a band point name, so there is at least one point.
           * We can safely skip the initial accumulator value. */
          .reduce(add);

        const nullableValue: Money | null = bandPoints.some(isBandPointDefined)
          ? value
          : null;

        return {
          name,
          annualCashEquivalent: value,
          nullableAnnualCashEquivalent: nullableValue,
          hourlyCashEquivalent: zero(value.currency),
          nullableHourlyCashEquivalent: null,
        };
      })
      .sort(contramap((bp) => bp.annualCashEquivalent, moneyComparator)),
  };
}

export function getSalaryBand<B extends { name: string }>(
  bands: B[] | null | undefined
) {
  if (!bands) return null;
  return bands.find((b) => b.name === CashBandName.SALARY);
}
