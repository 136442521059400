import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const OffersIcon = ({
  height = "24px",
  width = "24px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Offers"
      viewBox="0 0 32 32"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M14.5 25C20.299 25 25 20.299 25 14.5C25 8.70101 20.299 4 14.5 4C8.70101 4 4 8.70101 4 14.5C4 20.299 8.70101 25 14.5 25Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M21.9 21.9L28 28"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M14.4 15.8C16.2225 15.8 17.7 14.3225 17.7 12.5C17.7 10.6775 16.2225 9.2 14.4 9.2C12.5774 9.2 11.1 10.6775 11.1 12.5C11.1 14.3225 12.5774 15.8 14.4 15.8Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M9.09998 19C10.6 16.1 14.2 14.9 17.1 16.4C18.2 17 19.1 17.9 19.7 19"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
