import { FlatfileButton } from "@flatfile/react";
import { FlatfileButtonProps } from "@flatfile/react/dist/components/FlatFileButton";
import { flatFileKey } from "src/env";
import { useAuth } from "./Auth/AuthContext";

export function AssembleFlatfileButton(
  props: Omit<FlatfileButtonProps, "licenseKey" | "customer">
) {
  const { organization } = useAuth();
  if (organization == null) return null;

  return (
    <FlatfileButton
      {...props}
      licenseKey={flatFileKey()}
      customer={{ userId: organization.id.toString() }}
      onData={async (results) => {
        if (typeof props.onData === "function") {
          await props.onData(results);
        }
        document.body.classList.remove("flatfile-active");
      }}
      onCancel={() => {
        if (typeof props.onCancel === "function") {
          props.onCancel();
        }
        document.body.classList.remove("flatfile-active");
      }}
    />
  );
}
