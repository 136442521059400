export enum CorrectionCostToMoveFilterName {
  MANAGER = "Manager",
  REPORTS = "Reports",
  CURRENT_DEPARTMENT = "Current Department",
  CURRENT_LADDER = "Current Ladder",
  CURRENT_LEVEL = "Current Level",
  LOCATION_GROUP = "Location Group",
  CURRENT_BAND_POSITION = "Current Band Position",
}

export enum CorrectionCostToMoveFilterParam {
  MANAGER = "managerIds",
  REPORTS = "reportIds",
  CURRENT_DEPARTMENT = "departmentIds",
  CURRENT_LADDER = "ladderIds",
  CURRENT_LEVEL = "levels",
  LOCATION_GROUP = "locationGroupIds",
  CURRENT_BAND_POSITION = "currentBandPosition",
}

export type FilterOptionsType = {
  [CorrectionCostToMoveFilterParam.MANAGER]: {
    id: number;
    displayName: string;
  }[];
  [CorrectionCostToMoveFilterParam.CURRENT_DEPARTMENT]: {
    name: string;
    id: number;
  }[];
  [CorrectionCostToMoveFilterParam.CURRENT_LADDER]: {
    name: string;
    id: number;
  }[];
  [CorrectionCostToMoveFilterParam.CURRENT_LEVEL]: number[];
  [CorrectionCostToMoveFilterParam.LOCATION_GROUP]: {
    name: string;
    id: number;
  }[];
  [CorrectionCostToMoveFilterParam.REPORTS]: [];
  [CorrectionCostToMoveFilterParam.CURRENT_BAND_POSITION]: [];
};
