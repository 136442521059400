import { gql } from "@apollo/client";
import { getTenure } from "@asmbl/shared/employee";
import { Money } from "@asmbl/shared/money";
import { byDate, byDescendingDate, contramap } from "@asmbl/shared/sort";
import { mapMaybe } from "@asmbl/shared/utils";
import { TableRow, makeStyles } from "@material-ui/core";
import { useMemo, useState } from "react";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { ChevronUpIcon } from "src/components/AssembleIcons/Brand/ChevronUpIcon";
import { GRAY_6 } from "src/theme";
import { LevelingErrorsTable_employee as Employee } from "../../../__generated__/graphql";
import Warning from "../../../assets/svgs/warning-duotone-red.svg?react";
import { useCurrencies } from "../../../components/CurrenciesContext";
import { EmptyTableView } from "../../../components/EmptyTableView";
import {
  DoubleSortableTableHeaderCell,
  useSort,
} from "../../../components/SortableTable";
import { TotalCashCell } from "../../../components/Table/TotalCashCell";
import { WireTable } from "../../../components/Table/WireTable/WireTable";
import { WireTableCell } from "../../../components/Table/WireTable/WireTableCell";
import { WireTableHead } from "../../../components/Table/WireTable/WireTableHead";
import { WireTableHeaderCell } from "../../../components/Table/WireTable/WireTableHeaderCell";
import { columnWidthGenerator } from "../../../components/Table/constants";
import { TotalCash, getTotalCash } from "../../../models/CashCompensation";
import { getExchangedSalary } from "../../../models/Employment";
import { ArrayValue } from "../../../utils";
import { LevelingIssueRow } from "./LevelingIssueRow";

type Props = {
  employees: Employee[];
};

type CashCompensation = ArrayValue<Employee["activeCashCompensation"]>;
type Manager = Employee["manager"];
type Position = { name: string; level: number };

export type EmployeeRow = {
  employee: Employee;
  name: string;
  jobTitle: string | undefined | null;
  previousJobTitle: string | undefined | null;
  currentPosition: Position | undefined;
  previousPosition: Position | undefined;
  tenure: number | undefined;
  locationName: string | undefined;
  manager: Manager | undefined;
  totalCash: TotalCash<CashCompensation> | undefined;
  totalCashValue: Money | undefined;
  updatedAt: Date | undefined;
  levelingCode: string | undefined;
  previousLevelingCode: string | undefined;
};

const useStyles = makeStyles((theme) => ({
  // Give the hidden cell consistent padding so it doesn't jump around when we
  // open/close tabs
  hiddenCell: { height: "2rem", padding: "0 2rem" },
  nameCell: {
    paddingLeft: theme.spacing(1),
    borderRight: `1px solid ${GRAY_6}`,
  },
  tab: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1rem",
    cursor: "pointer",
  },
}));

export function LevelingErrorsTable({ employees }: Props): JSX.Element {
  const classes = useStyles();
  const { currencies, defaultCurrency } = useCurrencies();
  const [open, setOpen] = useState(true);

  const width = columnWidthGenerator(12);

  const emps: EmployeeRow[] = useMemo(
    () =>
      mapMaybe(employees, (e) => {
        const { activeEmployment } = e;
        if (activeEmployment === null) return;

        const totalCash = getTotalCash(e.activeCashCompensation);

        const previousLevel = e.employments
          // Filter out the active employment (and any future ones)
          .filter(
            (e) => new Date(e.activeAt) < new Date(activeEmployment.activeAt)
          )
          .sort(byDescendingDate((x) => x.activeAt))
          .at(0);

        return {
          employee: e,
          name: e.displayName,
          jobTitle: activeEmployment.jobTitle,
          previousJobTitle: previousLevel?.jobTitle,
          currentPosition: activeEmployment.position ?? undefined,
          previousPosition: previousLevel?.position ?? undefined,
          locationName: e.location?.name,
          tenure: getTenure(e),
          manager: e.manager ?? undefined,
          totalCash,
          totalCashValue:
            totalCash !== undefined
              ? getExchangedSalary(
                  defaultCurrency,
                  { salary: totalCash.annualTotal },
                  currencies
                )
              : undefined,
          levelingCode: activeEmployment.levelingCode ?? undefined,
          previousLevelingCode: previousLevel?.levelingCode ?? undefined,
          updatedAt: e.activeAt === null ? undefined : new Date(e.activeAt),
        };
      }),
    [currencies, employees, defaultCurrency]
  );

  const {
    sortedArray: sortedEmps,
    order,
    orderBy,
    handleRequestSort,
  } = useSort<EmployeeRow, "positionName" | "level">(emps, "name", "asc", {
    name: contramap((r) => r.name),
    jobTitle: contramap((r) => r.jobTitle ?? "-"),
    positionName: contramap((r) => r.currentPosition?.name ?? "-"),
    level: contramap((r) => r.currentPosition?.level ?? 0),
    tenure: contramap((r) => r.tenure ?? 0),
    locationName: contramap((r) => r.locationName ?? "-"),
    manager: contramap((r) => r.manager?.displayName ?? "-"),
    totalCashValue: contramap((r) => r.totalCashValue?.value ?? 0),
    updatedAt: byDate((r) => r.updatedAt ?? "0"),
    levelingCode: contramap((r) => r.levelingCode ?? "-"),
  });

  if (sortedEmps.length === 0) {
    return <EmptyTableView message="No employees with leveling code issues" />;
  }

  return (
    <WireTable>
      <WireTableHead>
        <TableRow>
          <WireTableCell className={classes.hiddenCell} />
          <DoubleSortableTableHeaderCell
            titleA="Name"
            titleB="Position"
            orderByFieldA="name"
            orderByFieldB="positionName"
            order={order}
            isSelectedA={orderBy === "name"}
            isSelectedB={orderBy === "positionName"}
            handleRequestSort={handleRequestSort}
            aria-label="Name / Position"
            align="left"
            width={width(2)}
            className={classes.nameCell}
          />
          <WireTableHeaderCell
            cellTitle="Job Title"
            order={order}
            isSelected={orderBy === "jobTitle"}
            orderByField="jobTitle"
            handleRequestSort={handleRequestSort}
            width={width(2)}
          />
          <WireTableHeaderCell
            cellTitle="Lvl"
            order={order}
            isSelected={orderBy === "level"}
            orderByField="level"
            handleRequestSort={handleRequestSort}
            width={width(0.5)}
          />
          <WireTableHeaderCell
            cellTitle="Tenure"
            order={order}
            isSelected={orderBy === "tenure"}
            orderByField="tenure"
            handleRequestSort={handleRequestSort}
            width={width(1)}
          />
          <WireTableHeaderCell
            cellTitle="Location"
            order={order}
            isSelected={orderBy === "locationName"}
            orderByField="locationName"
            handleRequestSort={handleRequestSort}
            width={width(1)}
          />
          <WireTableHeaderCell
            cellTitle="Mgr"
            order={order}
            isSelected={orderBy === "manager"}
            orderByField="manager"
            handleRequestSort={handleRequestSort}
            width={width(0.75)}
            align="center"
          />
          <WireTableHeaderCell
            cellTitle="Total Cash"
            order={order}
            isSelected={orderBy === "totalCashValue"}
            orderByField="totalCashValue"
            handleRequestSort={handleRequestSort}
            width={width(2)}
            align="center"
          />
          <WireTableHeaderCell
            cellTitle="Leveling Code"
            order={order}
            isSelected={orderBy === "levelingCode"}
            orderByField="levelingCode"
            handleRequestSort={handleRequestSort}
            width={width(2.75)}
          />
        </TableRow>
      </WireTableHead>
      <TableRow>
        <WireTableCell colSpan={10} onClick={() => setOpen((prev) => !prev)}>
          <div className={classes.tab}>
            {open ? <ChevronDownIcon /> : <ChevronUpIcon />}
            Invalid Leveling Codes ({sortedEmps.length})
            <Warning />
          </div>
        </WireTableCell>
      </TableRow>
      {open &&
        sortedEmps.map((e, i) => <LevelingIssueRow employee={e} key={i} />)}
    </WireTable>
  );
}

LevelingErrorsTable.fragments = {
  employee: gql`
    ${TotalCashCell.fragments.cash}
    fragment LevelingErrorsTable_employee on Employee2 {
      id
      displayName
      activeAt
      user {
        id
        photoURL
      }
      employments {
        id
        jobTitle
        activeAt
        levelingCode
        levelingMethod
        position {
          id
          name
          level
        }
      }
      activeEmployment {
        id
        jobTitle
        activeAt
        levelingCode
        levelingMethod
        payPeriod
        position {
          id
          name
          level
        }
      }
      location {
        id
        name
      }
      manager: minimalManager {
        id
        displayName
        user {
          id
          photoURL
        }
      }
      activeCashCompensation {
        activeAt
        employeeId
        type
        annualCashEquivalent
        hourlyCashEquivalent
        unit
        percentOfSalary
        ...TotalCashCell_cash
      }
    }
  `,
};
