import { gql } from "@apollo/client";
import { CurrencyCode, DEFAULT_USD } from "@asmbl/shared/constants";
import { isEmptyString } from "@asmbl/shared/utils";
import {
  ScalarDictionaryWithCustom as FlatFileRecord,
  IDataHookResponse,
} from "@flatfile/react";
import { Button } from "@material-ui/core";
import { AssembleFlatfileButton } from "src/components/AssembleFlatfileButton";
import { EmployeeIdentifierFields } from "src/models/csv/Fields/EmployeeIdentifierFields";
import { EquityImportButton_employee as Employee } from "../../../__generated__/graphql";
import { useTrack } from "../../../analytics";
import { DATE_REGEX } from "../../../constants";
import { matchEmployee } from "../../../models/csv/EmployeeCSV";

export type EmployeeEquityGrantRow = {
  employeeId: number;
  grantName: string;
  equityType: string;
  unitCount: string;
  price: string;
  priceCurrencyCode: string;
  issueDate: string;
  vestingStartDate: string;
  vestingScheduleDescription: string;
  awardType: string;
};

interface Props {
  employees: Employee[];
  handleChange: (data: Array<EmployeeEquityGrantRow>) => unknown;
  handleCancel?: () => unknown;
  lastUploadDate?: GraphQL_DateTime | null | undefined;
  isDialogButton?: boolean;
}

export function EquityImportButton({
  employees,
  handleChange,
  handleCancel,
  lastUploadDate,
  isDialogButton = false,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const validateRecord = recordValidator(employees);

  return (
    <AssembleFlatfileButton
      settings={{
        title: "Import your employees' equity",
        type: "EmployeeEquityRow",
        fields: [
          ...EmployeeIdentifierFields({ allowDuplicates: true }),
          {
            label: "Grant Name",
            key: "grantName",
            validators: [{ validate: "required" }, { validate: "unique" }],
            alternates: ["Certificate Number"],
          },
          {
            label: "Award Type",
            key: "awardType",
          },
          {
            label: "Unit Count",
            key: "unitCount",
            validators: [
              { validate: "required" },
              {
                validate: "regex_matches",
                regex: "^(\\d+|\\d{1,3}(,\\d{3})*)?$",
                error: "Input must be a number",
              },
            ],
            alternates: ["Units Issued"],
          },
          {
            label: "Price",
            key: "price",
            validators: [
              { validate: "required" },
              {
                validate: "regex_matches",
                regex: "[0-9]",
                error: "Input must be a number or decimal",
              },
            ],
            alternates: ["Price per share"],
          },
          {
            label: "Price Currency Code",
            key: "priceCurrencyCode",
            type: "select",
            validators: [{ validate: "required" }],
            alternates: ["Equity grant currency code"],
            options: Object.values(CurrencyCode).map((currencyCode) => ({
              value: currencyCode,
              label: currencyCode,
            })),
          },
          {
            label: "Issue Date",
            key: "issueDate",
            validators: [
              { validate: "required" },
              {
                validate: "regex_matches",
                regex: DATE_REGEX.source,
                error: "Must be in format YYYY-MM-DD or MM/DD/YYYY",
              },
            ],
          },
          {
            label: "Vesting Start Date",
            key: "vestingStartDate",
            validators: [
              { validate: "required" },
              {
                validate: "regex_matches",
                regex: DATE_REGEX.source,
                error: "Must be in format YYYY-MM-DD or MM/DD/YYYY",
              },
            ],
            alternates: ["Vesting Commencement Date"],
          },

          {
            label: "Vesting Schedule",
            key: "vestingScheduleDescription",
            validators: [{ validate: "required" }],
          },
        ],
        managed: true,
      }}
      onRecordInit={validateRecord}
      onRecordChange={validateRecord}
      onData={(results: { validData: EmployeeEquityGrantRow[] }) => {
        try {
          handleChange(results.validData);
        } catch (e) {
          if (e instanceof Error) {
            return Promise.resolve(
              `An error occurred: ${e.message}. Please try again.`
            );
          }
        }
        return Promise.resolve(
          `Uploaded ${results.validData.length} employee equity grants.`
        );
      }}
      onCancel={handleCancel}
      render={(_, launch) => (
        <Button
          onClick={() => {
            trackEvent({
              area: isDialogButton ? "Data Management" : "Employee Portal",
              subArea: !isDialogButton ? "Portal Access" : undefined,
              object: "Import Employee Grants Button",
              action: "Clicked",
              uploadType: lastUploadDate == null ? "Import" : "Update",
            });
            launch();
          }}
          variant="contained"
          color={lastUploadDate == null ? "primary" : undefined}
        >
          {lastUploadDate == null
            ? "Import Employee Equity"
            : "Update Employee Equity"}
        </Button>
      )}
    />
  );
}

// Combine all of our validators together into a single response object.
// Beware: validators can overwrite each other, so try to keep fields separate!
function recordValidator(employees: Employee[]) {
  return function validateRecord(record: FlatFileRecord): IDataHookResponse {
    const [, employeeMatchingInfo] = matchEmployee(record, employees);

    const priceCurrencyCodeValue = (
      isEmptyString(record["priceCurrencyCode"] as string | undefined | null)
        ? DEFAULT_USD.code
        : record["priceCurrencyCode"]
    ) as string;

    return {
      ...employeeMatchingInfo,
      priceCurrencyCode: {
        value: priceCurrencyCodeValue,
      },
    };
  };
}

EquityImportButton.fragments = {
  employee: gql`
    fragment EquityImportButton_employee on Employee {
      id
      employeeNumber
      email
      personalEmail
    }
  `,
};
