import { useQuery } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { PreviewFeature } from "src/components/FeatureContext";
import {
  GetCompStructure,
  GetOrganization,
  GetValuation,
  Noun,
} from "../../__generated__/graphql";
import { useAuth } from "../../components/Auth/AuthContext";
import { NoCompStructure } from "../../components/EmptyState/EmptyState";
import Loading from "../../components/Settings/Loading";
import {
  COMP_STRUCTURE_QUERY,
  GET_ORGANIZATION,
  GET_VALUATION,
} from "../../queries";
import CompensationComponents from "./Compensation/CompensationComponents";
import HourlyEmployees from "./Compensation/HourlyEmployees";
import { PageContainer } from "./PageContainer";

export function Compensation(): JSX.Element {
  const { permissions } = useAuth();

  const { data: compStructureData, loading: compStructureLoading } =
    useQuery<GetCompStructure>(COMP_STRUCTURE_QUERY);

  const { data: organizationData, loading: orgLoading } =
    useQuery<GetOrganization>(GET_ORGANIZATION);

  const { data: valuationData, loading: valLoading } =
    useQuery<GetValuation>(GET_VALUATION);

  if (compStructureLoading || orgLoading || valLoading) return <Loading />;
  if (!compStructureData || !organizationData) return <p>Not found</p>;

  return (
    <PageContainer
      header={`${organizationData.organization.name}'s Compensation Settings`}
      description="Specify all the different compensation bands used by your company."
      centerContent
    >
      {compStructureData.compStructure ? (
        <>
          <CompensationComponents
            compStructure={compStructureData.compStructure}
            useValuation={valuationData?.valuation != null}
            disabled={!permissions.canEditAny(Noun.CompStructure)}
          />
          <PreviewFeature flag={FeatureFlag.HourlyEmployees}>
            <HourlyEmployees
              compStructure={compStructureData.compStructure}
              disabled={!permissions.canEditAny(Noun.CompStructure)}
            />
          </PreviewFeature>
        </>
      ) : (
        <NoCompStructure />
      )}
    </PageContainer>
  );
}
