import { CashBandName, CurrencyCode } from "@asmbl/shared/constants";
import { Currency } from "@asmbl/shared/currency";
import {
  cashToPercentOfSalary,
  Money,
  percentageOfSalaryToCash,
  zero,
} from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { Box } from "@material-ui/core";
import { BandUnit, PositionType } from "../../../__generated__/graphql";
import { getSimpleCashLabel } from "../../../models/Currency";
import { OfferDataCash } from "../../../models/Offer";
import { GRAY_3, theme } from "../../../theme";
import { bandNameComparator } from "../../../utils";
import { AssembleTypography } from "../../AssembleTypography";

type Props = {
  localCurrency: Currency;
  annualOrHourlySalary: Money | undefined;
  annualSalary: Money | undefined;
  salaryCompaRatio: number | null;
  cashBandTypes: CashBandName[];
  cash: OfferDataCash;
  positionType: PositionType | undefined;
};

export function CashSection({
  localCurrency,
  annualOrHourlySalary,
  annualSalary,
  salaryCompaRatio,
  cashBandTypes,
  cash,
  positionType,
}: Props): JSX.Element {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gridRowGap={theme.spacing(3)}
    >
      <Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <AssembleTypography variant="productEyebrowSmall" textColor={GRAY_3}>
            SALARY
          </AssembleTypography>
          {salaryCompaRatio !== null && (
            <AssembleTypography
              variant="productEyebrowSmall"
              textColor={GRAY_3}
            >
              COMPA-RATIO
            </AssembleTypography>
          )}
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          {positionType === PositionType.ANNUAL ? (
            <AssembleTypography variant="productParagraphMedium">
              {getSimpleCashLabel(
                annualOrHourlySalary ?? zero(localCurrency.code)
              )}
            </AssembleTypography>
          ) : (
            <AssembleTypography variant="productParagraphMedium">
              {getSimpleCashLabel(
                annualOrHourlySalary ?? zero(localCurrency.code)
              )}
              /hr
              <br />
              {getSimpleCashLabel(annualSalary ?? zero(localCurrency.code))}/yr*
            </AssembleTypography>
          )}

          {salaryCompaRatio !== null && (
            <AssembleTypography variant="productMicrocopy">
              {(salaryCompaRatio * 100).toFixed(0)}%
            </AssembleTypography>
          )}
        </Box>
      </Box>

      {cashBandTypes
        .slice()
        .sort(bandNameComparator)
        .filter((bandName) => bandName !== CashBandName.SALARY)
        .map((bandName) => {
          const currentDataPoint:
            | { mode: BandUnit.CASH; value: Money | undefined }
            | { mode: BandUnit.PERCENTAGE; value: number | undefined }
            | undefined = cash[bandName];

          const { cashValue, percentValue } = getCashAndPercentageValues(
            annualSalary,
            localCurrency.code,
            currentDataPoint
          );

          return (
            <Box key={bandName}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <AssembleTypography
                  variant="productEyebrowSmall"
                  textColor={GRAY_3}
                >
                  {bandName}
                </AssembleTypography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <AssembleTypography variant="productParagraphMedium">
                  {getSimpleCashLabel(cashValue)}
                </AssembleTypography>

                {(bandName === CashBandName.COMMISSION ||
                  bandName === CashBandName.SPOT_BONUS ||
                  bandName === CashBandName.RECURRING_BONUS) &&
                  percentValue !== "N/A" && (
                    <AssembleTypography variant="productMicrocopy">
                      {percentValue} of salary
                    </AssembleTypography>
                  )}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
}

function getCashAndPercentageValues(
  salary: Money | undefined,
  currencyCode: CurrencyCode,
  currentDataPoint:
    | { mode: BandUnit.CASH; value: Money | undefined }
    | { mode: BandUnit.PERCENTAGE; value: number | undefined }
    | undefined
): {
  cashValue: Money;
  percentValue: string;
} {
  // if there is an input for the data point, but there is no salary,
  // then we'll return the input value and N/A for the percentage
  if (
    salary?.value === undefined &&
    currentDataPoint?.mode === BandUnit.CASH &&
    currentDataPoint.value !== undefined
  ) {
    return { cashValue: currentDataPoint.value, percentValue: "N/A" };
  }

  if (currentDataPoint?.value === undefined || salary?.value === undefined) {
    return { cashValue: zero(currencyCode), percentValue: "N/A" };
  }

  if (currentDataPoint.mode === BandUnit.CASH) {
    const percentageOfSalary =
      cashToPercentOfSalary(salary, currentDataPoint.value) / 100;

    const percentValue = formatNumeral(percentageOfSalary, {
      style: "percent",
      maximumFractionDigits: 2,
    });

    return { cashValue: currentDataPoint.value, percentValue };
  }
  // currentDataPoint.mode === BandUnit.PERCENTAGE
  const cashValue = percentageOfSalaryToCash(salary, currentDataPoint.value);
  return { cashValue, percentValue: `${currentDataPoint.value}%` };
}
