import {
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useState } from "react";
import { GRAY_4 } from "../../../theme";
import { AssemblePopper } from "../../AssemblePopper";
import { ChangeHandler } from "../types";
import { AtsCandidate } from "./CandidateLoadingBoundary";
import { DropdownOption } from "./DropdownOption";
import { DropdownPaper } from "./DropdownPaper";

/**
 * @param {candidates} If candidates is undefined, we assume the query that
 * provides it is still loading results, and we display a circular progress
 * bar in the autocomplete field.
 */
export type Props = {
  candidates: AtsCandidate[] | undefined;
  handleChange: ChangeHandler;
  setManualEntry: (value: boolean) => void;
};

export function AtsCandidateDropdown({
  candidates,
  handleChange,
  setManualEntry,
}: Props): JSX.Element {
  const [chosenCandidate, setChosenCandidate] = useState<
    AtsCandidate | undefined
  >();

  const selectCandidate = (candidate: AtsCandidate | undefined) => {
    handleChange("firstName", candidate?.firstName);
    handleChange("lastName", candidate?.lastName);
    handleChange("candidateEmail", candidate?.email);
    handleChange("title", candidate?.jobTitle);
    setChosenCandidate(candidate);
  };

  return (
    <Box style={{ width: "100%" }} mb={2}>
      <Autocomplete
        PopperComponent={AssemblePopper}
        options={candidates ?? []}
        getOptionLabel={(candidate) =>
          `${candidate.firstName} ${candidate.lastName}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Candidate"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {!candidates ? (
                    <CircularProgress style={{ color: GRAY_4 }} size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(option) => <DropdownOption candidate={option} />}
        onChange={(_, value) => selectCandidate(value ?? undefined)}
        value={chosenCandidate}
        noOptionsText={
          !candidates ? (
            <Typography>Still loading data from your ATS...</Typography>
          ) : (
            <Typography>
              We couldn't find any candidates from your ATS
            </Typography>
          )
        }
        PaperComponent={(props) => (
          <DropdownPaper {...props} setManualEntry={setManualEntry} />
        )}
      />
    </Box>
  );
}
