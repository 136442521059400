import { caseInsensitiveComparator, contramap } from "@asmbl/shared/sort";
import { cx } from "@emotion/css";
import {
  ButtonBase,
  IconButton,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { useMemo, useState } from "react";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { EditIcon } from "src/components/AssembleIcons/Small/EditIcon";
import { LiveLocationsProvider_market as Market } from "../../../__generated__/graphql";
import { JobStructure } from "../../../models/JobStructure";
import {
  UserPermissions,
  departmentsInScope,
  hasPartialDepartmentsInScope,
} from "../../../models/UserPermissions";
import { useEmplaceCompensationUserAccessGrant } from "../../../mutations/User";
import { useEmplaceCompensationUserInvitationAccessGrantCallback } from "../../../mutations/UserInvitation";
import { GRAY_4, PURPLE_1, PURPLE_2 } from "../../../theme";
import { ClickPropagationBoundary } from "../../ClickPropagationBoundary";
import { WireTableCell } from "../../Table/WireTable/WireTableCell";
import { AccessGrantDrawer } from "./AccessGrantDrawer";
import { JobStructureSelectionRows } from "./JobStructureSelectionRows";
import { NodeLabel } from "./NodeLabel";

const useStyles = makeStyles((theme) => ({
  middleCell: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  row: {
    cursor: "pointer",
    position: "relative",
    "&:hover, &:focus, &:focus-within": {
      "& .MuiTableCell-body": {
        color: PURPLE_1,
        "& $nodeLabel": {
          color: PURPLE_1,
        },
      },

      "& $editIcon": {
        opacity: 1,
      },
    },
  },
  marketCell: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  safariPositionFix: {
    position: "relative",
  },
  showDetailsButton: {
    transition: "transform 0.2s ease-in-out",
    transform: "rotate(-90deg)",
  },
  areDetailsShowing: {
    transform: "rotate(0deg)",
  },
  nodeLabel: {
    display: "inline",
  },
  editIcon: {
    display: "block",
    lineHeight: 1,
    position: "absolute",
    right: theme.spacing(2),
    top: "calc(50% - 12.5px)",
    opacity: 0,
  },
}));

type Props = {
  jobStructure: JobStructure;
  market: Market | null;
  userId: number;
  userPermissions: UserPermissions;
  showDetails: boolean;
  onToggleDetails: () => unknown;
  type: "user" | "user-invite";
  hasAnyNestedData: boolean;
};

export function MarketRow({
  jobStructure,
  market,
  userId,
  userPermissions,
  showDetails,
  onToggleDetails,
  type,
  hasAnyNestedData,
}: Props) {
  const classes = useStyles();

  /* Controls for the AccessGrantDrawer and its mutation */
  const [isOpen, setIsOpen] = useState(false);

  const emplaceCompensationUserAccessGrant =
    useEmplaceCompensationUserAccessGrant(userId);

  const emplaceCompensationUserInvitationAccessGrant =
    useEmplaceCompensationUserInvitationAccessGrantCallback(userId);

  const emplaceCompensationAccessGrant =
    type === "user"
      ? emplaceCompensationUserAccessGrant
      : emplaceCompensationUserInvitationAccessGrant;

  /* Get the Scope and its attributes */
  const scope = userPermissions.cashCompensationScope().view;

  const marketScope =
    market === null
      ? scope?.allMarkets
      : scope?.markets.find((m) => m.marketId === market.id)?.scope;

  const departments = useMemo(
    () => departmentsInScope(marketScope, jobStructure),
    [marketScope, jobStructure]
  );

  const allMarketsScope = scope?.allMarkets;

  const departmentsIncludingInherited = useMemo(
    () =>
      [
        ...new Set([
          ...departments,
          ...departmentsInScope(allMarketsScope, jobStructure),
        ]),
      ].sort(contramap((d) => d.name, caseInsensitiveComparator)),
    [allMarketsScope, departments, jobStructure]
  );

  const isGlobal =
    (marketScope?.global ?? false) || (allMarketsScope?.global ?? false);

  const hasPartialDepartments = useMemo(
    () =>
      hasPartialDepartmentsInScope(marketScope, jobStructure) ||
      hasPartialDepartmentsInScope(allMarketsScope, jobStructure),
    [marketScope, jobStructure, allMarketsScope]
  );

  const hasCompensationAccess =
    userPermissions.hasCompensationAccess() &&
    departmentsIncludingInherited.length > 0;

  const hasNestedData = Boolean(
    (marketScope &&
      (marketScope.global ||
        marketScope.departmentIDs.length > 0 ||
        marketScope.ladderIDs.length > 0 ||
        marketScope.positionIDs.length > 0)) ||
      (allMarketsScope &&
        (allMarketsScope.global ||
          allMarketsScope.departmentIDs.length > 0 ||
          allMarketsScope.ladderIDs.length > 0 ||
          allMarketsScope.positionIDs.length > 0))
  );

  const editIcon = (
    <ClickPropagationBoundary>
      <IconButton
        size="small"
        className={classes.editIcon}
        onClick={() => setIsOpen(true)}
      >
        <EditIcon color={GRAY_4} hoverColor={PURPLE_2} />
      </IconButton>
    </ClickPropagationBoundary>
  );

  return (
    <>
      <AccessGrantDrawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onChange={(value) =>
          emplaceCompensationAccessGrant(market?.id ?? null, value)
        }
        value={
          marketScope ?? {
            global: false,
            departmentIDs: [],
            ladderIDs: [],
            positionIDs: [],
          }
        }
        jobStructure={jobStructure}
      />
      <ButtonBase
        component={TableRow}
        className={classes.row}
        onClick={hasNestedData ? onToggleDetails : undefined}
      >
        <WireTableCell className={classes.middleCell}>
          <div className={classes.marketCell}>
            {hasNestedData ? (
              <ClickPropagationBoundary>
                <IconButton size="small" onClick={onToggleDetails}>
                  <ChevronDownIcon
                    inherit
                    className={cx(classes.showDetailsButton, {
                      [classes.areDetailsShowing]: showDetails,
                    })}
                  />
                </IconButton>
              </ClickPropagationBoundary>
            ) : hasAnyNestedData ? (
              <IconButton />
            ) : null}
            <span>{market?.name ?? "All Markets"}</span>
          </div>
        </WireTableCell>
        {!hasCompensationAccess ? (
          <WireTableCell colSpan={3}>
            <div className={classes.safariPositionFix}>
              No access policies {editIcon}
            </div>
          </WireTableCell>
        ) : (
          <>
            <WireTableCell className={classes.middleCell}>
              <div>
                {isGlobal ? (
                  <NodeLabel isInherited={!marketScope || !marketScope.global}>
                    All Departments
                  </NodeLabel>
                ) : (
                  departmentsIncludingInherited.map((d, i) => (
                    <NodeLabel
                      key={d.name}
                      isInherited={!departments.includes(d)}
                      className={classes.nodeLabel}
                    >
                      {d.name}
                      {i < departmentsIncludingInherited.length - 1 && ", "}
                    </NodeLabel>
                  ))
                )}
              </div>
            </WireTableCell>
            <WireTableCell colSpan={2}>
              <div className={classes.safariPositionFix}>
                {hasPartialDepartments
                  ? "A subset of ladders and positions"
                  : "All ladders and positions"}
                {editIcon}
              </div>
            </WireTableCell>
          </>
        )}
      </ButtonBase>
      {showDetails && (
        <JobStructureSelectionRows
          scope={marketScope}
          inheritedScope={scope?.allMarkets}
          jobStructure={jobStructure}
          spacerColumns={1}
        />
      )}
    </>
  );
}
