import { isValidString } from "@asmbl/shared/utils";
import { captureException } from "@sentry/react";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { serverURL } from "../../../../env";
import { useURLSearchParams } from "../../../../models/URLSearchParams";
import { useUpsertCartaConnectionConfig } from "../../../../mutations/Integrations";
import { useAuth } from "../../../Auth/AuthContext";

export const cartaOauthUrl = (
  organizationId: number | null | undefined,
  userId: number | null
) =>
  `${serverURL()}/oauth/carta?organizationId=${organizationId ?? ""}&userId=${
    userId ?? ""
  }`;

export const useCartaResponse = () => {
  const [, setSearchParams] = useSearchParams();
  const urlSearchParams = useURLSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const error = urlSearchParams.get("error");
  const refreshToken = urlSearchParams.get("refreshToken");
  const { userId, organization } = useAuth();
  const upsertCartaConnectionConfig = useUpsertCartaConnectionConfig();

  const handleCartaResponse = useCallback(async () => {
    if (error === "true") {
      enqueueSnackbar(
        "Something went wrong when connecting your Carta account. Please try again, or get in touch with us.",
        { variant: "error", persist: true }
      );

      captureException(new Error("A Carta integration failed to authorize"), {
        extra: { userId: userId, organizationId: organization?.id },
        tags: { notificationLevel: "slack" },
      });
    } else if (isValidString(refreshToken)) {
      await upsertCartaConnectionConfig(refreshToken);
      enqueueSnackbar(
        "Success! You've synced your Carta account. We'll start processing your data now.",
        { variant: "success" }
      );
    }
    setSearchParams({});
  }, [
    error,
    refreshToken,
    enqueueSnackbar,
    setSearchParams,
    organization,
    userId,
    upsertCartaConnectionConfig,
  ]);

  return handleCartaResponse;
};
