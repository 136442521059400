import { gql, useQuery } from "@apollo/client";
import { LinearProgress } from "@material-ui/core";
import {
  OfferNewLoadingBoundaryGetOfferConfig as GetOfferConfig,
  OfferNewLoadingBoundaryGetOfferConfigVariables as GetOfferConfigVariables,
} from "../../__generated__/graphql";
import { NoOfferSettingsConfigured } from "../EmptyState/EmptyState";
import { OfferNew } from "./OfferNew";

export function OfferNewLoadingBoundary(): JSX.Element {
  const { data, loading } = useQuery<GetOfferConfig, GetOfferConfigVariables>(
    query
  );

  if (loading) return <LinearProgress />;
  if (data?.offerConfig == null) return <NoOfferSettingsConfigured />;

  return (
    <OfferNew
      offerConfig={data.offerConfig}
      valuation={data.valuation}
      compStructure={data.compStructure}
    />
  );
}

const query = gql`
  ${OfferNew.fragments.offerConfig}
  ${OfferNew.fragments.valuation}
  ${OfferNew.fragments.compStructure}
  query OfferNewLoadingBoundaryGetOfferConfig {
    offerConfig {
      id
      ...OfferNewOfferConfig
    }
    valuation {
      id
      ...OfferNewValuation
    }
    compStructure {
      id
      ...OfferNewCompStructure
    }
  }
`;
