import { gql, useQuery } from "@apollo/client";
import {
  CashBandName,
  CurrencyCode,
  EquityBandName,
} from "@asmbl/shared/constants";
import { Currency } from "@asmbl/shared/currency";
import { LinearProgress, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import {
  BandUnit,
  OfferEditGetOffer as GetOffer,
  OfferEditGetOfferVariables as GetOfferVariables,
} from "../../__generated__/graphql";
import { NoOfferSettingsConfigured } from "../../components/EmptyState/EmptyState";
import { BENEFITS_PACKAGE_FIELDS } from "../../fragments";
import { OfferDataCash, OfferDataEquity } from "../../models/Offer";
import { AssembleTypography } from "../AssembleTypography";
import { useCurrencies } from "../CurrenciesContext";
import { OfferEdit } from "./OfferEdit";
import { OfferData } from "./types";

type Offer = GetOffer["offer"];

export function OfferEditLoadingBoundary(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { currencies, defaultCurrency } = useCurrencies();

  const { data, loading } = useQuery<GetOffer, GetOfferVariables>(query, {
    variables: { id: id != null ? parseInt(id) : NaN },
    skip: id == null || isNaN(parseInt(id)),
  });

  if (loading) return <LinearProgress />;
  if (data === undefined) {
    return (
      <Typography variant="h5" color="textSecondary">
        Sorry we couldn't find this offer or an error has occurred.
      </Typography>
    );
  }
  const { offer, offerConfig, valuation, compStructure } = data;

  if (offer.isLegacy) {
    return (
      <AssembleTypography>
        We're sorry, legacy offers cannot be edited.
      </AssembleTypography>
    );
  }

  if (valuation === null || compStructure === null || offerConfig === null) {
    return <NoOfferSettingsConfigured />;
  }
  const offerData = convertOffer(
    offer,
    offerConfig,
    currencies,
    defaultCurrency
  );

  return (
    <OfferEdit
      offerData={offerData}
      valuation={valuation}
      compStructure={compStructure}
    />
  );
}

function convertOffer(
  offer: Offer,
  offerConfig: GetOffer["offerConfig"],
  currencyMap: Map<CurrencyCode, Currency>,
  defaultCurrency: Currency
): OfferData {
  const salary = offer.cash.data.find((d) => d.type === "Salary");
  const code = salary?.money.currency as CurrencyCode;
  const currency = currencyMap.get(code) ?? defaultCurrency;

  const cash: OfferDataCash = {};
  offer.cash.data.forEach(({ type, money, percentOfSalary }) => {
    const band = type as CashBandName;
    switch (band) {
      case CashBandName.SALARY:
      case CashBandName.SIGNING_BONUS:
      case CashBandName.RELOCATION_BONUS_OFFICE:
      case CashBandName.RELOCATION_BONUS_REMOTE:
        cash[band] = { mode: BandUnit.CASH, value: money };
        break;
      case CashBandName.COMMISSION:
      case CashBandName.RECURRING_BONUS:
      case CashBandName.SPOT_BONUS:
        cash[band] =
          percentOfSalary !== null
            ? { mode: BandUnit.PERCENTAGE, value: percentOfSalary }
            : { mode: BandUnit.CASH, value: money };
        break;
    }
  });

  const equity: OfferDataEquity = {};
  offer.equity.data.forEach(({ type, units, money, percentOfSalary }) => {
    equity[type as EquityBandName] =
      percentOfSalary !== null
        ? { mode: BandUnit.PERCENTAGE, value: percentOfSalary }
        : units !== null
          ? { mode: BandUnit.UNITS, value: units }
          : { mode: BandUnit.CASH, value: money ?? undefined };
  });

  const [firstName, lastName] = offer.candidate.candidateName.split(" ");

  return {
    offerId: offer.id,
    candidateId: offer.candidate.id,
    candidateEmail: offer.candidate.candidateEmail,
    welcomeMessage: offer.message ?? undefined,
    closingMessage: offer.closingMessage ?? undefined,
    outcomeDescription: offer.outcomeDescription ?? undefined,
    offeredAt: new Date(offer.offeredAt),
    firstName,
    lastName,
    position: offer.position ?? undefined,
    market: offer.market ?? undefined,
    locationGroup: offer.locationGroup ?? undefined,
    title: offer.title ?? undefined,
    currency,
    benefitsPackage: offer.benefitsPackage,
    managerName: offer.review?.managerName ?? undefined,
    approverName: offer.review?.approverName ?? undefined,
    decisionMakerName: offer.review?.decisionMakerName ?? undefined,
    jobCode: offer.review?.jobCode ?? undefined,
    customFields: offerConfig?.customFields,
    customFieldAnswers: offer.review?.customFieldAnswers.map(
      ({ name, answer }) => ({ name, answer })
    ),
    cash,
    equity,
  };
}

const query = gql`
  ${OfferEdit.fragments.offerConfig}
  ${OfferEdit.fragments.valuation}
  ${OfferEdit.fragments.compStructure}
  ${BENEFITS_PACKAGE_FIELDS}
  query OfferEditGetOffer($id: Int!) {
    offer(id: $id) {
      id
      candidate {
        id
        candidateName
        candidateEmail
      }
      cash {
        data {
          type
          money
          percentOfSalary
        }
      }
      isLegacy
      message
      closingMessage
      outcomeDescription
      offeredAt
      equity {
        data {
          type
          money
          units
          percentOfSalary
        }
      }
      message
      position {
        id
        name
        level
        jobCodes
        type
        ladder {
          id
          name
          department {
            id
            name
          }
        }
      }
      market {
        id
        name
        currencyCode
        locationGroups {
          id
          isSourceComp
          name
          description
        }
      }
      locationGroup {
        id
        isSourceComp
        name
        description
      }
      organization {
        id
        name
      }
      title
      benefitsPackage {
        ...BenefitsPackageFields
      }
      review {
        managerName
        decisionMakerName
        approverName
        jobCode
        customFieldAnswers {
          name
          answer
        }
      }
    }
    compStructure {
      id
      ...OfferEditCompStructure
    }
    valuation {
      id
      ...OfferEditValuation
    }
    offerConfig {
      id
      ...OfferEditOfferConfig
    }
  }
`;
