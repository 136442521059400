import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { TotalEquityTable } from "../../../../components/Portal/Equity/EquityTable/TotalEquityTable";
import { TotalEquityTimeline } from "../../../../components/Portal/Equity/TotalEquityTimeline";
import { getIllustrativeUnitPrice } from "../../../../models/Equity";
import { theme } from "../../../../theme";
import {
  EmployeeEquity_employee as Employee,
  EmployeeEquity_equityHoldings as EquityHoldings,
} from "../../../../__generated__/graphql";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
  },
}));

interface Props {
  equityHoldings: EquityHoldings;
  employee?: Employee;
}

export const EmployeeEquity = ({
  equityHoldings,
  employee,
}: Props): JSX.Element | null => {
  const classes = useStyles();
  const { currencies } = useCurrencies();

  const sharePrice =
    getIllustrativeUnitPrice(
      currencies,
      equityHoldings.grants,
      equityHoldings.valuationCurrency
    ) ?? undefined;

  if (equityHoldings.grants == null || equityHoldings.grants.length === 0)
    return null;

  return (
    <div className={classes.root}>
      <TotalEquityTimeline
        equityHoldings={equityHoldings}
        subject={employee?.displayName}
        sharePrice={sharePrice}
      />
      <TotalEquityTable
        grants={equityHoldings.grants}
        sharePrice={sharePrice}
        valuationCurrency={equityHoldings.valuationCurrency}
      />
    </div>
  );
};

EmployeeEquity.fragments = {
  employee: gql`
    fragment EmployeeEquity_employee on Employee {
      id
      displayName
    }
  `,
  equityHoldings: gql`
    ${TotalEquityTable.fragments.equityHoldings}
    ${TotalEquityTable.fragments.equityGrant}
    ${TotalEquityTimeline.fragments.equityHoldings}
    fragment EmployeeEquity_equityHoldings on EquityHoldings {
      id
      valuationCurrency {
        id
        code
        exchangeRate
      }
      grants {
        id
        ...TotalEquityTable_equityGrant
      }
      ...TotalEquityTable_equityHoldings
      ...TotalEquityTimeline_equityHoldings
    }
  `,
};
