import { gql } from "@apollo/client";
import { cx } from "@emotion/css";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { BenefitsIcon } from "src/components/AssembleIcons/Small/BenefitsIcon";
import { AssembleTypography } from "../../../../components/AssembleTypography";
import { GRAY_2, GRAY_4, PURPLE_2 } from "../../../../theme";
import { LittleGiftButton_employee } from "../../../../__generated__/graphql";
import { AssignBenefitsButton } from "./AssignBenefitsButton";

const useStyles = makeStyles(() => ({
  wrapper: { color: GRAY_4 },
  hasBenefits: { color: GRAY_2 },
}));

type Props = {
  employee: LittleGiftButton_employee;
};

export function LittleGiftButton({ employee }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <AssignBenefitsButton employees={[employee]}>
      {(onClick) => (
        <Tooltip
          placement="top"
          title={
            employee.benefitsPackage ? (
              <AssembleTypography variant="caption">
                Benefits Package: {employee.benefitsPackage.name}
                <br />
                Click to edit
              </AssembleTypography>
            ) : (
              <AssembleTypography variant="caption">
                No Benefits Package. Assign one
                <br />
                to be displayed in Employee Portal
              </AssembleTypography>
            )
          }
        >
          <span
            className={cx(
              classes.wrapper,
              employee.benefitsPackage && classes.hasBenefits
            )}
          >
            <IconButton onClick={onClick} color="inherit">
              <BenefitsIcon hoverColor={PURPLE_2} width="18px" height="18px" />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </AssignBenefitsButton>
  );
}

LittleGiftButton.fragments = {
  employee: gql`
    ${AssignBenefitsButton.fragments.employee}
    fragment LittleGiftButton_employee on Employee {
      ...AssignBenefitsButton_employee
      benefitsPackage {
        id
        name
      }
    }
  `,
};
