import { gql } from "@apollo/client";
import {
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { MakeRecommendationIcon } from "src/components/AssembleIcons/Brand/MakeRecommendationIcon";
import { SupportingManagerBannerLoadingBoundary } from "src/components/CompCycle/CompCycleSingleRecModal/SupportingManagerBannerLoadingBoundary";
import { EditRequestButton2_participant } from "../../../../../__generated__/graphql";
import { CompCycleSingleRecLoadingBoundary } from "../../../../../components/CompCycle/CompCycleSingleRecModal/CompCycleSingleRecLoadingBoundary";
import { GRAY_4, PURPLE_1 } from "../../../../../theme";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    display: "flex",
    padding: theme.spacing(0.25),
  },
  dialogPaper: {
    position: "fixed",
    top: 0,
    padding: 0,
    margin: theme.spacing(5),
    maxWidth: "100vw",
    overflowX: "auto",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    minWidth: "1280px",

    "&:first-child": {
      paddingTop: 0,
    },
  },
}));

type Props = {
  employee: EditRequestButton2_participant;
  isIndirectReport: boolean;
  isPhaseConfigurationPeriod: boolean;
  compCycleId: number;
  disabled?: boolean;
  isEditModalOpen: boolean;
  setIsEditModalOpen: (isOpen: boolean) => void;
  disabledReason?: string;
};

export function EditRequestButton2({
  employee,
  isIndirectReport,
  isPhaseConfigurationPeriod,
  compCycleId,
  disabled = false,
  isEditModalOpen,
  setIsEditModalOpen,
  disabledReason = "Request locked. Check activity log for details.",
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Tooltip
        title={disabled ? disabledReason : "Edit Request"}
        placement="top"
        arrow
        enterDelay={500}
      >
        <span data-intercom-target={"edit-requests"}>
          <IconButton
            id="review-edit-icon"
            disabled={disabled}
            onClick={(event) => {
              // prevent employee row from getting selected
              event.stopPropagation();
              setIsEditModalOpen(true);
            }}
            size="small"
          >
            <MakeRecommendationIcon
              color={disabled ? GRAY_4 : PURPLE_1}
              width="16px"
              height="21px"
            />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        open={isEditModalOpen}
        onClose={(_event, reason) => {
          if (reason !== "backdropClick") {
            setIsEditModalOpen(false);
          }
        }}
        onClick={(e) => e.stopPropagation()}
        PaperProps={{ className: classes.dialogPaper }}
      >
        <>
          <SupportingManagerBannerLoadingBoundary />
          <DialogContent className={classes.dialogContent}>
            <CompCycleSingleRecLoadingBoundary
              compCycleId={compCycleId}
              employeeId={employee.subjectId}
              isIndirectReport={isIndirectReport}
              isPhaseConfigurationPeriod={isPhaseConfigurationPeriod}
              onClose={() => setIsEditModalOpen(false)}
              employeeCurrency={
                employee.subject.activeEmployment?.payCurrencyCode ?? null
              }
            />
          </DialogContent>
        </>
      </Dialog>
    </>
  );
}

EditRequestButton2.fragments = {
  participant: gql`
    fragment EditRequestButton2_participant on CompCycleParticipant {
      subjectId
      compCycleId
      subject {
        id
        activeEmployment {
          id
          payCurrencyCode
        }
      }
    }
  `,
};
