import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useAuth } from "../../components/Auth/AuthContext";
import { NoEmployeeFound } from "../../components/EmptyState/EmptyState";
import { PortalLoadingBoundary } from "./PortalLoadingBoundary";

export function PortalRouter(): JSX.Element {
  return (
    <Routes>
      <Route path=":id?" element={<PortalIdReader />} />
    </Routes>
  );
}

function PortalIdReader(): JSX.Element {
  const { id } = useParams<{ id?: string }>();
  const { employeeId: currentUserEmployeeId } = useAuth();

  // If there is an ID specified in the URL, parse and use that.
  // Else we are looking at the current user's portal.
  const urlEmployeeId = id == null ? null : parseInt(id);
  const targetEmployeeId = urlEmployeeId ?? currentUserEmployeeId;

  if (targetEmployeeId == null) {
    // The current user doesn't have an employee ID.
    return <NoEmployeeFound />;
  }

  if (isNaN(targetEmployeeId)) {
    return <Navigate to="/portal" replace />;
  }

  return (
    <PortalLoadingBoundary
      employeeId={targetEmployeeId}
      isCurrentUser={targetEmployeeId === currentUserEmployeeId}
    />
  );
}
