import { gql } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { getFormattedShortDate } from "@asmbl/shared/time";
import {
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { CalendarIcon } from "src/components/AssembleIcons/Extra-Small/CalendarIcon";
import { ClockIcon } from "src/components/AssembleIcons/Extra-Small/ClockIcon";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import {
  EmploymentCarouselCard_employment as Employment,
  PayPeriodType,
} from "../../../../__generated__/graphql";
import {
  getHourlyCashLabel,
  getSimpleCashLabel,
} from "../../../../models/Currency";
import {
  GRAY_1,
  GRAY_2,
  GRAY_4,
  GRAY_6,
  PURPLE_1,
  theme,
} from "../../../../theme";

interface Props {
  employment: Employment;
  selected: boolean;
  onSelect: (id: number) => unknown;
}

const useStyles = makeStyles(() => ({
  card: {
    minWidth: "13.5rem",
    border: `1px solid ${GRAY_6}`,
    boxShadow: "none",
    flexGrow: 1,
  },
  selected: {
    border: `2px solid ${PURPLE_1}`,
    boxSizing: "border-box",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  jobTitle: {
    color: GRAY_1,
    fontSize: "14px",
    lineHeight: "140%",
    letterSpacing: "-0.25px",
    textAlign: "center",
  },
  level: {
    color: GRAY_2,
    fontSize: "12px",
    lineHeight: "140%",
    textTransform: "uppercase",
    fontWeight: 700,
    paddingTop: theme.spacing(0.5),
  },
  salary: {
    color: GRAY_2,
    fontSize: "12px",
    lineHeight: "140%",
    fontWeight: 700,

    paddingTop: theme.spacing(0.5),
  },
  bulletIconContainer: {
    color: GRAY_2,
    fontSize: "8px",
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
  },
  employmentDates: {
    color: GRAY_4,
    fontSize: "12px",
    lineHeight: "140%",
    letterSpacing: "-0.15px",
    paddingTop: theme.spacing(0.5),
  },
  employmentIconContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  positionContainer: {
    display: "flex",
  },
}));

export const EmploymentCarouselCard = ({
  employment,
  selected,
  onSelect,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();

  const allowsHourly =
    compStructure?.allowHourlyEmployees === true &&
    isEnabled(FeatureFlag.HourlyEmployees);

  const startDate = getFormattedShortDate(employment.activeAt);

  const endDate =
    employment.inactiveAt === null
      ? "Present"
      : getFormattedShortDate(employment.inactiveAt);

  return (
    <Card className={`${classes.card} ${selected ? classes.selected : ""}`}>
      <CardActionArea onClick={() => onSelect(employment.id)}>
        <CardContent className={classes.content}>
          <div className={classes.employmentIconContainer}>
            {allowsHourly &&
              (employment.payPeriod === PayPeriodType.ANNUAL ? (
                <CalendarIcon />
              ) : (
                <ClockIcon />
              ))}
            <Typography className={classes.jobTitle}>
              {employment.jobTitle}
            </Typography>
          </div>
          <div className={classes.positionContainer}>
            {employment.position?.level !== undefined && (
              <>
                <Typography className={classes.level}>
                  {`Level ${employment.position.level}`}
                </Typography>
                <div className={classes.bulletIconContainer}>&bull;</div>
              </>
            )}
            {employment.salary !== null && (
              <Typography className={classes.salary}>
                {getSimpleCashLabel(employment.salary)}
              </Typography>
            )}
            {employment.payRate !== null && (
              <Typography className={classes.salary}>
                {getHourlyCashLabel(employment.payRate, {
                  notation: "compact",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            )}
          </div>
          <Typography className={classes.employmentDates}>{`${
            startDate === "" ? "-" : startDate
          } - ${endDate === "" ? "-" : endDate}`}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

EmploymentCarouselCard.fragments = {
  employment: gql`
    fragment EmploymentCarouselCard_employment on Employment {
      id
      jobTitle
      activeAt
      inactiveAt
      salary
      payPeriod
      payRate
      position {
        id
        level
      }
    }
  `,
};
