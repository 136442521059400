import { useMutation } from "@apollo/client";
import { Switch } from "@material-ui/core";
import { useState } from "react";
import {
  GetCompStructure,
  UpsertCompStructure,
  UpsertCompStructureVariables,
} from "../../../__generated__/graphql";
import FormBox from "../../../components/FormBox";
import { UPSERT_COMP_STRUCTURE } from "../../../mutations";
import { COMP_STRUCTURE_QUERY } from "../../../queries";
import { NonNull, toggleSet } from "../../../utils";

type CompStructure = NonNull<GetCompStructure["compStructure"]>;

function CompensationComponents({
  compStructure,
  useValuation,
  disabled,
}: {
  compStructure: CompStructure;
  useValuation: boolean;
  disabled: boolean;
}) {
  const [enabledCashBandTypes, setEnabledCashBandTypes] = useState(
    new Set(compStructure.cashBandTypes)
  );
  const [enabledEquityBandTypes, setEnabledEquityBandTypes] = useState(
    new Set(compStructure.equityBandTypes)
  );

  const [upsertCompStructure] = useMutation<
    UpsertCompStructure,
    UpsertCompStructureVariables
  >(UPSERT_COMP_STRUCTURE, {
    refetchQueries: [{ query: COMP_STRUCTURE_QUERY }],
  });

  const handleChangeCashCompComponents = (bandType: string) => {
    const newCashBandTypes = toggleSet(enabledCashBandTypes, bandType);
    setEnabledCashBandTypes(newCashBandTypes);
    void upsertCompStructure({
      variables: {
        data: {
          cashBandTypes: Array.from(newCashBandTypes),
        },
      },
    });
  };

  const handleChangeEquityCompComponents = (bandType: string) => {
    const newEquityBandTypes = toggleSet(enabledEquityBandTypes, bandType);
    setEnabledEquityBandTypes(newEquityBandTypes);
    void upsertCompStructure({
      variables: {
        data: {
          equityBandTypes: Array.from(newEquityBandTypes),
        },
      },
    });
  };

  return (
    <>
      {compStructure.availableCashBandTypes.map((bandType) => (
        <FormBox
          key={bandType}
          control={<Switch />}
          label={bandType}
          name={bandType}
          onChange={() => handleChangeCashCompComponents(bandType)}
          checked={enabledCashBandTypes.has(bandType)}
          disabled={disabled}
          reverse
        />
      ))}
      {compStructure.availableEquityBandTypes.map((bandType) => {
        return (
          <FormBox
            key={bandType}
            control={<Switch />}
            label={bandType}
            name={bandType}
            onChange={() => handleChangeEquityCompComponents(bandType)}
            disabled={!useValuation || disabled}
            checked={enabledEquityBandTypes.has(bandType)}
            reverse
          />
        );
      })}
    </>
  );
}

export default CompensationComponents;
