import { contramap } from "./sort";
import { mapMaybe } from "./utils";

export type Value = string | number;

export interface Option {
  label: string;
  value: Value;
  disabled?: boolean;
}

export function pluckOptionsBy<Type>(
  reports: Type[],
  valuePlucker: (report: Type) => Value | null | undefined,
  labelPlucker: (report: Type) => string | null | undefined
): Iterable<Option> {
  return new Map(
    mapMaybe(reports, (report) => {
      const value = valuePlucker(report);
      const label = labelPlucker(report);
      return value == null || label == null ? undefined : { value, label };
    })
      .sort(contramap((option) => option.label))
      .map((option) => [option.value, option])
  ).values();
}
