import { ClickAwayListener, Drawer, makeStyles } from "@material-ui/core";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useURLSearchParams } from "../../../models/URLSearchParams";
import { TRANSPARENT } from "../../../theme";
import { EmployeeDetailLoadingBoundary } from "./EmployeeDetailLoadingBoundary";

interface Props {
  id: number | undefined;
  compCycleId?: number;
}

// A custom hook to get the employee detail id from the URL, if present
export function useEmployeeDetail(): number | undefined {
  const query = useURLSearchParams();
  const id = parseInt(query.get("employee") ?? "NaN");
  return isNaN(id) ? undefined : id;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "80%",
    zIndex: theme.zIndex.modal + 1,
  },
  backdrop: {
    backgroundColor: TRANSPARENT,
  },
}));

export const EmployeeDetailDrawer = ({
  id,
  compCycleId,
}: Props): JSX.Element | null => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearchParams = useURLSearchParams();

  const handleClose = useCallback(() => {
    navigate(
      `${location.pathname}?${urlSearchParams.delete("employee").toString()}`
    );
  }, [location.pathname, navigate, urlSearchParams]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown, false);

    return () => window.removeEventListener("keydown", handleKeyDown, false);
  }, [handleKeyDown]);

  if (id === undefined) {
    return null;
  }

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClose}
    >
      <Drawer
        open
        anchor="right"
        variant="persistent"
        PaperProps={{ className: classes.paper, elevation: 16 }}
        BackdropProps={{ className: classes.backdrop }}
        onClose={handleClose}
      >
        <EmployeeDetailLoadingBoundary id={id} compCycleId={compCycleId} />
      </Drawer>
    </ClickAwayListener>
  );
};
