import { makeStyles } from "@material-ui/core";
import { GRAY_4, GRAY_6, GRAY_7 } from "../../../../theme";

const useStyles = makeStyles(() => ({
  circle: {
    height: "1.5rem",
    width: "1.5rem",
    borderRadius: "50%",
    border: `1px solid ${GRAY_6}`,
    background: GRAY_7,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "13px",
    color: GRAY_4,
  },
}));

interface Props {
  className?: string;
  ordinal: number;
}

export function OrdinalCircle({ className = "", ordinal }: Props): JSX.Element {
  const classes = useStyles();
  return <div className={`${classes.circle} ${className}`}>{ordinal}</div>;
}
