import { gql, useQuery } from "@apollo/client";
import { ManagerSelfRedirectLoadingBoundaryQuery } from "src/__generated__/graphql";
import { ErrorView } from "src/components/ErrorView";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { ManagerSelfRedirect } from "./ManagerSelfRedirect";

type Props = { compCycleId: number; children: JSX.Element };

export function ManagerSelfRedirectLoadingBoundary({
  compCycleId,
  children,
}: Props): JSX.Element {
  const { data, loading } = useQuery<ManagerSelfRedirectLoadingBoundaryQuery>(
    ManagerSelfRedirectLoadingBoundary.query,
    { variables: { compCycleId } }
  );

  if (!data) {
    return loading ? (
      <LoadingSpinner />
    ) : (
      <ErrorView text="Unable to load manager data." />
    );
  }

  return (
    <ManagerSelfRedirect actableManagers={data.actableManagers}>
      {children}
    </ManagerSelfRedirect>
  );
}

ManagerSelfRedirectLoadingBoundary.query = gql`
  ${ManagerSelfRedirect.fragments.actableManagers}
  query ManagerSelfRedirectLoadingBoundaryQuery($compCycleId: Int!) {
    actableManagers(compCycleId: $compCycleId) {
      id
      ...ManagerSelfRedirect_actableManagers
    }
  }
`;
