import { gql } from "@apollo/client";
import { formatCurrency } from "@asmbl/shared/money";
import { byDate } from "@asmbl/shared/sort";
import { plainDateToUTCString } from "@asmbl/shared/time";
import { mapMaybe } from "@asmbl/shared/utils";
import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import { DateTime as LuxonDateTime } from "luxon";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  CashCompType,
  CompensationTimelineChart_cashCompensation as CashCompensation,
  CurrencyCode,
} from "../../__generated__/graphql";
import { useTrack } from "../../analytics";
import {
  activeComp,
  cashValueOf,
  totalCashValue,
} from "../../models/CashCompensation";
import { getXTicksForTimeline, getYTicks } from "../../models/Chart";
import {
  DV_BLUE,
  DV_BLUE_2,
  DV_GREEN,
  GRAY_1,
  GRAY_2,
  GRAY_3,
  GRAY_6,
} from "../../theme";
import { toggleSet } from "../../utils";
import { AssembleTypography } from "../AssembleTypography";
import { CompTooltip } from "./CompTooltip";
import { Y_INTERVALS } from "./Equity/chart";

type Props = {
  cashCompensations: CashCompensation[];
  valuationCurrency: CurrencyCode;
  disableAnimation?: boolean;
  now?: Date;
};

type CashEvent = {
  activeAt: string;
  comp: Map<CashCompType, CashCompensation>;
};

const useStyles = makeStyles((theme) => ({
  chart: {
    marginTop: theme.spacing(3),
  },
  labelColor: {
    color: GRAY_2,
  },
  labelCheck: {
    color: "unset !important",
  },
}));

const labels: Partial<Record<CashCompType, string>> = {
  [CashCompType.SALARY]: "Base Salary",
  [CashCompType.RECURRING_BONUS]: "Target Bonus",
  [CashCompType.COMMISSION]: "Target Commission",
};

export function CompensationTimelineChart({
  cashCompensations,
  valuationCurrency,
  disableAnimation = false,
  now = new Date(),
}: Props): JSX.Element {
  const classes = useStyles();
  const { trackEvent } = useTrack();
  const location = useLocation();

  // Filter out any and all future cash comps for rendering the graph.
  const historicalCash = cashCompensations.filter(
    (c) => new Date(c.activeAt) <= now
  );

  // We can only compare apples to apples, so limit our data to one currency
  const currency =
    historicalCash.at(-1)?.annualCashEquivalent.currency ?? valuationCurrency;
  const sameCurrencyCashCompensations = historicalCash.filter(
    (c) => c.annualCashEquivalent.currency === currency
  );

  // If the employee has none of a Comp Type, hide it entirely.
  // Show others by default, but allow toggling by clicking the legend
  const cashCompTypes = activeCashCompTypes(sameCurrencyCashCompensations);
  const [series, setSeries] = useState<Set<CashCompType>>(
    new Set(cashCompTypes)
  );

  if (sameCurrencyCashCompensations.length === 0) {
    return <>No Compensation Data</>;
  }

  // We create one data point from each date with at least one (but maybe more)
  // change. Then we find the full set of cash comp that was active at each.
  const data: CashEvent[] = Array.from(
    new Set(
      sameCurrencyCashCompensations
        .map(({ activeAt }) => activeAt)
        .concat(plainDateToUTCString(now))
    )
  )
    .sort(byDate((d) => d))
    .map((date) => ({
      activeAt: date,
      comp: activeComp(sameCurrencyCashCompensations, date),
    }));

  // Ticks are generated manually to fit the data nicely
  const xTicks = getXTicksForTimeline(new Date(data[0].activeAt), now).map(
    (t) => t.toMillis()
  );

  const xTickLabels = xTicks.reduce((allTicks, tick): string[] => {
    const date = LuxonDateTime.fromMillis(tick);
    const formatted = date.toFormat("yyyy");
    if (allTicks.includes(formatted)) {
      return allTicks.concat("");
    }
    return allTicks.concat(formatted);
  }, [] as string[]);

  const yTicks = getYTicks(Y_INTERVALS, [
    ...mapMaybe(data, (d) => totalCashValue(d.comp)),
  ]);

  // set reference line date to "today" a.k.a midnight last night
  const refLineDate = new Date(now).setHours(0, 0, 0, 0);

  const handleToggle = (value: CashCompType) => {
    trackEvent({
      object: "Timeline Comp Component",
      area: location.pathname.includes("people") ? "People" : "Employee Portal",
      subArea: "Compensation Timeline Chart",
      action: "Toggled",
      compensationComponent: value,
    });
    setSeries(toggleSet(series, value));
  };

  return (
    <div data-cy="compensation-timeline-chart">
      <ResponsiveContainer width="100%" height={400} className={classes.chart}>
        <AreaChart
          data={data}
          key={series.size}
          margin={{ right: 15, top: 15 }}
        >
          <CartesianGrid stroke={GRAY_6} />
          <XAxis
            dataKey={(d: CashEvent) => new Date(d.activeAt).getTime()}
            type="number"
            ticks={xTicks}
            tickSize={8}
            tickLine={false}
            tickCount={xTicks.length}
            tickFormatter={(d: string, index: number) =>
              xTickLabels[index] ?? ""
            }
            domain={["dataMin", "dataMax"]}
            stroke={GRAY_3}
            fontSize={12}
            axisLine={{ stroke: GRAY_6 }}
          />
          <YAxis
            type="number"
            ticks={yTicks}
            tickSize={8}
            tickLine={false}
            interval={0}
            tickFormatter={(d: number) =>
              formatCurrency(
                { value: d, currency },
                {
                  notation: "compact",
                  minimumSignificantDigits: 1,
                  maximumSignificantDigits: 3,
                }
              )
            }
            domain={["dataMin", "dataMax"]}
            stroke={GRAY_3}
            fontSize={12}
            axisLine={{ stroke: GRAY_6 }}
          />
          {stackedCompArea({
            type: CashCompType.SALARY,
            color: DV_BLUE,
            disableAnimation,
            series,
            cashCompTypes,
          })}
          {stackedCompArea({
            type: CashCompType.RECURRING_BONUS,
            color: DV_BLUE_2,
            disableAnimation,
            series,
            cashCompTypes,
          })}
          {stackedCompArea({
            type: CashCompType.COMMISSION,
            color: DV_GREEN,
            disableAnimation,
            series,
            cashCompTypes,
          })}
          <ReferenceLine
            x={refLineDate}
            stroke={GRAY_1}
            strokeDasharray={"3 3"}
          >
            <Label fontSize={12} position="top" offset={5}>
              Now
            </Label>
          </ReferenceLine>
          <Legend
            iconSize={0}
            formatter={(value: CashCompType) => (
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.labelCheck}
                    size="small"
                    checked={series.has(value)}
                    onChange={() => handleToggle(value)}
                  />
                }
                label={
                  <AssembleTypography
                    data-cy={`comp-timeline-chart-legend-label-${value}`}
                    variant="productSmall"
                    display="inline"
                    className={classes.labelColor}
                  >
                    {labels[value]}
                  </AssembleTypography>
                }
              />
            )}
          />
          <Tooltip
            content={<CompTooltip currency={currency} />}
            wrapperStyle={{ outline: "none" }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

function activeCashCompTypes(
  cashCompensations: CashCompensation[]
): Set<CashCompType> {
  return new Set(cashCompensations.map((c) => c.type));
}

function stackedCompArea({
  cashCompTypes,
  series,
  type,
  disableAnimation,
  color,
}: {
  cashCompTypes: Set<CashCompType>;
  series: Set<CashCompType>;
  type: CashCompType;
  disableAnimation: boolean;
  color: string;
}): JSX.Element | null {
  return cashCompTypes.has(type) ? (
    <Area
      type="stepAfter"
      name={type}
      stackId={1}
      dataKey={(d: CashEvent) =>
        series.has(type) ? cashValueOf(type, d.comp)?.value : null
      }
      stroke={color}
      fill={color}
      isAnimationActive={!disableAnimation}
      animationDuration={375} //ms
    />
  ) : null;
}

CompensationTimelineChart.fragments = {
  cashCompensation: gql`
    fragment CompensationTimelineChart_cashCompensation on CashCompensation {
      employeeId
      type
      activeAt
      annualCashEquivalent
      hourlyCashEquivalent
      percentOfSalary
      unit
    }
  `,
  valuationCurrency: gql`
    fragment CompensationTimelineChart_valuationCurrency on EquityHoldings {
      id
      valuationCurrency {
        id
        code
      }
    }
  `,
};
