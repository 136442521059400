import { Box, makeStyles } from "@material-ui/core";
import { memo } from "react";
import { AssembleButton } from "../../components/AssembleButton/AssembleButton";
import { GRAY_1, GRAY_4, GRAY_6, WHITE } from "../../theme";

const useStyles = makeStyles(() => ({
  container: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  content: {
    alignItems: "center",
    background: WHITE,
    border: `1px solid ${GRAY_6}`,
    boxShadow: `2px 2px 5px ${GRAY_1}0F`,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "65vh",
    justifyContent: "space-evenly",
    width: "65vw",
  },
  text: {
    color: GRAY_4,
    display: "flex",
    flexDirection: "column",
    fontSize: "22px",
    letterSpacing: "-1px",
    lineHeight: "31PX",
    textAlign: "center",
  },
}));

export const CreatePhilosophy = memo(function CreatePhilosophy(props: {
  onClick: () => void;
}): JSX.Element {
  const classes = useStyles();
  const { onClick } = props;

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box className={classes.text}>
          <span>Let’s add your Compensation Philosophy.</span>
          <span>Click the button below to get started.</span>
        </Box>
        <AssembleButton
          size="medium"
          variant="contained"
          label="Add Compensation Philosophy"
          onClick={onClick}
        />
      </Box>
    </Box>
  );
});
