import { isZero } from "@asmbl/shared/money";
import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { CopyIcon } from "src/components/AssembleIcons/Brand/CopyIcon";
import { EditIcon } from "src/components/AssembleIcons/Brand/EditIcon";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { useDeleteBenefitsPackage } from "../../../../mutations/BenefitsPackage";
import { GRAY_4, GRAY_6, PURPLE_2, WHITE } from "../../../../theme";
import { BenefitsPackageFields as BenefitsPackage } from "../../../../__generated__/graphql";
import { DeleteButton } from "../../../Form/DeleteButton";

const useCardStyles = makeStyles((theme) => ({
  listItem: {
    listStyle: "none",
    marginBottom: theme.spacing(2),
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px",
    padding: theme.spacing(2),
    background: WHITE,
    display: "flex",
    justifyContent: "space-between",
    alignItem: "center",
    fontSize: "18px",
  },

  packageName: {
    fontSize: "18px",
  },

  secondaryContent: {
    position: "relative",
    flexGrow: 1,
    textAlign: "right",
  },

  benefitsCount: {
    color: GRAY_4,
    fontSize: "18px",
    opacity: 1,
    transition: "opacity 300ms",

    "$listItem:hover &, $listItem:focus-within &": {
      opacity: 0,
    },
  },

  actions: {
    color: GRAY_4,
    opacity: 0,
    position: "absolute",
    top: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    transition: "opacity 300ms",
    "$listItem:hover &, $listItem:focus-within &": {
      opacity: 1,
    },
  },

  actionButton: {
    marginLeft: theme.spacing(1),
  },
}));

interface BenefitsPackageCardProps {
  benefitsPackage: BenefitsPackage;
}

export function BenefitsPackageCard({
  benefitsPackage,
}: BenefitsPackageCardProps): JSX.Element {
  const classes = useCardStyles();

  const deleteBenefitsPackage = useDeleteBenefitsPackage();

  const benefitsCount =
    benefitsPackage.benefits.length === 1
      ? "1 Benefit"
      : `${benefitsPackage.benefits.length} Benefits`;

  return (
    <li className={classes.listItem}>
      <Typography className={classes.packageName}>
        {benefitsPackage.name}
      </Typography>
      <div className={classes.secondaryContent}>
        <Typography className={classes.benefitsCount}>
          {benefitsCount}
          {!isZero(benefitsPackage.totalValue) && (
            <> ({getSimpleCashLabel(benefitsPackage.totalValue)})</>
          )}
        </Typography>
        <div className={classes.actions}>
          <Tooltip title="Edit Benefits Package" placement="top">
            <IconButton
              className={classes.actionButton}
              component={Link}
              to={`/settings/benefits/${benefitsPackage.id}`}
              size="small"
              aria-label="Edit"
            >
              <EditIcon
                width="24px"
                height="24px"
                color={GRAY_4}
                hoverColor={PURPLE_2}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Copy Benefits Package" placement="top">
            <IconButton
              className={classes.actionButton}
              component={Link}
              to={`/settings/benefits/${benefitsPackage.id}/copy`}
              size="small"
              aria-label="Copy"
            >
              <CopyIcon
                width="24px"
                height="24px"
                color={GRAY_4}
                hoverColor={PURPLE_2}
              />
            </IconButton>
          </Tooltip>
          <DeleteButton
            className={classes.actionButton}
            buttonType="icon"
            size="small"
            objectName="Benefits Package"
            onDelete={() => deleteBenefitsPackage(benefitsPackage)}
          />
        </div>
      </div>
    </li>
  );
}
