import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const ZoomInIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Zoom In"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M7.24986 12.5C10.1493 12.5 12.4998 10.1495 12.4998 7.24998C12.4998 4.3505 10.1493 2 7.24986 2C4.35037 2 1.99988 4.3505 1.99988 7.24998C1.99988 10.1495 4.35037 12.5 7.24986 12.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M10.9619 10.9624L13.9994 14"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M7.25 5.5V9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        className="stroke"
        d="M9 7.25L5.5 7.25"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  </ColorableSVG>
);
