import { gql, useQuery } from "@apollo/client";
import { useTrack } from "../../analytics";
import { ErrorView } from "../../components/ErrorView";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { GetLadderDetails } from "../../__generated__/graphql";
import { ExcludedBandNamesProvider } from "./ExcludedBandNameContext";
import { LadderDetail } from "./LadderDetail";

export function LadderDetailLoadingBoundary(): JSX.Element {
  const { Track } = useTrack({
    area: "Job Architecture",
    subArea: "Ladder Detail",
  });

  const { loading, data } = useQuery<GetLadderDetails>(
    LadderDetailLoadingBoundary.query
  );

  if (!data) {
    return loading ? <LoadingSpinner /> : <ErrorView />;
  }

  return (
    <Track>
      <ExcludedBandNamesProvider>
        <LadderDetail
          organizationPermissionSettings={data.organization.permissionSettings}
        />
      </ExcludedBandNamesProvider>
    </Track>
  );
}

LadderDetailLoadingBoundary.query = gql`
  ${LadderDetail.fragments.permissionSettings}
  query GetLadderDetails {
    organization {
      id
      permissionSettings {
        id
        ...LadderDetail_permissionSettings
      }
    }
  }
`;
