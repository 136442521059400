import { Dialog, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { UploadModalFirstPage } from "./UploadModalFirstPage";
import { UploadModalSecondPage } from "./UploadModalSecondPage";
import { useTrack } from "src/analytics";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 540,
    width: 540,
    padding: theme.spacing(4),
  },
}));

type Props = {
  isOpen: boolean;
  onClose: () => unknown;
  refreshData: () => void;
};

export function MarketDataUploadModalContainer({
  isOpen,
  onClose,
  refreshData,
}: Props) {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [name, setName] = useState<string>("");
  const [provider, setProvider] = useState<string>("");
  const [customProvider, setCustomProvider] = useState<string>("");
  const [selectedMarket, setSelectedMarket] = useState<number | null>(null);
  const { trackEvent } = useTrack();

  useEffect(() => {
    if (isOpen) {
      trackEvent({
        object: "Market Data",
        action: "Upload Started",
      });
    }
  }, [isOpen, trackEvent]);

  const handleClose = () => {
    onClose();
    setCurrentPage(1);
    setName("");
    setProvider("");
    setCustomProvider("");
    setSelectedMarket(null);
  };

  return (
    <Dialog
      PaperProps={{ className: classes.paper }}
      open={isOpen}
      onClose={handleClose}
      transitionDuration={0}
      disableEnforceFocus
      disableAutoFocus
    >
      {currentPage === 1 && (
        <UploadModalFirstPage
          onClose={handleClose}
          onNext={() => setCurrentPage(2)}
        />
      )}
      {currentPage === 2 && (
        <UploadModalSecondPage
          onClose={handleClose}
          onNext={() => setCurrentPage(3)}
          name={name}
          setName={setName}
          provider={provider}
          setProvider={setProvider}
          customProvider={customProvider}
          setCustomProvider={setCustomProvider}
          selectedMarket={selectedMarket}
          setSelectedMarket={setSelectedMarket}
          refreshData={refreshData}
        />
      )}
    </Dialog>
  );
}
