import { Box, makeStyles } from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";
import { DragHandleIcon } from "src/components/AssembleIcons/Brand/DragHandleIcon";
import { isCustomFieldValid } from "../../../models/Offer";
import { GRAY_4, GRAY_6, PURPLE_1, RED, WHITE } from "../../../theme";
import { OrdinalCircle } from "../Benefits/Editor/OrdinalCircle";
import { CustomFieldEditor } from "./CustomFieldEditor";
import { CustomFieldSidebar } from "./CustomFieldSidebar";
import { KeyedCustomField } from "./OfferContentsForm";

const useStyles = makeStyles((theme) => ({
  row: {
    userSelect: "none",
    padding: 16,
    borderBottom: `1px solid ${GRAY_6}`,
    display: "flex",
    position: "relative",
    background: WHITE,
    border: `1px solid transparent`,

    "&$error": {
      border: `1px solid ${RED}`,
    },

    "&$dragging": {
      border: `1px solid ${GRAY_4}`,
      boxShadow: "0px 5px 15px rgba(10, 36, 64, 0.13)",
      boxSizing: "border-box",
    },
  },
  margin: {
    padding: theme.spacing(2.5, 2),
  },
  dragging: {},
  error: {},
  marginOrdinal: {
    "$row:hover &, $margin:focus-within &, $dragging &": {
      display: "none",
    },
  },
  marginDragHandle: {
    opacity: 0,
    color: GRAY_4,
    width: "1.5rem",
    height: "1rem",
    "$row:hover &, $margin:focus-within &, $dragging &": {
      opacity: 1,
    },

    "&:hover, $dragging &": {
      color: PURPLE_1,
    },
  },
  delete: {
    opacity: 0,
    transition: "opacity 300ms",

    "$row:hover &, $margin:focus-within &, $dragging &": {
      opacity: 1,
    },
  },
}));

/* Why do we take all the `fields` as opposed to just one specific field?
 * Because we have to check for errors within the context of the other fields,
 * e.g. checking if two fields have the same name.
 */
type Props = {
  fields: KeyedCustomField[];
  index: number;
  onFieldChange: (field: KeyedCustomField) => void;
  onDelete: () => void;
};

export function CustomFieldRow({
  fields,
  index,
  onFieldChange,
  onDelete,
}: Props): JSX.Element {
  const classes = useStyles();

  const state = isCustomFieldValid(index, fields);
  const styling = `${classes.row} ${state !== "VALID" ? classes.error : ""}`;
  return (
    <Draggable draggableId={fields[index].uniqueKey.toString()} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          className={`${styling} ${
            snapshot.isDragging ? classes.dragging : ""
          }`}
          style={{ ...provided.draggableProps.style }}
          {...provided.draggableProps}
        >
          <Box className={classes.margin}>
            <OrdinalCircle
              className={classes.marginOrdinal}
              ordinal={index + 1}
            />
            <div className={classes.marginDragHandle}>
              <div {...provided.dragHandleProps}>
                <DragHandleIcon inherit height="24px" width="24px" />
              </div>
            </div>
          </Box>
          <CustomFieldEditor
            field={fields[index]}
            onFieldChange={onFieldChange}
          />
          <CustomFieldSidebar
            onDelete={onDelete}
            state={state}
            deleteStyle={classes.delete}
          />
        </Box>
      )}
    </Draggable>
  );
}
