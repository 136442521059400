import { gql } from "@apollo/client";
import { Money } from "@asmbl/shared/money";
import { Box, makeStyles } from "@material-ui/core";
import { useMemo } from "react";
import { TotalEquityTimeline_equityHoldings as EquityHoldings } from "../../../__generated__/graphql";
import PoweredByCartaSvg from "../../../assets/svgs/carta/powered-by-carta-black.svg?react";
import { Card } from "../../Cards/Card";
import { CardHeader } from "../../Cards/CardHeader";
import { TotalEquityTimelineChart } from "./TotalEquityTimelineChart";
import { TotalEquityTimelineChartHeader } from "./TotalEquityTimelineChartHeader";

type Props = {
  equityHoldings: EquityHoldings;
  sharePrice: Money | undefined;
  subject?: string;
};

const useStyles = makeStyles(() => ({
  poweredByCartaHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export function TotalEquityTimeline({
  equityHoldings,
  subject,
  sharePrice,
}: Props): JSX.Element {
  const classes = useStyles();
  const header =
    subject === undefined
      ? "Vesting Schedule for All Your Grants"
      : `Vesting Schedule for ${subject}'s Grants`;

  const showCartaLogo =
    equityHoldings.grants?.some((g) => g.fromCarta) ?? false;

  const equity = useMemo(() => equityHoldings, [equityHoldings]);

  if (equity.vestingInformation === null) return <></>;

  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Card>
        {showCartaLogo ? (
          <div className={classes.poweredByCartaHeader}>
            <CardHeader header={header} />
            <PoweredByCartaSvg />
          </div>
        ) : (
          <CardHeader header={header} />
        )}
        <TotalEquityTimelineChartHeader equityHoldings={equity} />
        <TotalEquityTimelineChart
          equityHoldings={equity}
          sharePrice={sharePrice}
        />
      </Card>
    </Box>
  );
}

TotalEquityTimeline.fragments = {
  equityHoldings: gql`
    ${TotalEquityTimelineChart.fragments.equityHoldings}
    ${TotalEquityTimelineChartHeader.fragments.equityHoldings}
    fragment TotalEquityTimeline_equityHoldings on EquityHoldings {
      ...TotalEquityTimelineChart_equityHoldings
      ...TotalEquityTimelineChartHeader_equityHoldings
      grants {
        id
        fromCarta
      }
    }
  `,
};
