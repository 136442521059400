import { gql } from "@apollo/client";
import {
  Box,
  makeStyles,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import { ReactNode } from "react";
import { LevelIcon } from "src/components/AssembleIcons/Small/LevelIcon";
import { CompRecTableHeaders } from "../../../models/CompRecommendation";
import { getHeaderWidth, HeaderConfig } from "../../../models/HeaderConfig";
import { GRAY_4, WHITE } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";

type Props = {
  headerConfig: HeaderConfig;
};

const useStyles = makeStyles(() => ({
  compaRatioHeader: {
    minWidth: "190px",
  },
  cellTitle: {
    height: "1rem",
    width: "1rem",
    display: "inline-block",
  },
  headerCell: {
    backgroundColor: WHITE,
    height: "2rem",
  },
  doubleHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export function CompCycleTableHeaders({ headerConfig }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell
          className={classes.headerCell}
          variant="head"
          width={getHeaderWidth(headerConfig, CompRecTableHeaders.AVATAR)}
        />
        <DoubleHeaderCell
          className={classes.headerCell}
          titleA="Name"
          titleB="Position"
          padding="none"
          width={getHeaderWidth(headerConfig, CompRecTableHeaders.NAME_POS)}
          aria-label={CompRecTableHeaders.NAME_POS}
        />
        <DoubleHeaderCell
          className={classes.headerCell}
          titleA={
            <Tooltip title="Department / Ladder" placement="top">
              <span>Dept</span>
            </Tooltip>
          }
          titleB={
            <Tooltip title="Department / Ladder" placement="top">
              <span>Ladder</span>
            </Tooltip>
          }
          padding="none"
          width={getHeaderWidth(headerConfig, CompRecTableHeaders.DEPT_LADDER)}
          aria-label={CompRecTableHeaders.DEPT_LADDER}
        />

        <HeaderCell
          className={classes.headerCell}
          cellTitle={
            <div className={classes.cellTitle}>
              <LevelIcon color={GRAY_4} />
            </div>
          }
          aria-label={CompRecTableHeaders.LEVEL}
          width={getHeaderWidth(headerConfig, CompRecTableHeaders.LEVEL)}
          align="center"
          noWrap
        />

        <HeaderCell
          className={classes.headerCell}
          cellTitle="Perf. Rating"
          aria-label={CompRecTableHeaders.PERF_RATING}
          width={getHeaderWidth(headerConfig, CompRecTableHeaders.PERF_RATING)}
          align="left"
          noWrap
        />
        <HeaderCell
          className={classes.headerCell}
          cellTitle={CompRecTableHeaders.TENURE}
          aria-label={CompRecTableHeaders.TENURE}
          width={getHeaderWidth(headerConfig, CompRecTableHeaders.TENURE)}
          noWrap
        />
        <HeaderCell
          className={classes.headerCell}
          cellTitle={CompRecTableHeaders.LOCATION}
          aria-label={CompRecTableHeaders.LOCATION}
          width={getHeaderWidth(headerConfig, CompRecTableHeaders.LOCATION)}
          noWrap
        />
        <HeaderCell
          className={classes.headerCell}
          cellTitle="Mgr"
          aria-label={CompRecTableHeaders.MANAGER}
          width={getHeaderWidth(headerConfig, CompRecTableHeaders.MANAGER)}
          align="center"
          noWrap
        />
        <HeaderCell
          className={classes.headerCell}
          cellTitle="TCC Current / New"
          aria-label={CompRecTableHeaders.TOTAL_COMP}
          width={getHeaderWidth(headerConfig, CompRecTableHeaders.TOTAL_COMP)}
          align="right"
        />
        <HeaderCell
          className={clsx(classes.compaRatioHeader, classes.headerCell)}
          cellTitle={CompRecTableHeaders.BAND}
          aria-label={CompRecTableHeaders.BAND}
          width={getHeaderWidth(headerConfig, CompRecTableHeaders.BAND)}
          align="right"
        />
        {headerConfig[CompRecTableHeaders.BONUS].isVisible ? (
          <HeaderCell
            className={clsx(classes.compaRatioHeader, classes.headerCell)}
            cellTitle={CompRecTableHeaders.BONUS}
            aria-label={CompRecTableHeaders.BONUS}
            width={getHeaderWidth(headerConfig, CompRecTableHeaders.BONUS)}
            align="right"
          />
        ) : (
          <TableCell className={classes.headerCell} width="0%" padding="none" />
        )}
        {headerConfig[CompRecTableHeaders.EQUITY].isVisible ? (
          <HeaderCell
            className={clsx(classes.compaRatioHeader, classes.headerCell)}
            cellTitle={CompRecTableHeaders.EQUITY}
            aria-label={CompRecTableHeaders.EQUITY}
            width={getHeaderWidth(headerConfig, CompRecTableHeaders.EQUITY)}
            align="right"
          />
        ) : (
          <TableCell className={classes.headerCell} width="0%" padding="none" />
        )}
        <TableCell
          className={classes.headerCell}
          width={getHeaderWidth(headerConfig, CompRecTableHeaders.SPACER)}
          padding="none"
        />
      </TableRow>
    </TableHead>
  );
}

CompCycleTableHeaders.fragments = {
  employee: gql`
    fragment CompCycleTableHeaders_employee on Employee {
      id
    }
  `,
};

interface HeaderCellProps extends TableCellProps {
  cellTitle: React.ReactNode;
  noWrap?: boolean;
}

const HeaderCell = ({
  cellTitle,
  align,
  noWrap,
  ...rest
}: HeaderCellProps): JSX.Element => {
  return (
    <TableCell variant="head" align={align} {...rest}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={
          align === "center"
            ? "center"
            : align === "right"
              ? "flex-end"
              : "space-between"
        }
        alignItems="center"
      >
        <AssembleTypography variant="productTableHeader" noWrap={noWrap}>
          {cellTitle}
        </AssembleTypography>
      </Box>
    </TableCell>
  );
};

interface DoubleHeaderCellProps extends TableCellProps {
  titleA: ReactNode;
  titleB: ReactNode;
}

const DoubleHeaderCell = ({
  titleA,
  titleB,
  align,
  ...rest
}: DoubleHeaderCellProps): JSX.Element => {
  const classes = useStyles();

  return (
    <TableCell variant="head" align={align} {...rest}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={
          align === "center"
            ? "center"
            : align === "right"
              ? "flex-end"
              : "space-between"
        }
        alignItems="center"
      >
        <span className={classes.doubleHeader}>
          <AssembleTypography variant="productTableHeader">
            {titleA}
          </AssembleTypography>
          <AssembleTypography>&nbsp;/&nbsp;</AssembleTypography>
          <AssembleTypography variant="productTableHeader">
            {titleB}
          </AssembleTypography>
        </span>
      </Box>
    </TableCell>
  );
};
