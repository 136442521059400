import { gql } from "@apollo/client";
import { Button, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { AssembleTypography } from "../../../../components/AssembleTypography";
import {
  canEmployeeReceiveInvite,
  formatEmpData,
  shouldGetLeveled,
} from "../../../../models/Employee";
import { useInviteUsersToEmployeePortal } from "../../../../mutations/User";
import {
  EmployeePortal_employee as Employee,
  EmployeePortal_organization as Organization,
  EmployeePortal_portalConfig as PortalConfig,
  EmployeePortal_valuation as Valuation,
} from "../../../../__generated__/graphql";
import { PageContainer } from "../../PageContainer";
import { AssignBenefitsButton } from "../Benefits/AssignBenefitsButton";
import { PortalTable } from "../Table/PortalTable";
import { AccessConfigBanner } from "./AccessConfigBanner";
import { PortalBulkInvite } from "./PortalBulkInvite";
import { UploadEquityBanner } from "./UploadEquityBanner";

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: theme.spacing(2),
    justifyContent: "space-between",
  },
  selectedText: {
    display: "flex",
    alignSelf: "center",
  },
  buttonSpacing: {
    paddingLeft: theme.spacing(2),
  },
  buttonShadow: {
    boxShadow: "0px 1px 3px rgba(65, 54, 241, 0.5) !important",
  },
}));

type Props = {
  employees: Employee[];
  config: PortalConfig | null;
  organization: Organization;
  valuation: Valuation | null;
};

export type EmployeeMap = Map<number, Employee & { selected: boolean }>;

export const EmployeePortal = ({
  employees: allEmployees,
  config,
  organization,
  valuation,
}: Props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const employees = useMemo(
    // Inactive and Incomplete employees don't need Portal access
    () => allEmployees.filter(shouldGetLeveled),
    [allEmployees]
  );

  const [selectedEmployees, setSelectedEmployees] = useState<EmployeeMap>(
    createEmployeeMap(employees, false)
  );

  const inviteUsersToEmployeePortal = useInviteUsersToEmployeePortal();

  const handleInviteClick = async () => {
    const invites = [...selectedEmployees]
      .filter(([, emp]) => emp.selected && canEmployeeReceiveInvite(emp))
      .map(([, emp]) => formatEmpData(emp));

    const data = await inviteUsersToEmployeePortal(invites);

    // error occurred
    if (typeof data === "string") {
      enqueueSnackbar(data, { variant: "error" });
    }

    // clear all selected employees
    setSelectedEmployees(createEmployeeMap(employees, false));
  };

  const handleIndeterminateChange = (
    currentEmployeeSelectionIds: number[]
  ): void => {
    const currentEmployeeSelection = employees.filter((e) =>
      currentEmployeeSelectionIds.includes(e.id)
    );
    const allSelected = currentEmployeeSelection.every((emp) => {
      const mappedEmp = selectedEmployees.get(emp.id);
      return mappedEmp && mappedEmp.selected;
    });
    currentEmployeeSelection.forEach((emp) =>
      handleChange(emp.id, !allSelected)
    );
  };

  const handleChange = (id: number, selectedStatus?: boolean) => {
    const employee = selectedEmployees.get(id);
    if (employee !== undefined) {
      const selected = selectedStatus ?? !employee.selected;

      setSelectedEmployees(
        (map) => new Map(map.set(id, { ...employee, selected }))
      );
    }
  };

  const userCount = getUserCount(selectedEmployees);
  const selectedEmployeesText =
    userCount === 0
      ? `${employees.length} employees`
      : getModalTitle(userCount);

  return (
    <PageContainer
      header={"Employee Portal Access"}
      description="Define access to your employee's portal."
    >
      <div className={classes.formContainer}>
        {config === null && valuation == null && <AccessConfigBanner />}
        <UploadEquityBanner employees={employees} organization={organization} />
        <div className={classes.buttonContainer}>
          <AssembleTypography
            variant="productParagraphLarge"
            className={classes.selectedText}
          >
            {selectedEmployeesText}
          </AssembleTypography>
          <div className={classes.flexContainer}>
            <AssignBenefitsButton
              employees={Array.from(selectedEmployees.values()).filter(
                (e) => e.selected
              )}
            >
              {(onClick) => (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onClick}
                  disabled={userCount === 0}
                  className={clsx({
                    [classes.buttonShadow]: userCount > 0,
                  })}
                >
                  Assign Benefits
                </Button>
              )}
            </AssignBenefitsButton>
            <div className={classes.buttonSpacing} />
            <PortalBulkInvite
              employees={[...selectedEmployees.values()].filter(
                (emp) => emp.selected
              )}
              onInvite={handleInviteClick}
            />
          </div>
        </div>
        <PortalTable
          employees={employees}
          selectedEmployees={selectedEmployees}
          handleChange={handleChange}
          handleIndeterminateChange={handleIndeterminateChange}
        />
      </div>
    </PageContainer>
  );
};

const createEmployeeMap = (employees: Employee[], selectedValue: boolean) => {
  return new Map(
    employees.map((emp) => [emp.id, { ...emp, selected: selectedValue }])
  );
};

const getUserCount = (employees: EmployeeMap): number => {
  return [...employees.values()].filter((emp) => emp.selected).length;
};

const getModalTitle = (userCount: number): string => {
  return userCount === 1
    ? "1 employee selected"
    : `${userCount} employees selected`;
};

EmployeePortal.fragments = {
  employee: gql`
    ${AssignBenefitsButton.fragments.employee}
    ${PortalTable.fragments.employee}
    ${PortalBulkInvite.fragments.employee}
    ${UploadEquityBanner.fragments.employee}
    fragment EmployeePortal_employee on Employee {
      employmentStatus
      activeEmployment {
        id
      }
      ...AssignBenefitsButton_employee
      ...PortalTable_employee
      ...PortalBulkInvite_employee
      ...UploadEquityBanner_employee
    }
  `,
  portalConfig: gql`
    fragment EmployeePortal_portalConfig on PortalConfig {
      id
    }
  `,
  organization: gql`
    ${UploadEquityBanner.fragments.organization}
    fragment EmployeePortal_organization on Organization {
      id
      ...UploadEquityBanner_organization
    }
  `,
  valuation: gql`
    fragment EmployeePortal_valuation on Valuation {
      id
    }
  `,
};
