import { makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useUpsertManualMergeConnectionConfig } from "../../../mutations/Integrations";

const useStyles = makeStyles((theme) => ({
  content: {
    left: "50%",
    paddingBottom: `${theme.spacing(6)}px`,
    position: "absolute",
    transform: "translate(-50%)",
    width: theme.spacing(67),
  },
}));

type Props = {
  onSelect: (mergeConfigId: number, mergeConfigName: string) => unknown;
};

export function SelectYourHRIS({ onSelect }: Props): JSX.Element {
  const classes = useStyles();

  const upsertManualConfig = useUpsertManualMergeConnectionConfig();

  useEffect(() => {
    void upsertManualConfig().then((result) => {
      const config = result.data?.upsertOneManualMergeConnectionConfig;
      if (config !== undefined) {
        onSelect(config.id, config.integrationName);
      }
    });
  }, [onSelect, upsertManualConfig]);

  return (
    <div className={classes.content}>
      <LoadingSpinner />
    </div>
  );
}
