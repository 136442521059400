import { gql } from "@apollo/client";
import { Noun } from "@asmbl/shared/permissions";
import { mapMaybe } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import { useIntercom } from "react-use-intercom";
import {
  CompCycleSubmitChangesButton_currentEmployee as CurrentEmployee,
  CompCycleSubmitChangesButton_participant as Participant,
  RecommendationReviewStatus,
} from "src/__generated__/graphql";
import { useTrack } from "src/analytics";
import { getTypographyProps } from "src/components/AssembleButton/AssembleButton";
import { AssembleTypography } from "src/components/AssembleTypography";
import { useAuth } from "src/components/Auth/AuthContext";
import { SaveButton } from "src/components/Form/SaveButton";
import { useURLSearchParams } from "src/models/URLSearchParams";
import { useSubmitRecReviews } from "src/mutations/CompRecommendation";
import { useTableData } from "./Contexts/TableDataContext2";

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: "max-content",
    height: theme.spacing(4),
  },
}));

type Props = {
  compCycleId: number;
  currentEmployee: CurrentEmployee | null;
  participants: Participant[];
};

export function CompCycleSubmitChangesButton({
  compCycleId,
  currentEmployee,
  participants,
}: Props) {
  const classes = useStyles();
  const intercom = useIntercom();
  const { selectedCurrency } = useTableData();
  const { trackEvent } = useTrack();
  const { permissions, userId } = useAuth();
  const urlSearchParams = useURLSearchParams();

  const currencyCode = selectedCurrency === "all" ? null : selectedCurrency;
  const submitCompRecReview = useSubmitRecReviews(currencyCode);

  const managerId = urlSearchParams.get("manager");

  const hasDirectReports = currentEmployee?.indirectReportsCount !== 0;
  const hasGlobalEditAccess = permissions.canEditGlobal(
    Noun.CompRecommendation
  );

  const renderSubmitChangesButton = hasDirectReports || hasGlobalEditAccess;

  // only filter for the recommendations where we can review and we weren't
  // the last reviewer
  const compRecsToApprove = mapMaybe(participants, (participant) => ({
    ...participant.compRecommendation,
    subjectId: participant.subjectId,
  })).filter((rec) => {
    const canICurrentlyReview = rec.canICurrentlyReview === true;
    const reviews = rec.latestSubmittedReviews ?? [];

    if (reviews.length > 0) {
      // status doesn't matter, just whether the user has reviewed
      const latestSubmittedReviewByMe = reviews.find(
        ({ authorId }) => userId !== null && authorId === userId
      );
      const latestItemSubmissionTime =
        rec.latestSubmittedItems?.at(0)?.submittedAt ?? null;
      const reviewSubmissionTime =
        latestSubmittedReviewByMe?.submittedAt ?? null;

      // check if the user's review is not the latest activity
      if (reviewSubmissionTime !== null) {
        const myReviewIsNotLatestActivity =
          latestItemSubmissionTime !== null &&
          new Date(reviewSubmissionTime).getTime() <=
            new Date(latestItemSubmissionTime).getTime();

        return canICurrentlyReview && myReviewIsNotLatestActivity;
      }
    }

    // otherwise return if user can review
    return canICurrentlyReview;
  });

  const handleClick = async () => {
    try {
      // if we are an HRBP then we will be submitting the request on behalf
      // of the manager we are filtering under
      await submitCompRecReview({
        compCycleId,
        actingManagerEmployeeId:
          managerId != null && permissions.isHRBP()
            ? Number.parseInt(managerId)
            : null,
        data: mapMaybe(compRecsToApprove, (rec) => ({
          subjectId: rec.subjectId,
          compCycleId,
          status: RecommendationReviewStatus.APPROVED,
        })),
      });

      trackEvent({
        object: "Change Requests",
        subArea: "Approve All Button",
        action: "Submitted",
      });

      intercom.trackEvent("Comp Recommendations Submitted");
      return true;
    } catch (e) {
      return false;
    }
  };

  return renderSubmitChangesButton ? (
    <SaveButton
      disabled={compRecsToApprove.length === 0}
      className={classes.button}
      onSave={handleClick}
      labels={{
        default: renderLabel(
          compRecsToApprove.length === 0
            ? "There are no requests to accept"
            : `Mark ${compRecsToApprove.length} Request${
                compRecsToApprove.length > 1 ? "s" : ""
              } Accepted`
        ),
        processing: renderLabel("Submitting..."),
        success: renderLabel("Submitted"),
      }}
      hideEndIcon
    />
  ) : null;
}

function renderLabel(label: string): JSX.Element {
  return (
    <AssembleTypography
      {...getTypographyProps({ size: "small", variant: "contained" })}
    >
      {label}
    </AssembleTypography>
  );
}

CompCycleSubmitChangesButton.fragments = {
  currentEmployee: gql`
    fragment CompCycleSubmitChangesButton_currentEmployee on Employee {
      id
      indirectReportsCount
    }
  `,
  participant: gql`
    fragment CompCycleSubmitChangesButton_participant on CompCycleParticipant {
      subjectId
      compCycleId
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        canICurrentlyReview(actingManagerEmployeeId: $actingManagerEmployeeId)
        latestSubmittedReviews {
          id
          submittedAt
          authorId
        }
        latestSubmittedItems {
          id
          submittedAt
        }
      }
    }
  `,
};
