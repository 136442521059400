import { gql, useQuery } from "@apollo/client";
import { IconButton, makeStyles, Slide, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { GRAY_8, WHITE } from "../theme";
import { GetGlobalWarningInfo } from "../__generated__/graphql";
import { CloseCircleIcon } from "./AssembleIcons/Small/CloseCircleIcon";
import { NewWindowIcon } from "./AssembleIcons/Small/NewWindow";
import { useAuth } from "./Auth/AuthContext";
import { InfoBanner } from "./InfoBanner";
import { cartaOauthUrl } from "./Settings/Integrations/Carta/CartaUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(1.5, 2, 0, 2),
    zIndex: 1_000_000,
  },
  dismissButton: {
    position: "absolute",
    right: theme.spacing(2),
  },
  bannerContents: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  noHeight: {
    height: 0,
  },
  cartaLink: {
    color: WHITE,
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
  },
}));

export function GlobalWarningBanner(): JSX.Element | null {
  const classes = useStyles();
  const { permissions, userId, organization } = useAuth();
  const [showBanner, setShowBanner] = useState(true);
  const { data, loading } = useQuery<GetGlobalWarningInfo>(
    globalWarningInfoQuery,
    {
      pollInterval: 60_000,
    }
  );

  useEffect(() => {
    setTimeout(() => setShowBanner(true), 100);
  }, []);

  if (
    loading ||
    !data ||
    !data.cartaConnectionConfig ||
    data.cartaConnectionConfig.isActive ||
    window.location.pathname.includes("settings/data")
  )
    return null;

  const errorText = permissions.hasSettingsPermissions()
    ? "Uh oh, it appears we're no longer receiving data from Carta. Reconnect Carta to ensure your equity info is up to date."
    : "We're no longer receiving data from Carta which means equity info may be out of date. Contact your Assemble admin and have them reconnect your Carta account.";

  const cta = permissions.hasSettingsPermissions() ? (
    <a
      className={classes.cartaLink}
      href={cartaOauthUrl(organization?.id, userId)}
    >
      <span style={{ marginRight: "1rem" }}>Reconnect Carta</span>
      <NewWindowIcon color={WHITE} height={"24px"} width={"24px"} />
    </a>
  ) : (
    <IconButton
      className={classes.dismissButton}
      size="small"
      onClick={() => setShowBanner(false)}
    >
      <CloseCircleIcon color={GRAY_8} />
    </IconButton>
  );

  return (
    <Slide in={showBanner}>
      <div className={clsx(classes.root, { [classes.noHeight]: !showBanner })}>
        <InfoBanner fullWidth noMargin level="error">
          <div className={classes.bannerContents}>
            <Typography>{errorText}</Typography>
            {cta}
          </div>
        </InfoBanner>
      </div>
    </Slide>
  );
}

const globalWarningInfoQuery = gql`
  query GetGlobalWarningInfo {
    cartaConnectionConfig {
      id
      isActive
    }
  }
`;
