import { Chip, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { AssembleTypography } from "src/components/AssembleTypography";
import {
  STATUS_ACCEPTED,
  STATUS_ACCEPTED_HOVER,
  STATUS_AWAITING,
  STATUS_AWAITING_BORDER,
  STATUS_AWAITING_COLOR,
  STATUS_AWAITING_HOVER,
  STATUS_FUTURE_PHASE,
  STATUS_FUTURE_PHASE_BORDER,
  STATUS_FUTURE_PHASE_COLOR,
  STATUS_FUTURE_PHASE_HOVER,
  STATUS_LATE,
  STATUS_LATE_HOVER,
  STATUS_NEEDS_REVIEW,
  STATUS_NEEDS_REVIEW_HOVER,
  STATUS_REJECTED,
  STATUS_REJECTED_HOVER,
  STATUS_REVIEWED,
  STATUS_REVIEWED_HOVER,
  STATUS_SKIPPED,
  STATUS_SKIPPED_HOVER,
  theme,
  WHITE,
} from "src/theme";
import { capitalizeEachWord } from "src/utils";
import {
  CompRecommendationStatus,
  PhaseTimelineStatus,
  RelativeCompRecStatus,
} from "src/__generated__/graphql";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    padding: theme.spacing(1, 0),
    gap: theme.spacing(0.75),
  },
  chip: {
    marginLeft: "-1px",
    width: "5.25rem",
    height: "1.25rem",
    "& .MuiChip-label": {
      color: WHITE,
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  },
  accepted: {
    background: STATUS_ACCEPTED,
    "&:hover": {
      background: STATUS_ACCEPTED_HOVER,
    },
  },
  rejected: {
    background: STATUS_REJECTED,
    "&:hover": {
      background: STATUS_REJECTED_HOVER,
    },
  },
  needsReview: {
    background: STATUS_NEEDS_REVIEW,
    "&:hover": {
      background: STATUS_NEEDS_REVIEW_HOVER,
    },
  },
  reviewed: {
    background: STATUS_REVIEWED,
    "&:hover": {
      background: STATUS_REVIEWED_HOVER,
    },
  },
  late: {
    background: STATUS_LATE,
    "&:hover": {
      background: STATUS_LATE_HOVER,
    },
  },
  futurePhase: {
    border: `1px solid ${STATUS_FUTURE_PHASE_BORDER}`,
    background: STATUS_FUTURE_PHASE,
    "& .MuiChip-label": {
      color: STATUS_FUTURE_PHASE_COLOR,
    },
    "&:hover": {
      border: `1px solid ${STATUS_FUTURE_PHASE_BORDER}`,
      background: STATUS_FUTURE_PHASE_HOVER,
      "& .MuiChip-label": {
        color: STATUS_FUTURE_PHASE_COLOR,
      },
    },
  },
  awaiting: {
    border: `1px solid ${STATUS_AWAITING_BORDER}`,
    background: STATUS_AWAITING,
    "& .MuiChip-label": {
      color: STATUS_AWAITING_COLOR,
    },
    "&:hover": {
      border: `1px solid ${STATUS_AWAITING_BORDER}`,
      background: STATUS_AWAITING_HOVER,
      "& .MuiChip-label": {
        color: STATUS_AWAITING_COLOR,
      },
    },
  },
  skipped: {
    background: STATUS_SKIPPED,
    "&:hover": {
      background: STATUS_SKIPPED_HOVER,
    },
  },
}));

type Props = {
  statusToDisplay:
    | RelativeCompRecStatus
    | CompRecommendationStatus
    | PhaseTimelineStatus;
  onClick?: (
    statusToDisplay:
      | RelativeCompRecStatus
      | CompRecommendationStatus
      | PhaseTimelineStatus
  ) => void;
};

export function CondensedTableStatusCellComponent({
  statusToDisplay,
  onClick,
}: Props): JSX.Element {
  const classes = useStyles();

  const upcaseStatusToDisplay = statusToDisplay.toUpperCase();

  const statusIsNeedsReview =
    upcaseStatusToDisplay === RelativeCompRecStatus.NEEDS_REVIEW ||
    upcaseStatusToDisplay === CompRecommendationStatus.NEEDS_REVIEW ||
    upcaseStatusToDisplay === PhaseTimelineStatus.NEEDS_REVIEW;

  const statusIsFuturePhase =
    upcaseStatusToDisplay === RelativeCompRecStatus.FUTURE_PHASE ||
    upcaseStatusToDisplay === CompRecommendationStatus.FUTURE_PHASE ||
    upcaseStatusToDisplay === PhaseTimelineStatus.FUTURE_PHASE;

  return (
    <Chip
      className={clsx(classes.chip, {
        [classes.accepted]:
          upcaseStatusToDisplay === RelativeCompRecStatus.ACCEPTED,
        [classes.rejected]:
          upcaseStatusToDisplay === RelativeCompRecStatus.REJECTED ||
          upcaseStatusToDisplay === PhaseTimelineStatus.DENIED,

        [classes.needsReview]:
          upcaseStatusToDisplay === RelativeCompRecStatus.NEEDS_REVIEW ||
          upcaseStatusToDisplay === CompRecommendationStatus.NEEDS_REVIEW ||
          upcaseStatusToDisplay === PhaseTimelineStatus.NEEDS_REVIEW,
        [classes.awaiting]:
          upcaseStatusToDisplay === RelativeCompRecStatus.AWAITING,
        [classes.futurePhase]:
          upcaseStatusToDisplay === RelativeCompRecStatus.FUTURE_PHASE ||
          upcaseStatusToDisplay === CompRecommendationStatus.FUTURE_PHASE ||
          upcaseStatusToDisplay === PhaseTimelineStatus.FUTURE_PHASE,
        [classes.late]:
          upcaseStatusToDisplay === RelativeCompRecStatus.LATE ||
          upcaseStatusToDisplay === CompRecommendationStatus.LATE ||
          upcaseStatusToDisplay === PhaseTimelineStatus.LATE,
        [classes.reviewed]:
          upcaseStatusToDisplay === RelativeCompRecStatus.REVIEWED ||
          upcaseStatusToDisplay === CompRecommendationStatus.REVIEWED ||
          upcaseStatusToDisplay === PhaseTimelineStatus.REVIEWED,
        [classes.skipped]:
          upcaseStatusToDisplay === RelativeCompRecStatus.SKIPPED ||
          upcaseStatusToDisplay === PhaseTimelineStatus.SKIPPED,
      })}
      onClick={onClick ? () => onClick(statusToDisplay) : undefined}
      label={
        <AssembleTypography
          variant={
            statusIsNeedsReview || statusIsFuturePhase
              ? "productTableChipSmall"
              : "productTableChip"
          }
        >
          {capitalizeEachWord(statusToDisplay.replace("_", " "))}
        </AssembleTypography>
      }
      size="small"
      data-intercom-target={"request-status"}
      data-cy={`status-cell-${statusToDisplay}`}
    />
  );
}
