import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  CreateLocationAdjustment,
  CreateLocationAdjustmentVariables,
  CreateLocationGroup,
  CreateLocationGroupVariables,
  DeleteLocationAdjustment,
  DeleteLocationAdjustmentVariables,
  DeleteLocationGroup,
  DeleteLocationGroupVariables,
  LocationAdjustmentInput,
  LocationAdjustmentUpdateInput,
  LocationGroupInput,
  LocationGroupUpdateInput,
  UpdateLocationAdjustment,
  UpdateLocationAdjustmentVariables,
  UpdateLocationGroup,
  UpdateLocationGroupVariables,
} from "../__generated__/graphql";

/*
  Our 'CreateLocationGroup' mutation not only creates a location group, but also
  allows us to map locations to it at the same time. In order to update these
  locations in cache, we need to add them in our mutation response fields with
  their new locationGroup ID. If we don't do this, locations that have changed
  (been mapped) may appear stale (unmapped).

  Since these are used in traditional form/button actions and not an autosaving
  workflow, we don't mind if active queries have to refetch. We can skip loading
  the full fields of the locationAdjustments (condition, adjustment) and
  locations (city, state, country). We only want to be responsible for fields
  that our mutation actually modifies.

  As a result, the queries for the adjustments and unmapped locations on the
  Settings page will still refetch when you create a new location group. (But
  again, that's OK!)
 */
const LOCATION_GROUP_FIELDS = gql`
  fragment LocationGroupFields on LocationGroup {
    id
    name
    description
    rankedAdjustmentIds
    createdAt
    updatedAt
    organization {
      id
    }
    author {
      id
    }
    locations {
      id
      locationGroupId
      locationGroup {
        id
      }
    }
    locationAdjustments {
      id
    }
  }
`;

const CREATE_LOCATION_GROUP = gql`
  ${LOCATION_GROUP_FIELDS}
  mutation CreateLocationGroup($data: LocationGroupInput!) {
    createOneLocationGroup(data: $data) {
      id
      locationGroups {
        ...LocationGroupFields
      }
    }
  }
`;

export function useCreateLocationGroup(): [
  (data: LocationGroupInput) => Promise<unknown>,
  { loading: boolean },
] {
  const [createLocationGroup, { loading }] = useMutation<
    CreateLocationGroup,
    CreateLocationGroupVariables
  >(CREATE_LOCATION_GROUP);

  const mutate = useCallback(
    ({ name, description, locationIds, marketId }: LocationGroupInput) =>
      createLocationGroup({
        variables: {
          data: { name, description, locationIds, marketId },
        },
      }),
    [createLocationGroup]
  );

  return [mutate, { loading }];
}

/*
  Updating the location group itself doesn't require any special cache updates,
  as Apollo can update the fields on the existing object by id. However, if we
  mapped or unmapped locations, we need to make sure that we fetch those in the
  response as well.

  Currently, we create our 'unmapped locations' list from a root query of all
  locations, only filtering on the client. We're not actually creating or
  destroying Locations here, so we don't need to touch anything. If we had a
  root query specifically for *unmapped* locations, we would need to make sure
  we add and remove Locations appropriately.
 */
const UPDATE_LOCATION_GROUP = gql`
  ${LOCATION_GROUP_FIELDS}
  mutation UpdateLocationGroup($id: Int!, $data: LocationGroupUpdateInput!) {
    updateOneLocationGroup(id: $id, data: $data) {
      locationGroup {
        ...LocationGroupFields
      }
      unmappedLocations {
        id
        locationGroupId
        locationGroup {
          id
        }
      }
    }
  }
`;

export function useUpdateLocationGroup(): [
  (id: number, data: LocationGroupUpdateInput) => Promise<unknown>,
  { loading: boolean },
] {
  const [updateLocationGroup, { loading }] = useMutation<
    UpdateLocationGroup,
    UpdateLocationGroupVariables
  >(UPDATE_LOCATION_GROUP);

  const mutate = useCallback(
    (id: number, data: LocationGroupUpdateInput) =>
      updateLocationGroup({
        variables: {
          id,
          data,
        },
      }),
    [updateLocationGroup]
  );

  return [mutate, { loading }];
}

/*
  Similar to our Create mutation, we need to be sure to update its parent
  Market's list of Location Groups. And similar to our Update mutation, this can
  cause Locations to become unmapped, so we ask for those in the response
  as well.

  Note that deleting the LocationGroup does not actually delete the Locations,
  just unmaps them. Apollo handles that cache update on its own.

  This probably strands LocationAdjustments in our cache, but they are harmless
  since access is limited through their (now nonexistent) LocationGroup.
 */
const DELETE_LOCATION_GROUP = gql`
  ${LOCATION_GROUP_FIELDS}
  mutation DeleteLocationGroup($id: Int!) {
    deleteOneLocationGroup(id: $id) {
      locationGroup {
        ...LocationGroupFields
      }
      market {
        id
        locationGroups {
          ...LocationGroupFields
        }
      }
      unmappedLocations {
        id
        locationGroupId
        locationGroup {
          id
        }
      }
    }
  }
`;

export function useDeleteLocationGroup(id: number): () => Promise<unknown> {
  const [deleteLocationGroup] = useMutation<
    DeleteLocationGroup,
    DeleteLocationGroupVariables
  >(DELETE_LOCATION_GROUP, { variables: { id } });

  return deleteLocationGroup;
}

/*
  There is no root query for LocationAdjustments, as they are only queried
  through their LocationGroup. We do need to add them to that LocationGroup's
  list of adjustments, though. We could change the API to resolve the group
  directly, but to minimize change we fetch it as a nested field here.

  Note that adding the adjustment also changes the group's rankedAdjustmentIds
 */
const CREATE_LOCATION_ADJUSTMENT = gql`
  mutation CreateLocationAdjustment($data: LocationAdjustmentInput!) {
    createOneLocationAdjustment(data: $data) {
      id
      adjustment
      condition
      description
      createdAt
      updatedAt
      locationGroup {
        id
        name
        rankedAdjustmentIds
        locationAdjustments {
          id
        }
      }
      author {
        id
        name
        email
      }
    }
  }
`;

export function useCreateLocationAdjustment(): [
  (data: LocationAdjustmentInput) => Promise<unknown>,
  { loading: boolean },
] {
  const [createLocationAdjustment, { loading }] = useMutation<
    CreateLocationAdjustment,
    CreateLocationAdjustmentVariables
  >(CREATE_LOCATION_ADJUSTMENT);

  const mutate = useCallback(
    (data: LocationAdjustmentInput) =>
      createLocationAdjustment({
        variables: {
          data,
        },
      }),
    [createLocationAdjustment]
  );

  return [mutate, { loading }];
}

const UPDATE_LOCATION_ADJUSTMENT = gql`
  mutation UpdateLocationAdjustment(
    $id: Int!
    $data: LocationAdjustmentUpdateInput!
  ) {
    updateOneLocationAdjustment(id: $id, data: $data) {
      id
      adjustment
      condition
      description
      updatedAt
      author {
        id
      }
    }
  }
`;

export function useUpdateLocationAdjustment(): [
  (id: number, data: LocationAdjustmentUpdateInput) => Promise<unknown>,
  { loading: boolean },
] {
  const [updateLocationAdjustment, { loading }] = useMutation<
    UpdateLocationAdjustment,
    UpdateLocationAdjustmentVariables
  >(UPDATE_LOCATION_ADJUSTMENT);

  const mutate = useCallback(
    (id: number, data: LocationAdjustmentUpdateInput) =>
      updateLocationAdjustment({
        variables: { id, data },
      }),
    [updateLocationAdjustment]
  );

  return [mutate, { loading }];
}

/*
  Similar to Create, we need to update the LocationGroup in cache to remove
  the adjustment. Since there's no root query for adjustments, and they are
  only found via their LocationGroup, we can strand the old one in cache.
 */
const DELETE_LOCATION_ADJUSTMENT = gql`
  mutation DeleteLocationAdjustment($id: Int!) {
    deleteOneLocationAdjustment(id: $id) {
      locationGroup {
        id
        rankedAdjustmentIds
        locationAdjustments {
          id
        }
      }
    }
  }
`;

export function useDeleteLocationAdjustment(
  id: number
): () => Promise<unknown> {
  const [deleteLocationAdjustment] = useMutation<
    DeleteLocationAdjustment,
    DeleteLocationAdjustmentVariables
  >(DELETE_LOCATION_ADJUSTMENT);

  return useCallback(
    () =>
      deleteLocationAdjustment({
        variables: { id },
      }),
    [deleteLocationAdjustment, id]
  );
}
