import { CurrencyCode } from "@asmbl/shared/constants";
import { money, Money } from "@asmbl/shared/money";
import {
  Box,
  Divider,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { memo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { DeleteIcon } from "src/components/AssembleIcons/Brand/DeleteIcon";
import { DragHandleIcon } from "src/components/AssembleIcons/Brand/DragHandleIcon";
import { Benefit } from "../../../../models/Benefits";
import { currencySymbol } from "../../../../models/Currency";
import { GRAY_4, GRAY_6, PURPLE_1, PURPLE_2, WHITE } from "../../../../theme";
import { NumberInput } from "../../../Form/NumberInput";
import { OrdinalCircle } from "./OrdinalCircle";

const MAX_NAME_CHARACTERS = 25;
const MAX_DESCRIPTION_CHARACTERS = 425;

const useStyles = makeStyles((theme) => ({
  row: {
    borderBottom: `1px solid ${GRAY_6}`,
    display: "flex",
    position: "relative",
    background: WHITE,
  },

  margin: {
    padding: theme.spacing(2.5, 2),
  },

  dragging: {},

  marginOrdinal: {
    "$row:hover &, $margin:focus-within &, $dragging &": {
      display: "none",
    },
  },

  marginDragHandle: {
    opacity: 0,
    color: GRAY_4,
    width: "1.5rem",
    "$row:hover &, $margin:focus-within &, $dragging &": {
      opacity: 1,
    },

    "&:focus-within": {
      color: PURPLE_1,
    },
  },

  fieldset: {
    flex: "1 1 100%",
  },
  fieldContainer: {
    display: "flex",
    alignItems: "center",
  },
  nameField: {
    padding: theme.spacing(2, 0),
  },
  valueField: {
    padding: theme.spacing(2, 0),
  },
  currencyAdornment: {
    color: GRAY_4,
    paddingRight: theme.spacing(1),
  },
  descriptionField: {
    padding: theme.spacing(2, 0),
  },
  fieldCounter: {
    color: GRAY_4,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  actions: {
    position: "absolute",
    height: "100%",
    top: 0,
    right: -theme.spacing(7),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    paddingTop: theme.spacing(2),
    opacity: 0,
    transition: "opacity 300ms",

    "$row:hover &": {
      opacity: 1,
    },

    "&:focus-within": {
      opacity: 1,
    },
  },
  actionButton: {
    marginRight: theme.spacing(2),
  },
}));

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: React.CSSProperties = {}
): React.CSSProperties => ({
  // change style if dragging
  border: isDragging ? `1px solid ${GRAY_4}` : undefined,
  boxSizing: isDragging ? "border-box" : undefined,
  boxShadow: isDragging ? "0px 5px 15px rgba(10, 36, 64, 0.13)" : undefined,

  // styles we need to apply on draggables
  ...draggableStyle,
});

interface Props {
  index: number;
  selectedCurrency: CurrencyCode;
  benefit: Benefit;
  onChangeName: (benefit: Benefit, name: string) => unknown;
  onChangeValue: (benefit: Benefit, value: Money | null) => unknown;
  onChangeDescription: (benefit: Benefit, description: string) => unknown;
  onDelete: (benefit: Benefit) => unknown;
  hideDelete?: boolean;
}

export const BenefitInputRow = memo(function BenefitInputRow({
  index,
  selectedCurrency,
  benefit,
  onChangeName,
  onChangeValue,
  onChangeDescription,
  onDelete,
  hideDelete = false,
}: Props): JSX.Element {
  const { name = "", description = "" } = benefit;

  const classes = useStyles();

  return (
    <Draggable draggableId={benefit.rank} index={index}>
      {(provided, snapshot) => (
        <Box
          className={`${classes.row} ${
            snapshot.isDragging ? classes.dragging : ""
          }`}
          ref={(el: HTMLElement | null) => {
            provided.innerRef(el);
          }}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <Box className={classes.margin}>
            <OrdinalCircle
              className={classes.marginOrdinal}
              ordinal={index + 1}
            />
            <div className={classes.marginDragHandle}>
              <div {...provided.dragHandleProps}>
                <DragHandleIcon color={GRAY_4} height="24px" width="24px" />
              </div>
            </div>
          </Box>
          <Box className={classes.fieldset}>
            <Box className={classes.fieldContainer}>
              <TextField
                placeholder="Benefit title..."
                value={name}
                onChange={(e) => onChangeName(benefit, e.target.value)}
                fullWidth
                InputProps={{
                  className: classes.nameField,
                  disableUnderline: true,
                }}
                inputProps={{
                  maxLength: MAX_NAME_CHARACTERS,
                  "aria-label": "Benefit Title",
                }}
              />
              <Typography className={classes.fieldCounter}>
                {name.length}/{MAX_NAME_CHARACTERS}
              </Typography>
            </Box>
            <Divider />
            <NumberInput
              variant="standard"
              placeholder="Estimated value..."
              startAdornment={
                benefit.value && (
                  <span className={classes.currencyAdornment}>
                    {currencySymbol(selectedCurrency)}
                  </span>
                )
              }
              value={benefit.value?.value ?? null}
              onValueChange={({ floatValue }) =>
                onChangeValue(
                  benefit,
                  floatValue != null
                    ? money(floatValue, selectedCurrency)
                    : null
                )
              }
              fullWidth
              InputProps={{
                className: classes.valueField,
                disableUnderline: true,
              }}
              inputProps={{
                "aria-label": "Benefit Value",
              }}
            />
            <Divider />
            <Box className={classes.fieldContainer}>
              <TextField
                aria-label="Benefit Description"
                placeholder="Benefit description..."
                value={description}
                onChange={(e) => onChangeDescription(benefit, e.target.value)}
                fullWidth
                multiline
                rows={3}
                rowsMax={8}
                InputProps={{
                  className: classes.descriptionField,
                  disableUnderline: true,
                }}
                inputProps={{
                  maxLength: MAX_DESCRIPTION_CHARACTERS,
                  "aria-label": "Benefit Description",
                }}
              />
              <Typography className={classes.fieldCounter}>
                {description.length}/{MAX_DESCRIPTION_CHARACTERS}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.actions}>
            {!hideDelete && (
              <Tooltip title="Delete Benefit" placement="top">
                <IconButton
                  size="small"
                  className={classes.actionButton}
                  onClick={() => onDelete(benefit)}
                >
                  <DeleteIcon
                    height="24px"
                    width="24px"
                    color={GRAY_4}
                    hoverColor={PURPLE_2}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      )}
    </Draggable>
  );
});
