import {
  formatCurrency,
  money,
  preferredPrice,
  unitsOfTotalGrossValue,
} from "@asmbl/shared/money";
import {
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import {
  Allocation,
  AllocationUnit,
  BudgetType,
  convertAllocationToCash,
} from "../../../models/Budget";
import { currencySymbol } from "../../../models/Currency";
import { GRAY_4 } from "../../../theme";
import { AssembleLabel } from "../../Form/AssembleLabel";
import { NumberInput } from "../../Form/NumberInput";
import { Valuation } from "./types";

type Props = {
  value: Allocation;
  valuation: Valuation;
  onChange: (value: Allocation) => unknown;
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    columnGap: theme.spacing(1),
  },
  select: {
    boxSizing: "border-box",
    width: "8rem",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    top: "calc(50% - 8px)",
    height: "16px",
    right: "16px",
  },
  input: {
    flex: "1 0 0", // Take up whatever space is left
  },
}));

export function EquityInput({
  value,
  valuation,
  onChange,
}: Props): JSX.Element {
  const classes = useStyles();
  const htmlId = "comp-cycle-new-equity-input";

  const cashValue =
    value.value !== null ? convertAllocationToCash(value, valuation) : null;
  const unitValue =
    value.value !== null
      ? value.unit === AllocationUnit.UNIT
        ? value.value
        : Math.round(
            unitsOfTotalGrossValue(
              valuation.fdso,
              valuation.valuation,
              value.value
            )
          )
      : null;

  return (
    <div className={classes.container}>
      <AssembleLabel htmlFor={htmlId}>
        {`${BudgetType.EQUITY} (
        ${formatCurrency(preferredPrice(valuation.fdso, valuation.valuation), {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })} per Unit)`}
      </AssembleLabel>
      <Select
        classes={{ root: classes.select, icon: classes.icon }}
        value={value.unit}
        onChange={({ target: { value } }) => {
          onChange(
            value === "unit"
              ? { unit: AllocationUnit.UNIT, value: unitValue }
              : { unit: AllocationUnit.CASH, value: cashValue }
          );
        }}
        variant="outlined"
        IconComponent={AssembleIconComponent}
      >
        <MenuItem value="cash">Cash</MenuItem>
        <MenuItem value="unit">Units</MenuItem>
      </Select>
      {value.unit === AllocationUnit.CASH && (
        <NumberInput
          className={classes.input}
          id={htmlId}
          placeholder="0"
          thousandSeparator
          value={cashValue?.value ?? null}
          onValueChange={(e) =>
            onChange({
              unit: AllocationUnit.CASH,
              value:
                e.floatValue === undefined
                  ? null
                  : money(e.floatValue, valuation.valuation.currency),
            })
          }
          allowNegative={false}
          startAdornment={
            <InputAdornment position="start">
              {currencySymbol(valuation.valuation.currency)}
            </InputAdornment>
          }
        />
      )}
      {value.unit === AllocationUnit.UNIT && (
        <NumberInput
          className={classes.input}
          id={htmlId}
          placeholder="000,000"
          thousandSeparator
          value={unitValue}
          onValueChange={(e) =>
            onChange({ unit: AllocationUnit.UNIT, value: e.floatValue ?? null })
          }
          allowNegative={false}
          startAdornment={<InputAdornment position="start">#</InputAdornment>}
        />
      )}
    </div>
  );
}

function AssembleIconComponent(props: Record<string, unknown>) {
  return (
    <div {...props}>
      <ChevronDownIcon color={GRAY_4} />
    </div>
  );
}
