import { contramap } from "@asmbl/shared/sort";
import { makeStyles } from "@material-ui/core";
import { UserAvatar } from "../../../../UserAvatar";
import { LocationRow } from "../LocationsTable";

const useStyles = makeStyles(() => ({
  cell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export function LocationAuthorCell({
  value,
}: {
  value: { id: number; name: string | null; photoURL: string | null } | null;
}): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.cell}>
      {value != null ? (
        <UserAvatar
          displayName={value.name}
          photoURL={value.photoURL}
          showTooltip
        />
      ) : (
        "N/A"
      )}
    </div>
  );
}

Object.assign(LocationAuthorCell, {
  Cell: LocationAuthorCell,
  id: "mappedBy" as const,
  align: "center" as const,
  Header: "Mapped By" as const,
  accessor: "mappedBy" as const,
  width: 1,
  ordering: contramap((location: LocationRow) => location.mappedBy?.name ?? ""),
});
