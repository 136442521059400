export const SSO_ERROR = "sso_error";
export const WORK_OS_COOKIE = "workosToken";

// 7 days in seconds
export const SSO_AUTH_EXPIRATION_S = 7 * 24 * 60 * 60;

export enum AuthError {
  // Apollo related errors
  UNAUTHENTICATED = "UNAUTHENTICATED",
  FORBIDDEN = "FORBIDDEN",
  // Assemble Related errors
  SSO_ONLY = "SSO_ONLY",
  NO_USER = "NO_USER",
}

export const AuthErrorMessage: Record<AuthError, string> = {
  [AuthError.SSO_ONLY]: "Your organization requires you to log in with SSO.",
  [AuthError.NO_USER]:
    "We're sorry, it appears you haven't been invited to an organization yet. " +
    "Please contact your compensation manager to gain access.",
  [AuthError.UNAUTHENTICATED]: "User is not authenticated.",
  [AuthError.FORBIDDEN]: "",
};
