import { cx } from "@emotion/css";
import { makeStyles, TableCell, TableRow } from "@material-ui/core";
import { AssembleTypography } from "../../components/AssembleTypography";
import { BLUE_1 } from "../../theme";
import { useExcludedBandNames } from "../LadderDetail/ExcludedBandNameContext";

export const useStyles = makeStyles(() => ({
  disabledText: {
    color: BLUE_1,
    opacity: 0.2,
  },
}));

type Props = {
  bandNames: string[];
  isEditing?: boolean;
};
export function PositionDetailTableHeader({
  bandNames,
  isEditing = false,
}: Props) {
  const classes = useStyles();
  const { excludedBandNames } = useExcludedBandNames();

  return (
    <TableRow>
      <TableCell variant="head" width="15%">
        <AssembleTypography variant="productTableHeader">
          Band point
        </AssembleTypography>
      </TableCell>
      {bandNames.map((name) => (
        <TableCell key={name} variant="head" align="right" width="15%">
          <AssembleTypography
            variant="productTableHeader"
            className={cx({
              [classes.disabledText]: excludedBandNames.has(name) && !isEditing,
            })}
          >
            {name}
          </AssembleTypography>
        </TableCell>
      ))}
      <TableCell width="10%" align="right">
        <AssembleTypography variant="productTableHeader">
          Annualized
        </AssembleTypography>
      </TableCell>
    </TableRow>
  );
}
