import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { ReactNode } from "react";
import { GRAY_4 } from "../../theme";
import { ChevronDownIcon } from "../AssembleIcons/Brand/ChevronDownIcon";

interface Option {
  label: string | ReactNode;
  value: string | number;
  disabled?: boolean;
}

interface Props<T> {
  id: string;
  label?: string;
  value?: string | number;
  options?: Option[];
  onChange: (value: T) => unknown;
  defaultValue?: string | number;
  size?: "small" | "medium" | "large";
  children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    textAlign: "left",
  },
  icon: {
    height: "16px",
    right: theme.spacing(0.75),
    // The caret icon is a little top heavy, so centering it is a bit precise
    top: "calc(50% - 8px)",
    transition: "transform cubic-bezier(0.4, 0, 0.2, 1) 275ms",
    "&.MuiSelect-iconOpen": {
      top: "calc(50% - 5px)",
    },
  },
  small: {
    height: "2rem",
    "& .MuiSelect-selectMenu": {
      paddingBlock: "0.375rem",
      fontSize: "0.8125rem",
      lineHeight: "1.125rem",
    },
  },
  medium: {
    height: "2.5rem",
    "& .MuiSelect-selectMenu": {
      paddingBlock: "0.5625rem",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
    },
  },
  large: {
    height: "3.75rem",
    "& .MuiSelect-selectMenu": {
      paddingBlock: "1.1125rem",
      fontSize: "1rem",
      lineHeight: "1.4rem",
    },
  },

  input: {
    paddingLeft: theme.spacing(1.5),
  },
}));

export function SelectField<T>({
  id,
  label,
  value,
  options,
  onChange,
  size = "large",
  children,
  defaultValue,
}: Props<T>): JSX.Element {
  const classes = useStyles();

  return (
    <FormControl className={classes[size]} variant="outlined" fullWidth>
      <Select
        id={id}
        labelId={`${id}-label`}
        classes={{
          root: classes.root,
          icon: classes.icon,
        }}
        inputProps={{
          className: classes.input,
        }}
        fullWidth
        variant="outlined"
        label={label}
        value={value}
        onChange={(e) => onChange(e.target.value as T)}
        IconComponent={AssembleIconComponent}
        displayEmpty
        defaultValue={defaultValue}
      >
        {options == null
          ? children
          : options.map(({ value, label, disabled }) => (
              <MenuItem key={value} value={value} disabled={disabled}>
                {label}
              </MenuItem>
            ))}
      </Select>
      <InputLabel id={`${id}-label`} shrink>
        {label}
      </InputLabel>
    </FormControl>
  );
}

function AssembleIconComponent(props: { className: string }) {
  return (
    <div {...props} data-chromatic="ignore">
      <ChevronDownIcon inline color={GRAY_4} />
    </div>
  );
}
