import { gql } from "@apollo/client";
import {
  ScalarDictionaryWithCustom as FlatFileRecord,
  IDataHookResponse,
} from "@flatfile/react";
import { EmployeeIdentifierFields } from "src/models/csv/Fields/EmployeeIdentifierFields";
import {
  UploadLevelsButton_employee as Employee,
  LevelEmployeesInput,
  UploadLevelsButton_position as Position,
} from "../__generated__/graphql";
import { matchEmployee } from "../models/csv/EmployeeCSV";
import { getPositionFields, matchPosition } from "../models/csv/PositionCSV";
import { useLevelEmployees } from "../mutations/Employments";
import { AssembleButton } from "./AssembleButton/AssembleButton";
import { AssembleFlatfileButton } from "./AssembleFlatfileButton";

type Props = {
  employees: Employee[];
  positions: Position[];
};

export function UploadLevelsButton({
  employees,
  positions,
}: Props): JSX.Element {
  const levelEmployees = useLevelEmployees();
  const validateRecord = recordValidator(employees, positions);

  const pos = new Set<string>(positions.map((position) => position.name));

  const departments = new Set<string>(
    positions.map((position) => position.ladder.department.name)
  );

  const ladders = new Set<string>(
    positions.map((position) => position.ladder.name)
  );

  const levels = new Set(positions.map((position) => position.level));

  return (
    <AssembleFlatfileButton
      onRecordInit={validateRecord}
      onRecordChange={validateRecord}
      settings={{
        title: "Add your leveling data",
        type: "level",
        fullScreen: true,
        fields: [
          ...EmployeeIdentifierFields(),
          ...getPositionFields(pos, departments, ladders, levels),
        ],
      }}
      onData={async (results) => {
        await levelEmployees(
          parseData(results.validData, employees, positions)
        );

        return "Successfully updated employee levels";
      }}
      render={(_, launch) => (
        <AssembleButton
          onClick={launch}
          variant="contained"
          label="Bulk level employees"
          size="medium"
        />
      )}
    />
  );
}

function parseData(
  validData: unknown[],
  employees: Employee[],
  positions: Position[]
): LevelEmployeesInput[] {
  return validData.map((row) => {
    const [position] = matchPosition(row as FlatFileRecord, positions);
    const [employee] = matchEmployee(row as FlatFileRecord, employees);

    const employeeId = employee?.id;
    const positionId = position?.id;

    if (employeeId == null) {
      throw new Error("Employee Not Found");
    }

    if (positionId == null) {
      throw new Error("Position Not Found");
    }

    return {
      employeeId,
      positionId,
    };
  });
}

function recordValidator(employees: Employee[], positions: Position[]) {
  return function validateRecord(record: FlatFileRecord): IDataHookResponse {
    const [, employeeMatchingInfo] = matchEmployee(record, employees);
    const [, positionMatchingInfo] = matchPosition(record, positions);

    return {
      ...employeeMatchingInfo,
      ...positionMatchingInfo,
    };
  };
}

UploadLevelsButton.fragments = {
  organization: gql`
    fragment UploadLevelsButton_organization on Organization {
      id
      name
    }
  `,
  employee: gql`
    fragment UploadLevelsButton_employee on Employee {
      id
      employeeNumber
      email
      personalEmail
    }
  `,
  position: gql`
    fragment UploadLevelsButton_position on Position {
      id
      name
      level
      ladder {
        id
        name
        department {
          id
          name
        }
      }
    }
  `,
};
