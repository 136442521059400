import { PrimaryRoleName } from "@asmbl/shared/permissions";
import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { capitalCase } from "change-case";
import { useCallback, useState } from "react";
import { CheckIcon } from "src/components/AssembleIcons/Brand/CheckIcon";
import { CloseCircleIcon } from "src/components/AssembleIcons/Brand/CloseCircleIcon";
import { InfoIcon } from "src/components/AssembleIcons/Brand/InfoIcon";
import { GRAY_2, GRAY_4, GREEN_2, PURPLE_2, RED, theme } from "../../../theme";
import AssembleDialog from "../../AssembleDialog";
import { ExplanatoryTooltip } from "../../ExplanatoryTooltip";
import RadioCard from "../../Form/RadioCard";
import FormHeader from "../FormHeader";
import { CancelledInviteBanner } from "./CancelledInviteBanner";
import { DisabledWarningBanner } from "./DisabledWarningBanner";

interface PrimaryRoleFormProps {
  userName: string | null;
  primaryRoleName: PrimaryRoleName | null;
  onRoleChange(roleName: PrimaryRoleName): unknown;
  onSetIsDisabled(isDisabled: boolean): unknown;
  isDisabled: boolean;
  isSelf: boolean;
  isUserInvite: boolean;
}

const useStyles = makeStyles(() => ({
  formCard: { height: "167px" },
  formCardLabel: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  roleName: {
    fontWeight: "normal",
  },
  accessCardIcon: {
    width: "24px",
    marginRight: theme.spacing(1),
  },
  accessCardText: {
    color: GRAY_2,
  },
  accessCardGoodForContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "auto",
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(4),
    color: GRAY_4,
  },
  accessCardGoodForTitle: {
    textTransform: "uppercase",
    fontSize: "10px",
    fontWeight: 700,
    marginRight: theme.spacing(0.5),
    color: GRAY_4,
  },
  accessCardGoodForText: {
    fontSize: "12px",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(0.5),
  },
  header: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px ${theme.spacing(
      0.5
    )}px ${theme.spacing(1)}px !important`,
  },
}));

export function PrimaryRoleForm({
  userName,
  primaryRoleName,
  onRoleChange,
  onSetIsDisabled,
  isDisabled,
  isSelf,
  isUserInvite,
}: PrimaryRoleFormProps): JSX.Element {
  const classes = useStyles();
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [roleToConfirm, setRoleToConfirm] = useState<PrimaryRoleName>();

  const handleRoleChange = useCallback(
    (roleName: PrimaryRoleName) => {
      if (isSelf || roleName === PrimaryRoleName.SYSTEM_ADMIN) {
        setIsConfirming(true);
        setRoleToConfirm(roleName);
      } else {
        onRoleChange(roleName);
      }
    },
    [isSelf, onRoleChange]
  );

  const handleCancel = useCallback(() => setIsConfirming(false), []);
  const handleConfirm = useCallback(() => {
    if (roleToConfirm === undefined) {
      return;
    }
    onRoleChange(roleToConfirm);
    setIsConfirming(false);
  }, [roleToConfirm, onRoleChange]);

  return (
    <Box px={5} py={4}>
      {isConfirming && (
        <AssembleDialog
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          title={
            isSelf
              ? "Are you sure you want to change your own role?"
              : "Do you want to change this person's Primary Access Role?"
          }
          text={
            isSelf
              ? "Changing your own role may cause you to lose some of your existing access."
              : "Changing this person's access level will cause them to lose all Compensation Band access."
          }
          cancelButtonText="Back to Editing"
          confirmButtonText="Yes, Change Role"
          confirmButtonVariant="negative"
        />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <FormHeader
          description={`Select the appropriate level of access and permissions for ${
            userName ?? "this user"
          }.`}
          header={
            <Box display="flex">
              Primary Access Role & Permissions <PrimaryRoleTooltip />
            </Box>
          }
          disabled={isDisabled}
        />
        {!isSelf && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onSetIsDisabled(!isDisabled)}
            disabled={isDisabled && isUserInvite}
          >
            {isUserInvite
              ? "Cancel Invite"
              : isDisabled
                ? "Enable Account"
                : "Disable Account"}
          </Button>
        )}
      </Box>
      <CancelledInviteBanner
        userInviteCancelled={isDisabled && isUserInvite}
        userName={userName}
      />
      <DisabledWarningBanner
        userIsDisabled={isDisabled && !isUserInvite}
        userName={userName}
      />
      <Box display="flex" justifyContent="space-between">
        <RadioGroup
          onChange={(e) => handleRoleChange(e.target.value as PrimaryRoleName)}
          value={primaryRoleName ?? ""}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.header}>
              <Typography variant="h5">Administrative Access</Typography>
            </Grid>
            <Grid item xs={6}>
              <RadioCard
                disabled={isDisabled}
                className={classes.formCard}
                classes={{ label: classes.formCardLabel }}
                label={
                  <>
                    <Typography variant="h6" className={classes.roleName}>
                      {capitalCase(PrimaryRoleName.FULL_ACCESS)}
                    </Typography>
                    <Box mt={1} className={classes.rowContainer}>
                      {roleDescriptions[PrimaryRoleName.FULL_ACCESS].check.map(
                        (text, idx) => (
                          <AccessCardRow key={idx} isCheck text={text} />
                        )
                      )}
                      {roleDescriptions[
                        PrimaryRoleName.FULL_ACCESS
                      ].noCheck.map((text, idx) => (
                        <AccessCardRow key={idx} text={text} />
                      ))}
                    </Box>
                    <AccessCardGoodFor
                      text={
                        roleDescriptions[PrimaryRoleName.FULL_ACCESS].goodFor
                      }
                    />
                  </>
                }
                value={PrimaryRoleName.FULL_ACCESS}
                isSelected={primaryRoleName === PrimaryRoleName.FULL_ACCESS}
              />
            </Grid>
            <Grid item xs={6}>
              <RadioCard
                disabled={isDisabled}
                className={classes.formCard}
                classes={{ label: classes.formCardLabel }}
                label={
                  <>
                    <Typography variant="h6" className={classes.roleName}>
                      {capitalCase(PrimaryRoleName.SYSTEM_ADMIN)}
                    </Typography>
                    <Box mt={1} className={classes.rowContainer}>
                      {roleDescriptions[PrimaryRoleName.SYSTEM_ADMIN].check.map(
                        (text, idx) => (
                          <AccessCardRow key={idx} isCheck text={text} />
                        )
                      )}
                      {roleDescriptions[
                        PrimaryRoleName.SYSTEM_ADMIN
                      ].noCheck.map((text, idx) => (
                        <AccessCardRow key={idx} text={text} />
                      ))}
                    </Box>
                    <AccessCardGoodFor
                      text={
                        roleDescriptions[PrimaryRoleName.SYSTEM_ADMIN].goodFor
                      }
                    />
                  </>
                }
                value={PrimaryRoleName.SYSTEM_ADMIN}
                isSelected={primaryRoleName === PrimaryRoleName.SYSTEM_ADMIN}
              />
            </Grid>
            <Grid item xs={12} className={classes.header}>
              <Typography variant="h5">Scoped Access</Typography>
            </Grid>
            <Grid item xs={6}>
              <RadioCard
                disabled={isDisabled}
                className={classes.formCard}
                classes={{ label: classes.formCardLabel }}
                label={
                  <>
                    <Typography variant="h6" className={classes.roleName}>
                      {capitalCase(PrimaryRoleName.BASIC_VIEWER)}
                    </Typography>
                    <Box mt={1} className={classes.rowContainer}>
                      {roleDescriptions[PrimaryRoleName.BASIC_VIEWER].check.map(
                        (text, idx) => (
                          <AccessCardRow key={idx} isCheck text={text} />
                        )
                      )}
                      {roleDescriptions[
                        PrimaryRoleName.BASIC_VIEWER
                      ].noCheck.map((text, idx) => (
                        <AccessCardRow key={idx} text={text} />
                      ))}
                    </Box>
                    <AccessCardGoodFor
                      text={
                        roleDescriptions[PrimaryRoleName.BASIC_VIEWER].goodFor
                      }
                    />
                  </>
                }
                value={PrimaryRoleName.BASIC_VIEWER}
                isSelected={primaryRoleName === PrimaryRoleName.BASIC_VIEWER}
              />
            </Grid>
            <Grid item xs={6}>
              <RadioCard
                disabled={isDisabled}
                className={classes.formCard}
                classes={{ label: classes.formCardLabel }}
                label={
                  <>
                    <Typography variant="h6" className={classes.roleName}>
                      {PrimaryRoleName.HRBP}
                    </Typography>
                    <Box mt={1} className={classes.rowContainer}>
                      {roleDescriptions[PrimaryRoleName.HRBP].check.map(
                        (text, idx) => (
                          <AccessCardRow key={idx} isCheck text={text} />
                        )
                      )}
                      {roleDescriptions[PrimaryRoleName.HRBP].noCheck.map(
                        (text, idx) => (
                          <AccessCardRow key={idx} text={text} />
                        )
                      )}
                    </Box>
                    <AccessCardGoodFor
                      text={roleDescriptions[PrimaryRoleName.HRBP].goodFor}
                    />
                  </>
                }
                value={PrimaryRoleName.HRBP}
                isSelected={primaryRoleName === PrimaryRoleName.HRBP}
              />
            </Grid>
            <Grid item xs={6}>
              <RadioCard
                disabled={isDisabled}
                className={classes.formCard}
                classes={{ label: classes.formCardLabel }}
                label={
                  <>
                    <Typography variant="h6" className={classes.roleName}>
                      {capitalCase(PrimaryRoleName.RECRUITER)}
                    </Typography>
                    <Box mt={1} className={classes.rowContainer}>
                      {roleDescriptions[PrimaryRoleName.RECRUITER].check.map(
                        (text, idx) => (
                          <AccessCardRow key={idx} isCheck text={text} />
                        )
                      )}
                      {roleDescriptions[PrimaryRoleName.RECRUITER].noCheck.map(
                        (text, idx) => (
                          <AccessCardRow key={idx} text={text} />
                        )
                      )}
                    </Box>
                    <AccessCardGoodFor
                      text={roleDescriptions[PrimaryRoleName.RECRUITER].goodFor}
                    />
                  </>
                }
                value={PrimaryRoleName.RECRUITER}
                isSelected={primaryRoleName === PrimaryRoleName.RECRUITER}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </Box>
    </Box>
  );
}

function PrimaryRoleTooltip(): JSX.Element {
  return (
    <Box ml={1}>
      <ExplanatoryTooltip
        title="Primary Access Role & Permissions"
        body={
          <>
            The <b>Primary Access Role</b> determines which areas (Job
            Structure, Offers Sheets, Access Controls, and more) of your
            organization a person has access to.
          </>
        }
        placement="top"
        width="400px"
      >
        <IconButton size="small">
          <InfoIcon color={GRAY_4} hoverColor={PURPLE_2} />
        </IconButton>
      </ExplanatoryTooltip>
    </Box>
  );
}

export function AccessCardRow({
  isCheck = false,
  text,
}: {
  isCheck?: boolean;
  text: string;
}): JSX.Element {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="flex-start">
      <Box className={classes.accessCardIcon}>
        {isCheck ? (
          <CheckIcon color={GREEN_2} height="24px" width="24px" />
        ) : (
          <CloseCircleIcon color={RED} height="24px" width="24px" />
        )}
      </Box>
      <Typography variant="body2" className={classes.accessCardText}>
        {text}
      </Typography>
    </Box>
  );
}

export function AccessCardGoodFor({ text }: { text: string }): JSX.Element {
  const classes = useStyles();
  return (
    <Box className={classes.accessCardGoodForContainer}>
      <Typography className={classes.accessCardGoodForTitle}>
        Good for:
      </Typography>
      <Typography className={classes.accessCardGoodForText}>{text}</Typography>
    </Box>
  );
}

export const roleDescriptions: {
  [key: string]: { check: string[]; noCheck: string[]; goodFor: string };
} = {
  [PrimaryRoleName.FULL_ACCESS]: {
    check: [
      "Can view and edit everything, including Compensation and Job Structure, and can add people to your organization",
    ],
    noCheck: [],
    goodFor: "Compensation Managers, VPs of People, etc.",
  },
  [PrimaryRoleName.SYSTEM_ADMIN]: {
    check: [
      "Can edit Permissions and Access Control, and can view Job Structure and Compensation Philosophy",
    ],
    noCheck: ["Can't edit anything else"],
    goodFor: "IT Professionals, Contractors, etc.",
  },
  [PrimaryRoleName.RECRUITER]: {
    check: ["Has Basic Viewer access and can edit Offer Sheets"],
    noCheck: ["Can't edit Access Control or Job Structure"],
    goodFor: "Recruiters, Hiring Managers, etc.",
  },
  [PrimaryRoleName.BASIC_VIEWER]: {
    check: [
      "Can always view Job Structure, Compensation Philosophy, and Compensation Bands when assigned",
    ],
    noCheck: ["Can't edit or view anything else"],
    goodFor: "Anyone in the organization",
  },
  [PrimaryRoleName.HRBP]: {
    check: [
      "Has Basic Viewer access and any employee data for the managers they support",
    ],
    noCheck: ["Can't edit Access Control, Job Structure, or Comp Bands"],
    goodFor: "HRBPs",
  },
};
