import { gql } from "@apollo/client";
import { formatCurrency } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { mapMaybe } from "@asmbl/shared/utils";
import { Box, DialogContent, makeStyles, Typography } from "@material-ui/core";
import { useEffect, useRef } from "react";
import { CompCycleSummaryContent2_compRecommendation } from "../../__generated__/graphql";
import { CompItemRecommendationTypeTitle } from "../../models/CompRecommendation";
import { GRAY_1, GRAY_4, WHITE } from "../../theme";
import { CompNoteModalDialogAuthoredContent } from "./CompNoteModalDialogAuthoredContent";
import { CompNoteModalDialogReceivedContent } from "./CompNoteModalDialogReceivedContent";

type Props = {
  compRecommendation: CompCycleSummaryContent2_compRecommendation;
  viewerId: number | undefined;
};

const useStyles = makeStyles((theme) => ({
  noteTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "flex-start",
    margin: theme.spacing(2, 3, 0, 3),
  },
  authoredNoteTitle: {
    color: WHITE,
    fontWeight: "bold",
    lineHeight: "140%",
    fontSize: "10px",
    textTransform: "uppercase",
    opacity: 0.75,
  },
  authoredNoteValue: {
    color: WHITE,
    letterSpacing: "-0.5px",
    fontSize: "14px",
    lineHeight: "155%",
    fontWeight: "bold",
  },
  receivedNoteTitle: {
    color: GRAY_4,
    fontSize: "10px",
    fontWeight: "bold",
    lineHeight: "140%",
    textTransform: "uppercase",
  },
  receivedNoteValue: {
    color: GRAY_1,
    letterSpacing: "-0.5px",
    fontSize: "14px",
    lineHeight: "155%",
    fontWeight: "bold",
  },
}));

export function CompCycleSummaryContent2({
  compRecommendation,
  viewerId,
}: Props): JSX.Element {
  const classes = useStyles();
  const bottomOfContentRef = useRef<HTMLElement>(null);

  const scrollToBottom = () => {
    bottomOfContentRef.current?.scrollIntoView(false);
  };

  useEffect(() => {
    scrollToBottom();
  }, [compRecommendation]);

  const itemContentBubbles = mapMaybe(
    compRecommendation.allSubmittedItems,
    (item) => {
      if (
        (item.author.id !== viewerId && item.submittedAt === null) ||
        item.note === null ||
        item.note.length === 0
      ) {
        return null;
      }

      const value =
        item.recommendedCashValue?.value !== undefined
          ? formatCurrency(item.recommendedCashValue, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
          : "";
      const title = CompItemRecommendationTypeTitle[item.recommendationType];

      if (item.author.id === viewerId) {
        return {
          timestamp: new Date(item.submittedAt ?? new Date()),
          content: (
            <CompNoteModalDialogAuthoredContent
              key={`item-${item.id}`}
              note={item.note}
              timestamp={item.submittedAt as string}
            >
              <div className={classes.noteTitleContainer}>
                <Typography className={classes.authoredNoteTitle}>
                  {title}
                </Typography>
                <Typography className={classes.authoredNoteValue}>
                  {value}
                </Typography>
              </div>
            </CompNoteModalDialogAuthoredContent>
          ),
        };
      } else {
        return {
          timestamp: new Date(item.submittedAt ?? new Date()),
          content: (
            <CompNoteModalDialogReceivedContent
              key={`item-${item.id}`}
              author={item.author}
              displayTimestamp={true}
              timestamp={item.submittedAt as string}
              note={item.note}
            >
              <div className={classes.noteTitleContainer}>
                <Typography className={classes.receivedNoteTitle}>
                  {title}
                </Typography>
                <Typography className={classes.receivedNoteValue}>
                  {value}
                </Typography>
              </div>
            </CompNoteModalDialogReceivedContent>
          ),
        };
      }
    }
  );

  const reviewContentBubbles = mapMaybe(
    compRecommendation.allSubmittedReviews,
    (review) => {
      if (review.note === null || review.note.length === 0) {
        return null;
      }
      if (review.author.id === viewerId) {
        return {
          timestamp: new Date(review.submittedAt ?? new Date()),
          content: (
            <CompNoteModalDialogAuthoredContent
              key={`review-${review.id}`}
              note={review.note}
              timestamp={review.submittedAt as string}
            />
          ),
        };
      } else {
        return {
          timestamp: new Date(review.submittedAt ?? new Date()),
          content: (
            <CompNoteModalDialogReceivedContent
              key={`review-${review.id}`}
              author={review.author}
              displayTimestamp={true}
              timestamp={review.submittedAt as string}
              note={review.note}
            />
          ),
        };
      }
    }
  );

  const interleavedContentBubbles = [
    ...itemContentBubbles,
    ...reviewContentBubbles,
  ]
    .sort(contramap((bubble) => bubble.timestamp))
    .map((bubble) => bubble.content);

  return (
    <DialogContent>
      {interleavedContentBubbles}
      <Box ref={bottomOfContentRef} />
    </DialogContent>
  );
}

const recItem = gql`
  fragment CompCycleSummaryContent2_recItem on RecItem2 {
    id
    author {
      id
      email
      name
      photoURL
    }
    note
    submittedAt
    recommendedCashValue
    recommendationType
  }
`;
CompCycleSummaryContent2.fragments = {
  compRecommendation: gql`
    ${recItem}
    fragment CompCycleSummaryContent2_compRecommendation on CompRecommendation2 {
      subjectId
      compCycleId
      allSubmittedItems {
        ...CompCycleSummaryContent2_recItem
      }
      allSubmittedReviews {
        id
        author {
          id
          email
          name
          photoURL
        }
        note
        submittedAt
      }
    }
  `,
};
