import { gql } from "@apollo/client";
import { zero } from "@asmbl/shared/money";
import { basicComparator, contramap } from "@asmbl/shared/sort";
import { formatNumeral } from "@asmbl/shared/utils";
import {
  makeStyles,
  Paper,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { useState } from "react";
import { CostToMoveEmployeesTable_employeeCostToMove as EmployeeCostToMove } from "src/__generated__/graphql";
import { NoResultsIcon } from "src/components/AssembleIcons/Brand/NoResultsIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { useCurrencies } from "src/components/CurrenciesContext";
import { TableHeaderBar } from "src/components/Table/TableHeaderBar";
import {
  WireTable,
  WireTableCell,
  WireTableHead,
  WireTableHeaderCell,
} from "src/components/Table/WireTable";
import { getSimpleCashLabel } from "src/models/Currency";
import { GRAY_4 } from "src/theme";
import { CostToMoveExport } from "./CostToMoveExport";

const useStyles = makeStyles((theme) => ({
  downloadButton: {
    display: "flex",
    alignItems: "center",
  },
  tableRoot: { border: "none" },
  emptyTable: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

type Props = {
  employeeCTMs: EmployeeCostToMove[];
  filter: { [key: string]: number[] | null | undefined };
  targetBandPoint: string;
};

export const CostToMoveEmployeesTable = ({
  employeeCTMs,
  filter,
  targetBandPoint,
}: Props) => {
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [sortBy, setSortBy] = useState<string>("convertedCTM");
  const classes = useStyles();
  const { defaultCurrencyCode } = useCurrencies();

  const setSort = (field: string) => {
    if (sortBy === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortDirection("asc");
      setSortBy(field);
    }
  };

  let sortedEmployees = employeeCTMs.slice().sort(
    contramap((employeeCTM) => {
      if (sortBy === "convertedCTM") {
        return employeeCTM.convertedCostToMove?.value ?? 0;
      } else if (sortBy === "CTM") {
        return employeeCTM.costToMove?.value ?? 0;
      } else if (sortBy === "targetBandPoint") {
        return employeeCTM.targetBandPoint?.value ?? 0;
      } else if (sortBy === "salary") {
        return employeeCTM.spend.value;
      } else if (sortBy === "email") {
        return employeeCTM.employee.email;
      } else if (sortBy === "name") {
        return employeeCTM.employee.displayName;
      } else if (sortBy === "comparatio") {
        return employeeCTM.comparatio;
      }

      return employeeCTM.convertedCostToMove ?? zero(defaultCurrencyCode);
    }, basicComparator)
  );

  if (sortDirection === "desc") sortedEmployees = sortedEmployees.reverse();

  return (
    <TableContainer component={Paper} elevation={0}>
      <TableHeaderBar
        slotLeft={<></>}
        slotCenter={<></>}
        slotRight={
          <Tooltip title="Download data as a CSV">
            <div className={classes.downloadButton}>
              <CostToMoveExport
                filter={filter}
                targetBandPoint={targetBandPoint}
              />
            </div>
          </Tooltip>
        }
        inContainer
      />
      <WireTable className={classes.tableRoot}>
        <WireTableHead>
          <WireTableHeaderCell
            variant="head"
            cellTitle="Name"
            orderByField="name"
            order={sortDirection}
            isSelected={sortBy === "name"}
            handleRequestSort={setSort}
          />
          <WireTableHeaderCell
            variant="head"
            cellTitle="Email"
            orderByField="email"
            order={sortDirection}
            isSelected={sortBy === "email"}
            handleRequestSort={setSort}
          />
          <WireTableHeaderCell
            variant="head"
            cellTitle="Salary"
            orderByField="salary"
            order={sortDirection}
            isSelected={sortBy === "salary"}
            handleRequestSort={setSort}
          />
          <WireTableHeaderCell
            variant="head"
            cellTitle="Target Bandpoint"
            orderByField="targetBandPoint"
            order={sortDirection}
            isSelected={sortBy === "targetBandPoint"}
            handleRequestSort={setSort}
          />
          <WireTableHeaderCell
            variant="head"
            cellTitle="Cost To Bandpoint"
            orderByField="CTM"
            order={sortDirection}
            isSelected={sortBy === "CTM"}
            handleRequestSort={setSort}
          />
          <WireTableHeaderCell
            variant="head"
            cellTitle={`Cost To Bandpoint (${defaultCurrencyCode})`}
            orderByField="convertedCTM"
            order={sortDirection}
            isSelected={sortBy === "convertedCTM"}
            handleRequestSort={setSort}
          />
          <WireTableHeaderCell
            variant="head"
            cellTitle="Compa-ratio"
            orderByField="comparatio"
            order={sortDirection}
            isSelected={sortBy === "comparatio"}
            handleRequestSort={setSort}
          />
        </WireTableHead>

        {sortedEmployees.length === 0 && (
          <TableBody>
            <TableRow>
              <WireTableCell colSpan={7} rowSpan={2}>
                <div className={classes.emptyTable}>
                  <NoResultsIcon color={GRAY_4} width="48px" height="48px" />
                  <AssembleTypography
                    variant="productParagraphLarge"
                    textColor={GRAY_4}
                  >
                    We couldn't find any employees matching these filters.
                  </AssembleTypography>
                </div>
              </WireTableCell>
            </TableRow>
          </TableBody>
        )}

        <TableBody>
          {sortedEmployees.map((employeeCTM) => (
            <TableRow key={employeeCTM.employee.id}>
              <WireTableCell>{employeeCTM.employee.displayName}</WireTableCell>
              <WireTableCell>{employeeCTM.employee.email}</WireTableCell>
              <WireTableCell align="right">
                {getSimpleCashLabel(employeeCTM.spend)}
              </WireTableCell>
              <WireTableCell align="right">
                {employeeCTM.targetBandPoint != null
                  ? getSimpleCashLabel(employeeCTM.targetBandPoint)
                  : "-"}
              </WireTableCell>
              <WireTableCell align="right">
                {employeeCTM.costToMove != null
                  ? getSimpleCashLabel(employeeCTM.costToMove)
                  : "-"}
              </WireTableCell>
              <WireTableCell align="right">
                {employeeCTM.convertedCostToMove != null
                  ? getSimpleCashLabel(employeeCTM.convertedCostToMove)
                  : "-"}
              </WireTableCell>
              <WireTableCell align="right">
                {employeeCTM.comparatio != null
                  ? formatNumeral(employeeCTM.comparatio, { style: "percent" })
                  : "-"}
              </WireTableCell>
            </TableRow>
          ))}
        </TableBody>
      </WireTable>
    </TableContainer>
  );
};

CostToMoveEmployeesTable.fragments = {
  employeeCostToMove: gql`
    fragment CostToMoveEmployeesTable_employeeCostToMove on EmployeeCostToMove {
      employee {
        id
        displayName
        email
      }
      spend
      targetBandPoint
      costToMove
      convertedCostToMove
      comparatio
    }
  `,
};
