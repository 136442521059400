import { gql } from "@apollo/client";
import { CashBandName } from "@asmbl/shared/constants";
import { InputAdornment, TableCell, TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { currencySymbol } from "../../models/Currency";
import {
  EditableBandPointCell_cashBandPoint as CashBandPoint,
  EditableBandPointCell_equityBandPoint as EquityBandPoint,
  EditableBandPointCell_position as Position,
  PositionType,
} from "../../__generated__/graphql";
import { AlternateForms } from "./AlternateForms";

type BandPoint = CashBandPoint | EquityBandPoint;

type Props = {
  position: Position;
  bandPoint: BandPoint;
  onChange: (value: number) => unknown;
};

export function EditableBandPointCell({
  position,
  bandPoint,
  onChange,
}: Props): JSX.Element {
  return (
    <TableCell align="right">
      <NumberFormat
        customInput={TextField}
        variant="outlined"
        defaultValue={rawValue(bandPoint)}
        InputProps={adornments(bandPoint, bandPoint.__typename, position.type)}
        onValueChange={({ floatValue }) => onChange(floatValue ?? 0)}
        thousandSeparator
        allowNegative={false}
      />
      <AlternateForms bandPoint={bandPoint} position={position} />
    </TableCell>
  );
}

function rawValue(bandPoint: BandPoint): number {
  const value = bandPoint.value;
  if (value.__typename === "CashValue") {
    return value.rate !== null ? value.rate.value : 0;
  } else if (value.__typename === "UnitValue") {
    return value.unitValue ?? 0;
  } else {
    return value.percentValue ?? 0;
  }
}

function adornments(
  bandPoint: BandPoint,
  bandType: "AdjustedCashBandPoint" | "AdjustedEquityBandPoint",
  positionType: PositionType
): {
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
} {
  const value = bandPoint.value;
  if (value.__typename === "CashValue") {
    return {
      startAdornment: (
        <InputAdornment position="start">
          {currencySymbol(value.currencyCode)}
        </InputAdornment>
      ),
      endAdornment:
        bandType === "AdjustedEquityBandPoint" ? (
          <InputAdornment position="end"> total</InputAdornment>
        ) : positionType === PositionType.HOURLY ? (
          <InputAdornment position="end">
            {bandPoint.bandName === CashBandName.SALARY ? " / hr" : " / year"}
          </InputAdornment>
        ) : undefined,
    };
  } else if (value.__typename === "UnitValue") {
    return {
      endAdornment: (
        <InputAdornment position="end"> total units</InputAdornment>
      ),
    };
  } else {
    return { endAdornment: <InputAdornment position="end"></InputAdornment> };
  }
}

EditableBandPointCell.fragments = {
  position: gql`
    ${AlternateForms.fragments.position}
    fragment EditableBandPointCell_position on Position {
      id
      type
      ...AlternateForms_position
    }
  `,
  cashBandPoint: gql`
    ${AlternateForms.fragments.cashBandPoint}
    fragment EditableBandPointCell_cashBandPoint on AdjustedCashBandPoint {
      name
      bandName
      ...AlternateForms_cashBandPoint
      value {
        ... on CashValue {
          rate
          currencyCode
        }
      }
    }
  `,
  equityBandPoint: gql`
    ${AlternateForms.fragments.equityBandPoint}
    fragment EditableBandPointCell_equityBandPoint on AdjustedEquityBandPoint {
      name
      bandName
      vestingMonths
      ...AlternateForms_equityBandPoint
      value {
        ... on CashValue {
          rate
          currencyCode
        }
        ... on UnitValue {
          unitValue
        }
        ... on PercentValue {
          percentValue
        }
      }
    }
  `,
};
