import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const WarningColorIcon = ({
  height = "16px",
  width = "17px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2998_89910)">
        <path
          d="M7.43456 1.60756L0.665609 13.1727C0.557298 13.3577 0.500179 13.5677 0.5 13.7815C0.499822 13.9954 0.556589 14.2055 0.664591 14.3907C0.772593 14.5759 0.928019 14.7298 1.11523 14.8367C1.30245 14.9437 1.51484 15 1.73105 15H15.2689C15.4852 15 15.6976 14.9437 15.8848 14.8367C16.072 14.7298 16.2274 14.5759 16.3354 14.3907C16.4434 14.2055 16.5002 13.9954 16.5 13.7815C16.4998 13.5677 16.4427 13.3577 16.3344 13.1727L9.56544 1.60756C9.4573 1.4228 9.30194 1.26941 9.11493 1.16277C8.92793 1.05613 8.71586 1 8.5 1C8.28413 1 8.07206 1.05613 7.88506 1.16277C7.69806 1.26941 7.5427 1.4228 7.43456 1.60756Z"
          fill="url(#paint0_linear_2998_89910)"
        />
        <path d="M8.5 5.5V8.5V5.5Z" fill="white" />
        <path
          d="M8.5 5.5V8.5"
          stroke="#EEF5FB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 11.5C7.5 10.9477 7.94772 10.5 8.5 10.5C9.05228 10.5 9.5 10.9477 9.5 11.5C9.5 12.0523 9.05228 12.5 8.5 12.5C7.94772 12.5 7.5 12.0523 7.5 11.5Z"
          fill="white"
          strokeWidth="4"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2998_89910"
          x1="13.6292"
          y1="14.7977"
          x2="7.37149"
          y2="4.38767"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF900D" />
          <stop offset="1" stopColor="#FFDD28" />
        </linearGradient>
        <clipPath id="clip0_2998_89910">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  </ColorableSVG>
);
