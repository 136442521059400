export enum CashBandType {
  VARIABLE_CASH = "VARIABLE_CASH",
  FIXED_CASH = "FIXED_CASH",
}

export enum ScheduleType {
  ANNUAL = "ANNUAL",
  ONE_TIME = "ONE_TIME",
}
export enum EquityBandType {
  INITIAL_GRANT = "INITIAL_GRANT",
  REFRESHER_GRANT = "REFRESHER_GRANT",
}

export interface EquityCompComponent {
  bandType: EquityBandType;
  scheduleType: ScheduleType;
  excludedFromOffers: boolean;
}
export interface CashCompComponent {
  bandType: CashBandType;
  scheduleType: ScheduleType;
}

export enum AbbreviatedCashBandName {
  SALARY = "Salary",
  COMMISSION = "Comm",
  RECURRING_BONUS = "Rec Bonus",
  SPOT_BONUS = "Spot Bonus",
  SIGNING_BONUS = "Sign Bonus",
  RELOCATION_BONUS_OFFICE = "Relo Bonus (Office)",
  RELOCATION_BONUS_REMOTE = "Relo Bonus (Remote)",
}

export enum CashBandName {
  SALARY = "Salary",
  COMMISSION = "Commission",
  RECURRING_BONUS = "Recurring Bonus",
  SPOT_BONUS = "Spot Bonus",
  SIGNING_BONUS = "Signing Bonus",
  RELOCATION_BONUS_OFFICE = "Relocation Bonus (Office)",
  RELOCATION_BONUS_REMOTE = "Relocation Bonus (Remote)",
}

/*
  This mapping is duplicated on the client, which cannot import Prisma client values.
  When updating here, keep the client copy in sync.
 */
export const CASH_COMP_COMPONENTS: Record<CashBandName, CashCompComponent> = {
  Salary: {
    bandType: CashBandType.FIXED_CASH,
    scheduleType: ScheduleType.ANNUAL,
  },
  Commission: {
    bandType: CashBandType.VARIABLE_CASH,
    scheduleType: ScheduleType.ANNUAL,
  },
  "Recurring Bonus": {
    bandType: CashBandType.VARIABLE_CASH,
    scheduleType: ScheduleType.ANNUAL,
  },
  "Spot Bonus": {
    bandType: CashBandType.VARIABLE_CASH,
    scheduleType: ScheduleType.ONE_TIME,
  },
  "Signing Bonus": {
    bandType: CashBandType.FIXED_CASH,
    scheduleType: ScheduleType.ONE_TIME,
  },
  "Relocation Bonus (Office)": {
    bandType: CashBandType.FIXED_CASH,
    scheduleType: ScheduleType.ONE_TIME,
  },
  "Relocation Bonus (Remote)": {
    bandType: CashBandType.FIXED_CASH,
    scheduleType: ScheduleType.ONE_TIME,
  },
};

export enum EquityBandName {
  INITIAL_EQUITY_GRANT = "Initial Equity Grant",
  EQUITY_REFRESH_GRANT = "Equity Refresh Grant",
}

export enum CurrencyCode {
  AED = "AED",
  AMD = "AMD",
  ARS = "ARS",
  AUD = "AUD",
  BHD = "BHD",
  BRL = "BRL",
  CAD = "CAD",
  CHF = "CHF",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  CZK = "CZK",
  DKK = "DKK",
  EUR = "EUR",
  GBP = "GBP",
  HKD = "HKD",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KRW = "KRW",
  KWD = "KWD",
  MXN = "MXN",
  NAD = "NAD",
  NOK = "NOK",
  NZD = "NZD",
  OMR = "OMR",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  QAR = "QAR",
  RON = "RON",
  RUB = "RUB",
  SAR = "SAR",
  SEK = "SEK",
  SGD = "SGD",
  THB = "THB",
  TND = "TND",
  TRY = "TRY",
  TWD = "TWD",
  USD = "USD",
  UYU = "UYU",
  XPF = "XPF",
  ZAR = "ZAR",
}

export const CASH_COMP_COMPONENT_NAMES = Object.keys(CASH_COMP_COMPONENTS);

export const EQUITY_COMP_COMPONENTS: Record<string, EquityCompComponent> = {
  "Initial Equity Grant": {
    bandType: EquityBandType.INITIAL_GRANT,
    scheduleType: ScheduleType.ONE_TIME,
    excludedFromOffers: false,
  },
  "Equity Refresh Grant": {
    bandType: EquityBandType.REFRESHER_GRANT,
    scheduleType: ScheduleType.ONE_TIME,
    excludedFromOffers: true,
  },
};

export const EQUITY_COMP_COMPONENT_NAMES = Object.keys(EQUITY_COMP_COMPONENTS);

export const BusinessStructure = {
  SOLE_PROPRIETORSHIP: "SOLE_PROPRIETORSHIP",
  LP: "LP",
  LLP: "LLP",
  LLC: "LLC",
  B_CORP: "B_CORP",
  C_CORP: "C_CORP",
  NONPROFIT: "NONPROFIT",
};

export const DEFAULT_USD = { code: CurrencyCode.USD, exchangeRate: 1 };

export const EquityGrantMethod = {
  CASH: "CASH",
  UNITS: "UNITS",
};

export const EquityGrantTypes = {
  STOCK_OPTION: "STOCK_OPTION",
  RSU: "RSU",
  RSA: "RSA",
  CAPITAL_INTEREST: "CAPITAL_INTEREST",
  SHARED_PROFIT_INTEREST: "SHARED_PROFIT_INTEREST",
};

export const MONTHS_IN_A_YEAR = 12;
