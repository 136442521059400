import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { ratio } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { formatNumeral } from "@asmbl/shared/utils";
import {
  CashCompType,
  CondensedTableActualRecurringBonusPercentCell2_participant as Participant,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { activeComp, cashValueOf } from "../../../../models/CashCompensation";
import { getActualRecurringBonus } from "../../../../models/CompRecommendation";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

function getPercentOfTarget(
  employee: Participant,
  defaultCurrencyCode: CurrencyCode
) {
  const activeCashComp = activeComp(
    employee.subject.activeCashCompensation ?? [],
    new Date()
  );
  const target = cashValueOf(CashCompType.RECURRING_BONUS, activeCashComp);
  const bonus = getActualRecurringBonus(
    employee.compRecommendation?.latestSubmittedItems ?? [],
    defaultCurrencyCode
  );
  if (bonus.value === 0) {
    return 0;
  }
  if (target && target.currency === bonus.currency) {
    return ratio(bonus, target);
  }
  return null;
}

export function CondensedTableActualRecurringBonusPercentCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();
  const percent = getPercentOfTarget(employee, defaultCurrencyCode);
  return (
    <AssembleTruncatedTypography align="right">
      {percent === null
        ? "N/A"
        : formatNumeral(percent / 100, {
            style: "percent",
            maximumFractionDigits: 2,
          })}
    </AssembleTruncatedTypography>
  );
}

CondensedTableActualRecurringBonusPercentCell2.fragments = {
  participant: gql`
    fragment CondensedTableActualRecurringBonusPercentCell2_participant on CompCycleParticipant {
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
        }
      }
      subject {
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          unit
          annualCashEquivalent
          hourlyCashEquivalent
          unit
          percentOfSalary
        }
      }
    }
  `,
};

CondensedTableActualRecurringBonusPercentCell2.Header =
  "Actual Recur. Bonus (% Target)";
CondensedTableActualRecurringBonusPercentCell2.id =
  "actual-recurring-bonus-percent-column";

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableActualRecurringBonusPercentCell2,
  Header: CondensedTableActualRecurringBonusPercentCell2.Header,
  id: CondensedTableActualRecurringBonusPercentCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableActualRecurringBonusPercentCell2.column = column;
CondensedTableActualRecurringBonusPercentCell2.ordering = ({
  defaultCurrencyCode,
  currencies,
}: {
  defaultCurrencyCode: CurrencyCode;
  currencies: Map<CurrencyCode, Currency>;
}) =>
  contramap((e: Participant) => {
    const actualRecurringBonus = getActualRecurringBonus(
      e.compRecommendation?.latestSubmittedItems ?? [],
      defaultCurrencyCode
    );

    const currentCurrency = currencies.get(actualRecurringBonus.currency);
    const defaultCurrency = currencies.get(defaultCurrencyCode);

    if (currentCurrency == null || defaultCurrency == null) {
      return 0;
    }

    return exchangeFromTo(
      actualRecurringBonus,
      currentCurrency,
      defaultCurrency
    ).value;
  });
