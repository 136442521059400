import { makeStyles, TableCell } from "@material-ui/core";
import { AnonymizedCompensationBandVisualizationLoadingSkeleton } from "../../../components/AnonymizedCompensationBandVisualization/AnonymizedCompensationBandVisualizationLoadingSkeleton";
import { AssembleTypography } from "../../../components/AssembleTypography";
import { ErrorView } from "../../../components/ErrorView";
import { GRAY_6, GRAY_8 } from "../../../theme";
import { Range } from "../../../utils";

const useStyles = makeStyles(() => ({
  infoCell: { minWidth: "160px" },
  bandVisualizationCell: {
    backgroundColor: GRAY_8,
    borderLeft: `1px solid ${GRAY_6}`,
    borderRight: `1px solid ${GRAY_6}`,
  },
}));

type Props = { loading: boolean; totalCompRange: Range };

export function AnonymizedCompensationLoadingCells({
  loading,
  totalCompRange,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <TableCell component="th" scope="row" align="center">
        <AssembleTypography>-</AssembleTypography>
      </TableCell>
      <TableCell
        className={classes.bandVisualizationCell}
        align="left"
        padding="none"
      >
        {loading ? (
          <AnonymizedCompensationBandVisualizationLoadingSkeleton
            totalCompRange={totalCompRange}
          />
        ) : (
          <ErrorView />
        )}
      </TableCell>
      <TableCell className={classes.infoCell} />
    </>
  );
}
