import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { useState } from "react";
import {
  AssembleButton,
  getIconProps,
} from "src/components/AssembleButton/AssembleButton";
import { JobArchitectureIcon } from "src/components/AssembleIcons/Brand/JobArchitectureIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { ExplanatoryTooltip } from "src/components/ExplanatoryTooltip";
import { OrganizationHierarchyLoadingBoundary } from "src/components/OrganizationHierarchy/OrganizationHierarchyLoadingBoundary";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    width: "90%",
    maxWidth: "none",
  },
  content: {
    overflow: "hidden",
  },
}));

export function OrganizationHierarchyModal(): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const onClose = () => setOpen(false);

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") {
      onClose();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{ className: classes.dialogPaper }}
        disableEscapeKeyDown={false}
        onKeyDown={onKeyDown}
      >
        <DialogTitle>
          <AssembleTypography variant="productTabHeader">
            Organization Hierarchy
          </AssembleTypography>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <OrganizationHierarchyLoadingBoundary />
        </DialogContent>

        <DialogActions>
          <AssembleButton
            label="Close"
            variant="outlined"
            size="medium"
            onClick={onClose}
          />
        </DialogActions>
      </Dialog>

      <ExplanatoryTooltip
        title="Organization Hierarchy"
        body="View your entire organization in an interactive chart."
      >
        <span>
          <AssembleButton
            onClick={() => setOpen(true)}
            variant="outlined"
            size="small"
            startIcon={
              <JobArchitectureIcon {...getIconProps({ size: "small" })} />
            }
          />
        </span>
      </ExplanatoryTooltip>
    </>
  );
}
