import { gql } from "@apollo/client";
import {
  Box,
  Divider,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { useTrack } from "../../../../analytics";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getIllustrativeUnitPrice } from "../../../../models/Equity";
import {
  employeeCompanyHasEquitySetup,
  employeeHasEquity,
} from "../../../../models/EquityVestEvent";
import { GRAY_1 } from "../../../../theme";
import { EmployeeCompensation_employee as Employee } from "../../../../__generated__/graphql";
import { EmployeeEquity } from "../EmployeeEquity/EmployeeEquity";
import { ActualVariableCashCompensationTable } from "./ActualVariableCashCompTable";
import { CompensationTimeline } from "./CompensationTimeline";
import { CurrentCompensation } from "./CurrentCompensation";

const useStyles = makeStyles(() => ({
  headerText: {
    color: GRAY_1,
  },
}));

interface Props {
  employee: Employee;
}

export function EmployeeCompensation({ employee }: Props): JSX.Element | null {
  const classes = useStyles();
  const { currencies } = useCurrencies();
  const { trackEvent } = useTrack();

  const [tab, setTab] = useState<
    "current" | "equity" | "timeline" | "actualVariableComp"
  >("current");

  const handleTabChange = (
    _: unknown,
    value: "current" | "equity" | "timeline"
  ) => {
    trackEvent({
      area: "People",
      subArea: "Employee Detail View",
      object: "Compensation Tab",
      action: "Changed",
      tab: value,
      employeeId: employee.id,
    });

    setTab(value);
  };

  const hasEquityData =
    employeeHasEquity(employee.equityHoldings) &&
    employeeCompanyHasEquitySetup(
      null,
      getIllustrativeUnitPrice(
        currencies,
        employee.equityHoldings.grants,
        employee.equityHoldings.valuationCurrency
      )
    );

  const hasActualVariableCompData =
    employee.actualVariableCashCompensations != null &&
    employee.actualVariableCashCompensations.length > 0;

  return (
    <Box mx={4} mt={5} mb={12}>
      <Typography variant="h5" className={classes.headerText} paragraph>
        Compensation
      </Typography>

      <Box mb={4}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Current Compensation" value="current" />
          {hasEquityData && <Tab label="Equity" value="equity" />}
          <Tab label="Compensation Timeline" value="timeline" />
          {hasActualVariableCompData && (
            <Tab
              label="Actual Variable Cash Compensation"
              value="actualVariableComp"
            />
          )}
        </Tabs>
        <Divider />
      </Box>

      {tab === "current" && <CurrentCompensation employee={employee} />}
      {tab === "equity" && (
        <EmployeeEquity
          employee={employee}
          equityHoldings={employee.equityHoldings}
        />
      )}
      {tab === "timeline" && (
        <CompensationTimeline
          cash={employee.cashCompensation}
          valuationCurrency={employee.equityHoldings.valuationCurrency.code}
        />
      )}
      {tab === "actualVariableComp" && (
        <ActualVariableCashCompensationTable employee={employee} />
      )}
    </Box>
  );
}

EmployeeCompensation.fragments = {
  employee: gql`
    ${CompensationTimeline.fragments.cash}
    ${CompensationTimeline.fragments.valuationCurrency}
    ${CurrentCompensation.fragments.employee}
    ${EmployeeEquity.fragments.employee}
    ${EmployeeEquity.fragments.equityHoldings}
    ${ActualVariableCashCompensationTable.fragments.employee}
    fragment EmployeeCompensation_employee on Employee {
      id

      cashCompensation {
        employeeId
        type
        activeAt
        annualCashEquivalent
        ...CompensationTimeline_cash
      }

      equityHoldings {
        id
        grants {
          id
          sharePrice
        }
        valuationCurrency {
          id
          code
          exchangeRate
        }
        ...CompensationTimeline_valuationCurrency
        ...EmployeeEquity_equityHoldings
      }

      ...EmployeeEquity_employee
      ...CurrentCompensation_employee
      ...ActualVariableCashComp_employee
    }
  `,
};
