import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import { memo } from "react";
import { GRAY_9 } from "../../../theme";

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const useStyles = makeStyles((theme: Theme) => ({
  box: {
    borderRadius: "2px",
    height: "16px",
    width: "16px",
  },
  container: {
    zIndex: 10,
  },
  font: {
    textTransform: "capitalize",
  },
  legend: {
    backgroundColor: GRAY_9,
    borderRadius: "10px",
    boxShadow: "0px 3px 3px rgba(10, 36, 64, 0.05)",
    padding: theme.spacing(3),
    zIndex: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  line: {
    borderRadius: "2px",
    height: "4px",
    width: "16px",
  },
}));

//  ----------------------------------------------------------------------------
//  Types
//  ----------------------------------------------------------------------------
export type LegendItem = {
  color: string;
  value: string;
};

type LegendProps = {
  items: LegendItem[];
};

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
const Legend = ({ items }: LegendProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.legend}>
      {items.map((x: LegendItem, i) => {
        const lastItem = i === items.length - 1;
        return (
          <Box
            key={x.value}
            mr={lastItem ? 0 : 4}
            alignItems="center"
            display="flex"
            flexDirection="row"
          >
            <Box
              className={classes.box}
              mr={2}
              style={{ background: x.color }}
            />
            <Typography className={classes.font} variant="subtitle2">
              {x.value}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default memo(Legend);
