import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const CompCycleSettingsIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Comp Cycle Settings"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M9.58029 14.9019L8.53842 14.1775H7.4615L6.41966 14.9019C6.35321 14.9488 6.27764 14.98 6.19833 14.993C6.11903 15.006 6.03794 15.0007 5.96085 14.9773C4.93744 14.6609 4.00027 14.0966 3.22581 13.3304C3.16786 13.2725 3.12287 13.2019 3.09406 13.1238C3.06524 13.0456 3.05329 12.9618 3.05908 12.8783L3.13964 11.5751L2.60118 10.6024L1.47879 10.0235C1.40655 9.987 1.34293 9.9343 1.29245 9.8692C1.24196 9.80409 1.20586 9.72818 1.18672 9.64686C0.937765 8.56428 0.93776 7.43565 1.18671 6.35307C1.20585 6.27175 1.24195 6.19584 1.29244 6.13074C1.34293 6.06563 1.40655 6.01293 1.47879 5.9764L2.60122 5.39755L3.13968 4.42486L3.05913 3.12168C3.05335 3.03816 3.06529 2.95435 3.09411 2.87619C3.12293 2.79804 3.16792 2.72748 3.22587 2.66953C4.00032 1.90338 4.93749 1.33906 5.9609 1.02262C6.03799 0.999259 6.11908 0.99391 6.19838 1.00696C6.27769 1.02 6.35326 1.05113 6.41971 1.09811L7.46158 1.82248H8.5385L9.58033 1.09813C9.64679 1.05115 9.72236 1.02003 9.80167 1.00699C9.88097 0.993946 9.96205 0.999299 10.0391 1.02267C11.0626 1.33909 11.9997 1.9034 12.7742 2.66955C12.8321 2.7275 12.8771 2.79806 12.9059 2.87622C12.9348 2.95437 12.9467 3.03819 12.9409 3.1217L12.8604 4.42492L13.3988 5.39762L14.5212 5.97646"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.20436 5 10.243 5.70969 10.7204 6.73369"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        className="stroke"
        d="M13 8V8.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M13 14V14.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M11.5373 14H13.7463C14.403 14 15 13.4643 15 12.75C15 12.0952 14.4627 11.5 13.7463 11.5H12.2537C11.597 11.5 11 10.9643 11 10.25C11 9.53571 11.5373 9 12.2537 9H14.4627"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
