import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const PeopleIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      height={height}
      width={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 11C6.65685 11 8 9.65685 8 8C8 6.34315 6.65685 5 5 5C3.34315 5 2 6.34315 2 8C2 9.65685 3.34315 11 5 11Z"
        className="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.43048 3.57127C8.81841 3.39102 9.24064 3.29431 9.66874 3.28764C10.0968 3.28098 10.5209 3.3645 10.9123 3.5326C11.3036 3.7007 11.6533 3.94947 11.9377 4.26215C12.2221 4.57483 12.4346 4.94416 12.561 5.34527C12.6873 5.74638 12.7245 6.16995 12.6701 6.58745C12.6156 7.00495 12.4708 7.40669 12.2454 7.76561C12.02 8.12452 11.7192 8.43229 11.3633 8.66816C11.0074 8.90404 10.6046 9.06255 10.1821 9.13303C10.985 8.99861 11.8079 9.05604 12.5811 9.30046C13.3542 9.54488 14.0549 9.96909 14.6238 10.5372"
        className="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.65326 13.0565C2.26174 12.5312 2.99108 12.1586 3.77969 11.9703C4.56831 11.782 5.39305 11.7834 6.18428 11.9745C6.9755 12.1656 7.70999 12.5407 8.32572 13.0682"
        className="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
