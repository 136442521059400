import { makeStyles } from "@material-ui/core";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { PeopleIcon } from "src/components/AssembleIcons/Navigation/PeopleIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { DV_ORANGE, PURPLE_2 } from "src/theme";
import { useMultipleMeritMatrices } from "../useMultipleMatrices";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  button: {
    "&:hover": {
      border: "1px solid transparent",
      boxShadow: "none",
    },
  },
}));

export function ButtonBar(): JSX.Element {
  const classes = useStyles();
  const { totalEligible } = useMultipleMeritMatrices();

  return (
    <div className={classes.root}>
      <AssembleButton
        className={classes.button}
        size="small"
        variant="text"
        startIcon={<PeopleIcon color={PURPLE_2} />}
        label={`Total Eligible (${totalEligible})`}
      />
      <AssembleButton
        className={classes.button}
        color="inherit"
        size="small"
        variant="text"
        startIcon={<PeopleIcon color={DV_ORANGE} />}
        label={
          <AssembleTypography
            variant="productButtonSmall"
            textColor={DV_ORANGE}
          >
            Unassigned People {totalEligible}
          </AssembleTypography>
        }
      />
    </div>
  );
}
