import { FeatureFlag, FeatureTextFlag } from "@asmbl/shared/feature-flags";
import { Box, Link, Typography } from "@material-ui/core";
import * as configcat from "configcat-js";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router";
import { configcatKey } from "../env";
import { getDomainFromEmail } from "../utils";
import { useAuth } from "./Auth/AuthContext";

export type FeatureFlagContextType = {
  loading: boolean;
  enabledFlags: FeatureFlag[];
  isEnabled: (flag: FeatureFlag) => boolean;
  getTextValue: (flag: FeatureTextFlag) => string | undefined;
};

const configCatClient = configcat.createClientWithAutoPoll(configcatKey());

const isEnabled =
  (flags: FeatureFlag[]) =>
  (flagToCheck: FeatureFlag): boolean => {
    return flags.includes(flagToCheck);
  };

const getTextValue =
  (textFlags: Map<string, string>) =>
  (flagToCheck: FeatureTextFlag): string | undefined => {
    return textFlags.get(flagToCheck);
  };

async function getAllValues(
  identifier: string,
  email: string | null,
  orgId: number | undefined
) {
  const featureFlagSettings = await configCatClient.getAllValuesAsync({
    identifier,
    email: email ?? undefined,
    custom: getCustomConfigCatAttributes({ email }, orgId),
  });

  const enabledFeatureFlags = featureFlagSettings
    .filter(filterActiveFeatures)
    .map((setting) => setting.settingKey);

  const textFlagMap = createTextFlagMap(featureFlagSettings);

  return { enabledFeatureFlags, textFlagMap };
}

const featureFlagContext = createContext<FeatureFlagContextType>({
  loading: true,
  enabledFlags: [],
  isEnabled: isEnabled([]),
  getTextValue: getTextValue(new Map()),
});

type FeatureFlagProviderProps = {
  children: ReactNode;
};

export const FeatureFlagProvider: React.FC<FeatureFlagProviderProps> = ({
  children,
}: FeatureFlagProviderProps) => {
  const featureFlags = useFeatureFlagProvider();

  return (
    <featureFlagContext.Provider value={featureFlags}>
      {children}
    </featureFlagContext.Provider>
  );
};

export function useFeatureFlags(): FeatureFlagContextType {
  return useContext(featureFlagContext);
}

function useFeatureFlagProvider() {
  const location = useLocation();
  const { user, organization } = useAuth();
  const orgId = organization?.id;

  const [loading, setLoading] = useState(true);
  const [enabledFlags, setEnabledFlags] = useState<FeatureFlag[]>([]);
  const [textFlags, setTextFlags] = useState(new Map<string, string>());

  // Fetch feature flag settings from config cat
  useEffect(() => {
    const fetchConfigCat = async () => {
      if (!user) return;
      const { enabledFeatureFlags, textFlagMap } = await getAllValues(
        user.uid,
        user.email,
        orgId
      );
      setEnabledFlags(enabledFeatureFlags);
      setTextFlags(textFlagMap);
      setLoading(false);
    };
    void fetchConfigCat();
  }, [location, user, orgId]);

  return {
    loading,
    enabledFlags,
    isEnabled: isEnabled(enabledFlags),
    getTextValue: getTextValue(textFlags),
  };
}

/** Computes custom fields for ConfigCat to match upon like domain and orgId. */
function getCustomConfigCatAttributes(
  user: { email: string | null },
  orgId: number | undefined
) {
  const userEmailDomain = getDomainFromEmail(user.email);

  return {
    ...(orgId !== undefined && { orgId: orgId.toString() }),
    ...(userEmailDomain !== null && { domain: userEmailDomain }),
  };
}

function filterActiveFeatures(setting: {
  settingKey: string;
  settingValue: unknown;
}): setting is {
  settingKey: FeatureFlag;
  settingValue: boolean;
} {
  return (
    Boolean(setting.settingValue) &&
    Object.values(FeatureFlag).includes(setting.settingKey as FeatureFlag)
  );
}

function filterTextFlags(setting: {
  settingKey: string;
  settingValue: unknown;
}): setting is {
  settingKey: FeatureTextFlag;
  settingValue: string;
} {
  return (
    Object.values(FeatureTextFlag).includes(
      setting.settingKey as FeatureTextFlag
    ) &&
    String(setting.settingValue) !== "false" &&
    String(setting.settingValue) !== "undefined" &&
    String(setting.settingValue) !== "null" &&
    String(setting.settingValue) !== ""
  );
}

function createTextFlagMap(
  featureFlagSettings: { settingKey: string; settingValue: unknown }[]
): Map<string, string> {
  const textFlagMap = new Map<string, string>();
  featureFlagSettings
    .filter(filterTextFlags)
    .forEach(({ settingKey, settingValue }) => {
      textFlagMap.set(settingKey, settingValue);
    });
  return textFlagMap;
}

export const EnabledFeaturesList: React.FC = () => {
  const flags = useFeatureFlags();
  const { user } = useAuth();

  const isAssembler = getDomainFromEmail(user?.email) === "assemble.inc";

  if (flags.loading) {
    return null;
  }
  if (!isAssembler) {
    return null;
  }

  return (
    <>
      <Box m={2} />
      <Typography variant="h5">
        Feature flags <Link href="https://app.configcat.com/">enabled</Link>
      </Typography>
      <ul>
        {flags.enabledFlags.map((feature) => (
          <li key={feature}>{feature}</li>
        ))}
      </ul>
    </>
  );
};

type PreviewFeatureProps = {
  children?: ReactNode;
  fallback?: ReactNode;
} & (
  | { flag: FeatureFlag }
  | { some: FeatureFlag[] }
  | { every: FeatureFlag[] }
);

export function PreviewFeature(props: PreviewFeatureProps): JSX.Element {
  const activeFlags = useFeatureFlags();

  let isEnabled: boolean;

  if ("some" in props) {
    const flags = props.some;
    isEnabled = flags.some(activeFlags.isEnabled);
  } else if ("every" in props) {
    const flags = props.every;
    isEnabled = flags.every(activeFlags.isEnabled);
  } else {
    isEnabled = activeFlags.isEnabled(props.flag);
  }

  return <>{isEnabled ? props.children : props.fallback}</>;
}

/**
 * Execute a runtime ConfigCat check. Useful for when you don't already have an
 * authorized user or need to check on behalf of another user.
 * @returns True if the flag is a boolean that is truthy. False if the flag
 * doesn't exist or is falsy.
 */
export async function isEnabledWithUser(
  flag: FeatureFlag,
  email: string | null | undefined,
  orgId?: number
): Promise<boolean> {
  if (email == null) return false;

  const { enabledFeatureFlags } = await getAllValues(email, email, orgId);
  return enabledFeatureFlags.includes(flag);
}
