import { gql, useQuery } from "@apollo/client";
import { LinearProgress, makeStyles } from "@material-ui/core";
import { GetExploToken } from "../../../__generated__/graphql";
import { useTrack } from "../../../analytics";
import { ErrorView } from "../../../components/ErrorView";
import { PAGE_HEADER_HEIGHT } from "../../../components/Layout/PageHeader";
import { envName } from "../../../env";
import { DashboardType, ReportingDashboard } from "./ReportingDashboard";

const useStyles = makeStyles(() => ({
  root: {
    // subtract comp cycle nav bar from height
    height: `calc(100% - ${PAGE_HEADER_HEIGHT})`,
    "& explo-dashboard": { height: `calc(100% - ${PAGE_HEADER_HEIGHT})` },
    "& #embedded-container": { height: "100%" },
  },
}));

type Props = {
  compCycleId: number;
  organizationId: number;
  dashboardType: DashboardType;
};

export function ReportingLoadingBoundary({
  compCycleId,
  organizationId,
  dashboardType,
}: Props) {
  const classes = useStyles();
  const environment = envName();
  const { Track } = useTrack({
    subArea: "Analysis",
    dashboard: dashboardType,
    compCycleId,
  });

  const { data, error, loading } = useQuery<GetExploToken>(
    ReportingLoadingBoundary.queries.getExploToken,
    {
      variables: { compCycleId },
    }
  );

  if (loading) {
    return <LinearProgress />;
  }

  if (!data || !data.exploToken.token || error) {
    return (
      <ErrorView
        text={
          error?.message ??
          "We are not able to retrieve the current page. Please refresh or contact Customer Success."
        }
      />
    );
  }

  return (
    <Track>
      <div className={classes.root}>
        <ReportingDashboard
          token={data.exploToken.token}
          organizationId={organizationId}
          compCycleId={compCycleId}
          environment={environment ?? "development"}
          dashboardType={dashboardType}
        />
      </div>
    </Track>
  );
}

ReportingLoadingBoundary.queries = {
  getExploToken: gql`
    query GetExploToken($compCycleId: Int!) {
      exploToken(compCycleId: $compCycleId) {
        token
      }
    }
  `,
};
