import { cx } from "@emotion/css";
import { makeStyles, TableCell, TableCellProps } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "3rem",
    padding: theme.spacing(0, 2),
    "&:not(:last-child)": {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
}));

export function WireTableCell(props: TableCellProps): JSX.Element {
  const classes = useStyles();
  return <TableCell {...props} className={cx(classes.root, props.className)} />;
}
