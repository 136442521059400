import { CurrencyCode } from "@asmbl/shared/constants";
import {
  makeStyles,
  TableCell,
  TextField,
  TextFieldProps,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { AllocationUnit } from "../../../../models/Budget";
import { currencySymbol } from "../../../../models/Currency";
import { GRAY_4, GRAY_8, PURPLE_2 } from "../../../../theme";
import { AllocateBudgetsDogEarWithTooltip } from "./AllocateBudgetsDogEarWithTooltip";

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: 0,
    position: "relative",
    width: "11rem",
    zIndex: 0, // Establish a stacking context to contain the dog ear
  },
  textField: {
    height: theme.spacing(7),
  },
  textFieldInput: {
    display: "flex",
    height: "100%",
    borderRadius: 0,
    border: "none",
    boxShadow: "none",
    transition: "none",
    padding: 0,

    "&:hover": {
      background: GRAY_8,
      border: "none",
      transition: "none",
    },

    "&:focus-within": {
      border: "none",
      boxShadow: "none",
      outline: `2px solid ${PURPLE_2}`,
      outlineOffset: "-2px",
      transition: "none",

      "& $symbolAdornment": {
        color: PURPLE_2,
      },
    },
  },
  innerInput: {
    textAlign: "right",
  },
  empty: {},
  symbolAdornment: {
    color: GRAY_4,
    fontSize: "0.875rem",
    marginLeft: theme.spacing(1.5),

    "$empty &": {
      color: "transparent",
    },
  },
}));

export type TextFieldCellWithDogEarProps = {
  inputVariant: AllocationUnit;
  currency: CurrencyCode;
  hasUnpublishedChanges: boolean;
} & TextFieldProps;

export function TextFieldCellWithDogEar({
  inputVariant,
  currency,
  hasUnpublishedChanges,
  value,
  ...rest
}: TextFieldCellWithDogEarProps): JSX.Element {
  const classes = useStyles();
  const adornment =
    inputVariant === AllocationUnit.CASH ? currencySymbol(currency) : "#";

  return (
    <TableCell className={classes.cell}>
      <>
        {hasUnpublishedChanges && <AllocateBudgetsDogEarWithTooltip />}
        <TextField
          {...rest}
          fullWidth
          value={value}
          className={classes.textField}
          InputProps={{
            className: clsx(classes.textFieldInput, {
              [classes.empty]: value === "",
            }),
            classes: { input: classes.innerInput },
            startAdornment: (
              <Typography className={classes.symbolAdornment}>
                {adornment}
              </Typography>
            ),
          }}
        />
      </>
    </TableCell>
  );
}
