import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency } from "@asmbl/shared/currency";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { Comparator, ComparatorWithOrder } from "@asmbl/shared/sort";
import { cx } from "@emotion/css";
import { TableCell, TableHead, TableRow, makeStyles } from "@material-ui/core";
import { ComponentType } from "react";
import { CellProps, Column, ColumnInstance } from "react-table";
import {
  CondensedTable_meritAdjustmentGuide as MeritAdjustmentGuide,
  CondensedTable_meritAdjustmentRange as MeritAdjustmentRange,
  CondensedTable_participant as Participant,
  CondensedTable_perfRatingOption as PerfRatingOption,
  CondensedTable_position as Position,
} from "src/__generated__/graphql";

import { useFeatureFlags } from "src/components/FeatureContext";
import { Order, SortableTableHeaderCell } from "src/components/SortableTable";
import { GRAY_4, GRAY_6, WHITE } from "src/theme";
import { CondensedTableActionsCell2 as CondensedTableActionsCell } from "../Cells/CondensedTableActionsCell2";
import { CondensedTableActivityCell2 as CondensedTableActivityCell } from "../Cells/CondensedTableActivityCell2";
import { CondensedTableNameCell2 as CondensedTableNameCell } from "../Cells/CondensedTableNameCell2";
import { CondensedTableStatusCell2 as CondensedTableStatusCell } from "../Cells/CondensedTableStatusCell2";
import { LARGE_COL_WIDTH, XLG_COL_WIDTH } from "../Cells/dimensions";
import { usePagination } from "../Contexts/PaginationContext";

export type ColumnComponent2 = ComponentType<CellProps<Participant>> & {
  id: string;
  column: Column<Participant>;
  sortable?: boolean;
  ordering: (data: {
    currencies: Map<CurrencyCode, Currency>;
    defaultCurrencyCode: CurrencyCode;
    defaultCurrency: Currency;
    meritGuidance: {
      meritAdjustmentGuides: MeritAdjustmentGuide[];
      meritAdjustmentRanges: MeritAdjustmentRange[];
      perfRatingOptions: PerfRatingOption[];
    };
    availablePositions: Position[];
    workingHoursPerYear: number | undefined;
  }) => Comparator<Participant> | ComparatorWithOrder<Participant>;
};

const useStyles = makeStyles((theme) => ({
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 2,
  },
  headerCell: {
    borderTop: `1px solid ${GRAY_6}`,
    borderLeft: "none",
    borderRight: "none",
    textTransform: "uppercase",
    backgroundColor: WHITE,
    color: GRAY_4,
    fontWeight: 700,
    fontSize: "10px",
    lineHeight: "100%",
    textAlign: "left",
    padding: theme.spacing(0, 1.5),
  },
  nameHeaderCell: {
    borderRight: `1px solid ${GRAY_6}`,
  },
  nameCell: {
    left: 0,
  },
  actionHeaderCell: {
    borderRight: `1px solid ${GRAY_6}`,
  },
  actionCell: {
    position: "sticky",
    left: XLG_COL_WIDTH, // account for the name column
  },
  statusCell: {
    position: "sticky",
    // left: account for the name + action columns
    left: XLG_COL_WIDTH + LARGE_COL_WIDTH,
    borderRight: `1px solid ${GRAY_6}`,
    boxShadow: "3px 0px 6px 0px rgba(10, 36, 64, 0.10)",
  },
  activityCell: {
    position: "sticky",
    // left: account for the name + action columns
    left: XLG_COL_WIDTH + LARGE_COL_WIDTH,
    borderRight: `1px solid ${GRAY_6}`,
  },
}));

type Props = {
  headers: ColumnInstance<Participant>[];
  order: Order;
  orderBy: string;
  handleRequestSort: (property: string) => void;
};

export function CondensedTableInnerHeader({
  headers,
  order,
  orderBy,
  handleRequestSort,
}: Props): JSX.Element {
  const classes = useStyles();
  const { sortBy, setSortBy, sortDir, setSortDir } = usePagination();
  const { isEnabled } = useFeatureFlags();
  const isPaginated = isEnabled(FeatureFlag.ClientPlanTable030124);

  const onChangeSort = (property: string) => {
    if (isPaginated) {
      setSortBy(property);
      setSortDir(property === sortBy && sortDir === "asc" ? "desc" : "asc");
    }
    handleRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow
        className={classes.stickyHeader}
        data-intercom-target={"table-header-thing"}
      >
        {headers.map((header) => {
          if (!header.isVisible) {
            return null;
          }

          const headerClasses = cx(classes.headerCell, {
            [classes.stickyHeader]:
              header.id === CondensedTableNameCell.id ||
              header.id === CondensedTableActionsCell.id ||
              header.id === CondensedTableStatusCell.id ||
              header.id === CondensedTableActivityCell.id,

            [classes.nameHeaderCell]: header.id === CondensedTableNameCell.id,
            [classes.nameCell]: header.id === CondensedTableNameCell.id,

            [classes.actionHeaderCell]:
              header.id === CondensedTableActionsCell.id,
            [classes.actionCell]: header.id === CondensedTableActionsCell.id,

            [classes.statusCell]: header.id === CondensedTableStatusCell.id,
            [classes.activityCell]: header.id === CondensedTableActivityCell.id,
          });

          return header.defaultCanSort === false ? (
            <TableCell
              {...header.getHeaderProps({
                style: {
                  maxWidth: header.width,
                  minWidth: header.width,
                  width: header.width,
                  fontSize: ".6875rem",
                },
              })}
              className={headerClasses}
            >
              {header.Header?.toString()}
            </TableCell>
          ) : (
            <SortableTableHeaderCell<Record<string, unknown>>
              cellTitle={header.Header?.toString()}
              orderByField={header.id}
              order={isPaginated ? sortDir : order}
              isSelected={
                isPaginated ? sortBy === header.id : orderBy === header.id
              }
              handleRequestSort={onChangeSort}
              data-intercom-target={"plan-" + header.id}
              className={headerClasses}
              {...header.getHeaderProps({
                style: {
                  maxWidth: header.width,
                  minWidth: header.width,
                  width: header.width,
                },
              })}
              key={header.getHeaderProps().key}
              noWrap={false}
              align="left"
            />
          );
        })}
      </TableRow>
    </TableHead>
  );
}
