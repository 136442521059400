import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { TotalEquityTimelineChartHeader_equityHoldings as EquityHoldings } from "../../../__generated__/graphql";
import { TotalEquityNext12MonthsChartHeader } from "./TotalEquityNext12MonthsChartHeader";
import { TotalGrantedEquityChartHeader } from "./TotalGrantedEquityChartHeader";
import { TotalUnvestedEquityChartHeader } from "./TotalUnvestedEquityChartHeader";
import { TotalVestedEquityChartHeader } from "./TotalVestedEquityChartHeader";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(6),
  },
}));

type Props = { equityHoldings: EquityHoldings };

export function TotalEquityTimelineChartHeader({
  equityHoldings,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      {equityHoldings.vestingInformation && (
        // This should always render because we check for the existence of
        // `vestingInformation` in the TotalEquityTimeline component.
        <div className={classes.container}>
          <TotalGrantedEquityChartHeader equityHoldings={equityHoldings} />
          <TotalVestedEquityChartHeader
            equityGrantVestEvents={equityHoldings.vestingInformation.vestEvents}
            equityHoldings={equityHoldings}
          />
          <TotalUnvestedEquityChartHeader
            equityGrantVestEvents={equityHoldings.vestingInformation.vestEvents}
            equityHoldings={equityHoldings}
          />
          <TotalEquityNext12MonthsChartHeader
            equityGrantVestEvents={equityHoldings.vestingInformation.vestEvents}
            equityHoldings={equityHoldings}
          />
        </div>
      )}
    </>
  );
}

TotalEquityTimelineChartHeader.fragments = {
  equityHoldings: gql`
    ${TotalGrantedEquityChartHeader.fragments.equityHoldings}
    ${TotalUnvestedEquityChartHeader.fragments.equityHoldings}
    ${TotalVestedEquityChartHeader.fragments.equityHoldings}

    ${TotalVestedEquityChartHeader.fragments.equityGrantVestEvent}
    ${TotalUnvestedEquityChartHeader.fragments.equityGrantVestEvent}
    ${TotalEquityNext12MonthsChartHeader.fragments.equityGrantVestEvent}
    fragment TotalEquityTimelineChartHeader_equityHoldings on EquityHoldings {
      valuationCurrency {
        id
        code
        exchangeRate
      }
      ...TotalGrantedEquityChartHeader_equityHoldings
      ...TotalVestedEquityChartHeader_equityHoldings
      ...TotalUnvestedEquityChartHeader_equityHoldings
      vestingInformation {
        id
        vestEvents {
          id
          ...TotalVestedEquityChartHeader_equityGrantVestEvent
          ...TotalUnvestedEquityChartHeader_equityGrantVestEvent
          ...TotalEquityNext12MonthsChartHeader_equityGrantVestEvent
        }
      }
    }
  `,
};
