//  -- Components ---------------------
import {
  Box,
  FormControlLabel,
  FormControlLabelProps,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { memo } from "react";
//  -- Theme constants ----------------
import { GRAY_6, GRAY_8, PURPLE_1, WHITE } from "../../theme";

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const useStyles = makeStyles((theme: Theme) => ({
  box: {
    background: `linear-gradient(180deg, ${WHITE} 63.33%, ${GRAY_8} 100%)`,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px",
    display: "flex",
    flexDirection: (p: StyleProps) => (p.reverse ? "row-reverse" : "row"),
    justifyContent: (p: StyleProps) =>
      p.reverse ? "space-between" : "flex-start",
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    position: "relative",
    transition: "all 125ms",

    width: "100%",

    "&:focus-within": {
      border: `1px solid ${PURPLE_1}`,
      boxShadow: "0 0 0 3px rgba(100, 91, 255, 0.25)",
    },

    "&:hover": {
      border: `1px solid ${PURPLE_1}`,
      color: PURPLE_1,
    },
  },
}));

type StyleProps = {
  reverse: boolean;
};

interface FormBoxProps extends FormControlLabelProps {
  reverse?: boolean;
}

const FormBox = ({
  label,
  control,
  style,
  onChange,
  reverse = false,
  ...rest
}: FormBoxProps) => {
  const classes = useStyles({ reverse });

  return (
    <FormControlLabel
      {...rest}
      classes={{
        root: classes.box,
      }}
      control={control}
      label={<Box ml={reverse ? 0 : 1}>{label}</Box>}
      onChange={onChange}
      style={style}
    />
  );
};

export default memo(FormBox);
