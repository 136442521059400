import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import {
  GetDepartments,
  AutomatedManagerAccess_organization as Organization,
  AutomatedManagerAccess_report as Report,
} from "src/__generated__/graphql";
import { AssembleHeader } from "src/components/AssembleHeader/AssembleHeader";
import { AutomatedManagerAccessTable } from "./AutomatedManagerAccessTable";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3.5),
  },
}));

type Props = {
  organization: Organization;
  reports: Report[];
  jobStructure: GetDepartments;
};

export function AutomatedManagerAccess({
  reports,
  jobStructure,
  organization,
}: Props): JSX.Element | null {
  const classes = useStyles();

  const isAutomatedManagerPermissionsFeatureOn =
    organization.permissionSettings.automatedManagerAccess !== "NONE";

  if (!isAutomatedManagerPermissionsFeatureOn) return null;

  return (
    <div className={classes.root}>
      <AssembleHeader
        title="Auto-permissions Band Access"
        description={
          "These are generated for each manager based on the reporting structure in your HRIS."
        }
      />

      <AutomatedManagerAccessTable
        reports={reports}
        jobStructure={jobStructure}
        organization={organization}
      />
    </div>
  );
}

AutomatedManagerAccess.fragments = {
  report: gql`
    ${AutomatedManagerAccessTable.fragments.report}
    fragment AutomatedManagerAccess_report on Employee {
      id
      ...AutomatedManagerAccessTable_report
    }
  `,
  organization: gql`
    ${AutomatedManagerAccessTable.fragments.organization}
    fragment AutomatedManagerAccess_organization on Organization {
      id
      permissionSettings {
        id
        automatedManagerAccess
      }
      ...AutomatedManagerAccessTable_organization
    }
  `,
};
