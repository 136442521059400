import { mapMaybe } from "@asmbl/shared/utils";
import {
  CompCyclePhaseData,
  PhaseConfigurationData,
} from "./usePhaseConfiguration";

/**
 * @param phaseConfiguration phase configuration data
 * @returns the absolute start date of all the phases in the configuration
 */
export function getCompCycleStartDate(
  phaseConfiguration: PhaseConfigurationData
): Date | undefined {
  return phaseConfiguration.length > 0
    ? phaseConfiguration
        .map((item) => item.startDate)
        .filter((date) => date != null)
        .reduce((earliest: Date | undefined, current: Date | undefined) => {
          return current && earliest
            ? current < earliest
              ? current
              : earliest
            : current || earliest;
        }, undefined)
    : undefined;
}
/**
 * @param phaseConfiguration phase configuration data
 * @returns 0 if the phase configuration is empty, otherwise the latest phase
 * order
 */
export function getLatestPhaseOrder(
  phaseConfiguration: PhaseConfigurationData
) {
  if (phaseConfiguration.length === 0) return 0;
  return Math.max(...phaseConfiguration.map((phase) => phase.phaseOrder));
}

/**
 * @param phaseConfiguration the phase configuration data
 * @param phaseOrder the phase order to get the start date of
 * @returns the start date of the phase of the given order
 */
export function getPhaseStartDate(
  phaseConfiguration: PhaseConfigurationData,
  phaseOrder: number
): Date | undefined {
  const startDate = phaseConfiguration.find(
    (phase) => phase.phaseOrder === phaseOrder
  )?.startDate;

  return startDate ?? undefined;
}

/**
 * @param phaseConfiguration the phase configuration data
 * @param phaseOrder the phase order to get the date range of
 * @returns the date range of the phase of the given order
 */
export function getPhaseDateRange(
  phaseConfiguration: PhaseConfigurationData,
  endDate: Date | undefined,
  phaseOrder: number
): { minDate: Date | undefined; maxDate: Date | undefined } {
  if (phaseConfiguration.length === 0) {
    return { minDate: undefined, maxDate: endDate };
  }

  const previousPhase = phaseConfiguration.find(
    (phase) => phase.phaseOrder === phaseOrder - 1
  );

  const currentPhase = phaseConfiguration.find(
    (phase) => phase.phaseOrder === phaseOrder
  );

  const nextPhase = phaseConfiguration.find(
    (phase) => phase.phaseOrder === phaseOrder + 1
  );

  if (!currentPhase) {
    return { minDate: undefined, maxDate: endDate };
  }

  return {
    minDate: previousPhase?.startDate ?? undefined,
    maxDate: nextPhase?.startDate ?? endDate,
  };
}

/**
 * @param phaseConfiguration phase configuration data
 * @param phaseOrder the phase order to get phase data of
 * @returns the phase data of the given phase order
 */
export const getCurrentPhaseConfiguration = (
  phaseConfiguration: PhaseConfigurationData,
  phaseOrder: number
) => phaseConfiguration.find((phase) => phase.phaseOrder === phaseOrder);

/**
 *
 * @param phaseOrder the phase order to initialize the phase data with
 * @returns a new phase data object with the given phase order
 */
export const initPhaseData = (phaseOrder: number): CompCyclePhaseData => {
  return {
    startDate: undefined,
    phaseOrder,
    assigneeIds: [],
  };
};

/**
 *
 * @param phaseConfiguration phase configuration data
 * @param employee the employee to check the assigned phase of
 * @returns the phase order of the phase the employee is assigned to
 */
export function getAssignedPhase<T extends { id: number }>(
  phaseConfiguration: PhaseConfigurationData,
  employee: T
): number | null {
  const assignedPhase = phaseConfiguration.find((phase) =>
    phase.assigneeIds.includes(employee.id)
  );

  return assignedPhase ? assignedPhase.phaseOrder : null;
}

/**
 *
 * @param phaseConfiguration phase configuration data
 * @param currentPhaseOrder the current phase order to check if the employee
 * is assigned to
 * @param employee the employee to check if they are assigned to another phase
 * @returns true if the employee is assigned to another phase, false otherwise
 */
export function isEmployeeAssignedToAnotherPhase<T extends { id: number }>(
  phaseConfiguration: PhaseConfigurationData,
  currentPhaseOrder: number,
  employee: T
): boolean {
  const assignedPhase = getAssignedPhase(phaseConfiguration, employee);

  return assignedPhase != null && assignedPhase !== currentPhaseOrder;
}

export function isEmployeeManagementChainAssignedToAnotherPhase<
  T extends { id: number; reports: { id: number }[] },
>(
  phaseConfiguration: PhaseConfigurationData,
  currentPhaseOrder: number,
  employee: T
): boolean {
  const assignedPhases = mapMaybe(employee.reports, (report) =>
    getAssignedPhase(phaseConfiguration, report)
  );

  return assignedPhases.some(
    (assignedPhase) => assignedPhase < currentPhaseOrder
  );
}

/**
 *
 * @param phaseConfiguration phase configuration data
 * @param currentPhaseOrder the current phase order to check if the employee
 * is assigned to
 * @param employee the employee to check if they are assigned to the
 * current phase
 * @returns true if the employee is assigned to the current phase,
 * false otherwise
 */
export function isEmployeeAssignedToCurrentPhase<T extends { id: number }>(
  phaseConfiguration: PhaseConfigurationData,
  currentPhaseOrder: number,
  employee: T
): boolean {
  const assignedPhase = getAssignedPhase(phaseConfiguration, employee);

  return assignedPhase === currentPhaseOrder;
}

export function attachReports<
  T extends { id: number; managerId: number | null },
>(employees: T[]): Map<number, T & { reports: number[] }> {
  const employeeMap = new Map<number, T & { reports: number[] }>();

  employees.forEach((employee) => {
    employeeMap.set(employee.id, { ...employee, reports: [] });
  });

  employees.forEach((employee) => {
    const currentEmployee = employeeMap.get(employee.id);

    if (!currentEmployee) return;

    currentEmployee.reports = getReports(employeeMap, employee.id);
  });

  return employeeMap;
}

export function getReports<T extends { id: number; managerId: number | null }>(
  employees: Map<number, T>,
  employeeId: number
): number[] {
  const reports: number[] = [];

  employees.forEach((employee) => {
    if (employee.managerId === employeeId) {
      reports.push(employee.id);
      reports.push(...getReports(employees, employee.id));
    }
  });

  return reports;
}
