import { css, cx } from "@emotion/css";
import React from "react";
import { GRAY_1 } from "src/theme";
import { AssembleIconProps } from "./types";

type Props = AssembleIconProps & { children: React.ReactElement };

const styles = (props: AssembleIconProps) => {
  const inherit = props.inherit === true;
  const color = props.color ?? GRAY_1;
  const hoverColor = props.hoverColor ?? color;
  const focusColor = props.focusColor ?? hoverColor;
  const activeColor = props.activeColor ?? focusColor;

  return css`
    display: ${props.inline === true ? "inline-block" : "block"};

    & path,
    circle,
    rect {
      &.stroke {
        stroke: ${inherit ? "currentColor" : color};
      }

      &.fill {
        fill: ${inherit ? "currentColor" : color};
      }
    }

    &:hover {
      & path,
      circle,
      rect {
        &.stroke {
          stroke: ${inherit ? "currentColor" : hoverColor};
        }
      }

      & path,
      circle,
      rect {
        &.fill {
          fill: ${inherit ? "currentColor" : hoverColor};
        }
      }
    }

    &:focus {
      & path,
      circle,
      rect {
        &.stroke {
          stroke: ${inherit ? "currentColor" : focusColor};
        }
      }

      & path,
      circle,
      rect {
        &.fill {
          fill: ${inherit ? "currentColor" : focusColor};
        }
      }
    }

    &:active {
      & path,
      circle,
      rect {
        &.stroke {
          stroke: ${inherit ? "currentColor" : activeColor};
        }
      }

      & path,
      circle,
      rect {
        &.fill {
          fill: ${inherit ? "currentColor" : activeColor};
        }
      }
    }
  `;
};

export const ColorableSVG = ({
  className,
  children,
  ...props
}: Props): JSX.Element =>
  React.cloneElement(children, { className: cx(className, styles(props)) });
