import { useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";
import NoHrisSvg from "../../assets/svgs/illustrations/no-hris-integration.svg?react";
import { AssembleLink } from "../../components/AssembleLink";
import { EmptyTableView } from "../../components/EmptyTableView";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import {
  COMP_STRUCTURE_QUERY,
  DEPARTMENTS_QUERY,
  GET_MERGE_CONFIGS,
} from "../../queries";
import {
  GetCompStructure,
  GetDepartments,
  GetMergeConfigs,
} from "../../__generated__/graphql";
import { Comparatio } from "./Comparatio";
import { LevelComparatio } from "./LevelComparatio";

export function Comparatios(): JSX.Element {
  const {
    data: configData,
    loading: configLoading,
    error,
  } = useQuery<GetMergeConfigs>(GET_MERGE_CONFIGS);

  const { data: departmentsData, loading: departmentsLoading } =
    useQuery<GetDepartments>(DEPARTMENTS_QUERY);
  const { data: compStructureData, loading: compStructureLoading } =
    useQuery<GetCompStructure>(COMP_STRUCTURE_QUERY);

  if (configLoading || departmentsLoading || compStructureLoading) {
    return <LoadingSpinner />;
  }

  const levels: number[] = compStructureData?.compStructure?.levels ?? [];
  const departments =
    departmentsData?.departments.map((department) => department.name).sort() ??
    [];
  return error ||
    (configData !== undefined &&
      configData.mergeConnectionConfigs.length > 0) ? (
    <>
      <Box m={2} />
      <Comparatio
        departments={departments}
        levels={levels}
        groupByFilter="gender"
        title="Gender Compa-ratio"
      />
      <Box m={2} />
      <Comparatio
        departments={departments}
        levels={levels}
        groupByFilter="ethnicity"
        title="Race & Ethnicity Compa-ratio"
      />
      <Box m={2} />
      <LevelComparatio departments={departments} />
    </>
  ) : (
    <EmptyTableView
      message={
        <>
          Go to your{" "}
          <AssembleLink to="/settings/data">
            Data Management Settings
          </AssembleLink>{" "}
          and add your HRIS integration then level your employees in order to
          see your employees' compa-ratios.
        </>
      }
      svg={<NoHrisSvg />}
      width="25em"
    />
  );
}
