import { DualContentCell } from "./DualContentCell";

interface Props {
  className?: string;
  department?: string;
  ladder?: string;
  textColor?: string;
}

export function DepartmentAndLadderCell({
  className,
  department,
  ladder,
  textColor,
}: Props): JSX.Element {
  return (
    <DualContentCell
      string1={department}
      string2={ladder}
      className={className}
      textColor={textColor}
    />
  );
}
