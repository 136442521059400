import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { Money } from "@asmbl/shared/money";

export function getIllustrativeUnitPrice<
  T extends { sharePrice: Money | null },
>(
  currencies: Map<CurrencyCode, Currency<CurrencyCode>>,
  grants: T[] | null,
  valuationCurrency: Currency
): Money<CurrencyCode> | null {
  const sharePrice = grants && grants.length > 0 ? grants[0].sharePrice : null;

  if (sharePrice == null) return null;

  const currency = currencies.get(sharePrice.currency);

  if (currency == null) return null;

  return exchangeFromTo(sharePrice, currency, valuationCurrency);
}

export function formatUnitSharePrice(
  sharePriceTarget: number | undefined,
  sharePriceFallback: Money<CurrencyCode> | null
): Money<CurrencyCode> | null {
  if (sharePriceFallback === null) {
    return null;
  }

  return sharePriceTarget === undefined
    ? {
        // make sure the share price value is to nearest cent
        value: parseFloat(sharePriceFallback.value.toFixed(2)),
        currency: sharePriceFallback.currency,
      }
    : {
        // make sure the share price value is to nearest cent
        value: parseFloat(sharePriceTarget.toFixed(2)),
        currency: sharePriceFallback.currency,
      };
}
