import { Box, Typography } from "@material-ui/core";
import { memo, ReactNode } from "react";
import { GRAY_1, GRAY_2 } from "../../../theme";

//  ----------------------------------------------------------------------------
//  Types
//  ----------------------------------------------------------------------------
export type SectionProps = {
  id?: string;
  active: boolean;
  children: React.ReactNode;
  description: ReactNode;
  header: string;
};

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
const Section = ({
  id,
  active,
  children,
  description,
  header,
}: SectionProps) => {
  return (
    <Box
      id={id}
      display="flex"
      flexDirection="column"
      style={{ opacity: active ? 1 : 0.2 }}
    >
      <Box mb={1}>
        <Typography variant="h4" style={{ color: GRAY_1, fontWeight: 500 }}>
          {header}
        </Typography>
        <Typography variant="body2" style={{ color: GRAY_2 }}>
          {description}
        </Typography>
      </Box>
      <Box mt={2} style={{ color: GRAY_2 }}>
        {children}
      </Box>
    </Box>
  );
};

export default memo(Section);
