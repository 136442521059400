import { gql } from "@apollo/client";
import { PrimaryRoleName } from "@asmbl/shared/permissions";
import { makeStyles, TableCell, TableRow } from "@material-ui/core";
import { useMemo } from "react";
import { NOUN_SCOPES_FIELDS } from "../../../../mutations/User";
import { AccessChip } from "../../../../pages/People/AccessChip";
import { BadgedUserAvatar } from "../../../../pages/People/BadgedUserAvatar";
import { TeamCompensationAccessType } from "../../../../__generated__/graphql";
import { AssembleTypography } from "../../../AssembleTypography";
import { isExistingUser, isPendingUser, UserOrPendingUser } from "../utils";

const useStyles = makeStyles((theme) => ({
  userRow: { height: "4.375rem" },
  avatarCell: { width: "32px", paddingLeft: theme.spacing(2) },
}));

export function AccessControlTableRow({
  user,
  teamCompensationAccess,
}: {
  user: UserOrPendingUser;
  canEditAccessControl: boolean;
  teamCompensationAccess: TeamCompensationAccessType;
}): JSX.Element {
  const classes = useStyles();

  const hasFullAccess = isExistingUser(user)
    ? user.userAccessGrant?.roleName === PrimaryRoleName.FULL_ACCESS
    : user.userInvitationAccessGrant?.roleName === PrimaryRoleName.FULL_ACCESS;

  const compDataAccess = useMemo(() => {
    if (hasFullAccess) return "Everyone";
    if (
      user.employee?.isManager === true &&
      teamCompensationAccess !== TeamCompensationAccessType.NONE
    ) {
      return "Reports";
    }
    return "-";
  }, [hasFullAccess, teamCompensationAccess, user.employee]);

  const isUser = isExistingUser(user);
  const userChipId: number | null = isUser
    ? user.id
    : isPendingUser(user)
      ? user.userInvitationAccessGrant?.id ?? null
      : null;

  const createdAtDate = new Date(user.createdAt).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return (
    <TableRow key={user.id} className={classes.userRow}>
      <TableCell className={classes.avatarCell} padding="none">
        <BadgedUserAvatar
          displayName={user.name}
          photoURL={isUser ? user.photoURL : null}
        />
      </TableCell>
      <TableCell>
        <AssembleTypography variant="productTableContent">
          {user.name}
        </AssembleTypography>
        <AssembleTypography variant="productTableContent">
          {user.email}
        </AssembleTypography>
      </TableCell>
      <TableCell>
        <AssembleTypography variant="productTableContent">
          {user.employee?.activeEmployment?.jobTitle ?? "-"}
        </AssembleTypography>
      </TableCell>
      <TableCell>
        <AssembleTypography variant="productTableContent">
          {user.employee?.totalReportsCount ?? "-"}
        </AssembleTypography>
      </TableCell>
      <TableCell>
        <AssembleTypography variant="productTableContent">
          {compDataAccess}
        </AssembleTypography>
      </TableCell>
      <TableCell>
        <AssembleTypography variant="productTableContent">
          {createdAtDate}
        </AssembleTypography>
      </TableCell>
      <TableCell>
        <AccessChip
          email={user.email}
          teamCompensationAccess={teamCompensationAccess}
          userAccessGrant={
            isUser ? user.userAccessGrant : user.userInvitationAccessGrant
          }
          userId={userChipId}
          userIsDisabled={isUser ? user.isDisabled : false}
          userIsPending={isPendingUser(user)}
        />
      </TableCell>
    </TableRow>
  );
}

AccessControlTableRow.fragments = {
  activeUserInvitations: gql`
    ${NOUN_SCOPES_FIELDS}
    fragment AccessControlTableRow_activeUserInvitations on UserInvitation {
      id
      email
      name
      createdAt
      userInvitationAccessGrant {
        id
        roleName
        nounScopes {
          ...NounScopesFields
        }
      }
      employee {
        id
        isManager
        totalReportsCount
        activeEmployment {
          id
          jobTitle
        }
      }
    }
  `,
  users: gql`
    ${NOUN_SCOPES_FIELDS}
    fragment AccessControlTableRow_userRecords on User {
      id
      email
      name
      createdAt
      photoURL
      isDisabled
      userAccessGrant {
        id
        roleName
        nounScopes {
          ...NounScopesFields
        }
      }
      employee {
        id
        isManager
        totalReportsCount
        activeEmployment {
          id
          jobTitle
        }
      }
    }
  `,
  permissionSettings: gql`
    fragment AccessControlTableRow_permissionSettings on PermissionSettings {
      id
      teamCompensationAccess
    }
  `,
};
