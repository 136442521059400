import { makeStyles, TableCell, Typography } from "@material-ui/core";
import { GRAY_3, GRAY_6 } from "../../../../theme";
import {
  ConnectorLines,
  IndentationCells,
} from "../../Budgets/Table/Connectors";
import { RequestToolTip } from "./RequestTooltip";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "12px",
    height: "100%",
    width: "100%",
    borderLeft: `1px solid ${GRAY_6}`,
    borderBottom: `1px solid ${GRAY_6}`,
    borderRight: `1px solid ${GRAY_6}`,
  },
  mainCell: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    border: "none !important",
    padding: 0,
  },
  mainCellText: {
    padding: theme.spacing(0, 1),
    paddingTop: "2px",
    lineHeight: "1.4",
    fontSize: "0.75rem",
    fontWeight: "normal",
    letterSpacing: "-0.15px",
    color: GRAY_3,
    whiteSpace: "nowrap",
  },
}));

type Props = {
  subjectName: string;
  indentation: IndentationCells;
};

export function SummarizedNameCell({
  subjectName,
  indentation,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <TableCell className={classes.mainCell} width="30%">
      <ConnectorLines indentation={indentation} keyPrefix={subjectName} />

      <div className={classes.contentContainer}>
        <RequestToolTip subjectName={subjectName} />
        <Typography className={classes.mainCellText}>
          {`${subjectName}'s Requests`}
        </Typography>
      </div>
    </TableCell>
  );
}
