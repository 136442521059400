import { formatCurrency, Money, multiply } from "@asmbl/shared/money";
import { getFormattedDate } from "@asmbl/shared/time";
import { formatNumeral } from "@asmbl/shared/utils";
import { Divider, makeStyles, Paper } from "@material-ui/core";
import { Fragment } from "react";
import { GRAY_2, GRAY_4 } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "17rem",
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "8px",
    boxShadow: "0px 15px 25px rgba(10, 36, 64, 0.08)",
  },
  dateLabel: { color: GRAY_4 },
  li: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  summaryElement: { width: "50%" },
  name: { color: GRAY_2, flex: 1 },
  divider: { marginBlock: theme.spacing(1) },
}));

type Props = {
  sharePrice: Money | null;
  totalUnitCount: number | null;
} & Partial<{
  // These properties are injected by the chart component:
  label: number; // The millisecond timestamp of the date
  payload: {
    payload: {
      currentUnitCount: number;
      grantName: string;
      unitCount: number;
      vestingDate: string;
    };
  }[];
}>;

export function EquityTooltip({
  label,
  payload,
  sharePrice,
  totalUnitCount,
}: Props): JSX.Element | null {
  const classes = useStyles();

  if (label === undefined || payload === undefined) {
    return null;
  }

  return (
    <Paper className={classes.paper} elevation={0}>
      <AssembleTypography
        variant="productEyebrow"
        className={classes.dateLabel}
        gutterBottom
      >
        {getFormattedDate(new Date(label))}
      </AssembleTypography>
      {payload.map(({ payload: { unitCount, currentUnitCount } }, index) => (
        <Fragment key={index}>
          <div className={classes.li}>
            <AssembleTypography variant="productSmall" className={classes.name}>
              Vesting Units
            </AssembleTypography>
            <AssembleTypography
              variant="productSmallBold"
              data-cy="equity-timeline-chart-tooltip-vesting-units"
              align="right"
            >
              {formatNumeral(currentUnitCount)}
            </AssembleTypography>
          </div>
          <div className={classes.li}>
            <AssembleTypography variant="productSmall" className={classes.name}>
              Unit Price
            </AssembleTypography>
            <AssembleTypography
              variant="productSmallBold"
              data-cy="equity-timeline-chart-tooltip-unit-price"
              align="right"
            >
              {sharePrice ? formatCurrency(sharePrice) : "-"}
            </AssembleTypography>
          </div>
          <div className={classes.li}>
            <AssembleTypography variant="productSmall" className={classes.name}>
              Vesting Value
            </AssembleTypography>
            <AssembleTypography
              variant="productSmallBold"
              data-cy="equity-timeline-chart-tooltip-vesting-value"
              align="right"
            >
              {sharePrice
                ? formatCurrency(multiply(sharePrice, currentUnitCount))
                : "-"}
            </AssembleTypography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.li}>
            <div className={classes.summaryElement}>
              <AssembleTypography
                variant="productSmall"
                className={classes.name}
              >
                Total Vested Units
              </AssembleTypography>
            </div>
            <div className={classes.summaryElement}>
              <AssembleTypography
                variant="productSmallBold"
                data-cy="equity-timeline-chart-tooltip-total-vested"
                align="right"
              >
                {formatNumeral(unitCount)}{" "}
                {totalUnitCount !== null &&
                  `(${formatNumeral(unitCount / totalUnitCount, {
                    style: "percent",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })})`}
              </AssembleTypography>
            </div>
          </div>
          <div className={classes.li}>
            <div className={classes.summaryElement}>
              <AssembleTypography
                variant="productSmall"
                className={classes.name}
              >
                Total Vested Value
              </AssembleTypography>
            </div>
            <div className={classes.summaryElement}>
              <AssembleTypography
                variant="productSmallBold"
                data-cy="equity-timeline-chart-tooltip-total-vested"
                align="right"
              >
                {sharePrice && sharePrice.value
                  ? formatCurrency(multiply(sharePrice, unitCount))
                  : "-"}
              </AssembleTypography>
            </div>
          </div>
        </Fragment>
      ))}
    </Paper>
  );
}
