import { Box, makeStyles, Typography } from "@material-ui/core";
import { GRAY_6, GRAY_8 } from "../theme";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    background: GRAY_8,
    borderBottom: `1px solid ${GRAY_6}`,
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    paddingInline: theme.spacing(2),
    minHeight: "3rem",
  },
}));
export function PaperHeader({ children }: { children: string }): JSX.Element {
  const classes = useStyles();
  return (
    <Box className={classes.header}>
      <Typography>{children}</Typography>
    </Box>
  );
}
