import { gql } from "@apollo/client";
import { makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { useTrack } from "src/analytics";
import {
  CompRecommendationInput,
  CompCycleUploadRequests_employee as Employee,
  CompCycleUploadRequests_position as Position,
  CompCycleUploadRequests_valuation as Valuation,
} from "../../../__generated__/graphql";
import CsvImportIcon from "../../../assets/svgs/illustrations/csv-import.svg?react";
import { useEmplaceCompRecommendationsWithCompCycleId } from "../../../mutations/CompRecommendation";
import { BLUE_2, GRAY_4, GRAY_6, GRAY_7, WHITE } from "../../../theme";
import { withMinWait } from "../../../utils";
import { AssembleButton } from "../../AssembleButton/AssembleButton";
import { AssembleTypography } from "../../AssembleTypography";
import Section from "../../Workflow/Section";
import { UploadRequestsButton } from "../Buttons/UploadRequestsButton";
import {
  CompCycleData,
  CompCycleDataChangeHandler,
} from "../CompCycleWizard/types";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    left: "50%",
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(2),
    position: "absolute",
    transform: "translate(-50%)",
    width: theme.spacing(67),
  },
  contentInner: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(4),
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
  },
  card: {
    background: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px",
    padding: theme.spacing(5),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: theme.spacing(2),
  },
  fileFormatBlock: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: GRAY_7,
    columnGap: theme.spacing(1),
    padding: theme.spacing(2),
  },
  fileFormatTitle: { textTransform: "uppercase" },
  fileFormatDescription: {
    textAlign: "center",
    fontFamily: "OverpassMono",
    fontSize: "0.8125rem",
    fontWeight: 600,
    lineHeight: "1.4",
  },
  buttonBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
  },
  buttonBarUpdate: {
    justifyContent: "flex-end",
  },
}));

/**
 * Using discriminant union types to represent the different props that can be
 * passed since I do not want to recreate this component when the functionality
 * is nearly identical in the create wizard step and the settings step
 */
type Props =
  | {
      mode: "create";
      valuation: Valuation;
      employees: Employee[];
      positions: Position[];
      compCycleData: CompCycleData;
      handleChange: CompCycleDataChangeHandler;
      onSubmit: (data: CompCycleData) => Promise<unknown>;
      onBack: () => unknown;
    }
  | {
      mode: "update";
      compCycleId: number;
      valuation: Valuation;
      employees: Employee[];
      positions: Position[];
      compCycleData: Pick<CompCycleData, "compComponents">;
    };

export default function CompCycleUploadRequests(
  props: Props
): JSX.Element | null {
  const classes = useStyles();
  const { trackEvent } = useTrack();
  const { mode, valuation, employees, positions, compCycleData } = props;

  const emplaceCompRecommendations =
    useEmplaceCompRecommendationsWithCompCycleId();

  const requestsAndRecommendationsChange =
    mode === "create"
      ? (data: Array<CompRecommendationInput>): Promise<unknown> => {
          props.handleChange("requestsAndRecommendations", data);

          trackEvent({
            object: "Change Request",
            action: "Created",
            subArea: "CSV Import",
          });

          return props.onSubmit({
            ...compCycleData,
            requestsAndRecommendations: data,
          });
        }
      : async (data: Array<CompRecommendationInput>): Promise<unknown> => {
          trackEvent({
            object: "Change Request",
            action: "Updated",
            subArea: "CSV Import",
            compCycleId: props.compCycleId,
          });
          return await withMinWait(
            () => emplaceCompRecommendations(props.compCycleId, data),
            2_000
          );
        };

  return (
    <div className={classes.content}>
      <Section
        active={true}
        header="Add requests and recommendations."
        description={
          <>
            Bulk import requests and recommendations for your people by
            uploading a .csv or Excel file.
          </>
        }
      >
        <div className={classes.contentInner}>
          <div className={classes.cardContainer}>
            <div className={classes.card}>
              <div className={classes.cardContent}>
                <CsvImportIcon />
                <div className={classes.fileFormatBlock}>
                  <AssembleTypography
                    className={classes.fileFormatTitle}
                    variant="productEyebrowSmall"
                    textColor={GRAY_4}
                  >
                    File Format:
                  </AssembleTypography>
                  <AssembleTypography
                    className={classes.fileFormatDescription}
                    textColor={BLUE_2}
                  >
                    Dependent on Cycle Settings
                  </AssembleTypography>
                </div>
                <AssembleTypography
                  variant="productMicrocopy"
                  textColor={GRAY_4}
                >
                  You can skip this step and visit cycle settings to add them
                  later if you'd like.
                </AssembleTypography>
              </div>
            </div>
          </div>
          <div
            className={clsx(classes.buttonBar, {
              [classes.buttonBarUpdate]: mode === "update",
            })}
          >
            {mode === "create" ? (
              <AssembleButton
                onClick={props.onBack}
                label="Back"
                size="medium"
                variant="text"
              />
            ) : null}
            {mode === "create" ? (
              <AssembleButton
                onClick={() => props.onSubmit(compCycleData)}
                label="Skip this step"
                size="medium"
                variant="text"
              />
            ) : null}
            <UploadRequestsButton
              label="Import Your Requests"
              employees={employees}
              positions={positions}
              currentValuation={valuation}
              handleChange={requestsAndRecommendationsChange}
              compComponentSettings={compCycleData.compComponents}
            />
          </div>
        </div>
      </Section>
    </div>
  );
}

CompCycleUploadRequests.fragments = {
  employee: gql`
    ${UploadRequestsButton.fragments.employee}
    fragment CompCycleUploadRequests_employee on Employee {
      id
      ...UploadRequestsButton_employee
    }
  `,
  valuation: gql`
    ${UploadRequestsButton.fragments.valuation}
    fragment CompCycleUploadRequests_valuation on Valuation {
      id
      ...UploadRequestsButton_valuation
    }
  `,
  position: gql`
    ${UploadRequestsButton.fragments.position}
    fragment CompCycleUploadRequests_position on Position {
      id
      ...UploadRequestsButton_position
    }
  `,
};
