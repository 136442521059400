import { ReactNode } from "react";
import { Noun } from "../__generated__/graphql";
import { useAuth } from "./Auth/AuthContext";

/* AccessBoundary is a component that declaratively renders (or not) based on
 * whether the current user has certain permissions. It's interface is modeled
 * after the similar PreviewFeature component.
 */

type AccessBoundaryProps = {
  children?: ReactNode;
  fallback?: ReactNode;
  scope: "global" | "any";
  verb: "view" | "edit";
} & (
  | {
      noun: Noun;
    }
  | {
      every: Noun[];
    }
);

export function AccessBoundary(props: AccessBoundaryProps): JSX.Element {
  const { permissions } = useAuth();

  const hasPermissionRecord: Record<
    "global" | "any",
    Record<"view" | "edit", (noun: Noun) => boolean>
  > = {
    global: {
      view: (noun) => permissions.canViewGlobal(noun),
      edit: (noun) => permissions.canEditGlobal(noun),
    },
    any: {
      view: (noun) => permissions.canViewAny(noun),
      edit: (noun) => permissions.canEditAny(noun),
    },
  };

  const hasPermission = hasPermissionRecord[props.scope][props.verb];

  const nouns = "every" in props ? props.every : [props.noun];

  return <>{nouns.every(hasPermission) ? props.children : props.fallback}</>;
}
