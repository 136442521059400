import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { GRAY_6 } from "../../../theme";
import {
  SubmittedRecommendationCard_activeEmployment,
  SubmittedRecommendationCard_cashCompensation,
  SubmittedRecommendationCard_perfRating,
  SubmittedRecommendationCard_submittedItem,
  SubmittedSummaryCard_submittedReview,
} from "../../../__generated__/graphql";
import { SubmittedRecommendationCard } from "./SubmittedRecommendationCard";
import { SubmittedReviewCard } from "./SubmittedReviewCard";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  reviews: {
    // Left connector line
    background: `linear-gradient(${GRAY_6}, ${GRAY_6}) no-repeat left 11px top 0 / 1px 100%`,
  },
}));

type Props = {
  submittedItems: SubmittedRecommendationCard_submittedItem[];
  submittedReviews: SubmittedSummaryCard_submittedReview[];
  activeEmployment: SubmittedRecommendationCard_activeEmployment | null;
  cashCompensations: SubmittedRecommendationCard_cashCompensation[];
  perfRating: SubmittedRecommendationCard_perfRating | null;
  finalApproversIds: number[];
};

export function SubmittedSummaryCard({
  submittedItems,
  submittedReviews,
  activeEmployment,
  cashCompensations,
  perfRating,
  finalApproversIds,
}: Props): JSX.Element {
  const classes = useStyles();

  const reverseChronologicalReviews = submittedReviews
    .slice()
    .sort(
      (a, b) =>
        new Date(a.submittedAt as string).getTime() -
        new Date(b.submittedAt as string).getTime()
    )
    .reverse();

  return (
    <div className={classes.root}>
      <div className={classes.reviews}>
        {reverseChronologicalReviews.map((review, index) => (
          <SubmittedReviewCard
            key={review.id}
            submittedReview={review}
            finalApproversIds={finalApproversIds}
            isMostRecentReview={index === 0}
          />
        ))}
      </div>
      <SubmittedRecommendationCard
        cashCompensations={cashCompensations}
        submittedItems={submittedItems}
        activeEmployment={activeEmployment}
        perfRating={perfRating}
      />
    </div>
  );
}

SubmittedSummaryCard.fragments = {
  submittedReview: gql`
    ${SubmittedReviewCard.fragments.review}
    fragment SubmittedSummaryCard_submittedReview on RecReview {
      id
      ...SubmittedReviewCard_review
    }
  `,
  cashCompensation: gql`
    ${SubmittedRecommendationCard.fragments.cashCompensation}
    fragment SubmittedSummaryCard_cashCompensation on CashCompensation {
      ...SubmittedRecommendationCard_cashCompensation
    }
  `,
};
