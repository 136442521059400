import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { makeStyles, TableCell } from "@material-ui/core";
import { useURLSearchParams } from "../../models/URLSearchParams";
import { NeedsLevelingAvatar } from "../../pages/People/BadgedUserAvatar";
import { GRAY_2, GRAY_4 } from "../../theme";
import { CalendarIcon } from "../AssembleIcons/Extra-Small/CalendarIcon";
import { ClockIcon } from "../AssembleIcons/Extra-Small/ClockIcon";
import { AssembleLink } from "../AssembleLink";
import { AssembleTruncatedTypography } from "../AssembleTruncatedTypography";
import { useCompStructure } from "../CompStructureContext";
import { useFeatureFlags } from "../FeatureContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(1.5),
    margin: theme.spacing(-0.5, 1, -0.5, 0),
  },
  positionContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  iconContainer: {
    height: "18px",
    display: "flex",
    alignItems: "center",
  },
}));
interface Props {
  displayName: string;
  photoURL: string | null | undefined;
  employeeId: number;
  position: string | undefined;
  canOpenDetailView?: true;
  isHourly: boolean;
  needsLeveling: boolean;
  canUserLevel: boolean;
  className?: string;
  useGrayscale?: boolean;
}

export function NameAndPositionWithAvatarCell({
  displayName,
  photoURL,
  employeeId,
  position,
  className,
  canOpenDetailView,
  needsLeveling,
  canUserLevel,
  isHourly,
  useGrayscale = false,
}: Props): JSX.Element {
  const classes = useStyles();
  const urlSearchParams = useURLSearchParams();
  const neverLeveled = useGrayscale ? GRAY_4 : "inherit";
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();

  const showHourly =
    compStructure?.allowHourlyEmployees === true &&
    isEnabled(FeatureFlag.HourlyEmployees);

  const displayNameText = (
    <AssembleTruncatedTypography
      variant="productExtraSmallSemiBold"
      textColor={neverLeveled}
    >
      {displayName}
    </AssembleTruncatedTypography>
  );

  return (
    <TableCell className={className}>
      <div className={classes.root}>
        <NeedsLevelingAvatar
          photoURL={photoURL ?? null}
          displayName={displayName}
          needsLeveling={needsLeveling}
          canUserLevel={canUserLevel}
          backgroundColor={needsLeveling === true ? GRAY_2 : undefined}
        />
        <div>
          {canOpenDetailView === true ? (
            <AssembleLink
              to={`?${urlSearchParams
                .set("employee", employeeId.toString())
                .toString()}`}
              whitespace={false}
              color="textPrimary"
              underline="none"
            >
              displayNameText
            </AssembleLink>
          ) : (
            displayNameText
          )}
          {showHourly ? (
            <div className={classes.positionContainer}>
              <div className={classes.iconContainer}>
                {position !== undefined && position !== "Unleveled" ? (
                  isHourly ? (
                    <ClockIcon />
                  ) : (
                    <CalendarIcon />
                  )
                ) : null}
              </div>
              <AssembleTruncatedTypography textColor={neverLeveled}>
                {position ?? "Unleveled"}
              </AssembleTruncatedTypography>
            </div>
          ) : (
            <AssembleTruncatedTypography textColor={neverLeveled}>
              {position ?? "Unleveled"}
            </AssembleTruncatedTypography>
          )}
        </div>
      </div>
    </TableCell>
  );
}
