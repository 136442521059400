import { TrackingComponent, TrackingHook, useTracking } from "react-tracking";

export type AssembleEvent = {
  object: string;
  action: string;
  area: string;
  subArea: string;
  [key: string]: string | number | boolean | null;
};

export function useInitTrack(): TrackingHook<AssembleEvent>["Track"] {
  const dispatch = (data: AssembleEvent) => {
    const eventName = `${data.object} - ${data.action}`;
    window.analytics.track(eventName, data);
  };

  const { Track } = useTracking<AssembleEvent>({}, { dispatch });
  return Track;
}
export function useTrack(trackingData?: Partial<AssembleEvent>): {
  trackEvent: (this: void, data: Partial<AssembleEvent>) => void;
  Track: TrackingComponent<AssembleEvent>;
} {
  return useTracking<AssembleEvent>(trackingData);
}
