import { useQuery } from "@apollo/client";
import { useState } from "react";
import { BorderlessSelect } from "../../components/Form/BorderlessSelect";
import { ActionBarItem } from "../../components/Visualization/ActionBarItem";
import { ComparatioChart } from "../../components/Visualization/ComparatioChart";
import { FilterableCard } from "../../components/Visualization/FilterableCard";
import { GET_EMPLOYEE_ANALYTICS_QUARTILES } from "../../queries";
import { GetEmployeeAnalyticsQuartiles } from "../../__generated__/graphql";
interface Props {
  departments: string[];
}

export function LevelComparatio({ departments }: Props): JSX.Element {
  const departmentFilters = ["All Departments", ...departments];
  const [departmentFilter, setDepartmentFilter] = useState<string | null>(null);
  const handleDepartmentChange = (value: string) => {
    value === "All Departments"
      ? setDepartmentFilter(null)
      : setDepartmentFilter(value);
  };

  const generateTitleScope = (title: string) => {
    return departmentFilter === null
      ? `${title} Across the Organization`
      : `${title} Across ${departmentFilter}`;
  };
  const { data: quartilesByLevel, loading } =
    useQuery<GetEmployeeAnalyticsQuartiles>(GET_EMPLOYEE_ANALYTICS_QUARTILES, {
      variables: {
        groupByFilterOpts: {
          groupBy: "level",
          department: departmentFilter,
        },
      },
    });

  const quartiles = quartilesByLevel?.employeeAnalytics.quartiles ?? [];
  const comparatioItems = Array.from(quartiles)
    .sort((a, b) => parseInt(a.label) - parseInt(b.label))
    .map((quartile) => {
      return {
        ...quartile,
        label: `Level ${quartile.label}`,
      };
    });

  return (
    <FilterableCard
      title={generateTitleScope("Level Compa-ratio")}
      actionBar={
        <ActionBarItem>
          <BorderlessSelect
            options={departmentFilters}
            selected={departmentFilter ?? "All Departments"}
            onChange={handleDepartmentChange}
          />
        </ActionBarItem>
      }
    >
      <ComparatioChart
        items={comparatioItems}
        height="550px"
        loading={loading}
      />
    </FilterableCard>
  );
}
