import { makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { useImportEmployeePerfScores } from "src/mutations/CompCycle";
import { withMinWait } from "src/utils";
import { BLUE_2, GRAY_4, GRAY_6, GRAY_7, WHITE } from "../../../theme";
import { AssembleButton } from "../../AssembleButton/AssembleButton";
import Section from "../../Workflow/Section";
import {
  EmployeePerfScoreRow,
  PerfImportButton,
} from "../Buttons/PerfImportButton";
import { PerfScoreExplanation } from "../CompCycleWizard/PerfScoreExplanation";
import { CompCycleDataChangeHandler } from "../CompCycleWizard/types";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    left: "50%",
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(2),
    position: "absolute",
    transform: "translate(-50%)",
    width: theme.spacing(67),
  },
  perfDescription: {
    margin: theme.spacing(2, 0, 4, 0),
  },
  perfDescriptionOld: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(5),
    backgroundColor: WHITE,
    borderColor: GRAY_6,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  csvImportBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    columnGap: theme.spacing(4),
  },
  fileFormatBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: GRAY_7,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  fileFormatTypography: {
    color: GRAY_4,
    fontSize: "12px",
    fontWeight: "bold",
    fontFamily: "Circular",
    lineHeight: "17px",
    letterSpacing: "0em",
    textTransform: "uppercase",
  },
  fileFieldsTypography: {
    color: BLUE_2,
    paddingLeft: theme.spacing(2),
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "OverpassMono",
  },
  buttonBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
  },
  buttonBarUpdate: {
    justifyContent: "flex-end",
  },
  buttonBarNextContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(2),
  },
  perfButton: {
    left: "50%",
    position: "absolute",
    top: "-52px",
    translate: "-50% 0",
  },
}));

/**
 * Using discriminant union types to represent the different props that can be
 * passed since I do not want to recreate this component when the functionality
 * is nearly identical in the create wizard step and the settings step
 */
type Props =
  | {
      mode: "create";
      handleChange: CompCycleDataChangeHandler;
      onNext: () => unknown;
      onBack: () => unknown;
    }
  | { mode: "update"; compCycleId: number };

export default function CompCyclePerformanceRatings(props: Props): JSX.Element {
  const { mode } = props;
  const classes = useStyles();
  const importEmployeePerfScores = useImportEmployeePerfScores();

  const headerDescription = (
    <>
      Import your ratings directly from an existing CSV or Excel file. <br /> If
      your performance ratings aren't ready, skip this step and upload them at
      any point during the cycle.
    </>
  );

  const performanceRatingsChange =
    mode === "create"
      ? (perfData: Array<EmployeePerfScoreRow>) => {
          props.handleChange("perfData", perfData);
          props.onNext();
        }
      : async (perfData: Array<EmployeePerfScoreRow>) => {
          return await withMinWait(
            () =>
              importEmployeePerfScores({
                compCycleId: props.compCycleId,
                employeePerfScores: perfData.map((row) => ({
                  email: row.email,
                  perfRating: row.perfRating,
                  note: row.note,
                })),
              }),
            2_000
          );
        };

  return (
    <div className={classes.content}>
      <Section
        active={true}
        header="Add your people's performance ratings."
        description={headerDescription}
      >
        <PerfScoreExplanation className={classes.perfDescription} />

        <div
          className={clsx(classes.buttonBar, {
            [classes.buttonBarUpdate]: mode === "update",
          })}
        >
          {mode === "create" ? (
            <AssembleButton
              onClick={props.onBack}
              label="Back"
              size="medium"
              variant="text"
            />
          ) : null}
          <PerfImportButton handleChange={performanceRatingsChange} />
          <div className={classes.buttonBarNextContainer}>
            {mode === "create" ? (
              <AssembleButton
                onClick={() => props.onNext()}
                size="medium"
                variant="text"
                label="Skip this step"
              />
            ) : null}
          </div>
        </div>
      </Section>
    </div>
  );
}
