import {
  CollapsedRule,
  collapseRule,
  Condition,
  isConditionEmpty,
  Rule,
} from "@asmbl/shared/eligibility";
import { mapMaybe } from "@asmbl/shared/utils";
import {
  Button,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { useMemo, useRef, useState } from "react";
import { DeleteIcon } from "src/components/AssembleIcons/Brand/DeleteIcon";
import { CalendarIcon } from "src/components/AssembleIcons/Small/CalendarIcon";
import { CashIcon } from "src/components/AssembleIcons/Small/CashIcon";
import { DepartmentIcon } from "src/components/AssembleIcons/Small/DepartmentIcon";
import { LadderIcon } from "src/components/AssembleIcons/Small/LadderIcon";
import { LevelIcon } from "src/components/AssembleIcons/Small/LevelIcon";
import { PerformanceRatingIcon } from "src/components/AssembleIcons/Small/PerformanceRating";
import { AssembleTypography } from "src/components/AssembleTypography";
import { DatePickerButton } from "src/components/DatePickerButton";
import { SearchableFilter } from "src/components/Filter/SearchableFilter";
import { GRAY_4, GRAY_6, PURPLE_1, PURPLE_2, WHITE } from "src/theme";
import { KeysOfUnion } from "src/utils";

const useStyles = makeStyles(() => ({
  box: {
    width: "100%",
    background: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "8px",
    height: "88px",
  },
  title: {
    margin: "8px 12px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  deleteButton: {
    width: 32,
    height: 32,
    padding: 8,
    color: GRAY_4,
  },
  addButton: {
    whiteSpace: "nowrap",
    border: `1px solid ${WHITE}`,
    "&:hover": {
      border: `1px solid ${PURPLE_1}`,
    },
    "&:focus, &:active": {
      boxShadow:
        "0px 1px 3px rgba(10, 36, 64, 0.1), 0px 0px 0px 3px rgba(10, 0, 174, 0.25)",
    },
  },
  container: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    alignItems: "center",
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
  },
  rulesContainer: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
  },
  rule: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
  },
  menuItem: {
    display: "flex",
    gap: "0.5rem",
  },
}));

type Props = {
  title?: string;
  rule: Rule;
  onChange: (rule: Rule) => void;
  onDelete?: () => void;
  departmentOptions?: { label: string; value: number }[];
  ladderOptions?: { label: string; value: number }[];
  levelOptions?: { label: string; value: number }[];
  perfRatingOptions?: { label: string; value: string }[];
};

type Keys = Exclude<
  KeysOfUnion<Condition>,
  "alwaysIncludeIds" | "alwaysExcludeIds"
>;
type Values = CollapsedRule[Keys];

type Name =
  | "Department"
  | "Ladder"
  | "Level"
  | "Start Date"
  | "Performance Rating"
  | "Last Comp Change";

const nameToKey: Record<Name, Keys> = {
  "Start Date": "startDateCutOff",
  Department: "departmentIds",
  Ladder: "ladderIds",
  Level: "levels",
  "Performance Rating": "perfRatings",
  "Last Comp Change": "lastCompChange",
};

export function RuleEditor({
  title = "",
  rule,
  onChange,
  onDelete,
  departmentOptions = [],
  ladderOptions = [],
  levelOptions = [],
  perfRatingOptions = [],
}: Props): JSX.Element {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const toggleOpen = () => setOpen((prev) => !prev);

  const combined = useMemo(() => collapseRule(rule), [rule]);

  const handleChange = (index: number, key: Keys) => (values: Values) => {
    const newRule = { [key]: values } as Condition;
    const newArr = rule.slice();
    newArr[index] = newRule;
    onChange(newArr);
  };

  const handleDelete = (index: number) =>
    onChange(rule.filter((_, i) => i !== index));

  const available: (Name | undefined)[] = [
    // Only show a filter if there are 2 or more options
    combined.departmentIds || departmentOptions.length <= 1
      ? undefined
      : "Department",
    combined.ladderIds || ladderOptions.length <= 1 ? undefined : "Ladder",
    combined.levels || levelOptions.length <= 1 ? undefined : "Level",
    // We show perf-ratings if you have at least one option. Some companies may
    // only give ratings to some of their employees and leave the rest blank. In
    // those cases, we want to be able to filter for the employees that have
    // that a rating.
    combined.perfRatings || perfRatingOptions.length <= 0
      ? undefined
      : "Performance Rating",
    combined.startDateCutOff ? undefined : "Start Date",
    combined.lastCompChange ? undefined : "Last Comp Change",
  ];
  const options = mapMaybe(available, (x) => x);

  return (
    <div className={classes.box}>
      <div className={classes.title}>
        <AssembleTypography variant="productEyebrowSmall">
          {title}
        </AssembleTypography>
        <Tooltip title="Delete">
          <IconButton
            className={classes.deleteButton}
            onClick={() => onDelete && onDelete()}
          >
            <DeleteIcon color={GRAY_4} hoverColor={PURPLE_2} />
          </IconButton>
        </Tooltip>
      </div>

      <div className={classes.container}>
        {rule.length > 0 && (
          <div className={classes.rulesContainer}>
            {rule.map((r, i) => {
              const component =
                "departmentIds" in r ? (
                  <SearchableFilter
                    name="Department"
                    options={departmentOptions}
                    selected={combined.departmentIds ?? []}
                    onChange={handleChange(i, "departmentIds")}
                    onDelete={() => handleDelete(i)}
                    icon={<DepartmentIcon width="16px" height="16px" inherit />}
                  />
                ) : "ladderIds" in r ? (
                  <SearchableFilter
                    name="Ladder"
                    options={ladderOptions}
                    selected={combined.ladderIds ?? []}
                    onChange={handleChange(i, "ladderIds")}
                    onDelete={() => handleDelete(i)}
                    icon={<LadderIcon width="16px" height="16px" inherit />}
                  />
                ) : "levels" in r ? (
                  <SearchableFilter
                    name="Level"
                    options={levelOptions}
                    selected={combined.levels ?? []}
                    onChange={handleChange(i, "levels")}
                    onDelete={() => handleDelete(i)}
                    icon={<LevelIcon width="16px" height="16px" inherit />}
                  />
                ) : "perfRatings" in r ? (
                  <SearchableFilter
                    name="Performance Rating"
                    options={perfRatingOptions}
                    selected={combined.perfRatings ?? []}
                    onChange={handleChange(i, "perfRatings")}
                    onDelete={() => handleDelete(i)}
                    icon={
                      <PerformanceRatingIcon
                        width="16px"
                        height="16px"
                        inherit
                      />
                    }
                  />
                ) : "startDateCutOff" in r ? (
                  <DatePickerButton
                    title="Start Date"
                    selectedText="Before"
                    value={r.startDateCutOff ?? undefined}
                    onChange={handleChange(i, "startDateCutOff")}
                    onDelete={() => handleDelete(i)}
                  />
                ) : "lastCompChange" in r ? (
                  <DatePickerButton
                    title="Last Comp Change"
                    selectedText="Before"
                    value={r.lastCompChange ?? undefined}
                    onChange={handleChange(i, "lastCompChange")}
                    onDelete={() => handleDelete(i)}
                  />
                ) : undefined;

              return (
                <div
                  // We need a unique key, and index alone isn't enough.
                  key={`${i}-${Object.keys(r).join("-")}`}
                  className={classes.rule}
                >
                  {component}
                  {i !== rule.length - 1 && <And />}
                </div>
              );
            })}
          </div>
        )}
        {options.length > 0 && (
          <div>
            <Button
              ref={ref}
              className={classes.addButton}
              color="primary"
              variant="text"
              onClick={toggleOpen}
              disabled={rule.some(isConditionEmpty)}
            >
              Add Criteria
            </Button>
            <Menu
              open={open}
              anchorEl={ref.current}
              onClose={() => setOpen(false)}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
              {options.map((name) => (
                <MenuItem
                  key={name}
                  className={classes.menuItem}
                  onClick={() => {
                    onChange([...rule, createNewCondition(name)]);
                    setOpen(false);
                  }}
                >
                  {name === "Department" && <DepartmentIcon inherit />}
                  {name === "Ladder" && <LadderIcon inherit />}
                  {name === "Level" && <LevelIcon inherit />}
                  {name === "Last Comp Change" && <CashIcon inherit />}
                  {name === "Start Date" && <CalendarIcon inherit />}
                  {name === "Performance Rating" && (
                    <PerformanceRatingIcon inherit />
                  )}

                  {name}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
      </div>
    </div>
  );
}

function And(): JSX.Element {
  return (
    <AssembleTypography variant="productEyebrowSmall" textColor={GRAY_4}>
      AND
    </AssembleTypography>
  );
}

function createNewCondition(name: Name): Condition {
  if (name === "Last Comp Change") {
    return { lastCompChange: null };
  }
  if (name === "Start Date") {
    return { startDateCutOff: null };
  }

  const field = nameToKey[name];
  return { [field]: [] } as unknown as Condition;
}
