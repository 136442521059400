import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  UserAccessControlLoadingBoundaryQuery,
  UserAccessControlLoadingBoundaryQueryVariables,
} from "../../../__generated__/graphql";
import Loading from "../Loading";
import { UserAccessControl } from "./UserAccessControl";

export function UserAccessControlLoadingBoundary(): JSX.Element | null {
  const { id } = useParams<{ id: string }>();
  const targetId = id != null ? parseInt(id, 10) : NaN;

  const { data, loading, refetch } = useQuery<
    UserAccessControlLoadingBoundaryQuery,
    UserAccessControlLoadingBoundaryQueryVariables
  >(UserAccessControlLoadingBoundary.query, {
    variables: { userId: targetId },
    skip: isNaN(targetId),
    errorPolicy: "all",
  });

  if (!data || data.userRecord === null) {
    return loading ? <Loading /> : null;
  }

  return (
    <UserAccessControl
      type="user"
      targetId={targetId}
      organization={data.organization}
      user={data.userRecord}
      refreshUser={refetch}
    />
  );
}

UserAccessControlLoadingBoundary.query = gql`
  ${UserAccessControl.fragments.user}
  ${UserAccessControl.fragments.organization}
  query UserAccessControlLoadingBoundaryQuery($userId: Int!) {
    userRecord(id: $userId) {
      id
      ...UserAccessControl_user
    }
    organization {
      id
      ...UserAccessControl_organization
    }
  }
`;
