import { gql } from "@apollo/client";
import { Money } from "@asmbl/shared/money";
import { EquityGrantChartTooltip_equityGrant } from "../../../../__generated__/graphql";
import { EquityTooltip } from "../EquityTooltip";

type Props = {
  equityGrant: EquityGrantChartTooltip_equityGrant;
  sharePrice: Money | undefined;
} & Partial<{
  // These properties are injected by the chart component:
  label: number; // The millisecond timestamp of the date
  payload: {
    payload: {
      currentUnitCount: number;
      grantName: string;
      unitCount: number;
      vestingDate: string;
    };
  }[];
}>;

export function EquityGrantChartTooltip({
  label,
  payload,
  equityGrant,
  sharePrice,
}: Props): JSX.Element | null {
  return (
    <EquityTooltip
      sharePrice={sharePrice ?? null}
      totalUnitCount={equityGrant.unitCount}
      label={label}
      payload={payload}
    />
  );
}

EquityGrantChartTooltip.fragments = {
  equityGrant: gql`
    fragment EquityGrantChartTooltip_equityGrant on EquityGrant {
      id
      issueDate
      vestingStartDate
      unitCount
      sharePrice
      vestingInformation {
        vestedUnits
        unvestedUnits
        vestEvents {
          id
          grantName
          unitCount
          vestingDate
        }
      }
    }
  `,
};
