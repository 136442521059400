import { gql } from "@apollo/client";
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import { useMemo } from "react";
import { groupLocations } from "../../../models/Location";
import { GRAY_4 } from "../../../theme";
import { toggleSet } from "../../../utils";
import { LocationsFormList_locations as Location } from "../../../__generated__/graphql";
import { AssembleTypography } from "../../AssembleTypography";

type Props = {
  locations: Location[];
  selectedLocationIds: Set<number>;
  setSelectedLocationIds(ids: Set<number>): unknown;
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    padding: 0,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  listSubheaderContainer: {
    backgroundColor: "inherit",
    padding: 0,
  },
  listSubheader: {
    padding: 0,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemIcon: {
    minWidth: 0,
    marginRight: theme.spacing(2),
  },
}));

export function LocationsFormList({
  locations,
  selectedLocationIds,
  setSelectedLocationIds,
}: Props): JSX.Element {
  const classes = useStyles();

  const groupedLocations = useMemo(
    () => groupLocations(locations),
    [locations]
  );

  const toggleLocationSelect = (id: number) => {
    setSelectedLocationIds(toggleSet(selectedLocationIds, id));
  };

  const groupedLocationKeys = Array.from(groupedLocations.keys());

  return (
    <>
      <AssembleTypography>
        <b>HRIS Locations</b>
      </AssembleTypography>
      {groupedLocationKeys.length === 0 && (
        <AssembleTypography variant="productSmallSemiBold" textColor={GRAY_4}>
          There are no HRIS locations attributed to this Location Group. To add
          a HRIS Location to this group, you need to first remove it from the
          Location Group it's assigned to.
        </AssembleTypography>
      )}
      <List className={classes.root} subheader={<li />}>
        {groupedLocationKeys.map((group) => (
          <li key={group} className={classes.listSection}>
            <ul className={classes.listSubheaderContainer}>
              <ListSubheader className={classes.listSubheader}>
                {group}
              </ListSubheader>
              {groupedLocations.get(group)?.map((location: Location) => (
                <ListItem
                  key={location.id}
                  className={classes.listItem}
                  button
                  onClick={() => toggleLocationSelect(location.id)}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <Checkbox
                      edge="start"
                      checked={selectedLocationIds.has(location.id)}
                      tabIndex={-1}
                    />
                  </ListItemIcon>
                  <ListItemText primary={location.city} />
                </ListItem>
              ))}
            </ul>
          </li>
        ))}
      </List>
    </>
  );
}

LocationsFormList.fragments = {
  locations: gql`
    fragment LocationsFormList_locations on Location {
      id
      city
      state
      country
    }
  `,
};
