import { formatCurrency, money } from "@asmbl/shared/money";
import { makeStyles, Paper } from "@material-ui/core";
import { TooltipProps } from "recharts/types/component/Tooltip";
import { GRAY_2, WHITE } from "../../theme";
import { AssembleTypography } from "../AssembleTypography";
import { useCurrencies } from "../CurrenciesContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "8px",
    boxShadow: "0px 15px 25px rgba(10, 36, 64, 0.08)",
    background: GRAY_2,
  },
}));

export function AnonymizedCompensationTooltip({
  active,
  payload,
  point,
}: TooltipProps<number, string> & {
  point: string | null;
}): JSX.Element | null {
  const classes = useStyles();
  const { selectedCurrency } = useCurrencies();

  const data = payload?.find((p) => p.name === point);

  return active === true && data?.value !== undefined ? (
    <Paper className={classes.paper} elevation={0}>
      <AssembleTypography variant="productTooltip" textColor={WHITE}>
        {formatCurrency(money(data.value, selectedCurrency.code), {
          maximumFractionDigits: 0,
        })}
      </AssembleTypography>
    </Paper>
  ) : null;
}
