import { gql, useQuery } from "@apollo/client";
import { CompCycleDisplay } from "../../../../components/CompCycle/types";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import {
  GetLazyReportingTargetTierData,
  TargetRow_compCycleData as CompCycleData,
} from "../../../../__generated__/graphql";
import { IndentationCells } from "../../Budgets/Table/Connectors";
import { SkeletonRow } from "../../Budgets/Table/SkeletonRow";
import { ReportingTargetTier } from "./ReportingTargetTier";

type Props = {
  employeeId: number;
  organizationName: string;
  varianceDisplay: CompCycleDisplay;
  compCycleData: CompCycleData;
  indentation: IndentationCells;
};
export function LazyReportingTargetTier({
  employeeId,
  organizationName,
  varianceDisplay,
  compCycleData,
  indentation,
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();
  const { loading, data } = useQuery<GetLazyReportingTargetTierData>(
    LazyReportingTargetTier.query(),
    {
      variables: {
        compCycleId: compCycleData.id,
        employeeId,
        currencyCode: defaultCurrencyCode,
      },
      pollInterval: 10_000,
    }
  );

  if (loading) {
    return <SkeletonRow subComponents={3} indentation={indentation} />;
  }

  if (!data || !data.compCycle) {
    return (
      <tr>
        <td>Error</td>
      </tr>
    );
  }

  return (
    <ReportingTargetTier
      rootBudget={data.compCycleBudget}
      manager={data.employee}
      reports={data.employees}
      organizationName={organizationName}
      compCycleData={compCycleData}
      varianceDisplay={varianceDisplay}
      indentation={indentation}
    />
  );
}

LazyReportingTargetTier.query = () => gql`
  ${ReportingTargetTier.fragments.reports}
  ${ReportingTargetTier.fragments.compCycleBudget}
  ${ReportingTargetTier.fragments.compCycleData}
  ${ReportingTargetTier.fragments.manager}
  query GetLazyReportingTargetTierData(
    $compCycleId: Int!
    $employeeId: Int!
    $currencyCode: CurrencyCode!
  ) {
    compCycleBudget(compCycleId: $compCycleId, employeeId: $employeeId) {
      compCycleId
      employeeId
      ...ReportingTargetTier_compCycleBudget
    }
    employee(id: $employeeId) {
      id
      ...ReportingTargetTier_manager
    }
    employees(managerId: $employeeId) {
      ...ReportingTargetTier_reports
    }
    compCycle(id: $compCycleId) {
      id
      ...ReportingTargetTier_compCycleData
    }
  }
`;
