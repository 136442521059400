import { makeStyles } from "@material-ui/core";
import { ReactNode } from "react";
import { GRAY_2 } from "../../theme";
import { AssembleTypography } from "../AssembleTypography";

const useStyles = makeStyles(() => ({
  subheader: { color: GRAY_2 },
}));

type Props = {
  header: ReactNode;
  subheader?: ReactNode;
};

export function CardHeader({ header, subheader }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <AssembleTypography variant="h6">{header}</AssembleTypography>
      {subheader != null && (
        <AssembleTypography
          className={classes.subheader}
          variant="productParagraphLarge"
        >
          {subheader}
        </AssembleTypography>
      )}
    </>
  );
}
