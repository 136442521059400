import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTrack } from "src/analytics";
import {
  FilterParam,
  getNumericParams,
  NumericParam,
} from "src/models/FilterParams";
import {
  ImmutableURLSearchParams,
  useURLSearchParams,
} from "src/models/URLSearchParams";
import {
  CostToMovePage_compStructure as CompStructure,
  CostToMovePage_department as Department,
  CostToMovePage_employee as Employee,
  CostToMovePage_market as Market,
} from "src/__generated__/graphql";
import { CostToMoveLoadingBoundary } from "./CostToMoveLoadingBoundary";
import { CostToMoveTopBar } from "./CostToMoveTopBar";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    padding: theme.spacing(2),
    height: "calc(100vh - 80px)",
  },
}));

type Props = {
  compStructure: CompStructure;
  managers: Employee[];
  departments: Department[];
  markets: Market[];
};

export function CostToMovePage({
  compStructure,
  managers,
  departments,
  markets,
}: Props): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();
  const urlSearchParams = useURLSearchParams();

  const targetBandPoint =
    parseTargetBandPointFromUrl(urlSearchParams) ??
    compStructure.bandPointTypes[0];

  const handleTargetBandPointChange = (bandPoint: string) => {
    navigate(
      {
        search: urlSearchParams.set("target-band-point", bandPoint).toString(),
      },
      { replace: true }
    );
  };

  const filter = parseFilterFromUrl(urlSearchParams);

  const filterCount = Object.values(filter).filter(
    (value) => value != null
  ).length;

  const { Track, trackEvent } = useTrack({
    subArea: "CostToMove",
    filterCount,
    bandPointName: targetBandPoint,
  });

  const handleFilterChange = (field: string, values: string[] | number[]) => {
    const filterParam = fieldToFilterParam(field);
    if (filterParam === null) return;

    if (values.length === 0) {
      navigate(
        {
          search: urlSearchParams.delete(filterParam).toString(),
        },
        { replace: true }
      );
    } else {
      navigate(
        {
          search: urlSearchParams
            .set(filterParam, values.toString())
            .toString(),
        },
        { replace: true }
      );
    }
  };

  const handleFilterReset = () => {
    // Only delete filter-specific params to keep unrelated URL params intact
    navigate(
      {
        search: urlSearchParams
          .deleteMany(Object.values(FilterParam))
          .toString(),
      },
      { replace: true }
    );
  };

  useEffect(() => {
    trackEvent({
      object: "Band Placement Report",
      action: "Viewed",
      filterCount,
      bandPointName: targetBandPoint,
    });
  }, [filterCount, targetBandPoint, trackEvent]);

  return (
    <Track>
      <div className={classes.pageContainer}>
        <CostToMoveTopBar
          compStructure={compStructure}
          managers={managers}
          departments={departments}
          markets={markets}
          filter={filter}
          setFilter={handleFilterChange}
          resetFilters={handleFilterReset}
          targetBandPoint={targetBandPoint}
          setTargetBandPoint={handleTargetBandPointChange}
        />
        <CostToMoveLoadingBoundary
          filter={filter}
          targetBandPoint={targetBandPoint}
        />
      </div>
    </Track>
  );
}

function parseTargetBandPointFromUrl(
  urlSearchParams: ImmutableURLSearchParams
): string | null | undefined {
  return urlSearchParams.get("target-band-point");
}

function parseFilterFromUrl(
  urlSearchParams: ImmutableURLSearchParams
): Record<string, number[] | null | undefined> {
  const managerIds = parseNumericParams(
    getNumericParams(urlSearchParams, FilterParam.MANAGER)
  );
  const locationGroupIds = parseNumericParams(
    getNumericParams(urlSearchParams, FilterParam.LOCATION)
  );
  const departmentIds = parseNumericParams(
    getNumericParams(urlSearchParams, FilterParam.DEPARTMENT)
  );
  const ladderIds = parseNumericParams(
    getNumericParams(urlSearchParams, FilterParam.LADDER)
  );
  const levels = parseNumericParams(
    getNumericParams(urlSearchParams, FilterParam.LEVEL)
  );

  return {
    managerIds,
    locationGroupIds,
    departmentIds,
    ladderIds,
    levels,
  };
}

function parseNumericParams(params: Set<NumericParam>): number[] | undefined {
  return params.has("all")
    ? undefined
    : Array.from(params.values()).filter(
        // We can allow null once we support null as a valid filter option
        (p): p is number => p !== "all" && p !== "null"
      );
}

function fieldToFilterParam(field: string): FilterParam | null {
  const mapping: Record<string, FilterParam> = {
    managerIds: FilterParam.MANAGER,
    departmentIds: FilterParam.DEPARTMENT,
    ladderIds: FilterParam.LADDER,
    levels: FilterParam.LEVEL,
    locationGroupIds: FilterParam.LOCATION,
  };

  return mapping[field];
}

CostToMovePage.fragments = {
  compStructure: gql`
    ${CostToMoveTopBar.fragments.compStructure}
    fragment CostToMovePage_compStructure on CompStructure {
      ...CostToMoveTopBar_compStructure
    }
  `,
  employee: gql`
    ${CostToMoveTopBar.fragments.employee}
    fragment CostToMovePage_employee on Employee {
      ...CostToMoveTopBar_employee
    }
  `,
  department: gql`
    ${CostToMoveTopBar.fragments.department}
    fragment CostToMovePage_department on Department {
      ...CostToMoveTopBar_department
    }
  `,
  market: gql`
    ${CostToMoveTopBar.fragments.market}
    fragment CostToMovePage_market on Market {
      ...CostToMoveTopBar_market
    }
  `,
};
