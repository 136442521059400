export type KeysOfUnion<T> = T extends T ? keyof T : never;

/**
 * Marks K fields of T as required.
 *
 * @example type User = { name: string; age?: number; };
 *         type UserWithRequiredAge = WithRequired<User, "age">;
 *        // UserWithRequiredAge is { name: string; age: number; }
 */
export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

type RequiredNotNull<T> = { [P in keyof T]: NonNullable<T[P]> };

/**
 * Marks K fields of T as Not Null.
 *
 * @example type User = { name: string; age: number | null; };
 *         type UserWithNonNullAge = WithRequired<User, "age">;
 *        // UserWithNonNullAge is { name: string; age: number; }
 */
export type Ensure<T, K extends keyof T> = T & RequiredNotNull<Pick<T, K>>;

export type ArrayValue<T> = T extends Array<infer U> ? U : never;

export enum CompUnit {
  PERCENT_OF_SALARY = "PERCENT_OF_SALARY",
  CASH = "CASH",
  HOURLY_CASH = "HOURLY_CASH",
  UNITS = "UNITS",
}

export type CompUnitType = (typeof CompUnit)[keyof typeof CompUnit];

export const CashCompComponent = {
  SALARY: "SALARY",
  COMMISSION: "COMMISSION",
  RECURRING_BONUS: "RECURRING_BONUS",
  SPOT_BONUS: "SPOT_BONUS",
  SIGNING_BONUS: "SIGNING_BONUS",
  RELOCATION_BONUS_OFFICE: "RELOCATION_BONUS_OFFICE",
  RELOCATION_BONUS_REMOTE: "RELOCATION_BONUS_REMOTE",
};

export type CashCompComponent =
  (typeof CashCompComponent)[keyof typeof CashCompComponent];

export const RecItemType = {
  MARKET: "MARKET",
  PROMOTION: "PROMOTION",
  MERIT_INCREASE: "MERIT_INCREASE",
  MERIT_BONUS: "MERIT_BONUS",
  EQUITY_GRANT: "EQUITY_GRANT",
  TARGET_COMMISSION: "TARGET_COMMISSION",
  TARGET_RECURRING_BONUS: "TARGET_RECURRING_BONUS",
  ACTUAL_RECURRING_BONUS: "ACTUAL_RECURRING_BONUS",
};

export type RecItemType = (typeof RecItemType)[keyof typeof RecItemType];
