import { gql } from "@apollo/client";
import { parseLevelingCodes } from "@asmbl/shared/leveling-codes";
import { mapMaybe } from "@asmbl/shared/utils";
import {
  ScalarDictionaryWithCustom as FlatFileRecord,
  IDataHookResponse,
} from "@flatfile/react";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { AssembleFlatfileButton } from "src/components/AssembleFlatfileButton";
import {
  ImportLevelingCodesInput,
  LevelingCodeMapping,
  LevelingCodesImportButton_position as Position,
} from "../../../__generated__/graphql";
import { matchPosition } from "../../../models/csv/PositionCSV";
import { isFieldPresent } from "../../../models/csv/common";
import { useImportLevelingCodeUpload } from "../../../mutations/Position";

type Props = {
  positions: Position[];
  mappings: LevelingCodeMapping[];
  onCancel?: () => unknown;
  onUpload?: () => unknown;
};

type TypedRow = {
  positionId: number | null;
  levelingCodes: string[];
};

export function LevelingCodesImportButton({
  positions,
  mappings,
  onCancel,
  onUpload,
}: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const validateRecord = recordValidator(positions);

  const upload = useImportLevelingCodeUpload();

  return (
    <AssembleFlatfileButton
      settings={{
        title: "Add your Leveling Code Data",
        type: "leveling_codes",
        fields: [
          {
            label: "Leveling Codes",
            key: "levelingCodes",
          },
          {
            label: "Position ID",
            key: "positionId",
            validators: [
              {
                validate: "regex_matches",
                regex: "[1-9][0-9]*",
                error: "Must be a number or leave empty to unmap the value",
              },
            ],
          },
          {
            label: "Department",
            key: "departmentName",
            type: "string",
          },
          {
            label: "Ladder",
            key: "ladderName",
            type: "string",
          },
          {
            label: "Level",
            key: "levelNumber",
            type: "string",
          },
          {
            label: "Position",
            key: "positionName",
            type: "string",
          },
        ],
      }}
      onRecordInit={validateRecord}
      onRecordChange={validateRecord}
      onData={async (results) => {
        await upload(parseData(results.validData, positions, mappings));
        if (onUpload !== undefined) {
          onUpload();
          enqueueSnackbar("Give us a moment to process your mappings.", {
            variant: "success",
            persist: true,
            preventDuplicate: true,
          });
        }
      }}
      onCancel={onCancel}
      render={(_, launch) => (
        <Button onClick={launch} variant="contained" color="primary">
          Upload Leveling Codes
        </Button>
      )}
    />
  );
}

function recordValidator(positions: Position[]) {
  return function validateRecord(record: FlatFileRecord): IDataHookResponse {
    const [, positionMatchingInfo] = matchPosition(record, positions);

    return {
      ...positionMatchingInfo,
    };
  };
}

function parseData(
  validData: unknown[],
  positions: Position[],
  remoteMappings: LevelingCodeMapping[]
): ImportLevelingCodesInput[] {
  const currentMappings = new Map<string, number | null>();
  const rows = validData.map((d) => convertUntypedRow(d as FlatFileRecord));

  for (const row of rows) {
    if (row.positionId !== null) {
      const position = positions.find((p) => p.id === row.positionId);

      if (position == null) {
        throw new Error("Position Not Found");
      }
    }

    for (const code of row.levelingCodes) {
      if (currentMappings.has(code)) {
        throw Error(`"${code}" is duplicated in this upload`);
      }
      currentMappings.set(code, row.positionId);
    }
  }

  // validate that the merge of remote + current mappings have no overlap

  const serverLevelingCodes: Map<number, string[]> = new Map();
  const clientLevelingCodes: Map<number, string[]> = new Map();

  for (const mapping of remoteMappings) {
    serverLevelingCodes.set(mapping.positionId, [
      ...(serverLevelingCodes.get(mapping.positionId) ?? []),
      mapping.levelingCode,
    ]);
  }

  for (const row of rows) {
    if (row.positionId !== null) {
      clientLevelingCodes.set(row.positionId, [
        ...(clientLevelingCodes.get(row.positionId) ?? []),
        ...row.levelingCodes,
      ]);
    }
  }
  // Remove positions that are being mutated
  for (const posId of clientLevelingCodes.keys()) {
    serverLevelingCodes.delete(posId);
  }
  // Gather all server codes
  const allLevelingCodesOnTheServer = new Set(
    [...serverLevelingCodes.values()].flat()
  );

  // Get all new leveling codes
  const allLevelingCodesOnTheClient = [...clientLevelingCodes.values()].flat();
  // If any new code from flatfile exists on the server, there's an issue
  const conflictingCodes = mapMaybe(allLevelingCodesOnTheClient, (code) => {
    if (allLevelingCodesOnTheServer.has(code)) {
      return code;
    }
  });

  if (conflictingCodes.length > 0) {
    throw Error(
      `The following codes are mapped to another position in your organization: ${conflictingCodes.join(
        ","
      )}`
    );
  }

  return rows.map((r) => ({
    positionId: r.positionId as number, // validated above
    levelingCodes: r.levelingCodes,
  }));
}

function convertUntypedRow(row: FlatFileRecord): TypedRow {
  const object = {
    positionId: isFieldPresent(row.positionId)
      ? parseInt(row.positionId)
      : null,
    levelingCodes: parseLevelingCodes(
      (row.levelingCodes as string | undefined | null) ?? ""
    ),
  };

  return object;
}

LevelingCodesImportButton.fragments = {
  position: gql`
    fragment LevelingCodesImportButton_position on Position {
      id
      name
      level
      ladder {
        id
        name
        department {
          id
          name
        }
      }
    }
  `,
  levelingCodeMapping: gql`
    fragment LevelingCodesImportButton_levelingCodeMapping on LevelingCodeMapping {
      positionId
      levelingCode
    }
  `,
};
