import { makeStyles, TableCell, TableRow } from "@material-ui/core";
import { GRAY_6 } from "../../../../theme";

export const useStyles = makeStyles(() => ({
  lastRow: {
    borderTop: "none",
    "& .MuiTableCell-body": {
      borderBottom: `2px solid ${GRAY_6}`,
    },
    padding: 0,
  },
}));

/**
 * This is merely to have a thick dividing line between people-rows. Previously
 * we had conditional logic across various components to determine whether this
 * line was drawn or not.
 */
export function LastRow(): JSX.Element {
  const classes = useStyles();
  return (
    <TableRow aria-level={2} className={classes.lastRow}>
      <TableCell role="gridcell" colSpan={14} padding="none" />
    </TableRow>
  );
}
