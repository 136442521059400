import { gql, useQuery } from "@apollo/client";
import { CashBandName, EquityBandName } from "@asmbl/shared/constants";
import { contramap } from "@asmbl/shared/sort";
import { Box, makeStyles } from "@material-ui/core";
import { memo, useEffect, useMemo, useState } from "react";
import { useExcludedBandNames } from "src/pages/LadderDetail/ExcludedBandNameContext";
import {
  GetLadders,
  GetLaddersAndPositions,
  GetLaddersVariables,
} from "../../../__generated__/graphql";
import { useCurrencies } from "../../../components/CurrenciesContext";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useLocations } from "../../../components/LocationsContext";
import { DV_BAND_COLORS } from "../../../constants";
import {
  ArrayValue,
  NonNull,
  bandNameComparator,
  toggleSet,
} from "../../../utils";
import { ColoredLadder } from "./Compare";
import { CompareLegend } from "./CompareLegend";
import { CompareTable } from "./CompareTable";
import { CompareToolbar } from "./CompareToolbar";

type GetLadders_ladders = ArrayValue<GetLadders["ladders"]>;
type CompStructure = NonNull<GetLaddersAndPositions["compStructure"]>;
export type GetLadders_marketDataSet = ArrayValue<GetLadders["marketDataSets"]>;
export type GetLadders_marketDataSets_marketDataSamples = ArrayValue<
  GetLadders_marketDataSet["marketDataSamples"]
>;
export type ColoredFullLadder = { ladder: GetLadders_ladders; color: string };
export type GetLadders_position = ArrayValue<GetLadders_ladders["positions"]>;

interface Props {
  selectedLadders: ColoredLadder[];
  compStructure: CompStructure;
}

const useStyles = makeStyles(() => ({
  compareTableContainer: {
    height: `calc(100vh - 220px)`,
    maxHeight: `calc(100vh - 220px)`,
  },
}));

export enum SELECTED_COMP_TYPE_LABELS {
  SALARY = "Salary",
  TOTAL_CASH = "Total Cash",
  TOTAL_GRANT_VALUE = "Total Grant Value",
  ANNUAL_GRANT_VALUE = "Annual Grant Value",
}

export const SELECTED_COMP_TYPES = [
  SELECTED_COMP_TYPE_LABELS.SALARY,
  SELECTED_COMP_TYPE_LABELS.TOTAL_CASH,
  SELECTED_COMP_TYPE_LABELS.TOTAL_GRANT_VALUE,
  SELECTED_COMP_TYPE_LABELS.ANNUAL_GRANT_VALUE,
] as const;

export type SelectedCompType = (typeof SELECTED_COMP_TYPES)[number];

function SelectedLaddersLoaderImpl({
  selectedLadders,
  compStructure,
}: Props): JSX.Element {
  const classes = useStyles();

  const { selectedCurrency } = useCurrencies();
  const { markets, selectedLocation } = useLocations();
  const [selectedMarket, selectedLocationGroup] = selectedLocation ?? [];

  const { data, error } = useQuery<GetLadders, GetLaddersVariables>(
    SelectedLaddersLoaderImpl.query,
    {
      variables: {
        ladderIds: selectedLadders.map((l) => l.ladder.id),
        currencyCode: selectedCurrency.code,
        marketId: selectedMarket?.id ?? markets[0].id,
        locationGroupId: selectedLocationGroup?.id,
      },
    }
  );

  const [excludedLevels, setExcludedLevels] = useState<number[]>([]);
  const [excludedPositionIds, setExcludedPositionIds] = useState(
    new Set<number>()
  );
  const [fullLadders, setFullLadders] = useState<GetLadders_ladders[]>();

  const [marketDataSets, setMarketDataSets] = useState<
    GetLadders_marketDataSet[]
  >([]);

  const [includedMarketDataSets, setIncludedMarketDataSets] = useState<
    number[]
  >([]);

  const [selectedCompType, setSelectedCompType] = useState<SelectedCompType>(
    SELECTED_COMP_TYPE_LABELS.SALARY
  );

  const availableBandNames = compStructure.allBandTypes
    .slice()
    .sort(bandNameComparator);

  const availableCashBandNames = availableBandNames.filter((band) =>
    (Object.values(CashBandName) as string[]).includes(band)
  );

  const availableEquityBandNames = availableBandNames.filter((band) =>
    (Object.values(EquityBandName) as string[]).includes(band)
  );

  const { excludedBandNames, setExcludedBandNames } = useExcludedBandNames();

  const coloredFullLadders = useMemo(
    () =>
      fullLadders?.map((ladder) => ({
        ladder,
        color:
          selectedLadders.find((l) => l.ladder.id === ladder.id)?.color ??
          DV_BAND_COLORS[0],
      })),
    [fullLadders, selectedLadders]
  );

  // Reset filters when the search is cleared out
  useEffect(() => {
    if (selectedLadders.length === 0) {
      setExcludedPositionIds(new Set());
      setExcludedLevels([]);
    }
  }, [selectedLadders]);

  // Serve stale ladders while fetching new selections
  useEffect(() => {
    if (data !== undefined) {
      // sort the ladders that were fetched to match the order of ladders
      // rendered in the autocomplete component to ensure the colors match
      const ladders = data.ladders
        .slice()
        .sort(
          contramap((target) =>
            selectedLadders.findIndex((l) => l.ladder.name === target.name)
          )
        );

      setFullLadders(ladders);
    }
  }, [data, selectedLadders]);

  useEffect(() => {
    if (data !== undefined) {
      setMarketDataSets(data.marketDataSets);
    }
  }, [data]);

  if (fullLadders === undefined) {
    return <LoadingSpinner />;
  }
  if (error) return <p>{JSON.stringify(error)}</p>;

  const handleLevelsExclusion = (excludedLevels: number[]) => {
    setExcludedLevels(excludedLevels);
  };

  const handleChangeCompType = (compType: SelectedCompType) => {
    setSelectedCompType(compType);
    switch (compType) {
      case "Salary":
        setExcludedBandNames(
          toggleSet(new Set(availableBandNames), CashBandName.SALARY)
        );
        break;
      case "Total Cash":
        setExcludedBandNames(new Set(availableEquityBandNames));
        break;
      case "Total Grant Value":
      case "Annual Grant Value":
        setExcludedBandNames(new Set(availableCashBandNames));
        break;
      default:
        break;
    }
  };

  const selectedMarketId = selectedLocation ? selectedLocation[0].id : null;

  return (
    <>
      <CompareToolbar
        ladders={coloredFullLadders ?? []}
        excludedLevels={excludedLevels}
        handleLevelsExclusion={handleLevelsExclusion}
      />
      <Box
        className={classes.compareTableContainer}
        display="flex"
        flexDirection="row"
      >
        <CompareTable
          positions={fullLadders
            .flatMap((ladder) => ladder.positions)
            .filter(
              (position) =>
                !excludedPositionIds.has(position.id) &&
                !excludedLevels.includes(position.level)
            )}
          selectedLadders={coloredFullLadders ?? []}
          selectedCurrency={selectedCurrency}
          excludedBandNames={excludedBandNames}
          marketDataSets={marketDataSets.filter(
            (m) =>
              includedMarketDataSets.includes(m.id) &&
              selectedMarketId === m.market?.id
          )}
          hasMarketData={marketDataSets.length > 0}
          selectedCompType={selectedCompType}
        />
        {coloredFullLadders && coloredFullLadders.length > 0 && (
          <CompareLegend
            availableBandNames={availableBandNames}
            selectedCompType={selectedCompType}
            handleChangeCompType={handleChangeCompType}
            marketDataSets={marketDataSets}
            includedMarketDataSets={includedMarketDataSets}
            setIncludedMarketDataSets={setIncludedMarketDataSets}
          />
        )}
      </Box>
    </>
  );
}

export const SelectedLaddersLoader = memo(SelectedLaddersLoaderImpl);

SelectedLaddersLoaderImpl.query = gql`
  ${CompareTable.fragments.position}
  query GetLadders(
    $ladderIds: [Int!]!
    $currencyCode: CurrencyCode!
    $marketId: Int!
    $locationGroupId: Int
  ) {
    ladders(ids: $ladderIds) {
      id
      name
      department {
        id
        name
      }
      positions {
        id
        ...CompareTable_position
      }
    }
    marketDataSets {
      id
      label
      provider
      createdAt
      market {
        id
      }
      marketDataSamples {
        id
        jobCode
        compType
        sampleSize
        currencyCode
        marketDataPoints {
          percentile
          value
        }
      }
    }
  }
`;
