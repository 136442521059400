import { JobStructureTreeNodeData } from "src/models/JobStructure";
import { TreeNode, TreeSelect } from "./TreeSelect";

interface JobStructureSelectProps {
  selectionTree: TreeNode<JobStructureTreeNodeData>;
  onChange(selectionTree: TreeNode<JobStructureTreeNodeData>): unknown;
}
export function JobStructureSelect({
  selectionTree,
  onChange,
}: JobStructureSelectProps): JSX.Element {
  return (
    <TreeSelect
      defaultExpand={true}
      node={selectionTree}
      indentation={[]}
      onChange={onChange}
    />
  );
}
