import { gql } from "@apollo/client";
import { formatNumeral } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import { CorrectionCostToMoveCards_costToMoveAnalysis } from "src/__generated__/graphql";
import { CompCard } from "src/components/Cards/CompCard";
import { CompCardContent } from "src/components/Cards/CompCardContent";
import { MultiCard } from "src/components/Cards/MultiCard";
import { CashBandName } from "src/constants";
const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  topLine: {
    display: "flex",
    justifyContent: "stretch",
    gap: theme.spacing(2),
    marginBlock: theme.spacing(2),
  },
  topLineCard: {
    flex: "1 0",
  },
}));

type Props = {
  data: CorrectionCostToMoveCards_costToMoveAnalysis;
};

export function CorrectionCostToMoveCards({ data }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      {data.costToMoveByComp.map((comp) => {
        const compType =
          CashBandName[comp.compType as keyof typeof CashBandName];
        return (
          <div className={classes.topLine} key={comp.compType}>
            <CompCard
              className={classes.topLineCard}
              value={comp.spend}
              description={`${compType === CashBandName.SALARY ? "Base Salary" : `Target ${compType}`}`}
            />
            <CompCard
              className={classes.topLineCard}
              value={`${
                comp.comparatio === null
                  ? "N/A"
                  : `${Math.round(comp.comparatio * 100)}%`
              }`}
            />
            <MultiCard className={classes.topLineCard}>
              <CompCardContent
                value={`${comp.affectedCount} (${formatNumeral(
                  comp.affectedCount / comp.totalCount,
                  {
                    style: "percent",
                    minimumSignificantDigits: 2,
                    maximumSignificantDigits: 3,
                  }
                )})`}
                description={`out of ${comp.totalCount} employees`}
              />
              <CompCardContent value={comp.costToMove} />
              <CompCardContent
                value={`${
                  comp.affectedComparatio === null
                    ? "N/A"
                    : `${Math.round(comp.affectedComparatio * 100)}%`
                }`}
              />
            </MultiCard>
          </div>
        );
      })}
      {/* TOTAL */}
      <div className={classes.topLine}>
        <CompCard
          className={classes.topLineCard}
          value={data.totalSpend}
          description="Total Cash Compensation"
        />
        <CompCard
          className={classes.topLineCard}
          value={`${
            data.averageTCCComparatio === null
              ? "N/A"
              : `${Math.round(data.averageTCCComparatio)}%`
          }`}
        />
        <MultiCard className={classes.topLineCard}>
          <CompCardContent
            value={`${data.totalAffectedEmployeeCount} (${formatNumeral(
              data.totalAffectedEmployeeCount / data.totalEmployeeCount,
              {
                style: "percent",
                minimumSignificantDigits: 2,
                maximumSignificantDigits: 3,
              }
            )})`}
            description={`out of ${data.totalEmployeeCount} employees`}
          />
          <CompCardContent value={data.totalCostToMove} />
          <CompCardContent
            value={`${
              data.averageAffectedTCCComparatio === null
                ? "N/A"
                : `${Math.round(data.averageAffectedTCCComparatio)}%`
            }`}
          />
        </MultiCard>
      </div>
    </>
  );
}

CorrectionCostToMoveCards.fragments = {
  costToMoveAnalysis: gql`
    fragment CorrectionCostToMoveCards_costToMoveAnalysis on TotalCostToMoveAnalysis {
      costToMoveByComp {
        compType
        costToMove
        comparatio
        totalCount
        affectedCount
        spend
        affectedComparatio
      }
      totalSpend
      totalCostToMove
      averageTCCComparatio
      averageAffectedTCCComparatio
      totalAffectedEmployeeCount
      totalEmployeeCount
    }
  `,
};
