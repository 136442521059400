import { gql } from "@apollo/client";
import { add, formatCurrency } from "@asmbl/shared/money";
import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { MouseEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTrack } from "../../analytics";
import { AccessBoundary } from "../../components/AccessBoundary";
import { AssembleTypography } from "../../components/AssembleTypography";
import { useCurrencies } from "../../components/CurrenciesContext";
import { DismissableInfoBanner } from "../../components/InfoBanner";
import {
  ADJUSTED_CASH_BAND_POINT_FIELDS,
  ADJUSTED_EQUITY_BAND_POINT_FIELDS,
} from "../../fragments";
import { pointGroups } from "../../models/Band";
import { isBandPointDefined } from "../../models/BandPoint";
import { currencySymbol } from "../../models/Currency";
import {
  Noun,
  PositionDetailReadTable_cashBandPoint as CashBandPoint,
  PositionDetailReadTable_equityBandPoint as EquityBandPoint,
  PositionDetailReadTable_position as Position,
} from "../../__generated__/graphql";
import { useExcludedBandNames } from "../LadderDetail/ExcludedBandNameContext";
import { CompensationHeading } from "./CompensationHeading";
import { PositionDetailBandPointCell } from "./PositionDetailBandPointCell";
import { PositionDetailTableHeader } from "./PositionDetailTableHeader";

const useStyles = makeStyles((theme) => ({
  annualizedCell: { borderLeft: `1px solid ${theme.palette.divider}` },
  iconSpacer: { marginLeft: theme.spacing(1) },
}));

type Props = {
  empty: boolean;
  bandNames: string[];
  position: Position;
};

export function PositionDetailReadTable({
  empty,
  bandNames,
  position,
}: Props): JSX.Element {
  const classes = useStyles();
  const { trackEvent } = useTrack();
  const navigate = useNavigate();
  const { selectedCurrency } = useCurrencies();

  const pgs = pointGroups<CashBandPoint | EquityBandPoint>(position, new Set());
  const { excludedBandNames } = useExcludedBandNames();
  const editURL = `/positions/${position.id}/edit`;

  const onEditBands = (event: MouseEvent) => {
    trackEvent({ object: "Edit Bands Form", action: "Opened" });
    // Try to replace the state, so that we end up with the page in history
    // twice after the edit.
    navigate(editURL, { replace: true });
    event.preventDefault();
  };

  return (
    <>
      <CompensationHeading
        actions={
          <AccessBoundary scope="global" verb="edit" noun={Noun.JobStructure}>
            <Button
              component={Link}
              to={editURL}
              onClick={onEditBands}
              variant="contained"
              size="small"
            >
              Edit Bands
            </Button>
          </AccessBoundary>
        }
      />
      {empty && (
        <DismissableInfoBanner
          level="info-gradient"
          align="center"
          centeredClose
        >
          <AssembleTypography variant="productSmallBold">
            There's no compensation information for this position. Add info by
            editing the band!<span className={classes.iconSpacer}>👆</span>
          </AssembleTypography>
        </DismissableInfoBanner>
      )}

      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <PositionDetailTableHeader bandNames={bandNames} />
          </TableHead>
          <TableBody>
            {pgs.map((pointGroup) => (
              <TableRow key={pointGroup.name}>
                <TableCell>{pointGroup.name}</TableCell>
                {pointGroup.bandPoints.map((point) => (
                  <TableCell key={point.bandName} align="right">
                    <PositionDetailBandPointCell
                      key={point.bandName}
                      bandPoint={point}
                      disabled={excludedBandNames.has(point.bandName)}
                      position={position}
                    />
                  </TableCell>
                ))}

                <TableCell className={classes.annualizedCell} align="right">
                  <AssembleTypography>
                    {pointGroup.bandPoints.some(isBandPointDefined)
                      ? formatCurrency(
                          pointGroup.bandPoints
                            .filter(
                              (point) => !excludedBandNames.has(point.bandName)
                            )
                            .map((point) => point.annualCashEquivalent)
                            .reduce(add)
                        )
                      : `${currencySymbol(selectedCurrency.code)} -`}
                  </AssembleTypography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const cashBandPoint = gql`
  ${ADJUSTED_CASH_BAND_POINT_FIELDS}
  ${PositionDetailBandPointCell.fragments.cashBandPoint}
  fragment PositionDetailReadTable_cashBandPoint on AdjustedCashBandPoint {
    name
    bandName
    annualCashEquivalent
    ...PositionDetailBandPointCell_cashBandPoint
    ...AdjustedCashBandPointFields
  }
`;

const equityBandPoint = gql`
  ${ADJUSTED_EQUITY_BAND_POINT_FIELDS}
  ${PositionDetailBandPointCell.fragments.equityBandPoint}
  fragment PositionDetailReadTable_equityBandPoint on AdjustedEquityBandPoint {
    name
    bandName
    annualCashEquivalent
    ...PositionDetailBandPointCell_equityBandPoint
    ...AdjustedEquityBandPointFields
  }
`;

PositionDetailReadTable.fragments = {
  position: gql`
    ${cashBandPoint}
    ${equityBandPoint}
    ${PositionDetailBandPointCell.fragments.position}
    fragment PositionDetailReadTable_position on Position {
      id
      type
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        name
        bandPoints {
          id
          ...PositionDetailReadTable_cashBandPoint
        }
      }
      adjustedEquityBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        name
        bandPoints {
          id
          ...PositionDetailReadTable_equityBandPoint
        }
      }
      ...PositionDetailBandPointCell_position
    }
  `,
};
