import { gql } from "@apollo/client";
import { formatCurrency } from "@asmbl/shared/money";
import { makeStyles } from "@material-ui/core";
import { getIllustrativeUnitPrice } from "../../../models/Equity";
import { GRAY_2 } from "../../../theme";
import {
  TotalEquityIllustrativeUnitPrice_equityGrants as Grant,
  TotalEquityIllustrativeUnitPrice_equityHoldings as EquityHoldings,
} from "../../../__generated__/graphql";
import { AssembleTypography } from "../../AssembleTypography";
import { useCurrencies } from "../../CurrenciesContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(4),
    alignItems: "baseline",
  },
  subtext: { color: GRAY_2 },
}));

type Props = {
  grants: Grant[] | null;
  valuationCurrency: EquityHoldings["valuationCurrency"];
};

export function TotalEquityIllustrativeUnitPrice({
  grants,
  valuationCurrency,
}: Props) {
  const classes = useStyles();
  const { currencies } = useCurrencies();

  const illustrativeUnitPrice = getIllustrativeUnitPrice(
    currencies,
    grants,
    valuationCurrency
  );

  return (
    <div className={classes.root}>
      <AssembleTypography
        data-cy="illustrative-equity-unit-price"
        variant="productHeader"
      >
        {`Illustrative Equity Unit Price: ${
          illustrativeUnitPrice !== null
            ? formatCurrency(illustrativeUnitPrice, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "-"
        }`}
      </AssembleTypography>
      <AssembleTypography
        className={classes.subtext}
        variant="productExtraSmallSemiBold"
      >
        The value of equity is based on your current company valuation.
      </AssembleTypography>
    </div>
  );
}

TotalEquityIllustrativeUnitPrice.fragments = {
  equityGrants: gql`
    fragment TotalEquityIllustrativeUnitPrice_equityGrants on EquityGrant {
      id
      sharePrice
    }
  `,
  equityHoldings: gql`
    fragment TotalEquityIllustrativeUnitPrice_equityHoldings on EquityHoldings {
      id
      valuationCurrency {
        id
        code
        exchangeRate
      }
    }
  `,
};
