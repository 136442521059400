import { gql } from "@apollo/client";
import { TableRow, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { SupportedManagersRow_manager as Manager } from "src/__generated__/graphql";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { ChevronRightIcon } from "src/components/AssembleIcons/Brand/ChevronRightIcon";
import { WireTableCell } from "src/components/Table/WireTable";
import { GRAY_4, WHITE } from "src/theme";

const useStyles = makeStyles((theme) => ({
  nameRow: {
    display: "flex",
    alignItems: "center",
  },
  expandIcon: {
    cursor: "pointer",
    padding: theme.spacing(1),
    paddingLeft: ({ indent }: { indent: number }) => theme.spacing(indent * 2),
  },
  disabledExpandIcon: {
    cursor: "default",
  },
}));

type Props = {
  manager: Manager;
  managers: Manager[];
  indent?: number;
};

export function SupportedManagersRow({ manager, managers, indent = 0 }: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const classes = useStyles({ indent });

  const directReportIds = manager.directReports.map((dr) => dr.id);
  const directReports = managers.filter((reportingManager) =>
    directReportIds.includes(reportingManager.id)
  );

  const indirectReportIds = manager.indirectReports.map((ir) => ir.id);
  const indirectReportManagers = managers.filter((reportingManager) =>
    indirectReportIds.includes(reportingManager.id)
  );
  return (
    <>
      <TableRow>
        <WireTableCell>
          <div className={classes.nameRow}>
            <span
              onClick={() => setIsExpanded(!isExpanded)}
              className={clsx(classes.expandIcon, {
                [classes.disabledExpandIcon]: directReports.length === 0,
              })}
            >
              {isExpanded ? (
                <ChevronDownIcon
                  color={directReports.length > 0 ? GRAY_4 : WHITE}
                />
              ) : (
                <ChevronRightIcon
                  color={directReports.length > 0 ? GRAY_4 : WHITE}
                />
              )}
            </span>
            {manager.displayName}
          </div>
        </WireTableCell>
        <WireTableCell>
          {manager.activeEmployment?.position?.ladder.department.name}
        </WireTableCell>
        <WireTableCell>
          {manager.activeEmployment?.position?.name}
        </WireTableCell>
        <WireTableCell>
          {directReports.length + indirectReportManagers.length}
        </WireTableCell>
      </TableRow>
      {isExpanded &&
        directReports.map((dr) => (
          <SupportedManagersRow
            key={`managers-table-${dr.id}`}
            manager={dr}
            managers={managers}
            indent={indent + 1 > 5 ? 5 : indent + 1}
          />
        ))}
    </>
  );
}

SupportedManagersRow.fragments = {
  manager: gql`
    fragment SupportedManagersRow_manager on Employee {
      id
      displayName
      managerId
      activeEmployment {
        id
        jobTitle
        position {
          id
          name
          ladder {
            id
            department {
              id
              name
            }
          }
        }
      }
      directReports {
        id
      }
      indirectReports {
        id
      }
    }
  `,
};
