import { gql } from "@apollo/client";
import { CompCycleDisplay } from "../../../../components/CompCycle/types";
import {
  ReportingEquityTier_compCycleBudget as CompCycleBudget,
  ReportingEquityTier_compCycleData as CompCycleData,
  ReportingEquityTier_manager as Manager,
  ReportingEquityTier_reports as Report,
  ReportingEquityTier_valuation as Valuation,
} from "../../../../__generated__/graphql";
import { IndentationCells } from "../../Budgets/Table/Connectors";
import { Equity } from "../BudgetsVsRequests";
import { EquityRow } from "./EquityRow";
import { EquitySummarizedRow } from "./EquitySummarizedRow";

type Props = {
  rootBudget: CompCycleBudget | null;
  manager: Manager | null;
  reports: Report[];
  currentValuation: Valuation;
  organizationName: string;
  varianceDisplay: CompCycleDisplay;
  equityDisplay: Equity;
  compCycleData: CompCycleData;
  indentation: IndentationCells;
};

export function ReportingEquityTier({
  rootBudget,
  manager,
  reports,
  currentValuation,
  organizationName,
  varianceDisplay,
  equityDisplay,
  compCycleData,
  indentation,
}: Props): JSX.Element {
  return (
    <>
      <EquitySummarizedRow
        manager={manager}
        reports={reports}
        rootBudget={rootBudget}
        currentValuation={currentValuation}
        varianceDisplay={varianceDisplay}
        equityDisplay={equityDisplay}
        organizationName={organizationName}
        indentation={indentation}
      />
      {reports
        .filter(
          (employee) =>
            employee.indirectReports.length > 0 ||
            employee.directReports.length > 0
        )
        .map((employee, idx, filteredEmployees) => {
          return (
            <EquityRow
              key={idx}
              employee={employee}
              organizationName={organizationName}
              varianceDisplay={varianceDisplay}
              expandable={employee.indirectReports.length > 0}
              currentValuation={currentValuation}
              equityDisplay={equityDisplay}
              compCycleData={compCycleData}
              indentation={indentation}
              isLastRow={idx === filteredEmployees.length - 1}
            />
          );
        })}
    </>
  );
}

ReportingEquityTier.fragments = {
  compCycleBudget: gql`
    ${EquitySummarizedRow.fragments.compCycleBudget}
    fragment ReportingEquityTier_compCycleBudget on CompCycleBudget {
      compCycleId
      employeeId
      ...EquitySummarizedRow_compCycleBudget
    }
  `,
  manager: gql`
    ${EquitySummarizedRow.fragments.manager}
    fragment ReportingEquityTier_manager on Employee {
      id
      ...EquitySummarizedRow_manager
    }
  `,
  reports: gql`
    ${EquitySummarizedRow.fragments.reports}
    ${EquityRow.fragments.employee}
    fragment ReportingEquityTier_reports on Employee {
      id

      directReports {
        id
      }

      indirectReports {
        id
      }

      ...EquityRow_employee
      ...EquitySummarizedRow_reports
    }
  `,
  valuation: gql`
    ${EquitySummarizedRow.fragments.valuation}
    ${EquityRow.fragments.valuation}
    fragment ReportingEquityTier_valuation on Valuation {
      id
      ...EquityRow_valuation
      ...EquitySummarizedRow_valuation
    }
  `,
  compCycleData: gql`
    ${EquityRow.fragments.compCycleData}
    fragment ReportingEquityTier_compCycleData on CompCycle {
      id
      ...EquityRow_compCycleData
    }
  `,
};
