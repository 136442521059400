import { contramap } from "@asmbl/shared/sort";
import { makeStyles } from "@material-ui/core";
import { CheckIcon } from "src/components/AssembleIcons/Brand/CheckIcon";
import { CloseCircleIcon } from "src/components/AssembleIcons/Brand/CloseCircleIcon";
import { GREEN_2, RED } from "../../../../../theme";
import { LocationRow } from "../LocationsTable";

const useStyles = makeStyles(() => ({
  cell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export function LocationMappedCell({ value }: { value: boolean }): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.cell}>
      {value ? (
        <CheckIcon color={GREEN_2} height="18px" width="18px" />
      ) : (
        <CloseCircleIcon color={RED} height="18px" width="18px" />
      )}
    </div>
  );
}

Object.assign(LocationMappedCell, {
  Cell: LocationMappedCell,
  id: "mapped" as const,
  align: "center" as const,
  Header: "Mapped" as const,
  accessor: "mapped" as const,
  width: 1,
  ordering: contramap((location: LocationRow) => location.mapped),
});
