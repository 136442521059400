import { gql } from "@apollo/client";
import { isZero } from "@asmbl/shared/money";
import { makeStyles, Typography } from "@material-ui/core";
import { CashIcon } from "src/components/AssembleIcons/Small/CashIcon";
import { AssembleLinkify } from "../../components/AssembleLinkify/AssembleLinkify";
import { AssembleTypography } from "../../components/AssembleTypography";
import { Bubble } from "../../components/Employee/BubbleInfo/BubbleInfo";
import { getSimpleCashLabel } from "../../models/Currency";
import { GRAY_2 } from "../../theme";
import { PortalBenefitsGrid_benefitsPackage } from "../../__generated__/graphql";

type Props = {
  benefitsPackage: PortalBenefitsGrid_benefitsPackage;
};

const useStyles = makeStyles((theme) => ({
  totalSummary: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
  },
  total: {
    marginBottom: theme.spacing(0.5),
  },
  legend: {
    alignSelf: "flex-end",
    display: "flex",
    gap: theme.spacing(1.5),
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: theme.spacing(4),
  },
  card: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "8px",
    padding: theme.spacing(3, 2, 3, 3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: theme.spacing(2),
  },
  description: {
    color: GRAY_2,
    whiteSpace: "pre-wrap",
  },
  value: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
}));

export function PortalBenefitsGrid({ benefitsPackage }: Props): JSX.Element {
  const classes = useStyles();

  const hasAnyValue = !isZero(benefitsPackage.totalValue);

  return (
    <>
      {hasAnyValue && (
        <div className={classes.totalSummary}>
          <div>
            <Typography component="div" variant="h2" className={classes.total}>
              {getSimpleCashLabel(benefitsPackage.totalValue)}
            </Typography>
            <AssembleTypography
              variant="productParagraphSmall"
              textColor={GRAY_2}
            >
              Estimated annual value of company contributions to your benefits
            </AssembleTypography>
          </div>
          <div className={classes.legend}>
            <Bubble icon={<CashIcon inherit />} small color="gray" />
            <AssembleTypography variant="productHeader">
              Estimated Value
            </AssembleTypography>
          </div>
        </div>
      )}
      <div className={classes.grid}>
        {benefitsPackage.benefits.map((benefit) => (
          <div key={benefit.id} className={classes.card}>
            <div>
              <AssembleTypography variant="h5" paragraph>
                {benefit.name}
              </AssembleTypography>
              <AssembleLinkify newTab>
                <AssembleTypography
                  variant="productParagraphLarge"
                  className={classes.description}
                >
                  {benefit.description}
                </AssembleTypography>
              </AssembleLinkify>
            </div>
            {benefit.value && (
              <div className={classes.value}>
                <Bubble icon={<CashIcon inherit />} small color="gray" />
                <AssembleTypography variant="productHeader">
                  {getSimpleCashLabel(benefit.value)}
                </AssembleTypography>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

PortalBenefitsGrid.fragments = {
  benefitsPackage: gql`
    fragment PortalBenefitsGrid_benefitsPackage on BenefitsPackage {
      totalValue
      benefits {
        id
        name
        description
        value
      }
    }
  `,
};
