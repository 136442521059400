import { gql } from "@apollo/client";
import { zero } from "@asmbl/shared/money";
import { useCompStructure } from "src/components/CompStructureContext";
import {
  TotalTargetCashChange_cashCompensation,
  TotalTargetCashChange_recItem,
} from "../../../__generated__/graphql";
import {
  getTotalCash,
  getTotalCashAfterRecommendation,
} from "../../../models/CashCompensation";
import { AssembleTypography } from "../../AssembleTypography";
import { useCurrencies } from "../../CurrenciesContext";
import { CompComponentContainer } from "./CompComponentContainer";
import { BadgedMoneyValue } from "./CompValue";
import { LabelValue } from "./LabelValue";
import { SalaryChange } from "./SalaryChange";
import { TargetVariablePayChange } from "./TargetVariablePayChange";

type Props = {
  recItems: TotalTargetCashChange_recItem[];
  cashCompensations: TotalTargetCashChange_cashCompensation[];
};
export function TotalTargetCashChange({
  recItems,
  cashCompensations,
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();
  const { compStructure } = useCompStructure();
  const prevTotalCash = getTotalCash(cashCompensations);
  const newTotalCash =
    getTotalCashAfterRecommendation(
      defaultCurrencyCode,
      cashCompensations,
      recItems,
      compStructure?.workingHoursPerYear
    )?.annualTotal ?? zero(prevTotalCash?.currency ?? defaultCurrencyCode);

  return (
    <CompComponentContainer>
      <LabelValue
        label={
          <AssembleTypography variant="productEyebrowSmall" gutterBottom>
            Total Target Cash Change
          </AssembleTypography>
        }
        compValue={
          <BadgedMoneyValue
            previousValue={prevTotalCash?.annualTotal}
            value={newTotalCash}
          />
        }
      />
      <SalaryChange cashCompensations={cashCompensations} recItems={recItems} />
      <TargetVariablePayChange
        cashCompensations={cashCompensations}
        recItems={recItems}
      />
    </CompComponentContainer>
  );
}

TotalTargetCashChange.fragments = {
  recItem: gql`
    ${SalaryChange.fragments.recItem}
    ${TargetVariablePayChange.fragments.recItem}
    fragment TotalTargetCashChange_recItem on RecItem {
      unitType
      ...SalaryChange_recItem
      ...TargetVariablePayChange_recItem
    }
  `,
  cashCompensation: gql`
    ${SalaryChange.fragments.cashCompensation}
    ${TargetVariablePayChange.fragments.cashCompensation}
    fragment TotalTargetCashChange_cashCompensation on CashCompensation {
      ...SalaryChange_cashCompensation
      ...TargetVariablePayChange_cashCompensation
    }
  `,
};
