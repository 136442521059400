import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { APP_FONTS, GRAY_4, GRAY_6 } from "../../theme";
import { TimeSeriesResponse } from "../../__generated__/graphql";
import {
  createChartColors,
  formatNormalizedTimeSeriesData,
  formatTimeSeriesData,
  SeriesItem,
} from "./utils";

type TimeSeries = TimeSeriesResponse;

interface Props {
  items: TimeSeries[];
  height?: string;
  showPercentages: boolean;
}

export function TimelineSeriesChart({
  items,
  height,
  showPercentages,
}: Props): JSX.Element {
  const valueFormatter = (value: number) => {
    const intValue = Math.round(value);
    return showPercentages ? `${intValue}%` : `${intValue}`;
  };

  /**
   * These functions take the items prop and format the values into an Apex
   * series object:
   * [{ data: [{ x: dateString, y: value}, ..., {x: dateString, y: value }] }]
   * If the chart is in percentages view, formatNormalizedTimeSeriesData
   * transforms the item values into percentages based on the total series sum
   * for the item's date
   */
  const formattedTimeSeries: SeriesItem[] = showPercentages
    ? formatNormalizedTimeSeriesData(items)
    : formatTimeSeriesData(items);

  const containsOmittedCategory = formattedTimeSeries.some(
    (series) => series.name === "Omitted"
  );

  const colors = createChartColors(
    containsOmittedCategory,
    formattedTimeSeries.length
  );

  const options: ApexOptions = {
    chart: {
      stacked: true,
      toolbar: { show: false },
      fontFamily: APP_FONTS,
      animations: {
        animateGradually: {
          enabled: false,
        },
      },
    },
    grid: {
      position: "front",
      borderColor: GRAY_6,
      strokeDashArray: 3,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    colors: containsOmittedCategory ? colors.reverse() : colors,
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "left",
      fontSize: "14px",
      markers: {
        height: 8,
        width: 8,
        radius: 3,
      },
      inverseOrder: true,
    },
    fill: {
      type: "solid",
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          month: "MMM yy",
        },
        style: {
          fontSize: "12px",
          colors: GRAY_4,
        },
      },
      axisBorder: {
        show: false,
      },
    },
    stroke: {
      show: false,
    },
    yaxis: {
      tickAmount: 4,
      opposite: true,
      axisBorder: { show: false },
      axisTicks: {
        show: true,
        color: "white",
      },
      max: showPercentages ? 100 : undefined,
      min: 0,
      labels: {
        style: {
          fontSize: "14px",
          colors: GRAY_4,
        },
        formatter: valueFormatter,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      x: {
        format: "MMM dd, yyyy",
      },
      y: {
        formatter: valueFormatter,
      },
      inverseOrder: true,
      shared: false,
    },
  };

  return (
    <>
      <ReactApexChart
        series={formattedTimeSeries}
        options={options}
        type="area"
        height={height ?? 200}
      />
    </>
  );
}
