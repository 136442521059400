import { gql, useQuery } from "@apollo/client";
import Loading from "../../../../components/Settings/Loading";
import {
  EmployeePortalQuery,
  EmployeePortalQueryVariables,
} from "../../../../__generated__/graphql";
import { EmptyState } from "../../../People/EmptyTableState";
import { EmployeePortal } from "./EmployeePortal";

export const EmployeePortalBoundary = () => {
  const { data, loading } = useQuery<
    EmployeePortalQuery,
    EmployeePortalQueryVariables
  >(EmployeePortalBoundary.query, { pollInterval: 15_000 });

  if (!data || data.employees.length === 0) {
    return loading ? <Loading /> : <EmptyState />;
  }

  return (
    <EmployeePortal
      employees={data.employees}
      config={data.portalConfig}
      organization={data.organization}
      valuation={data.valuation}
    />
  );
};

EmployeePortalBoundary.query = gql`
  ${EmployeePortal.fragments.employee}
  ${EmployeePortal.fragments.portalConfig}
  ${EmployeePortal.fragments.organization}
  ${EmployeePortal.fragments.valuation}
  query EmployeePortalQuery {
    employees {
      id
      ...EmployeePortal_employee
    }
    portalConfig {
      id
      ...EmployeePortal_portalConfig
    }

    organization {
      id
      ...EmployeePortal_organization
    }

    valuation {
      id
      ...EmployeePortal_valuation
    }
  }
`;
