import Linkify from "react-linkify";

type AssembleLinkifyProps = { children: JSX.Element; newTab?: boolean };

/**
 * @usage <AssembleLinkify>
 *          <ComponentWithLinks />
 *        </AssembleLinkify>
 * @param children - the component to wrap this component around to linkify
 * @param newTab - identifies whether the link will be opened in a new tab
 * @returns a component that adds href's to the links in the `children`
 * component
 */
export function AssembleLinkify({
  children,
  newTab = false,
}: AssembleLinkifyProps): JSX.Element {
  const openInNewTabDecorator = (
    href: string | undefined,
    text: string | undefined,
    key: React.Key | null | undefined
  ) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  return (
    <Linkify componentDecorator={newTab ? openInNewTabDecorator : undefined}>
      {children}
    </Linkify>
  );
}
