import { gql } from "@apollo/client";
import { formatCurrency } from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import {
  calculateEquityFromGrants,
  Grant,
} from "../../../models/EquityVestEvent";
import { GRAY_4 } from "../../../theme";
import { TotalGrantedEquityChartHeader_equityHoldings as EquityHoldings } from "../../../__generated__/graphql";
import { AssembleTypography } from "../../AssembleTypography";
import { useCurrencies } from "../../CurrenciesContext";
import { useTotalEquitySlider } from "../../TotalEquitySlider/TotalEquitySliderContext";

const useStyles = makeStyles(() => ({
  element: {
    display: "flex",
    flexDirection: "column",
  },
  header: { color: GRAY_4 },
  cash: { fontWeight: 500 },
  units: { letterSpacing: "-1px" },
}));

type Props = {
  equityHoldings: EquityHoldings;
};

export function TotalGrantedEquityChartHeader({
  equityHoldings,
}: Props): JSX.Element {
  const classes = useStyles();
  const { currencies } = useCurrencies();
  const equitySliderData = useTotalEquitySlider();

  const sharePriceTarget = equitySliderData?.value;

  const totalGrantedEquity = calculateEquityFromGrants(
    currencies,
    sharePriceTarget,
    (equityHoldings.grants as (Grant & { unitCount: number })[] | null) ?? [],
    equityHoldings.valuationCurrency
  );

  return (
    <div className={classes.element}>
      <AssembleTypography
        className={classes.header}
        variant="productEyebrowMedium"
      >
        Total Granted Equity
      </AssembleTypography>
      <AssembleTypography
        className={classes.cash}
        variant="productLongForm"
        data-cy="total-granted-equity-header-cash"
      >
        {totalGrantedEquity.value !== null
          ? formatCurrency(totalGrantedEquity.value, {
              maximumFractionDigits: 0,
            })
          : "-"}
      </AssembleTypography>
      <AssembleTypography
        className={classes.units}
        variant="sidebarTitle"
        data-cy="total-granted-equity-header-units"
      >
        {equityHoldings.totalUnitCount !== null
          ? `${formatNumeral(equityHoldings.totalUnitCount)} units`
          : "-"}
      </AssembleTypography>
    </div>
  );
}

TotalGrantedEquityChartHeader.fragments = {
  equityHoldings: gql`
    fragment TotalGrantedEquityChartHeader_equityHoldings on EquityHoldings {
      id
      grants {
        id
        sharePrice
        unitCount
      }
      totalUnitCount
      valuationCurrency {
        id
        code
        exchangeRate
      }
    }
  `,
};
