/**
 * mapMaybe is a helper function to map an array and then filter out any null or undefined values.
 * Separately mapping and then filtering requires a type predicate for Typescript to properly infer
 * the type. Previously, we had an `isPresent` helper that you would pass to `filter`. This tended
 * to be used outside of filters, though, where a simple `x == null` would suffice.
 *
 * Before:
 *     employees.map(employee => employee.name).filter(isPresent);
 * After:
 *     mapMaybe(employees, employee => employee.name);
 *
 * Before:
 *     if (isPresent(employee.name)) { ... }
 * After:
 *     if (employee.name != null) { ... }
 */
export function mapMaybe<T, U>(
  array: T[],
  mapFn: (t: T, i: number) => U | undefined | null | void
): U[] {
  return array.flatMap((t, i) => {
    const maybeU = mapFn(t, i);
    return maybeU == null ? [] : [maybeU];
  });
}

/**
 * Given an array of objects, return a Map of those objects grouped by a key.
 *
 * @param objects An array of objects;
 * @param key Name of a key on the object.
 * This key need to have a primitive value for grouping to work.
 *
 * @example
 * const people = [
 *   {name: "Bob", number: 1, type: "person"},
 *   {name: "Jill", number: 2, type: "person"},
 *   {name: "Bow Wow", number: 1, type: "dog"}
 * ]
 *
 *  groupByKey(people, "type") =>
 *  Map {
 *   'person' => [
 *     { name: 'Bob', number: 1, type: 'person' },
 *     { name: 'Jill', number: 2, type: 'person' }
 *   ],
 *   'dog' => [
 *      { name: 'Bow Wow', number: 1, type: 'dog' }
 *    ]
 *  }
 */
export function groupByKey<T, K extends keyof T>(
  objects: T[],
  key: K
): Map<T[K], T[]> {
  return objects.reduce((acc, cur) => {
    const property = cur[key];
    const value = acc.get(property);
    if (value) {
      value.push(cur);
    } else {
      acc.set(property, [cur]);
    }
    return acc;
  }, new Map<T[K], T[]>());
}

/**
 * Generates an array of dates in three month intervals
 * @param startDate first date value in return array
 * @param endDate optional last date value, otherwise it is the current date
 * @returns an array of dates: [startDate, ..., endDate]
 */
export function generateQuarterlyDates(
  startDate?: Date,
  endDate: Date = new Date()
): Date[] {
  const dates: Date[] = [];
  const lastYear = new Date().getFullYear() - 1;
  let currentDate = startDate
    ? new Date(startDate)
    : new Date(new Date().setFullYear(lastYear));
  while (currentDate < endDate) {
    dates.push(currentDate);
    currentDate = new Date(currentDate);
    currentDate.setMonth(currentDate.getMonth() + 3);
  }
  dates.push(endDate);
  return dates;
}

/**
 * Returns `true` if the string is undefined, null, or an empty string. Otherwise, returns `false`.
 */
export function isEmptyString(s: string | undefined | null): boolean {
  return s === null || s === undefined || s === "";
}

/**
 * The inverse of {@link isEmptyString}, but with added typing benefits.
 */
export function isValidString(s: string | undefined | null): s is string {
  return !isEmptyString(s);
}

/**
 * Condense a string down to alphanumeric characters. Useful for comparing
 * when different characters for commas or apostrophes or errant spaces
 * may cause issues with comparing strings
 */
export function condenseString(s: string | undefined): string | null {
  if (s == null) return null;
  return s.replace(/[^0-9a-z]/gi, "");
}

/** Returns true if the date string can be parsed successfully. Returns false otherwise. */
export function isValidDate(date: string | null | undefined): date is string {
  return date != null && !isNaN(new Date(date).getTime());
}

/**
 * When you use Promise.allSettled, it returns a PromiseSettledResult[]. This
 * is a type guard that you can use to filter on only fulfilled promises.
 */
export function fullfilledPromise<T>(
  promise: PromiseSettledResult<T>
): promise is PromiseFulfilledResult<T> {
  return promise.status === "fulfilled";
}

export function formatNumeral(
  value: number,
  options?: Intl.NumberFormatOptions
): string {
  return new Intl.NumberFormat("en-US", options).format(value);
}

/**
 * Given a list of objects and a key from one of those objects, returns a set
 * of the values at that key.
 *
 * Example:
 *    const employees = [{id: 1, name: "Jacob"}, {id: 2, name: "Joe"}]
 *    setify(employees, "id") === new Set([1, 2])
 */
export function setify<T, K extends keyof T>(list: T[], key: K): Set<T[K]> {
  return new Set(list.map((item) => item[key]));
}

/**
 * An over-loaded function for easily creating maps out of an array of objects.
 *
 * 1. Given a list of objects and a key from one of those objects, returns a map
 *    where the value points back to the original object, like so:
 *      const employees = [{id: 1, name: "Jacob"}, {id: 2, name: "Joe"}]
 *      mapify(employees, "id") === {
 *                                    1: {id: 1, name: "Jacob"},
 *                                    2: {id: 2, name: "Joe"},
 *                                  }
 *
 * 2. Given a list of objects and two keys from one of those objects, returns a
 *    map using those two keys as the key/value pair:
 *      const employees = [{id: 1, name: "Jacob"}, {id: 2, name: "Joe"}]
 *      mapify(employees, "id", "name") === { 1: "Jacob", 2: "Joe" }
 */
export function mapify<T, K extends keyof T>(list: T[], key: K): Map<T[K], T>;
export function mapify<T, K extends keyof T, V extends keyof T>(
  list: T[],
  key: K,
  value: V
): Map<T[K], T[V]>;
export function mapify<T, K extends keyof T, V extends keyof T>(
  list: T[],
  key: K,
  value?: V
) {
  return value === undefined
    ? new Map(list.map((i) => [i[key], i]))
    : new Map(list.map((i) => [i[key], i[value]]));
}

/**
 * Takes a list of objects and a key and returns a list of unique objects by
 * that key.
 */
export function distinct<T>(arr: T[], key: keyof T): T[] {
  const unique = mapify(arr, key);
  return [...unique.values()];
}

/**
 * Check if a string is valid JSON.
 */
export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * Rounds a number to 2 (by default) places, trimming any trailing-zeroes from
 * the end.
 */
export function round(value: number, rounding = 2): string {
  return formatNumeral(value, { maximumFractionDigits: rounding });
}

/**
 * `zip` takes two lists and returns a list of corresponding pairs.
 * If one input list is shorter than the other, excess elements of the longer
 * list are discarded.
 */
export function zip<A, B>(a: A[], b: B[]): [A, B][] {
  const len = Math.min(a.length, b.length);
  const zipped: [A, B][] = [];
  for (let i = 0; i < len; i++) {
    zipped.push([a[i], b[i]]);
  }
  return zipped;
}

/**
 * Like filter, but returns two arrays:
 *  - the first is an array of items that match the given function
 *  - the second is an array of items that do *not* match the given function
 *
 * Example:
 *   const items = [1, 2, 3, 4, 5]
 *   const [even, odd] = partition(items, (i) => i % 2 === 0)
 *
 *   even === [2, 4]
 *   odd === [1, 3, 5]
 */
export function partition<T>(arr: T[], func: (item: T) => boolean): [T[], T[]] {
  const pass: T[] = [];
  const fail: T[] = [];

  arr.forEach((item) => {
    if (func(item)) {
      pass.push(item);
    } else {
      fail.push(item);
    }
  });
  return [pass, fail];
}

/**
 * Sorts an array of objects by `activeAt` DESCENDING, then picks the first one
 * with an `activeAt` less than or equal to `before`
 */
export function findMostRecent<T extends { activeAt: Date }>(
  arr: T[],
  before: Date
): T | undefined {
  return arr
    .slice()
    .sort((a, b) => b.activeAt.getTime() - a.activeAt.getTime())
    .find((x) => x.activeAt <= before);
}

/**
 * compares two arrays for (shallow) equality, regardless of order
 * @param arr1
 * @param arr2
 * @returns
 */

export function areArraysEqual<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}
