import { Button, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { GRAY_1, GRAY_5, PURPLE_1, WHITE } from "../../theme";
import { ChevronLeftIcon } from "../AssembleIcons/Brand/ChevronLeftIcon";
import { ChevronRightIcon } from "../AssembleIcons/Brand/ChevronRightIcon";
import { PageSizeSelector } from "./PageSizeSelector";

interface Props {
  page: number;
  setPage: (page: number) => unknown;
  pageSize: number;
  setPageSize: (pageSize: number) => unknown;
  items: number;
  compact?: boolean;
  includeAll?: boolean;
  includePageSizeSelector?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "fit-content",
    position: "relative",
    height: "100%",
    maxHeight: "4rem",
  },

  pageRange: {
    display: "flex",
    alignItems: "center",
    background: WHITE,
    borderRadius: "5px",
  },
  prevButton: {
    height: "100%",
    minWidth: "2rem",
    borderRadius: "5px 0 0 5px",
    border: `1px solid ${GRAY_5}`,

    "&:hover, &:focus, &:active": {
      borderColor: PURPLE_1,
    },
  },
  range: {
    height: "100%",
    width: "7rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTop: `1px solid ${GRAY_5}`,
    borderBottom: `1px solid ${GRAY_5}`,
    color: GRAY_1,
    fontSize: "0.875rem",
  },
  compactRange: {
    minWidth: "5.5rem",
    width: "100%",
    padding: theme.spacing(0, 1),
    boxShadow: "0px 1px 3px rgba(10, 36, 64, 0.1)",
  },
  rangeLeftBorder: {
    borderLeft: `1px solid ${GRAY_5}`,
  },
  rangeRightBorder: {
    borderRight: `1px solid ${GRAY_5}`,
  },
  nextButton: {
    height: "100%",
    minWidth: "2rem",
    borderRadius: "0px 5px 5px 0px",
    border: `1px solid ${GRAY_5}`,

    "&:hover, &:focus, &:active": {
      borderColor: PURPLE_1,
    },
  },
  nextIcon: {
    height: "16px",
    width: "16px",
  },
  compactButton: {
    padding: "0px",
    width: "2rem",
    minWidth: "2rem",
    maxWidth: "2rem",
    boxShadow: "0px 1px 3px rgba(10, 36, 64, 0.1)",
  },
  buttonDisabled: {
    border: `1px solid ${GRAY_5}`,
    opacity: 0.3,
  },
}));

export function Paginator({
  page,
  setPage,
  pageSize,
  setPageSize,
  items,
  compact = false,
  includeAll = false,
  includePageSizeSelector = true,
}: Props): JSX.Element {
  const numPages = Math.ceil(items / pageSize);
  const minItem = Math.min(pageSize * page + 1, items);
  const maxItem = Math.min(pageSize * (page + 1), items);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {includePageSizeSelector && (
        <PageSizeSelector
          pageSize={pageSize}
          setPageSize={setPageSize}
          setPage={setPage}
          items={items}
          compact={compact}
          includeAll={includeAll}
        />
      )}
      <div className={classes.pageRange}>
        <Button
          className={clsx(classes.prevButton, {
            [classes.compactButton]: compact,
            [classes.buttonDisabled]: page === 0 && compact,
          })}
          onClick={() => setPage(page - 1)}
          disabled={page === 0}
        >
          <ChevronLeftIcon
            inherit
            className={clsx({
              [classes.compactButton]: compact,
            })}
          />
        </Button>
        <Typography
          className={clsx(classes.range, {
            [classes.compactRange]: compact,
            [classes.rangeLeftBorder]: compact && page === 0,
            [classes.rangeRightBorder]: compact && page === numPages - 1,
          })}
        >
          {minItem === maxItem
            ? `${minItem} of ${items}`
            : `${minItem} - ${maxItem} of ${items}`}
        </Typography>
        <Button
          className={clsx(classes.nextButton, {
            [classes.compactButton]: compact,
            [classes.buttonDisabled]: compact && page === numPages - 1,
          })}
          onClick={() => setPage(page + 1)}
          disabled={page === numPages - 1}
        >
          <ChevronRightIcon
            inherit
            className={clsx({
              [classes.compactButton]: compact,
            })}
          />
        </Button>
      </div>
    </div>
  );
}
