import { useCallback, useEffect, useRef } from "react";
import {
  unstable_useBlocker as useBlocker,
  useBeforeUnload,
} from "react-router-dom";

export const UnsavedChangesWarning = ({
  pageEdited,
}: {
  pageEdited: boolean;
}): JSX.Element => {
  return (
    <Prompt
      when={pageEdited}
      message="Are you sure you want to leave? If you leave the page now, you'll lose your unsaved changes."
    />
  );
};

function usePrompt(message: string | null | undefined | false) {
  const blocker = useBlocker(
    useCallback(
      () => (typeof message === "string" ? !window.confirm(message) : false),
      [message]
    )
  );
  const prevState = useRef(blocker.state);
  useEffect(() => {
    if (blocker.state === "blocked") {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (typeof message === "string") {
          event.preventDefault();
          event.returnValue = message;
        }
      },
      [message]
    ),
    { capture: true }
  );
}

// You can also reimplement the v5 <Prompt> component API
function Prompt({ when, message }: PromptProps) {
  try {
    usePrompt(when ? message : false);
  } catch (e) {
    return null;
  }
  return null;
}

type PromptProps = {
  when: boolean;
  message: string;
};
