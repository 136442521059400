import { gql, useQuery } from "@apollo/client";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { useEffect } from "react";
import {
  GetMergeToken,
  GetMergeTokenVariables,
  MergeCategory,
} from "../../../__generated__/graphql";

interface Props {
  originId: string | null;
  category: MergeCategory;
  onSuccess: (
    originId: string,
    publicToken: string,
    category: MergeCategory
  ) => unknown;
  onExit: () => unknown;
}

/*
  This component will try to fetch a Merge link token for the given originId
  (or a new token if none provided). As soon as it's ready, it will open the
  modal to start the linking process. */
export function MergeModal({
  originId,
  category,
  onSuccess,
  onExit,
}: Props): JSX.Element {
  const { data } = useQuery<GetMergeToken, GetMergeTokenVariables>(
    MergeModal.queries.getMergeToken,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: { originId, category },
    }
  );

  if (!data) {
    return <></>;
  }

  return (
    <InnerMergeModal
      linkToken={data.mergeLink.linkToken}
      originId={data.mergeLink.originId}
      category={category}
      onSuccess={onSuccess}
      onExit={onExit}
    />
  );
}

interface InnerProps {
  linkToken: string;
  originId: string;
  category: MergeCategory;
  onSuccess: (
    originId: string,
    publicToken: string,
    category: MergeCategory
  ) => unknown;
  onExit: () => unknown;
}
function InnerMergeModal({
  linkToken,
  originId,
  category,
  onSuccess,
  onExit,
}: InnerProps): null {
  const { open, isReady } = useMergeLink({
    linkToken,
    onExit,
    onSuccess: (publicToken) => onSuccess(originId, publicToken, category),
  });

  useEffect(() => {
    if (isReady) {
      open();
    }
  }, [isReady, open]);

  return null;
}

MergeModal.queries = {
  getMergeToken: gql`
    query GetMergeToken($originId: String, $category: MergeCategory!) {
      mergeLink(originId: $originId, category: $category) {
        linkToken
        originId
      }
    }
  `,
};
