import { gql, useMutation } from "@apollo/client";
import { Button, makeStyles, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { useTrack } from "../../../../analytics";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { SaveButton } from "../../../../components/Form/SaveButton";
import { OutcomeWithAnnotation } from "../../../../components/Settings/IllustrativeOutcomesForm";
import UnsavedChangesWarning from "../../../../components/UnsavedChangesWarning";
import { UPDATE_ORG_PERMISSION_SETTINGS } from "../../../../mutations";
import { useUpsertPortalConfig } from "../../../../mutations/PortalConfig";
import {
  EmployeeBandAccessType,
  PortalConfig as PortalConfigModel,
  UpdatePermissionSettings,
  UpdatePermissionSettingsVariables,
} from "../../../../__generated__/graphql";
import { PageContainer } from "../../PageContainer";
import { CompensationBandsForm } from "./CompensationBandsForm";
import { EquityOutcomes } from "./EquityOutcomes";
import { EquityOutcomesForm } from "./EquityOutcomesForm";

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  saveButton: {
    height: "100%",
  },
}));

export const LABEL_CHARACTER_LIMIT = 30;

export interface ExitOutcomeWithAnnotation extends OutcomeWithAnnotation {
  placeholder: boolean;
  placeholderText?: string;
}

export type FormData = {
  defaultExitOutcome: number | null;
  exitOutcomes: ExitOutcomeWithAnnotation[];
};

type Props = {
  portalConfig: PortalConfigModel | null;
  employeeBandAccess: EmployeeBandAccessType;
  organizationName: string;
};

type ChangeHandler = <Field extends keyof FormData>(
  id: Field,
  value: FormData[Field]
) => unknown;

export const PortalConfig = ({
  portalConfig,
  employeeBandAccess,
  organizationName,
}: Props) => {
  const classes = useStyles();
  const { trackEvent } = useTrack();
  /* Note: because the valuation and org currency should be the same,
   * this is used as the fallback currency code in case the portalConfig is null.
   * If the valuation currency can be different than the org currency,
   * this fallback will need to be updated */
  const { defaultCurrencyCode } = useCurrencies();

  const initialData = {
    exitOutcomes: initializeOutcomes(portalConfig),
    defaultExitOutcome:
      portalConfig?.defaultExitOutcome ?? PLACEHOLDER_OUTCOMES[0].value,
  };

  const [formData, setFormData] = useState<FormData>(initialData);
  const [[formChanged, bandChanged], setPageUpdated] = useState([false, false]);
  const [bandAccess, setBandAccess] =
    useState<EmployeeBandAccessType>(employeeBandAccess);

  const error = generateErrorMessage(
    formData.exitOutcomes,
    formData.defaultExitOutcome
  );

  const upsertPortalConfig = useUpsertPortalConfig();
  const [updatePermissionSettings] = useMutation<
    UpdatePermissionSettings,
    UpdatePermissionSettingsVariables
  >(UPDATE_ORG_PERMISSION_SETTINGS);

  const handleBandAccessChange = (newBandAccess: EmployeeBandAccessType) => {
    setBandAccess(newBandAccess);
    setPageUpdated([formChanged, true]);
  };

  const handleChange: ChangeHandler = (id, value) => {
    setFormData((prevData) => ({ ...prevData, [id]: value }));
    setPageUpdated([true, bandChanged]);
  };

  const handleCancel = () => {
    setFormData(initialData);
    setBandAccess(employeeBandAccess);
    setPageUpdated([false, false]);
  };

  const handleSave = async () => {
    let successfullySaved = false;
    trackEvent({
      area: "Employee Portal",
      subArea: "Portal Config",
      object: "Settings Save Button",
      action: "Clicked",
    });
    // user has cleared the outcomes, reset the config to null
    if (
      formData.defaultExitOutcome === null &&
      formData.exitOutcomes.every((outcome) => outcome.value === 0)
    ) {
      const updatedConfig = await upsertPortalConfig(null);
      successfullySaved = updatedConfig === null;
    }
    // portal config values have been updated
    else if (formData.defaultExitOutcome !== null && error.length === 0) {
      const updatedConfig = await upsertPortalConfig({
        exitOutcomes: formData.exitOutcomes.map((outcome) => outcome.value),
        xAxisAnnotations: formData.exitOutcomes.map(
          (outcome) => outcome.annotation
        ),
        defaultExitOutcome: formData.defaultExitOutcome,
      });
      successfullySaved = updatedConfig !== null;
    }

    // check if band access has been updated
    if (employeeBandAccess !== bandAccess) {
      const updatedSettings = await updatePermissionSettings({
        variables: {
          data: {
            employeeBandAccess: bandAccess,
          },
        },
      });
      successfullySaved =
        updatedSettings.data?.updatePermissionSettings.employeeBandAccess ===
        bandAccess;
    }
    if (successfullySaved) setPageUpdated([false, false]);
    return successfullySaved;
  };

  const handleFormChange = () => setPageUpdated([true, bandChanged]);
  return (
    <PageContainer
      centerContent
      header="Employee Portal Configuration"
      description={`Enable and configure Employee Portal for all your employees in ${organizationName}.`}
    >
      <>
        <UnsavedChangesWarning pageEdited={formChanged || bandChanged} />
        <CompensationBandsForm
          employeeBandAccess={bandAccess}
          handleBandAccessChange={handleBandAccessChange}
        />
        <EquityOutcomes
          formData={formData}
          handleFormChange={handleFormChange}
          changeHandler={handleChange}
          currencyCode={
            portalConfig?.valuationCurrencyCode ?? defaultCurrencyCode
          }
        />
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.cancelButton}
            onClick={handleCancel}
            variant="contained"
            disabled={!formChanged}
          >
            Cancel
          </Button>
          <Tooltip
            title={formChanged && error.length > 0 ? error : ""}
            placement="top"
          >
            <span>
              <SaveButton
                className={classes.saveButton}
                onSave={handleSave}
                disabled={
                  (!formChanged && !bandChanged) ||
                  (formChanged && error.length > 0)
                }
                hideEndIcon
              />
            </span>
          </Tooltip>
        </div>
      </>
    </PageContainer>
  );
};

function initializeOutcomes(
  portalConfig: Props["portalConfig"]
): ExitOutcomeWithAnnotation[] {
  if (portalConfig === null) return PLACEHOLDER_OUTCOMES;
  const existingOutcomes = portalConfig.exitOutcomes.map((outcome, index) => ({
    key: Math.random(),
    value: outcome,
    annotation: portalConfig.xAxisAnnotations[index] ?? "",
    placeholder: false,
  }));

  return existingOutcomes.length === 0
    ? PLACEHOLDER_OUTCOMES
    : existingOutcomes;
}

export const PLACEHOLDER_OUTCOMES: ExitOutcomeWithAnnotation[] = [
  {
    key: Math.random(),
    value: 1,
    annotation: "",
    placeholderText: "Ex: 1 year ago",
    placeholder: true,
  },
  {
    key: Math.random(),
    value: 10,
    annotation: "",
    placeholderText: "Ex: Current price",
    placeholder: true,
  },
  {
    key: Math.random(),
    value: 50,
    annotation: "",
    placeholderText: "Ex: Target 2024",
    placeholder: true,
  },
];

PortalConfig.fragments = {
  portalConfig: gql`
    ${EquityOutcomesForm.fragments.portalConfig}
    fragment PortalConfig_portalConfig on PortalConfig {
      id
      exitOutcomes
      defaultExitOutcome
      xAxisAnnotations
      valuationCurrencyCode
      ...EquityOutcomesForm_portalConfig
    }
  `,
  organization: gql`
    ${CompensationBandsForm.fragments.organization}
    fragment PortalConfig_permissionSettings on Organization {
      id
      name
      ...CompensationBandsForm_permissionSettings
    }
  `,
};

const generateErrorMessage = (
  outcomes: ExitOutcomeWithAnnotation[],
  defaultExitOutcome: number | null
): string => {
  let error = "";
  if (
    outcomes.every((outcome) => outcome.value === 0) &&
    defaultExitOutcome === null
  ) {
    return error;
  }
  if (
    outcomes.some(
      (outcome) =>
        outcome.annotation.length > LABEL_CHARACTER_LIMIT &&
        outcome.placeholder !== true
    )
  ) {
    error =
      "To submit Equity Outcomes, please keep labels to 30 characters or less.";
  } else if (outcomes.length < 3) {
    error =
      "To submit Equity Outcomes, please input at least three equity values.";
  } else if (
    outcomes.some(
      (outcome) =>
        outcome.annotation.length === 0 || outcome.placeholder === true
    )
  ) {
    error =
      "To submit Equity Outcomes, input a value and a milestone label for each equity outcome.";
  } else if (defaultExitOutcome === null) {
    error = "To submit Equity Outcomes, please select a default equity value.";
  }
  return error;
};
