import { gql } from "@apollo/client";
import { getIllustrativeUnitPrice } from "../../../models/Equity";
import {
  calculateEquityFromVestEvents,
  employeeCompanyHasEquitySetup,
  employeeHasEquity,
  filterVestEventsForNext12Months,
} from "../../../models/EquityVestEvent";
import { EquityCard_equityHoldings as EquityHoldings } from "../../../__generated__/graphql";
import { CompCard } from "../../Cards/CompCard";
import { useCurrencies } from "../../CurrenciesContext";
import { useTotalEquitySlider } from "../../TotalEquitySlider/TotalEquitySliderContext";

type Props = {
  equityHoldings: EquityHoldings;
};

export function EquityCard({ equityHoldings }: Props): JSX.Element | null {
  const { currencies } = useCurrencies();
  const equitySliderData = useTotalEquitySlider();
  const sharePriceTarget = equitySliderData?.value;

  const hasEquity =
    employeeHasEquity(equityHoldings) &&
    employeeCompanyHasEquitySetup(
      sharePriceTarget ?? null,
      getIllustrativeUnitPrice(
        currencies,
        equityHoldings.grants,
        equityHoldings.valuationCurrency
      )
    );

  if (!hasEquity) return null;

  const equityEventsOfNext12Months = filterVestEventsForNext12Months(
    equityHoldings.vestingInformation?.vestEvents ?? []
  );

  const equity = calculateEquityFromVestEvents(
    currencies,
    sharePriceTarget,
    equityEventsOfNext12Months,
    equityHoldings.valuationCurrency
  );

  const value =
    equityEventsOfNext12Months.length === 0 || equity.value == null
      ? "No Data"
      : equity.value;

  return (
    <CompCard
      label="Equity"
      value={value}
      description="Next 12 months of vesting"
    />
  );
}

EquityCard.fragments = {
  equityHoldings: gql`
    fragment EquityCard_equityHoldings on EquityHoldings {
      id
      valuationCurrency {
        id
        code
        exchangeRate
      }
      grants {
        id
        sharePrice
      }
      vestingInformation {
        id
        vestEvents {
          id
          vestingDate
          unitCount
          grant {
            id
            sharePrice
          }
        }
      }
    }
  `,
};
