import { gql } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { makeStyles } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import { CalendarIcon } from "src/components/AssembleIcons/Brand/CalendarIcon";
import { ClockIcon } from "src/components/AssembleIcons/Brand/ClockIcon";
import { PositionIcon } from "src/components/AssembleIcons/Brand/PositionIcon";
import { useAuth } from "src/components/Auth/AuthContext";
import { useCompStructure } from "src/components/CompStructureContext";
import { ComplexToggle } from "src/components/ComplexToggle";
import { useFeatureFlags } from "src/components/FeatureContext";
import { Paginator } from "src/components/Pagination/Paginator";
import { SearchBar } from "src/components/Search/SearchBar";
import { CompRecAction } from "src/models/CompRecommendation";
import {
  CondensedTableHeader_compCycle2,
  Noun,
} from "../../../../__generated__/graphql";
import { EmployeeRecsCsvExportWrapper } from "../../../../components/CsvExport/EmployeeRecsExportWrapper";
import { ExplanatoryTooltip } from "../../../../components/ExplanatoryTooltip";
import { TableHeaderBar } from "../../../../components/Table/TableHeaderBar";
import { ActionToggle } from "../ActionToggle";
import { usePagination } from "../Contexts/PaginationContext";
import { useTableFilterContext } from "../Contexts/TableFilterContext";
import CompCycleColumnOrder from "./CompCycleColumnOrder";
import {
  CondensedTableCurrencyPicker,
  CurrencyCodeWithAll,
} from "./CondensedTableCurrencyPicker";
import CondensedTableStatusSelect2 from "./CondensedTableStatusSelect2";

const useStyles = makeStyles((theme) => ({
  searchBar: { width: "16rem" },
  currencyPicker: { width: "13rem" },
  slotLeftContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: "1rem",
  },
  slotRightContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: ".75rem",
  },
  slotCenterContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: ".75rem",
  },
  positionTypeToggle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: theme.spacing(0.5),
  },
}));

type Props = {
  onSearchTermChange: (value: string | null) => unknown;
  selectedCurrency: CurrencyCodeWithAll;
  onChangeSelectedCurrency: (currency: CurrencyCodeWithAll) => void;
  nameSearch: string | null;
  compCycle: CondensedTableHeader_compCycle2;
  actionToggle: CompRecAction;
  setActionToggle: Dispatch<SetStateAction<CompRecAction>>;
};

export function CondensedTableHeader2({
  onSearchTermChange,
  selectedCurrency,
  onChangeSelectedCurrency,
  nameSearch,
  compCycle,
  actionToggle,
  setActionToggle,
}: Props): JSX.Element {
  const classes = useStyles();
  const { permissions } = useAuth();
  const { filter, handleFilterChange } = useTableFilterContext();
  const { pageSize, setCurrentPage, currentPage, totalCount, setPageSize } =
    usePagination();
  const { isEnabled } = useFeatureFlags();
  const { compStructure } = useCompStructure();
  const limitedSeqApprovalPermissions =
    !permissions.canViewGlobal(Noun.Employee) && compCycle.phases.length > 0;
  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const placeholder = `Search ${totalCount} employees`;

  const selectedPositionType = filter.positionType ?? "";

  const showHourlyFilter = Boolean(
    isEnabled(FeatureFlag.HourlyEmployeesInCompCycles) &&
      compStructure?.allowHourlyEmployees
  );

  return (
    <TableHeaderBar
      slotLeft={
        <div className={classes.slotLeftContainer}>
          <SearchBar
            defaultValue={nameSearch}
            handleOnBlur={onSearchTermChange}
            placeholder={placeholder}
            classes={{ input: classes.searchBar }}
            disabled={totalCount === 0}
          />
          {!limitedSeqApprovalPermissions && (
            <CondensedTableStatusSelect2 compCycle={compCycle} />
          )}
        </div>
      }
      slotCenter={
        <div className={classes.slotCenterContainer}>
          {showHourlyFilter && (
            <div>
              <ComplexToggle
                options={[
                  [
                    "",
                    <span className={classes.positionTypeToggle} key="all">
                      <PositionIcon key="cash" inherit /> All
                    </span>,
                  ],
                  [
                    "HOURLY",
                    <span className={classes.positionTypeToggle} key="hourly">
                      <ClockIcon key="hourly" inherit /> Hourly
                    </span>,
                  ],
                  [
                    "ANNUAL",
                    <span className={classes.positionTypeToggle} key="annual">
                      <CalendarIcon key="annual" inherit /> Annual
                    </span>,
                  ],
                ]}
                selected={selectedPositionType}
                onChange={(value) =>
                  handleFilterChange("positionType", [value])
                }
              />
            </div>
          )}
          {limitedSeqApprovalPermissions && (
            <div>
              <ActionToggle
                actionToggle={actionToggle}
                setActionToggle={setActionToggle}
              />
            </div>
          )}
          {isEnabled(FeatureFlag.ClientPlanTable030124) ? (
            <div>
              <Paginator
                items={totalCount}
                pageSize={pageSize}
                page={currentPage}
                setPage={handleChangePage}
                setPageSize={setPageSize}
                compact={showHourlyFilter}
                includePageSizeSelector={
                  !(showHourlyFilter && limitedSeqApprovalPermissions)
                }
                includeAll
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      }
      slotRight={
        <div className={classes.slotRightContainer}>
          <div className={classes.currencyPicker}>
            <CondensedTableCurrencyPicker
              selectedCurrency={selectedCurrency}
              onChangeSelectedCurrency={onChangeSelectedCurrency}
            />
          </div>
          <ExplanatoryTooltip
            title="Export the current employees' changes"
            body="This generates a CSV with employees' current comp recommendations in their local currency."
          >
            <span>
              <EmployeeRecsCsvExportWrapper
                compCycleId={compCycle.id}
                compCycleName={compCycle.name}
                variant="tableButton"
              />
            </span>
          </ExplanatoryTooltip>
          <CompCycleColumnOrder hasPhases={compCycle.phases.length > 0} />
        </div>
      }
    />
  );
}

CondensedTableHeader2.fragments = {
  compCycle2: gql`
    ${CondensedTableStatusSelect2.fragments.compCycle}
    fragment CondensedTableHeader_compCycle2 on CompCycle2 {
      id
      name
      ...CondensedTableStatusSelect_compCycle
    }
  `,
};
