import { CurrencyCode, EquityBandName } from "@asmbl/shared/constants";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import {
  Money,
  moneyComparator,
  percentageOfSalaryToCash,
  ratio,
} from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { Box, Fade, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import clsx from "clsx";
import { memo } from "react";
import { getSimpleCashLabel } from "../../models/Currency";
import { OfferDataValue } from "../../models/Offer";
import { GRAY_4, GRAY_9, WHITE } from "../../theme";
import { BandUnit } from "../../__generated__/graphql";
import { useCurrencies } from "../CurrenciesContext";
import { BandName } from "../OfferGeneration/types";

const useStyles = makeStyles(() => ({
  band: {
    background: GRAY_4,
    borderRadius: "2px",
    display: "flex",
    flexDirection: "row",
    height: "5px",
    position: "relative",
    width: "100%",
  },
  singleBand: {
    background: GRAY_9,
  },
  bandMidPoints: {
    background: WHITE,
    border: "none",
    borderRadius: "100%",
    boxShadow: "none",
    cursor: "pointer",
    height: "3px",
    width: "3px",
    position: "relative",
    top: "50%",
    transform: "translate(-50%, -50%)",

    "&::before, &::after": {
      backgroundColor: GRAY_9,
      bottom: "100%",
      content: '""',
      height: "10px",
      left: "50%",
      opacity: 0.6,
      position: "absolute",
      transform: "translateX(-50%)",
      width: "1px",
    },

    "&::after": {
      top: "100%",
    },
  },
  dot: {
    backgroundColor: WHITE,
    border: `1px solid ${GRAY_4}`,
    borderRadius: "9px",
    boxShadow: "0px 1px 2px rgba(10, 36, 64, 0.2)",
    height: "9px",
    marginLeft: "-4px",
    marginTop: "-8px",
    position: "relative",
    width: "9px",
  },
  icon: {
    fontSize: "9px",
    height: "9px",
    left: -1,
    position: "absolute",
    top: -1,
    width: "9px",
  },
  line: {
    background: GRAY_9,
    height: "100%",
    position: "absolute",
    top: 0,
    transition: "all 250ms ease",
    width: "1px",
    zIndex: 10,
  },
  offset: {
    height: "100%",
    position: "relative",
    width: "1px",
  },
  captionValue: {
    color: GRAY_4,
  },
  captionTitle: {
    color: GRAY_4,
    fontWeight: 700,
    textTransform: "uppercase",
  },
}));

/**
 * @param value - if this is undefined, we won't show the comp bubble on the
 * slider
 * @param bandPoints - band points for the given band, (can be a single point)
 * any points in between will be displayed with a tooltip.
 * @param salary - Salary used to calculate the percentages.
 * @param showPercentage - Identifies whether we want the slider to show what
 * the percentage of salary the min and max points are.
 * @param showUnits - If `equityUnits` is passed in, and is greater than length
 * 0, it will display these values on the top of the band.
 * @param equityUnits - The equivalent unit values to what is contained in
 * `bandPoints`. Ideally should be the same length as `bandPoints`.
 */
export type CompSliderProps = {
  bandName: BandName;
  value: OfferDataValue;
  bandPoints: Money[];
  salary: Money<CurrencyCode> | undefined;
  equityUnits?: { value: number }[];
  totalEquityValue?: Money<CurrencyCode>;
  showPercentage?: boolean;
  showUnits?: boolean;
  showCurrentEquityValue?: boolean;
};

export const FormInputSlider = memo(function CompSlider({
  bandName,
  value,
  bandPoints,
  salary,
  equityUnits,
  totalEquityValue,
  showPercentage = false,
  showUnits = false,
  showCurrentEquityValue = true,
}: CompSliderProps) {
  const classes = useStyles();
  const { currencies } = useCurrencies();

  const sortedBandPoints = bandPoints.slice().sort(moneyComparator);

  const min = sortedBandPoints[0];
  const max = sortedBandPoints[sortedBandPoints.length - 1];
  const midPoints = sortedBandPoints.slice(1, -1);
  const isSingularityBand = bandPoints.length === 1;
  const sortedUnits = equityUnits?.sort((a, b) => a.value - b.value) ?? [];
  const unitsMidPoints = sortedUnits.slice(1, -1);

  const isEquityBand = Object.values(EquityBandName).includes(
    bandName as EquityBandName
  );
  const showCashValues = !isEquityBand || showCurrentEquityValue;
  const showPercentageOfSalary =
    salary !== undefined &&
    salary.value > 0 &&
    showPercentage &&
    showCashValues;

  return (
    <>
      <Box
        className={clsx(classes.band, {
          [classes.singleBand]: isSingularityBand,
        })}
      >
        {midPoints.map((midPoint: Money, index) => (
          <Box
            key={midPoint.value}
            className={classes.offset}
            style={{ left: `${getOffset(midPoint, min, max)}%` }}
          >
            <Tooltip
              title={
                isEquityBand
                  ? getEquityMidpointLabel(
                      midPoint,
                      unitsMidPoints[index].value,
                      showCurrentEquityValue
                    )
                  : getSimpleCashLabel(midPoint)
              }
              placement="top"
            >
              <div className={classes.bandMidPoints} />
            </Tooltip>
          </Box>
        ))}

        {value !== undefined &&
          value.mode === BandUnit.CASH &&
          value.value !== undefined && (
            <Fade in={Boolean(value.value)}>
              <Box
                className={classes.line}
                style={{
                  left: `${getOffset(value.value, min, max)}%`,
                }}
              >
                <Box className={classes.dot}>
                  {value.value.value > max.value && (
                    <Add className={classes.icon} />
                  )}
                  {value.value.value < min.value && (
                    <Remove className={classes.icon} />
                  )}
                </Box>
              </Box>
            </Fade>
          )}

        {salary !== undefined &&
          value !== undefined &&
          value.mode === BandUnit.PERCENTAGE && (
            <Fade in={Boolean(value.value)}>
              <Box
                className={classes.line}
                style={{
                  left: `${getOffset(
                    percentageOfSalaryToCash(salary, value.value as number),
                    min,
                    max
                  )}%`,
                }}
              >
                <Box className={classes.dot}>
                  {percentageOfSalaryToCash(salary, value.value as number)
                    .value > max.value && <Add className={classes.icon} />}
                  {percentageOfSalaryToCash(salary, value.value as number)
                    .value < min.value && <Remove className={classes.icon} />}
                </Box>
              </Box>
            </Fade>
          )}

        {value !== undefined &&
          value.mode === BandUnit.UNITS &&
          value.value !== undefined &&
          totalEquityValue !== undefined && (
            <Fade in={Boolean(value.value)}>
              <Box
                className={classes.line}
                style={{ left: `${getOffset(totalEquityValue, min, max)}%` }}
              >
                <Box className={classes.dot}>
                  {totalEquityValue.value > max.value && (
                    <Add className={classes.icon} />
                  )}
                  {totalEquityValue.value < min.value && (
                    <Remove className={classes.icon} />
                  )}
                </Box>
              </Box>
            </Fade>
          )}
      </Box>

      {showCashValues && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={isSingularityBand ? "center" : "space-between"}
        >
          <Typography variant="caption" className={classes.captionValue}>
            {getSimpleCashLabel(min)}
          </Typography>

          {!isSingularityBand && (
            <Typography variant="caption" className={classes.captionValue}>
              {getSimpleCashLabel(max)}
            </Typography>
          )}
        </Box>
      )}

      {showUnits && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={isSingularityBand ? "center" : "space-between"}
        >
          <Typography variant="caption" className={classes.captionValue}>
            {formatNumeral(sortedUnits[0].value, { maximumFractionDigits: 0 })}
          </Typography>
          <Typography variant="caption" className={classes.captionTitle}>
            &nbsp;Units
          </Typography>
          {!isSingularityBand && (
            <Typography variant="caption" className={classes.captionValue}>
              {formatNumeral(sortedUnits[sortedUnits.length - 1].value, {
                maximumFractionDigits: 0,
              })}
            </Typography>
          )}
        </Box>
      )}

      {showPercentageOfSalary && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={isSingularityBand ? "center" : "space-between"}
        >
          <Typography variant="caption" className={classes.captionValue}>
            {calculatePercent(salary, min, currencies)}
          </Typography>
          <Typography variant="caption" className={classes.captionTitle}>
            &nbsp;% of salary
          </Typography>
          {!isSingularityBand && (
            <Typography variant="caption" className={classes.captionValue}>
              {calculatePercent(salary, max, currencies)}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
});

function getOffset(value: Money, minValue: Money, maxValue: Money): number {
  const [selection, min, max] = [value.value, minValue.value, maxValue.value];

  // if the min & max values are the same then we just want the offset to be in
  // the center
  if (min === max) return 50;

  const offset = ((selection - min) / (max - min)) * 100;

  if (offset > 100) {
    return 100;
  } else if (offset < 0) {
    return 0;
  }

  return offset;
}

function calculatePercent(
  salary: Money,
  absolute: Money,
  currencies: Map<CurrencyCode, Currency>
): string {
  const fromCurrency = currencies.get(absolute.currency);
  const toCurrency = currencies.get(salary.currency);
  let exchangedAbsolute: Money | null = null;

  if (absolute.currency === salary.currency) {
    exchangedAbsolute = absolute;
  } else if (fromCurrency && toCurrency) {
    exchangedAbsolute = exchangeFromTo(absolute, fromCurrency, toCurrency);
  }

  if (!exchangedAbsolute) {
    return "-";
  }
  return formatNumeral(ratio(exchangedAbsolute, salary), {
    style: "percent",
    maximumFractionDigits: 2,
  });
}

function getEquityMidpointLabel(
  cash: Money,
  units: number,
  showCurrentEquityValue: boolean
) {
  const formattedUnits = formatNumeral(units, { maximumFractionDigits: 0 });
  return showCurrentEquityValue
    ? `${getSimpleCashLabel(cash)} (${formattedUnits} units)`
    : `${formattedUnits} units`;
}
