import { gql } from "@apollo/client";
import { Currency } from "@asmbl/shared/currency";
import { formatCurrency } from "@asmbl/shared/money";
import { makeStyles, ThemeProvider } from "@material-ui/core";
import { ApexOptions } from "apexcharts";
import fastDeepEqual from "fast-deep-equal";
import { memo, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import ReactDOMServer from "react-dom/server";
import { BandVisualization_position } from "../../__generated__/graphql";
import {
  ADJUSTED_CASH_BAND_FIELDS,
  ADJUSTED_EQUITY_BAND_FIELDS,
} from "../../fragments";
import { computeSeries, totalCompBand } from "../../models/Band";
import { useExcludedBandNames } from "../../pages/LadderDetail/ExcludedBandNameContext";
import {
  APP_FONTS,
  DV_ORANGE,
  DV_PINK,
  DV_YELLOW,
  GRAY_6,
  theme,
  TRANSPARENT,
  WHITE,
} from "../../theme";
import { Range } from "../../utils";
import { CompensationEmptyWarning } from "../EmptyState/CompensationEmptyWarning";
import { BandVisualizationTooltip } from "./BandVisualizationTooltip";

const useStyles = makeStyles(() => ({
  container: {
    overflow: "hidden", // Fixes height issue with ApexChart in scrolling table
    position: "relative",
  },
}));

export interface BandVisualizationProps {
  position: BandVisualization_position;
  totalCompRange: Range;
  height?: number;
  color?: string;
  selectedCurrency: Currency;
  hourly: boolean;
}

export default function BandVisualization({
  position,
  totalCompRange,
  height,
  color,
  selectedCurrency,
  hourly,
}: BandVisualizationProps): JSX.Element {
  const { excludedBandNames } = useExcludedBandNames();
  const hasCashBands = (position.adjustedCashBands?.length ?? 0) > 0;

  const tooltipHtml = useMemo(
    () =>
      ReactDOMServer.renderToString(
        <ThemeProvider theme={theme}>
          <BandVisualizationTooltip
            position={position}
            excludedBandNames={excludedBandNames}
            hourly={hourly}
          />
        </ThemeProvider>
      ),
    [position, excludedBandNames, hourly]
  );

  const totalCompSegment = (totalCompRange.max - totalCompRange.min) / 100;
  const series = useMemo(
    () => [
      {
        data: [
          {
            x: "Total",
            y: computeSeries(
              totalCompBand(position, excludedBandNames),
              totalCompSegment,
              hourly
            ),
          },
        ],
      },
    ],
    [excludedBandNames, hourly, position, totalCompSegment]
  );

  const fill = useMemo<ApexFill>(() => {
    if (color !== undefined) {
      return {
        type: "solid",
        colors: [color],
      };
    }

    return {
      type: "gradient",
      gradient: {
        colorStops: [
          { offset: 0, color: DV_YELLOW },
          { offset: 50, color: DV_ORANGE },
          { offset: 100, color: DV_PINK },
        ],
      },
    } as ApexFill;
  }, [color]);

  const chartOptions = useMemo<ApexOptions>(
    () => ({
      chart: {
        toolbar: { show: false },
        parentHeightOffset: 0,
        fontFamily: APP_FONTS,
        background: WHITE,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "18px", // renders as 24px
          borderRadius: 2,
          borderRadiusApplication: "around",
        },
      },
      tooltip: {
        custom: () => tooltipHtml,
      },
      xaxis: {
        labels: {
          offsetX: 18,
          formatter: function (value: string) {
            return formatCurrency(
              { value: Number(value), currency: selectedCurrency.code },
              {
                notation: "compact",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }
            );
          },
          style: {
            colors: TRANSPARENT, // Hack to fix label positioning when empty
            fontSize: "11px",
            fontWeight: 700,
          },
        },
        min: totalCompRange.min,
        max: totalCompRange.max,
        position: "top",
        axisBorder: { show: false },
        axisTicks: { show: false },
        tickAmount: 30,
        floating: true,
      },
      yaxis: { show: false },
      grid: {
        show: true,
        strokeDashArray: 2,
        xaxis: {
          lines: { show: true },
        },
        yaxis: {
          lines: { show: false },
        },
        borderColor: GRAY_6,
        padding: {
          top: -12,
          right: -8,
          bottom: -30,
          left: -16,
        },
      },
      fill,
    }),
    [totalCompRange, fill, tooltipHtml, selectedCurrency]
  );

  return (
    <MemoChart
      key={selectedCurrency.code}
      empty={!hasCashBands}
      noAccess={
        position.adjustedCashBands === null &&
        position.adjustedEquityBands === null
      }
      height={height}
      options={chartOptions}
      series={series}
    />
  );
}

BandVisualization.fragments = {
  position: gql`
    ${BandVisualizationTooltip.fragments.position}
    ${ADJUSTED_CASH_BAND_FIELDS}
    ${ADJUSTED_EQUITY_BAND_FIELDS}
    fragment BandVisualization_position on Position {
      ...BandVisualizationTooltip_position
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedCashBandFields
      }
      adjustedEquityBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedEquityBandFields
      }
    }
  `,
};

type ChartProps = {
  empty: boolean;
  noAccess: boolean;
  height?: number;
  options: ApexOptions;
  series: ApexAxisChartSeries;
};

const MemoChart = memo(function MemoChart({
  options,
  series,
  height = 130,
  empty,
  noAccess,
}: ChartProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ReactApexChart
        options={options}
        series={series}
        type="rangeBar"
        height={height}
      />

      {empty && <CompensationEmptyWarning noAccess={noAccess} />}
    </div>
  );
}, chartPropsAreEqual);

function chartPropsAreEqual(
  prevProps: ChartProps,
  curProps: ChartProps
): boolean {
  if (!fastDeepEqual(prevProps.series, curProps.series)) return false;
  if (!fastDeepEqual(prevProps.options, curProps.options)) return false;
  return true;
}
