import { getFormattedMonthAndDay } from "@asmbl/shared/time";
import { Fab, IconButton, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { useIntercom } from "react-use-intercom";
import { useTrack } from "src/analytics";
import { PlusIcon } from "src/components/AssembleIcons/Brand/PlusIcon";
import { EditIcon } from "src/components/AssembleIcons/Small/EditIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import {
  CompCycleDataChangeHandler,
  CompCyclePhaseData,
} from "src/components/CompCycle/CompCycleWizard/types";
import {
  SelectionMode,
  useCompCycleOrganizationChartSelectionData,
  useCompCycleOrganizationChartViewsData,
} from "src/components/CompCycleOrganizationChart/CompCycleOrganizationChartContext";
import { GRAY_1, GRAY_4, GRAY_6, GRAY_7, PURPLE_1, WHITE } from "src/theme";
import { EditPhaseModal } from "./EditPhaseModal";

const useStyles = makeStyles((theme) => ({
  phaseCard: {
    position: "relative",

    display: "flex",
    height: theme.spacing(12),
    width: theme.spacing(19),
    flexDirection: "column",
    padding: theme.spacing(1.5, 2, 2),
    gap: theme.spacing(2),
    flexShrink: 0,
    borderRadius: "8px",
    border: `1px solid ${GRAY_6}`,
  },
  hovering: {
    "&:hover": {
      border: `1px solid ${PURPLE_1}`,
    },
  },
  uneditable: {
    backgroundColor: GRAY_7,
  },
  editing: { border: `1px solid ${PURPLE_1}` },
  phaseCardHeader: {
    color: GRAY_1,
    display: "flex",
    justifyContent: "space-between",
  },
  phaseDateText: {
    height: theme.spacing(3), // match the icon height to prevent div jumping
  },
  cardContentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  cardContentItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  icon: { padding: "0" },
  button: {
    position: "absolute",
    top: -18,
    right: -14,
    transform: "rotate(45deg)",
    height: theme.spacing(4.5),
    width: theme.spacing(4.5),
    background: WHITE,
    boxShadow: "none",
    border: `1px solid ${GRAY_6}`,

    "&:hover": {
      background: WHITE,
      border: `1px solid ${PURPLE_1}`,
    },
  },
}));

type Props = {
  phases: CompCyclePhaseData[];
  phase: CompCyclePhaseData;
  previousPhase: CompCyclePhaseData | undefined;
  nextPhase: CompCyclePhaseData | undefined;
  handleChange: CompCycleDataChangeHandler;
  isSettingsView?: boolean;
};

export function PhaseSidebarCard({
  phases,
  phase,
  previousPhase,
  nextPhase,
  handleChange,
  isSettingsView = false,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const intercom = useIntercom();

  const classes = useStyles();
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const { chartState, setChartState, setPageEditState } =
    useCompCycleOrganizationChartViewsData();
  const { selectionMode, selectedEmployees, clearChartSelections } =
    useCompCycleOrganizationChartSelectionData();
  const editing = chartState === "edit" && nextPhase == null;
  const phaseHasEnded =
    new Date(phase.startDate).getTime() < new Date().getTime();
  const cannotEditPhase = isSettingsView && phaseHasEnded;
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const closeModal = () => setEditModalOpen(false);

  const handlePhaseDelete = () => {
    trackEvent({
      object: "Exit Phase Creation Button",
      action: "Clicked",
    });

    intercom.trackEvent("Exit Phase Creation Button Clicked");

    clearChartSelections();
    const updatedPhases = [...phases]
      .filter((p) => p.phaseOrder !== phase.phaseOrder)
      .sort((a, b) => a.phaseOrder - b.phaseOrder)
      .map((phase, index) => ({ ...phase, phaseOrder: index + 1 }));

    handleChange("phasesData", updatedPhases);
    setPageEditState(true);
    setChartState("view");
  };

  const editingThisPhase =
    phases.at(-1)?.phaseOrder === phase.phaseOrder && editing;

  const assignedDisplayNumber =
    editingThisPhase && selectionMode === SelectionMode.Managers
      ? selectedEmployees.size
      : phase.assigneeIds.length;

  const modalKey = phases.map((p) => p.phaseOrder).join("");

  return (
    <>
      {isEditModalOpen && (
        <EditPhaseModal
          key={modalKey}
          phases={phases}
          phase={phase}
          previousPhase={previousPhase}
          nextPhase={nextPhase}
          handleChange={handleChange}
          closeModal={closeModal}
          isSettingsView={isSettingsView}
        />
      )}
      <div
        className={clsx(classes.phaseCard, {
          [classes.editing]: editing,
          [classes.hovering]: isHovering && !cannotEditPhase,
          [classes.uneditable]: cannotEditPhase,
        })}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseOut}
        data-intercom-target={"phase-card"}
        data-cy={`editable-${cannotEditPhase ? "false" : "true"}`}
      >
        {editingThisPhase && (
          <Fab
            classes={{ root: classes.button }}
            centerRipple={false}
            onClick={handlePhaseDelete}
            size="small"
          >
            <PlusIcon color={PURPLE_1} height="36px" width="36px" />
          </Fab>
        )}
        <div className={classes.phaseCardHeader}>
          <AssembleTypography variant="productSmall" textColor={GRAY_1}>
            Phase {phase.phaseOrder}
          </AssembleTypography>

          {(cannotEditPhase || !isHovering) && (
            <AssembleTypography
              variant="productSmaller"
              textColor={GRAY_4}
              className={classes.phaseDateText}
            >
              {getFormattedMonthAndDay(new Date(phase.startDate))}
            </AssembleTypography>
          )}
          {isHovering && chartState !== "edit" && !cannotEditPhase && (
            <IconButton
              className={classes.icon}
              onClick={() => setEditModalOpen(true)}
              data-cy="edit-phase-button"
            >
              <EditIcon inherit />
            </IconButton>
          )}
        </div>
        <div className={classes.cardContentContainer}>
          {selectionMode === SelectionMode.Layers && (
            <div className={classes.cardContentItem}>
              <AssembleTypography
                variant="productEyebrowSmall"
                textColor={GRAY_4}
              >
                Layers
              </AssembleTypography>
              <AssembleTypography
                variant="productEyebrowSmall"
                textColor={GRAY_4}
              >
                {phase.layers.join(",")}
              </AssembleTypography>
            </div>
          )}

          <div className={classes.cardContentItem}>
            <AssembleTypography
              variant="productEyebrowSmall"
              textColor={GRAY_4}
            >
              People
            </AssembleTypography>
            <AssembleTypography
              variant="productEyebrowSmall"
              textColor={GRAY_4}
            >
              {assignedDisplayNumber}
            </AssembleTypography>
          </div>
        </div>
      </div>
    </>
  );
}

// If there is a next phase, set the max available phase start date to the day before
export function getCalendarMaxDate(
  currentPhaseDate: GraphQL_DateTime | undefined
): Date | undefined {
  const convertedPhaseDate =
    currentPhaseDate !== undefined ? new Date(currentPhaseDate) : undefined;
  if (convertedPhaseDate === undefined) {
    return undefined;
  }

  const prevDay = new Date(convertedPhaseDate);
  prevDay.setDate(prevDay.getDate() - 1);

  return new Date(prevDay.getTime() + prevDay.getTimezoneOffset() * 60000);
}
