import { PrimaryRoleName } from "@asmbl/shared/permissions";
import * as changeCase from "change-case";
import { Avatar, Chip, makeStyles, Theme } from "@material-ui/core";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { LockIcon } from "src/components/AssembleIcons/Brand/LockIcon";
import { CompReviewIcon } from "src/components/AssembleIcons/Navigation/CompReviewIcon";
import { ExplanatoryTooltip } from "../../components/ExplanatoryTooltip";
import { UserInviteCancellationModal } from "../../components/Settings/AccessControl/UserInvite/UserInviteCancellationModal";
import { UserPermissions } from "../../models/UserPermissions";
import {
  BLUE_1,
  BLUE_3,
  DV_BLACK,
  DV_PINK,
  DV_PURPLE,
  DV_YELLOW,
  GRAY_4,
  GRAY_5,
  WHITE,
} from "../../theme";
import {
  NounScopesFields,
  TeamCompensationAccessType,
} from "../../__generated__/graphql";

interface UserAccessGrant {
  id: number;
  roleName: PrimaryRoleName;
  nounScopes: NounScopesFields;
}

interface AccessChipProps {
  userAccessGrant: UserAccessGrant | null;
  userId: number | null;
  userIsDisabled: boolean;
  teamCompensationAccess: TeamCompensationAccessType;
  userIsPending: boolean;
  email: string;
}

interface StyleProps {
  textColor: string;
  primaryColor: string;
  backgroundColor: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: "pointer",
    height: "20px",
    borderRadius: "2px",
    borderWidth: "1px",
    border: `1px solid ${GRAY_4}`,
    borderColor: ({ primaryColor }: StyleProps) => primaryColor,
    backgroundColor: ({ backgroundColor }: StyleProps) => backgroundColor,
    paddingRight: theme.spacing(0.5),

    "&:hover": {
      boxShadow: ({ primaryColor }: StyleProps) =>
        `0px 3px 11px 0px ${primaryColor}66;`,
    },
  },
  labelSmall: {
    color: ({ textColor }: StyleProps) => textColor,
    fontWeight: 500,
    lineHeight: "1.4",
    fontSize: "12px",
    paddingRight: 0,
    letterSpacing: "-0.25px",
  },
  avatar: {
    backgroundColor: WHITE,
    height: "100%",
    borderRight: `1px solid ${GRAY_5}`,
  },
  //  Need this to override Material UI styling that can't be overwritten the
  //  regular way
  outlined: {
    "& .MuiChip-avatarSmall": {
      marginLeft: 0,
    },
  },
}));

export function AccessChip({
  userAccessGrant,
  userId,
  userIsDisabled,
  teamCompensationAccess,
  userIsPending,
  email,
}: AccessChipProps): JSX.Element {
  const [
    isUserInviteCancellationBannerOpen,
    setIsUserInviteCancellationBannerOpen,
  ] = useState(false);
  const toggleUserInviteCancellationBanner = () =>
    setIsUserInviteCancellationBannerOpen(!isUserInviteCancellationBannerOpen);

  const permission = useMemo(
    () =>
      new UserPermissions(
        userAccessGrant?.roleName ?? null,
        userAccessGrant?.nounScopes ?? null,
        false
      ),
    [userAccessGrant?.nounScopes, userAccessGrant?.roleName]
  );

  const canViewCompensation =
    !userIsDisabled && permission.hasCompensationAccess();

  const label = useMemo<string>(() => {
    if (userIsDisabled || userAccessGrant == null) return "No Access";

    const teamCompAccessName = getTeamCompAccessText(
      userAccessGrant.roleName,
      teamCompensationAccess
    );

    if (
      permission.roleName !== PrimaryRoleName.FULL_ACCESS &&
      permission.hasCompensationAccess()
    ) {
      return `${getShortRoleName(
        permission.roleName
      )} + Bands ${teamCompAccessName}`;
    }

    const capitalizedRoleName =
      permission.roleName === PrimaryRoleName.HRBP
        ? permission.roleName.toUpperCase()
        : changeCase.capitalCase(permission.roleName ?? "");

    return `${capitalizedRoleName} ${teamCompAccessName}`;
  }, [userIsDisabled, userAccessGrant, teamCompensationAccess, permission]);

  const { primaryColor, backgroundColor, textColor } = getColorForRole(
    userIsDisabled,
    userIsPending,
    permission.roleName ?? null
  );

  const classes = useStyles({ primaryColor, backgroundColor, textColor });

  const userInviteCancellationModal = () => {
    toggleUserInviteCancellationBanner();
  };

  const pageLink =
    userIsPending && userAccessGrant !== null
      ? `/settings/permissions/user-invitations/${userAccessGrant.id}`
      : userId !== null
        ? `/settings/permissions/users/${userId}`
        : "";

  return (
    <>
      {isUserInviteCancellationBannerOpen && (
        <UserInviteCancellationModal
          toggleUserInviteCancellationModal={toggleUserInviteCancellationBanner}
          email={email}
        />
      )}
      <MaybeLink to={pageLink}>
        <ExplanatoryTooltip
          disabled={!userIsPending}
          placement="top"
          hideArrow
          title="Pending Invite"
          body={`This employee hasn't created an account yet.
           Click the button to revoke your invite.`}
        >
          <div
            onClick={userIsPending ? userInviteCancellationModal : undefined}
          >
            <Chip
              classes={{
                root: classes.root,
                labelSmall: classes.labelSmall,
                avatar: classes.avatar,
                outlined: classes.outlined,
              }}
              variant="outlined"
              size="small"
              label={label}
              avatar={
                <Avatar variant="square">
                  {canViewCompensation ? (
                    <CompReviewIcon color={primaryColor} />
                  ) : (
                    <LockIcon color={primaryColor} />
                  )}
                </Avatar>
              }
            />
          </div>
        </ExplanatoryTooltip>
      </MaybeLink>
    </>
  );
}

function MaybeLink(props: { to: string; children: JSX.Element }): JSX.Element {
  const { to, children } = props;

  return to === "" ? (
    children
  ) : (
    <Link to={to} style={{ textDecoration: "none" }}>
      {children}
    </Link>
  );
}

function getShortRoleName(role: PrimaryRoleName | null) {
  switch (role) {
    case PrimaryRoleName.FULL_ACCESS:
      return "Full";
    case PrimaryRoleName.HRBP:
      return "HRBP";
    case PrimaryRoleName.BASIC_VIEWER:
      return "Basic";
    case PrimaryRoleName.RECRUITER:
      return "Recruiter";
    case PrimaryRoleName.SYSTEM_ADMIN:
      return "System";
    default:
      return "Custom";
  }
}

function getColorForRole(
  isDisabled: boolean,
  isPending: boolean,
  roleName: PrimaryRoleName | null
): StyleProps {
  if (isDisabled) {
    return {
      textColor: WHITE,
      primaryColor: GRAY_4,
      backgroundColor: GRAY_4,
    };
  }

  if (isPending) {
    return {
      textColor: GRAY_5,
      primaryColor: GRAY_5,
      backgroundColor: WHITE,
    };
  }

  switch (roleName) {
    case PrimaryRoleName.FULL_ACCESS:
      return {
        primaryColor: BLUE_1,
        backgroundColor: BLUE_1,
        textColor: WHITE,
      };
    case PrimaryRoleName.BASIC_VIEWER:
      return {
        primaryColor: DV_PURPLE,
        backgroundColor: DV_PURPLE,
        textColor: WHITE,
      };
    case PrimaryRoleName.RECRUITER:
      return {
        primaryColor: DV_PINK,
        backgroundColor: DV_PINK,
        textColor: WHITE,
      };
    case PrimaryRoleName.SYSTEM_ADMIN:
      return {
        primaryColor: BLUE_3,
        backgroundColor: BLUE_3,
        textColor: WHITE,
      };
    case PrimaryRoleName.HRBP:
      return {
        primaryColor: DV_YELLOW,
        backgroundColor: DV_YELLOW,
        textColor: DV_BLACK,
      };
    default:
      return {
        primaryColor: GRAY_4,
        backgroundColor: GRAY_4,
        textColor: WHITE,
      };
  }
}

function getTeamCompAccessText(
  roleName: PrimaryRoleName | undefined,
  compAccess: TeamCompensationAccessType
): string {
  if (
    roleName === PrimaryRoleName.FULL_ACCESS ||
    roleName === PrimaryRoleName.EMPLOYEE ||
    roleName === PrimaryRoleName.SYSTEM_ADMIN
  ) {
    return "";
  }
  if (
    compAccess === TeamCompensationAccessType.INDIRECT_REPORTS_CASH_AND_EQUITY
  ) {
    return "+ All Comp";
  } else if (compAccess === TeamCompensationAccessType.INDIRECT_REPORTS_CASH) {
    return "+ Cash";
  }
  return "";
}
