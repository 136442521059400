import { gql } from "@apollo/client";
import { makeStyles, TableCell } from "@material-ui/core";
import { AssembleTypography } from "../../../components/AssembleTypography";
import { GRAY_6, GRAY_8 } from "../../../theme";
import { Range } from "../../../utils";
import { LadderDetailNoAccessCells_position } from "../../../__generated__/graphql";
import { LadderDetailBandVisualizationInformationCell } from "../BandVisualization/LadderDetailBandVisualizationInformationCell";
import { NoAccessBandVisualization } from "./NoAccessBandVisualization";

const useStyles = makeStyles(() => ({
  infoCell: { minWidth: "160px" },
  noAccessBandVisualizationCell: {
    backgroundColor: GRAY_8,
    borderLeft: `1px solid ${GRAY_6}`,
    borderRight: `1px solid ${GRAY_6}`,
  },
}));

type Props = {
  anonymizedView: boolean;
  totalCompRange: Range;
  empty: boolean;
  noAccess: boolean;
  position: LadderDetailNoAccessCells_position;
};

export function LadderDetailNoAccessCells({
  anonymizedView,
  totalCompRange,
  empty,
  noAccess,
  position,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      {anonymizedView && (
        <TableCell component="th" scope="row" align="center">
          <AssembleTypography>N/A</AssembleTypography>
        </TableCell>
      )}
      <TableCell
        className={classes.noAccessBandVisualizationCell}
        align="left"
        padding="none"
      >
        <NoAccessBandVisualization
          totalCompRange={totalCompRange}
          empty={empty}
          noAccess={noAccess}
        />
      </TableCell>
      <TableCell className={classes.infoCell} align="right" padding="none">
        <LadderDetailBandVisualizationInformationCell position={position} />
      </TableCell>
    </>
  );
}

LadderDetailNoAccessCells.fragments = {
  position: gql`
    ${LadderDetailBandVisualizationInformationCell.fragments.position}
    fragment LadderDetailNoAccessCells_position on Position {
      ...LadderDetailBandVisualizationInformationCell_position
    }
  `,
};
