import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  CostToMovePage_compStructure as CompStructure,
  CostToMovePage_department as Department,
  CostToMovePage_employee as Employee,
  CostToMovePage_market as Market,
} from "src/__generated__/graphql";
import { useTrack } from "src/analytics";
import { AssembleTypography } from "src/components/AssembleTypography";
import {
  FilterParam,
  getNumericParams,
  NumericParam,
} from "src/models/FilterParams";
import {
  ImmutableURLSearchParams,
  useURLSearchParams,
} from "src/models/URLSearchParams";
import { GRAY_1, GRAY_4, GRAY_6, WHITE } from "src/theme";
import { CorrectionCostToMoveLoadingBoundary } from "./CorrectionCostToMoveLoadingBoundary";
import { CorrectionCostToMoveTopBar } from "./CorrectionCostToMoveTopBar";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    padding: theme.spacing(2),
    height: "calc(100vh - 80px)",
  },
  colHeads: {
    display: "flex",
    justifyContent: "stretch",
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.default,
    zIndex: 1,
    marginBlock: theme.spacing(2),
    gap: theme.spacing(2),
    "& p": {
      flex: 1,
      textAlign: "center",
    },
  },
  col: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(1, 3),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: WHITE,
    borderRadius: "8px",
    border: `1px solid ${GRAY_6}`,
  },
  single: {
    flex: "1 0",
  },
  three: {
    flex: "1 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "stretch",
    gap: theme.spacing(2),
  },
  contain: {
    gap: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    justifyContent: "stretch",
    alignItems: "center",
  },
  containCard: {
    display: "flex",
    minWidth: "10rem",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  verticalSep: {
    height: theme.spacing(5),
    borderLeft: `1px dashed ${GRAY_4}`,
  },
  eyebrowText: {
    color: GRAY_4,
    textAlign: "center",
    "& b": {
      color: GRAY_1,
    },
  },
}));

type Props = {
  compStructure: CompStructure;
  managers: Employee[];
  departments: Department[];
  markets: Market[];
};

export function CorrectionCostToMovePage({
  compStructure,
  managers,
  departments,
  markets,
}: Props): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();
  const urlSearchParams = useURLSearchParams();

  const targetBandPoint =
    parseTargetBandPointFromUrl(urlSearchParams) ??
    compStructure.bandPointTypes[0];

  const handleTargetBandPointChange = (bandPoint: string) => {
    navigate(
      {
        search: urlSearchParams.set("target-band-point", bandPoint).toString(),
      },
      { replace: true }
    );
  };

  const filter = parseFilterFromUrl(urlSearchParams);

  const filterCount = Object.values(filter).filter(
    (value) => value != null
  ).length;

  const { Track, trackEvent } = useTrack({
    subArea: "CostToMove",
    filterCount,
    bandPointName: targetBandPoint,
  });

  const handleFilterChange = (field: string, values: string[] | number[]) => {
    const filterParam = fieldToFilterParam(field);
    if (filterParam === null) return;

    if (values.length === 0) {
      navigate(
        {
          search: urlSearchParams.delete(filterParam).toString(),
        },
        { replace: true }
      );
    } else {
      navigate(
        {
          search: urlSearchParams
            .set(filterParam, values.toString())
            .toString(),
        },
        { replace: true }
      );
    }
  };

  const handleFilterReset = () => {
    // Only delete filter-specific params to keep unrelated URL params intact
    navigate(
      {
        search: urlSearchParams
          .deleteMany(Object.values(FilterParam))
          .toString(),
      },
      { replace: true }
    );
  };

  useEffect(() => {
    trackEvent({
      object: "Band Placement Report",
      action: "Viewed",
      filterCount,
      bandPointName: targetBandPoint,
    });
  }, [filterCount, targetBandPoint, trackEvent]);

  return (
    <Track>
      <div className={classes.pageContainer}>
        <CorrectionCostToMoveTopBar
          compStructure={compStructure}
          managers={managers}
          departments={departments}
          markets={markets}
          filter={filter}
          setFilter={handleFilterChange}
          resetFilters={handleFilterReset}
          targetBandPoint={targetBandPoint}
          setTargetBandPoint={handleTargetBandPointChange}
        />
        <div className={classes.colHeads}>
          <div className={`${classes.col} ${classes.single}`}>
            <div className={classes.containCard}>
              <AssembleTypography
                variant="productEyebrow"
                className={classes.eyebrowText}
              >
                <b>Annual Headcount Spend</b>
              </AssembleTypography>
            </div>
          </div>
          <div className={`${classes.col} ${classes.single}`}>
            <div className={classes.containCard}>
              <AssembleTypography
                variant="productEyebrow"
                className={classes.eyebrowText}
              >
                <b>Average Compa-Ratio</b>
                <br />
                (All Active Employees)
              </AssembleTypography>
            </div>
          </div>
          <div className={`${classes.col} ${classes.single}`}>
            <div className={classes.contain}>
              <div className={classes.containCard}>
                <AssembleTypography
                  variant="productEyebrow"
                  className={classes.eyebrowText}
                >
                  <b>Population Below Bandpoint</b>
                </AssembleTypography>
              </div>
              <div className={classes.verticalSep} />
              <div className={classes.containCard}>
                <AssembleTypography
                  variant="productEyebrow"
                  className={classes.eyebrowText}
                >
                  <b>Cost to Move</b>
                  <br />
                  (To Bandpoint)
                </AssembleTypography>
              </div>
              <div className={classes.verticalSep} />
              <div className={classes.containCard}>
                <AssembleTypography
                  variant="productEyebrow"
                  className={classes.eyebrowText}
                >
                  <b>Average Compa-Ratio</b>
                  <br />
                  (Below Bandpoint)
                </AssembleTypography>
              </div>
            </div>
          </div>
        </div>
        <CorrectionCostToMoveLoadingBoundary
          filter={filter}
          targetBandPoint={targetBandPoint}
        />
      </div>
    </Track>
  );
}

export function parseTargetBandPointFromUrl(
  urlSearchParams: ImmutableURLSearchParams
): string | null | undefined {
  return urlSearchParams.get("target-band-point");
}

export function parseFilterFromUrl(
  urlSearchParams: ImmutableURLSearchParams
): Record<string, number[] | null | undefined> {
  const managerIds = parseNumericParams(
    getNumericParams(urlSearchParams, FilterParam.MANAGER)
  );
  const locationGroupIds = parseNumericParams(
    getNumericParams(urlSearchParams, FilterParam.LOCATION)
  );
  const departmentIds = parseNumericParams(
    getNumericParams(urlSearchParams, FilterParam.DEPARTMENT)
  );
  const ladderIds = parseNumericParams(
    getNumericParams(urlSearchParams, FilterParam.LADDER)
  );
  const levels = parseNumericParams(
    getNumericParams(urlSearchParams, FilterParam.LEVEL)
  );

  return {
    managerIds,
    locationGroupIds,
    departmentIds,
    ladderIds,
    levels,
  };
}

function parseNumericParams(params: Set<NumericParam>): number[] | undefined {
  return params.has("all")
    ? undefined
    : Array.from(params.values()).filter(
        // We can allow null once we support null as a valid filter option
        (p): p is number => p !== "all" && p !== "null"
      );
}

function fieldToFilterParam(field: string): FilterParam | null {
  const mapping: Record<string, FilterParam> = {
    managerIds: FilterParam.MANAGER,
    departmentIds: FilterParam.DEPARTMENT,
    ladderIds: FilterParam.LADDER,
    levels: FilterParam.LEVEL,
    locationGroupIds: FilterParam.LOCATION,
  };

  return mapping[field];
}

CorrectionCostToMovePage.fragments = {
  compStructure: gql`
    ${CorrectionCostToMoveTopBar.fragments.compStructure}
    fragment CorrectionCostToMovePage_compStructure on CompStructure {
      ...CorrectionCostToMoveTopBar_compStructure
    }
  `,
  employee: gql`
    ${CorrectionCostToMoveTopBar.fragments.employee}
    fragment CorrectionCostToMovePage_employee on Employee {
      ...CorrectionCostToMoveTopBar_employee
    }
  `,
  department: gql`
    ${CorrectionCostToMoveTopBar.fragments.department}
    fragment CorrectionCostToMovePage_department on Department {
      ...CorrectionCostToMoveTopBar_department
    }
  `,
  market: gql`
    ${CorrectionCostToMoveTopBar.fragments.market}
    fragment CorrectionCostToMovePage_market on Market {
      ...CorrectionCostToMoveTopBar_market
    }
  `,
};
