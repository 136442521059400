import { InfoIcon } from "src/components/AssembleIcons/Small/InfoIcon";
import { ExplanatoryTooltip } from "../../../../components/ExplanatoryTooltip";
import { GRAY_4 } from "../../../../theme";

type Props = { subjectName: string };

export function RequestToolTip({ subjectName }: Props): JSX.Element {
  return (
    <ExplanatoryTooltip
      title={`${subjectName}'s Requests`}
      body={`These are the amounts ${subjectName} requested for their direct reports' changes.`}
      width="23.125rem"
      placement="top"
    >
      <div>
        <InfoIcon color={GRAY_4} />
      </div>
    </ExplanatoryTooltip>
  );
}
