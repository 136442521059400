import { Box, makeStyles, Typography } from "@material-ui/core";
import { useCallback, useState } from "react";
import { CheckIcon } from "src/components/AssembleIcons/Brand/CheckIcon";
import { LadderIcon } from "src/components/AssembleIcons/Brand/LadderIcon";
import { ProfileIcon } from "src/components/AssembleIcons/Brand/ProfileIcon";
import Step from "../../components/Workflow/Step";
import { GRAY_4, GRAY_6, GREEN_2, WHITE } from "../../theme";
import UploadHRISStep from "./UploadHRIS/UploadHRISStep";
type StyleProps = {
  transitioning: boolean;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  content: {
    flex: 1,
    padding: theme.spacing(12, 5),
    position: "relative",
  },
  sidebar: {
    backgroundColor: WHITE,
    boxShadow: `1px 0px 0px ${GRAY_6}`,
    flexShrink: 0,
    height: "100%",
    left: "81px",
    marginRight: "1px",
    padding: theme.spacing(6, 2),
    position: "fixed",
    width: theme.spacing(31),
    zIndex: 10,
  },
  transition: {
    marginTop: (p: StyleProps) => (p.transitioning ? "40px" : "0px"),
    opacity: (p: StyleProps) => (p.transitioning ? 0 : 1),
    transition: (p: StyleProps) => (p.transitioning ? "none" : "all 350ms"),
  },
  stepName: {
    fontSize: ".875rem",
  },
}));

export function UploadHRIS(): JSX.Element {
  const [transitioning, setTransitioning] = useState<boolean>(false);
  const classes = useStyles({ transitioning });

  const [step, setStep] = useState<number>(0);
  const updateStep = useCallback((updatedStep: number) => {
    setTransitioning(true);

    setTimeout(() => {
      window.scrollTo(0, 0);
      setStep(updatedStep);
      setTransitioning(false);
    }, 150);
  }, []);

  return (
    <Box className={classes.container}>
      <Box className={classes.sidebar}>
        <Box mb={2} ml={2}>
          <Typography variant="overline" style={{ color: GRAY_4 }}>
            Upload HRIS Data
          </Typography>
        </Box>
        <Step
          active={step === 1}
          icon={
            step > 1 ? <CheckIcon color={GREEN_2} /> : <ProfileIcon inherit />
          }
          onClick={() => (step > 1 ? updateStep(1) : null)}
          prevStep={step > 1}
        >
          <Typography className={classes.stepName}>Employees</Typography>
        </Step>
        <Step
          active={step === 2}
          icon={
            step > 2 ? <CheckIcon color={GREEN_2} /> : <LadderIcon inherit />
          }
          onClick={() => (step > 2 ? updateStep(2) : null)}
          prevStep={step > 2}
        >
          <Typography className={classes.stepName}>
            Employment History
          </Typography>
        </Step>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.transition}>
          <UploadHRISStep step={step} updateStep={setStep} />
        </Box>
      </Box>
    </Box>
  );
}
