import {
  Money,
  add,
  divide,
  formatCurrency,
  multiply,
  zero,
} from "@asmbl/shared/money";
import { mapMaybe } from "@asmbl/shared/utils";
import {
  CashCompType,
  CompRecommendationInput,
  CompRecommendationStatus,
  CompUnit,
  CurrencyCode,
  PayPeriodType,
  RecItemInput,
  RecItemType,
  ReviewRequestStatus,
} from "../__generated__/graphql";
import { CompCycleDisplay } from "../components/CompCycle/types";
import { getPercentageChange } from "./Money";

export const CompItemRecommendationTypeTitle: Record<RecItemType, string> = {
  PROMOTION: "Promotion Adjustment",
  MARKET: "Market Adjustment",
  MERIT_INCREASE: "Merit Adjustment",
  TARGET_COMMISSION: "Target Commission",
  TARGET_RECURRING_BONUS: "Target Recurring Bonus",
  ACTUAL_RECURRING_BONUS: "Actual Recurring Bonus",
  MERIT_BONUS: "One-Time Bonus",
  EQUITY_GRANT: "New Equity",
} as const;

export enum CompRecTableHeaders {
  AVATAR = "Avatar",
  NAME_POS = "Name / Position",
  DEPT_LADDER = "Department / Ladder",
  LEVEL = "Level",
  PERF_RATING = "Performance Rating",
  TENURE = "Tenure",
  LOCATION = "Location",
  MANAGER = "Manager",
  TOTAL_COMP = "Total Cash Compensation",
  BAND = "Band Position",
  BONUS = "One-Time Bonus",
  EQUITY = "New Equity",
  SPACER = "Spacer",
}

export enum CompRecAction {
  ALL = "All",
  NEEDS_REVIEW = "Needs Review",
  REVIEWED = "Reviewed",
}

export const ReviewStatusReviewerDisplay: Record<ReviewRequestStatus, string> =
  {
    AWAITING_DEPENDENCY: "Awaiting Requests",
    AWAITING_REVIEW: "Awaiting Review",
    APPROVED: "Approved",
    DENIED: "Denied",
  };

export type CompRecommendation = Omit<
  CompRecommendationInput,
  "id" | "items"
> & {
  items: Map<RecItemType, RecItemInput>;
  reviewStatus?: CompRecommendationStatus | null;
  canICurrentlyEdit?: boolean;
};

type SubmittedCompRecommendation = {
  reviewStatus: ReviewRequestStatus | null;
  latestSubmittedItems: Item[];
};

export type Item = {
  recommendationType: RecItemType;
  recommendedCashValue?: Money | null;
  recommendedPercentValue?: number | null;
  cashEquivalent?: Money | null;
  unitType?: CompUnit | null;
};

type EmployeeReport = {
  compRecommendation: SubmittedCompRecommendation | null;
};

export function filterOutDeniedCompRecommendations(
  compRecommendations: SubmittedCompRecommendation[]
): SubmittedCompRecommendation[] {
  return compRecommendations.filter(
    (compRecommendation) =>
      compRecommendation.reviewStatus !== ReviewRequestStatus.DENIED
  );
}

export function filterForCompRecommendationType(
  submittedItems: Item[][],
  requestType: RecItemType
): Item[][] {
  return submittedItems.map((items) =>
    items.filter((item) => item.recommendationType === requestType)
  );
}

/*
 * This is a helper function that calculates the total value of the requests
 * of all the direct and indirect reports of an employee for a given request
 * type.
 */
export function calculateRequestsFromReports(
  reports: EmployeeReport[],
  requestType: RecItemType
): Money | null {
  const compRecommendations = mapMaybe(
    reports,
    (report) => report.compRecommendation
  );

  const filteredCompRecommendations =
    filterOutDeniedCompRecommendations(compRecommendations);

  const latestSubmittedItems = filteredCompRecommendations.map(
    (recommendation) => recommendation.latestSubmittedItems
  );

  const filteredSubmittedItems = filterForCompRecommendationType(
    latestSubmittedItems,
    requestType
  );

  const latestSubmittedItemOfType = filteredSubmittedItems
    .map((list) => list.pop())
    .filter((item) => item !== undefined);

  const submittedItems = mapMaybe(
    latestSubmittedItemOfType,
    (submitted) => submitted?.cashEquivalent
  );

  if (submittedItems.length === 0) return null;

  // For Budgets vs Requests, we request the recommendedCashValue all to
  // be in the org's default currency. So, these are safe to add together.
  // Also, the list is non-empty, so we can safely omit a starting value.
  return submittedItems.reduce(add);
}

export function formatRequest(requests: Money | null): string {
  return requests === null
    ? "-"
    : formatCurrency(requests, { maximumFractionDigits: 0 });
}

export function formatVariance(
  varianceDisplay: CompCycleDisplay,
  budget: Money | null,
  variance: Money | null
): string {
  if (budget === null || variance === null) {
    return "-";
  }

  if (varianceDisplay === "absolute") {
    return formatCurrency(
      Object.is(variance.value, -0) ? multiply(variance, -1) : variance,
      {
        maximumFractionDigits: 0,
      }
    );
  } else {
    return divide(
      add(
        budget,
        Object.is(variance.value, -0) ? multiply(variance, -1) : variance
      ),
      budget.value
    ).value.toLocaleString("en-US", {
      style: "percent",
      maximumFractionDigits: 2,
    });
  }
}

export function getPercentChangeInSalary(
  salaryIncrease: Money,
  currentSalary: Money
): string {
  return currentSalary.value !== 0
    ? getPercentageChange(salaryIncrease, currentSalary)
    : "0%";
}

export const getDraftSalaryIncrease = (
  draftRecommendation: CompRecommendation | null,
  defaultCurrencyCode: CurrencyCode,
  activeEmploymentType: PayPeriodType | undefined,
  workingHoursPerYear: number
): {
  annualCashEquivalent: Money;
  hourlyCashEquivalent: Money;
  unitType: CompUnit | null;
} => {
  const adjustments = mapMaybe(
    [RecItemType.MERIT_INCREASE, RecItemType.PROMOTION, RecItemType.MARKET],
    (type) => draftRecommendation?.items.get(type)
  );

  if (adjustments.length === 0) {
    return {
      annualCashEquivalent: zero(defaultCurrencyCode),
      hourlyCashEquivalent: zero(defaultCurrencyCode),
      unitType: null,
    };
  }

  const itemCurrency =
    adjustments.find((item) => item.recommendedCashValue?.currency)
      ?.recommendedCashValue?.currency ?? defaultCurrencyCode;

  let annualCashEquivalent = zero(itemCurrency);
  let hourlyCashEquivalent = zero(itemCurrency);

  adjustments.forEach((item) => {
    if (item.recommendedCashValue) {
      annualCashEquivalent = add(
        annualCashEquivalent,
        item.unitType === CompUnit.HOURLY_CASH
          ? multiply(workingHoursPerYear, item.recommendedCashValue)
          : item.recommendedCashValue
      );
      hourlyCashEquivalent = add(
        hourlyCashEquivalent,
        item.unitType === CompUnit.HOURLY_CASH
          ? item.recommendedCashValue
          : divide(item.recommendedCashValue, workingHoursPerYear)
      );
    }
  });

  const promotion = adjustments.find(
    (item) => item.recommendationType === RecItemType.PROMOTION
  );

  const employmentCompType =
    activeEmploymentType === PayPeriodType.HOURLY
      ? CompUnit.HOURLY_CASH
      : CompUnit.CASH;

  // default to the promotion unitType if it is provided
  if (promotion?.unitType != null) {
    return {
      annualCashEquivalent,
      hourlyCashEquivalent,
      unitType: promotion.unitType,
    };
  }

  // only return hourly if employment is hourly or any items are hourly
  const unitType =
    adjustments.length === 0 ||
    // user deleted all previously entered changes
    adjustments.every((item) => item.unitType == null)
      ? employmentCompType
      : adjustments.some((item) => item.unitType === CompUnit.HOURLY_CASH)
        ? CompUnit.HOURLY_CASH
        : CompUnit.CASH;

  return {
    annualCashEquivalent,
    hourlyCashEquivalent,
    unitType,
  };
};

export function getPayIncrease(
  items: Item[],
  defaultCurrency: CurrencyCode
): Money {
  const salaryItems = items.filter(
    (item) =>
      item.recommendationType === RecItemType.MERIT_INCREASE ||
      item.recommendationType === RecItemType.PROMOTION ||
      item.recommendationType === RecItemType.MARKET
  );

  const currencyCode = getItemCurrency(salaryItems) ?? defaultCurrency;

  return salaryItems
    .map((item) => item.recommendedCashValue ?? zero(currencyCode))
    .reduce(add, zero(currencyCode));
}

export function getPromotionSalary(
  items: Item[],
  defaultCurrencyCode: CurrencyCode
): Money {
  return getRecommendedCashValue(
    items,
    RecItemType.PROMOTION,
    defaultCurrencyCode
  );
}

export function getMeritBonus(
  items: Item[],
  defaultCurrencyCode: CurrencyCode
): Money {
  return getRecommendedCashValue(
    items,
    RecItemType.MERIT_BONUS,
    defaultCurrencyCode
  );
}

export function getActualRecurringBonus(
  items: Item[],
  defaultCurrencyCode: CurrencyCode
): Money {
  return getRecommendedCashValue(
    items,
    RecItemType.ACTUAL_RECURRING_BONUS,
    defaultCurrencyCode
  );
}

export function getMeritSalaryIncrease(
  items: Item[],
  defaultCurrencyCode: CurrencyCode
): Money {
  return getRecommendedCashValue(
    items,
    RecItemType.MERIT_INCREASE,
    defaultCurrencyCode
  );
}

export function getMarketSalaryIncrease(
  items: Item[],
  defaultCurrencyCode: CurrencyCode
): Money {
  return getRecommendedCashValue(
    items,
    RecItemType.MARKET,
    defaultCurrencyCode
  );
}

export function getEquityGrantValue(
  items: Item[],
  defaultCurrencyCode: CurrencyCode
): Money {
  return getRecommendedCashValue(
    items,
    RecItemType.EQUITY_GRANT,
    defaultCurrencyCode
  );
}

function getRecommendedCashValue(
  items: Item[],
  type: RecItemType,
  defaultCurrencyCode: CurrencyCode
): Money {
  const item = items.find((item) => item.recommendationType === type);
  const itemCurrency = getItemCurrency(items) ?? defaultCurrencyCode;
  return item?.recommendedCashValue ?? zero(itemCurrency);
}

export function getItemCurrency(items: Item[]): CurrencyCode | undefined {
  return mapMaybe(items, (item) => item.recommendedCashValue?.currency).at(0);
}

/*
 * A predicate function for use in `find` and `filter` clauses
 */
export const whereType =
  (recItemType: RecItemType) => (item: { recommendationType: RecItemType }) =>
    item.recommendationType === recItemType;

type Employee = {
  id: number;
  activeCashCompensation:
    | { type: CashCompType; annualCashEquivalent: Money }[]
    | null;
};
export type RecItemWithSubject = RecItemInput & { subject: Employee };

export function getCashRecItems(
  employee: Employee,
  items: RecItemInput[],
  workingHoursPerYear: number,
  salary: Money
): RecItemWithSubject[] {
  return items.map((item) =>
    getCashRecItem(item, salary, workingHoursPerYear, employee)
  );
}

/**
 *
 * @param item
 * @param salary
 * @param employee - if included, it will return the employee in the result
 *
 * @returns RecItemInput
 * Returns a cash recItem. If item is of a PERCENT_OF_SALARY unit type it gets
 * converted using the salary param. Else, it returns the recItem as is since it
 * is already a cash item.
 */
export function getCashRecItem<I extends Item>(
  item: I,
  salary: Money,
  workingHoursPerYear: number
): I;
export function getCashRecItem<I extends Item>(
  item: I,
  salary: Money,
  workingHoursPerYear: number,
  employee: Employee
): I & { subject: Employee };
export function getCashRecItem<I extends Item>(
  item: I,
  salary: Money,
  workingHoursPerYear: number,
  employee?: Employee
): I | (I & { subject: Employee }) {
  if (item.unitType === CompUnit.CASH || item.unitType == null) {
    return { ...item, subject: employee };
  }

  const itemCashValue =
    item.unitType === CompUnit.HOURLY_CASH && item.recommendedCashValue
      ? multiply(item.recommendedCashValue, workingHoursPerYear)
      : item.recommendedCashValue;

  return {
    ...item,
    unitType: CompUnit.CASH,
    recommendedCashValue: item.recommendedPercentValue
      ? multiply(salary, (item.recommendedPercentValue ?? 0) / 100)
      : itemCashValue,
    subject: employee,
  };
}

export function hasUnpublishedChanges(
  submittedValue: number | undefined,
  revisedValue: number | undefined
): boolean {
  return revisedValue !== submittedValue;
}

export function isHourlyRecItem(item: Item | null | undefined): boolean {
  return item?.unitType === CompUnit.HOURLY_CASH;
}
