import { estimatedPrice, formatCurrency, money } from "@asmbl/shared/money";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Fab,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { DeleteIcon } from "src/components/AssembleIcons/Brand/DeleteIcon";
import { PlusCircleIcon } from "src/components/AssembleIcons/Brand/PlusCircleIcon";
import OfferLetterExplanation from "../../../assets/svgs/illustrations/offer-letter-explanation.svg?react";
import { currencySymbol } from "../../../models/Currency";
import { BLUE_2, GRAY_4, GRAY_6, PURPLE_2 } from "../../../theme";
import {
  arrayWithoutItem,
  formatNonZeroOrEmptyString,
  NonNull,
} from "../../../utils";
import { GetEquitySettings } from "../../../__generated__/graphql";
import { AssembleLink } from "../../AssembleLink";
import { useCurrencies } from "../../CurrenciesContext";
import { NumberInput } from "../../Form/NumberInput";
import { OutcomeWithAnnotation } from "../IllustrativeOutcomesForm";

type Valuation = NonNull<GetEquitySettings["valuation"]>;

const useStyles = makeStyles((theme: Theme) => ({
  dismissibleDesc: {
    backgroundColor: GRAY_6,
    borderRadius: 6,
    fontWeight: 400,
  },
  explainOfferCloseButton: {
    position: "fixed",
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  illustrativeFormPair: {
    marginBottom: theme.spacing(2),
    marginRight: -theme.spacing(7),
    "&:hover": {
      "& $deleteOutcome": {
        opacity: 1,
      },
    },
  },
  unitPriceAdornment: {
    width: "80px",
    justifyContent: "flex-end",
  },
  deleteOutcome: {
    opacity: 0,
    transition: "opacity 300ms",
  },
}));

const LABEL_CHARACTER_LIMIT = 30;

type FormProps = {
  outcomes: OutcomeWithAnnotation[];
  valuation: Valuation | null;
  onChangeOutcomes: (outcomes: OutcomeWithAnnotation[]) => unknown;
};

const OutcomeAnnotationSubForm = ({
  outcomes,
  valuation,
  onChangeOutcomes,
}: FormProps): JSX.Element => {
  const showLabelLengthError = outcomes.some(
    ({ annotation }) => annotation.length > LABEL_CHARACTER_LIMIT
  );

  const canAddField = outcomes.length < 8;

  const handleAddField = () => {
    if (canAddField) {
      onChangeOutcomes([
        ...outcomes,
        { key: Math.random(), value: 0, annotation: "" },
      ]);
    }
  };

  return (
    <>
      <Typography>
        To show your candidates comparative equity values, provide{" "}
        <strong>at least 2 (up to 8)</strong> outcomes below. If you don't, your
        candidates only see numbers based on your company's{" "}
        <AssembleLink to="/settings/equity" whitespace={false}>
          current valuation
        </AssembleLink>
        .
      </Typography>
      <Box my={1} />
      <Typography>
        You can give each outcome a name (<strong>up to 30 characters</strong>)
        to explain or justify it.
      </Typography>
      <Box my={2} />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {outcomes.map((outcome, i) => (
          <IllustrativeFormPair
            key={outcome.key}
            index={i}
            outcomes={outcomes}
            valuation={valuation}
            onChangeOutcomes={onChangeOutcomes}
          />
        ))}
        {showLabelLengthError && (
          <Typography color="error" gutterBottom>
            Please keep labels to 30 characters or less. To provide additional
            context to your candidates, you can use the Additional Information
            field below.
          </Typography>
        )}
        {canAddField && (
          <Tooltip title={`Add Outcome`} placement="top">
            <Fab
              centerRipple={true}
              onClick={handleAddField}
              size="medium"
              color="primary"
            >
              <PlusCircleIcon color={BLUE_2} width={"24px"} height={"24px"} />
            </Fab>
          </Tooltip>
        )}
      </Box>
    </>
  );
};

function IllustrativeFormPair({
  index,
  outcomes,
  valuation,
  onChangeOutcomes,
}: FormProps & { index: number }) {
  const classes = useStyles();
  const { defaultCurrencyCode } = useCurrencies();

  const outcome = outcomes[index];

  const deleteOutcome = () => {
    onChangeOutcomes(arrayWithoutItem(outcomes, index));
  };

  const updateOutcome = ({
    value = outcome.value,
    annotation = outcome.annotation,
  }: {
    value?: number;
    annotation?: string;
  }) => {
    const newOutcomes = outcomes.slice();
    newOutcomes[index] = { key: outcome.key, value, annotation };
    onChangeOutcomes(newOutcomes);
  };

  return (
    <Box
      className={classes.illustrativeFormPair}
      display="flex"
      alignItems="center"
    >
      <Box flex={1} mr={2}>
        <NumberInput
          id={`exitOutcomes-${index}`}
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              {currencySymbol(
                valuation?.valuation.currency ?? defaultCurrencyCode
              )}
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment
              className={classes.unitPriceAdornment}
              position="end"
            >
              {valuation !== null
                ? formatCurrency(
                    estimatedPrice(
                      valuation,
                      money(outcome.value, valuation.valuation.currency)
                    ),
                    {
                      notation: "compact",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )
                : "Unit Price"}
            </InputAdornment>
          }
          onValueChange={({ floatValue }) => {
            updateOutcome({
              value: floatValue,
            });
          }}
          placeholder="0"
          label="Illustrative Outcome"
          value={formatNonZeroOrEmptyString(outcome.value)}
          allowNegative={false}
        />
      </Box>
      <Box flex={1} mr={1}>
        <TextField
          id={`annotations-${index}`}
          label="Chart Label (Optional)"
          onChange={(e) => {
            updateOutcome({
              annotation: e.target.value,
            });
          }}
          error={outcome.annotation.length > LABEL_CHARACTER_LIMIT}
          placeholder="FY22 Revenue"
          value={outcome.annotation}
          variant="outlined"
          fullWidth
        />
      </Box>
      <Tooltip title="Delete Outcome" placement="top">
        <IconButton className={classes.deleteOutcome} onClick={deleteOutcome}>
          <DeleteIcon
            color={GRAY_4}
            hoverColor={PURPLE_2}
            width="24px"
            height="24px"
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

const ExampleDialog = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <Box display="flex" alignItems="center" justifyContent="center">
        <OfferLetterExplanation />
      </Box>
      <DialogActions>
        <AssembleButton
          className={classes.explainOfferCloseButton}
          variant="outlined"
          onClick={handleClose}
          label="Close"
          size="medium"
        />
      </DialogActions>
    </Dialog>
  );
};

export const IllustrativeOutcomesMultiInput = ({
  outcomes,
  valuation,
  onChangeOutcomes,
}: FormProps): JSX.Element => {
  const classes = useStyles();

  const [isExampleVisible, setIsExampleVisible] = useState<boolean>(false);
  const handleExampleClose = () => setIsExampleVisible(!isExampleVisible);

  return (
    <>
      <ExampleDialog open={isExampleVisible} handleClose={handleExampleClose} />
      <Box className={classes.dismissibleDesc} py={2} px={1}>
        <Typography>
          Illustrative Outcomes help candidates better understand the value of
          their compensation package in a variety of outcomes in the future.
        </Typography>
        <Box mt={1} textAlign="right">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsExampleVisible(true)}
          >
            View an Example
          </Button>
        </Box>
      </Box>
      <Box m={2} />
      <OutcomeAnnotationSubForm
        outcomes={outcomes}
        valuation={valuation}
        onChangeOutcomes={onChangeOutcomes}
      />
    </>
  );
};
