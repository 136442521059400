import { exchangeFromTo } from "@asmbl/shared/currency";
import { add, isZero, Money, ratio, subtract, zero } from "@asmbl/shared/money";
import { ButtonBase, Link, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { RecItemWithSubject } from "src/models/CompRecommendation";
import {
  CashCompType,
  Currency,
  EquityGrantMethod,
} from "../../../__generated__/graphql";
import { useTrack } from "../../../analytics";
import { getAccurateSimpleCashLabel } from "../../../models/Currency";
import { PURPLE_1 } from "../../../theme";
import { useCompStructure } from "../../CompStructureContext";
import { useCurrencies } from "../../CurrenciesContext";
import { BudgetDetail } from "./BudgetDetail";

const useStyles = makeStyles(() => ({
  toggleButton: {
    color: PURPLE_1,
    font: "unset",
    textTransform: "inherit",
    textAlign: "left",
  },
}));

type Props = {
  budgetType: string;
  variableCompType: CashCompType.COMMISSION | CashCompType.RECURRING_BONUS;
  items: RecItemWithSubject[];
  compCycleBudget?: Money | null;
  valueUsed: Money;
  incrementalValueUsed: Money;
};

export function BudgetVariableDetail2({
  budgetType,
  variableCompType,
  items,
  compCycleBudget,
  valueUsed,
  incrementalValueUsed,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const classes = useStyles();
  const { compStructure } = useCompStructure();
  const { currencies, defaultCurrency } = useCurrencies();
  const [showTotal, setShowTotal] = useState(true);

  const toggleCash = () => {
    const wasTotal = showTotal;
    setShowTotal(!wasTotal);
    trackEvent({
      object: "Variable Comp Display in Budget Drawer",
      action: "Toggled",
      variableDisplay: wasTotal ? "total" : "incremental",
    });
  };

  const compCycleCurrency =
    (compCycleBudget && currencies.get(compCycleBudget.currency)) ??
    defaultCurrency;

  const convertedValueUsed = exchangeFromTo(
    valueUsed,
    currencies.get(valueUsed.currency) as Currency,
    compCycleCurrency
  );

  const zer0 = zero(compCycleCurrency.code);

  const incremental = items
    .filter((i) => i.recommendedCashValue != null)
    .map((i) => [
      i.recommendedCashValue ?? zer0,
      i.subject.activeCashCompensation?.find((i) => i.type === variableCompType)
        ?.annualCashEquivalent ?? zer0,
    ])
    .map(([newCash, currentCash]) =>
      isZero(newCash) || showTotal ? newCash : subtract(newCash, currentCash)
    )
    .reduce(add, zer0);

  const used = add(
    convertedValueUsed,
    exchangeFromTo(
      incremental,
      currencies.get(incremental.currency) as Currency,
      compCycleCurrency
    )
  );

  const allocated = compCycleBudget ?? null;

  const percentUsed =
    allocated === null || isZero(allocated)
      ? null
      : ratio(used, allocated) * 100;

  // we only pass items in the rec modal, so make sure things are totalled correctly
  const displayedIncremental =
    items.length === 0
      ? incrementalValueUsed
      : add(incrementalValueUsed, incremental);

  return (
    <BudgetDetail
      budgetType={budgetType}
      percentUsed={percentUsed}
      title={
        compStructure?.equityGrantMethod !== EquityGrantMethod.CASH ? (
          <Link
            component={ButtonBase}
            className={classes.toggleButton}
            onClick={toggleCash}
          >
            {budgetType} {showTotal ? "Total" : "(Incremental)"}
          </Link>
        ) : (
          `${budgetType}`
        )
      }
      hideBudgetBar={!showTotal}
      usedOfAllocated={
        showTotal && allocated
          ? `${getAccurateSimpleCashLabel(
              used
            )} of ${getAccurateSimpleCashLabel(allocated)}`
          : showTotal
            ? getAccurateSimpleCashLabel(used)
            : `${displayedIncremental.value > 0 ? "+" : ""}${getAccurateSimpleCashLabel(
                displayedIncremental
              )}`
      }
    />
  );
}
