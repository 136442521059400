import { ApexOptions } from "apexcharts";
import { useCallback, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import {
  APP_FONTS,
  DV_YELLOW,
  DV_YELLOW_LIGHT,
  GRAY_2,
  GRAY_4,
  GRAY_5,
} from "../../theme";
import { ArrayValue } from "../../utils";
import { GetEmployeeAnalyticsQuartiles } from "../../__generated__/graphql";
import { comparatioTooltipHtml } from "./ComparatioTooltip";
import { formatPercent } from "./utils";

type Quartile = ArrayValue<
  GetEmployeeAnalyticsQuartiles["employeeAnalytics"]["quartiles"]
>;

export type ComparatioChartItem = {
  label: string;
  values: number[];
  sampleSize: number;
};

type Props = {
  items: Quartile[];
  height?: string;
  loading: boolean;
};

export function ComparatioChart({
  items,
  height,
  loading,
}: Props): JSX.Element {
  const { min, max } = computeYAxisRanges(items);

  const series = useMemo(() => {
    return [
      {
        data: items.map((i) => ({
          x: i.label.toUpperCase(),
          y: i.values,
        })),
      },
    ];
  }, [items]);

  // HACK: have columns centered in the box-plot when there are only a few items
  if (series[0].data.length > 1 && series[0].data.length < 5) {
    const currentItems = series[0].data;
    const dummyData = { x: "", y: [] };
    series[0].data = [dummyData, ...currentItems, dummyData];
  }

  const generateTooltip = useCallback(
    ({ dataPointIndex }: { dataPointIndex: number }) => {
      const hasDummyData = series[0].data.length !== items.length;
      if (
        hasDummyData &&
        (dataPointIndex !== 0 || dataPointIndex !== series[0].data.length - 1)
      ) {
        return comparatioTooltipHtml(items, dataPointIndex - 1);
      } else if (!hasDummyData) {
        return comparatioTooltipHtml(items, dataPointIndex);
      }
    },
    [items, series]
  );

  const options: ApexOptions = {
    labels: items.map((i) => i.label),
    series,
    plotOptions: {
      boxPlot: {
        colors: {
          upper: DV_YELLOW,
          lower: DV_YELLOW_LIGHT,
        },
      },
      bar: {
        columnWidth: computeColumnWidth(series[0].data.length),
      },
    },
    stroke: {
      colors: [GRAY_2],
    },
    chart: {
      fontFamily: APP_FONTS,
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    grid: {
      borderColor: GRAY_5,
      strokeDashArray: 2,
      padding: {
        left: 20,
      },
    },
    noData: {
      text: loading ? undefined : "We couldn’t find data for this population",
      style: {
        fontFamily: APP_FONTS,
        color: GRAY_4,
        fontSize: "14px",
      },
    },
    fill: {
      opacity: 0.5,
    },
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false },
      tooltip: { enabled: false },
      labels: {
        style: {
          fontFamily: APP_FONTS,
          colors: GRAY_2,
          fontSize: "12px",
          fontWeight: 700,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: formatPercent,
      },
      min,
      max,
    },
    tooltip: {
      shared: false,
      intersect: true,
      followCursor: true,
      custom: generateTooltip,
    },
    states: {
      hover: {
        filter: { type: "none" },
      },
      active: {
        filter: { type: "none" },
      },
    },
  };

  return (
    <ReactApexChart
      height={height}
      options={options}
      series={options.series}
      type="boxPlot"
    />
  );
}

/**
 * Apex charts only supports percentage-based column widths, so to maintain similar column widths
 * regardless of the number of bars, we need to do some math.
 */
function computeColumnWidth(numBars: number): string {
  return (numBars * 1.9).toString();
}

/**
 * Computes the min and max on the y-axis such that all the data can fit while
 * keeping 100% in the center of the chart.
 */
function computeYAxisRanges(items: ComparatioChartItem[]) {
  let min = Infinity;
  let max = -Infinity;
  items.forEach((item) => {
    item.values.forEach((value) => {
      min = Math.min(min, value);
      max = Math.max(max, value);
    });
  });
  const maxDistance = Math.max(Math.abs(1 - min), Math.abs(max - 1));
  return {
    min: 1 - maxDistance,
    max: 1 + maxDistance,
  };
}
