import { Route, Routes } from "react-router-dom";
import { AuthenticationFormState } from "./Authentication";
import { AuthenticationLoadingBoundary } from "./AuthenticationLoadingBoundary";
import { AuthenticationSso } from "./AuthenticationSso";
import { SsoRedirect } from "./SsoRedirect";

export function AuthenticationRouter(): JSX.Element {
  return (
    <Routes>
      <Route
        path="sign-in"
        element={
          <AuthenticationLoadingBoundary
            formState={AuthenticationFormState.SignIn}
          />
        }
      />
      <Route
        path="sign-up"
        element={
          <AuthenticationLoadingBoundary
            formState={AuthenticationFormState.Register}
          />
        }
      />
      <Route
        path="forgot-password"
        element={
          <AuthenticationLoadingBoundary
            formState={AuthenticationFormState.ForgotPassword}
          />
        }
      />
      <Route path="sso/redirect" element={<SsoRedirect />} />
      <Route path="sso" element={<AuthenticationSso />} />
      <Route
        index
        element={
          <AuthenticationLoadingBoundary
            formState={AuthenticationFormState.SignIn}
          />
        }
      />
    </Routes>
  );
}
