import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { forwardRef, Ref } from "react";
import AboveBandDot from "../../assets/svgs/dataviz/above-band-dot.svg?react";
import BelowBandDot from "../../assets/svgs/dataviz/below-band-dot.svg?react";
import {
  DV_GREEN,
  DV_ORANGE,
  DV_PINK,
  DV_YELLOW,
  GRAY_3,
  GRAY_4,
  MONOSPACED_FONT,
  RED,
  WHITE,
} from "../../theme";

type Props = {
  altValue?: number;
  value: number;
  min: number;
  max: number;
  outOfRangeStyle: "circle" | "band";
  color?: "default" | "gray";
};

type HourlyBandPointProps = {
  altValue?: number;
  value: number;
  bandPointValue: number;
  color?: "default" | "gray";
};

type StyleProps = {
  outOfRangeStyle: "circle" | "band";
  color: "default" | "gray";
  areAnyBelowRange: boolean;
  areAnyAboveRange: boolean;
  areBothBelowRange: boolean;
  areBothAboveRange: boolean;
};

const useStyles = (props: StyleProps) =>
  makeStyles(() => ({
    root: {
      width: "100%",
    },
    container: {
      display: "flex",
      flexDirection: "column",
    },
    bubble: {
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "1.25rem",
      width: "1.25rem",
      borderRadius: "50%",
      fontFamily: MONOSPACED_FONT,
      fontSize: "0.75rem",
      color: WHITE,
      lineHeight: "155%",
      fontWeight: 700,
      letterSpacing: "-0.5px",
      cursor: "default",
    },
    aboveBubble: {
      background: RED,
    },
    belowBubble: {
      background: DV_GREEN,
    },
    band: {
      height: "8px",
      background:
        props.color === "gray"
          ? GRAY_4
          : `linear-gradient(270deg, ${DV_PINK} 0%, ${DV_ORANGE} 78.13%, ${DV_YELLOW} 100%)`,
      borderRadius: "2px",
      display: "flex",
      flexDirection: "row",
      position: "relative",
      marginRight: props.areBothAboveRange
        ? "28px"
        : props.areAnyAboveRange
          ? "16px"
          : undefined,
      marginLeft: props.areBothBelowRange
        ? "28px"
        : props.areAnyBelowRange
          ? "16px"
          : undefined,
    },
    dotContainer: {
      display: "flex",
      paddingLeft: "6px",
      paddingRight: "6px",
      height: "0",
    },
    dot: {
      height: "10px",
      width: "10px",
      borderRadius: "50%",
      backgroundColor: WHITE,
      color: DV_ORANGE,
      border:
        props.color === "gray"
          ? `1.5px solid ${GRAY_3}`
          : `1.5px solid ${DV_ORANGE}`,
      boxShadow: "0px 1px 2px rgba(10, 36, 64, 0.2)",
      position: "relative",
      transform: "translate(-50%, -9px)",
    },
    hourlyRangeDot: {
      height: "10px",
      width: "10px",
      borderRadius: "50%",
      backgroundColor: DV_ORANGE,
      position: "relative",
      transform: "translate(50%, -9px)",
    },
    hourlyDot: {
      height: "10px",
      width: "10px",
      borderRadius: "50%",
      backgroundColor: DV_ORANGE,
      position: "relative",
      transform: "translate(200%, -9px)",
    },
    altDot: {
      border: `1.5px solid ${GRAY_3}`,
      color: GRAY_3,
    },
    outOfRangeDot: {
      position: "relative",
      transform: "translate(-50%, -10px)",
    },
    line: {
      height: "100%",
      position: "absolute",
      top: 0,
      transition: "all 250ms ease",
      width: "1px",
    },
  }));

export const CondensedSlider = forwardRef(function CondensedSlider(
  props: Props,
  ref: Ref<HTMLDivElement>
) {
  const {
    altValue,
    value,
    min,
    max,
    outOfRangeStyle,
    color = "default",
    ...rest
  } = props;

  const isCurValueBelowRange = value < min;
  const isCurValueAboveRange = value > max;
  const isCurValueOutOfRange = isCurValueBelowRange || isCurValueAboveRange;

  const isAltValueBelowRange = altValue !== undefined && altValue < min;
  const isAltValueAboveRange = altValue !== undefined && altValue > max;
  const isAltValueOutOfRange = isAltValueBelowRange || isAltValueAboveRange;

  const areAnyBelowRange = isCurValueBelowRange || isAltValueBelowRange;
  const areAnyAboveRange = isCurValueAboveRange || isAltValueAboveRange;
  const areBothBelowRange = isCurValueBelowRange && isAltValueBelowRange;
  const areBothAboveRange = isCurValueAboveRange && isAltValueAboveRange;

  const valueDotOffset = getOffset(value, min, max);
  const altValueDotOffset =
    altValue !== undefined ? getOffset(altValue, min, max) : undefined;

  const classes = useStyles({
    outOfRangeStyle,
    color,
    areAnyBelowRange,
    areAnyAboveRange,
    areBothBelowRange,
    areBothAboveRange,
  })();

  return (
    <div ref={ref} color={color} {...rest} className={classes.root}>
      {isCurValueOutOfRange && outOfRangeStyle === "circle" ? (
        <div
          className={clsx(classes.bubble, {
            [classes.belowBubble]: isCurValueBelowRange,
            [classes.aboveBubble]: isCurValueAboveRange,
          })}
        >
          {isCurValueBelowRange ? "B" : "A"}
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.band}>
            <div className={classes.line}></div>
          </div>
          {altValueDotOffset !== undefined && (
            <div className={classes.dotContainer}>
              {isAltValueAboveRange && (
                <AboveBandDot
                  className={classes.outOfRangeDot}
                  style={{
                    left: isCurValueAboveRange
                      ? `calc(${altValueDotOffset} - 12px)`
                      : altValueDotOffset,
                  }}
                />
              )}
              {isAltValueBelowRange && (
                <BelowBandDot
                  className={classes.outOfRangeDot}
                  style={{
                    left: isCurValueBelowRange
                      ? `calc(${altValueDotOffset} + 12px)`
                      : altValueDotOffset,
                  }}
                />
              )}
              {!isAltValueOutOfRange && (
                <div
                  className={`${classes.dot} ${classes.altDot}`}
                  style={{ left: altValueDotOffset }}
                />
              )}
            </div>
          )}
          <div className={classes.dotContainer}>
            {isCurValueAboveRange && (
              <AboveBandDot
                className={classes.outOfRangeDot}
                style={{ left: valueDotOffset }}
              />
            )}
            {isCurValueBelowRange && (
              <BelowBandDot
                className={classes.outOfRangeDot}
                style={{ left: valueDotOffset }}
              />
            )}
            {!isCurValueOutOfRange && (
              <div className={classes.dot} style={{ left: valueDotOffset }} />
            )}
          </div>
        </div>
      )}
    </div>
  );
});

export const CondensedHourlyBandPoint = forwardRef(
  function CondensedHourlyBandPoint(
    props: HourlyBandPointProps,
    ref: Ref<HTMLDivElement>
  ) {
    const {
      altValue,
      value,
      bandPointValue,
      color = "default",
      ...rest
    } = props;

    const isCurValueBelowRange = value < bandPointValue;
    const isCurValueAboveRange = value > bandPointValue;

    const isAltValueBelowRange =
      altValue !== undefined && altValue < bandPointValue;
    const isAltValueAboveRange =
      altValue !== undefined && altValue > bandPointValue;
    const isAltValueOutOfRange = isAltValueBelowRange || isAltValueAboveRange;

    const areAnyBelowRange = isCurValueBelowRange || isAltValueBelowRange;
    const areAnyAboveRange = isCurValueAboveRange || isAltValueAboveRange;
    const areBothBelowRange = isCurValueBelowRange && isAltValueBelowRange;
    const areBothAboveRange = isCurValueAboveRange && isAltValueAboveRange;

    const valueDotOffset = getOffset(value, bandPointValue, bandPointValue);
    const altValueDotOffset =
      altValue !== undefined
        ? getOffset(altValue, bandPointValue, bandPointValue)
        : undefined;

    const classes = useStyles({
      outOfRangeStyle: "band", // no "circle" outOfRange option for hourly
      color,
      areAnyBelowRange,
      areAnyAboveRange,
      areBothBelowRange,
      areBothAboveRange,
    })();

    return (
      <div ref={ref} color={color} {...rest} className={classes.root}>
        <div className={classes.container}>
          {altValueDotOffset !== undefined && (
            <div className={classes.dotContainer}>
              {isAltValueAboveRange && (
                <AboveBandDot
                  className={classes.outOfRangeDot}
                  style={{
                    left: isCurValueAboveRange
                      ? `calc(${altValueDotOffset} - 12px)`
                      : altValueDotOffset,
                  }}
                />
              )}
              {isAltValueBelowRange && (
                <BelowBandDot
                  className={classes.outOfRangeDot}
                  style={{
                    left: isCurValueBelowRange
                      ? `calc(${altValueDotOffset} + 12px)`
                      : altValueDotOffset,
                  }}
                />
              )}
              {!isAltValueOutOfRange && (
                <div
                  className={`${classes.dot} ${classes.altDot}`}
                  style={{ left: altValueDotOffset }}
                />
              )}
            </div>
          )}
          <div className={classes.dotContainer}>
            {isCurValueAboveRange && (
              <AboveBandDot
                className={classes.outOfRangeDot}
                style={{ left: valueDotOffset }}
              />
            )}
            {isCurValueBelowRange && (
              <BelowBandDot
                className={classes.outOfRangeDot}
                style={{ left: valueDotOffset }}
              />
            )}
            {(isCurValueAboveRange || isCurValueBelowRange) && (
              <div className={classes.hourlyRangeDot} />
            )}
            {!isCurValueAboveRange && !isCurValueBelowRange && (
              <div className={classes.hourlyDot} />
            )}
          </div>
        </div>
      </div>
    );
  }
);

// Returns a CSS offset for displaying a band point
function getOffset(value: number, min: number, max: number): string {
  // if the min & max values are the same, then just center it
  if (min === max && value === min) return "50%";

  const offset = ((value - min) / (max - min)) * 100;
  const constrainedOffset = Math.min(100, Math.max(0, offset));
  return `${constrainedOffset}%`;
}
