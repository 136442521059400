import { gql } from "@apollo/client";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { AssembleTypography } from "../../../components/AssembleTypography";
import {
  ADJUSTED_CASH_BAND_FIELDS,
  ADJUSTED_EQUITY_BAND_FIELDS,
} from "../../../fragments";
import { totalCompBand } from "../../../models/Band";
import { currencySymbol, getSimpleCashLabel } from "../../../models/Currency";
import { DV_ORANGE, DV_PINK, YELLOW_TEXT } from "../../../theme";
import { LadderDetailBandVisualizationInformationCell_position as Position } from "../../../__generated__/graphql";
import { useExcludedBandNames } from "../ExcludedBandNameContext";

const useStyles = makeStyles(() => ({
  totalCompMinLabel: {
    color: YELLOW_TEXT,
    fontWeight: 700,
  },
  totalCompMaxLabel: {
    color: DV_PINK,
    fontWeight: 700,
  },
  totalCompMiddleLabel: {
    color: DV_ORANGE,
    fontWeight: 700,
  },
  totalCompLabelCell: {
    border: 0,
    textAlign: "left",
  },
  dollarValueCell: {
    textAlign: "right",
    border: 0,
  },
}));

type Props = {
  position: Position;
  hourly: boolean;
};

export function LadderDetailBandVisualizationInformationCell({
  position,
  hourly,
}: Props): JSX.Element {
  const classes = useStyles();
  const { excludedBandNames } = useExcludedBandNames();
  const band = totalCompBand(position, excludedBandNames);

  const classForPoint = (pointIndex: number, total: number) => {
    switch (pointIndex) {
      case 0:
        return classes.totalCompMinLabel;
      case total - 1:
        return classes.totalCompMaxLabel;
      default:
        return classes.totalCompMiddleLabel;
    }
  };

  const currency = band.bandPoints.at(0)?.annualCashEquivalent.currency;
  const symbol = currency ? currencySymbol(currency) : "";

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      width={220}
      px={2}
    >
      {band.bandPoints.map((bp, index, bps) => (
        <Grid
          container
          key={bp.name}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={6} className={classes.totalCompLabelCell}>
            <AssembleTypography
              className={classForPoint(index, bps.length)}
              variant="subtitle2"
            >
              {bp.name}
            </AssembleTypography>
          </Grid>
          <Grid item xs={6} className={classes.dollarValueCell}>
            <AssembleTypography>
              {hourly
                ? bp.nullableHourlyCashEquivalent
                  ? getSimpleCashLabel(bp.nullableHourlyCashEquivalent)
                  : `${symbol} -`
                : bp.nullableAnnualCashEquivalent
                  ? getSimpleCashLabel(bp.nullableAnnualCashEquivalent)
                  : `${symbol} -`}
            </AssembleTypography>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
}

LadderDetailBandVisualizationInformationCell.fragments = {
  position: gql`
    ${ADJUSTED_CASH_BAND_FIELDS}
    ${ADJUSTED_EQUITY_BAND_FIELDS}
    fragment LadderDetailBandVisualizationInformationCell_position on Position {
      id
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedCashBandFields
      }
      adjustedEquityBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedEquityBandFields
      }
    }
  `,
};
