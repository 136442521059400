import { gql, useQuery } from "@apollo/client";
import { LocationsTableMergeConfigsQuery } from "../../../../__generated__/graphql";
import Loading from "../../Loading";
import { LocationsTableView } from "./LocationsTableView";

export function LocationsTableViewLoadingBoundary(): JSX.Element | null {
  const { data, loading } = useQuery<LocationsTableMergeConfigsQuery>(
    LocationsTableViewLoadingBoundary.query
  );

  if (loading) return <Loading />;
  if (data === undefined) return null;

  return (
    <LocationsTableView
      locations={data.locations}
      mergeConfigs={data.mergeConnectionConfigs}
    />
  );
}

LocationsTableViewLoadingBoundary.query = gql`
  ${LocationsTableView.fragments.mergeConfigs}
  ${LocationsTableView.fragments.locations}
  query LocationsTableMergeConfigsQuery {
    mergeConnectionConfigs {
      ...LocationsTableView_mergeConfigs
      id
    }
    locations {
      id
      ...LocationsTableView_locations
    }
  }
`;
