import { gql } from "@apollo/client";
import { caseInsensitiveComparator } from "@asmbl/shared/sort";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { InfoIcon } from "src/components/AssembleIcons/Small/InfoIcon";
import {
  AssembleBreadCrumb,
  AssembleBreadCrumbs,
  AssembleDropdownBreadCrumb,
} from "../../components/AssembleBreadCrumbs";
import {
  NoCompStructure,
  NoSearchResults,
} from "../../components/EmptyState/EmptyState";
import { ExplanatoryTooltip } from "../../components/ExplanatoryTooltip";
import { SearchByName } from "../../components/Search/SearchByName";
import FormHeader from "../../components/Settings/FormHeader";
import { HRISLocationsLinkOpen } from "../../components/Settings/Locations/HRISLocationsLinkOpen";
import { LocationGroupCard } from "../../components/Settings/Locations/LocationGroupCard";
import { LocationGroupEditor } from "../../components/Settings/Locations/LocationGroupEditor";
import { LocationMappingInfoBanner } from "../../components/Settings/Locations/LocationMappingInfoBanner";
import { LocationsTableView } from "../../components/Settings/Locations/LocationsTable/LocationsTableView";
import { LocationsTableViewLoadingBoundary } from "../../components/Settings/Locations/LocationsTable/LocationsTableViewLoadingBoundary";
import { GRAY_4, PURPLE_2 } from "../../theme";
import {
  Locations_compStructure as CompStructure,
  Locations_departments as Department,
  Locations_locationGroups as LocationGroup,
  Locations_locations as Location,
  Locations_markets as Market,
} from "../../__generated__/graphql";
import { LocationsActions } from "./Locations/LocationsActions";
import { PageContainer } from "./PageContainer";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
  },
  unmappedLocationsContainer: {
    breakInside: "avoid",
    pageBreakInside: "avoid",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  infoButton: { verticalAlign: "baseline" },
  cardList: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

type Props = {
  markets: Market[];
  market: Market;
  locations: Location[];
  locationGroups: LocationGroup[];
  departments: Department[];
  compStructure: CompStructure | null;
};

export function Locations({
  markets,
  market,
  locations,
  locationGroups,
  departments,
  compStructure,
}: Props): JSX.Element | null {
  const classes = useStyles();

  const [draggingGroupId, setDraggingGroupId] = useState<number | null>(null);

  if (!compStructure) return <NoCompStructure />;

  const sortedLocationGroups = locationGroups
    .slice()
    .sort((a, b) => caseInsensitiveComparator(a.name, b.name));

  return (
    <SearchByName items={sortedLocationGroups}>
      {(matchingLocationGroups, searchTerm, onSearchTermChange) => (
        <PageContainer>
          <div className={classes.header}>
            <FormHeader
              mb={0}
              header={
                <>
                  Markets & Locations
                  <Box ml={1} display="inline-block">
                    <ExplanatoryTooltip
                      title="Zone Adjustments"
                      body={
                        <>
                          Compensation is adjusted by the first matching
                          adjustment within each group.
                        </>
                      }
                      ctaUrl="//help.assemble.inc/en/articles/5092854#h_568b5d53cb"
                      placement="bottom"
                      width="400px"
                    >
                      <IconButton size="small" className={classes.infoButton}>
                        <InfoIcon color={GRAY_4} hoverColor={PURPLE_2} />
                      </IconButton>
                    </ExplanatoryTooltip>
                  </Box>
                </>
              }
            />
            <HRISLocationsLinkOpen />
          </div>
          <AssembleBreadCrumbs variant="horizontal">
            <AssembleBreadCrumb
              to={"/settings/markets"}
              display="All Markets"
            />
            <AssembleDropdownBreadCrumb
              options={markets.map(({ id, name }) => ({
                id: id,
                name: name,
                to: `/settings/markets/${id}/locations`,
              }))}
              selectedOption={{
                id: market.id,
                name: market.name,
                to: `/settings/markets/${market.id}/locations`,
              }}
            />
          </AssembleBreadCrumbs>

          <LocationsActions
            searchTerm={searchTerm}
            onSearchTermChange={onSearchTermChange}
            locations={locations}
            market={market}
          />
          <LocationMappingInfoBanner locations={locations} />
          <div className={classes.cardList}>
            {matchingLocationGroups.map((locationGroup) => (
              <LocationGroupCard
                market={market}
                key={locationGroup.id}
                locationGroup={locationGroup}
                locations={locations}
                allLocationGroups={sortedLocationGroups}
                departments={departments}
                compStructure={compStructure}
                draggingGroupId={draggingGroupId}
                onDragStart={() => setDraggingGroupId(locationGroup.id)}
                onDragEnd={() => setDraggingGroupId(null)}
              />
            ))}
          </div>
          {searchTerm !== "" && matchingLocationGroups.length === 0 && (
            <NoSearchResults
              noSearchCopy={
                <>
                  If you're searching for an HRIS Location, you can view HRIS
                  Location mappings by clicking the button in the top-right of
                  your screen.
                </>
              }
            />
          )}
          <LocationsTableViewLoadingBoundary />
        </PageContainer>
      )}
    </SearchByName>
  );
}

Locations.fragments = {
  compStructure: gql`
    ${LocationGroupCard.fragments.compStructure}
    fragment Locations_compStructure on CompStructure {
      ...LocationGroupCard_compStructure
      id
    }
  `,
  locations: gql`
    ${LocationsTableView.fragments.locations}
    ${LocationMappingInfoBanner.fragments.locations}
    ${LocationGroupCard.fragments.locations}
    ${LocationsActions.fragments.locations}
    fragment Locations_locations on Location {
      ...LocationsTableView_locations
      ...LocationMappingInfoBanner_locations
      ...LocationGroupCard_locations
      ...LocationsActions_locations
      id
    }
  `,
  locationGroups: gql`
    ${LocationGroupCard.fragments.locationGroup}
    ${LocationGroupEditor.fragments.locationGroup}
    fragment Locations_locationGroups on LocationGroup {
      ...LocationGroupCard_locationGroup
      ...LocationGroupEditor_locationGroup
      id
      name
    }
  `,
  departments: gql`
    ${LocationGroupCard.fragments.departments}
    fragment Locations_departments on Department {
      ...LocationGroupCard_departments
      id
    }
  `,
  markets: gql`
    ${LocationsActions.fragments.markets}
    fragment Locations_markets on Market {
      ...LocationsActions_markets
      id
      name
    }
  `,
};
