import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const ListUnorderedIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="List (Unordered)"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M8.25 6H20.25"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M8.25056 12H20.25"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M8.25056 18H20.25"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="fill"
        d="M4.125 13.5C4.95343 13.5 5.625 12.8284 5.625 12C5.625 11.1716 4.95343 10.5 4.125 10.5C3.29657 10.5 2.625 11.1716 2.625 12C2.625 12.8284 3.29657 13.5 4.125 13.5Z"
      />
      <path
        className="fill"
        d="M4.125 7.5C4.95343 7.5 5.625 6.82843 5.625 6C5.625 5.17157 4.95343 4.5 4.125 4.5C3.29657 4.5 2.625 5.17157 2.625 6C2.625 6.82843 3.29657 7.5 4.125 7.5Z"
      />
      <path
        className="fill"
        d="M4.125 19.5C4.95343 19.5 5.625 18.8284 5.625 18C5.625 17.1716 4.95343 16.5 4.125 16.5C3.29657 16.5 2.625 17.1716 2.625 18C2.625 18.8284 3.29657 19.5 4.125 19.5Z"
      />
    </svg>
  </ColorableSVG>
);
