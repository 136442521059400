import { mapMaybe } from "@asmbl/shared/utils";
import {
  Button,
  Checkbox,
  Chip,
  ClickAwayListener,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import { CheckBox, CheckBoxOutlineBlank, Clear } from "@material-ui/icons";
import Autocomplete, {
  AutocompleteCloseReason,
} from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTrack } from "../../analytics";
import { FilterParam } from "../../models/FilterParams";
import { useURLSearchParams } from "../../models/URLSearchParams";
import { GRAY_1, GRAY_5, PURPLE_1, RED } from "../../theme";
import { ChevronDownIcon } from "../AssembleIcons/Brand/ChevronDownIcon";
import { AssembleTruncatedTypography } from "../AssembleTruncatedTypography";
import { AssembleTypography } from "../AssembleTypography";
import { FilterSelectOption } from "./FilterSelect";

const useStyles = makeStyles((theme: Theme) => ({
  active: {},
  empty: {},
  button: {
    height: "2rem",
    border: `1px solid ${GRAY_5}`,
    borderRadius: "4px",
    color: GRAY_1,
    padding: theme.spacing(0, 1, 0, 1.5),
    boxShadow: "0px 1px 3px rgba(10, 36, 64, 0.1)",

    // Hovering, click-down, and active states (when not empty)
    "&:hover, &:focus, &:active, &$active": {
      border: `1px solid ${PURPLE_1}`,
      boxShadow:
        "0px 1px 3px rgba(10, 36, 64, 0.1), 0px 0px 0px 3px rgba(10, 0, 174, 0.25)",
    },

    // By default, the empty state should have the color red...
    "&$empty": {
      border: `1px solid ${RED}`,
      color: RED,

      // ...and when you hover over it, it gets a red box-shadow...
      "&:hover": {
        boxShadow:
          "0px 1px 3px rgba(10, 36, 64, 0.1), 0px 0px 0px 3px rgba(255, 56, 92, 0.25)",
      },

      // ...but when it's active or clicked-down, restore the normal colors.
      "&:active, &:focus, &$active": {
        color: GRAY_1,
        border: `1px solid ${PURPLE_1}`,
        boxShadow:
          "0px 1px 3px rgba(10, 36, 64, 0.1), 0px 0px 0px 3px rgba(10, 0, 174, 0.25)",
      },
    },
  },
  innerButton: {
    display: "flex",
    justifyContent: "space-between",
    gap: "0.5rem",
    alignItems: "center",
  },
  label: {
    color: "inherit",
  },
  popper: {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    marginTop: "3px", // to account for the active boxShadow of the button
    borderRadius: "3px",
    width: "300px",
    zIndex: 1,
    backgroundColor: "white",
  },
  autocompletePopper: {
    border: "none",
  },
  inputBase: {
    padding: "8px",
    borderBottom: "1px solid #dfe2e5",
    fontSize: "0.875rem",
  },
  inputRoot: {
    padding: "4px 8px !important",
    fontSize: "0.875rem",
  },
  paper: {
    boxShadow: "none",
  },
  option: {
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  open: {},
  arrowIcon: {
    transition: "transform 350ms",
    minWidth: "1rem",
    minHeight: "1rem",
    width: "1rem",
    height: "1rem",
    color: "inherit",

    "&$open": {
      transform: "rotate(-180deg)",
    },
  },
  listbox: {
    padding: "8px",
  },
}));

export type SearchableFilterProps = {
  name: string;
  options: FilterSelectOption[];
  onChange: () => unknown;
  Icon?: JSX.Element;
  hideChips?: boolean;
  disabled?: boolean;
  param: FilterParam;
};

export function ParamSearchableFilter({
  name,
  options,
  onChange,
  Icon,
  param,
  hideChips = false,
  disabled = false,
}: SearchableFilterProps) {
  const classes = useStyles();
  const { trackEvent } = useTrack();
  const navigate = useNavigate();
  const urlSearchParams = useURLSearchParams();
  const value = urlSearchParams.get(param) ?? "all";
  const isActive = value !== "all";
  const urlValues = value.split(",");
  const nullSelected = isActive && urlValues[0] === "null";

  const selectedValues = mapMaybe(
    value
      .split(",")
      .map((value) =>
        options.find((option) => option.value.toString() === value)
      ),
    (value) => value
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(selectedValues.length === 0);

  const handleChange = (selectedOptions: FilterSelectOption[]) => {
    // this comes from FilterStateManager and resets the table pagination
    onChange();
    // the only selected option was de-selected, so set to 'all' as default
    if (selectedOptions.length === 0) {
      trackEvent({
        object: "Multiple Filters",
        action: "Selected",
        filterCount: 0,
        filterType: param,
        filterSelection: "none",
        filterAction: "Cleared",
      });
      // if manager filter is cleared, also clear reports
      return param === FilterParam.MANAGER
        ? navigate(
            `?${urlSearchParams
              .deleteMany([param, FilterParam.REPORTS])
              .set(param, "all")
              .toString()}`
          )
        : navigate(`?${urlSearchParams.set(param, "all").toString()}`);
    }

    // if the URL value is 'all' and we made it to this point, then that means
    // that the user has de-selected the values that are listed in
    // selectedOptions
    if (!isActive && selectedOptions.length === 1) {
      trackEvent({
        object: "Multiple Filters",
        action: "Selected",
        filterCount: 0,
        filterType: param,
        filterSelection: selectedOptions
          .map((option) => option.label)
          .join(", "),
        filterAction: "Deleted",
      });

      return navigate(`?${urlSearchParams.set(param, "all").toString()}`);
    }
    const allSelected = selectedOptions.findIndex(
      (selectedOption) => selectedOption.value.toString() === "all"
    );

    /* 
    We have the "all" option as the filter default (selectedOptions[0]), 
    so we need to check a few scenarios to determine if "all" was user selected or not. 
    If all is selected with existing options, 
    the index !== 0 (not default) and index !== -1 (not in selectedOptions).
    However, if the current filter is null and all is selected, the index === 0.
    */
    if (
      (allSelected !== 0 && allSelected !== -1) ||
      (nullSelected && allSelected === 0)
    ) {
      trackEvent({
        object: "Multiple Filters",
        action: "Selected",
        filterCount: 1,
        filterType: param,
        filterSelection: "all",
        filterAction: "Added",
      });
      // if manager is updated to "all", delete the reports param
      return param === FilterParam.MANAGER
        ? navigate(
            `?${urlSearchParams
              .deleteMany([param, FilterParam.REPORTS])
              .set(param, "all")
              .toString()}`
          )
        : navigate(`?${urlSearchParams.set(param, "all").toString()}`);
    }

    const newParams = selectedOptions
      .filter((option) => option.value !== "all")
      .map((option) => option.value)
      .join(",");

    trackEvent({
      object: "Multiple Filters",
      action: "Selected",
      filterCount: selectedOptions.length,
      filterType: param,
      filterSelection: selectedOptions.map((option) => option.label).join(", "),
      filterAction: "Added",
    });

    return navigate(`?${urlSearchParams.set(param, newParams).toString()}`);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAutocompleteClose = (
    _: React.ChangeEvent<unknown>,
    reason: AutocompleteCloseReason
  ) => {
    // Only close when user explicitly attempts to close.
    if (reason === "escape" || reason === "select-option") {
      handleClose();
    }
  };

  const id = open ? `searchable-filter-${name}` : undefined;

  const buttonLabel = `${name}: ${selectedValues
    .map((s) => s.label)
    .join(", ")}`;

  return (
    <>
      <Button
        ref={setAnchorEl}
        className={clsx(classes.button, {
          [classes.active]: open,
          [classes.empty]: selectedValues.length === 0,
        })}
        aria-describedby={id}
        onClick={() => setOpen(true)}
        fullWidth
        disabled={disabled}
      >
        <div className={classes.innerButton}>
          {Icon}
          <AssembleTruncatedTypography className={classes.label}>
            {buttonLabel}
          </AssembleTruncatedTypography>

          <ChevronDownIcon
            inherit
            className={clsx(classes.arrowIcon, { [classes.open]: open })}
          />
        </div>
      </Button>
      <Popper
        id={id}
        anchorEl={anchorEl}
        open={anchorEl != null && open}
        placement="bottom-start"
        className={classes.popper}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              multiple
              onClose={handleAutocompleteClose}
              classes={{
                paper: classes.paper,
                option: classes.option,
                popperDisablePortal: classes.popperDisablePortal,
                listbox: classes.listbox,
                popper: classes.autocompletePopper,
                inputRoot: classes.inputRoot,
              }}
              value={selectedValues}
              onChange={(_, value) => {
                handleChange(value);
              }}
              options={options}
              disableCloseOnSelect
              disablePortal
              forcePopupIcon={false}
              renderTags={
                hideChips
                  ? () => null
                  : (value: FilterSelectOption[], getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          key={`${name}-${index}`}
                          label={option.label}
                          deleteIcon={<Clear />}
                          size="small"
                          // if hideDelete, disable and hide the delete icon
                          disabled={option.hideDelete === true}
                          {...(option.hideDelete === true
                            ? { onDelete: undefined }
                            : {})}
                        />
                      ))
              }
              noOptionsText={`No ${name}`}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank />}
                    checkedIcon={<CheckBox />}
                    checked={selected}
                    color={"primary"}
                    size="small"
                    indeterminate={
                      option.value === "all" &&
                      !selected &&
                      selectedValues.length > 0
                    }
                  />
                  <AssembleTypography variant="body2" textColor={GRAY_1}>
                    {option.label}
                  </AssembleTypography>
                </>
              )}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  ref={params.InputProps.ref}
                  {...params}
                  variant="outlined"
                  className={classes.inputBase}
                  placeholder={
                    selectedValues.length > 0
                      ? ""
                      : "Select one or more options…"
                  }
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
