import { StrictMode } from "react";
import * as ReactDOM from "react-dom";
import { RouterProvider } from "react-router-dom";
import { appRouter } from "./pages/App";

ReactDOM.render(
  <StrictMode>
    <RouterProvider router={appRouter} />
  </StrictMode>,
  document.getElementById("root")
);
