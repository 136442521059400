import { Grid } from "@material-ui/core";
import { RichUtils } from "draft-js";
import { PureComponent } from "react";
import { BlockquoteBlock } from "./BlockquoteBlock/BlockquoteBlock";
import { BoldStyle } from "./BoldStyle/BoldStyle";
import { CodeBlock } from "./CodeBlock/CodeBlock";
import { H1Block } from "./H1Block/H1Block";
import { H2Block } from "./H2Block/H2Block";
import { H3Block } from "./H3Block/H3Block";
import { H4Block } from "./H4Block/H4Block";
import { H5Block } from "./H5Block/H5Block";
import { H6Block } from "./H6Block/H6Block";
import { ItalicStyle } from "./ItalicStyle/ItalicStyle";
import { MonoStyle } from "./MonoStyle/MonoStyle";
import { OrderedListBlock } from "./OrderedListBlock/OrderedListBlock";
import { UnderlineStyle } from "./UnderlineStyle/UnderlineStyle";
import { UnorderedListBlock } from "./UnorderedListBlock/UnorderedListBlock";

export type RTEToolbarProps = {
  editorState: Draft.EditorState;
  externalProps: unknown;
  onToggleStyle(editorState: Draft.EditorState): void;
};

export class RTEToolbar extends PureComponent<RTEToolbarProps> {
  boldStyle = (): void => {
    this.props.onToggleStyle(
      RichUtils.toggleInlineStyle(this.props.editorState, "BOLD")
    );
  };

  italicStyle = (): void => {
    this.props.onToggleStyle(
      RichUtils.toggleInlineStyle(this.props.editorState, "ITALIC")
    );
  };

  underlineStyle = (): void => {
    this.props.onToggleStyle(
      RichUtils.toggleInlineStyle(this.props.editorState, "UNDERLINE")
    );
  };

  orderedListBlock = (): void => {
    this.props.onToggleStyle(
      RichUtils.toggleBlockType(this.props.editorState, "ordered-list-item")
    );
  };

  unorderedListBlock = (): void => {
    this.props.onToggleStyle(
      RichUtils.toggleBlockType(this.props.editorState, "unordered-list-item")
    );
  };

  headlineBlock = (headlineValue: string): void => {
    this.props.onToggleStyle(
      RichUtils.toggleBlockType(this.props.editorState, headlineValue)
    );
  };

  blockquoteBlock = (): void => {
    this.props.onToggleStyle(
      RichUtils.toggleBlockType(this.props.editorState, "blockquote")
    );
  };

  codeBlock = (): void => {
    this.props.onToggleStyle(
      RichUtils.toggleBlockType(this.props.editorState, "code-block")
    );
  };

  monoStyle = (): void => {
    this.props.onToggleStyle(
      RichUtils.toggleInlineStyle(this.props.editorState, "CODE")
    );
  };

  render(): JSX.Element {
    const { editorState } = this.props;
    return (
      <Grid
        container
        justifyContent="space-evenly"
        style={{ padding: "0px 10px" }}
      >
        <Grid item>
          <H1Block editorState={editorState} onClick={this.headlineBlock} />
        </Grid>

        <Grid item>
          <H2Block editorState={editorState} onClick={this.headlineBlock} />
        </Grid>

        <Grid item>
          <H3Block editorState={editorState} onClick={this.headlineBlock} />
        </Grid>

        <Grid item>
          <H4Block editorState={editorState} onClick={this.headlineBlock} />
        </Grid>

        <Grid item>
          <H5Block editorState={editorState} onClick={this.headlineBlock} />
        </Grid>

        <Grid item>
          <H6Block editorState={editorState} onClick={this.headlineBlock} />
        </Grid>

        <Grid item>
          <BlockquoteBlock
            editorState={editorState}
            onClick={this.blockquoteBlock}
          />
        </Grid>

        <Grid item>
          <UnorderedListBlock
            editorState={editorState}
            onClick={this.unorderedListBlock}
          />
        </Grid>

        <Grid item>
          <OrderedListBlock
            editorState={editorState}
            onClick={this.orderedListBlock}
          />
        </Grid>

        <Grid item>
          <CodeBlock editorState={editorState} onClick={this.codeBlock} />
        </Grid>

        <Grid item>
          <BoldStyle editorState={editorState} onClick={this.boldStyle} />
        </Grid>

        <Grid item>
          <ItalicStyle editorState={editorState} onClick={this.italicStyle} />
        </Grid>

        <Grid item>
          <UnderlineStyle
            editorState={editorState}
            onClick={this.underlineStyle}
          />
        </Grid>

        <Grid item>
          <MonoStyle editorState={editorState} onClick={this.monoStyle} />
        </Grid>
      </Grid>
    );
  }
}
