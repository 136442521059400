import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import {
  AutocompleteClassKey,
  ToggleButtonClassKey,
  ToggleButtonGroupClassKey,
} from "@material-ui/lab";
import CircularBlack from "./assets/fonts/CircularXXWeb-Black.woff2";
import CircularBold from "./assets/fonts/CircularXXWeb-Bold.woff2";
import CircularBook from "./assets/fonts/CircularXXWeb-Book.woff2";
import CircularMedium from "./assets/fonts/CircularXXWeb-Medium.woff2";
import OverpassMonoSemiBold from "./assets/fonts/OverpassMono-SemiBold.woff2";

// Primary / Brand
export const PURPLE_1 = "#645BFF";
export const PURPLE_2 = "#4A41F2";
export const BLUE_1 = "#071447";
export const BLUE_2 = "#2881FF";
export const BLUE_3 = "#0FC4FA";

// Text / Backgrounds
export const GRAY_1 = "#0A2440";
export const GRAY_2 = "#425466";
export const GRAY_3 = "#708598";
export const GRAY_4 = "#88A4BE";
export const GRAY_5 = "#BDD3E5";
export const GRAY_6 = "#DAEAF9";
export const GRAY_7 = "#EEF5FB";
export const GRAY_8 = "#F7FBFF";
export const GRAY_9 = "#F9FBFF";
export const GRAY_10 = "#C3C9CF";

// Data Viz
export const DV_PINK = "#DE33AB";
export const DV_GREEN = "#07DBB5";
export const DV_ORANGE = "#FF900D";
export const DV_ORANGE_2 = "#FC5201";
export const DV_BLUE = "#34AAFF";
export const DV_YELLOW = "#FFDD28";
export const DV_YELLOW_LIGHT = "#FFF6C9";
export const DV_PURPLE = "#AD6CFF";
export const DV_RED = "#FF5D47";
export const DV_BLUE_2 = "#135ECF";
export const DV_GREEN_2 = "#2C8976";
export const DV_YELLOW_GREEN = "#C8F836";
export const DV_BLACK = "#000000";

// Utility
export const GREEN_2 = "#17BD54";
export const GREEN_3 = "#089562";
export const RED = "#FF385C";
export const RED_2 = "#D91D4A";
export const RED_3 = "#F52759";
export const RED_4 = "#C81742";
export const RED_5 = "#D91E4B";
export const YELLOW = "#FFB628";
export const YELLOW_2 = "#FAA01A";
export const WARN = "#F7A506";
export const WARN_TEXT = "#F6A506";

// Helper
export const WHITE = "#FFFFFF";
export const TRANSPARENT = "rgba(255, 0, 0, 0)";
export const BOX_SHADOW = "inset 0px 1px 4px rgba(54, 70, 87, 0.14)";

// One-offs
export const YELLOW_TEXT = "#E8C50E";
export const SPEARMINT = "#00C6A2";
export const SPLASH_PURPLE = "#8E41F0";
export const SPLASH_PURPLE_BG = "#DED3FE";
export const SPLASH_ORANGE = "#FF7A00";
export const SPLASH_ORANGE_BG = "#FFE6C0";
export const SPLASH_BLUE = "#2881FF";
export const SPLASH_BLUE_BG = "#BBE7FF";
export const SPLASH_CYAN = "#08BBE2";
export const SPLASH_CYAN_BG = "#C6F8FF";
export const PRIMARY_BOX_SHADOW = "0px 1px 3px rgba(100,91,255,.35)";
export const OUTLINE_BOX_SHADOW = `0px 0px 0px 3px ${PURPLE_1}4D`;
export const ERROR_BG = `linear-gradient(145.58deg, ${RED} 28.94%, ${RED_2} 87.8%)`;
export const SUCCESS_BG = `linear-gradient(148.74deg, ${GREEN_2} 27.64%, ${GREEN_3} 87.25%);`;
export const WARNING_TEXT = "#E98C00";

// Review Request Statuses
// There's some duplication here, but it makes things more unified
export const STATUS_ACCEPTED = GRAY_4;
export const STATUS_ACCEPTED_HOVER = GRAY_3;
export const STATUS_FUTURE_PHASE = GRAY_8;
export const STATUS_FUTURE_PHASE_HOVER = GRAY_8;
export const STATUS_FUTURE_PHASE_BORDER = GRAY_5;
export const STATUS_FUTURE_PHASE_COLOR = GRAY_5;
export const STATUS_AWAITING = GRAY_8;
export const STATUS_AWAITING_HOVER = GRAY_8;
export const STATUS_AWAITING_BORDER = GRAY_5;
export const STATUS_AWAITING_COLOR = GRAY_5;
export const STATUS_NEEDS_REVIEW = "#09CCA9";
export const STATUS_NEEDS_REVIEW_HOVER = "#07C09F";
export const STATUS_REVIEWED = GRAY_4;
export const STATUS_REVIEWED_HOVER = GRAY_3;
export const STATUS_REJECTED = "#EB3319";
export const STATUS_REJECTED_HOVER = "#E1260D";
export const STATUS_LATE = DV_PINK;
export const STATUS_LATE_HOVER = "#CE239B";
export const STATUS_SKIPPED = GRAY_2;
export const STATUS_SKIPPED_HOVER = GRAY_1;

// Company theme colors
export const UNSPECIFIED_THEME = "#FF900D";
export const RADFORD_THEME = "#EB0017";
export const PAVE_THEME = "#4065FF";
export const CARTA_THEME = "#000000";
export const ERI_THEME = "#006A41";
export const MERCER_THEME = "#002C77";
export const CULPEPPER_THEME = "#3178B9";
export const WTW_THEME = "#7F35B2";

// Sizing
export const SIDEBAR_WIDTH = "80px";
export const SETTINGS_SIDEBAR_WIDTH = "248px";
export const SUBSETTINGS_SIDEBAR_START = "328px"; // sidebar + settings sidebar widths

// Fonts (see https://material-ui.com/customization/typography/)
const circularBlack = {
  fontFamily: "Circular",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 900,
  src: `
    local('Circular Black'),
    url(${CircularBlack}) format('woff')
  `,
};
const circularBold = {
  fontFamily: "Circular",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 700,
  src: `
    local('Circular Bold'),
    url(${CircularBold}) format('woff2')
  `,
};
const circularBook = {
  fontFamily: "Circular",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Circular Book'),
    url(${CircularBook}) format('woff2')
  `,
};
const circularMedium = {
  fontFamily: "Circular",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 500,
  src: `
    local('Circular Medium'),
    url(${CircularMedium}) format('woff2')
  `,
};
const overpassMonoSemiBold = {
  fontFamily: "OverpassMono",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 600,
  src: `
    local('Overpass Mono SemiBold'),
    url(${OverpassMonoSemiBold}) format('woff2')
  `,
};

export const APP_FONTS = [
  "Circular",
  "Roboto",
  "Helvetica",
  "Arial",
  "sans-serif",
].join(",");

export const MONOSPACED_FONT = "OverpassMono";

// Global dimensions
export const DIMENSIONS: Record<string, string> = {
  sidebar: SIDEBAR_WIDTH,
} as const;

// Allows overriding styles in material-ui lab
// https://github.com/mui-org/material-ui/issues/19427
declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey {
    MuiAutocomplete: AutocompleteClassKey;
    MuiToggleButton: ToggleButtonClassKey;
    MuiToggleButtonGroup: ToggleButtonGroupClassKey;
  }
}

// Workaround for a missing type. Fixed in Material v5
// https://github.com/mui-org/material-ui/issues/17010
declare module "@material-ui/core/Box" {
  interface BoxProps {
    ref?:
      | ((instance: HTMLElement | null) => void)
      | React.MutableRefObject<HTMLElement | null>
      | null;
  }
}

export const theme = createMuiTheme({
  typography: {
    body1: {
      fontSize: "1rem",
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
    },
    fontFamily: APP_FONTS,
    subtitle2: {
      fontSize: "0.625rem",
      fontWeight: 700,
      textTransform: "uppercase",
      transform: "transition color 125ms",
    },
    button: {
      textTransform: "initial",
    },
    overline: {
      fontWeight: "bold",
      fontSize: "0.6875rem",
    },
    h1: {
      fontSize: "2.375rem",
      fontWeight: 500,
      letterSpacing: "-1.5px",
      lineHeight: "140%",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 500,
      letterSpacing: "-1px",
      lineHeight: "140%",
    },
    h3: {
      fontSize: "1.625rem",
      fontWeight: 500,
      letterSpacing: "-1px",
      lineHeight: "140%",
    },
    h4: {
      fontSize: "1.375rem",
      fontWeight: 500,
      letterSpacing: "-1px",
      lineHeight: "140%",
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 500,
      letterSpacing: "-.5px",
      lineHeight: "140%",
    },
    h6: {
      fontSize: "1.125rem",
      fontWeight: 500,
      letterSpacing: "-.5px",
      lineHeight: "140%",
    },
  },
  palette: {
    primary: {
      main: PURPLE_1,
      contrastText: WHITE,
    },
    secondary: {
      main: BLUE_2,
      contrastText: WHITE,
    },
    background: {
      default: GRAY_9,
      paper: WHITE,
    },
    text: {
      primary: GRAY_1,
      secondary: GRAY_4,
    },
    divider: GRAY_6,
  },
  overrides: {
    MuiAccordion: {
      root: {
        margin: "0px",
        boxShadow: "none",
        "&:before": {
          backgroundColor: "none",
        },
        "&$expanded": {
          boxShadow: "none",
          margin: "0px",
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: "0px",
      },
    },
    MuiAccordionSummary: {
      root: {
        backgroundColor: GRAY_8,
        boxSizing: "border-box",
        borderBottom: `1px solid ${GRAY_6}`,
        "&$expanded": {
          margin: "0px",
          maxHeight: "50px",
          minHeight: "50px",
        },
        transition: "none",
      },
      content: {
        transition: "none",
      },
    },
    MuiAutocomplete: {
      popper: {
        border: `2px solid ${PURPLE_1}`,
        borderRadius: "5px",
        boxShadow: "0 15px 25px rgba(10, 36, 64, 0.08)",
      },
      paper: {
        margin: 0,
      },
      listbox: {
        padding: "8px 16px",
      },
      option: {
        margin: 0,
        borderRadius: "3px",
        border: "none",
        height: "50px",

        '&[data-focus="true"]': {
          backgroundColor: GRAY_7,
          boxShadow: "none",
        },
        '&[aria-selected="true"]': {
          backgroundColor: TRANSPARENT,
          color: PURPLE_1,
          fontWeight: 700,
        },
        '&[aria-disabled="true"]': {
          color: GRAY_5,
          opacity: 1,
        },
      },
      popupIndicator: {
        marginRight: "4px",
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: WHITE,
        color: GRAY_2,
        border: `1px solid ${GRAY_6}`,
        borderRadius: "5px",
        transition:
          "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:hover, &:focus": {
          border: `1px solid ${GRAY_2}`,
          backgroundColor: WHITE,
          color: GRAY_2,
        },
        "&:active": {
          border: `1px solid ${GRAY_2}`,
          backgroundColor: WHITE,
        },
        "&:disabled": {
          border: `1px solid ${TRANSPARENT}`,
          color: WHITE,
          backgroundColor: GRAY_10,
        },
      },
      containedPrimary: {
        backgroundColor: PURPLE_1,
        color: WHITE,
        border: `1px solid ${PURPLE_1}`,
        "&:hover, &:focus": {
          backgroundColor: PURPLE_2,
          border: `1px solid ${PURPLE_2}`,
          color: WHITE,
        },
        "&.Mui-disabled": {
          border: `1px solid ${TRANSPARENT}`,
          color: WHITE,
          backgroundColor: GRAY_10,
        },
      },
      containedSecondary: {
        backgroundColor: WHITE,
        color: BLUE_2,
        border: `1px solid ${GRAY_6}`,
        "&:hover": {
          backgroundColor: WHITE,
          border: `1px solid ${BLUE_2}`,
          color: BLUE_2,
        },
      },
      text: {
        color: GRAY_4,
        transition:
          "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:hover": {
          color: PURPLE_1,
          backgroundColor: "unset",
        },
      },
    },
    MuiIconButton: {
      root: {
        color: GRAY_4,

        "&:hover, &:focus": {
          background: "none",
          color: PURPLE_1,
        },
        "&:active": {
          color: GRAY_1,
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
    },
    MuiCardActionArea: {
      root: {
        "&:hover, $focusHighlight": {
          backgroundColor: WHITE,
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
    },
    MuiCheckbox: {
      root: {
        height: 30,
        width: 30,
        "&:hover, &:focus-within": {
          border: "3px solid rgba(100, 91, 255, 0.25)",
          color: PURPLE_1,
        },
      },
      colorSecondary: {
        border: "3px solid transparent",
        borderRadius: "3px",
        color: GRAY_6,
        height: 24,
        padding: 0,
        transition: "all 275ms",
        width: 24,
        "&$checked": {
          color: PURPLE_1,
        },
        "&$indeterminate": {
          color: PURPLE_1,
        },
        "&:hover, &:focus": {
          border: "3px solid rgba(100, 91, 255, 0.25)",
          color: PURPLE_1,
        },
      },
    },
    MuiChip: {
      root: {
        backgroundColor: GRAY_7,
        margin: "4px",
      },
    },
    MuiFormControlLabel: {
      label: {
        color: GRAY_1,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: "10px",
        padding: "16px",
      },
    },
    MuiDialogActions: {
      root: {
        padding: "16px 24px",
      },
    },
    MuiDialogContentText: {
      root: {
        fontSize: "1.125rem",
        color: GRAY_2,
      },
    },
    MuiDialogTitle: {
      root: {
        paddingBottom: "0",
        "& .MuiTypography-root": {
          fontSize: "1.375rem",
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: `${GRAY_1}40`,
      },
    },
    MuiFab: {
      primary: {
        backgroundColor: WHITE,
        boxShadow: "0px 0px !important",
        border: `1px solid ${GRAY_6}`,
        borderRadius: "50%",
        transition: "border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:hover": {
          border: `1px solid ${PURPLE_1}`,
          backgroundColor: WHITE,
        },
        "&:active": {
          border: `1.5px solid ${PURPLE_1}`,
          backgroundColor: WHITE,
        },
        "&$disabled": {
          border: `1px solid ${TRANSPARENT}`,
          backgroundColor: GRAY_10,
        },
      },
    },
    MuiInputBase: {
      input: {
        "&::placeholder": {
          color: GRAY_1,
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        background: WHITE,
        paddingInline: "4px", // Pad so the background looks nice
      },
    },
    MuiList: {
      root: {
        padding: "8px",
      },
    },
    MuiMenuItem: {
      root: {
        /*
         * To use textOverflow: ellipsis, you must wrap the MenuItem content in
         * a Typography element.
         * https://material-ui.com/components/menus/#limitations
         */
        borderRadius: "4px",
        textOverflow: "ellipsis",
        "&$selected": {
          background: "none",
          fontWeight: 700,
        },
        "&&:hover, &.Mui-focusVisible": {
          background: GRAY_7,
          color: PURPLE_1,
        },
      },
      gutters: {
        padding: "8px",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "5px",
      },
    },
    MuiRadio: {
      root: {
        height: 30,
        width: 30,
      },
      colorSecondary: {
        border: "3px solid transparent",
        backgroundColor: WHITE,
        color: GRAY_6,
        height: 24,
        padding: 0,
        transition: "all 275ms",
        width: 24,
        "&$checked": {
          border: `1px solid ${PURPLE_1}`,
          color: PURPLE_1,
          backgroundColor: WHITE,
        },
        "&:hover:not($checked)": {
          border: "3px solid rgba(100, 91, 255, 0.25)",
          color: PURPLE_1,
        },
      },
    },
    MuiSelect: {
      icon: {
        marginRight: "4px",
        color: GRAY_4,
      },
    },
    MuiSwitch: {
      root: {
        cursor: "pointer",
        width: "32px",
        height: "21px",
        padding: 0,
      },
      switchBase: {
        padding: 2,
        "&$checked": {
          color: WHITE,
          transform: "translateX(12px)",
          "& + $track": {
            backgroundColor: `${PURPLE_1} !important`,
            opacity: 1,
            border: "none",
          },
        },
      },
      thumb: {
        width: "17px",
        height: "17px",
        backgroundColor: WHITE,
        paddingBottom: "1px",
      },
      track: {
        borderRadius: 100,
        border: "none",
        backgroundColor: GRAY_6,
        opacity: 1,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: BLUE_3,
      },
    },
    MuiTable: {
      root: {
        borderCollapse: "initial",
      },
    },
    MuiTableContainer: {
      root: {
        border: `1px solid ${GRAY_6}`,
        borderBottom: `10px solid ${WHITE}`,
        boxShadow: `0px 1px ${GRAY_6}`,
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: WHITE,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${GRAY_6}`,
      },
      head: {
        color: GRAY_4,
        height: "40px",
        padding: "0 16px",
      },
      body: {
        borderBottom: `1px solid ${GRAY_6}`,
      },
      stickyHeader: {
        backgroundColor: WHITE,
      },
    },
    MuiTableSortLabel: {
      icon: {
        marginLeft: 0,
        marginRight: 0,
      },
      root: {
        "&$active": {
          color: GRAY_2,
        },
        "&:focus": {
          color: GRAY_2,
        },
        "&:hover": {
          color: GRAY_2,
        },
      },
    },
    MuiTextField: {
      root: {
        "& label": {
          paddingLeft: "4px",
          paddingRight: "4px",
        },
      },
    },
    MuiToggleButton: {
      root: {
        backgroundColor: WHITE,
        color: GRAY_4,
        border: `1px solid ${GRAY_6}`,
        boxSizing: "border-box",
        "&:hover": {
          color: PURPLE_1,
          backgroundColor: GRAY_8,
        },
        "&$selected": {
          color: GRAY_1,
          backgroundColor: GRAY_7,
          boxSizing: "border-box",
          "&:hover": {
            color: PURPLE_1,
            backgroundColor: GRAY_7,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      groupedHorizontal: {
        // overrides a transparent line between buttons
        "&:not(:first-child)": {
          borderLeft: `1px solid ${GRAY_6}`,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: GRAY_2,
        borderRadius: "5px",
        boxShadow: "0px 4px 10px rgba(10, 36, 64, 0.14)",
        fontSize: "12px",
        padding: "6px 12px",
      },
      arrow: {
        color: GRAY_2,
      },
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          circularBook,
          circularMedium,
          circularBold,
          circularBlack,
          overpassMonoSemiBold,
        ] as unknown as CSSProperties, // Cast due to missing font-face types: https://github.com/mui-org/material-ui/issues/15771
        html: {
          fontSize: "16px",
        },
        body: {
          fontSize: "100%",
        },
      },
    },
    MuiOutlinedInput: {
      adornedStart: {
        border: `1px solid ${GRAY_6}`,
      },
      notchedOutline: {
        border: "none !important",
      },
      root: {
        backgroundColor: WHITE,
        border: `1px solid ${GRAY_5}`,
        borderRadius: "5px",
        boxShadow: `inset 0px 1px 4px rgba(54, 70, 87, 0.14), 0px 0px 0px 0 transparent`,
        transition: "all 125ms",

        "&:hover": {
          border: `1px solid ${PURPLE_1}`,
          transition: "all 125ms",
        },

        "&$focused": {
          border: `1px solid ${PURPLE_1}`,
          boxShadow:
            "inset 0px 1px 4px rgba(54, 70, 87, 0.14), 0px 0px 0px 3px rgba(100, 91, 255, 0.25)",
          transition: "all 125ms",
        },

        "&$error": {
          border: `1px solid ${RED}`,
        },

        "&$disabled": {
          "&:hover": {
            border: `1px solid ${GRAY_6}`,
          },
        },
      },
      input: {
        paddingRight: "1rem",
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
    MuiButton: {
      disableFocusRipple: true,
    },
  },
});
