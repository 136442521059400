import { getFormattedMonthAndDay } from "@asmbl/shared/time";
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { GRAY_1, GRAY_4, GRAY_5, GRAY_6, WHITE } from "src/theme";
import { AssembleButton } from "../AssembleButton/AssembleButton";
import { AddToPhaseIcon } from "../AssembleIcons/Brand/AddToPhaseIcon";
import { EditIcon } from "../AssembleIcons/Small/EditIcon";
import { AssembleTypography } from "../AssembleTypography";
import { usePhaseConfiguration } from "./usePhaseConfiguration";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "4.5rem",

    background: WHITE,

    borderBottom: `1px solid ${GRAY_5}`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    boxShadow: `0px 1px 0px ${GRAY_6}`,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  navContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  navElement: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: `${6 / 16}rem`,
  },
  editDateWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: `${6 / 16}rem`,
  },
}));

type Props = {
  variant: "create" | "edit";
  startDate: Date | undefined;
  endDate: Date | undefined;
  handleConfigComplete: () => void;
  handleEditDateModalOpen: () => void;
};

export function Header({
  variant,
  startDate,
  endDate,
  handleConfigComplete,
  handleEditDateModalOpen,
}: Props): JSX.Element {
  const classes = useStyles();
  const { phaseConfiguration } = usePhaseConfiguration();
  const [showEdit, setShowEdit] = useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.navContainer}>
        <div className={classes.navElement}>
          <AddToPhaseIcon width="1rem" height="1rem" />
          <AssembleTypography variant="productHeader">
            Approval Phases
          </AssembleTypography>
        </div>
        <div className={classes.navElement}>
          <AssembleTypography
            variant="productParagraphMedium"
            textColor={startDate ? GRAY_1 : GRAY_4}
          >
            {startDate ? (
              getFormattedMonthAndDay(startDate, true)
            ) : (
              <i>No start date</i>
            )}
          </AssembleTypography>
          <AssembleTypography
            variant="productParagraphMedium"
            textColor={GRAY_4}
          >
            to
          </AssembleTypography>
          <div
            className={classes.editDateWrapper}
            onMouseEnter={() => setShowEdit(true)}
            onMouseLeave={() => setShowEdit(false)}
            onClick={handleEditDateModalOpen}
          >
            <AssembleTypography
              variant="productParagraphMedium"
              textColor={endDate ? GRAY_1 : GRAY_4}
            >
              {endDate ? (
                getFormattedMonthAndDay(endDate, true)
              ) : (
                <i>No end date</i>
              )}
            </AssembleTypography>
            {showEdit && <EditIcon color={GRAY_4} />}
          </div>
        </div>
        <div className={classes.navElement}>
          <AssembleButton
            size="medium"
            variant="outlined"
            label={variant === "create" ? "Save and Exit Setup" : "Save"}
            onClick={handleConfigComplete}
            disabled={phaseConfiguration.length === 0}
          />
        </div>
      </div>
    </div>
  );
}
