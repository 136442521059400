import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  DeleteMarketDataSet,
  DeleteMarketDataSetVariables,
  ImportMarketDataInput,
  ImportMarketDataVariables,
} from "src/__generated__/graphql";

const IMPORT_MARKET_DATA = gql`
  mutation ImportMarketData($data: ImportMarketDataInput!) {
    importMarketData(data: $data) {
      id
    }
  }
`;

export function useImportMarketData(): (
  data: ImportMarketDataInput
) => Promise<boolean> {
  const [importMarketData] = useMutation<
    ImportMarketDataInput,
    ImportMarketDataVariables
  >(IMPORT_MARKET_DATA);

  return useCallback(
    (data: ImportMarketDataInput) =>
      importMarketData({
        variables: {
          data,
        },
      })
        .then(() => true)
        .catch(() => false),
    [importMarketData]
  );
}

const DELETE_MARKET_DATA_SET = gql`
  mutation DeleteMarketDataSet($id: Int!) {
    deleteMarketDataSet(id: $id)
  }
`;

export function useDeleteMarketDataSet(): (id: number) => Promise<boolean> {
  const [deleteMarketDataSet] = useMutation<
    DeleteMarketDataSet,
    DeleteMarketDataSetVariables
  >(DELETE_MARKET_DATA_SET);

  return useCallback(
    (id: number) =>
      deleteMarketDataSet({ variables: { id } })
        .then(() => true)
        .catch(() => false),
    [deleteMarketDataSet]
  );
}
