import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const ExpandAllIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Expand All"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 8.5V3.75"
        className="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 5.75L8 3.75L6 5.75"
        className="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 7.5C8 9.35499 8 11.75 8 11.75"
        className="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 10.25L8 12.25L10 10.25"
        className="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-5-inside-1_3111_4719" fill="white">
        <rect x="1" width="14" height="16" rx="1" />
      </mask>
      <rect
        className="stroke"
        x="1"
        width="14"
        height="16"
        rx="1"
        strokeWidth="3"
        mask="url(#path-5-inside-1_3111_4719)"
      />
    </svg>
  </ColorableSVG>
);
