import {
  Button,
  Switch,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import fastDeepEqual from "fast-deep-equal";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  CustomFieldInput as CustomField,
  EquityGrantMethod,
  GetEquitySettings,
} from "../../../__generated__/graphql";
import { GRAY_1 } from "../../../theme";
import { NonNull } from "../../../utils";
import { AssembleTypography } from "../../AssembleTypography";
import FormBox from "../../FormBox";
import { IdAnchor } from "../../Layout/IdAnchor";
import { CustomFieldList } from "./CustomFieldList";

type CompStructure = NonNull<GetEquitySettings["compStructure"]>;
type OfferConfig = NonNull<GetEquitySettings["offerConfig"]>;

/*
 * We ultimately display the `customFields` in a drag-n-drop component, and
 * having a unique `key` is important when you are reordering elements of an
 * array. The `CustomField` object isn't stored with a unique key, which is an
 * issue.  As such, we need to give them a unique key when they are passed in.
 */
export type KeyedCustomField = CustomField & { uniqueKey: number };

export type OfferContentsData = {
  companyDescription?: string | null;
  defaultWelcomeMessage?: string | null;
  defaultClosingMessage?: string | null;
  equityFootnote?: string | null;
  sharePriceLabel?: string | null;
  offeredAt?: string;
  showEquityInformation?: boolean;
  showPercentOwnership?: boolean;
  showValuation?: boolean;
  showFdso?: boolean;
  showStage?: boolean;
  showCurrentEquityValue?: boolean;
  showAnnualizedEquity?: boolean;
  requireApproval?: boolean;
  showSharePriceFootnote?: boolean;
  equityCashInValuationCurrency?: boolean;
  customFields?: KeyedCustomField[];
  allowAnonymousDataAccess?: boolean;
};

export type ChangeHandler = <Field extends keyof OfferContentsData>(
  id: Field,
  value: OfferContentsData[Field]
) => unknown;

export type OfferContentsFormProps = {
  compStructure: CompStructure;
  offerConfig: OfferConfig | null;
  onSave: (
    initialFormData: OfferContentsData,
    newFormData: OfferContentsData
  ) => Promise<unknown>;

  allowAnonymousDataAccess: boolean | undefined;
  isSaving: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    margin: theme.spacing(1),
  },
  inputSpacing: {
    margin: theme.spacing(2),
  },
  saveButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export const OfferContentsForm = memo(function OfferContentsForm({
  allowAnonymousDataAccess,
  compStructure,
  offerConfig,
  onSave,
  isSaving,
}: OfferContentsFormProps): JSX.Element {
  const classes = useStyles();
  const pageRef = useRef<HTMLDivElement>(null);
  const { ref: anonymizedSectionRef } = useInView({ root: pageRef.current });

  const initialData = useMemo<OfferContentsData>(() => {
    // If offerConfig is not null, then make sure every pre-existing customField
    // is given a unique key. This is hacky 🤫
    const keyedOfferConfig = !offerConfig ? null : addUniqueKeys(offerConfig);

    return {
      ...compStructure,
      ...keyedOfferConfig,
      allowAnonymousDataAccess,
    };
  }, [compStructure, offerConfig, allowAnonymousDataAccess]);

  const [formData, setFormData] = useState<OfferContentsData>(initialData);

  useEffect(() => setFormData(initialData), [initialData]);

  const handleChange: ChangeHandler = (id, value) => {
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const showEquityInformation = formData.showEquityInformation ?? false;
  const sharePriceLabelIsPreferred =
    formData.sharePriceLabel === "Preferred Price per Share";

  return (
    <div className={classes.root}>
      <AssembleTypography variant="h5" textColor={GRAY_1}>
        Information
      </AssembleTypography>
      <div className={classes.container} />
      <TextField
        fullWidth
        id="companyDescription"
        label="About the Company"
        multiline
        onChange={(e) => handleChange("companyDescription", e.target.value)}
        minRows={6}
        variant="outlined"
        value={formData.companyDescription ?? ""}
        inputProps={{ maxLength: 1000 }}
      />
      <div className={classes.inputSpacing} />
      <TextField
        fullWidth
        id="equityFootnote"
        label="Equity Footnote"
        placeholder="Equity vests over a 4-year timespan, with a 12-month cliff."
        multiline
        onChange={(e) => handleChange("equityFootnote", e.target.value)}
        minRows={6}
        variant="outlined"
        value={formData.equityFootnote ?? ""}
        inputProps={{ maxLength: 500 }}
      />
      <div className={classes.inputSpacing} />
      <TextField
        fullWidth
        id="defaultWelcomeMessage"
        label="Welcome Message"
        helperText="Recruiters can still edit this message when creating offers."
        multiline
        onChange={(e) => handleChange("defaultWelcomeMessage", e.target.value)}
        minRows={6}
        variant="outlined"
        value={formData.defaultWelcomeMessage ?? ""}
        inputProps={{ maxLength: 1000 }}
      />
      <div className={classes.inputSpacing} />
      <TextField
        fullWidth
        id="defaultClosingMessage"
        label="Closing Message"
        helperText="Recruiters can still edit this message when creating offers."
        multiline
        onChange={(e) => handleChange("defaultClosingMessage", e.target.value)}
        minRows={6}
        variant="outlined"
        value={formData.defaultClosingMessage ?? ""}
        inputProps={{ maxLength: 1000 }}
      />
      <div className={classes.inputSpacing} />
      <AssembleTypography variant="h5" textColor={GRAY_1}>
        Equity and valuation information
      </AssembleTypography>
      <div className={classes.container} />
      <FormBox
        control={<Switch />}
        label="Show equity Information"
        onChange={(e, checked) =>
          handleChange("showEquityInformation", checked)
        }
        checked={showEquityInformation}
        reverse
      />

      <FormBox
        control={<Switch />}
        label="Show company financing stage"
        onChange={(e, checked) => handleChange("showStage", checked)}
        checked={(showEquityInformation && formData.showStage) ?? false}
        disabled={!showEquityInformation}
        reverse
      />
      <FormBox
        control={<Switch />}
        label="Show FDSO"
        onChange={(e, checked) => handleChange("showFdso", checked)}
        checked={(showEquityInformation && formData.showFdso) ?? false}
        disabled={!showEquityInformation}
        reverse
      />
      <FormBox
        control={<Switch />}
        label="Show company valuation"
        onChange={(e, checked) => handleChange("showValuation", checked)}
        checked={(showEquityInformation && formData.showValuation) ?? false}
        disabled={!showEquityInformation}
        reverse
      />
      <FormBox
        control={<Switch />}
        label="Label 'Preferred Price per Share' as 'Price per Share'"
        onChange={(e, checked) =>
          handleChange(
            "sharePriceLabel",
            checked ? "Price per Share" : "Preferred Price per Share"
          )
        }
        checked={!sharePriceLabelIsPreferred}
        disabled={!showEquityInformation}
        reverse
      />
      <FormBox
        control={<Switch />}
        label={`Show '${
          formData.sharePriceLabel ?? "Preferred Price per Share"
        }' Footnote`}
        onChange={(_event, checked) => {
          handleChange("showSharePriceFootnote", checked);
        }}
        checked={formData.showSharePriceFootnote}
        reverse
      />
      <Typography variant="body2" color="textSecondary">
        {`When enabled, this will show a footnote stating the ${
          formData.sharePriceLabel ?? "Preferred Price per Share"
        } is "Calculated by dividing the Company
        Valuation by the Fully-Diluted Shares Outstanding"`}
      </Typography>
      <div className={classes.inputSpacing} />

      <AssembleTypography variant="h5" textColor={GRAY_1}>
        Compensation Details
      </AssembleTypography>

      <div className={classes.container} />

      <FormBox
        control={<Switch />}
        label="Show equity cash value in company's valuation currency"
        onChange={(_event, checked) => {
          handleChange("equityCashInValuationCurrency", checked);
        }}
        checked={formData.equityCashInValuationCurrency}
        reverse
      />
      <Typography variant="body2" color="textSecondary">
        When enabled, this will show the cash value of equity grant in company's
        valuation currency when generating and viewing offers
      </Typography>
      <div className={classes.container} />

      <FormBox
        control={<Switch />}
        label="Show current equity value per unit"
        disabled={compStructure.equityGrantMethod !== EquityGrantMethod.UNITS}
        onChange={(_event, checked) => {
          handleChange("showCurrentEquityValue", checked);
        }}
        checked={formData.showCurrentEquityValue}
        reverse
      />
      <Typography variant="body2" color="textSecondary">
        When enabled, this will show the dollar value of each unit of equity
        when generating and viewing offers. This only applies if equity is
        granted as number of units.
      </Typography>

      <div className={classes.container} />

      <FormBox
        control={<Switch />}
        label="Show annualized equity"
        disabled={compStructure.equityGrantMethod !== EquityGrantMethod.UNITS}
        onChange={(_event, checked) =>
          handleChange("showAnnualizedEquity", checked)
        }
        checked={formData.showAnnualizedEquity}
        reverse
      />
      <Typography variant="body2" color="textSecondary">
        Disable this if you're concerned about showing an annual value for
        equity or have a non-standard vesting schedule (e.g., back-loaded). This
        only applies if equity is granted as number of units.
      </Typography>

      <div className={classes.inputSpacing} />

      {allowAnonymousDataAccess != null && (
        <IdAnchor id="anonymized-data" ref={anonymizedSectionRef}>
          <AssembleTypography variant="h5" textColor={GRAY_1}>
            Anonymized Data
          </AssembleTypography>
          <div className={classes.container} />
          <FormBox
            control={<Switch />}
            label="Allow recruiters to view anonymized compensation data for positions and locations"
            onChange={(_, checked) =>
              handleChange("allowAnonymousDataAccess", checked)
            }
            checked={formData.allowAnonymousDataAccess}
            reverse
          />
          <div className={classes.container} />
        </IdAnchor>
      )}
      <AssembleTypography variant="h5" textColor={GRAY_1}>
        Offer Approvals
      </AssembleTypography>
      <div className={classes.container} />
      <FormBox
        control={<Switch />}
        label="Require offer approvals"
        onChange={(e, checked) => handleChange("requireApproval", checked)}
        checked={formData.requireApproval ?? false}
        reverse
      />
      {formData.requireApproval === true && (
        <CustomFieldList
          customFields={formData.customFields ?? []}
          onChange={(newFields: KeyedCustomField[]) => {
            handleChange("customFields", newFields);
          }}
        />
      )}
      <div className={classes.container} />
      <div className={classes.saveButtonContainer}>
        <Button
          color="primary"
          disabled={isSaving || fastDeepEqual(initialData, formData)}
          onClick={() => onSave(initialData, formData)}
          variant="contained"
        >
          {isSaving ? "Saving..." : "Save"}
        </Button>
      </div>
    </div>
  );
});

/* Will take the OfferConfig object and add a `uniqueKey` field to every
 * CustomField in `customFields`
 */
const addUniqueKeys = (offerConfig: OfferConfig) => ({
  ...offerConfig,
  customFields: offerConfig.customFields.map((field) => ({
    ...field,
    uniqueKey: Math.random(),
  })),
});
