import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const NotesIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Notes"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M12.5 14H3.49951C3.3669 14 3.23973 13.9473 3.14596 13.8536C3.05219 13.7598 2.99951 13.6326 2.99951 13.5V2.5C2.99951 2.36739 3.05219 2.24021 3.14596 2.14645C3.23973 2.05268 3.3669 2 3.49951 2H9.5L13 5.5V13.5C13 13.5657 12.9871 13.6307 12.9619 13.6913C12.9368 13.752 12.9 13.8071 12.8536 13.8536C12.8071 13.9 12.752 13.9368 12.6913 13.9619C12.6307 13.9871 12.5657 14 12.5 14Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M6 8.25H10"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M6 10.75H10"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path className="fill" d="M9 2V5C9 5.55228 9.44772 6 10 6H13" />
    </svg>
  </ColorableSVG>
);
