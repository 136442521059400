import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import {
  CostToMoveTopBar_compStructure as CompStructure,
  CostToMoveTopBar_department as Department,
  CostToMoveTopBar_employee as Employee,
  CostToMoveTopBar_market as Market,
} from "src/__generated__/graphql";
import CostToMoveFilterBar from "./CostToMoveFilterBar";
import { CostToMoveFilterParam, FilterOptionsType } from "./CostToMoveFilters";
import { CostToMoveTargetSelector } from "./CostToMoveTargetSelector";

const useStyles = makeStyles(() => ({
  filterAndSelector: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

type Props = {
  compStructure: CompStructure | null;
  managers: Employee[];
  departments: Department[];
  markets: Market[];
  filter: { [key: string]: number[] | null | undefined };
  setFilter: (field: string, values: number[]) => void;
  resetFilters: () => void;
  targetBandPoint: string | null;
  setTargetBandPoint: (value: string) => void;
};

export function CostToMoveTopBar({
  compStructure,
  managers,
  departments,
  markets,
  filter,
  setFilter,
  resetFilters,
  targetBandPoint,
  setTargetBandPoint,
}: Props): JSX.Element {
  const classes = useStyles();

  const filterOptions: FilterOptionsType = {
    [CostToMoveFilterParam.MANAGER]: managers,
    [CostToMoveFilterParam.REPORTS]: [],
    [CostToMoveFilterParam.CURRENT_DEPARTMENT]: departments,
    [CostToMoveFilterParam.CURRENT_LADDER]: departments.flatMap(
      (dep) => dep.ladders
    ),
    [CostToMoveFilterParam.CURRENT_LEVEL]: compStructure?.levels ?? [],
    [CostToMoveFilterParam.LOCATION_GROUP]: markets.flatMap(
      (market) => market.locationGroups
    ),
    [CostToMoveFilterParam.CURRENT_BAND_POSITION]: [],
  };
  return (
    <div className={classes.filterAndSelector}>
      <CostToMoveFilterBar
        onChange={setFilter}
        onReset={resetFilters}
        filter={filter}
        filterOptions={filterOptions}
      />
      <CostToMoveTargetSelector
        compStructure={compStructure}
        targetBandPoint={targetBandPoint}
        setTargetBandPoint={setTargetBandPoint}
      />
    </div>
  );
}

CostToMoveTopBar.fragments = {
  compStructure: gql`
    ${CostToMoveTargetSelector.fragments.compStructure}
    fragment CostToMoveTopBar_compStructure on CompStructure {
      id
      levels
      ...CostToMoveTargetSelector_compStructure
    }
  `,
  employee: gql`
    fragment CostToMoveTopBar_employee on Employee {
      id
      displayName
    }
  `,
  department: gql`
    fragment CostToMoveTopBar_department on Department {
      id
      name
      ladders {
        id
        name
      }
    }
  `,
  market: gql`
    fragment CostToMoveTopBar_market on Market {
      id
      name
      locationGroups {
        id
        name
      }
    }
  `,
};
