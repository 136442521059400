import { makeStyles, TableRow } from "@material-ui/core";
import { memo } from "react";
import { Cell, Row } from "react-table";
import { columnWidthGenerator } from "../../../Table/constants";
import { WireTableCell } from "../../../Table/WireTable/WireTableCell";
import { C, LocationRow } from "./LocationsTable";

const useStyles = makeStyles((theme) => ({
  row: { height: "3.5rem" },
  cell: { paddingRight: theme.spacing(2) },
}));

type Props = {
  row: Row<LocationRow>;
  columns: C[];
};

const MemoizedLocationsTableRow = memo(function LocationsTableRow({
  row,
  columns,
}: Props): JSX.Element {
  const classes = useStyles();
  const rowKey = row.getRowProps().key;
  const getWidth = columnWidthGenerator(12);

  return (
    <TableRow className={classes.row} {...row.getRowProps()} key={rowKey}>
      {row.cells.map((cell: Cell<LocationRow>) => {
        const cellProps = cell.getCellProps();
        const col = columns.find((c) => c.id === cell.column.id) ?? columns[0];

        return (
          <WireTableCell
            width={getWidth(col.width)}
            className={classes.cell}
            {...cellProps}
            key={cellProps.key}
            align={col.align}
          >
            {cell.render("Cell")}
          </WireTableCell>
        );
      })}
    </TableRow>
  );
});

export const LocationsTableRow = Object.assign(MemoizedLocationsTableRow, {});
