import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AssembleTypography } from "../../components/AssembleTypography";
import { DeleteButton } from "../../components/Form/DeleteButton";
import { SaveButton } from "../../components/Form/SaveButton";
import {
  useCreateLadder,
  useDeleteLadder,
  useUpdateLadder,
} from "../../mutations/Ladder";
import { GRAY_5 } from "../../theme";

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(3) },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  context: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(3),
  },
  button: { height: theme.spacing(5) },
  cancelButton: { width: theme.spacing(9.375), borderColor: GRAY_5 },
}));

type LadderData = { id: number; name: string };

type Props = {
  mode: "create" | "edit";
  departmentId: number;
  persistedLadder?: LadderData;
  onCancel: () => unknown;
};

export function LadderForm({
  mode,
  departmentId,
  persistedLadder,
  onCancel,
}: Props): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();
  const createLadder = useCreateLadder();
  const updateLadder = useUpdateLadder();
  const deleteLadder = useDeleteLadder();

  const [formState, setFormState] = useState({
    name: persistedLadder?.name ?? "",
  });

  async function handleSubmit() {
    if (mode === "create") {
      await createLadder({
        departmentId,
        name: formState.name,
      });
    } else if (persistedLadder) {
      await updateLadder({
        id: persistedLadder.id,
        name: formState.name,
      });
    }

    return true;
  }

  async function handleDelete() {
    if (persistedLadder) {
      await deleteLadder(persistedLadder.id);
      navigate(`/departments/${departmentId}`);
    }
  }

  return (
    <Dialog className={classes.root} open onClose={onCancel} fullWidth>
      <DialogTitle id="form-dialog-title" className={classes.title}>
        <AssembleTypography variant="h4">
          {mode === "create" ? "Create" : "Edit"} Ladder
        </AssembleTypography>
      </DialogTitle>
      <DialogContent className={classes.context}>
        <TextField
          autoFocus
          fullWidth
          id="ladder-name"
          label="Ladder Name"
          variant="outlined"
          value={formState.name}
          onChange={(e) => setFormState({ ...formState, name: e.target.value })}
          required
        />
      </DialogContent>
      <DialogActions>
        {mode === "edit" && (
          <>
            <DeleteButton
              buttonType="button"
              objectName="Ladder"
              onDelete={handleDelete}
              size="small"
            />
            <div style={{ flex: 1 }}></div>
          </>
        )}

        <Button
          className={clsx(classes.button, classes.cancelButton)}
          onClick={onCancel}
          variant="outlined"
          size="large"
        >
          <AssembleTypography variant="productSmallBold">
            Cancel
          </AssembleTypography>
        </Button>
        <SaveButton
          className={classes.button}
          onSave={handleSubmit}
          onAfterSave={onCancel}
          cooldown={500}
          hideEndIcon
        />
      </DialogActions>
    </Dialog>
  );
}
