import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import { CloseDrawerIcon } from "../AssembleIcons/Brand/CloseDrawerIcon";

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginLeft: "-14px",
    marginRight: -theme.spacing(1),
    transform: "rotate(180deg)",
  },
}));

type Props = {
  title: string;
  onClose: () => unknown;
};

export function DrawerTitle({ title, onClose }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <IconButton className={classes.backButton} onClick={onClose}>
        <CloseDrawerIcon inherit height="24px" width="24px" />
      </IconButton>
      <Box m={0.5} />
      <Typography variant="h5">{title}</Typography>
    </Box>
  );
}
