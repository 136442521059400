import { makeStyles } from "@material-ui/core";
import { DV_PINK, WHITE } from "src/theme";

const useStyles = makeStyles((theme) => ({
  lateTagContainer: {
    backgroundColor: DV_PINK,
    color: WHITE,
    fontSize: ".625rem",
    borderRadius: 2,
    padding: theme.spacing(0.25, 0.5),
    fontWeight: 500,
  },
}));

export function LateTag() {
  const classes = useStyles();
  return <span className={classes.lateTagContainer}>LATE</span>;
}
