import { gql } from "@apollo/client";
import { Divider, IconButton, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import {
  PhaseConfigurationListElement_employee as Employee,
  PhaseConfigurationListElement_user as User,
} from "src/__generated__/graphql";
import { DeleteIcon } from "src/components/AssembleIcons/Brand/DeleteIcon";
import { GRAY_4, PURPLE_2, RED } from "src/theme";
import { OrdinalCircle } from "../../Settings/Benefits/Editor/OrdinalCircle";
import {
  CompCyclePhaseData,
  usePhaseConfiguration,
} from "../usePhaseConfiguration";
import { PhaseRowDetails } from "./PhaseRowDetails";
import { PhaseRowHeader } from "./PhaseRowHeader";

const useStyles = makeStyles((theme) => ({
  error: {
    border: `1px solid ${RED}`,
  },
  phaseWrapper: {
    padding: theme.spacing(3),
    position: "relative",
  },
  phaseContainer: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
  },
  divider: {
    marginLeft: "-1rem",
    marginRight: "-1rem",
  },
  phaseOrderLabel: {
    paddingTop: theme.spacing(2.5),
  },
  phaseRow: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  hideButtonWrapper: {
    pointerEvents: "none",
  },
  exposeButtonWrapper: {
    opacity: "1 !important",
  },
  // absolute positioning, values here are
  // relative to the parent element
  deleteButtonWrapper: {
    position: "absolute",
    padding: theme.spacing(2),
    top: "-10px",
    right: "-80px",
    opacity: 0,
    transition: "opacity 300ms",
  },
}));

type Props = {
  variant: "create" | "edit";
  endDate: Date;
  index: number;
  isError: boolean;
  users: User[];
  employees: Employee[];
  phaseConfig: CompCyclePhaseData;
  handleViewPhaseAccess: () => void;
  handleDeletePhase: (phaseOrder: number) => void;
  handleEditPhaseAccess: (phaseOrder: number) => void;
  handleEditPhaseDate: (phaseOrder: number) => void;
};

export function PhaseConfigurationListElement({
  variant,
  endDate,
  index,
  isError,
  users,
  employees,
  phaseConfig,
  handleViewPhaseAccess,
  handleDeletePhase,
  handleEditPhaseAccess,
  handleEditPhaseDate,
}: Props): JSX.Element {
  const classes = useStyles();
  const { phaseConfiguration } = usePhaseConfiguration();
  const [isWithinButtonElement, setIsWithinButtonElement] = useState(false);
  const [isWithinPhaseElement, setIsWithinPhaseElement] = useState(false);

  return (
    <div
      className={clsx(classes.phaseWrapper, { [classes.error]: isError })}
      key={phaseConfig.phaseOrder}
      onMouseEnter={() => setIsWithinPhaseElement(true)}
      onMouseLeave={() => setIsWithinPhaseElement(false)}
    >
      <div className={classes.phaseContainer}>
        <div className={classes.phaseOrderLabel}>
          <OrdinalCircle ordinal={phaseConfig.phaseOrder} />
        </div>
        <div className={classes.phaseRow}>
          <PhaseRowHeader
            variant={variant}
            phaseConfig={phaseConfig}
            endDate={endDate}
            viewPhaseAccess={handleViewPhaseAccess}
            editPhaseAccess={() =>
              handleEditPhaseAccess(phaseConfig.phaseOrder)
            }
            editPhaseDate={() => handleEditPhaseDate(phaseConfig.phaseOrder)}
          />
          <PhaseRowDetails
            phaseConfig={phaseConfig}
            employees={employees}
            users={users}
          />
        </div>
      </div>
      {index !== phaseConfiguration.length - 1 && (
        <Divider className={classes.divider} />
      )}
      <div
        className={clsx(classes.deleteButtonWrapper, {
          [classes.hideButtonWrapper]: variant === "edit",
          [classes.exposeButtonWrapper]:
            (isWithinButtonElement || isWithinPhaseElement) &&
            variant === "create",
        })}
        onMouseEnter={() => setIsWithinButtonElement(true)}
        onMouseLeave={() => setIsWithinButtonElement(false)}
      >
        <IconButton onClick={() => handleDeletePhase(phaseConfig.phaseOrder)}>
          <DeleteIcon
            color={GRAY_4}
            hoverColor={PURPLE_2}
            width="24px"
            height="24px"
          />
        </IconButton>
      </div>
    </div>
  );
}

PhaseConfigurationListElement.fragments = {
  employee: gql`
    ${PhaseRowDetails.fragments.employee}
    fragment PhaseConfigurationListElement_employee on Employee2 {
      id
      ...PhaseRowDetails_employee
    }
  `,
  user: gql`
    ${PhaseRowDetails.fragments.user}
    fragment PhaseConfigurationListElement_user on User {
      id
      ...PhaseRowDetails_user
    }
  `,
};
