import { isEmptyString } from "@asmbl/shared/utils";
import { LiveLocationsProvider_market as Market } from "../__generated__/graphql";
import { useCurrencies } from "./CurrenciesContext";
import { AutocompleteSelect } from "./Form/AutocompleteSelect";
import { useLocations } from "./LocationsContext";

type Props = {
  size?: "small" | "medium" | "large";
  forceMarket?: boolean;
  lockedMarket?: Market;
};

export const LocationPicker = ({
  size = "small",
  lockedMarket = undefined,
}: Props): JSX.Element | null => {
  const { markets, selectedLocation, selectMarket, selectLocation } =
    useLocations();
  const { selectCurrency } = useCurrencies();

  if (markets.length === 0 || selectedLocation === null) {
    // No markets or location groups loaded, so don't show a picker.
    return null;
  }

  // if the market is locked, use its source comp group
  const lockedMarketSourceComp = lockedMarket
    ? lockedMarket.locationGroups.find((lg) => lg.isSourceComp)
    : undefined;
  const [selectedMarket, selectedLocationGroup] =
    lockedMarket && lockedMarketSourceComp
      ? [lockedMarket, lockedMarketSourceComp]
      : selectedLocation;

  const labelInfo = !isEmptyString(selectedLocationGroup.description)
    ? {
        title: selectedLocationGroup.name,
        body: selectedLocationGroup.description as string,
      }
    : undefined;

  const onChangeMarket = (value: string) => {
    const id = parseInt(value);
    const newMarket = markets.find((market) => market.id === id) || markets[0];
    selectMarket(newMarket);

    // When changing market, auto-select the default currency of the market:
    if (newMarket !== selectedMarket) {
      selectCurrency(newMarket.currencyCode);
    }
  };

  const onChangeLocationGroup = (value: string) => {
    const id = parseInt(value);
    selectLocation(
      selectedMarket.locationGroups.find((group) => group.id === id) ||
        selectedMarket.locationGroups[0]
    );
  };

  return (
    <>
      {
        // Show the Markets picker only if there are multiple markets
        markets.length > 1 && (
          <AutocompleteSelect
            disabled={lockedMarket != null}
            label="Market"
            labelInfo={{
              title: "Market",
              body: (
                <>
                  A market is a comp structure with some combination of unique
                  compensation bands, location groups, geographic differentials,
                  and/or currency.
                </>
              ),
            }}
            size={size}
            onChange={onChangeMarket}
            value={selectedMarket.id.toString()}
            options={markets.map((market) => ({
              value: market.id.toString(),
              label: market.name,
            }))}
            intercomTarget="Market Select"
          />
        )
      }
      {
        /* Show the Location Group picker if there are multiple Location Groups
          (either in this market or across multiple markets). Location Group
          options include Source Comp, so we only check for one other group. */
        (markets.length > 1 || selectedMarket.locationGroups.length > 1) && (
          <AutocompleteSelect
            disabled={lockedMarket != null}
            label="Location Group"
            size={size}
            labelInfo={labelInfo}
            onChange={onChangeLocationGroup}
            value={selectedLocationGroup.id.toString()}
            options={selectedMarket.locationGroups.map((group) => ({
              value: group.id.toString(),
              label: group.name,
            }))}
            intercomTarget="Location Select"
          />
        )
      }
    </>
  );
};
