import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { SupportedManagersDrawer_manager as Manager } from "src/__generated__/graphql";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { ChevronRightIcon } from "src/components/AssembleIcons/Brand/ChevronRightIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_4, GRAY_6, GRAY_8, PURPLE_1, WHITE } from "src/theme";

type Props = {
  manager: Manager;
  managers: Manager[];
  toggleManagerSelected: (ids: number) => unknown;
  selectedManagerIds: number[];
  hideDropdown?: boolean;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    border: `solid 1px ${GRAY_6}`,
    borderRadius: 4,
    marginTop: theme.spacing(1.25),
    boxShadow: "0px 1px 3px rgba(10, 36, 64, 0.08)",
    width: 360,
    height: 60,
    "&:hover": {
      background: GRAY_8,
    },
  },
  transition: {
    transition: "all 250ms",
  },
  selectedContainer: {
    borderColor: PURPLE_1,
  },
  expandButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: theme.spacing(3),
    borderRight: `solid 1px ${GRAY_6}`,
  },
  expandButtonDisabled: {
    display: "none",
  },
  rightContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    cursor: "pointer",
  },
  lightText: {
    color: GRAY_4,
  },
  reportsCount: {
    display: "flex",
    width: "100%",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  managementLayer: {
    paddingLeft: theme.spacing(2.5),
  },
  eyebrow: {
    fontSize: ".6875rem",
  },
  purple: {
    color: PURPLE_1,
  },
}));

export const getManagementLayer = <T extends { managerId: number | null }>(
  managers: T[],
  parentId: number | null
) => managers.filter((manager) => manager.managerId === parentId);

export function ManagerCard({
  manager,
  managers,
  toggleManagerSelected,
  selectedManagerIds,
  hideDropdown = false,
}: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const classes = useStyles();
  const nextManagementLayer = getManagementLayer(managers, manager.id);

  const selectedManagers = managers.filter((manager) =>
    selectedManagerIds.includes(manager.id)
  );

  const isSelected =
    selectedManagerIds.includes(manager.id) ||
    selectedManagers.some(
      (selectedManager) =>
        selectedManager.directReports.map((dr) => dr.id).includes(manager.id) ||
        selectedManager.indirectReports.map((ir) => ir.id).includes(manager.id)
    );

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={clsx(classes.container, classes.transition, {
          [classes.selectedContainer]: isSelected,
        })}
      >
        <div
          className={clsx(classes.expandButton, {
            [classes.expandButtonDisabled]: nextManagementLayer.length === 0,
          })}
          onClick={
            nextManagementLayer.length === 0
              ? undefined
              : () => setIsExpanded(!isExpanded)
          }
        >
          {hideDropdown ? null : isExpanded ? (
            <ChevronDownIcon color={GRAY_4} />
          ) : (
            <ChevronRightIcon
              color={nextManagementLayer.length === 0 ? WHITE : GRAY_4}
            />
          )}
        </div>
        <div
          className={classes.rightContainer}
          onClick={() => toggleManagerSelected(manager.id)}
        >
          <AssembleTypography
            noWrap
            variant="productEyebrowSmall"
            className={clsx(classes.lightText, classes.eyebrow)}
          >
            {manager.activeEmployment?.jobTitle}
          </AssembleTypography>
          <span className={classes.reportsCount}>
            <AssembleTypography
              variant="productParagraphMedium"
              noWrap
              className={clsx(classes.transition, {
                [classes.purple]: isHovered,
              })}
            >
              {manager.displayName}
            </AssembleTypography>
            <AssembleTypography
              variant="productParagraphMedium"
              className={classes.lightText}
            >
              {manager.indirectReports.length + manager.directReports.length}
            </AssembleTypography>
          </span>
        </div>
      </div>
      {isExpanded && nextManagementLayer.length > 0 ? (
        <div className={classes.managementLayer}>
          {nextManagementLayer.map((nextManager) => (
            <ManagerCard
              key={`manager-${manager.id}`}
              manager={nextManager}
              managers={managers}
              toggleManagerSelected={toggleManagerSelected}
              selectedManagerIds={selectedManagerIds}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}
