import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const NewWindowIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="New Window"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2.54545L2 13.4545C2 13.7558 2.24421 14 2.54545 14H13.4545C13.7558 14 14 13.7558 14 13.4545V2.54545C14 2.24421 13.7558 2 13.4545 2H2.54545C2.24421 2 2 2.24421 2 2.54545Z"
        className="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 6H10V9"
        className="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 10L10 6"
        className="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
