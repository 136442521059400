import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  LinearProgress,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { NoOffersCreated } from "../../components/EmptyState/EmptyState";
import { PageHeader, PageTitle } from "../../components/Layout/PageHeader";
import { atsCandidateQuery } from "../../components/OfferGeneration/Candidate/CandidateLoadingBoundary";
import { ARCHIVE_OFFER } from "../../mutations";
import { GET_OFFERS } from "../../queries";
import {
  ArchiveOneOffer,
  ArchiveOneOfferVariables,
  GetOffers,
} from "../../__generated__/graphql";
import OfferListTable from "./OfferListTable";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

export type Offer = {
  id: number;
  offeredAt: Date;
  updatedAt: Date | null;
  title: string | null;
  candidateName: string;
  positionLevel?: number | null;
  positionName?: string | null;
  expiredAt?: Date | null;
  isLegacy: boolean;
};

export const OfferList = memo(function OfferList() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { data, loading, client } = useQuery<GetOffers>(GET_OFFERS);
  // While we don't use this data here, we query for it now to store it in the
  // Apollo cache. This query can theoretically take a long time, so we want to
  // start it as soon as possible.
  void client.query({ query: atsCandidateQuery });

  const [archiveOffer, { loading: archiveOfferLoading }] = useMutation<
    ArchiveOneOffer,
    ArchiveOneOfferVariables
  >(ARCHIVE_OFFER);

  const handleArchive = async (id: number) => {
    await archiveOffer({
      variables: { id },
      refetchQueries: [{ query: GET_OFFERS }],
    });
  };

  if (loading) return <LinearProgress />;
  if (!data) {
    return (
      <Typography variant="h6">
        Sorry, something went wrong. Please contact us for support.
      </Typography>
    );
  }

  const computedOffers: Offer[] = data.offers.map((x) => ({
    id: x.id,
    offeredAt: new Date(x.offeredAt),
    updatedAt: x.updatedAt === null ? null : new Date(x.updatedAt),
    title: x.title,
    candidateName: x.candidate.candidateName,
    positionName: x.position?.name,
    positionLevel: x.position?.level,
    expiredAt: x.expiredAt === null ? null : new Date(x.expiredAt),
    isLegacy: x.isLegacy,
  }));

  return (
    <>
      <PageHeader>
        <PageTitle>Offer Letters</PageTitle>
        <Button
          color="primary"
          onClick={() => navigate("/offers/new")}
          variant="contained"
        >
          Create Offer
        </Button>
      </PageHeader>
      <div className={classes.root}>
        {computedOffers.length === 0 ? (
          <NoOffersCreated />
        ) : (
          <OfferListTable
            computedOffers={computedOffers}
            handleArchiveOffer={handleArchive}
            archiveOfferLoading={archiveOfferLoading}
          />
        )}
      </div>
    </>
  );
});
