import { AssembleButton } from "../../AssembleButton/AssembleButton";
import { AllCompleteReviewDialogState } from "../Dialogs/CompleteReviewDialog";

interface Props {
  compCycle: {
    name: string;
    id: number;
    // include both fields so the button works for CompCycle & CompCycle2
    closeDate?: string | Date | null;
    endedAt?: string | Date | null;
  };
  setDialogState: (state: AllCompleteReviewDialogState) => void;
}

export function CompleteCompCycleButton({
  compCycle,
  setDialogState,
}: Props): JSX.Element | null {
  const endDate = compCycle.endedAt ?? compCycle.closeDate;
  return (
    <AssembleButton
      variant="contained"
      onClick={() => setDialogState("open")}
      disabled={endDate != null}
      id="page-complete-comp-cycle-button"
      label="Close Cycle"
      size="medium"
    />
  );
}
