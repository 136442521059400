import { useQuery } from "@apollo/client";
import { caseInsensitiveComparator, contramap } from "@asmbl/shared/sort";
import {
  Box,
  Chip,
  FormControl,
  LinearProgress,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { Autocomplete, AutocompleteGetTagProps } from "@material-ui/lab";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { UserPermissions } from "src/models/UserPermissions";
import { GetLaddersAndPositions, Noun } from "../../../__generated__/graphql";
import { AssemblePopper } from "../../../components/AssemblePopper";
import { useCurrencies } from "../../../components/CurrenciesContext";
import { CurrencyPicker } from "../../../components/CurrencyPicker";
import { NoCompStructure } from "../../../components/EmptyState/EmptyState";
import { LocationPicker } from "../../../components/LocationPicker";
import { useLocations } from "../../../components/LocationsContext";
import { DV_BAND_COLORS } from "../../../constants";
import { LADDERS_AND_POSITIONS_QUERY } from "../../../queries";
import { WHITE } from "../../../theme";
import {
  ArrayValue,
  bandNameComparator,
  getContrastTextColor,
} from "../../../utils";
import { ExcludedBandNamesProvider } from "../../LadderDetail/ExcludedBandNameContext";
import { SelectedLaddersLoader } from "./SelectedLaddersLoader";

type Ladder = ArrayValue<GetLaddersAndPositions["ladders"]>;

export type ColoredLadder = { ladder: Ladder; color: string };

const useStyles = makeStyles((theme) => ({
  search: {
    background: WHITE,
  },
  compareChipIcon: {
    color: "currentcolor",
    marginLeft: theme.spacing(1),
  },
  compareChipDeletable: {
    color: WHITE,
    "&:hover": {
      color: WHITE,
    },
  },
  popUpIndicator: {
    height: "100%",
    transition: "transform cubic-bezier(0.4, 0, 0.2, 1) 275ms",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: theme.spacing(1.5),
  },
  chipInputContainer: { flexGrow: 3 },
  currencyInputContainer: { flexGrow: 1 },
  selectLocationAndMarketInputContainer: {
    flexGrow: 2,
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(1.5),
  },
  onlyLocationOrMarketInputContainer: {
    flexGrow: 1,
  },
}));

export function Compare(): JSX.Element {
  const classes = useStyles();
  const { currenciesList } = useCurrencies();
  const { markets, selectedLocation } = useLocations();
  const [selectedMarket] = selectedLocation ?? [];
  const [selectedLadders, setSelectedLadders] = useState<ColoredLadder[]>([]);

  const { data, loading, error } = useQuery<GetLaddersAndPositions>(
    LADDERS_AND_POSITIONS_QUERY
  );

  useEffect(() => {
    const storedLadders = sessionStorage.getItem("selectedLadders");
    if (storedLadders !== null) {
      const ladders = JSON.parse(storedLadders) as Ladder[];
      const coloredLadders: ColoredLadder[] = [];
      for (const ladder of ladders) {
        coloredLadders.push(assignColor(coloredLadders, ladder));
      }

      setSelectedLadders(coloredLadders);
    }
  }, []);

  const sortedLadders = useMemo(() => {
    const ladders = data?.ladders.filter((ladder) =>
      ladder.positions.some(
        (position) =>
          position.areCashBandsAuthorized || position.areEquityBandsAuthorized
      )
    );

    return (
      ladders?.sort((a, b) => {
        // Sort by department name, then ladder name
        const departmentCompare = caseInsensitiveComparator(
          a.department.name,
          b.department.name
        );
        return departmentCompare || caseInsensitiveComparator(a.name, b.name);
      }) ?? []
    );
  }, [data?.ladders]);

  if (loading) return <LinearProgress />;
  if (error) return <p>{JSON.stringify(error)}</p>;
  if (!data) return <p>Not found</p>;
  if (!data.compStructure) return <NoCompStructure />;

  const handleSearchSelect = (ladders: Ladder[]) => {
    // The ordering isn't important as long as it's consistent across components
    const sorted = ladders.sort(contramap((ladder) => ladder.id));
    const coloredLadders: ColoredLadder[] = [];
    for (const ladder of ladders) {
      const previouslySelected = selectedLadders.find(
        (l) => l.ladder.id === ladder.id
      );
      coloredLadders.push(
        previouslySelected ?? assignColor(selectedLadders, ladder)
      );
    }
    setSelectedLadders(coloredLadders);
    sessionStorage.setItem("selectedLadders", JSON.stringify(sorted));
  };

  const renderOption = (ladder: Ladder) => (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      data-cy={`ladder-select-${ladder.name}`}
    >
      <Box />
      {ladder.name}
    </Box>
  );

  const renderTags = (
    tagValue: Ladder[],
    getTagProps: AutocompleteGetTagProps
  ) =>
    tagValue.map((ladder, index) => {
      const color = selectedLadders.find(
        (l) => l.ladder.id === ladder.id
      )?.color;
      return (
        <Chip
          key={ladder.id}
          label={ladder.name}
          {...getTagProps({ index })}
          style={{
            height: "1.5rem",
            color: getContrastTextColor(color ?? DV_BAND_COLORS[0]),
            backgroundColor: color,
            transition: "color 0s, background-color 0s",
          }}
          classes={{
            icon: classes.compareChipIcon,
            deleteIcon: classes.compareChipDeletable,
          }}
        />
      );
    });

  const renderCurrencyInput =
    currenciesList.length === 1 || selectedLocation === null ? false : true;

  const renderMarketsInput = markets.length > 1;

  const renderLocationGroupInput =
    (selectedMarket?.locationGroups.length ?? 0) > 0;

  const renderMarketAndLocationGroupInput =
    renderMarketsInput || renderLocationGroupInput;

  return (
    <Box px={2} py={2}>
      <Box />
      <div className={classes.searchContainer}>
        <div className={classes.chipInputContainer}>
          <Autocomplete
            fullWidth
            PopperComponent={AssemblePopper}
            className={classes.search}
            classes={{
              popupIndicator: classes.popUpIndicator,
            }}
            multiple
            size="small"
            id="compare-search"
            options={sortedLadders}
            groupBy={(ladder) => ladder.department.name}
            getOptionLabel={(ladder) => ladder.name}
            getOptionSelected={(prev, next) => prev.id === next.id}
            defaultValue={[]}
            disableCloseOnSelect
            filterSelectedOptions
            openOnFocus
            value={selectedLadders.map((l) => l.ladder)}
            onChange={(_event, values) => handleSearchSelect(values)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Search ladders"
                fullWidth
              />
            )}
            renderOption={renderOption}
            renderTags={renderTags}
            popupIcon={<ChevronDownIcon inherit />}
          />
        </div>

        {/* need to render check so the form control doesn't take space */}
        {renderMarketAndLocationGroupInput && (
          <FormControl
            className={clsx(classes.selectLocationAndMarketInputContainer, {
              [classes.onlyLocationOrMarketInputContainer]:
                (renderMarketsInput && !renderLocationGroupInput) ||
                (renderLocationGroupInput && !renderMarketsInput),
            })}
          >
            <LocationPicker forceMarket size="medium" />
          </FormControl>
        )}

        {/* need to render check so the form control doesn't take space */}
        {renderCurrencyInput && (
          <FormControl className={classes.currencyInputContainer}>
            <CurrencyPicker size="medium" />
          </FormControl>
        )}
      </div>
      <Box m={2} />
      <ExcludedBandNamesProvider
        initialExcludedBands={
          new Set(
            data.compStructure.allBandTypes
              .slice()
              .sort(bandNameComparator)
              .slice(1)
          )
        }
      >
        <SelectedLaddersLoader
          selectedLadders={selectedLadders}
          compStructure={data.compStructure}
        />
      </ExcludedBandNamesProvider>
    </Box>
  );
}

function assignColor(
  coloredLadders: ColoredLadder[],
  ladder: Ladder,
  maxPerColor = 1
): ColoredLadder {
  const color = DV_BAND_COLORS.find(
    (color) =>
      coloredLadders.filter((ladder) => ladder.color === color).length <
      maxPerColor
  );

  return color
    ? { ladder, color }
    : assignColor(coloredLadders, ladder, maxPerColor + 1);
}

Compare.canAccess = (permissions: UserPermissions): boolean => {
  return [Noun.JobStructure, Noun.CashBand, Noun.EquityBand].every((noun) =>
    permissions.canViewAny(noun)
  );
};

Compare.path = "bands";
