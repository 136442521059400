import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useState } from "react";
import { useIntercom } from "react-use-intercom";
import { useTrack } from "src/analytics";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { AssembleTypography } from "src/components/AssembleTypography";
import {
  SelectionMode,
  useCompCycleOrganizationChartSelectionData,
} from "src/components/CompCycleOrganizationChart/CompCycleOrganizationChartContext";

type Props = { onBack: () => void };

export function CreatePhasesModal({ onBack }: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const intercom = useIntercom();
  const { layerDepth, selectionMode, employeeData } =
    useCompCycleOrganizationChartSelectionData();
  const [isModalOpen, setIsModalOpen] = useState(true);

  // need to account for the synthetic root node
  const numberOfManagementLayers = layerDepth - 1;

  const onBackButtonClick = () => onBack();
  const onContinueButtonClick = () => {
    trackEvent({
      object: "Create Your Phases Button",
      action: "Clicked",
      numberOfManagementLayers,
    });
    intercom.trackEvent("Create Your Phases Button Clicked", {
      numberOfManagementLayers,
    });

    setIsModalOpen(false);
  };

  return (
    <Dialog open={isModalOpen}>
      <DialogTitle>Let's create your cycle approval phases</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {selectionMode === SelectionMode.Layers ? (
            <AssembleTypography variant="productParagraphMedium">
              We detected
              <strong> {numberOfManagementLayers} management layers </strong>
              from your employee data. Add these layers to phases in your cycle
              to create an approval hierarchy.&nbsp;
              <strong>Get started and we'll walk you through it.</strong>
            </AssembleTypography>
          ) : (
            <AssembleTypography variant="productParagraphMedium">
              We detected
              <strong> {employeeData.length} employees </strong> with reports
              from your employee data. Add these managers to phases in your
              cycle to create an approval hierarchy.&nbsp;
            </AssembleTypography>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <AssembleButton
          size="small"
          variant="outlined"
          label="Back"
          onClick={onBackButtonClick}
        />
        <AssembleButton
          size="small"
          variant="contained"
          label="Create Your Phases"
          onClick={onContinueButtonClick}
        />
      </DialogActions>
    </Dialog>
  );
}
