import { PrimaryRoleName } from "@asmbl/shared/permissions";
import { makeStyles } from "@material-ui/core";
import {
  SupportedManagersDrawer_manager,
  SupportedManagersRow_manager,
} from "src/__generated__/graphql";
import { NoSupportedManagersIcon } from "src/components/AssembleIcons/Brand/NoSupportedManagersIcon";
import { UserPermissions } from "src/models/UserPermissions";
import FormHeader from "../../FormHeader";
import { SupportedManagersDrawer } from "./SupportedManagersDrawer";
import { SupportedManagersTable } from "./SupportedManagersTable";
import { SupportedManagersTooltip } from "./SupportedManagersTooltip";

const useStyles = makeStyles((theme) => ({
  section: {
    paddingBlock: theme.spacing(4),
    paddingInline: theme.spacing(5),
  },
  heading: { display: "flex" },
  emptyContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(5),
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

type Props = {
  userPermissions: UserPermissions;
  userName: string;
  supportedManagerIds: number[];
  managers: SupportedManagersDrawer_manager[];
  handleSave: (employeeIds: number[]) => Promise<unknown>;
};

export function SupportedManagersSection({
  userPermissions,
  managers,
  userName,
  handleSave,
  supportedManagerIds,
}: Props) {
  const classes = useStyles();

  if (userPermissions.roleName !== PrimaryRoleName.HRBP) return null;

  const supportedManagers = managers.filter((manager) =>
    supportedManagerIds.includes(manager.id)
  );

  return (
    <div className={classes.section}>
      <div className={classes.headerContainer}>
        <FormHeader
          header={
            <div className={classes.heading}>
              Supported Managers <SupportedManagersTooltip />
            </div>
          }
          description={
            managers.length === 0
              ? `${userName} doesn't support any managers as of now.`
              : ""
          }
        />
        <SupportedManagersDrawer
          userName={userName}
          managers={managers}
          handleSave={handleSave}
          supportedManagerIds={supportedManagerIds}
        />
      </div>
      {managers.length === 0 ? (
        <div className={classes.emptyContainer}>
          <NoSupportedManagersIcon />
        </div>
      ) : (
        <SupportedManagersTable
          allManagers={managers as SupportedManagersRow_manager[]}
          supportedManagers={
            supportedManagers as SupportedManagersRow_manager[]
          }
        />
      )}
    </div>
  );
}
