import { isZero } from "@asmbl/shared/money";
import { makeStyles } from "@material-ui/core";
import { InfoIcon } from "src/components/AssembleIcons/Brand/InfoIcon";
import { BenefitsPackage, getTotalValue } from "../../../../models/Benefits";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { GRAY_4, PURPLE_1 } from "../../../../theme";
import { AssembleTypography } from "../../../AssembleTypography";
import { ExplanatoryTooltip } from "../../../ExplanatoryTooltip";

type Props = {
  benefitsPackage: BenefitsPackage;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

export function TotalValue({ benefitsPackage }: Props): JSX.Element | null {
  const classes = useStyles();

  const totalValue = getTotalValue(benefitsPackage);

  if (isZero(totalValue)) {
    return null;
  }

  return (
    <div className={classes.root}>
      <AssembleTypography variant="productEyebrowSmall">
        Total:
      </AssembleTypography>
      <AssembleTypography variant="body1">
        <b>{getSimpleCashLabel(totalValue)}</b>
      </AssembleTypography>
      <ExplanatoryTooltip
        title="Estimated Annual Value"
        body={
          <>
            This is the company's estimated annual contribution based on the
            values provided. It will be shown to candidates and employees
            assigned this package.
          </>
        }
        placement="top"
        width="25rem"
      >
        <div>
          <InfoIcon
            color={GRAY_4}
            hoverColor={PURPLE_1}
            inline
            width="14px"
            height="14px"
          />
        </div>
      </ExplanatoryTooltip>
    </div>
  );
}
