import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import { memo, ReactChild } from "react";
import { GRAY_1, GRAY_2 } from "../../theme";

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const useStyles = makeStyles((theme: Theme) => ({
  header: {
    color: GRAY_1,
    marginBottom: theme.spacing(1),
  },
  description: {
    color: GRAY_2,
  },
  disabled: {
    opacity: 0.32,
  },
}));

//  ----------------------------------------------------------------------------
//  Types
//  ----------------------------------------------------------------------------
type FormHeaderProps = {
  className?: string;
  description?: ReactChild;
  header: ReactChild;
  disabled?: boolean;
  id?: string;
  mb?: number;
};

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
const FormHeader = ({
  className = "",
  description,
  header,
  disabled,
  id,
  mb = 4,
}: FormHeaderProps) => {
  const classes = useStyles();

  return (
    <Box
      mb={mb}
      className={`${className} ${disabled === true ? classes.disabled : ""}`}
    >
      <Typography id={id} variant="h4" className={classes.header}>
        {header}
      </Typography>
      {description !== undefined && (
        <Typography variant="body2" className={classes.description}>
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default memo(FormHeader);
