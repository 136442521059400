import { useQuery } from "@apollo/client";
import { GET_BENEFITS_PACKAGES } from "../../../queries";
import { GetBenefitsPackages } from "../../../__generated__/graphql";
import Loading from "../Loading";
import { BenefitsPackageList } from "./BenefitsPackageList/BenefitsPackageList";

export function BenefitsLoadingBoundary(): JSX.Element {
  const { data, loading, error } = useQuery<GetBenefitsPackages>(
    GET_BENEFITS_PACKAGES
  );

  if (loading) {
    return <Loading />;
  }
  if (error || data === undefined) {
    return <>An error occurred.</>;
  }

  return <BenefitsPackageList benefitsPackages={data.benefitsPackages} />;
}
