export enum CostToMoveFilterName {
  MANAGER = "Manager",
  REPORTS = "Reports",
  CURRENT_DEPARTMENT = "Current Department",
  CURRENT_LADDER = "Current Ladder",
  CURRENT_LEVEL = "Current Level",
  LOCATION_GROUP = "Location Group",
  CURRENT_BAND_POSITION = "Current Band Position",
}

export enum CostToMoveFilterParam {
  MANAGER = "managerIds",
  REPORTS = "reportIds",
  CURRENT_DEPARTMENT = "departmentIds",
  CURRENT_LADDER = "ladderIds",
  CURRENT_LEVEL = "levels",
  LOCATION_GROUP = "locationGroupIds",
  CURRENT_BAND_POSITION = "currentBandPosition",
}

export type FilterOptionsType = {
  [CostToMoveFilterParam.MANAGER]: { id: number; displayName: string }[];
  [CostToMoveFilterParam.CURRENT_DEPARTMENT]: { name: string; id: number }[];
  [CostToMoveFilterParam.CURRENT_LADDER]: { name: string; id: number }[];
  [CostToMoveFilterParam.CURRENT_LEVEL]: number[];
  [CostToMoveFilterParam.LOCATION_GROUP]: { name: string; id: number }[];
  [CostToMoveFilterParam.REPORTS]: [];
  [CostToMoveFilterParam.CURRENT_BAND_POSITION]: [];
};
