import {
  Box,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { InfoIcon } from "src/components/AssembleIcons/Brand/InfoIcon";
import { GRAY_4, PURPLE_2, WHITE } from "../../../theme";

export const useStyles = makeStyles(() => ({
  tooltip: { minWidth: "400px" },
}));

export function CompensationBandSectionTooltip(): JSX.Element {
  const classes = useStyles();
  return (
    <Box ml={1}>
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={
          <Box p={1}>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              Compensation Band Access
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <Typography
                component="span"
                variant="body2"
                style={{ color: WHITE }}
              >
                Compensation Band Access
              </Typography>{" "}
              gives you the power to show compensation information within
              specific parts of your Job Structure for each person in your org.
            </Typography>
          </Box>
        }
        placement="top"
        arrow
        interactive
      >
        <IconButton size="small">
          <InfoIcon color={GRAY_4} hoverColor={PURPLE_2} />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
