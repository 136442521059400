import DraftEditor from "@draft-js-plugins/editor";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import Draft, {
  DraftEditorCommand,
  DraftStyleMap,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
} from "draft-js";
import "draft-js/dist/Draft.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { MONOSPACED_FONT } from "../../theme";
import { RTEToolbar } from "./RTEToolbar/RTEToolbar";

export type RichTextEditorProps = {
  autoFocus?: boolean;
  children: (editor: EditorTools) => JSX.Element;
  onBlur?: (e: React.SyntheticEvent) => void;
  editorState: EditorState;
  setEditorState: (state: EditorState) => void;
  onFocus?: (e: React.SyntheticEvent) => void;
  placeholder?: string;
  refCallback?: (instance: DraftEditor) => void;
  readOnly?: boolean;
};

type EditorTools = {
  Editor: () => JSX.Element;
  Toolbar: () => JSX.Element;
};

export const RichTextEditor = (props: RichTextEditorProps): JSX.Element => {
  const {
    autoFocus = false,
    children,
    editorState,
    setEditorState,
    onBlur,
    onFocus,
    placeholder = "",
    refCallback,
    readOnly,
  } = props;

  const [{ Plugins, StaticToolbar }] = useState(() => {
    const linkifyPlugin = createLinkifyPlugin({
      target: "_blank",
      rel: "noopener noreferrer",
    });

    const staticToolbarPlugin = createToolbarPlugin({
      theme: {
        toolbarStyles: {
          toolbar: "RichTextEditor-Toolbar",
        },
        buttonStyles: {},
      },
    });

    const { Toolbar } = staticToolbarPlugin;
    const plugins = [staticToolbarPlugin, linkifyPlugin];

    return {
      Plugins: plugins,
      StaticToolbar: Toolbar,
    };
  });

  const ref = useRef<DraftEditor | null>(null);
  const setDOMRef = (node: DraftEditor | null) => {
    if (node && refCallback) {
      refCallback(node);
    }
    ref.current = node;
  };

  useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.focus();
    }
  }, [autoFocus]);

  const getBlockClassNames = (block: Draft.ContentBlock): string => {
    switch (block.getType()) {
      case "blockquote":
        return "RichTextEditor-Blockquote";
      case "header-one":
        return "MuiTypography-root MuiTypography-h1";
      case "header-two":
        return "MuiTypography-root MuiTypography-h2";
      case "header-three":
        return "MuiTypography-root MuiTypography-h3";
      case "header-four":
        return "MuiTypography-root MuiTypography-h4";
      case "header-five":
        return "MuiTypography-root MuiTypography-h5";
      case "header-six":
        return "MuiTypography-root MuiTypography-h6";
      case "paragraph":
      case "unstyled":
      case "unordered-list-item":
      case "ordered-list-item":
        return "MuiTypography-root MuiTypography-body1";
      default:
        return "";
    }
  };

  const styleMap: DraftStyleMap = {
    CODE: {
      fontFamily: MONOSPACED_FONT,
    },
  };

  const getPlaceholder = useCallback(() => {
    if (!placeholder) {
      return "";
    }

    const contentState = editorState.getCurrentContent();
    if (
      !contentState.hasText() &&
      contentState.getBlockMap().first().getType() !== "unstyled"
    ) {
      // If a style is applied, the placeholder may no longer line up with the
      // cursor.
      return "";
    }

    return placeholder;
  }, [editorState, placeholder]);

  const handleKeyCommand = useCallback(
    (command: DraftEditorCommand) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        setEditorState(newState);
        return "handled";
      }
      return "not-handled";
    },
    [editorState, setEditorState]
  );

  const mapKeyToEditorCommand = useCallback(
    (...args: Parameters<typeof getDefaultKeyBinding>) => {
      const e = args[0];
      if (e.keyCode === 9) {
        const newEditorState = RichUtils.onTab(
          e,
          editorState,
          4 /* maxDepth */
        );
        setEditorState(newEditorState);
        return null;
      }
      return getDefaultKeyBinding(e);
    },
    [editorState, setEditorState]
  );

  return children({
    Editor: function Editor() {
      return (
        <DraftEditor
          blockStyleFn={getBlockClassNames}
          customStyleMap={styleMap}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={mapKeyToEditorCommand}
          onBlur={onBlur}
          onChange={setEditorState}
          onFocus={onFocus}
          placeholder={getPlaceholder()}
          plugins={Plugins}
          ref={setDOMRef}
          readOnly={readOnly}
        />
      );
    },
    Toolbar: function Toolbar() {
      return (
        <StaticToolbar>
          {(externalProps: unknown) => (
            <RTEToolbar
              externalProps={externalProps}
              editorState={editorState}
              onToggleStyle={setEditorState}
            />
          )}
        </StaticToolbar>
      );
    },
  });
};
