import { gql } from "@apollo/client";
import { formatCurrency } from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import {
  calculateEquityFromVestEvents,
  filterVestEventsForNext12Months,
  VestEvent,
} from "../../../models/EquityVestEvent";
import { GRAY_4 } from "../../../theme";
import {
  TotalEquityNext12MonthsChartHeader_equityGrantVestEvent as EquityGrantVestEvent,
  TotalEquityNext12MonthsChartHeader_equityHoldings as EquityHoldings,
} from "../../../__generated__/graphql";
import { AssembleTypography } from "../../AssembleTypography";
import { useCurrencies } from "../../CurrenciesContext";
import { useTotalEquitySlider } from "../../TotalEquitySlider/TotalEquitySliderContext";

const useStyles = makeStyles(() => ({
  element: {
    display: "flex",
    flexDirection: "column",
  },
  header: { color: GRAY_4 },
  cash: { fontWeight: 500 },
  units: { letterSpacing: "-1px" },
}));

type Props = {
  equityGrantVestEvents: EquityGrantVestEvent[];
  equityHoldings: EquityHoldings;
};

export function TotalEquityNext12MonthsChartHeader({
  equityGrantVestEvents,
  equityHoldings,
}: Props): JSX.Element {
  const date = new Date();
  const classes = useStyles();
  const { currencies } = useCurrencies();
  const equitySliderData = useTotalEquitySlider();

  const sharePriceTarget = equitySliderData?.value;

  const hasVestingData = equityGrantVestEvents.length > 0;

  const filteredVestEventsForNext12Months = filterVestEventsForNext12Months(
    equityGrantVestEvents as VestEvent[],
    date
  );

  const next12MonthsEquity = calculateEquityFromVestEvents(
    currencies,
    sharePriceTarget,
    filteredVestEventsForNext12Months,
    equityHoldings.valuationCurrency
  );

  return (
    <div className={classes.element}>
      <AssembleTypography
        className={classes.header}
        variant="productEyebrowMedium"
      >
        Next 12 Mos vesting
      </AssembleTypography>

      {hasVestingData ? (
        <>
          <AssembleTypography
            className={classes.cash}
            variant="productLongForm"
            data-cy="next-12-months-equity-header-cash"
          >
            {next12MonthsEquity.value !== null
              ? formatCurrency(next12MonthsEquity.value, {
                  maximumFractionDigits: 0,
                })
              : "-"}
          </AssembleTypography>
          <AssembleTypography
            className={classes.units}
            variant="sidebarTitle"
            data-cy="next-12-months-equity-header-units"
          >
            {`${formatNumeral(next12MonthsEquity.count)} units`}
          </AssembleTypography>
        </>
      ) : (
        <AssembleTypography className={classes.cash} variant="productLongForm">
          No Data
        </AssembleTypography>
      )}
    </div>
  );
}

TotalEquityNext12MonthsChartHeader.fragments = {
  equityGrantVestEvent: gql`
    fragment TotalEquityNext12MonthsChartHeader_equityGrantVestEvent on EquityGrantVestEventSource {
      id
      vestingDate
      unitCount
      grant {
        id
        sharePrice
        unitCount
      }
    }
  `,
  equityHoldings: gql`
    fragment TotalEquityNext12MonthsChartHeader_equityHoldings on EquityHoldings {
      id
      valuationCurrency {
        id
        code
        exchangeRate
      }
    }
  `,
};
