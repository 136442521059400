import { Box, makeStyles, Typography } from "@material-ui/core";
import { GRAY_5, WHITE } from "../../../theme";

const useStyles = makeStyles((theme) => ({
  tooltipContainer: {
    display: "flex",
    boxShadow: "0px 4px 10px rgba(10, 36, 64, 0.14)",
    borderRadius: "5px",
    justifyContent: "center",
  },
  tooltipTitle: {
    padding: theme.spacing(3, 2),
    color: GRAY_5,
    fontSize: "0.875rem",
    lineHeight: 1.55,
    letterSpacing: "-0.25px",
    width: "100%",
  },
  noBudgetTooltipTitle: {
    padding: theme.spacing(1),
    color: GRAY_5,
    fontSize: "0.875rem",
    lineHeight: 1.55,
    letterSpacing: "-0.25px",
    width: "100%",
  },
  tooltipText: {
    color: WHITE,
    fontSize: "0.875rem",
    lineHeight: 1.55,
    letterSpacing: "-0.25px",
  },
}));

const formatPercent = (percent: number): string => {
  const initial = percent.toFixed(0);
  if (initial === "0" && percent > 0) {
    return "<1";
  }
  return initial;
};

export function BudgetDetailTooltip({
  budgetType,
  percentUsed,
}: {
  budgetType: string;
  percentUsed: number | null;
}): JSX.Element {
  const classes = useStyles();
  const budgetTypeString = budgetType.toLowerCase().replace("budget", "");

  return (
    <Box className={classes.tooltipContainer}>
      {percentUsed !== null && percentUsed !== Number.POSITIVE_INFINITY ? (
        <Typography className={classes.tooltipTitle}>
          Currently using&nbsp;
          <Typography className={classes.tooltipText} component="span">
            {`${formatPercent(percentUsed)}%`}
          </Typography>
          &nbsp;of the {budgetTypeString} budget.
        </Typography>
      ) : (
        <Typography className={classes.noBudgetTooltipTitle}>
          You can still request changes even though there isn't an allocated
          budget.
        </Typography>
      )}
    </Box>
  );
}
