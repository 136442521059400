import { makeStyles, Tooltip } from "@material-ui/core";
import { AdjustedBandPoint, formatPointValue } from "../../../models/BandPoint";
import { DV_ORANGE, WHITE } from "../../../theme";

const useStyles = makeStyles(() => ({
  dot: {
    height: "9px",
    width: "9px",
    borderRadius: "50%",
    border: `1px solid ${WHITE}`,
    boxShadow: "0px 1px 2px rgba(10, 36, 64, 0.2)",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  hourlyDot: {
    height: "25px",
    width: "25px",
    backgroundColor: DV_ORANGE,
    borderRadius: "50%",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    left: "50%",
  },
}));

type PointProps = {
  point: AdjustedBandPoint;
  offset: string;
};
export function BandPoint({ point, offset }: PointProps): JSX.Element {
  const classes = useStyles();
  return (
    <Tooltip
      key={point.name}
      placement="top"
      title={`${point.name} · ${formatPointValue(point)}`}
    >
      <div className={classes.dot} style={{ left: offset }} />
    </Tooltip>
  );
}

export function HourlyBandPoint({
  point,
}: {
  point: AdjustedBandPoint;
}): JSX.Element {
  const classes = useStyles();
  return (
    <Tooltip
      key={point.name}
      placement="top"
      title={`Hourly rate · ${formatPointValue(point, true)}/hr`}
    >
      <div className={classes.hourlyDot} />
    </Tooltip>
  );
}
