import { useQuery } from "@apollo/client";
import { Currency } from "@asmbl/shared/currency";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { GET_CURRENCIES } from "../queries";
import { CurrencyCode, GetCurrencies, Noun } from "../__generated__/graphql";
import { AccessBoundary } from "./AccessBoundary";

export const DEFAULT_USD = { code: CurrencyCode.USD, exchangeRate: 1 };

export type CurrenciesContextType = {
  currenciesList: Currency[];
  currencies: Map<CurrencyCode, Currency>;
  selectedCurrency: Currency;
  selectCurrency: (code: CurrencyCode) => unknown;
  defaultCurrencyCode: CurrencyCode;
  defaultCurrency: Currency;
};

type LocalCurrenciesState = {
  code: CurrencyCode;
};

const DEFAULT_CONTEXT_VALUE: CurrenciesContextType = {
  currenciesList: [DEFAULT_USD],
  currencies: new Map([[DEFAULT_USD.code, DEFAULT_USD]]),
  selectedCurrency: DEFAULT_USD,
  selectCurrency: () => {
    // Always just the default
  },
  defaultCurrencyCode: DEFAULT_USD.code,
  defaultCurrency: DEFAULT_USD,
};

export const currenciesContext = createContext<CurrenciesContextType>(
  DEFAULT_CONTEXT_VALUE
);

function setLocalCurrenciesState(state: LocalCurrenciesState): void {
  localStorage.setItem("currenciesState", JSON.stringify(state));
}

const isLocalState = (state: unknown): state is LocalCurrenciesState => {
  return typeof state === "object" && state != null && "code" in state;
};

function getLocalState(): LocalCurrenciesState | null {
  try {
    const localState = localStorage.getItem("currenciesState");
    if (localState !== null) {
      const parsedState: unknown = JSON.parse(localState);
      if (isLocalState(parsedState)) {
        return parsedState;
      }
    }
  } catch (e) {
    return null;
  }

  return null;
}

function LiveCurrenciesProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element | null {
  const { data } = useQuery<GetCurrencies>(GET_CURRENCIES);
  if (!data) return null;

  return (
    <LiveCurrenciesProviderInner currenciesList={data.currencies}>
      {children}
    </LiveCurrenciesProviderInner>
  );
}

function LiveCurrenciesProviderInner({
  children,
  currenciesList,
}: {
  children: ReactNode;
  currenciesList: GetCurrencies["currencies"];
}): JSX.Element {
  const defaultCurrency =
    currenciesList.find((c) => c.isDefault) ?? currenciesList[0];

  const currencies = useMemo(
    () => new Map(currenciesList.map((c) => [c.code, c])),
    [currenciesList]
  );

  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(() => {
    const localState = getLocalState();
    const locallyStoredCurrency =
      localState !== null
        ? currenciesList.find((c) => c.code === localState.code)
        : null;

    return locallyStoredCurrency ?? defaultCurrency;
  });

  const selectCurrency = useCallback(
    (code: CurrencyCode) => {
      const currency = currencies.get(code) ?? defaultCurrency;
      setSelectedCurrency(currency);
      setLocalCurrenciesState({ code: currency.code });
    },
    [currencies, defaultCurrency]
  );

  const value = useMemo<CurrenciesContextType>(() => {
    return {
      currenciesList,
      currencies,
      selectedCurrency,
      selectCurrency,
      defaultCurrencyCode: defaultCurrency.code,
      defaultCurrency,
    };
  }, [
    currencies,
    currenciesList,
    selectCurrency,
    selectedCurrency,
    defaultCurrency,
  ]);

  return (
    <currenciesContext.Provider value={value}>
      {children}
    </currenciesContext.Provider>
  );
}

export function CurrenciesProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  return (
    <AccessBoundary
      scope="global"
      verb="view"
      noun={Noun.CompStructure}
      fallback={
        <currenciesContext.Provider value={DEFAULT_CONTEXT_VALUE}>
          {children}
        </currenciesContext.Provider>
      }
    >
      <LiveCurrenciesProvider>{children}</LiveCurrenciesProvider>
    </AccessBoundary>
  );
}

export function useCurrencies(): CurrenciesContextType {
  return useContext(currenciesContext);
}
