import { CartaLogo } from "src/components/AssembleIcons/Logo/CartaLogo";
import { CulpepperLogo } from "src/components/AssembleIcons/Logo/CulpepperLogo";
import { EconomicResearchInstituteLogo } from "src/components/AssembleIcons/Logo/EconomicResearchInstituteLogo";
import { MercerLogo } from "src/components/AssembleIcons/Logo/MercerLogo";
import { OtherLogo } from "src/components/AssembleIcons/Logo/OtherLogo";
import { PaveLogo } from "src/components/AssembleIcons/Logo/PaveLogo";
import { RadfordAonLogo } from "src/components/AssembleIcons/Logo/RadfordAonLogo";
import { WillisTowersWatsonLogo } from "src/components/AssembleIcons/Logo/WillisTowersWatsonLogo";

export const getMarketDataProviderLogo = (providerName: string) => {
  if (providerName === "Radford") return <RadfordAonLogo />;
  if (providerName === "OptionImpact/Pave") return <PaveLogo />;
  if (providerName === "Carta") return <CartaLogo />;
  if (providerName === "Economic Research Institute")
    return <EconomicResearchInstituteLogo />;
  if (providerName === "Mercer") return <MercerLogo />;
  if (providerName === "Culpepper") return <CulpepperLogo />;
  if (providerName === "Willis Towers Watson")
    return <WillisTowersWatsonLogo />;
  return <OtherLogo />;
};
