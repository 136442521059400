import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const ChevronUpIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Chevron Up"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="rotate(90 12 12)">
        <path
          d="M15 19.25L8.25 12.5L15 5.75"
          className="stroke"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  </ColorableSVG>
);
