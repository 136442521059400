import { Link, TypographyProps } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  AssembleTypography,
  AssembleTypographyVariant,
} from "./AssembleTypography";

interface Props extends Omit<TypographyProps, "variant"> {
  to: string;
  children: ReactNode;
  newTab?: boolean;
  whitespace?: boolean;
  color?:
    | "inherit"
    | "initial"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error";
  underline?: "none" | "always" | "hover";
  textColor?: string;
  variant?: Variant | AssembleTypographyVariant;
}

export function AssembleLink({
  to,
  children,
  newTab = false,
  whitespace = true,
  color = "primary",
  underline,
  ...rest
}: Props): JSX.Element {
  return (
    <>
      {whitespace ? " " : ""}
      <Link
        component={RouterLink}
        to={to}
        target={newTab ? "_blank" : undefined}
        underline={underline}
        color={color}
      >
        <AssembleTypography display="inline" color={color} {...rest}>
          {children}
        </AssembleTypography>
      </Link>
      {whitespace ? " " : ""}
    </>
  );
}
