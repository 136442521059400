import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import {
  ManagersTable_manager,
  ManagersTable_phase,
} from "src/__generated__/graphql";
import { GRAY_6, WHITE } from "src/theme";
import { ManagerCell } from "./ManagerCell";
import { ManagersTableHeaderCell } from "./ManagersTableHeaderCell";
import { getHighlightedEmployeeIds, getOrderedColumns } from "./util";

export type reviewStats = {
  [employeeId: string]: [number, number, boolean];
};

type Props = {
  phases: ManagersTable_phase[];
  managers: ManagersTable_manager[];
  showRollup: boolean;
  currentPhaseOrder?: number;
  reviewStats: reviewStats;
};

const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: WHITE,
    overflowX: "scroll",
    height: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    padding: ".5rem 1rem",
    border: `solid 1px ${GRAY_6}`,
    display: "flex",
    justifyContent: "space-between",
  },
  headerText: {
    display: "flex",
    alignItems: "center",
  },
});

export function ManagersTable({
  phases,
  managers,
  showRollup,
  currentPhaseOrder = 0,
  reviewStats,
}: Props) {
  const classes = useStyles();
  const [hoveredEmployeeId, setHoveredEmployeeId] = useState<number | null>(
    null
  );

  const highlightedEmployeeIds = getHighlightedEmployeeIds(
    hoveredEmployeeId,
    managers
  );

  const orderedColumns = getOrderedColumns(managers, phases);

  // Used to track how many rows tall each cell should be
  const rowSpans: { [key: string]: number } = {};

  return (
    <div className={classes.row}>
      {phases.map((phase, index) => {
        const idsForColumn = orderedColumns[index];
        return (
          <div
            key={`phase-column-${phase.phaseOrder}`}
            className={classes.column}
          >
            <ManagersTableHeaderCell
              phase={phase}
              currentPhaseOrder={currentPhaseOrder}
            />
            {idsForColumn.map((id) => {
              if (id == null) return null;

              const manager = managers.find((manager) => manager.id === id);
              if (!manager) return null;

              // rowSpans tracks how many rows each cell spans
              rowSpans[id] = 1;

              let sumOfRowSpansForReportsInPreviousRow = 1;

              if (index !== 0) {
                const allReportIds = [
                  ...manager.directReports.map((dr) => dr.id),
                  ...manager.indirectReports.map((ir) => ir.id),
                ];

                // Grab all direct reports in the previous row
                const reportsInPreviousRow = orderedColumns[index - 1].filter(
                  (id) => allReportIds.includes(id as number)
                );

                // Add up the rowSpans for all direct reports in previous row
                sumOfRowSpansForReportsInPreviousRow =
                  reportsInPreviousRow.reduce((total: number, reportId) => {
                    if (reportId == null || rowSpans[reportId] == null) {
                      return total;
                    }
                    const rowSpansTotal = rowSpans[reportId];
                    return total + rowSpansTotal;
                  }, 0);

                if (sumOfRowSpansForReportsInPreviousRow < 1)
                  // Make sure that there are no zero height rows
                  sumOfRowSpansForReportsInPreviousRow = 1;
                rowSpans[id] = sumOfRowSpansForReportsInPreviousRow;
              }

              return (
                <ManagerCell
                  key={`manager-cell-${id}`}
                  manager={manager}
                  rowSpan={sumOfRowSpansForReportsInPreviousRow}
                  isPastPhase={phase.phaseOrder < currentPhaseOrder}
                  setHoveredEmployeeId={
                    showRollup
                      ? setHoveredEmployeeId
                      : () => {
                          // noop
                        }
                  }
                  isHighlighted={highlightedEmployeeIds.includes(manager.id)}
                  reviewStats={reviewStats}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

ManagersTable.fragments = {
  phases: gql`
    fragment ManagersTable_phase on CompCyclePhase2 {
      id
      phaseOrder
      startDate
      compCyclePhaseAssignments {
        id
        phaseId
        status
        employee {
          displayName
        }
        recommendees {
          subjectId
        }
        reviewees {
          subjectId
        }
        employee {
          id
          displayName
        }
        reviewees {
          id
          type
          participant {
            subjectId
          }
        }
        recommendees {
          id
          type
          participant {
            subjectId
          }
        }
      }
    }
  `,
  managers: gql`
    fragment ManagersTable_manager on Employee2 {
      id
      displayName
      managerId
      activeEmployment {
        id
        jobTitle
      }
      directReports {
        id
      }
      indirectReports {
        id
      }
    }
  `,
};
