import { gql } from "@apollo/client";
import * as changeCase from "change-case";
import { PrimaryRoleName } from "@asmbl/shared/permissions";
import { Tooltip } from "@material-ui/core";
import { useUpdateUserIsDisabled } from "../../../../../mutations/User";
import { TableAccountButton_employee } from "../../../../../__generated__/graphql";
import { PortalDialog } from "../../PortalAccess/PortalDialog";
import { BaseButton } from "./BaseButton";

type Props = {
  employee: TableAccountButton_employee;
  roleName: PrimaryRoleName | null;
};

export const TableAccountButton = ({ employee, roleName }: Props) => {
  const accountCurrentlyDisabled = employee.user?.isDisabled ?? false;

  const updateUserIsDisabled = useUpdateUserIsDisabled();

  const handleRevoke = async () => {
    // if the employee is seeing this button, they should have a user acct
    // but since EmployeeRow.user can be null we have to handle it explicitly
    if (employee.user) {
      await updateUserIsDisabled(employee.user.id, !employee.user.isDisabled);
    }
  };

  const action = accountCurrentlyDisabled ? "Enable" : "Revoke";

  return (
    <PortalDialog
      onDialogClick={handleRevoke}
      actionButtonTitle={`${action} Access`}
      title={`${action} Portal Access for ${employee.displayName}`}
      body={
        accountCurrentlyDisabled
          ? `${employee.displayName} will be able to access their Employee Portal.`
          : `${employee.displayName} will no longer be able to access Employee Portal.`
      }
    >
      {(onClick) =>
        roleName === PrimaryRoleName.EMPLOYEE ? (
          <BaseButton
            props={{
              variant: "outlined",
              color: "primary",
            }}
            onClick={onClick}
            label={action}
          />
        ) : (
          <Tooltip
            title={`This employee has a ${
              roleName !== null
                ? changeCase.capitalCase(roleName)
                : "primary access"
            } role. Manage their account in the User Permissions tab.`}
            placement="top"
          >
            <span>
              <BaseButton
                props={{
                  variant: "outlined",
                  color: "primary",
                  disabled: true,
                }}
                label="Revoke"
              />
            </span>
          </Tooltip>
        )
      }
    </PortalDialog>
  );
};

TableAccountButton.fragments = {
  employee: gql`
    fragment TableAccountButton_employee on Employee {
      id
      displayName
      user {
        id
        isDisabled
      }
    }
  `,
};
