import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const CenterViewIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Center View"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M11.5 3H14V5.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M4.5 13H2V10.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M14 10.5V13H11.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M2 5.5V3H4.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        className="stroke"
        x="4"
        y="5"
        width="8"
        height="6"
        rx="1"
        strokeWidth="1"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
