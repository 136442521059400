import { gql, useMutation } from "@apollo/client";
import { exchangeCurrency } from "@asmbl/shared/currency";
import {
  Money,
  percentageOfSalaryToCash,
  preferredPrice,
  zero,
} from "@asmbl/shared/money";
import { useCallback } from "react";
import { OfferData } from "../components/OfferGeneration/types";
import { CashBandName, EquityBandName } from "../constants";
import { GET_OFFERS } from "../queries";
import {
  BandUnit,
  CreateCandidate,
  CreateCandidateVariables,
  CurrencyCode,
  EquityGrantMethod,
  UpsertOffer,
  UpsertOfferVariables,
} from "../__generated__/graphql";

type CompStructure = {
  cashBandTypes: string[];
  equityBandTypes: string[];
  equityGrantMethod: EquityGrantMethod;
};

type Valuation = {
  fdso: number;
  valuation: Money;
};

export function useUpsertOffer(
  compStructure: CompStructure | null,
  valuation: Valuation | null
): (data: OfferData) => Promise<number | undefined> {
  const [upsertOffer] = useMutation<UpsertOffer, UpsertOfferVariables>(
    UPSERT_OFFER,
    { refetchQueries: [{ query: GET_OFFERS }] }
  );
  const [createCandidate] = useMutation<
    CreateCandidate,
    CreateCandidateVariables
  >(CREATE_CANDIDATE);

  // Use the passed-in candidateId (if there is one), or create a whole new
  // candidate (assuming there's a candidate email).
  const getOrCreateCandidate = useCallback(
    async (data: OfferData) => {
      if (data.candidateId !== undefined) return data.candidateId;
      if (data.candidateEmail === undefined) return undefined;

      const candidate = await createCandidate({
        variables: {
          candidateEmail: data.candidateEmail,
          candidateName: [data.firstName, data.lastName].join(" ").trim(),
        },
      });
      return candidate.data?.createOneCandidate.id;
    },
    [createCandidate]
  );

  return useCallback(
    async (data: OfferData) => {
      if (!compStructure) return;

      const candidateId = await getOrCreateCandidate(data);
      if (candidateId === undefined) return;

      const cash = compStructure.cashBandTypes.map((band) => {
        let value = 0;
        let percentOfSalary: number | null = null;

        const type = band as CashBandName;
        const currency = data.currency.code;
        const currentDataPoint = data.cash[type];
        const salary = data.cash[CashBandName.SALARY]?.value;

        if (currentDataPoint?.value === undefined) {
          return { type, value, currency, percentOfSalary };
        }

        if (currentDataPoint.mode === BandUnit.CASH) {
          value = currentDataPoint.value.value;
        } else {
          // BandUnit.PERCENTAGE
          percentOfSalary = currentDataPoint.value;
          const cashFromPercentageOfSalary = percentageOfSalaryToCash(
            salary ?? zero(data.currency.code),
            currentDataPoint.value
          );

          // This value has to be an integer, so we must round it.
          value = Math.round(cashFromPercentageOfSalary.value);
        }

        return { type, value, currency, percentOfSalary };
      });

      const equity = compStructure.equityBandTypes.map((band) => {
        let value = 0;
        let percentOfSalary: number | null = null;
        let currency: CurrencyCode | undefined;
        const type = band as EquityBandName;
        const currentDataPoint = data.equity[type];
        const salary = data.cash[CashBandName.SALARY]?.value;

        if (!currentDataPoint || currentDataPoint.value === undefined) {
          return { type, value, currency: undefined, percentOfSalary };
        }

        if (currentDataPoint.mode === BandUnit.UNITS) {
          value = currentDataPoint.value;
          currency = undefined;
        } else if (currentDataPoint.mode === BandUnit.CASH) {
          value = currentDataPoint.value.value;
          currency = currentDataPoint.value.currency;
        } else if (salary !== undefined && valuation?.fdso !== undefined) {
          // currentDataPoint.mode === BandUnit.PERCENTAGE
          percentOfSalary = currentDataPoint.value;
          const { equityGrantMethod } = compStructure;

          const pricePerUnit = exchangeCurrency(
            preferredPrice(valuation.fdso, valuation.valuation),
            data.currency
          );

          const cashFromPercentageOfSalary = percentageOfSalaryToCash(
            salary,
            currentDataPoint.value
          );

          if (equityGrantMethod === EquityGrantMethod.CASH) {
            value = Math.round(cashFromPercentageOfSalary.value);
            currency = salary.currency;
          } else {
            value = Math.round(
              cashFromPercentageOfSalary.value / pricePerUnit.value
            );
          }
        }

        return { type, value, currency, percentOfSalary };
      });

      const offer = await upsertOffer({
        variables: {
          // Required Fields
          candidateId,
          offeredAt: data.offeredAt ?? new Date(),
          cash: { data: cash },
          equity: { data: equity },
          // Optional Fields
          offerId: data.offerId,
          marketId: data.market?.id,
          message: data.welcomeMessage,
          closingMessage: data.closingMessage,
          outcomeDescription: data.outcomeDescription,
          positionId: data.position?.id,
          title: data.title ?? data.position?.name,
          benefitsPackageId: data.benefitsPackage?.id,
          locationGroupId: data.locationGroup?.id,
          // Offer Approval Fields
          managerName: data.managerName,
          decisionMakerName: data.decisionMakerName,
          approverName: data.approverName,
          jobCode: data.jobCode,
          customFieldAnswers: data.customFieldAnswers,
        },
      });
      return offer.data?.createOneOffer.id;
    },
    [upsertOffer, compStructure, getOrCreateCandidate, valuation]
  );
}

const UPSERT_OFFER = gql`
  mutation UpsertOffer(
    $approverName: String
    $benefitsPackageId: Int
    $candidateId: Int!
    $cash: OfferCashInput!
    $closingMessage: String
    $customFieldAnswers: [CustomFieldAnswerInput!]
    $decisionMakerName: String
    $equity: OfferEquityInput!
    $jobCode: String
    $locationGroupId: Int
    $managerName: String
    $marketId: Int
    $message: String
    $offeredAt: DateTime!
    $offerId: Int
    $outcomeDescription: String
    $positionId: Int
    $title: String
  ) {
    createOneOffer(
      data: {
        approverName: $approverName
        benefitsPackageId: $benefitsPackageId
        candidateId: $candidateId
        cash: $cash
        closingMessage: $closingMessage
        customFieldAnswers: $customFieldAnswers
        decisionMakerName: $decisionMakerName
        equity: $equity
        jobCode: $jobCode
        locationGroupId: $locationGroupId
        managerName: $managerName
        marketId: $marketId
        message: $message
        offeredAt: $offeredAt
        offerId: $offerId
        outcomeDescription: $outcomeDescription
        positionId: $positionId
        title: $title
      }
    ) {
      id
    }
  }
`;

const CREATE_CANDIDATE = gql`
  mutation CreateCandidate($candidateEmail: String!, $candidateName: String!) {
    createOneCandidate(
      data: { candidateEmail: $candidateEmail, candidateName: $candidateName }
    ) {
      id
      candidateName
      candidateEmail
    }
  }
`;
