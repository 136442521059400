import { gql } from "@apollo/client";
import { contramap } from "@asmbl/shared/sort";
import { formatNumeral } from "@asmbl/shared/utils";
import {
  CashCompType,
  CondensedTableCurrentTargetRecurringBonusPercentCell2_participant as Participant,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { activeComp } from "../../../../models/CashCompensation";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

function getBonusPercent(employee: Participant): number {
  const activeCashComp = activeComp(
    employee.subject.activeCashCompensation ?? [],
    new Date()
  );
  return activeCashComp.get(CashCompType.RECURRING_BONUS)?.percentOfSalary ?? 0;
}

export function CondensedTableCurrentTargetRecurringBonusPercentCell2({
  row: { original: employee },
}: Props): JSX.Element {
  return (
    <AssembleTruncatedTypography align="right">
      {formatNumeral(getBonusPercent(employee) / 100, {
        style: "percent",
        maximumFractionDigits: 2,
      })}
    </AssembleTruncatedTypography>
  );
}

CondensedTableCurrentTargetRecurringBonusPercentCell2.fragments = {
  participant: gql`
    fragment CondensedTableCurrentTargetRecurringBonusPercentCell2_participant on CompCycleParticipant {
      subject {
        id
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          unit
          annualCashEquivalent
          hourlyCashEquivalent
          percentOfSalary
        }
      }
    }
  `,
};

CondensedTableCurrentTargetRecurringBonusPercentCell2.Header =
  ColumnIdsToHeaders.get(ColumnIds.CURRENT_TARGET_RECURRING_BONUS_PERCENT);
CondensedTableCurrentTargetRecurringBonusPercentCell2.id =
  ColumnIds.CURRENT_TARGET_RECURRING_BONUS_PERCENT;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableCurrentTargetRecurringBonusPercentCell2,
  Header: CondensedTableCurrentTargetRecurringBonusPercentCell2.Header,
  id: CondensedTableCurrentTargetRecurringBonusPercentCell2.id,
  width: LARGE_COL_WIDTH,
};

CondensedTableCurrentTargetRecurringBonusPercentCell2.column = column;
CondensedTableCurrentTargetRecurringBonusPercentCell2.ordering = () =>
  contramap(getBonusPercent);
