import { gql, useLazyQuery } from "@apollo/client";
import { SSO_ERROR } from "@asmbl/shared/auth";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GetUserInvitationFromToken } from "../../__generated__/graphql";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { useURLSearchParams } from "../../models/URLSearchParams";
import { Authentication, AuthenticationFormState } from "./Authentication";

type Props = { formState: AuthenticationFormState };

export function AuthenticationLoadingBoundary({
  formState,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const urlSearchParams = useURLSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const hasError = urlSearchParams.get(SSO_ERROR);
  if (hasError !== null) {
    const errorMsg =
      "It looks like your organization does not have SSO enabled. \n" +
      "Contact your system administrator if you believe this is a mistake.";
    enqueueSnackbar(errorMsg, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 8_000,
      style: { whiteSpace: "pre-line" },
    });
    navigate(urlSearchParams.delete(SSO_ERROR).toString(), { replace: true });
  }

  const token =
    formState === AuthenticationFormState.Register
      ? urlSearchParams.get("token")
      : null;

  const decodedToken = token != null ? decodeURIComponent(token) : null;

  const tokenProvided = token != null;

  const [getData, { data, loading, error }] =
    useLazyQuery<GetUserInvitationFromToken>(
      AuthenticationLoadingBoundary.query,
      {
        variables: { token: decodedToken },
        fetchPolicy: "no-cache",
      }
    );

  // You might be wondering, why are we using a lazyQuery here & calling it
  // immediately instead of just using a regular query. Well, it seems like
  // in some cases the regular query was loading in faster than the
  // `ApolloApp` (on the main App) which was then causing a race condition
  // when then causes an infinite loading loop on the sign up page. By using
  // the lazyQuery we can avoid that issue
  useEffect(() => {
    if (tokenProvided) {
      void getData();
    }
  }, [getData, tokenProvided]);

  let finalFormState = formState;

  if (tokenProvided) {
    if (data === undefined) {
      return loading ? (
        <LoadingSpinner />
      ) : (
        <div>{error?.message ?? "An error has occurred"}</div>
      );
    }

    const invalidToken = data.userInvitationFromToken == null;

    finalFormState = invalidToken
      ? AuthenticationFormState.InvalidToken
      : formState;
  }

  return (
    <Authentication
      formState={finalFormState}
      token={decodedToken}
      userInvitation={data?.userInvitationFromToken ?? null}
    />
  );
}

AuthenticationLoadingBoundary.query = gql`
  ${Authentication.fragments.userInvitation}
  query GetUserInvitationFromToken($token: String!) {
    userInvitationFromToken(token: $token) {
      id
      ...Authentication_userInvitation
    }
  }
`;
