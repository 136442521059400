import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";

type Props = {
  step: "open" | "cycleCreation" | "recommendations" | "success" | "error";
  createCompCycle: () => void;
  closeModal: () => void;
};

export function CorrectionCompCycleDialog({
  step,
  createCompCycle,
  closeModal,
}: Props): JSX.Element | null {
  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") {
      closeModal();
    }
  };
  switch (step) {
    case "open":
      return (
        <Dialog open onClose={() => closeModal()}>
          <DialogTitle>Create Band Placement Correction Cycle</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Clicking "Yes" will generate a new cycle that uses the current
              annual spends and bandpoint move costs to generate cycle budgets
              and pre-fill any selected employee recommendations if needed. Are
              you sure you want to continue?
            </DialogContentText>
            <DialogActions>
              <AssembleButton
                onClick={closeModal}
                variant="outlined"
                size="large"
                label="Cancel"
              />
              <AssembleButton
                onClick={createCompCycle}
                variant="contained"
                size="large"
                label="Yes"
              />
            </DialogActions>
          </DialogContent>
        </Dialog>
      );
    case "cycleCreation":
      return (
        <Dialog open onKeyDown={onKeyDown}>
          <DialogContent>
            <DialogContentText>
              Creating comp cycle and budgets...
            </DialogContentText>
          </DialogContent>
        </Dialog>
      );
    case "recommendations":
      return (
        <Dialog open onKeyDown={onKeyDown}>
          <DialogContent>
            <DialogContentText>
              Creating employee recommendations...
            </DialogContentText>
          </DialogContent>
        </Dialog>
      );
    case "success":
      return (
        <Dialog open onKeyDown={onKeyDown}>
          <DialogContent>
            <DialogContentText>
              Comp cycle successfully created! Redirecting to comp cycle page...
            </DialogContentText>
          </DialogContent>
        </Dialog>
      );
    case "error":
      return (
        <Dialog open onKeyDown={onKeyDown}>
          <DialogContent>
            <DialogContentText>
              An error occurred while creating the comp cycle. Please try again
              or reach out to our support team via Intercom.
            </DialogContentText>
          </DialogContent>
        </Dialog>
      );
    case null:
    default:
      return null;
  }
}
