import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const OtherGrayscaleLogo = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_917_7981)">
        <rect width={width} height={height} fill="#BDD3E5" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.98761 4.19168C4.22503 4.60764 3.9465 5.07709 3.9465 5.44002C3.9465 5.80295 4.22503 6.2724 4.98761 6.68836C5.726 7.09112 6.78979 7.36002 7.99984 7.36002C9.20988 7.36002 10.2737 7.09112 11.0121 6.68836C11.7746 6.2724 12.0532 5.80295 12.0532 5.44002C12.0532 5.07709 11.7746 4.60764 11.0121 4.19168C10.2737 3.78892 9.20988 3.52002 7.99984 3.52002C6.78979 3.52002 5.726 3.78892 4.98761 4.19168ZM4.37468 3.06797C5.33495 2.54419 6.61783 2.24002 7.99984 2.24002C9.38185 2.24002 10.6647 2.54419 11.625 3.06797C12.5611 3.57855 13.3332 4.3891 13.3332 5.44002C13.3332 6.49094 12.5611 7.30149 11.625 7.81207C10.6647 8.33585 9.38185 8.64002 7.99984 8.64002C6.61783 8.64002 5.33495 8.33585 4.37468 7.81207C3.43862 7.30149 2.6665 6.49094 2.6665 5.44002C2.6665 4.3891 3.43862 3.57855 4.37468 3.06797Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.3065 4.79999C3.65997 4.79999 3.9465 5.08653 3.9465 5.43999V7.99999C3.9465 8.36292 4.22503 8.83237 4.98761 9.24833C5.726 9.65108 6.78979 9.91999 7.99984 9.91999C9.20988 9.91999 10.2737 9.65108 11.0121 9.24833C11.7746 8.83237 12.0532 8.36292 12.0532 7.99999V5.43999C12.0532 5.08653 12.3397 4.79999 12.6932 4.79999C13.0466 4.79999 13.3332 5.08653 13.3332 5.43999V7.99999C13.3332 9.05091 12.5611 9.86145 11.625 10.372C10.6647 10.8958 9.38185 11.2 7.99984 11.2C6.61783 11.2 5.33495 10.8958 4.37468 10.372C3.43862 9.86145 2.6665 9.05091 2.6665 7.99999V5.43999C2.6665 5.08653 2.95304 4.79999 3.3065 4.79999Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.3065 7.35999C3.65997 7.35999 3.9465 7.64652 3.9465 7.99999V10.56C3.9465 10.9229 4.22503 11.3924 4.98761 11.8083C5.726 12.2111 6.78979 12.48 7.99984 12.48C9.20988 12.48 10.2737 12.2111 11.0121 11.8083C11.7746 11.3924 12.0532 10.9229 12.0532 10.56V7.99999C12.0532 7.64652 12.3397 7.35999 12.6932 7.35999C13.0466 7.35999 13.3332 7.64652 13.3332 7.99999V10.56C13.3332 11.6109 12.5611 12.4215 11.625 12.932C10.6647 13.4558 9.38185 13.76 7.99984 13.76C6.61783 13.76 5.33495 13.4558 4.37468 12.932C3.43862 12.4215 2.6665 11.6109 2.6665 10.56V7.99999C2.6665 7.64652 2.95304 7.35999 3.3065 7.35999Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_917_7981">
          <rect width={width} height={height} rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </ColorableSVG>
);
