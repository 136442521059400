import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const EmployeePortalIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Employee Portal"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className="stroke" cx="8" cy="8" r="7.25" strokeWidth="1.5" />
      <path
        className="stroke"
        d="M8 10C9.38071 10 10.5 8.88071 10.5 7.5C10.5 6.11929 9.38071 5 8 5C6.61929 5 5.5 6.11929 5.5 7.5C5.5 8.88071 6.61929 10 8 10Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M3.98743 12.4609C4.36406 11.7204 4.93825 11.0986 5.64644 10.6642C6.35464 10.2299 7.16921 10 7.99998 10C8.83075 10 9.64532 10.2299 10.3535 10.6642C11.0617 11.0985 11.6359 11.7204 12.0125 12.4609"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
