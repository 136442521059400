import { gql, useQuery } from "@apollo/client";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  GetMarketsAndLocationGroups,
  LiveLocationsProvider_locationGroup as LocationGroup,
  LiveLocationsProvider_market as Market,
  Noun,
} from "../__generated__/graphql";
import { AccessBoundary } from "./AccessBoundary";

export type LocationSelection = [Market, LocationGroup];

export type LocationsContextType = {
  /**
   * The full list of Markets visible to the user.
   */
  markets: Market[];
  /**
   * The currently selected Market-LocationGroup pair. This should only be used
   * on pages where the user explicitly selects a Market and LocationGroup,
   * such as Foundations and Insights.
   *
   *
   * This selection is expected to be null only when the user has no access to
   * Location and Market information (e.g. Employee role).
   */
  selectedLocation: LocationSelection | null;
  /**
   * Selects a new Market. Changing the Market sets the LocationGroup to null.
   */
  selectMarket: (market: Market) => unknown;
  /**
   * Selects a new LocationGroup in the current Market. If the LocationGroup is
   * not in the currently selected Market, the behavior is undefined.
   */
  selectLocation: (location: LocationGroup) => unknown;
};

export function assertLocationSelected(
  location: LocationSelection | null
): asserts location is LocationSelection {
  if (location === null) {
    throw new Error("Unexpected null location selection");
  }
}

type LocationProps = {
  children: ReactNode;
};

type LocalLocationState = {
  marketId: number | null;
  locationGroupId: number | null;
};

const DEFAULT_CONTEXT_VALUE = {
  markets: [],
  selectedLocation: null,
  selectMarket: () => {
    // No markets to select
  },
  selectLocation: () => {
    // No locations to select
  },
};

export const locationsContext = createContext<LocationsContextType>(
  DEFAULT_CONTEXT_VALUE
);

function setLocalLocationState(state: LocalLocationState): void {
  localStorage.setItem("locationContextState", JSON.stringify(state));
}

const isLocalState = (state: unknown): state is LocalLocationState => {
  return (
    typeof state === "object" &&
    state != null &&
    "marketId" in state &&
    "locationGroupId" in state
  );
};

function getLocalState(): LocalLocationState | null {
  try {
    const localState = localStorage.getItem("locationContextState");
    if (localState !== null) {
      const parsedState: unknown = JSON.parse(localState);
      if (isLocalState(parsedState)) {
        return parsedState;
      }
    }
  } catch (e) {
    return null;
  }

  return null;
}

const LiveLocationsProvider = ({
  children,
}: LocationProps): JSX.Element | null => {
  const localState = getLocalState();
  const { data, loading, error } = useQuery<GetMarketsAndLocationGroups>(
    LiveLocationsProvider.query
  );

  const [selectedMarketId, selectMarketId] = useState<number | null>(
    localState?.marketId ?? null
  );
  const [selectedLocationId, selectLocationId] = useState<number | null>(
    localState?.locationGroupId ?? null
  );

  const selectMarket = useCallback(
    (market: Market) => {
      selectMarketId(market.id);
      selectLocationId(null);
      setLocalLocationState({
        ...localState,
        marketId: market.id,
        locationGroupId: null,
      });
    },
    [localState]
  );

  const selectLocation = useCallback(
    (location: LocationGroup) => {
      selectLocationId(location.id);
      setLocalLocationState({
        marketId: selectedMarketId,
        locationGroupId: location.id,
      });
    },
    [selectedMarketId]
  );

  const value = useMemo(() => {
    if (!data?.markets || data.markets.length === 0) {
      return DEFAULT_CONTEXT_VALUE;
    }
    const selectedMarket =
      data.markets.find((m) => m.id === selectedMarketId) ?? data.markets[0];

    const sourceCompensationLocationGroup = selectedMarket.locationGroups.find(
      (locationGroup) => locationGroup.isSourceComp
    );

    const selectedLocationGroupById = selectedMarket.locationGroups.find(
      (locationGroup) => locationGroup.id === selectedLocationId
    );

    const selectedLocationGroup =
      selectedLocationGroupById ?? sourceCompensationLocationGroup;

    const selectedLocation: LocationSelection | null = selectedLocationGroup
      ? [selectedMarket, selectedLocationGroup]
      : null;

    return {
      markets: data.markets,
      selectedLocation,
      selectMarket,
      selectLocation,
    };
  }, [
    data?.markets,
    selectLocation,
    selectMarket,
    selectedLocationId,
    selectedMarketId,
  ]);

  if (loading || error) {
    return null;
  }

  return (
    <locationsContext.Provider value={value}>
      {children}
    </locationsContext.Provider>
  );
};

const locationGroupFragment = gql`
  fragment LiveLocationsProvider_locationGroup on LocationGroup {
    id
    name
    description
    isSourceComp
  }
`;
LiveLocationsProvider.fragments = {
  market: gql`
    ${locationGroupFragment}
    fragment LiveLocationsProvider_market on Market {
      id
      name
      currencyCode
      locationGroups {
        id
        isSourceComp
        ...LiveLocationsProvider_locationGroup
      }
    }
  `,
};
LiveLocationsProvider.query = gql`
  ${LiveLocationsProvider.fragments.market}
  query GetMarketsAndLocationGroups {
    markets {
      id
      ...LiveLocationsProvider_market
    }
  }
`;

export const LocationsProvider: React.FC<LocationProps> = ({
  children,
}: LocationProps) => {
  return (
    <AccessBoundary
      verb="view"
      scope="any"
      every={[Noun.CompStructure]}
      fallback={
        <locationsContext.Provider value={DEFAULT_CONTEXT_VALUE}>
          {children}
        </locationsContext.Provider>
      }
    >
      <LiveLocationsProvider>{children}</LiveLocationsProvider>
    </AccessBoundary>
  );
};

export function useLocations(): LocationsContextType {
  return useContext(locationsContext);
}
