import { gql } from "@apollo/client";
import { Box, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EditIcon } from "src/components/AssembleIcons/Brand/EditIcon";
import { GRAY_4, PURPLE_2 } from "src/theme";
import {
  Noun,
  PositionDetailEdit_position,
  PositionDetailEdit_valuation,
} from "../../__generated__/graphql";
import { useTrack } from "../../analytics";
import { AccessBoundary } from "../../components/AccessBoundary";
import { AdjustmentsBar } from "../../components/AdjustmentsBar";
import {
  AssembleBreadCrumb,
  AssembleBreadCrumbs,
  AssembleDropdownBreadCrumb,
} from "../../components/AssembleBreadCrumbs";
import { useDeletePosition, useUpdatePosition } from "../../mutations/Position";
import { EditPositionForm } from "./EditPositionForm";
import { PositionDetailEditTable } from "./PositionDetailEditTable";
import { PositionPeerNavigation } from "./PositionPeerNavigation";

const useStyles = makeStyles(() => ({
  breadCrumbContainer: {
    "& .MuiBreadcrumbs-ol > li:last-child": {
      width: "100%",
    },
  },
  leafCrumb: {
    height: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

type Props = {
  position: PositionDetailEdit_position;
  valuation: PositionDetailEdit_valuation;
};

export function PositionDetailEdit({
  position,
  valuation,
}: Props): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();
  const { Track } = useTrack({
    area: "Job Architecture",
    subArea: "Position Detail",
    positionName: position.name,
    ladderName: position.ladder.name,
    departmentName: position.ladder.department.name,
  });

  const [isEditingPosition, setIsEditingPosition] = useState(false);

  const updatePosition = useUpdatePosition(position.id);
  const deletePosition = useDeletePosition(position.id);

  const handleDeletePosition = async () => {
    await deletePosition();
    navigate(`/ladders/${position.ladder.id}`);
  };

  const canViewComp =
    position.adjustedCashBands !== null ||
    position.adjustedEquityBands !== null;

  return (
    <Track>
      <Box px={5} py={4}>
        {isEditingPosition && (
          <EditPositionForm
            mode="edit"
            persistedPosition={position}
            onSubmit={updatePosition}
            onCancel={() => setIsEditingPosition(false)}
            onDelete={handleDeletePosition}
          />
        )}
        <AdjustmentsBar locked />

        <div className={classes.breadCrumbContainer}>
          <AssembleBreadCrumbs variant="vertical">
            <AssembleBreadCrumb to="/" display={position.organization.name} />
            <AssembleBreadCrumb
              to={`/departments/${position.ladder.department.id}`}
              display={position.ladder.department.name}
            />
            <AssembleBreadCrumb
              to={`/ladders/${position.ladder.id}`}
              display={position.ladder.name}
            />
            <div className={classes.leafCrumb}>
              <div className={classes.leftContainer}>
                <AssembleDropdownBreadCrumb
                  options={position.ladder.positions.map(({ id, name }) => ({
                    id,
                    name,
                    to: `/positions/${id}`,
                  }))}
                  selectedOption={{
                    id: position.id,
                    name: position.name,
                    to: `/positions/${position.id}`,
                  }}
                />
                <Box m={1} />
                <AccessBoundary
                  scope="global"
                  verb="edit"
                  noun={Noun.JobStructure}
                >
                  <Tooltip title="Edit Position" placement="top">
                    <IconButton
                      size="small"
                      onClick={() => setIsEditingPosition(true)}
                    >
                      <EditIcon
                        color={GRAY_4}
                        hoverColor={PURPLE_2}
                        width="24px"
                        height="24px"
                      />
                    </IconButton>
                  </Tooltip>
                </AccessBoundary>
              </div>
            </div>
          </AssembleBreadCrumbs>
        </div>
        <Box m={2} />
        {canViewComp && (
          <PositionDetailEditTable position={position} valuation={valuation} />
        )}
      </Box>
    </Track>
  );
}

PositionDetailEdit.fragments = {
  position: gql`
    ${PositionPeerNavigation.fragments.position}
    ${PositionDetailEditTable.fragments.position}
    fragment PositionDetailEdit_position on Position {
      ...PositionPeerNavigation_position
      ...PositionDetailEditTable_position
      id
      name
      description
      jobCodes
      level
      ladder {
        id
        name
        department {
          id
          name
        }
      }
      organization {
        id
        name
      }
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
      }
      adjustedEquityBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
      }
    }
  `,
  valuation: gql`
    ${PositionDetailEditTable.fragments.valuation}
    fragment PositionDetailEdit_valuation on Valuation {
      ...PositionDetailEditTable_valuation
    }
  `,
};
