import { cleanEmail, isEmailValid } from "@asmbl/shared/email";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import headerLogo from "../../assets/svgs/header-logo.svg";
import { useSsoCookie } from "../../authHooks";
import { useAuth } from "../../components/Auth/AuthContext";
import { workOsAuthURL } from "../../env";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(4),
  },
  greeting: {
    fontSize: "2rem",
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

export function AuthenticationSso(): JSX.Element {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const { user } = useAuth();
  const { cookie, removeCookie } = useSsoCookie();

  if (user !== null) return <Navigate to="/" replace />;

  // Sometimes the WorkOS cookie still exists at this point, so if it does,
  // let's remove it.
  if (cookie !== undefined) {
    removeCookie();
  }

  const href = `${workOsAuthURL()}?email=${encodeURIComponent(
    cleanEmail(email)
  )}`;

  return (
    <Container maxWidth="sm">
      <Grid className={classes.logo} container>
        <img src={headerLogo} alt="Assemble" width="40%" />
      </Grid>
      <Box mx={6} p={4} component={Paper}>
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Typography
              component="h1"
              variant="h5"
              className={classes.greeting}
            >
              Please enter your email.
            </Typography>
            <form target="_self" action={href} method="GET">
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Work email address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <Button
                disabled={!isEmailValid(cleanEmail(email))}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                size="large"
                type="submit"
              >
                Sign In With SSO
              </Button>
            </form>
          </div>
        </Container>
      </Box>
    </Container>
  );
}
