import { IconButton, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ApprovalIcon } from "src/components/AssembleIcons/Brand/ApprovalIcon";
import { ArrowLeftIcon } from "src/components/AssembleIcons/Brand/ArrowLeftIcon";
import { CompCycleSettingsIcon } from "src/components/AssembleIcons/Brand/CompCycleSettingsIcon";
import { ImportIcon } from "src/components/AssembleIcons/Brand/ImportIcon";
import { PerformanceIcon } from "src/components/AssembleIcons/Brand/PerformanceIcon";
import { RulesIcon } from "src/components/AssembleIcons/Small/RulesIcon";
import { TargetIcon } from "src/components/AssembleIcons/Small/TargetIcon";
import { ThumbsUpIcon } from "src/components/AssembleIcons/Small/ThumbsUpIcon";
import { AssembleTypography } from "../../../components/AssembleTypography";
import { SidebarButton } from "../../../components/Settings/SettingsSidebar";
import {
  GRAY_1,
  GRAY_4,
  GRAY_5,
  GRAY_6,
  SETTINGS_SIDEBAR_WIDTH,
  SIDEBAR_WIDTH,
  WHITE,
} from "../../../theme";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    height: "100%",
    width: SETTINGS_SIDEBAR_WIDTH,
    position: "fixed",
    overflow: "auto",
    left: SIDEBAR_WIDTH,
    padding: theme.spacing(2, 2),
    zIndex: 10,
    backgroundColor: WHITE,
    borderLeft: `1px solid ${GRAY_6}`,
    borderRight: `1px solid ${GRAY_6}`,
  },
  backButton: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    border: `1px solid ${GRAY_5}`,
    boxSizing: "border-box",
    boxShadow: "0px 1px 3px rgba(10, 36, 64, 0.1)",
    borderRadius: "5px",
    marginRight: theme.spacing(2),
    padding: "0px",

    // Tweak position so it lines up with the comp cycle header on previous page
    marginTop: "3.5px",
    marginLeft: "-1px",
  },
  compCycleText: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

export function CompCycleSettingsSidebar({
  compCycleId,
}: {
  compCycleId: number;
}): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.sidebar}>
      <IconButton
        component={Link}
        to={`/comp-cycles/${compCycleId}`}
        className={classes.backButton}
      >
        <ArrowLeftIcon
          height="18px"
          width="18px"
          color={GRAY_4}
          hoverColor={GRAY_1}
        />
      </IconButton>
      <div className={classes.compCycleText}>
        <AssembleTypography variant="productEyebrow" textColor={GRAY_4}>
          Cycle Settings
        </AssembleTypography>
      </div>
      <SidebarButton
        icon={<CompCycleSettingsIcon width="16px" height="16px" inherit />}
        path={`/comp-cycles/${compCycleId}/settings/general`}
      >
        General Settings
      </SidebarButton>
      <SidebarButton
        icon={<PerformanceIcon width="16px" height="16px" inherit />}
        path={`/comp-cycles/${compCycleId}/settings/performance`}
      >
        Performance Ratings
      </SidebarButton>
      <SidebarButton
        icon={<RulesIcon width="16px" height="16px" inherit />}
        path={`/comp-cycles/${compCycleId}/settings/eligibility`}
      >
        Eligibility
      </SidebarButton>
      <SidebarButton
        icon={<TargetIcon width="16px" height="16px" inherit />}
        path={`/comp-cycles/${compCycleId}/settings/guidance`}
      >
        Merit & Budget
      </SidebarButton>
      <SidebarButton
        icon={<ImportIcon width="16px" height="16px" inherit />}
        path={`/comp-cycles/${compCycleId}/settings/requests`}
      >
        Upload Requests
      </SidebarButton>
      <SidebarButton
        icon={<ThumbsUpIcon width="16px" height="16px" inherit />}
        path={`/comp-cycles/${compCycleId}/settings/approvals`}
      >
        Approval & Phases
      </SidebarButton>
      <SidebarButton
        icon={<ApprovalIcon width="16px" height="16px" inherit />}
        path={`/comp-cycles/${compCycleId}/settings/close`}
      >
        Close Cycle
      </SidebarButton>
    </div>
  );
}
