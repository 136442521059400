import { gql } from "@apollo/client";
import { mapMaybe } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import React from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  AnonymizedCompensationCartesianGridProps,
  enoughCompData,
  getDataPointFill,
  getDataPointForChart,
} from "../../models/AnonymizedCompensation";
import { useExcludedBandNames } from "../../pages/LadderDetail/ExcludedBandNameContext";
import { GRAY_3, GRAY_5, WHITE } from "../../theme";
import { Range } from "../../utils";
import {
  AnonymizedCompensationBandVisualization_anonymizedCompensation as AnonymizedCompensation,
  AnonymizedCompensationBandVisualization_permissionSettings as PermissionSettings,
} from "../../__generated__/graphql";
import { AnonymizedCompensationTooltip } from "./AnonymizedCompensationTooltip";
import { NotEnoughDataLabel } from "./NotEnoughDataLabel";
import { NotEnoughDataTooltip } from "./NotEnoughDataTooltip";

const useStyles = makeStyles(() => ({
  container: { position: "relative" },
  refLine: {
    "& g > svg > path": {
      stroke: GRAY_3,
    },
  },
}));

type Props = {
  permissionSettings: PermissionSettings;
  anonymizedCompensation: AnonymizedCompensation;
  totalCompRange: Range;
  compBand: number[];
};

export default function AnonymizedCompensationBandVisualization({
  permissionSettings,
  anonymizedCompensation,
  totalCompRange,
  compBand,
}: Props): JSX.Element {
  const classes = useStyles();
  const { excludedBandNames } = useExcludedBandNames();
  const [currDataPoint, setCurrDataPoint] = React.useState<string | null>(null);

  const hasEnoughCompData = enoughCompData(
    anonymizedCompensation,
    permissionSettings
  );
  const minBand = Math.min(...compBand);
  const maxBand = Math.max(...compBand);
  const compBandMidpoint = Math.round((minBand + maxBand) / 2);

  const anonymizedCompensationData =
    anonymizedCompensation.positionAndLocationGroup.cashStatistics
      ?.anonymizedDataPoints ?? [];

  const dataPoints = mapMaybe(
    anonymizedCompensationData,
    (point) => getDataPointForChart(excludedBandNames, point)?.value
  );

  const chartData = [
    {
      compBand,
      ...Object.assign(
        {},
        ...dataPoints.map((point, index) => ({
          [`comp-data-point-${index}`]: point,
        }))
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <ResponsiveContainer width="100%" height={130}>
        <ComposedChart layout="vertical" data={chartData}>
          <CartesianGrid {...AnonymizedCompensationCartesianGridProps} />
          <XAxis
            domain={[totalCompRange.min, totalCompRange.max]}
            type="number"
            scale="linear"
            hide
          />
          <YAxis type="category" hide domain={[]} />
          <Bar
            animationDuration={1_000}
            isAnimationActive={true}
            name="compBand"
            dataKey="compBand"
            barSize={24}
            fill={GRAY_5}
            opacity="60%"
            layout="vertical"
            radius={2}
          />

          {
            <Tooltip
              cursor={false}
              content={
                !hasEnoughCompData ? (
                  <NotEnoughDataTooltip />
                ) : (
                  <AnonymizedCompensationTooltip point={currDataPoint} />
                )
              }
            />
          }

          <ReferenceLine
            className={classes.refLine}
            x={compBandMidpoint}
            stroke={WHITE}
            strokeDasharray="0rem 1.5rem 2.75rem"
            label={!hasEnoughCompData ? NotEnoughDataLabel : undefined}
          />

          {anonymizedCompensationData.map((point, index) => {
            const key = `comp-data-point-${index}`;

            const dataPoint = getDataPointForChart(excludedBandNames, point);

            if (dataPoint == null) {
              return;
            }

            // if the value is out of range of the totalCompRange
            // then we will omit rendering it to prevent issues with the
            // chart's bounds
            if (
              dataPoint.value > totalCompRange.max ||
              dataPoint.value < totalCompRange.min
            ) {
              return null;
            }

            return (
              <Scatter
                key={key}
                name={key}
                dataKey={key}
                type="number"
                fill={getDataPointFill(compBand, dataPoint.value)}
                opacity={0.5}
                onMouseOver={(e: { node: { x: number } }) => {
                  const dataPoint = e.node.x;
                  const index = dataPoints.indexOf(dataPoint);
                  setCurrDataPoint(`comp-data-point-${index}`);
                }}
                onMouseLeave={() => setCurrDataPoint(null)}
              />
            );
          })}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

AnonymizedCompensationBandVisualization.fragments = {
  permissionSettings: gql`
    fragment AnonymizedCompensationBandVisualization_permissionSettings on PermissionSettings {
      id
      anonymizedCompensationEmployeeThreshold
    }
  `,
  anonymizedCompensation: gql`
    fragment AnonymizedCompensationBandVisualization_anonymizedCompensation on AnonymizedCompensation {
      positionAndLocationGroup {
        cashStatistics {
          anonymizedDataPoints {
            salary
            commission
            bonus
          }
        }
        employeeStatistics {
          count
        }
      }
    }
  `,
};
