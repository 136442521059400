import { mapMaybe, partition } from "@asmbl/shared/utils";
import { cx } from "@emotion/css";
import {
  Checkbox,
  FormControl,
  InputBase,
  InputLabel,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useTrack } from "../../analytics";
import { FilterParam } from "../../models/FilterParams";
import { useURLSearchParams } from "../../models/URLSearchParams";
import { ChevronDownIcon } from "../AssembleIcons/Brand/ChevronDownIcon";
import { AssembleFitContentPopper } from "../AssemblePopper";
import { AssembleTruncatedTypography } from "../AssembleTruncatedTypography";
import { useAutocompleteStyles } from "../Form/AutocompleteSelect";

export type Value = string | number;

export type FilterSelectOption = {
  label: string;
  value: Value;
  disabled?: boolean;
  hideDelete?: boolean;
};

interface Props {
  label: string;
  param: FilterParam;
  options: FilterSelectOption[];
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  label: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    fontSize: "0.875rem",
  },
  selectedInput: { fontWeight: 500 },
  display: { width: "100%", paddingLeft: theme.spacing(2) },
}));

export function FilterSelect({
  label,
  param,
  options,
  disabled = false,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const autocompleteClasses = useAutocompleteStyles();

  const urlSearchParams = useURLSearchParams();
  const navigate = useNavigate();

  const value = urlSearchParams.get(param) ?? "all";

  const selectedValue = options.find(
    (option) => option.value.toString() === value
  );

  const handleChange = (newOption: FilterSelectOption) => {
    trackEvent({
      action: "Selected",
      object: "Filter",
      filterCount: 1,
      filterType: param,
      filterSelection: newOption.label,
      filterAction: "Added",
    });
    navigate(
      `?${(newOption.value !== "all"
        ? urlSearchParams.set(param, newOption.value.toString())
        : urlSearchParams.delete(param)
      ).toString()}`
    );
  };

  const labelId = `single-select-label-${label}`;

  return (
    <FormControl
      className={autocompleteClasses["medium"]}
      variant="outlined"
      fullWidth
    >
      <InputLabel id={labelId} className={autocompleteClasses.label} shrink>
        {label}
      </InputLabel>
      <Autocomplete
        openOnFocus
        disabled={disabled}
        PopperComponent={AssembleFitContentPopper}
        classes={{
          root: cx(autocompleteClasses.root, autocompleteClasses["medium"]),
          endAdornment: autocompleteClasses.endAdornment,
          popupIndicator: autocompleteClasses.popupIndicator,
          listbox: autocompleteClasses.listbox,
          option: autocompleteClasses.option,
          popper: autocompleteClasses.popover,
          paper: autocompleteClasses.paper,
        }}
        popupIcon={<ChevronDownIcon inline inherit />}
        disableClearable
        renderInput={(params) => (
          <InputBase
            {...params.InputProps}
            id={params.id}
            fullWidth={params.fullWidth}
            disabled={params.disabled}
            className={autocompleteClasses.inputComponent}
            inputProps={{
              ...params.inputProps,
              className: autocompleteClasses.input,
              "aria-labelledby": labelId,
            }}
          />
        )}
        value={selectedValue}
        getOptionLabel={(option) => option.label}
        options={options}
        onChange={(_, option) => handleChange(option)}
      />
    </FormControl>
  );
}

export function MultipleFilterSelect({
  label,
  param,
  options,
}: Props): JSX.Element {
  const classes = useStyles();
  const autocompleteClasses = useAutocompleteStyles();
  const { trackEvent } = useTrack();
  const navigate = useNavigate();
  const urlSearchParams = useURLSearchParams();

  const value = urlSearchParams.get(param) ?? "all";
  const isActive = value !== "all";
  const urlValues = value.split(",");

  const selectedValues = mapMaybe(
    value
      .split(",")
      .map((value) =>
        options.find((option) => option.value.toString() === value)
      ),
    (value) => value
  );

  const handleChange = (selectedOptions: FilterSelectOption[]) => {
    // either the only selected option was de-selected, or the value 'all' was
    // de-selected
    if (selectedOptions.length === 0) {
      trackEvent({
        object: "Multiple Filters",
        action: "Selected",
        filterCount: 0,
        filterType: param,
        filterSelection: "none",
        filterAction: "Cleared",
      });
      // if manager filter is cleared, also clear reports
      return param === FilterParam.MANAGER
        ? navigate(
            `?${urlSearchParams
              .deleteMany([param, FilterParam.REPORTS])
              .set(param, "null")
              .toString()}`
          )
        : navigate(`?${urlSearchParams.set(param, "null").toString()}`);
    }

    // if the URL value is 'all' and we made it to this point, then that means
    // that the user has de-selected the values that are listed in
    // selectedOptions

    if (!isActive) {
      // any option that is part of selectedOptions should now not be selected
      const partitions = partition(
        options.map((option) => option.value.toString()),
        (value) =>
          selectedOptions
            .map((option) => option.value.toString())
            .includes(value)
      );

      // `[0] is all matching elements, [1] is all non-matching elements`
      const newParams = partitions[1].join(",");

      trackEvent({
        object: "Multiple Filters",
        action: "Selected",
        filterCount: 0,
        filterType: param,
        filterSelection: selectedOptions
          .map((option) => option.label)
          .join(", "),
        filterAction: "Deleted",
      });

      return navigate(`?${urlSearchParams.set(param, newParams).toString()}`);
    }

    const allSelected = selectedOptions.find(
      (selectedOption) => selectedOption.value.toString() === "all"
    );

    // the value 'all' was selected
    if (allSelected != null) {
      trackEvent({
        object: "Multiple Filters",
        action: "Selected",
        filterCount: 1,
        filterType: param,
        filterSelection: "all",
        filterAction: "Added",
      });
      // if manager is updated to "all", delete the reports param
      return param === FilterParam.MANAGER
        ? navigate(
            `?${urlSearchParams
              .deleteMany([param, FilterParam.REPORTS])
              .toString()}`
          )
        : navigate(`?${urlSearchParams.delete(param).toString()}`);
    }

    const newParams = selectedOptions.map((option) => option.value).join(",");
    trackEvent({
      object: "Multiple Filters",
      action: "Selected",
      filterCount: selectedOptions.length,
      filterType: param,
      filterSelection: selectedOptions.map((option) => option.label).join(", "),
      filterAction: "Added",
    });
    return navigate(`?${urlSearchParams.set(param, newParams).toString()}`);
  };

  const labelId = `multiselect-label-${label}`;

  return (
    <FormControl
      className={autocompleteClasses["medium"]}
      variant="outlined"
      fullWidth
    >
      <InputLabel id={labelId} className={autocompleteClasses.label} shrink>
        {label}
      </InputLabel>

      <Autocomplete
        PopperComponent={AssembleFitContentPopper}
        classes={{
          root: cx(autocompleteClasses.root, autocompleteClasses["medium"]),
          endAdornment: autocompleteClasses.endAdornment,
          popupIndicator: autocompleteClasses.popupIndicator,
          listbox: autocompleteClasses.listbox,
          option: autocompleteClasses.option,
          popper: autocompleteClasses.popover,
          paper: autocompleteClasses.paper,
        }}
        multiple
        disableClearable
        disableCloseOnSelect
        value={selectedValues}
        popupIcon={<ChevronDownIcon inherit />}
        renderInput={(params) => (
          <InputBase
            {...params.InputProps}
            id={params.id}
            fullWidth={params.fullWidth}
            disabled={params.disabled}
            className={autocompleteClasses.inputComponent}
            inputProps={{
              ...params.inputProps,
              className: autocompleteClasses.input,
              "aria-labelledby": labelId,
            }}
          />
        )}
        renderOption={(option) => {
          const selected = selectedValues
            .map((selected) => selected.value)
            .includes(option.value);

          return (
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              <Checkbox
                icon={<CheckBoxOutlineBlank />}
                checkedIcon={<CheckBox />}
                checked={selected || !isActive}
                indeterminate={false}
                color="primary"
              />
              <Typography
                component="span"
                className={clsx(classes.label, {
                  [classes.selectedInput]: selected,
                })}
              >
                {option.label}
              </Typography>
            </MenuItem>
          );
        }}
        renderTags={() => {
          const nullSelected = isActive && urlValues[0] === "null";

          return (
            <span className={classes.display}>
              <AssembleTruncatedTypography variant="productParagraphMedium">
                {!isActive
                  ? `All ${label}`
                  : nullSelected
                    ? ""
                    : `${urlValues.length} selected`}
              </AssembleTruncatedTypography>
            </span>
          );
        }}
        getOptionLabel={(option) => option.label}
        options={options}
        onChange={(_, option) => handleChange(option)}
      />
    </FormControl>
  );
}
