import { useQuery } from "@apollo/client";
import { useState } from "react";
import { BorderlessSelect } from "../../components/Form/BorderlessSelect";
import { ActionBarItem } from "../../components/Visualization/ActionBarItem";
import { ComparatioChart } from "../../components/Visualization/ComparatioChart";
import { FilterableCard } from "../../components/Visualization/FilterableCard";
import { GET_EMPLOYEE_ANALYTICS_QUARTILES } from "../../queries";
import { GetEmployeeAnalyticsQuartiles } from "../../__generated__/graphql";

interface Props {
  departments: string[];
  levels: number[];
  groupByFilter: string;
  title: string;
}

export function Comparatio({
  departments,
  levels,
  title,
  groupByFilter,
}: Props): JSX.Element {
  const departmentFilters = ["All Departments", ...departments];
  const levelFilters = ["All Levels", ...levels.map((num) => `Level ${num}`)];
  const [departmentFilter, setDepartmentFilter] = useState<string | null>(null);
  const handleDepartmentChange = (value: string) => {
    value === "All Departments"
      ? setDepartmentFilter(null)
      : setDepartmentFilter(value);
  };
  const [levelFilter, setLevelFilter] = useState<number | null>(null);

  const handleLevelChange = (value: string) => {
    if (value === "All Levels") {
      setLevelFilter(null);
    } else {
      // value will be `Level {number}` so need to get just the int part of the
      // string
      const level = value.split(" ")[1];
      setLevelFilter(parseInt(level));
    }
  };

  const { data: quartilesByGroup, loading } =
    useQuery<GetEmployeeAnalyticsQuartiles>(GET_EMPLOYEE_ANALYTICS_QUARTILES, {
      variables: {
        groupByFilterOpts: {
          groupBy: groupByFilter,
          department: departmentFilter,
          level: levelFilter,
        },
      },
    });

  const quartiles = quartilesByGroup?.employeeAnalytics.quartiles ?? [];

  const generateTitleScope = (title: string) => {
    const levelText = levelFilter === null ? "" : `for Level ${levelFilter}`;
    const departmentText =
      departmentFilter === null
        ? "Across the Organization"
        : `Across ${departmentFilter}`;
    return `${title} ${departmentText} ${levelText}`;
  };

  return (
    <FilterableCard
      title={generateTitleScope(title)}
      actionBar={
        <>
          <ActionBarItem>
            <BorderlessSelect
              options={departmentFilters}
              selected={departmentFilter ?? "All Departments"}
              onChange={handleDepartmentChange}
            />
          </ActionBarItem>
          <ActionBarItem>
            <BorderlessSelect
              options={levelFilters}
              selected={
                levelFilter === null ? "All Levels" : `Level ${levelFilter}`
              }
              onChange={handleLevelChange}
            />
          </ActionBarItem>
        </>
      }
    >
      <ComparatioChart items={quartiles} height="550px" loading={loading} />
    </FilterableCard>
  );
}
