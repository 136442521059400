import { gql } from "@apollo/client";
import { findRecommendedPosition } from "@asmbl/shared/comp-recommendation";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { basicComparator, contramapWithOrder } from "@asmbl/shared/sort";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { CalendarIcon } from "src/components/AssembleIcons/Extra-Small/CalendarIcon";
import { ClockIcon } from "src/components/AssembleIcons/Extra-Small/ClockIcon";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { GRAY_4 } from "src/theme";
import {
  CompRecommendationStatus,
  CondensedTableNewPositionCell2_participant as Participant,
  PayPeriodType,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { XLG_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  rowActedOn: {
    "& path": {
      stroke: `${GRAY_4} !important`,
    },
  },
  icon: {
    flex: "none",
  },
}));

export function CondensedTableNewPositionCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { availablePositions } = useTableData();
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const classes = useStyles();
  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];
  const newPosition = findRecommendedPosition(recItems, availablePositions);
  const oldPosition = employee.subject.activeEmployment?.position?.name;
  const label = newPosition?.name ?? oldPosition ?? "-";

  const showHourly = Boolean(
    compStructure?.allowHourlyEmployees === true &&
      isEnabled(FeatureFlag.HourlyEmployees) &&
      (newPosition?.name != null || oldPosition)
  );
  const positionType =
    newPosition?.type ?? employee.subject.activeEmployment?.payPeriod;

  const reviewStatus = employee.compRecommendation?.reviewStatus ?? null;
  const actionNeededStatuses = [
    CompRecommendationStatus.FUTURE_PHASE,
    CompRecommendationStatus.NEEDS_REVIEW,
    CompRecommendationStatus.LATE,
  ];
  const rowActedOn = Boolean(
    reviewStatus != null && !actionNeededStatuses.includes(reviewStatus)
  );

  return (
    <div className={clsx(classes.root, rowActedOn && classes.rowActedOn)}>
      {showHourly && (
        <>
          {positionType === PayPeriodType.ANNUAL && (
            <CalendarIcon className={classes.icon} />
          )}
          {positionType === PayPeriodType.HOURLY && (
            <ClockIcon className={classes.icon} />
          )}
        </>
      )}
      <AssembleTruncatedTypography lines={1} title={label}>
        {label}
      </AssembleTruncatedTypography>
    </div>
  );
}

CondensedTableNewPositionCell2.fragments = {
  participant: gql`
    fragment CondensedTableNewPositionCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          payPeriod
          position {
            id
            name
            type
          }
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        reviewStatus
        latestSubmittedItems {
          id
          recommendationType
          recommendedPosition {
            id
          }
        }
      }
    }
  `,
  position: gql`
    fragment CondensedTableNewPositionCell2_position on Position {
      id
      name
      type
    }
  `,
};

CondensedTableNewPositionCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.NEW_POSITION
);
CondensedTableNewPositionCell2.id = ColumnIds.NEW_POSITION;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNewPositionCell2,
  Header: CondensedTableNewPositionCell2.Header,
  id: CondensedTableNewPositionCell2.id,
  width: XLG_COL_WIDTH,
};
CondensedTableNewPositionCell2.column = column;
CondensedTableNewPositionCell2.ordering = ({
  availablePositions,
}: Parameters<ColumnComponent2["ordering"]>[0]) =>
  contramapWithOrder(
    (e: Participant) => {
      const recItems = e.compRecommendation?.latestSubmittedItems ?? [];
      const newPosition = findRecommendedPosition(recItems, availablePositions);
      return newPosition?.name ?? "-";
    },
    (a, b, order) => {
      const isSortAsc = order === "asc";
      if (a === "-") {
        return isSortAsc ? 1 : -1;
      }
      if (b === "-") {
        return isSortAsc ? -1 : 1;
      }
      return basicComparator(a, b);
    }
  );
