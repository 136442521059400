import { useMutation, useQuery } from "@apollo/client";
import fastDeepEqual from "fast-deep-equal";
import {
  IllustrativeOutcomesData,
  IllustrativeOutcomesForm,
} from "../../components/Settings/IllustrativeOutcomesForm";
import Loading from "../../components/Settings/Loading";
import { UPSERT_OFFER_CONFIG } from "../../mutations";
import { GET_EQUITY_SETTINGS } from "../../queries";
import {
  GetEquitySettings,
  UpsertOfferConfig,
  UpsertOfferConfigVariables,
} from "../../__generated__/graphql";
import { PageContainer } from "./PageContainer";

const IllustrativeOutcomes = (): JSX.Element | null => {
  const { data: equitySettingsData, loading: equitySettingsDataLoading } =
    useQuery<GetEquitySettings>(GET_EQUITY_SETTINGS);

  const [updateOfferConfig, { loading: offerConfigUpdating }] = useMutation<
    UpsertOfferConfig,
    UpsertOfferConfigVariables
  >(UPSERT_OFFER_CONFIG, {
    refetchQueries: [{ query: GET_EQUITY_SETTINGS }],
  });

  const isOfferConfigDataDirty = (
    initialFormData: IllustrativeOutcomesData,
    newFormData: IllustrativeOutcomesData
  ) => {
    return !fastDeepEqual(
      getOfferConfigInputVariables(initialFormData),
      getOfferConfigInputVariables(newFormData)
    );
  };

  const saveFormData = async (
    initialFormData: IllustrativeOutcomesData,
    newFormData: IllustrativeOutcomesData
  ) => {
    const offerConfigMutation = isOfferConfigDataDirty(
      initialFormData,
      newFormData
    )
      ? updateOfferConfig({
          variables: getOfferConfigInputVariables(newFormData),
        })
      : null;

    return await offerConfigMutation;
  };

  if (equitySettingsDataLoading) return <Loading />;
  if (!equitySettingsData) return null;

  const { offerConfig, valuation } = equitySettingsData;

  const isSaving = offerConfigUpdating;
  return (
    <PageContainer
      header="Illustrative Outcomes"
      description="Choose which future outcomes to illustrate in Offers."
      centerContent
    >
      <IllustrativeOutcomesForm
        offerConfig={offerConfig}
        valuation={valuation}
        onSave={saveFormData}
        isSaving={isSaving}
      />
    </PageContainer>
  );
};

function getOfferConfigInputVariables(
  data: IllustrativeOutcomesData
): UpsertOfferConfigVariables {
  const { outcomes, defaultOutcomeDescription } = data;

  const outcomesWithValues = outcomes.filter((outcome) => outcome.value !== 0);

  return {
    data: {
      exitOutcomes: outcomesWithValues.map(({ value }) => value),
      xAxisAnnotations: outcomesWithValues.map(({ annotation }) => annotation),
      defaultOutcomeDescription,
    },
  };
}

export default IllustrativeOutcomes;
