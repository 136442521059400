import { makeStyles } from "@material-ui/core";
import { PageHeader, PageTitle } from "../../components/Layout/PageHeader";
import { NavSwitch } from "./NavSwitch";

const useStyles = makeStyles(() => ({
  headerLeft: {
    flexBasis: "33%",
    textAlign: "left",
  },
  headerCenter: {
    flexBasis: "33%",
    textAlign: "center",
  },
  headerRight: {
    flexBasis: "33%",
    textAlign: "right",
  },
}));

export function PeopleHeader({
  showNavSwitch,
}: {
  showNavSwitch: boolean;
}): JSX.Element {
  const classes = useStyles();

  return (
    <PageHeader>
      <div className={classes.headerLeft}>
        <PageTitle>People</PageTitle>
      </div>
      <div className={classes.headerCenter}>
        {showNavSwitch && <NavSwitch />}
      </div>
      <div className={classes.headerRight} />
    </PageHeader>
  );
}
