import { gql } from "@apollo/client";
import { mapMaybe } from "@asmbl/shared/utils";
import { TableBody, TableRow, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import {
  AutomatedManagerAccessType,
  GetDepartments,
  AutomatedManagerAccessTable_organization as Organization,
  AutomatedManagerAccessTable_report as Report,
} from "src/__generated__/graphql";
import {
  WireTable,
  WireTableHead,
  WireTableUnsortableHeaderCell,
} from "src/components/Table/WireTable";
import { AutomatedManagerAccessMarketRow } from "./AutomatedManagerAccessMarketRow";

const useStyles = makeStyles((theme) => ({
  marketCell: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    marginLeft: "30px", // to account for the missing expand/collapse icon
  },
}));

type Props = {
  reports: Report[];
  organization: Organization;
  jobStructure: GetDepartments;
};

export function AutomatedManagerAccessTable({
  reports,
  jobStructure,
  organization,
}: Props): JSX.Element {
  const classes = useStyles();
  const [detailsShowing, setDetailsShowing] = useState(false);

  const accessibleCurrentPositionIds = mapMaybe(
    reports,
    (report) => report.activeEmployment?.positionId
  );

  const accessibleNextPositionIds = reports.flatMap((report) =>
    report.nextPositions.map((p) => p.id)
  );

  const hasAnyNestedData =
    organization.permissionSettings.automatedManagerAccess ===
    AutomatedManagerAccessType.REPORTS_CURRENT_AND_NEXT_POSITIONS
      ? [...accessibleCurrentPositionIds, ...accessibleNextPositionIds].length >
        0
      : accessibleCurrentPositionIds.length > 0;

  return (
    <WireTable>
      <WireTableHead>
        <TableRow>
          <WireTableUnsortableHeaderCell
            width="25%"
            cellTitle={
              <div className={clsx({ [classes.marketCell]: hasAnyNestedData })}>
                <span>Market</span>
              </div>
            }
          />
          <WireTableUnsortableHeaderCell width="25%" cellTitle="Departments" />
          <WireTableUnsortableHeaderCell width="25%" cellTitle="Ladders" />
          <WireTableUnsortableHeaderCell width="25%" cellTitle="Positions" />
        </TableRow>
      </WireTableHead>
      <TableBody>
        <AutomatedManagerAccessMarketRow
          organization={organization}
          jobStructure={jobStructure}
          reports={reports}
          showDetails={detailsShowing}
          onToggleDetails={() => setDetailsShowing(!detailsShowing)}
        />
      </TableBody>
    </WireTable>
  );
}

AutomatedManagerAccessTable.fragments = {
  organization: gql`
    ${AutomatedManagerAccessMarketRow.fragments.organization}
    fragment AutomatedManagerAccessTable_organization on Organization {
      id
      ...AutomatedManagerAccessMarketRow_organization
    }
  `,
  report: gql`
    ${AutomatedManagerAccessMarketRow.fragments.report}
    fragment AutomatedManagerAccessTable_report on Employee {
      id
      ...AutomatedManagerAccessMarketRow_report
    }
  `,
};
