import { useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useState } from "react";
import {
  BandUnit,
  CompStructure,
  EmplaceBands,
  EmplaceBandsVariables,
  LadderDetail_position as Position,
} from "src/__generated__/graphql";
import { AssembleFlatfileButton } from "src/components/AssembleFlatfileButton";
import { EMPLACE_BANDS } from "src/mutations/Position";
import { getFlatfileDataUpload } from "./FlatfileDataUpload";

const useStyles = makeStyles({
  initialContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: 32,
  },
  matchingContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: 12,
  },
  column: { display: "flex", flexDirection: "column" },
});

type Props = {
  positions: Position[];
  marketId: number | null;
  locationGroupId: number | null;
  compStructure: CompStructure;
};

type ValidRow = {
  jobCode: string;
  level: string;
  positionId: string;
  [key: string]: string;
};

export function DataUploadContainer({
  positions,
  marketId,
  locationGroupId,
  compStructure,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDataSet, setSelectedDataSet] = useState<string | null>(null);
  const [marketData, setMarketData] = useState<ValidRow[] | null>(null);
  const [mappings, setMappings] = useState<{
    [positionId: string]: ValidRow;
  }>({});

  const [emplaceBands] = useMutation<EmplaceBands, EmplaceBandsVariables>(
    EMPLACE_BANDS
  );

  const classes = useStyles();

  const handleSave = async () => {
    const updateRequests = Object.keys(mappings).map((positionId) => {
      const mapping = mappings[positionId];
      return {
        positionId: Number(positionId),
        currencyCode: mapping.currencyCode,
        marketId: marketId ?? -1,
        locationGroupId,
        cashBands: compStructure.cashBandTypes.map((cashBandType) => {
          const cashBandTypeMappings = Object.keys(mapping).filter(
            (marketDataPoint) =>
              marketDataPoint.split(" - ")[0] === cashBandType
          );
          return {
            name: cashBandType,
            currencyCode: mapping.currencyCode,
            bandPoints: cashBandTypeMappings.map((uploadFieldName) => {
              const [_, marketDataPoint] = uploadFieldName.split(" - ");
              const value = Number(mapping[uploadFieldName]);
              return {
                name: marketDataPoint,
                unit: BandUnit.CASH,
                value,
              };
            }),
          };
        }),
        equityBands: compStructure.equityBandTypes.map((equityBandType) => {
          const equityBandTypeMappings = Object.keys(mapping).filter(
            (marketDataPoint) =>
              marketDataPoint.split(" - ")[0] === equityBandType
          );
          return {
            name: equityBandType,
            currencyCode: mapping.currencyCode,
            bandPoints: equityBandTypeMappings.map((uploadFieldName) => {
              const [_, marketDataPoint] = uploadFieldName.split(" - ");
              const value = Number(mapping[uploadFieldName]);
              return {
                name: marketDataPoint,
                unit: BandUnit.CASH,
                value,
              };
            }),
          };
        }),
      };
    });
    await Promise.all(
      updateRequests.map(async (updateRequest) => {
        await emplaceBands({
          variables: updateRequest as unknown as EmplaceBandsVariables,
        });
      })
    );
  };

  return (
    <div>
      <Button onClick={() => setIsOpen(true)}>
        Upload Data Set for this Ladder
      </Button>
      <Dialog
        disableEnforceFocus
        onClose={() => setIsOpen(false)}
        open={isOpen}
      >
        {marketData == null && (
          <div className={classes.initialContainer}>
            <div>What data set are you using?</div>
            <Select
              variant="outlined"
              value={selectedDataSet}
              onChange={(e) => setSelectedDataSet(e.target.value as string)}
            >
              <MenuItem value="CompGrid">CompGrid</MenuItem>
              <MenuItem value="Radford" disabled>
                Radford
              </MenuItem>
              <MenuItem value="Carta" disabled>
                Carta
              </MenuItem>
            </Select>
            <AssembleFlatfileButton
              settings={getFlatfileDataUpload(compStructure)}
              // eslint-disable-next-line @typescript-eslint/require-await
              onData={async (results) => {
                setMarketData(results.validData);
              }}
              render={(_, launch) => (
                <Button onClick={launch} variant="contained">
                  Upload Data
                </Button>
              )}
            />
          </div>
        )}
        {marketData != null && (
          <div className={classes.column}>
            What should each of these map to?
            {positions.map((position) => (
              <div
                className={classes.matchingContainer}
                key={`position-${position.id}`}
              >
                <span>{position.name}</span>
                <Select
                  onChange={(e) => {
                    const positionName = e.target.value;
                    const fullRow = marketData.find(
                      (row) => row.position === positionName
                    );

                    setMappings({ ...mappings, [position.id]: fullRow });
                  }}
                >
                  {marketData.map((datum) => (
                    <MenuItem key={datum.position} value={datum.position}>
                      {datum.position} (Level {datum.level})
                    </MenuItem>
                  ))}
                </Select>
              </div>
            ))}
            <Button onClick={handleSave}>Save</Button>
          </div>
        )}
      </Dialog>
    </div>
  );
}
