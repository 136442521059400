import { User as FirebaseUser } from "firebase/auth";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSsoCookie } from "../../authHooks";
import { authInstance } from "../../firebase";

export type BearerContextType = {
  getToken: () => Promise<string | null>;
  clearToken: () => Promise<void>;
};

const bearerContext = createContext<BearerContextType>({
  getToken: () => Promise.resolve(null),
  clearToken: () => Promise.resolve(),
});

type FirebaseState =
  | { state: "loading"; currentUser: null }
  | { state: "ready"; currentUser: FirebaseUser | null };

export function BearerTokenProvider({ children }: { children: ReactNode }) {
  // Work OS provides us a token via a server-authenticated cookie
  const { cookie: workOsToken, removeCookie } = useSsoCookie();

  // Firebase gives us its token client-side, so we sync it to state.
  const [firebaseState, setFirebaseState] = useState<FirebaseState>({
    state: "loading",
    currentUser: null,
  });

  useEffect(() => {
    const unsubscribe = authInstance.onAuthStateChanged((currentUser) => {
      setFirebaseState({ state: "ready", currentUser });
    });
    return unsubscribe;
  }, []);

  const getToken = useCallback(async () => {
    const token =
      workOsToken != null && workOsToken !== ""
        ? workOsToken
        : await firebaseState.currentUser?.getIdToken();

    if (token == null) {
      // eslint-disable-next-line no-console
      console.error("Failed to fetch auth token");
    }

    return token ?? null;
  }, [firebaseState.currentUser, workOsToken]);

  const clearToken = useCallback(async () => {
    removeCookie();
    await authInstance.signOut();
  }, [removeCookie]);

  if (firebaseState.state === "loading") {
    // Let Firebase completely initialize before doing anything.
    return null;
  }

  return (
    <bearerContext.Provider value={{ getToken, clearToken }}>
      {children}
    </bearerContext.Provider>
  );
}

export const useBearerToken = (): BearerContextType => {
  return useContext(bearerContext);
};
