import { makeStyles, TableCell } from "@material-ui/core";
import { UserAvatar } from "../../UserAvatar";

const useStyles = makeStyles(() => ({
  button: {
    padding: 0,
  },
  complete: {},
  avatar: {
    display: "flex",
    justifyContent: "center",
  },
  clickableAvatar: {
    display: "block",
    ".MuiTableRow-root:hover &, $complete &, $button:hover &, $button:focus &":
      {
        display: "none",
      },
  },
  check: {
    display: "none",
    ".MuiTableRow-root:hover &, $complete &, $button:hover &, $button:focus &":
      {
        display: "block",
      },
  },
}));

interface Props {
  displayName: string | undefined | null;
  photoURL: string | undefined | null;
}

export function AvatarCell({ displayName, photoURL }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <TableCell role="gridcell" padding="none" align="center">
      <div className={classes.avatar}>
        <UserAvatar displayName={displayName} photoURL={photoURL} />
      </div>
    </TableCell>
  );
}
