import { gql } from "@apollo/client";
import {
  PeopleTable_employee as Employee,
  PositionFieldsMinimal as Position,
} from "../../__generated__/graphql";
import { useTrack } from "../../analytics";
import { useAuth } from "../../components/Auth/AuthContext";
import { useEmployeeDetail } from "./EmployeeDetailDrawer/EmployeeDetailDrawer";
import { EmptyState } from "./EmptyTableState";
import { ReportsTable } from "./YourTeam/ReportsTable";

interface PeopleTableProps {
  employees: Employee[];
  isFiltered?: boolean;
  onLevel(employee: Employee, positionId: number | null): unknown;
  positions: Position[];
  totalCount: number;
  isLoading: boolean;
}

export function PeopleTable({
  employees,
  isFiltered = false,
  onLevel,
  positions,
  totalCount,
  isLoading,
}: PeopleTableProps): JSX.Element {
  const employeeDetailId = useEmployeeDetail();
  const { Track } = useTrack({
    subArea: "People Table",
  });
  const { permissions } = useAuth();
  const hasCompBandAccess = permissions.hasCompensationAccess();

  if (employees.length === 0 && !isFiltered && !isLoading)
    return <EmptyState />;

  return (
    <Track>
      <ReportsTable
        reports={employees}
        employeeDetailId={employeeDetailId}
        canUserLevel
        onLevel={onLevel}
        positions={positions}
        hasCompBandAccess={hasCompBandAccess}
        totalCount={totalCount}
        isLoading={isLoading}
      />
    </Track>
  );
}

PeopleTable.fragments = {
  employee: gql`
    ${ReportsTable.fragments.employee}
    fragment PeopleTable_employee on Employee2 {
      id
      ...ReportsTable_employee
    }
  `,
};
