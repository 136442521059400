import {
  Box,
  Divider,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { GRAY_4 } from "../../../theme";
import { KeyedCustomField } from "./OfferContentsForm";

const MAX_DESCRIPTION_LENGTH = 150;

const useStyles = makeStyles((theme) => ({
  nameField: {
    padding: theme.spacing(2, 0, 1, 0),
    width: "100%",
  },
  fieldLengthText: {
    color: GRAY_4,
    padding: theme.spacing(2, 0, 1, 2),
    display: "flex",
    alignItems: "center",
  },
}));

type Props = {
  field: KeyedCustomField;
  onFieldChange: (newField: KeyedCustomField) => void;
};

export function TextDescriptionEditor({
  field,
  onFieldChange,
}: Props): JSX.Element {
  const classes = useStyles();
  const [description, setDescription] = useState<string>(
    field.description ?? ""
  );

  return (
    <>
      <Divider />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TextField
          placeholder="i.e. - What's the vesting schedule..."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onBlur={() => {
            const newDescription = description.trim();
            onFieldChange({ ...field, description: newDescription });
          }}
          fullWidth
          multiline
          minRows={1}
          maxRows={20}
          InputProps={{
            className: classes.nameField,
            disableUnderline: true,
          }}
          inputProps={{
            maxLength: MAX_DESCRIPTION_LENGTH,
            "aria-label": "Custom Field Description",
          }}
        />
        <Typography className={classes.fieldLengthText}>
          {`${description.length}/${MAX_DESCRIPTION_LENGTH}`}
        </Typography>
      </Box>
    </>
  );
}
