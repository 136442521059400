import { useState } from "react";
import { SelectYourHRIS } from "./SelectYourHRIS";

type Props = {
  step: number;
  updateStep: (step: number) => unknown;
};

type UploadData = {
  mergeConfigId: number | null;
  mergeConfigName: string | null;
};

export default function CompCycleStep({
  step,
  updateStep,
}: Props): JSX.Element | null {
  const [, setUploadData] = useState<UploadData>({
    mergeConfigId: null,
    mergeConfigName: null,
  });

  switch (step) {
    case 0:
      return (
        <SelectYourHRIS
          onSelect={(mergeConfigId, mergeConfigName) => {
            setUploadData({
              mergeConfigId,
              mergeConfigName,
            });
            updateStep(1);
          }}
        />
      );
    default:
      return null;
  }
}
