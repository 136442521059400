import { cx } from "@emotion/css";
import { Box, Button, makeStyles } from "@material-ui/core";
import { ReactNode, useState } from "react";
import { ERROR_BG, GRAY_2, GRAY_4, WHITE } from "../theme";
import { CloseCircleIcon } from "./AssembleIcons/Small/CloseCircleIcon";

type Level = "success" | "info" | "warning" | "info-gradient" | "error";
interface Props {
  children: ReactNode;
  className?: string;
  onClose?: () => unknown;
  level?: Level;
  align?: "left" | "center";
  /**
   * The close button should be centered when the banner will be <= 72px tall.
   */
  centeredClose?: boolean;
  fullWidth?: boolean;
  noMargin?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(4),
    position: "relative",
    marginBottom: theme.spacing(2),
    borderRadius: "5px",
  },

  success: {
    background: "linear-gradient(148.74deg, #17BD54 27.64%, #089562 87.25%)",
  },

  info: { background: "rgba(15, 196, 250, 0.15)" },

  "info-gradient": {
    background: `linear-gradient(148.74deg, #2881FF 27.64%, #0FC4FA 87.25%)`,
    boxShadow: "0px 5px 15px rgba(15, 196, 250, 0.3)",
    color: WHITE,
    borderRadius: "8px",
  },

  warning: {
    background: "linear-gradient(148.88deg, #FAA01A 29.51%, #FFB628 85.88%)",
    boxShadow: "0px 4px 15px rgba(252, 169, 32, 0.3)",
    color: WHITE,
  },

  error: {
    background: ERROR_BG,
    boxShadow: "0px 4px 15px rgba(252, 169, 32, 0.3)",
    color: WHITE,
  },

  closeButton: {
    position: "absolute",
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
    padding: 0,
    minWidth: 0,
  },

  centeredCloseButton: {
    top: "50%",
    transform: "translateY(-50%)",
  },

  left: { textAlign: "left" },
  center: { textAlign: "center" },
  fullWidth: {
    width: "100%",
  },
  noMargin: { marginBottom: 0 },
}));

export function InfoBanner({
  children,
  onClose,
  className = "",
  level = "info",
  align = "left",
  centeredClose = false,
  fullWidth = false,
  noMargin = false,
}: Props): JSX.Element {
  const classes = useStyles();

  const getIconColor = (level: Level): string => {
    return level === "warning" ||
      level === "info-gradient" ||
      level === "success" ||
      level === "error"
      ? WHITE
      : GRAY_4;
  };

  const getIconHoverColor = (level: Level): string => {
    return level === "warning" ||
      level === "info-gradient" ||
      level === "success" ||
      level === "error"
      ? WHITE
      : GRAY_2;
  };

  const iconColor = getIconColor(level);
  const iconHoverColor = getIconHoverColor(level);

  return (
    <Box
      className={cx(
        classes.root,
        classes[level],
        classes[align],
        className,
        fullWidth ? classes.fullWidth : null,
        noMargin ? classes.noMargin : null
      )}
    >
      {children}
      {onClose ? (
        <Button
          className={cx(classes.closeButton, {
            [classes.centeredCloseButton]: centeredClose,
          })}
          variant="text"
          onClick={onClose}
        >
          <CloseCircleIcon color={iconColor} hoverColor={iconHoverColor} />
        </Button>
      ) : null}
    </Box>
  );
}
/*
  An uncontrolled InfoBanner that handles dismissing itself.
  While the InfoBanner provides an onClose callback, it relies
  on the parent to manage the state to open or close the banner.
  This variant appears until dismissed, and then hides itself. 
 */
export function DismissableInfoBanner(
  props: Omit<Props, "onClose">
): JSX.Element | null {
  const [open, setOpen] = useState(true);

  return open ? <InfoBanner {...props} onClose={() => setOpen(false)} /> : null;
}
