import { SearchableFilter } from "src/components/Filter/SearchableFilter";
import { SearchableFilterContainer } from "src/components/Filter/SearchableFilterContainer";
import {
  CostToMoveFilterName,
  CostToMoveFilterParam,
  FilterOptionsType,
} from "./CostToMoveFilters";

type Props = {
  onChange: (field: string, values: number[]) => void;
  onReset: () => void;
  filter: { [key: string]: number[] | null | undefined };
  filterOptions: FilterOptionsType;
};

const CostToMoveFilterBar = ({
  onChange,
  onReset,
  filter,
  filterOptions,
}: Props) => {
  const activeFilterMap: Record<CostToMoveFilterName, boolean> = {
    [CostToMoveFilterName.CURRENT_DEPARTMENT]:
      (filter[CostToMoveFilterParam.CURRENT_DEPARTMENT]?.length ?? 0) > 0,
    [CostToMoveFilterName.MANAGER]:
      (filter[CostToMoveFilterParam.MANAGER]?.length ?? 0) > 0,
    [CostToMoveFilterName.REPORTS]:
      (filter[CostToMoveFilterParam.REPORTS]?.length ?? 0) > 0,
    [CostToMoveFilterName.CURRENT_LADDER]:
      (filter[CostToMoveFilterParam.CURRENT_LADDER]?.length ?? 0) > 0,
    [CostToMoveFilterName.CURRENT_LEVEL]:
      (filter[CostToMoveFilterParam.CURRENT_LEVEL]?.length ?? 0) > 0,
    [CostToMoveFilterName.LOCATION_GROUP]:
      (filter[CostToMoveFilterParam.LOCATION_GROUP]?.length ?? 0) > 0,
    [CostToMoveFilterName.CURRENT_BAND_POSITION]:
      (filter[CostToMoveFilterParam.CURRENT_BAND_POSITION]?.length ?? 0) > 0,
  } as const;

  const activeFilters = Object.entries(activeFilterMap)
    .filter(([, value]) => value)
    .map(([key]) => key);

  return (
    <SearchableFilterContainer activeFilters={activeFilters} onReset={onReset}>
      <SearchableFilter
        name={CostToMoveFilterName.CURRENT_DEPARTMENT}
        options={filterOptions[CostToMoveFilterParam.CURRENT_DEPARTMENT].map(
          (department) => ({ value: department.id, label: department.name })
        )}
        selected={filter[CostToMoveFilterParam.CURRENT_DEPARTMENT] ?? []}
        onChange={(values) =>
          onChange(CostToMoveFilterParam.CURRENT_DEPARTMENT, values)
        }
      />
      <SearchableFilter
        name={CostToMoveFilterName.MANAGER}
        options={filterOptions[CostToMoveFilterParam.MANAGER].map(
          (manager) => ({ value: manager.id, label: manager.displayName })
        )}
        selected={filter[CostToMoveFilterParam.MANAGER] ?? []}
        onChange={(values) => onChange(CostToMoveFilterParam.MANAGER, values)}
      />
      <SearchableFilter
        name={CostToMoveFilterName.REPORTS}
        options={filterOptions[CostToMoveFilterParam.REPORTS]}
        selected={filter[CostToMoveFilterParam.REPORTS] ?? []}
        onChange={(values) => onChange(CostToMoveFilterParam.REPORTS, values)}
      />
      <SearchableFilter
        name={CostToMoveFilterName.CURRENT_LADDER}
        options={filterOptions[CostToMoveFilterParam.CURRENT_LADDER].map(
          (ladder) => ({ value: ladder.id, label: ladder.name })
        )}
        selected={filter[CostToMoveFilterParam.CURRENT_LADDER] ?? []}
        onChange={(values) =>
          onChange(CostToMoveFilterParam.CURRENT_LADDER, values)
        }
      />
      <SearchableFilter
        name={CostToMoveFilterName.CURRENT_LEVEL}
        options={filterOptions[CostToMoveFilterParam.CURRENT_LEVEL].map(
          (level) => ({ value: level, label: String(level) })
        )}
        selected={filter[CostToMoveFilterParam.CURRENT_LEVEL] ?? []}
        onChange={(values) =>
          onChange(CostToMoveFilterParam.CURRENT_LEVEL, values)
        }
      />
      <SearchableFilter
        name={CostToMoveFilterName.LOCATION_GROUP}
        options={filterOptions[CostToMoveFilterParam.LOCATION_GROUP].map(
          (location) => ({ value: location.id, label: location.name })
        )}
        selected={filter[CostToMoveFilterParam.LOCATION_GROUP] ?? []}
        onChange={(values) =>
          onChange(CostToMoveFilterParam.LOCATION_GROUP, values)
        }
      />
    </SearchableFilterContainer>
  );
};

export default CostToMoveFilterBar;
