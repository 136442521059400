import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import {
  map,
  money,
  Money,
  percentageOfSalaryToCash,
  totalGrossEquityValue,
} from "@asmbl/shared/money";
import { isEmptyString } from "@asmbl/shared/utils";
import { IDataHookResponse } from "@flatfile/adapter";
import { ScalarDictionaryWithCustom as FlatFileRecord } from "@flatfile/react";
import { capitalize } from "../../utils";
import { UploadRequestsButton_valuation as Valuation } from "../../__generated__/graphql";
import { isFieldPresent, ValidationResult } from "./common";

type Employee = {
  id: number;
  employeeNumber: string | null | undefined;
  email: string;
  personalEmail: string | null | undefined;
};

type EmployeeFields = {
  id: number | null;
  employeeNumber: string | null;
  email: string | null;
};

function parseEmployeeId(employeeId: unknown): ValidationResult<number> {
  if (!isFieldPresent(employeeId)) {
    return [null, null];
  }

  // We limit the field to digits via regex, so it won't be NaN.
  return [parseInt(employeeId), null];
}

function validateActualAgainstExpected(
  actual: Employee,
  expected: EmployeeFields
): IDataHookResponse | null {
  const idMismatch: IDataHookResponse | null =
    expected.id == null || expected.id === actual.id
      ? null
      : {
          employeeId: {
            info: [
              {
                message: `Found Employee has different Assemble ID: ${actual.id}`,
                level: "error",
              },
            ],
          },
        };

  const employeeNumberMismatch: IDataHookResponse | null =
    expected.employeeNumber == null ||
    expected.employeeNumber === actual.employeeNumber
      ? null
      : {
          employeeNumber: {
            info: [
              actual.employeeNumber == null
                ? {
                    message:
                      "Found Employee has no Employee Number in Assemble",
                    level: "info",
                  }
                : {
                    message: `Found Employee has different Employee Number: ${actual.employeeNumber}`,
                    level: "error",
                  },
            ],
          },
        };

  const workEmailMismatch: IDataHookResponse | null =
    expected.email != null && expected.email === actual.email
      ? {
          email: {
            info: [
              {
                message: "Found Employee via work email",
                level: "info",
              },
            ],
          },
        }
      : null;

  const personalEmailMismatch: IDataHookResponse | null =
    expected.email != null && expected.email === actual.personalEmail
      ? {
          email: {
            info: [
              {
                message: "Found Employee via personal email",
                level: "info",
              },
            ],
          },
        }
      : null;

  const emailMismatch: IDataHookResponse | null =
    expected.email != null &&
    workEmailMismatch == null &&
    personalEmailMismatch == null
      ? {
          email: {
            info: [
              {
                message: `Found Employee has different work and personal emails. Work email: "${
                  actual.email
                }" personal email: ${
                  actual.personalEmail !== null &&
                  actual.personalEmail !== undefined
                    ? `"${actual.personalEmail}"`
                    : "does not exist"
                }.`,
                level: "warning",
              },
            ],
          },
        }
      : null;

  return idMismatch ||
    employeeNumberMismatch ||
    workEmailMismatch ||
    personalEmailMismatch ||
    emailMismatch
    ? {
        ...idMismatch,
        ...employeeNumberMismatch,
        ...workEmailMismatch,
        ...personalEmailMismatch,
        ...emailMismatch,
      }
    : null;
}

export function matchEmployee<E extends Employee>(
  record: FlatFileRecord,
  employees: E[]
): ValidationResult<E> {
  const [employeeId, employeeIdErrors] = parseEmployeeId(record.employeeId);
  const employeeNumber = isFieldPresent(record.employeeNumber)
    ? record.employeeNumber
    : null;
  const email = isFieldPresent(record.email) ? record.email : null;

  const expectedEmployee: EmployeeFields = {
    id: employeeId,
    employeeNumber,
    email,
  };

  if (employeeIdErrors) {
    return [null, employeeIdErrors];
  }

  if (employeeId != null) {
    const employeeById = employees.find((e) => e.id === employeeId);

    if (employeeById) {
      return [
        employeeById,
        validateActualAgainstExpected(employeeById, expectedEmployee),
      ];
    }

    // If you provide an Assemble ID, it must be correct. We don't fall through.
    return [
      null,
      {
        employeeId: {
          info: [
            {
              message: "We couldn't find an Employee with this ID.",
              level: "error",
            },
          ],
        },
      },
    ];
  }

  if (employeeNumber != null) {
    const employeesByNumber = employees.filter(
      (e) => e.employeeNumber === employeeNumber
    );

    if (employeesByNumber.length > 1) {
      return [
        null,
        {
          employeeNumber: {
            info: [
              {
                message:
                  "Multiple employees match this Employee Number. Please correct this in your HRIS or use a different identifier.",
                level: "error",
              },
            ],
          },
        },
      ];
    }

    if (employeesByNumber.length === 1) {
      return [
        employeesByNumber[0],
        validateActualAgainstExpected(employeesByNumber[0], expectedEmployee),
      ];
    }

    // Employee Number could be null in our system, so we keep trying.
  }

  if (email != null) {
    const employeeByWorkEmail = employees.find((e) => e.email === email);

    if (employeeByWorkEmail) {
      return [
        employeeByWorkEmail,
        validateActualAgainstExpected(employeeByWorkEmail, expectedEmployee),
      ];
    }

    const employeesByPersonalEmail = employees.filter(
      (e) => e.personalEmail === email
    );

    if (employeesByPersonalEmail.length === 1) {
      const [employeeByPersonalEmail] = employeesByPersonalEmail;

      return [
        employeeByPersonalEmail,
        validateActualAgainstExpected(
          employeeByPersonalEmail,
          expectedEmployee
        ),
      ];
    } else if (employeesByPersonalEmail.length > 1) {
      return [
        null,
        {
          email: {
            info: [
              {
                message: "Multiple employees match this personal email.",
                level: "error",
              },
            ],
          },
        },
      ];
    }

    // Employees could have different emails across systems, so we keep trying.
  }

  return [
    null,
    {
      employeeId: {
        info: [{ message: "No matching Employee found", level: "error" }],
      },
      employeeNumber: {
        info: [{ message: "No matching Employee found", level: "error" }],
      },
      email: {
        info: [{ message: "No matching Employee found", level: "error" }],
      },
    },
  ];
}

export function validateDate(
  record: FlatFileRecord,
  key: string
): IDataHookResponse {
  const input = record[key];

  if (input == null || isEmptyString(input as string)) return {};

  const date = new Date(input as string);

  if (isNaN(date.getTime())) {
    return {
      [key]: {
        info: [{ message: "This is not a valid date.", level: "error" }],
      },
    };
  }
  return {};
}

export function validatePercentageWithAmount(
  employee: Employee | null,
  record: FlatFileRecord,
  data: {
    key:
      | "salaryPromotion"
      | "salaryMarket"
      | "salaryMerit"
      | "targetCommission"
      | "targetRecurringBonus";
    copy: string;
  },
  employeeSalary: Money | null
): IDataHookResponse {
  const { key, copy } = data;
  const percentFieldKey = `${key}Percent`;

  if (employee === null || employeeSalary === null) {
    return {
      [percentFieldKey]: {
        info: [
          {
            message: `Salary is not known for this employee.`,
            level: "warning",
          },
        ],
      },
    };
  }

  const value = record[key];
  const percent = record[percentFieldKey];

  if (isFieldPresent(value) && isFieldPresent(percent)) {
    const actualPercentageOfSalaryToCash = percentageOfSalaryToCash(
      employeeSalary,
      parseFloat(percent)
    );

    if (actualPercentageOfSalaryToCash.value !== parseInt(value)) {
      return {
        [percentFieldKey]: {
          info: [
            {
              message: `${capitalize(
                copy
              )} value does not match the ${copy} percentage.`,
              level: "warning",
            },
            {
              message: `${capitalize(
                copy
              )} value and percentage both specified. This value will be ignored.`,
              level: "warning",
            },
          ],
        },
      };
    }
  }

  return {};
}

export function convertPercentageSalaryToAmount(
  employee: Employee | null,
  record: FlatFileRecord,
  data: {
    key:
      | "salaryPromotion"
      | "salaryMarket"
      | "salaryMerit"
      | "targetCommission"
      | "targetRecurringBonus";
    copy: string;
  },
  employeeSalary: Money | null
): IDataHookResponse {
  const { key, copy } = data;
  const percentFieldKey = `${key}Percent`;

  if (employee === null || employeeSalary === null) {
    return {
      [percentFieldKey]: {
        info: [
          {
            message: `Salary is not known for this employee.`,
            level: "warning",
          },
        ],
      },
    };
  }

  const value = record[key];
  const percent = record[percentFieldKey];

  if (isFieldPresent(value) && isFieldPresent(percent)) {
    const actualPercentageOfSalaryToCash = percentageOfSalaryToCash(
      employeeSalary,
      parseFloat(percent)
    );

    if (actualPercentageOfSalaryToCash.value !== parseInt(value)) {
      return {
        [percentFieldKey]: {
          info: [
            {
              message: `${capitalize(
                copy
              )} value does not match the ${copy} percentage.`,
              level: "warning",
            },
            {
              message: `${capitalize(
                copy
              )} value and percentage both specified. This value will be ignored.`,
              level: "warning",
            },
          ],
        },
      };
    } else {
      return {
        [percentFieldKey]: {
          info: [
            {
              message: `${capitalize(
                copy
              )} value and percentage both specified. This value will be ignored.`,
              level: "warning",
            },
          ],
        },
      };
    }
  }

  if (isFieldPresent(percent) && !isFieldPresent(value)) {
    const actualPercentageOfSalaryToCash = percentageOfSalaryToCash(
      employeeSalary,
      parseFloat(percent)
    );

    return {
      [key]: {
        value: actualPercentageOfSalaryToCash.value,
        info: [
          {
            message: `Calculated from the current salary.`,
            level: "info",
          },
        ],
      },
    };
  }

  return {};
}

// We can only submit equity in absolute amounts; convert units as a convenience
export function convertUnitsToAmount(
  record: FlatFileRecord,
  currentValuation: Valuation,
  currencies: Map<CurrencyCode, Currency>,
  targetCurrencyCode: CurrencyCode
): IDataHookResponse | undefined {
  const valuationCurrency = currencies.get(currentValuation.valuation.currency);
  if (!valuationCurrency) {
    throw new Error(
      `Exchange rate not found for valuation currency: ${currentValuation.valuation.currency}`
    );
  }
  const targetCurrency = currencies.get(targetCurrencyCode);
  if (!targetCurrency) {
    throw new Error(
      `Exchange rate not found for target currency: ${targetCurrencyCode}`
    );
  }

  if (!isFieldPresent(record.equityUnits)) {
    return;
  }

  const units = parseInt(record.equityUnits);

  const unitValue = map(
    Math.round,
    exchangeFromTo(
      totalGrossEquityValue(
        currentValuation.fdso,
        currentValuation.valuation,
        units
      ),
      targetCurrency,
      valuationCurrency
    )
  );

  if (isFieldPresent(record.equity)) {
    const equityValue = money(parseInt(record.equity), targetCurrency.code);

    if (unitValue.value !== equityValue.value) {
      return {
        equityUnits: {
          info: [
            {
              message: `Equity Units and Amount both specified. This value will be ignored.`,
              level: "warning",
            },
          ],
        },
      };
    }
  } else {
    // Equity Amount was not specified, so populate it with unit equivalent
    return {
      equity: {
        value: unitValue.value,
        info: [{ message: "Calculated from current valuation", level: "info" }],
      },
    };
  }
}
