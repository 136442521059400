import { formatCurrency, multiply } from "@asmbl/shared/money";
import { Button, ListItem, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  CompCycleGrouping,
  CompCycleSubSalaryGrouping,
} from "../../../../components/CompCycle/types";
import { UserAvatar } from "../../../../components/UserAvatar";
import { DV_RED, GRAY_1, GRAY_4, GRAY_7 } from "../../../../theme";
import {
  CompComponent,
  CompSubComponent,
  OveragesSelector_budgetOverage as BudgetOverage,
} from "../../../../__generated__/graphql";

type Props = {
  compCycleId: number;
  overage: BudgetOverage;
  onClick: () => unknown;
};

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderRadius: "2px",
    "&:hover, &:focus": {
      backgroundColor: GRAY_7,
    },
    height: 56,
  },
  listButton: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
  },
  employee: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginRight: theme.spacing(1),
    gap: theme.spacing(1),
  },
  name: {
    display: "flex",
    flexDirection: "column",
  },
  nameText: {
    fontSize: "0.8125rem",
    color: GRAY_1,
  },
  topLevelHelper: {
    fontSize: "0.8125rem",
    color: GRAY_4,
  },
  overageContainer: {
    display: "flex",
    flexDirection: "column",
  },
  overageAmount: {
    color: DV_RED,
    fontSize: "0.75rem",
    background: "rgba(255, 93, 71, 0.1)",
    border: `1px solid ${DV_RED}`,
    borderRadius: "2px",
    fontWeight: 500,
    padding: "0 0.25rem 0 0.25rem",
  },
  overageVariant: {
    textTransform: "capitalize",
    color: GRAY_1,
    fontSize: "0.8125rem",
    textAlign: "right",
  },
}));

export function OverageListItem({
  compCycleId,
  overage,
  onClick,
}: Props): JSX.Element {
  const classes = useStyles();
  const params = new URLSearchParams();

  params.set("subPage", getSubPage(overage.variant));
  if (overage.employee !== null) {
    params.set("manager", overage.employee.id.toString());
  }
  const href = `/comp-cycles/${compCycleId}/budget?${params.toString()}`;

  return (
    <ListItem className={classes.listItem}>
      <Button
        component={Link}
        to={href}
        className={classes.listButton}
        onClick={onClick}
      >
        <div className={classes.employee}>
          <UserAvatar
            photoURL={overage.employee?.user?.photoURL}
            displayName={overage.employee?.displayName ?? null}
            size="small"
          />
          <div className={classes.name}>
            <Typography className={classes.nameText}>
              {overage.employee?.displayName ?? "Comp Cycle Budget"}
            </Typography>
            {overage.employee?.activeEmployment?.jobTitle != null && (
              <Typography className={classes.nameText}>
                {overage.employee.activeEmployment.jobTitle}
              </Typography>
            )}
          </div>
        </div>
        <div className={classes.overageContainer}>
          <Typography className={classes.overageVariant}>
            {getCompComponentTitle(overage.variant)}
          </Typography>
          <div className={classes.overageAmount}>
            {formatCurrency(multiply(-1, overage.overage), {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              notation: "compact",
            })}
          </div>
        </div>
      </Button>
    </ListItem>
  );
}

function getSubPage(
  component: CompComponent | CompSubComponent
): CompCycleGrouping {
  const map: Record<CompComponent | CompSubComponent, CompCycleGrouping> = {
    [CompComponent.SALARY]: "salary",
    [CompSubComponent.SALARY_MARKET]: "salary",
    [CompSubComponent.SALARY_MERIT]: "salary",
    [CompSubComponent.SALARY_PROMOTION]: "salary",
    [CompComponent.EQUITY]: "equity",
    [CompComponent.TARGET_COMMISSION]: "target",
    [CompComponent.TARGET_RECURRING_BONUS]: "target",
    [CompComponent.ACTUAL_RECURRING_BONUS]: "actual",
    [CompComponent.BONUS]: "actual",
  };

  return map[component];
}

function getCompComponentTitle(
  component: CompComponent | CompSubComponent
): CompCycleGrouping | CompCycleSubSalaryGrouping {
  const map: Record<
    CompComponent | CompSubComponent,
    CompCycleGrouping | CompCycleSubSalaryGrouping
  > = {
    [CompComponent.SALARY]: "salary",
    [CompSubComponent.SALARY_MARKET]: "market",
    [CompSubComponent.SALARY_MERIT]: "merit",
    [CompSubComponent.SALARY_PROMOTION]: "promotion",
    [CompComponent.EQUITY]: "equity",
    [CompComponent.TARGET_COMMISSION]: "target",
    [CompComponent.TARGET_RECURRING_BONUS]: "target",
    [CompComponent.ACTUAL_RECURRING_BONUS]: "actual",
    [CompComponent.BONUS]: "actual",
  };

  return map[component];
}
