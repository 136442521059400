import { CompCycleGrouping } from "../components/CompCycle/types";
import { BudgetType } from "./Budget";

type CompCycleSettings = {
  allowSalary: boolean;
  allowEquity: boolean;
  allowBonus: boolean;
  allowTargetCommission: boolean;
  allowTargetRecurringBonus: boolean;
  allowActualRecurringBonus: boolean;
};

export function defaultComponent(
  cycleData: CompCycleSettings
): CompCycleGrouping {
  const {
    allowSalary,
    allowEquity,
    allowBonus,
    allowTargetCommission,
    allowTargetRecurringBonus,
    allowActualRecurringBonus,
  } = cycleData;

  if (allowSalary) return "salary";
  if (allowTargetRecurringBonus || allowTargetCommission) return "target";
  if (allowBonus || allowActualRecurringBonus) return "actual";
  if (allowEquity) return "equity";

  return "salary";
}

/**
 * In Comp Cycles, we have many <ComplexToggle/> components. This standardizes
 * the options that we pass into each one.
 *
 * Previously this function was repeated multiple times. This is a consolidation
 */
type Option = [CompCycleGrouping, string];
export function getToggleOptions(
  settings: CompCycleSettings,
  {
    includeTheAllOption = false,
    allLabel = "All",
  }: { includeTheAllOption?: boolean; allLabel?: string } = {}
): Option[] {
  const allowActual = settings.allowBonus || settings.allowActualRecurringBonus;
  const allowTarget =
    settings.allowTargetCommission || settings.allowTargetRecurringBonus;

  // Add more options here
  const toggles: [Option, boolean][] = [
    [["all", allLabel], includeTheAllOption],
    [["salary", BudgetType.SALARY], settings.allowSalary],
    [["target", BudgetType.TARGET_VAR_PAY], allowTarget],
    [["actual", BudgetType.ACTUAL_VAR_PAY], allowActual],
    [["equity", BudgetType.EQUITY], settings.allowEquity],
  ];

  return toggles.filter(([, predicate]) => predicate).map(([option]) => option);
}
