import { MONTHS_IN_A_YEAR } from "@asmbl/shared/constants";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { max, min, Money, moneyComparator, zero } from "@asmbl/shared/money";
import {
  CurrencyCode,
  PayPeriodType,
  PositionType,
} from "../__generated__/graphql";
import { getSalaryCashComp } from "./CashCompensation";
import { AdjustedCashBand, CashCompensation } from "./Employee";

export function getExchangedSalary(
  targetCurrency: Currency,
  employment: { salary: Money | null },
  currencies: Map<CurrencyCode, Currency>
): Money | undefined {
  const employeePay = employment.salary;
  if (employeePay === null) {
    return undefined;
  }

  const currency = currencies.get(employeePay.currency);
  if (currency === undefined) {
    return undefined;
  }

  return exchangeFromTo(employeePay, currency, targetCurrency);
}

export function getSalaryBandPlacement(
  cash: CashCompensation[] | null,
  bands: AdjustedCashBand[] | null,
  currencies: Map<CurrencyCode, Currency>,
  defaultCurrency: Currency,
  newSalary?: Money
): number | undefined {
  if (cash === null || bands === null) return undefined;

  const salary = getSalaryCashComp(cash);
  const salaryBand = bands.find((b) => b.name === "Salary");

  if (salary === null || salary === undefined || salaryBand === undefined)
    return undefined;

  let employeeSalary = newSalary ? newSalary : salary.annualCashEquivalent;
  const bandPoints = salaryBand.bandPoints
    .map((bp) => bp.value.annualRate ?? zero(defaultCurrency.code))
    .slice()
    .sort(moneyComparator);

  let maxValue = max(...bandPoints)?.value;
  let minValue = min(...bandPoints)?.value;

  if (maxValue === undefined || minValue === undefined) return undefined;

  // if the band currency and the salary currency differ,
  // exchange the salary value to the band currency
  const bandPointValue = salaryBand.bandPoints[0].value;
  if (employeeSalary.currency !== bandPointValue.currencyCode) {
    const employeeCurrency =
      currencies.get(employeeSalary.currency) ?? defaultCurrency;
    const bandCurrency =
      currencies.get(bandPointValue.currencyCode) ?? defaultCurrency;

    employeeSalary = exchangeFromTo(
      employeeSalary,
      employeeCurrency,
      bandCurrency
    );
  }

  // Calculate the offset from the band
  if (minValue === maxValue) {
    // single point bands expand to a 1-currency width, lest we divide-by-zero
    minValue -= 0.5;
    maxValue += 0.5;
  }

  const raw = (employeeSalary.value - minValue) / (maxValue - minValue);
  return Math.round(raw * 100000) / 100000;
}

export function formatTenure(months: number): string {
  const monthPart = months % 12 === 1 ? "1 mo" : `${months % 12} mos`;
  const years = Math.floor(months / MONTHS_IN_A_YEAR);
  const yearPart = years === 1 ? "1 yr" : `${years} yrs`;

  if (years === 0) {
    return monthPart;
  }

  if (months % 12 === 0) {
    return yearPart;
  }

  return `${yearPart} ${monthPart}`;
}

export function isHourlyEmployment(
  pos:
    | {
        position?: { type: PositionType } | null | undefined;
        payPeriod?: PayPeriodType;
      }
    | null
    | undefined
): boolean {
  if (pos?.payPeriod) {
    return pos.payPeriod === PayPeriodType.HOURLY;
  } else {
    return isHourlyPosition(pos?.position);
  }
}

export function isHourlyPosition(
  position: { type: PositionType } | null | undefined
): boolean {
  return position?.type === PositionType.HOURLY;
}
