import { gql, useQuery } from "@apollo/client";
import { LinearProgress } from "@material-ui/core";
import { UserInviteModalLoadingBoundaryQuery } from "../../../../__generated__/graphql";
import { UserInviteModal } from "./UserInviteModal";

export type Props = {
  isOpen: boolean;
  toggleUserInviteModal: () => void;
  setLatestUserInvitationId: (id: number) => void;
};

export function UserInviteModalLoadingBoundary({
  isOpen,
  toggleUserInviteModal,
  setLatestUserInvitationId,
}: Props): JSX.Element {
  const { data, loading } = useQuery<UserInviteModalLoadingBoundaryQuery>(
    UserInviteModalLoadingBoundary.query
  );

  if (!data) {
    return loading ? <LinearProgress /> : <div>Error</div>;
  }

  return (
    <UserInviteModal
      organization={data.organization}
      departments={data.departments}
      isOpen={isOpen}
      toggleUserInviteModal={toggleUserInviteModal}
      setLatestUserInvitationId={setLatestUserInvitationId}
    />
  );
}

UserInviteModalLoadingBoundary.query = gql`
  ${UserInviteModal.fragments.organization}
  ${UserInviteModal.fragments.departments}
  query UserInviteModalLoadingBoundaryQuery {
    organization {
      ...UserInviteModal_organization
      id
    }
    departments {
      ...UserInviteModal_departments
      id
    }
  }
`;
