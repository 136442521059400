import {
  Divider,
  makeStyles,
  MenuItem,
  Select,
  SvgIconProps,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { GRAY_5, PURPLE_1, WHITE } from "../../theme";
import { ChevronDownIcon } from "../AssembleIcons/Brand/ChevronDownIcon";
import { AssembleTypography } from "../AssembleTypography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "20px",
    color: PURPLE_1,
    backgroundColor: WHITE,
    border: `1px solid ${GRAY_5}`,
    borderRadius: "2px",
    transition: "all 125ms",
    width: "fit-content",
    boxShadow: "none",

    "&:hover": {
      borderColor: PURPLE_1,
      color: PURPLE_1,
      textDecoration: "none",
      transition: "all 125ms",

      "& .MuiSelect-icon": {
        color: PURPLE_1,
      },

      "& .MuiDivider-root": {
        backgroundColor: PURPLE_1,
      },
    },

    "& .MuiOutlinedInput-input": {
      padding: "1px 19px 1px 4px",
    },

    "& .MuiDivider-root": {
      marginTop: "-2px",
    },

    "& .MuiSelect-icon": {
      top: "calc(50% - 8px)",
      right: "-2px",
      color: PURPLE_1,
    },
  },

  value: {
    paddingTop: "2px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    columnGap: theme.spacing(0.5),
  },
}));

type Props<T extends { id: number; name: string; to: string }> = {
  options: T[];
  selectedOption: T;
};

export function AssembleDropdownBreadCrumb<
  T extends { id: number; name: string; to: string },
>({ options, selectedOption }: Props<T>): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();

  const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedDestination = event.target.value as string;
    navigate(selectedDestination);
  };

  const caretIcon = ({ className }: SvgIconProps) => (
    <ChevronDownIcon className={className} inherit />
  );

  const renderValue = () => (
    <div className={classes.value}>
      <AssembleTypography display="inline" variant="productEyebrow">
        {selectedOption.name}
      </AssembleTypography>
      <Divider orientation="vertical" flexItem />
    </div>
  );

  return (
    <Select
      className={classes.root}
      variant="outlined"
      value={selectedOption}
      onChange={onChange}
      IconComponent={caretIcon}
      renderValue={renderValue}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.id} value={option.to}>
          {option.id === selectedOption.id ? (
            <strong>{option.name}</strong>
          ) : (
            <span>{option.name}</span>
          )}
        </MenuItem>
      ))}
    </Select>
  );
}
