import { gql } from "@apollo/client";
import { Divider, makeStyles } from "@material-ui/core";
import {
  PhaseRowDetails_employee as Employee,
  PhaseRowDetails_user as User,
} from "src/__generated__/graphql";
import { AssembleTruncatedTypography } from "src/components/AssembleTruncatedTypography";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_3 } from "src/theme";
import { CompCyclePhaseData } from "../usePhaseConfiguration";
import { getParticipantCount } from "./utils";

const useStyles = makeStyles((theme) => ({
  phaseCalibrationContainer: {
    display: "flex",
    paddingTop: theme.spacing(2),
  },
  phaseDetailsContainer: {
    minHeight: "3.375rem",
    maxWidth: theme.spacing(85),

    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),

    padding: theme.spacing(2),
  },
  phaseDetailColumn: {
    display: "flex",
    flexDirection: "column",
  },
  phaseEmployeeElement: {
    display: "flex",
    flexDirection: "row",
  },
}));

const MAX_ELEMENTS_PER_COLUMN = 4;
const MAX_COLUMNS = 2;
const MAX_ELEMENTS = MAX_ELEMENTS_PER_COLUMN * MAX_COLUMNS;

type Props = {
  phaseConfig: CompCyclePhaseData;
  employees: Employee[];
  users: User[];
};

export function PhaseRowDetails({
  phaseConfig,
  employees,
  users,
}: Props): JSX.Element | null {
  const classes = useStyles();

  if (getParticipantCount(phaseConfig) === 0) {
    return (
      <div>
        <Divider />
        <div className={classes.phaseCalibrationContainer}>
          <AssembleTypography
            textColor={GRAY_3}
            variant="productParagraphMedium"
          >
            Calibration Phase: Any Full Access user can access this phase and
            review recommendations.
          </AssembleTypography>
        </div>
        <div className={classes.phaseDetailsContainer}>
          <div className={classes.phaseDetailColumn}>
            {users.slice(0, MAX_ELEMENTS_PER_COLUMN).map(({ id, name }) => {
              return (
                <div key={id} className={classes.phaseEmployeeElement}>
                  <AssembleTypography variant="productParagraphMedium">
                    {name}
                  </AssembleTypography>
                </div>
              );
            })}
          </div>
          <div className={classes.phaseDetailColumn}>
            {users
              .slice(
                MAX_ELEMENTS_PER_COLUMN,
                getParticipantCount(phaseConfig) > MAX_ELEMENTS
                  ? // -1 to account for the space taken by the
                    // "more" element
                    MAX_ELEMENTS_PER_COLUMN * MAX_COLUMNS - 1
                  : getParticipantCount(phaseConfig)
              )
              .map(({ id, name }) => {
                return (
                  <div key={id} className={classes.phaseEmployeeElement}>
                    <AssembleTypography variant="productParagraphMedium" noWrap>
                      {name}
                    </AssembleTypography>
                  </div>
                );
              })}
            {users.length > MAX_ELEMENTS && (
              <AssembleTypography variant="productParagraphMedium">
                +
                {/**
                 * +1 to account for the row space we
                 * are using up by showing the "more" element
                 **/}
                {users.length - MAX_ELEMENTS + 1}
                &nbsp; more
              </AssembleTypography>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Divider />
      <div className={classes.phaseDetailsContainer}>
        <div className={classes.phaseDetailColumn}>
          {phaseConfig.assigneeIds
            .slice(0, MAX_ELEMENTS_PER_COLUMN)
            .map((assigneeId) => {
              const employee = employees.find(
                (employee) => employee.id === assigneeId
              );

              if (!employee) return null;

              return (
                <div key={employee.id} className={classes.phaseEmployeeElement}>
                  <AssembleTypography variant="productParagraphMedium">
                    {employee.displayName}&nbsp;-&nbsp;
                  </AssembleTypography>
                  <AssembleTruncatedTypography
                    textColor={GRAY_3}
                    variant="productParagraphMedium"
                  >
                    {employee.activeEmployment?.jobTitle}
                  </AssembleTruncatedTypography>
                </div>
              );
            })}
        </div>
        <div className={classes.phaseDetailColumn}>
          {phaseConfig.assigneeIds
            .slice(
              MAX_ELEMENTS_PER_COLUMN,
              getParticipantCount(phaseConfig) > MAX_ELEMENTS
                ? // -1 to account for the space taken by the
                  // "more" element
                  MAX_ELEMENTS_PER_COLUMN * MAX_COLUMNS - 1
                : getParticipantCount(phaseConfig)
            )
            .map((assigneeId) => {
              const employee = employees.find(
                (employee) => employee.id === assigneeId
              );

              if (!employee) return null;

              return (
                <div key={employee.id} className={classes.phaseEmployeeElement}>
                  <AssembleTypography variant="productParagraphMedium" noWrap>
                    {employee.displayName}&nbsp;-&nbsp;
                  </AssembleTypography>
                  <AssembleTruncatedTypography
                    textColor={GRAY_3}
                    variant="productParagraphMedium"
                  >
                    {employee.activeEmployment?.jobTitle}
                  </AssembleTruncatedTypography>
                </div>
              );
            })}
          {getParticipantCount(phaseConfig) > MAX_ELEMENTS && (
            <AssembleTypography variant="productParagraphMedium">
              +
              {/**
               * +1 to account for the row space we
               * are using up by showing the "more" element
               **/}
              {getParticipantCount(phaseConfig) - MAX_ELEMENTS + 1}
              &nbsp; more
            </AssembleTypography>
          )}
        </div>
      </div>
    </div>
  );
}

PhaseRowDetails.fragments = {
  employee: gql`
    fragment PhaseRowDetails_employee on Employee2 {
      id
      displayName
      activeEmployment {
        id
        jobTitle
      }
    }
  `,
  user: gql`
    fragment PhaseRowDetails_user on User {
      id
      name
    }
  `,
};
