import { useQuery } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { createContext, ReactElement, useContext, useMemo } from "react";
import {
  CompStructureFields,
  GetCompStructure,
  GetCompStructureVariables,
  Noun,
} from "../__generated__/graphql";
import { COMP_STRUCTURE_QUERY } from "../queries";
import { AccessBoundary } from "./AccessBoundary";
import { useFeatureFlags } from "./FeatureContext";

export type CompStructureContextType = {
  compStructure:
    | (CompStructureFields & { workingHoursPerYear: number | undefined })
    | null;
  loading: boolean;
};

const DEFAULT_CONTEXT_VALUE: CompStructureContextType = {
  compStructure: null,
  loading: false,
};

export const compStructureContext = createContext<CompStructureContextType>(
  DEFAULT_CONTEXT_VALUE
);

function LiveCompStructureProvider({
  children,
}: {
  children: ReactElement;
}): JSX.Element {
  const { data, loading } = useQuery<
    GetCompStructure,
    GetCompStructureVariables
  >(COMP_STRUCTURE_QUERY);
  const { isEnabled } = useFeatureFlags();
  const allowsHourly = isEnabled(FeatureFlag.HourlyEmployees);
  const value = useMemo<CompStructureContextType>(
    () => ({
      compStructure: data?.compStructure
        ? {
            ...data.compStructure,
            workingHoursPerYear:
              data.compStructure.employmentHoursPerWeek &&
              data.compStructure.employmentWeeksPerYear &&
              allowsHourly
                ? data.compStructure.employmentHoursPerWeek *
                  data.compStructure.employmentWeeksPerYear
                : undefined,
          }
        : null,
      loading,
    }),
    [data, loading, allowsHourly]
  );

  return (
    <compStructureContext.Provider value={value}>
      {children}
    </compStructureContext.Provider>
  );
}

export function CompStructureProvider({
  children,
}: {
  children: ReactElement;
}): JSX.Element {
  return (
    <AccessBoundary
      scope="global"
      verb="view"
      noun={Noun.CompStructure}
      fallback={
        <compStructureContext.Provider value={DEFAULT_CONTEXT_VALUE}>
          {children}
        </compStructureContext.Provider>
      }
    >
      <LiveCompStructureProvider>{children}</LiveCompStructureProvider>
    </AccessBoundary>
  );
}

export function useCompStructure(): CompStructureContextType {
  return useContext(compStructureContext);
}
