import { Box, makeStyles, Typography } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { GRAY_4, WHITE } from "../../theme";

type Props = {
  currentCount: number;
  previousCount?: number;
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: WHITE,
    paddingBottom: "40px",
  },
  count: {
    fontSize: "80px",
    lineHeight: 1,
    marginBottom: theme.spacing(1),
  },
  totalPeopleContainer: {
    display: "flex",
    flexDirection: "row",
  },
  multipleCount: {
    fontSize: "60px",
    lineHeight: 1,
    marginBottom: theme.spacing(1),
  },
  arrowIcon: {
    color: GRAY_4,
    fontSize: "20px",
    alignSelf: "center",
    margin: theme.spacing(1),
    fontWeight: "bold",
  },
  subtext: {
    fontSize: "12px",
    fontStyle: "italic",
    color: GRAY_4,
  },
}));

export function BigCount({ currentCount, previousCount }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {previousCount === undefined ? (
        <Typography className={classes.count}>{currentCount}</Typography>
      ) : (
        <Box className={classes.totalPeopleContainer}>
          <Typography className={classes.multipleCount}>
            {previousCount}
          </Typography>
          <ArrowForward className={classes.arrowIcon} />
          <Typography className={classes.multipleCount}>
            {currentCount}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
