import { FetchResult, gql, Reference, useMutation } from "@apollo/client";
import { Rule } from "@asmbl/shared/eligibility";
import { useCallback } from "react";
import {
  CompComponentsInput,
  CompCycleInput,
  CompCyclePerfScoreInput,
  CompCycleSingleEmployeePerfScoreInput,
  CompleteCompCycle,
  CompleteCompCycleVariables,
  CreateCompCycle,
  CreateCompCycleVariables,
  CreateCorrectionCompCycle,
  CreateCorrectionCompCycleVariables,
  DeleteCompCycle,
  DeleteCompCycleVariables,
  ImportEmployeePerfScores,
  ImportEmployeePerfScoresVariables,
  OrgBudgetCostToMoveInput,
  UpdateCompCycleComponentSettings,
  UpdateCompCycleComponentSettingsVariables,
  UpdateCompCycleEndDate,
  UpdateCompCycleEndDateVariables,
  updateCompCycleName,
  updateCompCycleNameVariables,
  UpdateEligibilityRules,
  UpdateEligibilityRulesVariables,
} from "../__generated__/graphql";

const CREATE_COMP_CYCLE = gql`
  mutation CreateCompCycle($data: CompCycleInput!) {
    createOneCompCycle(data: $data) {
      id
      name
      endedAt
    }
  }
`;

const CREATE_CORRECTION_COMP_CYCLE = gql`
  mutation CreateCorrectionCompCycle($data: OrgBudgetCostToMoveInput!) {
    createBandPlacementCorrectionCompCycle(data: $data) {
      id
      name
      endedAt
    }
  }
`;

export function useCreateCompCycle(): (
  data: CompCycleInput
) => Promise<FetchResult<CreateCompCycle>> {
  const [createCompCycle] = useMutation<
    CreateCompCycle,
    CreateCompCycleVariables
  >(CREATE_COMP_CYCLE);

  /*
    We currently upload performance ratings immediately
    after this mutation succeeds. That requires a real
    compCycleId, so be wary of optimistic responses.
   */

  return useCallback(
    (data) =>
      createCompCycle({
        variables: {
          data,
        },
      }),
    [createCompCycle]
  );
}
export function useCreateCorrectionCompCycle(): (
  data: OrgBudgetCostToMoveInput
) => Promise<FetchResult<CreateCorrectionCompCycle>> {
  const [createCorrectionCompCycle] = useMutation<
    CreateCorrectionCompCycle,
    CreateCorrectionCompCycleVariables
  >(CREATE_CORRECTION_COMP_CYCLE);

  return useCallback(
    (data) =>
      createCorrectionCompCycle({
        variables: {
          data,
        },
      }),
    [createCorrectionCompCycle]
  );
}

const IMPORT_EMPLOYEE_PERF_SCORE = gql`
  mutation ImportEmployeePerfScores($data: CompCyclePerfScoreInput!) {
    importEmployeePerfScores(data: $data) {
      count
      participants {
        compCycleId
        subjectId
        perfRating
      }
    }
  }
`;

export function useImportEmployeePerfScores(): (
  data: CompCyclePerfScoreInput
) => Promise<ImportEmployeePerfScores | null> {
  const [importEmployeePerfScores] = useMutation<
    ImportEmployeePerfScores,
    ImportEmployeePerfScoresVariables
  >(IMPORT_EMPLOYEE_PERF_SCORE);

  return useCallback(
    async (data) => {
      const response = await importEmployeePerfScores({
        variables: {
          data,
        },
      });
      return response.data ?? null;
    },
    [importEmployeePerfScores]
  );
}

const UPDATE_EMPLOYEE_PERF_SCORE = gql`
  mutation UpdateSingleEmployeePerfScore(
    $data: CompCycleSingleEmployeePerfScoreInput!
  ) {
    updateEmployeePerfScore(data: $data) {
      compCycleId
      subjectId
      perfRating
    }
  }
`;

export function useUpdateSingleEmployeePerfScore(): (
  data: CompCycleSingleEmployeePerfScoreInput
) => Promise<unknown> {
  const [updateSingleEmployeePerfScore] = useMutation(
    UPDATE_EMPLOYEE_PERF_SCORE
  );

  return useCallback(
    (data) =>
      updateSingleEmployeePerfScore({
        variables: {
          data,
        },
      }),
    [updateSingleEmployeePerfScore]
  );
}

const UPDATE_COMP_CYCLE_NAME = gql`
  mutation updateCompCycleName($data: UpdateCompCycleNameInput!) {
    updateCompCycleName(data: $data) {
      id
      name
    }
  }
`;

const UPDATE_COMP_CYCLE_END_DATE = gql`
  mutation UpdateCompCycleEndDate($data: UpdateCompCycleEndDateInput!) {
    updateCompCycleEndDate(data: $data) {
      id
      endDate
    }
  }
`;

const UPDATE_COMP_CYCLE_GUIDANCE_PREFERENCES = gql`
  mutation updateCompCycleGuidancePreferences(
    $data: UpdateCompCycleGuidancePreferencesInput!
  ) {
    updateCompCycleGuidancePreferences(data: $data) {
      id
      recommendationsPreFill
    }
  }
`;

const UPDATE_COMP_CYCLE_COMPONENTS = gql`
  mutation UpdateCompCycleComponentSettings($data: UpdateCompComponentsInput!) {
    updateCompCycleCompComponentSettings(data: $data) {
      id
      allowSalary
      allowSalaryMarket
      allowSalaryMerit
      allowSalaryPromotion
      allowEquity
      allowBonus
      allowTargetCommission
      allowTargetRecurringBonus
      allowActualRecurringBonus
    }
  }
`;

export function useUpdateCompCycleEndDate(
  compCycleId: number
): (date: Date | null) => Promise<unknown> {
  const [updateCompCycleEndDate] = useMutation<
    UpdateCompCycleEndDate,
    UpdateCompCycleEndDateVariables
  >(UPDATE_COMP_CYCLE_END_DATE);

  return useCallback(
    (endDate) =>
      updateCompCycleEndDate({
        variables: { data: { id: compCycleId, endDate } },
        optimisticResponse: {
          updateCompCycleEndDate: {
            __typename: "CompCycle",
            id: compCycleId,
            endDate,
          },
        },
      }),
    [compCycleId, updateCompCycleEndDate]
  );
}

export function useUpdateCompCycleName(
  compCycleId: number
): (name: string) => Promise<unknown> {
  const [updateCompCycleName] = useMutation<
    updateCompCycleName,
    updateCompCycleNameVariables
  >(UPDATE_COMP_CYCLE_NAME);

  return useCallback(
    (name: string) =>
      updateCompCycleName({
        variables: { data: { id: compCycleId, name } },
        optimisticResponse: {
          updateCompCycleName: {
            __typename: "CompCycle",
            id: compCycleId,
            name,
          },
        },
      }),
    [compCycleId, updateCompCycleName]
  );
}

export function useUpdateCompCycleGuidancePreferences(
  compCycleId: number
): (recommendationsPreFill: boolean) => Promise<unknown> {
  const [updateCompCycleGuidancePreferences] = useMutation(
    UPDATE_COMP_CYCLE_GUIDANCE_PREFERENCES
  );

  return useCallback(
    (recommendationsPreFill: boolean) =>
      updateCompCycleGuidancePreferences({
        variables: {
          data: {
            id: compCycleId,
            recommendationsPreFill,
          },
        },
        optimisticResponse: {
          updateCompCycleGuidancePreferences: {
            __typename: "CompCycle",
            id: compCycleId,
            recommendationsPreFill,
          },
        },
      }),
    [compCycleId, updateCompCycleGuidancePreferences]
  );
}

export function useUpdateCompCycleComponents(
  compCycleId: number
): (
  compComponents: CompComponentsInput
) => Promise<FetchResult<UpdateCompCycleComponentSettings>> {
  const [updateCompCycleCompComponentSettings] = useMutation<
    UpdateCompCycleComponentSettings,
    UpdateCompCycleComponentSettingsVariables
  >(UPDATE_COMP_CYCLE_COMPONENTS);

  return useCallback(
    (compComponents: CompComponentsInput) =>
      updateCompCycleCompComponentSettings({
        variables: {
          data: {
            id: compCycleId,
            compComponents,
          },
        },
      }),
    [compCycleId, updateCompCycleCompComponentSettings]
  );
}

/*
  Completing the Comp Cycle also marks any outstanding CompRecommendations as
  Approved. We need to resolve those so that Apollo can update them in cache.
 */
const COMPLETE_COMP_CYCLE = gql`
  mutation CompleteCompCycle($id: Int!) {
    completeOneCompCycle(id: $id) {
      compCycle {
        id
        endedAt
      }
      autoApprovedCompRecommendations {
        subjectId
        compCycleId
        updatedAt
        allSubmittedReviews {
          id
          submittedAt
          status
          note
          author {
            id
          }
        }
        reviewStatus
      }
    }
  }
`;

export function useCompleteCompCycle(
  compCycleId: number
): () => Promise<boolean> {
  const [completeCompCycle] = useMutation<
    CompleteCompCycle,
    CompleteCompCycleVariables
  >(COMPLETE_COMP_CYCLE);

  return useCallback(
    () =>
      completeCompCycle({
        variables: {
          id: compCycleId,
        },
      }).then(
        () => true,
        () => false
      ),
    [compCycleId, completeCompCycle]
  );
}

const DELETE_COMP_CYCLE = gql`
  mutation DeleteCompCycle($id: Int!) {
    deleteOneCompCycle(id: $id) {
      id
      deletedAt
      name
      endedAt
      createdAt
    }
  }
`;

export function useDeleteCompCycle(): (id: number) => Promise<unknown> {
  const [deleteCompCycle] = useMutation<
    DeleteCompCycle,
    DeleteCompCycleVariables
  >(DELETE_COMP_CYCLE, {
    update(cache, { data }) {
      if (data === null || data === undefined) {
        return;
      }

      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          compCycles(existingCycles: Reference[], { readField }) {
            return existingCycles.filter(
              (ref) => data.deleteOneCompCycle.id !== readField("id", ref)
            );
          },
        },
      });
    },
  });

  return useCallback(
    async (id: number) => {
      const { data } = await deleteCompCycle({
        variables: {
          id: id,
        },
      });
      return data ?? null;
    },
    [deleteCompCycle]
  );
}

const UPDATE_ELIGIBILITY_RULES = gql`
  mutation UpdateEligibilityRules($input: UpdateEligibilityRulesInput!) {
    updateEligibilityRules(input: $input) {
      id
      eligibilityRules
    }
  }
`;

export function useUpdateEligibilityRules(
  compCycleId: number
): (rules: Rule[]) => Promise<void> {
  const [uploadRules] = useMutation<
    UpdateEligibilityRules,
    UpdateEligibilityRulesVariables
  >(UPDATE_ELIGIBILITY_RULES);

  return useCallback(
    async (rules: Rule[]) => {
      await uploadRules({
        variables: { input: { compCycleId, rules } },
        optimisticResponse: {
          updateEligibilityRules: {
            __typename: "CompCycle",
            id: compCycleId,
            eligibilityRules: rules,
          },
        },
      });
    },
    [compCycleId, uploadRules]
  );
}

export function useCreateCycleEligibilityRules(): (
  rules: Rule[],
  compCycleId: number
) => Promise<FetchResult<UpdateEligibilityRules>> {
  const [uploadRules] = useMutation<
    UpdateEligibilityRules,
    UpdateEligibilityRulesVariables
  >(UPDATE_ELIGIBILITY_RULES);

  return useCallback(
    async (rules: Rule[], compCycleId: number) => {
      return await uploadRules({
        variables: { input: { compCycleId, rules } },
        optimisticResponse: {
          updateEligibilityRules: {
            __typename: "CompCycle",
            id: compCycleId,
            eligibilityRules: rules,
          },
        },
      });
    },
    [uploadRules]
  );
}
