import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const LocationGroupsIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Location Groups"
      viewBox="0 0 24 25"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M8 6.5C6.89543 6.5 6 7.39543 6 8.5C6 8.94593 6.14594 9.35778 6.39267 9.69038"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M10.8194 4.37068C9.99445 3.80745 9.01307 3.5 8 3.5C6.67392 3.5 5.40215 4.02678 4.46447 4.96447C3.52678 5.90215 3 7.17392 3 8.5C3 11.3653 5.02721 13.8252 6.5 15.2343"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M15 14.5625C16.2426 14.5625 17.25 13.5551 17.25 12.3125C17.25 11.0699 16.2426 10.0625 15 10.0625C13.7574 10.0625 12.75 11.0699 12.75 12.3125C12.75 13.5551 13.7574 14.5625 15 14.5625Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M20.625 12.3125C20.625 17.375 15 21.3125 15 21.3125C15 21.3125 9.375 17.375 9.375 12.3125C9.375 10.8207 9.96763 9.38992 11.0225 8.33502C12.0774 7.28013 13.5082 6.6875 15 6.6875C16.4918 6.6875 17.9226 7.28013 18.9775 8.33502C20.0324 9.38992 20.625 10.8207 20.625 12.3125V12.3125Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
