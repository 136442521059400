import { gql } from "@apollo/client";
import { isZero } from "@asmbl/shared/money";
import { BenefitsValueCard_employee } from "../../../__generated__/graphql";
import { CompCard } from "../../Cards/CompCard";

type Props = {
  employee: BenefitsValueCard_employee;
  className?: string;
};

export function BenefitsValueCard({ employee, className }: Props): JSX.Element {
  if (
    !employee.benefitsPackage ||
    isZero(employee.benefitsPackage.totalValue)
  ) {
    return <></>;
  }

  return (
    <CompCard
      className={className}
      label="Benefits"
      value={employee.benefitsPackage.totalValue}
      description="Estimated annual value"
    />
  );
}

BenefitsValueCard.fragments = {
  employee: gql`
    fragment BenefitsValueCard_employee on Employee {
      benefitsPackage {
        id
        name
        totalValue
      }
    }
  `,
};
