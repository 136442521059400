import { cx } from "@emotion/css";
import { makeStyles, Tooltip } from "@material-ui/core";
import { AdjustedBandPoint, formatPointValue } from "../../../models/BandPoint";
import { DV_GREEN, DV_ORANGE, GRAY_9, RED, WHITE } from "../../../theme";

const useEmployeePointStyles = makeStyles(() => ({
  line: {
    background: GRAY_9,
    height: "100%",
    position: "absolute",
    top: 0,
    transition: "all 250ms ease",
    width: "1px",
  },
  belowBand: {},
  inBand: {},
  aboveBand: {},
  dot: {
    height: "9px",
    width: "9px",
    borderRadius: "50%",
    backgroundColor: WHITE,
    border: `1px solid ${DV_ORANGE}`,
    boxShadow: "0px 1px 2px rgba(10, 36, 64, 0.2)",
    position: "absolute",
    left: "0.5px",
    top: "50%",
    transform: "translate(-50%, -50%)",

    "$belowBand &": {
      border: `1px solid ${WHITE}`,
      background: DV_GREEN,
    },
    "$aboveBand &": {
      border: `1px solid ${WHITE}`,
      background: RED,
    },
  },
}));

type EmployeePointProps = {
  point: AdjustedBandPoint;
  penetration: number;
  offset: string;
  isHourly?: boolean;
};
export function EmployeePoint({
  point,
  penetration,
  offset,
  isHourly = false,
}: EmployeePointProps): JSX.Element {
  const classes = useEmployeePointStyles();
  return (
    <Tooltip
      placement="top"
      title={`${point.name} · ${formatPointValue(point, isHourly)}${
        isHourly ? "/hr" : ""
      }`}
    >
      <div
        className={cx(classes.line, {
          [classes.belowBand]: penetration < 0,
          [classes.inBand]: 0 <= penetration && penetration <= 1,
          [classes.aboveBand]: 1 < penetration,
        })}
        style={{ left: offset }}
      >
        <div className={classes.dot} />
      </div>
    </Tooltip>
  );
}
