import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import get from "lodash/get";
import { useState } from "react";
import { ActivityIcon } from "src/components/AssembleIcons/Small/ActivityIcon";
import { useUserView } from "src/contexts/UserViewContext";
import { useTrack } from "../../../../../analytics";
import { ActivityLogDrawer } from "../../../../../components/CompCycle/ActivityLog/ActivityLogDrawer";
import { GRAY_5, PURPLE_1, RED, WHITE } from "../../../../../theme";

const useStyles = makeStyles({
  newActivityIndicator: {
    position: "absolute",
    background: RED,
    width: 6,
    height: 6,
    borderRadius: 6,
    right: -2,
    top: "calc(50% - 6px)",
    border: `solid 1px ${WHITE}`,
  },
});

type Props = {
  employeeId: number;
  compCycleId: number;
  lastActivityAt: GraphQL_DateTime | null;
};

export function ActivityLogButton({
  employeeId,
  compCycleId,
  lastActivityAt,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const { userView } = useUserView();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const lastViewed: number | undefined = get(
    userView,
    `json.compCycle[${compCycleId}].activityLog[${employeeId}]`
  );

  const showNewActivityIndicator =
    lastActivityAt != null &&
    (lastViewed == null ||
      lastViewed < new Date(lastActivityAt).getMilliseconds());

  const handleActivityLogChange = (newOpen: boolean) => {
    setOpen(newOpen);
    trackEvent({
      object: "Change Request Activity Log",
      action: newOpen ? "Clicked" : "Closed",
      employeeId,
    });
  };

  return (
    <>
      <Tooltip title="View Activity Log" placement="top" arrow>
        <IconButton
          id="review-activity-log-icon"
          onClick={(event) => {
            // prevent employee row from getting selected
            event.stopPropagation();
            handleActivityLogChange(true);
          }}
          size="small"
          data-intercom-target={"view-activity-log"}
        >
          {showNewActivityIndicator && (
            <span className={classes.newActivityIndicator} />
          )}
          <ActivityIcon color={lastActivityAt == null ? GRAY_5 : PURPLE_1} />
        </IconButton>
      </Tooltip>
      <ActivityLogDrawer
        openedEmployeeId={open ? employeeId : null}
        compCycleId={compCycleId}
        onClose={() => handleActivityLogChange(false)}
      />
    </>
  );
}
