import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { GRAY_2, GRAY_6, GRAY_8, WHITE } from "../../../theme";
import { reorder } from "../../../utils";
import { CustomFieldVariant } from "../../../__generated__/graphql";
import { CustomFieldRow } from "./CustomFieldRow";
import { KeyedCustomField } from "./OfferContentsForm";

const MAX_CUSTOM_FIELDS = 30;

const useStyles = makeStyles((theme) => ({
  card: {
    background: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px",
    width: "100%",
    borderBottom: "none",
  },
  cardHeader: {
    padding: theme.spacing(1.5),
    borderBottom: `1px solid ${GRAY_6}`,
  },
  headerText: {
    fontSize: "1rem",
    lineHeight: "140%",
    letterSpacing: "-0.25px",
    color: GRAY_2,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  cardFooter: {
    background: GRAY_8,
    borderRadius: "0px 0px 5px 5px",
    padding: theme.spacing(1, 7),
    justifyContent: "flex-start",
    width: "100%",
    borderBottom: `1px solid ${GRAY_6}`,
  },
}));

type Props = {
  customFields: KeyedCustomField[];
  onChange: (newFields: KeyedCustomField[]) => void;
};

export function CustomFieldList({
  customFields,
  onChange,
}: Props): JSX.Element {
  const classes = useStyles();
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      // ignore if dropped outside the list
      return;
    }

    const reorderedFields = reorder(
      customFields,
      result.source.index,
      result.destination.index
    );

    onChange(reorderedFields);
  };

  return (
    <>
      <Typography className={classes.headerText}>
        Add up to {MAX_CUSTOM_FIELDS} custom fields to request additional info
        or requirements when recruiters are creating offers.
      </Typography>
      <Box className={classes.card}>
        <Box className={classes.cardHeader}>
          <Typography variant="h6">Custom Fields</Typography>
        </Box>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {customFields.map((field, index) => (
                  <CustomFieldRow
                    key={field.uniqueKey}
                    fields={customFields}
                    index={index}
                    onFieldChange={(newField: KeyedCustomField) => {
                      const newFields = [...customFields];
                      newFields[index] = newField;
                      onChange(newFields);
                    }}
                    onDelete={() => {
                      const newFields = [...customFields];
                      newFields.splice(index, 1);
                      onChange(newFields);
                    }}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {customFields.length < MAX_CUSTOM_FIELDS ? (
          <Button
            className={classes.cardFooter}
            variant="text"
            onClick={() => {
              const newField = getNewCustomField();
              onChange([...customFields, newField]);
            }}
          >
            Add custom field...
          </Button>
        ) : (
          <Box className={classes.cardFooter} />
        )}
      </Box>
    </>
  );
}

const getNewCustomField = () => ({
  name: "",
  variant: CustomFieldVariant.TEXT,
  description: "",
  options: [],
  uniqueKey: Math.random(),
});
