import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { GRAY_2 } from "src/theme";
import { AssembleButton } from "../../AssembleButton/AssembleButton";
import { AssembleTypography } from "../../AssembleTypography";
import { DatePickerButton } from "../../DatePickerButton";

const useStyles = makeStyles((theme) => ({
  contentRoot: {
    paddingBottom: theme.spacing(1),
  },
  content: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  contentCopy: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
}));

type Props = {
  isOpen: boolean;
  date: Date | undefined;
  setDate: (newDate: Date | undefined) => void;
  onDateSave: () => void;
  initDateSelectorOpenValue?: boolean;
  dateRange?: { minDate: Date | undefined; maxDate: Date | undefined };
};

export function PhaseStartDateModal({
  isOpen,
  date,
  setDate,
  onDateSave,
  initDateSelectorOpenValue,
  dateRange,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} maxWidth="xs">
      <DialogTitle>
        <AssembleTypography variant="productHeader">
          Phase start date...
        </AssembleTypography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentRoot }}>
        <DialogContentText>
          <div className={classes.contentCopy}>
            <AssembleTypography variant="productParagraphLarge">
              Select when this phase should start. You can edit this up until
              you start your cycle.
            </AssembleTypography>
            <AssembleTypography
              variant="productParagraphMedium"
              fontWeight={700}
              textColor={GRAY_2}
            >
              (Note: Phases are optional and will end when the subsequent phase
              starts)
            </AssembleTypography>
          </div>
        </DialogContentText>
        <div className={classes.content}>
          <DatePickerButton
            title="Phase start date"
            clearable
            fullWidth
            value={date}
            onChange={setDate}
            zIndex={9999}
            disablePast
            errorOnEmpty={false}
            initOpenValue={initDateSelectorOpenValue}
            minDate={dateRange?.minDate}
            maxDate={dateRange?.maxDate}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <AssembleButton
          size="small"
          variant="text"
          label="Skip for Now"
          onClick={onDateSave}
        />

        <AssembleButton
          size="small"
          variant="contained"
          label="Save Phase"
          onClick={onDateSave}
        />
      </DialogActions>
    </Dialog>
  );
}
