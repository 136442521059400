import { Grid, makeStyles, Typography } from "@material-ui/core";
import { memo } from "react";
import { Benefit } from "../../models/Benefits";
import { getSimpleCashLabel } from "../../models/Currency";
import { BLUE_2 } from "../../theme";
import { NonNull } from "../../utils";
import { GetOffer } from "../../__generated__/graphql";
import { CashIcon } from "../AssembleIcons/Small/CashIcon";
import { AssembleLinkify } from "../AssembleLinkify/AssembleLinkify";
import { AssembleTypography } from "../AssembleTypography";
import { Bubble } from "../Employee/BubbleInfo/BubbleInfo";
import Header from "./Header";
import { globalStyles } from "./style";

type OfferConfig = NonNull<GetOffer["offerConfig"]>;
type BenefitsProps = {
  offerConfig: OfferConfig;
  benefits: Benefit[];
  hasAnyValue: boolean;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
  legend: {
    display: "flex",
    gap: theme.spacing(1.5),
  },
  benefitCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    height: "100%",
  },
  details: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(0.5),
  },
  description: {
    whiteSpace: "pre-wrap",
  },
  value: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
}));

export const Benefits = memo(function Benefits({
  offerConfig,
  benefits,
  hasAnyValue,
}: BenefitsProps): JSX.Element {
  const glClasses = globalStyles();
  const classes = useStyles();
  const color = offerConfig.brandColorPrimary ?? BLUE_2;

  return (
    <div className={classes.wrapper}>
      <Grid container spacing={3}>
        <Header
          color={color}
          headline="Benefits & Perks"
          subHead="Additional Information"
          legend={
            hasAnyValue ? (
              <div className={classes.legend}>
                <Bubble icon={<CashIcon inherit />} small color="gray" />
                <AssembleTypography variant="productHeader">
                  Estimated Value
                </AssembleTypography>
              </div>
            ) : undefined
          }
        />
        {benefits.map((benefit) => (
          <Grid key={benefit.rank} item xs={4}>
            <div className={classes.benefitCard}>
              <div className={classes.details}>
                <Typography className={glClasses.label} gutterBottom>
                  {benefit.name}
                </Typography>
                <AssembleLinkify newTab>
                  <Typography
                    className={`${glClasses.contentSmall} ${glClasses.gray2} ${classes.description}`}
                    variant="body1"
                  >
                    {benefit.description}
                  </Typography>
                </AssembleLinkify>
              </div>
              {benefit.value && (
                <div className={classes.value}>
                  <Bubble icon={<CashIcon inherit />} small color="gray" />
                  <AssembleTypography variant="productHeader">
                    {getSimpleCashLabel(benefit.value)}
                  </AssembleTypography>
                </div>
              )}
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
});
