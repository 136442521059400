import { gql } from "@apollo/client";
import { formatCurrency } from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { cx } from "@emotion/css";
import { makeStyles } from "@material-ui/core";
import { AssembleTypography } from "../../components/AssembleTypography";
import { currencySymbol } from "../../models/Currency";
import { BLUE_1 } from "../../theme";
import {
  PositionDetailBandPointCell_cashBandPoint as CashBandPoint,
  PositionDetailBandPointCell_equityBandPoint as EquityBandPoint,
  PositionDetailBandPointCell_position as Position,
  PositionType,
} from "../../__generated__/graphql";
import { AlternateForms } from "./AlternateForms";
import { CashBandName } from "@asmbl/shared/constants";

const useStyles = makeStyles(() => ({
  disabledText: {
    color: BLUE_1,
    opacity: 0.2,
  },
}));

type Props = {
  bandPoint: CashBandPoint | EquityBandPoint;
  position: Position;
  disabled?: boolean;
};

export function PositionDetailBandPointCell({
  bandPoint,
  position,
  disabled = false,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <AssembleTypography className={cx({ [classes.disabledText]: disabled })}>
        {(() => {
          if (bandPoint.value.__typename === "CashValue") {
            return bandPoint.value.rate === null
              ? `${currencySymbol(bandPoint.value.currencyCode)} -`
              : formatCurrency(bandPoint.value.rate);
          } else if (bandPoint.value.__typename === "UnitValue") {
            return bandPoint.value.unitValue === null
              ? "-"
              : `${formatNumeral(bandPoint.value.unitValue)}`;
          } else {
            return bandPoint.value.decimalValue === null
              ? "- %"
              : `${formatNumeral(bandPoint.value.decimalValue, {
                  style: "percent",
                  maximumSignificantDigits: 20,
                })}`;
          }
        })()}
        {position.type === PositionType.HOURLY &&
          bandPoint.value.__typename === "CashValue" &&
          bandPoint.bandName === CashBandName.SALARY &&
          bandPoint.value.rate !== null &&
          bandPoint.value.rate.value !== 0 &&
          " / hr"}
      </AssembleTypography>
      <AlternateForms
        position={position}
        bandPoint={bandPoint}
        disabled={disabled}
      />
    </>
  );
}

PositionDetailBandPointCell.fragments = {
  position: gql`
    ${AlternateForms.fragments.position}
    fragment PositionDetailBandPointCell_position on Position {
      id
      ...AlternateForms_position
    }
  `,
  cashBandPoint: gql`
    ${AlternateForms.fragments.cashBandPoint}
    fragment PositionDetailBandPointCell_cashBandPoint on AdjustedCashBandPoint {
      ...AlternateForms_cashBandPoint
      value {
        ... on CashValue {
          rate
          currencyCode
        }
      }
    }
  `,
  equityBandPoint: gql`
    ${AlternateForms.fragments.equityBandPoint}
    fragment PositionDetailBandPointCell_equityBandPoint on AdjustedEquityBandPoint {
      ...AlternateForms_equityBandPoint
      value {
        ... on CashValue {
          rate
          currencyCode
        }
        ... on UnitValue {
          unitValue
        }
        ... on PercentValue {
          decimalValue
        }
      }
    }
  `,
};
