import { gql } from "@apollo/client";
import { Currency } from "@asmbl/shared/currency";
import {
  max,
  min,
  money,
  Money,
  numberOfUnitsToCash,
  percentageOfSalaryToCash,
  zero,
} from "@asmbl/shared/money";
import { Box, Divider, makeStyles, Theme, Typography } from "@material-ui/core";
import { NumberFormatValues } from "react-number-format";
import {
  BandUnit,
  CurrencyCode,
  CompensationFormInput_equityBand as EquityBand,
  EquityGrantMethod,
} from "../../../__generated__/graphql";
import { CashBandName, EquityBandName } from "../../../constants";
import { isCashCompensation, OfferDataValue } from "../../../models/Offer";
import { GRAY_3, GRAY_4 } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";
import { FormInputSlider } from "../../CompSlider/FormInputSlider";
import ErrorWrapper from "../../ErrorWrapper";
import { BandName, ChangeHandler, OfferData } from "../types";
import { PositionBandData } from "./Compensation";
import {
  OfferInputCashValue,
  OfferInputPercentageOfSalary,
  OfferInputUnitValue,
} from "./CompensationFormInputs";
import { OfferInputTypeSelector } from "./CompensationFormInputTypesSelector";

const useStyles = makeStyles((theme: Theme) => ({
  compensationFormInputHeader: {
    color: GRAY_3,
    fontSize: "12px",
    fontWeight: 700,
  },
  compensationFormInputContentWrapper: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(4),
    alignItems: "center",
  },
  compensationFormInputWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  compensationFormInputAndSelectorWrapper: {
    minWidth: "8rem",
  },
  compensationFormInputLeft: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(1),
    justifyContent: "center",
  },
  compensationFormInputRight: {
    width: "62%",
  },
  compensationNoBandInfoWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  compensationNoBandInfoText: {
    color: GRAY_4,
    fontSize: "12px",
    textAlign: "center",
  },
}));

type Props = {
  data: OfferData;
  bandName: BandName;
  monetaryValue: OfferDataValue;
  currency: Currency<CurrencyCode>;
  positionData: PositionBandData | undefined;
  handleChange: ChangeHandler;
  label?: string;
  equityGrantMethod?: EquityGrantMethod;
  showCurrentEquityValue: boolean;
  pricePerUnit?: Money<CurrencyCode>;
  equityBandData?: EquityBand[];
  totalEquityValue?: Money<CurrencyCode>;
  showEquityInValuationCurrency?: boolean;
  annualSalary: Money<CurrencyCode> | undefined;
};

export function CompensationFormInput({
  data,
  bandName,
  monetaryValue,
  label,
  positionData,
  equityBandData,
  handleChange,
  equityGrantMethod,
  pricePerUnit,
  currency,
  totalEquityValue,
  showCurrentEquityValue,
  showEquityInValuationCurrency,
  annualSalary,
}: Props): JSX.Element {
  const classes = useStyles();

  const bandMatch = positionData?.positionBands.find(
    (band) => band.name === bandName
  );

  const equityBand = equityBandData?.find((band) => band.name === bandName);

  const equityUnits =
    equityBand?.bandPoints.map((p) => ({ value: p.totalUnits ?? 0 })) ?? [];

  const errorFlag = getErrorFlag(
    annualSalary,
    monetaryValue,
    bandMatch,
    pricePerUnit
  );

  const currentData = isCashCompensation(bandName)
    ? data.cash[bandName as CashBandName]
    : data.equity[bandName as EquityBandName];

  const handleChangeHelper = (
    mode: BandUnit,
    floatValue: NumberFormatValues["floatValue"]
  ) => {
    const type = isCashCompensation(bandName) ? "cash" : "equity";

    return handleChange(type, {
      ...data[type],
      [bandName]: {
        mode,
        value:
          floatValue === undefined
            ? undefined
            : mode === BandUnit.CASH
              ? money(floatValue, currency.code)
              : floatValue,
      },
    });
  };

  const handleOfferTypeChange = (offerType: BandUnit) =>
    handleChangeHelper(offerType, undefined);

  const handleAbsolute = ({ floatValue }: NumberFormatValues) => {
    return handleChangeHelper(BandUnit.CASH, floatValue);
  };

  const handlePercent = ({ floatValue }: NumberFormatValues) => {
    return handleChangeHelper(BandUnit.PERCENTAGE, floatValue);
  };

  const handleUnit = ({ floatValue }: NumberFormatValues) => {
    return handleChangeHelper(BandUnit.UNITS, floatValue);
  };

  const cashShouldAllowPercentage = [
    CashBandName.COMMISSION,
    CashBandName.RECURRING_BONUS,
    CashBandName.SPOT_BONUS,
  ].includes(bandName as CashBandName);

  const equityShouldAllowPercentage =
    showCurrentEquityValue &&
    [EquityBandName.INITIAL_EQUITY_GRANT].includes(bandName as EquityBandName);

  const shouldAllowPercentage =
    cashShouldAllowPercentage || equityShouldAllowPercentage;

  const offerType =
    currentData !== undefined
      ? currentData.mode
      : equityGrantMethod === EquityGrantMethod.UNITS
        ? BandUnit.UNITS
        : BandUnit.CASH;

  return (
    <>
      <Box mb={4} mt={3} key={bandName}>
        <Box mb={1}>
          <label
            className={classes.compensationFormInputHeader}
            htmlFor={bandName}
          >
            {(label ?? bandName).toUpperCase()}
          </label>
        </Box>

        <Box className={classes.compensationFormInputContentWrapper}>
          <Box className={classes.compensationFormInputLeft}>
            {shouldAllowPercentage && (
              <Box className={classes.compensationFormInputAndSelectorWrapper}>
                <OfferInputTypeSelector
                  offerType={offerType}
                  onOfferTypeChange={handleOfferTypeChange}
                  bandName={bandName}
                  equityGrantMethod={equityGrantMethod}
                />
              </Box>
            )}

            <Box className={classes.compensationFormInputWrapper}>
              <ErrorWrapper
                message={errorFlag.message ?? ""}
                visible={errorFlag.show}
              >
                <Box>
                  {offerType === BandUnit.PERCENTAGE && (
                    <OfferInputPercentageOfSalary
                      key={offerType}
                      bandName={bandName}
                      handlePercent={handlePercent}
                      percentInput={
                        currentData?.mode === BandUnit.PERCENTAGE
                          ? currentData.value
                          : undefined
                      }
                    />
                  )}

                  {offerType === BandUnit.CASH && (
                    <OfferInputCashValue
                      key={offerType}
                      bandName={bandName}
                      handleAbsolute={handleAbsolute}
                      absoluteInput={
                        currentData?.mode === BandUnit.CASH
                          ? currentData.value?.value
                          : undefined
                      }
                      currency={currency.code}
                      error={
                        currentData?.mode === BandUnit.CASH &&
                        currentData.value?.value !== undefined &&
                        currentData.value.value % 1 !== 0
                      }
                      positionType={positionData?.positionType}
                    />
                  )}

                  {offerType === BandUnit.UNITS && (
                    <OfferInputUnitValue
                      key={offerType}
                      bandName={bandName}
                      handleUnit={handleUnit}
                      unitInput={
                        currentData?.mode === BandUnit.UNITS
                          ? currentData.value
                          : undefined
                      }
                      error={
                        currentData?.mode === BandUnit.UNITS &&
                        currentData.value !== undefined &&
                        currentData.value % 1 !== 0
                      }
                    />
                  )}
                </Box>
              </ErrorWrapper>
            </Box>
          </Box>

          <Box className={classes.compensationFormInputRight}>
            {bandMatch !== undefined ? (
              <Box mt={1}>
                <FormInputSlider
                  bandName={bandName}
                  salary={annualSalary}
                  value={monetaryValue}
                  bandPoints={bandMatch.bandPoints}
                  equityUnits={equityUnits}
                  totalEquityValue={totalEquityValue}
                  showUnits={equityGrantMethod === EquityGrantMethod.UNITS}
                  showPercentage={shouldAllowPercentage}
                  showCurrentEquityValue={showCurrentEquityValue}
                />
              </Box>
            ) : (
              <Box className={classes.compensationNoBandInfoWrapper}>
                <Typography
                  variant="caption"
                  className={classes.compensationNoBandInfoText}
                >
                  There is no Band info for
                  <br />
                  {bandName}.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        {showEquityInValuationCurrency === true && (
          <Box mt={0.5} ml={2}>
            <AssembleTypography variant="productMicrocopy" textColor={GRAY_4}>
              Displayed in company's valuation currency.
            </AssembleTypography>
          </Box>
        )}
      </Box>
      <Divider orientation="horizontal" />
    </>
  );
}

function getErrorFlag(
  annualSalary: Money<CurrencyCode> | undefined,
  formValue: OfferDataValue,
  bandMatch?: { name: BandName; bandPoints: Money[] },
  pricePerUnit?: Money
) {
  if (
    bandMatch === undefined ||
    formValue === undefined ||
    formValue.value === undefined
  ) {
    return { show: false };
  }

  let cashValue: Money | undefined = undefined;

  if (formValue.mode === BandUnit.CASH) {
    cashValue = formValue.value;
  } else if (formValue.mode === BandUnit.PERCENTAGE) {
    cashValue =
      annualSalary && percentageOfSalaryToCash(annualSalary, formValue.value);
  } else {
    // BandUnit.Units
    cashValue =
      pricePerUnit && numberOfUnitsToCash(pricePerUnit, formValue.value);
  }

  if (cashValue === undefined) {
    return { show: false };
  }

  const minValue = min(...bandMatch.bandPoints) ?? zero(cashValue.currency);
  const maxValue = max(...bandMatch.bandPoints) ?? {
    value: Infinity,
    currency: cashValue.currency,
  };

  if (
    cashValue.value < minValue.value &&
    !almost(minValue.value, cashValue.value)
  ) {
    return { show: true, message: "Value is less than the band's low-point" };
  } else if (
    cashValue.value > maxValue.value &&
    !almost(cashValue.value, maxValue.value)
  ) {
    return { show: true, message: "Value is more than the band's high-point" };
  }

  return { show: false };
}

function almost(a: number, b: number, delta = 0.000001) {
  return Math.abs(a - b) < delta;
}

CompensationFormInput.fragments = {
  equityBand: gql`
    fragment CompensationFormInput_equityBand on AdjustedEquityBand {
      id
      name
      bandPoints {
        name
        totalGrossValue
        totalUnits
        value {
          ... on CashValue {
            annualRate
            currencyCode
          }
          ... on UnitValue {
            unitValue
          }
          ... on PercentValue {
            decimalValue
          }
        }
      }
    }
  `,
};
