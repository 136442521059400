import { IconButton, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { ArrowParentIcon } from "src/components/AssembleIcons/Small/ArrowParentIcon";
import { GRAY_4, PURPLE_2 } from "../../../../theme";

const useStyles = makeStyles(() => ({
  caret: {
    transition: "all 125ms",
    transform: "rotate(-90deg)",

    "&$expanded": {
      transform: "rotate(0)",
    },
  },
  expanded: {},
  arrowParent: { transform: "scaleX(-1) rotate(-90deg)" },
}));

type RowIconProps = {
  expandable?: boolean;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
};

export function RowIcon({
  expandable = false,
  expanded,
  setExpanded,
}: RowIconProps): JSX.Element {
  const classes = useStyles();

  return expandable ? (
    <IconButton
      data-cy="caret-icon"
      onClick={() => setExpanded(!expanded)}
      disableRipple
    >
      <ChevronDownIcon
        className={clsx(classes.caret, { [classes.expanded]: expanded })}
        color={GRAY_4}
        hoverColor={PURPLE_2}
      />
    </IconButton>
  ) : (
    <IconButton disabled>
      <ArrowParentIcon className={classes.arrowParent} color={GRAY_4} />
    </IconButton>
  );
}
