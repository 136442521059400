import { makeStyles } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useState } from "react";
import { BorderlessSelect } from "../../components/Form/BorderlessSelect";
import { ActionBarItem } from "../../components/Visualization/ActionBarItem";
import { FilterableCard } from "../../components/Visualization/FilterableCard";
import { DemographicsCharts } from "./DemographicsCharts";

const useStyles = makeStyles(() => ({
  toggleButton: {
    fontSize: "20px",
    padding: 0,
    paddingRight: "10px",
    paddingLeft: "10px",
    fontWeight: "bold",
    width: "55px",
  },
  leftToggle: {
    borderRadius: 0,
    borderTop: "none",
    borderBottom: "none",
  },
  rightToggle: {
    borderTop: "none",
    borderBottom: "none",
    borderRight: "none",
    borderBottomRightRadius: 0,
  },
}));

export enum TimeUnit {
  TODAY = "Today",
  LAST_YEAR = "Last 12 Months",
  ALL_TIME = "All Time",
}

export enum ToggleType {
  ABSOLUTE,
  PERCENT,
}

export function Demographics(): JSX.Element {
  const classes = useStyles();
  const [timeUnit, setTimeUnit] = useState(TimeUnit.TODAY as string);
  const [toggle, setToggle] = useState(ToggleType.ABSOLUTE);
  const title =
    timeUnit === TimeUnit.TODAY
      ? `Representation ${timeUnit}`
      : `Representation Over ${timeUnit}`;

  const handleChange = (e: React.MouseEvent, value: ToggleType | null) => {
    if (value !== null) {
      setToggle(value);
    }
  };

  return (
    <FilterableCard
      title={title}
      actionBar={
        <>
          <ActionBarItem>
            <BorderlessSelect
              options={[
                TimeUnit.TODAY as string,
                TimeUnit.LAST_YEAR as string,
                TimeUnit.ALL_TIME as string,
              ]}
              selected={timeUnit}
              onChange={setTimeUnit}
            />
          </ActionBarItem>
          <ToggleButtonGroup onChange={handleChange} exclusive value={toggle}>
            <ToggleButton
              value={ToggleType.ABSOLUTE}
              className={`${classes.toggleButton} ${classes.leftToggle}`}
            >
              #
            </ToggleButton>
            <ToggleButton
              value={ToggleType.PERCENT}
              className={`${classes.toggleButton} ${classes.rightToggle}`}
            >
              %
            </ToggleButton>
          </ToggleButtonGroup>
        </>
      }
    >
      <DemographicsCharts toggle={toggle} timeUnit={timeUnit} />
    </FilterableCard>
  );
}
