import { useCallback, useState } from "react";
import { TreeRow } from "./TreeRow";

export interface TreeNode<T extends { id: number; name: string }> {
  data: T;
  category: string;
  hint: JSX.Element;
  selected: boolean;
  inherited: boolean;
  indeterminate: boolean;
  children?: TreeNode<T>[];
  decorator?: JSX.Element;
}

interface TreeSelectProps<T extends { id: number; name: string }> {
  node: TreeNode<T>;
  onChange: (node: TreeNode<T>) => unknown;
  indentation: { isLastChild: boolean }[];
  defaultExpand?: boolean;
  expandAll?: boolean;
}

export function TreeSelect<T extends { id: number; name: string }>({
  node,
  onChange,
  indentation,
  defaultExpand = false,
  expandAll = false,
}: TreeSelectProps<T>): JSX.Element {
  const { data, selected, indeterminate, children, category, hint, decorator } =
    node;
  const [isCollapsed, setCollapsed] = useState<boolean>(
    expandAll ? false : !defaultExpand
  );

  const onNodeToggle = useCallback(() => {
    if (!selected || indeterminate) {
      onChange(
        mapTree(node, (n) => ({ ...n, selected: true, indeterminate: false }))
      );
    } else {
      onChange(
        mapTree(node, (n) => ({ ...n, selected: false, indeterminate: false }))
      );
    }
  }, [indeterminate, node, onChange, selected]);

  const onChildToggle = useCallback(
    (child: TreeNode<T>) => {
      if (children === undefined) {
        return;
      }
      const newChildren = children.map((c) =>
        c.data === child.data ? child : c
      );
      onChange({
        data,
        category,
        hint,
        selected: newChildren.some((child) => child.selected),
        inherited: false,
        indeterminate:
          newChildren.some((child) => child.indeterminate) ||
          (newChildren.some((child) => child.selected) &&
            newChildren.some((child) => !child.selected)),
        children: newChildren,
      });
    },
    [children, data, category, hint, onChange]
  );

  const onCollapseExpand = useCallback(
    () => setCollapsed((isCollapsed) => !isCollapsed),
    []
  );

  return (
    <>
      <TreeRow
        isCollapsible={children !== undefined && children.length > 0}
        collapsed={isCollapsed}
        indentation={indentation}
        selected={selected}
        indeterminate={indeterminate}
        onClick={onNodeToggle}
        onCollapseExpand={onCollapseExpand}
        label={data.name}
        category={category}
        hint={hint}
        decorator={decorator}
      />
      {!isCollapsed &&
        children?.map((child, c) => {
          const childIndentation = indentation.concat([
            { isLastChild: c === children.length - 1 },
          ]);
          return (
            <TreeSelect
              key={child.data.id}
              indentation={childIndentation}
              node={child}
              onChange={onChildToggle}
              defaultExpand={child.indeterminate}
              expandAll={expandAll}
            />
          );
        })}
    </>
  );
}

export function mapTree<T extends { id: number; name: string }>(
  root: Readonly<TreeNode<T>>,
  fn: (node: Readonly<TreeNode<T>>) => TreeNode<T>
): TreeNode<T> {
  return {
    ...fn(root),
    children: root.children?.map((child) => mapTree(child, fn)),
  };
}
