import { gql } from "@apollo/client";
import { Rule } from "@asmbl/shared/eligibility";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { EligibilityEditor_employee as Employee } from "src/__generated__/graphql";
import { MultiRuleEditor } from "./MultiRuleEditor";
import { ParticipantsLoadingBoundary } from "./ParticipantsLoadingBoundary";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
  },
}));

type Props = {
  compCycleId: number;
  ruleSet: Rule[];
  employees: Employee[];
  onChange: (rules: Rule[]) => Promise<void>;
  departmentOptions?: { label: string; value: number }[];
  ladderOptions?: { label: string; value: number }[];
  levelOptions?: { label: string; value: number }[];
  perfRatingOptions?: { label: string; value: string }[];
  loading: boolean;
};

export function EligibilityEditor({
  compCycleId,
  ruleSet,
  employees,
  onChange,
  departmentOptions,
  ladderOptions,
  levelOptions,
  perfRatingOptions,
  loading,
}: Props): JSX.Element {
  const [updatingRules, setUpdatingRules] = useState<boolean>(false);
  const classes = useStyles();

  const handleChange = async (newRules: Rule[]) => {
    setUpdatingRules(true);
    await onChange(newRules);
    setUpdatingRules(false);
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <CircularProgress />
      ) : (
        <MultiRuleEditor
          ruleSet={ruleSet}
          onChange={handleChange}
          employees={employees}
          departmentOptions={departmentOptions}
          ladderOptions={ladderOptions}
          levelOptions={levelOptions}
          perfRatingOptions={perfRatingOptions}
        />
      )}
      {updatingRules ? (
        <CircularProgress />
      ) : (
        <ParticipantsLoadingBoundary compCycleId={compCycleId} />
      )}
    </div>
  );
}

EligibilityEditor.fragments = {
  organization: gql`
    ${MultiRuleEditor.fragments.organization}
    fragment EligibilityEditor_organization on Organization {
      id
      ...MultiRuleEditor_organization
    }
  `,
  compCycle: gql`
    ${MultiRuleEditor.fragments.compCycle}
    fragment EligibilityEditor_compCycle on CompCycle {
      ...MultiRuleEditor_compCycle
    }
  `,
  employee: gql`
    ${MultiRuleEditor.fragments.employee}
    fragment EligibilityEditor_employee on Employee {
      ...MultiRuleEditor_employee
    }
  `,
};
