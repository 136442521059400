import { gql } from "@apollo/client";
import { isEmptyString } from "@asmbl/shared/utils";
import { Box, makeStyles, Tab, Tabs } from "@material-ui/core";
import { forwardRef, Ref } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NewWindowIcon } from "src/components/AssembleIcons/Small/NewWindow";
import { useTrack } from "../../analytics";
import { GRAY_1, GRAY_4 } from "../../theme";

type Props = {
  hasEquity: boolean;
  hasBenefits: boolean;
  hasBands: boolean;
  currentBandOnly: boolean;
  totalRewardsStatementUrl: string | null;
};

const useStyles = makeStyles((theme) => ({
  tabBar: {
    display: "flex",
    minHeight: theme.spacing(4.4),
  },
  tabsContainer: {
    display: "flex",
    gap: theme.spacing(3),
  },
  tab: {
    color: GRAY_4,
    minWidth: 0,
    minHeight: 0,
    padding: 0,

    "&:hover": {
      color: GRAY_1,
      opacity: 1,
    },
  },
  tabLink: {
    color: "inherit",
    textDecoration: "none",
  },
  activeTab: {
    color: GRAY_1,
  },
  tabHeaderRoot: {
    padding: 0,
  },
  externalLinkIcon: {
    marginLeft: theme.spacing(1),
  },
}));

export function PortalTabs({
  hasEquity,
  hasBenefits,
  hasBands,
  currentBandOnly,
  totalRewardsStatementUrl,
}: Props): JSX.Element {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Tabs
      value={location.hash || "#info"}
      classes={{
        root: classes.tabBar,
        flexContainer: classes.tabsContainer,
      }}
    >
      <LinkTab label="Total Compensation" value="#total-compensation" />
      {hasBands ? (
        <LinkTab
          label={
            currentBandOnly
              ? "Cash Compensation Band"
              : "Cash Compensation Band Comparison"
          }
          value="#comp-band"
        />
      ) : null}
      {hasEquity ? <LinkTab label="Equity" value="#equity" /> : null}
      <LinkTab label="Compensation Timeline" value="#timeline" />
      {hasBenefits ? (
        <LinkTab label="Benefits & Perks" value="#benefits" />
      ) : null}
      {!isEmptyString(totalRewardsStatementUrl) && (
        <LinkTab
          label="Compensation Change Letter"
          value={totalRewardsStatementUrl ?? ""}
          external
        />
      )}
      <LinkTab label="" value="#info" />
    </Tabs>
  );
}

type LinkTabProps = {
  label: string;
  value: string;
  selected?: boolean;
  external?: boolean;
};

const LinkTab = forwardRef(function LinkTab(
  { label, value, selected, external = false }: LinkTabProps,
  ref: Ref<HTMLDivElement>
): JSX.Element {
  const classes = useStyles();
  const { trackEvent } = useTrack();
  const navigate = useNavigate();

  const tabClicked = (label: string, value: string) => {
    if (!external) {
      navigate(value, { replace: true });
    }
    trackEvent({
      area: "Employee Portal",
      subArea: "Header Nav",
      object: "Portal Header Nav Item",
      action: "Viewed",
      navigationItem: label.length > 0 ? label : "Home",
    });
  };

  return (
    <Tab
      component="span"
      ref={ref}
      value={value}
      onClick={() => tabClicked(label, value)}
      label={
        external ? (
          <a
            className={classes.tabLink}
            href={value}
            target="_blank"
            rel="noreferrer"
          >
            <Box display="flex">
              {label} <NewWindowIcon className={classes.externalLinkIcon} />
            </Box>
          </a>
        ) : (
          <span className={classes.tabLink}>{label}</span>
        )
      }
      classes={{
        selected: classes.activeTab,
        textColorInherit: classes.tab,
      }}
      selected={selected}
    />
  );
});

PortalTabs.fragments = {
  employee: gql`
    fragment PortalTabs_employee on Employee {
      totalRewardsStatementUrl
    }
  `,
};
