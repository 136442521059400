import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const DenyIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Deny"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className="stroke" cx="8" cy="8" r="7.25" strokeWidth="1.5" />
      <path
        className="stroke"
        d="M3 3C3 3 9.29002 9.29002 13 13"
        strokeWidth="1.5"
      />
    </svg>
  </ColorableSVG>
);
