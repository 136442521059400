import { Box, TextField } from "@material-ui/core";
import {
  CustomFieldAnswerInput as CustomFieldAnswer,
  CustomFieldInput as CustomField,
  CustomFieldVariant,
} from "../../../__generated__/graphql";
import { SelectField } from "../../Form/SelectField";
import { useStyles } from "../style";
import { ChangeHandler } from "../types";

const MAX_CHARACTERS = 1000;

type Props = {
  customFields: CustomField[];
  customFieldAnswers: CustomFieldAnswer[];
  handleChange: ChangeHandler;
};

export function CustomFieldAnswers({
  customFields,
  customFieldAnswers,
  handleChange,
}: Props): JSX.Element {
  const classes = useStyles();

  // Convert customFieldAnswers to a hashmap
  const answers = new Map(customFieldAnswers.map((x) => [x.name, x.answer]));

  const getAnswer = (fieldName: string) => {
    return answers.get(fieldName) ?? "";
  };

  const replaceAnswer = (fieldName: string, newAnswer: string) => {
    answers.set(fieldName, newAnswer);
    const newAnswers = [...answers].map(([name, answer]) => ({
      name,
      answer,
    }));
    handleChange("customFieldAnswers", newAnswers);
  };

  return (
    <>
      {customFields.map((field) => {
        if (field.variant === CustomFieldVariant.DROPDOWN) {
          return (
            <Box className={classes.fullWidth} mb={2} key={field.name}>
              <SelectField
                id={field.name}
                options={[
                  {
                    label: "Please select an option...",
                    value: "",
                    disabled: true,
                  },
                  ...field.options.map((option) => ({
                    label: option,
                    value: option,
                  })),
                ]}
                label={field.name}
                value={getAnswer(field.name)}
                onChange={(value: string) => replaceAnswer(field.name, value)}
              />
            </Box>
          );
        } else {
          return (
            <Box className={classes.fullWidth} mb={2} key={field.name}>
              <TextField
                fullWidth
                id={field.name}
                inputProps={{ maxLength: MAX_CHARACTERS }}
                multiline
                placeholder={field.description ?? undefined}
                rows={1}
                maxRows={8}
                label={field.name}
                onChange={(e) => replaceAnswer(field.name, e.target.value)}
                variant="outlined"
                value={getAnswer(field.name)}
              />
            </Box>
          );
        }
      })}
    </>
  );
}
