import { useQuery } from "@apollo/client";
import { generateQuarterlyDates } from "@asmbl/shared/utils";
import { Box } from "@material-ui/core";
import { BigCount } from "../../components/Visualization/BigCount";
import { VisualizationCard } from "../../components/Visualization/VisualizationCard";
import { GET_EMPLOYEE_ANALYTICS_COUNTS_AND_TIMESERIES } from "../../queries";
import { formatDateString } from "../../utils";
import { GetEmployeeAnalytics } from "../../__generated__/graphql";
import { TimeUnit } from "./Demographics";

interface Props {
  timeUnit: string;
}

export function EmployeeCountChart({ timeUnit }: Props): JSX.Element {
  const lastYearDates: string[] = generateQuarterlyDates().map((date) =>
    formatDateString(date)
  );
  const { data: employeeCountsData, loading: employeeCountsLoading } =
    useQuery<GetEmployeeAnalytics>(
      GET_EMPLOYEE_ANALYTICS_COUNTS_AND_TIMESERIES,
      {
        variables: {
          groupByFilterOpts: {},
          allTimeSeriesOpts: { dates: [] },
          lastYearTimeSeriesOpts: { dates: lastYearDates },
        },
      }
    );

  const currentCount =
    employeeCountsData?.employeeAnalytics.count[0].value ?? 0;
  const lastYearCount =
    employeeCountsData?.employeeAnalytics.lastYearTimeSeries[0].values[0]
      .value ?? 0;
  const allTimeCount =
    employeeCountsData?.employeeAnalytics.allTimeTimeSeries[0].values[0]
      .value ?? 0;

  return (
    <Box flex={0.5}>
      <VisualizationCard title="Total People" loading={employeeCountsLoading}>
        {timeUnit === TimeUnit.TODAY ? (
          <BigCount currentCount={currentCount} />
        ) : (
          <BigCount
            currentCount={currentCount}
            previousCount={
              timeUnit === TimeUnit.ALL_TIME ? allTimeCount : lastYearCount
            }
          />
        )}
      </VisualizationCard>
    </Box>
  );
}
