import { Box, TableCell, TableRow } from "@material-ui/core";
import { NoResultsIcon } from "src/components/AssembleIcons/Brand/NoResultsIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_4 } from "../../theme";

export function EmptySearchResults({
  searchTerm,
  colSpan = 11,
}: {
  searchTerm: string;
  colSpan?: number;
}): JSX.Element {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} rowSpan={8}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={1}
        >
          <NoResultsIcon color={GRAY_4} width="48px" height="48px" />
          <AssembleTypography>
            {searchTerm.length > 0
              ? `We can't find any employees with "${searchTerm}" in their name.`
              : "We can't find any employees that match those filters."}
          </AssembleTypography>
        </Box>
      </TableCell>
    </TableRow>
  );
}
