type Props = {
  token: string;
  compCycleId: number;
  organizationId: number;
  environment: string;
  dashboardType: DashboardType;
};

export const Dashboards = [
  "pay-equity",
  "performance",
  "bonus",
  "compa-ratio",
] as const;
export type DashboardType = (typeof Dashboards)[number];

const DashboardTokens: Record<DashboardType, string> = {
  "pay-equity": "yWYEO391Eq",
  performance: "jDxemZwxLG",
  bonus: "yWYEp35AEq",
  "compa-ratio": "5L1BW2bAqe",
} as const;

export function ReportingDashboard({
  compCycleId,
  organizationId,
  token,
  environment,
  dashboardType,
}: Props) {
  return (
    <explo-dashboard
      dash-customer-token={`${DashboardTokens[dashboardType]}:${token}`}
      updateUrlParams={true}
      isProduction={environment === "production"}
      environment={environment}
      refresh-minutes={10}
      variables={JSON.stringify({
        comp_cycle_id: compCycleId,
        customer_id: organizationId,
      })}
    />
  );
}
