import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { GRAY_4, WHITE } from "../../theme";
import { LargeCompaRatioSlider } from "././LargeCompaRatioSlider";
import { BandPoint } from "./LargeCompSlider";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    flexDirection: "column",
  },
  tooltipPositionText: {
    color: GRAY_4,
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  tooltipLevelText: {
    color: GRAY_4,
    fontSize: "12px",
    marginBottom: "15px",
  },
  tooltip: {
    padding: theme.spacing(2),
    borderRadius: "10px",
    width: "320px",
    height: "147px",
    boxShadow: "0px 15px 25px rgba(10, 36, 64, 0.08)",
    background: WHITE,
  },
}));

export type Position = {
  name: string;
  level: number;
};

export type Props = {
  bandPoints: BandPoint[];
  value: number | null;
  position: Position | null;
  children: JSX.Element;
};

export function CompCompaRatioSliderTooltip({
  bandPoints,
  value,
  position,
  children,
}: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      placement="top"
      interactive
      title={
        <>
          <div className={classes.titleContainer}>
            <Typography className={classes.tooltipPositionText}>
              {position?.name ?? ""}
            </Typography>
            <Typography className={classes.tooltipLevelText}>{`Level ${
              position?.level ?? "N/A"
            }`}</Typography>
          </div>
          <LargeCompaRatioSlider
            value={value}
            bandPoints={bandPoints}
            position={position}
          />
        </>
      }
    >
      {children}
    </Tooltip>
  );
}
