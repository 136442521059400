import { partition } from "@asmbl/shared/utils";
import {
  CompCycleReviewPhases_compCycle,
  CompCycleReviewPhasesBoundary_participant,
  CompRecommendationStatus,
} from "src/__generated__/graphql";
import { CompCycleReviewerWithStatus } from "./CompCycleReviewPhases";

export const UNGROUPED_KEY = "all";

export const sortAuthors = (
  filteredAuthors: CompCycleReviewerWithStatus[],
  compCycle: CompCycleReviewPhases_compCycle
): { [key: string]: CompCycleReviewerWithStatus[] } =>
  filteredAuthors.reduce(
    (grouped, author) => {
      const authorPhase = compCycle.phases.find((phase) => {
        return phase.compCyclePhaseAssignments
          .map((assignment) => assignment?.assigneeId)
          .includes(author.id);
      });

      if (!authorPhase) return grouped;

      grouped[authorPhase.id].push(author);
      return grouped;
    },
    Object.fromEntries<CompCycleReviewerWithStatus[]>(
      compCycle.phases.map((phase) => [phase.id, []])
    )
  );

export function checkForLateRecs(
  managers: CompCycleReviewerWithStatus[]
): boolean {
  return managers.some((manager) => {
    const [directReports, indirectReports] = partition(
      manager.assignees,
      (report) => report.subject.managerId === manager.id
    );

    const reportsWithoutRecs = directReports.filter(
      (report) =>
        report.compRecommendation === null ||
        report.compRecommendation.reviewStatus !==
          CompRecommendationStatus.REVIEWED
    );

    const reportsWithoutReviews = filterRecsWithoutReviews(
      indirectReports,
      manager.user?.id
    );
    return reportsWithoutRecs.length > 0 || reportsWithoutReviews.length > 0;
  });
}

export function getLateRecsCounts(managers: CompCycleReviewerWithStatus[]): {
  totalCount: number;
  lateManagers: number[];
} {
  let totalCount = 0;
  const lateManagers: number[] = [];
  managers.forEach((manager) => {
    const [directReports, indirectReports] = partition(
      manager.assignees,
      (report) => report.subject.managerId === manager.id
    );

    const reportsWithoutRecs = directReports.filter(
      (report) =>
        report.compRecommendation === null ||
        report.compRecommendation.reviewStatus !==
          CompRecommendationStatus.REVIEWED
    );

    totalCount += reportsWithoutRecs.length;

    const reportsWithoutReviews = filterRecsWithoutReviews(
      indirectReports,
      manager.user?.id
    );

    totalCount += reportsWithoutReviews.length;

    if (reportsWithoutRecs.length > 0 || reportsWithoutReviews.length > 0)
      lateManagers.push(manager.id);
  });

  return { totalCount, lateManagers };
}

function filterRecsWithoutReviews(
  reports: CompCycleReviewPhasesBoundary_participant[],
  currUserId: number | undefined
) {
  return reports.filter(
    (report) =>
      report.compRecommendation !== null &&
      (report.compRecommendation.reviewStatus !==
        CompRecommendationStatus.REVIEWED ||
        (report.compRecommendation.latestSubmittedItems.at(0)?.author.id ===
          currUserId &&
          report.compRecommendation.latestSubmittedReviews.at(0)?.authorId ===
            currUserId))
  );
}
