import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { makeStyles, TableCell } from "@material-ui/core";
import { PayPeriodType } from "src/__generated__/graphql";
import { useURLSearchParams } from "../../models/URLSearchParams";
import { CalendarIcon } from "../AssembleIcons/Brand/CalendarIcon";
import { ClockIcon } from "../AssembleIcons/Brand/ClockIcon";
import { AssembleLink } from "../AssembleLink";
import { AssembleTruncatedTypography } from "../AssembleTruncatedTypography";
import { useCompStructure } from "../CompStructureContext";
import { useFeatureFlags } from "../FeatureContext";

const useStyles = makeStyles((theme) => ({
  root: { margin: theme.spacing(-0.5, 1, -0.5, 0) },
  center: {
    display: "inline-flex",
    alignItems: "center",
    gap: theme.spacing(0.25),
  },
}));

interface Props {
  displayName: string;
  employeeId: number;
  position: string | undefined;
  className?: string;
  canOpenDetailView?: true;
  payPeriodType?: PayPeriodType;
}

export function NameAndPositionCell({
  displayName,
  employeeId,
  position,
  className,
  canOpenDetailView,
  payPeriodType,
}: Props): JSX.Element {
  const classes = useStyles();
  const urlSearchParams = useURLSearchParams();
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();

  const showIcons =
    compStructure?.allowHourlyEmployees === true &&
    isEnabled(FeatureFlag.HourlyEmployees);
  const isHourly = payPeriodType === PayPeriodType.HOURLY;
  return (
    <TableCell role="gridcell" padding="none" className={className}>
      <div className={classes.root}>
        {canOpenDetailView === true ? (
          <AssembleLink
            to={`?${urlSearchParams
              .set("employee", employeeId.toString())
              .toString()}`}
            whitespace={false}
            color="textPrimary"
            underline="none"
          >
            <DisplayNameText name={displayName} />
          </AssembleLink>
        ) : (
          <DisplayNameText name={displayName} />
        )}
        <div className={classes.center}>
          {showIcons && !isHourly && <CalendarIcon />}
          {showIcons && isHourly && <ClockIcon />}
          <AssembleTruncatedTypography>
            {position ?? "Unleveled"}
          </AssembleTruncatedTypography>
        </div>
      </div>
    </TableCell>
  );
}

function DisplayNameText({ name }: { name: string }): JSX.Element {
  return (
    <AssembleTruncatedTypography variant="productExtraSmallSemiBold">
      {name}
    </AssembleTruncatedTypography>
  );
}
