import { gql } from "@apollo/client";
import { caseInsensitiveComparator } from "@asmbl/shared/sort";
import { Menu, MenuItem } from "@material-ui/core";
import cuid from "cuid";
import PopupState, {
  bindMenu,
  bindTrigger,
  InjectedProps,
} from "material-ui-popup-state";
import { useMemo, useState } from "react";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import {
  AdjustmentCriterion,
  CriterionOption,
  CriterionType,
} from "../../../models/AdjustmentCriterion";
import { ComputedLocationAdjustment } from "../../../models/ComputedLocationAdjustment";
import { arrayWithoutItem } from "../../../utils";
import {
  AdjustmentConditionCreator_compStructure as CompStructure,
  AdjustmentConditionCreator_departments as Department,
} from "../../../__generated__/graphql";
import { AdjustmentCriterionField } from "./AdjustmentCriterionField";

export type JobArchitectureCondition = {
  departments: number[];
  ladders: number[];
  levels: number[];
};

export type AdjustmentConditionCreatorProps = {
  departments: Department[];
  compStructure: CompStructure;
  onConditionChange: (condition: JobArchitectureCondition) => void;
  existingLocationAdjustment?: ComputedLocationAdjustment;
};

export function AdjustmentConditionCreator({
  departments,
  compStructure,
  onConditionChange,
  existingLocationAdjustment,
}: AdjustmentConditionCreatorProps): JSX.Element {
  const departmentOptions = useMemo<CriterionOption[]>(() => {
    return departments
      .map((d) => ({
        id: d.id,
        name: d.name,
      }))
      .sort((a, b) => caseInsensitiveComparator(a.name, b.name));
  }, [departments]);

  const ladderOptions = useMemo<CriterionOption[]>(() => {
    return departments
      .flatMap((d) => d.ladders)
      .map((d) => ({
        id: d.id,
        name: d.name,
      }))
      .sort((a, b) => caseInsensitiveComparator(a.name, b.name));
  }, [departments]);

  const levelOptions = useMemo<CriterionOption[]>(() => {
    return compStructure.levels
      .slice()
      .sort((a, b) => a - b)
      .map((l) => ({
        id: l,
        name: l.toString(),
      }));
  }, [compStructure]);

  const criterionOptionsMap = useMemo<Record<CriterionType, CriterionOption[]>>(
    () => ({
      Department: departmentOptions,
      Ladder: ladderOptions,
      Levels: levelOptions,
    }),
    [departmentOptions, ladderOptions, levelOptions]
  );

  const transformedCriteria = useMemo<AdjustmentCriterion[]>(
    () =>
      transformAdjustmentToCriteria(
        criterionOptionsMap,
        existingLocationAdjustment
      ),
    [criterionOptionsMap, existingLocationAdjustment]
  );

  const [criteria, setCriteria] =
    useState<AdjustmentCriterion[]>(transformedCriteria);

  const setCriteriaAndNotifyParent = (criteria: AdjustmentCriterion[]) => {
    setCriteria(criteria);
    onConditionChange(transformCriteriaToCondition(criteria));
  };

  const addCriterion = (type: CriterionType, popupState: InjectedProps) => {
    const criterion: AdjustmentCriterion = {
      id: cuid(),
      type,
      options: criterionOptionsMap[type],
    };
    setCriteriaAndNotifyParent([...criteria, criterion]);
    popupState.close();
  };

  const changeCriterion = (
    index: number,
    selectedOptions: CriterionOption[]
  ) => {
    const newCriteria = criteria.slice();
    const existingCriterion = newCriteria[index];
    const newCriterion: AdjustmentCriterion = {
      id: existingCriterion.id,
      type: existingCriterion.type,
      options: existingCriterion.options,
      selected: selectedOptions,
    };
    newCriteria[index] = newCriterion;
    setCriteriaAndNotifyParent(newCriteria);
  };

  const deleteCriterion = (index: number) => {
    const newCriteria = arrayWithoutItem(criteria, index);
    setCriteriaAndNotifyParent(newCriteria);
  };

  const levelCriteria = criteria.find((c) => c.type === "Levels");
  const containsLevel = !!levelCriteria;

  const departmentCriteria = criteria.find((c) => c.type === "Department");
  const containsDepartment = !!departmentCriteria;

  const ladderCriteria = criteria.find((c) => c.type === "Ladder");
  const containsLadder = !!ladderCriteria;

  return (
    <PopupState variant="popover">
      {(popupState) => (
        <>
          {criteria.map((criterion, index) => (
            <AdjustmentCriterionField
              key={criterion.id}
              criterion={criterion}
              onChange={(selectedOptions) =>
                changeCriterion(index, selectedOptions)
              }
              onDelete={() => deleteCriterion(index)}
            />
          ))}

          <AssembleButton
            variant="outlined"
            color="secondary"
            size="small"
            {...bindTrigger(popupState)}
            label="Add Criteria"
          />
          <Menu {...bindMenu(popupState)}>
            <MenuItem
              onClick={() => addCriterion("Department", popupState)}
              disabled={containsDepartment}
            >
              Department
            </MenuItem>

            <MenuItem
              onClick={() => addCriterion("Ladder", popupState)}
              disabled={containsLadder}
            >
              Ladder
            </MenuItem>
            <MenuItem
              onClick={() => addCriterion("Levels", popupState)}
              disabled={containsLevel}
            >
              Level
            </MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  );
}

AdjustmentConditionCreator.fragments = {
  compStructure: gql`
    fragment AdjustmentConditionCreator_compStructure on CompStructure {
      id
      levels
    }
  `,
  departments: gql`
    fragment AdjustmentConditionCreator_departments on Department {
      id
      name
      ladders {
        id
        name
      }
    }
  `,
};

function transformAdjustmentToCriteria(
  criterionOptionsMap: Record<CriterionType, CriterionOption[]>,
  locationAdjustment?: ComputedLocationAdjustment
): AdjustmentCriterion[] {
  if (!locationAdjustment) return [];
  const criteria: AdjustmentCriterion[] = [];

  if (locationAdjustment.departments.length > 0) {
    criteria.push({
      id: cuid(),
      type: "Department",
      options: criterionOptionsMap["Department"],
      selected: locationAdjustment.departments,
    });
  }

  if (locationAdjustment.ladders.length > 0) {
    criteria.push({
      id: cuid(),
      type: "Ladder",
      options: criterionOptionsMap["Ladder"],
      selected: locationAdjustment.ladders,
    });
  }

  if (locationAdjustment.levels.length > 0) {
    criteria.push({
      id: cuid(),
      type: "Levels",
      options: criterionOptionsMap["Levels"],
      selected: locationAdjustment.levels.map((level) => ({
        id: level,
        name: level.toString(),
      })),
    });
  }

  return criteria;
}

function transformCriteriaToCondition(
  criteria: AdjustmentCriterion[]
): JobArchitectureCondition {
  return {
    departments: getSelectedCriteriaIds(criteria, "Department"),
    ladders: getSelectedCriteriaIds(criteria, "Ladder"),
    levels: getSelectedCriteriaIds(criteria, "Levels"),
  };
}

function getSelectedCriteriaIds(
  criteria: AdjustmentCriterion[],
  type: CriterionType
): number[] {
  return criteria
    .filter((c) => c.type === type)
    .flatMap((c) => c.selected ?? [])
    .map((o) => o.id);
}
