import { mapMaybe } from "@asmbl/shared/utils";

export type LocationAdjustmentCondition = {
  departments: number[];
  ladders: number[];
  levels: number[];
  compComponents: string[];
};

export type LocationAdjustmentAdjustment = {
  value: number;
};

interface LocationAdjustment {
  id: number;
  description: string | null;
  condition: LocationAdjustmentCondition;
  adjustment: LocationAdjustmentAdjustment;
}

interface JobArchitectureRecord {
  id: number;
  name: string;
}

export class ComputedLocationAdjustment {
  readonly id: number;
  readonly adjustmentValue: number;
  readonly departments: JobArchitectureRecord[];
  readonly ladders: JobArchitectureRecord[];
  readonly levels: number[];
  readonly compComponents: string[];
  readonly description: string | null;

  constructor(
    { id, condition, adjustment, description }: LocationAdjustment,
    departmentIdMap: Map<number, string>,
    ladderIdMap: Map<number, string>
  ) {
    this.id = id;
    this.description = description;
    this.levels = condition.levels;
    this.compComponents = condition.compComponents;
    this.adjustmentValue = adjustment.value;
    this.departments = mapIdsToRecords(condition.departments, departmentIdMap);
    this.ladders = mapIdsToRecords(condition.ladders, ladderIdMap);
  }
}

function mapIdsToRecords(
  recordIds: number[],
  idNameMap: Map<number, string>
): JobArchitectureRecord[] {
  return mapMaybe(recordIds, (id) => {
    const name = idNameMap.get(id);
    return name !== undefined && name !== "" ? { id, name } : null;
  });
}
