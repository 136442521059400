import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const EyeOffIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Not Visible"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M4.5 3.75L19.5 20.25"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M20.1009 15.3361C21.7614 13.6617 22.5 12 22.5 12C22.5 12 19.5 5.24927 12 5.24927C11.6469 5.24927 11.3038 5.26376 10.9706 5.29274M6.93698 6.43065C3.11486 8.36617 1.5 12 1.5 12C1.5 12 4.5 18.7493 12 18.7493C13.7572 18.7633 15.4926 18.3585 17.0623 17.5685L6.93698 6.43065Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
