import { gql, useQuery } from "@apollo/client";
import { LinearProgress, makeStyles } from "@material-ui/core";
import { useEffect, useMemo } from "react";
import {
  FilterParam,
  getNumericListParam,
  getNumericOrNullListParam,
} from "src/models/FilterParams";
import { useURLSearchParams } from "src/models/URLSearchParams";
import { useUpdateEmployment } from "src/mutations/Employments";
import {
  Employee2,
  Employee2sMeta,
  MetaStatusEnum,
  PeopleQuery,
} from "../../__generated__/graphql";
import { useTrack } from "../../analytics";
import { theme } from "../../theme";
import {
  TableContextProvider,
  compTypeToExclusion,
  useReportsTableContext,
} from "./Context/TableContext";
import { PeopleTabParam } from "./PeopleTabParam";
import { PeopleTabSwitch } from "./PeopleTabSwitch";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 2),
    flex: 1,
  },
}));

function People(): JSX.Element {
  const classes = useStyles();
  const urlSearchParams = useURLSearchParams();

  const unsanitizedTabParam = urlSearchParams.get(
    FilterParam.TAB
  ) as PeopleTabParam;

  const tabParam = !Object.values(MetaStatusEnum).includes(
    unsanitizedTabParam as unknown as MetaStatusEnum
  )
    ? PeopleTabParam.ALL
    : unsanitizedTabParam;

  const {
    offset,
    limit,
    sortBy,
    setSortBy,
    setSortDir,
    sortDir,
    searchQuery,
    compType,
    setRefetch,
    setSearchQuery,
  } = useReportsTableContext();
  const urlSearchParamsString = urlSearchParams.toString();
  const reportsParam = useMemo(
    () =>
      (urlSearchParams.get(FilterParam.REPORTS) ?? "").includes("all") ||
      (urlSearchParams.get(FilterParam.REPORTS) ?? "").includes(",")
        ? undefined
        : urlSearchParams.get(FilterParam.REPORTS),
    [urlSearchParamsString]
  );
  const payTypeParam = useMemo(
    () =>
      (urlSearchParams.get(FilterParam.PAY_TYPE) ?? "").includes("all") ||
      (urlSearchParams.get(FilterParam.PAY_TYPE) ?? "").includes(",")
        ? undefined
        : urlSearchParams.get(FilterParam.PAY_TYPE),
    [urlSearchParamsString]
  );

  const managerParamList = useMemo(
    () => getNumericListParam(urlSearchParams, FilterParam.MANAGER),
    [urlSearchParamsString]
  );
  const departmentParam = useMemo(
    () => getNumericListParam(urlSearchParams, FilterParam.DEPARTMENT),
    [urlSearchParamsString]
  );
  const ladderParam = useMemo(
    () => getNumericListParam(urlSearchParams, FilterParam.LADDER),
    [urlSearchParamsString]
  );
  const levelParam = useMemo(
    () => getNumericOrNullListParam(urlSearchParams, FilterParam.LEVEL),
    [urlSearchParamsString]
  );
  const locationParam = useMemo(
    () => getNumericListParam(urlSearchParams, FilterParam.LOCATION),
    [urlSearchParamsString]
  );

  useEffect(() => {
    // Ensure no invalid sorting combinations
    if (
      sortBy === "jobTitle" &&
      tabParam !== PeopleTabParam.LEVELING &&
      tabParam !== PeopleTabParam.LEVELING_ISSUES
    ) {
      setSortBy("displayName");
      setSortDir("asc");
    }
  }, [sortBy, tabParam]);
  useEffect(() => {
    // Ensure leveling-issues tab doesn't get inapplicable filters
    if (tabParam === PeopleTabParam.LEVELING_ISSUES) {
      urlSearchParams.delete(FilterParam.LEVEL);
      urlSearchParams.delete(FilterParam.LADDER);
      urlSearchParams.delete(FilterParam.DEPARTMENT);
      urlSearchParams.delete(FilterParam.LOCATION);
      urlSearchParams.delete(FilterParam.MANAGER);
      urlSearchParams.delete(FilterParam.PAY_TYPE);
      setSortBy("displayName");
      setSortDir("asc");
      setSearchQuery("");
    }
  }, [tabParam, urlSearchParamsString]);

  const { data, loading, previousData, refetch } = useQuery<PeopleQuery>(
    People.query,
    {
      variables: {
        includeInactive: true,
        offset,
        limit,
        sort: {
          sortBy,
          sortDir,
        },
        filter: {
          displayName: searchQuery,
          metaStatus: tabParam,
          managerIds: managerParamList,
          reports: reportsParam,
          payType: payTypeParam,
          departmentIds: departmentParam,
          ladderIds: ladderParam,
          levelIds: levelParam,
          locationIds: locationParam,
          excludeCashComponents: compTypeToExclusion(compType),
        },
      },
    }
  );

  useEffect(() => {
    setRefetch(() => refetch);
  }, [refetch, setRefetch]);

  const { Track } = useTrack({
    area: "People",
  });

  const updateEmployment = useUpdateEmployment();

  const handleUpdateEmployment = async (
    employee: { activeEmployment: { id: number } | null },
    positionId: number | null
  ) => {
    const employment = employee.activeEmployment;
    if (employment === null) {
      return;
    }
    await updateEmployment({
      variables: {
        id: employment.id,
        data: { positionId },
      },
      optimisticResponse: {
        updateOneEmployment: {
          id: employment.id,
          __typename: "Employment",
          positionId,
        },
      },
      refetchQueries: [
        {
          query: People.query,
          variables: {
            includeInactive: true,
          },
        },
      ],
    });
  };

  if (loading && !previousData) return <LinearProgress />;
  if (!data && !previousData) return <>Missing position or employee data.</>;

  // Ensures that there's always usable data while loading the next query
  const relevantData = data ?? previousData;
  const employees = (relevantData?.employee2s.employees ??
    []) as unknown as Employee2[];
  return (
    <Track>
      <div className={classes.root}>
        <PeopleTabSwitch
          positions={relevantData?.positions ?? []}
          employees={employees}
          totalCount={relevantData?.employee2s.meta.counts.filteredTotal ?? 0}
          onLevelCallback={handleUpdateEmployment}
          isLoading={loading}
          metastatusCount={
            relevantData?.employee2s.meta.counts ?? {
              ALL: 0,
              ACTIVE: 0,
              INACTIVE: 0,
              LEVELING: 0,
              LEVELING_ISSUES: 0,
            }
          }
          dropdownOptions={
            (relevantData?.employee2s.meta ?? {}) as Omit<
              Employee2sMeta,
              "counts"
            >
          }
        />
      </div>
    </Track>
  );
}

People.query = gql`
  query PeopleQuery(
    $includeInactive: Boolean
    $offset: Int
    $limit: Int
    $sort: Employee2sSort
    $filter: Employee2sFilter
  ) {
    employee2s(
      includeInactive: $includeInactive
      offset: $offset
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      meta {
        counts {
          ALL
          ACTIVE
          INACTIVE
          LEVELING
          LEVELING_ISSUES
          filteredTotal
        }
        managers {
          id
          displayName
        }
        departments {
          id
          name
          organizationId
        }
        ladders {
          id
          name
        }
        locations {
          id
          name
        }
        levels
      }
      employees {
        id
        displayName
        activeAt
        employmentStatus
        activeCashCompensation {
          activeAt
          employeeId
          type
          annualCashEquivalent
          hourlyCashEquivalent
          unit
          percentOfSalary
        }
        adjustedCashBands {
          id
          name
          bandPoints {
            name
            annualCashEquivalent
            value {
              ... on CashValue {
                annualRate
                hourlyRate
                currencyCode
              }
            }
          }
        }
        equityHoldings {
          id
          grants {
            id
            name
            issueDate
            vestingStartDate
            vestingScheduleDescription
            unitCount
            price
            equityType
            optionType
            awardType
          }
          totalUnitCount
          totalGrossHoldingValue
        }
        activeEmployment {
          id
          positionId
          salary
          jobTitle
          levelingCode
          levelingMethod
          payPeriod
          position {
            id
            name
            level
            ladder {
              id
              name
              department {
                id
                name
              }
            }
          }
        }
        employments {
          id
          positionId
        }
        user {
          id
          photoURL
        }
        manager: minimalManager {
          id
          displayName
          user {
            id
            photoURL
          }
        }
        location {
          id
          name
        }
      }
    }

    positions {
      id
      name
      level
      type
      ladder {
        id
        name
        department {
          id
          name
        }
      }
      jobCodes
    }
  }
`;

export default function PeopleWithPagination() {
  return (
    <TableContextProvider>
      <People />
    </TableContextProvider>
  );
}
