import { gql } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { Money } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { formatNumeral } from "@asmbl/shared/utils";
import {
  CompUnit,
  CondensedTable_meritAdjustmentGuide as MeritAdjustmentGuide,
  CondensedTable_meritAdjustmentRange as MeritAdjustmentRange,
  CondensedTableSalaryMeritDifferenceCell2_participant as Participant,
  CondensedTable_perfRatingOption as PerfRatingOption,
  RecItemType,
} from "src/__generated__/graphql";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { getSalaryCashComp } from "src/models/CashCompensation";
import { getGuidance } from "src/models/MeritGuidance";
import { RED } from "src/theme";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getMeritSalaryIncrease } from "../../../../models/CompRecommendation";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { DefaultCell } from "./DefaultCell";

type Props = {
  row: { original: Participant };
};

export function CondensedTableSalaryMeritDifferenceCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const showHourly =
    (isEnabled(FeatureFlag.HourlyEmployeesInCompCycles) &&
      compStructure?.allowHourlyEmployees) === true;
  const tableData = useTableData();
  const guidance = getGuidance(
    {
      ...employee.subject,
      perfRating: employee.perfRating,
    },
    tableData.meritGuidance.meritAdjustmentGuides,
    tableData.meritGuidance.meritAdjustmentRanges,
    tableData.meritGuidance.perfRatingOptions
  );

  const pay = getSalaryCashComp(employee.subject.activeCashCompensation);

  if (!pay) return <DefaultCell />;

  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];
  const meritItem = recItems.find(
    (item) => item.recommendationType === RecItemType.MERIT_INCREASE
  );
  const isHourly = showHourly && meritItem?.unitType === CompUnit.HOURLY_CASH;

  const percent =
    meritItem?.unitType === CompUnit.PERCENT_OF_SALARY
      ? meritItem.recommendedPercentValue
      : calculatePercent(
          isHourly ? pay.hourlyCashEquivalent : pay.annualCashEquivalent,
          getMeritSalaryIncrease(recItems, pay.annualCashEquivalent.currency)
            .value
        );

  const difference = guidance != null ? (percent ?? 0) - guidance * 100 : null;
  return (
    <div>
      {guidance != null && difference !== null ? (
        <AssembleTruncatedTypography
          textColor={difference === 0 ? "inherit" : RED}
          align="right"
        >
          {difference >= 0 ? "+" : ""}
          {formatNumeral(difference / 100, {
            style: "percent",
            maximumFractionDigits: 2,
          })}
        </AssembleTruncatedTypography>
      ) : (
        <DefaultCell />
      )}
    </div>
  );
}

CondensedTableSalaryMeritDifferenceCell2.fragments = {
  participant: gql`
    fragment CondensedTableSalaryMeritDifferenceCell2_participant on CompCycleParticipant {
      subject {
        id
        activeCashCompensation(currencyCode: $currencyCode) {
          type
          annualCashEquivalent
          hourlyCashEquivalent
          unit
          employeeId
        }
        adjustedCashBands(currencyCode: $currencyCode) {
          id
          name
          bandPoints {
            id
            bandName
            name
            value {
              ... on CashValue {
                annualRate
                hourlyRate
                currencyCode
              }
            }
          }
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedPercentValue
          recommendedCashValue(currencyCode: $currencyCode)
          recommendedPercentValue
          unitType
        }
      }
      perfRating
    }
  `,
};

CondensedTableSalaryMeritDifferenceCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.SALARY_MERIT_GUIDANCE_DIFF
);
CondensedTableSalaryMeritDifferenceCell2.id =
  ColumnIds.SALARY_MERIT_GUIDANCE_DIFF;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableSalaryMeritDifferenceCell2,
  Header: CondensedTableSalaryMeritDifferenceCell2.Header,
  id: CondensedTableSalaryMeritDifferenceCell2.id,
  defaultCanSort: false,
};

CondensedTableSalaryMeritDifferenceCell2.column = column;
CondensedTableSalaryMeritDifferenceCell2.ordering = ({
  meritGuidance,
}: {
  meritGuidance: {
    meritAdjustmentGuides: MeritAdjustmentGuide[];
    meritAdjustmentRanges: MeritAdjustmentRange[];
    perfRatingOptions: PerfRatingOption[];
  };
}) =>
  contramap((e: Participant) => {
    const guidance = getGuidance(
      {
        ...e.subject,
        perfRating: e.perfRating,
      },
      meritGuidance.meritAdjustmentGuides,
      meritGuidance.meritAdjustmentRanges,
      meritGuidance.perfRatingOptions
    );

    const { defaultCurrencyCode } = useCurrencies();
    const recItems = e.compRecommendation?.latestSubmittedItems ?? [];
    const meritItem = recItems.find(
      (item) => item.recommendationType === RecItemType.MERIT_INCREASE
    );
    const currentPay = getSalaryCashComp(e.subject.activeCashCompensation);
    const pay =
      meritItem?.unitType === CompUnit.HOURLY_CASH
        ? currentPay?.hourlyCashEquivalent
        : currentPay?.annualCashEquivalent;

    const percent =
      meritItem?.unitType === CompUnit.PERCENT_OF_SALARY
        ? meritItem.recommendedPercentValue
        : calculatePercent(
            pay,
            getMeritSalaryIncrease(
              recItems,
              pay?.currency ?? defaultCurrencyCode
            ).value
          );

    const difference =
      guidance != null ? (percent ?? 0) - guidance * 100 : null;
    return difference ?? 0;
  });

function calculatePercent(
  baseSalary?: Money | null,
  absolute?: number
): number {
  if (baseSalary == null || absolute == null) return 0;
  return baseSalary.value !== 0
    ? Math.round((absolute / baseSalary.value) * 10000) / 100
    : 0;
}
