import { useQuery } from "@apollo/client";
import { TableCell, TableRow } from "@material-ui/core";
import { GetNeedsMappingCounts } from "../../../__generated__/graphql";
import { GET_NEEDS_MAPPING_COUNTS } from "../../../queries";
import { AssembleLink } from "../../AssembleLink";
import { InfoBanner } from "../../InfoBanner";

export function DataMappingBanner(): JSX.Element | null {
  const { data } = useQuery<GetNeedsMappingCounts>(GET_NEEDS_MAPPING_COUNTS);

  const employeesNeedLeveling = (data?.countEmployeesNeedingLeveling ?? 0) > 0;
  const locationsNeedMapping = (data?.countLocationsNeedingMapping ?? 0) > 0;

  if (!employeesNeedLeveling && !locationsNeedMapping) {
    return null;
  }

  const both = employeesNeedLeveling && locationsNeedMapping;

  const locationLink = locationsNeedMapping && (
    <AssembleLink to="/settings/markets#locations" variant="body2">
      Map locations to Location Groups
    </AssembleLink>
  );
  const employeeLink = employeesNeedLeveling && (
    <AssembleLink to="/people" variant="body2">
      Map job titles to Assemble Positions
    </AssembleLink>
  );
  return (
    <TableRow>
      <TableCell colSpan={6}>
        <InfoBanner noMargin>
          You have data that requires mapping.
          {locationLink}
          {both && " | "}
          {employeeLink}
        </InfoBanner>
      </TableCell>
    </TableRow>
  );
}
