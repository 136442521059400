import { FeatureTextFlag } from "@asmbl/shared/feature-flags";
import { Dialog, DialogContent, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { AssembleButton } from "../AssembleButton/AssembleButton";
import { useFeatureFlags } from "../FeatureContext";

const useStyles = makeStyles((theme) => ({
  jobCodeButton: { flexShrink: 0 },
  dialogContent: {
    padding: theme.spacing(1),
    height: "calc(100vh - 200px)", // Adjust for close button + intercom button
    overflow: "hidden",
  },
  closeButton: {
    position: "fixed",
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  iframe: { border: "none" },
}));

type JobCodeModalProps = {
  url: string;
  open: boolean;
  onClose(): unknown;
};

function JobCodeModal({ url, open, onClose }: JobCodeModalProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="lg" onClose={onClose}>
        <DialogContent className={classes.dialogContent}>
          <iframe
            className={classes.iframe}
            src={url}
            height="100%"
            width="100%"
          />
        </DialogContent>
        <AssembleButton
          className={classes.closeButton}
          size="medium"
          variant="outlined"
          onClick={onClose}
          label="Close"
        />
      </Dialog>
    </>
  );
}

export function JobCodeModalButton(): JSX.Element {
  const classes = useStyles();
  const features = useFeatureFlags();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleOpen = () => {
    setIsEditModalOpen(true);
  };

  const handleClose = () => {
    setIsEditModalOpen(false);
  };

  const jobCodeModalUrl = features.getTextValue(
    FeatureTextFlag.OfferApprovalJobCodeModal
  );

  if (jobCodeModalUrl === undefined) {
    return <></>;
  }

  return (
    <>
      <AssembleButton
        className={classes.jobCodeButton}
        variant="outlined"
        size="large"
        onClick={handleOpen}
        label="Generate Job Code"
      />
      <JobCodeModal
        url={jobCodeModalUrl}
        open={isEditModalOpen}
        onClose={handleClose}
      />
    </>
  );
}
