import { useQuery } from "@apollo/client";
import { Noun } from "@asmbl/shared/permissions";
import { byDate } from "@asmbl/shared/sort";
import { partition } from "@asmbl/shared/utils";
import { LinearProgress } from "@material-ui/core";
import { useTrack } from "../../../analytics";
import { useAuth } from "../../../components/Auth/AuthContext";
import { CompCycleNoCycles } from "../../../components/EmptyState/EmptyState";
import { PageHeader, PageTitle } from "../../../components/Layout/PageHeader";
import { GET_COMP_CYCLES } from "../../../queries";
import { GetCompCycles } from "../../../__generated__/graphql";
import { CompCycleList } from "./CompCycleList";

export function CompCyclesLoadingBoundary(): JSX.Element {
  const { Track } = useTrack({ subArea: "Comp Cycle List" });
  const { permissions } = useAuth();
  const onlyManagerAccess =
    permissions.isManager() && !permissions.canViewGlobal(Noun.CompCycle);

  const { data, loading } = useQuery<GetCompCycles>(GET_COMP_CYCLES);

  if (loading) {
    return <LinearProgress />;
  }

  const cycles = data?.compCycles ?? [];

  const [active, ended] = partition(cycles, (c) => c.endedAt === null);

  // Display the active cycles first (reverse-chronological) and then the
  // completed cycles (also reverse-chronological).
  const compCycles = active
    .slice()
    .sort(byDate((c) => c.createdAt))
    .reverse()
    .concat(
      ended
        .slice()
        .sort(byDate((c) => c.createdAt))
        .reverse()
    );

  return cycles.length === 0 && onlyManagerAccess ? (
    <>
      <PageHeader>
        <PageTitle>Comp Cycles</PageTitle>
      </PageHeader>
      <CompCycleNoCycles />
    </>
  ) : (
    <Track>
      <CompCycleList
        compCycles={compCycles}
        onlyManagerAccess={onlyManagerAccess}
      />
    </Track>
  );
}
