import { WithRequired } from "@asmbl/shared/types";
import { Button, ButtonProps, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { forwardRef, Ref } from "react";
import {
  GRAY_1,
  GRAY_2,
  GRAY_5,
  GRAY_7,
  GREEN_2,
  GREEN_3,
  OUTLINE_BOX_SHADOW,
  PRIMARY_BOX_SHADOW,
  PURPLE_1,
  PURPLE_2,
  RED,
  RED_2,
  RED_3,
  RED_4,
  RED_5,
  WHITE,
} from "../../theme";
import {
  AssembleTypography,
  AssembleTypographyProps,
} from "../AssembleTypography";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "max-content",
    height: theme.spacing(5),
  },

  onlyIcon: {
    height: `${theme.spacing(4)}px !important`,
    width: `${theme.spacing(4)}px !important`,

    "& .MuiButton-endIcon": {
      marginLeft: "-2px",

      "& > span > img": {
        width: "18px",
        height: "18px",
      },
    },

    "& .MuiButton-startIcon": {
      marginRight: "-2px",
      "& > span > img": {
        width: "18px",
        height: "18px",
      },
    },
  },

  contained: {
    color: WHITE,
    backgroundColor: PURPLE_1,
    boxShadow: PRIMARY_BOX_SHADOW,
    borderColor: PURPLE_1,

    "&:hover": {
      color: WHITE,
      backgroundColor: PURPLE_2,
      borderColor: PURPLE_1,
      boxShadow: PRIMARY_BOX_SHADOW,
    },

    "&:active": {
      color: WHITE,
      backgroundColor: PURPLE_2,
      boxShadow: `${OUTLINE_BOX_SHADOW} !important`,
    },

    "&:focus": {
      color: WHITE,
      backgroundColor: PURPLE_1,
      border: `1px solid ${PURPLE_1}`,
      boxShadow: `${OUTLINE_BOX_SHADOW} !important`,
    },
  },

  "contained.danger": {
    color: WHITE,
    border: `1px solid transparent`,
    background: `linear-gradient(145.58deg, ${RED} 28.94%, ${RED_2} 87.8%)`,
    boxShadow:
      "0px 1px 1px rgba(217, 29, 74, 0.1), 0px 1px 3px rgba(217, 29, 74, 0.2)",

    "&:hover": {
      color: WHITE,
      border: `1px solid transparent !important`,
      backgroundColor: RED_3,
      boxShadow:
        "0px 1px 1px rgba(217, 29, 74, 0.1), 0px 1px 3px rgba(217, 29, 74, 0.2)",
    },

    "&:active": {
      color: WHITE,
      border: `1px solid transparent !important`,
      backgroundColor: RED_4,
      boxShadow: "0px 0px 0px 3px rgba(255, 56, 92, 0.25) !important",
    },

    "&:focus": {
      color: WHITE,
      border: `1px solid transparent !important`,
      backgroundColor: RED_5,
      boxShadow: "0px 0px 0px 3px rgba(255, 56, 92, 0.25) !important",
    },
  },

  "contained.success": {
    color: WHITE,
    border: `1px solid transparent`,
    background: `linear-gradient(148.74deg, ${GREEN_2} 27.64%, ${GREEN_3} 87.25%)`,
    boxShadow:
      "0px 1px 1px rgba(10, 36, 64, 0.05), 0px 1px 3px rgba(10, 36, 64, 0.1);",

    "&:hover, &:active, &:focus": {
      color: WHITE,
      border: `1px solid transparent`,
      background: `linear-gradient(148.74deg, ${GREEN_2} 27.64%, ${GREEN_3} 87.25%) !important`,
      boxShadow:
        "0px 1px 1px rgba(10, 36, 64, 0.05), 0px 1px 3px rgba(10, 36, 64, 0.1);",
    },
  },

  "contained.working": {
    color: WHITE,
    border: `1px solid transparent`,
    background: GRAY_1,
    boxShadow: "none",

    "&:hover, &:active, &:focus": {
      color: WHITE,
      border: `1px solid transparent`,
      background: `${GRAY_1} !important`,
      boxShadow: "none",
    },
  },

  outlined: {
    color: GRAY_2,
    backgroundColor: WHITE,
    border: `1px solid ${GRAY_5}`,
    boxShadow:
      "0px 1px 1px rgba(10, 36, 64, 0.05), 0px 1px 3px rgba(10, 36, 64, 0.1);",

    "&:hover": {
      color: PURPLE_1,
      backgroundColor: WHITE,
      border: `1px solid ${PURPLE_1}`,
      boxShadow:
        "0px 1px 1px rgba(10, 36, 64, 0.05), 0px 1px 3px rgba(10, 36, 64, 0.1);",
    },

    "&:active": {
      color: PURPLE_1,
      backgroundColor: GRAY_7,
      border: `1px solid ${PURPLE_1}`,
      boxShadow: OUTLINE_BOX_SHADOW,
    },

    "&:focus": {
      color: PURPLE_1,
      backgroundColor: WHITE,
      border: `1px solid ${PURPLE_1}`,
      boxShadow: OUTLINE_BOX_SHADOW,
    },

    "&.Mui-disabled": {
      border: `1px solid ${GRAY_5}`,
      backgroundColor: WHITE,
    },
  },

  text: {
    color: PURPLE_2,
    border: `1px solid transparent`,

    "&:hover": {
      color: PURPLE_2,
      border: `1px solid ${PURPLE_1}`,
      boxShadow:
        "0px 1px 1px rgba(10, 36, 64, 0.05), 0px 1px 3px rgba(10, 36, 64, 0.1);",
    },

    "&.MuiButton-textSizeSmall": {
      color: PURPLE_2,
      padding: "4px 10px",
    },

    "&.MuiButton-textSizeLarge": {
      color: PURPLE_2,
      padding: "8px 22px",
    },

    "&:active": {
      color: PURPLE_2,
      // purple with 10% opacity
      backgroundColor: `${PURPLE_1}1A`,
      border: `1px solid ${PURPLE_1}`,
      boxShadow: OUTLINE_BOX_SHADOW,
    },

    "&:focus": {
      color: PURPLE_2,
      // purple with 1% opacity
      backgroundColor: `${PURPLE_1}03`,
      border: `1px solid ${PURPLE_1}`,
      boxShadow: OUTLINE_BOX_SHADOW,
    },

    "&.Mui-disabled": {
      border: `1px solid ${GRAY_5}`,
    },
  },

  sizeSmall: { height: theme.spacing(4) },
  sizeLarge: { height: theme.spacing(6) },
}));

export type AssembleButtonVariant =
  | "contained"
  | "outlined"
  | "text"
  | "danger"
  | "success"
  | "working";

export type AssembleButtonProps = WithRequired<
  Omit<ButtonProps, "variant" | "endIcon" | "startIcon">,
  "size"
> & {
  label?: string | JSX.Element;
  variant: AssembleButtonVariant;
  endIcon?: JSX.Element;
  startIcon?: JSX.Element;
};

export const AssembleButton = forwardRef(function AssembleButton(
  {
    label,
    size = "medium",
    variant = "contained",
    disabled = false,
    endIcon,
    startIcon,
    ...rest
  }: AssembleButtonProps,
  ref: Ref<HTMLButtonElement>
) {
  const classes = useStyles();

  const muiVariant =
    variant === "danger" || variant === "success" || variant === "working"
      ? "contained"
      : variant;

  return (
    <Button
      ref={ref}
      className={clsx({
        [classes["contained.danger"]]: variant === "danger",
        [classes["contained.success"]]: variant === "success",
        [classes["contained.working"]]: variant === "working",
      })}
      classes={{
        root: clsx(classes.root, {
          [classes.onlyIcon]: label == null && size === "small",
        }),
        contained: classes.contained,
        outlined: classes.outlined,
        text: classes.text,
        sizeSmall: classes.sizeSmall,
        sizeLarge: classes.sizeLarge,
      }}
      size={size}
      disabled={disabled}
      variant={muiVariant}
      endIcon={endIcon}
      startIcon={startIcon}
      {...rest}
    >
      {label != null && (
        <AssembleTypography {...getTypographyProps({ size, variant })}>
          {label}
        </AssembleTypography>
      )}
    </Button>
  );
});

export function getIconProps({ size }: Pick<AssembleButtonProps, "size">): {
  width: string;
  height: string;
  inherit: boolean;
} {
  let width: string;
  let height: string;

  switch (size) {
    case "small":
      width = "16px";
      height = "16px";
      break;
    case "medium":
      width = "16px";
      height = "16px";
      break;
    case "large":
      width = "24px";
      height = "24px";
      break;
    default:
      width = "16px";
      height = "16px";
  }

  return {
    width,
    height,
    inherit: true,
  };
}

export function getTypographyProps({
  size,
  variant,
}: Pick<AssembleButtonProps, "size" | "variant">): Pick<
  AssembleTypographyProps,
  "variant" | "textColor"
> {
  let typographyVariant: AssembleTypographyProps["variant"];
  let typographyColor: AssembleTypographyProps["textColor"] | undefined =
    undefined;

  switch (size) {
    case "small":
      typographyVariant = "productButtonSmall";
      break;
    case "medium":
      typographyVariant = "productButtonMedium";
      break;
    case "large":
      typographyVariant = "productButtonLarge";
      break;
    default:
      typographyVariant = "productButtonMedium";
  }

  switch (variant) {
    case "contained":
      typographyColor = WHITE;
      break;
    case "text":
      typographyColor = PURPLE_1;
      break;
    case "danger":
      typographyColor = WHITE;
      break;
    case "success":
      typographyColor = WHITE;
      break;
    case "working":
      typographyColor = WHITE;
      break;
  }

  return {
    variant: typographyVariant,
    textColor: typographyColor,
  };
}
