import { BlockQuoteIcon } from "src/components/AssembleIcons/Brand/BlockQuoteIcon";
import { StyledBlockButton } from "../StyledBlockButton/StyledBlockButton";

const BLOCKQUOTE = "blockquote";

export type BlockquoteBlockProps = {
  editorState: Draft.EditorState;
  onClick: () => void;
};

export const BlockquoteBlock = (props: BlockquoteBlockProps): JSX.Element => {
  const { editorState, onClick } = props;

  const startKey = editorState.getSelection().getStartKey();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(startKey)
    .getType();

  const active = blockType === BLOCKQUOTE;

  return (
    <StyledBlockButton active={active} onClick={onClick}>
      <BlockQuoteIcon width="24px" height="24px" inherit />
    </StyledBlockButton>
  );
};
