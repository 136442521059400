import { Button, makeStyles } from "@material-ui/core";
import { AssembleTypography } from "src/components/AssembleTypography";

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  header: {
    fontSize: "1.5rem",
    marginBottom: theme.spacing(1.5),
  },
  body: {
    marginBottom: theme.spacing(1.5),
  },
  bold: {
    fontWeight: 700,
  },
}));

type Props = {
  onClose: () => unknown;
  onNext: () => unknown;
};

export function UploadModalFirstPage({ onClose, onNext }: Props) {
  const classes = useStyles();
  return (
    <div>
      <AssembleTypography className={classes.header} variant="productHeader">
        Upload a Market Dataset
      </AssembleTypography>
      <AssembleTypography
        className={classes.body}
        variant="productParagraphLarge"
      >
        Compare your compensation to the market dataset you upload.
      </AssembleTypography>
      <AssembleTypography
        className={classes.bold}
        variant="productParagraphLarge"
      >
        An important note:
      </AssembleTypography>
      <AssembleTypography variant="productParagraphLarge">
        Make sure your job codes are included in your import - we won't know
        which positions in Assemble to map this market data to without them.
      </AssembleTypography>
      <div className={classes.buttonRow}>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onNext}>
          Choose Dataset
        </Button>
      </div>
    </div>
  );
}
