export enum Verb {
  View = "view",
  Edit = "edit",
}

export enum Noun {
  AccessControl = "AccessControl",
  CashBand = "CashBand",
  CompCycle = "CompCycle",
  CompCycleBudget = "CompCycleBudget",
  CompRecommendation = "CompRecommendation",
  CompStructure = "CompStructure",
  Employee = "Employee",
  EmployeeCashCompensation = "EmployeeCashCompensation",
  EmployeeEquityCompensation = "EmployeeEquityCompensation",
  EquityBand = "EquityBand",
  Integration = "Integration",
  JobStructure = "JobStructure",
  Market = "Market",
  MarketData = "MarketData",
  Offer = "Offer",
  Philosophy = "Philosophy",
  SensitiveData = "SensitiveData",
}

export const NounDisplay: Record<Noun, string> = {
  [Noun.AccessControl]: "Access Control",
  [Noun.CashBand]: "Cash Band",
  [Noun.CompCycleBudget]: "Comp Cycle Budget",
  [Noun.CompCycle]: "Compensation Cycle",
  [Noun.CompRecommendation]: "Compensation Recommendation",
  [Noun.CompStructure]: "Compensation Structure",
  [Noun.EmployeeCashCompensation]: "Employee Cash Compensation",
  [Noun.EmployeeEquityCompensation]: "Employee Equity Compensation",
  [Noun.Employee]: "Employee",
  [Noun.EquityBand]: "Equity Band",
  [Noun.Integration]: "Integration",
  [Noun.JobStructure]: "Job Structure",
  [Noun.Market]: "Market",
  [Noun.MarketData]: "Market Data",
  [Noun.Offer]: "Offer",
  [Noun.Philosophy]: "Philosophy",
  [Noun.SensitiveData]: "Sensitive Data",
};

/**
 * @deprecated Use PrimaryRoleName instead in Permissions V2.
 */
export enum RoleName {
  FullAccess = "Full Access",
  SystemAdmin = "System Admin",
  Recruiter = "Recruiter",
  CompensationBandViewer = "Compensation Band Viewer",
  BasicViewer = "Basic Viewer",
  Manager = "Manager",
  Employee = "Employee",
}

export type Role = {
  name: string;
  permissions: PermissionMap;
};

export type PermissionMap = {
  [key in Verb]: Noun[];
};

export type NewGrant = { roleName: PrimaryRoleName; nounScopes: NounScopes };

export enum PrimaryRoleName {
  FULL_ACCESS = "FULL_ACCESS",
  SYSTEM_ADMIN = "SYSTEM_ADMIN",
  RECRUITER = "RECRUITER",
  BASIC_VIEWER = "BASIC_VIEWER",
  EMPLOYEE = "EMPLOYEE",
  HRBP = "HRBP",
}

export const AvailablePrimaryRoleNames: string[] = Object.keys(PrimaryRoleName);

export type BaseScope = {
  global: boolean;
} | null;

export type JobStructureScopeBase = {
  departmentIDs: number[];
  ladderIDs: number[];
  positionIDs: number[];
};

export type JobStructureScope = (BaseScope & JobStructureScopeBase) | null;

/**
 * This scope controls access to Band information, which is related to both a
 * Position in the job structure and a particular Market. It is composed of a
 * portion of the job structure you can see in all markets, and (many) portions
 * of the job structure that you can see in a particular individual market.
 *
 * @example A fully global scope - { allMarkets: { global: true }, markets: {} }
 */
export type MarketJobStructureScope = {
  allMarkets: JobStructureScope;
  markets: { [id: number]: JobStructureScope };
} | null;

export type EmployeeScope =
  | (BaseScope & {
      directReportIDs: number[];
      indirectReportIDs: number[];
      supportingManagerEmployeeIDs: number[];
    })
  | null;

export type OfferScope =
  | (BaseScope & {
      authored: boolean;
    })
  | null;

export type NounScopesUnion =
  | BaseScope
  | JobStructureScope
  | EmployeeScope
  | OfferScope;

export type NounScopes = {
  readonly [Noun.JobStructure]: {
    readonly [Verb.Edit]: BaseScope;
    readonly [Verb.View]: JobStructureScope;
  };
  readonly [Noun.CompStructure]: {
    readonly [Verb.Edit]: BaseScope;
    readonly [Verb.View]: BaseScope;
  };
  readonly [Noun.AccessControl]: {
    readonly [Verb.Edit]: BaseScope;
    readonly [Verb.View]: BaseScope;
  };
  readonly [Noun.CashBand]: {
    readonly [Verb.Edit]: BaseScope;
    readonly [Verb.View]: MarketJobStructureScope;
  };
  readonly [Noun.CompCycle]: {
    readonly [Verb.Edit]: BaseScope;
    readonly [Verb.View]: BaseScope;
  };
  readonly [Noun.CompCycleBudget]: {
    readonly [Verb.Edit]: BaseScope;
    readonly [Verb.View]: BaseScope;
  };
  readonly [Noun.CompRecommendation]: {
    readonly [Verb.Edit]: EmployeeScope;
    readonly [Verb.View]: EmployeeScope;
  };
  readonly [Noun.Employee]: {
    readonly [Verb.Edit]: EmployeeScope;
    readonly [Verb.View]: EmployeeScope;
  };
  readonly [Noun.EmployeeCashCompensation]: {
    readonly [Verb.Edit]: EmployeeScope;
    readonly [Verb.View]: EmployeeScope;
  };
  readonly [Noun.EmployeeEquityCompensation]: {
    readonly [Verb.Edit]: EmployeeScope;
    readonly [Verb.View]: EmployeeScope;
  };
  readonly [Noun.EquityBand]: {
    readonly [Verb.Edit]: BaseScope;
    readonly [Verb.View]: MarketJobStructureScope;
  };
  readonly [Noun.Integration]: {
    readonly [Verb.Edit]: BaseScope;
    readonly [Verb.View]: BaseScope;
  };
  readonly [Noun.Market]: {
    readonly [Verb.Edit]: BaseScope;
    readonly [Verb.View]: BaseScope;
  };
  readonly [Noun.MarketData]: {
    readonly [Verb.Edit]: BaseScope;
    readonly [Verb.View]: BaseScope;
  };
  readonly [Noun.Offer]: {
    readonly [Verb.Edit]: OfferScope;
    readonly [Verb.View]: OfferScope;
  };
  readonly [Noun.Philosophy]: {
    readonly [Verb.Edit]: BaseScope;
    readonly [Verb.View]: BaseScope;
  };
  readonly [Noun.SensitiveData]: {
    readonly [Verb.Edit]: null;
    readonly [Verb.View]: BaseScope;
  };
};

export const AvailableRoleScopes: Record<
  PrimaryRoleName,
  Readonly<NounScopes>
> = {
  [PrimaryRoleName.BASIC_VIEWER]: {
    [Noun.CompStructure]: {
      [Verb.Edit]: null,
      [Verb.View]: {
        global: true,
      },
    },
    [Noun.JobStructure]: {
      [Verb.Edit]: null,
      [Verb.View]: {
        global: true,
        departmentIDs: [],
        ladderIDs: [],
        positionIDs: [],
      },
    },
    [Noun.Philosophy]: {
      [Verb.Edit]: null,
      [Verb.View]: {
        global: true,
      },
    },

    [Noun.AccessControl]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CashBand]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CompCycle]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CompCycleBudget]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CompRecommendation]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Employee]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.EmployeeCashCompensation]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.EmployeeEquityCompensation]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.EquityBand]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Integration]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Offer]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Market]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.MarketData]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.SensitiveData]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
  },
  [PrimaryRoleName.HRBP]: {
    [Noun.CompStructure]: {
      [Verb.Edit]: null,
      [Verb.View]: {
        global: true,
      },
    },
    [Noun.JobStructure]: {
      [Verb.Edit]: null,
      [Verb.View]: {
        global: true,
        departmentIDs: [],
        ladderIDs: [],
        positionIDs: [],
      },
    },
    [Noun.Philosophy]: {
      [Verb.Edit]: null,
      [Verb.View]: {
        global: true,
      },
    },

    [Noun.AccessControl]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CashBand]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CompCycle]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CompCycleBudget]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CompRecommendation]: {
      [Verb.Edit]: {
        global: false,
        directReportIDs: [],
        indirectReportIDs: [],
        supportingManagerEmployeeIDs: [],
      },
      [Verb.View]: {
        global: false,
        directReportIDs: [],
        indirectReportIDs: [],
        supportingManagerEmployeeIDs: [],
      },
    },
    [Noun.Employee]: {
      [Verb.Edit]: {
        global: false,
        directReportIDs: [],
        indirectReportIDs: [],
        supportingManagerEmployeeIDs: [],
      },
      [Verb.View]: {
        global: false,
        directReportIDs: [],
        indirectReportIDs: [],
        supportingManagerEmployeeIDs: [],
      },
    },
    [Noun.EmployeeCashCompensation]: {
      [Verb.Edit]: {
        global: false,
        directReportIDs: [],
        indirectReportIDs: [],
        supportingManagerEmployeeIDs: [],
      },
      [Verb.View]: {
        global: false,
        directReportIDs: [],
        indirectReportIDs: [],
        supportingManagerEmployeeIDs: [],
      },
    },
    [Noun.EmployeeEquityCompensation]: {
      [Verb.Edit]: {
        global: false,
        directReportIDs: [],
        indirectReportIDs: [],
        supportingManagerEmployeeIDs: [],
      },
      [Verb.View]: {
        global: false,
        directReportIDs: [],
        indirectReportIDs: [],
        supportingManagerEmployeeIDs: [],
      },
    },
    [Noun.EquityBand]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Integration]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Offer]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Market]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.MarketData]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.SensitiveData]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
  },
  [PrimaryRoleName.FULL_ACCESS]: {
    [Noun.AccessControl]: {
      [Verb.Edit]: {
        global: true,
      },
      [Verb.View]: {
        global: true,
      },
    },
    [Noun.CashBand]: {
      [Verb.Edit]: { global: true },
      [Verb.View]: {
        allMarkets: {
          global: true,
          departmentIDs: [],
          ladderIDs: [],
          positionIDs: [],
        },
        markets: {},
      },
    },
    [Noun.CompCycle]: {
      [Verb.Edit]: {
        global: true,
      },
      [Verb.View]: {
        global: true,
      },
    },
    [Noun.CompCycleBudget]: {
      [Verb.Edit]: {
        global: true,
      },
      [Verb.View]: {
        global: true,
      },
    },
    [Noun.CompRecommendation]: {
      [Verb.Edit]: {
        global: true,
        directReportIDs: [],
        indirectReportIDs: [],
        supportingManagerEmployeeIDs: [],
      },
      [Verb.View]: {
        global: true,
        directReportIDs: [],
        indirectReportIDs: [],
        supportingManagerEmployeeIDs: [],
      },
    },
    [Noun.CompStructure]: {
      [Verb.Edit]: {
        global: true,
      },
      [Verb.View]: {
        global: true,
      },
    },
    [Noun.Employee]: {
      [Verb.Edit]: {
        global: true,
        directReportIDs: [],
        indirectReportIDs: [],
        supportingManagerEmployeeIDs: [],
      },
      [Verb.View]: {
        global: true,
        directReportIDs: [],
        indirectReportIDs: [],
        supportingManagerEmployeeIDs: [],
      },
    },
    [Noun.EmployeeCashCompensation]: {
      [Verb.Edit]: {
        global: true,
        directReportIDs: [],
        indirectReportIDs: [],
        supportingManagerEmployeeIDs: [],
      },
      [Verb.View]: {
        global: true,
        directReportIDs: [],
        indirectReportIDs: [],
        supportingManagerEmployeeIDs: [],
      },
    },
    [Noun.EmployeeEquityCompensation]: {
      [Verb.Edit]: {
        global: true,
        directReportIDs: [],
        indirectReportIDs: [],
        supportingManagerEmployeeIDs: [],
      },
      [Verb.View]: {
        global: true,
        directReportIDs: [],
        indirectReportIDs: [],
        supportingManagerEmployeeIDs: [],
      },
    },
    [Noun.EquityBand]: {
      [Verb.Edit]: { global: true },
      [Verb.View]: {
        allMarkets: {
          global: true,
          departmentIDs: [],
          ladderIDs: [],
          positionIDs: [],
        },
        markets: {},
      },
    },
    [Noun.Integration]: {
      [Verb.Edit]: {
        global: true,
      },
      [Verb.View]: {
        global: true,
      },
    },
    [Noun.JobStructure]: {
      [Verb.Edit]: { global: true },
      [Verb.View]: {
        global: true,
        departmentIDs: [],
        ladderIDs: [],
        positionIDs: [],
      },
    },
    [Noun.Market]: {
      [Verb.Edit]: { global: true },
      [Verb.View]: { global: true },
    },
    [Noun.MarketData]: {
      [Verb.Edit]: { global: true },
      [Verb.View]: { global: true },
    },
    [Noun.Offer]: {
      [Verb.Edit]: {
        global: true,
        authored: true,
      },
      [Verb.View]: {
        global: true,
        authored: true,
      },
    },
    [Noun.Philosophy]: {
      [Verb.Edit]: {
        global: true,
      },
      [Verb.View]: {
        global: true,
      },
    },
    [Noun.SensitiveData]: {
      [Verb.Edit]: null,
      [Verb.View]: {
        global: true,
      },
    },
  },
  [PrimaryRoleName.RECRUITER]: {
    [Noun.CompStructure]: {
      [Verb.Edit]: null,
      [Verb.View]: {
        global: true,
      },
    },
    [Noun.JobStructure]: {
      [Verb.Edit]: null,
      [Verb.View]: {
        global: true,
        departmentIDs: [],
        ladderIDs: [],
        positionIDs: [],
      },
    },
    [Noun.Offer]: {
      [Verb.Edit]: {
        global: false,
        authored: true,
      },
      [Verb.View]: {
        global: false,
        authored: true,
      },
    },
    [Noun.Philosophy]: {
      [Verb.Edit]: null,
      [Verb.View]: {
        global: true,
      },
    },
    [Noun.AccessControl]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CashBand]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CompCycle]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CompCycleBudget]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CompRecommendation]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Employee]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.EmployeeCashCompensation]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.EmployeeEquityCompensation]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.EquityBand]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Integration]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Market]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.MarketData]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.SensitiveData]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
  },
  [PrimaryRoleName.SYSTEM_ADMIN]: {
    [Noun.CompStructure]: {
      [Verb.Edit]: null,
      [Verb.View]: {
        global: true,
      },
    },
    [Noun.JobStructure]: {
      [Verb.Edit]: null,
      [Verb.View]: {
        global: true,
        departmentIDs: [],
        ladderIDs: [],
        positionIDs: [],
      },
    },
    [Noun.Philosophy]: {
      [Verb.Edit]: null,
      [Verb.View]: {
        global: true,
      },
    },
    [Noun.AccessControl]: {
      [Verb.Edit]: {
        global: true,
      },
      [Verb.View]: {
        global: true,
      },
    },

    [Noun.CashBand]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CompCycle]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CompCycleBudget]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CompRecommendation]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Employee]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.EmployeeCashCompensation]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.EmployeeEquityCompensation]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.EquityBand]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Integration]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Offer]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Market]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.MarketData]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.SensitiveData]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
  },
  [PrimaryRoleName.EMPLOYEE]: {
    [Noun.AccessControl]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CashBand]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CompCycle]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CompCycleBudget]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CompRecommendation]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.CompStructure]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Employee]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.EmployeeCashCompensation]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.EmployeeEquityCompensation]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.EquityBand]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Integration]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.JobStructure]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Market]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.MarketData]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Offer]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.Philosophy]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
    [Noun.SensitiveData]: {
      [Verb.Edit]: null,
      [Verb.View]: null,
    },
  },
};

/**
 * Applies the Employee scope to the employee noun's view scope
 * of the currentGrant to create a new NounScopes. In other words,
 * it creates a new grant with updated employee access.
 *
 * @param nounScope
 * @param role
 */
export function applyEmployeeViewAccess(
  currentNounScopes: NounScopes,
  scope: EmployeeScope
): NounScopes {
  return {
    ...currentNounScopes,
    [Noun.Employee]: {
      [Verb.Edit]: currentNounScopes[Noun.Employee][Verb.Edit],
      [Verb.View]: scope,
    },
    [Noun.EmployeeCashCompensation]: {
      [Verb.Edit]: currentNounScopes[Noun.EmployeeCashCompensation][Verb.Edit],
      [Verb.View]: scope,
    },
    [Noun.EmployeeEquityCompensation]: {
      [Verb.Edit]:
        currentNounScopes[Noun.EmployeeEquityCompensation][Verb.Edit],
      [Verb.View]: scope,
    },
    [Noun.CompRecommendation]: {
      [Verb.Edit]: currentNounScopes[Noun.CompRecommendation][Verb.Edit],
      [Verb.View]: scope,
    },
  };
}

/**
 * Applies the Employee scope to the employee noun's view scope
 * of the currentGrant to create a new NounScopes. It then applies the scope to
 * CompRecommendation's edit scope. This is necessary so managers can view and edit
 * report data
 *
 * @param nounScope
 * @param role
 */
export function applyManagerAccess(
  currentNounScopes: NounScopes,
  scope: EmployeeScope
): NounScopes {
  const nounScope = applyEmployeeViewAccess(currentNounScopes, scope);
  return {
    ...nounScope,
    // apply compRec edit access
    [Noun.CompRecommendation]: {
      [Verb.Edit]: scope,
      [Verb.View]: nounScope[Noun.CompRecommendation][Verb.View],
    },
  };
}

export function applyHRBPAccess(
  currentNounScopes: NounScopes,
  directReportIDs: number[],
  indirectReportIDs: number[],
  supportingManagerEmployeeIDs: number[]
): NounScopes {
  const currentViewScope = currentNounScopes[Noun.Employee][Verb.View];
  const currentDirectReportIDs = currentViewScope?.directReportIDs ?? [];
  const currentIndirectReportIDs = currentViewScope?.indirectReportIDs ?? [];

  const employeeVerbScope: EmployeeScope = {
    global: currentViewScope?.global ?? false,
    directReportIDs: [
      ...currentDirectReportIDs,
      ...directReportIDs,
      ...supportingManagerEmployeeIDs,
    ],
    indirectReportIDs: [...currentIndirectReportIDs, ...indirectReportIDs],
    supportingManagerEmployeeIDs,
  };

  const updatedNounScope = applyManagerAccess(
    currentNounScopes,
    employeeVerbScope
  );

  return {
    ...updatedNounScope,
    [Noun.CompRecommendation]: {
      [Verb.Edit]: updatedNounScope[Noun.CompRecommendation][Verb.Edit],
      [Verb.View]: {
        global: employeeVerbScope.global,
        directReportIDs: employeeVerbScope.directReportIDs.filter(
          (id) => !supportingManagerEmployeeIDs.includes(id)
        ),
        indirectReportIDs: employeeVerbScope.indirectReportIDs,
        supportingManagerEmployeeIDs,
      },
    },
    [Noun.EmployeeCashCompensation]: {
      [Verb.Edit]: updatedNounScope[Noun.EmployeeCashCompensation][Verb.Edit],
      [Verb.View]: {
        global: employeeVerbScope.global,
        directReportIDs: employeeVerbScope.directReportIDs.filter(
          (id) => !supportingManagerEmployeeIDs.includes(id)
        ),
        indirectReportIDs: employeeVerbScope.indirectReportIDs,
        supportingManagerEmployeeIDs,
      },
    },
    [Noun.EmployeeEquityCompensation]: {
      [Verb.Edit]: updatedNounScope[Noun.EmployeeEquityCompensation][Verb.Edit],
      [Verb.View]: {
        global: employeeVerbScope.global,
        directReportIDs: employeeVerbScope.directReportIDs.filter(
          (id) => !supportingManagerEmployeeIDs.includes(id)
        ),
        indirectReportIDs: employeeVerbScope.indirectReportIDs,
        supportingManagerEmployeeIDs,
      },
    },
  };
}

/**
 * Applies the JobStructureScope to the compensation band access noun's view scope
 * of the currentGrant to create a new NounScopes. In other words,
 * it creates a new grant with updated comp-band access.
 *
 * @param nounScope
 * @param role
 */
export function applyCompensationBandViewAccess(
  currentNounScopes: NounScopes,
  scope: MarketJobStructureScope
): NounScopes {
  return {
    ...currentNounScopes,
    [Noun.CashBand]: {
      [Verb.Edit]: currentNounScopes[Noun.CashBand][Verb.Edit],
      [Verb.View]: scope,
    },
    [Noun.EquityBand]: {
      [Verb.Edit]: currentNounScopes[Noun.EquityBand][Verb.Edit],
      [Verb.View]: scope,
    },
  };
}

/**
 * Applies the JobStructureScope to the JobStructure noun's view scope
 * of the currentGrant to create a new NounScopes. In other words,
 * it creates a new grant with updated job structure access. This is
 * specifically used for accounting for position access for the employee portal
 *
 * @param nounScope
 * @param role
 */
export function applyJobArchitectureViewAccess(
  currentNounScopes: NounScopes,
  scope: JobStructureScope
): NounScopes {
  return {
    ...currentNounScopes,
    [Noun.JobStructure]: {
      [Verb.Edit]: currentNounScopes[Noun.JobStructure][Verb.Edit],
      [Verb.View]: scope,
    },
  };
}

/**
 * Creates a new NounScopes that carries over the current grant's compBand access and report access
 * if it is not being turned into a FullAccess role. FullAccess is not carried over since it has
 * global nounScopes instead of scoped ones.
 *
 * @param currentGrant - this will take either the shape of a grant from the
 * UserAccessGrant table or the shape of a grant from the UserInvitation
 * table. In each case they will have the { roleName, nounScopes } shape but
 * their types might differ. String vs PrimaryRoleName, NounScopes vs JsonValue
 * regardless - we could treat the PrimaryRoleName as a string and it doesn't
 * matter what the nounScopes is because we cast within the function.
 * @param role to apply to the current grant
 */
export function applyRole<T extends { roleName: string; nounScopes: unknown }>(
  currentGrant: T | null,
  role: keyof typeof PrimaryRoleName
): NounScopes {
  const newNounScopes = AvailableRoleScopes[role];

  if (
    currentGrant === null ||
    role === PrimaryRoleName.FULL_ACCESS ||
    Object.keys(currentGrant).length === 0
  ) {
    return newNounScopes;
  }

  const currentNounScopes = currentGrant.nounScopes as NounScopes;
  /*
     If the user currently has a global scoped verb we do not carry the
     global value over. This prevents the case where we move from FullAccess to BasicViewer
     and keep global CompBand or Employee access.
  */

  return {
    ...newNounScopes,
    // CashBand Nouns
    [Noun.JobStructure]: {
      [Verb.Edit]: newNounScopes[Noun.JobStructure][Verb.Edit],
      [Verb.View]: copyJobStructureScope(currentNounScopes, newNounScopes),
    },
    [Noun.CashBand]: {
      [Verb.Edit]: newNounScopes[Noun.CashBand][Verb.Edit],
      [Verb.View]: copyCompensationScope(
        currentNounScopes,
        newNounScopes,
        Noun.CashBand
      ),
    },
    [Noun.EquityBand]: {
      [Verb.Edit]: newNounScopes[Noun.EquityBand][Verb.Edit],
      [Verb.View]: copyCompensationScope(
        currentNounScopes,
        newNounScopes,
        Noun.EquityBand
      ),
    },
    // Employee Data Nouns
    [Noun.Employee]: {
      [Verb.Edit]: copyEmployeeScope(
        currentNounScopes,
        newNounScopes,
        Noun.Employee,
        Verb.Edit
      ),
      [Verb.View]: copyEmployeeScope(
        currentNounScopes,
        newNounScopes,
        Noun.Employee,
        Verb.View
      ),
    },
    [Noun.EmployeeCashCompensation]: {
      [Verb.Edit]: copyEmployeeScope(
        currentNounScopes,
        newNounScopes,
        Noun.EmployeeCashCompensation,
        Verb.Edit
      ),
      [Verb.View]: copyEmployeeScope(
        currentNounScopes,
        newNounScopes,
        Noun.EmployeeCashCompensation,
        Verb.View
      ),
    },
    [Noun.EmployeeEquityCompensation]: {
      [Verb.Edit]: copyEmployeeScope(
        currentNounScopes,
        newNounScopes,
        Noun.EmployeeEquityCompensation,
        Verb.Edit
      ),
      [Verb.View]: copyEmployeeScope(
        currentNounScopes,
        newNounScopes,
        Noun.EmployeeEquityCompensation,
        Verb.View
      ),
    },
    [Noun.CompRecommendation]: {
      [Verb.Edit]: copyEmployeeScope(
        currentNounScopes,
        newNounScopes,
        Noun.CompRecommendation,
        Verb.Edit
      ),
      [Verb.View]: copyEmployeeScope(
        currentNounScopes,
        newNounScopes,
        Noun.CompRecommendation,
        Verb.View
      ),
    },
  };
}

type CompBandAccessNoun = Noun.CashBand | Noun.EquityBand;
/**
 * Takes the current JobStructureScope for a noun/verb pair and rolls over any pre-existing values
 * except for global.
 * @param currentNounScope
 * @param newNounScope
 * @param noun
 * @param verb
 * @returns JobStructureScope
 */
function copyJobStructureScope(
  currentNounScope: NounScopes,
  newNounScope: NounScopes
): JobStructureScope {
  const currentJobStructureViewScope =
    currentNounScope[Noun.JobStructure][Verb.View];
  const newJobStructureViewScope = newNounScope[Noun.JobStructure][Verb.View];

  if (currentJobStructureViewScope === null) {
    return newJobStructureViewScope;
  }

  return {
    departmentIDs: currentJobStructureViewScope.departmentIDs,
    ladderIDs: currentJobStructureViewScope.ladderIDs,
    positionIDs: currentJobStructureViewScope.positionIDs,
    global: newJobStructureViewScope?.global ?? false,
  };
}

/**
 * Takes the current MarketJobStructureScope or JobStructureScope and rolls
 * over any pre-existing values. If the new scope is global, we do not carry
 * over any values. This makes it theoretically hard to convert back to the
 * previous values, such as when you toggle to Full Access and back. However,
 * currently upstream code has already short-circuited in that scenario anyway.
 *
 * This function is ONLY built to handle role changes, not internal changes
 * within the scope.
 *
 * This function is obnoxiously verbose while we support both scope shapes.
 * It will simplify down greatly when we only support the new shape.
 */
function copyCompensationScope(
  currentNounScope: NounScopes,
  newNounScope: NounScopes,
  noun: CompBandAccessNoun
): MarketJobStructureScope {
  const currentScope = currentNounScope[noun][Verb.View];
  const newScope = newNounScope[noun][Verb.View];

  if (newScope?.allMarkets && newScope.allMarkets.global) {
    return newScope;
  }

  if (currentScope?.allMarkets && currentScope.allMarkets.global) {
    // Old was global, so nothing to copy over.
    return newScope;
  }
  // Neither old nor new was global, so we can safely use the old values
  return currentScope;
}

type EmployeeAccessNoun =
  | Noun.Employee
  | Noun.EmployeeCashCompensation
  | Noun.CompRecommendation
  | Noun.EmployeeEquityCompensation;
/**
 * Takes the current EmployeeAccessScope for a noun/verb pair and rolls over any pre-existing values
 * except for global.
 * @param currentNounScope
 * @param newNounScope
 * @param noun
 * @param verb
 * @returns EmployeeScope
 */
function copyEmployeeScope(
  currentNounScope: NounScopes,
  newNounScope: NounScopes,
  noun: EmployeeAccessNoun,
  verb: Verb
): EmployeeScope {
  const currentEmployeeViewScope = currentNounScope[noun][verb];
  const newEmployeeViewScope = newNounScope[noun][verb];

  if (currentEmployeeViewScope === null) {
    return newEmployeeViewScope;
  }

  return {
    directReportIDs: currentEmployeeViewScope.directReportIDs,
    indirectReportIDs: currentEmployeeViewScope.indirectReportIDs,
    global: newEmployeeViewScope?.global ?? false,
    supportingManagerEmployeeIDs: [],
  };
}

/**
 * Replaces the scope for a particular Market within a MarketJobStructureScope
 * without wiping out the permissions for other Markets.
 *
 * @param marketId The market that the scope applies to will be set to
 * "allMarkets" if the marketId is null or undefined.
 * @param scopeToAdd The new scope for the target market.
 * @param currentScope The pre-existing Market scope. Unrelated markets will
 * not be touched.
 */
export function addToMarketJobStructureScope(
  marketId: number | null | undefined,
  scopeToAdd: JobStructureScope,
  currentScope: MarketJobStructureScope
): MarketJobStructureScope {
  if (marketId == null) {
    return {
      allMarkets: scopeToAdd,
      markets: currentScope?.markets ?? {},
    };
  }

  const result = {
    allMarkets: currentScope?.allMarkets ?? null,
    markets: {
      ...currentScope?.markets,
      [marketId]: scopeToAdd,
    },
  };

  if (scopeToAdd == null) {
    // If we just added a null row, delete it entirely instead.
    delete result.markets[marketId];
  }
  return result;
}

/**
 * Used to convert a string from the DB to a primary role name.
 * If no match is found it returns null
 */
export function getPrimaryRoleName(roleName: string): PrimaryRoleName | null {
  return (
    Object.values(PrimaryRoleName).find((role) => roleName === role) ?? null
  );
}

export const BASE_NOUN_SCOPES: Readonly<NounScopes> = {
  [Noun.AccessControl]: {
    [Verb.Edit]: null,
    [Verb.View]: null,
  },
  [Noun.CashBand]: {
    [Verb.Edit]: null,
    [Verb.View]: null,
  },
  [Noun.CompCycle]: {
    [Verb.Edit]: null,
    [Verb.View]: null,
  },
  [Noun.CompCycleBudget]: {
    [Verb.Edit]: null,
    [Verb.View]: null,
  },
  [Noun.CompRecommendation]: {
    [Verb.Edit]: null,
    [Verb.View]: null,
  },
  [Noun.CompStructure]: {
    [Verb.Edit]: null,
    [Verb.View]: null,
  },
  [Noun.Employee]: {
    [Verb.Edit]: null,
    [Verb.View]: null,
  },
  [Noun.EmployeeCashCompensation]: {
    [Verb.Edit]: null,
    [Verb.View]: null,
  },
  [Noun.EmployeeEquityCompensation]: {
    [Verb.Edit]: null,
    [Verb.View]: null,
  },
  [Noun.EquityBand]: {
    [Verb.Edit]: null,
    [Verb.View]: null,
  },
  [Noun.Integration]: {
    [Verb.Edit]: null,
    [Verb.View]: null,
  },
  [Noun.JobStructure]: {
    [Verb.Edit]: null,
    [Verb.View]: null,
  },
  [Noun.Market]: {
    [Verb.Edit]: null,
    [Verb.View]: null,
  },
  [Noun.MarketData]: {
    [Verb.Edit]: null,
    [Verb.View]: null,
  },
  [Noun.Offer]: {
    [Verb.Edit]: null,
    [Verb.View]: null,
  },
  [Noun.Philosophy]: {
    [Verb.Edit]: null,
    [Verb.View]: null,
  },
  [Noun.SensitiveData]: {
    [Verb.Edit]: null,
    [Verb.View]: null,
  },
};
