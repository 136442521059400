import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { CurrencyCode } from "../../../__generated__/graphql";
import { TotalEquitySliderV2 } from "../Equity/TotalEquitySliderV2";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(4),
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(2),
  },
}));

type Props = {
  defaultValue: number;
  values: { label: string; value: number }[];
  valuationCurrency: CurrencyCode;
};

export function TotalEquitySummarySliderContainer({
  defaultValue,
  values,
  valuationCurrency,
}: Props): JSX.Element {
  const classes = useStyles();

  const [sharePriceTarget, setSharePriceTarget] =
    useState<number>(defaultValue);

  const handleChange = (_: unknown, newValue: number | number[]) => {
    setSharePriceTarget(newValue as number);
  };

  return (
    <div className={classes.root}>
      <TotalEquitySliderV2
        defaultValue={defaultValue}
        value={sharePriceTarget}
        values={values}
        handleChange={handleChange}
        valuationCurrency={valuationCurrency}
      />
    </div>
  );
}
