import { Currency } from "@asmbl/shared/currency";
import { GetLadders_marketDataSet as MarketDataSet } from "src/pages/Compare/Bands/SelectedLaddersLoader";
import { exchangeMoney } from "@asmbl/shared/currency";
import { CurrencyCode } from "@asmbl/shared/constants";

// Handles currency conversion for a full market data set
export const convertMarketDataValues = (
  marketDataSets: MarketDataSet[] | null,
  selectedCurrency: Currency,
  currencies: Map<CurrencyCode, Currency>
): MarketDataSet[] =>
  marketDataSets?.map((marketDataSet) => ({
    ...marketDataSet,
    marketDataSamples: marketDataSet.marketDataSamples.map(
      (marketDataSample) => ({
        ...marketDataSample,
        currencyCode: selectedCurrency.code,
        marketDataPoints: marketDataSample.marketDataPoints.map(
          (marketDataPoint) => ({
            ...marketDataPoint,
            value: exchangeMoney(
              {
                value: marketDataPoint.value ?? 0,
                currency: marketDataSample.currencyCode as CurrencyCode,
              },
              selectedCurrency.code,
              { allCurrencies: currencies }
            ).value,
          })
        ),
      })
    ),
  })) ?? [];

export const annualizeMarketData = (
  marketDataSets: MarketDataSet[] | null
): MarketDataSet[] =>
  marketDataSets?.map((marketDataSet) => ({
    ...marketDataSet,
    marketDataSamples: marketDataSet.marketDataSamples.map(
      (marketDataSample) => ({
        ...marketDataSample,
        marketDataPoints: marketDataSample.marketDataPoints.map(
          (marketDataPoint) => ({
            ...marketDataPoint,
            value: (marketDataPoint?.value ?? 0) / 4,
          })
        ),
      })
    ),
  })) ?? [];
