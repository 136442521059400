import { gql } from "@apollo/client";
import { makeStyles, Typography } from "@material-ui/core";
import { CompCycleGrouping } from "../../../../components/CompCycle/types";
import { ComplexToggle } from "../../../../components/ComplexToggle";
import { useCompStructure } from "../../../../components/CompStructureContext";
import { AdjustSettingsContainer } from "../../../../components/Table/AdjustSettingsContainer";
import { TableHeaderBar } from "../../../../components/Table/TableHeaderBar";
import { AllocationUnit } from "../../../../models/Budget";
import { getToggleOptions } from "../../../../models/CompCycle";
import { GRAY_4, PURPLE_1 } from "../../../../theme";
import {
  EquityGrantMethod,
  TableHeader_compCycleSettings as CompCycleSettings,
} from "../../../../__generated__/graphql";
import { BackToMainButton } from "./BackToMainButton";

type Employee = { id: number };

type Props = {
  employee: Employee | null;
  compCycleId: number;
  selected: CompCycleGrouping;
  settings: CompCycleSettings;
  equityDisplay: AllocationUnit;
  onSetEquityDisplay: (equityDisplay: AllocationUnit) => unknown;
  onChange: (value: CompCycleGrouping) => unknown;
};

const useStyles = makeStyles((theme) => ({
  popOver: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(4),

    borderRadius: "5px",
    border: `2px solid ${PURPLE_1}`,
    boxSizing: "border-box",
    filter: "drop-shadow(0px 15px 25px rgba(10, 36, 64, 0.08))",
  },
  popOverSection: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
  },
  popOverText: {
    fontSize: "0.625rem",
    fontWeight: "bold",
    lineHeight: "0.875",
    color: GRAY_4,
    textTransform: "uppercase",
  },
  toggleOptions: {
    height: theme.spacing(4),
  },
}));

export function TableHeader({
  settings,
  employee,
  compCycleId,
  selected,
  equityDisplay,
  onSetEquityDisplay,
  onChange,
}: Props): JSX.Element {
  const classes = useStyles();
  const { compStructure } = useCompStructure();
  const toggleOptions = getToggleOptions(settings);
  return (
    <TableHeaderBar
      slotLeft={
        <>
          {employee !== null && (
            <BackToMainButton to={`/comp-cycles/${compCycleId}/budget`} />
          )}
        </>
      }
      slotCenter={
        <>
          {toggleOptions.length > 1 && (
            <ComplexToggle
              options={toggleOptions}
              selected={selected}
              onChange={onChange}
            />
          )}
        </>
      }
      slotRight={
        <>
          {selected === "equity" &&
            compStructure?.equityGrantMethod !== EquityGrantMethod.CASH && (
              <AdjustSettingsContainer
                context={
                  <div className={classes.popOver}>
                    <div className={classes.popOverSection}>
                      <Typography className={classes.popOverText}>
                        Show Equity
                      </Typography>
                      <div className={classes.toggleOptions}>
                        <ComplexToggle
                          options={[
                            [AllocationUnit.CASH, "Cash"],
                            [AllocationUnit.UNIT, "Units"],
                          ]}
                          selected={equityDisplay}
                          onChange={onSetEquityDisplay}
                        />
                      </div>
                    </div>
                  </div>
                }
              />
            )}
        </>
      }
    />
  );
}

TableHeader.fragments = {
  compCycleBudgetDraft: gql`
    fragment TableHeader_compCycleBudgetDraft on CompCycleBudgetDraft {
      employeeId
      employee {
        id
      }
    }
  `,
  compCycleSettings: gql`
    fragment TableHeader_compCycleSettings on CompCycle {
      allowSalary
      allowEquity
      allowBonus
      allowTargetCommission
      allowTargetRecurringBonus
      allowActualRecurringBonus
    }
  `,
};
