import { gql, Reference, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  CreateLadder,
  CreateLadderVariables,
  DeleteLadder,
  DeleteLadderVariables,
  UpdateLadder,
  UpdateLadderVariables,
} from "../__generated__/graphql";

const LADDER_FIELDS = gql`
  fragment LadderFields on Ladder {
    id
    name
  }
`;

type CreateLadderInput = {
  departmentId: number;
  name: string;
};

export const CREATE_LADDER = gql`
  ${LADDER_FIELDS}
  mutation CreateLadder($ladderName: String!, $departmentId: Int!) {
    createOneLadder(data: { name: $ladderName, departmentId: $departmentId }) {
      ...LadderFields
      department {
        id
        name
        ladders {
          id
          name
        }
      }
      organization {
        id
        name
      }
    }
  }
`;

export function useCreateLadder(): (
  ladder: CreateLadderInput
) => Promise<unknown> {
  const [createLadder] = useMutation<CreateLadder, CreateLadderVariables>(
    CREATE_LADDER,
    {
      update(cache, { data }) {
        if (data == null) {
          return;
        }

        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            ladders(existingLadders: Reference[]) {
              const newLadder = cache.writeFragment({
                fragment: LADDER_FIELDS,
                fragmentName: "LadderFields",
                data: data.createOneLadder,
              });

              return [...existingLadders, newLadder];
            },
          },
        });
      },
    }
  );

  return useCallback(
    (ladder: CreateLadderInput) =>
      createLadder({
        variables: {
          departmentId: ladder.departmentId,
          ladderName: ladder.name,
        },
      }),
    [createLadder]
  );
}

export const UPDATE_LADDER = gql`
  ${LADDER_FIELDS}
  mutation UpdateLadder($ladderId: Int!, $ladderName: String) {
    updateOneLadder(id: $ladderId, data: { name: $ladderName }) {
      ...LadderFields
    }
  }
`;

type UpdateLadderInput = { id: number; name: string };

export function useUpdateLadder(): (
  department: UpdateLadderInput
) => Promise<unknown> {
  const [UpdateLadder] = useMutation<UpdateLadder, UpdateLadderVariables>(
    UPDATE_LADDER
  );

  return useCallback(
    (ladder: UpdateLadderInput) =>
      UpdateLadder({
        variables: {
          ladderId: ladder.id,
          ladderName: ladder.name,
        },
      }),
    [UpdateLadder]
  );
}

export const DELETE_LADDER = gql`
  ${LADDER_FIELDS}
  mutation DeleteLadder($ladderId: Int!) {
    deleteOneLadder(id: $ladderId) {
      ...LadderFields
    }
  }
`;

export function useDeleteLadder(): (ladderId: number) => Promise<unknown> {
  const [deleteLadder] = useMutation<DeleteLadder, DeleteLadderVariables>(
    DELETE_LADDER,
    {
      update(cache, { data }) {
        if (data == null) {
          return;
        }

        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            ladders(existingLadders: Reference[], { readField }) {
              return existingLadders.filter(
                (ref) => readField("id", ref) !== data.deleteOneLadder.id
              );
            },
          },
        });
      },
    }
  );

  return useCallback(
    (ladderId: number) =>
      deleteLadder({
        variables: {
          ladderId,
        },
      }),
    [deleteLadder]
  );
}
