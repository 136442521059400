import { Box, makeStyles, TextField } from "@material-ui/core";
import { GRAY_4 } from "../../../theme";
import { CustomFieldVariant } from "../../../__generated__/graphql";
import { VariantSelector } from "./CustomFieldVariantSelector";
import { KeyedCustomField } from "./OfferContentsForm";
import { SelectOptionsEditor } from "./SelectOptionsEditor";
import { TextDescriptionEditor } from "./TextDescriptionEditor";
const MAX_FIELD_NAME_LENGTH = 35;

const useStyles = makeStyles((theme) => ({
  fieldset: {
    flex: "1 1 100%",
  },
  fieldContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  fieldCounter: {
    color: GRAY_4,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  nameField: {
    padding: theme.spacing(2, 0),
    fontSize: "1rem",
  },
}));

type Props = {
  field: KeyedCustomField;
  onFieldChange: (field: KeyedCustomField) => void;
};

export function CustomFieldEditor({
  field,
  onFieldChange,
}: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Box className={classes.fieldset}>
      <Box className={classes.fieldContainer}>
        <TextField
          placeholder="Custom field title..."
          value={field.name}
          onChange={(e) => onFieldChange({ ...field, name: e.target.value })}
          fullWidth
          InputProps={{
            className: classes.nameField,
            disableUnderline: true,
          }}
          inputProps={{
            maxLength: MAX_FIELD_NAME_LENGTH,
            "aria-label": "Custom Field Title",
          }}
        />
        <VariantSelector field={field} onFieldChange={onFieldChange} />
      </Box>
      {field.variant === CustomFieldVariant.DROPDOWN && (
        <SelectOptionsEditor field={field} onFieldChange={onFieldChange} />
      )}
      {field.variant === CustomFieldVariant.TEXT && (
        <TextDescriptionEditor field={field} onFieldChange={onFieldChange} />
      )}
    </Box>
  );
}
