import { InfoBanner } from "../../components/InfoBanner";
import {
  assertLocationSelected,
  useLocations,
} from "../../components/LocationsContext";

export function EditingMarketWarning(): JSX.Element | null {
  const { markets, selectedLocation } = useLocations();
  assertLocationSelected(selectedLocation);

  const [selectedMarket] = selectedLocation;

  const alreadyEndsWithMarket = selectedMarket.name
    .toLowerCase()
    .endsWith("market");

  const marketName = alreadyEndsWithMarket ? (
    <b>{selectedMarket.name}</b>
  ) : (
    <>
      <b>{selectedMarket.name}</b> market
    </>
  );

  return markets.length > 1 ? (
    <InfoBanner level="warning" align="center">
      You're currently editing bands in the {marketName}.
    </InfoBanner>
  ) : null;
}
