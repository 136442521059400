import { gql } from "@apollo/client";
import { PortalTableButton_employee } from "../../../../../__generated__/graphql";
import { TableAccessButton } from "./TableAccessButton";
import { TableAccountButton } from "./TableAccountButton";
import { TableInviteButton } from "./TableInviteButton";

type Props = {
  employee: PortalTableButton_employee;
};

export const PortalTableButton = ({ employee }: Props) => {
  if (employee.user) {
    return (
      <TableAccountButton
        employee={employee}
        roleName={employee.user.userAccessGrant?.roleName ?? null}
      />
    );
  }

  // if employee has some kind of invite without an acct
  if (employee.latestUserInvite !== null) {
    const expirationDate = new Date(employee.latestUserInvite.expiredAt);
    // the invite is either pending & can be revoked
    return expirationDate > new Date() &&
      employee.latestUserInvite.deletedAt === null ? (
      <TableAccessButton employee={employee} />
    ) : (
      // or expired/deleted & can be resent an invite
      <TableInviteButton employee={employee} />
    );
  }

  return <TableInviteButton employee={employee} />;
};

PortalTableButton.fragments = {
  employee: gql`
    ${TableInviteButton.fragments.employee}
    ${TableAccessButton.fragments.employee}
    ${TableAccountButton.fragments.employee}
    fragment PortalTableButton_employee on Employee {
      ...TableInviteButton_employee
      ...TableAccessButton_employee
      ...TableAccountButton_employee
      id
      user {
        id
        userAccessGrant {
          id
          roleName
        }
      }
      latestUserInvite {
        id
        createdAt
        deletedAt
        expiredAt
        tokenUsedAt
      }
    }
  `,
};
