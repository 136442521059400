import { makeStyles } from "@material-ui/core";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_2 } from "src/theme";
import SequentialApprovalsIcon from "../../../assets/svgs/illustrations/sequential-approvals-2.svg?react";

const useStyles = makeStyles((theme) => ({
  emptyView: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(3),
    padding: theme.spacing(4),
  },
}));

export function EmptyPhaseList(): JSX.Element | null {
  const classes = useStyles();

  return (
    <div className={classes.emptyView}>
      <SequentialApprovalsIcon />
      <AssembleTypography variant="productSmallSemiBold" textColor={GRAY_2}>
        Create a new phase. Then, add people and completion dates to it.
      </AssembleTypography>
    </div>
  );
}
