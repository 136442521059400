import { Popover, PopoverOrigin } from "@material-ui/core";
import React, { useState } from "react";
import { AssembleButton, getIconProps } from "../AssembleButton/AssembleButton";
import { AdjustSettingsIcon } from "../AssembleIcons/Brand/AdjustSettingsIcon";

type Props = { context?: React.ReactNode };

export function AdjustSettingsContainer({ context }: Props): JSX.Element {
  const anchorOrigin: PopoverOrigin = {
    vertical: "bottom",
    horizontal: "right",
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        onClose={handlePopoverClose}
      >
        {context}
      </Popover>

      <AssembleButton
        onClick={handlePopoverButtonClick}
        variant="outlined"
        size="small"
        startIcon={<AdjustSettingsIcon {...getIconProps({ size: "small" })} />}
      />
    </>
  );
}
