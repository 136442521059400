import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const LockIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Lock"
      viewBox="0 0 10 11"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke fill"
        d="M7.72727 4.5H2.27273C2.1221 4.5 2 4.63991 2 4.8125V9.1875C2 9.36009 2.1221 9.5 2.27273 9.5H7.72727C7.8779 9.5 8 9.36009 8 9.1875V4.8125C8 4.63991 7.8779 4.5 7.72727 4.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M3.5 4.5V2.5C3.5 2.23478 3.65804 1.98043 3.93934 1.79289C4.22064 1.60536 4.60218 1.5 5 1.5C5.39782 1.5 5.77936 1.60536 6.06066 1.79289C6.34196 1.98043 6.5 2.23478 6.5 2.5V4.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
