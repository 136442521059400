import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GRAY_4, GRAY_6, PURPLE_1, WHITE } from "../../theme";
import { CompensationIcon } from "../AssembleIcons/Brand/CompensationIcon";
import { BandPointIcon } from "../AssembleIcons/Small/BandPointIcon";
import { BenefitsIcon } from "../AssembleIcons/Small/BenefitsIcon";
import { CompanyInformationIcon } from "../AssembleIcons/Small/CompanyInformationIcon";
import { CurrencyIcon } from "../AssembleIcons/Small/CurrencyIcon";
import { DataManagementIcon } from "../AssembleIcons/Small/DataManagementIcon";
import { EmployeePortalIcon } from "../AssembleIcons/Small/EmployeePortalIcon";
import { EquityIcon } from "../AssembleIcons/Small/EquityIcon";
import { IllustrativeOutcomesIcon } from "../AssembleIcons/Small/IllustrativeOutcomesIcon";
import { LocationIcon } from "../AssembleIcons/Small/LocationIcon";
import { NotesIcon } from "../AssembleIcons/Small/NotesIcon";
import { PermissionsIcon } from "../AssembleIcons/Small/PermissionsIcon";
import { PoliciesIcon } from "../AssembleIcons/Small/PoliciesIcon";
import { SettingsIcon } from "../AssembleIcons/Small/SettingsIcon";
import { AssembleTypography } from "../AssembleTypography";
import { PreviewFeature } from "../FeatureContext";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: WHITE,
    boxShadow: `1px 0px 0px ${GRAY_6}`,

    flexShrink: 0,
    height: "100%",
    width: theme.spacing(31),
    left: "81px",

    marginRight: "1px",
    padding: theme.spacing(6, 2),

    position: "fixed",
    zIndex: 10,

    overflow: "auto",

    // Hide scrollbars
    "&::-webkit-scrollbar": {
      display: "none" /* Chrome, Safari and Opera */,
    },
    "-ms-overflow-style": "none" /* IE and Edge */,
    scrollbarWidth: "none" /* Firefox */,

    // Fancy scrolling shadows (https://codepen.io/chriscoyier/pen/YzXBYvL)
    background: `
      /* Shadow Cover TOP */
      linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
      ) center top,
      
      /* Shadow Cover BOTTOM */
      linear-gradient(
        rgba(255, 255, 255, 0), 
        white 70%
      ) center bottom,
      
      /* Shadow TOP */
      linear-gradient(
        rgba(112, 133, 152, 0.09),
        rgba(0, 0, 0, 0)
      ) center top,
      
      /* Shadow BOTTOM */
      linear-gradient(
        rgba(0, 0, 0, 0) 0%,
        rgba(112, 133, 152, 0.09) 80%,
        rgba(112, 133, 152, 0.18) 100%
      ) center bottom
    `,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 48px, 100% 96px, 100% 24px, 100% 48px",
    backgroundAttachment: "local, local, scroll, scroll",
  },
  groupOverline: {
    color: GRAY_4,
    fontSize: "12px",
    lineHeight: 1.4,
    fontWeight: 700,
    textTransform: "uppercase",
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

export const SettingsSidebar = memo(function SettingsSidebar(): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.sidebar}>
      <Box mb={3}>
        <Typography className={classes.groupOverline}>Total Rewards</Typography>
        <SidebarButton
          icon={<CompensationIcon width="16px" height="16px" inherit />}
          path="/settings/compensation"
        >
          Compensation
        </SidebarButton>
        <SidebarButton
          icon={<BandPointIcon width="16px" height="16px" inherit />}
          path="/settings/band-points"
        >
          Band Points
        </SidebarButton>
        <SidebarButton
          icon={<CurrencyIcon width="16px" height="16px" inherit />}
          path="/settings/currencies"
        >
          Currencies
        </SidebarButton>
        <SidebarButton
          icon={<LocationIcon width="16px" height="16px" inherit />}
          path="/settings/markets"
        >
          Markets & Locations
        </SidebarButton>
        <SidebarButton
          icon={<EquityIcon width="16px" height="16px" inherit />}
          path="/settings/equity"
        >
          Equity Details
        </SidebarButton>
      </Box>

      <Box mb={4}>
        <Typography className={classes.groupOverline}>Offers</Typography>
        <SidebarButton
          icon={<NotesIcon width="16px" height="16px" inherit />}
          path="/settings/offer-contents"
        >
          Contents
        </SidebarButton>
        <SidebarButton
          icon={<BenefitsIcon width="16px" height="16px" inherit />}
          path="/settings/benefits"
        >
          Benefits
        </SidebarButton>
        <SidebarButton
          icon={<IllustrativeOutcomesIcon width="16px" height="16px" inherit />}
          path="/settings/offer-outcomes"
        >
          Illustrative Outcomes
        </SidebarButton>
      </Box>

      <PreviewFeature flag={FeatureFlag.EmployeePortal}>
        <Box mb={4}>
          <Typography className={classes.groupOverline}>
            Employee Portal
          </Typography>
          <SidebarButton
            icon={<EmployeePortalIcon width="16px" height="16px" inherit />}
            path="/settings/portal-access"
          >
            Portal Access
          </SidebarButton>
          <SidebarButton
            icon={<SettingsIcon width="16px" height="16px" inherit />}
            path="/settings/portal-config"
          >
            Portal Config
          </SidebarButton>
        </Box>
      </PreviewFeature>

      <Box mb={4}>
        <Typography className={classes.groupOverline}>
          Assemble Administration
        </Typography>
        <SidebarButton
          icon={<CompanyInformationIcon width="16px" height="16px" inherit />}
          path="/settings/business"
        >
          Business Structure
        </SidebarButton>
        <SidebarButton
          icon={<DataManagementIcon width="16px" height="16px" inherit />}
          path="/settings/data"
        >
          Data Management
        </SidebarButton>
        <SidebarButton
          icon={<PoliciesIcon width="16px" height="16px" inherit />}
          path="/settings/policies"
        >
          Access Policies
        </SidebarButton>
        <SidebarButton
          icon={<PermissionsIcon width="16px" height="16px" inherit />}
          path="/settings/permissions"
        >
          User Permissions
        </SidebarButton>
      </Box>
    </Box>
  );
});

const useButtonStyles = makeStyles((theme) => ({
  sidebarButton: {
    alignItems: "center",
    background: "inherit",
    border: "0.5px solid transparent",
    borderRadius: "5px",
    color: GRAY_4,
    cursor: "pointer",
    display: "flex",
    outline: "none",
    padding: theme.spacing(1, 2),
    textAlign: "left",
    transition: "all 300ms",
    width: "100%",

    "&:hover, &:focus": {
      border: "0.5px solid #C6D5E2",
      boxShadow: "0px 1px 4px rgba(10, 36, 64, 0.08)",
      color: PURPLE_1,
    },
  },
  sidebarIcon: {
    width: "16px",
    marginRight: theme.spacing(1),
    transition: "all 300ms",
  },
  sidebarButtonActive: {
    color: "black",

    "&:hover, &:focus": {
      border: "0.5px solid transparent",
      boxShadow: "none",
      color: "black",
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    columnGap: theme.spacing(1),
  },
}));

export function SidebarButton({
  children,
  icon,
  path,
}: {
  children: string;
  icon: JSX.Element;
  path: string;
}): JSX.Element {
  const classes = useButtonStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const active = location.pathname.includes(path);

  return (
    <button
      className={`${classes.sidebarButton} ${
        active ? classes.sidebarButtonActive : ""
      }`}
      onClick={() => navigate(path)}
      tabIndex={active ? -1 : 0}
    >
      <div className={classes.content}>
        {icon}
        <AssembleTypography variant="productSmall">
          {children}
        </AssembleTypography>
      </div>
    </button>
  );
}
