import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const ImportIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Import"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2754_59072)">
        <path
          className="stroke"
          d="M3 18V20.25C3 20.4489 3.07902 20.6397 3.21967 20.7803C3.36032 20.921 3.55109 21 3.75 21H20.25C20.3485 21 20.446 20.9806 20.537 20.9429C20.628 20.9052 20.7107 20.85 20.7803 20.7803C20.85 20.7107 20.9052 20.628 20.9429 20.537C20.9806 20.446 21 20.3485 21 20.25V18"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="stroke"
          d="M12 3V14"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="stroke"
          d="M8.5 6.5L12.0476 2.95238L15.5953 6.5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2754_59072">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </ColorableSVG>
);
