import { cx } from "@emotion/css";
import { Box, Grid, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";
import { CopyIcon } from "src/components/AssembleIcons/Brand/CopyIcon";
import { DragHandleIcon } from "src/components/AssembleIcons/Brand/DragHandleIcon";
import { EditIcon } from "src/components/AssembleIcons/Brand/EditIcon";
import { ComputedLocationAdjustment } from "../../../models/ComputedLocationAdjustment";
import { useDeleteLocationAdjustment } from "../../../mutations/Location";
import { GRAY_4, GRAY_6, PURPLE_1, PURPLE_2, WHITE } from "../../../theme";
import { getAdjustmentColor } from "../../../utils";
import { AssembleTypography } from "../../AssembleTypography";
import { DeleteButton } from "../../Form/DeleteButton";
import { OrdinalCircle } from "../Benefits/Editor/OrdinalCircle";
import { AdjustmentEditorState } from "./LocationAdjustmentEditor";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    borderTop: `1px solid ${GRAY_6}`,
    background: WHITE,
    "&:hover": {
      "& $buttonContainer": {
        opacity: 1,
      },
    },
    "&:focus-within": {
      "& $buttonContainer": {
        opacity: 1,
      },
    },
  },
  dragging: {},
  margin: {
    paddingLeft: theme.spacing(1),
    position: "relative",
    width: "1.5rem",
    height: "1.5rem",
  },
  marginOrdinal: {
    "$container:hover &, $margin:focus-within &, $dragging &": {
      display: "none",
    },
  },

  marginDragHandle: {
    position: "absolute",
    top: 0,
    left: theme.spacing(0.5),
    height: "1.5rem",
    width: "1.5rem",
    opacity: 0,
    color: GRAY_4,
    "$container:hover &, $margin:focus-within &, $dragging &": {
      opacity: 1,
    },

    "&:focus-within": {
      color: PURPLE_1,
    },
  },
  adjustmentValue: {
    fontSize: "20px",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginRight: theme.spacing(1),
    opacity: 0,
    transition: "opacity 300ms",
  },
}));

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: React.CSSProperties = {}
): React.CSSProperties => ({
  // change style if dragging
  border: isDragging ? `1px solid ${GRAY_4}` : undefined,
  boxSizing: isDragging ? "border-box" : undefined,
  boxShadow: isDragging ? "0px 5px 15px rgba(10, 36, 64, 0.13)" : undefined,

  // styles we need to apply on draggables
  ...draggableStyle,
});

type LocationAdjustmentRowProps = {
  locationAdjustment: ComputedLocationAdjustment;
  index: number;
  openAdjustmentEditor: (
    adjustmentEditorState: AdjustmentEditorState
  ) => unknown;
};

export function LocationAdjustmentRow({
  locationAdjustment,
  index,
  openAdjustmentEditor,
}: LocationAdjustmentRowProps): JSX.Element {
  const classes = useStyles();

  const { id, adjustmentValue, departments, ladders, levels, compComponents } =
    locationAdjustment;

  const deleteLocationAdjustment = useDeleteLocationAdjustment(id);

  return (
    <Draggable key={id} draggableId={id.toString()} index={index}>
      {(provided, snapshot) => (
        <Grid
          container
          className={cx(classes.container, {
            [classes.dragging]: snapshot.isDragging,
          })}
          ref={(el: HTMLElement | null) => {
            provided.innerRef(el);
          }}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          alignItems="center"
        >
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <div className={classes.margin}>
                <OrdinalCircle
                  className={classes.marginOrdinal}
                  ordinal={index + 1}
                />
                <div
                  className={classes.marginDragHandle}
                  {...provided.dragHandleProps}
                >
                  <DragHandleIcon
                    color={GRAY_4}
                    hoverColor={PURPLE_2}
                    width="24px"
                    height="24px"
                  />
                </div>
              </div>
              <Box mr={3} />
              <AssembleTypography
                variant="productTableContent"
                className={classes.adjustmentValue}
                style={{ color: getAdjustmentColor(adjustmentValue) }}
              >
                {formatAdjustmentValue(adjustmentValue)}
              </AssembleTypography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <AssembleTypography variant="productTableContent">
              {formatCondition(
                departments.map((d) => d.name),
                "Departments"
              )}
            </AssembleTypography>
          </Grid>
          <Grid item xs={2}>
            <AssembleTypography variant="productTableContent">
              {formatCondition(
                ladders.map((l) => l.name),
                "Ladders"
              )}
            </AssembleTypography>
          </Grid>
          <Grid item xs={2}>
            <AssembleTypography variant="productTableContent">
              {formatCondition(levels, "Levels")}
            </AssembleTypography>
          </Grid>
          <Grid item xs={2}>
            <AssembleTypography variant="productTableContent">
              {formatCondition(compComponents, "Compensation")}
            </AssembleTypography>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.buttonContainer}>
              <Tooltip title="Edit Adjustment" placement="top">
                <IconButton
                  size="small"
                  onClick={() =>
                    openAdjustmentEditor({
                      mode: "edit",
                      existingLocationAdjustment: locationAdjustment,
                    })
                  }
                >
                  <EditIcon
                    color={GRAY_4}
                    hoverColor={PURPLE_2}
                    height={"24px"}
                    width={"24px"}
                  />
                </IconButton>
              </Tooltip>
              <Box m={0.5} />
              <Tooltip title="Duplicate Adjustment" placement="top">
                <IconButton
                  size="small"
                  onClick={() =>
                    openAdjustmentEditor({
                      mode: "duplicate",
                      existingLocationAdjustment: locationAdjustment,
                    })
                  }
                >
                  <CopyIcon
                    color={GRAY_4}
                    hoverColor={PURPLE_2}
                    height={"24px"}
                    width={"24px"}
                  />
                </IconButton>
              </Tooltip>
              <Box m={0.5} />
              <DeleteButton
                buttonType="icon"
                size="small"
                objectName="Adjustment"
                onDelete={deleteLocationAdjustment}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Draggable>
  );
}

export function SourceLocationAdjustmentRow({
  locationAdjustment,
}: {
  locationAdjustment: ComputedLocationAdjustment;
}): JSX.Element {
  const classes = useStyles();

  const { adjustmentValue, departments, ladders, levels, compComponents } =
    locationAdjustment;

  return (
    <Grid container className={classes.container}>
      <Grid item xs={2}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Box mr={6} />
          <AssembleTypography
            variant="productTableContent"
            className={classes.adjustmentValue}
            style={{ color: getAdjustmentColor(adjustmentValue) }}
          >
            {formatAdjustmentValue(adjustmentValue)}
          </AssembleTypography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <AssembleTypography variant="productTableContent">
          {formatCondition(
            departments.map((d) => d.name),
            "Departments"
          )}
        </AssembleTypography>
      </Grid>
      <Grid item xs={2}>
        <AssembleTypography variant="productTableContent">
          {formatCondition(
            ladders.map((l) => l.name),
            "Ladders"
          )}
        </AssembleTypography>
      </Grid>
      <Grid item xs={2}>
        <AssembleTypography variant="productTableContent">
          {formatCondition(levels, "Levels")}
        </AssembleTypography>
      </Grid>
      <Grid item xs={2}>
        <AssembleTypography variant="productTableContent">
          {formatCondition(compComponents, "Compensation")}
        </AssembleTypography>
      </Grid>
    </Grid>
  );
}

function formatAdjustmentValue(adjustmentValue: number): string {
  return adjustmentValue > 0 ? `+${adjustmentValue}%` : `${adjustmentValue}%`;
}

function formatCondition(
  ids: number[] | string[],
  type: "Departments" | "Ladders" | "Levels" | "Compensation"
): string {
  if (ids.length === 0) return `All ${type}`;
  return ids.join(", ");
}
