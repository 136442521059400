import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const ExportIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Export"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M18.75 21H5.24927C5.05036 21 4.85959 20.921 4.71894 20.7803C4.57828 20.6397 4.49927 20.4489 4.49927 20.25V3.75C4.49927 3.55109 4.57828 3.36032 4.71894 3.21967C4.85959 3.07902 5.05036 3 5.24927 3H14.25L19.5 8.25V20.25C19.5 20.3485 19.4806 20.446 19.4429 20.537C19.4052 20.628 19.35 20.7107 19.2803 20.7803C19.2107 20.85 19.128 20.9052 19.037 20.9429C18.946 20.9806 18.8485 21 18.75 21Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M10 12V16"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M13.875 3V8.625H19.5007"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M12 15L10 17L7.99999 15"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
