import { formatTimestamp } from "@asmbl/shared/time";
import { makeStyles, Typography } from "@material-ui/core";
import { GRAY_1, GRAY_3, GRAY_6, GRAY_9 } from "../../theme";
import { UserAvatar } from "../UserAvatar";

type Props = {
  author: { email: string; name: string | null; photoURL: string | null };
  displayTimestamp: boolean;
  note: string;
  timestamp: string;
  children?: JSX.Element;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    marginTop: theme.spacing(2),
  },
  content: {
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px",
    margin: theme.spacing(0, 9, 0.5, 6),
    backgroundColor: GRAY_9,
  },
  noteContentContainer: {
    display: "flex",
    alignContent: "center",
    justifyContent: "flex-start",
    margin: theme.spacing(3, 3, 3, 3),
  },
  noteContent: {
    fontSize: "13px",
    lineHeight: "140%",
    letterSpacing: "-0.25px",
    color: GRAY_1,
  },
  timestamp: {
    color: GRAY_3,
    fontSize: "12px",
    lineHeight: "140%",
    letterSpacing: "-0.15px",
    marginLeft: "12px",
  },
}));

export function CompNoteModalDialogReceivedContent({
  children,
  author,
  timestamp,
  displayTimestamp,
  note,
}: Props): JSX.Element {
  const classes = useStyles();
  const noteTimestamp = formatTimestamp(timestamp);
  return (
    <>
      {displayTimestamp && (
        <div className={classes.container}>
          <UserAvatar
            size="small"
            displayName={author.name ?? author.email}
            photoURL={author.photoURL}
          />
          <Typography className={classes.timestamp}>{noteTimestamp}</Typography>
        </div>
      )}
      <div className={classes.content}>
        {children}
        <div className={classes.noteContentContainer}>
          <Typography className={classes.noteContent}>{note}</Typography>
        </div>
      </div>
    </>
  );
}
