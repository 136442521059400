import { gql, useQuery } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import {
  CompRecommendationStatus,
  CondensedTablePeopleLoadingBoundaryQuery,
  CondensedTablePeopleLoadingBoundaryQueryVariables,
  RecReviewStatus,
} from "src/__generated__/graphql";
import { ErrorView } from "src/components/ErrorView";
import { useFeatureFlags } from "src/components/FeatureContext";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { CompRecAction } from "src/models/CompRecommendation";
import { usePagination } from "../Contexts/PaginationContext";
import { useTableFilterContext } from "../Contexts/TableFilterContext";
import { CondensedTable } from "./CondensedTable";
import { CurrencyCodeWithAll } from "./CondensedTableCurrencyPicker";

type Props = {
  compCycleId: number;
  employeeIds: number[];
  isAdmin: boolean;
  nameSearch: string | null;
  actionToggle: CompRecAction;
  selectedCurrency: CurrencyCodeWithAll;
};

export function CondensedTablePeopleLoadingBoundary({
  compCycleId,
  employeeIds,
  isAdmin,
  nameSearch,
  selectedCurrency,
  actionToggle,
}: Props): JSX.Element {
  const { filter, actingManagerEmployeeId } = useTableFilterContext();
  const {
    pageSize,
    currentPage,
    setTotalCount,
    sortBy,
    sortDir,
    setCurrentEmployeeIds,
  } = usePagination();

  const { isEnabled } = useFeatureFlags();

  const currencyCode = selectedCurrency === "all" ? null : selectedCurrency;

  const shouldPaginate = isEnabled(FeatureFlag.ClientPlanTable030124);
  const queryVariables = {
    compCycleId,
    filter: { ...filter, displayName: nameSearch },
    currencyCode,
    employeeIds,
    limit: shouldPaginate ? pageSize : undefined,
    offset: shouldPaginate ? currentPage * pageSize : undefined,
    isAdmin,
    skipEligibility: true,
    sort: shouldPaginate ? { sortBy, sortDir } : undefined,
    actingManagerEmployeeId,
  };

  const { data, loading } = useQuery<
    CondensedTablePeopleLoadingBoundaryQuery,
    CondensedTablePeopleLoadingBoundaryQueryVariables
  >(CondensedTablePeopleLoadingBoundary.query, {
    variables: queryVariables,
    onCompleted: (data: CondensedTablePeopleLoadingBoundaryQuery) => {
      if (data.compCycle2?.participants.count != null) {
        setTotalCount(data.compCycle2.participants.count);
        setCurrentEmployeeIds(
          data.compCycle2.participants.participants.map((e) => e.subjectId)
        );
      }
    },
  });

  if (
    !data ||
    data.compCycle2 == null ||
    data.valuation === null ||
    data.whoami?.user == null
  ) {
    return loading ? (
      <LoadingSpinner />
    ) : (
      <ErrorView text="Reach out to your comp admin for assistance." />
    );
  }
  const user = data.whoami.user;
  const employeesFilteredByAction =
    data.compCycle2.participants.participants.filter((e) => {
      const latestReview = e.compRecommendation?.latestSubmittedReviews.at(0);
      switch (actionToggle) {
        case CompRecAction.ALL:
          return true;
        case CompRecAction.NEEDS_REVIEW:
          return (
            e.compRecommendation === null ||
            (e.compRecommendation.reviewStatus ===
              CompRecommendationStatus.NEEDS_REVIEW &&
              latestReview?.author.id !== user.id) ||
            e.compRecommendation.reviewStatus ===
              CompRecommendationStatus.LATE ||
            (latestReview?.status === RecReviewStatus.DENIED &&
              latestReview.author.id !== user.id)
          );
        case CompRecAction.REVIEWED:
          return (
            (e.compRecommendation?.reviewStatus ===
              CompRecommendationStatus.REVIEWED &&
              !(
                latestReview?.status === RecReviewStatus.DENIED &&
                latestReview.author.id !== user.id
              )) ||
            (latestReview?.status === RecReviewStatus.APPROVED &&
              latestReview.author.id === user.id)
          );
        default:
          return false;
      }
    });

  return (
    <CondensedTable
      selectedCurrency={selectedCurrency}
      employees={employeesFilteredByAction}
      compCycle={data.compCycle2}
      isActiveCycle={data.compCycle2.closeDate === null}
      availablePositions={data.positions}
      budget={data.compCycleBudgets}
      valuation={data.valuation}
      user={user}
      isAdmin={isAdmin}
      meritGuidance={{
        meritAdjustmentGuides: data.compCycle2.meritAdjustmentGuides ?? [],
        meritAdjustmentRanges: data.meritAdjustmentRanges,
        perfRatingOptions: data.perfRatingOptions,
      }}
    />
  );
}

const MERIT_ADJUSTMENT_GUIDE = gql`
  fragment CondensedTable_meritAdjustmentGuide on MeritAdjustmentGuide {
    id
    perfRatingOptionId
    meritAdjustmentRangeId
    percent
    compCycleId
    organizationId
  }
`;

const PERF_RATING_OPTION = gql`
  fragment CondensedTable_perfRatingOption on PerfRatingOption {
    id
    name
    compCycleId
    organizationId
    rank
  }
`;

const MERIT_ADJUSTMENT_RANGE = gql`
  fragment CondensedTable_meritAdjustmentRange on MeritAdjustmentRange {
    id
    rangeStart
    compCycleId
    organizationId
  }
`;

CondensedTablePeopleLoadingBoundary.query = gql`
  ${CondensedTable.fragments.compCycle}
  ${CondensedTable.fragments.compCycleBudget}
  ${CondensedTable.fragments.participant}
  ${CondensedTable.fragments.position}
  ${CondensedTable.fragments.valuation}
  ${CondensedTable.fragments.user}
  ${MERIT_ADJUSTMENT_GUIDE}
  ${MERIT_ADJUSTMENT_RANGE}
  ${PERF_RATING_OPTION}
  query CondensedTablePeopleLoadingBoundaryQuery(
    $compCycleId: Int!
    $filter: GetParticipantsInput
    $currencyCode: CurrencyCode
    $employeeIds: [Int!]!
    $limit: Int
    $offset: Int
    $sort: Sort
    $isAdmin: Boolean!
    $skipEligibility: Boolean
    $actingManagerEmployeeId: Int
  ) {
    compCycle2(id: $compCycleId) {
      id
      name
      closeDate
      ...CondensedTable_compCycle

      participants(
        input: $filter
        limit: $limit
        offset: $offset
        sort: $sort
      ) {
        count
        participants {
          compCycleId
          subjectId
          compRecommendation(skipEligibility: $skipEligibility) {
            latestSubmittedReviews {
              id
              status
              authorId
            }
            reviewStatus
          }
          ...CondensedTable_participant
        }
      }

      meritAdjustmentGuides {
        id
        ...CondensedTable_meritAdjustmentGuide
      }
    }
    positions {
      id
      ...CondensedTable_position
    }
    valuation {
      id
      ...CondensedTable_valuation
    }
    compCycleBudgets(
      compCycleId: $compCycleId
      employeeIds: $employeeIds
      currencyCode: $currencyCode
    ) {
      ...CondensedTable_compCycleBudget
    }
    whoami {
      user {
        id
        ...CondensedTable_user
      }
    }
    perfRatingOptions(compCycleId: $compCycleId) {
      id
      ...CondensedTable_perfRatingOption
    }
    meritAdjustmentRanges(compCycleId: $compCycleId) {
      id
      ...CondensedTable_meritAdjustmentRange
    }
  }
`;
