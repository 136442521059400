import { isEmptyString } from "@asmbl/shared/utils";
import { Button, makeStyles } from "@material-ui/core";
import { useMemo, useState } from "react";
import { useTrack } from "../../../../analytics";
import { AssembleTypography } from "../../../../components/AssembleTypography";
import { TotalEquitySummaryModal } from "../../../../components/Portal/EquitySummary/TotalEquitySummaryModal";
import { OutcomeWithAnnotation } from "../../../../components/Settings/IllustrativeOutcomesForm";
import { GRAY_2, GRAY_5, GRAY_6, WHITE } from "../../../../theme";
import { CurrencyCode } from "../../../../__generated__/graphql";
import { EquityOutcomesForm } from "./EquityOutcomesForm";
import { PLACEHOLDER_OUTCOMES } from "./PortalConfig";

const useStyles = makeStyles((theme) => ({
  dismissibleDesc: {
    backgroundColor: GRAY_6,
    borderRadius: 6,
    fontWeight: 400,
    padding: theme.spacing(2),
  },
  equityOutcomes: {
    color: GRAY_2,
  },
  subHeader: {
    paddingBottom: theme.spacing(3),
  },
  exampleContainer: {
    marginTop: theme.spacing(1),
    textAlign: "left",
  },
  button: {
    boxShadow: "0px 1px 3px rgba(10, 36, 64, 0.1) !important",
    backgroundColor: WHITE,
    border: `1px solid ${GRAY_5}`,
    "&:hover": {
      backgroundColor: WHITE,
    },
  },
}));

export interface ExitOutcomeWithAnnotation extends OutcomeWithAnnotation {
  placeholder: boolean;
  placeholderText?: string;
}

export type FormData = {
  defaultExitOutcome: number | null;
  exitOutcomes: ExitOutcomeWithAnnotation[];
};

type Props = {
  formData: FormData;
  currencyCode: CurrencyCode;
  changeHandler: ChangeHandler;
  handleFormChange: () => unknown;
};

type ChangeHandler = <Field extends keyof FormData>(
  id: Field,
  value: FormData[Field]
) => unknown;

export const EquityOutcomes = ({
  formData,
  currencyCode,
  handleFormChange,
  changeHandler,
}: Props) => {
  const classes = useStyles();
  const { trackEvent } = useTrack();

  const [totalEquitySummaryOpen, setTotalEquitySummaryOpen] = useState(false);

  const toggleTotalEquitySummary = () => {
    if (!totalEquitySummaryOpen) {
      trackEvent({
        area: "Employee Portal",
        subArea: "Portal Config",
        object: "Equity Outcomes Preview Button",
        action: "Clicked",
      });
    }
    setTotalEquitySummaryOpen(!totalEquitySummaryOpen);
  };

  const previewData = useMemo(
    () => formatFormDataAsPortalConfig(formData),
    [formData]
  );

  const closeTotalEquitySummaryModal = () => setTotalEquitySummaryOpen(false);

  return (
    <>
      <TotalEquitySummaryModal
        portalConfig={previewData}
        open={totalEquitySummaryOpen}
        onClose={closeTotalEquitySummaryModal}
        valuationCurrency={currencyCode}
      />
      <div className={classes.subHeader}>
        <AssembleTypography variant="h5" className={classes.equityOutcomes}>
          Equity Outcomes
        </AssembleTypography>
        <AssembleTypography
          variant="productSmall"
          className={classes.equityOutcomes}
        >
          Control what information is shown for various scenarios of company
          growth.
        </AssembleTypography>
      </div>
      <div className={classes.dismissibleDesc}>
        <AssembleTypography variant="productParagraphLarge">
          Equity Outcomes help employees better understand the value of their
          equity package in a variety of outcomes in the future.
        </AssembleTypography>
        <div className={classes.exampleContainer}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={toggleTotalEquitySummary}
            disabled={
              formData.defaultExitOutcome === null &&
              formData.exitOutcomes.every((outcome) => outcome.value === 0)
            }
          >
            Preview Values
          </Button>
        </div>
      </div>
      <EquityOutcomesForm
        outcomes={formData.exitOutcomes}
        defaultExitOutcome={formData.defaultExitOutcome}
        onChangeOutcomes={(outcomes) => changeHandler("exitOutcomes", outcomes)}
        onChangeDefault={(value) => changeHandler("defaultExitOutcome", value)}
        valuationCurrency={currencyCode}
        onFormChange={handleFormChange}
      />
    </>
  );
};

// helper function that merges the placeholder data with the form data so that
// there is always data present to preview
function formatFormDataAsPortalConfig(formData: FormData): {
  __typename: "PortalConfig";
  defaultExitOutcome: number;
  exitOutcomes: number[];
  xAxisAnnotations: string[];
} {
  const formattedData = formData.exitOutcomes.map(
    ({ value, annotation, placeholderText }) => {
      return {
        exitOutcome: value,
        xAxisAnnotation: isEmptyString(annotation)
          ? (placeholderText as string)
          : annotation,
      };
    }
  );

  return {
    __typename: "PortalConfig",
    defaultExitOutcome:
      formData.defaultExitOutcome ?? PLACEHOLDER_OUTCOMES[0].value,
    exitOutcomes: formattedData.map(({ exitOutcome }) => exitOutcome),
    xAxisAnnotations: formattedData.map(
      ({ xAxisAnnotation }) => xAxisAnnotation
    ),
  };
}
