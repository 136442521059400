import { mapify } from "@asmbl/shared/utils";
import {
  Button,
  Checkbox,
  Chip,
  ClickAwayListener,
  FormControlLabel,
  IconButton,
  makeStyles,
  Popper,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank, Clear } from "@material-ui/icons";
import Autocomplete, {
  AutocompleteCloseReason,
} from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { useState } from "react";
import { GRAY_1, GRAY_2, GRAY_4, GRAY_5, PURPLE_1, RED } from "../../theme";
import { ChevronDownIcon } from "../AssembleIcons/Brand/ChevronDownIcon";
import { DeleteIcon } from "../AssembleIcons/Brand/DeleteIcon";
import { AssembleTruncatedTypography } from "../AssembleTruncatedTypography";
import { AssembleTypography } from "../AssembleTypography";

const useStyles = makeStyles((theme: Theme) => ({
  active: {},
  empty: {},
  button: {
    height: "2rem",
    width: "fit-content",
    minWidth: "100px",
    maxWidth: "300px",
    border: `1px solid ${GRAY_5}`,
    borderRadius: "4px",
    color: GRAY_2,
    padding: theme.spacing(0, 1, 0, 1.5),
    boxShadow: "0px 1px 3px rgba(10, 36, 64, 0.1)",

    // Hovering, click-down, and active states (when not empty)
    "&:hover, &:focus, &:active, &$active": {
      border: `1px solid ${PURPLE_1}`,
      boxShadow:
        "0px 1px 3px rgba(10, 36, 64, 0.1), 0px 0px 0px 3px rgba(10, 0, 174, 0.25)",
    },

    // By default, the empty state should have the color red...
    "&$empty": {
      border: `1px solid ${RED}`,
      color: RED,

      // ...and when you hover over it, it gets a red box-shadow...
      "&:hover": {
        boxShadow:
          "0px 1px 3px rgba(10, 36, 64, 0.1), 0px 0px 0px 3px rgba(255, 56, 92, 0.25)",
      },

      // ...but when it's active or clicked-down, restore the normal colors.
      "&:active, &:focus, &$active": {
        color: GRAY_1,
        border: `1px solid ${PURPLE_1}`,
        boxShadow:
          "0px 1px 3px rgba(10, 36, 64, 0.1), 0px 0px 0px 3px rgba(10, 0, 174, 0.25)",
      },
    },
  },
  innerButton: {
    display: "flex",
    justifyContent: "space-between",
    gap: "0.5rem",
    alignItems: "center",
  },
  label: {
    color: "inherit",
  },
  popper: {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    marginTop: "3px", // to account for the active boxShadow of the button
    borderRadius: "3px",
    width: "300px",
    zIndex: 1,
    backgroundColor: "white",
  },
  autocompletePopper: {
    border: "none",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 8px 0px 8px",
  },
  inputBase: {
    padding: "8px",
    borderBottom: "1px solid #dfe2e5",
    fontSize: "0.875rem",
  },
  inputRoot: {
    padding: "4px 8px !important",
    fontSize: "0.875rem",
  },
  paper: {
    boxShadow: "none",
  },
  option: {
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  open: {},
  arrowIcon: {
    transition: "transform 350ms",
    minWidth: "1rem",
    minHeight: "1rem",
    width: "1rem",
    height: "1rem",
    color: "inherit",

    "&$open": {
      transform: "rotate(-180deg)",
    },
  },
  listbox: {
    padding: "8px",
  },
  delete: {
    width: "16px",
    padding: 0,
  },
}));

export type SearchableFilterOption<T> = {
  label: string;
  value: T;
  group?: string;
};

export type SearchableFilterProps<T extends string | number> = {
  name: string;
  options: SearchableFilterOption<T>[];
  selected: T[];
  onChange: (values: T[]) => void;
  onDelete?: () => void;
  icon?: JSX.Element;
  hideChips?: boolean;
  disabled?: boolean;
  disableChip?: boolean;
  multiple?: boolean;
};

export function SearchableFilter<T extends string | number>({
  name,
  options,
  selected = [],
  onChange,
  onDelete,
  icon,
  hideChips = false,
  disableChip = false,
  multiple = true,
}: SearchableFilterProps<T>) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(selected.length === 0);
  const [searchedValues, setSearchedValues] = useState<T[]>(selected);
  // This is for looking up the name of any selected options.
  const valueToLabel = mapify(options, "value", "label");

  // This is for looking up the group name of any selected options.
  const containsGroups = options.every((o) => o.group !== undefined);
  const valueToGroup = mapify(options, "value", "group");

  const handleClose = () => {
    setOpen(false);
    onChange(searchedValues);
  };

  const handleAutocompleteClose = (
    _: React.ChangeEvent<unknown>,
    reason: AutocompleteCloseReason
  ) => {
    // Only close when user explicitly attempts to close.
    if (reason === "escape" || reason === "select-option") {
      handleClose();
    }
  };

  const handleDelete = () => {
    onChange([]);
    setSearchedValues([]);
    setOpen(false);
    onDelete && onDelete();
  };

  const id = open ? `searchable-filter-${name}` : undefined;

  const buttonLabel = `${name}: ${selected
    .map((s) => valueToLabel.get(s))
    .join(", ")}`;

  const PopperComponent = (
    <Popper
      id={id}
      anchorEl={anchorEl}
      open={anchorEl != null && open}
      placement="bottom-start"
      className={classes.popper}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <div className={classes.header}>
            <AssembleTypography
              variant="productMicrocopy"
              color="textSecondary"
            >
              {name}
            </AssembleTypography>
            <IconButton onClick={handleDelete} className={classes.delete}>
              <DeleteIcon color={GRAY_4} hoverColor={RED} />
            </IconButton>
          </div>
          <Autocomplete
            open
            multiple={multiple}
            onClose={handleAutocompleteClose}
            classes={{
              paper: classes.paper,
              option: classes.option,
              popperDisablePortal: classes.popperDisablePortal,
              listbox: classes.listbox,
              popper: classes.autocompletePopper,
              inputRoot: classes.inputRoot,
            }}
            value={multiple ? searchedValues : searchedValues[0]}
            onChange={(_, value) => {
              if (multiple) {
                const multipleValues = value as T[];
                setSearchedValues(multipleValues);
                onChange(multipleValues);
              } else {
                const singleValue = value as T;
                setSearchedValues([singleValue]);
                onChange([singleValue]);
              }
            }}
            disableCloseOnSelect
            disablePortal
            groupBy={
              containsGroups ? (o) => valueToGroup.get(o) as string : undefined
            }
            forcePopupIcon={false}
            renderTags={
              hideChips
                ? () => null
                : (value: T[], getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={`${name}-${option}`}
                        label={valueToLabel.get(option)}
                        deleteIcon={<Clear />}
                        size="small"
                        disabled={disableChip}
                      />
                    ))
            }
            noOptionsText={`No ${name}`}
            renderOption={(option, { selected }) =>
              multiple ? (
                <>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank />}
                    checkedIcon={<CheckBox />}
                    checked={selected}
                    color="primary"
                    size="small"
                  />
                  <AssembleTypography variant="body2" textColor={GRAY_1}>
                    {valueToLabel.get(option)}
                  </AssembleTypography>
                </>
              ) : (
                <FormControlLabel
                  control={
                    <Radio
                      color="primary"
                      size="small"
                      checked={searchedValues[0] === option}
                    />
                  }
                  label={
                    <AssembleTypography variant="body2" textColor={GRAY_1}>
                      {valueToLabel.get(option)}
                    </AssembleTypography>
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const value = valueToLabel.get(option);

                    const singleValue = value as T;
                    setSearchedValues([singleValue]);
                    onChange([singleValue]);
                  }}
                />
              )
            }
            options={options.map((v) => v.value)}
            getOptionLabel={(option) => valueToLabel.get(option) as string}
            renderInput={(params) => (
              <TextField
                ref={params.InputProps.ref}
                {...params}
                variant="outlined"
                className={classes.inputBase}
                placeholder={
                  searchedValues.length > 0 ? "" : "Select one or more options…"
                }
              />
            )}
          />
        </div>
      </ClickAwayListener>
    </Popper>
  );

  return (
    <>
      <Button
        ref={setAnchorEl}
        className={clsx(classes.button, {
          [classes.active]: open,
          [classes.empty]: selected.length === 0,
        })}
        aria-describedby={id}
        onClick={() => setOpen(true)}
      >
        <div className={classes.innerButton}>
          {icon}
          <AssembleTruncatedTypography className={classes.label}>
            {buttonLabel}
          </AssembleTruncatedTypography>

          <ChevronDownIcon
            inherit
            className={clsx(classes.arrowIcon, { [classes.open]: open })}
          />
        </div>
      </Button>
      {multiple ? PopperComponent : <RadioGroup>{PopperComponent}</RadioGroup>}
    </>
  );
}
