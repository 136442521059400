import { mapMaybe } from "@asmbl/shared/utils";
import { List } from "@material-ui/core";
import { useState } from "react";
import { useIntercom } from "react-use-intercom";
import { useTrack } from "src/analytics";
import {
  CompCycleDataChangeHandler,
  CompCyclePhaseData,
} from "src/components/CompCycle/CompCycleWizard/types";
import {
  useCompCycleOrganizationChartSelectionData,
  useCompCycleOrganizationChartViewsData,
} from "src/components/CompCycleOrganizationChart/CompCycleOrganizationChartContext";
import { DialogListItem, EditPhaseDialog } from "./EditPhaseDialog";

type Props = {
  closeModal: () => void;
  phases: CompCyclePhaseData[];
  phase: CompCyclePhaseData;
  previousPhase: CompCyclePhaseData | undefined;
  nextPhase: CompCyclePhaseData | undefined;
  handleChange: CompCycleDataChangeHandler;
  isSettingsView?: boolean;
};

export function EditLayerPhaseModal({
  closeModal,
  phases,
  phase,
  previousPhase,
  nextPhase,
  handleChange,
  isSettingsView = false,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const intercom = useIntercom();
  const { layerData, layerDepth, selectionMode } =
    useCompCycleOrganizationChartSelectionData();

  const { setPageEditState } = useCompCycleOrganizationChartViewsData();

  const restPhases = phases.filter(
    ({ phaseOrder }) => phaseOrder !== phase.phaseOrder
  );

  // we need to initialize the layers to have a default value
  // of true if `this` phase has the layer selected
  const initLayers: {
    [key: string]: boolean;
  } = [...Array(layerDepth).keys()]
    .map((i) => i)
    .reduce(
      (acc, layer) => ({ ...acc, [layer]: phase.layers.includes(layer) }),
      {}
    );

  const [selectedLayers, setSelectedLayers] = useState<{
    [key: string]: boolean | undefined;
  }>(initLayers);

  const [calendarDate, setCalendarDate] = useState(phase.startDate);

  const handleSave = () => {
    const layers = mapMaybe(Object.entries(selectedLayers), ([key, value]) => {
      if (value === true) return parseInt(key);
    });

    const currentLayers = layers.join(", ");

    trackEvent({
      object: "Phase Configuration Save Settings Button",
      action: "Clicked",
      allLayersSelected: layers.length === layerDepth,
      layers: currentLayers,
      selectionMode,
      phaseOrder: phase.phaseOrder,
    });

    intercom.trackEvent("Phase Configuration Save Settings Button Clicked", {
      allLayersSelected: layers.length === layerDepth,
      layers: currentLayers,
      phaseOrder: phase.phaseOrder,
    });

    const assigneeIds = mapMaybe(
      [...Object.entries(layerData)],
      ([layer, ids]) => {
        const currentLayer = parseInt(layer);

        if (layers.includes(currentLayer)) {
          return ids;
        }
      }
    ).flat();

    handleChange(
      "phasesData",
      [
        ...restPhases,
        {
          ...phase,
          layers,
          assigneeIds,
          startDate: calendarDate,
        },
      ].sort((a, b) => a.phaseOrder - b.phaseOrder)
    );

    setPageEditState(true);
    closeModal();
  };

  const handlePhaseDelete = () => {
    trackEvent({
      object: "Delete Phase Button",
      action: "Clicked",
    });
    intercom.trackEvent("Delete Phase Button Clicked");

    const updatedPhases = [...phases]
      .filter((p) => p.phaseOrder !== phase.phaseOrder)
      .sort((a, b) => a.phaseOrder - b.phaseOrder)
      .map((phase, index) => ({ ...phase, phaseOrder: index + 1 }));

    handleChange("phasesData", updatedPhases);
    setPageEditState(true);
    closeModal();
  };

  const handleSelectionChange = (key: string) => {
    trackEvent({
      object: "Phase List Item Checkbox",
      action: "Clicked",
      selectionMode,
    });
    intercom.trackEvent("Phase Layers List Item Checkbox Clicked");

    setSelectedLayers((state) => {
      const currentValue = state[key];

      return {
        ...state,
        [key]: currentValue === undefined ? true : !currentValue,
      };
    });
  };

  return (
    <EditPhaseDialog
      closeModal={closeModal}
      phase={phase}
      previousPhase={previousPhase}
      nextPhase={nextPhase}
      handlePhaseDelete={handlePhaseDelete}
      handleSave={handleSave}
      isSettingsView={isSettingsView}
      calendarDate={calendarDate}
      setCalendarDate={setCalendarDate}
    >
      <List>
        {Object.entries(initLayers).map(
          ([layer, isAssignedToThisPhase]: [string, boolean]) => {
            const layerIndex = Number.parseInt(layer);

            // we do not allow adding the synthetic node to a phase
            if (layerIndex === 0) return null;

            const assignedPhase = getPhaseAssignment(layerIndex, phases);
            const isAssignedToAnotherPhase =
              assignedPhase != null && !isAssignedToThisPhase;

            return (
              <DialogListItem
                key={layerIndex}
                isAssignedToAnotherPhase={isAssignedToAnotherPhase}
                isAssignedToThisPhase={isAssignedToThisPhase}
                isSettingsView={isSettingsView}
                listItemName={`Layer ${layerIndex}`}
                assignedPhaseOrder={assignedPhase?.phaseOrder}
                handleChange={() => handleSelectionChange(layer)}
              />
            );
          }
        )}
      </List>
    </EditPhaseDialog>
  );
}

function getPhaseAssignment(layer: number, phases: CompCyclePhaseData[]) {
  return phases.find((p) => p.layers.includes(layer));
}
