import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import { forwardRef, memo, Ref } from "react";
import assembleGrayLogo from "../../../assets/svgs/gray-logo-landscape.svg";
import assembleWhiteLogo from "../../../assets/svgs/white-logo-landscape.svg";
import { BLUE_2, GRAY_4, WHITE } from "../../../theme";
import { NonNull } from "../../../utils";
import { GetOffer } from "../../../__generated__/graphql";

type OfferConfig = NonNull<GetOffer["offerConfig"]>;

const useStyles = makeStyles((theme: Theme) => ({
  assembleLogo: {
    height: "20px",
    marginLeft: theme.spacing(1),
  },
  bgLogo: {
    height: "80vh",
    left: theme.spacing(8),
    opacity: (p: StyleProps) => (p.background ? 0 : 0.85),
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  circle: {
    border: (p: StyleProps) =>
      p.background ? `1px solid ${WHITE}` : `1px solid ${GRAY_4}`,
    borderRadius: "42px",
    height: "42px",
    width: "42px",
  },
  container: {
    backgroundColor: (p: StyleProps) => (p.background ? p.color : WHITE),
    backgroundImage: (p: StyleProps) =>
      p.backgroundImgUrl === undefined ? "none" : `url(${p.backgroundImgUrl})`,
    backgroundSize: "cover",
    boxSizing: "border-box",
    overflow: "hidden",
    minHeight: "100vh",
  },
  content: {
    height: "100%",
    padding: theme.spacing(8),
    position: "absolute",
    width: "100%",
    zIndex: 10,
  },
  contingentColor: {
    color: (p: StyleProps) => (p.background ? `${WHITE} !important` : GRAY_4),
  },
  index: {
    zIndex: 20,
  },
  logo: {
    height: "32px",
    width: "auto",
  },
  frame: {
    backgroundImage: (p: StyleProps) =>
      p.backgroundImgUrl === undefined ? "none" : `url(${p.backgroundImgUrl})`,
    backgroundSize: "0 0",
    height: "100%",
    position: "relative",
    minHeight: theme.spacing(115),
    minWidth: theme.spacing(168),
  },
}));

//  ----------------------------------------------------------------------------
//  Types
//  ----------------------------------------------------------------------------
export type FrameProps = {
  background?: boolean;
  children: JSX.Element;
  date?: boolean;
  offer: { offeredAt: Date | string };
  offerConfig: OfferConfig;
  page?: number;
};

type StyleProps = {
  background: boolean;
  color?: string;
  height?: number;
  width?: number;
  backgroundImgUrl?: string;
};

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
const Frame = forwardRef(function Frame(
  {
    background = false,
    children,
    date = false,
    offer,
    offerConfig,
    page,
  }: FrameProps,
  ref: Ref<HTMLElement>
): JSX.Element {
  const color = offerConfig.brandColorPrimary ?? BLUE_2;
  const cornerCompanyLogo =
    (background
      ? offerConfig.whiteLogoUrlPath
      : offerConfig.primaryLogoUrlPath) ?? undefined;
  const backgroundCompanyLogo =
    (background ? offerConfig.whiteLogoUrlPath : offerConfig.greyLogoUrlPath) ??
    undefined;
  const backgroundImgUrl =
    (background ? offerConfig.backgroundUrlPath : undefined) ?? undefined;
  const offerDate = new Date(offer.offeredAt);
  const classes = useStyles({
    background,
    color,
    backgroundImgUrl,
  });

  return (
    <Box
      className={classes.container}
      px={10}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        className={classes.frame}
        alignItems="center"
        display="flex"
        flexDirection="row"
        ref={ref}
      >
        <img
          className={classes.bgLogo}
          src={backgroundCompanyLogo}
          alt="Background company logo"
        />
        <Box left={64} top={64} position="absolute" style={{ zIndex: 10 }}>
          <img
            className={classes.logo}
            src={cornerCompanyLogo}
            alt="Company logo"
          />
        </Box>
        {date && (
          <Box
            className={classes.index}
            right={64}
            top={64}
            position="absolute"
          >
            <Typography className={classes.contingentColor} variant="body1">
              {offerDate.toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </Typography>
          </Box>
        )}
        <Box
          alignItems="center"
          bottom={64}
          className={classes.index}
          left={64}
          position="absolute"
          display="flex"
          flexDirection="row"
        >
          <Typography className={classes.contingentColor} variant="body2">
            Powered by{" "}
          </Typography>
          <img
            src={background ? assembleWhiteLogo : assembleGrayLogo}
            alt="Assemble"
            className={classes.assembleLogo}
          />
        </Box>
        {page !== undefined && (
          <Box
            className={classes.index}
            bottom={56}
            right={64}
            position="absolute"
          >
            <Box
              alignItems="center"
              className={classes.circle}
              display="flex"
              justifyContent="center"
            >
              <Typography className={classes.contingentColor} variant="body1">
                {page}
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          className={classes.content}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
});

export default memo(Frame);
