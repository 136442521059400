import { makeStyles } from "@material-ui/core";
import { ReactNode } from "react";
import { AssembleTypography } from "../../AssembleTypography";

const useStyles = makeStyles(() => ({
  lineItemContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },
}));

type Props = {
  label: ReactNode;
  compValue: ReactNode;
};

export function LabelValue({ label, compValue }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.lineItemContainer}>
      <AssembleTypography
        variant="productExtraSmall"
        noWrap
        style={{ flexShrink: 0 }}
      >
        {label}
      </AssembleTypography>
      {compValue}
    </div>
  );
}
