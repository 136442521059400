import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from "@material-ui/lab";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { DeleteIcon } from "src/components/AssembleIcons/Brand/DeleteIcon";
import {
  AdjustmentCriterion,
  CriterionOption,
} from "../../../models/AdjustmentCriterion";
import { AssemblePopper } from "../../AssemblePopper";

const useStyles = makeStyles((theme) => ({
  criterionRow: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    columnGap: theme.spacing(2),
  },
  criterionActionElement: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  chip: {
    maxWidth: theme.spacing(17.75),
    overflow: "hidden",
  },
  deleteButton: { height: "1.5rem" },
}));

export type AdjustmentCriterionFieldProps = {
  criterion: AdjustmentCriterion;
  onChange: (selectedOptions: CriterionOption[]) => unknown;
  onDelete: () => unknown;
};

export function AdjustmentCriterionField({
  criterion,
  onChange,
  onDelete,
}: AdjustmentCriterionFieldProps): JSX.Element {
  const classes = useStyles();

  const isLevelsCriterion = criterion.type === "Levels";

  return (
    <Box className={classes.criterionRow}>
      {isLevelsCriterion ? (
        <LevelsCriterionField
          criterion={criterion}
          onChange={onChange}
          onDelete={onDelete}
        />
      ) : (
        <JobArchitectureCriterionField
          criterion={criterion}
          onChange={onChange}
          onDelete={onDelete}
        />
      )}

      <div className={classes.criterionActionElement}>
        <IconButton className={classes.deleteButton} onClick={onDelete}>
          <DeleteIcon inherit height="24px" width="24px" />
        </IconButton>
      </div>
    </Box>
  );
}

function JobArchitectureCriterionField({
  criterion,
  onChange,
}: AdjustmentCriterionFieldProps) {
  const classes = useStyles();

  const renderTags = (
    tags: CriterionOption[],
    getTagProps: AutocompleteGetTagProps
  ) =>
    tags.map((tag, index) => (
      <Chip
        key={tag.id}
        title={tag.name}
        label={tag.name}
        {...getTagProps({ index })}
        className={classes.chip}
      />
    ));

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        label={criterion.type}
        variant="outlined"
        autoFocus={!criterion.selected}
        fullWidth
      />
    );
  };

  const renderOption = (
    option: CriterionOption,
    state: AutocompleteRenderOptionState
  ) => (
    <>
      <Checkbox
        icon={<CheckBoxOutlineBlank />}
        checkedIcon={<CheckBox />}
        checked={state.selected}
      />
      <Box m={1} />
      <Typography>{option.name}</Typography>
    </>
  );

  return (
    <Autocomplete
      PopperComponent={(props) =>
        AssemblePopper({
          ...props,
          contentWidth: "fit-content",
        })
      }
      popupIcon={<ChevronDownIcon inherit />}
      options={criterion.options}
      value={criterion.selected}
      onChange={(_, value) => onChange(value)}
      fullWidth
      multiple
      openOnFocus
      disableCloseOnSelect
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      renderInput={renderInput}
      renderOption={renderOption}
      renderTags={renderTags}
    />
  );
}

function LevelsCriterionField({
  criterion,
  onChange,
}: AdjustmentCriterionFieldProps) {
  const renderLevelTags = (tagValue: CriterionOption[]) => {
    return tagValue
      .sort((a, b) => Number(a.name) - Number(b.name))
      .map((value, index) => (
        <Typography key={value.id}>
          {index === 0 ? "" : ", "}
          {value.name}
        </Typography>
      ));
  };

  const renderLevelOptions = (
    option: CriterionOption,
    state: AutocompleteRenderOptionState
  ) => {
    return (
      <>
        <Checkbox
          icon={<CheckBoxOutlineBlank />}
          checkedIcon={<CheckBox />}
          checked={state.selected}
        />
        <Box m={1} />
        <Typography>{option.name}</Typography>
      </>
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return <TextField {...params} label="Levels" variant="outlined" />;
  };

  return (
    <Autocomplete
      PopperComponent={(props) =>
        AssemblePopper({
          ...props,
          contentWidth: "fit-input",
        })
      }
      popupIcon={<ChevronDownIcon inherit />}
      options={criterion.options}
      value={criterion.selected}
      onChange={(_, value) => onChange(value)}
      fullWidth
      multiple
      openOnFocus
      disableCloseOnSelect
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      renderTags={renderLevelTags}
      renderOption={renderLevelOptions}
      renderInput={renderInput}
    />
  );
}
