import { CircularProgress, IconButton } from "@material-ui/core";
import { GRAY_4, PURPLE_2 } from "../../theme";
import {
  AssembleButton,
  AssembleButtonProps,
  getIconProps,
} from "../AssembleButton/AssembleButton";
import { DownloadExportIcon } from "../AssembleIcons/Brand/DownloadExportIcon";
import { ExportIcon } from "../AssembleIcons/Brand/ExportIcon";

type CsvExportProps =
  | {
      variant: "icon";
      onClick: () => unknown;
      loading: boolean;
    }
  | {
      variant: "button" | "tableButton";
      onClick: () => unknown;
      loading: boolean;
      buttonProps?: AssembleButtonProps;
    };

export function CsvExport(props: CsvExportProps): JSX.Element {
  const { loading, onClick } = props;

  switch (props.variant) {
    case "icon":
      return (
        <IconButton
          style={{ width: "45px", height: "45px" }}
          aria-label="Export to CSV"
          size="small"
          onClick={onClick}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <ExportIcon
              color={GRAY_4}
              hoverColor={PURPLE_2}
              width="24px"
              height="24px"
            />
          )}
        </IconButton>
      );
    case "button":
      return (
        <AssembleButton
          size="medium"
          label={loading ? "Loading..." : "Export Cycle Data"}
          variant="outlined"
          onClick={onClick}
          {...props.buttonProps}
        />
      );
    case "tableButton":
      return (
        <AssembleButton
          onClick={onClick}
          variant="outlined"
          size="small"
          startIcon={
            loading ? undefined : (
              <DownloadExportIcon {...getIconProps({ size: "small" })} />
            )
          }
          {...props.buttonProps}
        >
          {loading && <CircularProgress />}
        </AssembleButton>
      );
  }
}
