import { Box, makeStyles } from "@material-ui/core";
import { ReactNode } from "react";
import { GRAY_6 } from "../../theme";

type Props = {
  children: ReactNode;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderLeft: `1px solid ${GRAY_6}`,
  },
}));

export function ActionBarItem({ children }: Props): JSX.Element {
  const classes = useStyles();
  return <Box className={classes.root}>{children}</Box>;
}
