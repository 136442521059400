import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const HourlyDotIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Dot"
      viewBox="0 0 25 25"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="fill" x="0.5" y="0.5" width="24" height="24" rx="12" />
    </svg>
  </ColorableSVG>
);
