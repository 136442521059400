import { makeStyles } from "@material-ui/core";
import { GRAY_6 } from "../../../theme";

const useBarStyles = makeStyles(() => ({
  /*
   * Comp Band Sliders generally do not scale with the font-size of the page,
   * and so most measurements are in pixels rather than rem.
   */
  root: {
    width: "100%",
    height: "25px",
    background: GRAY_6,
    borderRadius: "2px",
  },
}));

export function EmptyBar(): JSX.Element {
  const classes = useBarStyles();
  return <div className={classes.root} />;
}
