import { Card, makeStyles } from "@material-ui/core";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { PlusCircleIcon } from "src/components/AssembleIcons/Brand/PlusCircleIcon";
import { CloseCircleIcon } from "src/components/AssembleIcons/Small/CloseCircleIcon";
import { PeopleIcon } from "src/components/AssembleIcons/Small/PeopleIcon";
import { TrashIcon } from "src/components/AssembleIcons/Small/TrashIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_1, GRAY_4, GRAY_6, PURPLE_2 } from "src/theme";
import { useMultipleMeritMatrices } from "../useMultipleMatrices";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button: {
    "&:hover": {
      border: "1px solid transparent",
      boxShadow: "none",
    },
  },
  card: {
    padding: theme.spacing(2),

    boxShadow: "none",
    border: `1px solid ${GRAY_6}`,

    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardHeaderGroupInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(0.5),
  },
  cardHeaderGroupAction: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1.5),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  rules: {
    border: `1px solid ${GRAY_6}`,
    borderRadius: "8px",
    padding: theme.spacing(1.5),
  },
  rulesHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export function GuidanceDetailedView(): JSX.Element | null {
  const classes = useStyles();
  const { selectedMatrix, addRule, removeRule, addGuidance, addMeritBudget } =
    useMultipleMeritMatrices();

  if (selectedMatrix == null) return null;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <AssembleTypography variant="h4" textColor={GRAY_1}>
          {selectedMatrix.name}
        </AssembleTypography>
        <AssembleButton
          className={classes.button}
          size="small"
          variant="text"
          startIcon={<PeopleIcon color={PURPLE_2} />}
          label={`Eligible People (count)`}
        />
      </div>
      <Card className={classes.card}>
        <div className={classes.cardHeader}>
          <div className={classes.cardHeaderGroupInfo}>
            <AssembleButton
              className={classes.button}
              size="small"
              variant="text"
              startIcon={<CloseCircleIcon color={GRAY_4} />}
            />
            <AssembleTypography variant="productSectionHeader">
              Eligibility
            </AssembleTypography>
          </div>
          <div className={classes.cardHeaderGroupAction}>
            <AssembleButton
              size="small"
              variant="outlined"
              label="Filter by Email"
            />
            <AssembleButton
              size="small"
              variant="contained"
              label="Add Rule"
              onClick={() => addRule("testing")}
            />
          </div>
        </div>
        {selectedMatrix.eligibilityRules.length > 0 && (
          <div className={classes.cardContent}>
            <div className={classes.rules}>
              {selectedMatrix.eligibilityRules.map((_rule, index) => (
                <div key={index} className={classes.rulesHeader}>
                  <AssembleTypography
                    variant="productParagraphSmall"
                    textColor={GRAY_1}
                  >
                    Rule {index + 1}
                  </AssembleTypography>
                  <AssembleButton
                    className={classes.button}
                    size="small"
                    variant="text"
                    startIcon={<TrashIcon color={GRAY_4} />}
                    onClick={() => removeRule(index)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </Card>

      <Card className={classes.card}>
        <div className={classes.cardHeader}>
          <div className={classes.cardHeaderGroupInfo}>
            <AssembleButton
              className={classes.button}
              size="small"
              variant="text"
              startIcon={
                selectedMatrix.guidances.length === 0 ? (
                  <PlusCircleIcon color={GRAY_4} />
                ) : (
                  <CloseCircleIcon color={GRAY_4} />
                )
              }
              onClick={() => addGuidance("testing")}
            />
            <AssembleTypography variant="productSectionHeader">
              Merit Guidance
            </AssembleTypography>
          </div>
        </div>
      </Card>

      <Card className={classes.card}>
        <div className={classes.cardHeader}>
          <div className={classes.cardHeaderGroupInfo}>
            <AssembleButton
              className={classes.button}
              size="small"
              variant="text"
              startIcon={
                selectedMatrix.budgets.length === 0 ? (
                  <PlusCircleIcon color={GRAY_4} />
                ) : (
                  <CloseCircleIcon color={GRAY_4} />
                )
              }
              onClick={() => addMeritBudget("testing")}
            />
            <AssembleTypography variant="productSectionHeader">
              Budget
            </AssembleTypography>
          </div>
        </div>
      </Card>
    </div>
  );
}
