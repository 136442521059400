import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const TargetIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2187_106648)">
        <path
          className="stroke"
          d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z"
          className="stroke"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="stroke"
          d="M8 9.75C8.9665 9.75 9.75 8.9665 9.75 8C9.75 7.0335 8.9665 6.25 8 6.25C7.0335 6.25 6.25 7.0335 6.25 8C6.25 8.9665 7.0335 9.75 8 9.75Z"
        />
        <line x1="8" x2="8" y2="16" stroke="#88A4BE" strokeWidth="0.5" />
        <line
          x1="16"
          y1="8"
          x2="-2.18557e-08"
          y2="8"
          stroke="#88A4BE"
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_2187_106648">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </ColorableSVG>
);
