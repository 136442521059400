import { Box } from "@material-ui/core";
import { EmptyTableView } from "src/components/EmptyTableView";
import EmptyNoReportsSvg from "../../assets/svgs/illustrations/empty-state-no-reports.svg?react";
import { useAuth } from "../../components/Auth/AuthContext";
import { TableContextProvider } from "./Context/TableContext";
import { ReportsLoadingBoundary as ReportsLoadingBoundary2 } from "./YourTeam/ReportsLoadingBoundary";

export function YourTeam(): JSX.Element {
  const { employeeId, permissions } = useAuth();

  const isHRBP = permissions.isHRBP();

  if (employeeId === null && !isHRBP) {
    return <ReportsEmptyState />;
  }

  let managerId = employeeId;

  if (
    managerId == null &&
    isHRBP &&
    // This should always be true if isHRBP is true, but typescript isn't
    // catching that
    permissions.nounScopes?.Employee.view?.supportingManagerEmployeeIDs != null
  ) {
    managerId =
      permissions.nounScopes.Employee.view.supportingManagerEmployeeIDs[0];
  }

  // This should always be a number by this point, but again, typescript
  if (managerId == null) return <ReportsEmptyState />;

  return (
    <TableContextProvider>
      <ReportsLoadingBoundary2 managerId={managerId} />
    </TableContextProvider>
  );
}

function ReportsEmptyState(): JSX.Element {
  return (
    <Box pt={10}>
      <EmptyTableView
        message={
          <>
            We couldn't find anyone that reports to you. Please contact your
            Assemble administrator if you feel something is awry.
          </>
        }
        svg={<EmptyNoReportsSvg />}
        width="30%"
      />
    </Box>
  );
}
