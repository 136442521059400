//  -- Components ---------------------
import { Box, Typography } from "@material-ui/core";
import { memo, ReactNode } from "react";
//  -- Style --------------------------
import { headerStyles } from "../style";

//  ----------------------------------------------------------------------------
//  Types
//  ----------------------------------------------------------------------------
export type HeaderProps = {
  color: string;
  description?: string;
  headline: string;
  subHead: string;
  legend?: ReactNode;
};

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
const Header = ({
  color,
  description,
  headline,
  subHead,
  legend,
}: HeaderProps) => {
  const classes = headerStyles();

  return (
    <Box
      className={classes.container}
      position="absolute"
      left={64}
      top={148}
      width="100%"
    >
      <Box alignItems="flex-start" display="flex" flexDirection="column">
        <Typography className={classes.overline} style={{ color: color }}>
          {subHead}
        </Typography>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingRight={16}
        >
          <Typography className={classes.header}>{headline}</Typography>
          {legend}
        </Box>
        <Typography className={classes.subheader}>{description}</Typography>
      </Box>
    </Box>
  );
};

export default memo(Header);
