import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const NoSupportedManagersIcon = ({
  height = "156px",
  width = "156px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 156 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M117 132V122.048C117 122.048 133.932 105.278 141.089 98.1609C141.695 97.5565 142.176 96.8382 142.504 96.0473C142.832 95.2565 143.001 94.4086 143 93.5524V43.8469C143 43.8469 126.75 37.9514 126.75 83.2499L107.452 102.548L111.923 83.0484C114.153 73.7014 105.612 69.7039 100.952 76.9514C96.8627 85.2151 93.5319 93.8325 91 102.698V132"
        stroke="#88A4BE"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39 132V122.049C39 122.049 22.9775 105.194 15.1385 98.1026C14.4647 97.4928 13.9263 96.7486 13.5579 95.9178C13.1896 95.0871 12.9995 94.1883 13 93.2796V43.8796C13 43.8796 29.25 37.9516 29.25 83.2501L48.5485 102.549L44.0765 83.0486C41.8405 73.7016 50.388 69.7041 55.0485 76.9516C59.1373 85.2152 62.4681 93.8327 65 102.698V132"
        stroke="#88A4BE"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M120.25 132H87.75C85.9551 132 84.5 133.455 84.5 135.25V148.25C84.5 150.045 85.9551 151.5 87.75 151.5H120.25C122.045 151.5 123.5 150.045 123.5 148.25V135.25C123.5 133.455 122.045 132 120.25 132Z"
        fill="#EEF5FB"
        stroke="#88A4BE"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.25 132H35.75C33.9551 132 32.5 133.455 32.5 135.25V148.25C32.5 150.045 33.9551 151.5 35.75 151.5H68.25C70.0449 151.5 71.5 150.045 71.5 148.25V135.25C71.5 133.455 70.0449 132 68.25 132Z"
        fill="#EEF5FB"
        stroke="#88A4BE"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78 41C82.936 41 86.9375 36.9985 86.9375 32.0625C86.9375 27.1265 82.936 23.125 78 23.125C73.064 23.125 69.0625 27.1265 69.0625 32.0625C69.0625 36.9985 73.064 41 78 41Z"
        fill="#DAEAF9"
        stroke="#88A4BE"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.375 55.625C89.375 52.6082 88.1766 49.7149 86.0433 47.5817C83.9101 45.4484 81.0168 44.25 78 44.25C74.9832 44.25 72.0899 45.4484 69.9567 47.5817C67.8234 49.7149 66.625 52.6082 66.625 55.625V65.375H71.7698L73.125 81.625H82.875L84.2302 65.375H89.375V55.625Z"
        fill="#DAEAF9"
        stroke="#88A4BE"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.375 19.5C54.4136 19.5 57.6875 16.2261 57.6875 12.1875C57.6875 8.14892 54.4136 4.875 50.375 4.875C46.3364 4.875 43.0625 8.14892 43.0625 12.1875C43.0625 16.2261 46.3364 19.5 50.375 19.5Z"
        fill="#DAEAF9"
        stroke="#88A4BE"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.375 22.75C47.7891 22.75 45.3092 23.7772 43.4807 25.6057C41.6522 27.4342 40.625 29.9141 40.625 32.5V40.625H45.5L47.125 55.25H53.625L55.25 40.625H60.125V32.5C60.125 29.9141 59.0978 27.4342 57.2693 25.6057C55.4408 23.7772 52.9609 22.75 50.375 22.75Z"
        fill="#DAEAF9"
        stroke="#88A4BE"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.625 19.5C109.664 19.5 112.938 16.2261 112.938 12.1875C112.938 8.14892 109.664 4.875 105.625 4.875C101.586 4.875 98.3125 8.14892 98.3125 12.1875C98.3125 16.2261 101.586 19.5 105.625 19.5Z"
        fill="#DAEAF9"
        stroke="#88A4BE"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.625 22.75C103.039 22.75 100.559 23.7772 98.7307 25.6057C96.9022 27.4342 95.875 29.9141 95.875 32.5V40.625H100.75L102.375 55.25H108.875L110.5 40.625H115.375V32.5C115.375 29.9141 114.348 27.4342 112.519 25.6057C110.691 23.7772 108.211 22.75 105.625 22.75Z"
        fill="#DAEAF9"
        stroke="#88A4BE"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.2">
        <rect x="101" y="98" width="50" height="50" rx="25" fill="#88A4BE" />
        <g clipPath="url(#clip0_2769_12495)">
          <path
            d="M126 132C130.971 132 135 127.971 135 123C135 118.029 130.971 114 126 114C121.029 114 117 118.029 117 123C117 127.971 121.029 132 126 132Z"
            stroke="#88A4BE"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M122.25 123H129.75"
            stroke="#88A4BE"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M126 119.25V126.75"
            stroke="#88A4BE"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <rect x="106.5" y="103.5" width="49" height="49" rx="24.5" fill="white" />
      <rect
        x="106.5"
        y="103.5"
        width="49"
        height="49"
        rx="24.5"
        stroke="#FF385C"
      />
      <path d="M114 111L148 145" stroke="#FF385C" />
      <defs>
        <clipPath id="clip0_2769_12495">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(114 111)"
          />
        </clipPath>
      </defs>
    </svg>
  </ColorableSVG>
);
