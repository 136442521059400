import { CashBandName } from "../constants";
import { GRAY_4 } from "../theme";
import { CashCompType } from "../__generated__/graphql";
import { InfoIcon } from "./AssembleIcons/Brand/InfoIcon";
import { ExplanatoryTooltip } from "./ExplanatoryTooltip";

export function NoCashInfo({ type }: { type: CashCompType }): JSX.Element {
  const name = CashBandName[type];
  return (
    <ExplanatoryTooltip
      title="No variable comp found"
      placement="top-end"
      body={
        `This position has a ${name} band, ` +
        `but this employee does not have any ${name.toLowerCase()} data.`
      }
      ctaUrl="/settings/data"
      ctaLabel="Click here to add some"
    >
      <span>
        <InfoIcon color={GRAY_4} width={"12px"} height={"12px"} />
      </span>
    </ExplanatoryTooltip>
  );
}
