import { makeStyles, Tab, TabProps } from "@material-ui/core";
import clsx from "clsx";
import { ChangeEvent, ReactNode } from "react";
import { GRAY_1, GRAY_4, GRAY_6, WHITE } from "../../theme";
import { AssembleTypography } from "../AssembleTypography";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    maxWidth: "none",
    background: WHITE,
    borderRadius: "0px",
    borderTop: `1px solid ${GRAY_6}`,
    borderRight: "none",
    borderBottom: `1px solid ${GRAY_6}`,
    borderLeft: `1px solid ${GRAY_6}`,

    "&:hover $subheader": {
      color: GRAY_1,
    },

    "&.Mui-selected $subheader": {
      color: GRAY_1,
    },

    "&:last-child": {
      borderRight: `1px solid ${GRAY_6}`,
    },
  },
  label: {
    height: "100%",
    width: "100%",
    textAlign: "left",
    paddingTop: theme.spacing(3.125),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(2.25),
    paddingLeft: theme.spacing(2.5),
  },
  header: { color: GRAY_4, marginBottom: theme.spacing(1) },
  subheader: { color: GRAY_4 },
}));

export type AssembleTabProps<T> = Omit<
  TabProps,
  "value" | "onChange" | "label"
> & {
  header: ReactNode;
  subheader: ReactNode;
  value: T;
  selected: boolean;
  onChange: (_: ChangeEvent<unknown>, value: T) => unknown;
  decorator?: React.ReactNode | false;
};

export function AssembleTab<T>({
  value,
  header,
  subheader,
  selected,
  onChange,
  decorator,
  ...rest
}: AssembleTabProps<T>): JSX.Element {
  const classes = useStyles();

  return (
    <Tab
      {...rest}
      className={clsx(classes.root, rest.className)}
      label={
        <div className={classes.label}>
          <AssembleTypography
            className={classes.header}
            variant="productTabHeader"
          >
            {header}
          </AssembleTypography>
          {decorator}
          <AssembleTypography
            className={classes.subheader}
            variant="productTabSubheader"
          >
            {subheader}
          </AssembleTypography>
        </div>
      }
      value={value}
      selected={selected}
      onChange={onChange}
    />
  );
}
