// executes a global, case-insensitive search for the `needle` in `haystack`.
export function searchStringsForMatch(
  haystack: string,
  needle: string
): boolean {
  // check if the user has included commas
  const updatedNeedle = needle.replace(/,/, "");
  // create an array to check if the search is multiple words
  const needleArr = updatedNeedle.split(" ");

  // check that we find all the search words in haystack
  return needleArr.every((searchWord) => {
    // Let's prevent the user from typing in any regex values in the search bar
    // From: https://stackoverflow.com/a/6969486/6000276
    const cleanedUpNeedle = searchWord.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    // \b makes sure we match strings only at word boundaries
    const searchRegEx = new RegExp(`\\b(${cleanedUpNeedle})`, "gi");
    return searchRegEx.test(haystack);
  });
}
