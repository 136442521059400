import { Box, makeStyles } from "@material-ui/core";
import emptyCompensation from "../../assets/svgs/illustrations/empty-compensation.svg";
import { GRAY_4, WHITE } from "../../theme";
import { AssembleTypography } from "../AssembleTypography";

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    display: "flex",
    backgroundColor: WHITE,
    boxShadow: "0px 1px 3px rgba(10, 36, 64, 0.1)",
    borderRadius: "2px",
    padding: "4px 12px",
  },
  text: {
    fontSize: ".85rem",
    color: GRAY_4,
  },
}));

export const CompensationEmptyWarning = ({
  noAccess,
}: {
  noAccess: boolean;
}): JSX.Element => {
  const classNames = useStyles();
  return (
    <Box className={classNames.container}>
      <Box component="div" className={classNames.box}>
        <Box display="flex" alignItems="center" px="4px">
          <img src={emptyCompensation} alt="No Compensation" />
        </Box>

        <Box>
          <AssembleTypography className={classNames.text}>
            {noAccess
              ? "You do not have access to this compensation information"
              : "We couldn’t find any compensation information"}
          </AssembleTypography>
        </Box>
      </Box>
    </Box>
  );
};
