import { makeStyles } from "@material-ui/core";
import { ReactElement } from "react";
import { AssembleTypography } from "../../components/AssembleTypography";

const useStyles = makeStyles((theme) => ({
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: "flex",
    gap: theme.spacing(2),
  },
}));

export function CompensationHeading({
  actions,
}: {
  actions: ReactElement;
}): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.heading}>
      <AssembleTypography variant="h3">Compensation</AssembleTypography>
      <div className={classes.actions}>{actions}</div>
    </div>
  );
}
