import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import { MultipleMeritMatricesLoadingBoundaryQuery } from "src/__generated__/graphql";
import { ErrorView } from "src/components/ErrorView";
import { MultipleMeritMatrices } from "./MultipleMeritMatrices";
import { MultipleMeritMatricesProvider } from "./useMultipleMatrices";

type Props = { compCycleId?: number };

export function MultipleMeritMatricesLoadingBoundary({
  compCycleId,
}: Props): JSX.Element {
  const { data, loading } = useQuery<MultipleMeritMatricesLoadingBoundaryQuery>(
    MultipleMeritMatricesLoadingBoundary.query,
    { variables: { compCycleId } }
  );

  if (data == null)
    return loading ? (
      <CircularProgress />
    ) : (
      <ErrorView text="Something went wrong. Please refresh the page to try again" />
    );

  return (
    <MultipleMeritMatricesProvider
      initValue={{
        totalEligible: data.compCycle2?.participantEligibility.counts.eligible,
      }}
    >
      <MultipleMeritMatrices />
    </MultipleMeritMatricesProvider>
  );
}

MultipleMeritMatricesLoadingBoundary.query = gql`
  query MultipleMeritMatricesLoadingBoundaryQuery($compCycleId: Int!) {
    compCycle2(id: $compCycleId) {
      id
      participantEligibility {
        counts {
          eligible
        }
      }
    }
  }
`;
