import { Box, makeStyles, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ReactNode } from "react";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { NoResultsIcon } from "src/components/AssembleIcons/Brand/NoResultsIcon";
import { GRAY_4 } from "src/theme";
import { AssemblePopper } from "../../AssemblePopper";

const useStyles = makeStyles(() => ({
  popupIndicator: {
    height: "100%",
    transition: "transform cubic-bezier(0.4, 0, 0.2, 1) 275ms",
  },
}));

export interface AssembleAutocompleteProps<T, G> {
  options: T[];
  value: T | null;
  label: string;
  placeholder: string;
  noOptionsText?: string;
  className?: string;
  classes?: { input?: string; tableCell?: string };
  disableClearable?: boolean;
  disabled?: boolean;
  onChange: (newValue: T | null) => unknown;
  getOptionLabel: (option: T) => string;
  renderOption?: (option: T) => ReactNode;

  // if using groups
  getOptionGroup?: (option: T) => G;
  renderGroup?: (group: G) => ReactNode;
  /* Autocomplete can only handle string groups,
     so provide a way to transform back and forth */
  encodeGroup?: (group: G) => string;
  decodeGroup?: (encoded: string) => G;
}

export function AssembleAutocomplete<T, G>(
  props: AssembleAutocompleteProps<T, G>
): JSX.Element {
  const classes = useStyles();

  return (
    <Autocomplete
      classes={{ popupIndicator: classes.popupIndicator }}
      PopperComponent={AssemblePopper}
      className={props.className}
      options={props.options}
      getOptionLabel={props.getOptionLabel}
      popupIcon={<ChevronDownIcon inherit />}
      renderGroup={(params) => (
        <>
          {props.renderGroup != null && props.decodeGroup != null
            ? props.renderGroup(props.decodeGroup(params.group))
            : undefined}
          {params.children}
        </>
      )}
      groupBy={(option) =>
        props.encodeGroup == null || props.getOptionGroup == null
          ? ""
          : props.encodeGroup(props.getOptionGroup(option))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          placeholder={props.placeholder}
          variant="outlined"
        />
      )}
      renderOption={props.renderOption}
      disableClearable={props.disableClearable}
      onChange={(_, value) => {
        if (props.disableClearable === true && value === null) {
          return;
        }
        props.onChange(value);
      }}
      value={props.value}
      noOptionsText={
        <Box display="flex" flexDirection="column" alignItems="center" p={1}>
          <Typography>
            <NoResultsIcon color={GRAY_4} height="48px" width="48px" />
          </Typography>
          <Typography>
            {props.noOptionsText ??
              "We can't find any options matching your query."}
          </Typography>
        </Box>
      }
    />
  );
}
