import { gql } from "@apollo/client";
import { isValidDate } from "@asmbl/shared/utils";
import {
  Box,
  Drawer,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { NotesIcon } from "src/components/AssembleIcons/Brand/NotesIcon";
import { GRAY_4, PURPLE_2 } from "src/theme";
import {
  LocationGroupDetails_locationGroup as LocationGroup,
  LocationGroupDetails_locations as Location,
  LocationGroupDetails_markets as Market,
} from "../../../__generated__/graphql";
import { DrawerTitle } from "../DrawerTitle";
import { LocationGroupEditor } from "./LocationGroupEditor";
import { LocationsDisplayList } from "./LocationsDisplayList";

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    width: "330px",
    padding: theme.spacing(3),
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  listContainer: {
    marginTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(4),
  },
}));

type Props = {
  market: Market;
  locationGroup: Omit<LocationGroup, "createdAt" | "updatedAt"> &
    Partial<Pick<LocationGroup, "createdAt" | "updatedAt">>;
  locations: Location[];
  editingDisabled?: boolean;
};

export function LocationGroupDetails({
  market,
  locationGroup,
  locations,
  editingDisabled = false,
}: Props): JSX.Element {
  const classes = useStyles();

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const openDetails = () => setIsDetailsOpen(true);
  const handleCloseDetails = () => setIsDetailsOpen(false);

  const {
    name,
    description,
    author,
    id,
    locations: locationGroupLocations,
  } = locationGroup;
  const createdAt = locationGroup.createdAt as string;
  const updatedAt = locationGroup.updatedAt as string;

  const hasValidTimestamps = isValidDate(createdAt) && isValidDate(updatedAt);
  return (
    <>
      <Tooltip title="View Details" placement="top">
        <IconButton size="small" onClick={openDetails}>
          <NotesIcon
            color={GRAY_4}
            hoverColor={PURPLE_2}
            height={"24px"}
            width={"24px"}
          />
        </IconButton>
      </Tooltip>
      <Drawer anchor="left" open={isDetailsOpen} onClose={handleCloseDetails}>
        <Box className={classes.drawerContainer}>
          <Box className={classes.titleContainer}>
            <DrawerTitle
              title={`${name} Details`}
              onClose={handleCloseDetails}
            />
            {!editingDisabled && (
              <LocationGroupEditor
                market={market}
                existingLocationGroup={locationGroup}
                locations={locations}
              />
            )}
          </Box>
          <Box m={3.5} />
          <Typography variant="h6">Description</Typography>
          <Box m={1.56} />
          <Typography variant="body1">{description}</Typography>
          <Box m={3} />
          {hasValidTimestamps && (
            <>
              <Typography variant="h6">History</Typography>
              <Box m={1.5} />
              <Typography variant="body2">
                Last updated on {new Date(updatedAt).toLocaleString()}
              </Typography>
              <Box m={1} />
              <Typography variant="body2">
                Created by <strong>{author?.name}</strong> on{" "}
                {new Date(createdAt).toLocaleDateString()}
              </Typography>
            </>
          )}
          <Box m={3} />
          {locationGroupLocations.length > 0 && (
            <>
              <Typography variant="h6">HRIS Locations</Typography>
              <Box m={1.5} />
            </>
          )}
          <LocationsDisplayList locations={locations} locationGroupId={id} />
        </Box>
      </Drawer>
    </>
  );
}

LocationGroupDetails.fragments = {
  locations: gql`
    ${LocationGroupEditor.fragments.locations}
    ${LocationsDisplayList.fragments.locations}
    fragment LocationGroupDetails_locations on Location {
      ...LocationGroupEditor_locations
      ...LocationsDisplayList_locations
      id
    }
  `,
  locationGroup: gql`
    ${LocationGroupEditor.fragments.locationGroup}
    fragment LocationGroupDetails_locationGroup on LocationGroup {
      ...LocationGroupEditor_locationGroup
      id
      createdAt
      updatedAt
      author {
        id
        name
      }
    }
  `,
  markets: gql`
    ${LocationGroupEditor.fragments.markets}
    fragment LocationGroupDetails_markets on Market {
      ...LocationGroupEditor_markets
      id
    }
  `,
};
