import { formatCurrency, Money } from "@asmbl/shared/money";
import { makeStyles, Tooltip } from "@material-ui/core";
import { formatNumber } from "accounting";
import { memo, ReactNode } from "react";
import { AssembleTypography } from "../AssembleTypography";

type Props = {
  cash: { name: string; annualValue: Money; hourlyValue: Money }[];
  children: ReactNode;
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
    gap: "0.5rem",
  },
}));

export const TotalCashCellTooltip = memo(function TotalCashCellTooltip({
  cash,
  children,
}: Props): JSX.Element {
  const classes = useStyles();
  return cash.length === 0 ? (
    <>{children}</>
  ) : (
    <Tooltip
      placement="top"
      title={cash.map((item) => (
        <div key={item.name} className={classes.container}>
          <div className={classes.item}>
            <AssembleTypography variant="productSmall">
              {item.name}:
            </AssembleTypography>
            <AssembleTypography variant="productSmallBold">
              {formatCurrency(item.annualValue, { maximumFractionDigits: 0 })}
            </AssembleTypography>
          </div>
        </div>
      ))}
    >
      <span>{children}</span>
    </Tooltip>
  );
});

type WorkingHoursTooltipProps = {
  workingHours: number;
  children: ReactNode;
};

export const TotalWorkingHoursTooltip = memo(function TotalCashCellTooltip({
  workingHours,
  children,
}: WorkingHoursTooltipProps): JSX.Element {
  const classes = useStyles();
  return (
    <Tooltip
      placement="top"
      title={
        <div className={classes.container}>
          <div className={classes.item}>
            <AssembleTypography variant="productSmall">
              Based on a {formatNumber(workingHours)} hr work year
            </AssembleTypography>
          </div>
        </div>
      }
    >
      <span>{children}</span>
    </Tooltip>
  );
});
