import { gql } from "@apollo/client";
import { formatNumeral } from "@asmbl/shared/utils";
import { TableCell } from "@material-ui/core";
import { EquityDisplayCell_EquityHoldings as EquityHoldings } from "../../__generated__/graphql";
import { getSimpleCashLabel } from "../../models/Currency";
import { AssembleTypography } from "../AssembleTypography";

export type EquityDisplayType = "units" | "value";

type Props = {
  equityHoldings: EquityHoldings;
  mode: EquityDisplayType;
  textColor?: string;
  className?: string;
};

export function EquityDisplayCell({
  equityHoldings: { totalUnitCount, totalGrossHoldingValue },
  mode,
  textColor = "inherit",
  className,
}: Props): JSX.Element {
  let display: string;

  if (mode === "units") {
    display = totalUnitCount === 0 ? "-" : formatNumeral(totalUnitCount);
  } else {
    display =
      totalGrossHoldingValue === null || totalGrossHoldingValue.value === 0
        ? "-"
        : getSimpleCashLabel(totalGrossHoldingValue);
  }
  return (
    <TableCell className={className} role="gridcell" align={"right"}>
      <AssembleTypography variant="productSmall" textColor={textColor}>
        {display}
      </AssembleTypography>
    </TableCell>
  );
}

EquityDisplayCell.fragments = {
  equityHoldings: gql`
    fragment EquityDisplayCell_EquityHoldings on EquityHoldings2 {
      totalGrossHoldingValue
      totalUnitCount
    }
  `,
};
