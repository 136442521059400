import { TypedDocumentNode } from "@apollo/client";
import { dollars, money } from "@asmbl/shared/money";
import {
  AvailableRoleScopes,
  Noun,
  RoleName,
  Verb,
} from "@asmbl/shared/permissions";
import { plainDateToUTCString } from "@asmbl/shared/time";
import {
  AdjustedEquityBand,
  BandUnit,
  BenefitsPackageFields,
  BusinessStructure,
  CashCompType,
  CompRecommendationStatus,
  CompUnit,
  CurrencyCode,
  CustomFieldVariant,
  EmploymentStatus,
  EquityGrantMethod,
  EquityGrantTypes,
  EquityHoldings2,
  GetCompStructure,
  GetEquitySettings,
  GetOffer,
  GetOrganization,
  GetValuation,
  NounScopesFields,
  PayPeriodType,
  PositionType,
  PrimaryRoleName,
  RecItemType,
  RelativeCompRecStatus,
} from "./__generated__/graphql";
import { DEFAULT_USD } from "./components/CurrenciesContext";
import { OfferData } from "./components/OfferGeneration/types";
import { CashBandName, EquityBandName } from "./constants";
import { UserPermissions } from "./models/UserPermissions";
import { NonNull } from "./utils";

type CompStructure = NonNull<GetCompStructure["compStructure"]>;
type OfferConfig = NonNull<GetEquitySettings["offerConfig"]>;
type Offer = GetOffer["offer"];
type Organization = GetOrganization["organization"];
type Valuation = NonNull<GetValuation["valuation"]>;

/**
 * A custom type that adds better type checking for an Apollo MockedResponse.
 */
export type ApolloMock<ApolloResponse> = {
  request: {
    query: TypedDocumentNode;
    variables: Record<string, unknown>;
  };
  result: {
    data: ApolloResponse | null;
  };
};

/**
 * An empty no-operation helper method.
 */
export function noop(): void {
  // Do nothing
}

export function promiseNoop(): Promise<void> {
  return Promise.resolve();
}

const globalScope = {
  global: true,
  departmentIDs: [],
  ladderIDs: [],
  positionIDs: [],
};

export const AVAILABLE_ROLES: Record<
  Exclude<RoleName, RoleName.Manager | RoleName.CompensationBandViewer>,
  UserPermissions
> = {
  [RoleName.FullAccess]: new UserPermissions(
    PrimaryRoleName.FULL_ACCESS,
    {
      ...AvailableRoleScopes.FULL_ACCESS,
      // Until the client is set up to use the full Market scope, it still
      // expects the old JobStructureScope.
      [Noun.CashBand]: {
        [Verb.Edit]: globalScope,
        [Verb.View]: globalScope,
      },
      [Noun.EquityBand]: {
        [Verb.Edit]: globalScope,
        [Verb.View]: globalScope,
      },
    } as unknown as NounScopesFields,
    false
  ),
  [RoleName.SystemAdmin]: new UserPermissions(
    PrimaryRoleName.SYSTEM_ADMIN,
    AvailableRoleScopes.SYSTEM_ADMIN as NounScopesFields,
    false
  ),
  [RoleName.Recruiter]: new UserPermissions(
    PrimaryRoleName.RECRUITER,
    AvailableRoleScopes.RECRUITER as NounScopesFields,
    false
  ),
  [RoleName.BasicViewer]: new UserPermissions(
    PrimaryRoleName.BASIC_VIEWER,
    AvailableRoleScopes.BASIC_VIEWER as NounScopesFields,
    false
  ),
  [RoleName.Employee]: new UserPermissions(
    PrimaryRoleName.EMPLOYEE,
    AvailableRoleScopes.EMPLOYEE as NounScopesFields,
    true
  ),
};

export const MOCK_MARKET = {
  __typename: "Market" as const,
  id: 1,
  name: "Primary Market",
  currencyCode: CurrencyCode.USD,
  locationGroups: [],
};

export const MOCK_COMP_COMPONENT_SETTINGS = {
  __typename: "CompCycle" as const,
  id: 1,
  allowSalary: true,
  allowEquity: true,
  allowBonus: true,
  allowSalaryMerit: true,
  allowSalaryMarket: true,
  allowSalaryPromotion: true,
  allowTargetCommission: true,
  allowTargetRecurringBonus: true,
  allowActualRecurringBonus: true,
  finalApproversIds: [],
  budgetSpends: {
    __typename: "BudgetSpendsType" as const,
    ACTUAL_RECURRING_BONUS: money(0, CurrencyCode.USD),
    EQUITY_GRANT: money(0, CurrencyCode.USD),
    MARKET: money(0, CurrencyCode.USD),
    MERIT_BONUS: money(0, CurrencyCode.USD),
    MERIT_INCREASE: money(0, CurrencyCode.USD),
    PROMOTION: money(0, CurrencyCode.USD),
    TARGET_COMMISSION: money(0, CurrencyCode.USD),
    TARGET_COMMISSION_INCREMENTAL: money(0, CurrencyCode.USD),
    TARGET_RECURRING_BONUS: money(0, CurrencyCode.USD),
    TARGET_RECURRING_BONUS_INCREMENTAL: money(0, CurrencyCode.USD),
  },
  endedAt: null,
  name: "mock-comp-cycle",
  recommendationsPreFill: false,
  phases: [],
  currentPhase: null,
};

export const MOCK_ACTIVE_COMP_CYCLE = {
  __typename: "CompCycle" as const,
  id: 1,
  name: "CompCycle 1",
  endedAt: null,
};

export const MOCK_POSITION_RECORD = {
  __typename: "Position" as const,
  id: 1,
  name: "Test Position 1",
  level: 1,
  description: "A position to test",
  jobCodes: [],
  type: "ANNUAL",
  ladder: {
    __typename: "Ladder" as const,
    id: 1,
    name: "Ladder",
    department: { id: 1 },
  },
  adjustedCashBands: [
    {
      __typename: "AdjustedCashBand" as const,
      id: "1:1:Source",
      name: "Salary",
      bandPoints: [
        {
          __typename: "AdjustedCashBandPoint" as const,
          id: "1:1:Source",
          name: "Min",
          bandName: "Salary",
          value: {
            __typename: "CashValue" as const,
            annualRate: money(15_000, CurrencyCode.USD),
            hourlyRate: money(15_000 / 2080, CurrencyCode.USD),
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: money(15_000, CurrencyCode.USD),
          hourlyCashEquivalent: money(15_000 / 2080, CurrencyCode.USD),
        },
        {
          __typename: "AdjustedCashBandPoint" as const,
          id: "2:1:Source",
          name: "Low-Mid",
          bandName: "Salary",
          value: {
            __typename: "CashValue" as const,
            annualRate: money(25_000, CurrencyCode.USD),
            hourlyRate: money(25_000 / 2080, CurrencyCode.USD),
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: money(25_000, CurrencyCode.USD),
          hourlyCashEquivalent: money(25_000 / 2080, CurrencyCode.USD),
        },
        {
          __typename: "AdjustedCashBandPoint" as const,
          id: "3:1:Source",
          name: "Mid-High",
          bandName: "Salary",
          value: {
            __typename: "CashValue" as const,
            annualRate: money(35_000, CurrencyCode.USD),
            hourlyRate: money(35_000 / 2080, CurrencyCode.USD),
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: money(35_000, CurrencyCode.USD),
          hourlyCashEquivalent: money(35_000 / 2080, CurrencyCode.USD),
        },
        {
          __typename: "AdjustedCashBandPoint" as const,
          id: "4:1:Source",
          name: "Max",
          bandName: "Salary",
          value: {
            __typename: "CashValue" as const,
            annualRate: money(40_000, CurrencyCode.USD),
            hourlyRate: money(40_000 / 2080, CurrencyCode.USD),
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: money(40_000, CurrencyCode.USD),
          hourlyCashEquivalent: money(40_000 / 2080, CurrencyCode.USD),
        },
      ],
    },
    {
      __typename: "AdjustedCashBand" as const,
      id: "2:1:Source",
      name: "Commission",
      bandPoints: [
        {
          __typename: "AdjustedCashBandPoint" as const,
          id: "5:1:Source",
          name: "Min",
          bandName: "Commission",
          value: {
            __typename: "CashValue" as const,
            annualRate: money(30_000, CurrencyCode.USD),
            hourlyRate: money(30_000 / 2080, CurrencyCode.USD),
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: money(30_000, CurrencyCode.USD),
          hourlyCashEquivalent: money(30_000 / 2080, CurrencyCode.USD),
        },
        {
          __typename: "AdjustedCashBandPoint" as const,
          id: "6:1:Source",
          name: "Low-Mid",
          bandName: "Commission",
          value: {
            __typename: "CashValue" as const,
            annualRate: money(37_500, CurrencyCode.USD),
            hourlyRate: money(37_500 / 2080, CurrencyCode.USD),
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: money(37_500, CurrencyCode.USD),
          hourlyCashEquivalent: money(37_500 / 2080, CurrencyCode.USD),
        },
        {
          __typename: "AdjustedCashBandPoint" as const,
          id: "7:1:Source",
          name: "Mid-High",
          bandName: "Commission",
          value: {
            __typename: "CashValue" as const,
            annualRate: money(42_500, CurrencyCode.USD),
            hourlyRate: money(42_500 / 2080, CurrencyCode.USD),
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: money(42_500, CurrencyCode.USD),
          hourlyCashEquivalent: money(42_500 / 2080, CurrencyCode.USD),
        },
        {
          __typename: "AdjustedCashBandPoint" as const,
          id: "8:1:Source",
          name: "Max",
          bandName: "Commission",
          value: {
            __typename: "CashValue" as const,
            annualRate: money(50_000, CurrencyCode.USD),
            hourlyRate: money(50_000 / 2080, CurrencyCode.USD),
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: money(50_000, CurrencyCode.USD),
          hourlyCashEquivalent: money(50_000 / 2080, CurrencyCode.USD),
        },
      ],
    },
    {
      __typename: "AdjustedCashBand" as const,
      id: "3:1:Source",
      name: "Recurring Bonus",
      bandPoints: [
        {
          __typename: "AdjustedCashBandPoint" as const,
          id: "9:1:Source",
          name: "Min",
          bandName: "Recurring Bonus",
          value: {
            __typename: "CashValue" as const,
            annualRate: money(20_000, CurrencyCode.USD),
            hourlyRate: money(20_000 / 2080, CurrencyCode.USD),
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: money(20_000, CurrencyCode.USD),
          hourlyCashEquivalent: money(20_000 / 2080, CurrencyCode.USD),
        },
        {
          __typename: "AdjustedCashBandPoint" as const,
          id: "10:1:Source",
          name: "Low-Mid",
          bandName: "Recurring Bonus",
          value: {
            __typename: "CashValue" as const,
            annualRate: money(25_000, CurrencyCode.USD),
            hourlyRate: money(25_000 / 2080, CurrencyCode.USD),
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: money(25_000, CurrencyCode.USD),
          hourlyCashEquivalent: money(25_000 / 2080, CurrencyCode.USD),
        },
        {
          __typename: "AdjustedCashBandPoint" as const,
          id: "11:1:Source",
          name: "Mid-High",
          bandName: "Recurring Bonus",
          value: {
            __typename: "CashValue" as const,
            annualRate: money(35_000, CurrencyCode.USD),
            hourlyRate: money(35_000 / 2080, CurrencyCode.USD),
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: money(35_000, CurrencyCode.USD),
          hourlyCashEquivalent: money(35_000 / 2080, CurrencyCode.USD),
        },
        {
          __typename: "AdjustedCashBandPoint" as const,
          id: "12:1:Source",
          name: "Max",
          bandName: "Recurring Bonus",
          value: {
            __typename: "CashValue" as const,
            annualRate: money(40_000, CurrencyCode.USD),
            hourlyRate: money(40_000 / 2080, CurrencyCode.USD),
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: money(40_000, CurrencyCode.USD),
          hourlyCashEquivalent: money(40_000 / 2080, CurrencyCode.USD),
        },
      ],
    },
  ],
  adjustedEquityBands: [
    {
      __typename: "AdjustedEquityBand" as const,
      id: "1:1:Source",
      name: "Initial Equity Grant",
      bandPoints: [
        {
          __typename: "AdjustedEquityBandPoint" as const,
          id: "1:1:Source",
          name: "Min",
          bandName: "Initial Equity Grant",
          value: {
            __typename: "CashValue" as const,
            annualRate: money(10_000, CurrencyCode.USD),
            hourlyRate: money(10_000 / 2080, CurrencyCode.USD),
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: money(10_000, CurrencyCode.USD),
          hourlyCashEquivalent: money(10_000 / 2080, CurrencyCode.USD),
        },
        {
          __typename: "AdjustedEquityBandPoint" as const,
          id: "2:1:Source",
          name: "Low-Mid",
          bandName: "Initial Equity Grant",
          value: {
            __typename: "CashValue" as const,
            annualRate: money(12_500, CurrencyCode.USD),
            hourlyRate: money(12_500 / 2080, CurrencyCode.USD),
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: money(12_500, CurrencyCode.USD),
          hourlyCashEquivalent: money(12_500 / 2080, CurrencyCode.USD),
        },
        {
          __typename: "AdjustedEquityBandPoint" as const,
          id: "3:1:Source",
          name: "Mid-High",
          bandName: "Initial Equity Grant",
          value: {
            __typename: "CashValue" as const,
            annualRate: money(17_500, CurrencyCode.USD),
            hourlyRate: money(17_500 / 2080, CurrencyCode.USD),
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: money(17_500, CurrencyCode.USD),
          hourlyCashEquivalent: money(17_500 / 2080, CurrencyCode.USD),
        },
        {
          __typename: "AdjustedEquityBandPoint" as const,
          id: "4:1:Source",
          name: "Max",
          bandName: "Initial Equity Grant",
          value: {
            __typename: "CashValue" as const,
            annualRate: money(20_000, CurrencyCode.USD),
            hourlyRate: money(20_000 / 2080, CurrencyCode.USD),
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: money(20_000, CurrencyCode.USD),
          hourlyCashEquivalent: money(20_000 / 2080, CurrencyCode.USD),
        },
      ],
    },
  ] as AdjustedEquityBand[],
};

export const MOCK_POSITIONS = [
  {
    __typename: "Position" as const,
    id: 1,
    name: "Test Position 1",
    level: 1,
    jobCodes: ["JOB001"],
    type: PositionType.ANNUAL,
    ladder: {
      __typename: "Ladder" as const,
      id: 1,
      name: "Ladder",
      department: {
        __typename: "Department" as const,
        id: 1,
        name: "A department",
      },
    },
    adjustedCashBands: [
      {
        __typename: "AdjustedCashBand" as const,
        id: "1",
        name: CashBandName.SALARY,
        bandPoints: [
          {
            __typename: "AdjustedCashBandPoint" as const,
            id: "1",
            bandName: CashBandName.SALARY,
            name: "Min",
            value: {
              __typename: "CashValue" as const,
              annualRate: { value: 100_000, currency: CurrencyCode.USD },
              hourlyRate: { value: 100_000 / 2080, currency: CurrencyCode.USD },
              currencyCode: CurrencyCode.USD,
            },
            annualCashEquivalent: {
              value: 100_000,
              currency: CurrencyCode.USD,
            },
            hourlyCashEquivalent: {
              value: 48,
              currency: CurrencyCode.USD,
            },
          },
          {
            __typename: "AdjustedCashBandPoint" as const,
            id: "1",
            bandName: CashBandName.SALARY,
            name: "Max",
            value: {
              __typename: "CashValue" as const,
              annualRate: { value: 150_000, currency: CurrencyCode.USD },
              hourlyRate: { value: 150_000 / 2080, currency: CurrencyCode.USD },
              currencyCode: CurrencyCode.USD,
            },
            annualCashEquivalent: {
              value: 150_000,
              currency: CurrencyCode.USD,
            },
            hourlyCashEquivalent: {
              value: 72,
              currency: CurrencyCode.USD,
            },
          },
        ],
      },
    ],
  },
];

export const MOCK_COMP_STRUCTURE: CompStructure = {
  __typename: "CompStructure" as const,
  id: 1,
  bandPointTypes: ["Tier 1", "Tier 2", "Tier 3"],
  cashBandTypes: [
    "Salary",
    "Commission",
    "Recurring Bonus",
    "Spot Bonus",
    "Signing Bonus",
  ],
  equityBandTypes: ["Initial Equity Grant"],
  allBandTypes: [
    "Salary",
    "Commission",
    "Recurring Bonus",
    "Spot Bonus",
    "Signing Bonus",
    "Initial Equity Grant",
  ],
  availableCashBandTypes: [
    "Salary",
    "Commission",
    "Recurring Bonus",
    "Spot Bonus",
    "Signing Bonus",
    "Relocation Bonus (Office)",
    "Relocation Bonus (Remote)",
  ],
  availableEquityBandTypes: ["Initial Equity Grant", "Equity Refresh Grant"],
  businessStructure: BusinessStructure.C_CORP,
  companyDescription: "Taste the rainbow",
  equityGrantTypes: [EquityGrantTypes.STOCK_OPTION],
  showPercentOwnership: true,
  showValuation: true,
  vestingCliff: 12,
  vestingMonths: 48,
  levels: [1, 2, 3, 4, 5, 6, 7, 8],
  equityGrantMethod: EquityGrantMethod.UNITS,
  allowHourlyEmployees: false,
  employmentHoursPerWeek: 40,
  employmentWeeksPerYear: 52,
};

export const MOCK_VALUATION: Valuation = {
  id: 49,
  activeAt: "2021-04-07T21:34:46.417Z",
  estimatedDilution: 0.1251,
  financingStage: "Series B",
  fdso: 20000000000,
  strikePrice: { value: 11.99, currency: CurrencyCode.USD },
  valuation: {
    value: 2000000000,
    currency: CurrencyCode.USD,
  },
  __typename: "Valuation" as const,
};

export const MOCK_OFFER: Offer = {
  id: 15,
  candidate: { candidateName: "Velma Dinkley", __typename: "Candidate" },
  cash: {
    data: [
      {
        type: "Salary",
        money: {
          value: 60000,
          currency: CurrencyCode.USD,
        },
        __typename: "OfferCashBand" as const,
      },
      {
        type: "Commission",
        money: { value: 7000, currency: CurrencyCode.USD },
        __typename: "OfferCashBand" as const,
      },
      {
        type: "Recurring Bonus",
        money: {
          value: 10000,
          currency: CurrencyCode.USD,
        },
        __typename: "OfferCashBand" as const,
      },
      {
        type: "Spot Bonus",
        money: { value: 2000, currency: CurrencyCode.USD },
        __typename: "OfferCashBand" as const,
      },
      {
        type: "Signing Bonus",
        money: { value: 1000, currency: CurrencyCode.USD },
        __typename: "OfferCashBand" as const,
      },
    ],
    __typename: "OfferCash" as const,
  },
  closingMessage: "Test",
  offeredAt: "2021-04-07T23:21:15.495Z",
  equity: {
    data: [
      {
        type: "Initial Equity Grant",
        money: null,
        units: 1000000,
        __typename: "OfferEquityBand" as const,
      },
    ],
    __typename: "OfferEquity" as const,
  },
  message: "Test",
  position: {
    id: 204,
    name: "Customer Success 1",
    level: 1,
    __typename: "Position" as const,
    type: PositionType.ANNUAL,
  },
  organization: {
    id: 2,
    name: "Generic Data",
    __typename: "Organization" as const,
  },
  outcomeDescription: "This is a description",
  title: "Customer Success 1",
  benefitsPackage: null,
  __typename: "Offer" as const,
};

export const MOCK_OFFER_DATA: OfferData = {
  offeredAt: new Date("2021-04-07T23:21:15.495Z"),
  market: {
    __typename: "Market" as const,
    id: 1,
    name: "Primary Market",
    currencyCode: CurrencyCode.USD,
    locationGroups: [
      {
        __typename: "LocationGroup" as const,
        id: 33,
        name: "Tier 3",
        isSourceComp: false,
        description: "Something here",
      },
    ],
  },
  locationGroup: {
    __typename: "LocationGroup" as const,
    id: 33,
    name: "Tier 3",
    isSourceComp: false,
    description: "Something here",
  },
  position: {
    __typename: "Position" as const,
    id: 1,
    name: "Test Position 1",
    jobCodes: [],
    ladder: {
      __typename: "Ladder" as const,
      id: 100,
      name: "Ladder",
      department: {
        __typename: "Department" as const,
        id: 1,
        name: "My Department",
      },
    },
    level: 1,
    type: PositionType.ANNUAL,
  },
  firstName: "Velma",
  lastName: "Dinkley",
  candidateEmail: "velma@assemble.inc",
  welcomeMessage: "d",
  closingMessage: "d",
  cash: {
    [CashBandName.SALARY]: {
      mode: BandUnit.CASH,
      value: { value: 100_000, currency: CurrencyCode.USD },
    },
    [CashBandName.COMMISSION]: {
      mode: BandUnit.CASH,
      value: { value: 27_000, currency: CurrencyCode.USD },
    },
    [CashBandName.RECURRING_BONUS]: {
      mode: BandUnit.CASH,
      value: { value: 14_000, currency: CurrencyCode.USD },
    },
    [CashBandName.SPOT_BONUS]: {
      mode: BandUnit.CASH,
      value: {
        value: 1,
        currency: CurrencyCode.USD,
      },
    },
    [CashBandName.SIGNING_BONUS]: {
      mode: BandUnit.CASH,
      value: { value: 12, currency: CurrencyCode.USD },
    },
  },
  equity: {
    [EquityBandName.INITIAL_EQUITY_GRANT]: {
      mode: BandUnit.UNITS,
      value: 100_000,
    },
  },
  currency: DEFAULT_USD,
  customFields: [
    {
      name: "Commute Time",
      variant: CustomFieldVariant.TEXT,
      options: [],
      description: "Average time to office.",
    },
    {
      name: "Remote",
      variant: CustomFieldVariant.DROPDOWN,
      options: ["Yes", "No"],
    },
  ],
};

export const MOCK_YEN_OFFER_DATA: OfferData = {
  offeredAt: new Date("2021-04-07T23:21:15.495Z"),
  locationGroup: {
    __typename: "LocationGroup" as const,
    id: 33,
    isSourceComp: false,
    name: "Tier 3",
    description: "Something here",
  },
  position: {
    __typename: "Position" as const,
    id: 1,
    name: "Test Position 1",
    jobCodes: [],
    ladder: {
      __typename: "Ladder" as const,
      id: 100,
      name: "Ladder",
      department: {
        __typename: "Department" as const,
        id: 1,
        name: "My Department",
      },
    },
    level: 1,
    type: PositionType.ANNUAL,
  },
  firstName: "Velma",
  lastName: "Dinkley",
  candidateEmail: "velma@assemble.inc",
  welcomeMessage: "d",
  closingMessage: "d",
  cash: {
    [CashBandName.SALARY]: {
      mode: BandUnit.CASH,
      value: { value: 10_000_000, currency: CurrencyCode.JPY },
    },
    [CashBandName.COMMISSION]: {
      mode: BandUnit.CASH,
      value: { value: 2_700_000, currency: CurrencyCode.JPY },
    },
    [CashBandName.RECURRING_BONUS]: {
      mode: BandUnit.CASH,
      value: { value: 1_400_000, currency: CurrencyCode.JPY },
    },
    [CashBandName.SPOT_BONUS]: {
      mode: BandUnit.CASH,
      value: { value: 100, currency: CurrencyCode.JPY },
    },
    [CashBandName.SIGNING_BONUS]: {
      mode: BandUnit.CASH,
      value: { value: 1_200, currency: CurrencyCode.JPY },
    },
  },
  equity: {
    [EquityBandName.INITIAL_EQUITY_GRANT]: {
      mode: BandUnit.UNITS,
      value: 100_000,
    },
  },
  currency: { code: CurrencyCode.JPY, exchangeRate: 100 },
};

export const MOCK_OFFER_DATA_WITH_APPROVALS: OfferData = {
  offeredAt: new Date("2021-04-07T23:21:15.495Z"),
  locationGroup: {
    __typename: "LocationGroup" as const,
    id: 33,
    isSourceComp: false,
    name: "Tier 3",
    description: "Something here",
  },
  position: {
    __typename: "Position" as const,
    id: 1,
    name: "Test Position 1",
    jobCodes: ["abc123", "xyz789"],
    ladder: {
      __typename: "Ladder" as const,
      id: 100,
      name: "Ladder",
      department: {
        __typename: "Department" as const,
        id: 1,
        name: "My Department",
      },
    },
    level: 1,
    type: PositionType.ANNUAL,
  },
  firstName: "Velma",
  lastName: "Dinkley",
  candidateEmail: "velma@assemble.inc",
  welcomeMessage: "d",
  closingMessage: "d",
  cash: {
    [CashBandName.SALARY]: {
      mode: BandUnit.CASH,
      value: { value: 100_000, currency: CurrencyCode.USD },
    },
    [CashBandName.COMMISSION]: {
      mode: BandUnit.CASH,
      value: { value: 27_000, currency: CurrencyCode.USD },
    },
    [CashBandName.RECURRING_BONUS]: {
      mode: BandUnit.CASH,
      value: { value: 20_000, currency: CurrencyCode.USD },
    },
    [CashBandName.SPOT_BONUS]: {
      mode: BandUnit.CASH,
      value: { value: 1, currency: CurrencyCode.USD },
    },
    [CashBandName.SIGNING_BONUS]: {
      mode: BandUnit.CASH,
      value: { value: 12, currency: CurrencyCode.USD },
    },
  },
  equity: {
    [EquityBandName.INITIAL_EQUITY_GRANT]: {
      mode: BandUnit.UNITS,
      value: 10_000_000,
    },
  },
  currency: DEFAULT_USD,
  jobCode: "LQA-FECCE-YLTSI",
  managerName: "Michael Manager",
  approverName: "Abby Approver",
  decisionMakerName: "Debby Decision-Maker",
  customFields: [
    {
      name: "Commute Time",
      variant: CustomFieldVariant.TEXT,
      options: [],
      description: "Average time to office.",
    },
    {
      name: "Remote",
      variant: CustomFieldVariant.DROPDOWN,
      options: ["Yes", "No"],
    },
    {
      name: "Additional Info",
      variant: CustomFieldVariant.TEXT,
      options: [],
      description: "Provide any other useful information",
    },
    {
      name: "A very really long forty character title",
      variant: CustomFieldVariant.TEXT,
      options: [],
      description: "Push the limits of formatting",
    },
  ],
  customFieldAnswers: [
    {
      name: "Commute Time",
      answer: "20 minutes",
    },
    {
      name: "Remote",
      answer: "No",
    },
    {
      name: "Additional Info",
      answer:
        "Franklin gave Bobby the go ahead to let Bobby approve the hiring of Melissa Hicks, a Level 2 accountant based out of our Zone 1. Melissa’s interview rubric and file can be found in our company Greenhouse account.\n\n",
    },
    {
      name: "A very really long forty character title",
      answer: "wow, such title length",
    },
  ],
};

export const MOCK_OFFER_DATA_FROM_SCRATCH: OfferData = {
  welcomeMessage: "Welcome to the company",
  closingMessage: "Goodbye ",
  offeredAt: new Date("2021-10-20T18:10:48.910Z"),
  currency: DEFAULT_USD,
  firstName: "Ben",
  lastName: "Chen",
  candidateEmail: "benchen@assemble.inc",
  title: "Test",
  cash: {
    [CashBandName.SALARY]: {
      mode: BandUnit.CASH,
      value: { value: 100_000, currency: CurrencyCode.USD },
    },
    [CashBandName.COMMISSION]: {
      mode: BandUnit.CASH,
      value: { value: 27_000, currency: CurrencyCode.USD },
    },
    [CashBandName.RECURRING_BONUS]: {
      mode: BandUnit.CASH,
      value: { value: 0, currency: CurrencyCode.USD },
    },
    [CashBandName.SPOT_BONUS]: {
      mode: BandUnit.CASH,
      value: { value: 1, currency: CurrencyCode.USD },
    },
    [CashBandName.SIGNING_BONUS]: {
      mode: BandUnit.CASH,
      value: { value: 12, currency: CurrencyCode.USD },
    },
  },
  equity: {
    [EquityBandName.INITIAL_EQUITY_GRANT]: {
      mode: BandUnit.UNITS,
      value: 10_000_000,
    },
  },
  benefitsPackage: null,
  managerName: "Micro Manager",
  decisionMakerName: "Debby Decision-Maker",
  approverName: "Abby Approver",
  jobCode: "LQA-FECCE-YLTSI",
};

export const MOCK_OFFER_CONFIG: OfferConfig = {
  id: 1,
  exitOutcomes: [
    1000000000, 2000000000, 3000000000, 4000000000, 5000000000, 8000000000,
    10000000000,
  ],
  xAxisAnnotations: [
    "Current valuation",
    "FY22 Target",
    "FY23 Target",
    "IPO Target",
  ],
  defaultOutcomeDescription: "",
  equityFootnote: null,
  defaultWelcomeMessage: null,
  defaultClosingMessage: null,
  showEquityInformation: true,
  showSharePriceFootnote: false,
  showFdso: true,
  showStage: true,
  showCurrentEquityValue: true,
  showAnnualizedEquity: true,
  requireApproval: false,
  equityCashInValuationCurrency: false,
  brandColorPrimary: "#645BFF",
  sharePriceLabel: "Preferred Price per Share",
  primaryLogoUrlPath:
    "https://storage.googleapis.com/assemble-asset-bucket-dev/genericdata-c09859c03/assemble-logo-primary.svg",
  whiteLogoUrlPath:
    "https://storage.googleapis.com/assemble-asset-bucket-dev/genericdata-c09859c03/assemble-logo-white.svg",
  greyLogoUrlPath:
    "https://storage.googleapis.com/assemble-asset-bucket-dev/genericdata-c09859c03/assemble-logo-grey.svg",
  backgroundUrlPath:
    "https://storage.googleapis.com/assemble-asset-bucket-dev/genericdata-c09859c03/assemble-background.png",
  __typename: "OfferConfig" as const,
  customFields: [],
};

export const MOCK_DETAILED_EMPLOYEE2 = {
  __typename: "Employee2" as const,
  activeAt: plainDateToUTCString(new Date()),
  id: 1,
  displayName: "Annette Black",
  email: "employee@example.com",
  personalEmail: "employee@personal-email-example.com",
  employmentStatus: EmploymentStatus.ACTIVE,
  manager: null,
  minimalManager: null,
  equityHoldings: {
    __typename: "EquityHoldings2" as const,
    id: 1,
    valuationCurrency: CurrencyCode.USD,
    vestingInformation: "4 years with a 1 year cliff",
    grants: null,
    totalUnitCount: 0,
    totalGrossHoldingValue: null,
  } as EquityHoldings2,
  location: {
    __typename: "LocationGroup2" as const,
    id: 1,
    name: "Tier 1",
    marketId: 1,
    market: {
      __typename: "Market2" as const,
      id: 1,
    },
  },
  user: null,
  compaRatio: 0.8,
  adjustedCashBands: [
    {
      __typename: "AdjustedCashBand" as const,
      id: "1",
      name: CashBandName.SALARY,
      bandPoints: [
        {
          __typename: "AdjustedCashBandPoint" as const,
          id: "1",
          bandName: CashBandName.SALARY,
          name: "Min",
          value: {
            __typename: "CashValue" as const,
            annualRate: { value: 100_000, currency: CurrencyCode.USD },
            hourlyRate: { value: 100_000 / 2080, currency: CurrencyCode.USD },
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: { value: 100_000, currency: CurrencyCode.USD },
          hourlyCashEquivalent: { value: 48, currency: CurrencyCode.USD },
        },
        {
          __typename: "AdjustedCashBandPoint" as const,
          id: "1",
          bandName: CashBandName.SALARY,
          name: "Max",
          value: {
            __typename: "CashValue" as const,
            annualRate: { value: 150_000, currency: CurrencyCode.USD },
            hourlyRate: { value: 150_000 / 2080, currency: CurrencyCode.USD },
            currencyCode: CurrencyCode.USD,
          },
          annualCashEquivalent: { value: 150_000, currency: CurrencyCode.USD },
          hourlyCashEquivalent: { value: 73, currency: CurrencyCode.USD },
        },
      ],
    },
  ],
  activeEmployment: {
    __typename: "Employment2" as const,
    id: 1,
    jobTitle: "Software Engineer",
    activeAt: plainDateToUTCString(new Date("2023-08-23")),
    inactiveAt: null,
    positionId: 1,
    salary: { value: 100000, currency: CurrencyCode.USD },
    payRate: null,
    payCurrencyCode: CurrencyCode.USD,
    payPeriod: PayPeriodType.ANNUAL,
    position: {
      __typename: "Position" as const,
      id: 1,
      level: 6,
      type: PositionType.ANNUAL,
      name: "Product Engineer",
      ladder: {
        __typename: "Ladder" as const,
        id: 1,
        name: "Software Engineer",
        department: {
          __typename: "Department" as const,
          id: 1,
          name: "Engineering",
        },
      },
    },
  },
  employments: [
    {
      __typename: "Employment2" as const,
      id: 1,
      jobTitle: "Software Engineer",
      activeAt: plainDateToUTCString(new Date()),
      inactiveAt: null,
      positionId: 1,
      salary: {
        value: 100000,
        currency: CurrencyCode.USD,
      },
      payPeriod: PayPeriodType.ANNUAL,
      position: {
        __typename: "Position" as const,
        id: 1,
        level: 6,
        type: PositionType.ANNUAL,
        name: "Product Engineer",
        jobCodes: ["JOB001"],
        ladder: {
          __typename: "Ladder" as const,
          id: 1,
          name: "Software Engineer",
          department: {
            __typename: "Department" as const,
            id: 1,
            name: "Engineering",
          },
        },
      },
    },
    {
      __typename: "Employment2" as const,
      id: 2,
      jobTitle: "Software Engineer",
      activeAt: "2020-01-01",
      inactiveAt: plainDateToUTCString(new Date()),
      positionId: 1,
      salary: {
        value: 90000,
        currency: CurrencyCode.USD,
      },
      payPeriod: PayPeriodType.ANNUAL,
      position: {
        type: PositionType.ANNUAL,
        __typename: "Position" as const,
        id: 1,
        level: 6,
        name: "Product Engineer",
        ladder: {
          __typename: "Ladder" as const,
          id: 1,
          name: "Software Engineer",
          department: {
            __typename: "Department" as const,
            id: 1,
            name: "Engineering",
          },
        },
      },
    },
  ],
  activeCashCompensation: [
    {
      employeeId: 1,
      activeAt: "2010-01-01",
      type: CashCompType.SALARY,
      annualCashEquivalent: {
        value: 100_000,
        currency: CurrencyCode.USD,
      },
      hourlyCashEquivalent: {
        value: 100_000 / 2080,
        currency: CurrencyCode.USD,
      },
      percentOfSalary: 100,
      unit: CompUnit.CASH,
      __typename: "CashCompensation2" as const,
    },
  ],
};

export const MOCK_COMP_CYCLE_EMPLOYEES = [
  {
    __typename: "CompCycleParticipant" as const,
    subjectId: 1001,
    compCycleId: 1,
    perfRating: "N/A",
    subject: {
      ...MOCK_DETAILED_EMPLOYEE2,
      id: 1001,
      userId: 1001,
      photoURL: null,
      employeeNumber: "E1001",
      displayName: "Annette Black",
      isManager: true,
      employmentStatus: EmploymentStatus.ACTIVE,
      lastCompChangeDate: plainDateToUTCString(new Date("2023-01-01")),
      lastCompChangeAmount: {
        __typename: "CompValue" as const,
        annualCashEquivalent: dollars(10_000),
        hourlyCashEquivalent: dollars(10_000 / 2080),
        unitType: CompUnit.CASH,
      },
      adjustedEquityBands: [],
      adjustedCashBands: [
        {
          __typename: "AdjustedCashBand" as const,
          id: "1",
          name: CashBandName.SALARY,
          bandPoints: [
            {
              __typename: "AdjustedCashBandPoint" as const,
              id: "1",
              bandName: CashBandName.SALARY,
              name: "Min",
              value: {
                __typename: "CashValue" as const,
                annualRate: { value: 100_000, currency: CurrencyCode.USD },
                hourlyRate: {
                  value: 100_000 / 2080,
                  currency: CurrencyCode.USD,
                },
                currencyCode: CurrencyCode.USD,
              },
              annualCashEquivalent: {
                value: 100_000,
                currency: CurrencyCode.USD,
              },
            },
            {
              __typename: "AdjustedCashBandPoint" as const,
              id: "1",
              bandName: CashBandName.SALARY,
              name: "Mid",
              value: {
                __typename: "CashValue" as const,
                annualRate: { value: 110_000, currency: CurrencyCode.USD },
                hourlyRate: {
                  value: 110_000 / 2080,
                  currency: CurrencyCode.USD,
                },
                currencyCode: CurrencyCode.USD,
              },
              annualCashEquivalent: {
                value: 110_000,
                currency: CurrencyCode.USD,
              },
            },
            {
              __typename: "AdjustedCashBandPoint" as const,
              id: "1",
              bandName: CashBandName.SALARY,
              name: "Max",
              value: {
                __typename: "CashValue" as const,
                annualRate: { value: 120_000, currency: CurrencyCode.USD },
                hourlyRate: {
                  value: 120_000 / 2080,
                  currency: CurrencyCode.USD,
                },
                currencyCode: CurrencyCode.USD,
              },
              annualCashEquivalent: {
                value: 120_000,
                currency: CurrencyCode.USD,
              },
            },
          ],
        },
      ],
    },
    phaseTimeline: [],
    lastActivityAt: plainDateToUTCString(new Date()),
    compRecommendation: {
      __typename: "CompRecommendation2" as const,
      id: 1,
      compCycleId: 1,
      subjectId: 1,
      canICurrentlyReview: true,
      canICurrentlyEdit: true,
      subject: {
        __typename: "Employee2" as const,
        id: 1,
      },
      updatedAt: new Date(),
      allSubmittedItems: [
        {
          __typename: "RecItem2" as const,
          id: 1,
          authorId: 2,
          author: {
            __typename: "User2" as const,
            id: 1,
            email: "annette@example.com",
            name: "Annette Black",
            photoURL: "http://image.com/1",
          },
          submittedAt: null,
          createdAt: new Date(),
          recommendationType: RecItemType.MERIT_INCREASE,
          recommendedCashValue: {
            value: 5000,
            currency: CurrencyCode.USD,
          },
          recommendedEquityUnitCount: null,
          recommendedPercentValue: null,
          recommendedTitle: null,
          recommendedPosition: {
            __typename: "Position" as const,
            id: 1,
            submittedAt: null,
            name: "Senior Software Engineer",
            level: 3,
            type: PositionType.ANNUAL,
            ladder: {
              id: 1,
              name: "Software Engineer",
              __typename: "Ladder" as const,
              department: {
                __typename: "Department" as const,
                id: 1,
                name: "Engineering",
              },
            },
          },
          note: null,
          unitType: null,
        },
      ],
      latestSubmittedPayIncrease: {
        __typename: "CompValue" as const,
        annualCashEquivalent: money(5000, CurrencyCode.USD),
        hourlyCashEquivalent: money(5000 / 2080, CurrencyCode.USD),
        unitType: CompUnit.CASH,
      },
      latestSubmittedItems: [
        {
          __typename: "RecItem2" as const,
          id: 1,
          authorId: 2,
          author: {
            __typename: "User2" as const,
            id: 1,
            email: "annette@example.com",
            name: "Annette Black",
            photoURL: "http://image.com/1",
            employee: { __typename: "Employee2" as const, id: 1001 },
          },
          submittedAt: new Date(),
          createdAt: new Date(),
          recommendationType: RecItemType.MERIT_INCREASE,
          recommendedCashValue: {
            value: 5000,
            currency: CurrencyCode.USD,
          },
          recommendedEquityUnitCount: null,
          recommendedPercentValue: null,
          recommendedTitle: null,
          adjustedEquityBands: [],
          adjustedCashBands: [
            {
              __typename: "AdjustedCashBand" as const,
              id: "1",
              name: CashBandName.SALARY,
              bandPoints: [
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Min",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 50_000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 50_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 50000,
                    currency: CurrencyCode.USD,
                  },
                },
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Mid",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 55000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 55_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 55000,
                    currency: CurrencyCode.USD,
                  },
                },
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Max",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 60_000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 60_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 60000,
                    currency: CurrencyCode.USD,
                  },
                },
              ],
            },
          ],
          recommendedPosition: {
            __typename: "Position" as const,
            id: 1,
            submittedAt: new Date(),
            name: "Senior Software Engineer",
            level: 3,
            type: PositionType.ANNUAL,
            ladder: {
              id: 1,
              name: "Software Engineer",
              __typename: "Ladder" as const,
              department: {
                __typename: "Department" as const,
                id: 1,
                name: "Engineering",
              },
            },
          },
          note: null,
          unitType: null,
        },
      ],
      allSubmittedReviews: [],
      latestSubmittedReviews: [],
      reviewStatus: CompRecommendationStatus.REVIEWED,
    },
  },
  {
    __typename: "CompCycleParticipant" as const,
    subjectId: 1002,
    compCycleId: 1,
    phaseTimeline: [],
    subject: {
      ...MOCK_DETAILED_EMPLOYEE2,
      id: 1002,
      userId: 1002,
      photoURL: null,
      employeeNumber: "E1002",
      displayName: "Courtney Henry",
      isManager: true,
      employmentStatus: EmploymentStatus.ACTIVE,
      adjustedEquityBands: [],
      adjustedCashBands: [
        {
          __typename: "AdjustedCashBand" as const,
          id: "1",
          name: CashBandName.SALARY,
          bandPoints: [
            {
              __typename: "AdjustedCashBandPoint" as const,
              id: "1",
              bandName: CashBandName.SALARY,
              name: "Min",
              value: {
                __typename: "CashValue" as const,
                annualRate: { value: 50_000, currency: CurrencyCode.USD },
                hourlyRate: {
                  value: 50_000 / 2080,
                  currency: CurrencyCode.USD,
                },
                currencyCode: CurrencyCode.USD,
              },
              annualCashEquivalent: {
                value: 50000,
                currency: CurrencyCode.USD,
              },
            },
            {
              __typename: "AdjustedCashBandPoint" as const,
              id: "1",
              bandName: CashBandName.SALARY,
              name: "Mid",
              value: {
                __typename: "CashValue" as const,
                annualRate: { value: 55000, currency: CurrencyCode.USD },
                hourlyRate: {
                  value: 55_000 / 2080,
                  currency: CurrencyCode.USD,
                },
                currencyCode: CurrencyCode.USD,
              },
              annualCashEquivalent: {
                value: 55000,
                currency: CurrencyCode.USD,
              },
            },
            {
              __typename: "AdjustedCashBandPoint" as const,
              id: "1",
              bandName: CashBandName.SALARY,
              name: "Max",
              value: {
                __typename: "CashValue" as const,
                annualRate: { value: 60_000, currency: CurrencyCode.USD },
                hourlyRate: {
                  value: 60_000 / 2080,
                  currency: CurrencyCode.USD,
                },
                currencyCode: CurrencyCode.USD,
              },
              annualCashEquivalent: {
                value: 60000,
                currency: CurrencyCode.USD,
              },
            },
          ],
        },
      ],
      lastCompChangeDate: null,
      lastCompChangeAmount: {
        __typename: "CompValue" as const,
        annualCashEquivalent: dollars(0),
        hourlyCashEquivalent: dollars(0),
        unitType: CompUnit.CASH,
      },
    },
    perfRating: "Poor",
    lastActivityAt: plainDateToUTCString(new Date()),
    compRecommendation: {
      __typename: "CompRecommendation2" as const,
      id: 2,
      subjectId: 1,
      compCycleId: 1,
      canICurrentlyReview: true,
      canICurrentlyEdit: true,
      subject: {
        __typename: "Employee2" as const,
        id: 1,
      },
      updatedAt: new Date(),
      allSubmittedItems: [
        {
          __typename: "RecItem2" as const,
          id: 2,
          submittedAt: null,
          createdAt: new Date(),
          authorId: 2,
          author: {
            __typename: "User2" as const,
            id: 1,
            email: "annette@example.com",
            name: "Annette Black",
            photoURL: "http://image.com/1",
          },
          recommendationType: RecItemType.MERIT_INCREASE,
          recommendedCashValue: {
            value: 5000,
            currency: CurrencyCode.USD,
          },
          recommendedEquityUnitCount: null,
          recommendedPercentValue: null,
          recommendedTitle: null,
          adjustedEquityBands: [],
          adjustedCashBands: [
            {
              __typename: "AdjustedCashBand" as const,
              id: "1",
              name: CashBandName.SALARY,
              bandPoints: [
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Min",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 50000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 50_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 50000,
                    currency: CurrencyCode.USD,
                  },
                },
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Mid",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 55000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 55_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 55000,
                    currency: CurrencyCode.USD,
                  },
                },
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Max",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 60000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 60_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 60000,
                    currency: CurrencyCode.USD,
                  },
                },
              ],
            },
          ],
          recommendedPosition: {
            __typename: "Position" as const,
            id: 1,
            name: "Senior Software Engineer",
            level: 3,
            type: PositionType.ANNUAL,
            ladder: {
              id: 1,
              name: "Software Engineer",
              __typename: "Ladder" as const,
              department: {
                __typename: "Department" as const,
                id: 1,
                name: "Engineering",
              },
            },
          },
          note: null,
          unitType: null,
        },
      ],
      latestSubmittedPayIncrease: {
        __typename: "CompValue" as const,
        annualCashEquivalent: money(5000, CurrencyCode.USD),
        hourlyCashEquivalent: money(5000 / 2080, CurrencyCode.USD),
        unitType: CompUnit.CASH,
      },
      latestSubmittedItems: [
        {
          __typename: "RecItem2" as const,
          id: 2,
          submittedAt: new Date(),
          createdAt: new Date(),
          authorId: 2,
          author: {
            __typename: "User2" as const,
            id: 1,
            email: "annette@example.com",
            name: "Annette Black",
            photoURL: "http://image.com/1",
            employee: { __typename: "Employee2" as const, id: 1002 },
          },
          recommendationType: RecItemType.PROMOTION,
          recommendedCashValue: {
            value: 5000,
            currency: CurrencyCode.USD,
          },
          recommendedEquityUnitCount: null,
          recommendedPercentValue: null,
          recommendedTitle: null,
          recommendedPosition: {
            __typename: "Position" as const,
            id: 1,
            name: "Senior Software Engineer",
            level: 3,
            type: PositionType.ANNUAL,
            ladder: {
              id: 1,
              name: "Software Engineer",
              __typename: "Ladder" as const,
              department: {
                __typename: "Department" as const,
                id: 1,
                name: "Engineering",
              },
            },
          },
          adjustedEquityBands: [],
          adjustedCashBands: [
            {
              __typename: "AdjustedCashBand" as const,
              id: "1",
              name: CashBandName.SALARY,
              bandPoints: [
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Min",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 50000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 50_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 50000,
                    currency: CurrencyCode.USD,
                  },
                },
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Mid",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 55000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 55_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 55000,
                    currency: CurrencyCode.USD,
                  },
                },
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Max",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 60000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 60_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 60000,
                    currency: CurrencyCode.USD,
                  },
                },
              ],
            },
          ],
          note: null,
          unitType: null,
        },
      ],
      allSubmittedReviews: [],
      latestSubmittedReviews: [],
      reviewStatus: CompRecommendationStatus.NEEDS_REVIEW,
      RelativeCompRecStatus: RelativeCompRecStatus.AWAITING,
    },
  },
  {
    __typename: "CompCycleParticipant" as const,
    subjectId: 1003,
    compCycleId: 1,
    phaseTimeline: [],
    subject: {
      ...MOCK_DETAILED_EMPLOYEE2,
      id: 1003,
      userId: 1003,
      photoURL: null,
      employeeNumber: "E1003",
      displayName: "Bessie Cooper",
      isManager: true,
      employmentStatus: EmploymentStatus.ACTIVE,
      lastCompChangeDate: plainDateToUTCString(new Date("2023-01-01")),
      lastCompChangeAmount: {
        __typename: "CompValue" as const,
        annualCashEquivalent: dollars(15_000),
        hourlyCashEquivalent: dollars(15_000 / 2080),
        unitType: CompUnit.CASH,
      },
      adjustedEquityBands: [],
      adjustedCashBands: [
        {
          __typename: "AdjustedCashBand" as const,
          id: "1",
          name: CashBandName.SALARY,
          bandPoints: [
            {
              __typename: "AdjustedCashBandPoint" as const,
              id: "1",
              bandName: CashBandName.SALARY,
              name: "Min",
              value: {
                __typename: "CashValue" as const,
                annualRate: { value: 50000, currency: CurrencyCode.USD },
                hourlyRate: {
                  value: 50_000 / 2080,
                  currency: CurrencyCode.USD,
                },
                currencyCode: CurrencyCode.USD,
              },
              annualCashEquivalent: {
                value: 50000,
                currency: CurrencyCode.USD,
              },
            },
            {
              __typename: "AdjustedCashBandPoint" as const,
              id: "1",
              bandName: CashBandName.SALARY,
              name: "Mid",
              value: {
                __typename: "CashValue" as const,
                annualRate: { value: 55000, currency: CurrencyCode.USD },
                hourlyRate: {
                  value: 55_000 / 2080,
                  currency: CurrencyCode.USD,
                },
                currencyCode: CurrencyCode.USD,
              },
              annualCashEquivalent: {
                value: 55000,
                currency: CurrencyCode.USD,
              },
            },
            {
              __typename: "AdjustedCashBandPoint" as const,
              id: "1",
              bandName: CashBandName.SALARY,
              name: "Max",
              value: {
                __typename: "CashValue" as const,
                annualRate: { value: 60000, currency: CurrencyCode.USD },
                hourlyRate: {
                  value: 60_000 / 2080,
                  currency: CurrencyCode.USD,
                },
                currencyCode: CurrencyCode.USD,
              },
              annualCashEquivalent: {
                value: 60000,
                currency: CurrencyCode.USD,
              },
            },
          ],
        },
      ],
    },
    perfRating: "Excellent",
    lastActivityAt: plainDateToUTCString(new Date()),
    compRecommendation: {
      __typename: "CompRecommendation2" as const,
      id: 3,
      subjectId: 1,
      compCycleId: 1,
      canICurrentlyReview: true,
      canICurrentlyEdit: true,

      subject: {
        __typename: "Employee2" as const,
        id: 1,
      },
      updatedAt: new Date(),
      allSubmittedItems: [
        {
          __typename: "RecItem2" as const,
          id: 3,
          submittedAt: null,
          createdAt: new Date(),
          authorId: 1,
          author: {
            __typename: "User2" as const,
            id: 1,
            email: "annette@example.com",
            name: "Annette Black",
            photoURL: "http://image.com/1",
          },
          recommendationType: RecItemType.MERIT_INCREASE,
          recommendedCashValue: {
            value: 5000,
            currency: CurrencyCode.USD,
          },
          recommendedEquityUnitCount: null,
          recommendedPercentValue: null,
          recommendedTitle: null,
          adjustedEquityBands: [],
          adjustedCashBands: [
            {
              __typename: "AdjustedCashBand" as const,
              id: "1",
              name: CashBandName.SALARY,
              bandPoints: [
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  name: "Min",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 50000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 50_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 50000,
                    currency: CurrencyCode.USD,
                  },
                },
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  name: "Mid",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 55000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 55_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 55000,
                    currency: CurrencyCode.USD,
                  },
                },
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  name: "Max",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 60000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 60_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 60000,
                    currency: CurrencyCode.USD,
                  },
                },
              ],
            },
          ],
          recommendedPosition: {
            __typename: "Position" as const,
            id: 1,
            name: "Senior Software Engineer",
            level: 3,
            type: PositionType.ANNUAL,
            ladder: {
              id: 1,
              name: "Software Engineer",
              __typename: "Ladder" as const,
              department: {
                __typename: "Department" as const,
                id: 1,
                name: "Engineering",
              },
            },
          },
          note: null,
          unitType: null,
        },
      ],
      latestSubmittedPayIncrease: {
        __typename: "CompValue" as const,
        annualCashEquivalent: money(5000, CurrencyCode.USD),
        hourlyCashEquivalent: money(5000 / 2080, CurrencyCode.USD),
        unitType: CompUnit.CASH,
      },
      latestSubmittedItems: [
        {
          __typename: "RecItem2" as const,
          id: 3,
          submittedAt: new Date(),
          createdAt: new Date(),
          authorId: 1,
          author: {
            __typename: "User2" as const,
            id: 1,
            email: "annette@example.com",
            name: "Annette Black",
            photoURL: "http://image.com/1",
            employee: { __typename: "Employee2" as const, id: 1003 },
          },
          recommendationType: RecItemType.MERIT_INCREASE,
          recommendedCashValue: {
            value: 5000,
            currency: CurrencyCode.USD,
          },
          recommendedEquityUnitCount: null,
          recommendedPercentValue: null,
          recommendedTitle: null,
          adjustedEquityBands: [],
          adjustedCashBands: [
            {
              __typename: "AdjustedCashBand" as const,
              id: "1",
              name: CashBandName.SALARY,
              bandPoints: [
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Min",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 50000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 50_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 50000,
                    currency: CurrencyCode.USD,
                  },
                },
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Mid",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 55000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 55_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 55000,
                    currency: CurrencyCode.USD,
                  },
                },
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Max",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 60000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 60_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 60000,
                    currency: CurrencyCode.USD,
                  },
                },
              ],
            },
          ],
          recommendedPosition: {
            __typename: "Position" as const,
            id: 1,
            name: "Senior Software Engineer",
            level: 3,
            type: PositionType.ANNUAL,
            ladder: {
              id: 1,
              name: "Software Engineer",
              __typename: "Ladder" as const,
              department: {
                __typename: "Department" as const,
                id: 1,
                name: "Engineering",
              },
            },
          },
          note: null,
          unitType: null,
        },
      ],
      allSubmittedReviews: [],
      latestSubmittedReviews: [],
      reviewStatus: CompRecommendationStatus.NEEDS_REVIEW,
      RelativeCompRecStatus: RelativeCompRecStatus.NEEDS_REVIEW,
    },
  },
  {
    __typename: "CompCycleParticipant" as const,
    subjectId: 1001,
    compCycleId: 1,
    phaseTimeline: [],
    subject: {
      ...MOCK_DETAILED_EMPLOYEE2,
      id: 1004,
      userId: 1004,
      photoURL: null,
      phaseTimeline: [],
      employeeNumber: "E1004",
      displayName: "Floyd Miles",
      isManager: true,
      employmentStatus: EmploymentStatus.ACTIVE,
      lastCompChangeDate: plainDateToUTCString(new Date("2023-01-01")),
      lastCompChangeAmount: {
        __typename: "CompValue" as const,
        annualCashEquivalent: dollars(5_000),
        hourlyCashEquivalent: dollars(5_000 / 2080),
        unitType: CompUnit.CASH,
      },
      adjustedEquityBands: [],
      adjustedCashBands: [
        {
          __typename: "AdjustedCashBand" as const,
          id: "1",
          name: CashBandName.SALARY,
          bandPoints: [
            {
              __typename: "AdjustedCashBandPoint" as const,
              id: "1",
              bandName: CashBandName.SALARY,
              name: "Min",
              value: {
                __typename: "CashValue" as const,
                annualRate: { value: 50000, currency: CurrencyCode.USD },
                hourlyRate: {
                  value: 50_000 / 2080,
                  currency: CurrencyCode.USD,
                },
                currencyCode: CurrencyCode.USD,
              },
              annualCashEquivalent: {
                value: 50000,
                currency: CurrencyCode.USD,
              },
            },
            {
              __typename: "AdjustedCashBandPoint" as const,
              id: "1",
              bandName: CashBandName.SALARY,
              name: "Mid",
              value: {
                __typename: "CashValue" as const,
                annualRate: { value: 55000, currency: CurrencyCode.USD },
                hourlyRate: {
                  value: 55_000 / 2080,
                  currency: CurrencyCode.USD,
                },
                currencyCode: CurrencyCode.USD,
              },
              annualCashEquivalent: {
                value: 55000,
                currency: CurrencyCode.USD,
              },
            },
            {
              __typename: "AdjustedCashBandPoint" as const,
              id: "1",
              bandName: CashBandName.SALARY,
              name: "Max",
              value: {
                __typename: "CashValue" as const,
                annualRate: { value: 60000, currency: CurrencyCode.USD },
                hourlyRate: {
                  value: 60_000 / 2080,
                  currency: CurrencyCode.USD,
                },
                currencyCode: CurrencyCode.USD,
              },
              annualCashEquivalent: {
                value: 60000,
                currency: CurrencyCode.USD,
              },
            },
          ],
        },
      ],
    },
    perfRating: "Satisfactory",
    lastActivityAt: plainDateToUTCString(new Date()),
    compRecommendation: {
      __typename: "CompRecommendation2" as const,
      id: 4,
      canICurrentlyReview: true,
      canICurrentlyEdit: true,
      compCycleId: 1,
      subjectId: 1,
      subject: {
        __typename: "Employee2" as const,
        id: 1,
      },
      updatedAt: new Date(),
      hasNotes: true,
      allSubmittedItems: [
        {
          __typename: "RecItem2" as const,
          id: 4,
          submittedAt: new Date(),
          createdAt: new Date(),
          authorId: 1,
          author: {
            __typename: "User2" as const,
            id: 1,
            email: "annette@example.com",
            name: "Annette Black",
            photoURL: "http://image.com/1",
          },
          recommendationType: RecItemType.MERIT_INCREASE,
          recommendedCashValue: {
            value: 5000,
            currency: CurrencyCode.USD,
          },
          recommendedEquityUnitCount: null,
          recommendedPercentValue: null,
          recommendedPosition: null,
          recommendedTitle: null,
          note: "This is a note",
          unitType: null,
        },
        {
          __typename: "RecItem2" as const,
          id: 4,
          submittedAt: new Date(),
          createdAt: new Date(),
          authorId: 1,
          author: {
            __typename: "User2" as const,
            id: 1,
            email: "annette@example.com",
            name: "Annette Black",
            photoURL: "http://image.com/1",
          },
          recommendationType: RecItemType.PROMOTION,
          recommendedCashValue: {
            value: 7000,
            currency: CurrencyCode.USD,
          },
          recommendedPercentValue: null,
          recommendedEquityUnitCount: null,
          recommendedTitle: null,
          adjustedEquityBands: [],
          adjustedCashBands: [
            {
              __typename: "AdjustedCashBand" as const,
              id: "1",
              name: CashBandName.SALARY,
              bandPoints: [
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Min",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 50000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 50_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 50000,
                    currency: CurrencyCode.USD,
                  },
                },
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Mid",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 55000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 55_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 55000,
                    currency: CurrencyCode.USD,
                  },
                },
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Max",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 60000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 60_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 60000,
                    currency: CurrencyCode.USD,
                  },
                },
              ],
            },
          ],
          recommendedPosition: {
            __typename: "Position" as const,
            id: 1,
            name: "Senior Software Engineer",
            level: 3,
            type: PositionType.ANNUAL,
            ladder: {
              id: 1,
              name: "Software Engineer",
              __typename: "Ladder" as const,
              department: {
                __typename: "Department" as const,
                id: 1,
                name: "Engineering",
              },
            },
          },
          note: "This is a note",
          unitType: null,
          ladder: {
            id: 1,
            name: "Software Engineer",
            __typename: "Ladder" as const,
            department: {
              __typename: "Department" as const,
              id: 1,
              name: "Engineering",
            },
          },
        },
      ],
      latestSubmittedPayIncrease: {
        __typename: "CompValue" as const,
        annualCashEquivalent: { value: 5000, currency: CurrencyCode.USD },
        hourlyCashEquivalent: {
          value: 5000 / 2080,
          currency: CurrencyCode.USD,
        },
        unitType: CompUnit.CASH,
      },
      latestSubmittedItems: [
        {
          __typename: "RecItem2" as const,
          id: 4,
          submittedAt: new Date(),
          createdAt: new Date(),
          authorId: 1,
          author: {
            __typename: "User2" as const,
            id: 1,
            email: "annette@example.com",
            name: "Annette Black",
            photoURL: "http://image.com/1",
            employee: { __typename: "Employee" as const, id: 1004 },
          },
          recommendationType: RecItemType.MERIT_INCREASE,
          recommendedCashValue: {
            value: 5000,
            currency: CurrencyCode.USD,
          },
          recommendedEquityUnitCount: null,
          recommendedPercentValue: null,
          recommendedTitle: null,
          adjustedEquityBands: [],
          adjustedCashBands: null,
          recommendedPosition: null,
          note: "This is a note",
          unitType: null,
        },
        {
          __typename: "RecItem2" as const,
          id: 4,
          submittedAt: new Date(),
          createdAt: new Date(),
          authorId: 1,
          author: {
            __typename: "User2" as const,
            id: 1,
            email: "annette@example.com",
            name: "Annette Black",
            photoURL: "http://image.com/1",
            employee: { __typename: "Employee" as const, id: 1004 },
          },
          recommendationType: RecItemType.PROMOTION,
          recommendedCashValue: {
            value: 7000,
            currency: CurrencyCode.USD,
          },
          recommendedEquityUnitCount: null,
          recommendedPercentValue: null,
          recommendedTitle: null,
          adjustedEquityBands: [],
          adjustedCashBands: [
            {
              __typename: "AdjustedCashBand" as const,
              id: "1",
              name: CashBandName.SALARY,
              bandPoints: [
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Min",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 50000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 50_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 50000,
                    currency: CurrencyCode.USD,
                  },
                },
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Mid",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 55000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 55_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 55000,
                    currency: CurrencyCode.USD,
                  },
                },
                {
                  __typename: "AdjustedCashBandPoint" as const,
                  id: "1",
                  bandName: CashBandName.SALARY,
                  name: "Max",
                  value: {
                    __typename: "CashValue" as const,
                    annualRate: { value: 60000, currency: CurrencyCode.USD },
                    hourlyRate: {
                      value: 60_000 / 2080,
                      currency: CurrencyCode.USD,
                    },
                    currencyCode: CurrencyCode.USD,
                  },
                  annualCashEquivalent: {
                    value: 60000,
                    currency: CurrencyCode.USD,
                  },
                },
              ],
            },
          ],
          recommendedPosition: {
            __typename: "Position" as const,
            id: 1,
            name: "Senior Software Engineer",
            level: 3,
            type: PositionType.ANNUAL,
            ladder: {
              id: 1,
              name: "Software Engineer",
              __typename: "Ladder" as const,
              department: {
                __typename: "Department" as const,
                id: 1,
                name: "Engineering",
              },
            },
          },
          note: "This is a note",
          unitType: null,
        },
      ],
      allSubmittedReviews: [],
      latestSubmittedReviews: [],
      reviewStatus: CompRecommendationStatus.NEEDS_REVIEW,
      RelativeCompRecStatus: RelativeCompRecStatus.NEEDS_REVIEW,
    },
  },
];

export const MOCK_EMPLOYEE_WITHOUT_COMP_REC = {
  ...MOCK_COMP_CYCLE_EMPLOYEES[0],
  compRecommendation: null,
};

export const MOCK_BENEFITS_PACKAGES: BenefitsPackageFields[] = [
  {
    __typename: "BenefitsPackage" as const,
    id: 1,
    name: "Mystery Package",
    currencyCode: CurrencyCode.GBP,
    totalValue: { value: 7500, currency: CurrencyCode.GBP },
    benefits: [
      {
        __typename: "Benefit" as const,
        id: 1,
        benefitsPackageId: 1,
        value: null,
        name: "Free Food",
        description: "Unlimited Scooby Snacks!",
        rank: "abc",
      },
      {
        __typename: "Benefit" as const,
        id: 2,
        benefitsPackageId: 2,
        value: { value: 7500, currency: CurrencyCode.GBP },
        name: "Healthcare",
        description: "You don't need it.",
        rank: "bcd",
      },
    ],
  },
];

export const MOCK_ORGANIZATION: Organization = {
  __typename: "Organization" as const,
  id: 1,
  name: "Mystery Inc.",
};
