import { createContext, ReactNode, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GetParticipantsInput } from "src/__generated__/graphql";
import { useAuth } from "src/components/Auth/AuthContext";
import { FilterParam } from "src/models/FilterParams";
import { useURLSearchParams } from "src/models/URLSearchParams";
import { fieldToFilterParam, parseFilterFromUrl } from "../filterHelpers";

type Props = {
  filter: GetParticipantsInput;
  actingManagerEmployeeId: number | null;
  handleFilterReset: () => void;
  handleFilterChange: (
    field: keyof Omit<GetParticipantsInput, "displayName" | "offset" | "limit">,
    values: string[] | number[]
  ) => void;
};

export const TableFilterContext = createContext<Props>({
  actingManagerEmployeeId: null,
  filter: {},
  handleFilterReset: () => {
    // empty default
  },
  handleFilterChange: () => {
    // empty default
  },
});

export const TableFilterProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { permissions } = useAuth();
  const navigate = useNavigate();
  const urlSearchParams = useURLSearchParams();

  const handleFilterChange = (
    field: keyof Omit<GetParticipantsInput, "displayName" | "offset" | "limit">,
    values: string[] | number[]
  ) => {
    const filterParam = fieldToFilterParam(field);
    if (filterParam === null) return;

    if (values.length === 0) {
      navigate(
        {
          search: urlSearchParams.delete(filterParam).toString(),
        },
        { replace: true }
      );
    } else {
      navigate(
        {
          search: urlSearchParams
            .set(filterParam, values.toString())
            .toString(),
        },
        { replace: true }
      );
    }
  };

  const handleFilterReset = () => {
    // Only delete filter-specific params to keep unrelated URL params intact
    navigate(
      {
        search: urlSearchParams
          .deleteMany(Object.values(FilterParam))
          .toString(),
      },
      { replace: true }
    );
  };

  const filter = parseFilterFromUrl(urlSearchParams);

  const managerId = urlSearchParams.get("manager");
  const actingManagerEmployeeId =
    managerId != null && permissions.isHRBP()
      ? Number.parseInt(managerId)
      : null;

  return (
    <TableFilterContext.Provider
      value={{
        actingManagerEmployeeId,
        filter,
        handleFilterReset,
        handleFilterChange,
      }}
    >
      {children}
    </TableFilterContext.Provider>
  );
};

export const useTableFilterContext = (): Props => {
  return useContext(TableFilterContext);
};
