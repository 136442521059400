import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const EditIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Edit"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M5.793 13.5H3a.5.5 0 01-.5-.5v-2.793a.5.5 0 01.146-.353l7.5-7.5a.5.5 0 01.708 0l2.792 2.792a.5.5 0 010 .708l-7.5 7.5a.5.5 0 01-.353.146zM8.5 4L12 7.5M2.75 9.75l3.5 3.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
