import { Button, ButtonProps, makeStyles } from "@material-ui/core";
import { WHITE } from "../../theme";

const useStyles = makeStyles(() => ({
  button: {
    "&:not(.Mui-disabled)": {
      color: WHITE,
      background: `linear-gradient(145.58deg, #D91D4A 28.94%, #FF385C 87.8%)`,
      borderColor: "transparent",
      "&:hover, &:active": {
        color: WHITE,
        borderColor: "transparent",
        background: "linear-gradient(145.58deg, #CB0D3B 28.94%, #F41659 87.8%)",
      },
    },
  },
}));

export function DangerButton(props: ButtonProps): JSX.Element {
  const classes = useStyles();
  const className = `${classes.button} ${props.className ?? ""}`;
  return <Button {...props} className={className} variant="contained" />;
}
