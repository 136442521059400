import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { AssembleTypography } from "../../components/AssembleTypography";
import { DeleteButton } from "../../components/Form/DeleteButton";
import { SaveButton } from "../../components/Form/SaveButton";
import { GRAY_5 } from "../../theme";

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(3) },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  context: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(3),
  },
  button: { height: theme.spacing(5) },
  cancelButton: { width: theme.spacing(9.375), borderColor: GRAY_5 },
}));

type PositionData = {
  name: string;
  description: string | null;
  level: number;
  jobCodes: string[];
};

type FormState = Record<keyof PositionData, string>;
type Validator = Record<keyof PositionData, (formState: FormState) => boolean>;

type Props = {
  mode: "create" | "edit";
  persistedPosition?: PositionData;
  onSubmit: (data: PositionData) => Promise<boolean>;
  onCancel: () => unknown;
  onDelete?: () => unknown;
};

export function EditPositionForm({
  mode,
  persistedPosition,
  onSubmit,
  onCancel,
  onDelete,
}: Props): JSX.Element {
  const classes = useStyles();

  const [formState, setFormState] = useState({
    name: persistedPosition?.name ?? "",
    description: persistedPosition?.description ?? "",
    level: persistedPosition?.level.toString() ?? "",
    jobCodes: persistedPosition?.jobCodes.join(",") ?? "",
  });

  async function handleSubmit() {
    const data = parseForm(formState);
    if (!data) {
      return false;
    }
    return onSubmit(data);
  }

  const jobCodesError =
    formState.jobCodes !== "" && !validator.jobCodes(formState);

  return (
    <Dialog className={classes.root} open onClose={onCancel} fullWidth>
      <DialogTitle id="form-dialog-title" className={classes.title}>
        <AssembleTypography variant="h4">
          {mode === "create" ? "Create Position" : "Edit Position"}
        </AssembleTypography>
      </DialogTitle>
      <DialogContent className={classes.context}>
        <TextField
          autoFocus
          fullWidth
          id="position-name"
          label="Position Name"
          variant="outlined"
          value={formState.name}
          onChange={(event) =>
            setFormState({ ...formState, name: event.target.value })
          }
          required
        />

        <TextField
          id="position-level"
          label="Level Number"
          variant="outlined"
          value={formState.level}
          fullWidth={false}
          InputProps={{ inputMode: "numeric" }}
          onChange={(event) =>
            setFormState({ ...formState, level: event.target.value })
          }
          required
          error={formState.level !== "" && !validator.level(formState)}
        />

        <TextField
          fullWidth
          id="position-description"
          inputProps={{ maxLength: 1000 }}
          label="Description"
          variant="outlined"
          value={formState.description}
          onChange={(event) =>
            setFormState({ ...formState, description: event.target.value })
          }
          multiline
          rowsMax={8}
        />

        <TextField
          id="position-job-codes"
          label="Job Codes"
          helperText={
            jobCodesError
              ? "One or more of the values you entered is empty. Please enter valid values separated by a comma (e.g. value1, value2, value3)"
              : "Enter values separated by a comma (e.g. value1, value2, value3)"
          }
          variant="outlined"
          value={formState.jobCodes}
          fullWidth={false}
          onChange={(event) =>
            setFormState({ ...formState, jobCodes: event.target.value })
          }
          error={jobCodesError}
        />
      </DialogContent>
      <DialogActions>
        {mode === "edit" && onDelete && (
          <>
            <DeleteButton
              buttonType="button"
              objectName="Position"
              size="small"
              onDelete={onDelete}
            />
            <div style={{ flex: 1 }} />
          </>
        )}
        <Button
          className={clsx(classes.button, classes.cancelButton)}
          onClick={onCancel}
          variant="outlined"
          size="large"
        >
          <AssembleTypography variant="productSmallBold">
            Cancel
          </AssembleTypography>
        </Button>
        <SaveButton
          className={classes.button}
          disabled={
            !validateForm(formState) ||
            !isFormDirty(formState, persistedPosition)
          }
          onSave={handleSubmit}
          onAfterSave={onCancel}
          cooldown={500}
          hideEndIcon
        />
      </DialogActions>
    </Dialog>
  );
}

const validator: Validator = {
  name: (formState) => formState.name !== "",
  description: () => true,
  level: (formState) =>
    formState.level !== "" && !isNaN(Number(formState.level)),
  jobCodes: (formState) =>
    formState.jobCodes === "" ||
    !formState.jobCodes
      .split(",")
      .map((code) => code.trim())
      .includes(""),
};

function validateForm(formState: FormState): boolean {
  return Object.values(validator).every((validate) => validate(formState));
}

function parseForm(formState: FormState): PositionData | null {
  if (!validateForm(formState)) {
    return null;
  }

  return {
    name: formState.name,
    description: formState.description === "" ? null : formState.description,
    level: parseInt(formState.level),
    jobCodes:
      formState.jobCodes === ""
        ? []
        : formState.jobCodes.split(",").map((code) => code.trim()),
  };
}

function isFormDirty(
  formState: FormState,
  persisted: PositionData | undefined
): boolean {
  const parsed = parseForm(formState);
  return (
    parsed == null ||
    persisted == null ||
    Object.keys(parsed).some(
      (key) =>
        parsed[key as keyof PositionData] !==
        persisted[key as keyof PositionData]
    )
  );
}
