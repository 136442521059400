import { ISettings } from "@flatfile/adapter";
import { DECIMAL_VALIDATOR } from "./Validators";

export const ActualVariableCompFields: ISettings["fields"] = [
  {
    label: "Payment Date",
    description: "The date that this was paid out.\nFormat: YYYY-MM-DD",
    key: "paymentDate",
    validators: [
      { validate: "required" },
      {
        validate: "regex_matches",
        regex: "^\\d{4}-\\d{2}-\\d{2}$",
        error: "Must be in format YYYY-MM-DD",
      },
    ],
  },
  {
    label: "Type",
    key: "type",
    description:
      "The type of variable comp you want to upload.\nMust be one of 'COMMISSION' or 'BONUS'",
    type: "select",
    options: ["COMMISSION", "BONUS"].map((code) => ({
      label: code,
      value: code,
    })),
    validators: [{ validate: "required" }],
  },
  {
    label: "Subtype",
    key: "subType",
    description: "The subtype of variable comp you want to upload.",
  },
  {
    label: "Value",
    key: "value",
    description: "The value of the variable comp you want to upload.",
    validators: [
      { validate: "required" },
      {
        validate: "regex_matches",
        regex: DECIMAL_VALIDATOR,
        error: "Must be a number",
      },
    ],
  },
];
