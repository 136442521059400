import { Box, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { ReactNode } from "react";
import { InfoBanner } from "../../components/InfoBanner";
import FormHeader from "../../components/Settings/FormHeader";
import Transition from "../../components/Settings/Transition";

type Props = {
  header?: string;
  description?: string | JSX.Element;
  children: ReactNode;
  centerContent?: boolean;
  banner?: JSX.Element | false;
};

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    paddingBottom: theme.spacing(4),
  },
  centerContent: {
    width: theme.spacing(67),
    left: "50%",
    position: "absolute",
    transform: "translate(-50%)",
  },
  fillPage: {
    // settings sidebar width
    paddingLeft: theme.spacing(31),
  },
}));

export const PageContainer = ({
  header,
  description,
  children,
  centerContent = false,
  banner,
}: Props) => {
  const classes = useStyles();
  return (
    <Transition>
      <Box
        className={clsx(
          classes.formContainer,
          centerContent ? classes.centerContent : classes.fillPage
        )}
      >
        {banner !== undefined && banner !== false && (
          <InfoBanner level="error">{banner}</InfoBanner>
        )}
        {header !== undefined && (
          <FormHeader description={description} header={header} />
        )}
        {children}
      </Box>
    </Transition>
  );
};
