import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { AssembleLink } from "../../components/AssembleLink";
import { InfoBanner } from "../../components/InfoBanner";
import { GET_NEEDS_MAPPING_COUNTS } from "../../queries";
import { BLUE_2 } from "../../theme";
import { GetNeedsMappingCounts } from "../../__generated__/graphql";

const useStyles = makeStyles(() => ({
  bannerLink: {
    color: BLUE_2,
    fontWeight: 700,
    fontSize: "1rem",
  },
}));

export function LevelingBanner(): JSX.Element | null {
  const [isBannerShowing, setIsBannerShowing] = useState<boolean>(true);

  const { data, loading } = useQuery<GetNeedsMappingCounts>(
    GET_NEEDS_MAPPING_COUNTS,
    // Doesn't check cache before making a request, otherwise banner will still
    // appear after all employees are leveled
    {
      fetchPolicy: "network-only",
    }
  );
  const employeesNeedLeveling = (data?.countEmployeesNeedingLeveling ?? 0) > 0;
  const classes = useStyles();

  if (!employeesNeedLeveling && !loading) {
    return null;
  }

  return employeesNeedLeveling && isBannerShowing ? (
    <InfoBanner onClose={() => setIsBannerShowing(false)} level="warning">
      {`Some employees haven't been leveled. To see a breakdown for your entire organization, go to the `}
      <AssembleLink
        className={classes.bannerLink}
        to="/people"
        whitespace={false}
        variant="body2"
      >
        People Page
      </AssembleLink>
      {` and finish mapping your HRIS job titles to Assemble positions.`}
    </InfoBanner>
  ) : null;
}
