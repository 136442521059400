import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const LogoIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Assemble Logo"
      viewBox="0 0 94 117"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill"
        d="M0 25V86.9826L11.7545 93.6584V73.0341L40.012 90.8938V109.822L51.891 117H52V56.3683L0 25ZM11.7545 45.1373L40.012 63.0126V77.0396L11.7545 59.981V45.1373Z"
      />
      <path
        className="fill"
        d="M94 31.1875V91.2188L82.6218 98V37.9687L31 6.39063L41.7254 0L94 31.1875Z"
      />
      <path
        className="fill"
        d="M73 43.1974L20.7254 12L10 18.4083L61.6218 49.9965V110L72.6891 103.404L73 103.217V43.1974Z"
      />
    </svg>
  </ColorableSVG>
);
