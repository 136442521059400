import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { ArchiveOutlined } from "@material-ui/icons";
import { SyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import { EditIcon } from "src/components/AssembleIcons/Brand/EditIcon";
import { AssembleTypography } from "../../components/AssembleTypography";
import { ExplanatoryTooltip } from "../../components/ExplanatoryTooltip";
import { Offer } from "./OfferList";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    justifyContent: "center",
  },
}));

type Props = {
  offer: Offer;
  index: number;
  handleArchive: (id: number) => unknown;
  archiveOfferLoading: boolean;
};

export const OfferActions = ({
  offer,
  index,
  handleArchive,
  archiveOfferLoading,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const onArchive = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleArchive(offer.id);
  };

  const onEdit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/offers/${offer.id}/edit`);
  };

  return (
    <div className={classes.root}>
      {offer.expiredAt === null && (
        <EditTooltip offer={offer}>
          <span data-cy={`edit-button-${index}`} onClick={onEdit}>
            <IconButton
              data-cy={`edit-button-${index}`}
              size="small"
              disabled={offer.isLegacy}
              onClick={onEdit}
            >
              <EditIcon inherit width="24px" height="24px" />
            </IconButton>
          </span>
        </EditTooltip>
      )}
      {offer.expiredAt === null ? (
        <Tooltip title="Archive Offer" placement="top">
          <IconButton
            data-cy={`archive-button-${index}`}
            size="small"
            onClick={onArchive}
            disabled={archiveOfferLoading}
          >
            <ArchiveOutlined />
          </IconButton>
        </Tooltip>
      ) : (
        <AssembleTypography variant="productTableContent">
          Archived
        </AssembleTypography>
      )}
    </div>
  );
};

function EditTooltip({
  offer,
  children,
}: {
  offer: Offer;
  children: JSX.Element;
}): JSX.Element {
  return offer.isLegacy ? (
    <ExplanatoryTooltip
      placement="top"
      title="You can't edit this offer"
      body="Offers created before we added the ability to edit cannot be modified."
    >
      {children}
    </ExplanatoryTooltip>
  ) : (
    <Tooltip placement="top" title="Edit Offer">
      {children}
    </Tooltip>
  );
}
