import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { Noun, Verb } from "@asmbl/shared/permissions";
import { Box, Link, Tooltip, makeStyles } from "@material-ui/core";
import { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { LogoIcon } from "src/components/AssembleIcons/Brand/LogoIcon";
import { CompReviewIcon } from "src/components/AssembleIcons/Navigation/CompReviewIcon";
import { EmployeePortalIcon } from "src/components/AssembleIcons/Navigation/EmployeePortalIcon";
import { FoundationsIcon } from "src/components/AssembleIcons/Navigation/FoundationsIcon";
import { GiftIcon } from "src/components/AssembleIcons/Navigation/GiftIcon";
import { HomeIcon } from "src/components/AssembleIcons/Navigation/HomeIcon";
import { InsightsIcon } from "src/components/AssembleIcons/Navigation/InsightsIcon";
import { OffersIcon } from "src/components/AssembleIcons/Navigation/OffersIcon";
import { PayEquityIcon } from "src/components/AssembleIcons/Navigation/PayEquityIcon";
import { PeopleIcon } from "src/components/AssembleIcons/Navigation/PeopleIcon";
import { PhilosophyIcon } from "src/components/AssembleIcons/Navigation/PhilosophyIcon";
import { SettingsIcon } from "src/components/AssembleIcons/Navigation/SettingsIcon";
import { PrimaryRoleName } from "../../../__generated__/graphql";
import {
  canSeeOrganizationView,
  canSeeYourTeamView,
} from "../../../pages/People/PeoplePageRouter";
import { WHITE } from "../../../theme";
import { AccessBoundary } from "../../AccessBoundary";
import { useAuth } from "../../Auth/AuthContext";
import { PreviewFeature } from "../../FeatureContext";
import { UserAvatar } from "../../UserAvatar";
import { NavigationIcon } from "./NavigationIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "80px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: WHITE,
    padding: theme.spacing(4, 0),
    rowGap: theme.spacing(2),
  },

  navContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto",
    paddingTop: "16px",

    // Hide scrollbars
    "&::-webkit-scrollbar": {
      display: "none" /* Chrome, Safari and Opera */,
    },
    "-ms-overflow-style": "none" /* IE and Edge */,
    scrollbarWidth: "none" /* Firefox */,

    // Fancy scrolling shadows (https://codepen.io/chriscoyier/pen/YzXBYvL)
    background: `
      /* Shadow Cover TOP */
      linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
      ) center top,

      /* Shadow Cover BOTTOM */
      linear-gradient(
        rgba(255, 255, 255, 0),
        white 70%
      ) center bottom,

      /* Shadow TOP */
      linear-gradient(
        rgba(112, 133, 152, 0.09),
        rgba(0, 0, 0, 0)
      ) center top,

      /* Shadow BOTTOM */
      linear-gradient(
        rgba(0, 0, 0, 0) 0%,
        rgba(112, 133, 152, 0.09) 80%,
        rgba(112, 133, 152, 0.18) 100%
      ) center bottom
    `,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 12px, 100% 24px, 100% 6px, 100% 12px",
    backgroundAttachment: "local, local, scroll, scroll",
  },
  settingsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const NavigationBar = memo(function NavigationBar() {
  const classes = useStyles();
  const { user, permissions } = useAuth();

  return (
    <Box className={classes.root}>
      <LogoIcon height="40px" width="40px" />

      <Box className={classes.navContainer}>
        {permissions.hasAnyPermissions() && (
          <>
            {permissions.roleName === PrimaryRoleName.FULL_ACCESS && (
              <NavigationIcon
                Component={HomeIcon}
                matches={["home"]}
                route="/home"
                label="Home"
              />
            )}
            <AccessBoundary
              verb="view"
              scope="any"
              every={[Noun.JobStructure, Noun.CashBand, Noun.EquityBand]}
            >
              <NavigationIcon
                Component={InsightsIcon}
                matches={["insights"]}
                route="/insights"
                label="Insights"
              />
            </AccessBoundary>
            <PreviewFeature flag={FeatureFlag.OffersModule}>
              <AccessBoundary verb="view" scope="any" noun={Noun.Offer}>
                <NavigationIcon
                  Component={OffersIcon}
                  matches={["offers"]}
                  route="/offers"
                  label="Offers"
                />
              </AccessBoundary>
            </PreviewFeature>
            <AccessBoundary verb="view" scope="any" noun={Noun.JobStructure}>
              <NavigationIcon
                Component={FoundationsIcon}
                matches={["", "departments", "ladders", "positions"]}
                route="/"
                label="Foundations"
              />
            </AccessBoundary>
            <AccessBoundary verb="view" scope="any" noun={Noun.Philosophy}>
              <NavigationIcon
                Component={PhilosophyIcon}
                matches={["philosophy"]}
                route="/philosophy"
                label="Philosophy"
              />
            </AccessBoundary>
            {(canSeeOrganizationView(permissions) ||
              canSeeYourTeamView(permissions)) && (
              <NavigationIcon
                Component={PeopleIcon}
                matches={["people"]}
                route="/people"
                label="People"
              />
            )}
            <AccessBoundary
              verb="view"
              scope="any"
              every={[
                Noun.Employee,
                Noun.EmployeeCashCompensation,
                Noun.SensitiveData,
              ]}
            >
              <NavigationIcon
                Component={PayEquityIcon}
                matches={["pay-equity"]}
                route="/pay-equity"
                label="Pay Equity"
              />
            </AccessBoundary>
            <PreviewFeature flag={FeatureFlag.AdHocCompRecommendations}>
              <AccessBoundary
                every={[Noun.CompCycle, Noun.CompStructure]}
                scope="any"
                verb={Verb.View}
              >
                <NavigationIcon
                  Component={CompReviewIcon}
                  matches={["comp-cycles"]}
                  route="/comp-cycles"
                  label="Comp Cycles"
                />
              </AccessBoundary>
            </PreviewFeature>
            <PreviewFeature flag={FeatureFlag.EmployeePortal}>
              <NavigationIcon
                Component={EmployeePortalIcon}
                matches={["portal"]}
                route="/portal"
                label="Employee Portal"
              />
            </PreviewFeature>
          </>
        )}
      </Box>
      <Box flex={1} />
      <Box className={classes.settingsContainer}>
        <Tooltip title="Refer a Friend" placement="right">
          <div data-cy="gift-icon">
            <NavigationIcon
              Component={GiftIcon}
              matches={["referrals"]}
              route="https://airtable.com/appOAaqyZ5DOtytRO/pag3ghvxF71GP6oJ6/form"
              isExternal
              openInNewTab
            />
          </div>
        </Tooltip>
        {permissions.hasSettingsPermissions() && (
          <div data-cy="settings-icon">
            <NavigationIcon
              Component={SettingsIcon}
              matches={["settings"]}
              route="/settings/compensation"
            />
          </div>
        )}
        <Link component={RouterLink} to="/profile" underline="none">
          <UserAvatar
            photoURL={user?.photoURL}
            displayName={user?.displayName}
          />
        </Link>
      </Box>
    </Box>
  );
});
