import { gql } from "@apollo/client";
import { Card, CardContent, CardHeader, makeStyles } from "@material-ui/core";
import { useRef, useState } from "react";
import {
  Main_employee as Employee,
  Main_user as User,
} from "src/__generated__/graphql";
import { GRAY_1, GRAY_6, GRAY_8, WHITE } from "src/theme";
import { WarningAlertIcon } from "../AssembleIcons/Brand/WarningAlertIcon";
import { AssembleTypography } from "../AssembleTypography";
import { InfoBanner } from "../InfoBanner";
import { ButtonBar } from "./ButtonBar";
import { Header } from "./Header";
import { PhaseSelectDrawer } from "./PhaseDrawer/PhaseSelectDrawer";
import { PhaseConfigurationList } from "./PhaseList/PhaseConfigurationList";
import { CreateOrEditPhasesModal } from "./PhaseModals/CreateOrEditPhasesModal";
import { PhaseAssignmentModal } from "./PhaseModals/PhaseAssignmentModal";
import { PhaseDeleteModal } from "./PhaseModals/PhaseDeleteModal";
import { PhaseEndDateModal } from "./PhaseModals/PhaseEndDateModal";
import { PhaseSaveModal } from "./PhaseModals/PhaseSaveModal";
import { PhaseStartDateModal } from "./PhaseModals/PhaseStartDateModal";
import { usePhaseConfiguration } from "./usePhaseConfiguration";
import {
  getCompCycleStartDate,
  getLatestPhaseOrder,
  getPhaseDateRange,
  getPhaseStartDate,
} from "./utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(5.25),
    justifyContent: "space-between",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  contentRoot: {
    padding: 0,
  },
  card: {
    boxShadow: "none",
    border: `1px solid ${GRAY_6}`,
    overflow: "visible",
  },
  subheader: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  cardAddContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    borderBottom: `1px solid ${GRAY_6}`,
  },

  cardButton: {
    background: GRAY_8,
    width: "100%",

    padding: theme.spacing(2, 4),

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "&:hover": {
      cursor: "pointer",
      background: GRAY_6,
    },
  },
  cardFooter: {
    width: "100%",

    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  warningBanner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

type CreateProps = {
  variant: "create";
  compCycleName: string;
  users: User[];
  employees: (Employee & { reports: number[] })[];
  onPrevious: () => void;
  onNext: () => void;
  initCreatePhaseModalOpen?: boolean;
  initCompletionDateModalOpen?: boolean;
};

type EditProps = {
  variant: "edit";
  compCycleName: string;
  users: User[];
  employees: (Employee & { reports: number[] })[];
  onNext: () => void;
  initCreatePhaseModalOpen?: boolean;
  initCompletionDateModalOpen?: boolean;
};

type Props = CreateProps | EditProps;

export function Main({
  variant,
  compCycleName,
  employees,
  users,
  onNext,
  initCreatePhaseModalOpen /** for easy storybook testing **/,
  initCompletionDateModalOpen /** for easy storybook testing **/,
  ...props
}: Props): JSX.Element {
  const classes = useStyles();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const {
    phaseConfiguration,
    setPhaseData,
    deletePhase,
    endDate,
    setEndDate,
    phaseErrors,
    validatePhases,
  } = usePhaseConfiguration();

  const onPrevious =
    variant === "create" ? (props as CreateProps).onPrevious : undefined;

  const [isCreatePhasesModalOpen, setIsCreatePhasesModalOpen] =
    useState<boolean>(initCreatePhaseModalOpen ?? true);
  const [isCompletionDateModalOpen, setIsCompletionDateModalOpen] =
    useState<boolean>(initCompletionDateModalOpen ?? false);
  const [isPhaseAccessDrawerOpen, setIsPhaseAccessDrawerOpen] =
    useState<boolean>(false);
  const [isPhaseStartDateModalOpen, setIsPhaseStartDateModalOpen] =
    useState<boolean>(false);
  const [isPhaseDeleteModalOpen, setIsPhaseDeleteModalOpen] =
    useState<boolean>(false);
  const [isPhaseSaveModalOpen, setIsPhaseSaveModalOpen] =
    useState<boolean>(false);
  const [isPhaseAssignmentModalOpen, setIsPhaseAssignmentModalOpen] =
    useState<boolean>(false);

  const [currentSelectedPhase, setCurrentSelectedPhase] = useState<
    number | null
  >(null);

  const compCycleStartDate = getCompCycleStartDate(phaseConfiguration);

  const handleCreatePhases = () => {
    setIsCreatePhasesModalOpen(false);

    const timeout = setTimeout(() => {
      setIsCompletionDateModalOpen(endDate == null);
    }, 500);

    return () => clearTimeout(timeout);
  };

  const handleAddNewPhaseButtonClick = (variant: "review" | "calibration") => {
    if (buttonRef.current != null) buttonRef.current.blur();
    const latestPhaseOrder = getLatestPhaseOrder(phaseConfiguration);
    const newPhaseOrder = latestPhaseOrder + 1;

    setCurrentSelectedPhase(newPhaseOrder);

    if (variant === "review") {
      setIsPhaseAccessDrawerOpen(true);
    } else {
      setPhaseData(newPhaseOrder);
    }
  };

  const handlePhaseSave = () => {
    setIsPhaseAccessDrawerOpen(false);
  };

  const handleCancelPhase = () => {
    if (currentSelectedPhase == null) return;
    setIsPhaseAccessDrawerOpen(false);
  };

  const handleEditPhaseAccess = (phaseOrder: number) => {
    setCurrentSelectedPhase(phaseOrder);
    setIsPhaseAccessDrawerOpen(true);
  };

  const handleEditPhaseDate = (phaseOrder: number) => {
    setCurrentSelectedPhase(phaseOrder);
    setIsPhaseStartDateModalOpen(true);
  };

  const handlePhaseDelete = (phaseOrder: number) => {
    setCurrentSelectedPhase(phaseOrder);
    setIsPhaseDeleteModalOpen(true);
  };

  const handleConfigComplete = () => {
    const isValid = validatePhases();
    if (!isValid) return;

    setIsPhaseSaveModalOpen(true);
  };

  const handleDelete = () => {
    if (currentSelectedPhase == null) return;
    deletePhase(currentSelectedPhase);
    setIsPhaseDeleteModalOpen(false);
  };

  return (
    <>
      <CreateOrEditPhasesModal
        variant={
          variant === "create"
            ? phaseConfiguration.length === 0
              ? "create"
              : "edit"
            : "edit"
        }
        isOpen={isCreatePhasesModalOpen}
        onPrevious={onPrevious as () => void}
        onContinue={handleCreatePhases}
      />
      <PhaseSaveModal
        variant={variant}
        isOpen={isPhaseSaveModalOpen}
        handleClose={() => setIsPhaseSaveModalOpen(false)}
        handleSave={onNext}
      />
      <PhaseEndDateModal
        isOpen={isCompletionDateModalOpen}
        date={endDate}
        setDate={setEndDate}
        onDateSave={() => setIsCompletionDateModalOpen(false)}
        initDateSelectorOpenValue={false}
      />
      <PhaseAssignmentModal
        compCycleName={compCycleName}
        employees={employees}
        isOpen={isPhaseAssignmentModalOpen}
        handleClose={() => setIsPhaseAssignmentModalOpen(false)}
        startDate={compCycleStartDate}
        endDate={endDate}
      />
      {currentSelectedPhase != null && (
        <PhaseStartDateModal
          isOpen={isPhaseStartDateModalOpen}
          date={getPhaseStartDate(phaseConfiguration, currentSelectedPhase)}
          setDate={(date) =>
            setPhaseData(currentSelectedPhase, { startDate: date })
          }
          onDateSave={() => setIsPhaseStartDateModalOpen(false)}
          initDateSelectorOpenValue={false}
          dateRange={getPhaseDateRange(
            phaseConfiguration,
            endDate,
            currentSelectedPhase
          )}
        />
      )}
      {currentSelectedPhase != null && isPhaseAccessDrawerOpen && (
        <PhaseSelectDrawer
          key={currentSelectedPhase}
          phaseOrder={currentSelectedPhase}
          handleSave={handlePhaseSave}
          handleClose={handleCancelPhase}
          employees={employees}
        />
      )}
      {currentSelectedPhase != null && (
        <PhaseDeleteModal
          isOpen={isPhaseDeleteModalOpen}
          handleClose={() => setIsPhaseDeleteModalOpen(false)}
          handleDelete={handleDelete}
        />
      )}
      <div className={classes.root}>
        <Header
          variant={variant}
          startDate={compCycleStartDate}
          endDate={endDate}
          handleConfigComplete={handleConfigComplete}
          handleEditDateModalOpen={() => setIsCompletionDateModalOpen(true)}
        />
        <div className={classes.content}>
          <Card className={classes.card}>
            <CardHeader
              title={
                <AssembleTypography variant="productHeader" textColor={GRAY_1}>
                  Define Approval Phases (from final to initial)
                </AssembleTypography>
              }
              subheader={
                <div className={classes.subheader}>
                  <AssembleTypography
                    variant="productParagraphMedium"
                    textColor={GRAY_1}
                  >
                    First, start with your final approval phase (usually the
                    CEO). Then, add other phases that will take place
                    beforehand.
                  </AssembleTypography>
                  <AssembleTypography
                    variant="productSmallSemiBold"
                    textColor={GRAY_1}
                  >
                    (Note: phases will be automatically numbered, starting with
                    your initial phase as “Phase 1”)
                  </AssembleTypography>
                  {phaseErrors.length > 0 && (
                    <InfoBanner
                      className={classes.warningBanner}
                      level="error"
                      fullWidth
                    >
                      <WarningAlertIcon color={WHITE} />
                      It looks like you haven't set dates for some of the phases
                      below.
                    </InfoBanner>
                  )}
                </div>
              }
            />
            {phaseConfiguration.length > 0 && (
              <ButtonBar
                variant={variant}
                handleClick={handleAddNewPhaseButtonClick}
              />
            )}
            <CardContent className={classes.contentRoot}>
              <PhaseConfigurationList
                variant={variant}
                endDate={endDate}
                users={users}
                employees={employees}
                handleViewPhaseAccess={() =>
                  setIsPhaseAssignmentModalOpen(true)
                }
                handleDeletePhase={handlePhaseDelete}
                handleEditPhaseAccess={handleEditPhaseAccess}
                handleEditPhaseDate={handleEditPhaseDate}
              />
            </CardContent>
            {phaseConfiguration.length === 0 && (
              <div className={classes.cardFooter}>
                <ButtonBar
                  variant={variant}
                  handleClick={handleAddNewPhaseButtonClick}
                />
              </div>
            )}
          </Card>
        </div>
      </div>
    </>
  );
}

Main.fragments = {
  employee: gql`
    ${PhaseConfigurationList.fragments.employee}
    ${PhaseSelectDrawer.fragments.employee}
    ${PhaseAssignmentModal.fragments.employee}
    fragment Main_employee on Employee2 {
      id
      ...PhaseConfigurationList_employee
      ...PhaseSelectDrawer_employee
      ...PhaseAssignmentModal_employee
    }
  `,
  user: gql`
    ${PhaseConfigurationList.fragments.user}
    fragment Main_user on User {
      id
      ...PhaseConfigurationList_user
    }
  `,
};
