import { Currency } from "@asmbl/shared/currency";
import { contramap } from "@asmbl/shared/sort";
import { CurrencyCode } from "../../__generated__/graphql";
import { currencyName } from "../../models/Currency";
import { useCurrencies } from "../CurrenciesContext";
import { SelectField } from "../Form/SelectField";

interface Props {
  label: string;
  value: Currency;
  onChange: (value: Currency) => unknown;
}

const getCurrencyDisplay = (code: CurrencyCode) =>
  `${currencyName(code)} (${code})`;

export function OrgCurrencyField({
  label,
  value,
  onChange,
}: Props): JSX.Element {
  const { currenciesList } = useCurrencies();

  return (
    <SelectField
      id="currencies-select"
      label={label}
      value={value.code}
      onChange={(code) =>
        onChange(
          currenciesList.find((currency) => currency.code === code) ??
            currenciesList[0]
        )
      }
      options={currenciesList
        .map(({ code }) => ({
          value: code,
          label: getCurrencyDisplay(code),
        }))
        .sort(contramap((c) => c.label))}
    />
  );
}
