import { Box, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { memo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { BLUE_3 } from "../../../theme";
import { AssembleButton } from "../../AssembleButton/AssembleButton";
import Section from "../../Workflow/Section";
import { HAS_SEEN_OFFERS } from "../constants";
import { useStyles } from "../style";

export type IntroProps = {
  currentStep: number;
  onNext: () => unknown;
};

const Intro = ({ currentStep, onNext }: IntroProps) => {
  const classes = useStyles();
  const location = useLocation();
  const [hasSeenOffers, setHasSeenOffers] = useLocalStorage(
    HAS_SEEN_OFFERS,
    false
  );

  useEffect(() => {
    if (!hasSeenOffers) {
      setHasSeenOffers(true);
    }
  }, [hasSeenOffers, setHasSeenOffers]);

  return (
    <Box className={classes.content}>
      <Section
        id="Section-Intro"
        active={currentStep === 0}
        description="We'll need to collect some information about the role and candidate."
        header={
          location.pathname.includes("edit")
            ? "Let's edit an offer letter"
            : "Let's create a new offer letter."
        }
      >
        <Box mb={6}>
          <Box mb={2}>
            <Typography variant="body1">
              You'll need to know things like:
            </Typography>
          </Box>
          <ul className={classes.list}>
            <li>
              <Box display="flex" alignItems="center" mr={1}>
                {<Icon />}
              </Box>
              <Typography variant="body1">Job Title</Typography>
            </li>
            <li>
              <Box display="flex" alignItems="center" mr={1}>
                {<Icon />}
              </Box>
              <Typography variant="body1">Salary</Typography>
            </li>
            <li>
              <Box display="flex" alignItems="center" mr={1}>
                {<Icon />}
              </Box>
              <Typography variant="body1">Equity Information</Typography>
            </li>
          </ul>
        </Box>
        <AssembleButton
          onClick={onNext}
          variant="contained"
          size="medium"
          label="Get Started"
        />
      </Section>
    </Box>
  );
};

function Icon(): JSX.Element {
  return <Check style={{ color: BLUE_3, fontSize: "16px" }} />;
}

export default memo(Intro);
