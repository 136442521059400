import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "src/components/AssembleIcons/Brand/ArrowRightIcon";
import { ImportIcon } from "src/components/AssembleIcons/Brand/ImportIcon";
import { DeleteButton } from "src/components/Form/DeleteButton";
import { GetCompCycles } from "../../../__generated__/graphql";
import { AssembleTypography } from "../../../components/AssembleTypography";
import { EmployeePerfScoreRow } from "../../../components/CompCycle/Buttons/PerfImportButton";
import { PerfImportDialog } from "../../../components/CompCycle/Dialogs/PerfImportDialog";
import { CompCycleCsvExportWrapper } from "../../../components/CsvExport/CompCycleExportWrapper";
import { ExplanatoryTooltip } from "../../../components/ExplanatoryTooltip";
import { PageHeader, PageTitle } from "../../../components/Layout/PageHeader";
import {
  useDeleteCompCycle,
  useImportEmployeePerfScores,
} from "../../../mutations/CompCycle";
import { GRAY_1, GRAY_4, PURPLE_1, PURPLE_2 } from "../../../theme";
import { ArrayValue } from "../../../utils";

type CompCycle = ArrayValue<GetCompCycles["compCycles"]>;

const useStyles = makeStyles((theme) => ({
  table: {
    px: theme.spacing(4),
    display: "flex",
    justifyContent: "flex-start",
  },
  title: {
    color: GRAY_1,
    fontSize: "1.375rem",
    fontWeight: 500,
    marginLeft: theme.spacing(1),
  },
  clickable: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  moreInfoCell: {
    width: "200px",
  },
}));

type Props = {
  compCycles: CompCycle[];
  onlyManagerAccess: boolean;
};

export function CompCycleList({
  compCycles,
  onlyManagerAccess,
}: Props): JSX.Element {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const [isPerfDialogOpen, setIsPerfDialogOpen] = useState<number | null>(null);
  const importEmployeePerfScores = useImportEmployeePerfScores();

  const handlePerfDialogOpen = (id: number) => {
    setIsPerfDialogOpen(id);
  };
  const handlePerfDialogClose = () => setIsPerfDialogOpen(null);

  const deleteCompCycle = useDeleteCompCycle();

  const href = (cycleId: number) =>
    onlyManagerAccess
      ? `/comp-cycles/${cycleId}/requests`
      : `/comp-cycles/${cycleId}`;

  const openCycle = (cycle: CompCycle) =>
    cycle.endedAt === null && navigate(href(cycle.id));

  return (
    <>
      {!onlyManagerAccess && (
        <PerfImportDialog
          open={isPerfDialogOpen !== null}
          onClose={handlePerfDialogClose}
          onSubmit={async (perfData: EmployeePerfScoreRow[]) => {
            const cycle = compCycles.find(
              (cycle) => cycle.id === isPerfDialogOpen
            );
            if (cycle && cycle.endedAt === null) {
              await importEmployeePerfScores({
                compCycleId: cycle.id,
                employeePerfScores: perfData.map((row) => ({
                  email: row.email,
                  perfRating: row.perfRating,
                  note: row.note,
                })),
              });
              handlePerfDialogClose();
            }
          }}
        />
      )}
      <PageHeader>
        <PageTitle>Comp Cycles</PageTitle>
        {!onlyManagerAccess && (
          <Button
            component={Link}
            to="/comp-cycles/new"
            color="primary"
            size="medium"
            variant="contained"
            data-intercom-target={"button-new-comp-cycle"}
          >
            New Comp Cycle
          </Button>
        )}
      </PageHeader>
      <Box px={2} mt={2}>
        <TableContainer className={classes.table} component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell variant="head">
                  <AssembleTypography variant="productTableHeader">
                    Cycle Name
                  </AssembleTypography>
                </TableCell>
                <TableCell variant="head">
                  <AssembleTypography variant="productTableHeader">
                    Status
                  </AssembleTypography>
                </TableCell>
                <TableCell
                  align="right"
                  variant="head"
                  className={classes.moreInfoCell}
                >
                  <AssembleTypography variant="productTableHeader">
                    More Info
                  </AssembleTypography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {compCycles.map((cycle) => (
                <TableRow key={cycle.id}>
                  <TableCell
                    scope="row"
                    onClick={() => openCycle(cycle)}
                    className={clsx({
                      [classes.clickable]: cycle.endedAt === null,
                    })}
                  >
                    <Typography> {cycle.name} </Typography>
                  </TableCell>
                  <TableCell
                    scope="row"
                    onClick={() => openCycle(cycle)}
                    className={clsx({
                      [classes.clickable]: cycle.endedAt === null,
                    })}
                  >
                    <Typography>
                      {cycle.endedAt != null
                        ? `Ended on ${new Date(
                            cycle.endedAt
                          ).toLocaleDateString("en-US", {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          })}`
                        : "Active"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <div className={classes.buttonContainer}>
                      {!onlyManagerAccess && (
                        <DeleteButton
                          buttonType="icon"
                          objectName={"comp cycle"}
                          tooltipObjectName={cycle.name}
                          text="Important: This action is permanent and cannot be undone."
                          onDelete={() => deleteCompCycle(cycle.id)}
                        />
                      )}
                      {cycle.endedAt === null && !onlyManagerAccess && (
                        <Tooltip title="Upload Perf. Scores" placement="bottom">
                          <IconButton
                            style={{ width: "45px", height: "45px" }}
                            aria-label="Upload Perf. Scores"
                            size="small"
                            onClick={() => handlePerfDialogOpen(cycle.id)}
                          >
                            <ImportIcon
                              color={GRAY_4}
                              hoverColor={PURPLE_1}
                              width={"24px"}
                              height={"24px"}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      <ExplanatoryTooltip
                        title="Export your comp changes"
                        body={
                          <p>
                            Export every change that has been <b>accepted</b> by
                            your comp admin or is <b>awaiting approval</b>.
                          </p>
                        }
                      >
                        <span>
                          {!onlyManagerAccess && (
                            <CompCycleCsvExportWrapper
                              compCycleId={cycle.id}
                              compCycleName={cycle.name}
                              variant="icon"
                            />
                          )}
                        </span>
                      </ExplanatoryTooltip>
                      {cycle.endedAt === null && (
                        <Tooltip title="Go to Cycle">
                          <IconButton
                            component={Link}
                            to={{
                              pathname: href(cycle.id),
                              search: location.search,
                            }}
                          >
                            <ArrowRightIcon
                              data-cy="go-to-cycle"
                              aria-label="Go to Cycle"
                              width="24px"
                              height="24px"
                              color={GRAY_4}
                              hoverColor={PURPLE_2}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
