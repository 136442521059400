import { Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { AssembleTypography } from "src/components/AssembleTypography";
import { PURPLE_2 } from "../../../../theme";

type Props = {
  to: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "2rem",

    "&:focus, &:active, &:hover": {
      borderColor: PURPLE_2,
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: theme.spacing(1),
  },
}));

export function BackToMainButton({ to }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Button
      className={classes.root}
      variant="contained"
      component={Link}
      to={to}
    >
      <div className={classes.content}>
        <AssembleTypography variant="productParagraphSmall">
          Back to Main
        </AssembleTypography>
      </div>
    </Button>
  );
}
