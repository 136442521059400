import { Card, CardContent, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { HierarchyNode } from "d3";
import {
  OrganizationHierarchy_employee as Employee,
  EmploymentStatus,
} from "src/__generated__/graphql";
import { GRAY_1, GRAY_2, GRAY_4, GRAY_6, GRAY_7, PURPLE_1 } from "src/theme";
import { LayerIcon } from "../../AssembleIcons/Small/LayerIcon";
import { AssembleTypography } from "../../AssembleTypography";
import { EMPLOYEE_NODE_WIDTH } from "../shared/constants";

const useStyles = makeStyles((theme) => ({
  MuiCard: {
    root: {
      boxShadow: "none",
    },
  },
  card: {
    cursor: "default",
    border: `1px solid ${GRAY_6}`,
    boxShadow: "none",

    "&:hover": {
      cursor: "pointer",
      border: `1px solid ${PURPLE_1}`,
      transition: "all 125ms",
    },
  },
  container: {
    position: "relative",
    boxShadow: "none",
  },
  stacked: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1,
    border: `1px solid ${GRAY_6}`,
    boxShadow: "none",
    transition: "all 125ms",

    width: `${EMPLOYEE_NODE_WIDTH}px`, // width takes the full node width
    height: "7.25rem", // width takes a portion of the node width
    transform: "rotate(-4deg)",

    "&:hover": {
      cursor: "pointer",
      border: `1px solid ${PURPLE_1}`,
      transition: "all 125ms",
    },
  },
  cardContent: {
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3, 0),
  },
  section: {
    display: "flex",
    flexDirection: "column",
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(0.5),
  },
  layer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    columnGap: theme.spacing(0.5),
  },
  layerCopy: { marginTop: "3px" },
  inactive: {
    border: `1px solid ${GRAY_6}`,
    backgroundColor: GRAY_7,
    boxShadow: "none",

    "&:hover": {
      cursor: "default",
      backgroundColor: GRAY_7,
    },
  },
}));

type Props = { node: HierarchyNode<Employee> };

export function EmployeeCard({ node }: Props): JSX.Element {
  const classes = useStyles();

  const {
    data: {
      _directSubordinates: directReportsCount,
      _totalSubordinates: totalReportsCount,
      activeEmployment,
      employmentStatus,
    },
    depth,
  } = node;

  const layer = depth + 1;
  const indirectReportsCount = totalReportsCount - directReportsCount;
  const isExpanded = node.children != null;
  const isInactive = employmentStatus === EmploymentStatus.INACTIVE;

  return (
    <div className={classes.container}>
      {directReportsCount > 0 && !isExpanded && (
        <Card className={clsx(classes.stacked)} />
      )}
      <Card
        title={isInactive ? "This employee is inactive" : undefined}
        className={clsx(classes.card, {
          [classes.inactive]: isInactive,
        })}
      >
        <CardContent className={classes.cardContent}>
          <div className={classes.section}>
            <div className={classes.item}>
              <AssembleTypography variant="productSmallBold" textColor={GRAY_1}>
                {node.data.displayName}
              </AssembleTypography>
              <span className={classes.layer}>
                <LayerIcon inline color={GRAY_1} />
                <AssembleTypography
                  className={classes.layerCopy}
                  variant="productSmallBold"
                  textColor={GRAY_1}
                >
                  {layer}
                </AssembleTypography>
              </span>
            </div>
            <div className={classes.item}>
              <AssembleTypography
                variant="productParagraphSmall"
                textColor={GRAY_2}
              >
                {activeEmployment?.jobTitle ?? "-"}
              </AssembleTypography>
            </div>
          </div>
          <div className={classes.section}>
            <div className={classes.item}>
              <AssembleTypography
                variant="productEyebrowSmall"
                textColor={GRAY_4}
              >
                DIRECTS
              </AssembleTypography>
              <AssembleTypography
                variant="productEyebrowSmall"
                textColor={GRAY_4}
              >
                {directReportsCount}
              </AssembleTypography>
            </div>
            <div className={classes.item}>
              <AssembleTypography
                variant="productEyebrowSmall"
                textColor={GRAY_4}
              >
                INDIRECTS
              </AssembleTypography>
              <AssembleTypography
                variant="productEyebrowSmall"
                textColor={GRAY_4}
              >
                {indirectReportsCount}
              </AssembleTypography>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
