import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const EyeOnIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Visible"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M12 5.24902C4.5 5.24902 1.5 11.9998 1.5 11.9998C1.5 11.9998 4.5 18.749 12 18.749C19.5 18.749 22.5 11.9998 22.5 11.9998C22.5 11.9998 19.5 5.24902 12 5.24902Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
