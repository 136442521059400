import { useMutation, useQuery } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { zero } from "@asmbl/shared/money";
import fastDeepEqual from "fast-deep-equal";
import {
  CreateValuation,
  CreateValuationVariables,
  GetEquitySettings,
  UpsertCompStructure,
  UpsertCompStructureVariables,
} from "../../__generated__/graphql";
import { useCurrencies } from "../../components/CurrenciesContext";
import {
  EquityDetailsData,
  EquityDetailsForm,
} from "../../components/Settings/Equity/EquityDetailsForm";
import Loading from "../../components/Settings/Loading";
import { CREATE_VALUATION, UPSERT_COMP_STRUCTURE } from "../../mutations";
import { GET_EQUITY_SETTINGS } from "../../queries";
import { PageContainer } from "./PageContainer";

const EquityDetails = (): JSX.Element | null => {
  const { defaultCurrencyCode } = useCurrencies();
  const { data: equitySettingsData, loading: equitySettingsDataLoading } =
    useQuery<GetEquitySettings>(GET_EQUITY_SETTINGS);

  const [createValuation, { loading: valUpdating }] = useMutation<
    CreateValuation,
    CreateValuationVariables
  >(CREATE_VALUATION, {
    refetchQueries: [{ query: GET_EQUITY_SETTINGS }],
  });

  const [updateCompStructure, { loading: compUpdating }] = useMutation<
    UpsertCompStructure,
    UpsertCompStructureVariables
  >(UPSERT_COMP_STRUCTURE, {
    refetchQueries: [{ query: GET_EQUITY_SETTINGS }],
  });

  const isValuationDataDirty = (
    initialFormData: EquityDetailsData,
    newFormData: EquityDetailsData
  ) => {
    return !fastDeepEqual(
      getValuationInputVariables(defaultCurrencyCode, initialFormData),
      getValuationInputVariables(defaultCurrencyCode, newFormData)
    );
  };

  const saveFormData = async (
    initialFormData: EquityDetailsData,
    newFormData: EquityDetailsData
  ) => {
    const compStructureMutation = updateCompStructure({
      variables: getCompStructureInputVariables(newFormData),
    });
    const valuationMutation = isValuationDataDirty(initialFormData, newFormData)
      ? createValuation({
          variables: getValuationInputVariables(
            defaultCurrencyCode,
            newFormData
          ),
        })
      : null;

    return Promise.all([
      compStructureMutation,
      valuationMutation,
      defaultCurrencyCode,
    ]);
  };

  if (equitySettingsDataLoading) return <Loading />;
  if (!equitySettingsData) return null;

  const { compStructure, valuation, organization } = equitySettingsData;

  const isSaving = compUpdating || valUpdating;

  return (
    <PageContainer
      header={`How is ${organization.name}'s equity handled?`}
      description="This info is used to power the equity components of your compensation bands as well as equity values in Offers."
      centerContent
    >
      <EquityDetailsForm
        compStructure={compStructure}
        valuation={valuation}
        onSave={saveFormData}
        isSaving={isSaving}
        defaultCurrency={organization.defaultCurrency}
      />
    </PageContainer>
  );
};

function getCompStructureInputVariables(
  data: EquityDetailsData
): UpsertCompStructureVariables {
  return {
    data: {
      equityGrantTypes: data.equityGrantTypes,
      vestingCliff: data.vestingCliff ?? 0,
      vestingMonths: data.vestingMonths ?? 0,
      equityGrantMethod: data.equityGrantMethod,
      refreshGrantValue: data.refreshGrantValue,
    },
  };
}

function getValuationInputVariables(
  defaultCurrencyCode: CurrencyCode,
  data: EquityDetailsData
): CreateValuationVariables {
  return {
    data: {
      estimatedDilution: data.estimatedDilution ?? 0,
      fdso: data.fdso ?? 0,
      financingStage: data.financingStage ?? "",
      strikePrice: data.strikePrice ?? zero(defaultCurrencyCode),
      valuation: data.valuation ?? zero(defaultCurrencyCode),
    },
  };
}

export default EquityDetails;
