import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const CircleCheckIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Circle Check"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className="stroke" cx="8" cy="8" r="7.25" strokeWidth="1.5" />
      <path
        className="stroke"
        d="M10.875 6L6.95833 10.2498L5 8.12498"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
