import { makeStyles } from "@material-ui/core";
import { AssembleTag } from "src/components/AssembleTags/AssembleTag";
import { BLUE_2, WHITE } from "../../../theme";

const useStyles = makeStyles(() => ({
  tag: {
    background: BLUE_2,
    border: `1px solid ${BLUE_2}`,
    color: WHITE,
  },
}));

export function NewTag(): JSX.Element {
  const classes = useStyles();
  return <AssembleTag label="New" tagClassName={classes.tag} />;
}
