import { gql, useQuery } from "@apollo/client";
import { contramap } from "@asmbl/shared/sort";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { AssembleLink } from "../../../../components/AssembleLink";
import { SaveButton } from "../../../../components/Form/SaveButton";
import { SelectField } from "../../../../components/Form/SelectField";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { useAssignEmployeeBenefits } from "../../../../mutations/Employee";
import {
  BenefitsDialogQuery,
  BenefitsDialog_benefitsPackage as BenefitsPackage,
  BenefitsDialog_employee,
} from "../../../../__generated__/graphql";

type Props = {
  employees: BenefitsDialog_employee[];
  onClose: () => unknown;
};
export function BenefitsDialog({ employees, onClose }: Props): JSX.Element {
  const isSingle = employees.length === 1;
  const [isSaving, setIsSaving] = useState(false);

  const [selectedPackage, setPackage] = useState<BenefitsPackage | "" | "none">(
    isSingle ? employees[0].benefitsPackage ?? "" : ""
  );
  const { data, loading } = useQuery<BenefitsDialogQuery>(BenefitsDialog.query);
  const assignEmployeeBenefits = useAssignEmployeeBenefits();

  if (!data) {
    if (loading) {
      return (
        <Dialog open onClose={onClose}>
          <DialogContent>
            <LoadingSpinner />
          </DialogContent>
        </Dialog>
      );
    }
    return (
      <Dialog open onClose={onClose}>
        <DialogTitle>Error Loading Benefits Packages</DialogTitle>
        <DialogContent>Please try again later.</DialogContent>
      </Dialog>
    );
  }

  const benefitsPackages = data.benefitsPackages;
  const handleAssign = () => {
    setIsSaving(true);
    const benefitsPackageId =
      typeof selectedPackage === "string" ? null : selectedPackage.id;

    return assignEmployeeBenefits(
      employees.map((e) => ({
        employeeId: e.id,
        benefitsPackageId,
      }))
    )
      .then(
        () => true,
        () => false
      )
      .finally(() => setIsSaving(false));
  };

  const handleChange = (selected: string | number) => {
    if (selected === "none" || selected === "") {
      return setPackage(selected);
    }
    setPackage(
      benefitsPackages.find((p) => p.id.toString() === selected.toString()) ??
        ""
    );
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        Assign a New Benefits Package to{" "}
        {isSingle ? employees[0].displayName : `${employees.length} Employees`}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          You're about to update the benefits package for{" "}
          {isSingle ? employees[0].displayName : "the selected employees"}.
        </Typography>
        {benefitsPackages.length > 0 ? (
          <SelectField
            id="benefits-select"
            options={[
              { label: "Please select…", value: "", disabled: true },
              { label: <em>Don't show benefits</em>, value: "none" },
              ...benefitsPackages
                .map((benefitsPackage) => ({
                  label: benefitsPackage.name,
                  value: benefitsPackage.id.toString(),
                }))
                .sort(contramap((p) => p.label)),
            ]}
            value={
              typeof selectedPackage === "string"
                ? selectedPackage
                : selectedPackage.id.toString()
            }
            label="Benefits Package"
            onChange={handleChange}
          />
        ) : (
          <Typography>
            Add a{" "}
            <AssembleLink to="/settings/benefits">
              Benefits Package
            </AssembleLink>{" "}
            if you'd like to include it in the Employee Portal.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={onClose}
          disabled={isSaving}
        >
          Cancel
        </Button>
        <SaveButton
          disabled={selectedPackage === ""}
          cooldown={1000}
          onSave={handleAssign}
          onAfterSave={onClose}
          labels={{
            default: "Assign",
            processing: "Assigning",
            success: "Assigned",
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

const benefitsPackage = gql`
  fragment BenefitsDialog_benefitsPackage on BenefitsPackage {
    id
    name
  }
`;
BenefitsDialog.fragments = {
  employee: gql`
    ${benefitsPackage}
    fragment BenefitsDialog_employee on Employee {
      id
      displayName
      benefitsPackage {
        id
        ...BenefitsDialog_benefitsPackage
      }
    }
  `,
};

BenefitsDialog.query = gql`
  ${benefitsPackage}
  query BenefitsDialogQuery {
    benefitsPackages {
      id
      ...BenefitsDialog_benefitsPackage
    }
  }
`;
