import { gql } from "@apollo/client";
import { Box, Fade, IconButton, makeStyles } from "@material-ui/core";
import { useContext } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { CircleArrowLeftIcon } from "src/components/AssembleIcons/Brand/CircleArrowLeftIcon";
import { CircleArrowRightIcon } from "src/components/AssembleIcons/Brand/CircleArrowRightIcon";
import { GRAY_4, GRAY_6, GRAY_9, PURPLE_2, WHITE } from "../../../../theme";
import { EmploymentCarousel_employment as Employment } from "../../../../__generated__/graphql";
import { EmploymentCarouselPanel } from "./EmploymentCarouselPanel";
import "./hideScrollbar.css";

const useStyles = makeStyles(() => ({
  buttonContainer: {
    position: "absolute",
    top: 0,
    height: "100%",
    zIndex: 1,
    alignItems: "center",
    justifyContent: "center",
    transition: "opacity 200ms, visibility 200ms",
  },
  buttonLeft: {
    left: 0,
    background: `linear-gradient(90deg, ${GRAY_9} 20.34%, rgba(249, 251, 255, 0) 100%)`,
  },
  buttonRight: {
    right: 0,
    background: `linear-gradient(270deg, ${GRAY_9} 20.34%, rgba(249, 251, 255, 0) 100%)`,
  },
  carouselButton: {
    backgroundColor: WHITE,
    border: `1px solid ${GRAY_6}`,
    boxSizing: "border-box",
    boxShadow: `0px 4px 12px rgba(10, 36, 64, 0.12)`,
    "&:hover, &:focus": {
      backgroundColor: `${WHITE} !important`,
    },
    height: "50px",
    width: "50px",
  },
  carouselContainer: {
    width: "100%",
    minHeight: "6.2rem",
    alignItems: "center",
    position: "relative",
  },
}));

interface Props {
  employments: Employment[];
  onSelect: (id: number) => unknown;
  selected: number | undefined;
}

export const EmploymentCarousel = ({
  employments,
  onSelect,
  selected,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <ScrollMenu
      LeftArrow={CarouselLeftArrow}
      RightArrow={CarouselRightArrow}
      wrapperClassName={classes.carouselContainer}
    >
      {employments.map((employment: Employment) => {
        return (
          <EmploymentCarouselPanel
            selected={selected}
            onSelect={onSelect}
            itemId={employment.id.toString()}
            key={employment.id}
            employment={employment}
          />
        );
      })}
    </ScrollMenu>
  );
};

const CarouselLeftArrow = (): JSX.Element => {
  const classes = useStyles();
  const { initComplete, isFirstItemVisible, scrollPrev } =
    useContext(VisibilityContext);

  return (
    <Fade in={initComplete && !isFirstItemVisible} unmountOnExit>
      <Box
        display="flex"
        className={`${classes.buttonContainer} ${classes.buttonLeft}`}
      >
        <IconButton
          className={classes.carouselButton}
          onClick={() => scrollPrev()}
        >
          <CircleArrowLeftIcon
            inline
            width="24px"
            height="24px"
            color={GRAY_4}
            hoverColor={PURPLE_2}
          />
        </IconButton>
      </Box>
    </Fade>
  );
};

const CarouselRightArrow = (): JSX.Element => {
  const classes = useStyles();
  const { initComplete, isLastItemVisible, scrollNext } =
    useContext(VisibilityContext);

  return (
    <Fade in={initComplete && !isLastItemVisible} unmountOnExit>
      <Box
        display="flex"
        className={`${classes.buttonContainer} ${classes.buttonRight}`}
      >
        <IconButton
          className={classes.carouselButton}
          onClick={() => scrollNext()}
        >
          <CircleArrowRightIcon
            inline
            width="24px"
            height="24px"
            color={GRAY_4}
            hoverColor={PURPLE_2}
          />
        </IconButton>
      </Box>
    </Fade>
  );
};

EmploymentCarousel.fragments = {
  employment: gql`
    ${EmploymentCarouselPanel.fragments.employment}
    fragment EmploymentCarousel_employment on Employment {
      ...EmploymentCarouselPanel_employment
    }
  `,
};
