import { gql, useLazyQuery } from "@apollo/client";
import { useState } from "react";
import {
  CorrectionCostToMoveAnalysisQueryVariables,
  CorrectionCostToMoveExportQuery,
} from "src/__generated__/graphql";
import { useTrack } from "src/analytics";
import { CsvDownloader } from "src/components/CsvExport/CsvDownloader";
import { CsvExport } from "src/components/CsvExport/CsvExport";

type Props = {
  filter: { [key: string]: number[] | null | undefined };
  targetBandPoint: string;
};

export function CorrectionCostToMoveExport({
  filter,
  targetBandPoint,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const [loading, setLoading] = useState<boolean>(false);
  const [exportCSV] = useLazyQuery<
    CorrectionCostToMoveExportQuery,
    CorrectionCostToMoveAnalysisQueryVariables
  >(CorrectionCostToMoveExport.query, {
    variables: { filter, targetBandPoint },
  });

  const fetchCSV = async () => {
    setLoading(true);
    const response = await exportCSV();
    const result = response.data?.totalCostToMoveAnalysis.employeeExport;
    setLoading(false);
    trackEvent({
      object: "Band Placement Correction Report",
      action: "Exported",
    });

    if (result == null) {
      throw new Error();
    }

    return result;
  };

  return (
    <CsvDownloader fetch={fetchCSV} filename="TotalCostToMoveAnalysis">
      {(onDownload) => (
        <CsvExport variant="icon" onClick={onDownload} loading={loading} />
      )}
    </CsvDownloader>
  );
}

CorrectionCostToMoveExport.query = gql`
  query CorrectionCostToMoveExportQuery(
    $targetBandPoint: String!
    $filter: GetTotalEmployeesInput
  ) {
    totalCostToMoveAnalysis(target: $targetBandPoint, filter: $filter) {
      employeeExport
    }
  }
`;
