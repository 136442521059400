import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { useState } from "react";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { TrashIcon } from "src/components/AssembleIcons/Small/TrashIcon";
import { RED } from "src/theme";

type Props = {
  handleChange: () => void;
};

export const DeletePhaseModalButton = ({
  handleChange,
}: Props): JSX.Element => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleDeletePhase = () => {
    handleChange();
    setModalOpen(false);
  };

  return (
    <>
      <Dialog open={isModalOpen} maxWidth="xs">
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You'll have to reassign anything that has already been assigned to
            this phase.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <AssembleButton
            size="medium"
            variant="text"
            label="Go Back"
            onClick={() => setModalOpen(false)}
          />
          <AssembleButton
            size="medium"
            variant="danger"
            label="Delete Phase"
            onClick={handleDeletePhase}
          />
        </DialogActions>
      </Dialog>
      <IconButton onClick={() => setModalOpen(true)}>
        <TrashIcon width="24px" height="24px" color={RED} />
      </IconButton>
    </>
  );
};
