import { makeStyles, TableRow } from "@material-ui/core";
import { PayPeriodType } from "src/__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../components/AssembleTruncatedTypography";
import { ExplanatoryTooltip } from "../../../components/ExplanatoryTooltip";
import { TotalCashCell } from "../../../components/Table/TotalCashCell";
import { WireTableCell } from "../../../components/Table/WireTable/WireTableCell";
import { UserAvatar } from "../../../components/UserAvatar";
import { formatTenure } from "../../../models/Employment";
import { BLUE_2, GRAY_6, RED } from "../../../theme";
import { EmployeeRow } from "./LevelingErrorsTable";
import { NewTag } from "./NewTag";
import { OldTag } from "./OldTag";

type Props = {
  employee: EmployeeRow;
};

const useStyles = makeStyles((theme) => ({
  connectingLine: {
    display: "flex",
    justifySelf: "center",
    height: 44,
    width: "100%",
    background: `linear-gradient(180deg, ${BLUE_2}, #DCEEFE) no-repeat center/2px 100%`,
  },
  newOldCell: {
    background: `linear-gradient(to bottom, ${GRAY_6}, ${GRAY_6}) no-repeat center/1px 100%`,
    backgroundSize: "100% 1px",
    height: "100%",
    marginTop: 2,
    paddingTop: 2,
  },
  newOldCellContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  nameCell: {
    padding: 0,
    paddingLeft: theme.spacing(1),
  },
  totalCashCell: {
    borderRight: `1px solid ${GRAY_6}`,
  },
  row: {
    height: "4rem",
  },
  separatorLine: {
    padding: 0,
    borderTop: `3px solid ${GRAY_6}`,
  },
  redText: {
    color: "red",
  },
  centerCell: {
    display: "flex",
    justifyContent: "center",
  },
}));

export function LevelingIssueRow({ employee: row }: Props): JSX.Element {
  const classes = useStyles();
  const hasPreviousLevel = row.previousPosition !== undefined;

  return (
    <>
      {/* The new employment row */}
      <TableRow className={classes.row}>
        <NewOldCell hasPreviousLevel={hasPreviousLevel} />
        <WireTableCell className={classes.nameCell}>
          <div>
            <AssembleTruncatedTypography variant="productExtraSmallSemiBold">
              {row.name}
            </AssembleTruncatedTypography>
            <AssembleTruncatedTypography textColor={RED}>
              <Tooltip>Unleveled</Tooltip>
            </AssembleTruncatedTypography>
          </div>
        </WireTableCell>
        <WireTableCell>
          <AssembleTruncatedTypography>
            {row.previousJobTitle ?? "-"}
          </AssembleTruncatedTypography>
        </WireTableCell>
        <WireTableCell className={classes.redText}>
          <b>-</b> {/* Bold this dash to make it a little more evident */}
        </WireTableCell>
        <WireTableCell>
          {row.tenure === undefined ? "-" : formatTenure(row.tenure)}
        </WireTableCell>
        <WireTableCell>{row.locationName ?? ""}</WireTableCell>
        <WireTableCell align="center">
          {row.manager == null ? (
            "-"
          ) : (
            <div className={classes.centerCell}>
              <UserAvatar
                photoURL={row.manager.user?.photoURL}
                displayName={row.manager.displayName}
                showTooltip
              />
            </div>
          )}
        </WireTableCell>
        <TotalCashCell
          className={classes.totalCashCell}
          align="center"
          cash={row.totalCash}
          payTypeSelection={PayPeriodType.ANNUAL}
          isHourly={
            row.employee.activeEmployment?.payPeriod === PayPeriodType.HOURLY
          }
        />
        <WireTableCell>
          <AssembleTruncatedTypography textColor={RED}>
            <Tooltip>
              {row.levelingCode === undefined ? "-" : row.levelingCode}
            </Tooltip>
          </AssembleTruncatedTypography>
        </WireTableCell>
      </TableRow>
      {/* The previous employment row */}
      {hasPreviousLevel && (
        <TableRow className={classes.row}>
          <WireTableCell className={classes.nameCell}>
            <div>
              <AssembleTruncatedTypography variant="productExtraSmallSemiBold">
                {row.name}
              </AssembleTruncatedTypography>
              <AssembleTruncatedTypography>
                {row.previousPosition?.name ?? "Unknown"}
              </AssembleTruncatedTypography>
            </div>
          </WireTableCell>
          <WireTableCell>
            <AssembleTruncatedTypography>
              {row.jobTitle ?? "-"}
            </AssembleTruncatedTypography>
          </WireTableCell>
          <WireTableCell>{row.previousPosition?.level ?? "-"}</WireTableCell>
          <WireTableCell>
            {row.tenure === undefined ? "-" : formatTenure(row.tenure)}
          </WireTableCell>
          <WireTableCell>{row.locationName ?? ""}</WireTableCell>

          <WireTableCell align="center">
            {row.manager == null ? (
              "-"
            ) : (
              <UserAvatar
                photoURL={row.manager.user?.photoURL}
                displayName={row.manager.displayName}
              />
            )}
          </WireTableCell>
          {/* We are unable to show historical pay, so let's show nothing */}
          <WireTableCell />
          <WireTableCell>
            <AssembleTruncatedTypography>
              {row.previousLevelingCode}
            </AssembleTruncatedTypography>
          </WireTableCell>
        </TableRow>
      )}
      {/* Separator line */}
      <tr>
        <td colSpan={10} className={classes.separatorLine} />
      </tr>
    </>
  );
}

/**
 * To get the gradient line that "overlaps" two cells, I cheat a bit:
 * I use a single cell with a `rowSpan` of 2 when there are two rows, and a
 * `rowSpan` of 1 when there's only one row.
 *
 * In the first scenario, I use a linear-gradient background that is 1px tall
 * to create a fake dividing line in the middle of the cell
 */
function NewOldCell({
  hasPreviousLevel = true,
}: {
  hasPreviousLevel: boolean;
}): JSX.Element {
  const classes = useStyles();

  return hasPreviousLevel ? (
    <WireTableCell rowSpan={2} className={classes.newOldCell}>
      <div className={classes.newOldCellContainer}>
        <NewTag />
        <span className={classes.connectingLine} />
        <OldTag />
      </div>
    </WireTableCell>
  ) : (
    <WireTableCell rowSpan={1}>
      <div className={classes.newOldCellContainer}>
        <NewTag />
      </div>
    </WireTableCell>
  );
}

function Tooltip({ children }: { children: string }): JSX.Element {
  return (
    <ExplanatoryTooltip
      placement="top"
      body="We can't find that leveling code in Assemble. Please visit your HRIS to reconcile the data, or add the code to your existing leveling codes."
    >
      <span>{children}</span>
    </ExplanatoryTooltip>
  );
}
