import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const FoundationsIcon = ({
  height = "24px",
  width = "24px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Foundations"
      viewBox="0 0 32 32"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M16.1327 16L16 28.8512"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M27.5 21.9165V10.0835C27.5 9.90638 27.453 9.73247 27.3637 9.57952C27.2745 9.42656 27.1462 9.30005 26.9921 9.21291L16.4921 3.27813C16.3419 3.19328 16.1724 3.14869 16 3.14869C15.8276 3.14869 15.6581 3.19328 15.5079 3.27813L5.00794 9.21291C4.85378 9.30005 4.72551 9.42656 4.63627 9.57952C4.54703 9.73247 4.5 9.90638 4.5 10.0835V21.9165C4.5 22.0936 4.54703 22.2675 4.63627 22.4205C4.72551 22.5735 4.85378 22.7 5.00794 22.7871L15.5079 28.7219C15.6581 28.8067 15.8276 28.8513 16 28.8513C16.1724 28.8513 16.3419 28.8067 16.4921 28.7219L26.9921 22.7871C27.1462 22.7 27.2745 22.5735 27.3637 22.4205C27.453 22.2675 27.5 22.0936 27.5 21.9165V21.9165Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M27.363 9.57837L16.1328 16L4.63788 9.577"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
