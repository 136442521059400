import { makeStyles } from "@material-ui/core";
import { enqueueSnackbar } from "notistack";
import { Guidance_compCycle as CompCycle } from "src/__generated__/graphql";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { AssembleLink } from "src/components/AssembleLink";
import { AssembleTypography } from "src/components/AssembleTypography";
import Section from "src/components/Workflow/Section";
import { CompCycleTabs } from "src/pages/CompCycle/Navigation/NavigationTabBar";
import { GRAY_1 } from "src/theme";
import { MeritAndBudgetMatrices } from "./MeritAndBudgetMatrices";

const useStyles = makeStyles((theme) => ({
  content: {
    left: "50%",
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(2),
    position: "absolute",
    transform: "translate(-50%)",
  },
  h5: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
    color: GRAY_1,
  },
  saveContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    paddingTop: theme.spacing(3),
  },
}));

type Props = {
  compCycle: CompCycle;
  refreshMetrics: () => void;
  applyGuidanceToAll: () => Promise<unknown>;
};

export function MeritGuidance({
  compCycle,
  refreshMetrics,
  applyGuidanceToAll,
}: Props) {
  const classes = useStyles();

  const onApplyGuidanceClick = async () => {
    const userInput = window.confirm(
      "This will overwrite existing compensation requests and prefill merit requests with a request equal to the guidance provided in the table for employees with performance ratings."
    );
    if (userInput) {
      const success = await applyGuidanceToAll();
      if (success) {
        enqueueSnackbar({
          variant: "success",
          message: "Guidance successfully applied!",
        });
      }
    }
  };

  return (
    <div className={classes.content}>
      <Section
        active
        header="Merit Guidance & Budget"
        description="Provide merit adjustment guidance and budgets in percentages based on an employee's performance rating and compa-ratio."
      >
        <MeritAndBudgetMatrices
          compCycle={compCycle}
          refreshMetrics={refreshMetrics}
          shouldApplyGuidance={compCycle.recommendationsPreFill}
        />
        <AssembleTypography variant="h5" className={classes.h5}>
          Apply Guidance to Comp Cycle Budget
        </AssembleTypography>
        <AssembleTypography variant="productSmall">
          To dynamically allocate budgets for eligible employees based on the
          budget above,{" "}
          <AssembleLink
            to={`/comp-cycles/${compCycle.id}/${CompCycleTabs.AllocateBudgets}`}
            variant="productSmall"
          >
            visit the Allocate Budgets page
          </AssembleLink>{" "}
          and look for the Generate Salary Adjustments Budget button.
        </AssembleTypography>
        <div className={classes.saveContainer}>
          <AssembleButton
            onClick={onApplyGuidanceClick}
            label="Save"
            size="medium"
            variant="contained"
            disabled={!compCycle.recommendationsPreFill}
          />
        </div>
      </Section>
    </div>
  );
}
