import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const PeopleIcon = ({
  height = "24px",
  width = "24px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="People"
      viewBox="0 0 32 32"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M11 20C14.5899 20 17.5 17.0899 17.5 13.5C17.5 9.91015 14.5899 7 11 7C7.41015 7 4.5 9.91015 4.5 13.5C4.5 17.0899 7.41015 20 11 20Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M19.4266 7.24213C20.3206 6.99023 21.2582 6.93285 22.1763 7.07384C23.0944 7.21483 23.9715 7.55092 24.7488 8.05948C25.526 8.56803 26.1853 9.23724 26.6821 10.022C27.1789 10.8068 27.5018 11.689 27.629 12.609C27.7562 13.5291 27.6848 14.4658 27.4195 15.3559C27.1542 16.2461 26.7012 17.069 26.0911 17.7694C25.481 18.4697 24.7279 19.0312 23.8825 19.416C23.0371 19.8007 22.1191 19.9999 21.1903 20C22.9556 19.9987 24.6952 20.4228 26.2618 21.2364C27.8284 22.05 29.176 23.2291 30.1903 24.6739M2 24.6746C3.01511 23.2306 4.36275 22.052 5.92915 21.2384C7.49556 20.4248 9.23473 20.0001 10.9998 20C12.7649 19.9999 14.5041 20.4246 16.0706 21.238C17.637 22.0515 18.9848 23.23 20 24.6739"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
