import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import {
  CARTA_THEME,
  CULPEPPER_THEME,
  ERI_THEME,
  MERCER_THEME,
  PAVE_THEME,
  RADFORD_THEME,
  UNSPECIFIED_THEME,
  WHITE,
  WTW_THEME,
} from "src/theme";

const useStyles = makeStyles({
  targetPointOutline: {
    width: 9,
    height: 9,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 7,
    border: `solid 1px ${UNSPECIFIED_THEME}`,
    backgroundColor: WHITE,
  },
  targetPoint: {
    width: 4,
    height: 4,
    borderRadius: 4,
    backgroundColor: UNSPECIFIED_THEME,
  },
  radfordBackground: {
    backgroundColor: RADFORD_THEME,
  },
  radfordBorder: {
    borderColor: RADFORD_THEME,
  },
  paveBackground: {
    backgroundColor: PAVE_THEME,
  },
  paveBorder: {
    borderColor: PAVE_THEME,
  },
  cartaBackground: {
    backgroundColor: CARTA_THEME,
  },
  cartaBorder: {
    borderColor: CARTA_THEME,
  },
  eriBackground: {
    backgroundColor: ERI_THEME,
  },
  eriBorder: {
    borderColor: ERI_THEME,
  },
  mercerBackground: {
    backgroundColor: MERCER_THEME,
  },
  mercerBorder: {
    borderColor: MERCER_THEME,
  },
  culpepperBackground: {
    backgroundColor: CULPEPPER_THEME,
  },
  culpepperBorder: {
    borderColor: CULPEPPER_THEME,
  },
  wtwBackground: {
    backgroundColor: WTW_THEME,
  },
  wtwBorder: {
    borderColor: WTW_THEME,
  },
});

type Props = {
  provider: string;
};

export function ThemedTargetPoint({ provider }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.targetPointOutline, {
        [classes.radfordBorder]: provider === "Radford",
        [classes.paveBorder]: provider === "OptionImpact/Pave",
        [classes.cartaBorder]: provider === "Carta",
        [classes.eriBorder]: provider === "Economic Research Institute",
        [classes.mercerBorder]: provider === "Mercer",
        [classes.culpepperBorder]: provider === "Culpepper",
        [classes.wtwBorder]: provider === "Willis Towers Watson",
      })}
    >
      <div
        className={clsx(classes.targetPoint, {
          [classes.radfordBackground]: provider === "Radford",
          [classes.paveBackground]: provider === "OptionImpact/Pave",
          [classes.cartaBackground]: provider === "Carta",
          [classes.eriBackground]: provider === "Economic Research Institute",
          [classes.mercerBackground]: provider === "Mercer",
          [classes.culpepperBackground]: provider === "Culpepper",
          [classes.wtwBackground]: provider === "Willis Towers Watson",
        })}
      />
    </div>
  );
}
