import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { LabelValueTuple } from "src/__generated__/graphql";
import { APP_FONTS } from "../../theme";
import { createChartColors, sortCategories } from "./utils";

type Item = LabelValueTuple;

type Props = {
  items: Item[];
  height?: string;
  showPercentages?: boolean;
};

export function PieChart({
  items,
  height,
  showPercentages = false,
}: Props): JSX.Element {
  const containsOmittedCategory =
    items.findIndex((item) => item.label === "Omitted") !== -1;
  const sortedItems = sortCategories(items);
  const labels = sortedItems.map((i) => i.label);
  const values = sortedItems.map((i) => i.value);
  const colors = createChartColors(containsOmittedCategory, values.length);

  function chartFormatter(seriesName: string, opts: { seriesIndex: number }) {
    const absoluteValue = values[opts.seriesIndex];
    const seriesTotal = values.reduce((acc, cur) => acc + cur, 0);
    const percentValue = absoluteValue / seriesTotal;
    const formattedPercent = percentValue.toLocaleString("en-US", {
      style: "percent",
      maximumFractionDigits: 1,
    });
    return showPercentages
      ? `${seriesName}: ${formattedPercent} (${absoluteValue})`
      : `${seriesName}: ${absoluteValue} (${formattedPercent})`;
  }

  const options: ApexOptions = {
    labels,
    colors,
    series: values,
    dataLabels: { enabled: false },
    chart: {
      fontFamily: APP_FONTS,
      animations: {
        dynamicAnimation: {
          enabled: false,
        },
      },
    },
    legend: {
      itemMargin: { vertical: 6 },
      position: "right",
      markers: {
        radius: 3,
        height: 10,
        width: 10,
      },
      fontSize: "14px",
      // hack to get legend formatter to refresh when user toggles
      fontWeight: showPercentages ? 400 : "400",
      formatter: chartFormatter,
    },
    states: {
      hover: {
        filter: { type: "none" },
      },
      active: {
        filter: { type: "none" },
      },
    },
  };

  return (
    <ReactApexChart
      height={height}
      options={options}
      series={options.series}
      type="donut"
    />
  );
}
