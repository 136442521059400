import { CurrencyCode } from "@asmbl/shared/constants";
import { formatCurrency } from "@asmbl/shared/money";
import { IconButton, makeStyles, Slider } from "@material-ui/core";
import clsx from "clsx";
import React, { Ref } from "react";
import { BookmarkIcon } from "src/components/AssembleIcons/Brand/BookmarkIcon";
import { FlagFilledIcon } from "src/components/AssembleIcons/Brand/FlagFilledIcon";
import { FlameIcon } from "src/components/AssembleIcons/Brand/FlameIcon";
import { BLUE_2, GRAY_1, GRAY_6, WHITE } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  sliderTrack: { height: "0.5rem", color: BLUE_2 },
  sliderRail: { height: "0.5rem", borderRadius: "4px" },
  active: { color: BLUE_2, backgroundColor: BLUE_2, opacity: 1 },
  inActive: { color: GRAY_6 },
  sliderThumb: {
    width: "0.75rem",
    height: "0.75rem",
    marginLeft: "-0.5rem",
    marginTop: "-0.125rem",
    borderRadius: "10px",
  },
  sliderRoot: { color: GRAY_6, width: "100%" },
  thumbDefault: {
    width: "0.75rem",
    height: "0.75rem",
    borderRadius: "10px",
    backgroundColor: WHITE,
    border: `2px solid ${BLUE_2}`,
    boxShadow: "0px 1px 2px rgba(10, 36, 64, 0.2)",
  },
  thumbBorder: {
    height: "1.5rem",
    width: "1.5rem",
    border: `1px solid ${BLUE_2}`,
    backgroundColor: BLUE_2,
  },
  sliderLabelLeft: {
    paddingBottom: theme.spacing(2),
    width: "75%",
    textAlign: "right",
  },
  sliderLabelRight: {
    paddingBottom: theme.spacing(2),
    width: "75%",
    textAlign: "left",
  },
  valueLabel: {
    top: theme.spacing(-3.2),
    transition: "none",
    "& *": {
      background: "transparent",
      color: GRAY_1,
    },
  },
}));

type Props = {
  defaultValue: number;
  value: number;
  values: { label: string; value: number }[];
  handleChange: (_: unknown, newValue: number | number[]) => void;
  valuationCurrency: CurrencyCode;
};

export function TotalEquitySliderV2({
  defaultValue,
  value,
  values,
  handleChange,
  valuationCurrency,
}: Props): JSX.Element {
  const classes = useStyles();

  const marks = values.map(({ value }) => ({
    value,
    label: formatCurrency(
      {
        value,
        currency: valuationCurrency,
      },
      { maximumFractionDigits: 2 }
    ),
  }));

  const minValue = Math.min(...values.map(({ value }) => value));
  const maxValue = Math.max(...values.map(({ value }) => value));
  const range = maxValue - minValue;

  const getValueLabel = (value: number) =>
    valueLabelFormatter(value, valuationCurrency);

  /**
   * Snap to specific marking points if you're close enough (1%).
   * Otherwise, behave normally.
   */
  const handleChangeWithSnap = (_: unknown, newValue: number | number[]) => {
    const snapToMark = values.find(
      (point) => Math.abs(point.value - Number(newValue)) / range < 0.01
    );

    return snapToMark
      ? handleChange(_, snapToMark.value)
      : handleChange(_, newValue);
  };

  return (
    <>
      <AssembleTypography
        className={classes.sliderLabelLeft}
        variant="productHeader"
      >
        Illustrative Equity Unit Price:
      </AssembleTypography>
      <Slider
        classes={{
          root: classes.sliderRoot,
          track: classes.sliderTrack,
          rail: classes.sliderRail,
          thumb: clsx(classes.sliderThumb, classes.active),
          mark: clsx(classes.sliderThumb, classes.inActive),
          markActive: clsx(classes.sliderThumb, classes.active),
          valueLabel: classes.valueLabel,
        }}
        value={value}
        valueLabelDisplay="auto"
        valueLabelFormat={getValueLabel}
        onChange={handleChangeWithSnap}
        ThumbComponent={(props) => (
          <ThumbComponent
            htmlProps={props}
            defaultValue={defaultValue}
            values={values}
            value={value}
          />
        )}
        min={minValue}
        max={maxValue}
        marks={marks}
        step={null} // so it snaps to marks
      />
      <AssembleTypography
        className={classes.sliderLabelRight}
        variant="productHeader"
      >
        {values.find((point) => point.value === value)?.label ?? ""}
      </AssembleTypography>
    </>
  );
}

const ThumbComponent = React.forwardRef(function ThumbComponent(
  {
    htmlProps,
    defaultValue,
    values,
    value,
  }: {
    htmlProps: React.HTMLAttributes<unknown>;
    defaultValue: number;
    values: { label: string; value: number }[];
    value: number;
  },
  ref: Ref<HTMLDivElement>
): JSX.Element {
  const classes = useStyles();
  const { children, ...other } = htmlProps;
  const maxValue = Math.max(...values.map((v) => v.value));

  let icon: JSX.Element | null = null;

  if (defaultValue === value) {
    icon = <FlagFilledIcon color={WHITE} />;
  } else if (value === maxValue) {
    icon = <FlameIcon color={WHITE} />;
  } else if (values.find((point) => point.value === value)) {
    icon = <BookmarkIcon color={WHITE} width={"8px"} height={"12px"} />;
  }

  return (
    <div ref={ref} {...other} data-cy="total-equity-slider-thumb">
      {children}
      {icon === null ? (
        <div className={classes.thumbDefault}></div>
      ) : (
        <IconButton className={classes.thumbBorder} size="small">
          {icon}
        </IconButton>
      )}
    </div>
  );
});

function valueLabelFormatter(value: number, currency: CurrencyCode): string {
  return formatCurrency({
    value,
    currency: currency,
  });
}
