import { Radio, RadioGroup } from "@material-ui/core";
import { EquityGrantTypes } from "../../../__generated__/graphql";
import FormBox from "../../FormBox";

const EQUITY_GRANT_TYPES = [
  {
    display: "Stock Options",
    value: EquityGrantTypes.STOCK_OPTION,
  },
  {
    display: "Restricted Stock Units (RSU)",
    value: EquityGrantTypes.RSU,
  },
  {
    display: "Restricted Stock Award (RSA)",
    value: EquityGrantTypes.RSA,
  },
  {
    display: "Capital Interest",
    value: EquityGrantTypes.CAPITAL_INTEREST,
  },
  {
    display: "Shared Profit Interest",
    value: EquityGrantTypes.SHARED_PROFIT_INTEREST,
  },
] as const;

type StructureFormProps = {
  handleChange: (value: EquityGrantTypes) => unknown;
  value: EquityGrantTypes | null;
};

export function EquityGrantForm({
  handleChange,
  value: value,
}: StructureFormProps): JSX.Element {
  return (
    <RadioGroup
      onChange={(e) => handleChange(e.target.value as EquityGrantTypes)}
      value={value}
    >
      {EQUITY_GRANT_TYPES.map((equityType) => (
        <FormBox
          key={equityType.value}
          control={<Radio />}
          label={equityType.display}
          value={equityType.value}
        />
      ))}
    </RadioGroup>
  );
}
