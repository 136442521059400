import { gql } from "@apollo/client";
import { CashBandName, CurrencyCode } from "@asmbl/shared/constants";
import { moneyComparator, zero } from "@asmbl/shared/money";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  makeStyles,
} from "@material-ui/core";
import {
  CashCompType,
  CompBandTable_employee,
} from "../../../__generated__/graphql";
import { useTrack } from "../../../analytics";
import { ADJUSTED_CASH_BAND_POINT_FIELDS } from "../../../fragments";
import {
  totalCompBand,
  totalCompMax,
  totalCompMin,
} from "../../../models/Band";
import { annualizedCashCompToBandPoint } from "../../../models/BandPoint";
import { getTotalCash } from "../../../models/CashCompensation";
import { useExcludedBandNames } from "../../../pages/LadderDetail/ExcludedBandNameContext";
import { Range, bandComparator, getTotalCompRange } from "../../../utils";
import { CompBandRow } from "./CompBandRow";
import { CompBandTableHeader } from "./CompBandTableHeader";

const useStyles = makeStyles(() => ({
  tableContainer: {
    overflowY: "auto",
    maxHeight: "calc(100vh - 260px)",
  },
}));

type Props = {
  employee: CompBandTable_employee;
  selectedCurrencyCode: CurrencyCode;
};

const salaryBands = [
  CashCompType.SALARY,
  CashCompType.COMMISSION,
  CashCompType.RECURRING_BONUS,
];

export function CompBandTable({
  employee,
  selectedCurrencyCode,
}: Props): JSX.Element {
  const classes = useStyles();
  const { trackEvent } = useTrack();
  const { excludedBandNames, toggleBandExclusion } = useExcludedBandNames();

  const toggleBand = (bandName: string) => {
    toggleBandExclusion(bandName);
    trackEvent({
      object: "Compensation Band Table Comp Component",
      area: "Employee Portal",
      subArea: "Portal Home",
      action: "Toggled",
      compensationComponent: bandName,
    });
  };

  const salaryBandSet = new Set(salaryBands);

  // makes sure row comp sliders only show salary comp bands
  const displayedBands = mapDisplayedCashCompTypes(
    salaryBands,
    excludedBandNames
  );

  const empTotalCash = getTotalCash(
    employee.activeCashCompensation,
    displayedBands
  );

  // employee's subcomponents, already filtered on excluded band names
  const empSubComponents: Set<CashCompType> = new Set(
    empTotalCash?.subcomponents.map((comp) => comp.type)
  );

  // we filter the adjusted cash bands based on the employee's cash comp types
  // and excluded bands, since adjusted bands may still be populated with values
  const empBands = filterAdjustedBands(
    employee.adjustedCashBands,
    excludedBandNames,
    empSubComponents
  );

  const totalCompRange = assessCashBandRange(
    empBands,
    employee.nextCashBands,
    excludedBandNames,
    empSubComponents,
    selectedCurrencyCode
  );

  const headerBandNames =
    employee.activeCashCompensation
      ?.filter((cashComp) => salaryBandSet.has(cashComp.type))
      .map(annualizedCashCompToBandPoint)
      .sort(bandComparator) ?? [];

  const headerRange: Range = {
    min: Math.min(totalCompRange.min, empTotalCash?.annualTotal.value ?? 0),
    max: Math.max(totalCompRange.max, empTotalCash?.annualTotal.value ?? 0),
  };

  return (
    <TableContainer
      className={classes.tableContainer}
      component={Paper}
      elevation={0}
    >
      <Table>
        <CompBandTableHeader
          bandNames={headerBandNames}
          selectedCurrency={{
            code: selectedCurrencyCode,
            exchangeRate: 1, // header exchanges, but comps are same currency
          }}
          totalCompRange={headerRange}
          toggleBandExclusion={toggleBand}
        />
        <TableBody>
          {employee.activeEmployment?.position && (
            <CompBandRow
              adjustedCashBands={empBands}
              empTotalCashComp={empTotalCash}
              currency={selectedCurrencyCode}
              employee={employee}
              position={{
                name: employee.activeEmployment.position.name,
                description: "Your Current Position",
                level: employee.activeEmployment.position.level,
              }}
              adjustedCompRange={headerRange}
            />
          )}
          {employee.nextCashBands !== null && // if null, emp not permitted to see next band
            (employee.nextCashBands.length > 0 ? ( // if 0, no positions above current
              employee.nextCashBands
                .slice()
                .reverse()
                .map((info) => (
                  <CompBandRow
                    key={info.id}
                    adjustedCashBands={filterAdjustedBands(
                      info.cashBands,
                      excludedBandNames,
                      empSubComponents
                    )}
                    currency={selectedCurrencyCode}
                    employee={employee}
                    position={{
                      name: info.position?.name,
                      level: info.position?.level,
                      description: "Next Level",
                    }}
                    adjustedCompRange={headerRange}
                  />
                ))
            ) : (
              <CompBandRow
                adjustedCashBands={null}
                currency={selectedCurrencyCode}
                employee={employee}
                adjustedCompRange={headerRange}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CompBandTable.fragments = {
  employee: gql`
    ${CompBandRow.fragments.employee}
    ${ADJUSTED_CASH_BAND_POINT_FIELDS}
    fragment CompBandTable_employee on Employee {
      id
      adjustedCashBands {
        id
        name
        bandPoints {
          id
          ...AdjustedCashBandPointFields
        }
      }
      nextCashBands {
        id
        position {
          id
          name
          level
        }
        cashBands {
          id
          name
          bandPoints {
            id
            ...AdjustedCashBandPointFields
          }
        }
      }
      activeEmployment {
        id
        salary
        position {
          id
          name
          level
        }
      }
      ...CompBandRow_employee
    }
  `,
};

const assessCashBandRange = (
  employeeBands: CompBandTable_employee["adjustedCashBands"],
  nextCashBands: CompBandTable_employee["nextCashBands"] | null,
  excludedBandNames: Set<string>,
  empSubComponents: Set<CashCompType>,
  selectedCurrency: CurrencyCode
): Range => {
  const nextCompBands = nextCashBands?.flatMap((info) => info.cashBands) ?? [];
  const empComp = totalCompBand(
    {
      adjustedCashBands: employeeBands,
      adjustedEquityBands: null,
    },
    excludedBandNames
  );

  if (nextCompBands.length > 0) {
    const filteredNextBands = filterAdjustedBands(
      nextCompBands,
      excludedBandNames,
      empSubComponents
    );

    const totalComp = totalCompBand(
      {
        adjustedCashBands: filteredNextBands,
        adjustedEquityBands: null,
      },
      excludedBandNames
    );

    const allCompBands = [...empComp.bandPoints, ...totalComp.bandPoints].sort(
      (a, b) => moneyComparator(a.annualCashEquivalent, b.annualCashEquivalent)
    );

    return getTotalCompRange([
      {
        totalCompMin:
          totalCompMin({ bandPoints: allCompBands }) ?? zero(selectedCurrency),
        totalCompMax:
          totalCompMax({ bandPoints: allCompBands }) ?? zero(selectedCurrency),
      },
    ]);
  }

  return getTotalCompRange([
    {
      totalCompMin: totalCompMin(empComp) ?? zero(selectedCurrency),
      totalCompMax: totalCompMax(empComp) ?? zero(selectedCurrency),
    },
  ]);
};

function mapDisplayedCashCompTypes(
  salaryBands: CashCompType[],
  excludedBandNames: Set<string>
): Set<CashCompType> {
  return new Set(
    salaryBands.filter((type) => {
      if (type === CashCompType.SALARY) {
        return !excludedBandNames.has(CashBandName.SALARY);
      }
      if (type === CashCompType.COMMISSION) {
        return !excludedBandNames.has(CashBandName.COMMISSION);
      }
      if (type === CashCompType.RECURRING_BONUS) {
        return !excludedBandNames.has(CashBandName.RECURRING_BONUS);
      }
    })
  );
}

const filterAdjustedBands = (
  adjustedCashBands: CompBandTable_employee["adjustedCashBands"] | null,
  excludedBandNames: Set<string>,
  empSubComponents: Set<CashCompType>
): CompBandTable_employee["adjustedCashBands"] =>
  adjustedCashBands?.filter((band) => {
    if (band.name === CashBandName.SALARY) {
      return (
        !excludedBandNames.has(band.name) &&
        empSubComponents.has(CashCompType.SALARY)
      );
    }
    if (band.name === CashBandName.COMMISSION) {
      return (
        !excludedBandNames.has(band.name) &&
        empSubComponents.has(CashCompType.COMMISSION)
      );
    }
    if (band.name === CashBandName.RECURRING_BONUS) {
      return (
        !excludedBandNames.has(band.name) &&
        empSubComponents.has(CashCompType.RECURRING_BONUS)
      );
    }
    return !excludedBandNames.has(band.name);
  }) ?? [];
