import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const SaveIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Save"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M20.25 8.56066V19.5C20.25 19.6989 20.171 19.8897 20.0303 20.0303C19.8897 20.171 19.6989 20.25 19.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H15.4393C15.5378 3.75 15.6354 3.7694 15.7264 3.80709C15.8173 3.84478 15.9 3.90003 15.9697 3.96967L20.0303 8.03033C20.1 8.09997 20.1552 8.18265 20.1929 8.27365C20.2306 8.36464 20.25 8.46217 20.25 8.56066Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M12 16.875C13.2426 16.875 14.25 15.8676 14.25 14.625C14.25 13.3824 13.2426 12.375 12 12.375C10.7574 12.375 9.75 13.3824 9.75 14.625C9.75 15.8676 10.7574 16.875 12 16.875Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M14.625 8.62586H9.375V3.75085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
