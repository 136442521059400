import { gql } from "@apollo/client";
import { Tooltip } from "@material-ui/core";
import { useRevokeUserInvites } from "../../../../../mutations/User";
import { TableAccessButton_employee } from "../../../../../__generated__/graphql";
import { PortalDialog } from "../../PortalAccess/PortalDialog";
import { BaseButton } from "./BaseButton";

type Props = {
  employee: TableAccessButton_employee;
};

export const TableAccessButton = ({ employee }: Props) => {
  const revokeUserInvites = useRevokeUserInvites();

  const handleRevoke = async () => {
    await revokeUserInvites([employee.email]);
  };

  return (
    <PortalDialog
      onDialogClick={handleRevoke}
      actionButtonTitle="Cancel Invite"
      title={`Cancel ${employee.displayName}'s invite to Assemble`}
      body={`${employee.displayName} will no longer be able to use the invitation link to register with the Employee Portal.`}
    >
      {(onClick) => (
        <Tooltip
          title="This employee hasn't created an account yet. Click the button to revoke your invite."
          placement="top"
        >
          <span>
            <BaseButton
              props={{ variant: "outlined" }}
              label="Pending..."
              onClick={onClick}
            />
          </span>
        </Tooltip>
      )}
    </PortalDialog>
  );
};

TableAccessButton.fragments = {
  employee: gql`
    fragment TableAccessButton_employee on Employee {
      id
      displayName
      email
    }
  `,
};
