import { gql } from "@apollo/client";
import { Currency } from "@asmbl/shared/currency";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { useState } from "react";
import { useCurrencies } from "../../../components/CurrenciesContext";
import { OrgCurrencyField } from "../../../components/Currency/OrgCurrencyField";
import { SaveButton } from "../../../components/Form/SaveButton";
import { useCreateMarket, useUpdateMarket } from "../../../mutations/Market";
import { MarketEditor_market } from "../../../__generated__/graphql";

type Props = {
  onClose: () => unknown;
  existingMarket?: MarketEditor_market;
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    marginTop: theme.spacing(1),
    paddingBlock: theme.spacing(3),
    maxHeight: "calc(100vh - 240px)",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
}));

export function MarketEditor({ onClose, existingMarket }: Props): JSX.Element {
  const classes = useStyles();
  const { defaultCurrency } = useCurrencies();
  const [name, setName] = useState(existingMarket ? existingMarket.name : "");
  const [currency, setCurrency] = useState<Currency>(
    existingMarket
      ? { code: existingMarket.currencyCode, exchangeRate: 1 }
      : defaultCurrency
  );

  const createMarket = useCreateMarket();
  const updateMarket = useUpdateMarket(existingMarket?.id ?? -1);
  const upsertMarket = existingMarket ? updateMarket : createMarket;

  const handleSubmit = () =>
    upsertMarket({ name, currencyCode: currency.code });

  const handleCancel = () => onClose();

  const alreadyEndsWithMarket =
    existingMarket?.name.toLowerCase().endsWith("market") ?? false;

  return (
    <Dialog open onClose={onClose} fullWidth>
      <DialogTitle>
        {existingMarket ? (
          <>
            Edit {existingMarket.name}
            {!alreadyEndsWithMarket && " Market"}
          </>
        ) : (
          <>Create a New Market</>
        )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          name="name"
          variant="outlined"
          fullWidth
          label="Market Name"
          placeholder="United States"
          value={name}
          onChange={(e) => setName(e.target.value)}
          InputLabelProps={{ shrink: true }}
          inputProps={{ required: true }}
        />
        <OrgCurrencyField
          label="Local Currency"
          value={currency}
          onChange={setCurrency}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCancel}>
          Cancel
        </Button>
        <SaveButton
          hideEndIcon
          disabled={name === ""}
          cooldown={1000}
          onSave={handleSubmit}
          onAfterSave={onClose}
          labels={
            existingMarket
              ? {
                  default: <>Save&nbsp;Market</>,
                  processing: <>Save&nbsp;Market</>,
                  success: <>Market&nbsp;Saved</>,
                }
              : {
                  default: <>Create&nbsp;Market</>,
                  processing: <>Create&nbsp;Market</>,
                  success: <>Market&nbsp;Created</>,
                }
          }
        />
      </DialogActions>
    </Dialog>
  );
}

MarketEditor.fragments = {
  market: gql`
    fragment MarketEditor_market on Market {
      id
      name
      currencyCode
    }
  `,
};
