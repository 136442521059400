import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Divider,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useIntercom } from "react-use-intercom";
import { useTrack } from "../../../analytics";
import { useUpdateOrganizationPermissionSettings } from "../../../mutations/PermissionSettings";
import { PageContainer } from "../../../pages/Settings/PageContainer";
import { GET_ORG_PERMISSION_SETTINGS } from "../../../queries";
import { GRAY_1, GRAY_2, GRAY_3, theme } from "../../../theme";
import { GetPermissionSettings } from "../../../__generated__/graphql";
import { ErrorView } from "../../ErrorView";
import { AnonymizedDataSectionLoadingBoundary } from "../AnonymizedData/AnonymizedDataSectionLoadingBoundary";
import FormHeader from "../FormHeader";
import Loading from "../Loading";
import { PolicyChoices } from "./PolicyChoices/PolicyChoices";
import { StandardPoliciesTable } from "./StandardPoliciesTable";

const useStyles = makeStyles(() => ({
  contactUs: {
    alignSelf: "center",
    color: GRAY_3,
    fontSize: "14px",
  },
  header: {
    color: GRAY_1,
    fontWeight: 700,
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  button: {
    fontWeight: 500,
  },
  sectionSubtitle: {
    color: GRAY_2,
    fontSize: "16px",
  },
}));

export function AccessPolicies(): JSX.Element | null {
  const classes = useStyles();
  const intercom = useIntercom();
  const { Track } = useTrack({ subArea: "Access Policies" });

  const { data, loading } = useQuery<GetPermissionSettings>(
    GET_ORG_PERMISSION_SETTINGS
  );

  const handleAccessChange = useUpdateOrganizationPermissionSettings();

  if (!data) {
    return loading ? <Loading /> : <ErrorView />;
  }

  const compensationAccessType =
    data.organization.permissionSettings.teamCompensationAccess;

  const anonymizedCompensationAccessType =
    data.organization.permissionSettings.anonymizedCompensationStatisticsAccess;

  return (
    <Track>
      <PageContainer>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <FormHeader
              description={
                <Typography className={classes.sectionSubtitle}>
                  Choose policies and assign individual user permissions to
                  control what people can see and do in Assemble.
                </Typography>
              }
              header={
                <Typography variant="h3" className={classes.header}>
                  Access Policies
                </Typography>
              }
            />
            <Button
              variant="contained"
              color="secondary"
              href="/settings/permissions"
            >
              <Typography className={classes.button}>
                Configure User Permissions
              </Typography>
            </Button>
          </Box>

          <PolicyChoices
            current={data.organization.permissionSettings}
            compensationAccessType={compensationAccessType}
            anonymizedCompensationAccessType={anonymizedCompensationAccessType}
            handleAccessChange={handleAccessChange}
          />

          <AnonymizedDataSectionLoadingBoundary />

          <Divider className={classes.divider} />
          <StandardPoliciesTable />
          <Box className={classes.contactUs}>
            <Typography>
              Were you expecting to see another policy?{" "}
              <Link href="#" onClick={intercom.show}>
                Let us know
              </Link>
              .
            </Typography>
          </Box>
        </Box>
      </PageContainer>
    </Track>
  );
}
