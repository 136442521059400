import { gql, useQuery } from "@apollo/client";
import Loading from "../../../components/Settings/Loading";
import {
  LocationsQuery,
  LocationsWithMarketIdQuery,
} from "../../../__generated__/graphql";
import { Locations } from "../Locations";

export function LocationsLoadingBoundary(): JSX.Element | null {
  const { data, loading } = useQuery<LocationsQuery>(
    LocationsLoadingBoundary.query
  );

  if (loading) return <Loading />;
  if (!data) return null;

  const { locations, compStructure, markets, departments } = data;
  const [market] = markets;

  return (
    <Locations
      markets={markets}
      market={market}
      locations={locations}
      locationGroups={market.locationGroups}
      departments={departments}
      compStructure={compStructure}
    />
  );
}

LocationsLoadingBoundary.query = gql`
  ${Locations.fragments.locations}
  ${Locations.fragments.locationGroups}
  ${Locations.fragments.compStructure}
  ${Locations.fragments.departments}
  ${Locations.fragments.markets}
  query LocationsQuery {
    locations {
      ...Locations_locations
      id
    }
    markets {
      ...Locations_markets
      locationGroups {
        ...Locations_locationGroups
        id
      }
    }
    compStructure {
      ...Locations_compStructure
      id
    }
    departments {
      ...Locations_departments
      id
    }
  }
`;

type Props = { marketId: number };

export function LocationsLoadingBoundaryWithMarketId({
  marketId,
}: Props): JSX.Element | null {
  const { data, loading } = useQuery<LocationsWithMarketIdQuery>(
    LocationsLoadingBoundaryWithMarketId.query,
    { variables: { marketId } }
  );

  if (loading) return <Loading />;
  if (data == null) return null;

  const { locations, compStructure, market, departments, markets } = data;

  return (
    <Locations
      markets={markets}
      market={market}
      locations={locations}
      locationGroups={market.locationGroups}
      departments={departments}
      compStructure={compStructure}
    />
  );
}

LocationsLoadingBoundaryWithMarketId.query = gql`
  ${Locations.fragments.locations}
  ${Locations.fragments.locationGroups}
  ${Locations.fragments.compStructure}
  ${Locations.fragments.departments}
  ${Locations.fragments.markets}
  query LocationsWithMarketIdQuery($marketId: Int!) {
    locations {
      ...Locations_locations
      id
    }
    market(id: $marketId) {
      ...Locations_markets
      locationGroups {
        ...Locations_locationGroups
        id
      }
    }
    markets {
      ...Locations_markets
      locationGroups {
        ...Locations_locationGroups
        id
      }
    }
    compStructure {
      ...Locations_compStructure
      id
    }
    departments {
      ...Locations_departments
      id
    }
  }
`;
