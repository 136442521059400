import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const ClockIcon = ({
  height = "12px",
  width = "12px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Clock"
      viewBox="0 0 12 12"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6155_4345)">
        <path
          className="stroke"
          d="M11.25 6C11.25 8.8995 8.8995 11.25 6 11.25C3.1005 11.25 0.75 8.8995 0.75 6C0.75 3.10051 3.10051 0.75 6 0.75C8.8995 0.75 11.25 3.1005 11.25 6Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="stroke"
          d="M6 6L7.5 7.5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="stroke"
          d="M6 6V3.5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6155_4345">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </ColorableSVG>
);
