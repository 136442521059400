import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import {
  DV_GREEN,
  GRAY_1,
  GRAY_2,
  GRAY_6,
  GREEN_2,
  RED,
  WHITE,
} from "src/theme";
import { AssembleTypography } from "../AssembleTypography";
import { getMarketDataProviderLogo } from "src/pages/Settings/MarketData/util";
import { ThemedTargetPoint } from "./ThemedTargetPoint";
import { formatCurrency } from "@asmbl/shared/money";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Range } from "src/utils";
import { formatPercent } from "../Visualization/utils";
import { useCurrencies } from "../CurrenciesContext";
import { GetLadders_marketDataSet } from "src/pages/Compare/Bands/SelectedLaddersLoader";
import { gql } from "@apollo/client";
import { MarketDataComparisonTooltip_position } from "src/__generated__/graphql";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
  },
  container: {
    backgroundColor: WHITE,
    color: GRAY_1,
    padding: theme.spacing(2),
    minWidth: 275,
  },
  subheader: {
    color: GRAY_2,
    paddingBottom: theme.spacing(1),
    fontWeight: 400,
  },
  header: {
    fontWeight: 500,
    fontSize: "1rem",
  },
  marketDataSection: {
    borderTop: `solid 1px ${GRAY_6}`,
    padding: `${theme.spacing(2)}px 0`,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  row: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  dataRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  light: {
    color: GRAY_2,
  },
  red: {
    color: RED,
  },
  green: {
    color: GREEN_2,
  },
  visualization: {
    backgroundColor: DV_GREEN,
    width: "100%",
    height: 24,
    borderRadius: 4,
    position: "relative",
  },
  valuesContainer: {
    padding: `${theme.spacing(0.5)}px 0 ${theme.spacing(1)}px 0`,
    display: "flex",
    justifyContent: "space-between",
  },
  midpoint: {
    width: 7,
    height: 7,
    position: "absolute",
    backgroundColor: WHITE,
    borderRadius: 7,
    top: "calc(50% - 3px)",
  },
  dataPoint: {
    position: "absolute",
  },
}));

type Props = {
  position: MarketDataComparisonTooltip_position;
  marketDataSets: GetLadders_marketDataSet[];
  percentile: number;
  compType: string;
  totalCompRange: Range;
  color: string;
};

const calculatePlacement = (point: number, totalCompRange: Range) =>
  ((point - totalCompRange.min) / (totalCompRange.max - totalCompRange.min)) *
  100;

export function MarketDataComparisonTooltip({
  position,
  marketDataSets,
  percentile,
  compType,
  totalCompRange,
  color,
}: Props): JSX.Element {
  const classes = useStyles();
  const { selectedCurrency } = useCurrencies();
  const relevantMarketDataSets = marketDataSets.map((marketDataSet) => ({
    ...marketDataSet,
    marketDataSample: marketDataSet.marketDataSamples.find(
      (sample) =>
        sample.compType === compType &&
        position.jobCodes.includes(sample.jobCode)
    ),
  }));

  const midpoint = Math.floor((totalCompRange.min + totalCompRange.max) / 2);

  return (
    <div className={classes.container}>
      <AssembleTypography className={classes.header}>
        {position.name}
      </AssembleTypography>
      <AssembleTypography className={classes.subheader}>
        {position.jobCodes}
      </AssembleTypography>
      <div>
        <div
          className={classes.visualization}
          style={{ backgroundColor: color }}
        >
          <div
            className={classes.midpoint}
            style={{
              left: `calc(${calculatePlacement(
                midpoint,
                totalCompRange
              )}% - 3px)`,
            }}
          />
          {relevantMarketDataSets.map((marketDataSet, index) => {
            const marketDataPoint =
              marketDataSet.marketDataSample?.marketDataPoints.find(
                (point) => point.percentile === percentile
              );
            const left =
              marketDataPoint?.value != null
                ? `clamp(-10px, ${calculatePlacement(
                    marketDataPoint.value,
                    totalCompRange
                  )}%, 100%)`
                : "-10px";
            return (
              <span
                className={classes.dataPoint}
                key={`market-data-viz-${marketDataSet.id}`}
                style={{
                  top:
                    marketDataSets.length === 1
                      ? "calc(50% - 3px)"
                      : `${(100 / marketDataSets.length) * index}%`,
                  left,
                }}
              >
                <ThemedTargetPoint provider={marketDataSet.provider} />
              </span>
            );
          })}
        </div>
        <div className={classes.valuesContainer}>
          <AssembleTypography variant="productExtraSmall">
            {formatCurrency(
              {
                value: totalCompRange.min,
                currency: selectedCurrency.code,
              },
              { maximumFractionDigits: 0 }
            )}
          </AssembleTypography>
          <AssembleTypography variant="productExtraSmall">
            {formatCurrency(
              {
                value: midpoint,
                currency: selectedCurrency.code,
              },
              { maximumFractionDigits: 0 }
            )}
          </AssembleTypography>
          <AssembleTypography variant="productExtraSmall">
            {formatCurrency(
              {
                value: totalCompRange.max,
                currency: selectedCurrency.code,
              },
              { maximumFractionDigits: 0 }
            )}
          </AssembleTypography>
        </div>
      </div>
      {relevantMarketDataSets.map((marketDataSet) => {
        const marketDataPoint =
          marketDataSet.marketDataSample?.marketDataPoints.find(
            (point) => point.percentile === percentile
          );
        const isMidpointAboveMarket =
          marketDataPoint?.value == null
            ? false
            : midpoint > marketDataPoint.value;

        const diffFromMidpoint =
          marketDataPoint?.value == null
            ? null
            : (Math.max(marketDataPoint.value, midpoint) -
                Math.min(marketDataPoint.value, midpoint)) /
              midpoint;

        return (
          <div
            className={classes.marketDataSection}
            key={`market-data-${marketDataSet.id}`}
          >
            <div className={classes.dataRow}>
              <AssembleTypography>{marketDataSet.label}</AssembleTypography>
              {getMarketDataProviderLogo(marketDataSet.provider)}
            </div>
            <div className={classes.dataRow}>
              <AssembleTypography
                variant="productExtraSmall"
                className={classes.row}
              >
                <ThemedTargetPoint provider={marketDataSet.provider} />
                {`${percentile}th Percentile`}{" "}
                {diffFromMidpoint == null ? (
                  ""
                ) : (
                  <span>
                    (
                    <span
                      className={clsx({
                        [classes.red]: isMidpointAboveMarket,
                        [classes.green]: !isMidpointAboveMarket,
                      })}
                    >
                      {isMidpointAboveMarket ? "-" : "+"}
                      {formatPercent(diffFromMidpoint)}
                    </span>{" "}
                    vs mid)
                  </span>
                )}
              </AssembleTypography>
              <AssembleTypography variant="productExtraSmall">
                {marketDataPoint?.value == null ||
                marketDataSet.marketDataSample?.currencyCode == null
                  ? "N/A"
                  : formatCurrency({
                      value: marketDataPoint.value,
                      currency: marketDataSet.marketDataSample
                        .currencyCode as CurrencyCode,
                    })}
              </AssembleTypography>
            </div>
            <div className={clsx(classes.dataRow, classes.light)}>
              <AssembleTypography variant="productExtraSmall">
                Sample Size
              </AssembleTypography>
              <AssembleTypography variant="productExtraSmall">
                {marketDataSet.marketDataSample?.sampleSize ?? "N/A"}
              </AssembleTypography>
            </div>
          </div>
        );
      })}
    </div>
  );
}

MarketDataComparisonTooltip.fragments = {
  position: gql`
    fragment MarketDataComparisonTooltip_position on Position {
      name
      jobCodes
    }
  `,
};
