import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { TableCell } from "@material-ui/core";
import { CompCycleSingleRec_perfRatingOption } from "src/__generated__/graphql";
import { PreviewFeature } from "../FeatureContext";
import { SelectField } from "../Form/SelectField";

type Props = {
  perfRatingOptions: CompCycleSingleRec_perfRatingOption[];
  perfRating?: string;
  setRevisedPerfRating: (perfRating: string) => unknown;
};

export function PerfRatingCell({
  perfRatingOptions = [],
  perfRating,
  setRevisedPerfRating,
}: Props): JSX.Element {
  return (
    <PreviewFeature
      flag={FeatureFlag.PerformanceRatingDropdown}
      fallback={
        <TableCell role="gridcell" align="center">
          {perfRating ?? "-"}
        </TableCell>
      }
    >
      <TableCell role="gridcell" align="center">
        {perfRatingOptions.length === 0 ? (
          perfRating ?? ""
        ) : (
          <SelectField
            id="perf-rating-cell-select"
            defaultValue={perfRating}
            onChange={(perfRatingOption) =>
              setRevisedPerfRating(perfRatingOption as string)
            }
            options={perfRatingOptions
              .slice(0)
              .sort((a, b) => (a.rank < b.rank ? -1 : 1))
              .map((option) => ({
                label: option.name,
                value: option.name,
              }))}
            size="small"
          />
        )}
      </TableCell>
    </PreviewFeature>
  );
}
