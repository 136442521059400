import { makeStyles } from "@material-ui/core";
import { AccessBoundary } from "../../../components/AccessBoundary";
import { AssembleLink } from "../../../components/AssembleLink";
import { AssembleTypography } from "../../../components/AssembleTypography";
import { GRAY_1, GRAY_4 } from "../../../theme";
import { Noun } from "../../../__generated__/graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-end",
  },
  item: { paddingBottom: theme.spacing(1.5) },
  text: {
    color: GRAY_4,
    "&:hover": {
      color: GRAY_1,
    },
  },
}));

export function CompCycleSettingsButton({
  compCycleId,
}: {
  compCycleId: number;
}): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.item}>
        <AccessBoundary scope="global" verb="edit" noun={Noun.CompCycle}>
          <AssembleLink
            to={`/comp-cycles/${compCycleId}/settings`}
            underline="none"
          >
            <AssembleTypography
              variant="productSmallSemiBold"
              className={classes.text}
              data-intercom-target={"nav-comp-settings"}
            >
              Cycle Settings
            </AssembleTypography>
          </AssembleLink>
        </AccessBoundary>
      </div>
    </div>
  );
}
