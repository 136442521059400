import { gql } from "@apollo/client";
import { Money, moneyComparator } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { makeStyles, TableBody, TableRow } from "@material-ui/core";
import { CorrectionCostToMoveDepartmentsTable_departmentCostToMove as DepartmentCostToMove } from "src/__generated__/graphql";
import { AssembleLink } from "src/components/AssembleLink";
import { useSort } from "src/components/SortableTable";
import {
  WireTable,
  WireTableCell,
  WireTableHead,
  WireTableHeaderCell,
} from "src/components/Table/WireTable";
import { getSimpleCashLabel } from "src/models/Currency";
import { GRAY_1, PURPLE_1 } from "src/theme";

type Props = {
  byDepartment: DepartmentCostToMove[];
};

type Row = {
  id: number;
  name: string;
  totalSpend: Money;
  costToMove: Money;
  affectedEmployeeCount: number;
  totalEmployeeCount: number;
  affectedLadders: number[];
};

const useStyles = makeStyles(() => ({
  link: {
    color: GRAY_1,
    "&:hover": {
      color: PURPLE_1,
    },
  },
}));

export function CorrectionCostToMoveDepartmentsTable({
  byDepartment,
}: Props): JSX.Element | null {
  const classes = useStyles();
  const data: Row[] = byDepartment
    .map((d) => ({
      id: d.department.id,
      name: d.department.name,
      totalSpend: d.totalCostToMoveAnalysis.totalSpend,
      costToMove: d.totalCostToMoveAnalysis.totalCostToMove,
      affectedEmployeeCount:
        d.totalCostToMoveAnalysis.totalAffectedEmployeeCount,
      totalEmployeeCount: d.totalCostToMoveAnalysis.totalEmployeeCount,
      affectedLadders: d.affectedLadders.map((l) => l.id),
    }))
    .filter((d) => d.affectedEmployeeCount > 0);

  const {
    sortedArray: sortedDepartments,
    order,
    orderBy,
    handleRequestSort,
  } = useSort<Row>(data, "costToMove", "desc", {
    totalSpend: contramap((d: Row) => d.totalSpend, moneyComparator),
    costToMove: contramap((d: Row) => d.costToMove, moneyComparator),
  });

  if (data.length === 0) return null;

  return (
    <div>
      <WireTable>
        <WireTableHead>
          <WireTableHeaderCell
            handleRequestSort={handleRequestSort}
            cellTitle="Department"
            orderByField="name"
            order={order}
            isSelected={orderBy === "name"}
          />
          <WireTableHeaderCell
            handleRequestSort={handleRequestSort}
            cellTitle="Total Spend"
            orderByField="totalSpend"
            order={order}
            isSelected={orderBy === "totalSpend"}
          />
          <WireTableHeaderCell
            handleRequestSort={handleRequestSort}
            cellTitle="Cost to Bandpoint"
            orderByField="costToMove"
            order={order}
            isSelected={orderBy === "costToMove"}
          />
          <WireTableHeaderCell
            handleRequestSort={handleRequestSort}
            cellTitle="Impacted Employees"
            orderByField="affectedEmployeeCount"
            order={order}
            isSelected={orderBy === "affectedEmployeeCount"}
          />
          <WireTableHeaderCell
            handleRequestSort={handleRequestSort}
            cellTitle="Total Employees"
            orderByField="totalEmployeeCount"
            order={order}
            isSelected={orderBy === "totalEmployeeCount"}
          />
        </WireTableHead>
        <TableBody>
          {sortedDepartments.map((d) => (
            <TableRow key={d.id}>
              <WireTableCell>
                <AssembleLink
                  to={`/people/organization?department=${d.id}&ladder=${d.affectedLadders.join("%2C")}`}
                  display="inline"
                  underline="none"
                  newTab
                  className={classes.link}
                  variant="productSmall"
                >
                  {d.name}
                </AssembleLink>
              </WireTableCell>
              <WireTableCell align="right">
                {getSimpleCashLabel(d.totalSpend)}
              </WireTableCell>
              <WireTableCell align="right">
                {getSimpleCashLabel(d.costToMove)}
              </WireTableCell>
              <WireTableCell align="right">
                {d.affectedEmployeeCount}
              </WireTableCell>
              <WireTableCell align="right">
                {d.totalEmployeeCount}
              </WireTableCell>
            </TableRow>
          ))}
        </TableBody>
      </WireTable>
    </div>
  );
}

CorrectionCostToMoveDepartmentsTable.fragments = {
  departmentCostToMove: gql`
    fragment CorrectionCostToMoveDepartmentsTable_departmentCostToMove on TotalDepartmentCostToMove {
      department {
        id
        name
      }
      totalCostToMoveAnalysis {
        totalSpend
        totalCostToMove
        totalAffectedEmployeeCount
        totalEmployeeCount
      }
      affectedLadders {
        id
      }
    }
  `,
};
