import { Button, makeStyles } from "@material-ui/core";
import { HierarchyNode } from "d3";
import { State } from "d3-org-chart";
import { GRAY_2, GRAY_5, PRIMARY_BOX_SHADOW, PURPLE_1, WHITE } from "src/theme";
import { OrganizationHierarchy_employee as Employee } from "src/__generated__/graphql";
import { CollapseWindowIcon } from "../../AssembleIcons/Brand/CollapseWindowIcon";
import { ExpandAllIcon } from "../../AssembleIcons/Brand/ExpandAllIcon";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1.5),
  },
  button: {
    minWidth: "max-content",

    height: `${theme.spacing(4)}px !important`,
    width: `${theme.spacing(4)}px !important`,

    color: GRAY_2,
    backgroundColor: WHITE,
    border: `1px solid ${GRAY_5}`,

    "&:hover": {
      color: PURPLE_1,
      border: `1px solid ${PURPLE_1}`,
    },

    "&:active": {
      color: PURPLE_1,
      border: `1px solid ${PURPLE_1}`,
      boxShadow: PRIMARY_BOX_SHADOW,
    },

    "&:focus": {
      color: PURPLE_1,
      border: `1px solid ${PURPLE_1}`,
      boxShadow: PRIMARY_BOX_SHADOW,
    },

    "& .MuiButton-startIcon": {
      marginRight: "0px",
      marginLeft: "0px",

      "& > span > img": {
        width: "18px",
        height: "18px",
      },
    },
  },
}));

type Props = {
  node: HierarchyNode<Employee>;
  state: State<Employee>;
};

export function EmployeeCardButton({ node }: Props): JSX.Element {
  const classes = useStyles();
  const isExpanded = node.children != null;
  const startIcon = isExpanded ? (
    <CollapseWindowIcon color={PURPLE_1} />
  ) : (
    <ExpandAllIcon color={PURPLE_1} />
  );

  return (
    <div className={classes.container}>
      <Button className={classes.button} startIcon={startIcon} />
    </div>
  );
}
