import { FlatfileResults } from "@flatfile/react";
import {
  Button,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { enqueueSnackbar } from "notistack";
import { ImportMarketDataRow } from "src/__generated__/graphql";
import { useTrack } from "src/analytics";
import { AssembleFlatfileButton } from "src/components/AssembleFlatfileButton";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { useCurrencies } from "src/components/CurrenciesContext";
import { useLocations } from "src/components/LocationsContext";
import { useImportMarketData } from "src/mutations/MarketData";
import { MarketDataFields } from "./MarketDataFields";

type Props = {
  onClose: () => unknown;
  onNext: () => unknown;
  name: string;
  setName: (name: string) => unknown;
  provider: string;
  setProvider: (provider: string) => unknown;
  customProvider: string;
  setCustomProvider: (customProvider: string) => unknown;
  selectedMarket: number | null;
  setSelectedMarket: (value: number) => unknown;
  refreshData: () => void;
};

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  header: {
    fontSize: "1.5rem",
    marginBottom: theme.spacing(1.5),
  },
  input: {
    marginBottom: theme.spacing(3),
  },
  radio: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    gap: theme.spacing(1),
  },
}));

export function UploadModalSecondPage({
  onClose,
  name,
  setName,
  provider,
  setProvider,
  customProvider,
  setCustomProvider,
  selectedMarket,
  setSelectedMarket,
  refreshData,
}: Props) {
  const classes = useStyles();
  const { markets } = useLocations();
  const { currencies } = useCurrencies();
  const importMarketData = useImportMarketData();
  const { trackEvent } = useTrack();

  const handleData = async (results: FlatfileResults) => {
    onClose();
    if (selectedMarket == null) return;
    const success = await importMarketData({
      name,
      provider,
      customProvider,
      marketId: selectedMarket,
      rows: results.allData as ImportMarketDataRow[],
    });

    if (success) {
      refreshData();
      trackEvent({
        object: "Market Data",
        action: "Upload Submitted",
      });
      enqueueSnackbar("Market data successfully imported", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error",
      });
    }
  };
  return (
    <div>
      <AssembleTypography className={classes.header} variant="productHeader">
        Upload a Market Dataset
      </AssembleTypography>
      <TextField
        className={classes.input}
        variant="outlined"
        label="Name"
        fullWidth
        placeholder="2024-radford-Q1.csv"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        className={classes.input}
        variant="outlined"
        select
        fullWidth
        label="Market Data Provider"
        placeholder="Please select..."
        SelectProps={{ IconComponent: ChevronDownIcon }}
        value={provider}
        onChange={(e) => setProvider(e.target.value)}
      >
        <MenuItem value="Radford">Radford</MenuItem>
        <MenuItem value="OptionImpact/Pave">OptionImpact/Pave</MenuItem>
        <MenuItem value="Carta">Carta</MenuItem>
        <MenuItem value="Economic Research Institute">
          Economic Research Institute
        </MenuItem>
        <MenuItem value="Mercer">Mercer</MenuItem>
        <MenuItem value="Culpepper">Culpepper</MenuItem>
        <MenuItem value="Willis Towers Watson">Willis Towers Watson</MenuItem>
        <MenuItem value="Other">Unspecified</MenuItem>
      </TextField>
      {provider === "Other" && (
        <TextField
          className={classes.input}
          variant="outlined"
          label="Provider Name"
          fullWidth
          placeholder="What's the name of this dataset provider?"
          value={customProvider}
          onChange={(e) => setCustomProvider(e.target.value)}
        />
      )}
      <AssembleTypography variant="productHeader">
        Which market does this apply to?
      </AssembleTypography>
      <RadioGroup
        onChange={(e) => setSelectedMarket(parseInt(e.target.value))}
        value={selectedMarket}
      >
        {markets.map((market) => (
          <FormControlLabel
            key={`market-radio-${market.id}`}
            label={market.name}
            value={market.id}
            control={<Radio />}
            className={classes.radio}
          />
        ))}
      </RadioGroup>
      <div className={classes.buttonRow}>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <AssembleFlatfileButton
          settings={{
            title: "Add your dataset",
            type: "market_data",
            fullScreen: true,
            fields: MarketDataFields(currencies),
          }}
          onData={handleData}
          render={(_, launch) => (
            <Button
              variant="contained"
              color="primary"
              disabled={
                !name || !provider || (provider === "Other" && !customProvider)
              }
              onClick={launch}
            >
              Upload Dataset
            </Button>
          )}
        />
      </div>
    </div>
  );
}
