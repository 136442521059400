import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  makeStyles,
  Paper,
  PaperProps,
} from "@material-ui/core";
import { useMemo } from "react";
import { CloseCircleIcon } from "src/components/AssembleIcons/Brand/CloseCircleIcon";
import { GRAY_2, GRAY_5, WHITE } from "../../../theme";
import {
  CurrencyCode,
  TotalEquitySummary_portalConfig as PortalConfig,
} from "../../../__generated__/graphql";
import { AssembleTypography } from "../../AssembleTypography";
import { TotalEquitySummary } from "./TotalEquitySummary";
import { TotalEquitySummarySliderContainer } from "./TotalEquitySummarySliderContainer";

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(4) },
  inner: { padding: theme.spacing(0) },
  header: { paddingBottom: theme.spacing(1) },
  copy: { color: GRAY_2 },
  content: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(3),
  },
  fab: {
    float: "right",
    zIndex: 1,
    right: theme.spacing(6.5),
    top: theme.spacing(2.5),

    backgroundColor: WHITE,
    border: `border: 1px solid ${GRAY_5}`,
    borderRadius: "5px !important",
  },
  fabContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    columnGap: theme.spacing(0.5),
    padding: theme.spacing(1),
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  portalConfig: PortalConfig;
  valuationCurrency: CurrencyCode;
};

export function TotalEquitySummaryModal({
  open,
  onClose,
  portalConfig,
  valuationCurrency,
}: Props): JSX.Element {
  const classes = useStyles();

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") {
      onClose();
    }
  };

  const defaultValue = useMemo(
    () => portalConfig.defaultExitOutcome,
    [portalConfig.defaultExitOutcome]
  );

  const values = useMemo(
    () =>
      portalConfig.exitOutcomes.map((value, index) => ({
        label: portalConfig.xAxisAnnotations[index],
        value,
      })) as {
        label: string;
        value: number;
      }[],
    [portalConfig.exitOutcomes, portalConfig.xAxisAnnotations]
  );

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={open}
      maxWidth="md"
      onKeyDown={onKeyDown}
      PaperComponent={(props) => PaperComponent(props, onClose)}
    >
      <DialogTitle className={classes.header} classes={{ root: classes.inner }}>
        <AssembleTypography variant="portalH35">
          Equity Value Slider
        </AssembleTypography>
      </DialogTitle>
      <DialogContent
        className={classes.content}
        classes={{ root: classes.inner }}
      >
        <AssembleTypography
          className={classes.copy}
          variant="productParagraphLarge"
        >
          Help your employees understand the value of their equity and how it
          might impact their total annualized compensation. Tell the story you
          want to tell by showing the outcomes you want, whether they are
          historical, current, or projected.
        </AssembleTypography>

        <TotalEquitySummarySliderContainer
          defaultValue={defaultValue}
          values={values}
          valuationCurrency={valuationCurrency}
        />

        <TotalEquitySummary
          portalConfig={portalConfig}
          valuationCurrency={valuationCurrency}
        />
      </DialogContent>
    </Dialog>
  );
}

function PaperComponent(props: PaperProps, onClose: () => void): JSX.Element {
  const classes = useStyles();
  const { children, ...other } = props;

  return (
    <div>
      <Fab
        classes={{ root: classes.fab }}
        centerRipple={false}
        size="small"
        variant="extended"
        onClick={onClose}
      >
        <div className={classes.fabContent}>
          <AssembleTypography variant="productExtraSmallSemiBold">
            Close
          </AssembleTypography>
          <CloseCircleIcon color={GRAY_2} height="24px" width="24px" />
        </div>
      </Fab>
      <Paper {...other}>{children}</Paper>
    </div>
  );
}
