import { LinearProgress, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { InfoIcon } from "src/components/AssembleIcons/Small/InfoIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { ExplanatoryTooltip } from "src/components/ExplanatoryTooltip";
import {
  BLUE_3,
  DV_PINK,
  GRAY_3,
  GRAY_4,
  GRAY_6,
  PURPLE_2,
} from "../../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    margin: theme.spacing(0, 2),
    minWidth: "100px",
  },
  labelContainer: {
    display: "flex",
    columnGap: theme.spacing(0.5),
    alignItems: "center",
  },
  labelText: {
    color: GRAY_3,
  },
  labelTooltip: {
    width: "12px",
    height: "12px",
  },
  valueText: {
    marginBottom: theme.spacing(0.5),
  },
  progressContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textAlign: "end",
    pointerEvents: "none",
  },
  linearProgress: {
    height: "4px",
    flexShrink: 0,
    borderRadius: "2px",
    pointerEvents: "none",
    backgroundColor: GRAY_6,
  },
  progressBarPink: {
    background: DV_PINK,
  },
  progressBarBlue: {
    background: BLUE_3,
  },
  hiddenProgress: {
    height: theme.spacing(0.5),
    width: "100%",
    backgroundColor: GRAY_6,
  },
}));

export function ApproverCardDetail({
  label,
  isValueTextColorPrimary,
  tooltipBody,
  recsTotal,
  reportsTotal,
}: {
  label: "Reviewed" | "Requested";
  isValueTextColorPrimary: boolean;
  tooltipBody: string;
  recsTotal: number;
  reportsTotal: number;
}): JSX.Element {
  const classes = useStyles();

  const percentUsed = (recsTotal / reportsTotal) * 100;

  return (
    <div className={classes.root}>
      <div className={classes.labelContainer}>
        <AssembleTypography variant="productEyebrowSmall" textColor={GRAY_3}>
          {label}
        </AssembleTypography>
        <ExplanatoryTooltip
          title={label}
          body={tooltipBody}
          placement="bottom"
          width="25rem"
        >
          <span>
            <InfoIcon
              color={GRAY_4}
              hoverColor={PURPLE_2}
              width="12px"
              height="12px"
            />
          </span>
        </ExplanatoryTooltip>
      </div>
      <div className={classes.progressContainer}>
        <Typography
          className={classes.valueText}
          variant="h6"
          color={isValueTextColorPrimary ? "textPrimary" : "textSecondary"}
        >
          {reportsTotal > 0 ? `${recsTotal} of ${reportsTotal}` : "N/A"}
        </Typography>
        {reportsTotal > 0 ? (
          <LinearProgress
            variant="determinate"
            value={
              // clamp the value to 0-100, so that it doesn't wrap around
              Math.max(0, Math.min(percentUsed, 100))
            }
            classes={{
              root: classes.linearProgress,
              bar: clsx({
                [classes.progressBarPink]: label === "Requested",
                [classes.progressBarBlue]: label === "Reviewed",
              }),
            }}
          />
        ) : (
          <div className={classes.hiddenProgress} />
        )}
      </div>
    </div>
  );
}
