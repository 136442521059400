import { formatCurrency, Money, multiply } from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { AssembleTypography } from "../../../AssembleTypography";

type Props = {
  units: number | null;
  price: Money | undefined;
};

export const EquityVestInfoCell = ({ units, price }: Props) => {
  const formattedPrice =
    units === null || price === undefined
      ? ""
      : ` / ${formatCurrency(multiply(price, units), {
          maximumFractionDigits: 0,
        })}`;
  return (
    <AssembleTypography variant="sidebarTitle">
      {units === null ? "No Data" : formatNumeral(units)}
      {formattedPrice}
    </AssembleTypography>
  );
};
