import { Money, moneyComparator } from "@asmbl/shared/money";
import { Box, Typography } from "@material-ui/core";
import { getSimpleCashLabel } from "../../../models/Currency";
import { GRAY_1 } from "../../../theme";
import { OfferCompSlider } from "../../CompSlider/OfferCompSlider";
import ErrorWrapper from "../../ErrorWrapper";

type Props = {
  offeredComp: Money;
  bandPoints: Money[];
};

export function BreakdownCompSlider({
  offeredComp,
  bandPoints,
}: Props): JSX.Element | null {
  if (bandPoints.length === 0) {
    return null;
  }
  const sortedBandPoints = bandPoints.slice().sort(moneyComparator);
  const min = bandPoints[0];
  const max = bandPoints[bandPoints.length - 1];

  const errorMessage =
    offeredComp.value > max.value
      ? "Value is greater than the band's high-point."
      : offeredComp.value < min.value
        ? "Value is less than the band's low-point."
        : "";

  const outOfRange =
    offeredComp.value > max.value || offeredComp.value < min.value;

  return (
    <>
      <Box>
        <ErrorWrapper light message={errorMessage} visible={outOfRange}>
          <OfferCompSlider data={sortedBandPoints} value={offeredComp} />
        </ErrorWrapper>
        <Box
          mt={1}
          mb={3}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography variant="body2" style={{ color: GRAY_1 }}>
            {getSimpleCashLabel(min)}
          </Typography>
          <Typography variant="body2" style={{ color: GRAY_1 }}>
            {getSimpleCashLabel(max)}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
