import { Box, makeStyles, TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { GRAY_5, GRAY_6 } from "../../../../theme";
import { ConnectorLines, IndentationCells } from "./Connectors";

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(${GRAY_5}, ${GRAY_6})`,
  },
  icon: {
    width: "2.5rem",
    height: "2rem",
  },
  text: {
    width: "100%",
    height: "1rem",
  },
  connectorAndEmployeeContainer: {
    display: "flex",
    flexDirection: "row",
  },
  employeeTextContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  employeeInformationContainer: {
    width: "100%",

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: theme.spacing(2),

    // need to shift over a bit to align with the parent row
    marginLeft: -theme.spacing(0.5),
  },
}));

type Props = {
  subComponents: number;
  indentation: IndentationCells;
};

export function SkeletonRow({
  subComponents,
  indentation,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell>
        <Box className={classes.connectorAndEmployeeContainer}>
          <ConnectorLines
            indentation={indentation}
            keyPrefix={`skeleton-row-${indentation.length}`}
          />

          <Box className={classes.employeeInformationContainer}>
            <Skeleton
              className={clsx(classes.root, { [classes.icon]: true })}
              animation="wave"
              variant="circle"
            />
            <Box className={classes.employeeTextContainer}>
              <Skeleton
                className={clsx(classes.root, { [classes.text]: true })}
                variant="text"
                animation="wave"
              />
              <Skeleton
                className={clsx(classes.root, { [classes.text]: true })}
                variant="text"
                animation="wave"
              />
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Skeleton
          className={clsx(classes.root, { [classes.text]: true })}
          variant="text"
          animation="wave"
        />
      </TableCell>
      <TableCell>
        <Skeleton
          className={clsx(classes.root, { [classes.text]: true })}
          variant="text"
          animation="wave"
        />
      </TableCell>
      {[...new Array<null>(subComponents)].map((_, index) => (
        <TableCell key={index}>
          <Skeleton
            className={clsx(classes.root, { [classes.text]: true })}
            variant="text"
            animation="wave"
          />
        </TableCell>
      ))}
    </TableRow>
  );
}
