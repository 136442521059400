import { gql } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { GRAY_6 } from "src/theme";
import { EquityImportButton_employee as Employee } from "../../../__generated__/graphql";
import VariableCompCsvIllustration from "../../../assets/svgs/illustrations/integrations/variable-comp-csv.svg?react";
import { ActualVariableCompImportButton } from "../ActualVariableComp/ActualVariableCompImportButton";
import { VariableCompImportButton } from "./VariableCompImportButton";

interface Props {
  open: boolean;
  employees: Employee[];
  onClose?: () => unknown;
  onUpload?: () => unknown;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  content: {
    display: "flex",
    border: `1px solid ${GRAY_6}`,
    borderRadius: theme.spacing(1),
    alignItems: "center",
  },
  illustration: {
    marginLeft: theme.spacing(2),
  },
  stepsContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

export function VariableCompImportDialog({
  open,
  employees,
  onClose,
  onUpload,
}: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableEnforceFocus
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Add or Update Variable Comp Data</DialogTitle>
      <DialogContent className={classes.root}>
        <div className={classes.content}>
          <div className={classes.illustration}>
            <VariableCompCsvIllustration />
          </div>
          <div className={classes.stepsContainer}>
            <Typography variant="h6">
              Add or Update Target Variable Comp
            </Typography>
            <div className={classes.button}>
              <VariableCompImportButton
                employees={employees}
                onCancel={onClose}
                onUpload={onUpload}
              />
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <div className={classes.illustration}>
            <VariableCompCsvIllustration />
          </div>
          <div className={classes.stepsContainer}>
            <Typography variant="h6">
              Add or Update Actual Variable Comp
            </Typography>
            <div className={classes.button}>
              <ActualVariableCompImportButton
                employees={employees}
                onCancel={onClose}
                onUpload={onUpload}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

VariableCompImportDialog.fragments = {
  employee: gql`
    ${VariableCompImportButton.fragments.employee}
    fragment VariableCompImportDialog_employee on Employee {
      id
      ...VariableCompImportButton_employee
    }
  `,
};
