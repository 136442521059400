import { makeStyles, Typography } from "@material-ui/core";
import { GRAY_1 } from "../../theme";
import { CompNoteModalTextfield } from "./CompNoteModalTextfield";

type Props = {
  note: string | null;
  title: string;
  onSave: (newNote: string) => unknown;
};

const useStyles = makeStyles((theme) => ({
  title: {
    color: GRAY_1,
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "-0.25px",
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export function CompCycleManagerItemNoteContent({
  note,
  title,
  onSave,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.title}>{title}</Typography>
      <CompNoteModalTextfield
        note={note}
        buttonTitle="Save Note"
        onSave={onSave}
        expandedPlaceHolderText={
          "Provide any reasoning you think will help give others context or insight now or in the future..."
        }
      />
    </>
  );
}
