import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { money, Money } from "@asmbl/shared/money";
import { formatElapsedTime } from "@asmbl/shared/time";
import { makeStyles, Theme } from "@material-ui/core";
import { CompStatistics_anonymizedCompensation as AnonymizedCompensation } from "../../../__generated__/graphql";
import { GRAY_2, GRAY_4 } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";
import { MiniCompaRatioCompSlider } from "../../CompSlider/MiniCompaRatioSlider";
import { MiniCompSlider } from "../../CompSlider/MiniCompSlider";
import { LocationGroup, Position } from "../types";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontSize: "0.75rem",
    color: GRAY_4,
    fontWeight: 700,
    lineHeight: 1.05,
  },
  subheader: {
    fontSize: "0.75rem",
    color: GRAY_2,
    fontWeight: 500,
    lineHeight: 1.05,
    letterSpacing: "-0.15px",
  },
  statisticsContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(3),
  },
  statisticsElement: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(0.5),
  },
  sliderContainer: {
    paddingTop: theme.spacing(0.75),
  },
}));

type Props = {
  selectedCurrencyCode: CurrencyCode;
  position: Position;
  locationGroup: LocationGroup | undefined;
  annualOrHourlySalary: Money | undefined;
  salaryCompaRatio: number | null;
  offerStatistics: AnonymizedCompensation;
};

export function CompStatistics({
  selectedCurrencyCode,
  position,
  locationGroup,
  annualOrHourlySalary,
  salaryCompaRatio,
  offerStatistics,
}: Props): JSX.Element {
  const classes = useStyles();

  const {
    position: positionStatistics,
    positionAndLocationGroup: positionAndLocationGroupStatistics,
  } = offerStatistics;

  return (
    <div className={classes.statisticsContainer}>
      {positionStatistics.compaRatioStatistics != null && (
        <div className={classes.statisticsElement}>
          <AssembleTypography variant="productEyebrow" color="textSecondary">
            Compa-ratio (All locations) |{" "}
            {positionStatistics.employeeStatistics.count}{" "}
            <span style={{ textTransform: "none" }}>EEs</span>
          </AssembleTypography>
          <AssembleTypography variant="sidebarSubheader">
            Average Tenure:{" "}
            {formatElapsedTime(
              positionStatistics.employeeStatistics.averageTenure,
              {
                short: false,
              }
            )}
          </AssembleTypography>
          <div className={classes.sliderContainer}>
            <MiniCompaRatioCompSlider
              bandPoints={[
                {
                  name: "Min",
                  value: +Number(
                    positionStatistics.compaRatioStatistics.min
                  ).toFixed(2),
                },
                {
                  name: "Max",
                  value: +Number(
                    positionStatistics.compaRatioStatistics.max
                  ).toFixed(2),
                },
                {
                  name: "Average",
                  value: +Number(
                    positionStatistics.compaRatioStatistics.average
                  ).toFixed(2),
                },
                {
                  name: "Median",
                  value: +Number(
                    positionStatistics.compaRatioStatistics.median
                  ).toFixed(2),
                },
              ]}
              position={position}
              value={
                salaryCompaRatio != null
                  ? +Number(salaryCompaRatio.toFixed(2))
                  : null
              }
            />
          </div>
        </div>
      )}

      {positionAndLocationGroupStatistics.cashStatistics != null &&
        locationGroup != null && (
          <div className={classes.statisticsElement}>
            <AssembleTypography variant="productEyebrow" color="textSecondary">
              Position & {locationGroup.name} |{" "}
              {positionAndLocationGroupStatistics.employeeStatistics.count}
              <span style={{ textTransform: "none" }}>EEs</span>
            </AssembleTypography>
            <AssembleTypography variant="sidebarSubheader">
              Average Tenure:{" "}
              {formatElapsedTime(
                positionAndLocationGroupStatistics.employeeStatistics
                  .averageTenure,
                { short: false }
              )}
            </AssembleTypography>
            <div className={classes.sliderContainer}>
              <MiniCompSlider
                locationAdjustedBandPoints={[
                  {
                    name: "Min",
                    value: money(
                      positionAndLocationGroupStatistics.cashStatistics
                        .salaryStatistics.min.value,
                      selectedCurrencyCode
                    ),
                    currencyCode: selectedCurrencyCode,
                  },
                  {
                    name: "Max",
                    value: money(
                      positionAndLocationGroupStatistics.cashStatistics
                        .salaryStatistics.max.value,
                      selectedCurrencyCode
                    ),
                    currencyCode: selectedCurrencyCode,
                  },
                  {
                    name: "Average",
                    value: money(
                      positionAndLocationGroupStatistics.cashStatistics
                        .salaryStatistics.average.value,
                      selectedCurrencyCode
                    ),
                    currencyCode: selectedCurrencyCode,
                  },
                  {
                    name: "Median",
                    value: money(
                      positionAndLocationGroupStatistics.cashStatistics
                        .salaryStatistics.median.value,
                      selectedCurrencyCode
                    ),
                    currencyCode: selectedCurrencyCode,
                  },
                ]}
                position={position}
                value={annualOrHourlySalary ?? null}
              />
            </div>
          </div>
        )}
    </div>
  );
}

CompStatistics.fragments = {
  compStatistics: gql`
    fragment CompStatistics_anonymizedCompensation on AnonymizedCompensation {
      position {
        employeeStatistics {
          averageTenure
          count
        }
        compaRatioStatistics {
          min
          max
          average
          median
        }
      }
      positionAndLocationGroup {
        employeeStatistics {
          averageTenure
          count
        }
        cashStatistics {
          salaryStatistics {
            min
            max
            average
            median
          }
        }
      }
    }
  `,
};
