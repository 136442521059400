import { gql } from "@apollo/client";
import { Money } from "@asmbl/shared/money";
import { makeStyles } from "@material-ui/core";
import { DateTime as LuxonDateTime } from "luxon";
import { EquityChartVestingSummary_equityGrant as EquityGrant } from "../../../../__generated__/graphql";
import { AssembleTypography } from "../../../AssembleTypography";
import { EquityVestInfoCell } from "./EquityVestInfoCell";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "space-around",
  },
  text: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

type Props = {
  equityGrant: EquityGrant;
  sharePrice: Money | undefined;
};

export const EquityChartVestingSummary = ({
  equityGrant,
  sharePrice,
}: Props) => {
  const classes = useStyles();

  const vestEvents = equityGrant.vestingInformation?.vestEvents;
  const closest =
    vestEvents !== undefined && vestEvents.length > 0
      ? vestEvents
          .slice()
          .sort(
            (a, b) =>
              new Date(a.vestingDate).getTime() -
              new Date(b.vestingDate).getTime()
          )
          .reduce((a, b) =>
            // if a date is in the past, return b
            new Date(a.vestingDate).getTime() < new Date().getTime() ? b : a
          )
      : null;

  const isFullyVested =
    closest?.vestingDate !== undefined &&
    new Date(closest.vestingDate) < new Date();

  return (
    <div className={classes.root}>
      <div className={classes.text}>
        <EquityVestInfoCell
          units={equityGrant.vestingInformation?.vestedUnits ?? 0}
          price={sharePrice}
        />
        <AssembleTypography variant="formDescription">
          Vested Units
        </AssembleTypography>
      </div>
      <div className={classes.text}>
        <EquityVestInfoCell
          units={equityGrant.vestingInformation?.unvestedUnits ?? 0}
          price={sharePrice}
        />
        <AssembleTypography variant="formDescription">
          Unvested Units
        </AssembleTypography>
      </div>
      {!isFullyVested && (
        <div className={classes.text}>
          <EquityVestInfoCell
            units={closest?.unitCount ?? null}
            price={sharePrice}
          />
          <AssembleTypography variant="formDescription">
            Next Vesting Date&nbsp;
            {closest?.vestingDate !== undefined
              ? LuxonDateTime.fromJSDate(
                  new Date(closest.vestingDate)
                ).toFormat("M/d/yy")
              : undefined}
          </AssembleTypography>
        </div>
      )}
    </div>
  );
};

EquityChartVestingSummary.fragments = {
  equityGrant: gql`
    fragment EquityChartVestingSummary_equityGrant on EquityGrant {
      id
      grossValue
      vestingInformation {
        vestedUnits
        unvestedUnits
        vestEvents {
          id
          grantName
          unitCount
          vestingDate
        }
      }
    }
  `,
};
