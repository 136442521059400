import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { makeStyles, TableCell } from "@material-ui/core";
import { useEffect } from "react";
import { useTrack } from "../../../analytics";
import {
  default as AnonymizedCompensationBandVisualization,
  default as AnonymizedDataBandVisualization,
} from "../../../components/AnonymizedCompensationBandVisualization/AnonymizedCompensationBandVisualization";
import { AssembleTypography } from "../../../components/AssembleTypography";
import {
  ADJUSTED_CASH_BAND_FIELDS,
  ADJUSTED_EQUITY_BAND_FIELDS,
} from "../../../fragments";
import { computeSeries, totalCompBand } from "../../../models/Band";
import { GRAY_6, GRAY_8 } from "../../../theme";
import { Range } from "../../../utils";
import {
  AnonymizedCompensationCells_anonymizedCompensation as AnonymizedCompensation,
  AnonymizedCompensationCells_permissionSettings as PermissionSettings,
  AnonymizedCompensationCells_position,
} from "../../../__generated__/graphql";
import { useExcludedBandNames } from "../ExcludedBandNameContext";
import { AnonymizedCompensationInformationCell } from "./AnonymizedCompensationInformationCell";

const useStyles = makeStyles(() => ({
  compRangeCell: { minWidth: "160px" },
  bandVisualizationCell: {
    backgroundColor: GRAY_8,
    borderLeft: `1px solid ${GRAY_6}`,
    borderRight: `1px solid ${GRAY_6}`,
  },
}));

type Props = {
  permissionSettings: PermissionSettings;
  position: AnonymizedCompensationCells_position;
  anonymizedCompensation: AnonymizedCompensation;
  totalCompRange: Range;
  selectedCurrencyCode: CurrencyCode;
};

export function AnonymizedCompensationCells({
  permissionSettings,
  position,
  anonymizedCompensation,
  totalCompRange,
  selectedCurrencyCode,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const classes = useStyles();
  const { excludedBandNames } = useExcludedBandNames();

  const employeeCount =
    anonymizedCompensation.positionAndLocationGroup.employeeStatistics.count;
  const thresholdMet =
    anonymizedCompensation.positionAndLocationGroup.cashStatistics !== null;

  useEffect(() => {
    trackEvent({
      object: "Anonymized Comp",
      action: "Viewed",
      ladderName: position.ladder.name,
      positionName: position.name,
      employeeCount,
      thresholdMet,
    });
  }, [
    employeeCount,
    position.ladder.name,
    position.name,
    thresholdMet,
    trackEvent,
  ]);

  const compBand = computeSeries(
    totalCompBand(position, excludedBandNames),
    (totalCompRange.max - totalCompRange.min) / 100
  );

  return (
    <>
      <TableCell component="th" scope="row" align="center">
        <AssembleTypography>{employeeCount}</AssembleTypography>
      </TableCell>

      <TableCell
        className={classes.bandVisualizationCell}
        align="left"
        padding="none"
      >
        <AnonymizedCompensationBandVisualization
          compBand={compBand}
          anonymizedCompensation={anonymizedCompensation}
          totalCompRange={totalCompRange}
          permissionSettings={permissionSettings}
        />
      </TableCell>
      <TableCell className={classes.compRangeCell}>
        <AnonymizedCompensationInformationCell
          permissionSettings={permissionSettings}
          position={position}
          compBand={compBand}
          selectedCurrencyCode={selectedCurrencyCode}
          anonymizedCompensation={anonymizedCompensation}
        />
      </TableCell>
    </>
  );
}

AnonymizedCompensationCells.fragments = {
  position: gql`
    ${ADJUSTED_CASH_BAND_FIELDS}
    ${ADJUSTED_EQUITY_BAND_FIELDS}
    ${AnonymizedCompensationInformationCell.fragments.position}
    fragment AnonymizedCompensationCells_position on Position {
      id
      name
      ladder {
        id
        name
      }
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedCashBandFields
      }
      adjustedEquityBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedEquityBandFields
      }
      ...AnonymizedCompensationInformationCell_position
    }
  `,
  anonymizedData: gql`
    ${AnonymizedDataBandVisualization.fragments.anonymizedCompensation}
    ${AnonymizedCompensationInformationCell.fragments.anonymizedCompensation}
    fragment AnonymizedCompensationCells_anonymizedCompensation on AnonymizedCompensation {
      positionAndLocationGroup {
        employeeStatistics {
          count
        }
      }
      ...AnonymizedCompensationBandVisualization_anonymizedCompensation
      ...AnonymizedCompensationInformationCell_anonymizedCompensation
    }
  `,
  permissionSettings: gql`
    ${AnonymizedCompensationBandVisualization.fragments.permissionSettings}
    ${AnonymizedCompensationInformationCell.fragments.permissionSettings}
    fragment AnonymizedCompensationCells_permissionSettings on PermissionSettings {
      id
      ...AnonymizedCompensationBandVisualization_permissionSettings
      ...AnonymizedCompensationInformationCell_permissionSettings
    }
  `,
};
