import { JobStructureScope } from "@asmbl/shared/permissions";
import { Box, Drawer, makeStyles, Typography } from "@material-ui/core";
import fastDeepEqual from "fast-deep-equal";
import { useCallback, useMemo, useState } from "react";
import { TreeNode } from "src/components/JobStructureSelect/TreeSelect";
import {
  countPositionsSelected,
  JobStructure,
  JobStructureTreeNodeData,
  selectionToTree,
  treeToSelection,
} from "../../../models/JobStructure";
import { Scope } from "../../../models/UserPermissions";
import { GRAY_2, GRAY_4 } from "../../../theme";
import AssembleDialog from "../../AssembleDialog";
import { SaveButton } from "../../Form/SaveButton";
import { JobStructureSelect } from "../../JobStructureSelect/JobStructureSelect";
import { UnsavedChangesWarning } from "../../UnsavedChangesWarning/UnsavedChangesWarning";
import { DrawerTitle } from "../DrawerTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "550px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
  },

  header: {
    boxShadow: "-5px 0px 20px rgba(10, 36, 64, 0.08)",
    padding: theme.spacing(3),
    flexGrow: 0,
    zIndex: 2,
  },

  prompt: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: `${theme.spacing(3)}px`,
  },

  whichPositions: {
    fontSize: "16px",
    color: GRAY_2,
    fontWeight: 700,
  },
  numPositions: {
    fontSize: "16px",
    color: GRAY_4,
    fontWeight: 700,
  },

  tree: {
    flexGrow: 1,
    overflowY: "auto",
    padding: theme.spacing(3),
    zIndex: 1,
  },

  footer: {
    boxShadow: "5px 0px 20px rgba(10, 36, 64, 0.08)",
    padding: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "90px",
    flexGrow: 0,
    flexShrink: 0,
    zIndex: 2,
  },
}));

export function AccessGrantDrawer({
  isOpen,
  onClose,
  onChange,
  value,
  jobStructure,
}: {
  isOpen: boolean;
  onClose(): unknown;
  onChange(grant: Scope): Promise<unknown>;
  value: {
    global: boolean;
    departmentIDs: number[];
    ladderIDs: number[];
    positionIDs: number[];
  };
  jobStructure: JobStructure;
}): JSX.Element {
  const classes = useStyles();

  const [scope, setScope] = useState<NonNullable<JobStructureScope>>(value);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const tree = useMemo(
    () => selectionToTree(jobStructure, scope),
    [jobStructure, scope]
  );

  const numPositionsSelected = useMemo(
    () => countPositionsSelected(tree),
    [tree]
  );

  const hasUnsavedChanges = useMemo(
    () =>
      // Sometimes _typename interferes with fastDeepEqual, so check each field
      !(
        scope.global === value.global &&
        fastDeepEqual(scope.departmentIDs, value.departmentIDs) &&
        fastDeepEqual(scope.ladderIDs, value.ladderIDs) &&
        fastDeepEqual(scope.positionIDs, value.positionIDs)
      ),
    [value, scope]
  );

  const handleSelection = useCallback(
    (newTree: TreeNode<JobStructureTreeNodeData>) =>
      setScope(treeToSelection(newTree)),
    []
  );

  const handleSave = useCallback(async () => {
    try {
      await onChange(scope);
      onClose();
      return true;
    } catch (_) {
      return false;
    }
  }, [onChange, onClose, scope]);

  const handleClose = () => {
    if (hasUnsavedChanges) {
      setIsCancelDialogOpen(true);
    } else {
      setScope(value);
      onClose();
    }
  };

  const handleCancelCloseEditor = () => {
    setIsCancelDialogOpen(false);
  };

  const handleConfirmCloseEditor = () => {
    setIsCancelDialogOpen(false);
    setScope(value);
    onClose();
  };

  return (
    <Drawer anchor="left" open={isOpen} onClose={handleClose}>
      <UnsavedChangesWarning pageEdited={hasUnsavedChanges} />
      {isCancelDialogOpen && (
        <AssembleDialog
          onCancel={handleCancelCloseEditor}
          onConfirm={handleConfirmCloseEditor}
          title={`Are you sure you want to cancel?`}
          text="If you cancel now, all of your unsaved changes will be lost."
          cancelButtonText="Back to Editing"
          confirmButtonText="Yes, Cancel"
          confirmButtonVariant="negative"
        />
      )}
      <Box className={classes.root}>
        <AccessDrawerHeader
          numPositions={numPositionsSelected}
          onClose={handleClose}
        />
        <Box className={classes.tree}>
          <JobStructureSelect selectionTree={tree} onChange={handleSelection} />
        </Box>
        <Box className={classes.footer}>
          <SaveButton onSave={handleSave} disabled={!hasUnsavedChanges} />
        </Box>
      </Box>
    </Drawer>
  );
}

interface AccessDrawerHeaderProps {
  numPositions: number;
  onClose: () => unknown;
}
function AccessDrawerHeader({
  numPositions,
  onClose,
}: AccessDrawerHeaderProps): JSX.Element {
  const classes = useStyles();
  return (
    <Box className={classes.header}>
      <DrawerTitle title="Edit Access" onClose={onClose} />
      <Box className={classes.prompt}>
        <Typography className={classes.whichPositions}>
          Which positions can this person access?
        </Typography>
        <Typography className={classes.numPositions}>
          {numPositions} positions selected
        </Typography>
      </Box>
    </Box>
  );
}
