import { gql } from "@apollo/client";
import {
  CircularProgress,
  Dialog,
  makeStyles,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { AssembleTypography } from "src/components/AssembleTypography";
import {
  OveragesSelector_budgetOverage as BudgetOverage,
  AllocateBudgetsHeader_compCycleBudgetDraft as CompCycleBudgetDraft,
  AllocateBudgetsHeader_compCycleSettings as CompCycleSettings,
} from "../../../../__generated__/graphql";
import { ActionBar } from "../../../../components/Layout/ActionBar";
import { GRAY_1 } from "../../../../theme";
import { PublishBudgetButton } from "./PublishBudgetButton";
import { SaveNotification } from "./SaveNotification";

const useStyles = makeStyles((theme) => ({
  title: {
    color: GRAY_1,
    fontWeight: 500,
    size: "1rem",
    lineHeight: "1.4",
    letterSpacing: "-0.5px",
  },
  generateBudgetButton: {
    height: "32px",
  },
  circularProgress: {
    width: "20px !important",
    height: "20px !important",
    marginRight: theme.spacing(1),
  },
  buttonRow: {
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "flex-end",
  },
  spacing: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  compCycleBudgetDraft: CompCycleBudgetDraft;
  loading: boolean;
  pageEditCount: number;
  publishBudgets: () => Promise<unknown>;
  overages: BudgetOverage[] | undefined;
  organizationName: string;
  generateBudgetFromMatrix: () => void;
  budgetLoading: boolean;
  allowSalary: boolean;
  compCycleSettings: CompCycleSettings;
};

export function AllocateBudgetsHeader({
  compCycleBudgetDraft,
  loading,
  pageEditCount,
  publishBudgets,
  overages,
  organizationName,
  generateBudgetFromMatrix,
  budgetLoading,
  allowSalary,
  compCycleSettings,
}: Props): JSX.Element {
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const classes = useStyles();
  const managerName = compCycleBudgetDraft.employee?.displayName ?? null;

  const hasExistingDraft =
    compCycleBudgetDraft.reportsDrafts[0]?.salary != null;

  const hasBudgetGuidance = compCycleSettings.budgetGuides.length > 0;

  const onGenerateBudget = () => {
    if (hasExistingDraft) {
      setConfirmModalOpen(true);
    } else {
      generateBudgetFromMatrix();
    }
  };

  return (
    <ActionBar
      slotLeft={
        <Typography className={classes.title}>
          {`Allocating budget for ${
            managerName !== null
              ? `${managerName} and reports`
              : `${organizationName}`
          }`}
        </Typography>
      }
      slotRight={
        <>
          {allowSalary && hasBudgetGuidance ? (
            <AssembleButton
              className={classes.generateBudgetButton}
              variant="contained"
              onClick={onGenerateBudget}
              disabled={budgetLoading}
              size="medium"
              label={
                <span>
                  {budgetLoading ? (
                    <CircularProgress
                      classes={{ root: classes.circularProgress }}
                    />
                  ) : (
                    ""
                  )}
                  {hasExistingDraft ? "Regenerate" : "Generate"} Salary
                  Adjustment Budgets
                </span>
              }
            />
          ) : null}
          <PublishBudgetButton
            onPublish={publishBudgets}
            disabled={overages === undefined || overages.length > 0}
          />
          <SaveNotification
            pageEditCount={pageEditCount}
            loading={loading}
            compCycleId={compCycleBudgetDraft.compCycleId}
            overages={overages}
          />
          <Dialog
            open={confirmModalOpen}
            onClose={() => setConfirmModalOpen(false)}
          >
            <AssembleTypography variant="h5">
              Re-generating Budgets
            </AssembleTypography>
            <AssembleTypography className={classes.spacing}>
              You're about to re-generate your salary budgets. Doing so will
              overwrite any salary budgets you've applied dynamically or entered
              manually.
            </AssembleTypography>
            <AssembleTypography className={classes.spacing}>
              Other budgets you've applied manually won't be affected.
            </AssembleTypography>
            <div className={clsx(classes.buttonRow, classes.spacing)}>
              <AssembleButton
                variant="outlined"
                onClick={() => setConfirmModalOpen(false)}
                label="Cancel"
                size="medium"
              />
              <AssembleButton
                variant="danger"
                size="medium"
                onClick={() => {
                  setConfirmModalOpen(false);
                  generateBudgetFromMatrix();
                }}
                label="Overwrite Budget"
              />
            </div>
          </Dialog>
        </>
      }
    />
  );
}

AllocateBudgetsHeader.fragments = {
  compCycleBudgetDraft: gql`
    fragment AllocateBudgetsHeader_compCycleBudgetDraft on CompCycleBudgetDraft {
      compCycleId
      employeeId

      employee {
        id
        displayName
      }

      reportsDrafts {
        compCycleId
        employeeId
        salary
      }
    }
  `,
  compCycleSettings: gql`
    fragment AllocateBudgetsHeader_compCycleSettings on CompCycle {
      budgetGuides {
        id
      }
    }
  `,
};
