import { createContext, ReactNode, useContext, useState } from "react";
import { ColumnIds } from "./ColumnOrderContext";

type PaginationType = {
  currentPage: number;
  pageSize: number;
  setCurrentPage: (page: number) => void;
  totalCount: number;
  setTotalCount: (count: number) => void;
  setPageSize: (size: number) => void;
  sortBy: string;
  sortDir: "asc" | "desc";
  setSortBy: (property: string) => void;
  setSortDir: (direction: "asc" | "desc") => void;
  setSortParams: (property: string) => void;
  currentEmployeeIds: number[];
  setCurrentEmployeeIds: (ids: number[]) => void;
};

export const PaginationContext = createContext<PaginationType>({
  currentEmployeeIds: [],
  setCurrentEmployeeIds: (_ids: number[]) => {
    // empty default
  },
  currentPage: 0,
  pageSize: 0,
  setCurrentPage: (_page: number) => {
    // empty default
  },
  totalCount: 0,
  setTotalCount: (_count: number) => {
    // empty default
  },
  setPageSize: (_size: number) => {
    // empty default
  },
  sortBy: ColumnIds.NAME,
  sortDir: "asc",
  setSortBy: (_property: string) => {
    // empty default
  },
  setSortDir: (_direction: "asc" | "desc") => {
    // empty default
  },
  setSortParams: (_field: string) => {
    // empty default
  },
});

export const PaginationProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [currentEmployeeIds, setCurrentEmployeeIds] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [sortBy, setSortBy] = useState<string>(ColumnIds.NAME);
  const [sortDir, setSortDir] = useState<"asc" | "desc">("asc");

  const setSortParams = (field: string) => {
    if (field === sortBy) {
      setSortDir(sortDir === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortDir("asc");
    }
  };

  return (
    <PaginationContext.Provider
      value={{
        currentEmployeeIds,
        setCurrentEmployeeIds,
        pageSize,
        currentPage,
        setCurrentPage,
        totalCount,
        setTotalCount,
        setPageSize,
        sortBy,
        setSortBy,
        sortDir,
        setSortDir,
        setSortParams,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export const usePagination = (): PaginationType => {
  return useContext(PaginationContext);
};
