import { useLocation } from "react-router-dom";

/* An immutable wrapper around the native URLSearchParams class */
export class ImmutableURLSearchParams implements URLSearchParams {
  private mutableParams: URLSearchParams;
  public size: number;

  constructor(
    init:
      | string
      | string[][]
      | Record<string, string>
      | URLSearchParams
      | undefined
  ) {
    this.mutableParams = new URLSearchParams(init);
    this.size = this.mutableParams.size;
  }

  append(name: string, value: string): ImmutableURLSearchParams {
    const clone = new URLSearchParams(this.mutableParams);
    clone.append(name, value);
    return new ImmutableURLSearchParams(clone);
  }

  delete(name: string): ImmutableURLSearchParams {
    const clone = new URLSearchParams(this.mutableParams);
    clone.delete(name);
    return new ImmutableURLSearchParams(clone);
  }

  deleteMany(names: string[]): ImmutableURLSearchParams {
    const clone = new ImmutableURLSearchParams(this.mutableParams);
    return names.reduce((newParams, name) => newParams.delete(name), clone);
  }

  get(name: string): string | null {
    return this.mutableParams.get(name);
  }

  getAll(name: string): string[] {
    return this.mutableParams.getAll(name);
  }

  has(name: string): boolean {
    return this.mutableParams.has(name);
  }

  set(name: string, value: string): ImmutableURLSearchParams {
    const clone = new URLSearchParams(this.mutableParams);
    clone.set(name, value);
    return new ImmutableURLSearchParams(clone);
  }

  sort(): ImmutableURLSearchParams {
    const clone = new URLSearchParams(this.mutableParams);
    clone.sort();
    return new ImmutableURLSearchParams(clone);
  }

  toString(): string {
    return this.mutableParams.toString();
  }

  forEach(
    callbackfn: (value: string, key: string, parent: URLSearchParams) => void
  ): void {
    return this.mutableParams.forEach(callbackfn);
  }

  [Symbol.iterator](): IterableIterator<[string, string]> {
    return this.mutableParams[Symbol.iterator]();
  }

  entries(): IterableIterator<[string, string]> {
    return this.mutableParams.entries();
  }

  keys(): IterableIterator<string> {
    return this.mutableParams.keys();
  }

  values(): IterableIterator<string> {
    return this.mutableParams.values();
  }
}

/**
 * A hook to synchronize the URL query string with React
 * To write to the query string, modify the params and
 * either pushState or create a link with `toString`
 */
export function useURLSearchParams(): ImmutableURLSearchParams {
  return new ImmutableURLSearchParams(useLocation().search);
}
