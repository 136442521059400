import { gql, useQuery } from "@apollo/client";
import { LinearProgress } from "@material-ui/core";
import {
  CorrectionCostToMoveOuterPageQuery,
  CorrectionCostToMoveOuterPageQueryVariables,
  Noun,
} from "src/__generated__/graphql";
import { NoCompStructure } from "src/components/EmptyState/EmptyState";
import { ErrorView } from "src/components/ErrorView";
import { UserPermissions } from "src/models/UserPermissions";
import { CorrectionCostToMovePage } from "./CorrectionCostToMovePage";

export function CorrectionCostToMovePageLoadingBoundary(): JSX.Element {
  const { data, error } = useQuery<
    CorrectionCostToMoveOuterPageQuery,
    CorrectionCostToMoveOuterPageQueryVariables
  >(CorrectionCostToMovePageLoadingBoundary.query);

  if (!data) {
    if (error) {
      return <ErrorView text={error.message} />;
    }
    return <LinearProgress />;
  }

  if (!data.compStructure) {
    return <NoCompStructure />;
  }

  return (
    <CorrectionCostToMovePage
      compStructure={data.compStructure}
      managers={data.managers}
      departments={data.departments}
      markets={data.markets}
    />
  );
}

CorrectionCostToMovePageLoadingBoundary.query = gql`
  ${CorrectionCostToMovePage.fragments.compStructure}
  ${CorrectionCostToMovePage.fragments.employee}
  ${CorrectionCostToMovePage.fragments.department}
  ${CorrectionCostToMovePage.fragments.market}
  query CorrectionCostToMoveOuterPageQuery {
    compStructure {
      id
      ...CorrectionCostToMovePage_compStructure
    }
    managers {
      id
      ...CorrectionCostToMovePage_employee
    }
    departments {
      id
      ...CorrectionCostToMovePage_department
    }
    markets {
      id
      ...CorrectionCostToMovePage_market
    }
  }
`;

CorrectionCostToMovePageLoadingBoundary.canAccess = (
  permissions: UserPermissions
): boolean => {
  return [
    Noun.Employee,
    Noun.EmployeeCashCompensation,
    Noun.CashBand,
    Noun.CompStructure,
  ].every((noun) => permissions.canViewGlobal(noun));
};

CorrectionCostToMovePageLoadingBoundary.path = "band-placement-correction";
