import { makeStyles } from "@material-ui/core";
import AnalyzingOrgHierarchy from "src/assets/svgs/illustrations/analyzing-org-hierarchy.svg?react";
import { AssembleTypography } from "src/components/AssembleTypography";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { GRAY_2 } from "src/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
  },
  orgHierarchyIcon: {
    display: "block",
    marginBottom: theme.spacing(6),
  },
}));

export function ApprovalSettingsChartLoader(): JSX.Element {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  return (
    <div className={classes.root} style={{ height, width }}>
      <AnalyzingOrgHierarchy className={classes.orgHierarchyIcon} />
      <AssembleTypography textColor={GRAY_2} variant="productButtonMedium">
        Please sit tight for a moment...
      </AssembleTypography>
      <AssembleTypography textColor={GRAY_2} variant="productButtonMedium">
        we're retrieving your current phase and employee data to generate your
        organizational hierarchy.
      </AssembleTypography>
    </div>
  );
}
