import { formatCurrency, Money } from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import { GRAY_2 } from "../../../../theme";
import { AssembleTypography } from "../../../AssembleTypography";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
  },
  title: {
    color: GRAY_2,
  },
}));

type Props = {
  title: string;
  value?: Money;
  units?: number;
};

export const EquityGrantInfoCell = ({ title, value, units }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AssembleTypography variant="productSmall" className={classes.title}>
        {title}
      </AssembleTypography>
      <AssembleTypography variant="productParagraphLarge">
        {units !== undefined
          ? formatNumeral(units)
          : value !== undefined
            ? formatCurrency(value, { maximumFractionDigits: 2 })
            : "No Data"}
      </AssembleTypography>
    </div>
  );
};
