import { EquityBandName } from "@asmbl/shared/constants";
import { makeStyles, MenuItem, Select } from "@material-ui/core";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { BandUnit, EquityGrantMethod } from "../../../__generated__/graphql";
import { BandName } from "../types";

const useStyles = makeStyles((theme) => ({
  icon: {
    paddingTop: theme.spacing(1),
    transition: "transform cubic-bezier(0.4, 0, 0.2, 1) 275ms",
  },
}));

function getOfferInputOptions(
  bandName: BandName,
  equityGrantMethod?: EquityGrantMethod
): { value: BandUnit; name: string }[] {
  if (
    bandName === EquityBandName.INITIAL_EQUITY_GRANT &&
    equityGrantMethod === EquityGrantMethod.UNITS
  ) {
    return [
      {
        value: BandUnit.UNITS,
        name: "Units",
      },
      {
        value: BandUnit.PERCENTAGE,
        name: "% of salary",
      },
    ];
  }

  return [
    {
      value: BandUnit.CASH,
      name: "Cash value",
    },
    {
      value: BandUnit.PERCENTAGE,
      name: "% of salary",
    },
  ];
}

export type OfferInputTypeSelectorProps = {
  bandName: BandName;
  offerType: BandUnit;
  onOfferTypeChange: (offerType: BandUnit) => void;
  equityGrantMethod?: EquityGrantMethod;
};

const IconComponent = (props: Record<string, unknown>) => (
  <ChevronDownIcon
    data-chromatic="ignore"
    width="24px"
    height="24px"
    inherit
    inline
    {...props}
  />
);

export function OfferInputTypeSelector({
  bandName,
  offerType,
  onOfferTypeChange,
  equityGrantMethod,
}: OfferInputTypeSelectorProps): JSX.Element {
  const classes = useStyles();
  const options = getOfferInputOptions(bandName, equityGrantMethod);

  return (
    <Select
      classes={{ icon: classes.icon }}
      value={offerType}
      onChange={(e) => onOfferTypeChange(e.target.value as BandUnit)}
      IconComponent={IconComponent}
      variant="outlined"
      fullWidth
      data-chromatic="ignore"
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
}
