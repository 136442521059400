import { makeStyles } from "@material-ui/core";
import { OrgChart } from "d3-org-chart";
import { useIntercom } from "react-use-intercom";
import { useTrack } from "src/analytics";
import { CancelConfigurationButton } from "src/pages/CompCycle/HierarchyConfiguration/CancelConfigurationButton";
import { CompCycleOrganizationChart_employee as Employee } from "src/__generated__/graphql";
import { AssembleButton, getIconProps } from "../AssembleButton/AssembleButton";
import { CenterViewIcon } from "../AssembleIcons/Small/CenterViewIcon";
import { ZoomInIcon } from "../AssembleIcons/Small/ZoomInIcon";
import { ZoomOutIcon } from "../AssembleIcons/Small/ZoomOutIcon";
import { CompCycleDataChangeHandler } from "../CompCycle/CompCycleWizard/types";
import {
  useCompCycleOrganizationChartSelectionData,
  useCompCycleOrganizationChartViewsData,
} from "./CompCycleOrganizationChartContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "calc(100vw - 272px)",
    position: "absolute",
    top: theme.spacing(2.5),
    right: theme.spacing(24.5),
    paddingLeft: theme.spacing(2),

    display: "flex",
    justifyContent: "space-between",
  },
  left: {
    display: "flex",
  },
  right: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(1.25),
    alignItems: "center",
  },
}));

type Props = {
  chart: OrgChart<Employee>;
  updateStep: (step: number) => unknown;
  handleChange: CompCycleDataChangeHandler;
  onConfigureHome: () => unknown;
};

export function ChartButtonBar({
  chart,
  onConfigureHome,
  updateStep,
  handleChange,
}: Props): JSX.Element {
  const { setPageEditState } = useCompCycleOrganizationChartViewsData();
  const { clearChartSelections } = useCompCycleOrganizationChartSelectionData();
  const classes = useStyles();
  const { trackEvent } = useTrack();
  const intercom = useIntercom();

  const handleBack = () => {
    // reset the phases state
    handleChange("phasesData", []);
    // close the org chart and go to the phase configuration page
    setPageEditState(false);
    clearChartSelections();
    onConfigureHome();
    updateStep(2);
  };

  const handleSave = () => {
    trackEvent({
      object: "Save Configuration Button",
      action: "Clicked",
    });
    intercom.trackEvent("Phases Save Configuration Button Clicked");

    clearChartSelections();
    setPageEditState(false);
    onConfigureHome();
    updateStep(3);
  };

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <CancelConfigurationButton onClick={handleBack} />
      </div>
      <div className={classes.right}>
        <AssembleButton
          title="Fit chart onto view"
          size="small"
          variant="outlined"
          onClick={() => chart.fit()}
          startIcon={<CenterViewIcon {...getIconProps({ size: "small" })} />}
        />
        <AssembleButton
          title="Zoom out"
          size="small"
          variant="outlined"
          onClick={() => chart.zoomOut()}
          startIcon={<ZoomOutIcon {...getIconProps({ size: "small" })} />}
        />
        <AssembleButton
          title="Zoom in"
          size="small"
          variant="outlined"
          startIcon={<ZoomInIcon {...getIconProps({ size: "small" })} />}
          onClick={() => chart.zoomIn()}
        />
        <AssembleButton
          title="Save Configuration"
          size="small"
          variant="outlined"
          label="Save Configuration"
          onClick={handleSave}
          data-intercom-target={"button-save-configuration"}
        />
      </div>
    </div>
  );
}
