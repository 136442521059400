import { gql, useQuery } from "@apollo/client";
import { ErrorView } from "../../../../components/ErrorView";
import Loading from "../../../../components/Settings/Loading";
import { PortalConfigQuery } from "../../../../__generated__/graphql";
import { PageContainer } from "../../PageContainer";
import { PortalConfig } from "./PortalConfig";

export const PortalConfigBoundary = () => {
  const { data, loading } = useQuery<PortalConfigQuery>(
    PortalConfigBoundary.query
  );

  if (!data) {
    return loading ? (
      <Loading />
    ) : (
      <PageContainer>
        <ErrorView />
      </PageContainer>
    );
  }

  return (
    <PortalConfig
      portalConfig={data.portalConfig}
      employeeBandAccess={
        data.organization.permissionSettings.employeeBandAccess
      }
      organizationName={data.organization.name}
    />
  );
};

PortalConfigBoundary.query = gql`
  ${PortalConfig.fragments.portalConfig}
  ${PortalConfig.fragments.organization}
  query PortalConfigQuery {
    portalConfig {
      id
      ...PortalConfig_portalConfig
    }
    organization {
      id
      ...PortalConfig_permissionSettings
    }
  }
`;
