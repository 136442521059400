import { gql } from "@apollo/client";
import { Money } from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import { DateTime as LuxonDateTime } from "luxon";
import { useMemo } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getXTicksForTimeline, getYTicks } from "../../../models/Chart";
import { DV_BLUE_2, GRAY_1, GRAY_2, GRAY_3, GRAY_6 } from "../../../theme";
import { TotalEquityTimelineChart_equityHoldings as EquityHoldings } from "../../../__generated__/graphql";
import {
  EMPTY_X_INTERVALS,
  EMPTY_Y_INTERVALS,
  formatVestingEvents,
  Y_INTERVALS,
} from "./chart";
import { TotalEquityTimelineChartTooltip } from "./TotalEquityTimelineChartTooltip";

const useStyles = makeStyles((theme) => ({
  chart: { marginTop: theme.spacing(3) },
  labelColor: { color: GRAY_2 },
  noDataLabel: {
    fontWeight: 450,
    fontSize: "1rem",
    lineHeight: "1.4rem",
    letterSpacing: "-0.25px",
  },
}));

const AREA_CHART_MARGIN_STYLE = { right: 15, top: 15 };
const AXIS_LINE_STYLE = { stroke: GRAY_6 };
const TOOLTIP_WRAPPER_STYLE = { outline: "none" };

type Props = {
  equityHoldings: EquityHoldings;
  sharePrice: Money | undefined;
};

type ChartPoint = {
  vestingDate: string;
  unitCount: number;
  grantName: string;
  currentUnitCount: number;
};

export function TotalEquityTimelineChart({
  equityHoldings,
  sharePrice,
}: Props): JSX.Element {
  const classes = useStyles();

  const data = useMemo(
    () =>
      formatVestingEvents(
        equityHoldings.vestingInformation?.vestEvents ?? []
      ).map((equity) => ({ ...equity, unitCount: equity.unitCount })),
    [equityHoldings]
  );

  const hasData = data.length > 0;

  // Ticks are generated manually to fit the data nicely
  const xTicks = useMemo(
    () =>
      hasData
        ? getXTicksForTimeline(
            new Date(data[0].vestingDate),
            new Date(data[data.length - 1].vestingDate)
          ).map((t) => t.toMillis())
        : EMPTY_X_INTERVALS(new Date()),
    [data, hasData]
  );

  const yTicks = useMemo(
    () =>
      hasData
        ? getYTicks(
            Y_INTERVALS,
            data.map((d) => d.unitCount)
          )
        : EMPTY_Y_INTERVALS,
    [data, hasData]
  );

  // set reference line date to "today" a.k.a midnight last night
  const refLineDate = new Date().setHours(0, 0, 0, 0);

  const unitCountDataKey = (d: ChartPoint) => d.unitCount;

  const xAxisDataKey = (d: ChartPoint) => new Date(d.vestingDate).getTime();
  const xAxisDomain = ["dataMin", "dataMax"];
  const yAxisDomain = ["0", "dataMax"];
  return (
    <div data-cy="equity-timeline-chart">
      <ResponsiveContainer className={classes.chart} width="100%" height={265}>
        <AreaChart
          data={data}
          margin={AREA_CHART_MARGIN_STYLE}
          className="chromatic-ignore"
        >
          <CartesianGrid stroke={GRAY_6} />
          <XAxis
            dataKey={xAxisDataKey}
            type="number"
            ticks={xTicks}
            tickSize={8}
            tickLine={false}
            tickFormatter={formatXTicks}
            domain={xAxisDomain}
            stroke={GRAY_3}
            fontSize={12}
            axisLine={AXIS_LINE_STYLE}
            className="chromatic-ignore"
          >
            {!hasData && (
              <Label
                position={"top"}
                offset={110}
                fill={GRAY_3}
                className={classes.noDataLabel}
                value="Not enough equity vesting data provided to show this report."
              />
            )}
          </XAxis>
          <YAxis
            dataKey={unitCountDataKey}
            type="number"
            ticks={yTicks}
            tickSize={8}
            tickLine={false}
            interval={0}
            tickFormatter={formatYTicks}
            domain={yAxisDomain}
            stroke={GRAY_3}
            fontSize={12}
            axisLine={AXIS_LINE_STYLE}
          />
          <Area
            type="stepAfter"
            stackId={1}
            stroke={DV_BLUE_2}
            fill={DV_BLUE_2}
            fillOpacity={0.2}
            animationDuration={375} //ms
            dataKey={unitCountDataKey}
            className="chromatic-ignore"
          />
          <ReferenceLine
            x={refLineDate}
            stroke={GRAY_1}
            strokeDasharray={hasData ? "3 3" : "2 1000"}
            className="chromatic-ignore"
          >
            <Label
              fontSize={12}
              position="bottom"
              offset={10}
              className="chromatic-ignore"
            >
              Now
            </Label>
          </ReferenceLine>
          <Tooltip
            cursor={false}
            content={
              <TotalEquityTimelineChartTooltip
                equityHoldings={equityHoldings}
                sharePrice={sharePrice}
              />
            }
            wrapperStyle={TOOLTIP_WRAPPER_STYLE}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

TotalEquityTimelineChart.fragments = {
  equityHoldings: gql`
    ${TotalEquityTimelineChartTooltip.fragments.equityHoldings}
    fragment TotalEquityTimelineChart_equityHoldings on EquityHoldings {
      vestingInformation {
        id
        vestedUnits
        unvestedUnits
        vestEvents {
          id
          grantName
          unitCount
          vestingDate
          grant {
            id
            vestingStartDate
          }
        }
      }
      ...TotalEquityTimelineChartTooltip_equityHoldings
    }
  `,
};

const formatYTicks = (count: number) =>
  formatNumeral(count, {
    notation: "compact",
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 3,
  });

const formatXTicks = (d: number) => {
  const date = LuxonDateTime.fromMillis(d);

  return date.equals(date.startOf("year")) ? date.toFormat("yyyy") : "";
};
