import { ButtonBase, ButtonBaseProps, makeStyles } from "@material-ui/core";
import { ElementType } from "react";
import { GRAY_6, GRAY_8, PURPLE_1, WHITE } from "../../theme";
import { AssembleTypography } from "../AssembleTypography";

const useStyles = makeStyles((theme) => ({
  box: {
    width: "100%",
    maxWidth: "30rem",
    textAlign: "center",
    display: "block",
    background: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px",
    paddingBlock: theme.spacing(4),
    paddingInline: theme.spacing(2),

    "&:focus-within": {
      background: WHITE,
      borderColor: PURPLE_1,
    },

    "&:hover": {
      background: GRAY_8,
    },

    "&:active": {
      background: WHITE,
      borderColor: PURPLE_1,
    },
    "&:disabled": {
      cursor: "default",
      background: GRAY_8,
      border: `1px solid ${GRAY_6}`,
    },
  },
  label: {
    margin: theme.spacing(1, 0, 0.5, 0),
  },
}));

/* Extend the types of ButtonBase, so that we can support any props that
 * ButtonBase can while still taking advantage of our handful of structured
 * props.
 */
type OwnProps = {
  svg: React.ReactNode;
  label: string;
  sublabel?: string;
  children?: React.ReactNode;
};

export const CardButton = <D extends ElementType>({
  svg,
  label,
  sublabel,
  className,
  children,
  ...rest
}: ButtonBaseProps<D, OwnProps>): JSX.Element => {
  const classes = useStyles();

  return (
    <ButtonBase {...rest} className={`${classes.box} ${className ?? ""}`}>
      {svg}
      <AssembleTypography className={classes.label} color="textPrimary">
        {label}
      </AssembleTypography>
      {sublabel !== undefined && (
        <AssembleTypography variant="productSmall" color="textSecondary">
          {sublabel}
        </AssembleTypography>
      )}
      {children}
    </ButtonBase>
  );
};
