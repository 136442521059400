/*
  The Action Bar sits below the page header, and typically contains one or more
  buttons floating on the right, and filters or select menus on the left.
*/

import { makeStyles } from "@material-ui/core";
import { ReactNode } from "react";
import { WHITE } from "../../theme";

type Props = {
  slotLeft?: ReactNode;
  slotRight?: ReactNode;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1.5, 2),
    height: "3.5rem",
    backgroundColor: WHITE,
  },
  left: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    flex: 1,
  },
  right: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
}));

export function ActionBar({ slotLeft, slotRight }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.left}>{slotLeft}</div>
      <div className={classes.right}>{slotRight}</div>
    </div>
  );
}
