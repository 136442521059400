import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const ApprovalIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Approval"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M4.125 13.4318V4.875C4.125 4.67609 4.20402 4.48532 4.34467 4.34467C4.48532 4.20402 4.67609 4.125 4.875 4.125H19.125C19.3239 4.125 19.5147 4.20402 19.6553 4.34467C19.796 4.48532 19.875 4.67609 19.875 4.875V19.125C19.875 19.3239 19.796 19.5147 19.6553 19.6553C19.5147 19.796 19.3239 19.875 19.125 19.875H12.7159"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M12 14.25L6 20.25L3 17.25"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
