import { gql, useQuery } from "@apollo/client";
import { LinearProgress } from "@material-ui/core";
import { TotalEquitySliderLoadingBoundaryQuery } from "../../__generated__/graphql";
import { TotalEquitySliderProvider } from "./TotalEquitySliderContext";

type Props = { children: React.ReactNode };

export function TotalEquitySliderLoadingBoundary({
  children,
}: Props): JSX.Element {
  const { data, loading } = useQuery<TotalEquitySliderLoadingBoundaryQuery>(
    TotalEquitySliderLoadingBoundary.query
  );

  if (!data) {
    return loading ? <LinearProgress /> : <div>Error</div>;
  }

  return (
    <TotalEquitySliderProvider portalConfig={data.portalConfig}>
      {children}
    </TotalEquitySliderProvider>
  );
}

TotalEquitySliderLoadingBoundary.query = gql`
  ${TotalEquitySliderProvider.fragments.portalConfig}
  query TotalEquitySliderLoadingBoundaryQuery {
    portalConfig {
      id
      ...TotalEquitySliderProvider_portalConfig
    }
  }
`;
