import { gql } from "@apollo/client";
import { Money, moneyComparator, zero } from "@asmbl/shared/money";
import {
  caseInsensitiveComparator,
  contramap,
  nativeComparator,
} from "@asmbl/shared/sort";
import {
  makeStyles,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
} from "@material-ui/core";
import { useMemo } from "react";
import { Column, useTable } from "react-table";
import { theme } from "../../../../theme";
import {
  TotalEquityTable_equityGrant as Grant,
  TotalEquityTable_equityHoldings as EquityHoldings,
} from "../../../../__generated__/graphql";
import { useSort } from "../../../SortableTable";
import { DateCell } from "../../../Table/DateCell";
import { GrossValueCell } from "../../../Table/GrossValueCell";
import { TruncatedNameCell } from "../../../Table/TruncatedNameCell";
import { UnitCell } from "../../../Table/UnitCell";
import { VestingPercentageCell } from "../../../Table/VestingPercentageCell";
import { WireTable } from "../../../Table/WireTable/WireTable";
import { WireTableHead } from "../../../Table/WireTable/WireTableHead";
import { WireTableHeaderCell } from "../../../Table/WireTable/WireTableHeaderCell";
import { EquityTableChartRow } from "./TotalEquityTableChartRow";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(3),
  },
  table: {
    borderRadius: "8px",
  },
  cell: {
    height: theme.spacing(7),
  },
  headerCell: {
    height: "2rem",
  },
});

type Props = {
  grants: Grant[];
  valuationCurrency: EquityHoldings["valuationCurrency"];
  sharePrice: Money | undefined;
};

export type C = Column<Grant> & {
  id: keyof Grant;
  align?: TableCellProps["align"];
};

export function TotalEquityTable({
  grants,
  sharePrice,
  valuationCurrency,
}: Props): JSX.Element {
  const classes = useStyles();

  const columns: C[] = useMemo(
    () => [
      {
        id: "name",
        Header: "Grant",
        Cell: TruncatedNameCell,
        accessor: "name",
      },
      {
        id: "awardType",
        Header: "Award Type",
        accessor: "awardType",
      },
      {
        id: "issueDate",
        Header: "Issue Date",
        Cell: DateCell,
        accessor: "issueDate",
      },
      {
        id: "vestingStartDate",
        Header: "Vest Start",
        Cell: DateCell,
        accessor: "vestingStartDate",
      },
      {
        id: "vestingScheduleDescription",
        Header: "Vesting Schedule",
        accessor: "vestingScheduleDescription",
      },
      {
        id: "unitCount",
        Header: "Units",
        Cell: UnitCell,
        accessor: "unitCount",
        align: "right",
      },
      {
        id: "grossValue",
        Header: "Est. Gross Value",
        Cell: GrossValueCell,
        accessor: "grossValue",
        align: "right",
      },
      {
        id: "vestingInformation",
        Header: "% Vested",
        Cell: VestingPercentageCell,
        accessor: "vestingInformation",
      },
    ],
    []
  );

  const filteredColumns = useMemo(() => columns, [columns]);

  const memoizedData = useMemo(() => grants, [grants]);

  const {
    sortedArray: sortedGrants,
    orderBy,
    order,
    handleRequestSort,
  } = useSort<Grant>(memoizedData, "issueDate", "desc", {
    price: contramap((grant: Grant) => grant.price, moneyComparator),
    grossValue: contramap(
      (grant) => grant.grossValue ?? zero(valuationCurrency.code),
      moneyComparator
    ),
    vestingInformation: contramap(
      (grant) => grant.vestingInformation?.percentVested ?? 0,
      nativeComparator
    ),
    awardType: contramap(
      (grant) => grant.awardType ?? "",
      caseInsensitiveComparator
    ),
  });

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({ columns: filteredColumns, data: sortedGrants });

  return (
    <div className={classes.root}>
      <WireTable {...getTableProps()} className={classes.table}>
        <WireTableHead>
          <TableRow>
            {headers.map((header) => {
              const col = columns.find((c) => c.id === header.id) ?? columns[0];
              return (
                <WireTableHeaderCell<Grant>
                  align={col.align}
                  cellTitle={header.render("Header")}
                  orderByField={col.id}
                  order={order}
                  isSelected={orderBy === header.id}
                  handleRequestSort={handleRequestSort}
                  {...header.getHeaderProps()}
                  key={header.getHeaderProps().key}
                  className={classes.headerCell}
                />
              );
            })}
            <TableCell className={classes.headerCell} />
          </TableRow>
        </WireTableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            const rowKey = row.getRowProps().key;
            return (
              <EquityTableChartRow
                key={rowKey}
                columns={columns}
                row={row}
                index={index}
                sharePrice={sharePrice}
                valuationCurrency={valuationCurrency}
              />
            );
          })}
        </TableBody>
      </WireTable>
    </div>
  );
}

TotalEquityTable.fragments = {
  equityGrant: gql`
    ${EquityTableChartRow.fragments.equityGrant}
    fragment TotalEquityTable_equityGrant on EquityGrant {
      id
      name
      issueDate
      vestingStartDate
      vestingScheduleDescription
      unitCount
      price
      awardType
      grossValue
      vestingInformation {
        vestedUnits
        unvestedUnits
        percentVested
      }
      ...EquityTableChartRow_equityGrant
    }
  `,
  equityHoldings: gql`
    ${EquityTableChartRow.fragments.equityHoldings}
    fragment TotalEquityTable_equityHoldings on EquityHoldings {
      id
      ...EquityTableChartRow_equityHoldings
    }
  `,
};
