import { gql } from "@apollo/client";
import { makeStyles, Typography } from "@material-ui/core";
import {
  BioHeading_employee,
  BioHeading_equityHoldings as EquityHoldings,
} from "../../__generated__/graphql";
import { UserAvatar } from "../UserAvatar";
import { BioHeadingBanner } from "./BioHeadingBanner";

type Props = {
  employee: BioHeading_employee;
  equityHoldings?: EquityHoldings;
  showBanner?: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "start",
  },
  employeeInfo: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  employeeHeader: {
    display: "flex",
    flexDirection: "column",
  },
}));

export function BioHeading({
  employee,
  equityHoldings,
  showBanner = false,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.employeeInfo}>
        <UserAvatar
          size="medium"
          displayName={employee.displayName}
          photoURL={employee.user ? employee.user.photoURL : null}
        />
        <div className={classes.employeeHeader}>
          <Typography data-cy="display-name" component="h3" variant="h2">
            {employee.displayName}
          </Typography>
          <Typography data-cy="job-title" variant="body1">
            {employee.activeEmployment?.jobTitle ?? "-"}
          </Typography>
          <a
            href={`mailto:${employee.email}`}
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
          >
            <Typography data-cy="email" variant="body2">
              {employee.email}
            </Typography>
          </a>
        </div>
      </div>
      {showBanner && <BioHeadingBanner equityHoldings={equityHoldings} />}
    </div>
  );
}

BioHeading.fragments = {
  employee: gql`
    fragment BioHeading_employee on Employee {
      displayName
      email
      user {
        id
        photoURL
      }
      activeEmployment {
        id
        jobTitle
      }
    }
  `,
  equityHoldings: gql`
    ${BioHeadingBanner.fragments.equityHoldings}
    fragment BioHeading_equityHoldings on EquityHoldings {
      id
      ...BioHeadingBanner_equityHoldings
    }
  `,
};
