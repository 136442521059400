import { makeStyles } from "@material-ui/core";
import { ReactNode } from "react";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "stretch",
  },
}));
/**
 * A component that orients BubbleInfo components into an evenly spaced row.
 * This almost doesn't seem worthy of being its own component, but it will
 * help enforce that the spacing is consistent across different views.
 */
export function BubbleRow({ children }: { children: ReactNode }) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}
