import {
  CashBandName,
  CurrencyCode,
  EquityBandName,
} from "@asmbl/shared/constants";
import { Currency } from "@asmbl/shared/currency";
import { calculateCompaRatio, Money } from "@asmbl/shared/money";
import { Box, makeStyles, Theme, Tooltip } from "@material-ui/core";
import { formatNumber } from "accounting";
import { InfoIcon } from "src/components/AssembleIcons/Brand/InfoIcon";
import { getSalaryBand } from "src/models/Band";
import { GetCompStructure, PositionType } from "../../../__generated__/graphql";
import { BenefitsPackage } from "../../../models/Benefits";
import { getSimpleCashLabel } from "../../../models/Currency";
import { exchangeRate } from "../../../models/Money";
import { ComputedOfferedComp } from "../../../models/Offer";
import { GRAY_1, GRAY_3, GRAY_4, GRAY_6, theme, WHITE } from "../../../theme";
import { NonNull } from "../../../utils";
import { AssembleTypography } from "../../AssembleTypography";
import { useCurrencies } from "../../CurrenciesContext";
import { OfferData } from "../types";
import { BenefitsSection } from "./BenefitsSection";
import { BreakdownCompSlider } from "./BreakdownCompSlider";
import { CashSection } from "./CashSection";
import { PositionBandData } from "./Compensation";
import { CompStatisticsView } from "./CompStatisticsView";
import { EquitySection } from "./EquitySection";

type CompStructure = NonNull<GetCompStructure["compStructure"]>;

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    background: WHITE,
    borderLeft: `1px solid ${GRAY_6}`,
    height: "100%",
    position: "fixed",
    right: 0,
    top: 0,
    transition: "all 300ms 500ms",
    width: theme.spacing(41),
    overflowY: "auto",
  },
  divider: { borderBottom: `1px solid ${GRAY_6}` },
  title: {
    color: GRAY_1,
    fontWeight: 500,
  },
}));

export type Props = {
  computedOfferedComp: ComputedOfferedComp;
  selectedCurrencyCode: CurrencyCode;
  compStructure: CompStructure;
  positionData: PositionBandData | undefined;
  data: OfferData;
  benefitsPackages: BenefitsPackage[];
  grantEquityInUnits: boolean;
  pricePerUnit: Money<CurrencyCode>;
  positionName?: string;
  showCurrentEquityValue: boolean;
  showEquityInValuationCurrency: boolean;
  canRecruitersViewAnonymizedStatistics: boolean;
  localCurrency: Currency<CurrencyCode>;
  valuationCurrency: Currency<CurrencyCode>;
  annualOrHourlySalary: Money<CurrencyCode> | undefined;
  annualSalary: Money<CurrencyCode> | undefined;
};

export const CompBreakdown = ({
  computedOfferedComp,
  selectedCurrencyCode,
  compStructure,
  positionData,
  data,
  benefitsPackages,
  positionName,
  grantEquityInUnits,
  pricePerUnit,
  showCurrentEquityValue,
  showEquityInValuationCurrency,
  canRecruitersViewAnonymizedStatistics,
  localCurrency,
  valuationCurrency,
  annualOrHourlySalary,
  annualSalary,
}: Props): JSX.Element | null => {
  const classes = useStyles();
  const { currencies } = useCurrencies();
  const bandMatch = getSalaryBand(positionData?.positionBands);

  const salaryComparatio =
    bandMatch?.annualBandPoints && annualSalary
      ? calculateCompaRatio(bandMatch.annualBandPoints, annualSalary)
      : null;

  const bandPoints =
    (showCurrentEquityValue
      ? positionData?.annualizedTotalCompensationBand
      : positionData?.annualizedTotalCashBand) ?? [];

  const equityCurrency = showEquityInValuationCurrency
    ? valuationCurrency
    : localCurrency;
  const benefitsCurrency =
    currencies.get(
      computedOfferedComp.annual.benefits.inBenefitsCurrency.currency
    ) ?? localCurrency;
  const foreignCurrencies = [equityCurrency, benefitsCurrency].filter(
    (c) => c.code !== localCurrency.code
  );

  return (
    <Box px={4} pt={12} pb={9} className={classes.drawer}>
      <Box>
        <AssembleTypography variant="h3" className={classes.title}>
          Comp Breakdown
        </AssembleTypography>
      </Box>
      <Box mb={3}>
        <AssembleTypography variant="productEyebrowSmall" textColor={GRAY_3}>
          {data.title ?? positionName}
        </AssembleTypography>
      </Box>
      {positionName !== undefined && (
        <BreakdownCompSlider
          offeredComp={computedOfferedComp.annual.cash}
          bandPoints={bandPoints}
        />
      )}
      <CashSection
        localCurrency={data.currency}
        annualOrHourlySalary={annualOrHourlySalary}
        salaryCompaRatio={salaryComparatio}
        cashBandTypes={compStructure.cashBandTypes as CashBandName[]}
        cash={data.cash}
        annualSalary={annualSalary}
        positionType={positionData?.positionType}
      />
      <Box mb={3} mt={3} className={classes.divider} />
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gridColumnGap={theme.spacing(0.5)}
        >
          <AssembleTypography variant="productSummaryHeader">
            Annual Cash OTE
          </AssembleTypography>
          <Tooltip
            title={
              "On-Target Earnings: Salary, Commission, Recurring Bonus (as applicable)"
            }
            placement="top"
          >
            <span>
              <InfoIcon color={GRAY_4} width="12px" height="12px" />
            </span>
          </Tooltip>
        </Box>

        <AssembleTypography variant="productSummaryValue">
          {getSimpleCashLabel(computedOfferedComp.annual.cash)}
        </AssembleTypography>
      </Box>

      <Box mb={3} mt={3} className={classes.divider} />

      <EquitySection
        annualSalary={annualSalary}
        pricePerUnit={pricePerUnit}
        showCurrentEquityValue={showCurrentEquityValue}
        grantEquityInUnits={grantEquityInUnits}
        equityBandTypes={compStructure.equityBandTypes as EquityBandName[]}
        equity={data.equity}
        annualOfferedEquityComp={computedOfferedComp.annual.equity}
        showEquityInValuationCurrency={showEquityInValuationCurrency}
      />

      <Box mt={3} />

      <BenefitsSection
        annualOfferedBenefitsComp={
          computedOfferedComp.annual.benefits.inBenefitsCurrency
        }
        benefits={data.benefitsPackage}
        benefitsPackages={benefitsPackages}
      />

      <Box mb={3} mt={3} className={classes.divider} />

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <AssembleTypography variant="productSummaryHeader">
          Annual Total Comp
        </AssembleTypography>
        <AssembleTypography variant="productSummaryValue">
          {getSimpleCashLabel(computedOfferedComp.annual.total)}
        </AssembleTypography>
      </Box>

      {foreignCurrencies.length > 0 && (
        <Box display="flex" flexDirection="column" mt={1}>
          {foreignCurrencies.map((foreignCurrency) => (
            <AssembleTypography
              variant="productMicrocopy"
              key={foreignCurrency.code}
            >
              {exchangeRate(foreignCurrency, localCurrency)}
            </AssembleTypography>
          ))}
        </Box>
      )}

      {data.position != null &&
        canRecruitersViewAnonymizedStatistics &&
        bandMatch && (
          <CompStatisticsView
            selectedCurrencyCode={selectedCurrencyCode}
            annualOrHourlySalary={annualOrHourlySalary}
            salaryCompaRatio={salaryComparatio}
            position={data.position}
            locationGroup={data.locationGroup}
          />
        )}

      {positionData?.positionType === PositionType.HOURLY && (
        <>
          <Box mb={3} mt={3} className={classes.divider} />

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <AssembleTypography
              variant="productMicrocopy"
              color="textSecondary"
            >
              *Annual is based on a{" "}
              {formatNumber(
                compStructure.employmentHoursPerWeek *
                  compStructure.employmentWeeksPerYear
              )}{" "}
              hour work-week.
            </AssembleTypography>
          </Box>
        </>
      )}
    </Box>
  );
};
