import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { contramap } from "@asmbl/shared/sort";
import { formatNumeral } from "@asmbl/shared/utils";
import { useCompStructure } from "src/components/CompStructureContext";
import {
  CashCompType,
  CondensedTableNewTargetRecurringBonusPercentCell2_participant as Participant,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getTotalCashAfterRecommendation } from "../../../../models/CashCompensation";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

function getBonusPercent(
  currencyCode: CurrencyCode,
  employee: Participant,
  workingHoursPerYear: number | undefined
) {
  const totalCash = getTotalCashAfterRecommendation(
    currencyCode,
    employee.subject.activeCashCompensation ?? [],
    employee.compRecommendation?.latestSubmittedItems ?? [],
    workingHoursPerYear
  );
  return (
    totalCash?.subcomponents.find(
      (c) => c.type === CashCompType.RECURRING_BONUS
    )?.percentOfSalary ?? 0
  );
}

export function CondensedTableNewTargetRecurringBonusPercentCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { defaultCurrencyCode } = useCurrencies();

  return (
    <AssembleTruncatedTypography align="right">
      {formatNumeral(
        getBonusPercent(
          defaultCurrencyCode,
          employee,
          compStructure?.workingHoursPerYear
        ) / 100,
        {
          style: "percent",
          maximumFractionDigits: 2,
        }
      )}
    </AssembleTruncatedTypography>
  );
}

CondensedTableNewTargetRecurringBonusPercentCell2.fragments = {
  participant: gql`
    fragment CondensedTableNewTargetRecurringBonusPercentCell2_participant on CompCycleParticipant {
      subject {
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          unit
          annualCashEquivalent
          hourlyCashEquivalent
          percentOfSalary
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
          recommendedPercentValue
        }
      }
    }
  `,
};

CondensedTableNewTargetRecurringBonusPercentCell2.Header =
  ColumnIdsToHeaders.get(ColumnIds.NEW_TARGET_RECURRING_BONUS_PERCENT);
CondensedTableNewTargetRecurringBonusPercentCell2.id =
  ColumnIds.NEW_TARGET_RECURRING_BONUS_PERCENT;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNewTargetRecurringBonusPercentCell2,
  Header: CondensedTableNewTargetRecurringBonusPercentCell2.Header,
  id: CondensedTableNewTargetRecurringBonusPercentCell2.id,
  width: LARGE_COL_WIDTH,
};

CondensedTableNewTargetRecurringBonusPercentCell2.column = column;
CondensedTableNewTargetRecurringBonusPercentCell2.ordering = ({
  defaultCurrencyCode,
  workingHoursPerYear,
}: {
  defaultCurrencyCode: CurrencyCode;
  workingHoursPerYear: number | undefined;
}) =>
  contramap((employee: Participant) =>
    getBonusPercent(defaultCurrencyCode, employee, workingHoursPerYear)
  );
