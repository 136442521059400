import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const EllipsisMoreIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Ellipsis More"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill"
        d="M12 16.75C11.1716 16.75 10.5 17.4216 10.5 18.25C10.5 19.0784 11.1716 19.75 12 19.75C12.8284 19.75 13.5 19.0784 13.5 18.25C13.5 17.4216 12.8284 16.75 12 16.75Z"
      />
      <path
        className="stroke fill"
        d="M11.25 11.875C11.25 11.4608 11.5858 11.125 12 11.125C12.4142 11.125 12.75 11.4608 12.75 11.875C12.75 12.2892 12.4142 12.625 12 12.625C11.5858 12.625 11.25 12.2892 11.25 11.875Z"
        strokeWidth="1.5"
      />
      <path
        className="stroke fill"
        d="M11.25 5.5C11.25 5.08579 11.5858 4.75 12 4.75C12.4142 4.75 12.75 5.08579 12.75 5.5C12.75 5.91421 12.4142 6.25 12 6.25C11.5858 6.25 11.25 5.91421 11.25 5.5Z"
        strokeWidth="1.5"
      />
    </svg>
  </ColorableSVG>
);
