import { DV_BAND_COLORS } from "../../constants";
import { DV_BLACK } from "../../theme";
import { ArrayValue } from "../../utils";
import { TimeSeriesResponse } from "../../__generated__/graphql";

type TimeSeries = TimeSeriesResponse;
type Value = ArrayValue<TimeSeries["values"]>;

interface Item {
  label: string;
  value: number;
}
export interface SeriesItem {
  name: string;
  data: {
    x: string;
    y: number;
  }[];
}

export function getPercentage(value: number, total: number): number {
  const precision = 1000;
  return Math.floor((100 * value * precision) / total) / precision;
}

export function sortCategories(items: Item[]): Item[] {
  const sortedCategories = Array.from(items);
  const omittedGroupIndex = sortedCategories.findIndex(
    (item) => item.label === "Omitted"
  );

  // if there is an Omitted category, move to the end of the array
  if (omittedGroupIndex !== -1) {
    sortedCategories.push(sortedCategories[omittedGroupIndex]);
    sortedCategories.splice(omittedGroupIndex, 1);
  }
  return sortedCategories;
}

export function createChartColors(
  hasOmittedCategory: boolean,
  chartValuesLength: number
): string[] {
  let colors: string[] = Array.from(DV_BAND_COLORS);
  if (hasOmittedCategory) {
    colors = colors.slice(0, chartValuesLength - 1);
    colors.push(DV_BLACK);
  }
  return colors;
}

/**
 * This function does a few things to format the data into an Apex series object
 * using percentages instead of item values:
 * 1. Creates a Record to calculate the total series sum for each date in the
 *    timeSeries
 * 2. Transforms the item values into percentages based on the total series sum
 *    for the item's date
 * 3. Reverses the values as Apex plots the stacked area charts from bottom to
 *    top
 */
export function formatNormalizedTimeSeriesData(
  timeSeries: TimeSeries[]
): SeriesItem[] {
  // tracks the sum for each date
  const seriesSumsByDate: Record<string, number> = {};
  const values: Value[][] = timeSeries.map(
    (series: TimeSeries) => series.values
  );
  // Goes through each item in values and updates seriesSumsByDate
  values.forEach((category: Value[]) => {
    category.forEach((dateGroup: Value) => {
      const date = dateGroup.label;
      if (seriesSumsByDate[date]) {
        seriesSumsByDate[date] += dateGroup.value;
      } else {
        seriesSumsByDate[date] = dateGroup.value;
      }
    });
  });
  // maps the data into an Apex series object, using seriesSumByDate to
  // calculate the percentage of each item value
  return timeSeries
    .map((category) => {
      return {
        name: category.label,
        data: category.values.map((item: Value) => {
          const date = item.label;
          return {
            x: date,
            y: getPercentage(item.value, seriesSumsByDate[date]),
          };
        }),
      };
    })
    .reverse();
}

/**
 * This function formats the data into an Apex series object,
 * then reverses the values as Apex plots the stacked area charts from bottom
 * to top
 */
export function formatTimeSeriesData(timeSeries: TimeSeries[]): SeriesItem[] {
  return timeSeries
    .map((category) => ({
      name: category.label,
      data: category.values.map((date) => ({
        x: date.label,
        y: date.value,
      })),
    }))
    .reverse();
}

export function formatPercent(val: number): string {
  return val.toLocaleString("en-US", {
    style: "percent",
    maximumFractionDigits: 0,
  });
}
