import { InputAdornment, makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ALL_CURRENCIES, currencyName } from "../../models/Currency";
import { GRAY_4, GRAY_5 } from "../../theme";
import { CurrencyCode } from "../../__generated__/graphql";
import { ChevronDownIcon } from "../AssembleIcons/Brand/ChevronDownIcon";
import { SearchIcon } from "../AssembleIcons/Brand/SearchIcon";
import { AssemblePopper } from "../AssemblePopper";
interface Props {
  label: string;
  value: CurrencyCode | null;
  onChange: (value: CurrencyCode | null) => unknown;
  getOptionDisabled?: (value: CurrencyCode) => boolean;
  className?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  options?: CurrencyCode[];
}

const getCurrencyDisplay = (code: CurrencyCode) =>
  `${currencyName(code)} (${code})`;

const useStyles = makeStyles(() => ({
  root: {
    "& .Mui-disabled": {
      color: GRAY_5,
    },
  },
}));
export function CurrencyField({
  label,
  value,
  onChange,
  getOptionDisabled,
  className,
  autoFocus,
  disabled = false,
  options = ALL_CURRENCIES,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Autocomplete
      PopperComponent={AssemblePopper}
      className={`${classes.root} ${className ?? ""}`}
      options={options}
      getOptionLabel={getCurrencyDisplay}
      getOptionDisabled={(option) =>
        option !== value && Boolean(getOptionDisabled?.(option))
      }
      value={value ?? undefined}
      onChange={(_, newValue) => onChange(newValue)}
      popupIcon={<ChevronDownIcon inline inherit />}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color={GRAY_4} width={"24px"} height={"24px"} />
              </InputAdornment>
            ),
          }}
          autoFocus={autoFocus}
          label={label}
        />
      )}
      disableClearable
      disabled={disabled}
    />
  );
}
