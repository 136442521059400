import { gql, useQuery } from "@apollo/client";
import { LinearProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import {
  PositionDetailLoadingBoundaryQuery,
  PositionDetailLoadingBoundaryQueryVariables,
} from "../../__generated__/graphql";
import { useCurrencies } from "../../components/CurrenciesContext";
import { NoCompStructure } from "../../components/EmptyState/EmptyState";
import {
  assertLocationSelected,
  useLocations,
} from "../../components/LocationsContext";
import { ExcludedBandNamesProvider } from "../LadderDetail/ExcludedBandNameContext";
import { PositionDetail } from "./PositionDetail";

export function PositionDetailLoadingBoundary(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { selectedCurrency } = useCurrencies();
  const { selectedLocation } = useLocations();
  assertLocationSelected(selectedLocation);

  const { data, loading, error } = useQuery<
    PositionDetailLoadingBoundaryQuery,
    PositionDetailLoadingBoundaryQueryVariables
  >(PositionDetailLoadingBoundary.query, {
    variables: {
      positionId: Number(id),
      currencyCode: selectedCurrency.code,
      marketId: selectedLocation[0].id,
      locationGroupId: selectedLocation[1].id,
    },
  });

  if (!data || !data.position) {
    if (loading) {
      return <LinearProgress />;
    }
    if (error) {
      return <p>{JSON.stringify(error)}</p>;
    }
    return <p>Not found</p>;
  }
  if (!data.compStructure) {
    return <NoCompStructure />;
  }

  return (
    <ExcludedBandNamesProvider>
      <PositionDetail
        position={data.position}
        compStructure={data.compStructure}
      />
    </ExcludedBandNamesProvider>
  );
}

PositionDetailLoadingBoundary.query = gql`
  ${PositionDetail.fragments.position}
  ${PositionDetail.fragments.compStructure}
  query PositionDetailLoadingBoundaryQuery(
    $positionId: Int!
    $currencyCode: CurrencyCode!
    $marketId: Int!
    $locationGroupId: Int
  ) {
    position(id: $positionId) {
      id
      ...PositionDetail_position
    }
    compStructure {
      id
      ...PositionDetail_compStructure
    }
  }
`;
