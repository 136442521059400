import { gql } from "@apollo/client";
import { getFormattedMonthAndDay } from "@asmbl/shared/time";
import { Dialog, DialogContent, Divider, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { PhaseAssignmentModal_employee as Employee } from "src/__generated__/graphql";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_3 } from "src/theme";
import {
  PhaseNumberComplexToggle,
  PhaseSelect,
} from "../PhaseDrawer/PhaseNumberComplexToggle";
import { usePhaseConfiguration } from "../usePhaseConfiguration";
import { EmployeeStep } from "./EmployeeStep";
import { buildEmployeeHierarchy, groupByManager } from "./utils";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    padding: theme.spacing(1),

    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  toggleWrapper: {
    width: "75%",
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
}));

type Props = {
  compCycleName: string;
  employees: Employee[];
  isOpen: boolean;
  handleClose: () => void;
  startDate: Date | undefined;
  endDate: Date | undefined;
};

export function PhaseAssignmentModal({
  compCycleName,
  employees,
  isOpen,
  handleClose,
  startDate,
  endDate,
}: Props): JSX.Element {
  const classes = useStyles();
  const groupedEmployees = groupByManager(employees);
  const { phaseConfiguration } = usePhaseConfiguration();
  const hierarchy = buildEmployeeHierarchy(groupedEmployees);
  const [selectedPhaseNumber, setSelectedPhaseNumber] =
    useState<PhaseSelect>("all");

  const isCalibrationPhaseSelected =
    phaseConfiguration.find(
      ({ phaseOrder }) => selectedPhaseNumber === phaseOrder
    )?.assigneeIds.length === 0;

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          position: "absolute",
          top: "2%",
          left: "50%",
          transform: "translate(-50%, 0)",
        },
      }}
    >
      <div className={classes.dialogHeader}>
        <div className={classes.dialogTitle}>
          <AssembleTypography variant="productSummaryValue">
            {compCycleName}
          </AssembleTypography>
          <AssembleTypography variant="productSummaryValue">
            &nbsp;|&nbsp;
          </AssembleTypography>
          <AssembleTypography variant="productParagraphMedium">
            {getFormattedMonthAndDay(startDate, true)}&nbsp;-&nbsp;
            {getFormattedMonthAndDay(endDate, true)}
          </AssembleTypography>
        </div>
        <AssembleButton
          variant="outlined"
          onClick={handleClose}
          size="small"
          label="Close"
        />
      </div>

      <Divider />

      <div className={classes.toggleWrapper}>
        <PhaseNumberComplexToggle
          totalPhases={phaseConfiguration.length}
          selectedPhaseNumber={selectedPhaseNumber}
          setSelectedPhaseNumber={setSelectedPhaseNumber}
        />
      </div>

      <DialogContent className={classes.dialogContent}>
        {isCalibrationPhaseSelected ? (
          <AssembleTypography
            variant="productParagraphMedium"
            textColor={GRAY_3}
          >
            Configuration Phase: Any Full Access user can access this phase and
            review recommendations
          </AssembleTypography>
        ) : (
          <div>
            {hierarchy.map((item) => (
              <EmployeeStep
                level={0}
                key={item.employee.id}
                employee={item.employee}
                reports={item.reports}
                selectedPhase={selectedPhaseNumber}
              />
            ))}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

PhaseAssignmentModal.fragments = {
  employee: gql`
    fragment PhaseAssignmentModal_employee on Employee2 {
      id
      managerId
      displayName
      activeEmployment {
        id
        jobTitle
      }
    }
  `,
};
