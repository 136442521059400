import { TableCell, TableCellProps } from "@material-ui/core";
import { ReactNode } from "react";
import { AssembleTruncatedTypography } from "../AssembleTruncatedTypography";

interface Props {
  className?: string;
  string1?: string;
  string2?: string;
  prefix?: ReactNode;
  textColor?: string;
}

export function DualContentCell({
  className,
  string1,
  string2,
  prefix,
  textColor = "inherit",
  ...rest
}: Props & Omit<TableCellProps, "children" | "prefix">): JSX.Element {
  return (
    <TableCell {...rest} role="gridcell" className={className}>
      {string1 === undefined || string2 === undefined ? (
        <AssembleTruncatedTypography textColor={textColor}>
          -
        </AssembleTruncatedTypography>
      ) : (
        <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          {prefix !== undefined && prefix}
          <div>
            <AssembleTruncatedTypography textColor={textColor}>
              {string1}
            </AssembleTruncatedTypography>
            <AssembleTruncatedTypography textColor={textColor}>
              {string2}
            </AssembleTruncatedTypography>
          </div>
        </div>
      )}
    </TableCell>
  );
}
