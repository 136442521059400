import { gql } from "@apollo/client";
import { PrimaryRoleName } from "@asmbl/shared/permissions";
import { Box } from "@material-ui/core";
import { POSITION_FIELDS_MINIMAL } from "../../../fragments";
import { UserPermissions } from "../../../models/UserPermissions";
import { capitalize } from "../../../utils";
import {
  ReportsSection_organization as Organization,
  ReportsSection_reports as Report,
  TeamCompensationAccessType,
} from "../../../__generated__/graphql";
import { AssembleLink } from "../../AssembleLink";
import FormHeader from "../FormHeader";
import { DisabledWarningBanner } from "./DisabledWarningBanner";
import { ReportsSectionTable } from "./ReportsSectionTable";

type Props = {
  userName: string | null;
  userPermissions: UserPermissions;
  reports?: Report[];
  organization: Organization;
  isDisabled: boolean;
};

export function ReportsSection({
  userName,
  userPermissions,
  reports = [],
  organization,
  isDisabled,
}: Props): JSX.Element {
  const hasReports = reports.length > 0;
  const canManagersSeeCompensation =
    organization.permissionSettings.teamCompensationAccess !==
    TeamCompensationAccessType.NONE;
  const hasFullAccess =
    userPermissions.roleName === PrimaryRoleName.FULL_ACCESS;

  return (
    <Box px={5} py={4}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <FormHeader
          description={
            <>
              {hasReports ? (
                <>
                  The following people report to {userName ?? "this person"} in
                  your HRIS.
                </>
              ) : (
                <>No one reports to {userName ?? "this person"} in your HRIS.</>
              )}
              <br />
              {hasFullAccess ? (
                <>
                  {capitalize(userName ?? "This person")} can see both cash
                  compensation data and equity compensation data for{" "}
                  <b>all employees</b> because their Primary Access Role is{" "}
                  <b>Full Access</b>.
                </>
              ) : hasReports ? (
                canManagersSeeCompensation ? (
                  <>
                    Your current{" "}
                    <AssembleLink to="/settings/policies" variant="body2">
                      Access Policy
                    </AssembleLink>{" "}
                    allows managers to see{" "}
                    {organization.permissionSettings.teamCompensationAccess ===
                    TeamCompensationAccessType.INDIRECT_REPORTS_CASH_AND_EQUITY
                      ? "cash compensation data and equity"
                      : "cash"}{" "}
                    compensation data for their reports.
                  </>
                ) : (
                  <>
                    Your current{" "}
                    <AssembleLink to="/settings/policies" variant="body2">
                      Access Policy
                    </AssembleLink>{" "}
                    means {userName ?? "this person"} <b>can’t see</b> cash
                    compensation data nor equity compensation data for their
                    reports.
                  </>
                )
              ) : null}
            </>
          }
          header={<Box display="flex">Employee Compensation</Box>}
          disabled={isDisabled}
        />
      </Box>
      {hasReports && (
        <>
          <DisabledWarningBanner
            userIsDisabled={isDisabled}
            userName={userName}
          />
          <ReportsSectionTable
            managerName={userName}
            reports={reports}
            teamCompensationAccess={
              organization.permissionSettings.teamCompensationAccess
            }
            hasFullAccess={hasFullAccess}
          />
        </>
      )}
    </Box>
  );
}

ReportsSection.fragments = {
  reports: gql`
    ${POSITION_FIELDS_MINIMAL}
    ${ReportsSectionTable.fragments.reports}
    fragment ReportsSection_reports on Employee {
      id
      displayName
      activeEmployment {
        id
        position {
          ...PositionFieldsMinimal
        }
      }
      ...ReportsSectionTable_reports
    }
  `,
  organization: gql`
    fragment ReportsSection_organization on Organization {
      id
      permissionSettings {
        id
        teamCompensationAccess
      }
    }
  `,
};
