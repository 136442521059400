import { gql } from "@apollo/client";
import { memo } from "react";
import {
  YourTeamView_employee as Employee,
  Employee2,
  Employee2sMeta,
  Noun,
  Position,
  PositionFieldsMinimal,
} from "../../../__generated__/graphql";
import { useAuth } from "../../../components/Auth/AuthContext";
import { useEmployeeDetail } from "../EmployeeDetailDrawer/EmployeeDetailDrawer";
import { FilterStateManager } from "../FilterByStateManager/FilterStateManager2";
import { ReportsTable } from "./ReportsTable";
interface Props {
  reports: Employee2[];
  dropdownOptions: Omit<Employee2sMeta, "counts">;
  positions: Position[];
  isLoading: boolean;
  totalCount: number;
}

const MemoizedYourTeamView = memo(function YourTeamView({
  reports,
  dropdownOptions,
  positions,
  isLoading,
  totalCount,
}: Props): JSX.Element {
  const { permissions } = useAuth();
  const employeeDetailId = useEmployeeDetail();
  const hasCompBandAccess = permissions.hasCompensationAccess();

  return (
    <FilterStateManager employees={reports} dropdownOptions={dropdownOptions}>
      {(filteredReports) => (
        <ReportsTable
          reports={filteredReports as unknown as Employee[]}
          employeeDetailId={employeeDetailId}
          canUserLevel={permissions.canEditGlobal(Noun.Employee)}
          hasCompBandAccess={hasCompBandAccess}
          isLoading={isLoading}
          positions={positions as unknown as PositionFieldsMinimal[]}
          totalCount={totalCount}
        />
      )}
    </FilterStateManager>
  );
});

export const YourTeamView = Object.assign(MemoizedYourTeamView, {
  fragments: {
    employee: gql`
      ${ReportsTable.fragments.employee}
      fragment YourTeamView_employee on Employee2 {
        id
        ...ReportsTable_employee
      }
    `,
  },
});
