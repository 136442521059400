import { gql, useQuery } from "@apollo/client";
import { mapMaybe } from "@asmbl/shared/utils";
import { memo } from "react";
import { ApprovalsOrganizationChartLoadingBoundaryQuery } from "src/__generated__/graphql";
import {
  CompCycleOrganizationChartSelectionProvider,
  CompCycleOrganizationChartViewsProvider,
} from "src/components/CompCycleOrganizationChart/CompCycleOrganizationChartContext";
import {
  CompCycleNoPhases,
  NoOrgHierarchy,
} from "src/components/EmptyState/EmptyState";
import { ErrorView } from "src/components/ErrorView";
import { findMissingNodes } from "src/components/OrganizationHierarchy/shared";
import { ApprovalSettings } from "./ApprovalSettings";
import { ApprovalSettingsChartLoader } from "./ApprovalSettingsChartLoader";

type Props = {
  compCycleId: number;
};

const NoPhases = memo(CompCycleNoPhases);
const Loading = memo(ApprovalSettingsChartLoader);

export function ApprovalsOrganizationChartLoadingBoundary({
  compCycleId,
}: Props): JSX.Element {
  const { data, loading, error } =
    useQuery<ApprovalsOrganizationChartLoadingBoundaryQuery>(
      ApprovalsOrganizationChartLoadingBoundary.query,
      {
        variables: { compCycleId },
      }
    );

  if (!data) {
    return loading ? <Loading /> : <ErrorView text={error?.message} />;
  }

  if (data.compCyclePhase2s.length === 0) {
    return <NoPhases />;
  }
  const phases = mapMaybe(data.compCyclePhase2s, (phase) => phase);
  const hasInactiveManagers = findMissingNodes(data.employee2s.employees);

  if (hasInactiveManagers.length > 0) {
    const affectedIds = hasInactiveManagers.map((emp) => emp.id).join(", ");
    return <NoOrgHierarchy missingCopy={affectedIds} />;
  }

  return (
    <CompCycleOrganizationChartSelectionProvider>
      <CompCycleOrganizationChartViewsProvider>
        <ApprovalSettings
          employees={data.employee2s.employees}
          phases={phases}
          compCycleId={compCycleId}
        />
      </CompCycleOrganizationChartViewsProvider>
    </CompCycleOrganizationChartSelectionProvider>
  );
}

ApprovalsOrganizationChartLoadingBoundary.query = gql`
  ${ApprovalSettings.fragments.employee}
  ${ApprovalSettings.fragments.compCyclePhase}
  query ApprovalsOrganizationChartLoadingBoundaryQuery($compCycleId: Int!) {
    employee2s {
      employees {
        id
        ...ApprovalSettings_employee
      }
    }
    compCyclePhase2s(compCycleId: $compCycleId) {
      id
      ...ApprovalSettings_compCyclePhase
    }
  }
`;
