import { gql } from "@apollo/client";
import { WHITE } from "../../../../theme";
import { UnmappedLocationsBanner_locations as Location } from "../../../../__generated__/graphql";
import { AssembleLink } from "../../../AssembleLink";
import { AssembleTypography } from "../../../AssembleTypography";
import { DismissableInfoBanner } from "../../../InfoBanner";

type Props = { locations: Location[] };

export function UnmappedLocationsBanner({
  locations,
}: Props): JSX.Element | null {
  const hasUnmappedLocations = locations.some(
    (location) => location.locationGroup == null
  );

  return hasUnmappedLocations ? (
    <DismissableInfoBanner centeredClose level="info-gradient">
      <AssembleTypography variant="productSmallBold" textColor={WHITE}>
        There are locations from your HRIS that are unmapped.
        <AssembleLink
          to="/settings/locations"
          variant="productSmallBold"
          textColor={WHITE}
          color="inherit"
          underline="always"
        >
          Visit your Location Groups to assign locations.
        </AssembleLink>
      </AssembleTypography>
    </DismissableInfoBanner>
  ) : null;
}

UnmappedLocationsBanner.fragments = {
  locations: gql`
    fragment UnmappedLocationsBanner_locations on Location {
      id
      locationGroup {
        id
      }
    }
  `,
};
