import { OrgChart } from "d3-org-chart";
import { ReactNode, useMemo } from "react";
import { CompCycleOrganizationChart_employee as Employee } from "src/__generated__/graphql";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { COMP_CYCLE_HIERARCHY_CONFIGURATION_SIDEBAR_WIDTH } from "src/pages/CompCycle/HierarchyConfiguration/CompCycleHierarchyConfigurationSidebar";
import { CreatePhasesModal } from "src/pages/CompCycle/HierarchyConfiguration/CreatePhasesModal";
import { SIDEBAR_WIDTH } from "src/theme";
import { pixelsToNumber, remToPixels } from "src/utils";
import {
  CompCycleData,
  CompCycleDataChangeHandler,
} from "../CompCycle/CompCycleWizard/types";
import { PhaseSelectionButtonBar } from "./PhaseSelectionButtonBar";

type Props = {
  onConfigureHome: () => void;
  handleChange: CompCycleDataChangeHandler;
  wizardData: CompCycleData;
  children: (orgChart: OrgChart<Employee>) => ReactNode;
};

export function CompCycleOrganizationChartView({
  onConfigureHome,
  handleChange,
  wizardData,
  children,
}: Props): JSX.Element {
  const { width, height } = useWindowDimensions();

  const chart = useMemo(() => new OrgChart<Employee>(), []);

  const sidebarWidth =
    remToPixels(COMP_CYCLE_HIERARCHY_CONFIGURATION_SIDEBAR_WIDTH) +
    pixelsToNumber(SIDEBAR_WIDTH);

  const chartWidth = width - sidebarWidth;

  return (
    <>
      <CreatePhasesModal onBack={onConfigureHome} />
      <div style={{ width: chartWidth, height }}>
        {children(chart)}
        {
          <PhaseSelectionButtonBar
            chart={chart}
            phases={wizardData.phasesData}
            handleChange={handleChange}
          />
        }
      </div>
    </>
  );
}
