import { Link, makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import {
  GRAY_3,
  GRAY_5,
  GRAY_7,
  PRIMARY_BOX_SHADOW,
  PURPLE_1,
  WHITE,
} from "../../theme";
import { AssembleTypography } from "../AssembleTypography";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    height: "20px",
    color: GRAY_3,
    backgroundColor: WHITE,
    border: `1px solid ${GRAY_5}`,
    borderRadius: "2px",
    padding: "1px 4px",

    "&:hover": {
      color: PURPLE_1,
      borderColor: PURPLE_1,
      textDecoration: "none",
      transition: "all 125ms",
    },

    "&:focus-visible": {
      outline: "none",
      color: PURPLE_1,
      borderColor: PURPLE_1,
      border: `1px solid ${PURPLE_1}`,
      boxShadow: PRIMARY_BOX_SHADOW,
      textDecoration: "none",
      transition: "all 125ms",
    },

    "&:active": {
      backgroundColor: GRAY_7,
      boxShadow: PRIMARY_BOX_SHADOW,
    },
  },
}));

type Props = { to: string; display: React.ReactNode };

export function AssembleBreadCrumb({ to, display }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Link className={classes.root} component={RouterLink} to={to}>
      <AssembleTypography variant="productEyebrow">
        {display}
      </AssembleTypography>
    </Link>
  );
}
