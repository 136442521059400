import { makeStyles, Theme } from "@material-ui/core";
//  -- Theme constants ----------------
import {
  DV_ORANGE_2,
  GRAY_1,
  GRAY_2,
  GRAY_3,
  GRAY_4,
  GRAY_5,
  GRAY_6,
  WHITE,
} from "../../theme";

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
export const globalStyles = makeStyles((theme: Theme) => ({
  content: {
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "-0.5px",
  },
  contentSmall: {
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "-0.5px",
  },
  divider: {
    backgroundColor: GRAY_6,
    height: "1px",
    marginBottom: theme.spacing(3),
    width: "100%",
  },
  footnote: {
    color: GRAY_4,
    fontSize: "14px",
    lineHeight: "17px",
  },
  header: {
    color: GRAY_1,
    fontSize: "32px",
    fontWeight: "bold",
    lineHeight: "32px",
    letterSpacing: `-2px`,
    marginBottom: theme.spacing(5),
  },
  label: {
    fontSize: "24px",
    fontWeight: "bold",
    lineHeight: "24px",
    letterSpacing: `-1px`,
  },
  gray2: {
    color: GRAY_2,
  },
  gray3: {
    color: GRAY_3,
  },
  gray4: {
    color: GRAY_4,
  },
  gray5: {
    color: GRAY_5,
  },
  orange: {
    color: DV_ORANGE_2,
  },
}));

export const headerStyles = makeStyles((theme: Theme) => ({
  container: {
    zIndex: 100000,
  },
  header: {
    color: GRAY_1,
    fontSize: "48px",
    fontWeight: "bold",
    lineHeight: "36px",
    letterSpacing: "-3px",
    marginBottom: theme.spacing(2.5),
  },
  overline: {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "10px",
    letterSpacing: "0.5px",
    marginBottom: theme.spacing(3),
    textTransform: "uppercase",
  },
  subheader: {
    color: GRAY_4,
    fontSize: "16px",
    lineHeight: "12px",
    letterSpacing: "-0.25px",
  },
}));

export const textContentStyles = makeStyles((theme: Theme) => ({
  container: {
    color: WHITE,
    padding: `0px ${theme.spacing(8)}px`,
    width: "100%",
  },
  header: {
    fontSize: "90px",
    fontWeight: 800,
    lineHeight: "88px",
    letterSpacing: `-2px`,
    marginBottom: theme.spacing(3),
    marginTop: "-6px",
  },
  name: {
    fontSize: "46px",
    lineHeight: "34px",
    letterSpacing: "-3px",
    opacity: 0.65,
  },
}));
