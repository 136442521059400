import { gql } from "@apollo/client";
import { Currency } from "@asmbl/shared/currency";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import {
  Checkbox,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { CalendarIcon } from "src/components/AssembleIcons/Brand/CalendarIcon";
import { ClockIcon } from "src/components/AssembleIcons/Brand/ClockIcon";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { GRAY_1, GRAY_8, PURPLE_1, WHITE } from "src/theme";
import {
  LadderDetailTable_permissionSettings as PermissionSettings,
  LadderDetailTableRow_position as Position,
  PositionType,
} from "../../__generated__/graphql";
import { AssembleLink } from "../../components/AssembleLink";
import { AssembleLinkify } from "../../components/AssembleLinkify/AssembleLinkify";
import { isBandPointDefined } from "../../models/BandPoint";
import { Range } from "../../utils";
import { useTableStyles } from "../DepartmentsList/styles";
import { AnonymizedCompensationCellsLoadingBoundary } from "./AnonymizedCompensation/AnonymizedCompensationCellsLoadingBoundary";
import { LadderDetailBandCells } from "./BandVisualization/LadderDetailBandVisualizationCells";
import { useExcludedBandNames } from "./ExcludedBandNameContext";
import { LadderDetailNoAccessCells } from "./NoAccess/LadderDetailNoAccessCells";

const useStyles = makeStyles(() => ({
  positionRow: {
    height: "130px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: GRAY_8,
      color: `${PURPLE_1} !important`,
    },
    "&:hover .MuiButtonBase-root": {
      visibility: "visible",
    },
    "&:hover .MuiTypography-body2": {
      color: PURPLE_1,
    },
    "&:hover .MuiTypography-body1": {
      color: PURPLE_1,
    },
    "& .MuiButtonBase-root": {
      visibility: "hidden",
    },
    "&.MuiTableRow-root.Mui-selected": {
      BorderTop: `1px solid ${PURPLE_1} !important`,
      backgroundColor: WHITE,
      "&:hover": {
        backgroundColor: GRAY_8,
      },
    },
  },
  outlineTextColor: {
    color: PURPLE_1,
  },
  linkFontColor: {
    color: GRAY_1,
  },
  positionRowCheckbox: {
    marginRight: "4px",
    "&.Mui-checked": {
      visibility: "visible",
    },
  },
  positionRowTextContainer: {
    maxHeight: "6rem",
    overflow: "hidden",

    // Multiline ellipses for modern browsers
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 4,
    "&.Mui-checked": {
      color: PURPLE_1,
    },
  },
  icon: {
    margin: "9px",
  },
}));

type Props = {
  permissionSettings: PermissionSettings;
  position: Position;
  selectedRows: Set<number>;
  isComparing: boolean;
  totalCompRange: Range;
  handleRowSelect: (positionId: number) => void;
  selectedCurrency: Currency;
  anonymizedView: boolean;
  hourly: boolean;
};

export function LadderDetailTableRow({
  permissionSettings,
  position,
  selectedRows,
  isComparing,
  totalCompRange,
  handleRowSelect,
  selectedCurrency,
  anonymizedView,
  hourly,
}: Props): JSX.Element {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const { excludedBandNames } = useExcludedBandNames();
  const { isEnabled } = useFeatureFlags();
  const { compStructure } = useCompStructure();
  const allowsHourly = Boolean(
    isEnabled(FeatureFlag.HourlyEmployees) &&
      compStructure?.allowHourlyEmployees
  );
  const [showCheckbox, setShowCheckbox] = useState(false);

  const canViewComp =
    position.adjustedCashBands !== null ||
    position.adjustedEquityBands !== null;

  const hasCashBands =
    position.adjustedCashBands !== null &&
    position.adjustedCashBands
      .filter((band) => !excludedBandNames.has(band.name))
      .some((band) => band.bandPoints.some(isBandPointDefined));
  const hasEquityBands =
    position.adjustedEquityBands !== null &&
    position.adjustedEquityBands
      .filter((band) => !excludedBandNames.has(band.name))
      .some((band) => band.bandPoints.some(isBandPointDefined));
  const emptyBands = !hasCashBands && !hasEquityBands;

  return (
    <TableRow
      className={clsx(classes.positionRow, tableClasses.row)}
      onClick={() => handleRowSelect(position.id)}
      onMouseEnter={() => setShowCheckbox(true)}
      onMouseLeave={() => setShowCheckbox(false)}
      selected={selectedRows.has(position.id)}
    >
      <TableCell padding="checkbox" align="center">
        {!allowsHourly || showCheckbox || selectedRows.has(position.id) ? (
          <Checkbox
            className={classes.positionRowCheckbox}
            color="primary"
            checked={selectedRows.has(position.id)}
            onChange={() => handleRowSelect(position.id)}
            disabled={isComparing}
            size="small"
          />
        ) : (
          <>
            {position.type === PositionType.ANNUAL && (
              <CalendarIcon inherit className={classes.icon} />
            )}
            {position.type === PositionType.HOURLY && (
              <ClockIcon inherit className={classes.icon} />
            )}
          </>
        )}
      </TableCell>
      <TableCell component="td" scope="row">
        <div
          className={clsx(classes.positionRowTextContainer, {
            [classes.outlineTextColor]: selectedRows.has(position.id),
          })}
        >
          {canViewComp ? (
            <AssembleLink
              to={`/positions/${position.id}`}
              display="inline"
              underline="none"
              className={classes.linkFontColor}
            >
              {position.name}
            </AssembleLink>
          ) : (
            <Typography display="inline" color="primary">
              {position.name}
            </Typography>
          )}
          <AssembleLinkify newTab>
            <Typography variant="body2">{position.description}</Typography>
          </AssembleLinkify>
        </div>
      </TableCell>
      <TableCell
        component="td"
        scope="row"
        align="center"
        className={clsx({
          [classes.outlineTextColor]: selectedRows.has(position.id),
        })}
      >
        <Typography>{position.level}</Typography>
      </TableCell>

      {!canViewComp || emptyBands ? (
        <LadderDetailNoAccessCells
          empty={emptyBands}
          noAccess={!canViewComp}
          anonymizedView={anonymizedView}
          totalCompRange={totalCompRange}
          position={position}
        />
      ) : anonymizedView ? (
        <AnonymizedCompensationCellsLoadingBoundary
          selectedCurrencyCode={selectedCurrency.code}
          position={position}
          totalCompRange={totalCompRange}
          permissionSettings={permissionSettings}
        />
      ) : (
        <LadderDetailBandCells
          selectedCurrency={selectedCurrency}
          position={position}
          totalCompRange={totalCompRange}
          hourly={hourly}
        />
      )}
    </TableRow>
  );
}

LadderDetailTableRow.fragments = {
  position: gql`
    ${AnonymizedCompensationCellsLoadingBoundary.fragments.position}
    ${LadderDetailNoAccessCells.fragments.position}
    ${LadderDetailBandCells.fragments.position}
    fragment LadderDetailTableRow_position on Position {
      ...AnonymizedCompensationCellsLoadingBoundary_position
      ...LadderDetailNoAccessCells_position
      ...LadderDetailBandCells_position
      id
      name
      description
      level
      type
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
      }
      adjustedEquityBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
      }
    }
  `,
  permissionSettings: gql`
    ${AnonymizedCompensationCellsLoadingBoundary.fragments.permissionSettings}
    fragment LadderDetailTableRow_permissionSettings on PermissionSettings {
      id
      ...AnonymizedCompensationCellsLoadingBoundary_permissionSettings
    }
  `,
};
