import { gql } from "@apollo/client";
import { Money } from "@asmbl/shared/money";
import { formatNumeral, round } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import { DateTime as LuxonDateTime } from "luxon";
import { useMemo } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getXTicksForGrantTimeline, getYTicks } from "../../../models/Chart";
import { DV_BLUE_2, GRAY_2, GRAY_3, GRAY_6, WHITE } from "../../../theme";
import { EquityGrantTimelineChart_equityGrant as Grant } from "../../../__generated__/graphql";
import {
  EMPTY_X_INTERVALS,
  EMPTY_Y_INTERVALS,
  formatVestingEvents,
  Y_INTERVALS,
} from "./chart";
import { EquityGrantChartTooltip } from "./EquityTable/EquityGrantChartTooltip";

const useStyles = makeStyles((theme) => ({
  chart: { marginTop: theme.spacing(3) },
  labelColor: { color: GRAY_2 },
  noDataLabel: {
    fontWeight: 450,
    fontSize: "1rem",
    lineHeight: "1.4rem",
    letterSpacing: "-0.25px",
  },
}));

const AREA_CHART_MARGIN = { right: 60, top: 15, bottom: 40 };
const LINEAR_GRADIENT_FILL = { stopColor: DV_BLUE_2, stopOpacity: 1 };
const LINEAR_GRADIENT_STOP = { stopColor: WHITE, stopOpacity: 1 };
const AXIS_LINE_STYLE = { stroke: GRAY_6 };
const TOOLTIP_WRAPPER_STYLE = { outline: "none" };

type Props = { equityGrant: Grant; sharePrice: Money | undefined };

type ChartPoint = { vestingDate: string; unitCount: number; grantName: string };

type TickProps = {
  x: number;
  y: number;
  payload: { value: number };
};

type ChartTickProps = {
  x: number;
  y: number;
  date: LuxonDateTime;
  subtitle?: string;
};
export function EquityGrantTimelineChart({
  equityGrant,
  sharePrice,
}: Props): JSX.Element {
  const classes = useStyles();
  const vestingStartDate = new Date(String(equityGrant.vestingStartDate));

  const data = useMemo(
    () =>
      formatVestingEvents(equityGrant.vestingInformation?.vestEvents ?? []).map(
        (event) => ({ ...event, unitCount: event.unitCount })
      ),
    [equityGrant]
  );

  const hasData = data.length > 0;

  const xTicks = useMemo(
    () =>
      hasData
        ? getXTicksForGrantTimeline(
            new Date(data[0].vestingDate),
            new Date(data[data.length - 1].vestingDate)
          ).map((t) => t.toMillis())
        : EMPTY_X_INTERVALS(new Date()),
    [hasData, data]
  );

  const yTicks = useMemo(
    () =>
      hasData
        ? getYTicks(
            Y_INTERVALS,
            data.map((d) => d.unitCount)
          )
        : EMPTY_Y_INTERVALS,
    [hasData, data]
  );

  const xDates = useMemo(
    () => data.map((d) => new Date(d.vestingDate).getTime()),
    [data]
  );
  const yAxisDomain = ["0", "dataMax"];
  const unitCountDataKey = (d: ChartPoint) => d.unitCount;

  const xAxisDomain = ["dataMin", "dataMax"];
  const xAxisDataKey = (d: ChartPoint) => new Date(d.vestingDate).getTime();

  const offset =
    (new Date().getTime() - xDates[0]) /
    (xDates[xDates.length - 1] - xDates[0]);

  const gradientOffset = `${isNaN(offset) ? 0 : round(offset * 100, 0)}%`;

  function createTickComponent({ x, y, payload }: TickProps) {
    const date = LuxonDateTime.fromMillis(payload.value);

    if (!hasData) {
      return <></>;
    }

    if (payload.value === vestingStartDate.getTime()) {
      return (
        <CustomizedChartTick x={x} y={y} date={date} subtitle={"Vest Start"} />
      );
    }

    const lastVestingEvent = data[data.length - 1];

    return lastVestingEvent.currentUnitCount === equityGrant.unitCount ? (
      <CustomizedChartTick x={x} y={y} date={date} subtitle={"Fully Vested"} />
    ) : (
      <CustomizedChartTick x={x} y={y} date={date} />
    );
  }

  return (
    <ResponsiveContainer
      className={classes.chart}
      minWidth={795}
      height={270}
      data-chromatic="ignore"
    >
      <AreaChart
        data={data}
        margin={AREA_CHART_MARGIN}
        data-chromatic="ignore"
        className="chromatic-ignore"
      >
        <defs>
          <linearGradient
            id={`fillColor-${equityGrant.id}`}
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop offset={gradientOffset} style={LINEAR_GRADIENT_FILL} />
            <stop offset="0%" style={LINEAR_GRADIENT_STOP} />
          </linearGradient>
        </defs>
        <CartesianGrid stroke={GRAY_6} fill={WHITE} />
        <XAxis
          dataKey={xAxisDataKey}
          type="number"
          tickLine={false}
          tick={createTickComponent}
          domain={xAxisDomain}
          fontSize={12}
          ticks={xTicks}
          axisLine={AXIS_LINE_STYLE}
          interval={0}
          data-chromatic="ignore"
        >
          {!hasData && (
            <Label
              position={"top"}
              offset={90}
              fill={GRAY_3}
              className={classes.noDataLabel}
              value="Not enough equity vesting data provided to show this report."
            />
          )}
        </XAxis>
        <YAxis
          dataKey={unitCountDataKey}
          type="number"
          ticks={yTicks}
          tickSize={8}
          tickLine={false}
          interval={0}
          tickFormatter={formatYTicks}
          domain={yAxisDomain}
          fontSize={12}
          stroke={GRAY_3}
          axisLine={AXIS_LINE_STYLE}
        />
        <Area
          type="stepAfter"
          stackId={1}
          stroke={DV_BLUE_2}
          fill={`url(#fillColor-${equityGrant.id})`}
          fillOpacity={0.2}
          animationDuration={375} //ms
          dataKey={unitCountDataKey}
          data-chromatic="ignore"
          className="chromatic-ignore"
        />
        <Tooltip
          cursor={false}
          content={
            <EquityGrantChartTooltip
              equityGrant={equityGrant}
              sharePrice={sharePrice}
            />
          }
          wrapperStyle={TOOLTIP_WRAPPER_STYLE}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

EquityGrantTimelineChart.fragments = {
  equityGrant: gql`
    ${EquityGrantChartTooltip.fragments.equityGrant}
    fragment EquityGrantTimelineChart_equityGrant on EquityGrant {
      id
      vestingStartDate
      unitCount
      vestingInformation {
        vestedUnits
        unvestedUnits
        vestEvents {
          id
          grantName
          unitCount
          vestingDate
          grant {
            id
            vestingStartDate
          }
        }
      }
      ...EquityGrantChartTooltip_equityGrant
    }
  `,
};

function CustomizedChartTick({ x, y, date, subtitle }: ChartTickProps) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={8} fill={GRAY_3} fontSize={12}>
        <tspan textAnchor="start" x="0">
          {subtitle !== undefined
            ? date.toFormat("LLL d, yyyy")
            : date.toFormat("LLL yyyy")}
        </tspan>
        {subtitle !== undefined && (
          <tspan textAnchor="start" x="0" dy={16}>
            {subtitle}
          </tspan>
        )}
      </text>
    </g>
  );
}

function formatYTicks(count: number) {
  return formatNumeral(count, {
    notation: "compact",
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 3,
  });
}
