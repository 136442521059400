/**
 * A dynamic function for setting table width. Instead of having to set static
 * variables (like above), the Table component itself can define how many
 * columns it wants to use, and then each table cell can define their width as
 * a single number.
 *
 * It rounds widths to two decimal places. If you pass in a number less than or
 * equal to zero, the resulting function will always return "0%"
 *
 * @example
 *     const getWidth = columnWidthGenerator(12)
 *     ...
 *     <WireTableCell width={getWidth(cell.width)} />
 */
export function columnWidthGenerator(
  numColumns: number
): (widthInColumns: number) => string {
  const columnCount = Math.floor(numColumns);

  if (columnCount <= 0) {
    return () => "0%";
  }
  const singleColumnWidth = 100 / numColumns;

  return (widthInColumns: number) => {
    const size = Number((singleColumnWidth * widthInColumns).toFixed(2));
    return `${size}%`;
  };
}
