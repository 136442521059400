import { PhaseAssignmentModal_employee as Employee } from "src/__generated__/graphql";

export interface EmployeeHierarchyNode {
  employee: Employee;
  reports: EmployeeHierarchyNode[];
}

export function groupByManager(
  employees: Employee[]
): Map<number | null, Employee[]> {
  const map = new Map<number | null, Employee[]>();

  employees.reduce((acc, employee) => {
    const { managerId } = employee;

    if (!map.has(managerId)) {
      map.set(managerId, []);
    }

    map.get(managerId)?.push(employee);

    return acc;
  }, {});

  return map;
}

export function buildEmployeeHierarchy(
  groupedEmployees: Map<number | null, Employee[]>,
  managerId: number | null = null
): EmployeeHierarchyNode[] {
  const groupedByManager = groupedEmployees.get(managerId);

  if (groupedByManager == null) return [];

  return groupedByManager.map((employee) => ({
    employee,
    reports: buildEmployeeHierarchy(groupedEmployees, employee.id),
  }));
}
