import { CurrencyCode } from "@asmbl/shared/constants";
import { Money, zero } from "@asmbl/shared/money";
import { makeStyles } from "@material-ui/core";
import { sortBandPoints } from "../../models/BandPoint";
import { CompSliderTooltip, Position } from "./CompSliderPopover";
import { CondensedSlider } from "./CondensedSlider";

const useStyles = (size: "default" | "wide") =>
  makeStyles(() => ({
    root: {
      display: "flex",
      justifyContent: "center",
      width: size === "wide" ? "7.5rem" : "3.75rem",
    },
  }));

export type Props = {
  bandPoints: {
    name: string;
    value: {
      annualRate: Money | null;
      hourlyRate: Money | null;
      currencyCode: CurrencyCode;
    };
  }[];
  value: Money;
  position: Position | null;
  outOfRangeStyle: "circle" | "band";
  showHourly: boolean;
  size?: "default" | "wide";
  valueLabel?: string;
};

export function CondensedBandVisualization({
  bandPoints,
  value: moneyValue,
  position,
  outOfRangeStyle,
  valueLabel,
  showHourly,
  size = "default",
}: Props): JSX.Element {
  const classes = useStyles(size)();

  const formattedBandPoints = bandPoints.map((bp) => ({
    ...bp,
    value:
      showHourly && bp.value.hourlyRate
        ? {
            ...bp.value.hourlyRate,
            value: +bp.value.hourlyRate.value.toFixed(2),
          }
        : bp.value.annualRate,
    currencyCode: bp.value.currencyCode,
  }));

  const sortedValues = sortBandPoints(formattedBandPoints);
  const [min, max] = [
    sortedValues[0].value ?? zero(sortedValues[0].currencyCode),
    sortedValues[sortedValues.length - 1].value ??
      zero(sortedValues[sortedValues.length - 1].currencyCode),
  ];
  const value = moneyValue.value;

  return (
    <div className={classes.root}>
      <CompSliderTooltip
        value={moneyValue}
        locationAdjustedBandPoints={sortedValues}
        position={position}
        valueLabel={valueLabel}
      >
        <CondensedSlider
          value={value}
          min={min.value}
          max={max.value}
          outOfRangeStyle={outOfRangeStyle}
        />
      </CompSliderTooltip>
    </div>
  );
}
