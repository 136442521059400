import { gql } from "@apollo/client";
import { makeStyles, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { WarningAlertIcon } from "src/components/AssembleIcons/Brand/WarningAlertIcon";
import { useTrack } from "../../../../analytics";
import { RED } from "../../../../theme";
import { OveragesSelector_budgetOverage as BudgetOverage } from "../../../../__generated__/graphql";
import { OveragesPopper } from "./OveragesPopper";

const useStyles = makeStyles(() => ({
  error: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    "&:hover": {
      cursor: "pointer",
    },
  },
}));

type Props = {
  compCycleId: number;
  overages: BudgetOverage[];
};

export function OveragesSelector({
  compCycleId,
  overages,
}: Props): JSX.Element | null {
  const { trackEvent } = useTrack();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    trackEvent({ object: "Invalid Budget Warning", action: "Clicked" });
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLElement>) =>
    event.key === "Enter" && setAnchorEl(anchorEl ? null : event.currentTarget);

  const title =
    `${overages.length} ` +
    `${overages.length === 1 ? "error" : "errors"} ` +
    `${overages.length === 1 ? "needs" : "need"} reconciliation`;

  return (
    <>
      <OveragesPopper
        compCycleId={compCycleId}
        overages={overages}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
      <Tooltip placement="top" title={title}>
        <div
          className={classes.error}
          onClick={handleClick}
          onKeyDown={handleEnter}
          aria-expanded={Boolean(anchorEl)}
          tabIndex={0}
          role="button"
          aria-label="Click to see your budget overages"
        >
          <WarningAlertIcon color={RED} />
        </div>
      </Tooltip>
    </>
  );
}

OveragesSelector.fragments = {
  budgetOverage: gql`
    fragment OveragesSelector_budgetOverage on BudgetOverage {
      overage
      variant
      employee {
        id
        displayName
        user {
          photoURL
        }
        activeEmployment {
          id
          jobTitle
        }
      }
    }
  `,
};
