import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  AssignEmployeeBenefits,
  AssignEmployeeBenefitsVariables,
} from "../__generated__/graphql";

/*
  This mutation assigns a particular benefits package to an Employee, to
  show in their Employee Portal. It can also unassign the package.

  This is an update to the relation between two existing objects. Furthermore,
  we do not currently provide a way to find all Employees that have a particular
  BenefitsPackage, so we only need to update the cache from the Employee side.

  For convenience, we fetch all the commonly used fields on the package.
*/
const ASSIGN_EMPLOYEE_BENEFITS = gql`
  mutation AssignEmployeeBenefits($data: [AssignEmployeeBenefitsInput!]!) {
    assignEmployeeBenefits(data: $data) {
      id
      benefitsPackage {
        id
        name
        benefits {
          id
          name
          description
        }
      }
    }
  }
`;

type Assignment = { employeeId: number; benefitsPackageId: number | null };

export function useAssignEmployeeBenefits(): (
  assignments: Assignment[]
) => Promise<unknown> {
  const [assignEmployeeBenefits] = useMutation<
    AssignEmployeeBenefits,
    AssignEmployeeBenefitsVariables
  >(ASSIGN_EMPLOYEE_BENEFITS);

  return useCallback(
    (assignments: Assignment[]) =>
      assignEmployeeBenefits({
        variables: {
          data: assignments,
        },
      }),
    [assignEmployeeBenefits]
  );
}
