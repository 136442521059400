import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const SelectFieldIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Select Field"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M10.25 7.25L8 9.75L5.75 7.25"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
