import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { Money } from "@asmbl/shared/money";
import { contramap, nativeComparator } from "@asmbl/shared/sort";
import { Box } from "@material-ui/core";
import fastDeepEqual from "fast-deep-equal";
import { useCallback, useState } from "react";
import {
  Benefit,
  BenefitsPackage,
  MAX_BENEFITS_PER_PACKAGE,
  MIN_BENEFITS_PER_PACKAGE,
} from "../../../../models/Benefits";
import { rankBetween } from "../../../../models/Rank";
import { BenefitsPackageInput } from "../../../../mutations/BenefitsPackage";
import { useCurrencies } from "../../../CurrenciesContext";
import { OrgCurrencyField } from "../../../Currency/OrgCurrencyField";
import { SaveButton } from "../../../Form/SaveButton";
import UnsavedChangesWarning from "../../../UnsavedChangesWarning";
import { BenefitsEditorHeader } from "./BenefitsEditorHeader";
import { BenefitsEditorList } from "./BenefitsEditorList";
import { EditorLayout } from "./EditorLayout";
import { PreviewButton } from "./PreviewButton";

interface Props {
  persistedBenefitsPackage?: BenefitsPackage;
  initialFormBenefitsPackage?: BenefitsPackage;
  onSubmit: (benefitsPackage: BenefitsPackageInput) => Promise<boolean>;
}

export function BenefitsEditorFormState({
  persistedBenefitsPackage,
  initialFormBenefitsPackage,
  onSubmit,
}: Props): JSX.Element {
  const { currencies, defaultCurrency } = useCurrencies();
  const [formBenefitsPackage, setFormBenefitsPackage] =
    useState<BenefitsPackage>(
      initialFormBenefitsPackage ?? {
        name: "",
        currencyCode: defaultCurrency.code,
        benefits: [{ name: "", value: null, description: "", rank: "A" }],
      }
    );

  const selectedCurrency =
    currencies.get(formBenefitsPackage.currencyCode) ?? defaultCurrency;

  const [optimisticBenefitsPackage, setOptimisticBenefitsPackage] = useState(
    persistedBenefitsPackage
  );

  const handleChangeCurrency = useCallback(
    (newCurrency: Currency) =>
      setFormBenefitsPackage(
        changeCurrencyFromTo(selectedCurrency, newCurrency)
      ),
    [selectedCurrency]
  );

  const handleAddBenefit = useCallback(() => {
    setFormBenefitsPackage(addBenefit);
  }, []);

  const handleChangeName = useCallback((benefit: Benefit, name: string) => {
    setFormBenefitsPackage(setInBenefit(benefit, "name", name));
  }, []);

  const handleChangeValue = useCallback(
    (benefit: Benefit, value: Money | null) =>
      setFormBenefitsPackage(setInBenefit(benefit, "value", value)),
    []
  );

  const handleChangeDescription = useCallback(
    (benefit: Benefit, description: string) => {
      setFormBenefitsPackage(setInBenefit(benefit, "description", description));
    },
    []
  );
  const handleChangeRank = useCallback((benefit: Benefit, rank: string) => {
    setFormBenefitsPackage(setRankInBenefit(benefit, rank));
  }, []);

  const handleDelete = useCallback((benefit: Benefit) => {
    setFormBenefitsPackage((prevState) => ({
      ...prevState,
      benefits: prevState.benefits.filter((b) => b !== benefit),
    }));
  }, []);

  const handleSubmit = useCallback(() => {
    if (!isValid(formBenefitsPackage)) {
      return Promise.resolve(false);
    }
    setOptimisticBenefitsPackage(formBenefitsPackage);
    const result = onSubmit({
      name: formBenefitsPackage.name,
      currencyCode: formBenefitsPackage.currencyCode,
      benefits: formBenefitsPackage.benefits.map(
        ({ name, value, description, rank }) => ({
          name,
          value,
          description,
          rank,
        })
      ),
    });
    return result;
  }, [formBenefitsPackage, onSubmit]);

  const doesFormDifferFromPersisted = !fastDeepEqual(
    formBenefitsPackage,
    optimisticBenefitsPackage
  );

  return (
    <>
      <UnsavedChangesWarning pageEdited={doesFormDifferFromPersisted} />
      <EditorLayout
        header={
          <BenefitsEditorHeader
            headline={
              persistedBenefitsPackage !== undefined
                ? `Edit ${persistedBenefitsPackage.name}`
                : "Create a Benefits Package"
            }
            name={formBenefitsPackage.name}
            onChangeName={(name) =>
              setFormBenefitsPackage({ ...formBenefitsPackage, name })
            }
          />
        }
        main={
          <>
            <OrgCurrencyField
              label="Package Currency"
              value={selectedCurrency}
              onChange={handleChangeCurrency}
            />
            <BenefitsEditorList
              selectedCurrency={selectedCurrency.code}
              benefitsPackage={formBenefitsPackage}
              onAddBenefit={handleAddBenefit}
              onChangeName={handleChangeName}
              onChangeValue={handleChangeValue}
              onChangeDescription={handleChangeDescription}
              onChangeRank={handleChangeRank}
              onDelete={handleDelete}
            />
          </>
        }
        footer={
          <>
            <PreviewButton benefitsPackage={formBenefitsPackage} />
            <Box display="inline-block" ml={2} />
            <SaveButton
              onSave={handleSubmit}
              disabled={
                !doesFormDifferFromPersisted || !isValid(formBenefitsPackage)
              }
            />
          </>
        }
      />
    </>
  );
}

/* Form validity and data manipulation helpers */

function isValidStringField(name: string) {
  return name !== "";
}

function isValid(
  benefitsPackage: BenefitsPackage
): benefitsPackage is BenefitsPackageInput {
  if (!isValidStringField(benefitsPackage.name)) {
    return false;
  }
  const benefits = benefitsPackage.benefits;

  if (
    benefits.length < MIN_BENEFITS_PER_PACKAGE ||
    benefits.length > MAX_BENEFITS_PER_PACKAGE
  ) {
    return false;
  }

  return benefits.every(
    (benefit) =>
      isValidStringField(benefit.name) &&
      isValidStringField(benefit.description)
  );
}

function changeCurrencyFromTo(fromCurrency: Currency, toCurrency: Currency) {
  return (prevState: BenefitsPackage): BenefitsPackage => ({
    ...prevState,
    currencyCode: toCurrency.code,
    benefits: prevState.benefits.map((benefit) => ({
      ...benefit,
      value:
        benefit.value &&
        exchangeFromTo(benefit.value, fromCurrency, toCurrency),
    })),
  });
}

function addBenefit(prevState: BenefitsPackage): BenefitsPackage {
  const numBenefits = prevState.benefits.length;
  const lastRank =
    numBenefits > 0 ? prevState.benefits[numBenefits - 1].rank : undefined;

  return {
    ...prevState,
    benefits: prevState.benefits.concat({
      name: "",
      value: null,
      description: "",
      rank: rankBetween(lastRank, undefined),
    }),
  };
}

function setInBenefit<T extends keyof Benefit>(
  benefit: Benefit,
  field: T,
  value: Benefit[T]
): (prevState: BenefitsPackage) => BenefitsPackage {
  return (prevState) => ({
    ...prevState,
    benefits: prevState.benefits.map((b) =>
      b === benefit ? { ...b, [field]: value } : b
    ),
  });
}
function setRankInBenefit(
  benefit: Benefit,
  rank: string
): (prevState: BenefitsPackage) => BenefitsPackage {
  return (prevState) => ({
    ...prevState,
    benefits: prevState.benefits
      .map((b) => (b === benefit ? { ...b, rank } : b))
      .sort(contramap((b) => b.rank, nativeComparator)),
  });
}
