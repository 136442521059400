import { gql } from "@apollo/client";
import { Grid, RadioGroup, makeStyles } from "@material-ui/core";
import NoHRISIntegrationSVG from "../../../assets/svgs/illustrations/no-hris-integration.svg?react";

import { ChangeEvent } from "react";
import {
  AutomatedManagerAccessType,
  AutomatedManagerAccessSection_mergeConnectionConfig as MergeConnectionConfig,
  PermissionSettingsInput,
} from "src/__generated__/graphql";
import { AssembleLink } from "src/components/AssembleLink";
import { AssembleTypography } from "src/components/AssembleTypography";
import { PermissionSettings } from "../../../mutations/PermissionSettings";
import { PolicyCard } from "./PolicyChoices/PolicyCard";

const useStyles = makeStyles((theme) => ({
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(0.5),
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },

  cardContainer: { marginTop: theme.spacing(1.5) },

  illustrationContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    rowGap: theme.spacing(2),
  },
  illustrationCopy: { width: "33%" },
}));

type Props = {
  current: PermissionSettings;
  handleAccessChange: (
    data: PermissionSettingsInput,
    current: PermissionSettings
  ) => Promise<void>;
  mergeConnectionConfigs: MergeConnectionConfig[];
};

export function AutomatedManagerAccessSection({
  current,
  handleAccessChange,
  mergeConnectionConfigs,
}: Props): JSX.Element {
  const classes = useStyles();
  const currentSetting = current.automatedManagerAccess;

  const handleChange = (
    _event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    void handleAccessChange(
      { automatedManagerAccess: value as AutomatedManagerAccessType },
      current
    );
  };

  const hasHRIS =
    mergeConnectionConfigs.find(
      (config) => config.integrationType.toUpperCase() === "HRIS"
    ) != null;

  return (
    <div className={classes.descriptionContainer}>
      <div className={classes.header}>
        <AssembleTypography variant="productSectionHeader">
          Manager's Permissions
        </AssembleTypography>
      </div>
      {hasHRIS ? (
        <>
          <AssembleTypography variant="formDescription">
            Configure whether you want manager permissions for bands to be
            automatically or manually configured.
          </AssembleTypography>

          <RadioGroup
            className={classes.cardContainer}
            onChange={handleChange}
            value={currentSetting}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <PolicyCard
                  isDefault
                  accessType={currentSetting}
                  value={AutomatedManagerAccessType.NONE}
                  header="Manual Configuration Only"
                  bulletPoints={[
                    <>
                      Full Access users can manually configure the comp bands
                      for each user.
                    </>,
                  ]}
                />
              </Grid>
              <Grid item xs={4}>
                <PolicyCard
                  accessType={currentSetting}
                  value={AutomatedManagerAccessType.REPORTS_CURRENT_POSITIONS}
                  header="Report's Current Positions"
                  bulletPoints={[
                    <>
                      Supervisors can see the cash bands of their direct and
                      indirect report's current positions.
                    </>,
                    <>
                      Full Access users can manually configure additional comp
                      band access for each user.
                    </>,
                  ]}
                  subtext="Reporting relationships are imported from your HRIS."
                />
              </Grid>
              <Grid item xs={4}>
                <PolicyCard
                  accessType={currentSetting}
                  value={
                    // eslint-disable-next-line max-len
                    AutomatedManagerAccessType.REPORTS_CURRENT_AND_NEXT_POSITIONS
                  }
                  header="Report's Current and Next Positions"
                  bulletPoints={[
                    <>
                      Supervisors can see the cash bands of their direct and
                      indirect report's current positions and the position(s) of
                      the next level up.
                    </>,
                    <>
                      Full Access users can manually configure additional comp
                      band access for each user.
                    </>,
                  ]}
                  subtext="Reporting relationships are imported from your HRIS."
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </>
      ) : (
        <>
          <AssembleTypography variant="formDescription">
            Reporting structure is based on the data we pull from your HRIS.
          </AssembleTypography>
          <div className={classes.illustrationContainer}>
            <NoHRISIntegrationSVG width="100%" />
            <AssembleTypography
              variant="productParagraphMedium"
              className={classes.illustrationCopy}
            >
              We're unable to detect a HRIS integration.&nbsp;
              <AssembleLink to={"/settings/data"}>
                Visit here to connect your HRIS
              </AssembleLink>
              &nbsp;or contact support if you believe this is an error.
            </AssembleTypography>
          </div>
        </>
      )}
    </div>
  );
}

AutomatedManagerAccessSection.fragment = {
  mergeConnectionConfig: gql`
    fragment AutomatedManagerAccessSection_mergeConnectionConfig on MergeConnectionConfig {
      id
      integrationType
    }
  `,
};
