import { gql, useQuery } from "@apollo/client";
import { ActivityLogLoadingBoundaryQuery } from "../../../__generated__/graphql";
import { DelayedLoadingSpinner } from "../../LoadingSpinner";
import { ActivityLogView } from "./ActivityLogView";

type Props = {
  employeeId: number;
  compCycleId: number;
  onClose: () => unknown;
};

export function ActivityLogLoadingBoundary({
  employeeId,
  compCycleId,
  onClose,
}: Props): JSX.Element {
  const { data, loading } = useQuery<ActivityLogLoadingBoundaryQuery>(
    ActivityLogLoadingBoundary.query,
    { variables: { employeeId, compCycleId } }
  );

  if (!data || !data.compCycle) {
    if (loading) {
      return <DelayedLoadingSpinner />;
    }
    return <>An error occurred</>;
  }

  return (
    <>
      <ActivityLogView
        employee={data.compCycle.participant}
        compCycle={data.compCycle}
        onClose={onClose}
      />
    </>
  );
}

ActivityLogLoadingBoundary.query = gql`
  ${ActivityLogView.fragments.employee}
  ${ActivityLogView.fragments.compCycle}
  query ActivityLogLoadingBoundaryQuery($employeeId: Int!, $compCycleId: Int!) {
    compCycle(id: $compCycleId) {
      id
      ...ActivityLogView_compCycle

      participant(id: $employeeId) {
        id
        ...ActivityLogView_employee
      }
    }
  }
`;
