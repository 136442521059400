import { useState } from "react";
import { ComplexToggle } from "../../components/ComplexToggle";
import { Paginator } from "../../components/Pagination/Paginator";
import { SearchBar } from "../../components/Search/SearchBar";
import { TableHeaderBar } from "../../components/Table/TableHeaderBar";
import { searchStringsForMatch } from "../../models/Search";
import { Offer } from "./OfferList";
import { filterActiveOffers } from "./OfferListTable";

type Props = {
  offers: Offer[];
  setDisplayedOffers: (offers: Offer[]) => unknown;
  numberOfDisplayedOffers: number;
  page: number;
  setPage: (page: number) => unknown;
  pageSize: number;
  setPageSize: (pageSize: number) => unknown;
};

type TableFilterStates = "active" | "archived" | "all";

export const OfferListTableHeaderBar = ({
  offers,
  setDisplayedOffers,
  page,
  setPage,
  pageSize,
  setPageSize,
  numberOfDisplayedOffers,
}: Props): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState("");

  const [tableFilter, setTableFilter] = useState<TableFilterStates>("active");
  const handleFilterChange = (
    filter: string,
    newSearchTerm?: string | null
  ) => {
    const currentSeachTerm = newSearchTerm ?? searchTerm;
    if (filter === "active") {
      const activeOffers = filterActiveOffers(offers);
      setDisplayedOffers(filterBySearchTerm(activeOffers, currentSeachTerm));
    } else if (filter === "archived") {
      const archivedOffers = filterArchivedOffers(offers);
      setDisplayedOffers(filterBySearchTerm(archivedOffers, currentSeachTerm));
    } else {
      setDisplayedOffers(filterBySearchTerm(offers, currentSeachTerm));
    }
    setTableFilter(filter as TableFilterStates);
    setPage(0);
  };

  const handleSearch = (searchTerm: string | null) => {
    setSearchTerm(searchTerm ?? "");
    handleFilterChange(tableFilter, searchTerm);
    setPage(0);
  };

  const toggleOptions: [TableFilterStates, string][] = [
    ["active", `Active (${filterActiveOffers(offers).length})`],
    ["archived", `Archive (${filterArchivedOffers(offers).length})`],
    ["all", `All (${offers.length})`],
  ];

  return (
    <TableHeaderBar
      slotLeft={
        <SearchBar
          searchTerm={searchTerm}
          handleSearchChange={handleSearch}
          placeholder={"Search by name"}
        />
      }
      slotCenter={
        <>
          <ComplexToggle
            options={toggleOptions}
            selected={tableFilter}
            onChange={handleFilterChange}
          />
        </>
      }
      slotRight={
        numberOfDisplayedOffers >= 10 ? (
          <Paginator
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            items={numberOfDisplayedOffers}
            compact
          />
        ) : (
          <></>
        )
      }
      inContainer
    />
  );
};

function filterBySearchTerm(
  offers: Offer[],
  searchTerm: string | null
): Offer[] {
  return searchTerm === null || searchTerm.length === 0
    ? offers.slice()
    : offers
        .slice()
        .filter((offer) =>
          searchStringsForMatch(offer.candidateName, searchTerm)
        );
}

function filterArchivedOffers(offers: Offer[]): Offer[] {
  return offers.slice().filter((x) => x.expiredAt !== null);
}
