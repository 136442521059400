import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { formatCurrency } from "@asmbl/shared/money";
import { Divider, makeStyles } from "@material-ui/core";
import { GRAY_1, GRAY_2 } from "../../../theme";
import { TotalEquitySummary_portalConfig as PortalConfig } from "../../../__generated__/graphql";
import { AssembleTypography } from "../../AssembleTypography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  header: { paddingBottom: theme.spacing(3) },
  parallel: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dividerContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  listContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(8),
  },
  list: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
  },
  valueCopy: { color: GRAY_1 },
  descriptionCopy: { color: GRAY_2 },
}));

type Props = { portalConfig: PortalConfig; valuationCurrency: CurrencyCode };

export function TotalEquitySummary({
  portalConfig,
  valuationCurrency,
}: Props): JSX.Element {
  const classes = useStyles();

  const formattedConfig: { exitOutcome: number; annotation: string }[] = [];

  portalConfig.exitOutcomes.forEach((exitOutcome: number, idx: number) => {
    formattedConfig.push({
      exitOutcome,
      annotation: portalConfig.xAxisAnnotations[idx],
    });
  });

  const outcome = (
    defaultExitOutcome: number,
    exitOutcome: number,
    annotation: string,
    index: number
  ): JSX.Element => (
    <div key={index}>
      <div className={classes.parallel}>
        <AssembleTypography variant="productSmall">
          Outcome {index}
          {defaultExitOutcome === exitOutcome ? " (Default*):" : ":"}
        </AssembleTypography>
      </div>
      <div className={classes.parallel}>
        <AssembleTypography variant="productSmall">
          {annotation}
        </AssembleTypography>
        <AssembleTypography
          className={classes.valueCopy}
          variant="productParagraphLarge"
        >
          {formatCurrency({ value: exitOutcome, currency: valuationCurrency })}
        </AssembleTypography>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.dividerContainer}>
        <Divider />
      </div>
      <div className={classes.header}>
        <AssembleTypography variant="productHeader">
          Slider settings for illustrative purposes only:
        </AssembleTypography>
      </div>
      <div className={classes.listContainer}>
        <div className={classes.list}>
          {formattedConfig.map(({ annotation, exitOutcome }, index) =>
            outcome(
              portalConfig.defaultExitOutcome,
              exitOutcome,
              annotation,
              index + 1
            )
          )}
        </div>
        <div className={classes.list}>
          <div className={classes.parallel}>
            <AssembleTypography
              className={classes.descriptionCopy}
              variant="productSmall"
            >
              Illustrate how different outcomes might change total annualized
              compensation, and the estimated value of total equity holdings.
            </AssembleTypography>
          </div>

          <div className={classes.parallel}>
            <AssembleTypography
              className={classes.descriptionCopy}
              variant="productSmall"
            >
              Set up up to 8 total custom outcomes as equity slider values, and
              choose a default one that employees will see first.
            </AssembleTypography>
          </div>

          <div className={classes.parallel}>
            <AssembleTypography
              className={classes.descriptionCopy}
              variant="productSmall"
            >
              * Default is a milestone value the slider is set to when it first
              renders.
            </AssembleTypography>
          </div>
        </div>
      </div>
    </div>
  );
}

TotalEquitySummary.fragments = {
  portalConfig: gql`
    fragment TotalEquitySummary_portalConfig on PortalConfig {
      exitOutcomes
      defaultExitOutcome
      xAxisAnnotations
    }
  `,
};
