import { Box, makeStyles, Theme } from "@material-ui/core";
import { memo } from "react";
import { Route, Routes } from "react-router-dom";
import { useTrack } from "../analytics";
import { AccessBoundary } from "../components/AccessBoundary";
import { NoFeatureAccess } from "../components/EmptyState/EmptyState";
import { AccessControl } from "../components/Settings/AccessControl/AccessControl";
import { AccessPolicies } from "../components/Settings/AccessPolicies/AccessPolicies";
import { Benefits } from "../components/Settings/Benefits/Benefits";
import { SettingsSidebar } from "../components/Settings/SettingsSidebar";
import { Noun } from "../__generated__/graphql";
import { BandPoints } from "./Settings/BandPoints";
import { Business } from "./Settings/Business";
import { Compensation } from "./Settings/Compensation";
import Currency from "./Settings/Currency";
import { DataManagement } from "./Settings/DataManagement";
import { EmployeePortalBoundary } from "./Settings/EmployeePortal/PortalAccess/EmployeePortalBoundary";
import { PortalConfigBoundary } from "./Settings/EmployeePortal/PortalConfig/PortalConfigBoundary";
import EquityDetails from "./Settings/EquityDetails";
import IllustrativeOutcomes from "./Settings/IllustrativeOutcomes";
import { LocationsLoadingBoundary } from "./Settings/Locations/LocationsLoadingBoundary";
import { MarketIdReader } from "./Settings/Locations/LocationsRouter";
import { Markets } from "./Settings/Markets/Markets";
import OfferContents from "./Settings/OfferContents";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
  },
  content: {
    flex: 1,
    padding: theme.spacing(6, 5),
    position: "relative",
  },
}));

function NoSettingsAccess({ feature }: { feature: string }) {
  return (
    <Box ml={31}>
      <NoFeatureAccess feature={feature} />
    </Box>
  );
}

export const Settings = memo(function Settings() {
  const classes = useStyles();
  const { Track } = useTrack({ area: "Settings" });

  return (
    <Track>
      <AccessBoundary
        scope="global"
        verb="edit"
        // System Admins need to see Settings, and they only have access to
        // edit Access Control. Full Access users also have CompStructure,
        // Integration, and Offers.
        noun={Noun.AccessControl}
        fallback={<NoFeatureAccess />}
      >
        <Box className={classes.container}>
          <SettingsSidebar />
          <Box className={classes.content}>
            <Routes>
              <Route
                path="business"
                element={
                  <AccessBoundary
                    verb="view"
                    scope="any"
                    every={[Noun.CompStructure]}
                    fallback={<NoSettingsAccess feature="Business Structure" />}
                  >
                    <Business />
                  </AccessBoundary>
                }
              />
              <Route
                path="policies"
                element={
                  <AccessBoundary
                    verb="view"
                    scope="any"
                    every={[Noun.AccessControl]}
                    fallback={<NoSettingsAccess feature="Access Policies" />}
                  >
                    <AccessPolicies />
                  </AccessBoundary>
                }
              />
              <Route
                path="compensation"
                element={
                  <AccessBoundary
                    verb="view"
                    scope="any"
                    every={[Noun.CompStructure]}
                    fallback={<NoSettingsAccess feature="Compensation" />}
                  >
                    <Compensation />
                  </AccessBoundary>
                }
              />
              <Route
                path="band-points"
                element={
                  <AccessBoundary
                    verb="view"
                    scope="any"
                    every={[Noun.CompStructure]}
                    fallback={<NoSettingsAccess feature="Compensation" />}
                  >
                    <BandPoints />
                  </AccessBoundary>
                }
              />
              <Route
                path="equity"
                element={
                  <AccessBoundary
                    verb="view"
                    scope="any"
                    every={[Noun.CompStructure, Noun.Offer]}
                    fallback={<NoSettingsAccess feature="Equity Details" />}
                  >
                    <EquityDetails />
                  </AccessBoundary>
                }
              />
              <Route
                path="offer-contents"
                element={
                  <AccessBoundary
                    verb="view"
                    scope="any"
                    every={[Noun.CompStructure, Noun.Offer]}
                    fallback={<NoSettingsAccess feature="Offer Contents" />}
                  >
                    <OfferContents />
                  </AccessBoundary>
                }
              />
              <Route
                path="offer-outcomes"
                element={
                  <AccessBoundary
                    verb="view"
                    scope="any"
                    every={[Noun.CompStructure, Noun.Offer]}
                    fallback={
                      <NoSettingsAccess feature="Illustrative Outcomes" />
                    }
                  >
                    <IllustrativeOutcomes />
                  </AccessBoundary>
                }
              />

              <Route path="markets">
                <Route
                  index
                  element={
                    <AccessBoundary
                      verb="view"
                      scope="any"
                      every={[
                        Noun.CompStructure,
                        Noun.CashBand,
                        Noun.EquityBand,
                      ]}
                      fallback={
                        <NoSettingsAccess feature="Markets & Locations" />
                      }
                    >
                      <Markets />
                    </AccessBoundary>
                  }
                />
                <Route
                  path=":marketId/locations"
                  element={
                    <AccessBoundary
                      verb="view"
                      scope="any"
                      every={[
                        Noun.CompStructure,
                        Noun.CashBand,
                        Noun.EquityBand,
                      ]}
                      fallback={
                        <NoSettingsAccess feature="Markets & Locations" />
                      }
                    >
                      <MarketIdReader />
                    </AccessBoundary>
                  }
                />
              </Route>

              <Route
                path="locations"
                element={
                  <AccessBoundary
                    verb="view"
                    scope="any"
                    every={[Noun.CompStructure, Noun.CashBand, Noun.EquityBand]}
                    fallback={<NoSettingsAccess feature="Location Groups" />}
                  >
                    <LocationsLoadingBoundary />
                  </AccessBoundary>
                }
              />

              <Route
                path="currencies"
                element={
                  <AccessBoundary
                    verb="view"
                    scope="any"
                    every={[Noun.CompStructure]}
                    fallback={<NoSettingsAccess feature="Currencies" />}
                  >
                    <Currency />
                  </AccessBoundary>
                }
              />
              <Route
                path="permissions"
                element={
                  <AccessBoundary
                    verb="view"
                    scope="any"
                    every={[Noun.AccessControl]}
                    fallback={<NoSettingsAccess feature="People Permissions" />}
                  >
                    <AccessControl />
                  </AccessBoundary>
                }
              />
              <Route
                path="data"
                element={
                  <AccessBoundary
                    verb="view"
                    scope="any"
                    every={[Noun.Integration]}
                    fallback={<NoSettingsAccess feature="Integrations" />}
                  >
                    <DataManagement />
                  </AccessBoundary>
                }
              />
              <Route
                path="benefits"
                element={
                  <AccessBoundary
                    verb="view"
                    scope="any"
                    every={[Noun.Offer]}
                    fallback={<NoSettingsAccess feature="Benefits" />}
                  >
                    <Benefits />
                  </AccessBoundary>
                }
              />
              <Route
                path="portal-access"
                element={
                  <AccessBoundary
                    verb="view"
                    scope="any"
                    every={[Noun.AccessControl, Noun.Employee]}
                    fallback={
                      <NoSettingsAccess feature="Employee Portal Access" />
                    }
                  >
                    <EmployeePortalBoundary />
                  </AccessBoundary>
                }
              />
              <Route
                path="portal-config"
                element={
                  <AccessBoundary
                    verb="view"
                    scope="any"
                    every={[Noun.CompStructure, Noun.Employee]}
                    fallback={
                      <NoSettingsAccess feature="Employee Portal Config" />
                    }
                  >
                    <PortalConfigBoundary />
                  </AccessBoundary>
                }
              />
            </Routes>
          </Box>
        </Box>
      </AccessBoundary>
    </Track>
  );
});
