import { Money, moneyComparator } from "@asmbl/shared/money";
import { Box, Fade, makeStyles, Tooltip } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { memo } from "react";
import { getSimpleCashLabel } from "../../models/Currency";
import { DV_ORANGE, GRAY_9, WHITE } from "../../theme";

const useStyles = makeStyles(() => ({
  band: {
    background: `linear-gradient(270deg, #DE33AB 0%, ${DV_ORANGE} 78.13%, #FFDD28 100%)`,
    borderRadius: "2px",
    display: "flex",
    flexDirection: "row",
    height: "25px",
    position: "relative",
    width: "100%",
  },
  bubble: {
    background: "transparent",
    border: `1px solid ${WHITE}`,
    borderRadius: "100%",
    boxShadow: "0px 1px 2px rgba(10, 36, 64, 0.2)",
    cursor: "pointer",
    height: "7px",
    width: "7px",
    position: "relative",
    top: "50%",
    transform: "translate(-50%, -50%)",

    "&::before, &::after": {
      backgroundColor: GRAY_9,
      bottom: "100%",
      content: '""',
      height: "10px",
      left: "50%",
      opacity: 0.6,
      position: "absolute",
      transform: "translateX(-50%)",
      width: "1px",
    },

    "&::after": {
      top: "100%",
    },
  },
  outOfRange: {},
  dot: {
    backgroundColor: WHITE,
    border: `1px solid ${DV_ORANGE}`,
    borderRadius: "9px",
    boxShadow: "0px 1px 2px rgba(10, 36, 64, 0.2)",
    height: "9px",
    marginLeft: "-4px",
    marginTop: "-8px",
    position: "relative",
    width: "9px",

    "&$outOfRange": {
      border: "1px solid black",
    },
  },
  icon: {
    fontSize: "9px",
    height: "9px",
    left: -1,
    position: "absolute",
    top: -1,
    width: "9px",
  },
  line: {
    background: GRAY_9,
    height: "100%",
    position: "absolute",
    top: 0,
    transition: "all 250ms ease",
    width: "1px",
    zIndex: 10,
  },
  offset: {
    height: "100%",
    position: "relative",
    width: "1px",
  },
}));

export type CompSliderProps = {
  data: Money[];
  value?: Money;
};

export const OfferCompSlider = memo(function OfferCompSlider({
  data,
  value: moneyValue,
}: CompSliderProps) {
  const classes = useStyles();

  const sortedValues = data.slice().sort(moneyComparator);
  const maxValue = sortedValues[sortedValues.length - 1];
  const minValue = sortedValues[0];
  const tiers = sortedValues.slice(1, -1);
  const value = moneyValue?.value;

  const outOfRange =
    value !== undefined && (value > maxValue.value || value < minValue.value)
      ? classes.outOfRange
      : "";

  const getOffset = (value: number | undefined): number => {
    if (value === undefined) return 0;

    const offset =
      ((value - minValue.value) / (maxValue.value - minValue.value)) * 100;
    if (offset > 100) return 100;
    if (offset < 0) return 0;
    return offset;
  };

  return (
    <Box className={classes.band}>
      {tiers.map((tier: Money) => (
        <Box
          key={tier.value}
          className={classes.offset}
          style={{ left: `${getOffset(tier.value)}%` }}
        >
          <Tooltip title={getSimpleCashLabel(tier)} placement="top">
            <div className={classes.bubble} />
          </Tooltip>
        </Box>
      ))}
      <Fade in={Boolean(value)}>
        <Box className={classes.line} style={{ left: `${getOffset(value)}%` }}>
          <Box className={`${classes.dot} ${outOfRange}`}>
            {value !== undefined && value > maxValue.value && (
              <Add className={classes.icon} />
            )}
            {value !== undefined && value < minValue.value && (
              <Remove className={classes.icon} />
            )}
          </Box>
        </Box>
      </Fade>
    </Box>
  );
});
