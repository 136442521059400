import { Box, makeStyles } from "@material-ui/core";
import { useRef } from "react";
import { useInView } from "react-intersection-observer";
import {
  AnonymizedCompensationStatisticsAccessType,
  PermissionSettingsInput,
  TeamCompensationAccessType,
} from "../../../../__generated__/graphql";
import { PermissionSettings } from "../../../../mutations/PermissionSettings";
import { AssembleTypography } from "../../../AssembleTypography";
import { AnonymizedCompensationPolicySection } from "../AnonymizedCompensationPolicySection";
import { AutomatedManagerAccessSectionLoadingBoundary } from "../AutomatedManagerAccessSectionLoadingBoundary";
import { CompensationPolicySection } from "../CompensationPolicySection";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
  },
}));

type Props = {
  current: PermissionSettings;
  handleAccessChange: (
    data: PermissionSettingsInput,
    current: PermissionSettings
  ) => Promise<void>;
  compensationAccessType: TeamCompensationAccessType | undefined;
  anonymizedCompensationAccessType:
    | AnonymizedCompensationStatisticsAccessType
    | undefined;
};

export function PolicyChoices({
  current,
  handleAccessChange,
  compensationAccessType,
  anonymizedCompensationAccessType,
}: Props): JSX.Element {
  const classes = useStyles();
  const pageRef = useRef<HTMLDivElement>(null);
  const { ref: anonymizedSectionRef } = useInView({ root: pageRef.current });

  return (
    <Box className={classes.root}>
      <AssembleTypography variant="h5">Your Policy Choices</AssembleTypography>

      {compensationAccessType != null && (
        <CompensationPolicySection
          handleAccessChange={handleAccessChange}
          compensationAccessType={compensationAccessType}
          current={current}
        />
      )}
      <AutomatedManagerAccessSectionLoadingBoundary
        handleAccessChange={handleAccessChange}
        current={current}
      />
      {anonymizedCompensationAccessType != null && (
        <AnonymizedCompensationPolicySection
          sectionRef={anonymizedSectionRef}
          current={current}
          handleAccessChange={handleAccessChange}
          anonymizedCompensationAccessType={anonymizedCompensationAccessType}
        />
      )}
    </Box>
  );
}
