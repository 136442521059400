import { cx } from "@emotion/css";
import { makeStyles, Tooltip } from "@material-ui/core";
import { ReactNode } from "react";
import { GRAY_2, GRAY_3 } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";

const useStyles = makeStyles(() => ({
  inherited: {
    color: GRAY_3,
    "&:hover": { color: GRAY_2 },
  },
}));
export function NodeLabel({
  className,
  children,
  isInherited,
}: {
  className?: string;
  children: ReactNode;
  isInherited: boolean;
}): JSX.Element {
  const classes = useStyles();
  return isInherited ? (
    <Tooltip
      title="This access is inherited from the All Markets policy above."
      placement="top"
    >
      <span>
        <AssembleTypography
          variant="body2"
          className={cx(classes.inherited, className)}
        >
          {children}
        </AssembleTypography>
      </span>
    </Tooltip>
  ) : (
    <span className={className}>{children}</span>
  );
}
