import { gql } from "@apollo/client";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import clsx from "clsx";
import { AssembleTypography } from "../../../../components/AssembleTypography";
import { CompCycleDisplay } from "../../../../components/CompCycle/types";
import {
  CompComponentDisplay,
  CompCycleSettings,
  CompSubComponentDisplay,
} from "../../../../models/Budget";
import { GRAY_6, GRAY_8, WHITE } from "../../../../theme";
import {
  Salary_compCycleBudget as CompCycleBudget,
  Salary_compCycleData as CompCycleData,
  Salary_manager as Manager,
  Salary_reports as Report,
} from "../../../../__generated__/graphql";
import { ReportingSalaryTier } from "./ReportingSalaryTier";

type Props = {
  rootBudget: CompCycleBudget | null;
  manager: Manager | null;
  reports: Report[];
  compCycleData: CompCycleData;
  organizationName: string;
  varianceDisplay: CompCycleDisplay;
};

const useStyles = makeStyles(() => ({
  root: {
    "& td, & th": {
      borderBottom: `1px solid ${GRAY_6}`,
      borderRight: `1px solid ${GRAY_6}`,
    },
    paddingBottom: "100px",
  },
  header: {
    backgroundColor: WHITE,
    height: "2rem",
    borderTop: `1px solid ${GRAY_6}`,
  },
  subheader: {
    backgroundColor: GRAY_8,
    height: "2rem",
  },
  groupedHeader: {
    "th&": {
      borderRight: `1px solid ${GRAY_6}`,
    },
  },
  name: { width: "20%" },
  directAndIndirect: { width: "5%" },
  scrollContainer: {
    overflowX: "auto",
    zIndex: 1,
    height: "100%",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  secondHeader: {
    top: "2rem",
    boxShadow: "0px 1px 5px rgba(10, 36, 64, 0.1)",
  },
}));

export function Salary({
  rootBudget,
  manager,
  reports,
  compCycleData,
  organizationName,
  varianceDisplay,
}: Props): JSX.Element {
  const classes = useStyles();

  const subComponentCount = findSubComponentCount(compCycleData);
  // columns should take up 6 cols i.e. half the table width and add one for
  // total salary column
  const columnWidth = `${70 / (subComponentCount + 1)}%`;
  const subHeaderColumnWidth = `${70 / (subComponentCount + 1) / 3}%`;

  return (
    <div className={classes.scrollContainer}>
      <Table className={classes.root}>
        {/* Main header */}
        <TableHead className={classes.stickyHeader}>
          <TableRow>
            <TableCell
              className={clsx(classes.header, classes.name)}
              variant="head"
            >
              <AssembleTypography variant="productTableHeader">
                Name
              </AssembleTypography>
            </TableCell>

            <TableCell
              className={clsx(
                classes.header,
                classes.groupedHeader,
                classes.directAndIndirect
              )}
              variant="head"
            >
              <AssembleTypography variant="productTableHeader">
                Direct
              </AssembleTypography>
            </TableCell>

            <TableCell
              className={clsx(classes.header, classes.directAndIndirect)}
              variant="head"
            >
              <AssembleTypography variant="productTableHeader">
                Indirect
              </AssembleTypography>
            </TableCell>

            {compCycleData.allowSalaryMarket && (
              <TableCell
                className={clsx(classes.groupedHeader, classes.header)}
                style={{ width: columnWidth }}
                colSpan={3}
                variant="head"
                align="left"
              >
                <AssembleTypography variant="productTableHeader">
                  {CompSubComponentDisplay.salaryMarket}
                </AssembleTypography>
              </TableCell>
            )}

            {compCycleData.allowSalaryPromotion && (
              <TableCell
                className={clsx(classes.groupedHeader, classes.header)}
                colSpan={3}
                style={{ width: columnWidth }}
                variant="head"
                align="left"
              >
                <AssembleTypography variant="productTableHeader">
                  {CompSubComponentDisplay.salaryPromotion}
                </AssembleTypography>
              </TableCell>
            )}

            {compCycleData.allowSalaryMerit && (
              <TableCell
                className={classes.header}
                colSpan={3}
                style={{ width: columnWidth }}
                variant="head"
                align="left"
              >
                <AssembleTypography variant="productTableHeader">
                  {CompSubComponentDisplay.salaryMerit}
                </AssembleTypography>
              </TableCell>
            )}

            {compCycleData.allowSalary && (
              <TableCell
                className={classes.header}
                colSpan={3}
                style={{ width: columnWidth }}
                variant="head"
                align="left"
              >
                <AssembleTypography variant="productTableHeader">
                  {CompComponentDisplay.salary}
                </AssembleTypography>
              </TableCell>
            )}
          </TableRow>
        </TableHead>

        {/* Subheader */}
        <TableHead className={clsx(classes.stickyHeader, classes.secondHeader)}>
          <TableRow>
            <TableCell
              className={clsx(
                classes.subheader,
                classes.groupedHeader,
                classes.name
              )}
              variant="head"
              align="left"
            />
            <TableCell
              className={clsx(
                classes.subheader,
                classes.groupedHeader,
                classes.directAndIndirect
              )}
              variant="head"
              align="left"
            />
            <TableCell
              className={clsx(classes.groupedHeader, classes.subheader)}
              variant="head"
              align="left"
            />

            {/* Market subheader cells */}
            {compCycleData.allowSalaryMarket && (
              <>
                <TableCell
                  className={clsx(classes.groupedHeader, classes.subheader)}
                  style={{ width: subHeaderColumnWidth }}
                  variant="head"
                  align="left"
                >
                  <AssembleTypography variant="productTableHeader">
                    Budget
                  </AssembleTypography>
                </TableCell>
                <TableCell
                  className={clsx(classes.groupedHeader, classes.subheader)}
                  style={{ width: subHeaderColumnWidth }}
                  variant="head"
                  align="left"
                >
                  <AssembleTypography variant="productTableHeader">
                    Request
                  </AssembleTypography>
                </TableCell>
                <TableCell
                  className={classes.subheader}
                  style={{ width: subHeaderColumnWidth }}
                  variant="head"
                  align="left"
                >
                  <AssembleTypography variant="productTableHeader">
                    Usage
                  </AssembleTypography>
                </TableCell>
              </>
            )}

            {/* Promotion subheader cells */}
            {compCycleData.allowSalaryPromotion && (
              <>
                <TableCell
                  className={clsx(classes.groupedHeader, classes.subheader)}
                  style={{ width: subHeaderColumnWidth }}
                  variant="head"
                  align="left"
                >
                  <AssembleTypography variant="productTableHeader">
                    Budget
                  </AssembleTypography>
                </TableCell>
                <TableCell
                  className={clsx(classes.groupedHeader, classes.subheader)}
                  style={{ width: subHeaderColumnWidth }}
                  variant="head"
                  align="left"
                >
                  <AssembleTypography variant="productTableHeader">
                    Request
                  </AssembleTypography>
                </TableCell>
                <TableCell
                  className={classes.subheader}
                  style={{ width: subHeaderColumnWidth }}
                  variant="head"
                  align="left"
                >
                  <AssembleTypography variant="productTableHeader">
                    Usage
                  </AssembleTypography>
                </TableCell>
              </>
            )}

            {/* Merit subheader cells */}
            {compCycleData.allowSalaryMerit && (
              <>
                <TableCell
                  className={clsx(classes.groupedHeader, classes.subheader)}
                  style={{ width: subHeaderColumnWidth }}
                  variant="head"
                  align="left"
                >
                  <AssembleTypography variant="productTableHeader">
                    Budget
                  </AssembleTypography>
                </TableCell>
                <TableCell
                  className={clsx(classes.groupedHeader, classes.subheader)}
                  style={{ width: subHeaderColumnWidth }}
                  variant="head"
                  align="left"
                >
                  <AssembleTypography variant="productTableHeader">
                    Request
                  </AssembleTypography>
                </TableCell>
                <TableCell
                  className={classes.subheader}
                  style={{ width: subHeaderColumnWidth }}
                  variant="head"
                  align="left"
                >
                  <AssembleTypography variant="productTableHeader">
                    Usage
                  </AssembleTypography>
                </TableCell>
              </>
            )}

            {/* Salary total subheader cells */}
            {compCycleData.allowSalary && (
              <>
                <TableCell
                  className={clsx(classes.groupedHeader, classes.subheader)}
                  style={{ width: subHeaderColumnWidth }}
                  variant="head"
                  align="left"
                >
                  <AssembleTypography variant="productTableHeader">
                    Budget
                  </AssembleTypography>
                </TableCell>
                <TableCell
                  className={clsx(classes.groupedHeader, classes.subheader)}
                  style={{ width: subHeaderColumnWidth }}
                  variant="head"
                  align="left"
                >
                  <AssembleTypography variant="productTableHeader">
                    Request
                  </AssembleTypography>
                </TableCell>
                <TableCell
                  className={classes.subheader}
                  style={{ width: subHeaderColumnWidth }}
                  variant="head"
                  align="left"
                >
                  <AssembleTypography variant="productTableHeader">
                    Usage
                  </AssembleTypography>
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          <ReportingSalaryTier
            manager={manager}
            reports={reports}
            rootBudget={rootBudget}
            varianceDisplay={varianceDisplay}
            organizationName={organizationName}
            compCycleData={compCycleData}
            subComponentCount={subComponentCount}
            indentation={[]}
          />
        </TableBody>
      </Table>
    </div>
  );
}

Salary.fragments = {
  manager: gql`
    ${ReportingSalaryTier.fragments.manager}
    fragment Salary_manager on Employee {
      id
      ...ReportingSalaryTier_manager
    }
  `,
  reports: gql`
    ${ReportingSalaryTier.fragments.reports}
    fragment Salary_reports on Employee {
      id
      ...ReportingSalaryTier_reports
    }
  `,
  compCycleBudget: gql`
    ${ReportingSalaryTier.fragments.compCycleBudget}
    fragment Salary_compCycleBudget on CompCycleBudget {
      ...ReportingSalaryTier_compCycleBudget
    }
  `,
  compCycleData: gql`
    ${ReportingSalaryTier.fragments.compCycleData}
    fragment Salary_compCycleData on CompCycle {
      allowSalary
      allowSalaryMarket
      allowSalaryMerit
      allowSalaryPromotion
      ...ReportingSalaryTier_compCycleData
    }
  `,
};

const findSubComponentCount = (settings: CompCycleData): number => {
  return Object.entries(settings).filter(
    ([key, value]) =>
      value === true &&
      (key === CompCycleSettings.MARKET ||
        key === CompCycleSettings.MERIT ||
        key === CompCycleSettings.PROMOTION)
  ).length;
};
