import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useState } from "react";
import { useTrack } from "src/analytics";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { AssembleTypography } from "src/components/AssembleTypography";

type Props = { compCycleId: number };

export function ApprovalSettingsModal({ compCycleId }: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const onContinueButtonClick = () => {
    trackEvent({
      object: "Edit Phase Start Dates Button",
      action: "Clicked",
      compCycleId,
    });

    setIsModalOpen(false);
  };

  return (
    <Dialog open={isModalOpen}>
      <DialogTitle>Edit Approval Phase Start Dates</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <AssembleTypography variant="productParagraphMedium">
            You are able to edit the phase start dates if a phase is currently
            active or if the start date is in the future.
          </AssembleTypography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <AssembleButton
          size="small"
          variant="contained"
          label="Okay"
          onClick={onContinueButtonClick}
        />
      </DialogActions>
    </Dialog>
  );
}
