import { makeStyles, TextField } from "@material-ui/core";
import { SearchIcon } from "src/components/AssembleIcons/Brand/SearchIcon";
import { GRAY_4 } from "src/theme";
import { usePhaseManagerSearch } from "./usePhaseManagerSearch";

const useStyles = makeStyles((theme) => ({
  icon: { paddingRight: theme.spacing(1) },
}));

export function PhaseManagerSearch(): JSX.Element {
  const classes = useStyles();
  const { setSearchTerm } = usePhaseManagerSearch();

  return (
    <TextField
      variant="outlined"
      name="name"
      fullWidth
      size="small"
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{
        startAdornment: (
          <span className={classes.icon}>
            <SearchIcon color={GRAY_4} />
          </span>
        ),
        placeholder: "Search for managers...",
      }}
    />
  );
}
