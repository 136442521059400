import {
  Box,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { SelectFieldIcon } from "src/components/AssembleIcons/Brand/SelectFieldIcon";
import { TextFieldIcon } from "src/components/AssembleIcons/Brand/TextFieldIcon";
import CaretIcon from "../../../assets/svgs/caret.svg?react";
import { CustomFieldVariant } from "../../../__generated__/graphql";
import { KeyedCustomField } from "./OfferContentsForm";

const useStyles = makeStyles(() => ({
  fieldVariantControl: {
    "& .MuiSelect-icon": {
      right: "20px",
      top: 0,
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
  },
  fieldVariantSelect: {
    width: "12rem",
  },
}));

type FilterProps = {
  field: KeyedCustomField;
  onFieldChange: (newField: KeyedCustomField) => void;
};

export function VariantSelector({
  field,
  onFieldChange,
}: FilterProps): JSX.Element {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.fieldVariantControl}>
      <Select
        classes={{ select: classes.fieldVariantSelect }}
        value={field.variant}
        style={{
          fontSize: "0.875rem",
          height: "80%",
          boxShadow: "none",
        }}
        onChange={(e) =>
          onFieldChange({
            ...field,
            variant: e.target.value as CustomFieldVariant,
            options: [],
            description: "",
          })
        }
        IconComponent={CaretIcon}
      >
        <MenuItem
          key="text"
          value={CustomFieldVariant.TEXT}
          children={
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <TextFieldIcon />
              <Box marginRight={1} />
              Text Field
            </Box>
          }
        />
        <MenuItem
          key="dropdown"
          value={CustomFieldVariant.DROPDOWN}
          children={
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <SelectFieldIcon />
              <Box marginRight={1} />
              Select Field
            </Box>
          }
        />
      </Select>
    </FormControl>
  );
}
