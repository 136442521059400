import { gql } from "@apollo/client";
import {
  Dialog,
  Divider,
  Fab,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { CompNoteModal2_employee } from "../../__generated__/graphql";
import { DV_BLUE, GRAY_1, GRAY_4, WHITE } from "../../theme";
import { CloseCircleIcon } from "../AssembleIcons/Brand/CloseCircleIcon";
import { UserAvatar } from "../UserAvatar";

type Props = {
  employee: CompNoteModal2_employee;
  onClose: () => unknown;
  children: JSX.Element;
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  paper: {
    overflow: "visible",
    padding: "0px 0px 10px 0px",
  },
  button: {
    backgroundColor: WHITE,
    boxShadow: "0px 15px 25px rgba(10, 36, 64, 0.08)",
    position: "absolute",
    top: 0,
    right: 0,
    transform: "translate(50%, -50%)",
    "&:hover": {
      backgroundColor: WHITE,
    },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    color: GRAY_1,
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "-0.25px",
    marginRight: "14px",
  },
  content: {
    border: `1px solid ${DV_BLUE}`,
    borderRadius: "5px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
    backgroundColor: DV_BLUE,
    marginLeft: theme.spacing(15),
  },
  employeeHeader: {
    marginLeft: theme.spacing(2),
  },
  displayName: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "155%",
    letterSpacing: "-0.5px",
    color: GRAY_1,
  },
  jobTitle: {
    fontSize: "14px",
    lineHeight: "155%",
    letterSpacing: "-0.5px",
    color: GRAY_4,
  },
  error: {
    display: "flex",
    flexGrow: 1,
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export function CompNoteModal2({
  children,
  employee,
  onClose,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      id="comp-note-modal"
    >
      <div className={classes.header}>
        <Typography className={classes.title}>Notes for:</Typography>
        <UserAvatar
          size="small"
          displayName={employee.displayName}
          photoURL={employee.user?.photoURL}
        />
        <div className={classes.employeeHeader}>
          <Typography className={classes.displayName}>
            {employee.displayName}
          </Typography>
          <Typography className={classes.jobTitle}>
            {employee.activeEmployment?.jobTitle ?? "-"}
          </Typography>
        </div>
      </div>

      <Fab
        centerRipple={false}
        onClick={onClose}
        size="large"
        className={classes.button}
      >
        <CloseCircleIcon color={GRAY_4} height={"24px"} width={"24px"} />
      </Fab>
      <Divider />
      {children}
    </Dialog>
  );
}

CompNoteModal2.fragments = {
  employee: gql`
    fragment CompNoteModal2_employee on Employee2 {
      id
      displayName
      user {
        id
        photoURL
      }
      activeEmployment {
        id
        jobTitle
      }
    }
  `,
};
