import {
  InputAdornment,
  makeStyles,
  Switch,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { SearchIcon } from "src/components/AssembleIcons/Brand/SearchIcon";
import { ErrorColorIcon } from "src/components/AssembleIcons/Small/ErrorColorIcon";
import { LeftAlignIcon } from "src/components/AssembleIcons/Small/LeftAlignIcon";
import { StopHandIcon } from "src/components/AssembleIcons/Small/StopHandIcon";
import { SuccessColorIcon } from "src/components/AssembleIcons/Small/SuccessColorIcon";
import { WarningColorIcon } from "src/components/AssembleIcons/Small/WarningColorIcon";
import { ZoomInIcon } from "src/components/AssembleIcons/Small/ZoomInIcon";
import { ZoomOutIcon } from "src/components/AssembleIcons/Small/ZoomOutIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { ComplexToggle } from "src/components/ComplexToggle";
import { GRAY_4, GRAY_5, WHITE } from "src/theme";

type Props = {
  setNameSearch: (search: string) => unknown;
  completionFilter: string | null;
  setCompletionFilter: (value: string | null) => unknown;
  setShowRollup: (value: boolean) => unknown;
  showRollup: boolean;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 16px",
    backgroundColor: WHITE,
  },
  subContainer: {
    display: "flex",
    alignItems: "center",
  },
  searchInput: {
    borderColor: GRAY_5,
    padding: "0px",
    width: "13.25rem",
    height: "2rem",
    fontSize: "0.875rem",
  },
  searchInputIcon: {
    marginLeft: theme.spacing(1.5),
    padding: "0px",
  },
  smallGap: {
    gap: ".5rem",
  },
  mediumGap: {
    gap: ".75rem",
  },
  largeGap: {
    gap: "2rem",
  },
  toggleOption: {
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
    height: "calc(2rem - 2px)",
  },
}));

export function ManagersTableHeader({
  setNameSearch,
  setCompletionFilter,
  completionFilter,
  setShowRollup,
  showRollup,
}: Props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={clsx(classes.subContainer, classes.mediumGap)}>
        <TextField
          variant="outlined"
          placeholder="Search people"
          onChange={(e) => setNameSearch(e.target.value)}
          size="small"
          InputProps={{
            className: classes.searchInput,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  color={GRAY_4}
                  className={classes.searchInputIcon}
                />
              </InputAdornment>
            ),
          }}
        />
        <ComplexToggle
          options={[
            [
              "All",
              <div className={classes.toggleOption} key="all-toggle">
                <AssembleTypography
                  textColor={GRAY_4}
                  variant="productButtonSmall"
                >
                  All
                </AssembleTypography>
                <LeftAlignIcon />
              </div>,
            ],
            [
              "Submitted",
              <div className={classes.toggleOption} key="submitted-toggle">
                <AssembleTypography
                  textColor={GRAY_4}
                  variant="productButtonSmall"
                >
                  Submitted
                </AssembleTypography>
                <SuccessColorIcon />
              </div>,
            ],
            [
              "Incomplete",
              <div className={classes.toggleOption} key="incomplete-toggle">
                <AssembleTypography
                  textColor={GRAY_4}
                  variant="productButtonSmall"
                >
                  Incomplete
                </AssembleTypography>
                <ErrorColorIcon />
              </div>,
            ],
            [
              "Skipped",
              <div className={classes.toggleOption} key="skipped-toggle">
                <AssembleTypography
                  textColor={GRAY_4}
                  variant="productButtonSmall"
                >
                  Skipped
                </AssembleTypography>
                <WarningColorIcon />
              </div>,
            ],
          ]}
          selected={completionFilter}
          onChange={setCompletionFilter}
          forceUpperCase={false}
        />
      </div>
      <div className={clsx(classes.subContainer, classes.largeGap)}>
        <div className={clsx(classes.subContainer, classes.smallGap)}>
          <AssembleTypography textColor={GRAY_4} variant="productButtonSmall">
            Show submission flow
          </AssembleTypography>
          <Switch
            checked={showRollup}
            onChange={(_, checked) => setShowRollup(checked)}
          />
        </div>
        <div className={clsx(classes.subContainer, classes.mediumGap)}>
          <AssembleButton
            size="small"
            variant="outlined"
            label={<StopHandIcon />}
          />
          <AssembleButton
            size="small"
            variant="outlined"
            label={<ZoomOutIcon />}
          />
          <AssembleButton
            size="small"
            variant="outlined"
            label={<ZoomInIcon />}
          />
          <AssembleButton
            size="small"
            variant="outlined"
            label="Advance to Next Phase"
          />
        </div>
      </div>
    </div>
  );
}
