import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { CostToMoveTargetSelector_compStructure as CompStructure } from "src/__generated__/graphql";
import { AssembleTypography } from "src/components/AssembleTypography";
import { ComplexToggle } from "src/components/ComplexToggle";
import { GRAY_3 } from "src/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
    textTransform: "uppercase",
  },
  toggle: { height: "2rem" },
}));

type Props = {
  compStructure: CompStructure | null;
  targetBandPoint: string | null;
  setTargetBandPoint: (value: string) => void;
};

export function CorrectionCostToMoveTargetSelector({
  compStructure,
  targetBandPoint,
  setTargetBandPoint,
}: Props): JSX.Element | null {
  const classes = useStyles();
  const options = compStructure?.bandPointTypes ?? [];
  return (
    <div className={classes.root}>
      <AssembleTypography variant="productEyebrowMedium" textColor={GRAY_3}>
        Target Bandpoint
      </AssembleTypography>
      <div
        className={classes.toggle}
        data-intercom-target={"target-band-point-selector"}
      >
        <ComplexToggle
          options={options.map((option) => [option, option])}
          selected={targetBandPoint}
          onChange={setTargetBandPoint}
        />
      </div>
    </div>
  );
}

CorrectionCostToMoveTargetSelector.fragments = {
  compStructure: gql`
    fragment CorrectionCostToMoveTargetSelector_compStructure on CompStructure {
      id
      bandPointTypes
    }
  `,
};
