import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { GRAY_4 } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";
import { DismissableInfoBanner } from "../../InfoBanner";

const useStyles = makeStyles((theme) => ({
  atsText: {
    fontSize: "0.875rem",
    lineHeight: "155%",
    letterSpacing: "-0.25px",
    color: GRAY_4,
    marginTop: theme.spacing(1),
  },
  button: {
    fontSize: "0.875rem",
    padding: "0px 3px",
    paddingLeft: "0px",
    paddingBottom: "2px",
  },
  headerTextContainer: {
    display: "flex",
    flexDirection: "column",
  },
  headerTextRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

/**
 * @param {hasAtsIntegration} If this is true, we show helper text for selecting
 * from your ATS. If this is false, we show a banner encouraging you to set up
 * your ATS. If this is undefined, we show nothing, because that means this
 * value is still being loaded from the DB.
 */
type Props = {
  positionName?: string;
  marketName: string | undefined;
  locationGroupName: string | undefined;
  manualEntry: boolean;
  setManualEntry: (value: boolean) => void;
  hasAtsIntegration: boolean | undefined;
};

export function CandidateHeaderDescription({
  positionName,
  marketName,
  locationGroupName,
  manualEntry,
  setManualEntry,
  hasAtsIntegration,
}: Props): JSX.Element | null {
  const classes = useStyles();

  if (manualEntry && hasAtsIntegration === true) {
    return (
      <>
        <HeaderText
          positionName={positionName}
          marketName={marketName}
          locationGroupName={locationGroupName}
        />
        <Typography className={classes.atsText}>
          <Button
            variant="text"
            color="primary"
            className={classes.button}
            onClick={() => setManualEntry(false)}
          >
            Select from your ATS
          </Button>
          if you don’t want to enter them in manually.
        </Typography>
      </>
    );
  } else if (manualEntry && hasAtsIntegration === false) {
    return (
      <>
        <HeaderText
          positionName={positionName}
          marketName={marketName}
          locationGroupName={locationGroupName}
        />
        <Box style={{ marginTop: "1rem" }} />
        <DismissableInfoBanner>
          <Typography>
            Ask your Assemble Admin to add your Applicant Tracking System (ATS)
            and pull candidates directly into Offers.
          </Typography>
        </DismissableInfoBanner>
      </>
    );
  } else {
    return (
      <HeaderText
        positionName={positionName}
        marketName={marketName}
        locationGroupName={locationGroupName}
      />
    );
  }
}

export function HeaderText({
  positionName,
  marketName,
  locationGroupName,
}: {
  positionName: string | undefined;
  marketName: string | undefined;
  locationGroupName: string | undefined;
}): JSX.Element | null {
  const classes = useStyles();
  if (positionName === undefined) return null;

  return (
    <div className={classes.headerTextContainer}>
      <div className={classes.headerTextRow}>
        <AssembleTypography variant="productSmallBold">
          Position:&nbsp;
        </AssembleTypography>
        <AssembleTypography variant="productSmall">
          {positionName}
        </AssembleTypography>
      </div>
      {marketName !== undefined && (
        <div className={classes.headerTextRow}>
          <AssembleTypography variant="productSmallBold">
            Market:&nbsp;
          </AssembleTypography>
          <AssembleTypography variant="productSmall">
            {marketName}
          </AssembleTypography>
        </div>
      )}
      {locationGroupName !== undefined && (
        <div className={classes.headerTextRow}>
          <AssembleTypography variant="productSmallBold">
            Location:&nbsp;
          </AssembleTypography>
          <AssembleTypography variant="productSmall">
            {locationGroupName}
          </AssembleTypography>
        </div>
      )}
    </div>
  );
}
