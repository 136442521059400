import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PeopleTabSwitch_employee as Employee,
  Employee2,
  Employee2sMeta,
  LevelingErrorsTable_employee,
  PeopleTable_employee,
  PositionFieldsMinimal,
} from "../../__generated__/graphql";
import { useTrack } from "../../analytics";
import { AssembleTab } from "../../components/AssembleTabs/AssembleTab";
import { AssembleTabs } from "../../components/AssembleTabs/AssembleTabs";
import { ExplanatoryTooltip } from "../../components/ExplanatoryTooltip";
import { FilterParam } from "../../models/FilterParams";
import { useURLSearchParams } from "../../models/URLSearchParams";
import { useReportsTableContext } from "./Context/TableContext";
import { FilterStateManager } from "./FilterByStateManager/FilterStateManager2";
import { LevelingErrorsTable } from "./LevelingErrors/LevelingErrorsTable";
import { NotificationDot } from "./NotificationDot";
import { PeopleTabParam } from "./PeopleTabParam";
import { PeopleTable } from "./PeopleTable";

const useStyles = makeStyles((theme) => ({
  tab: { marginBottom: theme.spacing(2.5) },
  notificationDot: {
    position: "absolute",
    top: theme.spacing(3),
    right: theme.spacing(2.5),
    lineHeight: 1,
  },
}));

interface Props {
  onLevelCallback(employee: Employee, positionId: number | null): unknown;
  employees: Employee2[];
  positions: PositionFieldsMinimal[];
  metastatusCount:
    | {
        ALL: number;
        ACTIVE: number;
        INACTIVE: number;
        LEVELING: number;
        LEVELING_ISSUES: number;
      }
    | undefined;
  totalCount: number;
  dropdownOptions: Omit<Employee2sMeta, "counts">;
  isLoading: boolean;
}

export function PeopleTabSwitch({
  onLevelCallback,
  employees,
  positions,
  metastatusCount,
  totalCount,
  dropdownOptions,
  isLoading,
}: Props): JSX.Element {
  const classes = useStyles();
  const { trackEvent } = useTrack();
  const navigate = useNavigate();
  const urlSearchParams = useURLSearchParams();
  const tabParam = (urlSearchParams.get(FilterParam.TAB) ??
    PeopleTabParam.ALL) as PeopleTabParam;

  const { setOffset } = useReportsTableContext();

  const handleTabParamChange = (_: unknown, newValue: PeopleTabParam) => {
    trackEvent({
      subArea: "People Table",
      object: "People Tab",
      action: "Changed",
      peopleTabType: newValue,
    });
    setOffset(0);
    navigate(`?${urlSearchParams.set("tab", newValue).toString()}`);
  };

  const [cache, setCache] = useState({
    metastatusCount: metastatusCount ?? {
      ALL: 0,
      ACTIVE: 0,
      INACTIVE: 0,
      LEVELING: 0,
      LEVELING_ISSUES: 0,
    },
    dropdownOptions: dropdownOptions ?? {
      departments: [],
      ladders: [],
      levels: [],
      locations: [],
      managers: [],
    },
  });

  useEffect(() => {
    if (
      metastatusCount?.ALL !== cache.metastatusCount.ALL &&
      metastatusCount != null
    ) {
      setCache({
        dropdownOptions,
        metastatusCount,
      });
    }
  }, [dropdownOptions, metastatusCount, cache.metastatusCount.ALL]);

  return (
    <>
      <AssembleTabs
        className={classes.tab}
        value={tabParam}
        onChange={handleTabParamChange}
      >
        <AssembleTab
          selected={tabParam === PeopleTabParam.ALL}
          header="Active Full-Time Employees"
          subheader={metastatusCount?.ALL ?? 0}
          value={PeopleTabParam.ALL}
          onChange={handleTabParamChange}
        />
        <AssembleTab
          selected={tabParam === PeopleTabParam.ACTIVE}
          header="Currently In Assemble"
          subheader={metastatusCount?.ACTIVE ?? 0}
          value={PeopleTabParam.ACTIVE}
          onChange={handleTabParamChange}
        />
        {Boolean(metastatusCount?.LEVELING) && (
          <AssembleTab
            selected={tabParam === PeopleTabParam.LEVELING}
            header="Need Leveling"
            subheader={metastatusCount?.LEVELING}
            value={PeopleTabParam.LEVELING}
            onChange={handleTabParamChange}
            decorator={
              <ExplanatoryTooltip
                title="Leveling"
                body={
                  <>
                    <b>Leveling people</b> refers to mapping the people imported
                    from your HRIS to their appropriate Positions in Assemble.
                  </>
                }
                ctaUrl="//help.assemble.inc/en/articles/5395193"
                width="440px"
                placement="bottom"
                newTab
                hideArrow
              >
                <div className={classes.notificationDot}>
                  <NotificationDot color="green" />
                </div>
              </ExplanatoryTooltip>
            }
          />
        )}

        {Boolean(metastatusCount?.LEVELING_ISSUES) && (
          <AssembleTab
            selected={tabParam === PeopleTabParam.LEVELING_ISSUES}
            header="Leveling Code Issues"
            subheader={metastatusCount?.LEVELING_ISSUES}
            value={PeopleTabParam.LEVELING_ISSUES}
            onChange={handleTabParamChange}
            decorator={
              <ExplanatoryTooltip
                title="Leveling Code Issues"
                body="This tab shows people with a discrepancy between the leveling code in Assemble, and the leveling code we've pulled in from your HRIS."
                ctaUrl="https://help.assemble.inc/en/articles/7979936-how-to-automatically-level"
                width="440px"
                newTab
                hideArrow
              >
                <div className={classes.notificationDot}>
                  <NotificationDot color="red" />
                </div>
              </ExplanatoryTooltip>
            }
          />
        )}
        {Boolean(metastatusCount?.INACTIVE) && (
          <AssembleTab
            selected={tabParam === PeopleTabParam.INACTIVE}
            header="Inactive or Incomplete"
            subheader={metastatusCount?.INACTIVE}
            value={PeopleTabParam.INACTIVE}
            onChange={handleTabParamChange}
          />
        )}
      </AssembleTabs>
      <FilterStateManager
        employees={employees}
        dropdownOptions={dropdownOptions}
      >
        {(filteredEmployees) =>
          tabParam === PeopleTabParam.LEVELING_ISSUES ? (
            <LevelingErrorsTable
              employees={
                filteredEmployees as unknown as LevelingErrorsTable_employee[]
              }
            />
          ) : (
            <PeopleTable
              employees={filteredEmployees as unknown as PeopleTable_employee[]}
              isFiltered
              onLevel={onLevelCallback}
              positions={positions}
              isLoading={isLoading}
              // used to mount/unmount the table as the user clicks tabs
              key={tabParam}
              totalCount={totalCount}
            />
          )
        }
      </FilterStateManager>
    </>
  );
}

PeopleTabSwitch.fragments = {
  employee: gql`
    ${PeopleTable.fragments.employee}
    ${LevelingErrorsTable.fragments.employee}
    fragment PeopleTabSwitch_employee on Employee2 {
      ...PeopleTable_employee
      ...LevelingErrorsTable_employee
      id
      employmentStatus
      activeEmployment {
        id
        positionId
      }
      employments {
        positionId
      }
      user {
        id
      }
    }
  `,
};
