import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    zIndex: 0,
  },
  header: {
    boxShadow: "0 2px 9px rgba(10, 36, 64, 0.08)",
    flexShrink: 0,
    zIndex: 2,
  },

  main: {
    flexBasis: "100%",
    flexShrink: 1,
    overflowY: "auto",
    zIndex: 1,
    scrollbarGutter: "stable",
  },
}));

interface Props {
  header: JSX.Element;
  main: JSX.Element;
}

export function PeopleLayout({ header, main }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>{header}</div>
      <CanduBar />
      <div className={classes.main}>{main}</div>
    </div>
  );
}

function CanduBar() {
  return <div id="candu-bar" />;
}
