import { Paginator } from "../../../../components/Pagination/Paginator";
import { SearchBar } from "../../../../components/Search/SearchBar";
import { TableHeaderBar } from "../../../../components/Table/TableHeaderBar";

type Props = {
  searchTerm: string;
  onSearchChange: (value: string | null) => unknown;
  page: number;
  onPageChange: (value: number) => unknown;
  pageSize: number;
  onSetPageSize: (value: number) => unknown;
  rowCount: number;
};

export const PortalTableHeaderBar = ({
  searchTerm,
  onSearchChange,
  page,
  onPageChange,
  pageSize,
  onSetPageSize,
  rowCount,
}: Props) => {
  return (
    <TableHeaderBar
      inContainer
      slotLeft={
        <SearchBar
          searchTerm={searchTerm}
          handleSearchChange={onSearchChange}
        />
      }
      slotCenter={
        <Paginator
          page={page}
          setPage={onPageChange}
          pageSize={pageSize}
          setPageSize={onSetPageSize}
          items={rowCount} // = matchingRows.length
        />
      }
      slotRight={<></>}
    />
  );
};
