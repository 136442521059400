import { CurrencyCode, EquityBandName } from "@asmbl/shared/constants";
import { Money } from "@asmbl/shared/money";
import {
  Button,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import fastDeepEqual from "fast-deep-equal";
import { memo, useEffect, useMemo, useState } from "react";
import {
  EquityGrantMethod,
  EquityGrantTypes,
  GetEquitySettings,
} from "../../../__generated__/graphql";
import { currencySymbol } from "../../../models/Currency";
import { hasStrikePrice } from "../../../models/Offer";
import { GRAY_1 } from "../../../theme";
import { NonNull } from "../../../utils";
import { AssembleTypography } from "../../AssembleTypography";
import { NumberInput } from "../../Form/NumberInput";
import FormBox from "../../FormBox";
import { EquityGrantForm } from "./EquityGrantForm";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  disabled: {
    opacity: "30%",
  },
  smallSpacer: {
    margin: theme.spacing(1),
  },
  mediumSpacer: {
    margin: theme.spacing(1.5),
  },
  bigSpacer: {
    margin: theme.spacing(2),
  },
}));

type CompStructure = NonNull<GetEquitySettings["compStructure"]>;
type Valuation = NonNull<GetEquitySettings["valuation"]>;

export type EquityDetailsData = {
  createdAt?: string;
  estimatedDilution?: number;
  equityGrantTypes?: EquityGrantTypes[];
  equityGrantMethod?: EquityGrantMethod;
  fdso?: number;
  financingStage?: string;
  strikePrice?: Money;
  valuation?: Money;
  vestingCliff?: number;
  vestingMonths?: number;
  refreshGrantValue?: number | null;
};

export type ChangeHandler = <Field extends keyof EquityDetailsData>(
  id: Field,
  value: EquityDetailsData[Field]
) => unknown;

export type EquityDetailsFormProps = {
  compStructure: CompStructure | null;
  valuation: Valuation | null;
  onSave: (
    initialFormData: EquityDetailsData,
    newFormData: EquityDetailsData
  ) => Promise<unknown>;
  isSaving: boolean;
  defaultCurrency: CurrencyCode;
};

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
export const EquityDetailsForm = memo(function EquityDetailsForm({
  compStructure,
  valuation,
  onSave,
  isSaving,
  defaultCurrency,
}: EquityDetailsFormProps): JSX.Element {
  const classes = useStyles();
  const initialData = useMemo<EquityDetailsData>(
    () => ({
      ...compStructure,
      ...valuation,
    }),
    [compStructure, valuation]
  );

  const [formData, setFormData] = useState<EquityDetailsData>(initialData);

  useEffect(() => setFormData(initialData), [initialData]);

  const formCurrency = formData.valuation?.currency ?? defaultCurrency;
  const formCurrencySymbol = currencySymbol(defaultCurrency);

  const handleChange: ChangeHandler = (id, value) => {
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleEquityGrantChange = (grantType: EquityGrantTypes) => {
    handleChange("equityGrantTypes", [grantType]);

    // We want to set strike price to 0 for certain types of equity grants.
    if (hasStrikePrice(grantType)) {
      handleChange("strikePrice", valuation?.strikePrice);
    } else {
      handleChange("strikePrice", {
        value: 0,
        currency: formCurrency,
      });
    }
  };

  const selectedEquityGrantType = formData.equityGrantTypes?.[0] ?? null;
  const showStrikePrice =
    selectedEquityGrantType === null || hasStrikePrice(selectedEquityGrantType);
  const refreshGrantsEnabled = compStructure?.equityBandTypes.includes(
    EquityBandName.EQUITY_REFRESH_GRANT
  );

  return (
    <div className={classes.container}>
      <AssembleTypography variant="h5" textColor={GRAY_1}>
        Equity Grants
      </AssembleTypography>
      <div className={classes.smallSpacer} />
      <EquityGrantForm
        handleChange={handleEquityGrantChange}
        value={selectedEquityGrantType}
      />
      <div className={classes.smallSpacer} />
      <AssembleTypography variant="h5" textColor={GRAY_1}>
        Equity Grant Method
      </AssembleTypography>
      <div className={classes.smallSpacer} />
      <RadioGroup
        onChange={(e) =>
          handleChange("equityGrantMethod", e.target.value as EquityGrantMethod)
        }
        value={formData.equityGrantMethod}
      >
        <FormBox
          control={<Radio />}
          label="Equity Units"
          value={EquityGrantMethod.UNITS}
        />
        <FormBox
          control={<Radio />}
          label="Cash Value"
          value={EquityGrantMethod.CASH}
        />
      </RadioGroup>
      <div className={classes.bigSpacer} />
      <AssembleTypography variant="h5" textColor={GRAY_1}>
        Vesting Schedules
      </AssembleTypography>
      <div className={classes.smallSpacer} />
      <EquityFormNumberInput
        field="vestingMonths"
        label="Vesting Schedule"
        handleChange={handleChange}
        value={formData.vestingMonths}
        placeholder="##"
        endAdornment={<InputAdornment position="end">Months</InputAdornment>}
      />
      <div className={classes.smallSpacer} />
      <EquityFormNumberInput
        field="vestingCliff"
        label="Vesting Cliff"
        handleChange={handleChange}
        value={formData.vestingCliff}
        placeholder="##"
        endAdornment={<InputAdornment position="end">Months</InputAdornment>}
      />
      <div className={classes.bigSpacer} />
      <AssembleTypography
        className={clsx({ [classes.disabled]: !refreshGrantsEnabled })}
        variant="h5"
        textColor={GRAY_1}
      >
        Refresh Grant Value
      </AssembleTypography>
      <div className={classes.smallSpacer} />
      <AssembleTypography
        className={clsx({ [classes.disabled]: !refreshGrantsEnabled })}
        variant="productParagraphMedium"
      >
        Set your default refresh grant value to be a percentage of an employee’s
        initial grant.
      </AssembleTypography>
      <div className={classes.mediumSpacer} />
      <EquityFormNumberInput
        field="refreshGrantValue"
        label="Percentage of Initial Grant"
        handleChange={(id, value) =>
          // Show dilution as a percentage but save it as a float between 0-1
          handleChange("refreshGrantValue", Number(value) / 100)
        }
        value={(formData.refreshGrantValue ?? 0) * 100}
        placeholder="##"
        endAdornment={<InputAdornment position="end">%</InputAdornment>}
        disabled={!refreshGrantsEnabled}
      />
      <div className={classes.bigSpacer} />
      <AssembleTypography variant="h5" textColor={GRAY_1}>
        Company Valuation
      </AssembleTypography>
      <div className={classes.smallSpacer} />
      <EquityFormNumberInput
        field="valuation"
        label="Current Valuation"
        handleChange={(id, value) =>
          handleChange("valuation", {
            value: value as number,
            currency: formCurrency,
          })
        }
        value={formData.valuation?.value}
        placeholder="0.00"
        startAdornment={
          <InputAdornment position="start">{formCurrencySymbol}</InputAdornment>
        }
      />
      <div className={classes.smallSpacer} />
      <TextField
        fullWidth
        id="financingStage"
        label="Company Stage"
        onChange={(e) => handleChange("financingStage", e.target.value)}
        placeholder="Series A"
        variant="outlined"
        value={formData.financingStage ?? ""}
      />
      <div className={classes.smallSpacer} />
      {showStrikePrice && (
        <>
          <EquityFormNumberInput
            field="strikePrice"
            label="Strike Price"
            handleChange={(id, value) =>
              handleChange("strikePrice", {
                value: value as number,
                currency: formCurrency,
              })
            }
            value={formData.strikePrice?.value}
            placeholder="0.00"
            startAdornment={
              <InputAdornment position="start">
                {formCurrencySymbol}
              </InputAdornment>
            }
          />
          <div className={classes.smallSpacer} />
        </>
      )}
      <EquityFormNumberInput
        field="fdso"
        label="FDSO (Fully-Diluted Shares Outstanding)"
        handleChange={handleChange}
        value={formData.fdso}
        placeholder="###"
      />
      <div className={classes.smallSpacer} />
      <EquityFormNumberInput
        field="estimatedDilution"
        label="Estimated Dilution"
        handleChange={(id, value) =>
          // Show dilution as a percentage but save it as a float between 0-1
          handleChange("estimatedDilution", Number(value) / 100)
        }
        value={(formData.estimatedDilution ?? 0) * 100}
        placeholder="50"
        endAdornment={<InputAdornment position="end">%</InputAdornment>}
      />
      <div className={classes.bigSpacer} />
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          disabled={isSaving || fastDeepEqual(initialData, formData)}
          onClick={() => onSave(initialData, formData)}
          variant="contained"
        >
          {isSaving ? "Saving..." : "Save"}
        </Button>
      </div>
    </div>
  );
});

function EquityFormNumberInput({
  field,
  label,
  handleChange,
  value,
  placeholder,
  startAdornment,
  endAdornment,
  disabled,
}: {
  field: keyof EquityDetailsData;
  label: string;
  handleChange: ChangeHandler;
  value: number | undefined;
  placeholder?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  disabled?: boolean;
}) {
  const classes = useStyles();
  return (
    <NumberInput
      className={clsx({ [classes.disabled]: disabled })}
      fullWidth
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      onValueChange={(v) =>
        handleChange(field, v.value === "" ? undefined : Number(v.value))
      }
      placeholder={placeholder}
      label={label}
      value={value}
      allowNegative={false}
      disabled={disabled}
    />
  );
}
