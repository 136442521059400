import { isValidString } from "./utils";

/**
 * Leveling codes can contain commas, and if they do, we export the code wrapped
 * in quotes.
 *
 * To maintain the commas, we can't just call `.split(",")`. We have this regex
 * to strip out any quotes, but still maintain codes that have commas (and not
 * overly split the string). It also filters out empty strings and trims
 * whitespace.
 */
export function parseLevelingCodes(codesStr: string): string[] {
  return (
    codesStr
      .match(/(?:"([^"]*?)"|([^,"]*?))(?=\s*,|\s*$)/g)
      ?.map((item) => item.replace(/"/g, ""))
      ?.map((item) => item.trim())
      .filter(isValidString) ?? []
  );
}
