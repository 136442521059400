import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { AssembleTypography } from "src/components/AssembleTypography";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  handleDelete: () => void;
};

export function PhaseDeleteModal({
  isOpen,
  handleClose,
  handleDelete,
}: Props): JSX.Element {
  return (
    <Dialog open={isOpen} fullWidth maxWidth={"xs"}>
      <DialogTitle>
        <AssembleTypography variant="productHeader">
          Are you sure?
        </AssembleTypography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <AssembleTypography variant="productParagraphLarge">
            You'll have to reassign any managers that have already been assigned
            to this phase.
          </AssembleTypography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <AssembleButton
          size="medium"
          variant="text"
          label="Go Back"
          onClick={handleClose}
        />
        <AssembleButton
          size="medium"
          variant="danger"
          label="Delete Phase"
          onClick={handleDelete}
        />
      </DialogActions>
    </Dialog>
  );
}
