import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { Money } from "@asmbl/shared/money";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import clsx from "clsx";
import { BandPointIcon } from "src/components/AssembleIcons/Small/BandPointIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { getSalaryBand } from "src/models/Band";
import { CashCompensation } from "src/models/Employee";
import { GRAY_4 } from "src/theme";
import {
  CompComponent,
  CompUnit,
  PositionRow_employee,
  PositionRow_position,
  PositionRow_promotedPosition,
  PositionType,
  RecItemInput,
  RecItemType,
} from "../../../../__generated__/graphql";
import { useTrack } from "../../../../analytics";
import { ADJUSTED_CASH_BAND_FIELDS } from "../../../../fragments";
import { hasUnpublishedChanges } from "../../../../models/CompRecommendation";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { NewTitleInputCell } from "../../Cells/NewTitleInputCell";
import { PositionInputCell } from "../../Cells/PositionInputCell";
import { SalaryInputCell } from "../../Cells/SalaryInputCell";
import { CompCycleLineItemRowButton } from "../CompCycleLineItemRowButton";
import { useConnectorStyles, useRecItemRowStyles } from "./styles";
import { CompCycleLineItemRowProps } from "./types";

interface Props extends CompCycleLineItemRowProps {
  employee: PositionRow_employee;
  positions: PositionRow_position[];
  promotedPosition: PositionRow_promotedPosition | null;
  basePay: CashCompensation | null;
  payCurrencyCode: CurrencyCode | null;
}

export function PositionRow({
  employee,
  revisedRecommendation,
  submittedRecommendation,
  onChangeRecommendationItem,
  positions,
  promotedPosition,
  basePay,
  payCurrencyCode,
}: Props): JSX.Element {
  const { isEnabled } = useFeatureFlags();
  const { compStructure } = useCompStructure();
  const { Track, trackEvent } = useTrack({
    compensationComponent: CompComponent.SALARY,
    compensationSubComponent: "salaryPromotion",
    employeeId: employee.id,
  });
  const showHourly =
    (isEnabled(FeatureFlag.HourlyEmployeesInCompCycles) &&
      compStructure?.allowHourlyEmployees) === true;
  const isHourly = showHourly && promotedPosition?.type === PositionType.HOURLY;

  const classes = useRecItemRowStyles();
  const connectorStyle = useConnectorStyles();

  const promotionItem = revisedRecommendation?.items.get(RecItemType.PROMOTION);
  const promotionPositionId = promotionItem?.recommendedPositionId ?? null;

  const submittedValue = submittedRecommendation?.items.get(
    RecItemType.PROMOTION
  )?.recommendedCashValue?.value;
  const draftedValue = promotionItem?.recommendedCashValue?.value;

  const handlePositionChange = (
    newPositionId: number | null,
    newPositionType: PositionType | undefined
  ) => {
    const input: RecItemInput = {
      recommendationType: RecItemType.PROMOTION,
      recommendedPositionId: newPositionId,
      // clear out cash rec if the position type changes and the org allows hourly
      recommendedCashValue:
        newPositionId !== null &&
        (!showHourly ||
          (showHourly &&
            (newPositionType === promotedPosition?.type ||
              promotionPositionId === null)))
          ? promotionItem?.recommendedCashValue
          : null,
      note: newPositionId !== null ? promotionItem?.note : null,
      unitType:
        showHourly && newPositionType === PositionType.HOURLY
          ? CompUnit.HOURLY_CASH
          : CompUnit.CASH,
    };
    onChangeRecommendationItem(input);
    trackEvent({
      object: "Change Request Position",
      action: "Edited",
      currentPositionId: promotionItem?.recommendedPositionId,
      newPositionId: newPositionId,
    });
  };

  const handleSalaryChange = (
    newSalaryIncrease: Money | null | undefined,
    unitType: CompUnit | null
  ) => {
    const input: RecItemInput = {
      ...promotionItem,
      recommendationType: RecItemType.PROMOTION,
      recommendedCashValue: newSalaryIncrease,
      unitType,
    };
    onChangeRecommendationItem(input);
  };

  const handleTitleChange = (newTitle: string | null) => {
    const input: RecItemInput = {
      ...promotionItem,
      recommendationType: RecItemType.PROMOTION,
      recommendedTitle: newTitle,
    };
    onChangeRecommendationItem(input);
  };

  const newSalaryBand = getSalaryBand(promotedPosition?.adjustedCashBands);
  const newPayMin =
    showHourly && promotedPosition?.type === PositionType.HOURLY
      ? newSalaryBand?.bandPoints.at(0)?.hourlyCashEquivalent
      : newSalaryBand?.bandPoints.at(0)?.annualCashEquivalent;
  const newPayMax =
    showHourly && promotedPosition?.type === PositionType.HOURLY
      ? newSalaryBand?.bandPoints.at(-1)?.hourlyCashEquivalent
      : newSalaryBand?.bandPoints.at(-1)?.annualCashEquivalent;

  return (
    <Track>
      <TableRow aria-level={3} className={classes.root}>
        <TableCell role="gridcell" padding="none">
          <div className={connectorStyle.line} />
        </TableCell>
        <TableCell
          role="gridcell"
          padding="none"
          className={classes.textCell}
          colSpan={1}
        >
          <Typography className={classes.rowLabel}>
            Promotion & Adjustment
          </Typography>
        </TableCell>
        <PositionInputCell
          colSpan={2}
          positionId={promotionPositionId}
          positions={positions}
          onChange={handlePositionChange}
        />
        <TableCell
          colSpan={2}
          role="gridcell"
          className={clsx(classes.textCell, classes.bandVisualizationCell)}
          align="center"
        >
          {newPayMin && newPayMax && (
            <AssembleTypography
              className={classes.newPosition}
              color="textSecondary"
            >
              {getSimpleCashLabel(newPayMin, false, isHourly)}
              {" - "}
              {getSimpleCashLabel(newPayMax, false, isHourly)}
              &nbsp; <BandPointIcon color={GRAY_4} />
            </AssembleTypography>
          )}
        </TableCell>
        <SalaryInputCell
          value={promotionItem}
          isHourly={isHourly}
          hasUnpublishedChanges={hasUnpublishedChanges(
            submittedValue,
            draftedValue
          )}
          basePay={basePay}
          onChange={handleSalaryChange}
          disabled={
            promotionItem?.recommendedPositionId === null ||
            promotionItem?.recommendedPositionId === undefined
          }
          payCurrencyCode={payCurrencyCode}
        />
        <TableCell role="gridcell" className={classes.noteTextCell} colSpan={4}>
          <CompCycleLineItemRowButton
            disabled={
              promotionItem?.recommendedCashValue === undefined &&
              promotionItem?.recommendedPercentValue === undefined
            }
            employee={employee}
            title="Why are you promoting this person?"
            note={promotionItem?.note ?? null}
            onClick={(note: string) => {
              const updatedInput: RecItemInput = {
                ...promotionItem,
                recommendationType: RecItemType.PROMOTION,
                note,
              };
              onChangeRecommendationItem(updatedInput);
            }}
          />
        </TableCell>
      </TableRow>
      {promotionPositionId !== null && (
        <TableRow aria-level={3} className={classes.root}>
          <TableCell role="gridcell" padding="none">
            <div className={connectorStyle.line} />
          </TableCell>
          <TableCell
            role="gridcell"
            padding="none"
            className={classes.textCell}
            colSpan={1}
          />
          <NewTitleInputCell
            colSpan={2}
            newTitle={promotionItem?.recommendedTitle ?? ""}
            setNewTitle={handleTitleChange}
          />
          <TableCell
            role="gridcell"
            className={classes.noteTextCell}
            colSpan={9}
          />
        </TableRow>
      )}
    </Track>
  );
}
PositionRow.fragments = {
  employee: gql`
    ${CompCycleLineItemRowButton.fragments.employee}
    fragment PositionRow_employee on Employee2 {
      id
      ...CompCycleLineItemRowButton_employee
    }
  `,
  position: gql`
    fragment PositionRow_position on Position {
      id
      name
      level
      type
      ladder {
        id
        name
        department {
          id
          name
        }
      }
    }
  `,
  promotedPosition: gql`
    ${ADJUSTED_CASH_BAND_FIELDS}
    fragment PositionRow_promotedPosition on Position {
      id
      type
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedCashBandFields
      }
    }
  `,
};
