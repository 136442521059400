import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { GRAY_4, WHITE } from "../../theme";
import { AssembleLink } from "../AssembleLink";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(2),
    borderRadius: "10px",
    width: "100%",
    height: "100%",
    boxShadow: "0px 15px 25px rgba(10, 36, 64, 0.08)",
    background: WHITE,
  },
  tooltipText: {
    color: GRAY_4,
  },
}));

export type Props = {
  children: JSX.Element;
};

export function NullCompSliderTooltip({ children }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={
        <div>
          <Typography
            align="center"
            variant="body1"
            className={classes.tooltipText}
          >
            There’s no band info for this employee.{" "}
            <AssembleLink
              to="//help.assemble.inc/en/articles/6638003-no-band-information-message"
              newTab
              onClick={(event) => event.stopPropagation()}
            >
              Learn more.
            </AssembleLink>
          </Typography>
        </div>
      }
      interactive
      placement="top"
    >
      {children}
    </Tooltip>
  );
}
