import { useQuery } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { Noun } from "@asmbl/shared/permissions";
import { Box, LinearProgress, Link, Typography } from "@material-ui/core";
import * as changeCase from "change-case";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { GetCurrentUserPermissions } from "../__generated__/graphql";
import { AccessBoundary } from "../components/AccessBoundary";
import { AssembleButton } from "../components/AssembleButton/AssembleButton";
import { useAuth } from "../components/Auth/AuthContext";
import {
  EnabledFeaturesList,
  isEnabledWithUser,
  PreviewFeature,
} from "../components/FeatureContext";
import { CURRENT_USER_PERMISSIONS } from "../queries";
import { BulkEditOrg } from "./BulkEditOrg";

export function Profile(): JSX.Element {
  const { logout } = useAuth();

  return (
    <Box px={5} py={4}>
      <ProfileInfo />
      <InternalTools />
      <Box m={2} />
      <AssembleButton
        variant="contained"
        label="Sign out"
        size="medium"
        onClick={() => logout && logout()}
      />
    </Box>
  );
}

function ProfileInfo() {
  const { data, loading, error } = useQuery<GetCurrentUserPermissions>(
    CURRENT_USER_PERMISSIONS,
    { fetchPolicy: "no-cache" }
  );

  const [ssoOnly, setSsoOnly] = useState<boolean | null>(null);

  useEffect(() => {
    async function fetchSsoOnly() {
      if (data?.whoami?.email) {
        const result = await isEnabledWithUser(
          FeatureFlag.SsoOnly,
          data.whoami.email
        );
        setSsoOnly(result);
      }
    }
    void fetchSsoOnly();
  }, [data]);

  if (loading) return <LinearProgress />;
  if (error) return <p>{error.message}</p>;
  if (!data) return <p>Not found</p>;

  const roleName = data.whoami?.userAccessGrant?.roleName;

  return (
    <>
      {data.whoami !== null && (
        <>
          <Typography variant="h5">Your account</Typography>
          <Typography variant="body1">{data.whoami.email}</Typography>
          {ssoOnly !== null && !ssoOnly && (
            <Link component={RouterLink} to="/profile/change-password">
              Change your password
            </Link>
          )}
          <Box m={2} />
          <Typography variant="h5">Your organization</Typography>
          <Typography variant="body1">
            {data.whoami.organization.name}
          </Typography>
          <Box m={2} />
          <Typography variant="h5">Your role</Typography>
          <Box display="flex" alignItems="center">
            <Typography>
              {roleName !== undefined
                ? changeCase.capitalCase(roleName)
                : "No Access"}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
}

function InternalTools() {
  return (
    <>
      <EnabledFeaturesList />
      <AccessBoundary scope={"global"} verb={"edit"} noun={Noun.Employee}>
        <PreviewFeature flag={FeatureFlag.LeakyBandsCsvExport}>
          <BulkEditOrg />
        </PreviewFeature>
      </AccessBoundary>
    </>
  );
}
