import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const TrashIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Trash"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M13.4997 4.41666H2.49966"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M6.5 7.16667V11.1667"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M9.5 7.16667V11.1667"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M10.4999 4.5V3C10.4999 2.73478 10.3946 2.48043 10.207 2.29289C10.0195 2.10536 9.76512 2 9.49991 2H6.49991C6.23469 2 5.98034 2.10536 5.7928 2.29289C5.60527 2.48043 5.49991 2.73478 5.49991 3V4.5M12.4997 4.5V13.5C12.4997 13.6326 12.3532 13.85 12.3532 13.85C12.3532 13.85 12.1323 14 11.9997 14H3.99966C3.86706 14 3.64611 13.85 3.64611 13.85C3.64611 13.85 3.49966 13.6326 3.49966 13.5V4.5L12.4997 4.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
