import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  ClickAwayListener,
  createTheme,
  IconButton,
  makeStyles,
  Popper,
  ThemeProvider,
  Tooltip,
} from "@material-ui/core";
import { Overrides } from "@material-ui/core/styles/overrides";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import clsx from "clsx";
import { useRef, useState } from "react";
import {
  GRAY_1,
  GRAY_3,
  GRAY_4,
  GRAY_5,
  GRAY_6,
  PURPLE_1,
  RED,
  WHITE,
} from "../theme";
import { formatDateString } from "../utils";
import { ChevronDownIcon } from "./AssembleIcons/Brand/ChevronDownIcon";
import { DeleteIcon } from "./AssembleIcons/Brand/DeleteIcon";
import { CalendarIcon } from "./AssembleIcons/Small/CalendarIcon";
import { AssembleTypography } from "./AssembleTypography";

const datePickerTheme = createTheme({
  overrides: {
    MuiPickersDay: {
      current: {
        color: GRAY_1,
        backgroundColor: `${WHITE} !important`,
        border: `1px solid ${GRAY_6} !important`,
      },
      daySelected: {
        color: `${PURPLE_1} !important`,
        backgroundColor: `${WHITE} !important`,
        border: `1px solid ${PURPLE_1} !important`,
      },
    },
    MuiIconButton: {
      root: {
        color: GRAY_1,

        "&:hover": {
          color: `${WHITE} !important`,
          background: `${PURPLE_1} !important`,
        },

        "&.active": {
          background: `${WHITE} !important`,
          border: `1px solid ${PURPLE_1} !important`,
        },
        "&$disabled": {
          color: GRAY_5,
        },
      },
    },
  } as Overrides,
});

const useStyles = makeStyles(() => ({
  active: {},
  empty: {},
  button: {
    border: `1px solid ${GRAY_5}`,
    height: "32px",
    boxShadow: "0px 1px 3px rgba(10, 36, 64, 0.1)",
    color: GRAY_1,

    // Hovering, click-down, and active states (when not empty)
    "&:hover, &:focus, &:active, &$active": {
      border: `1px solid ${PURPLE_1}`,
      boxShadow:
        "0px 1px 3px rgba(10, 36, 64, 0.1), 0px 0px 0px 3px rgba(10, 0, 174, 0.25)",
    },

    // By default, the empty state should have the color red...
    "&$empty": {
      border: `1px solid ${RED}`,
      color: RED,

      // ...and when you hover over it, it gets a red box-shadow...
      "&:hover": {
        boxShadow:
          "0px 1px 3px rgba(10, 36, 64, 0.1), 0px 0px 0px 3px rgba(255, 56, 92, 0.25)",
      },

      // ...but when it's active or clicked-down, restore the normal colors.
      "&:active, &:focus, &$active": {
        color: GRAY_1,
        border: `1px solid ${PURPLE_1}`,
        boxShadow:
          "0px 1px 3px rgba(10, 36, 64, 0.1), 0px 0px 0px 3px rgba(10, 0, 174, 0.25)",
      },
    },
  },
  innerButton: {
    display: "flex",
    justifyContent: "space-between",
    gap: "0.5rem",
    alignItems: "center",
  },
  label: {
    color: "inherit",
  },
  open: {},
  arrowIcon: {
    transition: "transform 350ms",
    width: "1rem",
    height: "1rem",
    color: "inherit",

    "&$open": {
      transform: "rotate(-180deg)",
    },
  },
  popper: {
    marginTop: "3px", // to account for the active boxShadow of the button
    background: "white",
    padding: "0 0.5rem 0.5rem",
    borderRadius: "4px",
    border: `1px solid ${GRAY_6}`,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 10px",
    fontWeight: 600,
  },
  deleteButton: {
    width: 32,
    height: 32,
    padding: 8,
    color: GRAY_4,
  },
  datePicker: {
    border: `1px solid ${GRAY_6}`,
    borderRadius: "4px",
  },
  fullWidth: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

type Props = {
  title?: string;
  selectedText?: string;
  value?: Date | undefined;
  onChange?: (value: Date | undefined) => void;
  onDelete?: () => void;
  zIndex?: number;
  disableFuture?: boolean;
  disablePast?: boolean;
  initOpenValue?: boolean;
  errorOnEmpty?: boolean;
  minDate?: Date;
  maxDate?: Date;
  fullWidth?: boolean;
  clearable?: boolean;
};

export function DatePickerButton({
  title: inputTitle,
  selectedText = "",
  value,
  onChange,
  onDelete,
  zIndex,
  disableFuture,
  disablePast,
  initOpenValue,
  errorOnEmpty,
  minDate,
  maxDate,
  fullWidth,
  clearable,
}: Props): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = useState(initOpenValue ?? value === undefined);

  const ref = useRef<HTMLButtonElement>(null);

  const toggleOpen = () => setOpen((prev) => !prev);
  const handleDelete = () => {
    onChange && onChange(undefined);
    setOpen(false);
    onDelete && onDelete();
  };

  const title = inputTitle ?? "Date";
  const dateStr = value ? formatDateString(value) : "";

  const buttonLabel =
    value == null ? `${title}` : `${title}: ${selectedText} ${dateStr}`;

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={clsx({ [classes.fullWidth]: fullWidth })}>
        <Button
          ref={ref}
          className={clsx(classes.button, {
            [classes.active]: open,
            [classes.empty]: errorOnEmpty ?? value === undefined,
            [classes.fullWidth]: fullWidth,
          })}
          onClick={toggleOpen}
        >
          <div
            className={clsx(classes.innerButton, {
              [classes.fullWidth]: fullWidth,
            })}
          >
            {fullWidth === true ? (
              <>
                <AssembleTypography
                  variant="productSmallerBold"
                  className={classes.label}
                >
                  {buttonLabel}
                </AssembleTypography>
                <CalendarIcon inherit />
              </>
            ) : (
              <>
                <CalendarIcon inherit />
                <AssembleTypography
                  variant="productSmallerBold"
                  className={classes.label}
                >
                  {buttonLabel}
                </AssembleTypography>
                <ChevronDownIcon
                  className={clsx(classes.arrowIcon, { [classes.open]: open })}
                  inherit
                />
              </>
            )}
          </div>
        </Button>
        <Popper
          open={open}
          anchorEl={ref.current}
          placement="bottom-start"
          className={classes.popper}
          style={{ zIndex }}
        >
          <div className={classes.header}>
            <AssembleTypography
              variant="productExtraSmallSemiBold"
              textColor={GRAY_3}
            >
              {title}
            </AssembleTypography>
            {clearable == null ||
              (clearable === true && (
                <Tooltip title="Delete">
                  <IconButton
                    className={classes.deleteButton}
                    onClick={handleDelete}
                  >
                    <DeleteIcon color={GRAY_4} hoverColor={PURPLE_1} />
                  </IconButton>
                </Tooltip>
              ))}
          </div>
          <div className={classes.datePicker}>
            <ThemeProvider theme={datePickerTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  clearable={clearable}
                  disableFuture={disableFuture}
                  disablePast={disablePast}
                  style={{ borderRadius: "4px" }}
                  disableToolbar
                  autoOk
                  variant="static"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  value={value}
                  onChange={(date) => {
                    onChange && onChange(date ?? undefined);
                    setOpen(false);
                  }}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
