import { formatCurrency, Money } from "@asmbl/shared/money";
import { round } from "@asmbl/shared/utils";
import {
  CashCompType,
  CompensationTimelineTable_cashCompensation,
  CompUnit,
} from "../../__generated__/graphql";
import { AssembleTypography } from "../AssembleTypography";

export function CurrencyWithPercentCell({
  row,
  value,
}: {
  row: {
    original: CompensationTimelineTable_cashCompensation;
  };
  value: Money | null; // either annualCashEquivalent or hourlyCashEquivalent
}): JSX.Element {
  const isAnnual = row.original.unit !== CompUnit.HOURLY_CASH;
  const val = value ? formatCashEquivalent(isAnnual, value) : "N/A";

  const isNonSalary = row.original.type !== CashCompType.SALARY;

  return (
    <>
      {val}
      {isNonSalary && (
        <AssembleTypography variant="productExtraSmall">
          {round(row.original.percentOfSalary)}% of salary
        </AssembleTypography>
      )}
    </>
  );
}

function formatCashEquivalent(isAnnual: boolean, value: Money) {
  return isAnnual
    ? formatCurrency(value, {
        notation: "standard",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    : `${formatCurrency(value, {
        notation: "standard",
        minimumFractionDigits: 2,
        maximumFractionDigits: 3,
      })}/hr`;
}
