import { useQuery } from "@apollo/client";
import { generateQuarterlyDates } from "@asmbl/shared/utils";
import { Box } from "@material-ui/core";
import { PieChart } from "../../components/Visualization/PieChart";
import { TimelineSeriesChart } from "../../components/Visualization/TimelineSeriesChart";
import { VisualizationCard } from "../../components/Visualization/VisualizationCard";
import { GET_EMPLOYEE_ANALYTICS_COUNTS_AND_TIMESERIES } from "../../queries";
import { formatDateString } from "../../utils";
import { GetEmployeeAnalytics } from "../../__generated__/graphql";
import { TimeUnit, ToggleType } from "./Demographics";

interface Props {
  timeUnit: string;
  toggle: ToggleType;
}

export function GenderRepresentationChart({
  timeUnit,
  toggle,
}: Props): JSX.Element {
  const lastYearDates: string[] = generateQuarterlyDates().map((date) =>
    formatDateString(date)
  );

  const { data: employeeDataByGender, loading: loading } =
    useQuery<GetEmployeeAnalytics>(
      GET_EMPLOYEE_ANALYTICS_COUNTS_AND_TIMESERIES,
      {
        variables: {
          groupByFilterOpts: { groupBy: "gender" },
          allTimeSeriesOpts: { dates: [], groupBy: "gender" },
          lastYearTimeSeriesOpts: {
            dates: lastYearDates,
            groupBy: "gender",
          },
        },
      }
    );

  const employeeCount = employeeDataByGender?.employeeAnalytics.count ?? [];
  const allTimeSeriesData =
    employeeDataByGender?.employeeAnalytics.allTimeTimeSeries ?? [];
  const lastYearTimeSeriesData =
    employeeDataByGender?.employeeAnalytics.lastYearTimeSeries ?? [];

  return (
    <Box flex={1}>
      <VisualizationCard title="Gender" loading={loading}>
        {timeUnit === TimeUnit.TODAY ? (
          <>
            <Box mt={2} />
            <PieChart
              items={employeeCount}
              height="200px"
              showPercentages={toggle === ToggleType.PERCENT}
            />
          </>
        ) : (
          <TimelineSeriesChart
            items={
              timeUnit === TimeUnit.LAST_YEAR
                ? lastYearTimeSeriesData
                : allTimeSeriesData
            }
            height="200px"
            showPercentages={toggle === ToggleType.PERCENT}
          />
        )}
      </VisualizationCard>
    </Box>
  );
}
