import {
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { GRAY_1, GRAY_4 } from "../../theme";
import { ChevronDownIcon } from "../AssembleIcons/Brand/ChevronDownIcon";

const useSelectStyles = makeStyles((theme) => ({
  pageSize: {
    height: "100%",
    width: "8rem",
    marginRight: theme.spacing(1),
    "& .MuiOutlinedInput-root": {
      height: "100%",
      boxShadow: "0px 1px 3px rgba(10, 36, 64, 0.1)",
    },

    "& .MuiSelect-root": {
      display: "flex",
      height: "100%",
      paddingBlock: 0,
      alignItems: "center",
      color: GRAY_1,

      "&:focus": {
        borderRadius: "5px",
      },
    },

    "& .MuiSelect-icon": {
      right: theme.spacing(2),
      height: "100%",
      top: "2px", // The caret is pretty thin, and floats a little high
      display: "flex",
      alignItems: "center",
    },
  },
  compactPageSize: {
    minHeight: "2rem",
    width: "6.5rem",
    "& .MuiSelect-icon": {
      right: theme.spacing(0.75),
      top: "1px",
    },
    "& .MuiOutlinedInput-input": {
      paddingLeft: theme.spacing(1.5),
    },
  },
  pageSizeValue: {
    fontSize: "0.875rem",
  },
  pageSizeInputContainer: {
    display: "flex",
    flexDirection: "row",
  },
  pageSizeText: {
    color: GRAY_4,
    paddingLeft: "0.25rem",
    fontSize: "0.875rem",
  },
  compactPageSizeText: {
    paddingRight: theme.spacing(1.25),
  },
}));

function CaretIcon({ className }: { className: string }) {
  return (
    <div className={className}>
      <ChevronDownIcon inherit />
    </div>
  );
}

export function PageSizeSelector({
  pageSize,
  setPageSize,
  setPage,
  items,
  compact = false,
  includeAll,
}: {
  pageSize: number;
  setPageSize: (pageSize: number) => unknown;
  setPage: (page: number) => unknown;
  items: number;
  compact?: boolean;
  includeAll: boolean;
}): JSX.Element {
  const classes = useSelectStyles();

  if (items < 10) {
    return <></>;
  }

  function PageSizeInput(): JSX.Element {
    return (
      <div className={classes.pageSizeInputContainer}>
        <Typography className={classes.pageSizeValue}>{pageSize}</Typography>
        <Typography
          className={clsx(classes.pageSizeText, {
            [classes.compactPageSizeText]: compact,
          })}
        >{`/ page`}</Typography>
      </div>
    );
  }
  return (
    <FormControl
      variant="outlined"
      className={clsx(classes.pageSize, {
        [classes.compactPageSize]: compact,
      })}
    >
      <Select
        value={pageSize}
        onChange={(e) => {
          setPageSize(e.target.value as number);
          setPage(0);
        }}
        renderValue={PageSizeInput}
        IconComponent={CaretIcon}
        disabled={items < 10}
        id="page-size-selector"
      >
        <MenuItem key="10" value={10} children="10" id="page-size-10" />
        <MenuItem key="25" value={25} children="25" id="page-size-25" />
        <MenuItem key="50" value={50} children="50" id="page-size-50" />
        <MenuItem key="100" value={100} children="100" id="page-size-100" />
        {includeAll && <MenuItem key="All" value={items} children="All" />}
      </Select>
    </FormControl>
  );
}
