import { gql } from "@apollo/client";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { useIntercom } from "react-use-intercom";
import { useTrack } from "src/analytics";
import { DenyIcon } from "src/components/AssembleIcons/Small/DenyIcon";
import { useAuth } from "src/components/Auth/AuthContext";
import { CompNoteModal2 } from "src/components/CompNoteModal/CompNoteModal2";
import { useURLSearchParams } from "src/models/URLSearchParams";
import {
  DenyButton2_participant,
  RecommendationReviewStatus,
} from "../../../../../__generated__/graphql";
import { CompNoteModalTextfield } from "../../../../../components/CompNoteModal/CompNoteModalTextfield";
import { useSubmitRecReviews } from "../../../../../mutations/CompRecommendation";
import { PURPLE_1, RED } from "../../../../../theme";
import { useTableData } from "../../Contexts/TableDataContext2";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    display: "flex",
    padding: theme.spacing(0.25),
  },
  hidden: {
    visibility: "hidden",
  },
  disabledButton: {
    opacity: 0.3,
  },
}));

type Props = {
  employee: DenyButton2_participant;
  compCycleId: number;
  disabled?: boolean;
  recIsRejected?: boolean;
  hidden?: boolean;
  hideTooltip?: boolean;
};

export function DenyButton2({
  employee,
  compCycleId,
  disabled = false,
  recIsRejected,
  hidden = false,
  hideTooltip = false,
}: Props): JSX.Element {
  const classes = useStyles();
  const intercom = useIntercom();
  const { selectedCurrency } = useTableData();
  const { trackEvent } = useTrack();
  const { permissions } = useAuth();
  const urlSearchParams = useURLSearchParams();
  const [isDenyModalOpen, setIsDenyModalOpen] = useState(false);

  const managerId = urlSearchParams.get("manager");
  const currencyCode = selectedCurrency === "all" ? null : selectedCurrency;
  const submitRecReview = useSubmitRecReviews(currencyCode);
  return (
    <>
      {recIsRejected === true ? (
        <Tooltip
          title="Rejected"
          placement="top"
          arrow
          disableHoverListener={hideTooltip}
          enterDelay={500}
        >
          <div
            className={clsx(classes.iconContainer, {
              [classes.hidden]: hidden,
            })}
          >
            <DenyIcon color={RED} width="16px" height="21px" />
          </div>
        </Tooltip>
      ) : (
        <Tooltip
          title="Reject Request"
          placement="top"
          arrow
          disableHoverListener={hideTooltip}
          enterDelay={500}
        >
          <span className={clsx({ [classes.hidden]: hidden })}>
            <IconButton
              id="review-deny-icon"
              disabled={disabled}
              onClick={(event) => {
                // prevent employee row from getting selected
                event.stopPropagation();
                setIsDenyModalOpen(true);
              }}
              size="small"
              className={clsx({ [classes.disabledButton]: disabled })}
            >
              <DenyIcon color={PURPLE_1} width="16px" height="21px" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {isDenyModalOpen && (
        <CompNoteModal2
          employee={employee.subject}
          onClose={() => setIsDenyModalOpen(false)}
        >
          <CompNoteModalTextfield
            note={""}
            buttonTitle={"Reject Request"}
            onSave={async (note: string) => {
              // if we are an HRBP then we will be submitting the request
              // on behalf of the manager we are filtering under
              await submitRecReview({
                data: [
                  {
                    note,
                    compCycleId,
                    subjectId: employee.subjectId,
                    status: RecommendationReviewStatus.DENIED,
                  },
                ],
                compCycleId,
                actingManagerEmployeeId:
                  managerId != null && permissions.isHRBP()
                    ? Number.parseInt(managerId)
                    : null,
              });
              setIsDenyModalOpen(false);
              trackEvent({
                object: "Review Requests",
                subArea: "Deny Button",
                action: "Submitted",
              });
              intercom.trackEvent("Comp Review Rejected");
            }}
          />
        </CompNoteModal2>
      )}
    </>
  );
}

DenyButton2.fragments = {
  participant: gql`
    ${CompNoteModal2.fragments.employee}
    fragment DenyButton2_participant on CompCycleParticipant {
      subjectId
      compCycleId
      subject {
        ...CompNoteModal2_employee
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
      }
    }
  `,
};
