import { gql } from "@apollo/client";
import { createContext, useContext, useState } from "react";
import { useTrack } from "../../analytics";
import { TotalEquitySliderProvider_portalConfig as PortalConfig } from "../../__generated__/graphql";
type Props = {
  children: React.ReactNode;
  portalConfig: PortalConfig | null;
};

const TotalEquitySliderContext = createContext<{
  defaultValue: number;
  value: number;
  values: { label: string; value: number }[];
  handleChange: (_: unknown, newValue: number | number[]) => void;
} | null>(null);

export function TotalEquitySliderProvider({
  children,
  portalConfig,
}: Props): JSX.Element | null {
  const { trackEvent } = useTrack();

  const [sharePriceTarget, setSharePriceTarget] = useState<number>();

  const handleChange = (_: unknown, newValue: number | number[]) => {
    trackEvent({
      area: "Employee Portal",
      subarea: "Equity Slider",
      object: "Equity Slider",
      action: "Changed",
    });

    setSharePriceTarget(newValue as number);
  };

  if (portalConfig === null) {
    return (
      <TotalEquitySliderContext.Provider value={null}>
        {children}
      </TotalEquitySliderContext.Provider>
    );
  }

  const values = portalConfig.exitOutcomes.map((value, index) => ({
    label: portalConfig.xAxisAnnotations[index],
    value,
  })) as {
    label: string;
    value: number;
  }[];

  return (
    <TotalEquitySliderContext.Provider
      value={{
        defaultValue: portalConfig.defaultExitOutcome,
        value: sharePriceTarget ?? portalConfig.defaultExitOutcome,
        values: values,
        handleChange,
      }}
    >
      {children}
    </TotalEquitySliderContext.Provider>
  );
}

export function useTotalEquitySlider(): {
  defaultValue: number;
  value: number;
  values: { label: string; value: number }[];
  handleChange: (_: unknown, newValue: number | number[]) => void;
} | null {
  return useContext(TotalEquitySliderContext);
}

TotalEquitySliderProvider.fragments = {
  portalConfig: gql`
    fragment TotalEquitySliderProvider_portalConfig on PortalConfig {
      id
      exitOutcomes
      defaultExitOutcome
      xAxisAnnotations
    }
  `,
};
