import { Button, makeStyles, Theme } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTrack } from "../../../../analytics";
import { AssembleTypography } from "../../../../components/AssembleTypography";
import { InfoBanner } from "../../../../components/InfoBanner";
import { GRAY_5, WHITE } from "../../../../theme";

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    display: "flex",
    columnGap: theme.spacing(1),
  },
  bannerButton: {
    borderRadius: "4px",
    boxShadow: "0px 1px 3px rgba(10, 36, 64, 0.1)",
    border: `1px solid ${GRAY_5}`,
    backgroundColor: WHITE,
    marginTop: theme.spacing(1.5),
    maxWidth: "100%",
    "&:hover": {
      backgroundColor: WHITE,
    },
  },
}));

export const AccessConfigBanner = () => {
  const classes = useStyles();
  const { trackEvent } = useTrack();

  const handleClick = () => {
    trackEvent({
      area: "Employee Portal",
      subArea: "Portal Access",
      object: "Configure Portal Button",
      action: "Clicked",
    });
  };

  return (
    <InfoBanner>
      <AssembleTypography variant="productParagraphLarge">
        Help your employees better understand the value of their compensation.
        Go to the portal configuration page to choose which future outcomes to
        illustrate on Employee Portal or update your share price valuation.
      </AssembleTypography>
      <div className={classes.buttonContainer}>
        <Button
          component={Link}
          to="/settings/portal-config"
          variant="outlined"
          color="primary"
          onClick={handleClick}
          className={classes.bannerButton}
        >
          Configure Portal
        </Button>
        <Button
          component={Link}
          to="/settings/equity"
          variant="outlined"
          color="primary"
          onClick={handleClick}
          className={classes.bannerButton}
        >
          Configure Company Valuation
        </Button>
      </div>
    </InfoBanner>
  );
};
