import { getXTicksForTimeline } from "../../../models/Chart";

export const EMPTY_Y_INTERVALS = [0, 15_000, 30_000, 45_000, 60_000, 75_000];

// if the chart has no data, then we are going to arbitrarily set the dates
// on the chart to go form a year in the past from today, to 3 years in the
// future from today since most equity vesting schedules are done on a
// 3 year schedule
export const EMPTY_X_INTERVALS = (now: Date): number[] => {
  return getXTicksForTimeline(
    new Date(new Date().setFullYear(now.getFullYear() - 1)),
    new Date(new Date().setFullYear(now.getFullYear() + 3))
  ).map((t) => t.toMillis());
};

// Depending on the max total, we pick a suitable tick interval
export const Y_INTERVALS = [
  10_000_000, 5_000_000, 2_500_000, 1_000_000, 500_000, 250_000, 100_000,
  50_000, 25_000, 10_000, 5_000, 2_500, 1_000, 500, 250, 100, 50, 25, 10, 5, 2,
];

export const formatVestingEvents = (
  vestEvents: {
    vestingDate: GraphQL_DateTime;
    unitCount: number;
    grantName: string;
    grant: { vestingStartDate: GraphQL_DateTime };
  }[]
): {
  vestingDate: string;
  unitCount: number;
  grantName: string;
  currentUnitCount: number;
}[] => {
  const data = vestEvents
    .flatMap(
      ({ vestingDate, unitCount, grantName, grant: { vestingStartDate } }) => [
        {
          vestingDate: String(vestingStartDate),
          unitCount: 0,
          grantName,
          currentUnitCount: 0,
        },
        {
          vestingDate: String(vestingDate),
          unitCount,
          grantName,
          currentUnitCount: unitCount,
        },
      ]
    )
    .sort(
      (a, b) =>
        new Date(a.vestingDate).getTime() - new Date(b.vestingDate).getTime()
    );

  data.forEach((point, index) => {
    if (index > 0 && index < data.length) {
      point.unitCount = point.unitCount + data[index - 1].unitCount;
    }
  });
  return data;
};
