import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Noun } from "../../__generated__/graphql";
import { AccessBoundary } from "../../components/AccessBoundary";
import { useAuth } from "../../components/Auth/AuthContext";
import { NoFeatureAccess } from "../../components/EmptyState/EmptyState";
import { UserPermissions } from "../../models/UserPermissions";
import {
  EmployeeDetailDrawer,
  useEmployeeDetail,
} from "./EmployeeDetailDrawer/EmployeeDetailDrawer";
import PeopleWithPagination from "./People";
import { PeopleHeader } from "./PeopleHeader";
import { PeopleLayout } from "./PeopleLayout";
import { YourTeam } from "./YourTeam";

export function canSeeOrganizationView(permissions: UserPermissions): boolean {
  return [Noun.Employee, Noun.AccessControl, Noun.JobStructure].every((noun) =>
    permissions.canViewGlobal(noun)
  );
}

export function canSeeYourTeamView(permissions: UserPermissions): boolean {
  return (
    [Noun.JobStructure, Noun.CompStructure].every((noun) =>
      permissions.canViewAny(noun)
    ) && canSeeOtherEmployeesData(permissions)
  );
}

function canSeeOtherEmployeesData(permissions: UserPermissions): boolean {
  return permissions.isManager() || permissions.canViewGlobal(Noun.Employee);
}

export function PeoplePageRouter(): JSX.Element {
  const { permissions } = useAuth();
  const location = useLocation();
  const employeeDetailId = useEmployeeDetail();

  return (
    <>
      <PeopleLayout
        header={
          <PeopleHeader
            showNavSwitch={
              canSeeOrganizationView(permissions) &&
              canSeeYourTeamView(permissions)
            }
          />
        }
        main={
          <>
            <Routes>
              <Route
                path="organization"
                element={
                  <AccessBoundary
                    verb="view"
                    scope="global"
                    every={[
                      Noun.Employee,
                      Noun.AccessControl,
                      Noun.JobStructure,
                    ]}
                    fallback={<NoFeatureAccess feature="People" />}
                  >
                    <PeopleWithPagination />
                  </AccessBoundary>
                }
              />
              <Route
                path="team"
                element={
                  <AccessBoundary
                    verb="view"
                    scope="any"
                    every={[
                      Noun.Employee,
                      Noun.JobStructure,
                      Noun.CompStructure,
                    ]}
                    fallback={
                      <Navigate
                        to={`../organization${location.search}`}
                        replace
                      />
                    }
                  >
                    <YourTeam />
                  </AccessBoundary>
                }
              />
              <Route
                path="*"
                element={
                  canSeeOrganizationView(permissions) ||
                  !canSeeYourTeamView(permissions) ? (
                    <Navigate
                      to={`../organization${location.search}`}
                      replace
                    />
                  ) : (
                    <Navigate to={`../team${location.search}`} replace />
                  )
                }
              />
            </Routes>
          </>
        }
      />
      <EmployeeDetailDrawer id={employeeDetailId} />
    </>
  );
}
