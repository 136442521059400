import { IconButton, makeStyles } from "@material-ui/core";
import { InfoIcon } from "src/components/AssembleIcons/Brand/InfoIcon";
import { GRAY_4, PURPLE_1, WHITE } from "../../../theme";
import { ExplanatoryTooltip } from "../../ExplanatoryTooltip";

const useStyles = makeStyles(() => ({
  tooltip: {
    padding: "5px",
  },
  tooltipBody: {
    display: "flex",
    flexDirection: "column",
  },
  calculationText: {
    color: WHITE,
  },
}));

export const CompaRatioTooltip = () => {
  const classes = useStyles();
  return (
    <ExplanatoryTooltip
      title="Compa-Ratio"
      placement="top"
      width="420px"
      body={
        <div className={classes.tooltipBody}>
          <div>
            Compa-ratio is a measure that expresses your current salary as a
            percentage of your salary band's midpoint. This metric helps provide
            your relative position within your salary band, where 100%
            represents your salary being at the midpoint of your salary band.
          </div>
          <p />
          <div>We calculate it as follows:</div>
          <div className={classes.calculationText}>
            Your Salary / [(Min of Salary Band + Max of Salary Band) / 2]
          </div>
        </div>
      }
    >
      <IconButton size="small">
        <InfoIcon color={GRAY_4} hoverColor={PURPLE_1} inline />
      </IconButton>
    </ExplanatoryTooltip>
  );
};
