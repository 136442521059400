import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const WillisTowersWatsonLogo = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width} height={height} rx="2" fill="#7F35B2" />
      <path
        d="M14.5069 6.738L13.236 10.0059H12.1657L11.6306 8.18098L11.0913 10.0059H10.0219L9.08207 7.5685H8.49678V8.66613C8.49678 9.18124 8.70581 9.26612 8.99177 9.26612C9.06754 9.26462 9.14293 9.25514 9.21669 9.23783L9.50933 9.99676C9.28139 10.0524 9.04752 10.0803 8.81284 10.08C7.83289 10.08 7.41483 9.68137 7.41483 8.74851V7.5685H6.96834L6.021 10.0059H4.95076L4.41647 8.18098L3.87549 10.0059H2.80609L1.54688 6.738H2.7601L3.39305 8.71273L3.99673 6.738H4.83286L5.4424 8.71273L6.07201 6.738H7.41483V6.15049L8.49678 5.91998V6.738H9.97589L10.608 8.71273L11.2117 6.738H12.0478L12.6574 8.71273L13.2845 6.738H14.5069Z"
        fill="white"
      />
    </svg>
  </ColorableSVG>
);
