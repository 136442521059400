import { createContext, useContext, useState } from "react";
import { noop } from "src/test-helpers";

export enum PhaseComplexToggleOptions {
  ALL_MANAGERS = "ALL MANAGERS",
  IN_A_PHASE = "IN A PHASE",
  NOT_IN_A_PHASE = "NOT IN A PHASE",
  THIS_PHASE = "THIS PHASE",
}

const PhaseComplexToggleContext = createContext<{
  selectedFilter: PhaseComplexToggleOptions;
  setSelectedFilter: React.Dispatch<
    React.SetStateAction<PhaseComplexToggleOptions>
  >;
}>({
  selectedFilter: PhaseComplexToggleOptions.ALL_MANAGERS,
  setSelectedFilter: () => noop,
});

export function usePhaseComplexToggle() {
  return useContext(PhaseComplexToggleContext);
}

export function PhaseComplexToggleProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [selectedFilter, setSelectedFilter] =
    useState<PhaseComplexToggleOptions>(PhaseComplexToggleOptions.ALL_MANAGERS);

  return (
    <PhaseComplexToggleContext.Provider
      value={{
        selectedFilter,
        setSelectedFilter,
      }}
    >
      {children}
    </PhaseComplexToggleContext.Provider>
  );
}
