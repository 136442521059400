import { Card, CardContent, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { HierarchyNode } from "d3";
import {
  GRAY_1,
  GRAY_2,
  GRAY_4,
  GRAY_6,
  GRAY_7,
  PURPLE_1,
  WHITE,
} from "src/theme";
import { CompCycleOrganizationChart_employee as Employee } from "src/__generated__/graphql";
import { LayerIcon } from "../AssembleIcons/Small/LayerIcon";
import { AssembleTypography } from "../AssembleTypography";
import { EMPLOYEE_NODE_WIDTH } from "../OrganizationHierarchy/shared";
import {
  useCompCycleOrganizationChartSelectionData,
  useCompCycleOrganizationChartViewsData,
} from "./CompCycleOrganizationChartContext";

const useStyles = makeStyles((theme) => ({
  MuiCard: {
    root: {
      boxShadow: "none",
    },
  },
  synthetic: {
    pointerEvents: "none",
  },
  container: {
    position: "relative",
    boxShadow: "none",
  },
  stacked: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1,
    border: `1px solid ${GRAY_6}`,
    boxShadow: "none",
    transition: "all 125ms",

    width: `${EMPLOYEE_NODE_WIDTH}px`, // width takes the full node width
    height: "7.25rem", // width takes a portion of the node width
    transform: "rotate(-4deg)",

    "&:hover": {
      border: `1px solid ${PURPLE_1}`,
      transition: "all 125ms",
    },
  },
  viewing: {
    cursor: "default",
    border: `1px solid ${GRAY_6}`,
    boxShadow: "none",

    "&:hover": {
      border: `1px solid ${PURPLE_1}`,
      transition: "all 125ms",
    },
  },
  selected: {
    backgroundColor: GRAY_7,
    border: `1px solid ${PURPLE_1}`,
    boxShadow: "none",

    "&:hover": {
      backgroundColor: WHITE,
    },
  },
  assigned: {
    border: `1px solid ${GRAY_6}`,
    backgroundColor: GRAY_7,
    boxShadow: "none",

    "&:hover": {
      cursor: "default",
      backgroundColor: GRAY_7,
    },
  },
  cardContent: {
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3, 0),
  },
  section: {
    display: "flex",
    flexDirection: "column",
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(0.5),
  },
  layer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    columnGap: theme.spacing(0.5),
  },
  layerCopy: { marginTop: "3px" },
}));

type Props = {
  node: HierarchyNode<Employee>;
  isNodeSelected: boolean;
  isNodeAssigned: boolean;
  isSyntheticNode: boolean;
};

export function EmployeeCard({
  node,
  isNodeSelected,
  isNodeAssigned,
  isSyntheticNode,
}: Props): JSX.Element {
  const classes = useStyles();

  const { layerDepth } = useCompCycleOrganizationChartSelectionData();
  const { chartState } = useCompCycleOrganizationChartViewsData();

  const {
    data: {
      _directSubordinates: directReportsCount,
      _totalSubordinates: totalReportsCount,
      activeEmployment,
    },
    depth,
  } = node;

  const isNodeSelectable =
    depth !== layerDepth && !isSyntheticNode && totalReportsCount > 0;

  const indirectReportsCount = totalReportsCount - directReportsCount;

  const isExpanded = node.children != null;

  // the title here acts as a tooltip, so we will only show it
  // if we can select this node, we are in the edit state, and
  // the layer isn't already selected
  const renderTitle =
    isNodeSelectable && chartState === "edit" && !isNodeSelected;

  return (
    <div
      data-cy={`employee-card-layer-${depth}`}
      className={clsx(classes.container, {
        [classes.synthetic]: isSyntheticNode,
      })}
    >
      {directReportsCount > 0 && !isExpanded && (
        <Card
          className={clsx(classes.stacked, {
            [classes.selected]: isNodeSelected,
            [classes.assigned]: isNodeAssigned,
          })}
        />
      )}
      <Card
        title={renderTitle ? `Select Layer ${depth}` : undefined}
        className={clsx({
          [classes.selected]: isNodeSelected,
          [classes.assigned]: isNodeAssigned || !isNodeSelectable,
          [classes.viewing]: chartState === "view",
        })}
      >
        <CardContent className={classes.cardContent}>
          <div className={classes.section}>
            <div className={classes.item}>
              <AssembleTypography
                variant="productSmallBold"
                textColor={
                  isNodeAssigned || !isNodeSelectable ? GRAY_4 : GRAY_1
                }
              >
                {node.data.displayName}
              </AssembleTypography>
              {!isSyntheticNode && (
                <span className={classes.layer}>
                  <LayerIcon
                    inline
                    color={
                      isNodeAssigned || !isNodeSelectable ? GRAY_4 : GRAY_1
                    }
                  />
                  <AssembleTypography
                    className={classes.layerCopy}
                    variant="productSmallBold"
                    textColor={
                      isNodeAssigned || !isNodeSelectable ? GRAY_4 : GRAY_1
                    }
                  >
                    {depth}
                  </AssembleTypography>
                </span>
              )}
            </div>
            <div className={classes.item}>
              <AssembleTypography
                variant="productParagraphSmall"
                textColor={
                  isNodeAssigned || !isNodeSelectable ? GRAY_4 : GRAY_2
                }
              >
                {activeEmployment?.jobTitle ?? "-"}
              </AssembleTypography>
            </div>
          </div>
          <div className={classes.section}>
            <div className={classes.item}>
              <AssembleTypography
                variant="productEyebrowSmall"
                textColor={GRAY_4}
              >
                Directs
              </AssembleTypography>
              <AssembleTypography
                variant="productEyebrowSmall"
                textColor={GRAY_4}
              >
                {directReportsCount}
              </AssembleTypography>
            </div>
            <div className={classes.item}>
              <AssembleTypography
                variant="productEyebrowSmall"
                textColor={GRAY_4}
              >
                Indirects
              </AssembleTypography>
              <AssembleTypography
                variant="productEyebrowSmall"
                textColor={GRAY_4}
              >
                {indirectReportsCount}
              </AssembleTypography>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
