import { makeStyles } from "@material-ui/core";
import { ReactNode } from "react";
import { GRAY_3 } from "../../theme";

const useStyles = makeStyles((theme) => ({
  label: {
    display: "block",
    marginBlock: theme.spacing(2, 1),
    width: "100%",
    color: GRAY_3,
    fontSize: "0.75rem",
    fontWeight: 700,
    textTransform: "uppercase",
  },
}));

type Props = {
  htmlFor: string;
  children: ReactNode;
};
export function AssembleLabel({ htmlFor, children }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <label className={classes.label} htmlFor={htmlFor}>
      {children}
    </label>
  );
}
