import { AssembleTypography } from "src/components/AssembleTypography";
import { StyledBlockButton } from "../StyledBlockButton/StyledBlockButton";

const UNDERLINE = "UNDERLINE";

export type UnderlineStyleProps = {
  editorState: Draft.EditorState;
  onClick: () => unknown;
};

export const UnderlineStyle = (props: UnderlineStyleProps): JSX.Element => {
  const { editorState, onClick } = props;
  const italicIsActive = editorState.getCurrentInlineStyle().has(UNDERLINE);

  return (
    <StyledBlockButton active={italicIsActive} onClick={onClick}>
      <AssembleTypography
        style={{ fontSize: "18px", textDecoration: "underline" }}
      >
        U
      </AssembleTypography>
    </StyledBlockButton>
  );
};
