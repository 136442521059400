import { ArrayValue } from "../utils";
import { GetDepartments } from "../__generated__/graphql";

type Department = ArrayValue<GetDepartments["departments"]>;

export class ComputedDepartment {
  readonly id: number;
  readonly name: string;
  readonly laddersCount: number;
  readonly positionsCount: number;
  readonly levelMin: number;
  readonly levelMax: number;

  constructor(department: Department) {
    this.id = department.id;
    this.name = department.name;
    this.laddersCount = department.ladders.length;
    this.positionsCount = department.ladders.flatMap(
      (ladder) => ladder.positions
    ).length;

    const { min, max } = this.getLevelsRange(department);
    this.levelMin = min;
    this.levelMax = max;
  }

  private getLevelsRange(department: Department): {
    min: number;
    max: number;
  } {
    const sortedLevels = department.ladders
      .flatMap((ladder) => ladder.positions.flatMap((p) => p.level))
      .sort((a, b) => a - b);

    if (sortedLevels.length === 0) return { min: 0, max: 0 };

    const min = sortedLevels[0];
    const max = sortedLevels[sortedLevels.length - 1];
    return { min, max };
  }
}
