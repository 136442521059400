import { formatCurrency, preferredPrice } from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { memo } from "react";
import { EquityGrantMethod, GetOffer } from "../../__generated__/graphql";
import { hasStrikePrice } from "../../models/Offer";
import { BLUE_2 } from "../../theme";
import { NonNull } from "../../utils";
import Header from "./Header";
import { globalStyles } from "./style";

type CompStructure = NonNull<GetOffer["compStructure"]>;
type OfferConfig = NonNull<GetOffer["offerConfig"]>;
type Valuation = NonNull<GetOffer["valuation"]>;
type Organization = GetOffer["offer"]["organization"];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing(10),
    minHeight: "370px",
  },
}));

export type CompanyProps = {
  organization: Organization;
  valuation: Valuation;
  compStructure: CompStructure;
  offerConfig: OfferConfig;
};

type InfoRowProps = {
  highlight?: string;
  label: string;
  value: string;
};

const CompanyInfo = ({
  organization,
  valuation,
  compStructure,
  offerConfig,
}: CompanyProps) => {
  const classes = globalStyles();
  const localClasses = useStyles();

  const color = offerConfig.brandColorPrimary ?? BLUE_2;

  const showExerciseCost = compStructure.equityGrantTypes.every(hasStrikePrice);

  const InfoRow = ({ highlight, label, value }: InfoRowProps) => {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mb={3}
      >
        <Typography className={`${classes.label} ${classes.gray4}`}>
          {label}
          {Boolean(highlight) && <span style={{ color }}> {highlight}</span>}
        </Typography>
        <Typography className={`${classes.label} ${classes.gray2}`}>
          {value}
        </Typography>
      </Box>
    );
  };

  return (
    <Box className={localClasses.root}>
      <Header
        color={color}
        headline={organization.name}
        subHead="Company Details"
      />
      <Box flex={1}>
        <Typography className={classes.header}>
          Current Company Information
        </Typography>
        {compStructure.companyDescription?.split("\n").map((item, key) => {
          return (
            <Typography
              key={key}
              className={`${classes.content} ${classes.gray2}`}
              paragraph
            >
              {item}
            </Typography>
          );
        })}
      </Box>
      {offerConfig.showEquityInformation && (
        <Box flex={1} marginLeft={4}>
          <Typography className={classes.header}>Equity Information</Typography>
          {offerConfig.showFdso && (
            <InfoRow
              label="Fully-Diluted Shares Outstanding"
              value={formatNumeral(valuation.fdso)}
            />
          )}
          {compStructure.showValuation && (
            <InfoRow
              label="Company Valuation"
              value={formatCurrency(valuation.valuation, {
                notation: "compact",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              })}
            />
          )}
          {compStructure.equityGrantMethod === EquityGrantMethod.UNITS &&
            offerConfig.showCurrentEquityValue && (
              <InfoRow
                highlight={offerConfig.showSharePriceFootnote ? "*" : undefined}
                label={
                  showExerciseCost
                    ? offerConfig.sharePriceLabel
                    : "Value per Unit"
                }
                value={formatCurrency(
                  preferredPrice(valuation.fdso, valuation.valuation)
                )}
              />
            )}
          {showExerciseCost && (
            <InfoRow
              highlight={offerConfig.showSharePriceFootnote ? "**" : "*"}
              label="Current Strike Price per Share"
              value={formatCurrency(valuation.strikePrice)}
            />
          )}
          {offerConfig.showStage && (
            <InfoRow label="Company Stage" value={valuation.financingStage} />
          )}
          {offerConfig.showSharePriceFootnote && showExerciseCost && (
            <Box mt={5}>
              <Typography className={classes.footnote}>
                <span style={{ color }}>*</span>
                Calculated by Dividing the Company Valuation by the
                Fully-Diluted Shares Outstanding.
              </Typography>
            </Box>
          )}
          {showExerciseCost && (
            <Box mt={5}>
              <Typography className={classes.footnote}>
                <span style={{ color }}>
                  {offerConfig.showSharePriceFootnote ? "**" : "*"}
                </span>
                Current Strike Price shown as a reference. Your Strike Price
                will be determined by the Board after your start date. Your
                official Strike Price is subject to change from what is shown
                here.
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default memo(CompanyInfo);
