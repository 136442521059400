import { gql } from "@apollo/client";
import { exchangeFromTo } from "@asmbl/shared/currency";
import {
  add,
  isZero,
  money,
  Money,
  ratio,
  unitsOfTotalGrossValue,
} from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { ButtonBase, Link, makeStyles } from "@material-ui/core";
import { useState } from "react";
import {
  BudgetEquityDetail_valuation,
  EquityGrantMethod,
  RecItemInput,
} from "../../../__generated__/graphql";
import { useTrack } from "../../../analytics";
import { getAccurateSimpleCashLabel } from "../../../models/Currency";
import { PURPLE_1 } from "../../../theme";
import { useCompStructure } from "../../CompStructureContext";
import { useCurrencies } from "../../CurrenciesContext";
import { BudgetDetail } from "./BudgetDetail";

type Props = {
  valueUsed: Money;
  budgetType: string;
  items: RecItemInput[];
  valuation: BudgetEquityDetail_valuation;
  compCycleBudget?: Money | null;
};

function simpleLabel(n: number): string {
  return formatNumeral(n, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

const useStyles = makeStyles(() => ({
  toggleButton: {
    color: PURPLE_1,
    font: "unset",
    textTransform: "inherit",
  },
}));

export function BudgetEquityDetail({
  budgetType,
  items,
  valuation,
  compCycleBudget,
  valueUsed,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const classes = useStyles();
  const { compStructure } = useCompStructure();
  const { currencies, defaultCurrency } = useCurrencies();
  const [isCash, setIsCash] = useState(true);
  const valuationCurrency =
    currencies.get(valuation.valuation.currency) ?? defaultCurrency;

  const toggleCash = () => {
    const wasCash = isCash;
    setIsCash(!wasCash);
    trackEvent({
      object: "Equity Display in Budget Drawer",
      action: "Toggled",
      equityDisplay: wasCash ? "units" : "cash",
    });
  };

  const itemsUsed = items
    .flatMap<Money>((item) => {
      if (!item.recommendedCashValue) {
        // No change from this item, so skip it
        return [];
      }

      const itemCurrency = currencies.get(item.recommendedCashValue.currency);
      if (!itemCurrency) {
        // We can't convert this currency, so skip it.
        return [];
      }

      return exchangeFromTo(
        item.recommendedCashValue,
        itemCurrency,
        valuationCurrency
      );
    })
    .reduce(add, money(0, valuation.valuation.currency));

  const valueCurrency = currencies.get(valueUsed.currency) ?? valuationCurrency;

  const used = add(
    exchangeFromTo(valueUsed, valueCurrency, valuationCurrency),
    itemsUsed
  );

  const compCycleCurrency =
    (compCycleBudget && currencies.get(compCycleBudget.currency)) ??
    valuationCurrency;

  const allocated = compCycleBudget
    ? exchangeFromTo(compCycleBudget, compCycleCurrency, valuationCurrency)
    : null;

  const percentUsed =
    allocated === null || isZero(allocated)
      ? null
      : ratio(used, allocated) * 100;

  const usedUnits = unitsOfTotalGrossValue(
    valuation.fdso,
    valuation.valuation,
    used
  );
  const allocatedUnits = allocated
    ? unitsOfTotalGrossValue(valuation.fdso, valuation.valuation, allocated)
    : 0;

  return (
    <BudgetDetail
      budgetType={budgetType}
      percentUsed={percentUsed}
      title={
        compStructure?.equityGrantMethod !== EquityGrantMethod.CASH ? (
          <Link
            component={ButtonBase}
            className={classes.toggleButton}
            onClick={toggleCash}
          >
            {budgetType} {isCash ? "" : "Units"}
          </Link>
        ) : (
          `${budgetType}`
        )
      }
      usedOfAllocated={
        isCash ? (
          allocated ? (
            <>
              {getAccurateSimpleCashLabel(used)} of{" "}
              {getAccurateSimpleCashLabel(allocated)}
            </>
          ) : (
            getAccurateSimpleCashLabel(used)
          )
        ) : allocated ? (
          <>
            {simpleLabel(usedUnits)} of {simpleLabel(allocatedUnits)}{" "}
            {allocatedUnits === 1 ? "Unit" : "Units"}
          </>
        ) : (
          <>
            {simpleLabel(usedUnits)} {allocatedUnits === 1 ? "Unit" : "Units"}
          </>
        )
      }
    />
  );
}

BudgetEquityDetail.fragments = {
  valuation: gql`
    fragment BudgetEquityDetail_valuation on Valuation {
      id
      fdso
      valuation
    }
  `,
};
