import { gql, useLazyQuery } from "@apollo/client";
import { mapMaybe } from "@asmbl/shared/utils";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  OrganizationBudgetCostToMoveQuery,
  OrganizationBudgetCostToMoveQueryVariables,
} from "src/__generated__/graphql";
import { AssembleBarModal } from "src/components/AssembleBarModal";
import {
  useCreateCorrectionCompCycle,
  useCreateCycleEligibilityRules,
} from "src/mutations/CompCycle";
import { useEmplaceCompRecommendationsWithCompCycleId } from "src/mutations/CompRecommendation";
import { AssembleButton } from "../../../components/AssembleButton/AssembleButton";
import { CorrectionCompCycleDialog } from "./CorrectionCompCycleDialog";
import { EmployeeRecommendationMap } from "./CorrectionCostToMoveInnerPage";

type Props = {
  selectedEmployeesMap: EmployeeRecommendationMap;
  filter: { [key: string]: number[] | null | undefined };
  targetBandPoint: string;
  iconOnClickHandler: () => void;
};

export function ButtonBarModal({
  selectedEmployeesMap,
  filter,
  targetBandPoint,
  iconOnClickHandler,
}: Props): JSX.Element | null {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [step, setStep] = useState<
    "open" | "cycleCreation" | "recommendations" | "success" | "error"
  >("open");

  const [getBudgetData] = useLazyQuery<
    OrganizationBudgetCostToMoveQuery,
    OrganizationBudgetCostToMoveQueryVariables
  >(ButtonBarModal.query, {
    variables: {
      targetBandPoint,
      filter,
      employeeIds: [...selectedEmployeesMap.values()]
        .filter((e) => e.selected)
        .map((e) => e.recommendation.subjectId),
    },
  });

  const handleCycleClick = () => setModalOpen(true);
  const createCompCycle = useCreateCorrectionCompCycle();
  const emplaceCompRecommendations =
    useEmplaceCompRecommendationsWithCompCycleId();
  const updateEligibility = useCreateCycleEligibilityRules();

  const handleModalButtonClick = async () => {
    setStep("cycleCreation");
    const budgetData = await getBudgetData();
    if (!budgetData.data || budgetData.error) {
      setStep("error");
      return;
    }
    const { totalCostToMoveBudget } = budgetData.data.totalCostToMoveAnalysis;
    const compCycle = await createCompCycle({
      cashComponents: totalCostToMoveBudget.cashCompComponents,
      organizationBudget: totalCostToMoveBudget.organizationBudget.map(
        (budget) => ({
          targetBudget: budget.targetBudget,
          compType: budget.compType,
        })
      ),
      managerBudgets: totalCostToMoveBudget.managerBudgets.map((mgrBudget) => ({
        managerId: mgrBudget.managerId,
        costToMoveBudgets: mgrBudget.costToMoveBudgets.map((budget) => ({
          targetBudget: budget.targetBudget,
          compType: budget.compType,
        })),
      })),
    });

    if (!compCycle.data || compCycle.errors) {
      setStep("error");
    } else {
      const cycleId = compCycle.data.createBandPlacementCorrectionCompCycle.id;

      const selected = mapMaybe([...selectedEmployeesMap.values()], (emp) =>
        emp.selected && emp.recommendation.items.length > 0
          ? emp.recommendation
          : null
      );
      if (selectedEmployeeCount > 0) {
        setStep("recommendations");
        const recommendations = await emplaceCompRecommendations(
          cycleId,
          selected
        );
        if (
          !recommendations ||
          recommendations.emplaceCompRecommendations.participants.length === 0
        ) {
          setStep("error");
        }

        await updateEligibility(
          [[{ alwaysIncludeIds: selected.map((rec) => rec.subjectId) }]],
          cycleId
        );
      }
      setStep("success");
      return new Promise((_resolve) =>
        setTimeout(() => {
          navigate(`/comp-cycles/${cycleId}/requests`);
        }, 2000)
      );
    }
  };
  const selectedEmployeeCount = [...selectedEmployeesMap.values()].reduce(
    (acc, val) => acc + (val.selected ? 1 : 0),
    0
  );

  return (
    <>
      {modalOpen && (
        <CorrectionCompCycleDialog
          step={step}
          closeModal={() => {
            setModalOpen(false);
            setStep("open");
          }}
          createCompCycle={handleModalButtonClick}
        />
      )}
      <AssembleBarModal
        selectedCount={selectedEmployeeCount}
        iconOnClick={iconOnClickHandler}
      >
        <AssembleButton
          size="small"
          variant="contained"
          label="Create Comp Cycle to Close Band Placement Gaps"
          onClick={handleCycleClick}
          data-intercom-target="button-create-comp-cycle"
          disabled={selectedEmployeeCount === 0}
        />
      </AssembleBarModal>
    </>
  );
}

ButtonBarModal.query = gql`
  query OrganizationBudgetCostToMoveQuery(
    $targetBandPoint: String!
    $filter: GetTotalEmployeesInput
    $employeeIds: [Int!]!
  ) {
    totalCostToMoveAnalysis(target: $targetBandPoint, filter: $filter) {
      totalCostToMoveBudget(employeeIds: $employeeIds) {
        cashCompComponents
        organizationBudget {
          compType
          targetBudget
        }
        managerBudgets {
          managerId
          costToMoveBudgets {
            compType
            targetBudget
          }
        }
      }
    }
  }
`;
