import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const WarningOctagonIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Warning"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 7.5V12.75"
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4173 3H8.58274C8.48425 3 8.38672 3.0194 8.29573 3.05709C8.20473 3.09478 8.12205 3.15003 8.05241 3.21967L3.21967 8.05241C3.15003 8.12205 3.09478 8.20473 3.05709 8.29573C3.0194 8.38672 3 8.48425 3 8.58274V15.4173C3 15.5158 3.0194 15.6133 3.05709 15.7043C3.09478 15.7953 3.15003 15.8779 3.21967 15.9476L8.05241 20.7803C8.12205 20.85 8.20473 20.9052 8.29573 20.9429C8.38672 20.9806 8.48425 21 8.58274 21H15.4173C15.5158 21 15.6133 20.9806 15.7043 20.9429C15.7953 20.9052 15.8779 20.85 15.9476 20.7803L20.7803 15.9476C20.85 15.8779 20.9052 15.7953 20.9429 15.7043C20.9806 15.6133 21 15.5158 21 15.4173V8.58274C21 8.48425 20.9806 8.38672 20.9429 8.29573C20.9052 8.20473 20.85 8.12205 20.7803 8.05241L15.9476 3.21967C15.8779 3.15003 15.7953 3.09478 15.7043 3.05709C15.6133 3.0194 15.5158 3 15.4173 3Z"
        className="stroke"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M12 16.5V16.508H12.007"
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
