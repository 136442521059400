import { ISettings } from "@flatfile/adapter";
import { DECIMAL_VALIDATOR, INTEGER_VALIDATOR } from "./Validators";

export const TargetRecurringBonusFields: ISettings["fields"] = [
  {
    label: "Target Rec. Bonus ($)",
    key: "targetRecurringBonus",
    validators: [
      {
        validate: "regex_matches",
        regex: INTEGER_VALIDATOR,
        error: "Must be a whole number",
      },
    ],
  },
  {
    label: "Target Rec. Bonus (% of Salary)",
    key: "targetRecurringBonusPercent",
    validators: [
      {
        validate: "regex_matches",
        regex: DECIMAL_VALIDATOR,
        error: "Must be a number",
      },
    ],
  },
  {
    label: "Target Rec. Bonus Notes",
    key: "targetRecurringBonusNotes",
  },
];
