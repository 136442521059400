import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const SettingsIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Settings"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M7.99999 11C9.65684 11 11 9.65684 11 7.99999C11 6.34314 9.65684 5 7.99999 5C6.34314 5 5 6.34314 5 7.99999C5 9.65684 6.34314 11 7.99999 11Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M3.48681 11.183L3.412 12.3433C3.40663 12.4177 3.41772 12.4923 3.44448 12.5619C3.47124 12.6315 3.51301 12.6943 3.56682 12.7459C4.28596 13.428 5.15619 13.9304 6.1065 14.2122C6.17809 14.233 6.25338 14.2377 6.32702 14.2261C6.40066 14.2145 6.47084 14.1868 6.53255 14.145L7.49997 13.5001H8.49996L9.46741 14.145C9.52912 14.1868 9.59929 14.2145 9.67293 14.2262C9.74657 14.2378 9.82187 14.233 9.89345 14.2122C10.8438 13.9305 11.714 13.428 12.4331 12.7459C12.4869 12.6943 12.5287 12.6315 12.5555 12.5619C12.5822 12.4923 12.5933 12.4177 12.588 12.3434L12.5132 11.1831L13.0132 10.3171L14.0554 9.8017C14.1225 9.76917 14.1816 9.72225 14.2285 9.66429C14.2753 9.60632 14.3089 9.53873 14.3266 9.46633C14.5578 8.50248 14.5578 7.49762 14.3266 6.53377C14.3088 6.46137 14.2753 6.39378 14.2284 6.33581C14.1816 6.27785 14.1225 6.23093 14.0554 6.1984L13.0132 5.68304L12.5132 4.81702L12.588 3.65672C12.5934 3.58236 12.5823 3.50774 12.5555 3.43816C12.5288 3.36857 12.487 3.30575 12.4332 3.25415C11.714 2.57203 10.8438 2.06961 9.8935 1.78788C9.82191 1.76708 9.74662 1.76231 9.67298 1.77392C9.59934 1.78554 9.52917 1.81324 9.46746 1.85507L8.50004 2.49998H7.50004L6.53259 1.85505C6.47088 1.81322 6.40071 1.78551 6.32707 1.77389C6.25343 1.76228 6.17814 1.76704 6.10655 1.78784C5.15624 2.06958 4.28602 2.57201 3.56688 3.25414C3.51307 3.30573 3.4713 3.36856 3.44453 3.43814C3.41777 3.50772 3.40668 3.58234 3.41205 3.6567L3.48684 4.81696L2.98685 5.68298L1.94459 6.19835C1.87751 6.23088 1.81843 6.27779 1.77155 6.33576C1.72467 6.39372 1.69115 6.46131 1.67337 6.53371C1.44221 7.49757 1.44221 8.50242 1.67338 9.46627C1.69116 9.53867 1.72468 9.60626 1.77156 9.66423C1.81843 9.7222 1.87752 9.76911 1.94459 9.80164L2.98681 10.317L3.48681 11.183Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
