import { gql } from "@apollo/client";
import { add, isZero } from "@asmbl/shared/money";
import { mapMaybe } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import {
  CashCompType,
  CompRecommendationInput,
  CompUnit,
  CorrectionCostToMoveInnerPage_costToMoveAnalysis as CostToMoveAnalysis,
  RecItemType,
} from "src/__generated__/graphql";
import { ButtonBarModal } from "./ButtonBarModal";
import { CorrectionCostToMoveCards } from "./CorrectionCostToMoveCards";
import { CorrectionCostToMoveDepartmentsTable } from "./CorrectionCostToMoveDepartmentsTable";
import { CorrectionCostToMoveEmployeesTable } from "./EmployeesTable/CorrectionCostToMoveEmployeesTable";

const useStyles = makeStyles(() => ({
  bottomSpacer: {
    height: "100px",
  },
}));

type Props = {
  data: CostToMoveAnalysis;
  filter: { [key: string]: number[] | null | undefined };
  targetBandPoint: string;
};

export type EmployeeRecommendationMap = Map<
  number,
  {
    recommendation: CompRecommendationInput;
    selected: boolean;
  }
>;

export function CorrectionCostToMoveInnerPage({
  data,
  filter,
  targetBandPoint,
}: Props): JSX.Element {
  const classes = useStyles();
  const [selectAll, setSelectAll] = useState(false);

  const [selectedEmployees, setSelectedEmployees] =
    useState<EmployeeRecommendationMap>(
      createEmployeeMap(data, targetBandPoint)
    );

  const handleAvatarClick = (id: number) => {
    const emp = selectedEmployees.get(id);
    if (emp) {
      const updatedEmp = { ...emp, selected: !emp.selected };
      setSelectedEmployees(new Map([...selectedEmployees, [id, updatedEmp]]));
    }
  };

  const handleSelectAll = () => {
    const selected = !selectAll;
    const newSelectedEmployees = new Map(
      Array.from(selectedEmployees).map(([id, emp]) => [
        id,
        { ...emp, selected: selected },
      ])
    );
    setSelectedEmployees(newSelectedEmployees);
    setSelectAll(selected);
  };

  return (
    <>
      <CorrectionCostToMoveCards data={data} />
      {data.totalAffectedEmployeeCount > 0 && data.byDepartment.length > 0 && (
        <>
          <CorrectionCostToMoveDepartmentsTable
            byDepartment={data.byDepartment}
          />
          <br />
        </>
      )}
      <CorrectionCostToMoveEmployeesTable
        employeeCTMs={data.employees}
        filter={filter}
        targetBandPoint={targetBandPoint}
        cashCompComponents={data.cashCompComponents}
        selectedEmployees={selectedEmployees}
        setSelectedEmployees={handleAvatarClick}
        selectAllHandler={handleSelectAll}
      />
      <div className={classes.bottomSpacer} />
      <ButtonBarModal
        selectedEmployeesMap={selectedEmployees}
        filter={filter}
        targetBandPoint={targetBandPoint}
        iconOnClickHandler={handleSelectAll}
      />
    </>
  );
}

CorrectionCostToMoveInnerPage.fragments = {
  costToMoveAnalysis: gql`
    ${CorrectionCostToMoveEmployeesTable.fragments.employeeCostToMove}
    ${CorrectionCostToMoveDepartmentsTable.fragments.departmentCostToMove}
    ${CorrectionCostToMoveCards.fragments.costToMoveAnalysis}
    fragment CorrectionCostToMoveInnerPage_costToMoveAnalysis on TotalCostToMoveAnalysis {
      cashCompComponents
      totalSpend
      totalAffectedEmployeeCount
      employees {
        costToMoveByComp {
          percentOfSalary
        }
        ...CorrectionCostToMoveEmployeesTable_employeeCostToMove
      }
      byDepartment {
        ...CorrectionCostToMoveDepartmentsTable_departmentCostToMove
      }
      ...CorrectionCostToMoveCards_costToMoveAnalysis
    }
  `,
};
function createEmployeeMap(
  data: CostToMoveAnalysis,
  targetBandPoint: string
):
  | Map<number, { recommendation: CompRecommendationInput; selected: boolean }>
  | (() => Map<
      number,
      { recommendation: CompRecommendationInput; selected: boolean }
    >) {
  return new Map(
    data.employees.map((e) => {
      return [
        e.employee.id,
        {
          recommendation: {
            items: mapMaybe(
              e.costToMoveByComp.map((cost) => {
                const recItemType =
                  CASH_COMP_TO_REC_TYPE[cost.compType as CashCompType];

                const isSalaryComp = cost.compType === CashCompType.SALARY;

                // if there is no CTM and no spend, or the salary has no CTM,
                // we don't need to recommend anything for this comp type
                const noCompForEmployee =
                  (isZero(cost.costToMove) && isZero(cost.spend)) ||
                  (isSalaryComp && isZero(cost.costToMove));

                if (recItemType == null || noCompForEmployee) {
                  return null;
                }

                // honor existing percent of salary if there is no CTM
                const compValue =
                  !isSalaryComp &&
                  cost.percentOfSalary &&
                  isZero(cost.costToMove)
                    ? {
                        recommendedPercentValue: cost.percentOfSalary,
                        unitType: CompUnit.PERCENT_OF_SALARY,
                        note: `Calculated from existing compensation's percent of salary`,
                      }
                    : {
                        // target comp items are calculated by
                        // adding existing comp to incremental change
                        recommendedCashValue: !isSalaryComp
                          ? add(cost.costToMove, cost.spend)
                          : cost.costToMove,
                        unitType: CompUnit.CASH,
                      };
                return {
                  note: isZero(cost.costToMove)
                    ? `Calculated from existing compensation`
                    : `Calculated from cost to move to ${targetBandPoint} bandpoint`,
                  recommendationType: recItemType,
                  ...compValue,
                };
              }),
              (item) => item
            ),
            subjectId: e.employee.id,
          },
          selected: false,
        },
      ];
    })
  );
}

const CASH_COMP_TO_REC_TYPE: Partial<Record<CashCompType, RecItemType>> = {
  [CashCompType.SALARY]: RecItemType.MARKET,
  [CashCompType.COMMISSION]: RecItemType.TARGET_COMMISSION,
  [CashCompType.SPOT_BONUS]: RecItemType.MERIT_BONUS,
  [CashCompType.RECURRING_BONUS]: RecItemType.TARGET_RECURRING_BONUS,
};
