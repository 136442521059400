import { gql, Reference, useMutation } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { useCallback } from "react";
import {
  CreateMarket,
  CreateMarketVariables,
  DeleteMarket,
  DeleteMarketVariables,
  UpdateMarket,
  UpdateMarketVariables,
} from "../__generated__/graphql";

const MARKET_FIELDS = gql`
  fragment MarketFields on Market {
    id
    name
    currencyCode
  }
`;

const CREATE_MARKET = gql`
  ${MARKET_FIELDS}
  mutation CreateMarket($data: MarketInput!) {
    createOneMarket(data: $data) {
      ...MarketFields
    }
  }
`;

type MarketInput = {
  name: string;
  currencyCode: CurrencyCode;
};

export function useCreateMarket(): (data: MarketInput) => Promise<boolean> {
  const [createMarket] = useMutation<CreateMarket, CreateMarketVariables>(
    CREATE_MARKET,
    {
      update(cache, { data }) {
        if (data == null) {
          return;
        }

        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            markets(existingMarkets: Reference[]) {
              const newMarket = cache.writeFragment({
                fragment: MARKET_FIELDS,
                fragmentName: "MarketFields",
                data: data.createOneMarket,
              });
              return [...existingMarkets, newMarket];
            },
          },
        });
      },
    }
  );

  return useCallback(
    ({ name, currencyCode }: MarketInput) =>
      createMarket({
        variables: {
          data: { name, currencyCode },
        },
      }).then(
        () => true,
        () => false
      ),
    [createMarket]
  );
}

const UPDATE_MARKET = gql`
  ${MARKET_FIELDS}
  mutation UpdateMarket($marketId: Int!, $data: MarketInput!) {
    updateOneMarket(marketId: $marketId, data: $data) {
      ...MarketFields
    }
  }
`;

export function useUpdateMarket(
  marketId: number
): (data: MarketInput) => Promise<boolean> {
  const [updateMarket] = useMutation<UpdateMarket, UpdateMarketVariables>(
    UPDATE_MARKET
  );

  return useCallback(
    ({ name, currencyCode }: MarketInput) =>
      updateMarket({
        variables: {
          marketId,
          data: { name, currencyCode },
        },
      }).then(
        () => true,
        () => false
      ),
    [updateMarket, marketId]
  );
}

const DELETE_MARKET = gql`
  mutation DeleteMarket($marketId: Int!) {
    deleteOneMarket(marketId: $marketId)
  }
`;

export function useDeleteMarket(marketId: number): () => Promise<boolean> {
  const [deleteMarket] = useMutation<DeleteMarket, DeleteMarketVariables>(
    DELETE_MARKET,
    {
      update(cache, { data }) {
        if (data == null || data.deleteOneMarket === false) {
          return;
        }

        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            markets(existingMarkets: Reference[], { readField }) {
              return existingMarkets.filter(
                (ref) => readField("id", ref) !== marketId
              );
            },
          },
        });
      },
    }
  );

  return useCallback(
    () =>
      deleteMarket({
        variables: {
          marketId,
        },
      }).then(
        () => true,
        () => false
      ),
    [deleteMarket, marketId]
  );
}
