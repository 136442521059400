import { gql } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useMemo, useState } from "react";
import { EditIcon } from "src/components/AssembleIcons/Brand/EditIcon";
import {
  useCreateLocationGroup,
  useUpdateLocationGroup,
} from "../../../mutations/Location";
import { GRAY_2, GRAY_4, PURPLE_2 } from "../../../theme";
import { getStringFromForm } from "../../../utils";
import {
  LocationGroupEditorDialog_locationGroup as LocationGroup,
  LocationGroupEditorDialog_markets as DialogMarket,
  LocationGroupEditor_locations as Location,
  LocationGroupEditor_markets as Market,
} from "../../../__generated__/graphql";
import { LocationsFormList } from "./LocationsFormList";

const useStyles = makeStyles((theme) => ({
  textDescription: {
    color: GRAY_2,
  },
  dialogContent: {
    marginTop: theme.spacing(1),
    paddingTop: 0,
    maxHeight: "calc(100vh - 240px)",
  },
}));

type LocationGroupEditorProps = {
  market: Market;
  existingLocationGroup?: LocationGroup;
  locations: Location[];
};

type DialogProps = {
  market: DialogMarket;
  existingLocationGroup?: LocationGroup;
  locations: Location[];
  onClose: () => unknown;
};

/**
 * We separate the Form from the button that opens it, to ensure that
 * state is fully reset when creating multiple Location Groups.
 */
function LocationGroupEditorDialog({
  market,
  existingLocationGroup,
  locations,
  onClose,
}: DialogProps): JSX.Element {
  const classes = useStyles();

  const existingLocationIds = useMemo(
    () => new Set(existingLocationGroup?.locations.map((l) => l.id)),
    [existingLocationGroup]
  );

  const [selectedLocationIds, setSelectedLocationIds] =
    useState(existingLocationIds);

  const [updateLocationGroup, { loading: isUpdateLocationGroupLoading }] =
    useUpdateLocationGroup();

  const [createLocationGroup, { loading: isCreateLocationGroupLoading }] =
    useCreateLocationGroup();

  const selectableLocations = useMemo(() => {
    return locations.filter((location) => {
      return (
        location.locationGroup?.id === undefined ||
        existingLocationIds.has(location.id)
      );
    });
  }, [locations, existingLocationIds]);

  const handleCancel = () => {
    setSelectedLocationIds(existingLocationIds);
    onClose();
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const name = getStringFromForm(formData, "name");
    const description = getStringFromForm(formData, "description");
    const locationIds = Array.from(selectedLocationIds);

    if (name === null) throw Error("Location group name cannot be empty.");

    if (existingLocationGroup) {
      await updateLocationGroup(existingLocationGroup.id, {
        name,
        description,
        locationIds,
      });
    } else {
      await createLocationGroup({
        name,
        description,
        locationIds,
        marketId: market.id,
      });
    }

    onClose();
  };

  return (
    <Dialog open onClose={onClose} fullWidth>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogTitle>
          {existingLocationGroup
            ? `Editing ${existingLocationGroup.name}`
            : "Create a New Location Group"}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography variant="body2" className={classes.textDescription}>
            Location groups let you apply adjustment factors to a group of
            locations in your organization.{" "}
            <strong>For example: Remote employees receive -10% salary.</strong>
          </Typography>
          <Box m={3} />
          <TextField
            name="name"
            variant="outlined"
            fullWidth
            label="Name"
            required
            placeholder="e.g., - Tier 1, Zone 1, PacNW, Remote, Europe…"
            InputLabelProps={{ shrink: true }}
            defaultValue={existingLocationGroup?.name}
          />
          <Box m={3} />
          <TextField
            name="description"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 500 }}
            label="Description"
            multiline
            placeholder="Describe this group so that it makes sense to people who don’t have context…"
            rows={8}
            InputLabelProps={{ shrink: true }}
            defaultValue={existingLocationGroup?.description}
          />
          <Box m={2} />
          <LocationsFormList
            locations={selectableLocations}
            selectedLocationIds={selectedLocationIds}
            setSelectedLocationIds={setSelectedLocationIds}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={
              isUpdateLocationGroupLoading || isCreateLocationGroupLoading
            }
          >
            {existingLocationGroup ? "Save" : "Create Location Group"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

LocationGroupEditorDialog.fragments = {
  locations: gql`
    ${LocationsFormList.fragments.locations}
    fragment LocationGroupEditorDialog_locations on Location {
      ...LocationsFormList_locations
      id
      locationGroup {
        id
      }
    }
  `,
  locationGroup: gql`
    fragment LocationGroupEditorDialog_locationGroup on LocationGroup {
      id
      name
      description
      isSourceComp
      locations {
        id
      }
    }
  `,
  markets: gql`
    fragment LocationGroupEditorDialog_markets on Market {
      id
    }
  `,
};

export function LocationGroupEditor({
  market,
  existingLocationGroup,
  locations,
}: LocationGroupEditorProps): JSX.Element {
  const [open, setOpen] = useState(false);
  return (
    <>
      {existingLocationGroup ? (
        <Tooltip title="Edit Location Group" placement="top">
          <IconButton size="small" onClick={() => setOpen(true)}>
            <EditIcon
              color={GRAY_4}
              hoverColor={PURPLE_2}
              height={"24px"}
              width={"24px"}
            />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setOpen(true)}
        >
          New Location Group
        </Button>
      )}
      {open && (
        <LocationGroupEditorDialog
          market={market}
          existingLocationGroup={existingLocationGroup}
          locations={locations}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
}

LocationGroupEditor.fragments = {
  locations: gql`
    ${LocationGroupEditorDialog.fragments.locations}
    fragment LocationGroupEditor_locations on Location {
      ...LocationGroupEditorDialog_locations
      id
    }
  `,
  locationGroup: gql`
    ${LocationGroupEditorDialog.fragments.locationGroup}
    fragment LocationGroupEditor_locationGroup on LocationGroup {
      ...LocationGroupEditorDialog_locationGroup
      id
    }
  `,
  markets: gql`
    ${LocationGroupEditorDialog.fragments.markets}
    fragment LocationGroupEditor_markets on Market {
      ...LocationGroupEditorDialog_markets
      id
    }
  `,
};
