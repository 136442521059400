import { gql, useQuery } from "@apollo/client";
import { ReactNode } from "react";
import { ArrayValue } from "../../../utils";
import {
  GetAtsCandidates,
  GetHasAtsIntegration,
} from "../../../__generated__/graphql";

type GraphQLAtsCandidate = ArrayValue<GetAtsCandidates["atsCandidates"]>;

export type AtsCandidate = Omit<GraphQLAtsCandidate, "__typename">;

type Props = {
  children: (
    candidates: AtsCandidate[] | undefined,
    hasAtsIntegration: boolean | undefined
  ) => ReactNode;
};

const atsIntegrationQuery = gql`
  query GetHasAtsIntegration {
    organization {
      id
      hasAtsIntegration
    }
  }
`;

export const atsCandidateQuery = gql`
  query GetAtsCandidates {
    atsCandidates {
      firstName
      lastName
      email
      jobTitle
    }
  }
`;

export function CandidateLoadingBoundary({ children }: Props): JSX.Element {
  /* If the client doesn't have an ATS integration, this will return an empty
   * array.
   *
   * We query these separately because hasAtsIntegration is much faster than
   * atsCandidates, and we want that information as soon as possible.
   */
  const { data: ats } = useQuery<GetAtsCandidates>(atsCandidateQuery);
  const { data: hasAts } = useQuery<GetHasAtsIntegration>(atsIntegrationQuery);
  return (
    <>{children(ats?.atsCandidates, hasAts?.organization.hasAtsIntegration)}</>
  );
}
