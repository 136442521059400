import { makeStyles } from "@material-ui/core";
import { theme } from "src/theme";
import { CompCycleOrganizationChart_employee as Employee } from "src/__generated__/graphql";
import { AssembleButton } from "../AssembleButton/AssembleButton";
import { NoOrgHierarchy } from "../EmptyState/EmptyState";
import { useCompCycleOrganizationChartViewsData } from "./CompCycleOrganizationChartContext";

const useStyles = makeStyles(() => ({
  contentInner: {
    marginTop: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    rowGap: theme.spacing(4),
  },
}));

type Props = {
  inactiveManagers: Employee[];
  onConfigureHome: () => unknown;
  updateStep: (step: number) => unknown;
};

export function MissingNodesView({
  inactiveManagers,
  onConfigureHome,
  updateStep,
}: Props): JSX.Element {
  const classes = useStyles();
  const affectedIds = inactiveManagers.map((emp) => emp.id).join(", ");
  const { setPageEditState } = useCompCycleOrganizationChartViewsData();

  const handleBack = () => {
    // close the org chart and go to the phase configuration page
    onConfigureHome();
    setPageEditState(false);
    updateStep(2);
  };

  const handleNext = () => {
    onConfigureHome();
    setPageEditState(false);
    updateStep(3);
  };

  return (
    <>
      <NoOrgHierarchy
        missingCopy={affectedIds}
        content={
          <div className={classes.contentInner}>
            <AssembleButton
              onClick={handleBack}
              label="Back"
              size="medium"
              variant="text"
            />
            <AssembleButton
              onClick={handleNext}
              label="Skip this step"
              size="medium"
              variant="text"
            />
          </div>
        }
      />
    </>
  );
}
