import { AppBar, IconButton, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { CheckCircleSolidIcon } from "src/components/AssembleIcons/Brand/CheckCircleSolidIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { DV_BLACK, GRAY_6, PURPLE_1, WHITE } from "src/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "640px",
    height: theme.spacing(7),
    top: "auto",
    bottom: theme.spacing(4),
    left: "50%",
    transform: "translateX(-50%)", // This offsets the left: 50% declaration so it centers the button bar modal
    backgroundColor: WHITE,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: theme.spacing(0, 1.5),
    alignItems: "center",
    gap: theme.spacing(2),
    border: `1px solid ${GRAY_6}`,
    borderRadius: "8px",
    boxShadow: "0px 15px 25px 0px rgba(10, 36, 64, 0.08)",
  },
  iconBorder: {
    border: `2px solid ${GRAY_6}`,
    height: 32,
    width: 32,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  selectedText: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

type Props = {
  selectedCount: number;
  children: JSX.Element;
  className?: string;
  iconOnClick: () => void;
};

export function AssembleBarModal({
  selectedCount,
  children,
  className,
  iconOnClick,
}: Props): JSX.Element | null {
  const classes = useStyles();

  return (
    <>
      <AppBar className={clsx(classes.root, className)} position="fixed">
        <div className={classes.selectedText}>
          <div className={classes.iconBorder}>
            <IconButton onClick={iconOnClick}>
              <CheckCircleSolidIcon height="24" width="24" color={PURPLE_1} />
            </IconButton>
          </div>
          <AssembleTypography variant="productTooltip" textColor={DV_BLACK}>
            {selectedCount} {selectedCount === 1 ? "person" : "people"} selected
          </AssembleTypography>
        </div>
        {children}
      </AppBar>
    </>
  );
}
