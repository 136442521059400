import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const CompReviewIcon = ({
  height = "24px",
  width = "24px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Compensation Review"
      viewBox="0 0 32 32"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M16 10.2002V11.3002"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M16 20.1001V21.7001"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M16 28C9.4 28 4 22.6 4 16C4 9.4 9.4 4 16 4C22.6 4 28 9.4 28 16"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M13.5 20.1002H17.2C18.3 20.1002 19.3 19.2002 19.3 18.0002C19.3 16.9002 18.4 15.9002 17.2 15.9002H14.7C13.6 15.9002 12.6 15.0002 12.6 13.8002C12.6 12.6002 13.5 11.7002 14.7 11.7002H18.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M28 20L20.2727 27.8571"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M28 24V20H24"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
