import { Button, makeStyles } from "@material-ui/core";
import {
  GRAY_1,
  GRAY_5,
  PURPLE_1,
  TRANSPARENT,
  WHITE,
} from "../../../../theme";

export type StyledBlockButtonProps = {
  active?: boolean;
  onClick: () => unknown;
  children: React.ReactNode;
};

export const HOVER_COLOR = PURPLE_1;
const DEFAULT_COLOR = GRAY_1;

export const buttonColor = (active = false): string => {
  return active ? WHITE : DEFAULT_COLOR;
};
export const backgroundColor = (active = false): string => {
  return active ? DEFAULT_COLOR : WHITE;
};

const useStyles = makeStyles(() => ({
  styledBlockButton: {
    backgroundColor: (props: StyledBlockButtonProps) =>
      backgroundColor(props.active),
    border: `1px solid ${TRANSPARENT}`,
    borderRadius: "5px",
    boxShadow: "0px 0px !important",
    fontSize: "18px",
    color: (props: StyledBlockButtonProps) => buttonColor(props.active),
    fontWeight: "bold",
    minWidth: "36px",
    height: "48px",
    transition:
      "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      border: `1px solid ${GRAY_5}`,
      backgroundColor: WHITE,
      color: HOVER_COLOR,
    },
    "&:active": {
      border: `1px solid ${GRAY_5}`,
      backgroundColor: WHITE,
    },
  },
}));

export const StyledBlockButton = (
  props: StyledBlockButtonProps
): JSX.Element => {
  const { children, onClick } = props;
  const classes = useStyles(props);

  return (
    <div onMouseDown={(e) => e.preventDefault()}>
      <Button className={classes.styledBlockButton} onClick={onClick}>
        {children}
      </Button>
    </div>
  );
};
