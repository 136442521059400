import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { AddToPhaseIcon } from "src/components/AssembleIcons/Brand/AddToPhaseIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import {
  CompCycleDataChangeHandler,
  CompCyclePhaseData,
} from "src/components/CompCycle/CompCycleWizard/types";
import { GRAY_1, GRAY_6, SIDEBAR_WIDTH, WHITE } from "src/theme";
import { AddPhaseSidebarCard } from "./AddPhasesSidebarCard";
import { PhaseSidebarCard } from "./PhaseSidebarCard";

export const COMP_CYCLE_HIERARCHY_CONFIGURATION_SIDEBAR_WIDTH = "11.5rem";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    height: "100%",
    position: "fixed",
    overflow: "auto",
    top: 0,
    left: SIDEBAR_WIDTH,
    width: COMP_CYCLE_HIERARCHY_CONFIGURATION_SIDEBAR_WIDTH,
    padding: theme.spacing(3, 2),
    zIndex: 10,
    backgroundColor: WHITE,
    borderLeft: `1px solid ${GRAY_6}`,
    borderRight: `1px solid ${GRAY_6}`,
  },
  sidebarContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
  },
  sidebarTitle: {
    display: "flex",
    rowGap: "0.25rem",
  },
  sidebarHeader: {
    display: "flex",
    alignItems: "center",
    columnGap: "0.375rem",
  },
  icon: { margin: theme.spacing(0.25, 0.5, 0, 0) },
  phasesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

type Props = {
  phases: CompCyclePhaseData[];
  handleChange: CompCycleDataChangeHandler;
};

export function CompCycleHierarchyConfigurationSidebar({
  phases,
  handleChange,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={clsx(classes.sidebar, classes.sidebarContainer)}>
      <div className={classes.sidebarTitle}>
        <AddToPhaseIcon color={GRAY_1} className={classes.icon} />
        <AssembleTypography variant="sidebarTitle">
          Approval Phases
        </AssembleTypography>
      </div>
      <div className={classes.phasesContainer}>
        {phases.map((phase, index) => (
          <PhaseSidebarCard
            key={phase.phaseOrder}
            phases={phases}
            phase={phase}
            previousPhase={index > 0 ? phases.at(index - 1) : undefined}
            nextPhase={phases.at(index + 1)}
            handleChange={handleChange}
          />
        ))}
        <AddPhaseSidebarCard phases={phases} handleChange={handleChange} />
      </div>
    </div>
  );
}
