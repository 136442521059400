import { gql } from "@apollo/client";
import { useCallback, useState } from "react";
import {
  ApprovalSettings_compCyclePhase,
  ApprovalSettings_employee,
} from "src/__generated__/graphql";
import {
  CompCycleDataChangeHandler,
  CompCyclePhaseData,
} from "../CompCycleWizard/types";
import { ApprovalSettingsModal } from "./ApprovalSettingsModal";
import { ApprovalsOrganizationChart } from "./ApprovalsOrganizationChart";
import { ApprovalsOrganizationChartView } from "./ApprovalsOrganizationChartView";

type Props = {
  employees: ApprovalSettings_employee[];
  phases: ApprovalSettings_compCyclePhase[];
  compCycleId: number;
};

export function ApprovalSettings({
  employees,
  phases,
  compCycleId,
}: Props): JSX.Element {
  const [updatedPhase, setUpdatedPhaseData] = useState<{
    phasesData: CompCyclePhaseData[];
  }>({ phasesData: [] });
  const handleChange: CompCycleDataChangeHandler = useCallback(
    (id, value) => {
      setUpdatedPhaseData((prevData) => ({ ...prevData, [id]: value }));
    },
    [setUpdatedPhaseData]
  );
  return (
    <>
      <ApprovalSettingsModal compCycleId={compCycleId} />
      <ApprovalsOrganizationChartView
        phases={phases}
        compCycleId={compCycleId}
        phaseData={updatedPhase.phasesData}
        handleChange={handleChange}
      >
        {(chart) => (
          <ApprovalsOrganizationChart
            data={employees}
            phases={phases}
            chart={chart}
            phaseData={updatedPhase.phasesData}
            handleChange={handleChange}
          />
        )}
      </ApprovalsOrganizationChartView>
    </>
  );
}

ApprovalSettings.fragments = {
  employee: gql`
    ${ApprovalsOrganizationChart.fragments.employee}
    ${ApprovalsOrganizationChartView.fragments.employee}
    fragment ApprovalSettings_employee on Employee2 {
      id
      ...ApprovalsOrganizationChart_employee
      ...ApprovalsOrganizationChartView_employee
    }
  `,
  compCyclePhase: gql`
    ${ApprovalsOrganizationChart.fragments.compCyclePhase}
    ${ApprovalsOrganizationChartView.fragments.compCyclePhase}
    fragment ApprovalSettings_compCyclePhase on CompCyclePhase2 {
      id
      ...ApprovalsOrganizationChart_compCyclePhase
      ...ApprovalsOrganizationChartView_compCyclePhase
    }
  `,
};
