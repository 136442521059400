import {
  basicComparator,
  Comparator,
  ComparatorWithOrder,
} from "@asmbl/shared/sort";
import {
  Box,
  makeStyles,
  SvgIcon,
  SvgIconProps,
  TableCell,
  TableCellProps,
  TableSortLabel,
} from "@material-ui/core";
import clsx from "clsx";
import { ReactNode, useState } from "react";
import SortDescIcon from "../assets/svgs/sort-desc.svg?react";
import { GRAY_4 } from "../theme";
import { AssembleTypography } from "./AssembleTypography";

export type Order = "asc" | "desc";

function ascendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  return basicComparator(a[orderBy], b[orderBy]);
}

function getDefaultComparator<T>(orderBy: keyof T): Comparator<T> {
  return (a, b) => ascendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: Comparator<T>) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type NoInfer<T> = [T][T extends never ? 0 : never];

/**
 * Custom React hook that wraps sort state and sort handler.
 * 
 * By default the keys of the objects can be used as sort fields.
 * You can also specify a list of 'custom' keys as the second parameter,
 * and as long as you provide comparators for those keys, you can sort
 * by them as well. (The internal types for this are messy, but necessary
 * to prevent Typescript from trying to infer your custom keys too aggressively)
 * 
 * @param array List of objects to sort.
 * @param defaultOrder Default sorting order.
 * @param defaultOrderBy Default key within the object to sort by 
 *        (e.g., name or id).
 * @param customComparators Mapping of object keys (optional) and custom keys 
 *        (required) to custom comparators
 * 
 * @example 
  const {
    sortedArray, order, orderBy, handleRequestSort
  } = useSort(positions, "level", "desc", {
    cashBands: (order) => (a, b) =>
      order === "asc"
        ? a.totalCompMax - b.totalCompMax
        : b.totalCompMax - a.totalCompMax,
  });

 * @example
  // Ladder and department are not direct fields on Employment, but we can sort 
  // by them anyways:
  const {
    sortedArray, order, orderBy, handleRequestSort
  } = useSort<Employment, "ladder" | "department">, "desc", {
    ladder: contramap((e) => e.position?.ladder.name)
    department: contramap((e) => e.position?.ladder.department.name)
  });
 */
export function useSort<T, K extends string = never>(
  array: T[],
  defaultOrderBy: NoInfer<K | keyof T>,
  defaultOrder: Order,
  customComparators: {
    [key in keyof T]?: ComparatorWithOrder<T>;
  } & {
    [key in NoInfer<K>]: ComparatorWithOrder<T>;
  }
): {
  sortedArray: T[];
  order: Order;
  orderBy: K | keyof T;
  handleRequestSort: (property: K | keyof T) => void;
} {
  const [orderBy, setOrderBy] = useState<K | keyof T>(defaultOrderBy);
  const [order, setOrder] = useState<Order>(defaultOrder);

  function getComparator(): ComparatorWithOrder<T> {
    const comparator =
      // eslint doesn't understand that this can actually be undefined.
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      customComparators[orderBy] ?? getDefaultComparator<T>(orderBy as keyof T);
    return (a: T, b: T) =>
      order === "asc" ? comparator(a, b, order) : comparator(b, a, order);
  }

  const sortedArray = stableSort(array, getComparator() as Comparator<T>);

  return {
    sortedArray,
    order,
    orderBy,
    handleRequestSort: (property: K | keyof T, requestedOrder?: Order) => {
      if (orderBy === property) {
        setOrder((o) => (o === "asc" ? "desc" : "asc"));
      } else {
        setOrder("asc");
        setOrderBy(property);
      }
      if (requestedOrder) {
        setOrder(requestedOrder);
      }
    },
  };
}

export interface SortableTableHeaderCellProps<T> extends TableCellProps {
  cellTitle: ReactNode;
  orderByField: keyof T;
  order: Order;
  isSelected: boolean;
  noWrap?: boolean;
  handleRequestSort: (orderBy: keyof T) => void;
}

function SortIcon({ ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      component={SortDescIcon}
      {...rest}
      style={{
        position: "absolute",
        left: "100%",
        fontSize: "20px",
      }}
    />
  );
}

const useSortStyles = makeStyles(() => ({
  wrapLabels: {
    lineHeight: "100%",
  },
}));

export function SortableTableHeaderCell<T>({
  cellTitle,
  orderByField,
  order,
  isSelected,
  handleRequestSort,
  children,
  align,
  noWrap = false,
  ...rest
}: SortableTableHeaderCellProps<T>): JSX.Element {
  const onSortRequest = () => {
    handleRequestSort(orderByField);
  };
  const classes = useSortStyles();

  return (
    <TableCell
      variant="head"
      sortDirection={isSelected ? order : false}
      align={align}
      {...rest}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={
          align === "center"
            ? "center"
            : align === "right"
              ? "flex-end"
              : "space-between"
        }
        alignItems="center"
      >
        <TableSortLabel
          active={isSelected}
          direction={isSelected ? order : "asc"}
          IconComponent={SortIcon}
          onClick={onSortRequest}
        >
          <AssembleTypography
            variant="productTableHeader"
            noWrap={noWrap}
            className={clsx({ [classes.wrapLabels]: !noWrap })}
          >
            {cellTitle}
          </AssembleTypography>
        </TableSortLabel>
        {children}
      </Box>
    </TableCell>
  );
}

export interface DoubleSortableTableHeaderCellProps<T> extends TableCellProps {
  titleA: ReactNode;
  titleB: ReactNode;
  orderByFieldA: keyof T;
  orderByFieldB: keyof T;
  order: Order;
  isSelectedA: boolean;
  isSelectedB: boolean;
  handleRequestSort: (orderBy: keyof T, order?: Order) => void;
}

const useDoubleSortStyles = makeStyles(() => ({
  unselected: {
    ".MuiTableSortLabel-active &": {
      color: GRAY_4,
    },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export function DoubleSortableTableHeaderCell<T>({
  titleA,
  titleB,
  orderByFieldA,
  orderByFieldB,
  order,
  isSelectedA,
  isSelectedB,
  handleRequestSort,
  children,
  align,
  ...rest
}: DoubleSortableTableHeaderCellProps<T>): JSX.Element {
  const classes = useDoubleSortStyles();
  const onSortRequest = () => {
    if (isSelectedA || isSelectedB) {
      // A asc -> A desc -> B asc -> B desc -> A asc -> ...
      const newOrder = order === "asc" ? "desc" : "asc";
      handleRequestSort(
        isSelectedA === (newOrder === "asc") ? orderByFieldB : orderByFieldA,
        newOrder
      );
    } else {
      // Not selected, do default behavior
      handleRequestSort(orderByFieldA);
    }
  };

  return (
    <TableCell
      variant="head"
      sortDirection={isSelectedA || isSelectedB ? order : false}
      align={align}
      {...rest}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={
          align === "center"
            ? "center"
            : align === "right"
              ? "flex-end"
              : "space-between"
        }
        alignItems="center"
      >
        <TableSortLabel
          active={isSelectedA || isSelectedB}
          direction={isSelectedA || isSelectedB ? order : "asc"}
          IconComponent={SortIcon}
          onClick={onSortRequest}
        >
          <span className={classes.header}>
            <AssembleTypography
              variant="productTableHeader"
              className={!isSelectedA ? classes.unselected : undefined}
            >
              {titleA}
            </AssembleTypography>
            <AssembleTypography>&nbsp;/&nbsp;</AssembleTypography>
            <AssembleTypography
              variant="productTableHeader"
              className={!isSelectedB ? classes.unselected : undefined}
            >
              {titleB}
            </AssembleTypography>
          </span>
        </TableSortLabel>
        {children}
      </Box>
    </TableCell>
  );
}
