/* This file contains all User related mutations */

import { FetchResult, gql, Reference, useMutation } from "@apollo/client";
import { PrimaryRoleName } from "@asmbl/shared/permissions";
import { setify } from "@asmbl/shared/utils";
import { useCallback } from "react";
import {
  COMP_STRUCTURE_QUERY,
  CURRENT_USER_PERMISSIONS,
  GET_USERS_WITH_ACCESS_CONTROLS,
  GET_USER_WITH_ACCESS_CONTROLS,
} from "../queries";
import {
  ClearCompensationUserAccessGrant,
  ClearCompensationUserAccessGrantVariables,
  EmplaceCompensationUserAccessGrant,
  EmplaceCompensationUserAccessGrantVariables,
  InviteUsersToEmployeePortal,
  InviteUsersToEmployeePortalInput,
  InviteUsersToEmployeePortalVariables,
  InviteUserToAssemble,
  InviteUserToAssembleInput,
  InviteUserToAssembleVariables,
  RevokeInvites,
  RevokeInvitesVariables,
  ScopeInput,
  UpdatePrimaryAccessGrants,
  UpdatePrimaryAccessGrantsVariables,
  UpdateUserIsDisabled,
  UpdateUserIsDisabledVariables,
  UpsertUsersWithAccessGrants,
  UpsertUsersWithAccessGrantsVariables,
} from "../__generated__/graphql";

export const NOUN_SCOPES_FIELDS = gql`
  fragment NounScopesFields on NounScopes {
    AccessControl {
      edit {
        global
      }
      view {
        global
      }
    }
    CashBand {
      edit {
        global
      }
      view {
        allMarkets {
          global
          departmentIDs
          ladderIDs
          positionIDs
        }
        markets {
          marketId
          scope {
            global
            departmentIDs
            ladderIDs
            positionIDs
          }
        }
      }
    }
    CompCycle {
      edit {
        global
      }
      view {
        global
      }
    }
    CompCycleBudget {
      edit {
        global
      }
      view {
        global
      }
    }
    CompRecommendation {
      edit {
        global
        directReportIDs
        indirectReportIDs
        supportingManagerEmployeeIDs
      }
      view {
        global
        directReportIDs
        indirectReportIDs
        supportingManagerEmployeeIDs
      }
    }
    CompStructure {
      edit {
        global
      }
      view {
        global
      }
    }
    Employee {
      edit {
        global
        directReportIDs
        indirectReportIDs
        supportingManagerEmployeeIDs
      }
      view {
        global
        directReportIDs
        indirectReportIDs
        supportingManagerEmployeeIDs
      }
    }
    EmployeeCashCompensation {
      edit {
        global
        directReportIDs
        indirectReportIDs
        supportingManagerEmployeeIDs
      }
      view {
        global
        directReportIDs
        indirectReportIDs
        supportingManagerEmployeeIDs
      }
    }
    EmployeeEquityCompensation {
      edit {
        global
        directReportIDs
        indirectReportIDs
        supportingManagerEmployeeIDs
      }
      view {
        global
        directReportIDs
        indirectReportIDs
        supportingManagerEmployeeIDs
      }
    }
    EquityBand {
      edit {
        global
      }
      view {
        allMarkets {
          global
          departmentIDs
          ladderIDs
          positionIDs
        }
        markets {
          marketId
          scope {
            global
            departmentIDs
            ladderIDs
            positionIDs
          }
        }
      }
    }
    Integration {
      edit {
        global
      }
      view {
        global
      }
    }
    JobStructure {
      edit {
        global
      }
      view {
        global
        departmentIDs
        ladderIDs
        positionIDs
      }
    }
    Market {
      edit {
        global
      }
      view {
        global
      }
    }
    MarketData {
      edit {
        global
      }
      view {
        global
      }
    }
    Offer {
      edit {
        global
        authored
      }
      view {
        global
        authored
      }
    }
    Philosophy {
      edit {
        global
      }
      view {
        global
      }
    }
    SensitiveData {
      edit {
        global
      }
      view {
        global
      }
    }
  }
`;

export const UPDATE_PRIMARY_ACCESS_GRANTS = gql`
  ${NOUN_SCOPES_FIELDS}
  mutation UpdatePrimaryAccessGrants(
    $grantedUserId: Int!
    $role: PrimaryRoleName!
  ) {
    updateUserAccessGrantRole(
      data: { grantedUserId: $grantedUserId, role: $role }
    ) {
      id
      name
      email
      userAccessGrant {
        roleName
        nounScopes {
          ...NounScopesFields
        }
      }
    }
  }
`;

export function useUpdateUserPrimaryAccessGrants(
  grantedUserId: number
): (roleName: PrimaryRoleName) => Promise<unknown> {
  const [updatePrimaryAccessGrants] = useMutation<
    UpdatePrimaryAccessGrants,
    UpdatePrimaryAccessGrantsVariables
  >(UPDATE_PRIMARY_ACCESS_GRANTS, {
    refetchQueries: [
      { query: GET_USERS_WITH_ACCESS_CONTROLS },
      { query: CURRENT_USER_PERMISSIONS },
      {
        query: GET_USER_WITH_ACCESS_CONTROLS,
        variables: { userId: grantedUserId },
      },
    ],
  });

  return useCallback(
    (roleName) =>
      updatePrimaryAccessGrants({
        variables: { grantedUserId, role: roleName },
      }),
    [grantedUserId, updatePrimaryAccessGrants]
  );
}

const EMPLACE_COMPENSATION_USER_ACCESS_GRANT = gql`
  ${NOUN_SCOPES_FIELDS}
  mutation EmplaceCompensationUserAccessGrant(
    $grantedUserId: Int!
    $marketId: Int
    $scope: ScopeInput
  ) {
    updateUserAccessGrantCompBandAccess(
      data: {
        grantedUserId: $grantedUserId
        marketId: $marketId
        scope: $scope
      }
    ) {
      id
      name
      email
      userAccessGrant {
        roleName
        nounScopes {
          ...NounScopesFields
        }
      }
    }
  }
`;

export function useEmplaceCompensationUserAccessGrant(
  grantedUserId: number
): (marketId: number | null, scope: ScopeInput | null) => Promise<unknown> {
  const [emplaceCompensationUserAccessGrant] = useMutation<
    EmplaceCompensationUserAccessGrant,
    EmplaceCompensationUserAccessGrantVariables
  >(EMPLACE_COMPENSATION_USER_ACCESS_GRANT, {
    refetchQueries: [
      { query: GET_USERS_WITH_ACCESS_CONTROLS },
      { query: CURRENT_USER_PERMISSIONS },
      { query: COMP_STRUCTURE_QUERY },
      {
        query: GET_USER_WITH_ACCESS_CONTROLS,
        variables: { userId: grantedUserId },
      },
    ],
  });

  return useCallback(
    (marketId, scope) =>
      emplaceCompensationUserAccessGrant({
        variables: { grantedUserId, marketId, scope },
      }),
    [emplaceCompensationUserAccessGrant, grantedUserId]
  );
}

const CLEAR_COMPENSATION_USER_ACCESS_GRANT = gql`
  ${NOUN_SCOPES_FIELDS}
  mutation ClearCompensationUserAccessGrant($grantedUserId: Int!) {
    clearUserAccessGrantCompBandAccess(grantedUserId: $grantedUserId) {
      id
      name
      email
      userAccessGrant {
        roleName
        nounScopes {
          ...NounScopesFields
        }
      }
    }
  }
`;
export function useClearCompensationUserAccessGrant(
  grantedUserId: number
): () => Promise<unknown> {
  const [clearCompensationUserAccessGrant] = useMutation<
    ClearCompensationUserAccessGrant,
    ClearCompensationUserAccessGrantVariables
  >(CLEAR_COMPENSATION_USER_ACCESS_GRANT, {
    refetchQueries: [
      { query: GET_USERS_WITH_ACCESS_CONTROLS },
      { query: CURRENT_USER_PERMISSIONS },
      { query: COMP_STRUCTURE_QUERY },
      {
        query: GET_USER_WITH_ACCESS_CONTROLS,
        variables: { userId: grantedUserId },
      },
    ],
  });

  return useCallback(
    () => clearCompensationUserAccessGrant({ variables: { grantedUserId } }),
    [clearCompensationUserAccessGrant, grantedUserId]
  );
}

export const USER_INVITE_FIELDS = gql`
  fragment UserInviteFields on UserInvitation {
    id
    name
    email
    employee {
      id
      latestUserInvite {
        id
      }
    }
    createdAt
    expiredAt
    deletedAt
    tokenUsedAt
  }
`;

export const REVOKE_USER_INVITE = gql`
  ${USER_INVITE_FIELDS}
  mutation RevokeInvites($emails: [String!]!) {
    revokeUserInvites(emails: $emails) {
      ...UserInviteFields
    }
  }
`;

export const UPSERT_USERS_WITH_ACCESS_GRANTS = gql`
  mutation UpsertUsersWithAccessGrants($users: [UserWithAccessGrantsInput!]!) {
    upsertUsersWithAccessGrants(users: $users) {
      id
      email
      userAccessGrant {
        id
      }
    }
  }
`;

export function useRevokeUserInvites(): (
  emails: string[]
) => Promise<RevokeInvites | null> {
  const [revokeUserInvites] = useMutation<
    RevokeInvites,
    RevokeInvitesVariables
  >(REVOKE_USER_INVITE, {
    update(cache, { data }) {
      if (data == null) {
        return;
      }

      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          activeAssembleUserInvitations(
            existingUserInvitations: Reference[],
            { readField }
          ) {
            const idsToRemove = setify(data.revokeUserInvites, "id");

            return existingUserInvitations.filter((userInvitation) => {
              const currentId = readField("id", userInvitation) as number;
              return !idsToRemove.has(currentId);
            });
          },
        },
      });
    },
  });

  return useCallback(
    async (emails) => {
      const { data } = await revokeUserInvites({
        variables: { emails },
      });
      return data ?? null;
    },
    [revokeUserInvites]
  );
}

export const INVITE_USERS_TO_PORTAL = gql`
  ${USER_INVITE_FIELDS}
  mutation InviteUsersToEmployeePortal(
    $invites: [InviteUsersToEmployeePortalInput!]!
  ) {
    inviteUsersToEmployeePortal(invites: $invites) {
      errors
      invitations {
        invite {
          ...UserInviteFields
        }
      }
    }
  }
`;

export const INVITE_USER_TO_ASSEMBLE = gql`
  ${USER_INVITE_FIELDS}
  mutation InviteUserToAssemble($invite: InviteUserToAssembleInput!) {
    inviteUserToAssemble(invite: $invite) {
      errors
      invitation {
        email
        ...UserInviteFields
      }
    }
  }
`;

export function useInviteUsersToEmployeePortal(): (
  invites: InviteUsersToEmployeePortalInput[]
) => Promise<InviteUsersToEmployeePortal | string> {
  const [inviteUsersToEmployeePortal] = useMutation<
    InviteUsersToEmployeePortal,
    InviteUsersToEmployeePortalVariables
  >(INVITE_USERS_TO_PORTAL);

  return useCallback(
    async (invites) => {
      const { data } = await inviteUsersToEmployeePortal({
        variables: {
          invites,
        },
      });
      if (
        data == null ||
        (data.inviteUsersToEmployeePortal.errors !== null &&
          data.inviteUsersToEmployeePortal.errors.length > 0)
      ) {
        return (
          data?.inviteUsersToEmployeePortal.errors?.join(", ") ??
          "A server error occurred."
        );
      }
      return data;
    },
    [inviteUsersToEmployeePortal]
  );
}

export const UPDATE_USER_IS_DISABLED = gql`
  mutation UpdateUserIsDisabled($userId: Int!, $isDisabled: Boolean!) {
    updateUserIsDisabled(userId: $userId, isDisabled: $isDisabled) {
      id
      isDisabled
    }
  }
`;

export function useUpdateUserIsDisabled(): (
  userId: number,
  isDisabled: boolean
) => Promise<FetchResult<UpdateUserIsDisabled>> {
  const [updateUserIsDisabled] = useMutation<
    UpdateUserIsDisabled,
    UpdateUserIsDisabledVariables
  >(UPDATE_USER_IS_DISABLED);

  return useCallback(
    async (userId, isDisabled) =>
      await updateUserIsDisabled({ variables: { userId, isDisabled } }),
    [updateUserIsDisabled]
  );
}

export function useUpsertUsersWithAccessGrants(): (
  usersWithGrants: UpsertUsersWithAccessGrantsVariables
) => Promise<FetchResult<UpsertUsersWithAccessGrants>> {
  const [upsertUsersWithAccessGrants] = useMutation<
    UpsertUsersWithAccessGrants,
    UpsertUsersWithAccessGrantsVariables
  >(UPSERT_USERS_WITH_ACCESS_GRANTS);

  return useCallback(
    async (usersWithGrants) =>
      await upsertUsersWithAccessGrants({
        variables: usersWithGrants,
      }),
    [upsertUsersWithAccessGrants]
  );
}

export function useInviteUserToAssemble(): (
  invite: InviteUserToAssembleInput
) => Promise<InviteUserToAssemble | string> {
  const [inviteUserToAssemble] = useMutation<
    InviteUserToAssemble,
    InviteUserToAssembleVariables
  >(INVITE_USER_TO_ASSEMBLE, {
    update(cache, { data }) {
      if (data == null) {
        return;
      }

      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          activeAssembleUserInvitations(existingUserInvitations: Reference[]) {
            const newUserInvitation = cache.writeFragment({
              fragment: USER_INVITE_FIELDS,
              fragmentName: "UserInviteFields",
              data: data.inviteUserToAssemble.invitation,
            });

            return [...existingUserInvitations, newUserInvitation];
          },
        },
      });
    },
  });

  return useCallback(
    async (invite) => {
      const { data } = await inviteUserToAssemble({ variables: { invite } });

      if (
        data == null ||
        (data.inviteUserToAssemble.errors !== null &&
          data.inviteUserToAssemble.errors.length > 0)
      ) {
        return (
          data?.inviteUserToAssemble.errors?.join(", ") ??
          "A server error occurred."
        );
      }

      return data;
    },
    [inviteUserToAssemble]
  );
}
