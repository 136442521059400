import {
  FormControlLabel,
  FormControlLabelProps,
  makeStyles,
  Radio,
  Theme,
} from "@material-ui/core";
import { memo } from "react";
import { GRAY_6, GRAY_8, PURPLE_1, WHITE } from "../../theme";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    background: WHITE,
    boxSizing: "border-box",
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px",
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    margin: 0,
    padding: `${theme.spacing(2)}px`,
    position: "relative",
    transition: "all 125ms",
    width: "100%",

    "&:focus-within": {
      background: GRAY_8,
    },

    "&:hover": {
      background: GRAY_8,
    },
  },
  selected: {
    color: PURPLE_1,
    border: `1px solid ${PURPLE_1}`,
    boxShadow: `0 0 0 1px ${PURPLE_1}`,
  },
}));

interface Props extends Omit<FormControlLabelProps, "control"> {
  isSelected: boolean;
}
const RadioCard = ({
  label,
  style,
  onChange,
  isSelected,
  classes: originalClasses,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      {...rest}
      classes={{
        ...originalClasses,
        root: `${classes.box} ${isSelected ? classes.selected : ""}`,
      }}
      control={<Radio style={{ display: "none" }} />}
      label={label}
      onChange={onChange}
      style={style}
    />
  );
};

export default memo(RadioCard);
