import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const JobArchitectureIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Job Architecture"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M12 6V16"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M16.3333 3H7.66667C7.29848 3 7 3.33579 7 3.75V5.25C7 5.66421 7.29848 6 7.66667 6H16.3333C16.7015 6 17 5.66421 17 5.25V3.75C17 3.33579 16.7015 3 16.3333 3Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M13.2 17H10.8C10.3582 17 10 17.3582 10 17.8V20.2C10 20.6418 10.3582 21 10.8 21H13.2C13.6418 21 14 20.6418 14 20.2V17.8C14 17.3582 13.6418 17 13.2 17Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M6.2 17H3.8C3.35817 17 3 17.3582 3 17.8V20.2C3 20.6418 3.35817 21 3.8 21H6.2C6.64183 21 7 20.6418 7 20.2V17.8C7 17.3582 6.64183 17 6.2 17Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M20.2 17H17.8C17.3582 17 17 17.3582 17 17.8V20.2C17 20.6418 17.3582 21 17.8 21H20.2C20.6418 21 21 20.6418 21 20.2V17.8C21 17.3582 20.6418 17 20.2 17Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M18 17V12C18 11.7348 17.9256 11.4804 17.7933 11.2929C17.6609 11.1054 17.4813 11 17.2941 11H6.70588C6.51867 11 6.33913 11.1054 6.20675 11.2929C6.07437 11.4804 6 11.7348 6 12V17"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
