import {
  CashBandName,
  CurrencyCode,
  EquityBandName,
  MONTHS_IN_A_YEAR,
} from "@asmbl/shared/constants";
import {
  Currency,
  exchangeCurrency,
  exchangeFromTo,
  hourlyToAnnual,
} from "@asmbl/shared/currency";
import {
  Money,
  add,
  annualGrossEquityValue,
  divide,
  percentageOfSalaryToCash,
  roundMoney,
  zero,
} from "@asmbl/shared/money";
import { isEmptyString } from "@asmbl/shared/utils";
import {
  BandUnit,
  BenefitsPackageFields,
  CustomFieldInput as CustomField,
  CustomFieldVariant,
  EquityGrantTypes,
  PositionType,
} from "../__generated__/graphql";

export type OfferDataValueCash = {
  mode: BandUnit.CASH;
  value: Money | undefined;
};

export type OfferDataValuePercentage = {
  mode: BandUnit.PERCENTAGE;
  value: number | undefined;
};

export type OfferDataValueUnits = {
  mode: BandUnit.UNITS;
  value: number | undefined;
};

export type OfferDataValue =
  | OfferDataValueCash
  | OfferDataValuePercentage
  | OfferDataValueUnits
  | undefined;

export type OfferDataCash = {
  [CashBandName.SALARY]?: OfferDataValueCash | undefined;
  [CashBandName.COMMISSION]?:
    | OfferDataValueCash
    | OfferDataValuePercentage
    | undefined;

  [CashBandName.RECURRING_BONUS]?:
    | OfferDataValueCash
    | OfferDataValuePercentage
    | undefined;

  [CashBandName.SPOT_BONUS]?:
    | OfferDataValueCash
    | OfferDataValuePercentage
    | undefined;

  [CashBandName.SIGNING_BONUS]?: OfferDataValueCash | undefined;

  [CashBandName.RELOCATION_BONUS_OFFICE]?: OfferDataValueCash | undefined;

  [CashBandName.RELOCATION_BONUS_REMOTE]?: OfferDataValueCash | undefined;
};

// Equity values can be either in units (`number`), cash (`Money`), or
// percentage of salary (`number`). All of these are stored UN-ANNUALIZED, i.e.
// the value represents the total over the entire vesting schedule (4 years).
// To get the annualized value, you have to do:
// (total value / (vestingMonths / 12))
export type OfferDataEquity = {
  [EquityBandName.INITIAL_EQUITY_GRANT]?:
    | OfferDataValueCash
    | OfferDataValuePercentage
    | OfferDataValueUnits
    | undefined;
  [EquityBandName.EQUITY_REFRESH_GRANT]?:
    | OfferDataValueCash
    | OfferDataValuePercentage
    | OfferDataValueUnits
    | undefined;
};

/**
 * Determine if a type of equity grant should have an associated strike price.
 * RSU's and SPI's should not have a strike price.
 */
export function hasStrikePrice(equityGrantType: EquityGrantTypes): boolean {
  return (
    equityGrantType !== EquityGrantTypes.RSU &&
    equityGrantType !== EquityGrantTypes.SHARED_PROFIT_INTEREST
  );
}

export type CustomFieldStates =
  | "VALID"
  | "NO_OPTIONS"
  | "SAME_NAME"
  | "NO_NAME";

/* Given a list of CustomField objects, returns true if they are all good,
 * otherwise false.
 */
export const areCustomFieldsValid = (
  fields: CustomField[] | undefined
): boolean => {
  return fields === undefined
    ? true
    : fields.every((_, index) => isCustomFieldValid(index, fields) === "VALID");
};

/* Checks if a single field is valid in the context of the other fields,
 * returning VALID if things are all good, otherwise returning various error
 * states.
 */
export function isCustomFieldValid(
  index: number,
  fields: CustomField[]
): CustomFieldStates {
  const field = fields[index];
  if (isEmptyString(field.name)) {
    return "NO_NAME";
  }
  if (fields.filter((f) => f.name === field.name).length > 1) {
    return "SAME_NAME";
  }
  if (field.variant === CustomFieldVariant.DROPDOWN) {
    return field.options.length > 0 ? "VALID" : "NO_OPTIONS";
  }
  return "VALID";
}
interface TotalAnnualCompensationCompStructure {
  cashBandTypes: string[];
  vestingMonths: number;
  employmentHoursPerWeek: number;
  employmentWeeksPerYear: number;
}

/**
 * Computes the annual total cash compensation including Salary, Commission, and Recurring Bonus.
 */
export function getAnnualCashCompensation<C extends CurrencyCode>(
  cashComponents: OfferDataCash,
  compStructure: TotalAnnualCompensationCompStructure,
  currency: Currency<C>,
  positionType: PositionType | undefined
): Money<CurrencyCode> {
  let salary =
    cashComponents[CashBandName.SALARY]?.value ?? zero(currency.code);
  if (positionType === PositionType.HOURLY) {
    salary = hourlyToAnnual(
      compStructure.employmentHoursPerWeek *
        compStructure.employmentWeeksPerYear,
      salary
    );
  }

  return compStructure.cashBandTypes
    .filter((bandName) =>
      [
        CashBandName.SALARY,
        CashBandName.COMMISSION,
        CashBandName.RECURRING_BONUS,
      ].includes(bandName as CashBandName)
    )
    .map((bandName) => {
      const bandData = cashComponents[bandName as CashBandName];

      if (bandData === undefined) return zero(currency.code);

      const { mode, value } = bandData;

      if (mode === BandUnit.CASH) {
        if (value === undefined) return zero(currency.code);
        if (
          bandName === CashBandName.SALARY &&
          positionType === PositionType.HOURLY
        ) {
          return hourlyToAnnual(
            compStructure.employmentHoursPerWeek *
              compStructure.employmentWeeksPerYear,
            value
          );
        }
        return value;
      } else {
        // BandUnit.PERCENTAGE
        return value === undefined
          ? zero(currency.code)
          : percentageOfSalaryToCash(salary, value);
      }
    })
    .reduce(add);
}

/**
 * Computes the annual total compensation across cash and equity. Includes Salary, Commission, Recurring Bonus, and Equity.
 */
export function totalAnnualCompensation<C extends CurrencyCode>(
  defaultCurrency: Currency,
  cashComponents: OfferDataCash,
  equity: OfferDataEquity,
  fdso: number,
  valuation: Money,
  compStructure: TotalAnnualCompensationCompStructure,
  currency: Currency<C>
): Money<CurrencyCode> {
  const salary =
    cashComponents[CashBandName.SALARY]?.value ?? zero(currency.code);

  let annualEquity: Money = zero(defaultCurrency.code);

  const initialEquityGrant = equity[EquityBandName.INITIAL_EQUITY_GRANT];

  if (initialEquityGrant !== undefined) {
    if (initialEquityGrant.mode === BandUnit.CASH) {
      annualEquity = divide(
        exchangeFromTo(
          initialEquityGrant.value ?? zero(currency.code),
          currency,
          defaultCurrency
        ),
        compStructure.vestingMonths / MONTHS_IN_A_YEAR
      );
    } else if (initialEquityGrant.mode === BandUnit.UNITS) {
      annualEquity = annualGrossEquityValue(
        fdso,
        valuation,
        initialEquityGrant.value ?? 0,
        compStructure.vestingMonths
      );
    } else {
      // BandUnit.PERCENTAGE
      const cashFromPercentageOfSalary = percentageOfSalaryToCash(
        salary,
        initialEquityGrant.value ?? 0
      );

      annualEquity = divide(
        exchangeFromTo(cashFromPercentageOfSalary, currency, defaultCurrency),
        compStructure.vestingMonths / MONTHS_IN_A_YEAR
      );
    }
  }

  return add(
    exchangeCurrency(annualEquity, currency),
    getAnnualCashCompensation(
      cashComponents,
      compStructure,
      currency,
      undefined
    )
  );
}

export function isCashCompensation(bandName: string): boolean {
  return Object.values(CashBandName).includes(bandName as CashBandName);
}

export function isEquityCompensation(bandName: string): boolean {
  return Object.values(EquityBandName).includes(bandName as EquityBandName);
}

type OfferBenefitInput = {
  currency: Currency<CurrencyCode>;
  benefitsPackage: BenefitsPackageFields | null | undefined;
};

/**
 * @param currencies - from `useCurrencies`
 * @param benefitInputs - the offer state inputs controlled by the user
 * @returns the total value of the benefits package in the local currency
 * rounded to the nearest whole number if the total benefits package currency was
 * converted to the local currency
 */

export function getAnnualBenefitsValue(
  currencies: Map<CurrencyCode, Currency>,
  benefitInputs: OfferBenefitInput
): Money {
  const { benefitsPackage, currency } = benefitInputs;

  if (!benefitsPackage) {
    return zero(benefitInputs.currency.code);
  }

  const fromCurrency = currencies.get(benefitsPackage.currencyCode);

  if (!fromCurrency) {
    return zero(currency.code);
  }

  const value = exchangeFromTo(
    benefitsPackage.totalValue,
    fromCurrency,
    currency
  );

  return currency.code === fromCurrency.code ? value : roundMoney(value, 1);
}

type OfferOneTimeCompInput = {
  salary: OfferDataValueCash | undefined;
  spotBonus: OfferDataValueCash | OfferDataValuePercentage | undefined;
  signingBonus: OfferDataValueCash | undefined;
  relocationBonusOffice: OfferDataValueCash | undefined;
  relocationBonusRemote: OfferDataValueCash | undefined;
};

export function getOneTimeComp(
  localCurrency: Currency,
  oneTimeBonusInput: OfferOneTimeCompInput
): Money {
  const spotBonus = oneTimeBonusInput.spotBonus ?? {
    mode: BandUnit.CASH,
    value: zero(localCurrency.code),
  };

  const signingBonus = oneTimeBonusInput.signingBonus ?? {
    mode: BandUnit.CASH,
    value: zero(localCurrency.code),
  };

  const relocationBonusOffice = oneTimeBonusInput.relocationBonusOffice ?? {
    mode: BandUnit.CASH,
    value: zero(localCurrency.code),
  };

  const relocationBonusRemote = oneTimeBonusInput.relocationBonusRemote ?? {
    mode: BandUnit.CASH,
    value: zero(localCurrency.code),
  };

  const spotBonusValue =
    // if spot bonus is in cash, use that value
    spotBonus.mode === BandUnit.CASH
      ? spotBonus.value ?? zero(localCurrency.code)
      : // if the spot bonus is in percentage, calculate the cash value
        oneTimeBonusInput.salary?.value == null
        ? zero(localCurrency.code)
        : percentageOfSalaryToCash(
            oneTimeBonusInput.salary.value,
            spotBonus.value ?? 0
          );

  const signingBonusValue = signingBonus.value ?? zero(localCurrency.code);

  const relocationBonusOfficeValue =
    relocationBonusOffice.value ?? zero(localCurrency.code);

  const relocationBonusRemoteValue =
    relocationBonusRemote.value ?? zero(localCurrency.code);

  return add(
    add(spotBonusValue, signingBonusValue),
    add(relocationBonusOfficeValue, relocationBonusRemoteValue)
  );
}

type OfferEquityInput = {
  salary: OfferDataValueCash | undefined;
  equity:
    | OfferDataValueCash
    | OfferDataValuePercentage
    | OfferDataValueUnits
    | undefined;
};

type OfferValuation = { fdso: number; valuation: Money };

/** calculates the total gross equity value for the offers flow
 * @param showEquityInValuationCurrency whether or not to show the equity in
 * the company's valuation currency or the local currency
 * @param valuationCurrency the currency in which the company's valuation
 * is set in (same as the company's default currency)
 * @param localCurrency the currency in which the user is setting their offer
 * @param compStructure the compensation structure of the company
 * @param offerInputs the offer state inputs controlled by the user
 * @param valuation the valuation of the company
 * @returns an object with the annual equity value in both the valuation of the
 * company and the local currency (set by the user in the offers flow)
 */

export function getAnnualEquityValue(
  showEquityInValuationCurrency: boolean,
  valuationCurrency: Currency,
  localCurrency: Currency,
  compStructure: { vestingMonths: number },
  offerInputs: OfferEquityInput,
  valuation: OfferValuation
): {
  inValuationCurrency: Money;
  inLocalCurrency: Money;
} {
  const safeVestingMonths =
    compStructure.vestingMonths === 0
      ? MONTHS_IN_A_YEAR
      : compStructure.vestingMonths;

  const conversionFunction = showEquityInValuationCurrency
    ? equityInValuationCurrency
    : equityInLocalCurrency;

  return conversionFunction(
    valuationCurrency,
    localCurrency,
    safeVestingMonths,
    offerInputs,
    valuation
  );
}

// the `offerInputs.equity` values in this context are in LOCAL currency
function equityInLocalCurrency(
  valuationCurrency: Currency,
  localCurrency: Currency,
  safeVestingMonths: number,
  offerInputs: OfferEquityInput,
  valuation: OfferValuation
): {
  inValuationCurrency: Money;
  inLocalCurrency: Money;
} {
  if (offerInputs.equity?.value === undefined) {
    return {
      inLocalCurrency: zero(localCurrency.code),
      inValuationCurrency: zero(valuationCurrency.code),
    };
  }

  switch (offerInputs.equity.mode) {
    case BandUnit.CASH: {
      const inLocalCurrency = divide(
        offerInputs.equity.value,
        safeVestingMonths / MONTHS_IN_A_YEAR
      );

      return {
        inLocalCurrency,
        inValuationCurrency: exchangeFromTo(
          inLocalCurrency,
          localCurrency,
          valuationCurrency
        ),
      };
    }

    case BandUnit.UNITS: {
      const inValuationCurrency = annualGrossEquityValue(
        valuation.fdso,
        valuation.valuation,
        offerInputs.equity.value,
        safeVestingMonths
      );

      return {
        inLocalCurrency: exchangeFromTo(
          inValuationCurrency,
          valuationCurrency,
          localCurrency
        ),
        inValuationCurrency,
      };
    }

    case BandUnit.PERCENTAGE: {
      // if the salary input is undefined, then we cannot calculate
      // the equity value as a percentage of salary. Therefore we will
      // just return undefined for the equity value.
      if (offerInputs.salary?.value === undefined) {
        return {
          inLocalCurrency: zero(localCurrency.code),
          inValuationCurrency: zero(valuationCurrency.code),
        };
      }

      const inLocalCurrency = divide(
        percentageOfSalaryToCash(
          offerInputs.salary.value,
          offerInputs.equity.value
        ),
        safeVestingMonths / MONTHS_IN_A_YEAR
      );

      return {
        inLocalCurrency,
        inValuationCurrency: exchangeFromTo(
          inLocalCurrency,
          localCurrency,
          valuationCurrency
        ),
      };
    }
  }
}

// the `offerInputs.equity` values in this context are in VALUATION currency
function equityInValuationCurrency(
  valuationCurrency: Currency,
  localCurrency: Currency,
  safeVestingMonths: number,
  offerInputs: OfferEquityInput,
  valuation: OfferValuation
): {
  inValuationCurrency: Money;
  inLocalCurrency: Money;
} {
  if (offerInputs.equity?.value === undefined) {
    return {
      inLocalCurrency: zero(localCurrency.code),
      inValuationCurrency: zero(valuationCurrency.code),
    };
  }

  switch (offerInputs.equity.mode) {
    case BandUnit.CASH: {
      const inValuationCurrency = divide(
        offerInputs.equity.value,
        safeVestingMonths / MONTHS_IN_A_YEAR
      );

      return {
        inLocalCurrency: exchangeFromTo(
          inValuationCurrency,
          valuationCurrency,
          localCurrency
        ),
        inValuationCurrency,
      };
    }

    case BandUnit.UNITS: {
      const inValuationCurrency = annualGrossEquityValue(
        valuation.fdso,
        valuation.valuation,
        offerInputs.equity.value,
        safeVestingMonths
      );

      return {
        inLocalCurrency: exchangeFromTo(
          inValuationCurrency,
          valuationCurrency,
          localCurrency
        ),
        inValuationCurrency,
      };
    }

    case BandUnit.PERCENTAGE: {
      // if the salary input is undefined, then we cannot calculate
      // the equity value as a percentage of salary. Therefore we will
      // just return undefined for the equity value.
      if (offerInputs.salary?.value === undefined) {
        return {
          inLocalCurrency: zero(localCurrency.code),
          inValuationCurrency: zero(valuationCurrency.code),
        };
      }

      // in order to get the percent of salary, we have to convert the salary
      // to the same currency as the valuation
      const salaryInValuationCurrency = exchangeFromTo(
        offerInputs.salary.value,
        localCurrency,
        valuationCurrency
      );

      const percentOfSalaryInValuationCurrency = percentageOfSalaryToCash(
        salaryInValuationCurrency,
        offerInputs.equity.value
      );

      const inValuationCurrency = divide(
        percentOfSalaryInValuationCurrency,
        safeVestingMonths / MONTHS_IN_A_YEAR
      );

      return {
        inLocalCurrency: exchangeFromTo(
          inValuationCurrency,
          valuationCurrency,
          localCurrency
        ),
        inValuationCurrency,
      };
    }
  }
}

/**
 * type used to hold all of the computed values for the `Compensation`,
 * `Comp Breakdown`, `ApprovalSheet`, & `Approval` components - this is
 * to avoid having to compute the same values multiple times in different
 * locations
 *
 * `annual` contains the annualized values for the cash, benefits, and equity
 *
 *    `total` is the sum of the cash, benefits, and equity annualized values
 *
 *  `fullyVested` is the full amount (non-annualized) of the equity data
 *
 *  `oneTimeComp` is the sum of the benefits annualized values
 */
export type ComputedOfferedComp = {
  annual: {
    cash: Money;
    benefits: { inLocalCurrency: Money; inBenefitsCurrency: Money };
    equity: { inLocalCurrency: Money; inValuationCurrency: Money };
    total: Money;
  };
  fullyVested: { inLocalCurrency: Money; inValuationCurrency: Money };
  oneTimeComp: Money;
};
