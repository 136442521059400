import { gql } from "@apollo/client";
import { caseInsensitiveComparator, contramap } from "@asmbl/shared/sort";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EditIcon } from "src/components/AssembleIcons/Brand/EditIcon";
import { GRAY_4, PURPLE_2 } from "src/theme";
import {
  Noun,
  PositionDetail_compStructure,
  PositionDetail_position,
} from "../../__generated__/graphql";
import { useTrack } from "../../analytics";
import { AccessBoundary } from "../../components/AccessBoundary";
import { AdjustmentsBar } from "../../components/AdjustmentsBar";
import {
  AssembleBreadCrumb,
  AssembleBreadCrumbs,
  AssembleDropdownBreadCrumb,
} from "../../components/AssembleBreadCrumbs";
import { PositionVisualization } from "../../components/PositionVisualization";
import { isBandPointDefined } from "../../models/BandPoint";
import { useDeletePosition, useUpdatePosition } from "../../mutations/Position";
import { bandNameComparator, getBandColors } from "../../utils";
import { useExcludedBandNames } from "../LadderDetail/ExcludedBandNameContext";
import { EditPositionForm } from "./EditPositionForm";
import { PositionDescription } from "./PositionDescription";
import { PositionDetailReadTable } from "./PositionDetailReadTable";
import { PositionPeerNavigation } from "./PositionPeerNavigation";

const useStyles = makeStyles((theme: Theme) => ({
  positionNameTextField: {
    fontSize: theme.typography.h5.fontSize,
    marginLeft: -theme.spacing(1),
    marginTop: "1px",
  },
  bandEditButtonContainer: {
    height: theme.spacing(6),
  },
  editDisabledTooltip: {
    padding: "11px 17px",
  },
  visualizationGrid: {
    position: "relative",
  },
  breadCrumbContainer: {
    "& .MuiBreadcrumbs-ol > li:last-child": {
      width: "100%",
    },
    paddingBottom: theme.spacing(3),
  },
  leafCrumb: {
    height: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

type Props = {
  position: PositionDetail_position;
  compStructure: PositionDetail_compStructure;
};

export function PositionDetail({
  position,
  compStructure,
}: Props): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();
  const { Track } = useTrack({
    area: "Job Architecture",
    subArea: "Position Detail",
    positionName: position.name,
    ladderName: position.ladder.name,
    departmentName: position.ladder.department.name,
  });

  const [isEditingPosition, setIsEditingPosition] = useState(false);
  const { excludedBandNames, toggleBandExclusion } = useExcludedBandNames();

  const updatePosition = useUpdatePosition(position.id);
  const deletePosition = useDeletePosition(position.id);

  const handleDeletePosition = async () => {
    await deletePosition();
    navigate(`/ladders/${position.ladder.id}`);
  };

  const canViewComp =
    position.adjustedCashBands !== null ||
    position.adjustedEquityBands !== null;

  const hasCashBands =
    position.adjustedCashBands !== null &&
    position.adjustedCashBands
      .filter((band) => !excludedBandNames.has(band.name))
      .some((band) => band.bandPoints.some(isBandPointDefined));

  const hasEquityBands =
    position.adjustedEquityBands !== null &&
    position.adjustedEquityBands
      .filter((band) => !excludedBandNames.has(band.name))
      .some((band) => band.bandPoints.some(isBandPointDefined));

  const emptyBands = !hasCashBands && !hasEquityBands;

  const bandNames = [
    ...compStructure.cashBandTypes,
    ...compStructure.equityBandTypes,
  ].sort(bandNameComparator);

  const positions = position.ladder.positions
    .slice()
    .sort(contramap((p) => p.name, caseInsensitiveComparator));

  return (
    <Track>
      <Box px={5} py={4}>
        {isEditingPosition && (
          <EditPositionForm
            mode="edit"
            persistedPosition={position}
            onSubmit={updatePosition}
            onCancel={() => setIsEditingPosition(false)}
            onDelete={handleDeletePosition}
          />
        )}

        <AdjustmentsBar />

        <div className={classes.breadCrumbContainer}>
          <AssembleBreadCrumbs variant="vertical">
            <AssembleBreadCrumb to="/" display={position.organization.name} />
            <AssembleBreadCrumb
              to={`/departments/${position.ladder.department.id}`}
              display={position.ladder.department.name}
            />
            <AssembleBreadCrumb
              to={`/ladders/${position.ladder.id}`}
              display={position.ladder.name}
            />
            <div className={classes.leafCrumb}>
              <div className={classes.leftContainer}>
                <AssembleDropdownBreadCrumb
                  options={positions.map(({ id, name }) => ({
                    id,
                    name,
                    to: `/positions/${id}`,
                  }))}
                  selectedOption={{
                    id: position.id,
                    name: position.name,
                    to: `/positions/${position.id}`,
                  }}
                />

                <Box m={1} />
                <AccessBoundary
                  scope="global"
                  verb="edit"
                  noun={Noun.JobStructure}
                >
                  <Tooltip title="Edit Position" placement="top">
                    <IconButton
                      size="small"
                      onClick={() => setIsEditingPosition(true)}
                    >
                      <EditIcon
                        color={GRAY_4}
                        hoverColor={PURPLE_2}
                        width="24px"
                        height="24px"
                      />
                    </IconButton>
                  </Tooltip>
                </AccessBoundary>
              </div>
              <Box>
                {!emptyBands &&
                  bandNames.map((bandName, index) => (
                    <FormControlLabel
                      key={bandName}
                      control={
                        <Checkbox
                          style={{ color: getBandColors(index) }}
                          size="small"
                          checked={!excludedBandNames.has(bandName)}
                          onChange={() => toggleBandExclusion(bandName)}
                          disabled={
                            // If this is the last one, cannot uncheck it
                            !excludedBandNames.has(bandName) &&
                            excludedBandNames.size + 1 >= bandNames.length
                          }
                        />
                      }
                      label={
                        <Typography variant="body2" noWrap>
                          {bandName}
                        </Typography>
                      }
                    />
                  ))}
              </Box>
            </div>
          </AssembleBreadCrumbs>
        </div>

        <Grid container>
          <Grid item xs={3}>
            <PositionDescription position={position} />
          </Grid>

          <Grid
            item
            xs={
              position.jobCodes.length === 0 &&
              (position.description === null || position.description === "")
                ? 12
                : 9
            }
            className={classes.visualizationGrid}
          >
            <PositionVisualization position={position} empty={emptyBands} />
          </Grid>
        </Grid>

        <Box m={2} />

        {canViewComp && (
          <PositionDetailReadTable
            empty={emptyBands}
            bandNames={bandNames}
            position={position}
          />
        )}
      </Box>
    </Track>
  );
}

PositionDetail.fragments = {
  position: gql`
    ${PositionPeerNavigation.fragments.position}
    ${PositionDescription.fragments.position}
    ${PositionVisualization.fragments.position}
    ${PositionDetailReadTable.fragments.position}
    fragment PositionDetail_position on Position {
      ...PositionPeerNavigation_position
      ...PositionDescription_position
      ...PositionVisualization_position
      ...PositionDetailReadTable_position
      id
      name
      description
      level
      ladder {
        id
        name
        department {
          id
          name
        }
      }
      organization {
        id
        name
      }
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
      }
      adjustedEquityBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
      }
    }
  `,
  compStructure: gql`
    fragment PositionDetail_compStructure on CompStructure {
      id
      showPercentOwnership
      cashBandTypes
      equityBandTypes
    }
  `,
};
