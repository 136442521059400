import { cx } from "@emotion/css";
import { makeStyles, Table } from "@material-ui/core";
import { ComponentPropsWithRef } from "react";

type Props = ComponentPropsWithRef<"table">;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "8px",
    borderSpacing: 0,

    "& tr td:last-child": {
      borderRight: "none",
    },

    "& tr:last-child td": {
      borderBottom: "none",
    },
  },
}));

/**
 * This is an alternate-themed table with a full white background and rounded
 * corners. This gives it a more wireframe feel than our other tables. We have
 * not yet clarified the exact circumstances when we'd use this table over our
 * usual styles, so check in with Design Team with any questions.
 *
 * WireTable is meant to be used with the other table components in this folder.
 */
export function WireTable({ ref, ...props }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Table ref={ref} {...props} className={cx(classes.root, props.className)} />
  );
}
