import { useParams } from "react-router-dom";
import { useTrack } from "../../../analytics";
import { FilterParam, getNumericParam } from "../../../models/FilterParams";
import { useURLSearchParams } from "../../../models/URLSearchParams";
import { BudgetLoadingBoundary } from "./BudgetLoadingBoundary";

export function BudgetPage(): JSX.Element | null {
  const { compCycleId } = useParams<{ compCycleId: string }>();
  const urlSearchParams = useURLSearchParams();
  const managerParam = getNumericParam(urlSearchParams, FilterParam.MANAGER);
  const cycleId = compCycleId != null ? parseInt(compCycleId, 10) : NaN;

  const { Track } = useTrack({
    subArea: "Allocate Budgets",
    compCycleId: cycleId,
  });

  if (isNaN(cycleId)) {
    return null;
  }

  return (
    <Track>
      <BudgetLoadingBoundary
        employeeId={
          managerParam === "all" || managerParam === "null"
            ? null
            : managerParam
        }
        compCycleId={cycleId}
      />
    </Track>
  );
}
