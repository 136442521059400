import { TableBody, TableRow, makeStyles } from "@material-ui/core";
import FormHeader from "src/components/Settings/FormHeader";
import { MarketDataUploadButton } from "./MarketDataUploadButton";
import { AssembleLink } from "src/components/AssembleLink";
import { gql } from "@apollo/client";
import {
  MarketDataSettings_marketDataSet as MarketDataSet,
  MarketDataSettings_marketDataSet,
} from "src/__generated__/graphql";
import {
  WireTable,
  WireTableCell,
  WireTableHead,
  WireTableHeaderCell,
} from "src/components/Table/WireTable";
import { formatDateString } from "src/utils";
import { UserAvatar } from "src/components/UserAvatar";
import { DeleteIcon } from "src/components/AssembleIcons/Brand/DeleteIcon";
import { RED } from "src/theme";
import { EmptyImportIcon } from "src/components/AssembleIcons/Brand/EmptyImportIcon";
import { getMarketDataProviderLogo } from "./util";
import { useSort } from "src/components/SortableTable";
import { basicComparator } from "@asmbl/shared/sort";
import { useTrack } from "src/analytics";

const useStyles = makeStyles((theme) => ({
  sectionSpacer: { marginTop: theme.spacing(4) },
  headerWithButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  endCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  deleteIcon: {
    cursor: "pointer",
  },
  emptyStateContainer: {
    display: "flex",
    justifyContent: "center",
  },
  nameCell: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

type Props = {
  marketDataSets: MarketDataSet[] | null;
  deleteMarketDataSet: (id: number) => void;
  refreshData: () => void;
};

export function MarketDataSettings({
  marketDataSets,
  deleteMarketDataSet,
  refreshData,
}: Props) {
  const classes = useStyles();
  const { trackEvent } = useTrack();
  const onDeleteMarketDataClick = (id: number) => {
    if (window.confirm("Are you sure you want to delete this data set?")) {
      deleteMarketDataSet(id);
      trackEvent({
        object: "Market Data",
        action: "Upload Deleted",
      });
    }
  };

  const {
    sortedArray: sortedMarketDataSets,
    order,
    orderBy,
    handleRequestSort,
  } = useSort(marketDataSets ?? [], "label", "desc", {
    author: (
      a: MarketDataSettings_marketDataSet,
      b: MarketDataSettings_marketDataSet
    ) => basicComparator(a.author.name, b.author.name),
    market: (
      a: MarketDataSettings_marketDataSet,
      b: MarketDataSettings_marketDataSet
    ) => basicComparator(a.market?.name, b.market?.name),
  });

  return (
    <div>
      <div className={classes.headerWithButton}>
        <FormHeader
          className={classes.sectionSpacer}
          header="Market Datasets"
          description={
            <>
              Bring market data in to compare against your compensation bands.
              You can{" "}
              <AssembleLink to="/insights/bands" variant="body2">
                view your market data in Insights
              </AssembleLink>{" "}
              after importing.
            </>
          }
        />
        <MarketDataUploadButton refreshData={refreshData} />
      </div>
      {marketDataSets == null || marketDataSets.length === 0 ? (
        <div className={classes.emptyStateContainer}>
          <EmptyImportIcon />
        </div>
      ) : (
        <WireTable>
          <WireTableHead>
            <TableRow>
              <WireTableHeaderCell
                handleRequestSort={() => handleRequestSort("label")}
                order={order}
                orderByField={"label"}
                isSelected={orderBy === "label"}
                cellTitle="Name"
              />
              <WireTableHeaderCell
                handleRequestSort={() => handleRequestSort("provider")}
                order={order}
                orderByField={"provider"}
                isSelected={orderBy === "provider"}
                cellTitle="Provider"
              />
              <WireTableHeaderCell
                handleRequestSort={() => handleRequestSort("market")}
                order={order}
                orderByField={"market"}
                isSelected={orderBy === "market"}
                cellTitle="Market"
              />
              <WireTableHeaderCell
                handleRequestSort={() => handleRequestSort("createdAt")}
                order={order}
                orderByField={"createdAt"}
                isSelected={orderBy === "createdAt"}
                cellTitle="Date Added"
              />
              <WireTableHeaderCell
                handleRequestSort={() => handleRequestSort("author")}
                order={order}
                orderByField={"author"}
                isSelected={orderBy === "author"}
                cellTitle="Uploaded By"
              />
            </TableRow>
          </WireTableHead>
          <TableBody>
            {sortedMarketDataSets.map((marketDataSet) => (
              <TableRow key={`market-data-${marketDataSet.id}`}>
                <WireTableCell>
                  <span className={classes.nameCell}>
                    {getMarketDataProviderLogo(marketDataSet.provider)}
                    {marketDataSet.label}
                  </span>
                </WireTableCell>
                <WireTableCell>{marketDataSet.provider}</WireTableCell>
                <WireTableCell>{marketDataSet.market?.name}</WireTableCell>
                <WireTableCell>
                  {formatDateString(new Date(marketDataSet.createdAt))}
                </WireTableCell>
                <WireTableCell className={classes.endCell}>
                  <UserAvatar
                    displayName={marketDataSet.author.name}
                    photoURL={marketDataSet.author.photoURL}
                  />
                  <span
                    className={classes.deleteIcon}
                    onClick={() => onDeleteMarketDataClick(marketDataSet.id)}
                  >
                    <DeleteIcon hoverColor={RED} />
                  </span>
                </WireTableCell>
              </TableRow>
            ))}
          </TableBody>
        </WireTable>
      )}
    </div>
  );
}

MarketDataSettings.fragments = {
  marketDataSet: gql`
    fragment MarketDataSettings_marketDataSet on MarketDataSet {
      id
      label
      provider
      createdAt
      market {
        id
        name
      }
      author {
        photoURL
        name
      }
    }
  `,
};
