import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import {
  CompCycleData,
  CompCycleDataChangeHandler,
} from "src/components/CompCycle/CompCycleWizard/types";
import { CompCycleOrganizationChart } from "src/components/CompCycleOrganizationChart/CompCycleOrganizationChart";
import { CompCycleOrganizationChartLoadingBoundary } from "src/components/CompCycleOrganizationChart/CompCycleOrganizationChartLoadingBoundary";
import { COMP_CYCLE_HIERARCHY_CONFIGURATION_SIDEBAR_WIDTH } from "./CompCycleHierarchyConfigurationSidebar";

const useStyles = makeStyles(() => ({
  content: {
    position: "relative",
    left: COMP_CYCLE_HIERARCHY_CONFIGURATION_SIDEBAR_WIDTH,
  },
}));

type Props = {
  onConfigureHome: () => void;
  wizardData: CompCycleData;
  handleChange: CompCycleDataChangeHandler;
  updateStep: (step: number) => unknown;
};

export function ApprovalsAndPhases({
  onConfigureHome,
  handleChange,
  wizardData,
  updateStep,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <CompCycleOrganizationChartLoadingBoundary
        wizardData={wizardData}
        handleChange={handleChange}
        updateStep={updateStep}
        onConfigureHome={onConfigureHome}
      />
    </div>
  );
}

ApprovalsAndPhases.fragments = {
  employee: gql`
    ${CompCycleOrganizationChart.fragments.employee}
    fragment ApprovalsAndPhases_employee on Employee2 {
      id
      ...CompCycleOrganizationChart_employee
    }
  `,
};
