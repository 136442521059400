import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { AssembleLink } from "../../components/AssembleLink";
import {
  SortableTableHeaderCell,
  useSort,
} from "../../components/SortableTable";
import { ComputedDepartment } from "../../models/ComputedDepartment";
import { levelRangeComparator } from "../../utils";
import { useTableStyles } from "./styles";

const useStyles = makeStyles(() => ({
  tableContainer: {
    maxHeight: "calc(100vh - 260px)",
  },
}));

export function DepartmentsListTable({
  departments,
}: {
  departments: ComputedDepartment[];
}): JSX.Element {
  const classes = useStyles();
  const tableClasses = useTableStyles();

  const {
    sortedArray: sortedDepartments,
    order,
    orderBy,
    handleRequestSort,
  } = useSort(departments, "name", "asc", {
    levelMin: levelRangeComparator,
  });

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.tableContainer}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <SortableTableHeaderCell
              cellTitle="Department"
              orderByField="name"
              order={order}
              isSelected={orderBy === "name"}
              handleRequestSort={handleRequestSort}
              noWrap
            />
            <SortableTableHeaderCell
              cellTitle="Ladders"
              orderByField="laddersCount"
              order={order}
              isSelected={orderBy === "laddersCount"}
              handleRequestSort={handleRequestSort}
              noWrap
            />
            <SortableTableHeaderCell
              cellTitle="Levels"
              orderByField="levelMin"
              order={order}
              isSelected={orderBy === "levelMin"}
              handleRequestSort={handleRequestSort}
              noWrap
            />
            <SortableTableHeaderCell
              cellTitle="Positions"
              orderByField="positionsCount"
              order={order}
              isSelected={orderBy === "positionsCount"}
              handleRequestSort={handleRequestSort}
              noWrap
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedDepartments.map((department) => (
            <TableRow key={department.id} className={tableClasses.row}>
              <TableCell scope="row">
                <AssembleLink
                  to={`/departments/${department.id}`}
                  display="inline"
                  underline="none"
                  className={tableClasses.link}
                >
                  {department.name}
                </AssembleLink>
              </TableCell>
              <TableCell scope="row">
                <Typography>{department.laddersCount}</Typography>
              </TableCell>
              <TableCell scope="row">
                <Typography>
                  {department.levelMin}–{department.levelMax}
                </Typography>
              </TableCell>
              <TableCell scope="row">
                <Typography>{department.positionsCount}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
