import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { toggleSet } from "../../utils";

type ExcludedBandNameContextType = {
  excludedBandNames: Set<string>;
  toggleBandExclusion: (bandName: string) => void;
  resetAllExclusions: () => void;
  setExcludedBandNames: (excludedBandNames: Set<string>) => void;
};

const excludedBandNameContext = createContext<ExcludedBandNameContextType>({
  excludedBandNames: new Set(),
  toggleBandExclusion: () => {
    // Default no-op
  },
  resetAllExclusions: () => {
    // Default no-op
  },
  setExcludedBandNames: () => {
    // Default no-op
  },
});

export function ExcludedBandNamesProvider({
  children,
  initialExcludedBands = new Set(),
}: {
  children: ReactNode;
  initialExcludedBands?: Set<string>;
}): JSX.Element {
  const [excludedBandNames, setExcludedBandNames] =
    useState<Set<string>>(initialExcludedBands);

  const toggleBandExclusion = useCallback(
    (bandName: string) => {
      setExcludedBandNames(toggleSet(excludedBandNames, bandName));
    },
    [excludedBandNames]
  );

  const resetAllExclusions = useCallback(() => {
    setExcludedBandNames(new Set());
  }, []);

  return (
    <excludedBandNameContext.Provider
      value={{
        excludedBandNames,
        toggleBandExclusion,
        resetAllExclusions,
        setExcludedBandNames,
      }}
    >
      {children}
    </excludedBandNameContext.Provider>
  );
}

export function useExcludedBandNames(): ExcludedBandNameContextType {
  return useContext(excludedBandNameContext);
}
