import { caseInsensitiveComparator } from "@asmbl/shared/sort";
import { List } from "@material-ui/core";
import { useState } from "react";
import { useTrack } from "src/analytics";
import {
  CompCycleDataChangeHandler,
  CompCyclePhaseData,
} from "src/components/CompCycle/CompCycleWizard/types";
import {
  useCompCycleOrganizationChartSelectionData,
  useCompCycleOrganizationChartViewsData,
} from "src/components/CompCycleOrganizationChart/CompCycleOrganizationChartContext";
import { DialogListItem, EditPhaseDialog } from "./EditPhaseDialog";

type Props = {
  closeModal: () => void;
  phases: CompCyclePhaseData[];
  phase: CompCyclePhaseData;
  previousPhase: CompCyclePhaseData | undefined;
  nextPhase: CompCyclePhaseData | undefined;
  handleChange: CompCycleDataChangeHandler;
  isSettingsView?: boolean;
};

export function EditManagerPhaseModal({
  closeModal,
  phases,
  phase,
  previousPhase,
  nextPhase,
  handleChange,
  isSettingsView = false,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();

  const {
    employeeData,
    setSelectedEmployees,
    selectionMode,
    clearChartSelections,
  } = useCompCycleOrganizationChartSelectionData();

  const { setPageEditState } = useCompCycleOrganizationChartViewsData();

  const [currentEmployees, setCurrentEmployees] = useState(
    new Set(phase.assigneeIds)
  );

  const [calendarDate, setCalendarDate] = useState(phase.startDate);

  const handleSave = () => {
    trackEvent({
      object: "Phase Configuration Save Settings Button",
      action: "Clicked",
      selectionMode,
      numberOfManagers: currentEmployees.size,
      phaseOrder: phase.phaseOrder,
    });

    const assigneeIds = [...currentEmployees.values()];

    const additionalPhases = phases.filter(
      ({ phaseOrder }) => phaseOrder !== phase.phaseOrder
    );

    handleChange(
      "phasesData",
      [
        ...additionalPhases,
        {
          ...phase,
          layers: [],
          assigneeIds,
          startDate: calendarDate,
        },
      ].sort((a, b) => a.phaseOrder - b.phaseOrder)
    );

    setSelectedEmployees(new Set());
    setPageEditState(true);
    closeModal();
  };

  const handlePhaseDelete = () => {
    trackEvent({
      object: "Delete Phase Button",
      action: "Clicked",
    });

    const updatedPhases = [...phases]
      .filter((p) => p.phaseOrder !== phase.phaseOrder)
      .sort((a, b) => a.phaseOrder - b.phaseOrder)
      .map((phase, index) => ({ ...phase, phaseOrder: index + 1 }));

    handleChange("phasesData", updatedPhases);
    setPageEditState(true);
    clearChartSelections();
    closeModal();
  };

  const handleSelectionChange = (employeeId: number) => {
    trackEvent({
      object: "Phase List Item Checkbox",
      action: "Clicked",
      selectionMode,
    });

    const updatedSelection = new Set(currentEmployees);

    updatedSelection.has(employeeId)
      ? updatedSelection.delete(employeeId)
      : updatedSelection.add(employeeId);
    setCurrentEmployees(updatedSelection);
  };

  return (
    <EditPhaseDialog
      closeModal={closeModal}
      phase={phase}
      previousPhase={previousPhase}
      nextPhase={nextPhase}
      handlePhaseDelete={handlePhaseDelete}
      handleSave={handleSave}
      isSettingsView={isSettingsView}
      calendarDate={calendarDate}
      setCalendarDate={setCalendarDate}
    >
      <List>
        {employeeData
          .sort((a, b) =>
            caseInsensitiveComparator(a.displayName, b.displayName)
          )
          .map(
            // need to know if the employee is already selected in another phase
            // first guess: look in phase data  ???
            (emp) => {
              const empId = emp.id;

              // we do not allow adding the synthetic node to a phase
              if (empId === 0) return null;

              const assignedPhase = phases.find((p) =>
                p.assigneeIds.includes(empId)
              );
              const isAssignedToThisPhase =
                assignedPhase?.phaseOrder === phase.phaseOrder;
              const isAssignedToAnotherPhase =
                assignedPhase !== undefined && !isAssignedToThisPhase;

              return (
                <DialogListItem
                  key={empId}
                  isAssignedToAnotherPhase={isAssignedToAnotherPhase}
                  isAssignedToThisPhase={isAssignedToThisPhase}
                  isSettingsView={isSettingsView}
                  listItemName={emp.displayName}
                  assignedPhaseOrder={assignedPhase?.phaseOrder}
                  handleChange={() => handleSelectionChange(empId)}
                />
              );
            }
          )}
      </List>
    </EditPhaseDialog>
  );
}
