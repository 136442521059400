import {
  CompCycleDataChangeHandler,
  CompCyclePhaseData,
} from "src/components/CompCycle/CompCycleWizard/types";
import {
  SelectionMode,
  useCompCycleOrganizationChartSelectionData,
} from "src/components/CompCycleOrganizationChart/CompCycleOrganizationChartContext";
import { EditLayerPhaseModal } from "./EditLayerPhaseModal";
import { EditManagerPhaseModal } from "./EditManagerPhaseModal";

type Props = {
  phases: CompCyclePhaseData[];
  phase: CompCyclePhaseData;
  previousPhase: CompCyclePhaseData | undefined;
  nextPhase: CompCyclePhaseData | undefined;
  handleChange: CompCycleDataChangeHandler;
  closeModal: () => unknown;
  isSettingsView?: boolean;
};

export function EditPhaseModal({
  phases,
  phase,
  previousPhase,
  nextPhase,
  handleChange,
  closeModal,
  isSettingsView = false,
}: Props): JSX.Element {
  const { selectionMode } = useCompCycleOrganizationChartSelectionData();

  const modalKey = phases.map((p) => p.assigneeIds).join("");

  return selectionMode === SelectionMode.Layers ? (
    <EditLayerPhaseModal
      key={modalKey}
      closeModal={closeModal}
      phases={phases}
      phase={phase}
      previousPhase={previousPhase}
      nextPhase={nextPhase}
      handleChange={handleChange}
      isSettingsView={isSettingsView}
    />
  ) : (
    <EditManagerPhaseModal
      key={modalKey}
      closeModal={closeModal}
      phases={phases}
      phase={phase}
      previousPhase={previousPhase}
      nextPhase={nextPhase}
      handleChange={handleChange}
      isSettingsView={isSettingsView}
    />
  );
}
