import { gql } from "@apollo/client";
import {
  eq,
  formatCurrency,
  Money,
  moneyComparator,
  zero,
} from "@asmbl/shared/money";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import clsx from "clsx";
import { memo } from "react";
import { HourlyDotIcon } from "src/components/AssembleIcons/DataViz/HourlyDotIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { SubcomponentSlider_bandPoint as BandPoint } from "../../../__generated__/graphql";
import { getSimpleCashLabel } from "../../../models/Currency";
import { GRAY_1, GRAY_4, GRAY_9, WHITE } from "../../../theme";
const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    width: "100%",
  },
  band: {
    background: GRAY_4,
    borderRadius: "2px",
    display: "flex",
    flexDirection: "row",
    height: "5px",
    position: "relative",
    width: "100%",
  },
  hourlyContainer: {
    borderRadius: "2px",
    display: "flex",
    flexDirection: "row",
    height: "5px",
    position: "relative",
    width: "100%",
    alignContent: "center",
    justifyContent: "center",
  },
  singleBand: {
    background: GRAY_9,
  },
  bandMidPoints: {
    background: WHITE,
    border: "none",
    borderRadius: "100%",
    boxShadow: "none",
    cursor: "pointer",
    height: "3px",
    width: "3px",
    position: "relative",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  dot: {
    backgroundColor: WHITE,
    border: `1px solid ${GRAY_4}`,
    borderRadius: "9px",
    boxShadow: "0px 1px 2px rgba(10, 36, 64, 0.2)",
    height: "9px",
    marginLeft: "-4px",
    marginTop: "-8px",
    position: "relative",
    width: "9px",
  },
  hourlyDot: {
    border: `1px solid ${GRAY_4}`,
    borderRadius: "9px",
    boxShadow: "0px 1px 2px rgba(10, 36, 64, 0.2)",
    height: "9px",
    marginLeft: "-4px",
    marginTop: "-8px",
    position: "relative",
    width: "9px",
  },
  outOfRange: { border: `1px solid ${GRAY_1}` },
  icon: {
    color: GRAY_1,
    fontSize: "9px",
    height: "9px",
    left: -1,
    position: "absolute",
    top: -1,
    width: "9px",
  },
  hourlyIcon: {
    color: GRAY_1,
    fontSize: "5px",
    height: "5px",
    left: -1,
    position: "absolute",
    top: -1,
    width: "5px",
  },
  line: {
    background: GRAY_9,
    color: GRAY_9,
    height: "100%",
    position: "absolute",
    top: 0,
    width: "1px",
  },
  hourlyBandDot: {
    height: "100%",
    position: "absolute",
    top: 0,
    width: "1px",
  },
  offset: {
    height: "100%",
    position: "relative",
    width: "1px",
  },
  captionValue: {
    color: GRAY_4,
  },
  labels: {
    display: "flex",
    flexDirection: "row",
  },
  hourlyBandPointLabel: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
}));

export type Props = {
  value: Money;
  bandPoints: BandPoint[];
  isHourly: boolean;
};

export type HourlyBandPointProps = {
  value: Money;
  bandPointValue: Money;
};

function SubcomponentSlider({ value, bandPoints, isHourly }: Props) {
  const classes = useStyles();

  const sortedBandPoints = bandPoints
    .map(
      (x) =>
        (isHourly ? x.value.hourlyRate : x.value.annualRate) ??
        zero(x.value.currencyCode)
    )
    .sort(moneyComparator);

  const min = sortedBandPoints[0];
  const max = sortedBandPoints[sortedBandPoints.length - 1];
  const midPoints = sortedBandPoints.slice(1, sortedBandPoints.length - 1);

  const isSinglePointBand = bandPoints.length === 1 || eq(min, max);

  const belowRange = value.value < min.value;
  const aboveRange = value.value > max.value;
  const outOfRange = belowRange || aboveRange;

  return isSinglePointBand && isHourly ? (
    <SubcomponentHourlyBandPoint value={value} bandPointValue={min} />
  ) : (
    <div className={classes.container}>
      <div
        className={clsx(classes.band, {
          [classes.singleBand]: isSinglePointBand,
        })}
      >
        {midPoints.map((point) => (
          <div // The various midpoints of the band
            key={point.value}
            className={classes.offset}
            style={{ left: `${getOffset(point, min, max)}%` }}
          >
            <Tooltip title={formatMoney(point)} placement="top">
              <div className={classes.bandMidPoints} />
            </Tooltip>
          </div>
        ))}
        <div // The actual value on the slider
          className={classes.line}
          style={{ left: `${getOffset(value, min, max)}%` }}
        >
          <div
            className={clsx(classes.dot, { [classes.outOfRange]: outOfRange })}
          >
            {aboveRange && <Add className={classes.icon} />}
            {belowRange && <Remove className={classes.icon} />}
          </div>
        </div>
      </div>
      <div // Min & Max Labels
        className={classes.labels}
        style={{
          justifyContent: isSinglePointBand ? "center" : "space-between",
        }}
      >
        <Typography variant="caption" className={classes.captionValue}>
          {formatMoney(min)}
        </Typography>
        {!isSinglePointBand && (
          <Typography variant="caption" className={classes.captionValue}>
            {formatMoney(max)}
          </Typography>
        )}
      </div>
    </div>
  );
}

function SubcomponentHourlyBandPoint({
  value,
  bandPointValue,
}: HourlyBandPointProps): JSX.Element {
  const classes = useStyles();
  const belowRange = value.value < bandPointValue.value;
  const aboveRange = value.value > bandPointValue.value;

  return (
    <div className={classes.container}>
      <div className={classes.hourlyContainer}>
        <div // point values
          className={classes.hourlyBandDot}
        >
          <HourlyDotIcon width="5px" height="5px" color={GRAY_4} />
          {(aboveRange || belowRange) && (
            <div
              className={classes.hourlyDot}
              style={{ left: getHourlyOffset(value, bandPointValue) }}
            >
              {aboveRange && <Add className={classes.icon} />}
              {belowRange && <Remove className={classes.icon} />}
            </div>
          )}
        </div>
      </div>
      <div // bandpoint label
        className={classes.hourlyBandPointLabel}
      >
        <AssembleTypography variant="productSmallSemiBold" textColor={GRAY_4}>
          {`${formatMoney(bandPointValue)}/hr`}
        </AssembleTypography>
      </div>
    </div>
  );
}

function getOffset(value: Money, min: Money, max: Money): number {
  // if the min & max values are the same then we just want the offset to be in
  // the center
  if (min.value === max.value) return 50;

  const offset = ((value.value - min.value) / (max.value - min.value)) * 100;

  if (offset > 100) return 100;
  if (offset < 0) return 0;
  return offset;
}

function getHourlyOffset(value: Money, bandPoint: Money): number {
  const offset = value.value - bandPoint.value;
  // keep the above/below icons from being too far from the bandpoint dot
  if (offset < -100) {
    return -100;
  } else if (offset > 100) {
    return 100;
    // keep the below/above icons from overlapping the bandpoint dot
  } else if (offset < 10 && offset > 0) {
    return 10;
  } else if (offset < 0 && offset > -10) {
    return -10;
  }

  return offset;
}

function formatMoney(money: Money): string {
  if (money.value < 100_000) return getSimpleCashLabel(money);

  return formatCurrency(money, {
    notation: "compact",
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 3,
  });
}

const MemoizedSubcomponentSlider = memo(SubcomponentSlider);

export default Object.assign(MemoizedSubcomponentSlider, {
  fragments: {
    bandPoint: gql`
      fragment SubcomponentSlider_bandPoint on AdjustedCashBandPoint {
        name
        value {
          ... on CashValue {
            annualRate
            hourlyRate
            currencyCode
          }
        }
      }
    `,
  },
});
