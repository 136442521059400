import { gql, useQuery } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { useLocations } from "../../../components/LocationsContext";
import { Range } from "../../../utils";
import {
  AnonymizedCompensationCellsLoadingBoundary_position as Position,
  AnonymizedCompensationCells_permissionSettings as PermissionSettings,
  GetAnonymizedCompensationDataForVisualization,
  GetAnonymizedCompensationDataForVisualizationVariables,
} from "../../../__generated__/graphql";
import { AnonymizedCompensationCells } from "./AnonymizedCompensationCells";
import { AnonymizedCompensationLoadingCells } from "./AnonymizedCompensationLoadingCells";

type Props = {
  permissionSettings: PermissionSettings;
  position: Position;
  selectedCurrencyCode: CurrencyCode;
  totalCompRange: Range;
};

export function AnonymizedCompensationCellsLoadingBoundary({
  permissionSettings,
  position,
  selectedCurrencyCode,
  totalCompRange,
}: Props): JSX.Element | null {
  const { selectedLocation } = useLocations();
  const selectedLocationGroup = selectedLocation?.[1] ?? null;

  const { data, loading } = useQuery<
    GetAnonymizedCompensationDataForVisualization,
    GetAnonymizedCompensationDataForVisualizationVariables
  >(AnonymizedCompensationCellsLoadingBoundary.query, {
    variables: {
      positionId: position.id,
      locationGroupId: selectedLocationGroup?.id ?? -1,
      currencyCode: selectedCurrencyCode,
    },
    skip: !selectedLocationGroup,
  });

  if (!selectedLocationGroup) {
    return null;
  }

  if (!data) {
    return (
      <AnonymizedCompensationLoadingCells
        loading={loading}
        totalCompRange={totalCompRange}
      />
    );
  }

  return (
    <AnonymizedCompensationCells
      position={position}
      selectedCurrencyCode={selectedCurrencyCode}
      totalCompRange={totalCompRange}
      anonymizedCompensation={data.anonymizedCompensation}
      permissionSettings={permissionSettings}
    />
  );
}

AnonymizedCompensationCellsLoadingBoundary.query = gql`
  ${AnonymizedCompensationCells.fragments.anonymizedData}
  query GetAnonymizedCompensationDataForVisualization(
    $currencyCode: String!
    $positionId: Int!
    $locationGroupId: Int!
  ) {
    anonymizedCompensation(
      currencyCode: $currencyCode
      positionId: $positionId
      locationGroupId: $locationGroupId
    ) {
      ...AnonymizedCompensationCells_anonymizedCompensation
    }
  }
`;

AnonymizedCompensationCellsLoadingBoundary.fragments = {
  position: gql`
    ${AnonymizedCompensationCells.fragments.position}
    fragment AnonymizedCompensationCellsLoadingBoundary_position on Position {
      id
      ...AnonymizedCompensationCells_position
    }
  `,
  permissionSettings: gql`
    ${AnonymizedCompensationCells.fragments.permissionSettings}
    fragment AnonymizedCompensationCellsLoadingBoundary_permissionSettings on PermissionSettings {
      id
      ...AnonymizedCompensationCells_permissionSettings
    }
  `,
};
