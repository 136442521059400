import { round } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/styles";
import { Cell, Pie, PieChart } from "recharts";
import { DV_BLUE_2, GRAY_5 } from "../../theme";
import { TotalEquityTable_equityGrant } from "../../__generated__/graphql";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    width: "100%",
    paddingLeft: "0",
  },
}));

export function VestingPercentageCell({
  value,
  row,
}: {
  value: {
    percentVested: number | null;
    vestedUnits: number | null;
    unvestedUnits: number | null;
  } | null;
  row: {
    original: TotalEquityTable_equityGrant;
  };
}): JSX.Element {
  const classes = useStyles();

  const noGrantEvents =
    new Date(row.original.vestingStartDate) < new Date() &&
    (row.original.vestingInformation === null ||
      row.original.vestingInformation.vestEvents.length === 0);

  const pieChartData = [
    { name: "vestedUnits", value: value?.vestedUnits ?? 0 },
    // setting unvested to 1 ensures the pie chart renders when there is no data
    { name: "unvestedUnits", value: value?.unvestedUnits ?? 1 },
  ];

  const roundedPercentValue =
    value !== null && value.percentVested !== null
      ? round(value.percentVested)
      : "";

  const oneDataGroup =
    roundedPercentValue === "100" ||
    roundedPercentValue === "" ||
    roundedPercentValue === "0";

  return (
    <div className={classes.root}>
      <PieChart width={40} height={50}>
        <Pie
          data={pieChartData}
          dataKey="value"
          fill={noGrantEvents ? GRAY_5 : DV_BLUE_2}
          cx={"40%"}
          cy={"50%"}
          innerRadius={10}
          outerRadius={16}
          startAngle={90}
          endAngle={450}
          paddingAngle={oneDataGroup ? undefined : 4}
          isAnimationActive={false}
        >
          {pieChartData.map((entry, index) => (
            <Cell
              key={index}
              opacity={entry.name === "unvestedUnits" ? "30%" : "100%"}
              stroke={oneDataGroup ? "" : undefined}
            />
          ))}
        </Pie>
      </PieChart>

      <>
        {roundedPercentValue !== "" && !noGrantEvents
          ? `${roundedPercentValue}%`
          : "No Data"}
      </>
    </div>
  );
}
