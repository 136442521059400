import { gql, useQuery } from "@apollo/client";
import { useUpdateEligibilityRules } from "src/mutations/CompCycle";
import {
  GetEligibilityOptions,
  GetEligibilityOptionsVariables,
} from "src/__generated__/graphql";
import { EligibilityEditor } from "./EligibilityEditor";

type Props = { compCycleId: number };

export function EligibilityEditorLoadingBoundary({
  compCycleId,
}: Props): JSX.Element {
  const { data } = useQuery<
    GetEligibilityOptions,
    GetEligibilityOptionsVariables
  >(query, { variables: { compCycleId } });

  const handleChange = useUpdateEligibilityRules(compCycleId);

  const departments = data?.organization.departments.map((d) => ({
    label: d.name,
    value: d.id,
  }));

  const ladders = data?.organization.departments
    .flatMap((d) => d.ladders)
    .map((l) => ({ label: l.name, value: l.id }));

  const levels = (data?.organization.compStructure?.levels ?? []).map((l) => ({
    label: l.toString(),
    value: l,
  }));

  const perfRatings = (data?.compCycle?.perfRatings ?? []).map((p) => ({
    label: p,
    value: p,
  }));

  return (
    <EligibilityEditor
      compCycleId={compCycleId}
      ruleSet={data?.compCycle?.eligibilityRules ?? []}
      onChange={handleChange}
      employees={data?.employees ?? []}
      departmentOptions={departments}
      ladderOptions={ladders}
      levelOptions={levels}
      perfRatingOptions={perfRatings}
      loading={!data}
    />
  );
}

const query = gql`
  ${EligibilityEditor.fragments.organization}
  ${EligibilityEditor.fragments.compCycle}
  ${EligibilityEditor.fragments.employee}
  query GetEligibilityOptions($compCycleId: Int!) {
    organization {
      id
      ...EligibilityEditor_organization
    }
    compCycle(id: $compCycleId) {
      id
      eligibilityRules
      ...EligibilityEditor_compCycle
    }
    employees {
      id
      ...EligibilityEditor_employee
    }
  }
`;
