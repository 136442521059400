import { gql, useQuery } from "@apollo/client";
import { contramap } from "@asmbl/shared/sort";
import { useParams } from "react-router-dom";
import { ErrorView } from "src/components/ErrorView";
import {
  CompCycleReviewPhases_employee,
  CompCycleReviewPhasesBoundary_participant,
  CompCycleReviewPhasesBoundaryQuery,
  CompCycleReviewPhasesBoundaryQueryVariables,
} from "../../../__generated__/graphql";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useCompleteCompCycle } from "../../../mutations/CompCycle";
import { CompCycleReviewPhases } from "./CompCycleReviewPhases";

export type CompCycleReviewer = CompCycleReviewPhases_employee & {
  assignees: CompCycleReviewPhasesBoundary_participant[];
};

export function CompCycleReviewPhasesBoundary(): JSX.Element {
  const { compCycleId } = useParams<{ compCycleId: string }>();
  const cycleId = compCycleId != null ? parseInt(compCycleId, 10) : NaN;

  const { data, loading } = useQuery<
    CompCycleReviewPhasesBoundaryQuery,
    CompCycleReviewPhasesBoundaryQueryVariables
  >(CompCycleReviewPhasesBoundary.query, {
    variables: {
      compCycleId: cycleId,
    },
    skip: isNaN(cycleId),
  });

  const completeCompCycle = useCompleteCompCycle(cycleId);

  if (!data?.compCycle2) {
    return loading ? (
      <LoadingSpinner />
    ) : (
      <ErrorView text="This comp cycle was deleted or does not exist. If you think something went wrong, please contact us for support." />
    );
  }

  const reviewerMap = new Map<number, CompCycleReviewer>();
  const assignments = data.compCycle2.phases
    .flatMap((phase) => phase.compCyclePhaseAssignments)
    .filter((a) => a != null);

  data.compCycle2.participants.participants.forEach((p) => {
    if (p.subject.managerIds != null) {
      p.subject.managerIds.forEach((mgrId) => {
        const reviewer = reviewerMap.get(mgrId);
        if (reviewer) {
          reviewer.assignees.push(p);
        } else {
          const rvwr = assignments.find((a) => a.assigneeId === mgrId);
          if (rvwr) {
            reviewerMap.set(mgrId, { assignees: [p], ...rvwr.employee });
          }
        }
      });
    }
  });

  const sortedManagers = [...reviewerMap.values()].sort(
    contramap((r) => r.displayName)
  );

  return (
    <CompCycleReviewPhases
      authors={sortedManagers}
      compCycle={data.compCycle2}
      onComplete={completeCompCycle}
    />
  );
}

CompCycleReviewPhasesBoundary.fragments = {
  participant: gql`
    fragment CompCycleReviewPhasesBoundary_participant on CompCycleParticipant {
      subjectId
      compCycleId
      subject {
        id
        managerId
        managerIds
      }
      compRecommendation {
        subjectId
        compCycleId
        reviewStatus
        latestSubmittedItems {
          id
          submittedAt
          author {
            id
            name
          }
        }
        latestSubmittedReviews {
          id
          status
          authorId
        }
      }
    }
  `,
  phase: gql`
    ${CompCycleReviewPhases.fragments.reviewer}
    fragment CompCycleReviewPhasesBoundary_phase on CompCyclePhase2 {
      id
      compCyclePhaseAssignments {
        id
        status
        assigneeId
        employee {
          id
          ...CompCycleReviewPhases_employee
        }
        recommendees {
          id
          participant {
            subjectId
            compCycleId
            managerId
          }
        }
        reviewees {
          id
          participant {
            subjectId
            compCycleId
            managerId
          }
        }
      }
    }
  `,
};

CompCycleReviewPhasesBoundary.query = gql`
  ${CompCycleReviewPhases.fragments.compCycle}
  ${CompCycleReviewPhasesBoundary.fragments.participant}
  ${CompCycleReviewPhasesBoundary.fragments.phase}
  query CompCycleReviewPhasesBoundaryQuery($compCycleId: Int!) {
    compCycle2(id: $compCycleId) {
      id
      participants {
        participants {
          ...CompCycleReviewPhasesBoundary_participant
        }
      }
      phases {
        ...CompCycleReviewPhasesBoundary_phase
      }
      currentPhase {
        id
        compCyclePhaseAssignments {
          id
          phaseId
          status
          assigneeId
        }
      }
      ...CompCycleReviewPhases_compCycle
    }
  }
`;
