import { gql } from "@apollo/client";
import { getTenure } from "@asmbl/shared/employee";
import { formatElapsedTime } from "@asmbl/shared/time";
import { ClockIcon } from "src/components/AssembleIcons/Brand/ClockIcon";
import { TenureBubble_employee } from "../../../__generated__/graphql";
import { BubbleInfo } from "./BubbleInfo";

type Props = {
  employee: TenureBubble_employee;
};

export function TenureBubble({ employee }: Props): JSX.Element {
  const tenure = getTenure(employee);

  return (
    <BubbleInfo
      label="Tenure"
      info={tenure == null ? "-" : formatElapsedTime(tenure)}
      icon={<ClockIcon inherit />}
      color="cyan"
    />
  );
}

TenureBubble.fragments = {
  employee: gql`
    fragment TenureBubble_employee on Employee {
      activeAt
    }
  `,
};
