import { ListUnorderedIcon } from "src/components/AssembleIcons/Brand/ListUnorderedIcon";
import { StyledBlockButton } from "../StyledBlockButton/StyledBlockButton";

const UNORDERED_LIST = "unordered-list-item";

export type UnorderedListBlockProps = {
  editorState: Draft.EditorState;
  onClick: () => unknown;
};

export const UnorderedListBlock = (
  props: UnorderedListBlockProps
): JSX.Element => {
  const { editorState, onClick } = props;

  const startKey = editorState.getSelection().getStartKey();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(startKey)
    .getType();

  const active = blockType === UNORDERED_LIST;

  return (
    <StyledBlockButton active={active} onClick={onClick}>
      <ListUnorderedIcon width="24px" height="24px" inherit />
    </StyledBlockButton>
  );
};
