import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useTrack } from "src/analytics";
import { useDeleteMarketDataSet } from "src/mutations/MarketData";
import { format } from "timeago.js";
import {
  GetDataManagementInfo,
  GetOrgJobStructureCsv,
  GetOrgJobStructureWithBandsCsv,
  GetOrgLevelingCodeMappingCsv,
  Noun,
} from "../../__generated__/graphql";
import HrisCsvIllustration from "../../assets/svgs/illustrations/integrations/hris-csv.svg?react";
import LevelingCodeDownloadIllustration from "../../assets/svgs/illustrations/integrations/leveling-code-mapping-download.svg?react";
import LevelingCodeUploadIllustration from "../../assets/svgs/illustrations/integrations/leveling-code-mapping-upload.svg?react";
import PeopleDownloadIllustration from "../../assets/svgs/illustrations/integrations/people-download.svg?react";
import VariableCompCsvIllustration from "../../assets/svgs/illustrations/integrations/variable-comp-csv.svg?react";
import { AccessBoundary } from "../../components/AccessBoundary";
import { useAuth } from "../../components/Auth/AuthContext";
import { CsvDownloader } from "../../components/CsvExport/CsvDownloader";
import { PreviewFeature } from "../../components/FeatureContext";
import { CardButton } from "../../components/Form/CardButton";
import { EquityImportManagement } from "../../components/Settings/Equity/EquityImportManagement";
import FormHeader from "../../components/Settings/FormHeader";
import {
  cartaOauthUrl,
  useCartaResponse,
} from "../../components/Settings/Integrations/Carta/CartaUtil";
import { Integrations } from "../../components/Settings/Integrations/Integrations";
import { ManualHrisImportDialog } from "../../components/Settings/Integrations/ManualHris/ManualHrisImportDialog";
import { RipplingImportRecord } from "../../components/Settings/Integrations/ManualHris/RipplingImportButton";
import { LevelingCodesImportDialog } from "../../components/Settings/LevelingCodes/ImportDialog";
import Loading from "../../components/Settings/Loading";
import { VariableCompImportDialog } from "../../components/Settings/VariableComp/VariableCompImportDialog";
import { useImportRipplingPayrollCsv } from "../../mutations/Integrations";
import { WHITE } from "../../theme";
import { MarketDataSettings } from "./MarketData/MarketDataSettings";
import { PageContainer } from "./PageContainer";

const useStyles = makeStyles((theme) => ({
  sectionSpacer: { marginTop: theme.spacing(4) },
  buttonRow: {
    display: "flex",
    gap: theme.spacing(2),
  },
  errorRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  cartaLink: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: WHITE,
    textDecoration: "none",
  },
}));

export const DataManagement = (): JSX.Element => {
  const { organization, userId } = useAuth();
  const handleCartaResponse = useCartaResponse();
  const { Track, trackEvent } = useTrack({ subArea: "Data Management" });

  const orgId = organization?.id ?? null;

  const classes = useStyles();

  useEffect(() => {
    void handleCartaResponse();
  }, [handleCartaResponse]);

  const [isManualHrisDialogOpen, setIsManualHrisDialogOpen] = useState(false);
  const [isVariableCompDialogOpen, setIsVariableCompDialogOpen] =
    useState(false);
  const [isLevelingCodeDialogOpen, setIsLevelingCodeDialogOpen] =
    useState(false);

  const { data, loading, refetch } = useQuery<GetDataManagementInfo>(
    queries.organization
  );

  const deleteMarketDataSet = useDeleteMarketDataSet();

  const handleDeleteMarketDataSet = async (id: number) => {
    await deleteMarketDataSet(id);
    await refetch();
  };

  const openVariableCompDialog = () => {
    setIsVariableCompDialogOpen(true);

    trackEvent({
      object: "Import Employee Variable Comp",
      action: "Clicked",
    });
  };

  const [getExport, { loading: jobStructureLoading }] =
    useLazyQuery<GetOrgJobStructureCsv>(queries.getOrgJobStructureCsv, {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    });

  const [getExportWithBands, { loading: bandsLoading }] =
    useLazyQuery<GetOrgJobStructureWithBandsCsv>(
      queries.getOrgJobStructureWithBandsCsv,
      { fetchPolicy: "no-cache", nextFetchPolicy: "no-cache" }
    );

  const [getLevelingCodeExport, { loading: levelingCodeMappingLoading }] =
    useLazyQuery<GetOrgLevelingCodeMappingCsv>(
      queries.getOrgLevelingCodeMappings,
      { fetchPolicy: "no-cache", nextFetchPolicy: "no-cache" }
    );

  const handleOrgEquityUploadDateRefetch = async () => {
    // update the "last updated" subtitle so the user knows the data was uploaded
    await refetch();
  };

  const importRipplingPayrollCsv = useImportRipplingPayrollCsv();

  if (loading) return <Loading />;
  if (!data) return <p>Server error!</p>;

  const handleSubmitHrisData = async (data: RipplingImportRecord[]) => {
    await importRipplingPayrollCsv(data);
    setIsManualHrisDialogOpen(false);
    await refetch();
  };

  const lastManualSyncAt: string | null = data.mergeConnectionConfigs
    .filter((c) => c.isManualUpload)
    .map((c) => c.lastSyncAt as string)
    .reduce<string | null>(
      (prev, cur) =>
        prev === null || new Date(cur) > new Date(prev) ? cur : prev,
      null
    );

  // Cash Compensation includes salary, so we want to exclude any salary
  // values when determining the last upload date
  const { lastActualVariableCompUploadDate, lastVariableUpload } =
    data.organization;

  const getLastVariableUploadDate = (): GraphQL_DateTime | null => {
    if (
      lastActualVariableCompUploadDate != null &&
      lastVariableUpload != null
    ) {
      return new Date(lastActualVariableCompUploadDate).getTime() >
        new Date(lastVariableUpload).getTime()
        ? lastActualVariableCompUploadDate
        : lastVariableUpload;
    }

    return lastVariableUpload ?? lastActualVariableCompUploadDate;
  };

  const lastVariableUploadDate = getLastVariableUploadDate();

  const showCartaError =
    !!data.cartaConnectionConfig && !data.cartaConnectionConfig.isActive;

  return (
    <Track>
      <PageContainer
        header="Data Management"
        description="Manage the data that you bring into (and take out of) Assemble."
        banner={
          showCartaError && (
            <div className={classes.errorRow}>
              <span>
                Uh oh, it appears we're no longer receiving data from Carta.
              </span>
              <a
                className={classes.cartaLink}
                href={cartaOauthUrl(orgId, userId)}
              >
                <span style={{ marginRight: "1rem" }}>Reconnect Carta</span>
                <OpenInNew />
              </a>
            </div>
          )
        }
      >
        <>
          <Integrations
            mergeConnectionConfigs={data.mergeConnectionConfigs.filter(
              (c) => !c.isManualUpload
            )}
            cartaConnectionConfig={data.cartaConnectionConfig}
          />

          <FormHeader
            className={classes.sectionSpacer}
            header="Employee Data Imports"
            description={
              <>
                Manually import your employee data via <b>.csv format.</b>
              </>
            }
          />
          <div className={classes.buttonRow}>
            <ManualHrisImportDialog
              open={isManualHrisDialogOpen}
              onClose={() => setIsManualHrisDialogOpen(false)}
              onSubmit={handleSubmitHrisData}
            />
            <CardButton
              svg={<HrisCsvIllustration />}
              label="HRIS Data"
              sublabel={
                lastManualSyncAt !== null
                  ? `Last imported ${format(new Date(lastManualSyncAt))}`
                  : "Never imported"
              }
              onClick={() => setIsManualHrisDialogOpen(true)}
            />
            <EquityImportManagement
              orgId={orgId}
              employees={data.employees}
              orgData={data.organization}
              onEquityUpload={handleOrgEquityUploadDateRefetch}
              disabled={data.cartaConnectionConfig !== null}
            />
            <VariableCompImportDialog
              employees={data.employees}
              open={isVariableCompDialogOpen}
              onClose={() => setIsVariableCompDialogOpen(false)}
              onUpload={async () => {
                await refetch();
                setIsVariableCompDialogOpen(false);
              }}
            />
            <CardButton
              svg={<VariableCompCsvIllustration />}
              label="Variable Comp (Target & Actual)"
              sublabel={
                lastVariableUploadDate != null
                  ? `Last imported ${format(lastVariableUploadDate)}`
                  : "Never imported"
              }
              onClick={openVariableCompDialog}
            />
            <PreviewFeature flag={FeatureFlag.AutomaticLeveling}>
              <>
                <LevelingCodesImportDialog
                  positions={data.positions}
                  levelingCodeMappings={data.organization.levelingCodeMappings}
                  open={isLevelingCodeDialogOpen}
                  onClose={() => setIsLevelingCodeDialogOpen(false)}
                  onUpload={async () => {
                    await refetch();
                    setIsLevelingCodeDialogOpen(false);
                  }}
                />
                <CardButton
                  svg={<LevelingCodeUploadIllustration />}
                  label="Leveling Codes"
                  sublabel={
                    data.organization.lastLevelingCodeUploadDate !== null
                      ? `Last imported ${format(
                          data.organization.lastLevelingCodeUploadDate
                        )}`
                      : "Never imported"
                  }
                  onClick={() => setIsLevelingCodeDialogOpen(true)}
                />
              </>
            </PreviewFeature>
          </div>
          {/* Only someone with Full Access should be able to see the buttons
          for exporting */}
          <AccessBoundary
            scope="global"
            verb="view"
            every={[Noun.CashBand, Noun.EquityBand, Noun.JobStructure]}
          >
            <FormHeader
              className={classes.sectionSpacer}
              header="Organization Data Exports"
              description={
                <>
                  Export your organization's data in <b>.csv format.</b>
                </>
              }
            />

            <div className={classes.buttonRow}>
              <CsvDownloader
                fetch={() =>
                  getExport().then(
                    (result) => result.data?.exportOrgJobStructureToCsv.data
                  )
                }
                filename="Job Architecture.csv"
              >
                {(onDownload) => (
                  <CardButton
                    onClick={onDownload}
                    svg={
                      jobStructureLoading ? (
                        <CircularProgress />
                      ) : (
                        <PeopleDownloadIllustration />
                      )
                    }
                    label="Job Architecture"
                  />
                )}
              </CsvDownloader>
              <CsvDownloader
                fetch={() =>
                  getExportWithBands().then(
                    (result) =>
                      result.data?.exportOrgJobStructureWithBandsToCsv.data
                  )
                }
                filename="Job Architecture And Bands.csv"
              >
                {(onDownload) => (
                  <CardButton
                    onClick={onDownload}
                    label="Job Architecture & Comp Bands"
                    svg={
                      bandsLoading ? (
                        <CircularProgress />
                      ) : (
                        <PeopleDownloadIllustration />
                      )
                    }
                  />
                )}
              </CsvDownloader>
              <PreviewFeature flag={FeatureFlag.AutomaticLeveling}>
                <CsvDownloader
                  fetch={() =>
                    getLevelingCodeExport().then(
                      (result) =>
                        result.data?.exportOrgLevelingCodeMappingsToCsv.data
                    )
                  }
                  filename="LevelingCode Mappings.csv"
                >
                  {(onDownload) => (
                    <CardButton
                      onClick={onDownload}
                      label="Leveling Codes"
                      svg={
                        levelingCodeMappingLoading ? (
                          <CircularProgress />
                        ) : (
                          <LevelingCodeDownloadIllustration />
                        )
                      }
                    />
                  )}
                </CsvDownloader>
              </PreviewFeature>
            </div>
            <MarketDataSettings
              marketDataSets={data.marketDataSets}
              deleteMarketDataSet={handleDeleteMarketDataSet}
              refreshData={refetch}
            />
          </AccessBoundary>
        </>
      </PageContainer>
    </Track>
  );
};

const queries = {
  getOrgJobStructureCsv: gql`
    query GetOrgJobStructureCsv {
      exportOrgJobStructureToCsv {
        data
      }
    }
  `,
  getOrgJobStructureWithBandsCsv: gql`
    query GetOrgJobStructureWithBandsCsv {
      exportOrgJobStructureWithBandsToCsv {
        data
      }
    }
  `,
  getOrgLevelingCodeMappings: gql`
    query GetOrgLevelingCodeMappingCsv {
      exportOrgLevelingCodeMappingsToCsv {
        data
      }
    }
  `,
  organization: gql`
    ${EquityImportManagement.fragments.organization}
    ${EquityImportManagement.fragments.employee}
    ${VariableCompImportDialog.fragments.employee}
    ${LevelingCodesImportDialog.fragments.position}
    ${LevelingCodesImportDialog.fragments.levelingCodeMapping}
    ${Integrations.fragments.cartaConnectionConfig}
    ${MarketDataSettings.fragments.marketDataSet}
    query GetDataManagementInfo {
      organization {
        id
        lastLevelingCodeUploadDate
        ...EquityImportManagement_organization
        levelingCodeMappings {
          ...LevelingCodesImportDialog_levelingCodeMapping
        }
        lastVariableUpload
        lastActualVariableCompUploadDate
      }
      employees {
        id
        ...EquityImportManagement_employee
        ...VariableCompImportDialog_employee
        cashCompensation {
          employeeId
          type
          activeAt
          annualCashEquivalent
          createdAt
        }
      }
      mergeConnectionConfigs {
        id
        integrationName
        integrationType
        imageUrl
        lastSyncAt
        originId
        isManualUpload
        author {
          id
          name
          photoURL
        }
      }
      cartaConnectionConfig {
        id
        ...Integrations_cartaConnectionConfig
      }
      positions {
        id
        ...LevelingCodesImportDialog_position
      }
      marketDataSets {
        ...MarketDataSettings_marketDataSet
      }
    }
  `,
};
