import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { forwardRef, ReactNode, Ref, useEffect } from "react";

type Props = {
  id: string;
  children: ReactNode;
  scrollOffset?: "small" | "large";
};

const useStyles = makeStyles((theme) => ({
  small: { top: `-${theme.spacing(6.75)}px` },
  large: { top: `-${theme.spacing(9.5)}px` },
  root: { position: "relative" },
  anchor: { position: "absolute", left: 0 },
}));

export const IdAnchor = forwardRef(function IdAnchor(
  { id, children, scrollOffset }: Props,
  ref: Ref<HTMLDivElement>
): JSX.Element {
  const classes = useStyles();

  /**
   * this effect is only meant to run on init load, this scrolls the
   * intended component into view (if there is a hash in the URL)
   */
  useEffect(() => {
    // remove the `#` from the URL hash
    const updatedHash = window.location.hash.substring(1);
    const element = document.getElementById(updatedHash);
    element?.scrollIntoView();
  }, []);

  return (
    <div className={classes.root} ref={ref}>
      <div
        id={id}
        className={clsx(classes.anchor, {
          [classes.small]: scrollOffset === "small",
          [classes.large]: scrollOffset === "large",
        })}
      />
      {children}
    </div>
  );
});
