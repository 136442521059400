import { gql } from "@apollo/client";
import { Money, multiply, ratio, zero } from "@asmbl/shared/money";
import { formatNumeral, mapMaybe } from "@asmbl/shared/utils";
import { useCompStructure } from "src/components/CompStructureContext";
import {
  CashCompType,
  CompUnit,
  RecItemType,
  TargetVariablePayChange_cashCompensation,
  TargetVariablePayChange_recItem,
} from "../../../__generated__/graphql";
import { BudgetType } from "../../../models/Budget";
import { getTotalCashAfterRecommendation } from "../../../models/CashCompensation";
import {
  CompItemRecommendationTypeTitle,
  whereType,
} from "../../../models/CompRecommendation";
import { getSimpleCashLabel } from "../../../models/Currency";
import { AssembleTypography } from "../../AssembleTypography";
import { useCurrencies } from "../../CurrenciesContext";
import { CompComponentContainer } from "./CompComponentContainer";
import { CompValue } from "./CompValue";
import { LabelValue } from "./LabelValue";

type RecItemWithValue = TargetVariablePayChange_recItem & {
  recommendedCashValue: Money | null;
  recommendedPercentValue: number | null;
};

export function TargetVariablePayChange({
  cashCompensations,
  recItems,
}: {
  cashCompensations: TargetVariablePayChange_cashCompensation[];
  recItems: TargetVariablePayChange_recItem[];
}): JSX.Element | null {
  const { compStructure } = useCompStructure();
  const workingHoursPerYear = compStructure?.workingHoursPerYear;
  const { defaultCurrencyCode } = useCurrencies();

  const targetRecItems = mapMaybe(
    [RecItemType.TARGET_COMMISSION, RecItemType.TARGET_RECURRING_BONUS],
    (type) =>
      recItems
        .filter(
          (item): item is RecItemWithValue =>
            item.recommendedCashValue !== null ||
            item.recommendedPercentValue !== null
        )
        .find(whereType(type))
  );

  if (targetRecItems.length === 0) {
    return null;
  }

  const targetCurrency = targetRecItems.find(
    (item) => item.recommendedCashValue !== null
  );

  const newSalary =
    getTotalCashAfterRecommendation(
      defaultCurrencyCode,
      cashCompensations,
      recItems,
      workingHoursPerYear
    )?.subcomponents.find((item) => item.type === CashCompType.SALARY)
      ?.annualCashEquivalent ??
    zero(targetCurrency?.recommendedCashValue?.currency ?? defaultCurrencyCode);

  return (
    <CompComponentContainer dashed>
      <AssembleTypography
        variant="productEyebrowSmall"
        color="textSecondary"
        gutterBottom
      >
        {`New ${BudgetType.TARGET_VAR_PAY}`}
      </AssembleTypography>
      {targetRecItems.map((item) => {
        const cashCompensation = cashCompensations.find(
          (comp) =>
            comp.type === recItemTypeToCashCompType(item.recommendationType)
        );

        const itemValue = item.recommendedCashValue
          ? item.recommendedCashValue
          : item.recommendedPercentValue != null
            ? multiply(newSalary, item.recommendedPercentValue / 100)
            : undefined;

        const percentValue =
          item.recommendedPercentValue != null
            ? item.recommendedPercentValue
            : item.recommendedCashValue && newSalary.value !== 0
              ? ratio(item.recommendedCashValue, newSalary) * 100
              : null;

        return (
          <LabelValue
            key={item.recommendationType}
            label={CompItemRecommendationTypeTitle[item.recommendationType]}
            compValue={
              <CompValue
                previousValue={
                  cashCompensation &&
                  formatCashAndPercent(
                    cashCompensation.annualCashEquivalent,
                    cashCompensation.unit === CompUnit.PERCENT_OF_SALARY
                      ? cashCompensation.percentOfSalary
                      : null
                  )
                }
                value={
                  itemValue !== undefined
                    ? formatCashAndPercent(itemValue, percentValue, "new")
                    : "N/A"
                }
              />
            }
          />
        );
      })}
    </CompComponentContainer>
  );
}

function recItemTypeToCashCompType(type: RecItemType): CashCompType {
  const map: Partial<Record<RecItemType, CashCompType>> = {
    [RecItemType.TARGET_COMMISSION]: CashCompType.COMMISSION,
    [RecItemType.TARGET_RECURRING_BONUS]: CashCompType.RECURRING_BONUS,
  };

  return map[type] ?? CashCompType.SALARY;
}

function formatCashAndPercent(
  cashValue: Money,
  percentValue: number | null,
  salaryDescriptor = ""
) {
  const cash = getSimpleCashLabel(cashValue);

  let parenthetical = "";
  if (percentValue !== null) {
    const percent = formatNumeral(percentValue / 100, {
      style: "percent",
      maximumSignificantDigits: 3,
      minimumSignificantDigits: 2,
    });
    parenthetical = ` (${percent} of ${salaryDescriptor} salary)`;
  }

  return `${cash}${parenthetical}`;
}

TargetVariablePayChange.fragments = {
  recItem: gql`
    fragment TargetVariablePayChange_recItem on RecItem {
      recommendationType
      recommendedCashValue
      recommendedPercentValue
    }
  `,
  cashCompensation: gql`
    fragment TargetVariablePayChange_cashCompensation on CashCompensation {
      type
      unit
      annualCashEquivalent
      hourlyCashEquivalent
      unit
      percentOfSalary
    }
  `,
};
