import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const IllustrativeOutcomesIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Illustrative Outcomes"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M9 1.08296C8.67479 1.0284 8.34071 1 8 1C4.68629 1 2 3.68629 2 7C2 10.3137 4.68629 13 8 13C10.973 13 13.441 10.8377 13.917 8"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        className="stroke"
        d="M6 13L3.68702 14.542C3.48121 14.6792 3.57834 15 3.82569 15H12.1743C12.4217 15 12.5188 14.6792 12.313 14.542L10 13H6Z"
        strokeWidth="2"
      />
      <path
        className="stroke"
        d="M13 1V5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M15 3H11"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
