import { MONTHS_IN_A_YEAR } from "@asmbl/shared/constants";
import { Money, multiply } from "@asmbl/shared/money";
import { memo } from "react";
import { BLUE_2 } from "../../theme";
import { NonNull } from "../../utils";
import { GetOffer } from "../../__generated__/graphql";
import Header from "./Header";
import { LegendItem } from "./Legend/Legend";
import { CompBreakdown } from "./OfferFrames";
import Visualization from "./Visualization";
import { SeriesDataPoint } from "./Visualization/Visualization";

type CompStructure = NonNull<GetOffer["compStructure"]>;
type OfferConfig = NonNull<GetOffer["offerConfig"]>;
type Valuation = NonNull<GetOffer["valuation"]>;
type Offer = NonNull<GetOffer["offer"]>;

type CumulativeProps = {
  data: CompBreakdown;
  projections: Money[];
  xAxisAnnotations: string[][] | undefined;
  valuation: Valuation;
  compStructure: CompStructure;
  offerConfig: OfferConfig;
  offer: Offer;
};

const Cumulative = ({
  data,
  projections,
  xAxisAnnotations,
  valuation,
  compStructure,
  offerConfig,
  offer,
}: CumulativeProps) => {
  const color = offerConfig.brandColorPrimary ?? BLUE_2;

  const generateBarData = (
    data: CompBreakdown,
    compStructure: CompStructure
  ) => {
    const cumulativeCompProjections: number[] = [];
    const vestingYears = compStructure.vestingMonths / MONTHS_IN_A_YEAR;
    let max = multiply(data.maxProjected, vestingYears);

    if (data.equity && data.equity.projectedAnnualValues.length > 0) {
      const sortedProjectedAnnualValues = [
        ...data.equity.projectedAnnualValues,
      ].sort((a, b) => b.value - a.value);

      max = multiply(sortedProjectedAnnualValues[0], vestingYears);
    }

    data.equity?.projectedAnnualValues.forEach((projectedAnnualValue) => {
      cumulativeCompProjections.push(
        multiply(projectedAnnualValue, vestingYears).value
      );
    });

    return { barData: cumulativeCompProjections, max };
  };

  const graphData = generateBarData(data, compStructure);

  const barData: SeriesDataPoint[] = [
    {
      name: "Cumulative",
      type: "bar",
      color,
      data: graphData.barData,
    },
  ];

  const barChartLegendItems: LegendItem[] = [
    {
      color,
      value: "Total Equity (Full Vesting Period)",
    },
  ];

  return (
    <>
      <Header
        color={color}
        description="Full Vesting Period"
        headline="Total Equity"
        subHead="Illustrative Outcomes"
      />
      <Visualization
        projections={projections}
        xAxisAnnotations={xAxisAnnotations}
        maxProjected={graphData.max}
        seriesData={barData}
        outcomeDescription={offer.outcomeDescription}
        legendItems={barChartLegendItems}
        equityGrantTypes={compStructure.equityGrantTypes}
        compStructure={compStructure}
        valuation={valuation}
        units={data.equity?.units}
      />
    </>
  );
};

export default memo(Cumulative);
