import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const CompanyInformationIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Company Information"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M13.9996 13.9989V6.4989C13.9996 6.36629 13.947 6.23912 13.8532 6.14535C13.7594 6.05158 13.6322 5.9989 13.4996 5.9989H10.9996M9.99963 13.9989V2.4989C9.99963 2.36629 9.94696 2.23912 9.85319 2.14535C9.75942 2.05158 9.63224 1.9989 9.49963 1.9989H2.49963C2.36703 1.9989 2.23985 2.05158 2.14608 2.14535C2.05231 2.23912 1.99963 2.36629 1.99963 2.4989V13.9989H9.99963Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M5.24976 4.4989H6.99976"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M2.99976 8.4989H5.74976"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M5.24976 10.9989H6.99976"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
