import { gql } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import {
  LevelingCodeMapping,
  LevelingCodesImportButton_position as Position,
} from "../../../__generated__/graphql";
import { LevelingCodesImportButton } from "./ImportButton";

interface Props {
  open: boolean;
  positions: Position[];
  levelingCodeMappings: LevelingCodeMapping[];
  onClose?: () => unknown;
  onUpload?: () => unknown;
}

export function LevelingCodesImportDialog({
  open,
  positions,
  levelingCodeMappings,
  onClose,
  onUpload,
}: Props): JSX.Element {
  return (
    <Dialog open={open} onClose={onClose} disableEnforceFocus>
      <DialogTitle>Add or update Leveling Code mappings</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          Uploading a CSV will add or update the Leveling Codes for every
          position in the CSV.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <LevelingCodesImportButton
          positions={positions}
          mappings={levelingCodeMappings}
          onCancel={onClose}
          onUpload={onUpload}
        />
      </DialogActions>
    </Dialog>
  );
}

LevelingCodesImportDialog.fragments = {
  position: gql`
    ${LevelingCodesImportButton.fragments.position}
    fragment LevelingCodesImportDialog_position on Position {
      id
      ...LevelingCodesImportButton_position
    }
  `,
  levelingCodeMapping: gql`
    ${LevelingCodesImportButton.fragments.levelingCodeMapping}
    fragment LevelingCodesImportDialog_levelingCodeMapping on LevelingCodeMapping {
      ...LevelingCodesImportButton_levelingCodeMapping
    }
  `,
};
