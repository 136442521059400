import { gql } from "@apollo/client";
import { CompCycleDisplay } from "../../../../components/CompCycle/types";
import {
  ReportingSalaryTier_compCycleBudget as CompCycleBudget,
  ReportingSalaryTier_compCycleData as CompCycleData,
  ReportingSalaryTier_manager as Manager,
  ReportingSalaryTier_reports as Report,
} from "../../../../__generated__/graphql";
import { IndentationCells } from "../../Budgets/Table/Connectors";
import { SalaryRow } from "./SalaryRow";
import { SalarySummarizedRow } from "./SalarySummarizedRow";

type Props = {
  rootBudget: CompCycleBudget | null;
  manager: Manager | null;
  reports: Report[];
  compCycleData: CompCycleData;
  subComponentCount: number;
  organizationName: string;
  varianceDisplay: CompCycleDisplay;
  indentation: IndentationCells;
};

export function ReportingSalaryTier({
  rootBudget,
  manager,
  reports,
  organizationName,
  varianceDisplay,
  indentation,
  subComponentCount,
  compCycleData,
}: Props): JSX.Element {
  return (
    <>
      <SalarySummarizedRow
        manager={manager}
        reports={reports}
        rootBudget={rootBudget}
        varianceDisplay={varianceDisplay}
        organizationName={organizationName}
        compCycleData={compCycleData}
        indentation={indentation}
      />
      {reports
        .filter(
          (employee) =>
            employee.indirectReports.length > 0 ||
            employee.directReports.length > 0
        )
        .map((employee, idx, filteredEmployees) => {
          return (
            <SalaryRow
              key={idx}
              employee={employee}
              organizationName={organizationName}
              expandable={employee.indirectReports.length > 0}
              indentation={indentation}
              varianceDisplay={varianceDisplay}
              compCycleData={compCycleData}
              subComponentCount={subComponentCount}
              isLastRow={idx === filteredEmployees.length - 1}
            />
          );
        })}
    </>
  );
}

ReportingSalaryTier.fragments = {
  manager: gql`
    ${SalarySummarizedRow.fragments.manager}
    fragment ReportingSalaryTier_manager on Employee {
      id
      ...SalarySummarizedRow_manager
    }
  `,
  reports: gql`
    ${SalarySummarizedRow.fragments.reports}
    ${SalaryRow.fragments.employee}
    fragment ReportingSalaryTier_reports on Employee {
      id

      directReports {
        id
      }

      indirectReports {
        id
      }

      ...SalaryRow_employee
      ...SalarySummarizedRow_reports
    }
  `,
  compCycleBudget: gql`
    ${SalarySummarizedRow.fragments.compCycleBudget}
    fragment ReportingSalaryTier_compCycleBudget on CompCycleBudget {
      compCycleId
      employeeId
      ...SalarySummarizedRow_compCycleBudget
    }
  `,
  compCycleData: gql`
    ${SalarySummarizedRow.fragments.compCycleData}
    ${SalaryRow.fragments.compCycleData}
    fragment ReportingSalaryTier_compCycleData on CompCycle {
      id
      ...SalarySummarizedRow_compCycleData
      ...SalaryRow_compCycleData
    }
  `,
};
