import { gql, Reference, useMutation } from "@apollo/client";
import { PrimaryRoleName } from "@asmbl/shared/permissions";
import { useCallback } from "react";
import {
  ClearCompensationUserInvitationAccessGrant,
  ClearCompensationUserInvitationAccessGrantVariables,
  EmplaceCompensationUserInviteAccessGrant,
  EmplaceCompensationUserInviteAccessGrantVariables,
  ScopeInput,
  UpdateUserInvitationAccessGrant,
  UpdateUserInvitationAccessGrantVariables,
} from "../__generated__/graphql";

const UPDATE_USER_INVITATION_ACCESS_GRANT = gql`
  mutation UpdateUserInvitationAccessGrant(
    $data: UserInvitationAccessGrantRoleInput!
  ) {
    updateUserInvitationAccessGrantRole(data: $data) {
      id
      name
      email
      grant
    }
  }
`;

export function useUpdateUserInvitationAccessGrant(
  grantedUserInvitationId: number
): (role: PrimaryRoleName, supportedManagerIds?: number[]) => Promise<unknown> {
  const [updateUserInvitationAccessGrant] = useMutation<
    UpdateUserInvitationAccessGrant,
    UpdateUserInvitationAccessGrantVariables
  >(UPDATE_USER_INVITATION_ACCESS_GRANT, {
    update(cache, { data }) {
      if (data == null) {
        return;
      }

      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          userInvitationFromId() {
            return data.updateUserInvitationAccessGrantRole;
          },
          activeAssembleUserInvitations(
            existingUserInvitations: Reference[],
            { readField }
          ) {
            return existingUserInvitations.map((userInvitation) => {
              const currentId = readField("id", userInvitation) as number;

              return data.updateUserInvitationAccessGrantRole.id === currentId
                ? data.updateUserInvitationAccessGrantRole
                : userInvitation;
            });
          },
        },
      });
    },
  });

  return useCallback(
    (role, supportedManagerIds) =>
      updateUserInvitationAccessGrant({
        variables: {
          data: { grantedUserInvitationId, role, supportedManagerIds },
        },
      }),
    [grantedUserInvitationId, updateUserInvitationAccessGrant]
  );
}

const EMPLACE_COMPENSATION_USER_INVITE_ACCESS_GRANT = gql`
  mutation EmplaceCompensationUserInviteAccessGrant(
    $grantedUserInvitationId: Int!
    $marketId: Int
    $scope: ScopeInput
  ) {
    updateUserInvitationAccessGrantCompBandAccess(
      data: {
        grantedUserInvitationId: $grantedUserInvitationId
        marketId: $marketId
        scope: $scope
      }
    ) {
      id
      name
      email
      grant
    }
  }
`;

export function useEmplaceCompensationUserInvitationAccessGrant(): (
  grantedUserInvitationId: number,
  marketId: number | null,
  scope: ScopeInput | null
) => Promise<unknown> {
  const [emplaceCompensationUserInvitationAccessGrant] = useMutation<
    EmplaceCompensationUserInviteAccessGrant,
    EmplaceCompensationUserInviteAccessGrantVariables
  >(EMPLACE_COMPENSATION_USER_INVITE_ACCESS_GRANT, {
    update(cache, { data }) {
      if (data == null) {
        return;
      }

      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          userInvitationFromId() {
            return data.updateUserInvitationAccessGrantCompBandAccess;
          },
        },
      });
    },
  });

  return useCallback(
    (grantedUserInvitationId, marketId, scope) =>
      emplaceCompensationUserInvitationAccessGrant({
        variables: { grantedUserInvitationId, marketId, scope },
      }),
    [emplaceCompensationUserInvitationAccessGrant]
  );
}

export function useEmplaceCompensationUserInvitationAccessGrantCallback(
  grantedUserInvitationId: number
): (marketId: number | null, scope: ScopeInput | null) => Promise<unknown> {
  const [emplaceCompensationUserInvitationAccessGrant] = useMutation<
    EmplaceCompensationUserInviteAccessGrant,
    EmplaceCompensationUserInviteAccessGrantVariables
  >(EMPLACE_COMPENSATION_USER_INVITE_ACCESS_GRANT, {
    update(cache, { data }) {
      if (data == null) {
        return;
      }

      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          userInvitationFromId() {
            return data.updateUserInvitationAccessGrantCompBandAccess;
          },
        },
      });
    },
  });

  return useCallback(
    (marketId, scope) =>
      emplaceCompensationUserInvitationAccessGrant({
        variables: { grantedUserInvitationId, marketId, scope },
      }),
    [emplaceCompensationUserInvitationAccessGrant, grantedUserInvitationId]
  );
}

const CLEAR_COMPENSATION_USER_INVITATION_ACCESS_GRANT = gql`
  mutation ClearCompensationUserInvitationAccessGrant(
    $grantedUserInvitationId: Int!
  ) {
    clearUserInvitationAccessGrantCompBandAccess(
      grantedUserInvitationId: $grantedUserInvitationId
    ) {
      id
      name
      email
      grant
    }
  }
`;
export function useClearCompensationUserInvitationAccessGrant(
  grantedUserInvitationId: number
): () => Promise<unknown> {
  const [clearCompensationUserAccessGrant] = useMutation<
    ClearCompensationUserInvitationAccessGrant,
    ClearCompensationUserInvitationAccessGrantVariables
  >(CLEAR_COMPENSATION_USER_INVITATION_ACCESS_GRANT, {
    update(cache, { data }) {
      if (data == null) {
        return;
      }

      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          userInvitationFromId() {
            return data.clearUserInvitationAccessGrantCompBandAccess;
          },
          activeAssembleUserInvitations(
            existingUserInvitations: Reference[],
            { readField }
          ) {
            return existingUserInvitations.map((userInvitation) => {
              const currentId = readField("id", userInvitation) as number;

              return data.clearUserInvitationAccessGrantCompBandAccess.id ===
                currentId
                ? data.clearUserInvitationAccessGrantCompBandAccess
                : userInvitation;
            });
          },
        },
      });
    },
  });

  return useCallback(
    () =>
      clearCompensationUserAccessGrant({
        variables: { grantedUserInvitationId },
      }),
    [clearCompensationUserAccessGrant, grantedUserInvitationId]
  );
}
