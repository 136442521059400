import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { ReactNode, useState } from "react";

type Props = {
  title: string;
  actionButtonTitle: string;
  body: string;
  onDialogClick: () => void;
  children: (onClick: () => unknown) => ReactNode;
};

export const PortalDialog = ({
  title,
  actionButtonTitle,
  body,
  onDialogClick,
  children,
}: Props) => {
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const handleClick = () => {
    onDialogClick();
    setOpen(false);
  };
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{body}</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleClick}>
            {actionButtonTitle}
          </Button>
        </DialogActions>
      </Dialog>
      {children(() => setOpen(true))}
    </>
  );
};
