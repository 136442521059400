import { formatCurrency, Money, multiply } from "@asmbl/shared/money";
import { TotalEquityTable_equityGrant } from "../../__generated__/graphql";

export function GrossValueCell({
  value,
  sharePrice,
  row,
}: {
  value: Money | null;
  sharePrice?: Money | undefined;
  row: {
    original: TotalEquityTable_equityGrant;
  };
}): JSX.Element {
  if (sharePrice === undefined || value === null) {
    return <>N/A</>;
  }
  return (
    <>
      {formatCurrency(multiply(sharePrice, row.original.unitCount), {
        notation: "standard",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </>
  );
}
