import { cx } from "@emotion/css";
import { makeStyles } from "@material-ui/core";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 8,
    padding: theme.spacing(3),
    background: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));
/**
 * A rounded-corner box to hold content.
 */
export function Card({ children, className }: Props): JSX.Element {
  const classes = useStyles();
  return <div className={cx(classes.root, className)}>{children}</div>;
}
