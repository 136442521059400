import { makeStyles } from "@material-ui/core";
import { cloneElement } from "react";
import { GRAY_4 } from "src/theme";
import { Card } from "./Card";

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    gap: theme.spacing(3),
  },
  verticalSeparator: {
    height: "100%",
    borderLeft: `1px dashed ${GRAY_4}`,
    marginBlock: theme.spacing(1),
  },
}));
/**
 * This groups multiple related statistics together onto a combined card.
 **/

export function MultiCard({
  className,
  children,
}: {
  className?: string;
  children: React.ReactElement[];
}): JSX.Element {
  const classes = useStyles();

  return (
    <Card className={className}>
      <div className={classes.contentWrapper}>
        {joinElements(children, <div className={classes.verticalSeparator} />)}
      </div>
    </Card>
  );
}

/**
 * Like Array.prototype.join but for JSX elements.
 */
export function joinElements(
  elements: React.ReactNode[],
  separator: React.ReactElement
): JSX.Element {
  return (
    <>
      {elements.flatMap((element, index) => [
        index > 0 ? cloneElement(separator, { key: index }) : null,
        element,
      ])}
    </>
  );
}
