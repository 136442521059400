import { contramap } from "@asmbl/shared/sort";
import { isEmptyString } from "@asmbl/shared/utils";
import { LocationRow } from "../LocationsTable";

export function CityCell({ value }: { value: string | null }): JSX.Element {
  return <>{isEmptyString(value) ? "N/A" : value}</>;
}

Object.assign(CityCell, {
  Cell: CityCell,
  id: "city" as const,
  Header: "City" as const,
  accessor: "city" as const,
  width: 2.75,
  ordering: contramap((location: LocationRow) => location.city),
});
