import { Grid, makeStyles, RadioGroup } from "@material-ui/core";
import {
  PermissionSettingsInput,
  TeamCompensationAccessType,
} from "../../../__generated__/graphql";
import { PermissionSettings } from "../../../mutations/PermissionSettings";
import { theme } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";
import { PolicyCard } from "./PolicyChoices/PolicyCard";

const useStyles = makeStyles(() => ({
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  header: { marginBottom: theme.spacing(1) },
}));

interface Props {
  current: PermissionSettings;
  handleAccessChange: (
    data: PermissionSettingsInput,
    current: PermissionSettings
  ) => Promise<void>;
  compensationAccessType: TeamCompensationAccessType;
}

export function CompensationPolicySection({
  current,
  handleAccessChange,
  compensationAccessType,
}: Props): JSX.Element {
  const classes = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    void handleAccessChange(
      {
        teamCompensationAccess: e.target.value as TeamCompensationAccessType,
      },
      current
    );
  };

  return (
    <>
      <div className={classes.descriptionContainer}>
        <AssembleTypography
          variant="productSectionHeader"
          className={classes.header}
        >
          Employee Compensation
        </AssembleTypography>
        <AssembleTypography variant="formDescription">
          Who should be able to see employee cash compensation and employee
          equity grants?
        </AssembleTypography>
      </div>
      <RadioGroup onChange={handleChange} value={compensationAccessType}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <PolicyCard
              isDefault
              accessType={compensationAccessType}
              value={TeamCompensationAccessType.NONE}
              header="People with Full Access Only"
              bulletPoints={[
                <>
                  People assigned the <b>Full Access</b> role can see every
                  employee's cash and equity compensation.
                </>,
              ]}
            />
          </Grid>
          <Grid item xs={4}>
            <PolicyCard
              accessType={compensationAccessType}
              value={TeamCompensationAccessType.INDIRECT_REPORTS_CASH}
              header={
                <>
                  People with Full Access and Managers <br />
                  (Cash Only)
                </>
              }
              bulletPoints={[
                <>
                  Supervisors can see only the cash compensation of their direct
                  and indirect reports.
                </>,
                <>
                  People assigned the <b>Full Access</b> role can see every
                  employee's cash and equity compensation.
                </>,
              ]}
              subtext="Reporting relationships are imported from your HRIS."
            />
          </Grid>
          <Grid item xs={4}>
            <PolicyCard
              accessType={compensationAccessType}
              value={
                TeamCompensationAccessType.INDIRECT_REPORTS_CASH_AND_EQUITY
              }
              header={
                <>
                  People with Full Access and Managers <br />
                  (Cash & Equity)
                </>
              }
              bulletPoints={[
                <>
                  Supervisors can see both the cash and equity compensation of
                  their direct and indirect reports.
                </>,
                <>
                  People assigned the <b>Full Access</b> role can see every
                  employee's cash and equity compensation.
                </>,
              ]}
              subtext="Reporting relationships are imported from your HRIS."
            />
          </Grid>
        </Grid>
      </RadioGroup>
    </>
  );
}
