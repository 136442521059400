import { gql } from "@apollo/client";
import { Box, Button } from "@material-ui/core";
import { useCallback, useState } from "react";
import { ExplanatoryTooltip } from "src/components/ExplanatoryTooltip";
import { useUpsertMergeConnectionConfig } from "../../../mutations/Integrations";
import { ArrayValue } from "../../../utils";
import {
  GetMergeConfigs,
  Integrations_cartaConnectionConfig as CartaConnectionConfig,
  MergeCategory,
} from "../../../__generated__/graphql";
import { useAuth } from "../../Auth/AuthContext";
import FormHeader from "../FormHeader";
import { cartaOauthUrl } from "./Carta/CartaUtil";
import { ConnectIntegrationButton } from "./ConnectIntegrationButton";
import { IntegrationsTable } from "./IntegrationsTable";
import { MergeModal } from "./MergeModal";

type MergeConnectionConfig = ArrayValue<
  GetMergeConfigs["mergeConnectionConfigs"]
>;

export function Integrations({
  mergeConnectionConfigs,
  cartaConnectionConfig,
}: {
  mergeConnectionConfigs: MergeConnectionConfig[];
  cartaConnectionConfig?: CartaConnectionConfig | null;
}): JSX.Element {
  const upsertMergeConnectionConfig = useUpsertMergeConnectionConfig();
  const { userId, organization } = useAuth();

  const [mergeProps, setMergeProps] = useState<{
    originId: string | null;
    category: MergeCategory;
  }>();

  const editIntegration = (originId: string, category: MergeCategory): void =>
    setMergeProps({ originId, category });

  const createHrisIntegration = () =>
    setMergeProps({ originId: null, category: MergeCategory.hris });

  const createAtsIntegration = () =>
    setMergeProps({ originId: null, category: MergeCategory.ats });

  const onMergeSuccess = useCallback(
    (originId: string, publicToken: string, category: MergeCategory) => {
      void upsertMergeConnectionConfig(originId, publicToken, category);
      setMergeProps(undefined);
    },
    [upsertMergeConnectionConfig]
  );

  const createCartaIntegration = () => {
    if (organization !== null && userId !== null) {
      window.location.href = cartaOauthUrl(organization.id, userId);
    }
  };

  const hasAtsIntegration = mergeConnectionConfigs.some(
    (x) => x.integrationType === "ats"
  );

  const hasCartaIntegration = cartaConnectionConfig != null;

  return (
    <>
      {mergeProps && (
        <MergeModal
          key={mergeProps.originId ?? "null"}
          originId={mergeProps.originId}
          category={mergeProps.category}
          onSuccess={onMergeSuccess}
          onExit={() => setMergeProps(undefined)}
        />
      )}
      <Box display="flex" justifyContent="space-between" mb={2}>
        <FormHeader header="Integrations" mb={1} />
        <Box display="flex" flexDirection="row" style={{ gap: "1rem" }}>
          <ExplanatoryTooltip
            title={
              hasCartaIntegration
                ? "You already have a Cap Table integration."
                : "Add your Carta Cap Table."
            }
            body={
              hasCartaIntegration
                ? "You can only integrate with one Cap Table  at a time. Let us know if you'd like to connect to a different one."
                : "Bring your Carta information directly into Assemble."
            }
            ctaLabel={hasCartaIntegration ? "Contact Us" : "Learn How"}
            ctaUrl="https://help.assemble.inc/en/articles/8218654-connecting-your-carta-cap-table-to-assemble"
            newTab
            openIntercom={hasCartaIntegration}
            width="8rem"
          >
            <span>
              <Button
                size="small"
                style={{ height: "100%" }}
                variant="outlined"
                color="primary"
                onClick={createCartaIntegration}
                disabled={hasCartaIntegration}
              >
                Add Cap Table
              </Button>
            </span>
          </ExplanatoryTooltip>
          <ConnectIntegrationButton
            createIntegration={createAtsIntegration}
            category="ATS"
            disableButton={hasAtsIntegration}
            buttonStyle="outlined"
          />
          <ConnectIntegrationButton
            createIntegration={createHrisIntegration}
            category="HRIS"
            buttonStyle="contained"
          />
        </Box>
      </Box>
      <IntegrationsTable
        integrationConfigs={mergeConnectionConfigs}
        cartaConnectionConfig={cartaConnectionConfig}
        editIntegration={editIntegration}
        createCartaIntegration={createCartaIntegration}
      />
    </>
  );
}

Integrations.fragments = {
  cartaConnectionConfig: gql`
    ${IntegrationsTable.fragments.cartaConnectionConfig}
    fragment Integrations_cartaConnectionConfig on CartaConnectionConfig {
      ...IntegrationsTable_cartaConnectionConfig
    }
  `,
};
