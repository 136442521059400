import "./SignInWithGoogle.css";

type Props = {
  onClick: () => unknown;
};

/**
 * This is a copy of the StyledFirebaseButton we get from
 * {@link https://github.com/firebase/firebaseui-web-react} with all the same
 * CSS. The only difference is that we can control the functionality.
 */
export function SignInWithGoogleButton({ onClick }: Props): JSX.Element {
  return (
    <div id="firebaseui_container" lang="en">
      <div className="firebaseui-container firebaseui-page-provider-sign-in firebaseui-id-page-provider-sign-in firebaseui-use-spinner">
        <div className="firebaseui-card-content">
          <ul className="firebaseui-idp-list">
            <li className="firebaseui-list-item">
              <button
                onClick={onClick}
                className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-google firebaseui-id-idp-button"
                style={{ backgroundColor: "#ffffff" }}
              >
                <span className="firebaseui-idp-icon-wrapper">
                  <img
                    className="firebaseui-idp-icon"
                    alt=""
                    src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                  />
                </span>
                <span className="firebaseui-idp-text firebaseui-idp-text-long">
                  Sign in with Google
                </span>
                <span className="firebaseui-idp-text firebaseui-idp-text-short">
                  Google
                </span>
              </button>
            </li>
          </ul>
        </div>
        <div className="firebaseui-card-footer firebaseui-provider-sign-in-footer"></div>
      </div>
    </div>
  );
}
