import { TooltipProps } from "@material-ui/core";
import { ReactNode } from "react";
import { Props as ExplanatoryTooltipProps } from "./ExplanatoryTooltip";

interface Props {
  children: ReactNode;
  Tooltip: React.ElementType;
  showTooltip: boolean;
  tooltipProps:
    | Omit<ExplanatoryTooltipProps, "children">
    | Omit<TooltipProps, "children">
    | undefined;
}

export function ConditionalTooltipWrapper({
  children,
  Tooltip,
  showTooltip,
  tooltipProps,
}: Props): JSX.Element {
  return showTooltip ? (
    <>
      <Tooltip {...tooltipProps}>{children}</Tooltip>
    </>
  ) : (
    <>{children}</>
  );
}
