import { contramap } from "@asmbl/shared/sort";
import { isEmptyString } from "@asmbl/shared/utils";
import { LocationRow } from "../LocationsTable";

export function LocationGroupCell({
  value,
}: {
  value: { id: number; name: string } | null;
}): JSX.Element {
  return (
    <>{value === null || isEmptyString(value.name) ? "N/A" : value.name}</>
  );
}

Object.assign(LocationGroupCell, {
  Cell: LocationGroupCell,
  id: "locationGroup" as const,
  Header: "Location Group" as const,
  accessor: "locationGroup" as const,
  width: 2.75,
  ordering: contramap(
    (location: LocationRow) => location.locationGroup?.name ?? ""
  ),
});
