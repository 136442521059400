import { gql, useQuery } from "@apollo/client";
import Loading from "../../../components/Settings/Loading";
import {
  MarketsQuery,
  MarketsQueryVariables,
} from "../../../__generated__/graphql";
import { MarketsList } from "./MarketsList";

export function MarketsLoadingBoundary(): JSX.Element {
  const { data, loading } = useQuery<MarketsQuery, MarketsQueryVariables>(
    MarketsLoadingBoundary.query
  );
  if (!data) {
    if (loading) {
      return <Loading />;
    }
    return <>An error occurred while loading this page.</>;
  }

  return <MarketsList markets={data.markets} />;
}

MarketsLoadingBoundary.query = gql`
  ${MarketsList.fragments.market}
  query MarketsQuery {
    markets {
      id
      ...MarketsList_market
    }
  }
`;
