import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const WarningAlertIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Warning"
      viewBox="0 0 25 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M12 9V12.75"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M10.7018 3.74857L2.45402 17.9978C2.32204 18.2259 2.25245 18.4846 2.25223 18.748C2.25201 19.0115 2.32118 19.2703 2.45278 19.4986C2.58437 19.7268 2.77376 19.9163 3.00187 20.0481C3.22999 20.1799 3.48879 20.2493 3.75223 20.2493H20.2478C20.5112 20.2493 20.77 20.1799 20.9981 20.0481C21.2263 19.9163 21.4156 19.7268 21.5472 19.4986C21.6788 19.2703 21.748 19.0115 21.7478 18.748C21.7476 18.4846 21.678 18.2259 21.546 17.9978L13.2982 3.74857C13.1664 3.52093 12.9771 3.33193 12.7493 3.20055C12.5214 3.06916 12.263 3 12 3C11.737 3 11.4786 3.06916 11.2507 3.20055C11.0229 3.33193 10.8336 3.52093 10.7018 3.74857V3.74857Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 16.5C12.5 16.7761 12.2761 17 12 17C11.7239 17 11.5 16.7761 11.5 16.5C11.5 16.2239 11.7239 16 12 16C12.2761 16 12.5 16.2239 12.5 16.5Z"
        className="stroke"
        fill="#88A4BE"
        strokeWidth="2"
      />
    </svg>
  </ColorableSVG>
);
