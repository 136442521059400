import { SearchableFilter } from "src/components/Filter/SearchableFilter";
import { SearchableFilterContainer } from "src/components/Filter/SearchableFilterContainer";
import {
  CorrectionCostToMoveFilterName,
  CorrectionCostToMoveFilterParam,
  FilterOptionsType,
} from "./CorrectionCostToMoveFilters";

type Props = {
  onChange: (field: string, values: number[]) => void;
  onReset: () => void;
  filter: { [key: string]: number[] | null | undefined };
  filterOptions: FilterOptionsType;
};

const CorrectionCostToMoveFilterBar = ({
  onChange,
  onReset,
  filter,
  filterOptions,
}: Props) => {
  const activeFilterMap: Record<CorrectionCostToMoveFilterName, boolean> = {
    [CorrectionCostToMoveFilterName.CURRENT_DEPARTMENT]:
      (filter[CorrectionCostToMoveFilterParam.CURRENT_DEPARTMENT]?.length ??
        0) > 0,
    [CorrectionCostToMoveFilterName.MANAGER]:
      (filter[CorrectionCostToMoveFilterParam.MANAGER]?.length ?? 0) > 0,
    [CorrectionCostToMoveFilterName.REPORTS]:
      (filter[CorrectionCostToMoveFilterParam.REPORTS]?.length ?? 0) > 0,
    [CorrectionCostToMoveFilterName.CURRENT_LADDER]:
      (filter[CorrectionCostToMoveFilterParam.CURRENT_LADDER]?.length ?? 0) > 0,
    [CorrectionCostToMoveFilterName.CURRENT_LEVEL]:
      (filter[CorrectionCostToMoveFilterParam.CURRENT_LEVEL]?.length ?? 0) > 0,
    [CorrectionCostToMoveFilterName.LOCATION_GROUP]:
      (filter[CorrectionCostToMoveFilterParam.LOCATION_GROUP]?.length ?? 0) > 0,
    [CorrectionCostToMoveFilterName.CURRENT_BAND_POSITION]:
      (filter[CorrectionCostToMoveFilterParam.CURRENT_BAND_POSITION]?.length ??
        0) > 0,
  } as const;

  const activeFilters = Object.entries(activeFilterMap)
    .filter(([, value]) => value)
    .map(([key]) => key);

  return (
    <SearchableFilterContainer activeFilters={activeFilters} onReset={onReset}>
      <SearchableFilter
        name={CorrectionCostToMoveFilterName.CURRENT_DEPARTMENT}
        options={filterOptions[
          CorrectionCostToMoveFilterParam.CURRENT_DEPARTMENT
        ].map((department) => ({
          value: department.id,
          label: department.name,
        }))}
        selected={
          filter[CorrectionCostToMoveFilterParam.CURRENT_DEPARTMENT] ?? []
        }
        onChange={(values) =>
          onChange(CorrectionCostToMoveFilterParam.CURRENT_DEPARTMENT, values)
        }
      />
      <SearchableFilter
        name={CorrectionCostToMoveFilterName.MANAGER}
        options={filterOptions[CorrectionCostToMoveFilterParam.MANAGER].map(
          (manager) => ({ value: manager.id, label: manager.displayName })
        )}
        selected={filter[CorrectionCostToMoveFilterParam.MANAGER] ?? []}
        onChange={(values) =>
          onChange(CorrectionCostToMoveFilterParam.MANAGER, values)
        }
      />
      <SearchableFilter
        name={CorrectionCostToMoveFilterName.REPORTS}
        options={filterOptions[CorrectionCostToMoveFilterParam.REPORTS]}
        selected={filter[CorrectionCostToMoveFilterParam.REPORTS] ?? []}
        onChange={(values) =>
          onChange(CorrectionCostToMoveFilterParam.REPORTS, values)
        }
      />
      <SearchableFilter
        name={CorrectionCostToMoveFilterName.CURRENT_LADDER}
        options={filterOptions[
          CorrectionCostToMoveFilterParam.CURRENT_LADDER
        ].map((ladder) => ({ value: ladder.id, label: ladder.name }))}
        selected={filter[CorrectionCostToMoveFilterParam.CURRENT_LADDER] ?? []}
        onChange={(values) =>
          onChange(CorrectionCostToMoveFilterParam.CURRENT_LADDER, values)
        }
      />
      <SearchableFilter
        name={CorrectionCostToMoveFilterName.CURRENT_LEVEL}
        options={filterOptions[
          CorrectionCostToMoveFilterParam.CURRENT_LEVEL
        ].map((level) => ({ value: level, label: String(level) }))}
        selected={filter[CorrectionCostToMoveFilterParam.CURRENT_LEVEL] ?? []}
        onChange={(values) =>
          onChange(CorrectionCostToMoveFilterParam.CURRENT_LEVEL, values)
        }
      />
      <SearchableFilter
        name={CorrectionCostToMoveFilterName.LOCATION_GROUP}
        options={filterOptions[
          CorrectionCostToMoveFilterParam.LOCATION_GROUP
        ].map((location) => ({ value: location.id, label: location.name }))}
        selected={filter[CorrectionCostToMoveFilterParam.LOCATION_GROUP] ?? []}
        onChange={(values) =>
          onChange(CorrectionCostToMoveFilterParam.LOCATION_GROUP, values)
        }
      />
    </SearchableFilterContainer>
  );
};

export default CorrectionCostToMoveFilterBar;
