import { gql } from "@apollo/client";
import { exchangeFromTo } from "@asmbl/shared/currency";
import { ratio } from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import {
  CashCompType,
  CommissionCard_employee,
  CompUnit,
} from "../../__generated__/graphql";
import { CompCard } from "../Cards/CompCard";
import { useCurrencies } from "../CurrenciesContext";

type Props = {
  className?: string;
  employee: CommissionCard_employee;
};

export function CommissionCard({ className, employee }: Props): JSX.Element {
  const { currencies, defaultCurrency } = useCurrencies();

  const commission = employee.activeCashCompensation?.find(
    ({ type }) => type === CashCompType.COMMISSION
  );
  const salary = employee.activeCashCompensation?.find(
    ({ type }) => type === CashCompType.SALARY
  );

  if (!commission) {
    return <></>;
  }

  // leveraging the `salary` currency for both commission and salary
  const currentCurrency =
    (salary?.annualCashEquivalent.currency != null
      ? currencies.get(salary.annualCashEquivalent.currency)
      : null) ?? null;

  const commissionCash =
    currentCurrency != null
      ? exchangeFromTo(
          commission.annualCashEquivalent,
          currentCurrency,
          defaultCurrency
        )
      : null;

  const salaryCash =
    currentCurrency != null && salary?.annualCashEquivalent != null
      ? exchangeFromTo(
          salary.annualCashEquivalent,
          currentCurrency,
          defaultCurrency
        )
      : null;

  const percent =
    commission.unit === CompUnit.PERCENT_OF_SALARY
      ? commission.percentOfSalary
      : !salary
        ? null
        : commission.annualCashEquivalent.currency ===
            salary.annualCashEquivalent.currency
          ? ratio(
              commission.annualCashEquivalent,
              salary.annualCashEquivalent
            ) * 100
          : commissionCash && salaryCash && salaryCash.value !== 0
            ? ratio(commissionCash, salaryCash) * 100
            : null;

  return (
    <CompCard
      className={className}
      label="Target Commission"
      value={commission.annualCashEquivalent}
      description={
        percent != null
          ? `${formatNumeral(percent, {
              maximumSignificantDigits: 3,
            })}% of your Base Salary`
          : "Your Target Bonus for this year"
      }
    />
  );
}

CommissionCard.fragments = {
  employee: gql`
    fragment CommissionCard_employee on Employee {
      activeCashCompensation {
        employeeId
        type
        activeAt
        annualCashEquivalent
        percentOfSalary
        unit
      }
    }
  `,
};
