import { useQuery } from "@apollo/client";
import { isZero } from "@asmbl/shared/money";
import { Button, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { createPortal } from "react-dom";
import { BenefitsPackage, getTotalValue } from "../../../../models/Benefits";
import { GET_OFFER_CONFIG } from "../../../../queries";
import { GetOfferConfig } from "../../../../__generated__/graphql";
import { getBenefitsFrames } from "../../../OfferDisplay/OfferFrames";
import Loading from "../../Loading";

interface Props {
  benefitsPackage: BenefitsPackage;
  size?: "small" | "medium" | "large";
  className?: string;
}

export function PreviewButton({
  benefitsPackage,
  size = "large",
  className,
}: Props): JSX.Element {
  const [show, setShow] = useState<boolean>(false);

  return (
    <>
      <Button
        className={className}
        variant="contained"
        size={size}
        color="secondary"
        onClick={() => setShow(true)}
      >
        Preview Benefits
      </Button>
      {show && (
        <SlidePreview
          benefitsPackage={benefitsPackage}
          onClose={() => setShow(false)}
        />
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  portal: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // Our main stacking context is not isolated to the root, so this
    // needs to be greater than the main layout components (navbar, etc):
    zIndex: 20,
    overflowY: "scroll",
  },
  content: {
    position: "relative",
    zIndex: 0,
  },
  buttonWrapper: {
    position: "fixed",
    bottom: theme.spacing(3),
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1,
    boxShadow: "0px 4px 18px rgba(10, 36, 64, 0.15)",
  },
}));

interface SlidePreviewProps {
  benefitsPackage: BenefitsPackage;
  onClose: () => unknown;
}

function SlidePreview({
  benefitsPackage,
  onClose,
}: SlidePreviewProps): JSX.Element {
  const classes = useStyles();
  const { data, loading } = useQuery<GetOfferConfig>(GET_OFFER_CONFIG);

  if (loading) {
    return <Loading />;
  }
  if (data === undefined || data.offerConfig === null) {
    return <>An Error Occurred</>;
  }

  const offer = { offeredAt: new Date() };
  const offerConfig = data.offerConfig;

  const benefitsFrames = getBenefitsFrames({
    benefitsPackage,
    hasAnyValue: !isZero(getTotalValue(benefitsPackage)),
    offer,
    offerConfig,
  });

  return createPortal(
    <div className={classes.portal}>
      <div className={classes.content}>{benefitsFrames}</div>
      <div className={classes.buttonWrapper}>
        <Button onClick={onClose} variant="contained" color="primary">
          Close Preview
        </Button>
      </div>
    </div>,
    document.body
  );
}
