import {
  Box,
  makeStyles,
  TableCell,
  TextField,
  TooltipProps,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { NoResultsIcon } from "src/components/AssembleIcons/Brand/NoResultsIcon";
import { GRAY_4, GRAY_5, PURPLE_1, WHITE } from "../../../theme";
import { AssemblePopper } from "../../AssemblePopper";
import { ConditionalTooltipWrapper } from "../../ConditionalTooltipWrapper";
import { ExplanatoryTooltip } from "../../ExplanatoryTooltip";
import { AssembleAutocompleteProps } from "./AssembleAutocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiAutocomplete-endAdornment": {
      top: "6px", // this is the only way to override the default MUI position
      right: 0,
      position: "absolute",
    },
  },
  tableCell: {
    padding: theme.spacing(1.5, 2),
  },
  inputComponent: {
    fontSize: "0.8125rem",
    borderRadius: "4px",
    border: `1px solid ${GRAY_5}`,
    boxShadow: "inset 0px 1px 4px rgba(54, 70, 87, 0.14)",
    transition: "none",
    "&:hover": {
      background: WHITE,
      border: `1 solid ${PURPLE_1}`,
      transition: "none",
      boxShadow: "inset 0px 1px 4px rgba(54, 70, 87, 0.14)",
    },

    "&:focus-within, &.Mui-focused": {
      border: `1px solid ${PURPLE_1}`,
      paddingLeft: "1px",
      boxShadow: `0px 0px 0px 3px rgba(100, 91, 255, 0.3), inset 0px 1px 4px rgba(54, 70, 87, 0.14)`,
      transition: "none",
      background: WHITE,
    },
  },
  input: {
    cursor: "pointer",
    padding: theme.spacing(1, 1.5),
    maxHeight: theme.spacing(4),
    "&::placeholder": {
      color: GRAY_4,
      // By default, browsers add lower opacity to the placeholder. Let's get
      // rid of that.
      opacity: 1,
    },
  },
  listbox: {
    paddingTop: 0,
  },
  clearIndicator: {
    background: WHITE,
    "&:focus-within, &:hover, &.Mui-focused, ": {
      background: WHITE,
    },
  },
}));

export function AutocompleteTableCell<T, G>(
  props: AssembleAutocompleteProps<T, G> & {
    colSpan?: number;
    explanatoryTooltip?:
      | {
          title: string;
          placement: TooltipProps["placement"];
          body: string;
          ctaLabel: string;
          ctaUrl: string;
          disabled: boolean;
          newTab?: boolean;
        }
      | undefined;
  }
): JSX.Element {
  const classes = useStyles();

  return (
    <TableCell
      classes={{ body: props.classes?.tableCell, root: classes.tableCell }}
      role="gridcell"
      colSpan={props.colSpan}
      // prevents opening the employee detail when the dropdown is selected
      onClick={(e) => e.stopPropagation()}
    >
      <ConditionalTooltipWrapper
        showTooltip={props.explanatoryTooltip !== undefined}
        Tooltip={ExplanatoryTooltip}
        tooltipProps={props.explanatoryTooltip}
      >
        <Autocomplete
          closeIcon={null}
          className={props.className}
          classes={{
            root: classes.root,
            listbox: classes.listbox,
            clearIndicatorDirty: classes.clearIndicator,
          }}
          options={props.options}
          disabled={props.disabled}
          getOptionLabel={props.getOptionLabel}
          renderGroup={(params) => (
            <>
              {props.renderGroup != null &&
                props.decodeGroup != null &&
                props.renderGroup(props.decodeGroup(params.group))}
              {params.children}
            </>
          )}
          groupBy={(option) =>
            props.encodeGroup != null && props.getOptionGroup != null
              ? props.encodeGroup(props.getOptionGroup(option))
              : ""
          }
          popupIcon={<ChevronDownIcon inherit />}
          PopperComponent={(props) =>
            AssemblePopper({
              ...props,
              placement: "bottom-end",
              // even with content-width prop,
              // we need to add width type here to override MUI props styling
              style: {
                width: "fit-content",
              },
              modifiers: {
                preventOverflow: { boundariesElement: "viewport" },
              },
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={props.placeholder}
              InputProps={{
                ...params.InputProps,
                className: classes.inputComponent,
              }}
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                className: `${clsx(classes.input, props.classes?.input)}`,
                "aria-label": props.label,
              }}
            />
          )}
          renderOption={props.renderOption}
          disableClearable={props.disableClearable}
          onChange={(_, value) => {
            if (props.disableClearable === true && value === null) {
              return;
            }
            props.onChange(value);
          }}
          value={props.value}
          noOptionsText={
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              p={1}
            >
              <Typography>
                <NoResultsIcon color={GRAY_4} height="48px" width="48px" />
              </Typography>
              <Typography>
                {props.noOptionsText ??
                  "We can't find any options matching your query."}
              </Typography>
            </Box>
          }
        />
      </ConditionalTooltipWrapper>
    </TableCell>
  );
}
