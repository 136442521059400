import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency } from "@asmbl/shared/currency";
import { Comparator, ComparatorWithOrder } from "@asmbl/shared/sort";
import { Table, TableBody, makeStyles } from "@material-ui/core";
import { ComponentType, useEffect, useMemo, useRef } from "react";
import {
  CellProps,
  Column,
  useColumnOrder as useReactTableColumnOrder,
  useTable,
} from "react-table";
import { useVirtual } from "react-virtual";
import {
  CondensedTable_compCycle,
  CondensedTable_compCycleBudget,
  CondensedTable_valuation,
  CondensedTable_meritAdjustmentGuide as MeritAdjustmentGuide,
  CondensedTable_meritAdjustmentRange as MeritAdjustmentRange,
  CondensedTable_participant as Participant,
  CondensedTable_perfRatingOption as PerfRatingOption,
  CondensedTable_position as Position,
} from "src/__generated__/graphql";

import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { debounce } from "lodash";
import { BulkActionsBar } from "src/components/CompCycle/AppBar/BulkActionsBar";
import { CycleBudgetAppBar } from "src/components/CompCycle/AppBar/CycleBudgetAppBar";
import { useCompStructure } from "src/components/CompStructureContext";
import { useCurrencies } from "src/components/CurrenciesContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { useSort } from "src/components/SortableTable";
import { WHITE } from "src/theme";
import { rowHeightEstimator } from "../Cells/dimensions";
import { useBulkActionsData } from "../Contexts/BulkActionsContext";
import { ColumnIds, useColumnOrder } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { CondensedTableInnerHeader } from "./CondensedTableInnerHeader";
import { CondensedTableRow } from "./CondensedTableRow";

export type ColumnComponent2 = ComponentType<CellProps<Participant>> & {
  id: string;
  column: Column<Participant>;
  sortable?: boolean;
  ordering: (data: {
    currencies: Map<CurrencyCode, Currency>;
    defaultCurrencyCode: CurrencyCode;
    defaultCurrency: Currency;
    meritGuidance: {
      meritAdjustmentGuides: MeritAdjustmentGuide[];
      meritAdjustmentRanges: MeritAdjustmentRange[];
      perfRatingOptions: PerfRatingOption[];
    };
    availablePositions: Position[];
    workingHoursPerYear: number | undefined;
  }) => Comparator<Participant> | ComparatorWithOrder<Participant>;
};

const useStyles = makeStyles(() => ({
  scrollContainer: {
    overflowX: "auto",
    paddingBottom: "7.5rem",
    zIndex: 0,
    height: "100%",
  },
  root: {
    backgroundColor: WHITE,
    position: "relative",
    width: "100%",
  },
}));

type Props = {
  columns: ColumnComponent2[];
  employees: Participant[];
  valuation: CondensedTable_valuation;
  budget: CondensedTable_compCycleBudget | null;
  compCycle: CondensedTable_compCycle;
};

export function CondensedTableInner({
  columns,
  employees,
  compCycle,
  valuation,
  budget,
}: Props): JSX.Element {
  const classes = useStyles();
  const { meritGuidance, availablePositions } = useTableData();
  const { currencies, defaultCurrency } = useCurrencies();
  const { hiddenColumns, columns: orderedColumns } = useColumnOrder();
  const { selectedCount, isFeatureEnabled } = useBulkActionsData();
  const { isEnabled } = useFeatureFlags();
  const { compStructure } = useCompStructure();
  const workingHoursPerYear = compStructure?.workingHoursPerYear;
  const isPaginated = isEnabled(FeatureFlag.ClientPlanTable030124);
  const showBulkActions = selectedCount > 0 && isFeatureEnabled;
  const columnConfig = useMemo(() => columns.map((c) => c.column), [columns]);

  const customComparators = useMemo(() => {
    const comparators: Record<
      string,
      Comparator<Participant> | ComparatorWithOrder<Participant>
    > = {};
    for (const column of columns) {
      comparators[column.id] = column.ordering({
        currencies,
        defaultCurrencyCode: defaultCurrency.code,
        defaultCurrency,
        meritGuidance,
        availablePositions,
        workingHoursPerYear,
      });
    }
    return comparators;
  }, [
    columns,
    currencies,
    defaultCurrency,
    meritGuidance,
    availablePositions,
    workingHoursPerYear,
  ]);

  const {
    sortedArray: sortedEmployees,
    order,
    orderBy,
    handleRequestSort,
  } = useSort<Participant, string>(
    employees,
    ColumnIds.NAME,
    "asc",
    customComparators
  );

  const data = useMemo(
    () => (isPaginated ? employees : sortedEmployees),
    [employees, isPaginated, sortedEmployees]
  );

  const memoColumns = useMemo(() => columnConfig, [columnConfig]);

  const {
    getTableProps,
    getTableBodyProps,
    headers,
    rows,
    prepareRow,
    setColumnOrder,
    setHiddenColumns,
  } = useTable(
    {
      columns: memoColumns,
      data,
      initialState: { hiddenColumns },
      debug: true,
    },
    useReactTableColumnOrder
  );

  useEffect(() => {
    setColumnOrder(orderedColumns);
  }, [orderedColumns, setColumnOrder]);

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenColumns]);

  const parentRef = useRef<HTMLDivElement>(null);
  // used for budget drawer to take into account horizontal scrollbar
  const scrollBarHeight = parentRef.current
    ? parentRef.current.offsetHeight - parentRef.current.clientHeight
    : 0;

  // store the user's scroll position so table
  // can be restored after filtering or sorting
  const saveScrollPosition = (scrollableDiv: HTMLDivElement) => {
    localStorage.setItem(
      "planTableScrollPosition",
      scrollableDiv.scrollLeft.toString()
    );
  };

  useEffect(() => {
    const scrollPosition = localStorage.getItem("planTableScrollPosition");
    if (scrollPosition && parentRef.current) {
      parentRef.current.scrollLeft = parseInt(scrollPosition, 10);
    }
  }, []);

  const debouncedHandleScroll = debounce(() => {
    if (parentRef.current) {
      saveScrollPosition(parentRef.current);
    }
  }, 1000);

  const rowVirtualizer = useVirtual({
    size: rows.length,
    parentRef,
    estimateSize: rowHeightEstimator,
    overscan: 10,
    paddingStart: 40,
  });

  return (
    <>
      <div
        ref={parentRef}
        className={classes.scrollContainer}
        onScroll={debouncedHandleScroll}
      >
        <Table
          {...getTableProps()}
          className={classes.root}
          style={{ height: `${rowVirtualizer.totalSize}px` }}
        >
          <CondensedTableInnerHeader
            headers={headers}
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
          />
          <TableBody {...getTableBodyProps()}>
            {rowVirtualizer.virtualItems.map((virtualRow) => {
              return (
                <CondensedTableRow
                  key={virtualRow.index}
                  virtualRow={virtualRow}
                  rows={rows}
                  prepareRow={prepareRow}
                />
              );
            })}
          </TableBody>
        </Table>
      </div>
      <CycleBudgetAppBar
        compCycle={compCycle}
        budget={budget}
        valuation={valuation}
        bottomOffset={scrollBarHeight}
      />
      {showBulkActions && <BulkActionsBar />}
    </>
  );
}
