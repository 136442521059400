import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { ReactNode } from "react";
import { GRAY_1, GRAY_2 } from "src/theme";
import { AssembleTypography } from "../AssembleTypography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  disabled: { opacity: 0.32 },
}));

type Props = {
  title: ReactNode;
  description?: ReactNode;
  disabled?: boolean;
};

export function AssembleHeader({
  title,
  description,
  disabled,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, { [classes.disabled]: disabled })}>
      <AssembleTypography variant="productSectionHeader" textColor={GRAY_1}>
        {title}
      </AssembleTypography>
      {description !== undefined && (
        <AssembleTypography variant="formDescription" textColor={GRAY_2}>
          {description}
        </AssembleTypography>
      )}
    </div>
  );
}
