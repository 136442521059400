import { Money } from "@asmbl/shared/money";
import { cx } from "@emotion/css";
import { makeStyles } from "@material-ui/core";
import { ReactNode } from "react";
import { getSimpleCashLabel } from "../../../models/Currency";
import { DV_GREEN, DV_RED, RED, SPEARMINT } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";

export const useStyles = makeStyles((theme) => ({
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },
  badged: {
    borderRadius: "2px",
    padding: "1px 4px",
    marginLeft: theme.spacing(1),

    "& $text": { fontWeight: 500 },
  },
  previousText: {
    fontWeight: 400,
    color: theme.palette.text.secondary,
    whiteSpace: "pre-wrap", // Preserve a space after the arrow
  },
  text: {},
  increase: {
    border: `1px solid ${SPEARMINT}`,
    background: `${DV_GREEN}1A`,

    "& $previousText, & $text": { color: SPEARMINT },
  },
  decrease: {
    border: `1px solid ${RED}`,
    background: `${DV_RED}1A`,

    "& $previousText, & $text": { color: RED },
  },
}));

type Props = {
  previousValue?: ReactNode;
  value: ReactNode;
  badged?: "increase" | "decrease";
};

export function CompValue({ previousValue, value, badged }: Props) {
  const classes = useStyles();

  return (
    <div
      className={cx(classes.valueContainer, {
        [classes.badged]: badged != null,
        [classes.increase]: badged === "increase",
        [classes.decrease]: badged === "decrease",
      })}
    >
      {previousValue != null && (
        <AssembleTypography
          variant="productExtraSmall"
          className={classes.previousText}
        >
          {previousValue}
          {" → "}
        </AssembleTypography>
      )}
      <AssembleTypography variant="productExtraSmall" className={classes.text}>
        {value}
      </AssembleTypography>
    </div>
  );
}

type BadgedMoneyValueProps = {
  previousValue?: Money;
  value: Money;
  badged?: boolean;
};
export function BadgedMoneyValue({
  previousValue,
  value,
}: BadgedMoneyValueProps) {
  if (!previousValue) {
    const isIncrease = value.value >= 0;
    return (
      <CompValue
        value={
          <>
            {isIncrease ? "+" : "-"}
            {getSimpleCashLabel(value)}
          </>
        }
        badged={isIncrease ? "increase" : "decrease"}
      />
    );
  }
  const isIncrease = value.value >= previousValue.value;
  return (
    <CompValue
      previousValue={getSimpleCashLabel(previousValue)}
      value={getSimpleCashLabel(value)}
      badged={isIncrease ? "increase" : "decrease"}
    />
  );
}
