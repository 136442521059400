import { gql } from "@apollo/client";
import { Currency } from "@asmbl/shared/currency";
import { moneyComparator, zero } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { useCallback, useState } from "react";
import {
  LadderDetailTable_permissionSettings as OrganizationPermissionSettings,
  LadderDetailTable_position as Position,
} from "../../__generated__/graphql";
import BandVisualizationHeader from "../../components/BandVisualization/BandVisualizationHeader";
import { useSort } from "../../components/SortableTable";
import {
  ADJUSTED_CASH_BAND_FIELDS,
  ADJUSTED_EQUITY_BAND_FIELDS,
} from "../../fragments";
import {
  totalCompBand,
  totalCompMax,
  totalCompMaxHourly,
  totalCompMin,
  totalCompMinHourly,
} from "../../models/Band";
import { getTotalCompRange } from "../../utils";
import { useExcludedBandNames } from "./ExcludedBandNameContext";
import { LadderDetailTableHeader } from "./LadderDetailTableHeader";
import { LadderDetailTableRow } from "./LadderDetailTableRow";

const useStyles = makeStyles(() => ({
  tableContainer: {
    overflowY: "auto",
    maxHeight: "calc(100vh - 260px)",
  },
  bandVisualizationHeaderCell: {
    top: "40px",
    padding: 0,
    height: "14px",
  },
}));

type Props = {
  permissionSettings: OrganizationPermissionSettings;
  positions: Position[];
  totalPositions: number;
  selectedRows: Set<number>;
  isComparing: boolean;
  bandNames: string[];
  handleRowSelect: (positionId: number) => void;
  handleSelectedAll: () => void;
  selectedCurrency: Currency;
  anonymizedView: boolean;
  updateAnonymizedView: (viewAnonymized: boolean) => unknown;
};

export function LadderDetailTable({
  permissionSettings,
  positions,
  totalPositions,
  selectedRows,
  isComparing,
  bandNames,
  handleRowSelect,
  handleSelectedAll,
  selectedCurrency,
  anonymizedView,
  updateAnonymizedView,
}: Props): JSX.Element | null {
  const classes = useStyles();
  const { excludedBandNames } = useExcludedBandNames();
  const [hourly, setHourly] = useState(false);

  const getTotalCompBand = useCallback(
    (position: Position) => totalCompBand(position, excludedBandNames),
    [excludedBandNames]
  );

  const {
    sortedArray: sortedPositions,
    order,
    orderBy,
    handleRequestSort,
  } = useSort<Position, "totalCompMax">(positions, "level", "desc", {
    totalCompMax: contramap(
      (p) => totalCompMax(getTotalCompBand(p)) ?? zero(selectedCurrency.code),
      moneyComparator
    ),
  });

  if (sortedPositions.length === 0) return null;

  const totalCompRange = getTotalCompRange(
    positions.map((p) => {
      const totalComp = getTotalCompBand(p);

      const getCompMin = hourly ? totalCompMinHourly : totalCompMin;
      const getCompMax = hourly ? totalCompMaxHourly : totalCompMax;

      return {
        totalCompMin: getCompMin(totalComp) ?? zero(selectedCurrency.code),
        totalCompMax: getCompMax(totalComp) ?? zero(selectedCurrency.code),
      };
    })
  );

  return (
    <TableContainer
      className={classes.tableContainer}
      component={Paper}
      elevation={0}
    >
      <Table stickyHeader>
        <TableHead>
          <LadderDetailTableHeader
            selectedRows={selectedRows}
            totalPositions={totalPositions}
            isComparing={isComparing}
            order={order}
            orderBy={orderBy}
            bandNames={bandNames}
            handleSelectedAll={handleSelectedAll}
            handleRequestSort={handleRequestSort}
            anonymizedView={anonymizedView}
            permissionSettings={permissionSettings}
            updateAnonymizedView={updateAnonymizedView}
            hourly={hourly}
            setHourly={setHourly}
          />
          <TableRow>
            <TableCell
              className={classes.bandVisualizationHeaderCell}
              // account for `People` column when anonymized view is on
              colSpan={anonymizedView ? 4 : 3}
            />
            <TableCell
              className={classes.bandVisualizationHeaderCell}
              colSpan={1}
            >
              <BandVisualizationHeader
                totalCompRange={totalCompRange}
                height={14}
                selectedCurrency={selectedCurrency}
              />
            </TableCell>
            <TableCell
              className={classes.bandVisualizationHeaderCell}
              colSpan={1}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedPositions.map((position) => (
            <LadderDetailTableRow
              key={position.id}
              permissionSettings={permissionSettings}
              position={position}
              selectedRows={selectedRows}
              isComparing={isComparing}
              totalCompRange={totalCompRange}
              handleRowSelect={handleRowSelect}
              selectedCurrency={selectedCurrency}
              anonymizedView={anonymizedView}
              hourly={hourly}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

LadderDetailTable.fragments = {
  position: gql`
    ${ADJUSTED_CASH_BAND_FIELDS}
    ${ADJUSTED_EQUITY_BAND_FIELDS}
    ${LadderDetailTableRow.fragments.position}
    fragment LadderDetailTable_position on Position {
      ...LadderDetailTableRow_position
      id
      level
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedCashBandFields
      }
      adjustedEquityBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedEquityBandFields
      }
    }
  `,
  permissionSettings: gql`
    ${LadderDetailTableHeader.fragments.permissionSettings}
    ${LadderDetailTableRow.fragments.permissionSettings}
    fragment LadderDetailTable_permissionSettings on PermissionSettings {
      id
      ...LadderDetailTableHeader_permissionSettings
      ...LadderDetailTableRow_permissionSettings
    }
  `,
};
