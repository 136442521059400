import { gql } from "@apollo/client";
import { CurrencyCode, MONTHS_IN_A_YEAR } from "@asmbl/shared/constants";
import {
  Currency,
  exchangeFromTo,
  hourlyToAnnual,
} from "@asmbl/shared/currency";
import { Money, add, divide, formatCurrency, zero } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import { mapMaybe } from "@asmbl/shared/utils";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { Fragment, memo, useMemo } from "react";
import {
  BandUnit,
  BenefitsPackageFields as BenefitsPackage,
  EquityGrantMethod,
  GetCompStructure,
  LiveLocationsProvider_market as Market,
  Compensation_position as Position,
  PositionType,
} from "../../../__generated__/graphql";
import {
  COMP_TYPES_EXCLUDED_FROM_OFFERS,
  CashBandName,
  EquityBandName,
} from "../../../constants";
import { isBandPointDefined } from "../../../models/BandPoint";
import { ComputedOfferedComp } from "../../../models/Offer";
import {
  ArrayValue,
  NonNull,
  bandNameComparator,
  createPointComparator,
} from "../../../utils";
import { AssembleButton } from "../../AssembleButton/AssembleButton";
import { AssembleLink } from "../../AssembleLink";
import { useCurrencies } from "../../CurrenciesContext";
import { OrgCurrencyField } from "../../Currency/OrgCurrencyField";
import { SelectField } from "../../Form/SelectField";
import Section from "../../Workflow/Section";
import { HeaderText } from "../Candidate/CandidateHeaderDescription";
import { useStyles } from "../style";
import { BandName, ChangeHandler, OfferData } from "../types";
import { CompBreakdown } from "./CompBreakdown";
import { CompensationFormInput } from "./CompensationFormInput";

type CompStructure = NonNull<GetCompStructure["compStructure"]>;

function parsePositionData(
  compStructure: CompStructure,
  cashCurrency: Currency,
  equityCurrency: Currency,
  position?: Position
): PositionBandData {
  const pointComparator = createPointComparator(compStructure.bandPointTypes);

  // This is a legacy conversion to morph the adjustedCashBands and
  // adjustedEquityBands into what the ComputedPosition used to output. It'd be
  // great to delete one day.
  // We filter out bands that have undefined values, since we cannot show band
  // comparisons for those comp types.
  const cashData = mapMaybe(position?.adjustedCashBands ?? [], (band) =>
    band.bandPoints.some(isBandPointDefined)
      ? {
          name: band.name as BandName,
          bandPoints: band.bandPoints
            .slice()
            .sort(pointComparator)
            .map((bp) => {
              if (
                position?.type === PositionType.HOURLY &&
                band.name === CashBandName.SALARY
              )
                return bp.hourlyCashEquivalent;
              else return bp.annualCashEquivalent;
            }),
          annualBandPoints: band.bandPoints
            .slice()
            .sort(pointComparator)
            .map((bp) => {
              return bp.annualCashEquivalent;
            }),
        }
      : null
  );
  const equityData = mapMaybe(position?.adjustedEquityBands ?? [], (band) =>
    band.bandPoints.some(isBandPointDefined)
      ? {
          name: band.name as BandName,
          bandPoints: band.bandPoints
            .slice()
            .sort(pointComparator)
            .map((bp) => bp.totalGrossValue),
          annualBandPoints: band.bandPoints
            .slice()
            .sort(pointComparator)
            .map((bp) => bp.totalGrossValue),
        }
      : null
  );

  const annualizedTotalCashBand: Money[] = [];
  const annualizedTotalEquityBandInValuationCurrency: Money[] = [];
  let annualizedTotalEquityBandInLocalCurrency: Money[] = [];

  const positionBands = [
    ...cashData.map((b) => ({
      ...b,
      bandPoints: mapMaybe(b.bandPoints, (bP) => bP),
      annualBandPoints: mapMaybe(b.annualBandPoints, (bP) => bP),
    })),
    ...equityData,
  ];

  cashData.forEach((band) => {
    for (let i = 0; i < band.bandPoints.length; i++) {
      annualizedTotalCashBand[i] = add(
        band.annualBandPoints[i] ?? zero(cashCurrency.code),
        annualizedTotalCashBand[i] ?? zero(cashCurrency.code)
      );
    }
  });

  equityData.forEach((band) => {
    for (let i = 0; i < band.bandPoints.length; i++) {
      annualizedTotalEquityBandInValuationCurrency[i] = add(
        divide(
          band.bandPoints[i],
          compStructure.vestingMonths / MONTHS_IN_A_YEAR
        ),
        annualizedTotalEquityBandInValuationCurrency[i] ??
          zero(equityCurrency.code)
      );
    }
  });

  if (cashCurrency.code !== equityCurrency.code) {
    annualizedTotalEquityBandInLocalCurrency =
      annualizedTotalEquityBandInValuationCurrency.map((band) =>
        exchangeFromTo(band, equityCurrency, cashCurrency)
      );
  }

  const annualizedTotalCompensationBand = addMoneyArrays(
    annualizedTotalCashBand,
    annualizedTotalEquityBandInLocalCurrency
  );

  return {
    positionBands,
    annualizedTotalCashBand,
    annualizedTotalEquityBand: annualizedTotalEquityBandInValuationCurrency,
    annualizedTotalCompensationBand,
    positionType: position?.type,
  };
}

function addMoneyArrays(a: Money[], b: Money[]) {
  const longerArray = a.length > b.length ? a : b;
  const shorterArray = a.length > b.length ? b : a;
  if (longerArray.length === 0) return [];

  const fallbackCurrency = longerArray[0].currency;
  return longerArray.map((array, index) =>
    add(array, shorterArray[index] ?? zero(fallbackCurrency))
  );
}

export type Props = {
  compStructure: CompStructure;
  data: OfferData;
  handleChange: ChangeHandler;
  onNext: () => unknown;
  onBack: () => unknown;
  position?: Position;
  market?: Market;
  locationGroup?: ArrayValue<Market["locationGroups"]>;
  benefitsPackages: BenefitsPackage[];
  canRecruitersViewAnonymizedStatistics: boolean;
  showEquityInValuationCurrency: boolean;
  computedOfferedComp: ComputedOfferedComp;
  localCurrency: Currency<CurrencyCode>;
  valuationCurrency: Currency<CurrencyCode>;
  showCurrentEquityValue: boolean;
  pricePerUnit: Money;
};

export type PositionBandData = {
  positionBands: {
    name: BandName;
    bandPoints: Money[];
    annualBandPoints: Money[];
  }[];
  annualizedTotalCashBand: Money[];
  annualizedTotalEquityBand: Money[];
  annualizedTotalCompensationBand: Money[];
  positionType: PositionType | undefined;
};

const MemoizedCompensation = memo(function Compensation({
  compStructure,
  data,
  handleChange,
  onNext,
  onBack,
  position,
  market,
  locationGroup,
  benefitsPackages,
  canRecruitersViewAnonymizedStatistics,
  showEquityInValuationCurrency,
  computedOfferedComp,
  localCurrency,
  valuationCurrency,
  showCurrentEquityValue,
  pricePerUnit,
}: Props): JSX.Element {
  const classes = useStyles();
  const { currencies } = useCurrencies();

  const formattedPricePerUnit = formatCurrency(pricePerUnit, {
    maximumFractionDigits: 2,
  });

  const positionData = position
    ? parsePositionData(
        compStructure,
        localCurrency,
        valuationCurrency,
        position
      )
    : undefined;

  const annualOrHourlySalary = data.cash[CashBandName.SALARY]?.value;
  const annualSalary =
    positionData?.positionType === PositionType.ANNUAL
      ? annualOrHourlySalary
      : annualOrHourlySalary
        ? hourlyToAnnual(
            compStructure.employmentHoursPerWeek *
              compStructure.employmentWeeksPerYear,
            annualOrHourlySalary
          )
        : undefined;

  const isNextDisabled = useMemo(() => {
    if (benefitsPackages.length > 0 && data.benefitsPackage === undefined) {
      return true;
    }

    const isMissingCashBands = compStructure.cashBandTypes.some(
      (band) => data.cash[band as CashBandName] === undefined
    );
    const isMissingEquityBands = compStructure.equityBandTypes.some(
      (band) =>
        data.equity[band as EquityBandName] === undefined &&
        !COMP_TYPES_EXCLUDED_FROM_OFFERS.includes(band as EquityBandName)
    );

    return isMissingCashBands || isMissingEquityBands;
  }, [
    benefitsPackages.length,
    compStructure.cashBandTypes,
    compStructure.equityBandTypes,
    data.benefitsPackage,
    data.cash,
    data.equity,
  ]);

  const onChangeCurrency = (currency: Currency) => {
    handleChange("currency", currency);
    handleChange("cash", {});
    handleChange("equity", {});
  };

  const onChangeBenefits = (selected: string | number) => {
    if (selected === "none") {
      return handleChange("benefitsPackage", null);
    }
    handleChange(
      "benefitsPackage",
      benefitsPackages.find(
        (benefitsPackage) => benefitsPackage.id.toString() === selected
      )
    );
  };

  const grantEquityInUnits =
    compStructure.equityGrantMethod === EquityGrantMethod.UNITS;

  return (
    <>
      <div className={classes.content}>
        <Section
          id="Section-Compensation"
          active
          description={
            <HeaderText
              positionName={data.position?.name}
              marketName={market?.name}
              locationGroupName={locationGroup?.name}
            />
          }
          header="Enter the compensation details."
        >
          {currencies.size <= 1 && <Box mb={-3} />}
          {currencies.size > 1 && (
            <Box mb={2.5}>
              <OrgCurrencyField
                label="Currency"
                value={localCurrency}
                onChange={onChangeCurrency}
              />
            </Box>
          )}
          <Fragment key={localCurrency.code}>
            {compStructure.cashBandTypes
              .slice()
              .sort(bandNameComparator)
              .map((bandName: string) => (
                <CompensationFormInput
                  key={bandName}
                  data={data}
                  bandName={bandName as CashBandName}
                  monetaryValue={data.cash[bandName as CashBandName]}
                  positionData={positionData}
                  handleChange={handleChange}
                  currency={localCurrency}
                  showCurrentEquityValue={showEquityInValuationCurrency}
                  annualSalary={annualSalary}
                />
              ))}

            {compStructure.equityBandTypes
              .slice()
              .filter(
                (bandType) =>
                  !COMP_TYPES_EXCLUDED_FROM_OFFERS.includes(
                    bandType as EquityBandName
                  )
              )
              .sort(bandNameComparator)
              .map((bandName: string) => {
                const equityAmount = data.equity[bandName as EquityBandName];

                return (
                  <CompensationFormInput
                    key={bandName}
                    data={data}
                    bandName={bandName as EquityBandName}
                    label={
                      grantEquityInUnits && showCurrentEquityValue
                        ? `${bandName} (${formattedPricePerUnit} per unit)`
                        : bandName
                    }
                    monetaryValue={equityAmount}
                    totalEquityValue={
                      equityAmount && equityAmount.mode === BandUnit.UNITS
                        ? showEquityInValuationCurrency
                          ? computedOfferedComp.fullyVested.inValuationCurrency
                          : computedOfferedComp.fullyVested.inLocalCurrency
                        : undefined
                    }
                    positionData={positionData}
                    equityBandData={position?.adjustedEquityBands ?? undefined}
                    handleChange={handleChange}
                    equityGrantMethod={compStructure.equityGrantMethod}
                    pricePerUnit={pricePerUnit}
                    currency={
                      showEquityInValuationCurrency
                        ? valuationCurrency
                        : localCurrency
                    }
                    showEquityInValuationCurrency={
                      showEquityInValuationCurrency
                    }
                    showCurrentEquityValue={showCurrentEquityValue}
                    annualSalary={annualSalary}
                  />
                );
              })}
          </Fragment>
          <Box mt={4} mb={3}>
            <Typography variant="h3" color="textPrimary">
              Select a Benefits Package
            </Typography>
          </Box>
          <Box mb={4}>
            {benefitsPackages.length > 0 ? (
              <SelectField
                id="benefits-select"
                options={[
                  { label: "Please select…", value: "", disabled: true },
                  { label: <em>Don't show benefits</em>, value: "none" },
                  ...benefitsPackages
                    .map((benefitsPackage) => ({
                      label: benefitsPackage.name,
                      value: benefitsPackage.id.toString(),
                    }))
                    .sort(contramap((p) => p.label)),
                ]}
                value={
                  data.benefitsPackage === null
                    ? "none"
                    : data.benefitsPackage?.id ?? ""
                }
                label="Benefits Package"
                onChange={onChangeBenefits}
              />
            ) : (
              <Typography>
                Add a{" "}
                <AssembleLink to="/settings/benefits">
                  Benefits Package
                </AssembleLink>{" "}
                if you'd like to include it in your Offer.
              </Typography>
            )}
          </Box>
          <div className={classes.spaceBetweenContainer}>
            <AssembleButton
              onClick={onBack}
              variant="outlined"
              size="medium"
              label="Back"
            />

            <Tooltip
              title="Enter all the compensation details to continue"
              placement="top"
              arrow
              disableHoverListener={!isNextDisabled}
            >
              <span>
                <AssembleButton
                  disabled={isNextDisabled}
                  onClick={onNext}
                  variant="contained"
                  size="medium"
                  label="Next"
                />
              </span>
            </Tooltip>
          </div>
        </Section>
      </div>
      <CompBreakdown
        computedOfferedComp={computedOfferedComp}
        selectedCurrencyCode={localCurrency.code}
        compStructure={compStructure}
        positionData={positionData}
        data={data}
        benefitsPackages={benefitsPackages}
        positionName={position?.name}
        grantEquityInUnits={grantEquityInUnits}
        pricePerUnit={pricePerUnit}
        showCurrentEquityValue={showCurrentEquityValue}
        showEquityInValuationCurrency={showEquityInValuationCurrency}
        canRecruitersViewAnonymizedStatistics={
          canRecruitersViewAnonymizedStatistics
        }
        localCurrency={localCurrency}
        valuationCurrency={valuationCurrency}
        annualOrHourlySalary={annualOrHourlySalary}
        annualSalary={annualSalary}
      />
    </>
  );
});

export const Compensation = Object.assign(MemoizedCompensation, {
  fragments: {
    position: gql`
      ${CompensationFormInput.fragments.equityBand}
      fragment Compensation_position on Position {
        id
        name
        description
        level
        jobCodes
        type
        adjustedCashBands(
          currencyCode: $cashCurrencyCode
          marketId: $marketId
          locationGroupId: $locationGroupId
        ) {
          id
          name
          bandPoints {
            name
            annualCashEquivalent
            hourlyCashEquivalent
            value {
              ... on CashValue {
                annualRate
                currencyCode
              }
            }
          }
        }
        adjustedEquityBands(
          currencyCode: $equityCurrencyCode
          marketId: $marketId
          locationGroupId: $locationGroupId
        ) {
          ...CompensationFormInput_equityBand
          id
          name
          bandPoints {
            name
            totalGrossValue
            totalUnits
            value {
              ... on CashValue {
                annualRate
                currencyCode
              }
              ... on UnitValue {
                unitValue
              }
              ... on PercentValue {
                decimalValue
              }
            }
          }
        }
      }
    `,
  },
});
