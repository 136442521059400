import { makeStyles } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import { ComplexToggle } from "src/components/ComplexToggle";
import { CompRecAction } from "src/models/CompRecommendation";
import { theme } from "src/theme";

const useStyles = makeStyles(() => ({
  container: {
    display: "inline-flex",
    alignItems: "center",
    gap: 8,
    paddingInline: theme.spacing(0.5),
    textTransform: "uppercase",
    whiteSpace: "nowrap",
  },
}));

type Props = {
  actionToggle: CompRecAction;
  setActionToggle: Dispatch<SetStateAction<CompRecAction>>;
};

export function ActionToggle({
  actionToggle,
  setActionToggle,
}: Props): JSX.Element {
  const classes = useStyles();
  const toggleOptions = Object.values(CompRecAction);

  return (
    <ComplexToggle
      options={toggleOptions.map((action) => [
        action,
        <div className={classes.container} key={action}>
          <span>{action}</span>
        </div>,
      ])}
      selected={actionToggle}
      onChange={setActionToggle}
    />
  );
}
