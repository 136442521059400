import { makeStyles } from "@material-ui/core";
import { WHITE } from "../../../../theme";
import { AssembleLink } from "../../../AssembleLink";
import { AssembleTypography } from "../../../AssembleTypography";
import { DismissableInfoBanner } from "../../../InfoBanner";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.appBar + 1,
    position: "fixed",
    top: theme.spacing(4),
    width: "75%",
    left: "15%",
  },
  content: {
    paddingRight: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

type Props = { userInvitationId: number | null };

export function UserInviteBanner({ userInvitationId }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DismissableInfoBanner level="success" centeredClose>
        <div className={classes.content}>
          <div>
            <AssembleTypography
              variant="productSummaryHeader"
              textColor={WHITE}
            >
              Invitation Sent!
            </AssembleTypography>
            <AssembleTypography
              variant="productParagraphMedium"
              textColor={WHITE}
            >
              {userInvitationId != null
                ? "To edit this person's compensation access, click into their access below."
                : "Configure compensation access for this individual below."}
            </AssembleTypography>
          </div>
          {userInvitationId != null && (
            <div>
              <AssembleLink
                underline="none"
                to={`/settings/permissions/user-invitations/${userInvitationId}`}
              >
                <AssembleTypography
                  variant="productSummaryHeader"
                  textColor={WHITE}
                >
                  Open User's Permissions
                </AssembleTypography>
              </AssembleLink>
            </div>
          )}
        </div>
      </DismissableInfoBanner>
    </div>
  );
}
