import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  CompCyclePhase,
  CreatePhasesInput,
  EmplaceCompCyclePhases,
  EmplaceCompCyclePhasesVariables,
  UpdateCompCyclePhases,
  UpdateCompCyclePhasesVariables,
  UpdatePhasesInput,
} from "src/__generated__/graphql";

const EMPLACE_COMP_CYCLE_PHASES = gql`
  mutation EmplaceCompCyclePhases(
    $compCycleId: Int!
    $phases: [CreatePhasesInput!]!
  ) {
    emplaceCompCyclePhases(compCycleId: $compCycleId, phases: $phases) {
      id
      phases {
        id
        startDate
        phaseOrder
      }
    }
  }
`;
const UPDATE_COMP_CYCLE_PHASES = gql`
  mutation UpdateCompCyclePhases(
    $compCycleId: Int!
    $phases: [UpdatePhasesInput!]!
  ) {
    updateCompCyclePhases(compCycleId: $compCycleId, phases: $phases) {
      id
      phases {
        id
        startDate
        phaseOrder
      }
    }
  }
`;

export function useEmplaceCompCyclePhases(): (
  compCycleId: number,
  phases: CreatePhasesInput[]
) => Promise<Pick<CompCyclePhase, "id" | "startDate" | "phaseOrder">[]> {
  const [emplaceCompCyclePhases] = useMutation<
    EmplaceCompCyclePhases,
    EmplaceCompCyclePhasesVariables
  >(EMPLACE_COMP_CYCLE_PHASES);

  return useCallback(
    async (compCycleId: number, phases: CreatePhasesInput[]) => {
      const result = await emplaceCompCyclePhases({
        variables: { compCycleId, phases },
      });

      return result.data?.emplaceCompCyclePhases.phases ?? [];
    },
    [emplaceCompCyclePhases]
  );
}

export function useUpdateCompCyclePhases(): (
  compCycleId: number,
  phases: UpdatePhasesInput[]
) => Promise<Pick<CompCyclePhase, "id" | "startDate" | "phaseOrder">[]> {
  const [updateCompCyclePhases] = useMutation<
    UpdateCompCyclePhases,
    UpdateCompCyclePhasesVariables
  >(UPDATE_COMP_CYCLE_PHASES);

  return useCallback(
    async (compCycleId: number, phases: UpdatePhasesInput[]) => {
      const result = await updateCompCyclePhases({
        variables: { compCycleId, phases },
      });

      return result.data?.updateCompCyclePhases.phases ?? [];
    },
    [updateCompCyclePhases]
  );
}

const REPLACE_PHASE_ASSIGNMENTS = gql`
  mutation ReplaceCompCyclePhaseAssignment(
    $compCycleId: Int!
    $compCyclePhaseId: Int!
    $assigneeIds: [Int!]!
  ) {
    replaceCompCyclePhaseAssignment(
      compCycleId: $compCycleId
      compCyclePhaseId: $compCyclePhaseId
      assigneeIds: $assigneeIds
    ) {
      id
      phaseId
      status
    }
  }
`;

export function useReplacePhaseAssignments(): (
  compCycleId: number,
  compCyclePhaseId: number,
  assigneeIds: number[]
) => Promise<unknown> {
  const [replaceCompCyclePhaseAssignment] = useMutation(
    REPLACE_PHASE_ASSIGNMENTS
  );

  return useCallback(
    async (compCycleId, compCyclePhaseId, assigneeIds) => {
      return replaceCompCyclePhaseAssignment({
        variables: { compCycleId, compCyclePhaseId, assigneeIds },
      });
    },
    [replaceCompCyclePhaseAssignment]
  );
}
