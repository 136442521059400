import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency } from "@asmbl/shared/currency";
import { ISettings } from "@flatfile/adapter";

export const CurrencyField = (
  currencies: Map<CurrencyCode, Currency<CurrencyCode>>
): ISettings["fields"] => [
  {
    label: "Currency",
    key: "currency",
    description: "The currency code used for this employee, e.g. USD or EUR",
    type: "select",
    sizeHint: 0.75,
    options: [...currencies.keys()].map((code) => ({
      label: code,
      value: code,
    })),
    validators: [{ validate: "required" }],
  },
];
