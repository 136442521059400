import { CurrencyCode } from "./constants";
import { divide, Money, multiply } from "./money";

export interface Currency<C extends CurrencyCode = CurrencyCode> {
  code: C;
  exchangeRate: number;
}

/**
 * @deprecated - Use `exchangeFromTo` or `exchangeMoney` instead.
 * This function converts a Money object from the organization's default
 * currency to the target currency.
 */
export function exchangeCurrency<C extends CurrencyCode>(
  dollars: Money<C>,
  currency: Currency<C>
): Money<C> {
  return {
    value: dollars.value * currency.exchangeRate,
    currency: currency.code,
  };
}

export function exchangeFromTo<C extends CurrencyCode, D extends CurrencyCode>(
  money: Money<C>,
  fromCode: Currency<C>,
  toCode: Currency<D>
): Money<D> {
  return {
    value: money.value * (toCode.exchangeRate / fromCode.exchangeRate),
    currency: toCode.code,
  };
}

export function exchangeMoney<C extends CurrencyCode, D extends CurrencyCode>(
  money: Money<C>,
  toCode: D,
  currencies: { allCurrencies: Map<CurrencyCode, Currency> }
): Money<D> {
  const fromCurrency = currencies.allCurrencies.get(money.currency) as
    | Currency<C>
    | undefined;

  const toCurrency = currencies.allCurrencies.get(toCode) as
    | Currency<D>
    | undefined;

  if (!fromCurrency) {
    throw new Error(`Currency ${money.currency} not found`);
  }

  if (!toCurrency) {
    throw new Error(`Currency ${toCode} not found`);
  }

  return exchangeFromTo(money, fromCurrency, toCurrency);
}

export function currencyCodeOrNull(
  code: string | CurrencyCode
): CurrencyCode | null {
  const casted = code as CurrencyCode;

  return Object.values(CurrencyCode).includes(casted) ? casted : null;
}

export function hourlyToAnnual(
  workingHoursPerYear: number,
  value: Money
): Money {
  return multiply(value, workingHoursPerYear);
}

export function annualToHourly(
  workingHoursPerYear: number,
  value: Money
): Money {
  return divide(value, workingHoursPerYear);
}
