import { gql } from "@apollo/client";
import { LocationGroupsIcon } from "src/components/AssembleIcons/Brand/LocationGroupsIcon";
import { LocationBubble_employee } from "../../../__generated__/graphql";
import { BubbleInfo } from "./BubbleInfo";

type Props = {
  employee: LocationBubble_employee;
};

export function LocationBubble({ employee }: Props): JSX.Element {
  const location =
    employee.homeLocation !== null
      ? `${employee.homeLocation.city}, ${
          employee.homeLocation.state.length > 0
            ? employee.homeLocation.state
            : employee.homeLocation.country
        }`
      : "-";

  return (
    <BubbleInfo
      data-cy="location"
      label="Location"
      info={location}
      icon={<LocationGroupsIcon inherit />}
      color="orange"
    />
  );
}

LocationBubble.fragments = {
  employee: gql`
    fragment LocationBubble_employee on Employee {
      homeLocation {
        id
        city
        state
        country
      }
    }
  `,
};
