import { gql } from "@apollo/client";
import { TableBody, TableRow } from "@material-ui/core";
import { SupportedManagersRow_manager as Manager } from "src/__generated__/graphql";
import {
  WireTable,
  WireTableHead,
  WireTableUnsortableHeaderCell,
} from "src/components/Table/WireTable";
import { SupportedManagersRow } from "./SupportedManagersRow";

type Props = {
  allManagers: Manager[];
  supportedManagers: Manager[];
};

export function SupportedManagersTable({
  supportedManagers,
  allManagers,
}: Props) {
  return (
    <WireTable>
      <WireTableHead>
        <TableRow>
          <WireTableUnsortableHeaderCell width="25%" cellTitle="Manager" />
          <WireTableUnsortableHeaderCell width="25%" cellTitle="Department" />
          <WireTableUnsortableHeaderCell width="25%" cellTitle="Position" />
          <WireTableUnsortableHeaderCell
            width="25%"
            cellTitle="Manager Reports"
          />
        </TableRow>
      </WireTableHead>
      <TableBody>
        {supportedManagers.map((manager) => (
          <SupportedManagersRow
            manager={manager}
            managers={allManagers}
            key={`managers-table-${manager.id}`}
          />
        ))}
      </TableBody>
    </WireTable>
  );
}

SupportedManagersTable.fragments = {
  manager: gql`
    fragment SupportedManagersTable_manager on Employee {
      id
      displayName
      managerId
      activeEmployment {
        id
        jobTitle
        position {
          id
          name
          ladder {
            id
            department {
              id
              name
            }
          }
        }
      }
      directReports {
        id
      }
      indirectReports {
        id
      }
    }
  `,
};
