import {
  Box,
  FormControlLabel,
  FormGroup,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";

import MarketDataIcon from "src/pages/Settings/MarketData/MarketDataIcon";
import { AssembleTypography } from "../../../components/AssembleTypography";
import { PURPLE_1, theme } from "../../../theme";
import { GetLadders_marketDataSet } from "./SelectedLaddersLoader";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { AssembleLink } from "src/components/AssembleLink";
import { useTrack } from "src/analytics";

const useStyles = makeStyles((theme) => ({
  legend: {
    flex: 1,
    padding: theme.spacing(2),
    overflow: "hidden",
  },
  marketDataPositionInput: {
    marginRight: 0,
    alignItems: "flex-start",
    padding: `0 ${theme.spacing(0.5)}px`,
    "&:hover": {
      color: `${PURPLE_1} !important`,
    },
  },
  positionInputDim: {
    opacity: 0.5,
  },
  positionLabel: {
    flex: 1,
    color: "inherit",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: theme.spacing(1),
  },
  marketDataSetLabel: {
    maxWidth: "calc(220px - 48px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
}));

export type CompareMarketDataSelectorProps = {
  marketDataSets: GetLadders_marketDataSet[];
  includedMarketDataSets: number[];
  setIncludedMarketDataSets: React.Dispatch<React.SetStateAction<number[]>>;
};

export function CompareMarketDataSelector({
  marketDataSets,
  includedMarketDataSets,
  setIncludedMarketDataSets,
}: CompareMarketDataSelectorProps): JSX.Element {
  const classes = useStyles();

  const { trackEvent } = useTrack();

  return (
    <Box mb={1}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={1}
      >
        <AssembleTypography
          variant="productTableHeader"
          color="textSecondary"
          display="block"
        >
          Market Data
        </AssembleTypography>
      </Box>
      {marketDataSets.length === 0 && (
        <AssembleTypography
          variant="productSmallSemiBold"
          color="textSecondary"
          align="center"
        >
          Visit Data Management in Settings to add a dataset.
        </AssembleTypography>
      )}
      <FormGroup className={classes.formGroup}>
        {marketDataSets.map((marketDataSet) => (
          <FormControlLabel
            className={clsx(classes.marketDataPositionInput, {
              [classes.positionInputDim]: !includedMarketDataSets.includes(
                marketDataSet.id
              ),
            })}
            classes={{
              label: classes.positionLabel,
            }}
            key={marketDataSet.label}
            data-testid={`legend-marketDataSet-${marketDataSet.label}`}
            onClick={() => {
              setIncludedMarketDataSets((prev) => {
                if (prev.includes(marketDataSet.id)) {
                  return prev.filter((id) => id !== marketDataSet.id);
                }
                trackEvent({
                  object: "Market Data",
                  action: "Toggled",
                  dataSet: marketDataSet.label,
                });
                return [...prev, marketDataSet.id];
              });
            }}
            control={
              <Box display="flex" alignItems="center" height={24} mr={1} ml={1}>
                <MarketDataIcon
                  provider={marketDataSet.provider}
                  active={includedMarketDataSets.includes(marketDataSet.id)}
                />
              </Box>
            }
            label={
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <AssembleTypography
                  className={classes.marketDataSetLabel}
                  color="inherit"
                >
                  {marketDataSet.label}
                </AssembleTypography>
              </Box>
            }
          />
        ))}
        <AssembleLink
          to="/settings/data"
          style={{ marginTop: theme.spacing(1), maxWidth: "100%" }}
        >
          <AssembleButton
            size="medium"
            variant="outlined"
            label="Manage Data"
            fullWidth
          />
        </AssembleLink>
      </FormGroup>
    </Box>
  );
}
