import {
  Box,
  Chip,
  Divider,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { BLUE_2, GRAY_4, WHITE } from "../../../theme";
import { KeyedCustomField } from "./OfferContentsForm";

const MAX_OPTION_LENGTH = 40;

const useStyles = makeStyles((theme) => ({
  nameField: {
    padding: theme.spacing(2, 0, 1, 0),
  },
  chip: {
    marginRight: 5,
    marginTop: 10,
    background: BLUE_2,
    fontSize: "0.875rem",
    fontColor: WHITE,

    "& .MuiSvgIcon-root, .MuiChip-deleteIcon": {
      color: WHITE,
    },
    "& .MuiChip-label": {
      color: WHITE,
    },
  },
  fieldText: {
    color: GRAY_4,
    padding: theme.spacing(2, 0, 1, 2),
  },
}));

type Props = {
  field: KeyedCustomField;
  onFieldChange: (newField: KeyedCustomField) => void;
};

export function SelectOptionsEditor({
  field,
  onFieldChange,
}: Props): JSX.Element {
  const classes = useStyles();
  const [newOption, setNewOption] = useState<string>("");

  return (
    <>
      <Divider />
      {field.options.map((option) => (
        <Chip
          key={option}
          label={option}
          className={classes.chip}
          onDelete={() => {
            const newOptions = field.options.filter((op) => op !== option);
            onFieldChange({ ...field, options: newOptions });
          }}
        />
      ))}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TextField
          placeholder="Press `Enter` to submit a new option..."
          value={newOption}
          onChange={(e) => setNewOption(e.target.value)}
          onKeyDown={(e) => {
            const option = newOption.trim();
            if (e.key === "Enter" && option.length > 0) {
              const newOptions = [...field.options, option];
              onFieldChange({ ...field, options: newOptions });
              setNewOption("");
            }
          }}
          fullWidth
          InputProps={{
            className: classes.nameField,
            disableUnderline: true,
          }}
          inputProps={{
            maxLength: MAX_OPTION_LENGTH,
            "aria-label": "Custom Field Title",
          }}
        />
        <Typography className={classes.fieldText}>
          {`${newOption.length}/${MAX_OPTION_LENGTH}`}
        </Typography>
      </Box>
    </>
  );
}
