import { gql } from "@apollo/client";
import { formatCurrency, Money } from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import {
  CompItemRecommendationTypeTitle,
  CompRecTableHeaders,
  whereType,
} from "../../../models/CompRecommendation";
import {
  EquityChange_recItem,
  RecItemType,
} from "../../../__generated__/graphql";
import { AssembleTypography } from "../../AssembleTypography";
import { CompComponentContainer } from "./CompComponentContainer";
import { BadgedMoneyValue, CompValue } from "./CompValue";
import { LabelValue } from "./LabelValue";

type RecItemWithValue = EquityChange_recItem & {
  recommendedCashValue: GraphQL_Money;
};
type Props = {
  recItems: EquityChange_recItem[];
};

export function EquityChange({ recItems }: Props): JSX.Element | null {
  const equityRecItem = recItems
    .filter(
      (item): item is RecItemWithValue => item.recommendedCashValue !== null
    )
    .find(whereType(RecItemType.EQUITY_GRANT));

  if (!equityRecItem) {
    return null;
  }

  return (
    <CompComponentContainer>
      <LabelValue
        label={
          <AssembleTypography variant="productEyebrowSmall" gutterBottom>
            {CompRecTableHeaders.EQUITY}
          </AssembleTypography>
        }
        compValue={
          <BadgedMoneyValue value={equityRecItem.recommendedCashValue} />
        }
      />
      <LabelValue
        label={CompItemRecommendationTypeTitle.EQUITY_GRANT}
        compValue={
          <CompValue
            value={formatEquityValue(
              equityRecItem.recommendedCashValue,
              equityRecItem.recommendedEquityUnitCount
            )}
          />
        }
      />
    </CompComponentContainer>
  );
}

/**
 * Returns a string that shows the unit count and cash value of equity.
 */
function formatEquityValue(value: Money | null, unitCount: number | null) {
  const formattedRecommendedCashValue = value
    ? formatCurrency(value, { maximumFractionDigits: 0 })
    : "n/a";

  return unitCount != null
    ? `${formatNumeral(unitCount)} Units | ${formattedRecommendedCashValue}`
    : formattedRecommendedCashValue;
}

EquityChange.fragments = {
  recItem: gql`
    fragment EquityChange_recItem on RecItem {
      recommendationType
      recommendedCashValue
      recommendedEquityUnitCount
    }
  `,
};
