import { Box, makeStyles } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { AssembleIconProps } from "src/components/AssembleIcons/types";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_1, GRAY_4 } from "../../../theme";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    color: GRAY_4,
    transition: "color 300ms ease",
    "&:hover": {
      color: GRAY_1,
    },
  },
  icon: {
    height: "24px",
    width: "24px",
  },
  label: {
    fontWeight: 700,
    fontSize: "0.625rem",
    textAlign: "center",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  link: { textDecoration: "none" },
}));

type NavigationIconProps = {
  Component: (props: AssembleIconProps) => JSX.Element;
  matches: string[];
  route: string;
  label?: string;
  openInNewTab?: boolean;
  isExternal?: boolean;
};

export function NavigationIcon({
  Component,
  matches,
  route,
  label,
  openInNewTab,
  isExternal = false,
}: NavigationIconProps): JSX.Element {
  const classes = useStyles();
  const location = useLocation();

  const path = location.pathname.split("/")[1];
  const match = matches.includes(path);

  return (
    <Box width="100%">
      {isExternal ? (
        <a
          href={route}
          className={classes.link}
          target={openInNewTab ? "_blank" : undefined}
          rel="noreferrer"
        >
          <Box className={classes.iconContainer}>
            <Component color={match ? GRAY_1 : GRAY_4} />
            <AssembleTypography
              className={classes.label}
              color={match ? "textPrimary" : undefined}
              title={label}
            >
              {label}
            </AssembleTypography>
          </Box>
        </a>
      ) : (
        <Link
          to={{ pathname: route, search: location.search }}
          className={classes.link}
          target={openInNewTab ? "_blank" : undefined}
        >
          <Box className={classes.iconContainer}>
            <Component color={match ? GRAY_1 : GRAY_4} />
            <AssembleTypography
              className={classes.label}
              color={match ? "textPrimary" : undefined}
              title={label}
            >
              {label}
            </AssembleTypography>
          </Box>
        </Link>
      )}
    </Box>
  );
}
