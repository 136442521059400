import { gql, useMutation } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { useCallback } from "react";
import {
  CompRecommendationInput,
  EmplaceCompRecommendations,
  EmplaceCompRecommendationsVariables,
  RecReviewInput,
  SubmitRecReviews,
  SubmitRecReviewsVariables,
} from "../__generated__/graphql";

/*
  EmplaceCompRecommendations is a misnomer, as this mutation actually emplaces
  the current user's items on a CompRecommendation. If there is not yet
  a CompRecommendation for the subject Employee, one will be created.

  Items on a CompRecommendation are only accessible via the CompRecommendation,
  so resolving the relevant fields updates the Apollo cache properly. We also
  load the subject Employee in case this is a newly created CompRecommendation.
 */
const EMPLACE_COMP_RECOMMENDATIONS = gql`
  mutation EmplaceCompRecommendations(
    $compCycleId: Int!
    $data: [CompRecommendationInput!]!
    $actingManagerEmployeeId: Int
    $currencyCode: CurrencyCode
    $skipEligibility: Boolean
  ) {
    emplaceCompRecommendations(
      compCycleId: $compCycleId
      actingManagerEmployeeId: $actingManagerEmployeeId
      data: $data
    ) {
      participants {
        compCycleId
        subjectId
        perfRating
        compRecommendation(skipEligibility: $skipEligibility) {
          subjectId
          compCycleId
          canICurrentlyReview
          canICurrentlyEdit
          reviewStatus
          allSubmittedItems {
            id
            authorId
          }
          latestSubmittedItems {
            id
            recommendationType
            note
            submittedAt
            unitType
            recommendedCashValue(currencyCode: $currencyCode)
            recommendedPercentValue
            recommendedEquityUnitCount
            recommendedTitle
            recommendedPosition {
              id
              name
              level
              ladder {
                id
                name
                department {
                  id
                  name
                }
              }
            }
            adjustedCashBands {
              id
              name
              bandPoints {
                name
                value {
                  ... on CashValue {
                    annualRate
                    currencyCode
                  }
                }
              }
            }
            author {
              id
              employee {
                id
              }
            }
          }
          latestSubmittedPayIncrease {
            annualCashEquivalent
            hourlyCashEquivalent
            unitType
          }
          latestSubmittedReviews {
            id
            status
            submittedAt
            authorId
          }
          allSubmittedReviews {
            id
            authorId
          }
        }
      }
    }
  }
`;

export function useEmplaceCompRecommendations(
  compCycleId: number,
  currencyCode: CurrencyCode | null,
  actingManagerEmployeeId?: number | null
): (
  compRecommendations: CompRecommendationInput[]
) => Promise<EmplaceCompRecommendations | null> {
  const [emplaceCompRecommendations] = useMutation<
    EmplaceCompRecommendations,
    EmplaceCompRecommendationsVariables
  >(EMPLACE_COMP_RECOMMENDATIONS);

  return useCallback(
    async (recs: CompRecommendationInput[]) => {
      const { data } = await emplaceCompRecommendations({
        variables: {
          compCycleId,
          data: recs,
          currencyCode,
          actingManagerEmployeeId,
          skipEligibility: true,
        },
      });
      return data ?? null;
    },
    [
      actingManagerEmployeeId,
      compCycleId,
      currencyCode,
      emplaceCompRecommendations,
    ]
  );
}

// same as the `useEmplaceCompRecommendations` function above, but this
// function passes in the `compCycleId` as an argument instead of having it
// defined at hook definition time
export function useEmplaceCompRecommendationsWithCompCycleId(): (
  compCycleId: number,
  compRecommendations: CompRecommendationInput[]
) => Promise<EmplaceCompRecommendations | null> {
  const [emplaceCompRecommendations] = useMutation<
    EmplaceCompRecommendations,
    EmplaceCompRecommendationsVariables
  >(EMPLACE_COMP_RECOMMENDATIONS);

  return useCallback(
    async (
      compCycleId: number,
      compRecommendations: CompRecommendationInput[]
    ) => {
      const { data } = await emplaceCompRecommendations({
        variables: {
          compCycleId,
          data: compRecommendations,
        },
      });
      return data ?? null;
    },
    [emplaceCompRecommendations]
  );
}

export type RecReviewsUpsertInput = {
  data: RecReviewInput[];
  compCycleId: number;
  actingManagerEmployeeId?: number | null;
};

/*
  SubmitRecReviews can also affect several other fields. 
  When you submit a review, it is  added to the 'allSubmittedReviews' list.
  Also, the computed ReviewStatus may be updated.
  In order for the comp rec cache to update properly, we must pass the $skipEligibility & currencyCode params
 */
const SUBMIT_REC_REVIEWS = gql`
  mutation SubmitRecReviews(
    $reviews: [RecReviewInput!]!
    $actingManagerEmployeeId: Int
    $currencyCode: CurrencyCode
    $skipEligibility: Boolean = true
  ) {
    submitRecReviews(
      actingManagerEmployeeId: $actingManagerEmployeeId
      reviews: $reviews
    ) {
      participants {
        subjectId
        compCycleId
        compRecommendation(skipEligibility: $skipEligibility) {
          subjectId
          compCycleId
          allSubmittedReviews {
            id
            submittedAt
            status
            note
            author {
              id
            }
          }
          latestSubmittedReviews {
            id
            submittedAt
            status
            note
            authorId
          }
          canICurrentlyReview
          canICurrentlyEdit
          reviewStatus
          latestSubmittedItems {
            id
            recommendationType
            note
            submittedAt
            unitType
            recommendedCashValue(currencyCode: $currencyCode)
            recommendedPercentValue
            recommendedEquityUnitCount
            recommendedTitle
            recommendedPosition {
              id
            }
            adjustedCashBands {
              id
              name
              bandPoints {
                name
                value {
                  ... on CashValue {
                    annualRate
                    currencyCode
                  }
                }
              }
            }
            unitType
          }
          latestSubmittedPayIncrease {
            annualCashEquivalent
            hourlyCashEquivalent
            unitType
          }
        }
      }
    }
  }
`;

export function useSubmitRecReviews(
  currencyCode: CurrencyCode | null
): (data: RecReviewsUpsertInput) => Promise<SubmitRecReviews | null> {
  const [submitRecReviews] = useMutation<
    SubmitRecReviews,
    SubmitRecReviewsVariables
  >(SUBMIT_REC_REVIEWS);

  return useCallback(
    async (pendingReviews) => {
      const { data } = await submitRecReviews({
        variables: {
          reviews: pendingReviews.data,
          actingManagerEmployeeId: pendingReviews.actingManagerEmployeeId,
          currencyCode,
        },
      });
      return data ?? null;
    },
    [submitRecReviews, currencyCode]
  );
}
