import { gql } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { getFormattedDate, getFormattedShortDate } from "@asmbl/shared/time";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { ArrowRightIcon } from "src/components/AssembleIcons/Brand/ArrowRightIcon";
import { CalendarIcon } from "src/components/AssembleIcons/Extra-Small/CalendarIcon";
import { ClockIcon } from "src/components/AssembleIcons/Extra-Small/ClockIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import {
  EmploymentAccordion_employment as Employment,
  PayPeriodType,
} from "../../../../__generated__/graphql";
import { AssembleLink } from "../../../../components/AssembleLink";
import { GRAY_1, GRAY_4, GRAY_6, theme } from "../../../../theme";

interface Props {
  employment: Employment;
  onSelect: (id: number | undefined) => unknown;
  selected: number | undefined;
}

const useStyles = makeStyles(() => ({
  bulletIconContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    fontSize: "10px",
  },
  table: {
    color: GRAY_1,
    fontSize: "14px",
    lineHeight: "155%",
    letterSpacing: "-0.25px",
  },
  innerTableCell: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  outerTableCell: {
    borderRight: `1px solid ${GRAY_6}`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: "15%",
  },
  link: {
    fontSize: "14px",
    lineHeight: "155%",
    letterSpacing: "-0.25px",
  },
  jobContainer: {
    display: "flex",
    gap: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    alignItems: "center",
  },
  jobTitleContainer: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  arrowContainer: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.75),
  },
}));

export const EmploymentAccordion = ({
  employment,
  onSelect,
  selected,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const shortStartDate = getFormattedShortDate(employment.activeAt);
  const startDate = getFormattedDate(employment.activeAt);
  const endDate =
    employment.inactiveAt === null
      ? "Present"
      : getFormattedShortDate(employment.inactiveAt);
  const allowsHourly =
    compStructure?.allowHourlyEmployees === true &&
    isEnabled(FeatureFlag.HourlyEmployees);

  return (
    <Accordion
      key={employment.id}
      expanded={selected === employment.id}
      onChange={(event, shouldBeSelected) =>
        onSelect(shouldBeSelected ? employment.id : undefined)
      }
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <div className={classes.jobContainer}>
          <div className={classes.jobTitleContainer}>
            {allowsHourly &&
              (employment.payPeriod === PayPeriodType.ANNUAL ? (
                <CalendarIcon height="16px" width="16px" />
              ) : (
                <ClockIcon height="16px" width="16px" />
              ))}
            <AssembleTypography
              variant="productParagraphLarge"
              textColor={GRAY_1}
            >
              {employment.jobTitle}
            </AssembleTypography>
          </div>
          {employment.position?.level !== undefined ? (
            <>
              <div className={classes.bulletIconContainer}>&bull;</div>
              <AssembleTypography
                variant="productParagraphLarge"
                textColor={GRAY_1}
              >
                {`Level ${employment.position.level}`}
              </AssembleTypography>
            </>
          ) : (
            <></>
          )}
        </div>
        <AssembleTypography
          variant="productParagraphLarge"
          textColor={GRAY_4}
        >{` (${
          shortStartDate === "" ? "-" : shortStartDate
        } `}</AssembleTypography>
        <div className={classes.arrowContainer}>
          <ArrowRightIcon color={GRAY_4} width="12px" height="12px" />
        </div>
        <AssembleTypography
          variant="productParagraphLarge"
          textColor={GRAY_4}
        >{` ${endDate})`}</AssembleTypography>
      </AccordionSummary>
      <AccordionDetails>
        <Table className={classes.table} size="small">
          <TableBody>
            <TableRow>
              <TableCell className={classes.outerTableCell}>
                Department
              </TableCell>
              <TableCell className={classes.innerTableCell}>
                {employment.position?.ladder.department.id !== undefined ? (
                  <AssembleLink
                    to={`/departments/${employment.position.ladder.department.id}`}
                    newTab
                    display="inline"
                    className={classes.link}
                  >
                    {employment.position.ladder.department.name}
                  </AssembleLink>
                ) : (
                  "-"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.outerTableCell}>Ladder</TableCell>
              <TableCell className={classes.innerTableCell}>
                {employment.position?.ladder.id !== undefined ? (
                  <AssembleLink
                    to={`/ladders/${employment.position.ladder.id}`}
                    newTab
                    display="inline"
                    className={classes.link}
                  >
                    {employment.position.ladder.name}
                  </AssembleLink>
                ) : (
                  "-"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.outerTableCell}>Position</TableCell>
              <TableCell className={classes.innerTableCell}>
                {employment.position?.id !== undefined ? (
                  <AssembleLink
                    to={`/positions/${employment.position.id}`}
                    newTab
                    display="inline"
                    className={classes.link}
                  >
                    {employment.position.name}
                  </AssembleLink>
                ) : (
                  "-"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.outerTableCell}>Level</TableCell>
              <TableCell className={classes.innerTableCell}>
                {employment.position?.level ?? "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.outerTableCell}>Title</TableCell>
              <TableCell className={classes.innerTableCell}>
                {employment.jobTitle}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.outerTableCell}>
                Role Start Date
              </TableCell>
              <TableCell className={classes.innerTableCell}>
                {startDate === "" ? "-" : startDate}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};

EmploymentAccordion.fragments = {
  employment: gql`
    fragment EmploymentAccordion_employment on Employment {
      id
      jobTitle
      activeAt
      inactiveAt
      salary
      payPeriod
      payRate
      position {
        id
        level
        name
        ladder {
          id
          name
          department {
            id
            name
          }
        }
      }
    }
  `,
};
