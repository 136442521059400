import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { GRAY_1, GRAY_2, GRAY_8, theme } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";
import { StandardPoliciesTableBody } from "./StandardPoliciesTableBody";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  sectionTitle: {
    color: GRAY_1,
    fontWeight: 700,
    fontSize: "22px",
    marginBottom: theme.spacing(1),
  },
  sectionSubtitle: {
    color: GRAY_2,
    marginBottom: theme.spacing(3),
  },
  access: {
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  tableRowHeader: {
    backgroundColor: GRAY_8,
    alignItems: "center",
  },
  table: {
    backgroundColor: GRAY_8,
    verticalAlign: "top",
    boxShadow: "none",
  },
}));

export function StandardPoliciesTable(): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant="h3" className={classes.sectionTitle}>
        Assemble's Standard Policies
      </Typography>
      <Typography variant="body1" className={classes.sectionSubtitle}>
        Assemble always applies the following rules to control access to your
        data.
      </Typography>
      <Box className={classes.access}>
        <TableContainer component={Paper} elevation={0}>
          <Table
            stickyHeader
            size="small"
            classes={{
              root: classes.table,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  width="15%"
                  variant="head"
                  className={classes.tableRowHeader}
                />
                <TableCell
                  width="42.5%"
                  variant="head"
                  className={classes.tableRowHeader}
                >
                  <AssembleTypography variant="productTableHeader">
                    Who Can View?
                  </AssembleTypography>
                </TableCell>
                <TableCell
                  width="42.5%"
                  variant="head"
                  className={classes.tableRowHeader}
                >
                  <AssembleTypography variant="productTableHeader">
                    Who Can Edit?
                  </AssembleTypography>
                </TableCell>
              </TableRow>
            </TableHead>
            <StandardPoliciesTableBody />
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
