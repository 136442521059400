import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const FlameIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Flame"
      viewBox="0 0 12 14"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.91639 1.04241C7.86569 0.940836 7.79394 0.85122 7.70593 0.779514C7.61792 0.707809 7.51565 0.655656 7.40593 0.626523C7.29621 0.597389 7.18154 0.591942 7.06955 0.610543C6.95756 0.629145 6.85081 0.671368 6.75639 0.734408C6.48039 0.918408 6.26519 1.18081 6.09879 1.43841C5.9276 1.70241 5.77639 2.00881 5.6428 2.33121C5.3756 2.97441 5.1516 3.74561 4.9708 4.51841C4.75214 5.4628 4.5884 6.41907 4.4804 7.38241C4.1549 7.17257 3.89337 6.87727 3.7244 6.52881C3.462 5.98481 3.40599 5.30161 3.40599 4.40561C3.40596 4.24741 3.35902 4.09277 3.27112 3.96124C3.18321 3.82971 3.05828 3.7272 2.91212 3.66667C2.76596 3.60613 2.60513 3.59029 2.44997 3.62114C2.29481 3.652 2.15228 3.72816 2.04039 3.84001C1.51973 4.35956 1.10682 4.97684 0.825377 5.65641C0.543937 6.33599 0.399512 7.06446 0.400395 7.80001C0.400461 8.7209 0.627632 9.62757 1.06179 10.4397C1.49594 11.2518 2.12368 11.9444 2.88939 12.456C3.65511 12.9676 4.53518 13.2824 5.45164 13.3727C6.36811 13.4629 7.29268 13.3257 8.14348 12.9733C8.99427 12.6209 9.74502 12.0641 10.3292 11.3522C10.9135 10.6404 11.3131 9.79542 11.4928 8.89222C11.6724 7.98902 11.6266 7.05545 11.3593 6.1742C11.092 5.29295 10.6115 4.49121 9.96039 3.84001C9.48679 3.36721 9.1764 3.05201 8.882 2.66641C8.5916 2.28561 8.30279 1.81601 7.91639 1.04241ZM7.69639 11.096C7.36076 11.4312 6.93331 11.6595 6.46803 11.7519C6.00275 11.8443 5.52052 11.7968 5.08224 11.6153C4.64396 11.4338 4.26928 11.1266 4.00554 10.7323C3.7418 10.338 3.60081 9.87438 3.60039 9.40001C3.60039 9.40001 4.30359 9.80001 5.60039 9.80001C5.60039 9.00001 6.00039 6.60001 6.60039 6.20001C7.00039 7.00001 7.22919 7.23441 7.69719 7.70321C7.92043 7.92575 8.09747 8.19023 8.21815 8.48142C8.33883 8.77262 8.40076 9.0848 8.40039 9.40001C8.40076 9.71522 8.33883 10.0274 8.21815 10.3186C8.09747 10.6098 7.92043 10.8743 7.69719 11.0968L7.69639 11.096Z"
      />
    </svg>
  </ColorableSVG>
);
