import { gql, useQuery } from "@apollo/client";
import { LinearProgress } from "@material-ui/core";
import { useTrack } from "../../analytics";
import { NoEmployeeFound } from "../../components/EmptyState/EmptyState";
import { TotalEquitySliderLoadingBoundary } from "../../components/TotalEquitySlider/TotalEquitySliderLoadingBoundary";
import { PortalQuery, PortalQueryVariables } from "../../__generated__/graphql";
import { Portal } from "./Portal";

type Props = {
  employeeId: number;
  isCurrentUser: boolean;
};
export function PortalLoadingBoundary({
  employeeId,
  isCurrentUser,
}: Props): JSX.Element {
  const { Track } = useTrack({
    area: "Employee Portal",
    subArea: "Portal Home",
    employeeId,
    isCurrentUser,
  });

  const { data, loading } = useQuery<PortalQuery, PortalQueryVariables>(
    PortalLoadingBoundary.query,
    {
      variables: {
        employeeId,
      },
    }
  );

  if (!data) {
    if (loading) {
      return <LinearProgress />;
    }

    return <NoEmployeeFound />;
  }

  return (
    <Track>
      <TotalEquitySliderLoadingBoundary>
        <Portal employee={data.employee} organization={data.organization} />
      </TotalEquitySliderLoadingBoundary>
    </Track>
  );
}

PortalLoadingBoundary.query = gql`
  ${Portal.fragments.employee}
  ${Portal.fragments.organization}
  query PortalQuery($employeeId: Int!) {
    employee(id: $employeeId) {
      id
      ...Portal_employee
    }
    organization {
      id
      ...Portal_organization
    }
  }
`;
