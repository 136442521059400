import { caseInsensitiveComparator } from "@asmbl/shared/sort";
import { ISettings } from "@flatfile/adapter";
import {
  ScalarDictionaryWithCustom as FlatFileRecord,
  IDataHookResponse,
} from "@flatfile/react";
import { DECIMAL_VALIDATOR, INTEGER_VALIDATOR } from "./Validators";

export function getPromotionFields(
  positions: { id: number; name: string }[]
): ISettings["fields"] {
  const sortedPositions = Array.from(positions)
    .sort((a, b) => caseInsensitiveComparator(a.name, b.name))
    .map(({ name, id }) => ({
      label: name,
      value: id.toString(),
    }));
  return [
    {
      label: "New Assemble Position Name",
      key: "salaryPromotionId",
      type: "select",
      sizeHint: 1.25,
      options: [{ label: "", value: "" }, ...sortedPositions],
      validators: [
        {
          validate: "required_with",
          fields: [
            "salaryPromotion",
            "salaryPromotionPercent",
            "salaryPromotionNotes",
            "salaryPromotionTitle",
          ],
        },
      ],
    },
    {
      label: "Promotion Adjustment ($)",
      key: "salaryPromotion",
      validators: [
        {
          validate: "regex_matches",
          regex: INTEGER_VALIDATOR,
          error: "Must be a whole number",
        },
      ],
    },
    {
      label: "Promotion Adjustment (%)",
      key: "salaryPromotionPercent",
      validators: [
        {
          validate: "regex_matches",
          regex: DECIMAL_VALIDATOR,
          error: "Must be a number",
        },
      ],
    },
    {
      label: "New Internal Job Title",
      key: "salaryPromotionTitle",
      description:
        "This is separate from the Assemble Position Name. For example, if the new Assemble position is 'Analyst - IC 2', this field might say 'Senior Analyst'",
    },
    {
      label: "Promotion Notes",
      key: "salaryPromotionNotes",
    },
  ];
}

export function validatePromotion(
  record: FlatFileRecord,
  positions: { id: number; name: string }[]
): IDataHookResponse {
  const promoId = record.salaryPromotionId;
  if (promoId != null && promoId !== "") {
    // eslint-disable-next-line
    return positions.some((p) => p.id == promoId)
      ? {}
      : {
          ["salaryPromotionId"]: {
            info: [
              {
                message: "Position ID not found in the list of positions.",
                level: "error",
              },
            ],
          },
        };
  }
  return {};
}
