import { gql } from "@apollo/client";
import { SalaryCard_employee } from "../../../__generated__/graphql";
import { CompCard } from "../../Cards/CompCard";

type Props = {
  employee: SalaryCard_employee;
  className?: string;
};

export function SalaryCard({ employee, className }: Props): JSX.Element {
  if (!employee.activeEmployment?.salary) {
    return <></>;
  }
  return (
    <CompCard
      className={className}
      label="Base Salary"
      value={employee.activeEmployment.salary}
      description="Your current Base Salary"
    />
  );
}

SalaryCard.fragments = {
  employee: gql`
    fragment SalaryCard_employee on Employee {
      activeEmployment {
        id
        salary
      }
    }
  `,
};
