import {
  ManagersTable_manager,
  ManagersTable_phase,
} from "src/__generated__/graphql";

export const getHighlightedEmployeeIds = (
  hoveredEmployeeId: number | null,
  managers: ManagersTable_manager[]
): (number | null)[] => {
  const highlightedEmployeeIds = [hoveredEmployeeId];
  let targetHoveredEmployee = managers.find(
    (manager) => manager.id === hoveredEmployeeId
  );

  const allManagerIds = managers.map((manager) => manager.id);

  while (
    targetHoveredEmployee?.managerId != null &&
    allManagerIds.includes(targetHoveredEmployee.managerId)
  ) {
    highlightedEmployeeIds.push(targetHoveredEmployee.id);
    targetHoveredEmployee = managers.find(
      (manager) => manager.id === targetHoveredEmployee?.managerId
    );
  }

  return highlightedEmployeeIds;
};

// I'm sorry for this function
export const getOrderedColumns = (
  managers: ManagersTable_manager[],
  phases: ManagersTable_phase[]
) => {
  const reversedColumns = [];

  for (let i = phases.length - 1; i >= 0; i--) {
    const targetPhase = phases[i];
    reversedColumns.push(
      targetPhase.compCyclePhaseAssignments.map(
        (phaseAssignment) => phaseAssignment?.employee.id
      )
    );
  }

  const forwardColumns = reversedColumns.toReversed();

  forwardColumns.forEach((column, i) => {
    // Sort whole column by manager id
    column.sort((idA, idB) => {
      const employeeA = managers.find((manager) => manager.id === idA);
      const employeeB = managers.find((manager) => manager.id === idB);
      const employeeAManagerId = employeeA?.managerId ?? 0;
      const employeeBManagerId = employeeB?.managerId ?? 0;

      return employeeAManagerId > employeeBManagerId ? 1 : -1;
    });

    // For any column after the first, sort based on the previous column's
    // manager ids (which should all be correctly grouped now)
    if (i > 0) {
      // Arrays are sorted in place, so it should be updating the original
      const previousColumn = forwardColumns[i - 1];
      const previousColumnManagerIds = previousColumn.map((employeeId) => {
        const employee = managers.find((manager) => manager.id === employeeId);
        return employee?.managerId;
      });

      const uniquePreviousColumnManagerIds = Array.from(
        new Set(previousColumnManagerIds)
      );

      uniquePreviousColumnManagerIds.forEach((prevColumnManagerId, index) => {
        if (
          prevColumnManagerId != null &&
          column.includes(prevColumnManagerId) &&
          column[index] !== prevColumnManagerId
        ) {
          const stashed = column[index];
          const indexToDelete = column.indexOf(prevColumnManagerId);
          column[index] = prevColumnManagerId;
          column.splice(indexToDelete, 1);
          column.push(stashed);
        }
      });
    }
  });

  return forwardColumns;
};
