import { Noun } from "@asmbl/shared/permissions";
import {
  Box,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Radio,
  Typography,
} from "@material-ui/core";
import { useAuth } from "src/components/Auth/AuthContext";
import { AssembleTypography } from "../../../components/AssembleTypography";
import { GRAY_6, GRAY_7, PURPLE_1, WHITE } from "../../../theme";
import { CompareMarketDataSelector } from "./CompareMarketDataSelector";
import {
  GetLadders_marketDataSet,
  SELECTED_COMP_TYPES,
  SelectedCompType,
} from "./SelectedLaddersLoader";

const useStyles = makeStyles((theme) => ({
  legendContainer: {
    overflowY: "auto",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    width: "220px",
    background: WHITE,
    borderTop: `1px solid ${GRAY_6}`,
    borderRight: `1px solid ${GRAY_6}`,
    borderBottom: `5px solid ${GRAY_7}`,
    borderRadius: "0 0px 5px 0",
    boxShadow: `0px 1px ${GRAY_6}`,
  },
  legend: {
    flex: 1,
    padding: theme.spacing(2),
  },
  legendBorderBottom: {
    borderBottom: `1px solid ${GRAY_6}`,
  },
  positionInput: {
    marginRight: 0,
    alignItems: "center",
    padding: `0 ${theme.spacing(0.5)}px`,
    "&:hover": {
      color: `${PURPLE_1} !important`,
    },
  },
  positionLabel: {
    flex: 1,
    color: "inherit",
  },
  legendHeader: {
    paddingBottom: theme.spacing(1),
  },
}));

export type CompareLegendProps = {
  selectedCompType: SelectedCompType;
  availableBandNames: string[];
  marketDataSets: GetLadders_marketDataSet[];
  includedMarketDataSets: number[];
  setIncludedMarketDataSets: React.Dispatch<React.SetStateAction<number[]>>;
  handleChangeCompType: (compType: SelectedCompType) => void;
};

export function CompareLegend({
  selectedCompType,
  marketDataSets,
  includedMarketDataSets,
  setIncludedMarketDataSets,
  handleChangeCompType,
}: CompareLegendProps): JSX.Element {
  const classes = useStyles();

  const { permissions } = useAuth();
  const hasDataAccess = permissions.canViewGlobal(Noun.MarketData);

  return (
    <Box className={classes.legendContainer}>
      <Box className={classes.legend}>
        <Box mb={2}>
          <AssembleTypography
            variant="productTableHeader"
            color="textSecondary"
            display="block"
            className={classes.legendHeader}
          >
            Cash & Equity Comp
          </AssembleTypography>
          <FormGroup>
            {SELECTED_COMP_TYPES.map((compType) => (
              <FormControlLabel
                className={classes.positionInput}
                classes={{
                  label: classes.positionLabel,
                }}
                key={compType}
                data-testid={`legend-compType-${compType}`}
                control={
                  <Radio
                    color="primary"
                    size="small"
                    checked={selectedCompType === compType}
                    onChange={() => handleChangeCompType(compType)}
                  />
                }
                label={
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body2">{compType}</Typography>
                  </Box>
                }
              />
            ))}
          </FormGroup>
        </Box>
        {hasDataAccess && (
          <CompareMarketDataSelector
            marketDataSets={marketDataSets}
            includedMarketDataSets={includedMarketDataSets}
            setIncludedMarketDataSets={setIncludedMarketDataSets}
          />
        )}
      </Box>
      <Box className={classes.legendBorderBottom} />
    </Box>
  );
}
