import { gql, useQuery } from "@apollo/client";
import { CompCycleDisplay } from "../../../../components/CompCycle/types";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import {
  EquityRow_compCycleData as CompCycleData,
  EquityRow_valuation as Valuation,
  GetLazyReportingEquityTier,
} from "../../../../__generated__/graphql";
import { IndentationCells } from "../../Budgets/Table/Connectors";
import { SkeletonRow } from "../../Budgets/Table/SkeletonRow";
import { Equity } from "../BudgetsVsRequests";
import { ReportingEquityTier } from "./ReportingEquityTier";

type Props = {
  employeeId: number;
  currentValuation: Valuation;
  organizationName: string;
  varianceDisplay: CompCycleDisplay;
  equityDisplay: Equity;
  compCycleData: CompCycleData;
  indentation: IndentationCells;
};

export function LazyReportingEquityTier({
  employeeId,
  currentValuation,
  organizationName,
  varianceDisplay,
  equityDisplay,
  compCycleData,
  indentation,
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();

  const { data, loading } = useQuery<GetLazyReportingEquityTier>(
    LazyReportingEquityTier.query(),
    {
      variables: {
        compCycleId: compCycleData.id,
        employeeId,
        currencyCode: defaultCurrencyCode,
      },
      pollInterval: 10_000,
    }
  );

  if (!data || !data.compCycle) {
    return loading ? (
      <SkeletonRow subComponents={3} indentation={indentation} />
    ) : (
      <tr>
        <td>Error</td>
      </tr>
    );
  }

  return (
    <ReportingEquityTier
      rootBudget={data.compCycleBudget}
      manager={data.employee}
      reports={data.employees}
      currentValuation={currentValuation}
      organizationName={organizationName}
      compCycleData={compCycleData}
      varianceDisplay={varianceDisplay}
      equityDisplay={equityDisplay}
      indentation={indentation}
    />
  );
}

LazyReportingEquityTier.query = () => gql`
  ${ReportingEquityTier.fragments.reports}
  ${ReportingEquityTier.fragments.compCycleBudget}
  ${ReportingEquityTier.fragments.compCycleData}
  ${ReportingEquityTier.fragments.manager}

  query GetLazyReportingEquityTier(
    $compCycleId: Int!
    $employeeId: Int!
    $currencyCode: CurrencyCode!
  ) {
    compCycleBudget(compCycleId: $compCycleId, employeeId: $employeeId) {
      compCycleId
      employeeId
      ...ReportingEquityTier_compCycleBudget
    }
    employee(id: $employeeId) {
      id
      ...ReportingEquityTier_manager
    }
    employees(managerId: $employeeId) {
      ...ReportingEquityTier_reports
    }
    compCycle(id: $compCycleId) {
      id
      ...ReportingEquityTier_compCycleData
    }
  }
`;
