import { gql } from "@apollo/client";
import { Currency } from "@asmbl/shared/currency";
import { makeStyles, TableCell } from "@material-ui/core";
import BandVisualization from "../../../components/BandVisualization/BandVisualization";
import {
  DV_ORANGE,
  DV_PINK,
  GRAY_6,
  GRAY_8,
  YELLOW_TEXT,
} from "../../../theme";
import { Range } from "../../../utils";
import { LadderDetailBandCells_position } from "../../../__generated__/graphql";
import { LadderDetailBandVisualizationInformationCell } from "./LadderDetailBandVisualizationInformationCell";

const useStyles = makeStyles(() => ({
  compRangeCell: {
    minWidth: "160px",
  },
  bandVisualizationCell: {
    backgroundColor: GRAY_8,
    borderLeft: `1px solid ${GRAY_6}`,
    borderRight: `1px solid ${GRAY_6}`,
  },
  totalCompMinLabel: {
    color: YELLOW_TEXT,
    fontWeight: 700,
  },
  totalCompMaxLabel: {
    color: DV_PINK,
    fontWeight: 700,
  },
  totalCompMiddleLabel: {
    color: DV_ORANGE,
    fontWeight: 700,
  },
  totalCompLabelCell: {
    border: 0,
  },
  dollarValueCell: {
    textAlign: "right",
    border: 0,
  },
}));

type Props = {
  selectedCurrency: Currency;
  position: LadderDetailBandCells_position;
  totalCompRange: Range;
  hourly: boolean;
};

export function LadderDetailBandCells({
  selectedCurrency,
  position,
  totalCompRange,
  hourly,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <TableCell
        className={classes.bandVisualizationCell}
        align="left"
        padding="none"
      >
        <BandVisualization
          position={position}
          totalCompRange={totalCompRange}
          selectedCurrency={selectedCurrency}
          hourly={hourly}
        />
      </TableCell>
      <TableCell className={classes.compRangeCell} align="right" padding="none">
        <LadderDetailBandVisualizationInformationCell
          position={position}
          hourly={hourly}
        />
      </TableCell>
    </>
  );
}

LadderDetailBandCells.fragments = {
  position: gql`
    ${BandVisualization.fragments.position}
    fragment LadderDetailBandCells_position on Position {
      id
      ...BandVisualization_position
    }
  `,
};
