import { PrimaryRoleName } from "@asmbl/shared/permissions";
import {
  AccessControlQuery,
  NounScopesFields,
} from "../../../__generated__/graphql";

type UserOrPendingUserOverlap = {
  id: number;
  createdAt: GraphQL_DateTime;
  email: string;
  name: string | null;
  employee: {
    id: number;
    isManager: boolean;
    totalReportsCount: number;
    activeEmployment: {
      id: number;
      jobTitle: string | null;
    } | null;
  } | null;
};

type AccessGrant = {
  id: number;
  roleName: PrimaryRoleName;
  nounScopes: NounScopesFields;
} | null;

type ExistingUserExclusive = {
  photoURL: string | null;
  isDisabled: boolean;
  userAccessGrant: AccessGrant;
};

type PendingUserExclusive = {
  userInvitationAccessGrant: AccessGrant;
};

export type ExistingUser = UserOrPendingUserOverlap & ExistingUserExclusive;
export type PendingUser = UserOrPendingUserOverlap & PendingUserExclusive;

export type UserOrPendingUser = ExistingUser | PendingUser;

export function reshapeData(
  users: AccessControlQuery["userRecords"],
  pendingUsers: AccessControlQuery["activeAssembleUserInvitations"]
): UserOrPendingUser[] {
  return [...users, ...pendingUsers] as UserOrPendingUser[];
}

export function isExistingUser(user: UserOrPendingUser): user is ExistingUser {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (user as ExistingUser).userAccessGrant !== undefined;
}

export function isPendingUser(user: UserOrPendingUser): user is PendingUser {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (user as PendingUser).userInvitationAccessGrant !== undefined;
}
