import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { AssembleButton } from "../../AssembleButton/AssembleButton";
import {
  EmployeePerfScoreRow,
  PerfImportButton,
} from "../Buttons/PerfImportButton";

interface Props {
  open: boolean;
  onSubmit: (data: Array<EmployeePerfScoreRow>) => unknown;
  onClose?: () => unknown;
}

export function PerfImportDialog({
  open,
  onSubmit,
  onClose,
}: Props): JSX.Element {
  return (
    <Dialog open={open} onClose={onClose} disableEnforceFocus>
      <DialogTitle>Any previous data will be overwritten.</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          If you're uploading any new data that is different than your old data,
          we'll overwrite the existing data.
        </Typography>
      </DialogContent>
      <DialogActions>
        <AssembleButton
          variant="outlined"
          onClick={onClose}
          size="medium"
          label="Cancel"
        />
        <PerfImportButton handleChange={onSubmit} handleCancel={onClose} />
      </DialogActions>
    </Dialog>
  );
}
