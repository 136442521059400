import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  ImportVariableComp,
  ImportVariableCompInput,
  ImportVariableCompVariables,
} from "../__generated__/graphql";

const IMPORT_VARIABLE_COMP = gql`
  mutation ImportVariableComp($data: [ImportVariableCompInput!]!) {
    importVariableComp(data: $data) {
      entriesAdded
    }
  }
`;
export function useImportVariableComp() {
  const [variableCompUpload] = useMutation<
    ImportVariableComp,
    ImportVariableCompVariables
  >(IMPORT_VARIABLE_COMP);

  const upload = useCallback(
    async (data: ImportVariableCompInput[]) => {
      await variableCompUpload({ variables: { data } });
    },
    [variableCompUpload]
  );

  return upload;
}
