import {
  usesEmployeeScope,
  usesJobStructureScope,
  usesMarketJobStructureScope,
  usesOfferScope,
} from "@asmbl/shared/new-access-grant";
import {
  EmployeeScope,
  JobStructureScope,
  OfferScope,
  Verb,
} from "@asmbl/shared/permissions";
import {
  MarketJobStructureScope,
  Noun,
  NounScopesFields as NounScopes,
  PrimaryRoleName,
} from "../__generated__/graphql";
import { JobStructure } from "./JobStructure";

export class UserPermissions {
  readonly roleName: PrimaryRoleName | null;
  readonly nounScopes: NounScopes | null;
  readonly userHasEmployeeData: boolean;

  constructor(
    roleName: PrimaryRoleName | null,
    nounScopes: NounScopes | null,
    userHasEmployeeData: boolean
  ) {
    this.roleName = roleName;
    this.nounScopes = nounScopes;
    this.userHasEmployeeData = userHasEmployeeData;
  }

  canViewGlobal(noun: Noun): boolean {
    const scope = this.nounScopes?.[noun].view;
    if (!scope) {
      return false;
    }
    if ("global" in scope) {
      return scope.global;
    }
    if ("allMarkets" in scope) {
      return scope.allMarkets?.global ?? false;
    }
    // The above checks are exhaustive; safely return scope as type `never`
    return scope;
  }

  canEditGlobal(noun: Noun): boolean {
    return this.nounScopes?.[noun].edit?.global ?? false;
  }

  isHRBP(): boolean {
    return (
      (this.nounScopes?.Employee.view?.supportingManagerEmployeeIDs ?? [])
        .length > 0
    );
  }

  isManager(): boolean {
    return (
      (this.nounScopes?.Employee.view?.directReportIDs ?? []).length > 0 ||
      (this.nounScopes?.Employee.view?.indirectReportIDs ?? []).length > 0 ||
      (this.nounScopes?.Employee.view?.supportingManagerEmployeeIDs ?? [])
        .length > 0
    );
  }

  isManagerOfManagers(): boolean {
    return (this.nounScopes?.Employee.view?.indirectReportIDs ?? []).length > 0;
  }

  canViewAny(noun: Noun): boolean {
    return this.canAny(Verb.View, noun);
  }

  canEditAny(noun: Noun): boolean {
    return this.canAny(Verb.Edit, noun);
  }

  hasAnyPermissions(): boolean {
    return this.roleName != null && this.nounScopes != null;
  }

  canViewFoundations(): boolean {
    return [Noun.JobStructure, Noun.Philosophy, Noun.CompStructure].some(
      (noun) => this.canViewAny(noun)
    );
  }

  hasSettingsPermissions(): boolean {
    return (
      this.canEditGlobal(Noun.AccessControl) ||
      this.canEditGlobal(Noun.CompStructure) ||
      this.canEditGlobal(Noun.Integration) ||
      // Recruiters only have authored access and will not see Settings:
      this.canEditGlobal(Noun.Offer)
    );
  }

  hasCompensationAccess(): boolean {
    return this.canViewAny(Noun.CashBand) || this.canViewAny(Noun.EquityBand);
  }

  cashCompensationScope() {
    return (
      this.nounScopes?.CashBand ?? { [Verb.Edit]: null, [Verb.View]: null }
    );
  }

  private hasAnyJobStructureScope(
    scope: JobStructureScope | null | undefined
  ): boolean {
    return (
      !!scope &&
      (scope.global ||
        scope.departmentIDs.length > 0 ||
        scope.ladderIDs.length > 0 ||
        scope.positionIDs.length > 0)
    );
  }

  private canAny(verb: Verb, noun: Noun): boolean {
    if (this.nounScopes == null) {
      return false;
    }

    if (
      (verb === Verb.View && this.canViewGlobal(noun)) ||
      this.canEditGlobal(noun)
    ) {
      return true;
    }

    if (usesJobStructureScope(noun)) {
      const scope = this.nounScopes[noun][verb] as JobStructureScope | null;
      return scope != null && this.hasAnyJobStructureScope(scope);
    } else if (usesMarketJobStructureScope(noun)) {
      const scope = this.nounScopes[noun][
        verb
      ] as MarketJobStructureScope | null;
      return (
        scope != null &&
        (this.hasAnyJobStructureScope(scope.allMarkets) ||
          scope.markets.some((market) =>
            this.hasAnyJobStructureScope(market.scope)
          ))
      );
    } else if (usesEmployeeScope(noun)) {
      // Attribute based permission. Employee can view own data
      if (this.userHasEmployeeData && verb === Verb.View) {
        return true;
      }

      const scope = this.nounScopes[noun][verb] as EmployeeScope;

      if (scope == null) {
        return false;
      }

      return (
        scope.global === true ||
        scope.directReportIDs.length > 0 ||
        scope.indirectReportIDs.length > 0
      );
    } else if (usesOfferScope(noun)) {
      const scope = this.nounScopes[noun][verb] as OfferScope;

      if (scope == null) {
        return false;
      }

      return scope.global === true || scope.authored === true;
    }

    /**
     * Implicit permissions like the ones shared/abilities.ts calculates.
     * This will go away once we move to client-side abilities
     */
    switch (noun) {
      case Noun.CompCycle:
      case Noun.CompCycleBudget:
        // Scoped access to employees let you see active comp-cycles and budgets
        return verb === Verb.View ? this.isManager() : false;
      default:
        return false;
    }
  }
}

export interface Scope {
  global: boolean;
  departmentIDs: number[];
  ladderIDs: number[];
  positionIDs: number[];
}

/**
 * Filters a JobStructure to include only the Departments listed wholly or
 * partially in the given Scope. This allows us to take the assorted lists of
 * Position and Ladder IDs and easily find their Departments.
 *
 * @param scope A JobStructureScope, such as for CashBand or JobStructure
 * @param jobStructure The Organization's full JobStructure, including
 *  Departments, Ladders, and Positions
 */
export function departmentsInScope(
  scope: Scope | null | undefined,
  jobStructure: JobStructure
): JobStructure["departments"] {
  if (scope == null) {
    return [];
  }

  if (scope.global) {
    return jobStructure.departments;
  }

  return jobStructure.departments.filter(
    (department) =>
      // Is the department listed in the scope directly?
      scope.departmentIDs.includes(department.id) ||
      // Or are any of its Ladders listed?
      department.ladders.some(
        (ladder) =>
          // Is the ladder listed in the scope directly?
          scope.ladderIDs.includes(ladder.id) ||
          // Or are any of its Positions listed?
          ladder.positions.some((position) =>
            scope.positionIDs.includes(position.id)
          )
      )
  );
}

/**
 * Returns true if the given Scope describes partial access to some Department.
 * If the Scope only describes full Departments (or no access at all), this will
 * be false.
 *
 * This only checks if the Departments are listed directly. If every Ladder (or
 * Position) in the Department is individually listed, that still counts as partial
 * access; the intention is to capture whether this scope applies to future
 * Positions created as well.
 *
 * @param scope A JobStructureScope, such as for CashBand or JobStructure
 * @param jobStructure The Organization's full JobStructure, including
 *   Departments, Ladders, and Positions
 */
export function hasPartialDepartmentsInScope(
  scope: Scope | null | undefined,
  jobStructure: JobStructure
): boolean {
  if (scope == null || scope.global) {
    return false;
  }

  const allDepartmentsInScope = departmentsInScope(scope, jobStructure);
  const wholeDepartmentsInScope = jobStructure.departments.filter(
    (department) => scope.departmentIDs.includes(department.id)
  );
  return allDepartmentsInScope.length !== wholeDepartmentsInScope.length;
}
