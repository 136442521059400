import { makeStyles } from "@material-ui/core";
import {
  GRAY_1,
  GRAY_3,
  GRAY_4,
  GRAY_6,
  GRAY_9,
  PURPLE_1,
  WHITE,
} from "src/theme";

const METRIC_STYLES = {
  display: "flex",
  justifyContent: "space-between",
  flex: "1 1 0",
  padding: "18px 24px",
  border: `1px ${GRAY_6} solid`,
};

export const useTableStyles = makeStyles((theme) => ({
  muiCellOverride: {
    borderBottom: "none",
  },
  cell: {
    backgroundColor: WHITE,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  withLeftBorder: {
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  withTopBorder: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  roundTopLeft: {
    borderTopLeftRadius: 5,
  },
  roundTopRight: {
    borderTopRightRadius: 5,
  },
  roundBottomLeft: {
    borderBottomLeftRadius: 5,
  },
  roundBottomRight: {
    borderBottomRightRadius: 0,
  },
  shortCell: {
    padding: "8px 16px",
  },
  smallFont: {
    color: GRAY_3,
    fontWeight: "bold",
    fontSize: ".75rem",
  },
  metricsCell: {
    padding: 0,
    border: "none",
  },
  metricsContainer: {
    display: "inline-flex",
    width: "calc(100% + 1px)",
    backgroundColor: GRAY_9,
    color: GRAY_1,
    marginTop: "-1px",
    marginLeft: "-1px",
  },
  totalCurrentSpend: {
    ...METRIC_STYLES,
    borderBottomLeftRadius: 5,
  },
  totalSalaryBudget: {
    ...METRIC_STYLES,
    borderLeft: "none",
    borderBottomRightRadius: 5,
  },
  correspondingHovered: {
    outline: `solid 1px ${GRAY_4}`,
    outlineOffset: -1,
  },
  hovered: {
    outlineColor: PURPLE_1,
  },
  correspondingFocused: {
    outline: `solid 1px ${GRAY_4}`,
    outlineOffset: -1,
  },
  focused: {
    outlineColor: PURPLE_1,
    boxShadow: "0px 1px 3px 0px rgba(10, 36, 64, 0.35) inset",
  },
  cursorText: {
    cursor: "text",
  },
  meritMetrics: {
    ...METRIC_STYLES,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    justifyContent: "flex-end",
    gap: theme.spacing(4),
  },
}));
