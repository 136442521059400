import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  PortalConfig,
  PortalConfigInput,
  upsertPortalConfig,
  upsertPortalConfigVariables,
} from "../__generated__/graphql";

export const UPSERT_PORTAL_CONFIG = gql`
  mutation upsertPortalConfig($data: PortalConfigInput) {
    upsertPortalConfig(data: $data) {
      id
      defaultExitOutcome
      exitOutcomes
      xAxisAnnotations
    }
  }
`;

export function useUpsertPortalConfig(): (
  portalConfig: PortalConfigInput | null
) => Promise<PortalConfig | null> {
  const [upsertPortalConfig] = useMutation<
    upsertPortalConfig,
    upsertPortalConfigVariables
  >(UPSERT_PORTAL_CONFIG);

  return useCallback(
    async (portalConfig: PortalConfigInput | null) => {
      const { data } = await upsertPortalConfig({
        variables: {
          data: portalConfig,
        },
      });
      return data?.upsertPortalConfig as PortalConfig;
    },
    [upsertPortalConfig]
  );
}
