import { makeStyles } from "@material-ui/core";
import { DV_ORANGE, DV_PINK, DV_YELLOW } from "../../../theme";

const useStyles = makeStyles(() => ({
  /*
   * Comp Band Sliders generally do not scale with the font-size of the page,
   * and so most measurements are in pixels rather than rem.
   */
  root: {
    width: "100%",
    height: "25px",
    background: `no-repeat linear-gradient(270deg, ${DV_PINK} 0%, ${DV_ORANGE} 78.13%, ${DV_YELLOW} 100%)`,
    borderRadius: "2px",
  },
}));

export function Bar(): JSX.Element {
  const classes = useStyles();
  return <div className={classes.root} />;
}
