import { useEffect, useRef } from "react";

interface Options {
  // array of keys that constitute the hotkey, e.g., ['Control', 'Shift', 'K']
  keyCombo: string[];
  onKeyDown: () => void;
  onKeyUp: () => void;
  enabled?: boolean;
}

export function useHotKey({
  keyCombo,
  onKeyDown,
  onKeyUp,
  enabled = true,
}: Options) {
  const isHotKeyActiveRef = useRef(false);
  const keysPressedRef = useRef<Set<string>>(new Set());

  useEffect(() => {
    if (!enabled) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      keysPressedRef.current.add(event.key);

      const isHotKeyPressed = keyCombo.every((key) =>
        keysPressedRef.current.has(key)
      );
      if (isHotKeyPressed && !isHotKeyActiveRef.current) {
        onKeyDown();
        isHotKeyActiveRef.current = true;
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      keysPressedRef.current.delete(event.key);

      const isHotKeyReleased = keyCombo.some(
        (key) => !keysPressedRef.current.has(key)
      );
      if (isHotKeyReleased && isHotKeyActiveRef.current) {
        onKeyUp();
        isHotKeyActiveRef.current = false;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [keyCombo, onKeyDown, onKeyUp, enabled]);

  return null;
}
