import { makeStyles } from "@material-ui/core";
import { OrgChart } from "d3-org-chart";
import { useTrack } from "src/analytics";
import {
  AssembleButton,
  getIconProps,
} from "src/components/AssembleButton/AssembleButton";
import { CenterViewIcon } from "src/components/AssembleIcons/Small/CenterViewIcon";
import { ZoomInIcon } from "src/components/AssembleIcons/Small/ZoomInIcon";
import { ZoomOutIcon } from "src/components/AssembleIcons/Small/ZoomOutIcon";
import { useCompCycleOrganizationChartViewsData } from "src/components/CompCycleOrganizationChart/CompCycleOrganizationChartContext";
import { CancelConfigurationButton } from "src/pages/CompCycle/HierarchyConfiguration/CancelConfigurationButton";
import { CompCycleOrganizationChart_employee as Employee } from "src/__generated__/graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "calc(100vw - 272px)",
    position: "absolute",
    top: theme.spacing(2.5),
    right: theme.spacing(24.5),
    paddingLeft: theme.spacing(2),

    display: "flex",
    justifyContent: "space-between",
  },
  left: {
    display: "flex",
  },
  right: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(1.25),
    alignItems: "center",
  },
}));

type Props = {
  chart: OrgChart<Employee>;
  onBack: () => unknown;
  onSubmit: () => unknown;
};

export function ApprovalSettingsChartButtonBar({
  chart,
  onBack,
  onSubmit,
}: Props): JSX.Element {
  const { setPageEditState } = useCompCycleOrganizationChartViewsData();
  const classes = useStyles();
  const { trackEvent } = useTrack();

  const handleSave = () => {
    setPageEditState(false);
    trackEvent({
      object: "Save Configuration Button - Settings View",
      action: "Clicked",
    });
    onSubmit();
  };

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <CancelConfigurationButton onClick={onBack} isSettingsView />
      </div>
      <div className={classes.right}>
        <AssembleButton
          title="Fit chart onto view"
          size="small"
          variant="outlined"
          onClick={() => chart.fit()}
          startIcon={<CenterViewIcon {...getIconProps({ size: "small" })} />}
        />
        <AssembleButton
          title="Zoom out"
          size="small"
          variant="outlined"
          onClick={() => chart.zoomOut()}
          startIcon={<ZoomOutIcon {...getIconProps({ size: "small" })} />}
        />
        <AssembleButton
          title="Zoom in"
          size="small"
          variant="outlined"
          startIcon={<ZoomInIcon {...getIconProps({ size: "small" })} />}
          onClick={() => chart.zoomIn()}
        />
        <AssembleButton
          title="Save Configuration"
          size="small"
          variant="outlined"
          label="Save Configuration"
          onClick={handleSave}
          data-intercom-target={"button-save-configuration"}
        />
      </div>
    </div>
  );
}
