import { CurrencyCode } from "@asmbl/shared/constants";
import { getFormattedDate } from "@asmbl/shared/time";
import { Divider, makeStyles, Paper } from "@material-ui/core";
import { getSimpleCashLabel } from "../../models/Currency";
import { GRAY_2, GRAY_4 } from "../../theme";
import { CashCompType } from "../../__generated__/graphql";
import { AssembleTypography } from "../AssembleTypography";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "16rem",
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "8px",
    boxShadow: "0px 15px 25px rgba(10, 36, 64, 0.08)",
  },
  dateLabel: {
    color: GRAY_4,
  },
  li: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  bubble: {
    height: ".5rem",
    width: ".5rem",
    borderRadius: "50%",
    flex: "0 0 auto",
  },
  name: { color: GRAY_2, flex: 1 },
  value: { color: GRAY_2, flex: 0 },
  divider: {
    marginBlock: theme.spacing(1),
  },
}));

type Props = {
  currency: CurrencyCode;
} & Partial<{
  // These properties are injected by the chart component:
  label: number; // The millisecond timestamp of the date
  payload: {
    color: string;
    name: CashCompType;
    value: number;
  }[];
}>;

export function CompTooltip({
  label,
  payload,
  currency,
}: Props): JSX.Element | null {
  const classes = useStyles();

  if (label === undefined || payload === undefined) {
    return null;
  }

  return (
    <Paper className={classes.paper} elevation={0}>
      <AssembleTypography
        variant="productEyebrow"
        className={classes.dateLabel}
        gutterBottom
      >
        {getFormattedDate(new Date(label))}
      </AssembleTypography>
      {payload.map(({ color, name, value }) => (
        <div key={name} className={classes.li}>
          <div className={classes.bubble} style={{ backgroundColor: color }} />
          <AssembleTypography variant="productSmall" className={classes.name}>
            {labels[name]}
          </AssembleTypography>
          <AssembleTypography variant="productSmallBold">
            {getSimpleCashLabel({ currency, value })}
          </AssembleTypography>
        </div>
      ))}
      {payload.length > 1 && (
        <>
          <Divider className={classes.divider} />
          <div className={classes.li}>
            <AssembleTypography variant="productSmall" className={classes.name}>
              Total
            </AssembleTypography>
            <AssembleTypography variant="productSmallBold">
              {getSimpleCashLabel({
                currency,
                value: payload.map((p) => p.value).reduce((a, b) => a + b, 0),
              })}
            </AssembleTypography>
          </div>
        </>
      )}
    </Paper>
  );
}

const labels: Partial<Record<CashCompType, string>> = {
  [CashCompType.SALARY]: "Base Salary",
  [CashCompType.RECURRING_BONUS]: "Target Bonus",
  [CashCompType.COMMISSION]: "Target Commission",
};
