import { gql } from "@apollo/client";
import { createContext, useContext } from "react";
import {
  CondensedTable_meritAdjustmentGuide as MeritAdjustmentGuide,
  CondensedTable_meritAdjustmentRange as MeritAdjustmentRange,
  CondensedTable_perfRatingOption as PerfRatingOption,
  TableDataContext2_position,
} from "src/__generated__/graphql";
import { CondensedTableCurrentDepartmentCell2 } from "../Cells/CondensedTableCurrentDepartmentCell2";
import { CondensedTableCurrentLadderCell2 } from "../Cells/CondensedTableCurrentLadderCell2";
import { CondensedTableCurrentLevelCell2 } from "../Cells/CondensedTableCurrentLevelCell2";
import { CondensedTableNewDepartmentCell2 } from "../Cells/CondensedTableNewDepartmentCell2";
import { CondensedTableNewLadderCell2 } from "../Cells/CondensedTableNewLadderCell2";
import { CondensedTableNewLevelCell2 } from "../Cells/CondensedTableNewLevelCell2";
import { CondensedTableNewPositionCell2 } from "../Cells/CondensedTableNewPositionCell2";
import { CurrencyCodeWithAll } from "../CondensedTable/CondensedTableCurrencyPicker";

/* This context stores values that are 'globally' useful for the Condensed
 * Table, such as valuation and available positions. Providing these values
 * through a context allows us to bridge over the `react-table` rendering,
 * which requires a consistent Column prop interface.
 */
type TableDataContextValue = {
  compCycleId: number;
  availablePositions: TableDataContext2_position[];
  isActiveCycle: boolean;
  selectedCurrency: CurrencyCodeWithAll;
  directReportIds: number[];
  indirectReportIds: number[];
  currentPhase: Phase | null;
  compCyclePhases: Phase[];
  recommendationsPreFill: boolean;
  meritGuidance: {
    meritAdjustmentGuides: MeritAdjustmentGuide[];
    meritAdjustmentRanges: MeritAdjustmentRange[];
    perfRatingOptions: PerfRatingOption[];
  };
};

type Phase = {
  id: number;
  phaseOrder: number;
  startDate: GraphQL_DateTime;
};

export const TableDataContext2 = createContext<TableDataContextValue>({
  compCycleId: -1,
  availablePositions: [],
  isActiveCycle: false,
  selectedCurrency: "all",
  directReportIds: [],
  indirectReportIds: [],
  currentPhase: null,
  compCyclePhases: [],
  recommendationsPreFill: false,
  meritGuidance: {
    meritAdjustmentGuides: [],
    meritAdjustmentRanges: [],
    perfRatingOptions: [],
  },
});

export function useTableData() {
  return useContext(TableDataContext2);
}

export const TableDataContextProvider = Object.assign(
  TableDataContext2.Provider,
  {
    fragments: () => ({
      position: gql`
        ${CondensedTableCurrentDepartmentCell2.fragments.position}
        ${CondensedTableNewDepartmentCell2.fragments.position}
        ${CondensedTableCurrentLadderCell2.fragments.position}
        ${CondensedTableNewLadderCell2.fragments.position}
        ${CondensedTableCurrentLevelCell2.fragments.position}
        ${CondensedTableNewLevelCell2.fragments.position}
        ${CondensedTableNewPositionCell2.fragments.position}
        fragment TableDataContext2_position on Position {
          ...CondensedTableCurrentDepartmentCell_position
          ...CondensedTableNewDepartmentCell_position
          ...CondensedTableCurrentLadderCell_position
          ...CondensedTableNewLadderCell_position
          ...CondensedTableCurrentLevelCell_position
          ...CondensedTableNewLevelCell_position
          ...CondensedTableNewPositionCell2_position
        }
      `,
      user: gql`
        fragment TableDataContext_user on User {
          id
          employee {
            id
            directReports {
              id
            }
            indirectReports {
              id
            }
          }
        }
      `,
    }),
  }
);
