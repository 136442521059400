import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const ThumbsUpIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Thumbs Up"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M4.80813 7.65217L7.52822 2C7.81398 2 8.09695 2.05848 8.36097 2.1721C8.62498 2.28572 8.86487 2.45225 9.06693 2.66219C9.269 2.87213 9.42929 3.12137 9.53865 3.39567C9.64801 3.66997 9.70429 3.96397 9.70429 4.26087V5.95652H13.912C14.0662 5.95652 14.2188 5.99061 14.3594 6.05652C14.5 6.12244 14.6255 6.21867 14.7276 6.33883C14.8297 6.459 14.9061 6.60035 14.9516 6.7535C14.9971 6.90666 15.0107 7.06812 14.9916 7.22717L14.1756 14.0098C14.1427 14.2832 14.0148 14.5347 13.816 14.717C13.6172 14.8994 13.3611 15 13.0959 15H4.80813M1.54402 7.65217H4.80813V15H1.54402C1.39974 15 1.26136 14.9405 1.15934 14.8345C1.05732 14.7285 1 14.5847 1 14.4348V8.21739C1 8.06749 1.05732 7.92372 1.15934 7.81772C1.26136 7.71172 1.39974 7.65217 1.54402 7.65217V7.65217Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
