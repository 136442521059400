import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { pluckOptionsBy } from "@asmbl/shared/filter";
import { contramap } from "@asmbl/shared/sort";
import { Box, makeStyles } from "@material-ui/core";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { Employee2sMeta, PayPeriodType } from "../../__generated__/graphql";
import { ParamSearchableFilter } from "../../components/Filter/ParamSearchableFilter";
import { FilterParam } from "../../models/FilterParams";
import { theme } from "../../theme";

interface Props {
  disableReportingFilter: boolean;
  dropdownOptions: Omit<Employee2sMeta, "counts">;
  onChange: () => unknown;
}

const useStyles = makeStyles(() => ({
  filterBar: {
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
    marginBottom: theme.spacing(3),
    height: "2.5rem",
  },
  exportButton: {
    width: 160,
    height: "2.5rem",
  },
}));

export function FilterBar({
  disableReportingFilter,
  dropdownOptions,
  onChange,
}: Props): JSX.Element {
  const classes = useStyles();
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const hourlyEmployeesEnabled =
    isEnabled(FeatureFlag.HourlyEmployees) &&
    compStructure?.allowHourlyEmployees === true;

  const levelArray = [
    ...pluckOptionsBy(
      dropdownOptions.levels,
      (level) => level,
      (level) => `Level ${level}`
    ),
  ]
    .sort(contramap((opt) => opt.value))
    .reverse();

  return (
    <Box className={classes.filterBar}>
      <ParamSearchableFilter
        name="Managers"
        options={[
          { label: "All Managers", value: "all", hideDelete: true },
          ...pluckOptionsBy(
            dropdownOptions.managers,
            (manager) => manager.id,
            (manager) => manager.displayName
          ),
        ]}
        param={FilterParam.MANAGER}
        onChange={onChange}
      />
      <ParamSearchableFilter
        name="Reports"
        param={FilterParam.REPORTS}
        options={[
          { label: "All Reports", value: "all", hideDelete: true },
          { label: "Direct Reports", value: "direct" },
          { label: "Indirect Reports", value: "indirect" },
        ]}
        disabled={disableReportingFilter}
        onChange={onChange}
      />
      {hourlyEmployeesEnabled && (
        <ParamSearchableFilter
          name="Hourly/Annual"
          param={FilterParam.PAY_TYPE}
          options={[
            { label: "All", value: "all" },
            { label: "Hourly", value: PayPeriodType.HOURLY },
            { label: "Annual", value: PayPeriodType.ANNUAL },
          ]}
          onChange={onChange}
        />
      )}
      <ParamSearchableFilter
        name="Departments"
        param={FilterParam.DEPARTMENT}
        options={[
          { label: "All Departments", value: "all", hideDelete: true },
          ...pluckOptionsBy(
            dropdownOptions.departments,
            (department) => department.id,
            (department) => department.name
          ),
        ]}
        onChange={onChange}
      />
      <ParamSearchableFilter
        name="Ladders"
        param={FilterParam.LADDER}
        options={[
          { label: "All Ladders", value: "all", hideDelete: true },
          ...pluckOptionsBy(
            dropdownOptions.ladders,
            (ladder) => ladder.id,
            (ladder) => ladder.name
          ),
        ]}
        onChange={onChange}
      />
      <ParamSearchableFilter
        name="Levels"
        param={FilterParam.LEVEL}
        options={[
          { label: "All Levels", value: "all", hideDelete: true },
          ...levelArray,
          { label: "Unleveled", value: "null" },
        ]}
        onChange={onChange}
      />
      <ParamSearchableFilter
        name="Locations"
        param={FilterParam.LOCATION}
        options={[
          { label: "All Locations", value: "all", hideDelete: true },
          ...pluckOptionsBy(
            dropdownOptions.locations,
            (location) => location.id,
            (location) => location.name
          ),
        ]}
        onChange={onChange}
      />
    </Box>
  );
}
