import EyeOffSVG from "../../assets/svgs/eye-off.svg?react";

type Props = {
  viewBox: {
    x: number;
    y: number;
  };
};

export function NotEnoughDataLabel({ viewBox }: Props): JSX.Element {
  return (
    <g>
      {/* the x & y +/- values are to center the eye on the band*/}
      <EyeOffSVG
        x={viewBox.x - 8}
        y={viewBox.y + 52}
        height="16px"
        width="16px"
      />
    </g>
  );
}
