import { money, Money } from "@asmbl/shared/money";
import { InputAdornment, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useCurrencies } from "../../../components/CurrenciesContext";
import { AssembleLabel } from "../../../components/Form/AssembleLabel";
import { NumberInput } from "../../../components/Form/NumberInput";
import { currencySymbol } from "../../../models/Currency";

type Props = {
  label: string;
  id: string;
  value: Money | null;
  onBlur: (value: Money | null) => unknown;
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    columnGap: theme.spacing(1),
  },
}));

export function SettingsCashInput({
  label,
  id,
  value,
  onBlur,
}: Props): JSX.Element {
  const classes = useStyles();
  const [formValue, setFormValue] = useState(value);
  const { defaultCurrencyCode } = useCurrencies();
  const currencyCode = value ? value.currency : defaultCurrencyCode;

  const handleBlur = () => {
    onBlur(formValue);
  };

  return (
    <div className={classes.container}>
      <AssembleLabel htmlFor={id}>{label}</AssembleLabel>
      <NumberInput
        id={id}
        thousandSeparator
        placeholder="000,000"
        value={formValue?.value ?? null}
        onValueChange={(e) =>
          setFormValue(
            e.floatValue === undefined
              ? null
              : money(e.floatValue, currencyCode)
          )
        }
        onBlur={handleBlur}
        allowNegative={false}
        startAdornment={
          <InputAdornment position="start">
            {currencySymbol(currencyCode)}
          </InputAdornment>
        }
        fullWidth
      />
    </div>
  );
}
