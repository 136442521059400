import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  AddGuidanceRange,
  AddGuidanceRangeVariables,
  AddPerfRatingOption,
  AddPerfRatingOptionVariables,
  UpdateBudgetGuidance,
  UpdateBudgetGuidanceVariables,
  UpdateGuidanceRange,
  UpdateGuidanceRangeVariables,
  UpdatePerfRatingOption,
  UpdatePerfRatingOptionVariables,
} from "src/__generated__/graphql";

const UPDATE_BUDGET_GUIDANCE = gql`
  mutation UpdateBudgetGuidance($data: UpdateBudgetGuidanceInput!) {
    updateBudgetGuide(data: $data) {
      id
      percent
    }
  }
`;

export function useUpdateBudgetGuidance(): (
  id: number,
  percent: number
) => Promise<unknown> {
  const [updateBudgetGuidance] = useMutation<
    UpdateBudgetGuidance,
    UpdateBudgetGuidanceVariables
  >(UPDATE_BUDGET_GUIDANCE);

  return useCallback(
    (id: number, percent: number) =>
      updateBudgetGuidance({
        variables: { data: { id, percent } },
      }),
    [updateBudgetGuidance]
  );
}

const ADD_PERF_RATING_OPTION = gql`
  mutation AddPerfRatingOption($data: AddGuidanceInput!) {
    addPerfRatingOption(data: $data) {
      id
      rank
    }
  }
`;

export function useAddPerfRatingOption(
  compCycleId: number
): () => Promise<unknown> {
  const [addPerfRatingOption] = useMutation<
    AddPerfRatingOption,
    AddPerfRatingOptionVariables
  >(ADD_PERF_RATING_OPTION);

  return useCallback(
    () =>
      addPerfRatingOption({
        variables: { data: { compCycleId } },
      }),
    [addPerfRatingOption, compCycleId]
  );
}

const ADD_GUIDANCE_RANGE = gql`
  mutation AddGuidanceRange($data: AddGuidanceInput!) {
    addGuidanceRange(data: $data) {
      id
      rangeStart
    }
  }
`;

export function useAddGuidanceRange(
  compCycleId: number
): () => Promise<unknown> {
  const [addGuidanceRange] = useMutation<
    AddGuidanceRange,
    AddGuidanceRangeVariables
  >(ADD_GUIDANCE_RANGE);

  return useCallback(
    () =>
      addGuidanceRange({
        variables: { data: { compCycleId } },
      }),
    [addGuidanceRange, compCycleId]
  );
}

const UPDATE_PERF_RATING_OPTION = gql`
  mutation UpdatePerfRatingOption($data: UpdatePerfRatingOptionInput!) {
    updatePerfRatingOption(data: $data) {
      id
      name
    }
  }
`;

export function useUpdatePerfRatingOption(): (
  id: number,
  newName: string
) => Promise<unknown> {
  const [updatePerfRatingOption] = useMutation<
    UpdatePerfRatingOption,
    UpdatePerfRatingOptionVariables
  >(UPDATE_PERF_RATING_OPTION);

  return useCallback(
    (id: number, newName: string) =>
      updatePerfRatingOption({
        variables: { data: { id, name: newName } },
      }),
    [updatePerfRatingOption]
  );
}

const UPDATE_GUIDANCE_RANGE = gql`
  mutation UpdateGuidanceRange($data: UpdateGuidanceRangeInput!) {
    updateGuidanceRange(data: $data) {
      id
      rangeStart
    }
  }
`;

export function useUpdateGuidanceRange(): (
  id: number,
  rangeStart: number
) => Promise<unknown> {
  const [updateGuidanceRange] = useMutation<
    UpdateGuidanceRange,
    UpdateGuidanceRangeVariables
  >(UPDATE_GUIDANCE_RANGE);

  return useCallback(
    (id: number, rangeStart: number) =>
      updateGuidanceRange({
        variables: { data: { id, rangeStart } },
      }),
    [updateGuidanceRange]
  );
}
