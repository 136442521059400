import { Navigate, useParams } from "react-router-dom";
import {
  LocationsLoadingBoundary,
  LocationsLoadingBoundaryWithMarketId,
} from "./LocationsLoadingBoundary";

export function MarketIdReader(): JSX.Element {
  const { marketId: id } = useParams<{ marketId?: string }>();

  if (id == null) {
    return <LocationsLoadingBoundary />;
  }

  if (isNaN(parseInt(id))) {
    return <Navigate to=".." replace />;
  }

  return <LocationsLoadingBoundaryWithMarketId marketId={parseInt(id)} />;
}
