import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency } from "@asmbl/shared/currency";
import { ISettings } from "@flatfile/adapter";
import { CurrencyField } from "src/models/csv/Fields";

export const MarketDataFields = (
  currencies: Map<CurrencyCode, Currency<CurrencyCode>>
): ISettings["fields"] => {
  return [
    {
      label: "Job Code",
      key: "jobCode",
      validators: [{ validate: "required" }],
      description: "The job code that identifies this position in Assemble",
    },
    ...CurrencyField(currencies),
    {
      label: "Company Stage",
      key: "companyStage",
    },
    {
      label: "Sample Size",
      key: "sampleSize",
    },
    {
      label: "Salary 10th Percentile",
      key: "salary10",
    },
    {
      label: "Salary 25th Percentile",
      key: "salary25",
    },
    {
      label: "Salary 40th Percentile",
      key: "salary40",
    },
    {
      label: "Salary 50th Percentile",
      key: "salary50",
    },
    {
      label: "Salary 60th Percentile",
      key: "salary60",
    },
    {
      label: "Salary 75th Percentile",
      key: "salary75",
    },
    {
      label: "Salary 90th Percentile",
      key: "salary90",
    },
    {
      label: "Variable 10th Percentile",
      key: "variable10",
    },
    {
      label: "Variable 25th Percentile",
      key: "variable25",
    },
    {
      label: "Variable 40th Percentile",
      key: "variable40",
    },
    {
      label: "Variable 50th Percentile",
      key: "variable50",
    },
    {
      label: "Variable 60th Percentile",
      key: "variable60",
    },
    {
      label: "Variable 75th Percentile",
      key: "variable75",
    },
    {
      label: "Variable 90th Percentile",
      key: "variable90",
    },
    {
      label: "Equity 10th Percentile",
      key: "equity10",
    },
    {
      label: "Equity 25th Percentile",
      key: "equity25",
    },
    {
      label: "Equity 40th Percentile",
      key: "equity40",
    },
    {
      label: "Equity 50th Percentile",
      key: "equity50",
    },
    {
      label: "Equity 60th Percentile",
      key: "equity60",
    },
    {
      label: "Equity 75th Percentile",
      key: "equity75",
    },
    {
      label: "Equity 90th Percentile",
      key: "equity90",
    },
    {
      label: "Total Cash 10th Percentile",
      key: "totalCash10",
    },
    {
      label: "Total Cash 25th Percentile",
      key: "totalCash25",
    },
    {
      label: "Total Cash 40th Percentile",
      key: "totalCash40",
    },
    {
      label: "Total Cash 50th Percentile",
      key: "totalCash50",
    },
    {
      label: "Total Cash 60th Percentile",
      key: "totalCash60",
    },
    {
      label: "Total Cash 75th Percentile",
      key: "totalCash75",
    },
    {
      label: "Total Cash 90th Percentile",
      key: "totalCash90",
    },
  ];
};
