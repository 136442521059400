import { gql } from "@apollo/client";
import { eq, Money } from "@asmbl/shared/money";
import { Button, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CompCycleData } from "src/components/CompCycle/CompCycleWizard/types";
import { useUpdateCompCycleComponents } from "src/mutations/CompCycle";
import {
  CompCycleSettings_budget as Budget,
  CompCycleSettings_compCycle as CompCycle,
  CompCycleSettings_valuation as Valuation,
} from "../../../__generated__/graphql";
import { UploadRequestsButton } from "../../../components/CompCycle/Buttons/UploadRequestsButton";
import { CompComponentsForm } from "../../../components/CompCycle/CompCycleWizard/CompComponentsForm";
import { AssembleLabel } from "../../../components/Form/AssembleLabel";
import { CompComponent, CompComponentDisplay } from "../../../models/Budget";
import { useDraftBudget } from "../../../mutations/CompCycleBudget";
import { GRAY_1, GRAY_2, PURPLE_1 } from "../../../theme";
import { SettingsCashInput } from "./SettingsCashInput";
import { SettingsEquityInput } from "./SettingsEquityInput";

const ORG_ID = null;

const useStyles = makeStyles((theme) => ({
  content: {
    position: "relative",
    paddingBlock: theme.spacing(4, 6),
    margin: "0 auto",
    width: theme.spacing(67),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  title: {
    color: GRAY_1,
  },
  header: {
    textTransform: "uppercase",
    color: GRAY_1,
    margin: theme.spacing(2, 0, 0.5, 0),
  },
  h5: {
    marginTop: theme.spacing(3),
  },
  settingsSubtitle: {
    color: GRAY_2,
  },
  select: {
    boxSizing: "border-box",
    width: "8rem",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    top: "calc(50% - 8px)",
    height: "16px",
    right: "16px",
  },
  input: {
    flex: "1 0 0", // Take up whatever space is left
  },
  linkButton: {
    color: PURPLE_1,
    display: "inline-block",
    padding: 0,
    minHeight: 0,
    minWidth: 0,
  },
  buttonContainer: {
    marginTop: theme.spacing(1),
  },
}));
export type CompComponentSettings = CompCycleData["compComponents"];

type Props = {
  compCycle: CompCycle;
  updateName: (name: string) => Promise<unknown>;
  budget: Budget | null;
  valuation: Valuation;
};

export const CompCycleSettings = ({
  compCycle,
  updateName,
  budget,
  valuation,
}: Props): JSX.Element => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean | null>(null);

  const draftBudget = useDraftBudget(compCycle.id);

  const handleNameBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const newName = event.target.value.trim();
    if (newName.length > 0 && newName !== compCycle.name) {
      void updateName(event.target.value);
    }
  };

  const updateCompComponents = useUpdateCompCycleComponents(compCycle.id);

  const handleSetBudget = async (
    variant: CompComponent,
    newValue: Money | null
  ) => {
    if (eq(budget?.[variant], newValue)) {
      return;
    }
    setLoading(true);
    await draftBudget(ORG_ID, { [variant]: newValue });
    setLoading(false);
  };

  const handleUpdateCompComponents = async (
    // needed to match the signature of CompCycleDataChangeHandler
    _compComponents: "compComponents",
    updatedComponents: CompComponentSettings
  ) => {
    // since we pass the entire cycle object to the form,
    // we need to extract the relevant fields
    await updateCompComponents({
      allowSalary: updatedComponents.allowSalary,
      allowSalaryMarket: updatedComponents.allowSalaryMarket,
      allowSalaryMerit: updatedComponents.allowSalaryMerit,
      allowSalaryPromotion: updatedComponents.allowSalaryPromotion,
      allowEquity: updatedComponents.allowEquity,
      allowBonus: updatedComponents.allowBonus,
      allowTargetCommission: updatedComponents.allowTargetCommission,
      allowTargetRecurringBonus: updatedComponents.allowTargetRecurringBonus,
      allowActualRecurringBonus: updatedComponents.allowActualRecurringBonus,
    });
  };

  return (
    <div className={classes.content}>
      <div className={classes.section}>
        <Typography variant="h4" color="textPrimary">
          Comp Cycle Management
        </Typography>
        <AssembleLabel htmlFor="comp-cycle-name">Cycle Name</AssembleLabel>
        <TextField
          id="comp-cycle-name"
          variant="outlined"
          defaultValue={compCycle.name}
          fullWidth
          onBlur={handleNameBlur}
        />
      </div>

      <div className={classes.section}>
        <Typography variant="h4" color="textPrimary">
          Budget Settings
        </Typography>
        <Typography variant="h5" color="textPrimary" className={classes.h5}>
          Compensation Components in This Cycle
        </Typography>
        <Typography variant="body2" className={classes.settingsSubtitle}>
          These are the compensation components that will be used during this
          cycle. <br />
          <b>These components cannot be changed after being selected.</b>
        </Typography>
        <CompComponentsForm
          compComponents={compCycle}
          handleChange={handleUpdateCompComponents}
          mode="edit"
        />

        <Typography variant="h5" color="textPrimary" className={classes.h5}>
          Total Budget
        </Typography>
        <Typography variant="body2" className={classes.settingsSubtitle}>
          Enter the total budget of each of the compensation components you
          selected, including any discretionary budget.
        </Typography>

        {compCycle.allowSalary && (
          <SettingsCashInput
            label={CompComponentDisplay.salary}
            id="comp-cycle-settings-salary-input"
            value={budget?.salary ?? null}
            onBlur={(value) => handleSetBudget("salary", value)}
          />
        )}
        {compCycle.allowTargetCommission && (
          <SettingsCashInput
            label={CompComponentDisplay.targetCommission}
            id="comp-cycle-settings-target-commission"
            value={budget?.targetCommission ?? null}
            onBlur={(value) => handleSetBudget("targetCommission", value)}
          />
        )}
        {compCycle.allowTargetRecurringBonus && (
          <SettingsCashInput
            label={CompComponentDisplay.targetRecurringBonus}
            id="comp-cycle-settings-target-recurring-bonus"
            value={budget?.targetRecurringBonus ?? null}
            onBlur={(value) => handleSetBudget("targetRecurringBonus", value)}
          />
        )}
        {compCycle.allowActualRecurringBonus && (
          <SettingsCashInput
            label={CompComponentDisplay.actualRecurringBonus}
            id="comp-cycle-actual-recurring-bonus-input"
            value={budget?.actualRecurringBonus ?? null}
            onBlur={(value) => handleSetBudget("actualRecurringBonus", value)}
          />
        )}
        {compCycle.allowBonus && (
          <SettingsCashInput
            label={CompComponentDisplay.bonus}
            id="comp-cycle-settings-bonus-input"
            value={budget?.bonus ?? null}
            onBlur={(value) => handleSetBudget("bonus", value)}
          />
        )}
        {compCycle.allowEquity && (
          // This could be combined with EquityInput, but the onChange/onBlur
          // behaviors are significantly different.
          <SettingsEquityInput
            value={budget?.equity ?? null}
            valuation={valuation}
            onBlur={(value) => handleSetBudget("equity", value)}
          />
        )}
        {loading === false && (
          <Typography variant="body2" className={classes.settingsSubtitle}>
            Success! The total budget has been updated, but you'll need to go to
            the{" "}
            <Button
              component={Link}
              to={`/comp-cycles/${compCycle.id}/budget`}
              className={classes.linkButton}
            >
              Allocate Budgets pane to publish it.
            </Button>
          </Typography>
        )}
      </div>
    </div>
  );
};

// We explicitly add the key fields (compCycleId, employeeId) to the query,
// even though we don't use them here. They are important for Apollo caching.
CompCycleSettings.fragments = {
  budget: gql`
    fragment CompCycleSettings_budget on CompCycleBudgetDraft {
      compCycleId
      employeeId
      salary
      equity
      bonus
      targetRecurringBonus
      targetCommission
      actualRecurringBonus
    }
  `,
  compCycle: gql`
    fragment CompCycleSettings_compCycle on CompCycle {
      id
      name
      allowSalary
      allowSalaryMarket
      allowSalaryMerit
      allowSalaryPromotion
      allowEquity
      allowBonus
      allowTargetCommission
      allowTargetRecurringBonus
      allowActualRecurringBonus
    }
  `,
  valuation: gql`
    ${UploadRequestsButton.fragments.valuation}
    fragment CompCycleSettings_valuation on Valuation {
      id
      ...UploadRequestsButton_valuation
    }
  `,
};
