import { isZero, Money } from "@asmbl/shared/money";
import { Box, makeStyles } from "@material-ui/core";
import { BenefitsPackage } from "../../../models/Benefits";
import { getSimpleCashLabel } from "../../../models/Currency";
import { GRAY_3, theme } from "../../../theme";
import { BenefitsPackageFields } from "../../../__generated__/graphql";
import { AssembleTypography } from "../../AssembleTypography";
import { PreviewButton } from "../../Settings/Benefits/Editor/PreviewButton";

const useStyles = makeStyles(() => ({
  previewButton: {
    width: "100%",
    boxShadow:
      "0px 1px 1px rgba(10, 36, 64, 0.05), 0px 1px 3px rgba(10, 36, 64, 0.1) !important",
  },
}));

type Props = {
  benefits: BenefitsPackageFields | null | undefined;
  benefitsPackages: BenefitsPackage[];
  annualOfferedBenefitsComp: Money;
};

export function BenefitsSection({
  benefits,
  benefitsPackages,
  annualOfferedBenefitsComp,
}: Props): JSX.Element | null {
  const classes = useStyles();
  if (benefitsPackages.length === 0) return null;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gridRowGap={theme.spacing(0.5)}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <AssembleTypography variant="productEyebrowSmall" textColor={GRAY_3}>
          BENEFITS PACKAGE
        </AssembleTypography>

        {benefits != null && !isZero(annualOfferedBenefitsComp) && (
          <AssembleTypography variant="productEyebrowSmall" textColor={GRAY_3}>
            VALUE
          </AssembleTypography>
        )}
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <AssembleTypography variant="productParagraphMedium">
          {benefits === null
            ? "Don't show benefits"
            : benefits === undefined
              ? "None selected"
              : benefits.name}
        </AssembleTypography>
        <AssembleTypography variant="productMicrocopy">
          {benefits != null &&
            !isZero(annualOfferedBenefitsComp) &&
            getSimpleCashLabel(annualOfferedBenefitsComp)}
        </AssembleTypography>
      </Box>

      {benefits != null && (
        <Box mt={1} display="flex" flexDirection="row">
          <PreviewButton
            className={classes.previewButton}
            size="small"
            benefitsPackage={benefits}
          />
        </Box>
      )}
    </Box>
  );
}
