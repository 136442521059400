import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const CartaGrayscaleLogo = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_917_7967)">
        <rect width={width} height={height} fill="#BDD3E5" />
        <path
          d="M3.4668 8.04697C3.4668 5.04079 6.01006 3.25333 8.28883 3.25333C9.91652 3.25333 11.4425 3.86269 12.2563 5.28453L10.7304 6.17826C10.4659 5.77202 10.0996 5.44703 9.69271 5.2236C9.26544 5.00016 8.79748 4.87829 8.32952 4.87829C6.98668 4.87829 5.39968 5.93452 5.39968 8.00635C5.39968 10.0376 6.92564 11.1547 8.4516 11.1547C9.50959 11.1547 10.4048 10.5657 10.9338 9.63131L12.4801 10.3625C11.6052 11.9469 10.0182 12.8 8.1871 12.8C5.88799 12.8 3.4668 11.0328 3.4668 8.04697Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_917_7967">
          <rect width={width} height={height} rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </ColorableSVG>
);
