import { gql } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { makeStyles, TableCell } from "@material-ui/core";
import {
  BandPlacementCell_employee,
  BandPlacementCell_position,
  PayPeriodType,
  PositionType,
} from "../../__generated__/graphql";
import { ADJUSTED_CASH_BAND_FIELDS } from "../../fragments";
import { isBandPointDefined } from "../../models/BandPoint";
import {
  getTotalCash,
  getTotalCashAfterRecommendation,
  isRecurringComp,
} from "../../models/CashCompensation";
import { CompRecommendation } from "../../models/CompRecommendation";
import { AssembleTypography } from "../AssembleTypography";
import { NullCompSlider } from "../CompSlider/NullCompSlider";
import { TotalCashCondensedSlider } from "../CompSlider/TotalCashCompensation/TotalCashCondensedSlider";
import { useCompStructure } from "../CompStructureContext";
import { useCurrencies } from "../CurrenciesContext";
import { useFeatureFlags } from "../FeatureContext";

const useStyles = makeStyles((theme) => ({
  newBandContainer: {
    marginTop: theme.spacing(1),
  },
  rightAlignWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "flex-end",
  },
}));
interface Props {
  employee: BandPlacementCell_employee;
  draftRecommendation: CompRecommendation | null;
  promotedPosition: BandPlacementCell_position | null;
  alignRight?: boolean;
}

export function BandPlacementCell({
  employee,
  draftRecommendation,
  promotedPosition,
  alignRight,
}: Props): JSX.Element {
  const classes = useStyles();
  const { defaultCurrencyCode } = useCurrencies();
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const showHourly =
    compStructure?.allowHourlyEmployees === true &&
    isEnabled(FeatureFlag.HourlyEmployeesInCompCycles);

  const { activeEmployment } = employee;
  const position = activeEmployment?.position ?? null;
  const currentTotalCash = getTotalCash(employee.activeCashCompensation);

  const promotedLocationAdjustedBands =
    promotedPosition?.adjustedCashBands?.filter(isRecurringComp);
  const currentLocationAdjustedBands =
    employee.adjustedCashBands?.filter(isRecurringComp);

  const newTotalCash =
    draftRecommendation !== null
      ? getTotalCashAfterRecommendation(
          defaultCurrencyCode,
          employee.activeCashCompensation,
          Array.from(draftRecommendation.items.values()),
          compStructure?.workingHoursPerYear
        )
      : undefined;

  const currentBandsHaveData =
    currentLocationAdjustedBands != null &&
    currentLocationAdjustedBands.some((b) =>
      b.bandPoints.some(isBandPointDefined)
    );
  const promotedBandsHaveData =
    promotedLocationAdjustedBands != null &&
    promotedLocationAdjustedBands.some((b) =>
      b.bandPoints.some(isBandPointDefined)
    );

  const isCurrHourly = activeEmployment?.payPeriod === PayPeriodType.HOURLY;
  const isHourlyPromo = promotedPosition?.type === PositionType.HOURLY;
  return (
    <TableCell role="gridcell">
      <div className={alignRight === true ? classes.rightAlignWrapper : ""}>
        {promotedPosition ? (
          <>
            <div>
              <AssembleTypography variant="productExtraSmall" gutterBottom>
                Current
              </AssembleTypography>
              {position && currentTotalCash && currentBandsHaveData ? (
                <TotalCashCondensedSlider
                  cash={currentTotalCash}
                  bands={currentLocationAdjustedBands}
                  position={position}
                  size="wide"
                  color="gray"
                  isHourly={showHourly && (isHourlyPromo || isCurrHourly)}
                  payTypeSelection={
                    showHourly && (isHourlyPromo || isCurrHourly)
                      ? PayPeriodType.HOURLY
                      : PayPeriodType.ANNUAL
                  }
                />
              ) : (
                <NullCompSlider variant="condensedWide" />
              )}
            </div>
            <div className={classes.newBandContainer}>
              <AssembleTypography variant="productExtraSmall" gutterBottom>
                New
              </AssembleTypography>
              {newTotalCash && promotedBandsHaveData ? (
                <TotalCashCondensedSlider
                  cash={newTotalCash}
                  bands={promotedLocationAdjustedBands}
                  position={promotedPosition}
                  size="wide"
                  isHourly={showHourly && isHourlyPromo}
                  payTypeSelection={
                    showHourly && isHourlyPromo
                      ? PayPeriodType.HOURLY
                      : PayPeriodType.ANNUAL
                  }
                />
              ) : (
                <NullCompSlider variant="condensedWide" />
              )}
            </div>
          </>
        ) : (
          <>
            {position && currentTotalCash && currentBandsHaveData ? (
              <TotalCashCondensedSlider
                cash={currentTotalCash}
                newCash={newTotalCash}
                bands={currentLocationAdjustedBands}
                position={position}
                size="wide"
                color="default"
                isHourly={isCurrHourly}
                payTypeSelection={
                  isCurrHourly ? PayPeriodType.HOURLY : PayPeriodType.ANNUAL
                }
              />
            ) : (
              <NullCompSlider variant="condensedWide" />
            )}
          </>
        )}
      </div>
    </TableCell>
  );
}

BandPlacementCell.fragments = {
  employee: gql`
    ${TotalCashCondensedSlider.fragments.cash}
    ${TotalCashCondensedSlider.fragments.position}
    ${ADJUSTED_CASH_BAND_FIELDS}
    fragment BandPlacementCell_employee on Employee2 {
      id
      activeEmployment {
        id
        payPeriod
        position {
          id
          type
          ...TotalCashCondensedSlider_position
        }
      }
      activeCashCompensation(currencyCode: $currencyCode) {
        employeeId
        activeAt
        type
        annualCashEquivalent
        hourlyCashEquivalent
        unit
        ...TotalCashCondensedSlider_cash
      }
      adjustedCashBands {
        id
        ...AdjustedCashBandFields
      }
    }
  `,
  position: gql`
    ${TotalCashCondensedSlider.fragments.position}
    ${ADJUSTED_CASH_BAND_FIELDS}
    fragment BandPlacementCell_position on Position {
      id
      type
      ...TotalCashCondensedSlider_position
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedCashBandFields
      }
    }
  `,
};
