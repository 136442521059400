import { gql } from "@apollo/client";
import { CompCycleGrouping } from "../../../../components/CompCycle/types";
import {
  AllocationUnit,
  budgetAllocationFactory,
} from "../../../../models/Budget";
import { DraftBudgetInput } from "../../../../mutations/CompCycleBudget";
import {
  ReportingTier_compCycleBudget as RootBudget,
  ReportingTier_compCycleBudgetDraft as RootBudgetDraft,
  ReportingTier_compCycleSettings as CompCycleCompComponentSettings,
  ReportingTier_valuation as Valuation,
} from "../../../../__generated__/graphql";
import { IndentationCells } from "./Connectors";
import { EmployeeAllocationRow } from "./EmployeeAllocationRow";
import { RemainingAllocationRow } from "./RemainingAllocationRow";

type Props = {
  budgetDraft: RootBudgetDraft;
  budget: RootBudget | null;
  compComponent: CompCycleGrouping;
  equityDisplay: AllocationUnit;
  compComponentSettings: CompCycleCompComponentSettings;
  valuation: Valuation;
  organizationName: string;
  onSaveDraft: (
    employeeId: number | null,
    budget: DraftBudgetInput
  ) => Promise<unknown>;
  indentation: IndentationCells;
};

export function ReportingTier({
  budgetDraft,
  compComponent,
  equityDisplay,
  compComponentSettings,
  valuation,
  organizationName,
  onSaveDraft,
  indentation,
}: Props): JSX.Element {
  const { employee, reportsDrafts } = budgetDraft;

  const getAllocation = budgetAllocationFactory({
    compComponent: compComponent === "all" ? "salary" : compComponent,
    compCycle: compComponentSettings,
    equityDisplay,
    valuation,
  });

  return (
    <>
      <RemainingAllocationRow
        managerAllocation={getAllocation(budgetDraft)}
        reportAllocations={reportsDrafts.map(getAllocation)}
        subject={{
          name: employee?.displayName ?? organizationName,
          isOrg: employee === null,
        }}
        indentation={indentation}
      />

      {budgetDraft.reportsDrafts.map((reportDraft, idx) => {
        if (!reportDraft.employee) {
          // This should never happen, as the organization is never somebody's report
          return null;
        }

        const report = reportDraft.employee.compCycleBudget;
        const draftAllocation = getAllocation(reportDraft);
        const publishedAllocation = report ? getAllocation(report) : null;

        return (
          <EmployeeAllocationRow
            key={reportDraft.employeeId}
            budgetAllocation={draftAllocation}
            publishedAllocation={publishedAllocation}
            employee={reportDraft.employee}
            compComponent={compComponent}
            equityDisplay={equityDisplay}
            valuation={valuation}
            onSaveDraft={onSaveDraft}
            expandable={reportDraft.employee.indirectReportsCount > 0}
            organizationName={organizationName}
            indentation={indentation}
            isLastRow={idx === budgetDraft.reportsDrafts.length - 1}
          />
        );
      })}
    </>
  );
}

ReportingTier.fragments = {
  compCycleBudgetDraft: gql`
    ${EmployeeAllocationRow.fragments.employee}
    fragment ReportingTier_compCycleBudgetDraft on CompCycleBudgetDraft {
      compCycleId
      employeeId
      employee {
        id
        indirectReportsCount
        displayName
      }

      reportsDrafts {
        compCycleId
        employeeId
        salary
        salaryMarket
        salaryPromotion
        salaryMerit
        equity
        bonus
        targetCommission
        targetRecurringBonus
        actualRecurringBonus
        employee {
          id
          indirectReportsCount
          ...EmployeeAllocationRow_employee

          compCycleBudget(compCycleId: $compCycleId) {
            compCycleId
            employeeId

            salary
            salaryMarket
            salaryPromotion
            salaryMerit
            equity
            bonus
            targetCommission
            targetRecurringBonus
            actualRecurringBonus
          }
        }
      }

      salary
      salaryMarket
      salaryPromotion
      salaryMerit
      equity
      bonus
      targetCommission
      targetRecurringBonus
      actualRecurringBonus
    }
  `,
  compCycleBudget: gql`
    ${EmployeeAllocationRow.fragments.employee}
    fragment ReportingTier_compCycleBudget on CompCycleBudget {
      compCycleId
      employeeId
      employee {
        id
        indirectReportsCount
        displayName
      }

      reportsBudgets {
        compCycleId
        employeeId
        salary
        salaryMarket
        salaryPromotion
        salaryMerit
        equity
        bonus
        targetCommission
        targetRecurringBonus
        actualRecurringBonus
        employee {
          id
          indirectReportsCount
          ...EmployeeAllocationRow_employee
        }
      }

      salary
      salaryMarket
      salaryPromotion
      salaryMerit
      equity
      bonus
      targetCommission
      targetRecurringBonus
      actualRecurringBonus
    }
  `,
  compCycleSettings: gql`
    fragment ReportingTier_compCycleSettings on CompCycle {
      id
      allowSalaryMarket
      allowSalaryPromotion
      allowSalaryMerit
      allowBonus
      allowTargetCommission
      allowTargetRecurringBonus
      allowActualRecurringBonus
    }
  `,
  valuation: gql`
    ${EmployeeAllocationRow.fragments.valuation}
    fragment ReportingTier_valuation on Valuation {
      id
      ...EmployeeAllocationRow_valuation
    }
  `,
};
