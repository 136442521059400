import { gql } from "@apollo/client";
import { CompCycleDisplay } from "../../../../components/CompCycle/types";
import {
  ReportingTargetTier_compCycleBudget as CompCycleBudget,
  ReportingTargetTier_compCycleData as CompCycleData,
  ReportingTargetTier_manager as Manager,
  ReportingTargetTier_reports as Report,
} from "../../../../__generated__/graphql";
import { IndentationCells } from "../../Budgets/Table/Connectors";
import { TargetRow } from "./TargetRow";
import { TargetSummarizedRow } from "./TargetSummarizedRow";

type Props = {
  manager: Manager | null;
  reports: Report[];
  rootBudget: CompCycleBudget | null;
  organizationName: string;
  varianceDisplay: CompCycleDisplay;
  compCycleData: CompCycleData;
  indentation: IndentationCells;
};

export function ReportingTargetTier({
  manager,
  reports,
  rootBudget,
  organizationName,
  varianceDisplay,
  compCycleData,
  indentation,
}: Props): JSX.Element {
  return (
    <>
      <TargetSummarizedRow
        manager={manager}
        reports={reports}
        rootBudget={rootBudget}
        organizationName={organizationName}
        varianceDisplay={varianceDisplay}
        compCycleData={compCycleData}
        indentation={indentation}
      />

      {reports
        .filter(
          (employee) =>
            employee.indirectReports.length > 0 ||
            employee.directReports.length > 0
        )
        .map((employee, idx, filteredEmployees) => {
          return (
            <TargetRow
              key={idx}
              employee={employee}
              organizationName={organizationName}
              varianceDisplay={varianceDisplay}
              expandable={employee.indirectReports.length > 0}
              compCycleData={compCycleData}
              indentation={indentation}
              isLastRow={idx === filteredEmployees.length - 1}
            />
          );
        })}
    </>
  );
}

ReportingTargetTier.fragments = {
  compCycleBudget: gql`
    ${TargetSummarizedRow.fragments.compCycleBudget}
    fragment ReportingTargetTier_compCycleBudget on CompCycleBudget {
      compCycleId
      employeeId
      ...TargetSummarizedRow_compCycleBudget
    }
  `,
  manager: gql`
    ${TargetSummarizedRow.fragments.manager}
    fragment ReportingTargetTier_manager on Employee {
      id
      ...TargetSummarizedRow_manager
    }
  `,
  reports: gql`
    ${TargetSummarizedRow.fragments.reports}
    ${TargetRow.fragments.employee}
    fragment ReportingTargetTier_reports on Employee {
      id

      directReports {
        id
      }

      indirectReports {
        id
      }

      ...TargetRow_employee
      ...TargetSummarizedRow_reports
    }
  `,
  compCycleData: gql`
    ${TargetRow.fragments.compCycleData}
    ${TargetSummarizedRow.fragments.compCycleData}
    fragment ReportingTargetTier_compCycleData on CompCycle {
      id
      ...TargetRow_compCycleData
      ...TargetSummarizedRow_compCycleData
    }
  `,
};
