import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { BandVisualizationTooltip_position as Position } from "../../__generated__/graphql";
import {
  ADJUSTED_CASH_BAND_FIELDS,
  ADJUSTED_EQUITY_BAND_FIELDS,
} from "../../fragments";
import {
  totalCompBand,
  totalCompMax,
  totalCompMaxHourly,
  totalCompMin,
  totalCompMinHourly,
} from "../../models/Band";
import { getSimpleCashLabel } from "../../models/Currency";
import { AssembleTypography } from "../AssembleTypography";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1),
  },
}));

type Props = {
  position: Position;
  excludedBandNames: Set<string>;
  hourly?: boolean;
};

export function BandVisualizationTooltip({
  position,
  excludedBandNames,
  hourly,
}: Props) {
  const classes = useStyles();

  return (
    <div className={classes.tooltip}>
      <AssembleTypography variant="body2">
        <b>{getCompRangeString(position, excludedBandNames, hourly)}</b>
      </AssembleTypography>
    </div>
  );
}

BandVisualizationTooltip.fragments = {
  position: gql`
    ${ADJUSTED_CASH_BAND_FIELDS}
    ${ADJUSTED_EQUITY_BAND_FIELDS}
    fragment BandVisualizationTooltip_position on Position {
      id
      jobCodes
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedCashBandFields
      }
      adjustedEquityBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedEquityBandFields
      }
    }
  `,
};

function getCompRangeString(
  position: Position,
  excludedBandNames: Set<string>,
  hourly = false
) {
  const getCompMin = hourly ? totalCompMinHourly : totalCompMin;
  const getCompMax = hourly ? totalCompMaxHourly : totalCompMax;

  const band = totalCompBand(position, excludedBandNames);

  const min = getCompMin(band);
  const max = getCompMax(band);

  if (!min || !max) {
    return "This band has no band points";
  }

  const minLabel = getSimpleCashLabel(min, false, hourly);
  const maxLabel = getSimpleCashLabel(max, false, hourly);

  if (min.value === max.value) {
    return maxLabel;
  }
  return `${minLabel} – ${maxLabel}`;
}
