import { Popper, PopperProps } from "@material-ui/core";

type AssemblePopperProps = PopperProps & {
  contentWidth?: "fit-content" | "fit-input";
};

export function AssemblePopper({
  contentWidth = "fit-input",
  ...props
}: AssemblePopperProps): JSX.Element {
  const style =
    contentWidth === "fit-input"
      ? { ...props.style }
      : { ...props.style, minWidth: "fit-content" };

  return <Popper placement={"bottom-start"} {...props} style={style} />;
}

export function AssembleFitContentPopper({
  ...props
}: PopperProps): JSX.Element {
  return <AssemblePopper contentWidth="fit-content" {...props} />;
}
