import { gql } from "@apollo/client";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from "@material-ui/core";
import clsx from "clsx";
import { AssembleTypography } from "../../../../components/AssembleTypography";
import { CompCycleDisplay } from "../../../../components/CompCycle/types";
import { CompComponentDisplay } from "../../../../models/Budget";
import { GRAY_6, GRAY_8, WHITE } from "../../../../theme";
import {
  Target_compCycleBudget as CompCycleBudget,
  Target_compCycleData as CompCycleData,
  Target_manager as Manager,
  Target_reports as Report,
} from "../../../../__generated__/graphql";
import { ReportingTargetTier } from "./ReportingTargetTier";

type Props = {
  manager: Manager | null;
  reports: Report[];
  rootBudget: CompCycleBudget | null;
  organizationName: string;
  compCycleData: CompCycleData;
  varianceDisplay: CompCycleDisplay;
};

const useStyles = makeStyles(() => ({
  root: {
    "& td, & th": {
      borderBottom: `1px solid ${GRAY_6}`,
      borderRight: `1px solid ${GRAY_6}`,
    },
    paddingBottom: "100px",
  },
  header: {
    backgroundColor: WHITE,
    height: "2rem",
    borderTop: `1px solid ${GRAY_6}`,
  },
  subheader: {
    backgroundColor: GRAY_8,
    height: "2rem",
  },
  groupedHeader: {
    "th&": {
      borderRight: `1px solid ${GRAY_6}`,
    },
  },
  name: {
    width: "20%",
  },
  directAndIndirect: {
    width: "5%",
  },
  totalTarget: {
    width: "70%",
  },
  totalTargetSubheader: {
    width: "23.33%",
  },
  scrollContainer: {
    overflowX: "auto",
    zIndex: 1,
    height: "100%",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  secondHeader: {
    top: "2rem",
    boxShadow: "0px 1px 5px rgba(10, 36, 64, 0.1)",
  },
}));

export function Target({
  manager,
  reports,
  rootBudget,
  organizationName,
  compCycleData,
  varianceDisplay,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.scrollContainer}>
      <Table className={classes.root}>
        {/* Main header */}
        <TableHead className={classes.stickyHeader}>
          <TableCell
            className={clsx(
              classes.header,
              classes.name,
              classes.groupedHeader
            )}
            variant="head"
          >
            <AssembleTypography variant="productTableHeader">
              Name
            </AssembleTypography>
          </TableCell>

          <TableCell
            className={clsx(
              classes.header,
              classes.groupedHeader,
              classes.directAndIndirect
            )}
            variant="head"
          >
            <AssembleTypography variant="productTableHeader">
              Direct
            </AssembleTypography>
          </TableCell>

          <TableCell
            className={clsx(classes.header, classes.directAndIndirect)}
            variant="head"
          >
            <AssembleTypography variant="productTableHeader">
              Indirect
            </AssembleTypography>
          </TableCell>

          {compCycleData.allowTargetCommission && (
            <TableCell
              className={clsx(classes.header, classes.groupedHeader)}
              variant="head"
              colSpan={3}
              align="left"
            >
              <AssembleTypography variant="productTableHeader">
                {CompComponentDisplay.targetCommission}
              </AssembleTypography>
            </TableCell>
          )}
          {compCycleData.allowTargetRecurringBonus && (
            <TableCell
              className={clsx(classes.header, classes.groupedHeader)}
              variant="head"
              colSpan={3}
              align="left"
            >
              <AssembleTypography variant="productTableHeader">
                {CompComponentDisplay.targetRecurringBonus}
              </AssembleTypography>
            </TableCell>
          )}
        </TableHead>

        {/* Subheader */}
        <TableHead className={clsx(classes.stickyHeader, classes.secondHeader)}>
          <TableCell
            className={clsx(
              classes.subheader,
              classes.groupedHeader,
              classes.name
            )}
            variant="head"
            align="left"
          />
          <TableCell
            className={clsx(
              classes.subheader,
              classes.groupedHeader,
              classes.directAndIndirect
            )}
            variant="head"
            align="left"
          />
          <TableCell
            className={clsx(
              classes.subheader,
              classes.groupedHeader,
              classes.directAndIndirect
            )}
            variant="head"
            align="left"
          />

          {/* One Time Bonus subheader cells */}
          {compCycleData.allowTargetCommission && (
            <>
              <TableCell
                className={clsx(classes.subheader, classes.groupedHeader)}
                variant="head"
                align="left"
              >
                <AssembleTypography variant="productTableHeader">
                  Budget
                </AssembleTypography>
              </TableCell>
              <TableCell
                className={clsx(classes.subheader, classes.groupedHeader)}
                variant="head"
                align="left"
              >
                <AssembleTypography variant="productTableHeader">
                  Request
                </AssembleTypography>
              </TableCell>
              <TableCell
                className={clsx(classes.subheader)}
                variant="head"
                align="left"
              >
                <AssembleTypography variant="productTableHeader">
                  Usage
                </AssembleTypography>
              </TableCell>
            </>
          )}
          {/* Target Recurring Bonus subheader cells */}
          {compCycleData.allowTargetRecurringBonus && (
            <>
              <TableCell
                className={clsx(classes.subheader, classes.groupedHeader)}
                variant="head"
                align="left"
              >
                <AssembleTypography variant="productTableHeader">
                  Budget
                </AssembleTypography>
              </TableCell>
              <TableCell
                className={clsx(classes.subheader, classes.groupedHeader)}
                variant="head"
                align="left"
              >
                <AssembleTypography variant="productTableHeader">
                  Request
                </AssembleTypography>
              </TableCell>
              <TableCell
                className={clsx(classes.subheader)}
                variant="head"
                align="left"
              >
                <AssembleTypography variant="productTableHeader">
                  Usage
                </AssembleTypography>
              </TableCell>
            </>
          )}
        </TableHead>

        <TableBody>
          <ReportingTargetTier
            manager={manager}
            reports={reports}
            rootBudget={rootBudget}
            organizationName={organizationName}
            varianceDisplay={varianceDisplay}
            compCycleData={compCycleData}
            indentation={[]}
          />
        </TableBody>
      </Table>
    </div>
  );
}

Target.fragments = {
  compCycleBudget: gql`
    ${ReportingTargetTier.fragments.compCycleBudget}
    fragment Target_compCycleBudget on CompCycleBudget {
      compCycleId
      employeeId
      ...ReportingTargetTier_compCycleBudget
    }
  `,
  manager: gql`
    ${ReportingTargetTier.fragments.manager}
    fragment Target_manager on Employee {
      id
      ...ReportingTargetTier_manager
    }
  `,
  reports: gql`
    ${ReportingTargetTier.fragments.reports}
    fragment Target_reports on Employee {
      id
      ...ReportingTargetTier_reports
    }
  `,
  compCycleData: gql`
    ${ReportingTargetTier.fragments.compCycleData}
    fragment Target_compCycleData on CompCycle {
      id
      allowTargetCommission
      allowTargetRecurringBonus
      ...ReportingTargetTier_compCycleData
    }
  `,
};
