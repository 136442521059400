import { DateTime as LuxonDateTime } from "luxon";

export function getXTicksForTimeline(start: Date, end: Date): LuxonDateTime[] {
  // Tick lines go at each quarter,
  // starting from the first one after the start of the data
  let tick = LuxonDateTime.fromJSDate(start)
    .plus({ quarter: 1 })
    .startOf("quarter");

  // ...and ending just after the current quarter
  const tickMax = LuxonDateTime.fromJSDate(end)
    .plus({ quarter: 1 })
    .startOf("quarter");

  // Iterate over that range, building the list of ticks
  const ticks: LuxonDateTime[] = [tick];

  const totalTicks = tickMax.diff(tick, "quarters").quarters;
  const increment = totalTicks > 16 ? { quarter: 2 } : { quarter: 1 };

  while (tick.toMillis() < tickMax.toMillis()) {
    tick = tick.plus(increment);
    ticks.push(tick);
  }

  return ticks;
}

export function getYTicks(intervals: number[], data: number[]): number[] {
  // Tick lines start from 0 (of whatever currency)
  let yTick = 0;
  // ...and go up just past the max value
  const yTickMax = Math.max(...data);

  // Choose the biggest increment that results in at least 4 ticks
  const increment = intervals.find((i) => yTickMax / 4 > i) ?? 1;
  const yTicks: number[] = [yTick];
  while (yTick < yTickMax) {
    yTick += increment;
    yTicks.push(yTick);
  }

  return yTicks;
}

export function getXTicksForGrantTimeline(
  start: Date,
  end: Date
): LuxonDateTime[] {
  // starting from the first one after the start of the data
  let tick = LuxonDateTime.fromJSDate(start);

  const tickMax = LuxonDateTime.fromJSDate(end);

  // Iterate over that range, building the list of ticks on a yearly basis
  const ticks: LuxonDateTime[] = [tick];

  while (tick.toMillis() < tickMax.toMillis()) {
    tick = tick.plus({ year: 1 });
    ticks.push(tick);
  }

  return ticks;
}
