import { CircularProgress, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useState } from "react";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    alignItems: "center",
    flexGrow: 1,
  },
}));

type Props = { className?: string };

export function LoadingSpinner({ className }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <CircularProgress />
    </div>
  );
}

export function DelayedLoadingSpinner(): JSX.Element | null {
  const [showSpinner, setShowSpinner] = useState(false);

  // Add a short delay to reduce flickering for content that might load quickly
  useEffect(() => {
    setTimeout(() => setShowSpinner(true), 500);
  }, []);

  return showSpinner ? <LoadingSpinner /> : null;
}
