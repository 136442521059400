import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { Money } from "@asmbl/shared/money";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { PositionType } from "src/__generated__/graphql";
import { GRAY_4, WHITE } from "../../theme";
import { CalendarIcon } from "../AssembleIcons/Extra-Small/CalendarIcon";
import { ClockIcon } from "../AssembleIcons/Extra-Small/ClockIcon";
import { useCompStructure } from "../CompStructureContext";
import { useFeatureFlags } from "../FeatureContext";
import {
  LargeCompSlider,
  LargeCompSliderAdjustedBandPoint,
} from "./LargeCompSlider";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    flexDirection: "column",
  },
  positionTitle: {
    display: "flex",
    gap: theme.spacing(0.5),
    alignItems: "center",
  },
  tooltipPositionText: {
    color: GRAY_4,
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  tooltipLevelText: {
    color: GRAY_4,
    fontSize: "12px",
    marginBottom: "15px",
  },
  tooltip: {
    padding: theme.spacing(2, 2.5),
    borderRadius: "10px",
    width: "320px",
    height: "147px",
    boxShadow: "0px 15px 25px rgba(10, 36, 64, 0.08)",
    background: WHITE,
  },
}));

export type Position = {
  name: string;
  level: number;
  type: PositionType;
};

export type Props = {
  locationAdjustedBandPoints: LargeCompSliderAdjustedBandPoint[];
  value: Money | null;
  position: Position | null;
  children: JSX.Element;
  valueLabel?: string;
};

export function CompSliderTooltip({
  locationAdjustedBandPoints: bandPoints,
  value: moneyValue,
  position,
  children,
  valueLabel,
}: Props): JSX.Element {
  const classes = useStyles();
  const { isEnabled } = useFeatureFlags();
  const { compStructure } = useCompStructure();
  const showHourly = Boolean(
    compStructure?.allowHourlyEmployees &&
      isEnabled(FeatureFlag.HourlyEmployees)
  );
  const isHourly = position?.type === PositionType.HOURLY;
  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      placement="top"
      interactive
      title={
        <>
          <div className={classes.titleContainer}>
            <div className={classes.positionTitle}>
              {showHourly && isHourly ? (
                <ClockIcon color={GRAY_4} />
              ) : (
                <CalendarIcon color={GRAY_4} />
              )}
              <Typography className={classes.tooltipPositionText}>
                {position?.name ?? ""}
              </Typography>
            </div>
            <Typography className={classes.tooltipLevelText}>{`Level ${
              position?.level ?? "N/A"
            }`}</Typography>
          </div>
          <LargeCompSlider
            value={moneyValue}
            locationAdjustedBandPoints={bandPoints}
            valueLabel={valueLabel}
          />
        </>
      }
    >
      {children}
    </Tooltip>
  );
}
