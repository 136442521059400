import { ApolloClient, gql, useLazyQuery, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import * as changeCase from "change-case";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { IntercomContextValues, useIntercom } from "react-use-intercom";
import {
  GetCurrentUserPermissions,
  Noun,
  TeamCompensationAccessType,
} from "../../__generated__/graphql";
import { initCandu } from "../../candu";
import { Organization } from "../../models/Organization";
import { UserPermissions } from "../../models/UserPermissions";
import { CURRENT_USER_PERMISSIONS } from "../../queries";
import { useBearerToken } from "./BearerContext";

type WhoAmIOrganization = NonNullable<
  GetCurrentUserPermissions["whoami"]
>["organization"];

export type AuthContextType = {
  user: AuthUser | null;
  permissions: UserPermissions;
  employeeId: number | null;
  userId: number | null;
  organization: Organization | null;
  isLoading: boolean;
  refetch?: () => Promise<unknown>;
  logout?: () => Promise<unknown>;
};

type AuthUser = {
  email: string | null;
  displayName: string | null;
  photoURL: string | null;
  emailVerified: boolean;
  uid: string;
};

const EMPTY_PERMISSIONS = new UserPermissions(null, null, false);
const DEFAULT_CONTEXT = {
  user: null,
  permissions: EMPTY_PERMISSIONS,
  employeeId: null,
  userId: null,
  organization: null,
  isLoading: true,
};

const authContext = createContext<AuthContextType>(DEFAULT_CONTEXT);

export function AuthProvider(props: {
  client: ApolloClient<unknown>;
  children: ReactNode;
}): JSX.Element {
  const auth = useAuthProvider(props.client);

  return (
    <authContext.Provider value={auth}>{props.children}</authContext.Provider>
  );
}

type MockedAuthProviderProps = {
  context: AuthContextType;
  children: ReactNode;
};

export function MockedAuthProvider(
  props: MockedAuthProviderProps
): JSX.Element {
  return (
    <authContext.Provider value={props.context}>
      {props.children}
    </authContext.Provider>
  );
}

export function useAuth(): AuthContextType {
  return useContext(authContext);
}

function updateSegment(
  attributes: {
    user: AuthUser | null;
    organization: WhoAmIOrganization;
  } | null
) {
  if (attributes?.user) {
    const { user, organization } = attributes;
    window.analytics.identify(user.uid);
    window.analytics.group(organization.id.toString());
  } else {
    window.analytics.reset();
  }
}

function setSentryCtx(userId: number, organization: WhoAmIOrganization) {
  Sentry.setUser({
    id: userId.toString(),
  });
  Sentry.setContext("organization", {
    id: organization.id,
    name: organization.name,
  });
}

function updateIntercom(
  intercom: IntercomContextValues,
  attributes: {
    user: AuthUser | null;
    organization: WhoAmIOrganization;
    permissions: UserPermissions;
    userCreatedAt: Date;
  } | null
) {
  if (attributes?.user) {
    const { user, organization, permissions, userCreatedAt } = attributes;
    intercom.boot();
    intercom.update({
      name: user.displayName ?? undefined,
      email: user.email ?? undefined,
      userId: user.uid,
      createdAt: userCreatedAt.toUTCString(),
      company: {
        companyId: organization.id.toString(),
        name: organization.name,
        customAttributes: {
          canManagersViewComp:
            organization.permissionSettings.teamCompensationAccess !==
            TeamCompensationAccessType.NONE,
          /** GOING FORWARD, NEW ATTRIBUTES SHOULD BE HUMAN-READABLE */
          "Has Active Comp Cycle": organization.hasActiveCompCycle,
        },
      },
      customAttributes: {
        isManager: permissions.isManager(),
        isManagerOfManagers: permissions.isManagerOfManagers(),
        /** GOING FORWARD, NEW ATTRIBUTES SHOULD BE HUMAN-READABLE */
        "User Role":
          permissions.roleName !== null
            ? changeCase.capitalCase(permissions.roleName)
            : "",
        "Can View Comp": viewableCompComponents(permissions),
        "Can View Bands": canViewBands(permissions),
      },
    });
  } else {
    // We need to do both a shutdown and a boot in order to to clear out user
    // data and then make the messenger still usable by logged-out customers for
    // support.
    intercom.shutdown();
    intercom.boot();
  }
}

const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout
  }
`;

function useLogout(client: ApolloClient<unknown>): () => Promise<unknown> {
  const intercom = useIntercom();
  const { clearToken } = useBearerToken();
  const [serverLogout] = useMutation(LOGOUT_MUTATION);

  return useCallback(async () => {
    updateSegment(null);
    updateIntercom(intercom, null);
    await serverLogout();

    sessionStorage.clear();

    await clearToken();

    // Reset Apollo https://github.com/apollographql/apollo-client/issues/3766#issuecomment-578075556
    client.stop();
    await client.cache.reset();
    await client.clearStore();
  }, [client, intercom, clearToken]);
}

function useAuthProvider(client: ApolloClient<unknown>): AuthContextType {
  const intercom = useIntercom();
  const logout = useLogout(client);
  const { getToken } = useBearerToken();

  const [refetch, { data }] = useLazyQuery<GetCurrentUserPermissions>(
    CURRENT_USER_PERMISSIONS,
    { onError: logout, fetchPolicy: "no-cache", nextFetchPolicy: "no-cache" }
  );

  // Refetch WhoAmI whenever our auth state changes
  useEffect(() => void refetch(), [getToken, refetch]);

  const authContext = useMemo(() => {
    if (!data) {
      return { ...DEFAULT_CONTEXT, logout, refetch };
    }

    if (data.whoami === null) {
      initCandu();
      intercom.boot(); // Boot Intercom as a visitor
      return {
        ...DEFAULT_CONTEXT,
        user: null,
        isLoading: false,
        logout,
        refetch,
      };
    }

    const { whoami } = data;
    const { organization, employeeId, id: userId, userAccessGrant } = whoami;

    const user = toAuthUser(whoami);
    const permissions = new UserPermissions(
      userAccessGrant?.roleName ?? null,
      userAccessGrant?.nounScopes ?? null,
      employeeId !== null
    );

    setSentryCtx(userId, organization);
    updateSegment({ user, organization });
    initCandu(user.uid);
    updateIntercom(intercom, {
      user,
      organization,
      permissions,
      userCreatedAt: new Date(whoami.createdAt),
    });

    return {
      user,
      permissions,
      employeeId,
      userId,
      organization,
      isLoading: false,
      refetch,
      logout,
    };
  }, [data, intercom, logout, refetch]);

  return authContext;
}

function viewableCompComponents(
  permissions: UserPermissions
): "Equity" | "Cash" | "All" | "None" {
  const equity = permissions.canViewAny(Noun.EmployeeEquityCompensation);
  const cash = permissions.canViewAny(Noun.EmployeeCashCompensation);

  if (cash && equity) return "All";
  else if (!cash && equity) return "Equity";
  else if (cash && !equity) return "Cash";
  else return "None";
}

function canViewBands(permissions: UserPermissions): boolean {
  const equity = permissions.canViewAny(Noun.EquityBand);
  const cash = permissions.canViewAny(Noun.CashBand);
  return cash && equity;
}

function toAuthUser(
  whoami: NonNullable<GetCurrentUserPermissions["whoami"]>
): AuthUser {
  return {
    email: whoami.email,
    uid: whoami.uid,
    displayName: whoami.displayName,
    photoURL: whoami.photoURL,
    emailVerified: whoami.emailVerified,
  };
}
