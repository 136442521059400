import { gql } from "@apollo/client";
import { contramap } from "@asmbl/shared/sort";
import { makeStyles } from "@material-ui/core";
import { CondensedTableManagerCell2_participant as Participant } from "../../../../__generated__/graphql";
import { UserAvatar } from "../../../../components/UserAvatar";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { MEDIUM_COL_WIDTH } from "./dimensions";

const useStyles = makeStyles(() => ({
  managerCell: {
    display: "flex",
    justifyContent: "center",
  },
}));

type Props = {
  row: { original: Participant };
};

export function CondensedTableManagerCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.managerCell}>
      {employee.subject.manager === null ? (
        "-"
      ) : (
        <UserAvatar
          displayName={employee.subject.manager.displayName}
          photoURL={employee.subject.manager.user?.photoURL ?? null}
          showTooltip
          grayscale
        />
      )}
    </div>
  );
}

CondensedTableManagerCell2.fragments = {
  participant: gql`
    fragment CondensedTableManagerCell2_participant on CompCycleParticipant {
      subject {
        manager: minimalManager {
          id
          displayName
          user {
            id
            photoURL
          }
        }
      }
    }
  `,
};

CondensedTableManagerCell2.Header = ColumnIdsToHeaders.get(ColumnIds.MANAGER);
CondensedTableManagerCell2.id = ColumnIds.MANAGER;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableManagerCell2,
  Header: CondensedTableManagerCell2.Header,
  id: CondensedTableManagerCell2.id,
  width: MEDIUM_COL_WIDTH,
};
CondensedTableManagerCell2.column = column;
CondensedTableManagerCell2.ordering = () =>
  contramap((e: Participant) => e.subject.manager?.displayName ?? "");
