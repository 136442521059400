import { Money } from "@asmbl/shared/money";
import { Card } from "./Card";
import { CompCardContent } from "./CompCardContent";

type Props = {
  label?: string;
  value: Money | string | number;
  description?: string;
  className?: string;
  secondLabel?: string;
  secondValue?: Money | string | number;
  secondDescription?: string;
};

/**
 * A small card displaying a component of an employee's compensation.
 * This generic component is meant to be specialized by components
 * using colocated fragments to describe the data they need.
 */
export function CompCard({
  label,
  value,
  description,
  className,
}: Props): JSX.Element {
  return (
    <Card className={className}>
      <CompCardContent label={label} value={value} description={description} />
    </Card>
  );
}
