import { makeStyles } from "@material-ui/core";
import { WHITE } from "../../theme";
import { AssembleTypography } from "../AssembleTypography";
import { InfoBanner } from "../InfoBanner";

const useStyles = makeStyles(() => ({ copy: { color: WHITE } }));

type Props = { onClose: () => void };

export function DataAccuracyBanner({ onClose }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <InfoBanner onClose={onClose} level="info-gradient">
      <AssembleTypography className={classes.copy} variant="productSmallBold">
        Employee portal data accuracy depends on information provided by your
        company.
      </AssembleTypography>
    </InfoBanner>
  );
}
