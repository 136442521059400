import { gql } from "@apollo/client";
import { Drawer, makeStyles } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { GRAY_9 } from "../../../../theme";
import {
  LocationsTableView_locations as Locations,
  LocationsTableView_mergeConfigs as MergeConfig,
} from "../../../../__generated__/graphql";
import FormHeader from "../../FormHeader";
import { HRISLocationsLinkClose } from "../HRISLocationsLinkClose";
import { LocationsTable } from "./LocationsTable";
import { UnmappedLocationsBanner } from "./UnmappedLocationsBanner";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: `calc(100% - ${theme.spacing(41)}px)`,
    backgroundColor: GRAY_9,
  },
  container: {
    padding: `${theme.spacing(6)}px ${theme.spacing(6)}px`,
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2.5),
    paddingBottom: theme.spacing(0.5),
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

type Props = {
  mergeConfigs: MergeConfig[] | undefined;
  locations: Locations[];
};

export function LocationsTableView({
  mergeConfigs,
  locations,
}: Props): JSX.Element | null {
  const classes = useStyles();
  const location = useLocation();

  if (location.hash !== "#locations") {
    return null;
  }

  return (
    <Drawer
      open
      variant="persistent"
      anchor="right"
      PaperProps={{ className: classes.paper }}
    >
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <FormHeader mb={0} header="HRIS Location Data Management" />
            <HRISLocationsLinkClose />
          </div>
        </div>

        <UnmappedLocationsBanner locations={locations} />

        <LocationsTable mergeConfigs={mergeConfigs} locations={locations} />
      </div>
    </Drawer>
  );
}

LocationsTableView.fragments = {
  mergeConfigs: gql`
    ${LocationsTable.fragments.mergeConfigs}
    fragment LocationsTableView_mergeConfigs on MergeConnectionConfig {
      ...LocationsTable_mergeConfigs
      id
    }
  `,
  locations: gql`
    ${LocationsTable.fragments.locations}
    ${UnmappedLocationsBanner.fragments.locations}
    fragment LocationsTableView_locations on Location {
      ...LocationsTable_locations
      ...UnmappedLocationsBanner_locations
      id
    }
  `,
};
