import { Button, makeStyles, Paper } from "@material-ui/core";
import { HTMLAttributes } from "react";
import { GRAY_2, GRAY_6, GRAY_8 } from "../../../theme";

const useStyles = makeStyles(() => ({
  button: {
    position: "absolute",
    color: GRAY_2,
    bottom: 0,
    width: "100%",
    height: "2.85rem",
    borderRadius: "0px",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    backgroundColor: GRAY_8,
    borderTop: `1px solid ${GRAY_6}`,
    justifyContent: "flex-start",
    "& :hover": {
      backgroundColor: GRAY_8,
    },
  },
  separator: {
    height: "2.85rem",
  },
}));

type Props = {
  setManualEntry: (value: boolean) => void;
} & HTMLAttributes<HTMLElement>;
/**
 * By default you can't put a button here, so we wrap it in a div that prevents
 * the Popper from blurring when you click on it. This allows the button to be
 * clicked.
 *        <div onMouseDown={(e) => e.preventDefault()}>
 */
export function DropdownPaper({
  children,
  setManualEntry,
}: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Paper>
      {children}
      <div className={classes.separator} />
      <div onMouseDown={(e) => e.preventDefault()}>
        <Button
          className={classes.button}
          color="primary"
          onClick={() => setManualEntry(true)}
        >
          Enter a candidate manually...
        </Button>
      </div>
    </Paper>
  );
}
