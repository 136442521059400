import { IconButton, makeStyles, TableBody, TableRow } from "@material-ui/core";
import { useState } from "react";
import { CollapseAllIcon } from "src/components/AssembleIcons/Brand/CollapseAllIcon";
import { ExpandAllIcon } from "src/components/AssembleIcons/Brand/ExpandAllIcon";
import { JobStructure } from "../../../models/JobStructure";
import { UserPermissions } from "../../../models/UserPermissions";
import { GRAY_2 } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";
import { useLocations } from "../../LocationsContext";
import { WireTable } from "../../Table/WireTable/WireTable";
import { WireTableHead } from "../../Table/WireTable/WireTableHead";
import { WireTableUnsortableHeaderCell } from "../../Table/WireTable/WireTableHeaderCell";
import { MarketRow } from "./MarketRow";

const useStyles = makeStyles((theme) => ({
  marketCell: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
}));

type Props = {
  jobStructure: JobStructure;
  userId: number;
  userPermissions: UserPermissions;
  type: "user" | "user-invite";
};

export function MarketsSubsection({
  jobStructure,
  userId,
  userPermissions,
  type,
}: Props) {
  const { markets } = useLocations();
  const classes = useStyles();

  const [detailsShowing, setDetailsShowing] = useState<Set<number>>(new Set());
  const scope = userPermissions.cashCompensationScope().view;

  const toggleDetails = (marketId: number) => () =>
    setDetailsShowing((prev) => {
      const next = new Set(prev);
      prev.has(marketId) ? next.delete(marketId) : next.add(marketId);
      return next;
    });

  const areAllExpanded = detailsShowing.size >= markets.length;

  const expandCollapseAll = () => {
    if (areAllExpanded) {
      setDetailsShowing(new Set());
    } else {
      setDetailsShowing(new Set([-1, ...markets.map((m) => m.id)]));
    }
  };

  const hasAnyNestedData = markets.some((market) => {
    const marketScope = scope?.markets.find(
      (m) => m.marketId === market.id
    )?.scope;

    return (
      marketScope &&
      (marketScope.global ||
        marketScope.departmentIDs.length > 0 ||
        marketScope.ladderIDs.length > 0 ||
        marketScope.positionIDs.length > 0)
    );
  });

  return (
    <div>
      <AssembleTypography variant="productSectionHeader">
        Supplemental Band Access
      </AssembleTypography>
      <AssembleTypography
        variant="formDescription"
        textColor={GRAY_2}
        paragraph
      >
        Add any additional scopes of access for any roles not covered by the
        auto-permissioned access above.
      </AssembleTypography>

      <WireTable>
        <WireTableHead>
          <TableRow>
            <WireTableUnsortableHeaderCell
              width="25%"
              cellTitle={
                <div className={classes.marketCell}>
                  {hasAnyNestedData ? (
                    <IconButton size="small" onClick={expandCollapseAll}>
                      {areAllExpanded ? (
                        <CollapseAllIcon inherit />
                      ) : (
                        <ExpandAllIcon inherit />
                      )}
                    </IconButton>
                  ) : null}
                  <span>Market</span>
                </div>
              }
            />
            <WireTableUnsortableHeaderCell
              width="25%"
              cellTitle="Departments"
            />
            <WireTableUnsortableHeaderCell width="25%" cellTitle="Ladders" />
            <WireTableUnsortableHeaderCell width="25%" cellTitle="Positions" />
          </TableRow>
        </WireTableHead>
        <TableBody>
          <MarketRow
            market={null}
            key="allMarkets"
            jobStructure={jobStructure}
            userId={userId}
            userPermissions={userPermissions}
            showDetails={detailsShowing.has(-1)}
            onToggleDetails={toggleDetails(-1)}
            type={type}
            hasAnyNestedData={hasAnyNestedData}
          />

          {markets.map((market) => (
            <MarketRow
              key={market.id}
              jobStructure={jobStructure}
              market={market}
              userId={userId}
              userPermissions={userPermissions}
              showDetails={detailsShowing.has(market.id)}
              onToggleDetails={toggleDetails(market.id)}
              type={type}
              hasAnyNestedData={hasAnyNestedData}
            />
          ))}
        </TableBody>
      </WireTable>
    </div>
  );
}
