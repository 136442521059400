import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { GRAY_4, GRAY_6, WHITE } from "src/theme";

const useStyles = makeStyles(() => ({
  tag: {
    width: 32,
    height: 16,
    borderRadius: "2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    background: WHITE,
    border: `1px solid ${GRAY_6}`,
    color: GRAY_4, // text color
  },
  text: {
    margin: 0,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "11px",
    lineHeight: "140%",
    textTransform: "uppercase",
  },
}));

type Props = {
  label: string;
  tagClassName?: string;
};

export function AssembleTag({ label, tagClassName }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <div className={clsx(classes.tag, tagClassName)}>
      <p className={classes.text}>{label}</p>
    </div>
  );
}
