import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  LinearProgress,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  RawDraftContentState,
} from "draft-js";
import fastDeepEqual from "fast-deep-equal";
import { useState } from "react";
import { useAuth } from "../../components/Auth/AuthContext";
import { NoCompPhilosophy } from "../../components/EmptyState/EmptyState";
import { RichTextEditor } from "../../components/RichTextEditor/RichTextEditor";
import UnsavedChangesWarning from "../../components/UnsavedChangesWarning";
import { EditorStatus } from "../../constants";
import { UPSERT_PHILOSOPHY } from "../../mutations";
import { GET_PHILOSOPHY } from "../../queries";
import {
  DIMENSIONS,
  GRAY_1,
  GRAY_3,
  GRAY_4,
  GRAY_6,
  GRAY_8,
  MONOSPACED_FONT,
  WHITE,
} from "../../theme";
import {
  GetPhilosophy,
  Noun,
  UpsertPhilosophy,
  UpsertPhilosophyVariables,
} from "../../__generated__/graphql";
import { CreatePhilosophy } from "./CreatePhilosophy";
import { PhilosophyActionBar } from "./PhilosophyActionBar";

type Philosophy = GetPhilosophy["philosophy"];

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginRight: DIMENSIONS.sidebar,
    padding: "0 210px",
  },
  editorContainer: {
    minWidth: "686px",
    maxWidth: "818px",
    margin: "auto",
    paddingBottom: theme.spacing(3),
  },
  editor: {
    backgroundColor: WHITE,
    border: `1px solid ${GRAY_6}`,
    boxShadow: `2px 2px 5px ${GRAY_1}0F`,
    minHeight: "68vh",
    padding: theme.spacing(6),
    fontSize: "1rem",
    "& .RichTextEditor-Blockquote": {
      borderLeft: `2px solid ${GRAY_1}`,
      color: GRAY_3,
      margin: "16px 0",
      padding: "0px 0px 0px 20px",
    },
    "& .public-DraftStyleDefault-pre": {
      backgroundColor: GRAY_8,
      color: GRAY_3,
      fontFamily: MONOSPACED_FONT,
      fontWeight: 600,
      padding: "15px 20px",
      "& *": {
        fontFamily: MONOSPACED_FONT,
      },
    },
    "& .public-DraftStyleDefault-unorderedListItem": {
      listStyleType: "square",
    },
  },
  toolbar: {
    alignItems: "center",
    backgroundColor: WHITE,
    border: `1px solid ${GRAY_6}`,
    boxShadow: `0px 2px 3px ${GRAY_1}0D`,
    display: "flex",
    height: "100px",
    justifyContent: "space-between",
    margin: 0,
    padding: 0,
    position: "sticky",
    top: 0,
    zIndex: 2,
    "& .RichTextEditor-Toolbar": {
      width: "100%",
    },
  },
  lastEdited: {
    alignItems: "center",
    textAlign: "center",
    verticalAlign: "middle",
    display: "flex",
    height: "100px",
    justifyContent: "center",
    margin: 0,
    padding: 0,
    color: GRAY_4,
  },
}));

export const Philosophy = (): JSX.Element | null => {
  const { permissions } = useAuth();

  const canEditPhilosophy = permissions.canEditGlobal(Noun.Philosophy);

  const [editorStatus, setEditorStatus] = useState(EditorStatus.VIEWING);
  const openEditor = () => {
    setEditorStatus(EditorStatus.EDITING);
  };

  const [upsertPhilosophy] = useMutation<
    UpsertPhilosophy,
    UpsertPhilosophyVariables
  >(UPSERT_PHILOSOPHY);

  const saveEditorState = async (state: EditorState) => {
    setEditorStatus(EditorStatus.SAVING);
    try {
      await upsertPhilosophy({
        variables: {
          compPhilosophy: convertToRaw(state.getCurrentContent()),
        },
        refetchQueries: [{ query: GET_PHILOSOPHY }],
      });
      setEditorStatus(EditorStatus.SAVED);
      setTimeout(() => {
        setEditorStatus(EditorStatus.VIEWING);
      }, 3000);
    } catch (error) {
      setEditorStatus(EditorStatus.EDITING);
    }
  };

  const { data, loading } = useQuery<GetPhilosophy>(GET_PHILOSOPHY);

  if (loading) return <LinearProgress />;

  if (
    !data?.philosophy &&
    editorStatus === EditorStatus.VIEWING &&
    canEditPhilosophy
  ) {
    return <CreatePhilosophy onClick={openEditor} />;
  }

  if (!data?.philosophy && !canEditPhilosophy) {
    return <NoCompPhilosophy />;
  }

  return (
    <PhilosophyEditor
      canEdit={canEditPhilosophy}
      editorStatus={editorStatus}
      setEditorStatus={setEditorStatus}
      saveEditorState={saveEditorState}
      savedPhilosophy={data?.philosophy}
    />
  );
};

const PhilosophyEditor = ({
  canEdit,
  editorStatus,
  setEditorStatus,
  saveEditorState,
  savedPhilosophy,
}: {
  canEdit: boolean;
  editorStatus: EditorStatus;
  setEditorStatus: (state: EditorStatus) => void;
  saveEditorState: (state: EditorState) => void;
  savedPhilosophy?: Philosophy | null;
}) => {
  const classes = useStyles({ editorStatus });

  const placeholder =
    "You can begin writing your compensation philosophy here and the tools above to format it. Or, copy and paste rich text from a PDF or Word document...";

  const oldContent = savedPhilosophy?.json as RawDraftContentState | undefined;
  const initialEditorState =
    oldContent != null
      ? EditorState.createWithContent(convertFromRaw(oldContent))
      : EditorState.createEmpty();
  const [editorState, setEditorState] =
    useState<EditorState>(initialEditorState);

  const openEditor = () => {
    setEditorStatus(EditorStatus.EDITING);
  };
  const resetToInitialContent = () => {
    setEditorState(initialEditorState);
    setEditorStatus(EditorStatus.VIEWING);
  };

  const handleSave = () => {
    saveEditorState(editorState);
  };

  const contentIsEdited = !fastDeepEqual(
    convertToRaw(initialEditorState.getCurrentContent()),
    convertToRaw(editorState.getCurrentContent())
  );

  return (
    <Box className={classes.container}>
      <UnsavedChangesWarning
        pageEdited={editorStatus !== EditorStatus.VIEWING && contentIsEdited}
      />
      <Box className={classes.editorContainer}>
        <RichTextEditor
          setEditorState={setEditorState}
          editorState={editorState}
          readOnly={editorStatus !== EditorStatus.EDITING}
          placeholder={placeholder}
        >
          {({ Editor, Toolbar }) => (
            <>
              {editorStatus === EditorStatus.VIEWING ? (
                <LastEdited
                  createdAt={savedPhilosophy?.createdAt as string | undefined}
                  author={
                    savedPhilosophy?.author?.name ??
                    savedPhilosophy?.author?.email
                  }
                />
              ) : (
                <menu className={classes.toolbar}>{Toolbar()}</menu>
              )}
              <section className={classes.editor}>{Editor()}</section>
            </>
          )}
        </RichTextEditor>
      </Box>
      {canEdit && (
        <PhilosophyActionBar
          editorStatus={editorStatus}
          handleSave={handleSave}
          contentIsEdited={contentIsEdited}
          resetToInitialContent={resetToInitialContent}
          openEditor={openEditor}
        />
      )}
    </Box>
  );
};

const LastEdited = ({
  createdAt,
  author,
}: {
  createdAt?: string;
  author?: string | null;
}) => {
  const classes = useStyles();

  const renderDate = () => {
    return createdAt != null && createdAt !== ""
      ? new Date(createdAt).toLocaleDateString()
      : null;
  };

  const renderAuthor = () => {
    return author != null && author !== "" ? `by ${author}` : null;
  };

  return (
    <Typography className={classes.lastEdited} variant="body2">
      Last edited {renderDate()} {renderAuthor()}
    </Typography>
  );
};
