import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { CommissionCard } from "../../components/Portal/CommissionCard";
import { BenefitsValueCard } from "../../components/Portal/Comp/BenefitsValueCard";
import { BonusCard } from "../../components/Portal/Comp/BonusCard";
import { EquityCard } from "../../components/Portal/Comp/EquityCard";
import { SalaryCard } from "../../components/Portal/Comp/SalaryCard";
import { TotalCompPie } from "../../components/Portal/Comp/TotalCompPie";
import { TotalCompGrid_employee } from "../../__generated__/graphql";

type Props = { employee: TotalCompGrid_employee };

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridTemplateAreas: `
      "a a a a a a b b b b b b"
    `,
    gap: theme.spacing(4),
  },
  gridA: { gridArea: "a" },
  gridB: {
    gridArea: "b",
    display: "flex",
    gap: theme.spacing(4),
    flexDirection: "row-reverse",
    justifyContent: "stretch",
    flexWrap: "wrap-reverse",
    alignItems: "stretch",
    "& > *": { flex: "1 0 15rem" },
  },
}));

export function TotalCompGrid({ employee }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.grid}>
      <TotalCompPie
        className={classes.gridA}
        employee={employee}
        cashCompensation={employee.activeCashCompensation ?? []}
        equityHoldings={employee.equityHoldings}
      />
      <div className={classes.gridB}>
        <BenefitsValueCard employee={employee} />
        <EquityCard equityHoldings={employee.equityHoldings} />
        <BonusCard employee={employee} />
        <CommissionCard employee={employee} />
        <SalaryCard employee={employee} />
      </div>
    </div>
  );
}

TotalCompGrid.fragments = {
  employee: gql`
    ${SalaryCard.fragments.employee}
    ${CommissionCard.fragments.employee}
    ${BonusCard.fragments.employee}
    ${EquityCard.fragments.equityHoldings}
    ${BenefitsValueCard.fragments.employee}
    ${TotalCompPie.fragments.employee}
    ${TotalCompPie.fragments.cashCompensation}
    ${TotalCompPie.fragments.equityHoldings}
    fragment TotalCompGrid_employee on Employee {
      ...SalaryCard_employee
      ...CommissionCard_employee
      ...BonusCard_employee
      ...BenefitsValueCard_employee
      ...TotalCompPie_employee

      activeCashCompensation {
        employeeId
        type
        activeAt
        annualCashEquivalent
        ...TotalCompPie_cashCompensation
      }

      equityHoldings {
        id
        ...EquityCard_equityHoldings
        ...TotalCompPie_equityHoldings
      }
    }
  `,
};
