import { gql } from "@apollo/client";
import { JobStructureScope } from "@asmbl/shared/permissions";
import { makeStyles } from "@material-ui/core";
import { Dispatch, SetStateAction, useState } from "react";
import { JobStructure } from "../../../../models/JobStructure";
import {
  UserInviteCompBandAccess_departments as Department,
  UserInviteCompBandAccess_organization as Organization,
} from "../../../../__generated__/graphql";
import { AssembleButton } from "../../../AssembleButton/AssembleButton";
import { AssembleTypography } from "../../../AssembleTypography";
import { useLocations } from "../../../LocationsContext";
import { AccessGrantDrawer } from "../../UserAccessControl/AccessGrantDrawer";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(1),
  },
}));

type Props = {
  organization: Organization;
  departments: Department[];
  compBandAccess: { [market: string]: JobStructureScope } | null;
  setCompBandAccess: Dispatch<
    SetStateAction<{ [market: string]: JobStructureScope } | null>
  >;
};

export function UserInviteCompBandAccess({
  organization,
  departments,
  compBandAccess,
  setCompBandAccess,
}: Props): JSX.Element {
  const classes = useStyles();
  const { markets } = useLocations();
  const [selectedMarket, setSelectedMarket] = useState<null | number>(null);
  const [isOpen, setIsOpen] = useState(false);

  const jobStructure: JobStructure = { organization, departments };

  const currentCompBandAccess = compBandAccess?.[
    selectedMarket?.toString() ?? "allMarkets"
  ] ?? {
    global: false,
    departmentIDs: [],
    ladderIDs: [],
    positionIDs: [],
  };

  return (
    <>
      <AccessGrantDrawer
        key={selectedMarket ?? "allMarkets"}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onChange={(value) => {
          return Promise.resolve(
            setCompBandAccess({
              ...compBandAccess,
              [selectedMarket?.toString() ?? "allMarkets"]: value,
            })
          );
        }}
        value={currentCompBandAccess}
        jobStructure={jobStructure}
      />

      <AssembleTypography variant="productHeader">
        Configure compensation band access for:
      </AssembleTypography>

      <div className={classes.buttonGroup}>
        <AssembleButton
          size="small"
          variant="outlined"
          label="All Markets"
          onClick={() => {
            setSelectedMarket(null);
            setIsOpen(true);
          }}
        />

        {markets.map((market) => (
          <AssembleButton
            key={market.id}
            size="small"
            variant="outlined"
            label={market.name}
            onClick={() => {
              setSelectedMarket(market.id);
              setIsOpen(true);
            }}
          />
        ))}
      </div>
    </>
  );
}

UserInviteCompBandAccess.fragments = {
  organization: gql`
    fragment UserInviteCompBandAccess_organization on Organization {
      id
      name
    }
  `,
  departments: gql`
    fragment UserInviteCompBandAccess_departments on Department {
      id
      name
      ladders {
        id
        name
        positions {
          id
          level
          name
        }
      }
    }
  `,
};
