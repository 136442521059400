import { Box, Fab } from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import { BLUE_2, theme } from "../../theme";
import { ArrayValue } from "../../utils";
import {
  CurrencyInput,
  GetCurrenciesSettings,
} from "../../__generated__/graphql";
import { AssembleButton } from "../AssembleButton/AssembleButton";
import { PlusCircleIcon } from "../AssembleIcons/Brand/PlusCircleIcon";
import {
  AdditionalCurrenciesInput,
  CurrencyValue,
} from "../Currency/AdditionalCurrenciesInput";
import { SaveButton } from "../Form/SaveButton";
import UnsavedChangesWarning from "../UnsavedChangesWarning";
import { CurrencyField } from "./CurrencyField";

type Currency = ArrayValue<GetCurrenciesSettings["currencies"]>;

interface CurrencyFormProps {
  currencies: Currency[];
  onSubmit: (currencies: CurrencyInput[]) => Promise<boolean>;
}

type FormState = CurrencyValue[];

function isCurrencyValid(
  currency: CurrencyValue
): currency is CurrencyInput & CurrencyValue {
  return currency.code !== null && currency.exchangeRate !== null;
}
function isFormValid(
  formState: FormState
): formState is (CurrencyInput & CurrencyValue)[] {
  return formState.every(isCurrencyValid);
}

function doesFormMatchPersisted(
  formState: FormState,
  persisted: Currency[]
): boolean {
  if (persisted.length !== formState.length) {
    return false;
  }
  return formState.every(
    ({ code, exchangeRate }, i) =>
      persisted[i].code === code && persisted[i].exchangeRate === exchangeRate
  );
}

export const CurrencyForm = ({
  currencies: currenciesWithDefault,
  onSubmit,
}: CurrencyFormProps): JSX.Element => {
  const defaultCurrency = currenciesWithDefault.find((c) => c.isDefault);
  const currencies = useMemo(
    () => currenciesWithDefault.filter((c) => !c.isDefault),
    [currenciesWithDefault]
  );
  const [formState, setFormState] = useState<FormState>(currencies);
  const [resetKey, resetForm] = useState<number>(Math.random());

  const handleSubmit = useCallback(async () => {
    if (!isFormValid(formState)) {
      return false;
    }

    // Strip ids, __typenames, and any other fields
    const sanitized = formState.map(({ code, exchangeRate }) => ({
      code,
      exchangeRate,
    }));

    return await onSubmit(sanitized);
  }, [formState, onSubmit]);

  const handleAddCurrency = useCallback(
    () =>
      setFormState(
        formState.concat([
          { id: Math.random(), code: null, exchangeRate: null },
        ])
      ),
    [formState]
  );

  const handleChange = useCallback(
    (targetId: number, value: CurrencyValue | null) => {
      setFormState(
        value === null
          ? formState.filter(({ id }) => id !== targetId)
          : formState.map((currency) => {
              if (currency.id === targetId) {
                return value;
              } else {
                return currency;
              }
            })
      );
    },
    [formState]
  );

  const handleCancel = useCallback(() => {
    setFormState(currencies);
    resetForm(Math.random());
  }, [currencies]);

  const isTouched = useMemo(
    () => !doesFormMatchPersisted(formState, currencies),
    [formState, currencies]
  );

  return (
    <form onSubmit={handleSubmit}>
      <UnsavedChangesWarning pageEdited={isTouched} />
      {defaultCurrency && (
        <CurrencyField
          label="Default Currency"
          value={defaultCurrency.code}
          disabled
          onChange={() => {
            /* disabled */
          }}
        />
      )}
      <Box mt={2} />
      <AdditionalCurrenciesInput
        key={resetKey}
        currencies={formState}
        disabledCurrency={defaultCurrency?.code}
        onChange={handleChange}
      />
      <Fab
        centerRipple={true}
        onClick={handleAddCurrency}
        size="medium"
        color="primary"
      >
        <PlusCircleIcon color={BLUE_2} height={"24px"} width={"24px"} />
      </Fab>
      <Box mt={3} />
      <Box
        display="flex"
        justifyContent="flex-end"
        gridColumnGap={theme.spacing(2)}
      >
        <AssembleButton
          onClick={handleCancel}
          disabled={!isTouched}
          variant="outlined"
          size="medium"
          label="Cancel"
        />
        <SaveButton
          onSave={handleSubmit}
          disabled={!isTouched || !isFormValid(formState)}
        />
      </Box>
    </form>
  );
};
