import { makeStyles, Theme } from "@material-ui/core";
import { GRAY_1, GRAY_2, GRAY_3, GRAY_6, WHITE } from "../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  content: {
    left: "47.5%",
    paddingBottom: `${theme.spacing(6)}px`,
    position: "absolute",
    transform: "translate(-50%)",
    width: theme.spacing(67),
  },
  wideContent: {
    left: "50%",
    paddingBottom: `${theme.spacing(6)}px`,
    position: "absolute",
    transform: "translate(-50%)",
    marginLeft: theme.spacing(10),
  },
  divider: {
    borderBottom: `1px solid ${GRAY_6}`,
  },
  fullWidth: {
    width: "100%",
  },
  list: {
    listStyleType: "none",
    margin: "0px",
    padding: "0px",

    "& li": {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      marginBottom: theme.spacing(1),
    },
  },
  confirmationContainer: {
    backgroundColor: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "10px",
    padding: theme.spacing(3),
  },
  labelText: {
    color: GRAY_2,
  },
  valueText: {
    color: GRAY_1,
    fontWeight: 500,
  },
  commentText: {
    color: GRAY_3,
    whiteSpace: "pre-wrap",
  },
  spaceBetweenContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  jobCodeContainer: {
    display: "flex",
    gap: theme.spacing(1),
  },
}));
