import { makeStyles } from "@material-ui/core";
import AnalyzingOrgHierarchy from "src/assets/svgs/illustrations/analyzing-org-hierarchy.svg?react";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { COMP_CYCLE_HIERARCHY_CONFIGURATION_SIDEBAR_WIDTH } from "src/pages/CompCycle/HierarchyConfiguration/CompCycleHierarchyConfigurationSidebar";
import { GRAY_2, SIDEBAR_WIDTH } from "src/theme";
import { pixelsToNumber, remToPixels } from "src/utils";
import { AssembleTypography } from "../AssembleTypography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  orgHierarchyIcon: {
    display: "block",
    marginBottom: theme.spacing(6),
  },
}));

export function CompCycleOrganizationChartLoader(): JSX.Element {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  const offset =
    pixelsToNumber(SIDEBAR_WIDTH) +
    remToPixels(COMP_CYCLE_HIERARCHY_CONFIGURATION_SIDEBAR_WIDTH);

  return (
    <div className={classes.root} style={{ height, width: width - offset }}>
      <AnalyzingOrgHierarchy className={classes.orgHierarchyIcon} />
      <AssembleTypography textColor={GRAY_2} variant="productButtonMedium">
        Please sit tight for a moment...
      </AssembleTypography>
      <AssembleTypography textColor={GRAY_2} variant="productButtonMedium">
        we're analyzing your employee data to determine your organizational
        hierarchy.
      </AssembleTypography>
    </div>
  );
}
