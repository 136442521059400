import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const PhilosophyIcon = ({
  height = "24px",
  width = "24px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Philosophy"
      viewBox="0 0 32 32"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M12 19H18"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M12 15.5H18"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M12 22.5H18"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M28 21V24.5C28 25.2 27.7 25.8 27.3 26.3C26.8 26.8 26.2 27 25.5 27C24.8 27 24.2 26.7 23.7 26.3C23.2 25.8 23 25.2 23 24.5V21H28Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M20.5 5C19.8 5 19.2 5.3 18.7 5.7C18.3 6.2 18 6.8 18 7.5V11H4V7.5C4 6.8 4.3 6.2 4.7 5.7C5.2 5.3 5.8 5 6.5 5H20.5Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M20.5 5C19.8 5 19.2 5.3 18.7 5.7C18.3 6.2 18 6.8 18 7.5V11H7V13V24.5C7 25.2 7.3 25.8 7.7 26.3C8.2 26.7 8.8 27 9.5 27H25.5C24.8 27 24.2 26.7 23.7 26.3C23.2 25.8 23 25.2 23 24.5V13V11V7.5C23 6.8 22.7 6.2 22.3 5.7C21.8 5.3 21.2 5 20.5 5Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
