import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const ArrowRightIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      data-cy={props["data-cy"]}
      aria-label={props["aria-label"] ?? "Arrow Right"}
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M12.5 3L21.5 12L12.5 21"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M21.5 12C14 12 4 12 4 12"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </ColorableSVG>
);
