import { Drawer, makeStyles } from "@material-ui/core";
import { TRANSPARENT } from "../../../theme";
import { ActivityLogLoadingBoundary } from "./ActivityLogLoadingBoundary";

const useStyles = makeStyles(() => ({
  paper: {
    width: "29rem",
  },
  backdrop: {
    backgroundColor: TRANSPARENT,
  },
}));

type Props = {
  openedEmployeeId: number | null;
  compCycleId: number;
  onClose: () => unknown;
};

export function ActivityLogDrawer({
  openedEmployeeId,
  compCycleId,
  onClose,
}: Props): JSX.Element {
  const classes = useStyles();

  const isEmployeeOpened = openedEmployeeId !== null;

  return (
    <Drawer
      open={isEmployeeOpened}
      anchor="right"
      onClose={onClose}
      BackdropProps={{ className: classes.backdrop }}
      PaperProps={{ className: classes.paper }}
      id="activity-log-drawer"
    >
      {isEmployeeOpened && (
        <ActivityLogLoadingBoundary
          employeeId={openedEmployeeId}
          compCycleId={compCycleId}
          onClose={onClose}
        />
      )}
    </Drawer>
  );
}
