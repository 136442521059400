import { Box } from "@material-ui/core";
import FormHeader from "../../components/Settings/FormHeader";
import { Comparatios } from "./Comparatios";
import { Demographics } from "./Demographics";
import { LevelingBanner } from "./LevelingBanner";

export function PayEquity(): JSX.Element {
  return (
    <Box px={5} py={4} flex={1} display="flex" flexDirection="column">
      <FormHeader header="Pay Equity & Representation" />
      <LevelingBanner />
      <Demographics />
      <Comparatios />
    </Box>
  );
}
