import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { CheckIcon } from "src/components/AssembleIcons/Brand/CheckIcon";
import { CompCycleSettingsIcon } from "src/components/AssembleIcons/Brand/CompCycleSettingsIcon";
import { ImportIcon } from "src/components/AssembleIcons/Brand/ImportIcon";
import { PerformanceIcon } from "src/components/AssembleIcons/Brand/PerformanceIcon";
import { ThumbsUpIcon } from "src/components/AssembleIcons/Small/ThumbsUpIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import {
  GRAY_1,
  GRAY_4,
  GRAY_6,
  GREEN_2,
  SIDEBAR_WIDTH,
  WHITE,
} from "src/theme";
import Step from "../../Workflow/Step";
const useStyles = makeStyles((theme) => ({
  sidebar: {
    height: "100%",
    width: theme.spacing(31),

    position: "fixed",
    overflow: "auto",

    left: SIDEBAR_WIDTH,
    padding: theme.spacing(6, 2),
    zIndex: 10,

    backgroundColor: WHITE,
    boxShadow: `1px 0px 0px ${GRAY_6}`,

    borderLeft: `1px solid ${GRAY_6}`,
  },
  icon: {
    width: "1.5rem",
    height: "1.5rem",
    color: GRAY_4,

    "&:hover": {
      color: GRAY_1,
      opacity: 1,
    },
  },
  sidebarContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
  },
  sidebarHeader: {
    marginLeft: theme.spacing(2),
  },
}));

type Props = { step: number; updateStep: (step: number) => void };

export function CompCycleWizardSidebar({
  step,
  updateStep,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={clsx(classes.sidebar, classes.sidebarContainer)}>
      <AssembleTypography
        className={classes.sidebarHeader}
        variant="productEyebrow"
        textColor={GRAY_4}
      >
        Cycle Settings
      </AssembleTypography>
      <div>
        <Step
          icon={
            step > 0 ? (
              <CheckIcon color={GREEN_2} />
            ) : (
              <CompCycleSettingsIcon inherit />
            )
          }
          active={step === 0}
          onClick={() => (step > 0 ? updateStep(0) : null)}
          prevStep={step > 0}
        >
          General Settings
        </Step>
        <Step
          active={step === 1}
          icon={
            step > 1 ? (
              <CheckIcon color={GREEN_2} />
            ) : (
              <PerformanceIcon inherit />
            )
          }
          onClick={() => (step > 1 ? updateStep(1) : null)}
          prevStep={step > 1}
        >
          Performance Ratings
        </Step>
        <Step
          active={step === 2}
          icon={
            step > 2 ? <CheckIcon color={GREEN_2} /> : <ThumbsUpIcon inherit />
          }
          onClick={() => (step > 2 ? updateStep(2) : null)}
          prevStep={step > 2}
        >
          Approvals & Phases
        </Step>
        <Step
          active={step === 3}
          icon={
            step > 3 ? <CheckIcon color={GREEN_2} /> : <ImportIcon inherit />
          }
          onClick={() => (step > 3 ? updateStep(3) : null)}
          prevStep={step > 3}
        >
          Upload Requests
        </Step>
      </div>
    </div>
  );
}
