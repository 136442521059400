import { gql, useQuery } from "@apollo/client";
import { IconButton, makeStyles } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { ArrowLeftIcon } from "src/components/AssembleIcons/Brand/ArrowLeftIcon";
import {
  CompCycleHeaderQuery,
  CompCycleHeaderQueryVariables,
} from "../../../__generated__/graphql";
import {
  PAGE_HEADER_HEIGHT,
  PageHeader,
  PageTitle,
} from "../../../components/Layout/PageHeader";
import { GRAY_1, GRAY_4, GRAY_5 } from "../../../theme";
import { CompCycleSettingsButton } from "./CompCycleSettingsButton";
import { NavigationTabBar } from "./NavigationTabBar";

const useStyles = makeStyles((theme) => ({
  left: {
    display: "flex",
    height: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  backButton: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    border: `1px solid ${GRAY_5}`,
    boxSizing: "border-box",
    boxShadow: "0px 1px 3px rgba(10, 36, 64, 0.1)",
    borderRadius: "5px",
    marginRight: theme.spacing(2),
    padding: "0px",
  },
  tabBar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    width: "100%",
    height: "100%",
    gap: theme.spacing(0.5),
  },
  entered: {
    minHeight: PAGE_HEADER_HEIGHT,
  },
}));

export function CompCycleHeader(): JSX.Element | null {
  const classes = useStyles();
  const { compCycleId } = useParams<{ compCycleId: string }>();
  const cycleId = compCycleId != null ? parseInt(compCycleId, 10) : NaN;

  const { data } = useQuery<
    CompCycleHeaderQuery,
    CompCycleHeaderQueryVariables
  >(CompCycleHeader.query, { variables: { cycleId }, skip: isNaN(cycleId) });

  const hideTabs = !data;

  return (
    <>
      <PageHeader>
        <div className={classes.left}>
          <IconButton
            component={Link}
            to="/comp-cycles"
            className={classes.backButton}
          >
            <ArrowLeftIcon
              height="18px"
              width="18px"
              color={GRAY_4}
              hoverColor={GRAY_1}
            />
          </IconButton>
          <div className={classes.tabBar}>
            <PageTitle>
              {data?.compCycle ? data.compCycle.name : "Comp Cycle Overview"}
            </PageTitle>
            <NavigationTabBar hideTabs={hideTabs} compCycleId={cycleId} />
          </div>
        </div>
        <CompCycleSettingsButton compCycleId={cycleId} />
      </PageHeader>
      <CanduBar />
    </>
  );
}

function CanduBar() {
  return <div id="candu-bar" />;
}

CompCycleHeader.query = gql`
  query CompCycleHeaderQuery($cycleId: Int!) {
    compCycle(id: $cycleId) {
      id
      name
      endedAt
    }
    activeCompCycles {
      id
      endedAt
    }
  }
`;
