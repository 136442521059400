import { useEffect, useState } from "react";

type OS = "Windows" | "MacOS" | "Linux" | "iOS" | "Android" | "Unknown";

export function useDetectOS(): OS {
  const [os, setOS] = useState<OS>("Unknown");

  useEffect(() => {
    const userAgent = window.navigator.userAgent;

    if (/Windows NT/i.test(userAgent)) {
      setOS("Windows");
    } else if (/Mac/i.test(userAgent) && !/iPhone|iPad|iPod/i.test(userAgent)) {
      setOS("MacOS");
    } else if (/Linux/i.test(userAgent) && !/Android/i.test(userAgent)) {
      setOS("Linux");
    } else if (/Android/i.test(userAgent)) {
      setOS("Android");
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setOS("iOS");
    } else {
      setOS("Unknown");
    }
  }, []);

  return os;
}
