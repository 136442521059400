import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { PhaseAssignmentModal_employee as Employee } from "src/__generated__/graphql";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_1, GRAY_3, GRAY_4 } from "src/theme";

import { PhaseSelect } from "../PhaseDrawer/PhaseNumberComplexToggle";
import { usePhaseConfiguration } from "../usePhaseConfiguration";
import { getAssignedPhase } from "../utils";
import { EmployeeHierarchyNode } from "./utils";

const useStyles = makeStyles((theme) => ({
  indent: { marginLeft: theme.spacing(2.5) },
  header: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(0.5),
  },
}));

type Props = {
  level: number;
  employee: Employee;
  selectedPhase: PhaseSelect;
  reports: EmployeeHierarchyNode[];
};

export function EmployeeStep({
  employee,
  reports,
  selectedPhase,
  level,
}: Props): JSX.Element {
  const classes = useStyles();
  const { phaseConfiguration } = usePhaseConfiguration();
  const assignedPhase = getAssignedPhase(phaseConfiguration, employee);

  return (
    <div className={clsx({ [classes.indent]: level > 0 })}>
      {(selectedPhase === "all" ||
        (selectedPhase === "none" && assignedPhase == null) ||
        selectedPhase === assignedPhase) && (
        <div className={classes.header}>
          {level > 0 && (
            <AssembleTypography
              variant="productParagraphMedium"
              textColor={GRAY_3}
            >
              ---&nbsp;
            </AssembleTypography>
          )}
          <AssembleTypography
            variant="productParagraphMedium"
            textColor={assignedPhase != null ? GRAY_1 : GRAY_4}
          >
            {employee.displayName}&nbsp;-&nbsp;
          </AssembleTypography>
          <AssembleTypography
            variant="productParagraphMedium"
            textColor={assignedPhase != null ? GRAY_3 : GRAY_4}
          >
            {employee.activeEmployment?.jobTitle ?? "No Job Title"}
            &nbsp;-&nbsp;
          </AssembleTypography>
          <AssembleTypography
            variant="productParagraphMedium"
            textColor={assignedPhase != null ? GRAY_3 : GRAY_4}
          >
            {assignedPhase != null
              ? `Phase ${assignedPhase}`
              : "Not in a phase"}
          </AssembleTypography>
        </div>
      )}
      {reports.length > 0 && (
        <div>
          {reports.map((report) => (
            <EmployeeStep
              key={report.employee.id}
              level={level + 1}
              employee={report.employee}
              reports={report.reports}
              selectedPhase={selectedPhase}
            />
          ))}
        </div>
      )}
    </div>
  );
}
