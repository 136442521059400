import { gql } from "@apollo/client";
import { AvailablePrimaryRoleNames } from "@asmbl/shared/permissions";
import { mapify } from "@asmbl/shared/utils";
import { ISettings } from "@flatfile/adapter";
import { IDataHookResponse, ScalarDictionaryWithCustom } from "@flatfile/react";
import * as changeCase from "change-case";
import {
  UploadUsersWithGrantsButton_organization as Organization,
  PrimaryRoleName,
  UpsertUsersWithAccessGrantsVariables,
} from "../__generated__/graphql";
import { useUpsertUsersWithAccessGrants } from "../mutations/User";
import { AssembleButton } from "./AssembleButton/AssembleButton";
import { AssembleFlatfileButton } from "./AssembleFlatfileButton";

type Props = {
  organization: Organization;
};

type ExpectedData = {
  name: string;
  email: string;
  roleName: PrimaryRoleName;
  canViewCompBands: boolean;
  canViewGlobal: boolean;
  departmentIDs: string;
  ladderIDs: string;
  positionIDs: string;
};

const NUMBERED_LIST_REGEX = /^[0-9]*([,\s]*[0-9])*$/.source;

const SETTINGS: ISettings = {
  title: "Add users and access grants",
  type: "UploadUsersWithGrants",
  fullScreen: true,
  fields: [
    {
      label: "Name",
      key: "name",
      validators: [{ validate: "required" }],
    },
    {
      label: "Email",
      key: "email",
      validators: [{ validate: "required" }, { validate: "unique" }],
    },
    {
      label: "Role Name",
      key: "roleName",
      type: "select",
      description:
        "The user's primary role. This will replace their current role. ",
      options: AvailablePrimaryRoleNames.map((name) => ({
        label: changeCase.capitalCase(name),
        value: name,
      })),
      validators: [{ validate: "required" }],
    },
    {
      label: "Can View Comp Bands",
      key: "canViewCompBands",
      type: "checkbox",
      description: "Enable this to allow the user to see comp bands.",
      validators: [{ validate: "required" }],
    },
    {
      label: "Global Band Access",
      key: "canViewGlobal",
      type: "checkbox",
      description:
        "Enable this to allow the user to see ALL comp bands in the company.",
      validators: [{ validate: "required_with", fields: ["canViewCompBands"] }],
    },
    {
      label: "Department IDs",
      key: "departmentIDs",
      description:
        "A list of department IDs that the user can see comp bands for, separated by commas.",
      validators: [
        {
          validate: "regex_matches",
          regex: NUMBERED_LIST_REGEX,
          error: "Must be a comma-separated list of IDs",
        },
        {
          validate: "required_with_all_values",
          fieldValues: {
            canViewCompBands: true,
            canViewGlobal: false,
            departmentIDs: "",
            ladderIDs: "",
            positionIDs: "",
          },
        },
      ],
    },
    {
      label: "Ladder IDs",
      key: "ladderIDs",
      description:
        "A list of ladder IDs that the user can see comp bands for, separated by commas.",
      validators: [
        {
          validate: "regex_matches",
          regex: NUMBERED_LIST_REGEX,
          error: "Must be a comma-separated list of IDs",
        },
        {
          validate: "required_with_all_values",
          fieldValues: {
            canViewCompBands: true,
            canViewGlobal: false,
            departmentIDs: "",
            ladderIDs: "",
            positionIDs: "",
          },
        },
      ],
    },
    {
      label: "Position IDs",
      key: "positionIDs",
      description:
        "A list of position IDs that the user can see comp bands for, separated by commas.",
      validators: [
        {
          validate: "regex_matches",
          regex: NUMBERED_LIST_REGEX,
          error: "Must be a comma-separated list of IDs",
        },
        {
          validate: "required_with_all_values",
          fieldValues: {
            canViewCompBands: true,
            canViewGlobal: false,
            departmentIDs: "",
            ladderIDs: "",
            positionIDs: "",
          },
        },
      ],
    },
  ],
};

export function UploadUsersWithGrantsButton({
  organization,
}: Props): JSX.Element {
  const upsertUsers = useUpsertUsersWithAccessGrants();
  const validator = getValidator(organization);

  return (
    <AssembleFlatfileButton
      settings={SETTINGS}
      onRecordInit={validator}
      onRecordChange={validator}
      onData={async (results) => {
        const result = await upsertUsers(parseData(results.validData));
        const userUpdateCount =
          result.data?.upsertUsersWithAccessGrants.length ?? 0;
        document.body.classList.remove("flatfile-active");
        return `Successfully updated/added ${userUpdateCount} users along with new access grants.`;
      }}
      render={(_, launch) => (
        <AssembleButton
          onClick={launch}
          variant="contained"
          label="Bulk add users and access grants"
          size="medium"
        />
      )}
    />
  );
}

function getValidator(
  organization: Organization
): (data: ScalarDictionaryWithCustom, index: number) => IDataHookResponse {
  const existingUsersMap = mapify(organization.users, "email", "name");

  return (data) => {
    const expectedData = data as ExpectedData;
    const {
      name,
      email,
      roleName,
      canViewCompBands,
      canViewGlobal,
      departmentIDs,
      ladderIDs,
      positionIDs,
    } = expectedData;

    const invalidDepartmentIds = findMissingIds(
      organization.departments.map((d) => d.id),
      listToArray(departmentIDs)
    );
    const invalidLadderIds = findMissingIds(
      organization.departments.flatMap((d) => d.ladders).map((l) => l.id),
      listToArray(ladderIDs)
    );
    const invalidPositionIds = findMissingIds(
      organization.departments
        .flatMap((d) => d.ladders)
        .flatMap((l) => l.positions)
        .map((l) => l.id),
      listToArray(positionIDs)
    );

    return {
      name: {
        value: existingUsersMap.get(email) ?? name,
        info: existingUsersMap.has(name)
          ? [
              {
                message:
                  "This user already has a name and it will not be updated.",
                level: "info",
              },
            ]
          : undefined,
      },
      email: {
        value: email.toLocaleLowerCase(),
        info: existingUsersMap.has(email)
          ? [
              {
                message: "This will modify an existing user.",
                level: "warning",
              },
            ]
          : undefined,
      },
      canViewCompBands: {
        value: roleName === PrimaryRoleName.FULL_ACCESS || canViewCompBands,
        info:
          roleName === PrimaryRoleName.FULL_ACCESS
            ? [
                {
                  message: "Full Access can view all comp bands",
                  level: "info",
                },
              ]
            : undefined,
      },
      canViewGlobal: {
        value:
          roleName === PrimaryRoleName.FULL_ACCESS ||
          (canViewCompBands && canViewGlobal),
        info:
          roleName === PrimaryRoleName.FULL_ACCESS
            ? [
                {
                  message: "Full Access can view all comp bands",
                  level: "info",
                },
              ]
            : undefined,
      },
      departmentIDs: {
        value: canViewGlobal ? "" : departmentIDs,
        info:
          invalidDepartmentIds.length > 0
            ? [
                {
                  message: `Invalid IDs: ${invalidDepartmentIds.join(", ")}`,
                  level: "error",
                },
              ]
            : undefined,
      },
      ladderIDs: {
        value: canViewGlobal ? "" : ladderIDs,
        info:
          invalidLadderIds.length > 0
            ? [
                {
                  message: `Invalid IDs: ${invalidLadderIds.join(", ")}`,
                  level: "error",
                },
              ]
            : undefined,
      },
      positionIDs: {
        value: canViewGlobal ? "" : positionIDs,
        info:
          invalidPositionIds.length > 0
            ? [
                {
                  message: `Invalid IDs: ${invalidPositionIds.join(", ")}`,
                  level: "error",
                },
              ]
            : undefined,
      },
    };
  };
}

function parseData(data: unknown[]): UpsertUsersWithAccessGrantsVariables {
  const castedData = data as ExpectedData[];
  const users = castedData.map((d) => ({
    email: d.email,
    name: d.name,
    roleName: d.roleName,
    compBandAccess: d.canViewCompBands
      ? {
          global: d.canViewGlobal,
          departmentIDs: listToArray(d.departmentIDs),
          ladderIDs: listToArray(d.ladderIDs),
          positionIDs: listToArray(d.positionIDs),
        }
      : null,
  }));
  return { users };
}

function listToArray(list: string): number[] {
  if (list.trim() === "") return [];
  return list.split(",").map((c) => parseInt(c));
}

function findMissingIds(existingIds: number[], newIds: number[]) {
  return newIds.reduce<number[]>(
    (acc, cur) => (existingIds.includes(cur) ? acc : acc.concat(cur)),
    []
  );
}

UploadUsersWithGrantsButton.fragments = {
  organization: gql`
    fragment UploadUsersWithGrantsButton_organization on Organization {
      id
      users {
        id
        email
        name
      }
      departments {
        id
        ladders {
          id
          positions {
            id
          }
        }
      }
    }
  `,
};
