import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { GRAY_2 } from "src/theme";
import { AssembleButton } from "../../AssembleButton/AssembleButton";
import { AssembleTypography } from "../../AssembleTypography";
import { DatePickerButton } from "../../DatePickerButton";

const useStyles = makeStyles((theme) => ({
  contentRoot: {
    paddingBottom: theme.spacing(1),
  },
  content: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  contentCopy: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
}));

type Props = {
  isOpen: boolean;
  date: Date | undefined;
  setDate: (newDate: Date | undefined) => void;
  onDateSave: () => void;
  initDateSelectorOpenValue?: boolean;
};

export function PhaseEndDateModal({
  isOpen,
  date,
  setDate,
  onDateSave,
  initDateSelectorOpenValue,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} maxWidth="xs">
      <DialogTitle>
        <AssembleTypography variant="productHeader">
          Select Completion Date
        </AssembleTypography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentRoot }}>
        <DialogContentText>
          <div className={classes.contentCopy}>
            <AssembleTypography variant="productParagraphLarge">
              When should this comp cycle conclude?
            </AssembleTypography>
            <AssembleTypography
              variant="productParagraphMedium"
              fontWeight={700}
              textColor={GRAY_2}
            >
              (Note: this is when reviewers will no longer be able to make
              recommendations for their employees)
            </AssembleTypography>
          </div>
        </DialogContentText>
        <div className={classes.content}>
          <DatePickerButton
            title="Cycle end date"
            errorOnEmpty={false}
            fullWidth
            value={date}
            onChange={setDate}
            zIndex={9999}
            disablePast
            initOpenValue={initDateSelectorOpenValue}
            clearable={false}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <AssembleButton
          size="small"
          variant="contained"
          label="Save cycle and end date"
          onClick={onDateSave}
          disabled={!date}
        />
      </DialogActions>
    </Dialog>
  );
}
