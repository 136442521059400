import {
  CompCyclePhaseData,
  PhaseConfigurationData,
} from "../usePhaseConfiguration";

export function getParticipantCount(currentPhase: CompCyclePhaseData) {
  return currentPhase.assigneeIds.length;
}

export function getPhaseEndDate(
  phaseConfiguration: PhaseConfigurationData,
  currentPhase: CompCyclePhaseData,
  endDate: Date
) {
  const nextPhase = phaseConfiguration.find(
    ({ phaseOrder }) => phaseOrder === currentPhase.phaseOrder + 1
  );

  // if we don't have a next phase, then we will use the end
  // date of the cycle
  return !nextPhase ? endDate : nextPhase.startDate;
}
