import { gql } from "@apollo/client";
import { byDate } from "@asmbl/shared/sort";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";
import { GRAY_1, GRAY_6, theme } from "../../../../theme";
import { ArrayValue } from "../../../../utils";
import { EmployeeEmployment_employee as Employee } from "../../../../__generated__/graphql";
import { EmploymentAccordion } from "./EmploymentAccordion";
import { EmploymentCarousel } from "./EmploymentCarousel";

interface Props {
  employee: Employee;
}
type Employment = ArrayValue<Employee["employments"]>;

const useStyles = makeStyles(() => ({
  headerText: {
    color: GRAY_1,
    marginBottom: theme.spacing(3),
  },
  accordionContainer: {
    borderRadius: "5px",
    overflow: "hidden",
    borderTop: `1px solid ${GRAY_6}`,
    borderLeft: `1px solid ${GRAY_6}`,
    borderRight: `1px solid ${GRAY_6}`,
  },
}));

export const EmployeeEmployment = ({ employee }: Props): JSX.Element => {
  const classes = useStyles();
  const [selected, setSelected] = useState<number | undefined>(
    employee.employments.at(0)?.id
  );

  const chronologicalEmployments = employee.employments
    .slice()
    .sort(byDate((e) => e.activeAt));

  return (
    <Box ml={4} mt={5} mr={3}>
      <Typography variant="h5" className={classes.headerText}>
        Employment History
      </Typography>
      <Box mb={3} display="flex" justifyContent="center">
        {chronologicalEmployments.length > 0 && (
          <EmploymentCarousel
            employments={chronologicalEmployments}
            onSelect={setSelected}
            selected={selected}
          />
        )}
      </Box>
      <Box className={classes.accordionContainer}>
        {employee.employments.map((employment: Employment) => {
          return (
            <EmploymentAccordion
              employment={employment}
              onSelect={setSelected}
              selected={selected}
              key={employment.id}
            />
          );
        })}
      </Box>
    </Box>
  );
};

EmployeeEmployment.fragments = {
  employee: gql`
    ${EmploymentCarousel.fragments.employment}
    ${EmploymentAccordion.fragments.employment}
    fragment EmployeeEmployment_employee on Employee {
      id
      employments {
        id
        ...EmploymentCarousel_employment
        ...EmploymentAccordion_employment
      }
    }
  `,
};
