import { AssembleTypography } from "src/components/AssembleTypography";
import { StyledBlockButton } from "../StyledBlockButton/StyledBlockButton";

const ITALIC = "ITALIC";

export type ItalicStyleProps = {
  editorState: Draft.EditorState;
  onClick: () => unknown;
};

export const ItalicStyle = (props: ItalicStyleProps): JSX.Element => {
  const { editorState, onClick } = props;
  const italicIsActive = editorState.getCurrentInlineStyle().has(ITALIC);

  return (
    <StyledBlockButton active={italicIsActive} onClick={onClick}>
      <AssembleTypography style={{ fontSize: "18px", fontStyle: "italic" }}>
        I
      </AssembleTypography>
    </StyledBlockButton>
  );
};
