import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import {
  SupportedManagersQuery,
  SupportedManagersQueryVariables,
} from "src/__generated__/graphql";
import { SupportedManagersDrawer } from "../../UserAccessControl/SupportedManagers/SupportedManagersDrawer";

type Props = {
  userName: string;
  onSave: (employeeIds: number[]) => void;
  supportedManagerIds: number[] | null;
};

export function SupportedManagersLoadingBoundary({
  userName,
  onSave,
  supportedManagerIds,
}: Props) {
  const { data, loading } = useQuery<
    SupportedManagersQuery,
    SupportedManagersQueryVariables
  >(SupportedManagersLoadingBoundary.query, { fetchPolicy: "no-cache" });

  if (data == null) {
    return loading ? (
      <CircularProgress />
    ) : (
      <div>Error loading supported managers data</div>
    );
  }

  return (
    <SupportedManagersDrawer
      userName={userName}
      supportedManagerIds={supportedManagerIds ?? []}
      managers={data.managers}
      handleSave={onSave}
    />
  );
}

SupportedManagersLoadingBoundary.query = gql`
  ${SupportedManagersDrawer.fragments.manager}
  query SupportedManagersInviteQuery {
    managers {
      ...SupportedManagersDrawer_manager
    }
  }
`;
