import { FeatureFlag } from "@asmbl/shared/feature-flags";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { ChangeEvent } from "react";
import { CashCompType, PayPeriodType } from "src/__generated__/graphql";
import { AssembleTypography } from "src/components/AssembleTypography";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { AdjustSettingsContainer } from "src/components/Table/AdjustSettingsContainer";
import { EquityDisplayType } from "src/components/Table/EquityDisplayCell";
import { ALL_CASH_COMP_OPTIONS, TOTAL_CASH_OPTION } from "src/constants";
import { GRAY_4, PURPLE_1, theme } from "src/theme";

type PeoplePageSettingsContainerProps = {
  onCompComponentChange: (event: ChangeEvent<HTMLInputElement>) => unknown;
  compComponentSelection: Set<CashCompType>;
  showEquityGrants: boolean;
  equityDisplayUnitType: EquityDisplayType;
  onEquityToggleChange: (value: EquityDisplayType | null) => unknown;
  onPayTypeToggleChange: (value: PayPeriodType) => unknown;
  payTypeSelection: PayPeriodType;
};

const useStyles = makeStyles(() => ({
  popOver: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
    maxWidth: "250px",
    borderRadius: "5px",
    border: `2px solid ${PURPLE_1}`,
    boxSizing: "border-box",
    filter: "drop-shadow(0px 15px 25px rgba(10, 36, 64, 0.08))",
  },
  popOverSection: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
  },
  popOverText: {
    color: GRAY_4,
  },
  group: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(2),
  },
  radioGroup: {
    display: "flex",
    marginLeft: theme.spacing(1.5),
  },
  radioButtonMargin: {
    marginLeft: theme.spacing(3),
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  checkbox: {
    minWidth: 0,
    marginRight: theme.spacing(1),
  },
  radioButton: {
    marginRight: theme.spacing(0.5),
    border: "none !important",
  },
}));

export function PeopleSettingsContainer({
  onCompComponentChange,
  compComponentSelection,
  showEquityGrants,
  equityDisplayUnitType,
  onEquityToggleChange,
  onPayTypeToggleChange,
  payTypeSelection,
}: PeoplePageSettingsContainerProps): JSX.Element {
  const classes = useStyles();
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const showHourlyCompOption =
    isEnabled(FeatureFlag.HourlyEmployees) &&
    compStructure?.allowHourlyEmployees === true;

  return (
    <AdjustSettingsContainer
      context={
        <div className={classes.popOver}>
          <div className={classes.popOverSection}>
            <AssembleTypography
              className={classes.popOverText}
              variant="productEyebrowSmall"
            >
              View Cash Comp Components As
            </AssembleTypography>
            <List disablePadding classes={{ root: classes.listItem }}>
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.checkbox}>
                  <Checkbox
                    id="component-checkbox-total-cash"
                    edge="start"
                    onChange={onCompComponentChange}
                    checked={
                      // includes salary, commission, recurring bonus
                      compComponentSelection.size ===
                      ALL_CASH_COMP_OPTIONS.length
                    }
                    value={TOTAL_CASH_OPTION}
                    indeterminate={
                      compComponentSelection.size > 0 &&
                      compComponentSelection.size < ALL_CASH_COMP_OPTIONS.length
                    }
                    size="small"
                  />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <AssembleTypography variant="productParagraphMedium">
                    <label htmlFor="component-checkbox-total-cash">
                      Total Cash
                    </label>
                  </AssembleTypography>
                </ListItemText>
              </ListItem>
              <Box className={classes.group}>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.checkbox}>
                    <Checkbox
                      id="component-checkbox-market"
                      edge="start"
                      onChange={onCompComponentChange}
                      checked={compComponentSelection.has(CashCompType.SALARY)}
                      value={CashCompType.SALARY}
                      size="small"
                    />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    <AssembleTypography variant="productParagraphMedium">
                      <label htmlFor="component-checkbox-market">Salary</label>
                    </AssembleTypography>
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.checkbox}>
                    <Checkbox
                      id="component-checkbox-commission"
                      edge="start"
                      onChange={onCompComponentChange}
                      checked={compComponentSelection.has(
                        CashCompType.COMMISSION
                      )}
                      value={CashCompType.COMMISSION}
                      size="small"
                    />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    <AssembleTypography variant="productParagraphMedium">
                      <label htmlFor="component-checkbox-commission">
                        Commission
                      </label>
                    </AssembleTypography>
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.checkbox}>
                    <Checkbox
                      id="component-checkbox-promotion"
                      edge="start"
                      onChange={onCompComponentChange}
                      checked={compComponentSelection.has(
                        CashCompType.RECURRING_BONUS
                      )}
                      value={CashCompType.RECURRING_BONUS}
                      size="small"
                    />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    <AssembleTypography variant="productParagraphMedium">
                      <label htmlFor="component-checkbox-promotion">
                        Recurring Bonus
                      </label>
                    </AssembleTypography>
                  </ListItemText>
                </ListItem>
              </Box>
            </List>
          </div>
          {showHourlyCompOption && (
            <div className={classes.popOverSection}>
              <AssembleTypography
                className={classes.popOverText}
                variant="productEyebrowSmall"
              >
                Show hourly comp as
              </AssembleTypography>
              <FormControl
                className={classes.radioGroup}
                disabled={
                  compComponentSelection.has(CashCompType.RECURRING_BONUS) ||
                  compComponentSelection.has(CashCompType.COMMISSION) ||
                  compComponentSelection.size === 0
                }
              >
                <Tooltip
                  title={
                    !(
                      compComponentSelection.has(
                        CashCompType.RECURRING_BONUS
                      ) ||
                      compComponentSelection.has(CashCompType.COMMISSION) ||
                      compComponentSelection.size === 0
                    )
                      ? ""
                      : "Hourly comp is annualized when viewing comp components that are both hourly and annual."
                  }
                >
                  <RadioGroup
                    row
                    value={payTypeSelection}
                    onChange={(_e, value) =>
                      onPayTypeToggleChange(value as PayPeriodType)
                    }
                    className={classes.radioGroup}
                  >
                    <FormControlLabel
                      value={PayPeriodType.HOURLY}
                      label={
                        <AssembleTypography variant="productParagraphMedium">
                          Hourly
                        </AssembleTypography>
                      }
                      control={
                        <Radio className={classes.radioButton} size="small" />
                      }
                    />
                    <div className={classes.radioButtonMargin} />
                    <FormControlLabel
                      value={PayPeriodType.ANNUAL}
                      label={
                        <AssembleTypography variant="productParagraphMedium">
                          Annual
                        </AssembleTypography>
                      }
                      control={
                        <Radio className={classes.radioButton} size="small" />
                      }
                    />
                  </RadioGroup>
                </Tooltip>
              </FormControl>
            </div>
          )}
          {showEquityGrants && (
            <div className={classes.popOverSection}>
              <AssembleTypography
                className={classes.popOverText}
                variant="productEyebrowSmall"
              >
                Show Equity As
              </AssembleTypography>
              <FormControl className={classes.radioGroup}>
                <RadioGroup
                  row
                  value={equityDisplayUnitType}
                  onChange={(_e, value) =>
                    onEquityToggleChange(value as EquityDisplayType)
                  }
                  className={classes.radioGroup}
                >
                  <FormControlLabel
                    value="units"
                    label={
                      <AssembleTypography variant="productParagraphMedium">
                        Units
                      </AssembleTypography>
                    }
                    control={
                      <Radio className={classes.radioButton} size="small" />
                    }
                  />
                  <div className={classes.radioButtonMargin} />
                  <FormControlLabel
                    value="value"
                    label={
                      <AssembleTypography variant="productParagraphMedium">
                        Cash Value
                      </AssembleTypography>
                    }
                    control={
                      <Radio className={classes.radioButton} size="small" />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
          )}
        </div>
      }
    />
  );
}
