import { Grid, makeStyles, RadioGroup } from "@material-ui/core";
import { InfoIcon } from "src/components/AssembleIcons/Small/InfoIcon";
import {
  AnonymizedCompensationStatisticsAccessType as AccessType,
  PermissionSettingsInput,
} from "../../../__generated__/graphql";
import { useTrack } from "../../../analytics";
import { PermissionSettings } from "../../../mutations/PermissionSettings";
import { GRAY_4 } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";
import { ExplanatoryTooltip } from "../../ExplanatoryTooltip";
import { IdAnchor } from "../../Layout/IdAnchor";
import { PolicyCard } from "./PolicyChoices/PolicyCard";

const useStyles = makeStyles((theme) => ({
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(0.5),
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  icon: {
    width: "1rem",
    height: "1rem",
  },
  radioGroupContainer: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  sectionRef: (node?: Element | null | undefined) => void;
  current: PermissionSettings;
  handleAccessChange: (
    data: PermissionSettingsInput,
    current: PermissionSettings
  ) => Promise<void>;
  anonymizedCompensationAccessType: AccessType;
};

export function AnonymizedCompensationPolicySection({
  sectionRef,
  current,
  handleAccessChange,
  anonymizedCompensationAccessType,
}: Props): JSX.Element {
  const classes = useStyles();
  const { trackEvent } = useTrack();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const accessType = e.target.value as AccessType;
    trackEvent({
      object: "Anonymized Comp",
      action: "Policy Changed",
      anonymizedCompPolicy: accessType,
      previousAnonymizedCompPolicy: anonymizedCompensationAccessType,
    });
    void handleAccessChange(
      { anonymizedCompensationStatisticsAccess: accessType },
      current
    );
  };

  return (
    <IdAnchor
      id="anonymized-employee-compensation"
      ref={sectionRef}
      scrollOffset="small"
    >
      <div className={classes.descriptionContainer}>
        <div className={classes.header}>
          <AssembleTypography variant="productSectionHeader">
            Anonymized Employee Compensation
          </AssembleTypography>
          <ExplanatoryTooltip
            title="What is anonymized data?"
            placement="top"
            body={
              <>
                Assemble can show anonymized data for&nbsp;
                <b>bands with at least the minimum number of employees.</b>
              </>
            }
            newTab
            ctaUrl="//help.assemble.inc/en/articles/5550372-changing-your-access-policies#h_11b3bbf9ad"
          >
            <div>
              <InfoIcon color={GRAY_4} />
            </div>
          </ExplanatoryTooltip>
        </div>
        <AssembleTypography variant="formDescription">
          Who should be able to see anonymized employee cash compensation
          visualized against compensation bands?
        </AssembleTypography>
      </div>
      <RadioGroup
        className={classes.radioGroupContainer}
        onChange={handleChange}
        value={anonymizedCompensationAccessType}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <PolicyCard
              isDefault
              accessType={anonymizedCompensationAccessType}
              value={AccessType.FULL_ACCESS}
              header="People with Full Access"
              bulletPoints={[
                <>
                  People assigned the <b>Full Access</b> role can see anonymized
                  employee data for all positions.
                </>,
              ]}
              subtext="Use this to preview the anonymized data before enabling it for your team."
            />
          </Grid>
          <Grid item xs={4}>
            <PolicyCard
              accessType={anonymizedCompensationAccessType}
              value={AccessType.COMP_BAND_ACCESS}
              header="Compensation Band Viewers"
              bulletPoints={[
                <>
                  People assigned the <b>Full Access</b> role can see anonymized
                  employee data for all positions.
                </>,
                <>
                  If you give other users access to view compensation bands for
                  specific departments, ladders, or positions, they can also
                  view anonymized employee data for those bands.
                </>,
              ]}
            />
          </Grid>
        </Grid>
      </RadioGroup>
    </IdAnchor>
  );
}
