import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const PermissionsIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Permissions"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M8 12.5C9.38071 12.5 10.5 11.3807 10.5 10C10.5 8.61929 9.38071 7.5 8 7.5C6.61929 7.5 5.5 8.61929 5.5 10C5.5 11.3807 6.61929 12.5 8 12.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M13.5 5H2.5C2.22386 5 2 5.22386 2 5.5V14.5C2 14.7761 2.22386 15 2.5 15H13.5C13.7761 15 14 14.7761 14 14.5V5.5C14 5.22386 13.7761 5 13.5 5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M3.96875 14.9999C4.34148 14.2492 4.91642 13.6174 5.62881 13.1757C6.3412 12.734 7.16276 12.5 8.00096 12.5C8.83916 12.5 9.66072 12.734 10.3731 13.1757C11.0855 13.6174 11.6604 14.2492 12.0332 14.9999"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M5.75 4.5V3.25C5.75003 2.67492 5.97024 2.12169 6.36542 1.7039C6.76059 1.28611 7.30073 1.03548 7.87491 1.00349C8.44909 0.971492 9.01374 1.16055 9.45288 1.53185C9.89203 1.90314 9.93644 1.92849 10.0004 2.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
