import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { CompItemRecommendationTypeTitle } from "../../../models/CompRecommendation";
import { GRAY_5 } from "../../../theme";
import {
  ReviewRequestStatus,
  SubmittedRecommendationCard_activeEmployment as ActiveEmployment,
  SubmittedRecommendationCard_cashCompensation,
  SubmittedRecommendationCard_perfRating as PerfRating,
  SubmittedRecommendationCard_submittedItem as SubmittedItem,
} from "../../../__generated__/graphql";
import { ActualVariablePayChange } from "./ActualVariablePayChange";
import { EquityChange } from "./EquityChange";
import { PerfRatingCallout } from "./PerfRatingCallout";
import { PositionChange } from "./PositionChange";
import { SubmittedCardHeader } from "./SubmittedCardHeader";
import { SubmittedCardNote } from "./SubmittedCardNote";
import { TotalTargetCashChange } from "./TotalTargetCashChange";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
  },
  body: {
    paddingLeft: theme.spacing(3),
  },
  compComponentContainer: {
    marginBottom: theme.spacing(2),

    "&:not(:last-of-type)": {
      paddingBottom: theme.spacing(2),
      borderBottom: `1px dashed ${GRAY_5}`,
    },
  },
  note: {
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  submittedItems: SubmittedItem[];
  activeEmployment: ActiveEmployment | null;
  cashCompensations: SubmittedRecommendationCard_cashCompensation[];
  perfRating: PerfRating | null;
};

export function SubmittedRecommendationCard({
  submittedItems,
  activeEmployment,
  cashCompensations,
  perfRating,
}: Props): JSX.Element {
  const classes = useStyles();

  const author = submittedItems[0].author;
  const submittedAt = new Date(submittedItems[0].submittedAt as string);

  return (
    <div className={classes.root}>
      <SubmittedCardHeader
        author={author}
        submittedAt={submittedAt}
        status={ReviewRequestStatus.AWAITING_REVIEW}
        hasPromotion={submittedItems.some(
          (item) => item.recommendedPosition !== null
        )}
      />
      <div className={classes.body}>
        <SubmittedCardNote
          className={classes.note}
          notes={submittedItems.map((item) => ({
            title: CompItemRecommendationTypeTitle[item.recommendationType],
            text: item.note,
          }))}
        />
        <PerfRatingCallout perfRating={perfRating} />
        <PositionChange
          employment={activeEmployment}
          recItems={submittedItems}
        />
        <TotalTargetCashChange
          cashCompensations={cashCompensations}
          recItems={submittedItems}
        />
        <ActualVariablePayChange
          cashCompensations={cashCompensations}
          recItems={submittedItems}
        />
        <EquityChange recItems={submittedItems} />
      </div>
    </div>
  );
}

SubmittedRecommendationCard.fragments = {
  submittedItem: gql`
    ${SubmittedCardHeader.fragments.author}
    ${PositionChange.fragments.recItem}
    ${TotalTargetCashChange.fragments.recItem}
    ${ActualVariablePayChange.fragments.recItem}
    fragment SubmittedRecommendationCard_submittedItem on RecItem {
      recommendationType
      createdAt
      note
      submittedAt
      recommendedCashValue
      recommendedEquityUnitCount
      ...PositionChange_recItem
      ...TotalTargetCashChange_recItem
      ...ActualVariablePayChange_recItem
      recommendedPosition {
        id
      }
      author {
        id
        ...SubmittedCardHeader_author
      }
    }
  `,
  activeEmployment: gql`
    ${PositionChange.fragments.employment}
    fragment SubmittedRecommendationCard_activeEmployment on Employment {
      ...PositionChange_employment
    }
  `,
  cashCompensation: gql`
    ${TotalTargetCashChange.fragments.cashCompensation}
    ${ActualVariablePayChange.fragments.cashCompensation}
    fragment SubmittedRecommendationCard_cashCompensation on CashCompensation {
      ...TotalTargetCashChange_cashCompensation
      ...ActualVariablePayChange_cashCompensation
    }
  `,
  perfRating: gql`
    ${PerfRatingCallout.fragments.perfRating}
    fragment SubmittedRecommendationCard_perfRating on EmployeeCompCyclePerfRating {
      ...PerfRatingCallout_perfRating
    }
  `,
};
