import { gql } from "@apollo/client";
import {
  ScalarDictionaryWithCustom as FlatFileRecord,
  IDataHookResponse,
} from "@flatfile/react";
import { Button, makeStyles } from "@material-ui/core";
import { AssembleFlatfileButton } from "src/components/AssembleFlatfileButton";
import { EmployeeIdentifierFields } from "src/models/csv/Fields/EmployeeIdentifierFields";
import { EquityVestingImportButton_employee as Employee } from "../../../__generated__/graphql";
import { useTrack } from "../../../analytics";
import { DATE_REGEX } from "../../../constants";
import { matchEmployee } from "../../../models/csv/EmployeeCSV";
import { isFieldPresent } from "../../../models/csv/common";
import { PURPLE_1 } from "../../../theme";

const useStyles = makeStyles(() => ({
  textButton: {
    color: PURPLE_1,
    padding: 0,
  },
}));

export type EquityGrantVestingEventRow = {
  grantName: string;
  vestingDate: string;
  unitCount: string;
  employeeId: string;
};

export interface Props {
  employees: Employee[];
  lastUploadDate: GraphQL_DateTime | null;
  disabled: boolean;
  handleChange: (data: Array<EquityGrantVestingEventRow>) => unknown;
  renderAsLink?: boolean;
  isDialogButton?: boolean;
}

export function EquityVestingImportButton({
  employees,
  disabled,
  lastUploadDate,
  renderAsLink = false,
  handleChange,
  isDialogButton = false,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const recordValidator = (record: FlatFileRecord) =>
    validateRecord(record, employees);

  const classes = useStyles();

  return (
    <AssembleFlatfileButton
      settings={{
        title: "Import your employees' equity grant vesting events",
        type: "EmployeeEquityVestingEventRow",
        fields: [
          ...EmployeeIdentifierFields({ allowDuplicates: true }),
          {
            label: "Grant Name",
            key: "grantName",
            validators: [{ validate: "required" }],
            alternates: ["Certificate Number"],
          },
          {
            label: "Unit Count",
            key: "unitCount",
            validators: [
              { validate: "required" },
              {
                validate: "regex_matches",
                regex: "^(\\d+|\\d{1,3}(,\\d{3})*)?$",
                error: "Input must be a number",
              },
            ],
            alternates: ["Vested and recognized", "Quantity"],
          },
          {
            label: "Vesting Date",
            key: "vestingDate",
            validators: [
              { validate: "required" },
              {
                validate: "regex_matches",
                regex: DATE_REGEX.source,
                error: "Must be in format YYYY-MM-DD or MM/DD/YYYY",
              },
            ],
          },
        ],
        managed: true,
      }}
      preload={false}
      onRecordInit={recordValidator}
      onRecordChange={recordValidator}
      onData={(results: { validData: EquityGrantVestingEventRow[] }) => {
        try {
          handleChange(results.validData);
        } catch (e) {
          if (e instanceof Error) {
            return Promise.resolve(
              `An error occurred: ${e.message}. Please try again.`
            );
          }
        }
        return Promise.resolve(
          `Uploaded ${results.validData.length} employee equity grant vesting events.`
        );
      }}
      render={(_, launch) => {
        return renderAsLink ? (
          <Button
            onClick={() => {
              trackEvent({
                area: "Data Management",
                object: "Import Employee Vesting Events Button",
                action: "Clicked",
                uploadType: "Import",
              });
              launch();
            }}
            variant="text"
            className={classes.textButton}
          >
            Import equity vesting details.
          </Button>
        ) : (
          <Button
            onClick={() => {
              trackEvent({
                area: isDialogButton ? "Data Management" : "Employee Portal",
                subArea: !isDialogButton ? "Portal Access" : undefined,
                object: "Import Employee Vesting Events Button",
                action: "Clicked",
                uploadType: lastUploadDate == null ? "Import" : "Update",
              });
              launch();
            }}
            variant="contained"
            color={lastUploadDate === null ? "primary" : undefined}
            disabled={disabled}
          >
            {lastUploadDate === null
              ? "Import Equity Vesting"
              : "Update Equity Vesting"}
          </Button>
        );
      }}
    />
  );
}

// Combine all of our validators together into a single response object.
// Beware: validators can overwrite each other, so try to keep fields separate!
function validateRecord(
  record: FlatFileRecord,
  employees: Employee[]
): IDataHookResponse {
  const [employee, employeeMatchingInfo] = matchEmployee(record, employees);
  const grantMatchingInfo = matchEquityGrant(record, employee);
  return {
    ...employeeMatchingInfo,
    ...grantMatchingInfo,
  };
}

function matchEquityGrant(
  record: FlatFileRecord,
  employee: Employee | null
): IDataHookResponse | null {
  if (!employee || !isFieldPresent(record.grantName)) {
    return null;
  }

  const grantMatch = employee.equityHoldings.grants?.find(
    (grant) => grant.name === record.grantName
  );

  if (
    grantMatch &&
    isFieldPresent(record.unitCount) &&
    grantMatch.unitCount < parseInt(record.unitCount, 10)
  ) {
    return {
      unitCount: {
        info: [
          {
            message: `The vesting unit count exceeds the total unit count (${grantMatch.unitCount}) for this grant.`,
            level: "error",
          },
        ],
      },
    };
  }

  return grantMatch
    ? null
    : {
        grantName: {
          info: [
            {
              message: "No matching grant was found for this employee.",
              level: "error",
            },
          ],
        },
      };
}

EquityVestingImportButton.fragments = {
  employee: gql`
    fragment EquityVestingImportButton_employee on Employee {
      id
      employeeNumber
      email
      personalEmail
      equityHoldings {
        id
        grants {
          id
          name
          unitCount
        }
      }
    }
  `,
  organization: gql`
    fragment EquityVestingImportButton_organization on Organization {
      id
      lastEquityVestingEventsUploadDate
    }
  `,
};
