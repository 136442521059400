import { CashBandType, CurrencyCode } from "@asmbl/shared/constants";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { Money, add, isZero, zero } from "@asmbl/shared/money";
import { memo, useMemo } from "react";
import { GetOffer } from "../../__generated__/graphql";
import { CashBandCandidateDisplayName, CashBandName } from "../../constants";
import { getAnnualBenefitsValue } from "../../models/Offer";
import { BLUE_2, GRAY_1, GRAY_2, GRAY_4, GRAY_5 } from "../../theme";
import { NonNull } from "../../utils";
import { useCurrencies } from "../CurrenciesContext";
import Header from "./Header";
import { LegendItem } from "./Legend/Legend";
import { CompBreakdown } from "./OfferFrames";
import Visualization from "./Visualization";
import { SeriesDataPoint } from "./Visualization/Visualization";

type CompStructure = NonNull<GetOffer["compStructure"]>;
type Offer = GetOffer["offer"];
type OfferConfig = NonNull<GetOffer["offerConfig"]>;
type Valuation = NonNull<GetOffer["valuation"]>;

type BreakdownProps = {
  data: CompBreakdown;
  offer: Offer;
  projections: Money[];
  valuation: Valuation;
  compStructure: CompStructure;
  offerConfig: OfferConfig;
  xAxisAnnotations: string[][];
  localCurrency: Currency<CurrencyCode>;
  equityCurrency: Currency<CurrencyCode>;
};

const Breakdown = ({
  data,
  offer,
  projections,
  valuation,
  compStructure,
  offerConfig,
  xAxisAnnotations,
  localCurrency,
  equityCurrency,
}: BreakdownProps) => {
  const color = offerConfig.brandColorPrimary ?? BLUE_2;
  const { currencies } = useCurrencies();

  // -- Gen chart data -----
  const barData: SeriesDataPoint[] = [];
  for (const fixed of data.cash[CashBandType.FIXED_CASH]) {
    barData.push({
      name: CashBandCandidateDisplayName[fixed.name],
      type: "bar",
      color: fixed.name === CashBandName.SALARY ? GRAY_2 : GRAY_4,
      data: Array<number>(projections.length).fill(fixed.money.value),
    });
  }

  const variableCash = data.cash[CashBandType.VARIABLE_CASH]
    .map((comp) => comp.money)
    .reduce(add, zero(data.maxProjected.currency));

  if (variableCash.value > 0)
    barData.push({
      name: "Variable Cash",
      type: "bar",
      color: GRAY_5,
      data: Array<number>(projections.length).fill(variableCash.value),
    });

  if (data.equity?.projectedAnnualValues)
    barData.push({
      name: "Equity",
      type: "bar",
      color: color,
      data: data.equity.projectedAnnualValues
        .map((v) => exchangeFromTo(v, equityCurrency, localCurrency))
        .map((m) => m.value),
    });

  // Benefits
  const benefitsValueInOfferCurrency = useMemo(
    () =>
      getAnnualBenefitsValue(currencies, {
        currency: localCurrency,
        benefitsPackage: offer.benefitsPackage,
      }),
    [currencies, localCurrency, offer.benefitsPackage]
  );

  if (!isZero(benefitsValueInOfferCurrency)) {
    barData.push({
      name: "Est. Annual Benefits Value",
      type: "bar",
      color: GRAY_1,
      data: Array<number>(projections.length).fill(
        benefitsValueInOfferCurrency.value
      ),
    });
  }
  // -- Legend data -----
  const legendItems: LegendItem[] = [];

  for (const fixed of data.cash[CashBandType.FIXED_CASH]) {
    legendItems.push({
      color: fixed.name === CashBandName.SALARY ? GRAY_2 : GRAY_4,
      value: CashBandCandidateDisplayName[fixed.name],
    });
  }

  if (variableCash.value > 0) {
    legendItems.push({
      color: GRAY_5,
      value: "Variable Cash",
    });
  }

  if (data.equity?.projectedAnnualValues) {
    legendItems.push({
      color: color,
      value: "Gross Annual Equity Value",
    });
  }

  if (!isZero(benefitsValueInOfferCurrency)) {
    legendItems.push({
      color: GRAY_1,
      value: "Est. Annual Benefits Value",
    });
  }

  return (
    <>
      <Header
        color={color}
        description="Annualized"
        headline="Total Compensation"
        subHead="Illustrative Outcomes"
      />
      <Visualization
        projections={projections}
        xAxisAnnotations={xAxisAnnotations}
        maxProjected={data.maxProjected}
        seriesData={barData}
        outcomeDescription={offer.outcomeDescription}
        legendItems={legendItems}
        equityGrantTypes={compStructure.equityGrantTypes}
        valuation={valuation}
        compStructure={compStructure}
        units={data.equity?.units}
      />
    </>
  );
};

export default memo(Breakdown);
