import { gql } from "@apollo/client";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import { NotesIcon } from "src/components/AssembleIcons/Small/NotesIcon";
import { format } from "timeago.js";
import {
  RecReviewStatus,
  ReviewerNoteRow_participant,
} from "../../../../__generated__/graphql";
import { GRAY_2 } from "../../../../theme";
import { AssembleButton } from "../../../AssembleButton/AssembleButton";
import { DraftNoteModalButton2 } from "../../Buttons/DraftNoteModalButton2";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-body": {
      border: "none",
      paddingBlock: theme.spacing(0),
    },
  },
  noteBox: {
    display: "flex",
    background: "#FEFAE2",
    borderRadius: "5px",
    padding: theme.spacing(2),
    margin: theme.spacing(1, 2, 3, 2),
  },
  noteIcon: {
    color: "#FF5D47",
    fontSize: "0.8125rem",
    lineHeight: 1.4,
    marginRight: theme.spacing(1),
  },
  noteIconInner: {
    width: "1rem",
  },
  noteText: {
    flex: 1,
  },
  noteTitle: {
    fontSize: "0.8125rem",
    lineHeight: 1.4,
    letterSpacing: "-0.15px",
    color: "#FF5D47",
  },
  noteBody: {
    fontSize: "0.8125rem",
    lineHeight: 1.5,
    letterSpacing: "-0.15px",
    color: GRAY_2,
  },
}));

interface Props {
  employee: ReviewerNoteRow_participant;
}

export function ReviewerNoteRow({ employee }: Props): JSX.Element {
  const classes = useStyles();
  const review = getMostRecentReview(employee.compRecommendation);

  if (review === null || review.status !== RecReviewStatus.DENIED) {
    return <></>;
  }

  const title = `From ${review.author.name ?? review.author.email}${
    review.submittedAt !== null
      ? `, ${format(new Date(review.submittedAt))}.`
      : ""
  }`;

  return (
    <TableRow aria-level={2} className={classes.root}>
      <TableCell role="gridcell" colSpan={14}>
        <div className={classes.noteBox}>
          <Typography className={classes.noteIcon}>
            <NotesIcon inherit />
          </Typography>
          <div className={classes.noteText}>
            <Typography className={classes.noteTitle}>{title}</Typography>
            <Typography className={classes.noteBody}>
              {review.note !== null && review.note.length
                ? `"${review.note}"`
                : "This review was rejected."}
            </Typography>
          </div>
          <DraftNoteModalButton2 employee={employee}>
            {(onOpen) => (
              <AssembleButton
                variant="outlined"
                onClick={onOpen}
                label="Older Messages"
                size="small"
              />
            )}
          </DraftNoteModalButton2>
        </div>
      </TableCell>
    </TableRow>
  );
}

ReviewerNoteRow.fragments = {
  participant: gql`
    ${DraftNoteModalButton2.fragments.participant}
    fragment ReviewerNoteRow_participant on CompCycleParticipant {
      subjectId
      compCycleId
      ...DraftNoteModalButton2_participant
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        allSubmittedReviews {
          id
          submittedAt
          author {
            id
            name
            email
          }
          status
          note
        }
      }
    }
  `,
};

const getMostRecentReview = <T extends { submittedAt: string | Date | null }>(
  rec: { allSubmittedReviews: T[] } | null
): T | null => {
  if (rec === null || rec.allSubmittedReviews.length === 0) {
    return null;
  }

  return rec.allSubmittedReviews.reduce((a, b) => {
    return new Date(a.submittedAt ?? 0) > new Date(b.submittedAt ?? 0) ? a : b;
  });
};
