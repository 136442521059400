import { gql } from "@apollo/client";
import { findRecommendedPosition } from "@asmbl/shared/comp-recommendation";
import { contramap } from "@asmbl/shared/sort";
import { CondensedTableNewLevelCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { MEDIUM_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};
export function CondensedTableNewLevelCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { availablePositions } = useTableData();
  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];
  const newPosition = findRecommendedPosition(recItems, availablePositions);
  const newLevel = newPosition?.level;
  const oldLevel = employee.subject.activeEmployment?.position?.level;
  const label = `${newLevel ?? oldLevel ?? "-"}`;

  return (
    <AssembleTruncatedTypography align="center" title={label}>
      {label}
    </AssembleTruncatedTypography>
  );
}

CondensedTableNewLevelCell2.fragments = {
  participant: gql`
    fragment CondensedTableNewLevelCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          position {
            id
            level
          }
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedPosition {
            id
          }
        }
      }
    }
  `,
  position: gql`
    fragment CondensedTableNewLevelCell_position on Position {
      id
      level
    }
  `,
};

CondensedTableNewLevelCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.NEW_LEVEL
);
CondensedTableNewLevelCell2.id = ColumnIds.NEW_LEVEL;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNewLevelCell2,
  Header: CondensedTableNewLevelCell2.Header,
  id: CondensedTableNewLevelCell2.id,
  width: MEDIUM_COL_WIDTH,
};
CondensedTableNewLevelCell2.column = column;
CondensedTableNewLevelCell2.ordering = () =>
  contramap(
    (e: Participant) => e.subject.activeEmployment?.position?.level ?? 0
  );
