import { gql } from "@apollo/client";
import { contramap } from "@asmbl/shared/sort";
import { formatNumeral } from "@asmbl/shared/utils";
import { CondensedTableCurrentSalaryCompaRatioCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { getCompaRatioNew } from "../../../../models/Employee";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};
export function CondensedTableCurrentSalaryCompaRatioCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const compaRatio = getCompaRatioNew(
    employee.subject.activeCashCompensation,
    employee.subject.adjustedCashBands
  );

  return (
    <AssembleTruncatedTypography align="right">
      {compaRatio != null
        ? formatNumeral(compaRatio, { style: "percent" })
        : "-"}
    </AssembleTruncatedTypography>
  );
}

CondensedTableCurrentSalaryCompaRatioCell2.ordering = () =>
  contramap(
    (e: Participant) =>
      getCompaRatioNew(
        e.subject.activeCashCompensation,
        e.subject.adjustedCashBands
      ) ?? 0
  );

CondensedTableCurrentSalaryCompaRatioCell2.fragments = {
  participant: gql`
    fragment CondensedTableCurrentSalaryCompaRatioCell2_participant on CompCycleParticipant {
      subject {
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          annualCashEquivalent
          hourlyCashEquivalent
          unit
        }
        adjustedCashBands(currencyCode: $currencyCode) {
          id
          name
          bandPoints {
            name
            value {
              ... on CashValue {
                annualRate
                hourlyRate
                currencyCode
              }
            }
          }
        }
      }
    }
  `,
};

CondensedTableCurrentSalaryCompaRatioCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.CURRENT_SALARY_COMPA_RATIO
);
CondensedTableCurrentSalaryCompaRatioCell2.id =
  ColumnIds.CURRENT_SALARY_COMPA_RATIO;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableCurrentSalaryCompaRatioCell2,
  Header: CondensedTableCurrentSalaryCompaRatioCell2.Header,
  id: CondensedTableCurrentSalaryCompaRatioCell2.id,
  width: LARGE_COL_WIDTH,
  defaultCanSort: false,
};
CondensedTableCurrentSalaryCompaRatioCell2.column = column;
