import { gql } from "@apollo/client";
import { contramap } from "@asmbl/shared/sort";
import { CondensedTableCurrentLadderCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { getLadderName } from "../../../../models/Employee";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { XLG_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};
export function CondensedTableCurrentLadderCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const label = employee.subject.activeEmployment?.position?.ladder.name ?? "-";

  return (
    <AssembleTruncatedTypography lines={2} title={label}>
      {label}
    </AssembleTruncatedTypography>
  );
}

CondensedTableCurrentLadderCell2.fragments = {
  participant: gql`
    fragment CondensedTableCurrentLadderCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          position {
            id
            ladder {
              id
              name
            }
          }
        }
      }
    }
  `,
  position: gql`
    fragment CondensedTableCurrentLadderCell_position on Position {
      id
      ladder {
        id
        name
      }
    }
  `,
};

CondensedTableCurrentLadderCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.CURRENT_LADDER
);
CondensedTableCurrentLadderCell2.id = ColumnIds.CURRENT_LADDER;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableCurrentLadderCell2,
  Header: CondensedTableCurrentLadderCell2.Header,
  id: CondensedTableCurrentLadderCell2.id,
  width: XLG_COL_WIDTH,
};
CondensedTableCurrentLadderCell2.column = column;
CondensedTableCurrentLadderCell2.ordering = () =>
  contramap((e: Participant) => getLadderName(e.subject) ?? "");
