import { gql, useLazyQuery } from "@apollo/client";
import { isEmailValid } from "@asmbl/shared/email";
import { useCallback } from "react";
import { EmailValidatorQuery } from "../__generated__/graphql";

export function useEmailValidator() {
  const [emailValidation] = useLazyQuery<EmailValidatorQuery>(
    useEmailValidator.query
  );

  return useCallback(
    async (email: string) => {
      if (!isEmailValid(email)) {
        return { valid: false, message: "Not a valid email" };
      }

      const result = await emailValidation({ variables: { email } });

      if (result.data) {
        if (!result.data.isDomainValid) {
          return { valid: false, message: "Not a valid domain" };
        }

        if (!result.data.isEmailNotTaken) {
          return { valid: false, message: "Email already taken" };
        }
      }

      return { valid: true, message: "" };
    },
    [emailValidation]
  );
}

useEmailValidator.query = gql`
  query EmailValidatorQuery($email: String!) {
    isDomainValid(email: $email)
    isEmailNotTaken(email: $email)
  }
`;
