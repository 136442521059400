import { CurrencyCode } from "@asmbl/shared/constants";
import { Money } from "@asmbl/shared/money";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { useTrack } from "../../../analytics";
import { GRAY_6, WHITE } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";
import { LocationGroup, Position } from "../types";
import { CompStatisticsLoadingBoundary } from "./CompStatisticsLoadingBoundary";

type Props = {
  selectedCurrencyCode: CurrencyCode;
  position: Position;
  locationGroup: LocationGroup | undefined;
  annualOrHourlySalary: Money | undefined;
  salaryCompaRatio: number | null;
};

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    borderBottom: `1px solid ${GRAY_6}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
  },
  accordion: {
    backgroundColor: WHITE,
    padding: theme.spacing(0),
  },
  accordionHeader: {
    backgroundColor: WHITE,
    padding: theme.spacing(0),
    height: theme.spacing(4),
    borderBottom: "none",
  },
  accordionDetails: {
    display: "block",
    paddingBottom: theme.spacing(2),
  },
}));

export function CompStatisticsView({
  selectedCurrencyCode,
  annualOrHourlySalary,
  salaryCompaRatio,
  position,
  locationGroup,
}: Props): JSX.Element | null {
  const classes = useStyles();
  const { trackEvent } = useTrack();
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleChange = (_event: ChangeEvent<unknown>, isExpanded: boolean) => {
    setExpanded(isExpanded);
    trackEvent({
      object: "Offer Statistics Visibility",
      action: `Toggled ${isExpanded ? "on" : "off"}`,
    });
  };

  if (!locationGroup) return null;

  return (
    <>
      <div className={classes.divider} />

      <Accordion
        className={classes.accordion}
        expanded={expanded}
        onChange={handleChange}
      >
        <AccordionSummary
          className={classes.accordionHeader}
          expandIcon={<ChevronDownIcon inherit />}
        >
          <AssembleTypography variant="sidebarTitle">
            Comp Across Company
          </AssembleTypography>
        </AccordionSummary>

        {expanded && (
          <AccordionDetails className={classes.accordionDetails}>
            <CompStatisticsLoadingBoundary
              selectedCurrencyCode={selectedCurrencyCode}
              position={position}
              locationGroup={locationGroup}
              annualOrHourlySalary={annualOrHourlySalary}
              salaryCompaRatio={salaryCompaRatio}
            />
          </AccordionDetails>
        )}
      </Accordion>
    </>
  );
}
