import { gql } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { Box, makeStyles } from "@material-ui/core";
import { ClockIcon } from "src/components/AssembleIcons/Brand/ClockIcon";
import { LevelIcon } from "src/components/AssembleIcons/Brand/LevelIcon";
import { CalendarIcon } from "src/components/AssembleIcons/Small/CalendarIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { PreviewFeature } from "src/components/FeatureContext";
import {
  PositionDescription_position,
  PositionType,
} from "../../__generated__/graphql";
import { AssembleLinkify } from "../../components/AssembleLinkify/AssembleLinkify";
import { DV_PINK, GRAY_2, GRAY_3, GRAY_4, GRAY_5, GRAY_6 } from "../../theme";

const useStyles = makeStyles((theme) => ({
  positionLevelLabel: {
    marginRight: theme.spacing(1),
  },
  jobCodesContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  jobCodesList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  jobCodesLabel: {
    color: GRAY_3,
    paddingRight: "25px",
  },
  jobCodeText: {
    backgroundColor: GRAY_6,
    borderRadius: "3px",
    color: DV_PINK,
    fontSize: "15px",
    letterSpacing: "-0.25px",
    lineHeight: "140%",
    fontWeight: 600,
    marginRight: "10px",
    padding: "0px 5px 0px 5px",
  },
  verticalSeparator: {
    height: "100%",
    borderLeft: `1px dashed ${GRAY_4}`,
    marginBlock: theme.spacing(1),
  },
  positionDetailOuterContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(1),
    alignItems: "center",
  },
  positionDetailInnerContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(1),
    alignItems: "center",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(3),
  },
}));

type Props = {
  position: PositionDescription_position;
};

export function PositionDescription({ position }: Props): JSX.Element {
  const classes = useStyles();
  const jobCodes = position.jobCodes.filter((code) => code !== "");

  return (
    <div className={classes.root}>
      {/* position level and type */}
      <div className={classes.positionDetailOuterContainer}>
        <div className={classes.positionDetailInnerContainer}>
          <LevelIcon color={GRAY_4} height="24px" width="24px" />
          <AssembleTypography
            variant="productParagraphLarge"
            textColor={GRAY_2}
          >
            {position.level}
          </AssembleTypography>
        </div>

        <AssembleTypography variant="productParagraphLarge" textColor={GRAY_5}>
          |
        </AssembleTypography>

        <PreviewFeature flag={FeatureFlag.HourlyEmployees}>
          {position.type === PositionType.ANNUAL && (
            <div className={classes.positionDetailInnerContainer}>
              <CalendarIcon color={GRAY_4} height="24px" width="24px" />
              <AssembleTypography
                variant="productParagraphLarge"
                textColor={GRAY_2}
              >
                Annual
              </AssembleTypography>
            </div>
          )}
          {position.type === PositionType.HOURLY && (
            <div className={classes.positionDetailInnerContainer}>
              <ClockIcon color={GRAY_4} height="24px" width="24px" />
              <AssembleTypography
                variant="productParagraphLarge"
                textColor={GRAY_2}
              >
                Hourly
              </AssembleTypography>
            </div>
          )}
        </PreviewFeature>
      </div>

      {/* position description */}
      <div>
        {position.description !== null && position.description !== "" && (
          <AssembleLinkify newTab>
            <AssembleTypography
              variant="productParagraphMedium"
              textColor={GRAY_2}
            >
              {position.description}
            </AssembleTypography>
          </AssembleLinkify>
        )}
      </div>

      {/* position job codes */}
      <div>
        {jobCodes.length > 0 && (
          <Box className={classes.jobCodesContainer}>
            <AssembleTypography
              variant="subtitle2"
              className={classes.jobCodesLabel}
            >
              Job Codes
            </AssembleTypography>
            <div className={classes.jobCodesList}>
              {position.jobCodes.map((code, index) => (
                <AssembleTypography key={index} className={classes.jobCodeText}>
                  {code}
                </AssembleTypography>
              ))}
            </div>
          </Box>
        )}
      </div>
    </div>
  );
}

PositionDescription.fragments = {
  position: gql`
    fragment PositionDescription_position on Position {
      level
      description
      jobCodes
      type
    }
  `,
};
