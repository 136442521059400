import { cx } from "@emotion/css";
import {
  FormControl,
  InputBase,
  InputLabel,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useMemo } from "react";
import {
  BOX_SHADOW,
  GRAY_4,
  GRAY_5,
  GRAY_6,
  GRAY_7,
  PURPLE_1,
  WHITE,
} from "../../theme";
import { ChevronDownIcon } from "../AssembleIcons/Brand/ChevronDownIcon";
import { InfoIcon } from "../AssembleIcons/Brand/InfoIcon";
import { AssembleFitContentPopper } from "../AssemblePopper";
import { ExplanatoryTooltip } from "../ExplanatoryTooltip";

export type Option = {
  label: string;
  value: string | number;
};

interface Props {
  disabled?: boolean;
  label?: string;
  labelInfo?: {
    title: string;
    body: JSX.Element | string;
  };
  onChange: (value: string) => unknown;
  value: string;
  options: Option[];
  renderOption?: (option: Option) => JSX.Element;
  size?: "small" | "medium" | "large";
  intercomTarget?: string;
}

interface StorybookProps {
  storybookForceOpen?: boolean;
}

export const useAutocompleteStyles = makeStyles((theme: Theme) => ({
  root: {
    background: WHITE,
    borderRadius: "5px",
    boxShadow: BOX_SHADOW,
    border: `1px solid ${GRAY_5}`,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    height: "100%",
    padding: 0,
    "&.Mui-focused": {
      border: `1px solid ${PURPLE_1}`,
      boxShadow: `0 0 0 3px rgba(100, 91, 255, 0.25)`,
    },

    "&:hover": {
      border: `1px solid ${PURPLE_1}`,
    },
  },

  small: {
    height: "2rem",
    "& .MuiInputBase-input": {
      paddingBlock: "0.375rem",
      fontSize: "0.8125rem",
      lineHeight: "1.125rem",
    },
  },
  medium: {
    height: "2.5rem",
    "& .MuiInputBase-input": {
      paddingBlock: "0.5625rem",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
    },
  },
  large: {
    height: "3.75rem",
    "& .MuiInputBase-input": {
      paddingBlock: "1.1125rem",
      fontSize: "1rem",
      lineHeight: "1.4rem",
    },
  },

  infoIcon: {
    marginLeft: theme.spacing(1),
  },

  inputComponent: {
    flexWrap: "nowrap",
    padding: 0,
    alignItems: "center",
  },

  input: {
    boxSizing: "border-box",
    paddingInline: theme.spacing(1.5),
    flexGrow: 1,
    height: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  endAdornment: {
    top: "calc(50% - 10px)",
    right: theme.spacing(0.5),
  },

  popupIndicator: {
    height: "100%",
    transition: "transform cubic-bezier(0.4, 0, 0.2, 1) 275ms",
  },

  popover: {
    borderRadius: "5px",
    border: `1px solid ${GRAY_6}`,
    boxShadow: "0px 3px 24px rgba(10, 36, 64, 0.05)",
  },

  paper: {
    margin: 0,
  },

  listbox: {
    padding: theme.spacing(1),
    maxHeight: "275px",
  },

  option: {
    borderRadius: 0,
    border: "none",
    margin: 0,
    padding: theme.spacing(1),
    "&[aria-selected='true']": {
      fontWeight: 700,
    },
    "&[data-focus='true']": {
      background: GRAY_7,
      color: PURPLE_1,
      boxShadow: "none",
    },
  },
  label: {
    pointerEvents: "auto",
  },
}));

export function AutocompleteSelect({
  disabled,
  label,
  labelInfo,
  options,
  onChange,
  value,
  renderOption: customRenderOption,
  size = "large",
  storybookForceOpen,
  intercomTarget,
}: Props & StorybookProps): JSX.Element {
  const classes = useAutocompleteStyles();
  const labelId = useMemo(() => `label-${Math.random()}`, []);

  const renderOption =
    customRenderOption !== undefined
      ? customRenderOption
      : (option: Option) => (
          <Typography variant="inherit" noWrap>
            {option.label}
          </Typography>
        );

  return (
    <FormControl
      className={classes[size]}
      variant="outlined"
      fullWidth
      data-intercom-target={intercomTarget}
    >
      <Autocomplete
        data-cy="autocomplete-select"
        disabled={disabled}
        PopperComponent={AssembleFitContentPopper}
        classes={{
          root: cx(classes.root, classes[size]),
          endAdornment: classes.endAdornment,
          popupIndicator: classes.popupIndicator,
          listbox: classes.listbox,
          option: classes.option,
          popper: classes.popover,
          paper: classes.paper,
        }}
        onChange={(_, option) => onChange(option.value.toString())}
        value={options.find((o) => o.value.toString() === value.toString())}
        options={options}
        getOptionLabel={(option) => option.label}
        renderOption={renderOption}
        popupIcon={<ChevronDownIcon inherit inline />}
        renderInput={(params) => (
          <InputBase
            {...params.InputProps}
            id={params.id}
            fullWidth={params.fullWidth}
            disabled={params.disabled}
            className={classes.inputComponent}
            inputProps={{
              ...params.inputProps,
              className: classes.input,
              "aria-labelledby": labelId,
            }}
          />
        )}
        disableClearable
        open={storybookForceOpen}
        disablePortal={storybookForceOpen}
      />
      <InputLabel id={labelId} className={classes.label} shrink>
        {label}
        {labelInfo !== undefined && (
          <ExplanatoryTooltip
            title={labelInfo.title}
            body={labelInfo.body}
            placement="bottom"
            width="25rem"
          >
            <span>
              <InfoIcon
                className={classes.infoIcon}
                color={GRAY_4}
                hoverColor={PURPLE_1}
                inline
                height={"14px"}
                width={"14px"}
              />
            </span>
          </ExplanatoryTooltip>
        )}
      </InputLabel>
    </FormControl>
  );
}
