import { gql } from "@apollo/client";
import EmptyLocationMappingSvg from "../../../../assets/svgs/illustrations/empty-state-hris-mapping.svg?react";
import NoHrisSvg from "../../../../assets/svgs/illustrations/no-hris-integration.svg?react";
import { LocationsTableEmpty_mergeConfigs as MergeConfig } from "../../../../__generated__/graphql";
import { AssembleLink } from "../../../AssembleLink";
import { EmptyTableView } from "../../../EmptyTableView";

type Props = { mergeConfigs: MergeConfig[] | undefined };

export function LocationsTableEmpty({ mergeConfigs }: Props): JSX.Element {
  return mergeConfigs !== undefined && mergeConfigs.length > 0 ? (
    <EmptyTableView
      message="You don't currently have any locations that need mapping."
      svg={<EmptyLocationMappingSvg width="15%" />}
    />
  ) : (
    <EmptyTableView
      message={
        <>
          Go to your&nbsp;
          <AssembleLink to="/settings/data">
            Data Management Settings
          </AssembleLink>
          &nbsp;and add your HRIS integration to add your employee locations to
          Location Groups.
        </>
      }
      svg={<NoHrisSvg />}
      width="30em"
    />
  );
}

LocationsTableEmpty.fragments = {
  mergeConfigs: gql`
    fragment LocationsTableEmpty_mergeConfigs on MergeConnectionConfig {
      id
    }
  `,
};
