import {
  formatCurrency,
  money,
  Money,
  preferredPrice,
  unitsOfTotalGrossValue,
} from "@asmbl/shared/money";
import {
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useState } from "react";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { AssembleLabel } from "../../../components/Form/AssembleLabel";
import { NumberInput } from "../../../components/Form/NumberInput";
import {
  Allocation,
  AllocationUnit,
  BudgetType,
  convertAllocationToCash,
} from "../../../models/Budget";
import { currencySymbol } from "../../../models/Currency";
import { GRAY_4 } from "../../../theme";

type Props = {
  value: Money | null;
  valuation: { fdso: number; valuation: Money };
  onBlur: (value: Money | null) => unknown;
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    columnGap: theme.spacing(1),
  },
  select: {
    boxSizing: "border-box",
    width: "8rem",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    top: "calc(50% - 8px)",
    height: "16px",
    right: "16px",
  },
  input: {
    flex: "1 0 0", // Take up whatever space is left
  },
}));

export function SettingsEquityInput({
  value,
  valuation,
  onBlur,
}: Props): JSX.Element {
  const classes = useStyles();
  const htmlId = "comp-cycle-new-equity-input";
  const [formAllocation, setFormAllocation] = useState<Allocation>({
    unit: AllocationUnit.CASH,
    value,
  });
  const currency = valuation.valuation.currency;
  const cashValue =
    formAllocation.value !== null
      ? convertAllocationToCash(formAllocation, valuation)
      : null;
  const unitValue =
    formAllocation.value !== null
      ? formAllocation.unit === AllocationUnit.UNIT
        ? formAllocation.value
        : Math.round(
            unitsOfTotalGrossValue(
              valuation.fdso,
              valuation.valuation,
              formAllocation.value
            )
          )
      : null;

  const handleBlur = () => {
    onBlur(convertAllocationToCash(formAllocation, valuation));
  };

  return (
    <div className={classes.container}>
      <AssembleLabel htmlFor={htmlId}>
        {`${BudgetType.EQUITY} (
        ${formatCurrency(preferredPrice(valuation.fdso, valuation.valuation), {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })} per Unit)`}
      </AssembleLabel>
      <Select
        classes={{ root: classes.select, icon: classes.icon }}
        value={formAllocation.unit}
        onChange={({ target: { value: newValue } }) => {
          setFormAllocation(
            newValue === "unit"
              ? { unit: AllocationUnit.UNIT, value: unitValue }
              : { unit: AllocationUnit.CASH, value: cashValue }
          );
        }}
        variant="outlined"
        IconComponent={AssembleIconComponent}
      >
        <MenuItem value="cash">Cash</MenuItem>
        <MenuItem value="unit">Units</MenuItem>
      </Select>
      {formAllocation.unit === AllocationUnit.CASH && (
        <NumberInput
          className={classes.input}
          id={htmlId}
          placeholder="000,000"
          thousandSeparator
          value={cashValue?.value ?? null}
          onValueChange={(e) =>
            setFormAllocation({
              unit: AllocationUnit.CASH,
              value:
                e.floatValue === undefined
                  ? null
                  : money(e.floatValue, currency),
            })
          }
          onBlur={handleBlur}
          allowNegative={false}
          startAdornment={
            <InputAdornment position="start">
              {currencySymbol(currency)}
            </InputAdornment>
          }
        />
      )}
      {formAllocation.unit === AllocationUnit.UNIT && (
        <NumberInput
          className={classes.input}
          id={htmlId}
          placeholder="000,000"
          thousandSeparator
          value={unitValue}
          onValueChange={(e) =>
            setFormAllocation({
              unit: AllocationUnit.UNIT,
              value: e.floatValue ?? null,
            })
          }
          onBlur={handleBlur}
          allowNegative={false}
          startAdornment={<InputAdornment position="start">#</InputAdornment>}
        />
      )}
    </div>
  );
}

function AssembleIconComponent(props: Record<string, unknown>) {
  return (
    <div {...props}>
      <ChevronDownIcon color={GRAY_4} />
    </div>
  );
}
