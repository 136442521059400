import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const CurrencyIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Currency"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M9.97473 12.9315H13.8737"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M9.97473 10.4406H13.8737"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M11.9243 9.42334V14.7067"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M14.8485 6L11.9242 8.57407L9 6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M3.30774 8.38464V9.00003"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M3.30774 1V1.61538"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M5.01093 2.3737C4.86807 2.23084 4.69848 2.11752 4.51182 2.0402C4.32517 1.96289 4.12511 1.9231 3.92308 1.9231H2.53846C2.13044 1.9231 1.73912 2.08518 1.4506 2.3737C1.16209 2.66222 1 3.05353 1 3.46156C1 3.86958 1.16209 4.2609 1.4506 4.54941C1.73912 4.83793 2.13044 5.00002 2.53846 5.00002H4.23077C4.63879 5.00002 5.03011 5.16211 5.31863 5.45062C5.60714 5.73914 5.76923 6.13045 5.76923 6.53848C5.76923 6.94651 5.60714 7.33782 5.31863 7.62634C5.03011 7.91485 4.63879 8.07694 4.23077 8.07694H2.38462C1.97659 8.07694 1.58528 7.91485 1.29676 7.62634"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
