import { CompRecTableHeaders } from "./CompRecommendation";

export type HeaderConfig = Record<
  CompRecTableHeaders,
  { weight: number; isVisible: boolean }
>;

/**
 * Returns a percentage width for a given header column based on which other
 * columns are visible and their respective weights.
 */
export function getHeaderWidth(
  config: HeaderConfig,
  column: CompRecTableHeaders
) {
  const weight = config[column].isVisible ? config[column].weight : 0;
  const percentage = (100 / getTotalVisibleWeight(config)) * weight;
  return `${percentage.toFixed(3)}%`;
}

/**
 * Sums up all the weights of visible columns.
 */
export function getTotalVisibleWeight(config: HeaderConfig) {
  return Object.values(config).reduce(
    (acc, cur) => (cur.isVisible ? acc + cur.weight : acc),
    0
  );
}
