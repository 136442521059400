import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  UserInvitationAccessControlLoadingBoundaryQuery,
  UserInvitationAccessControlLoadingBoundaryQueryVariables,
} from "../../../__generated__/graphql";
import Loading from "../Loading";
import { UserAccessControl } from "./UserAccessControl";

type ElementOrNull = JSX.Element | null;

export function UserInvitationAccessControlLoadingBoundary(): ElementOrNull {
  const { id } = useParams<{ id: string }>();
  const targetId = id != null ? parseInt(id, 10) : NaN;

  const { data, loading, refetch } = useQuery<
    UserInvitationAccessControlLoadingBoundaryQuery,
    UserInvitationAccessControlLoadingBoundaryQueryVariables
  >(UserInvitationAccessControlLoadingBoundary.query, {
    variables: { userInvitationId: targetId },
    skip: isNaN(targetId),
    errorPolicy: "all",
  });

  if (!data || data.userInvitationFromId === null) {
    return loading ? <Loading /> : null;
  }

  return (
    <UserAccessControl
      type="user-invite"
      targetId={targetId}
      organization={data.organization}
      user={data.userInvitationFromId}
      refreshUser={refetch}
    />
  );
}

UserInvitationAccessControlLoadingBoundary.query = gql`
  ${UserAccessControl.fragments.userInvitation}
  ${UserAccessControl.fragments.organization}
  query UserInvitationAccessControlLoadingBoundaryQuery(
    $userInvitationId: Int!
  ) {
    userInvitationFromId(id: $userInvitationId) {
      id
      ...UserAccessControl_userInvitation
    }
    organization {
      id
      ...UserAccessControl_organization
    }
  }
`;
