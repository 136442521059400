import { Dispatch, SetStateAction, useState } from "react";
import { Employee2, Employee2sMeta } from "../../../__generated__/graphql";
import { FilterParam, getNumericParams } from "../../../models/FilterParams";
import { useURLSearchParams } from "../../../models/URLSearchParams";
import { useReportsTableContext } from "../Context/TableContext";
import { FilterBar } from "../FilterBar2";

export interface Props<E extends Employee2> {
  employees: E[];
  children: (
    filteredEmployees: E[],
    page: number,
    setPage: Dispatch<SetStateAction<number>>
  ) => JSX.Element;
  dropdownOptions: Omit<Employee2sMeta, "counts">;
}

export function FilterStateManager<E extends Employee2>({
  employees,
  children,
  dropdownOptions,
}: Props<E>): JSX.Element {
  const [page, setPage] = useState(0);
  const { setOffset } = useReportsTableContext();

  const urlSearchParams = useURLSearchParams();
  const managerParam = getNumericParams(urlSearchParams, FilterParam.MANAGER);

  const selectedManagerIds = managerParam.has("all")
    ? []
    : managerParam.has("null")
      ? []
      : ([...managerParam.values()] as number[]);

  return (
    <>
      <FilterBar
        disableReportingFilter={
          // user hasn't selected any managers to filter by
          selectedManagerIds.length === 0 || selectedManagerIds.at(0) === null
        }
        onChange={() => {
          setPage(0);
          setOffset(0);
        }}
        dropdownOptions={dropdownOptions}
      />
      {children(employees, page, setPage)}
    </>
  );
}
