import { cx } from "@emotion/css";
import {
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ClearOutlined } from "@material-ui/icons";
import { useRef } from "react";
import { GRAY_4, GRAY_5, theme } from "../../theme";
import { SearchIcon } from "../AssembleIcons/Brand/SearchIcon";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1.5),
    padding: "0px",
  },
  input: {
    borderColor: GRAY_5,
    padding: "0px",
    width: "13.25rem",
    height: "2rem",
    fontSize: "0.875rem",
  },
  inputWide: {
    width: "15rem",
  },
  goButton: {
    minWidth: "3rem",
    // width: "3rem",
    height: "1.5rem",
    borderColor: GRAY_5,
    boxShadow: "0px 1px 2px 0px rgba(10, 36, 64, 0.10)",
    marginRight: "0.15rem",
    marginLeft: theme.spacing(1),
  },
}));

type Props = {
  searchTerm?: string | null;
  handleSearchChange?: (value: string) => unknown;
  placeholder?: string;
  classes?: { icon?: string; input?: string };
  width?: "medium" | "wide";
  handleOnBlur?: (value: string) => unknown;
  defaultValue?: string | null;
  useGoButton?: boolean;
  prevSearchTerm?: string;
  disabled?: boolean;
};

/**
 * An Assemble-stylized, generic-use search bar
 */
export function SearchBar({
  searchTerm,
  handleSearchChange = () => {
    // noop
  },
  placeholder,
  classes: inputClasses,
  width = "medium",
  handleOnBlur = () => {
    // noop
  },
  defaultValue,
  useGoButton,
  prevSearchTerm,
  disabled = false,
}: Props): JSX.Element {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleOnBlur((event.target as HTMLInputElement).value);
    }
  };

  const handleClear = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    handleSearchChange("");
    handleOnBlur("");
  };

  return (
    <TextField
      inputRef={inputRef}
      value={searchTerm}
      defaultValue={defaultValue}
      variant="outlined"
      disabled={disabled}
      placeholder={placeholder ?? "Search"}
      size="small"
      onChange={(e) => handleSearchChange(e.target.value)}
      onBlur={
        useGoButton === true ? undefined : (e) => handleOnBlur(e.target.value)
      }
      onKeyDown={onKeyDown}
      InputProps={{
        className: cx(
          classes.input,
          inputClasses?.input,
          width === "wide" && classes.inputWide
        ),
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon
              color={GRAY_4}
              className={cx(classes.icon, inputClasses?.icon)}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {useGoButton === true ? (
              <Tooltip
                enterDelay={250}
                title={<Typography>Press Enter</Typography>}
              >
                <Button
                  classes={{ root: classes.goButton }}
                  color="primary"
                  disabled={
                    prevSearchTerm != null && prevSearchTerm === searchTerm
                  }
                  variant="outlined"
                  onClick={() => handleOnBlur(searchTerm ?? defaultValue ?? "")}
                >
                  Search
                </Button>
              </Tooltip>
            ) : null}
            {(useGoButton !== true &&
              searchTerm != null &&
              searchTerm.length > 0) ||
            (defaultValue != null && defaultValue.length > 0) ? (
              <IconButton onClick={handleClear}>
                <ClearOutlined />
              </IconButton>
            ) : null}
          </>
        ),
        style: {
          padding: theme.spacing(0.5, 0, 0.5, 0),
          boxShadow: "inset 0px 1px 4px rgba(54, 70, 87, 0.14)",
          borderRadius: useGoButton === true ? "5px" : "65px",
        },
      }}
    />
  );
}
