import { makeStyles, MenuItem, Popover } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import clsx from "clsx";
import { SyntheticEvent, useState } from "react";
import { WHITE } from "../theme";

export type Option = {
  className?: string;
  value: string;
  label: string;
  hideOption?: boolean;
};

type Props = {
  options: Option[];
  onChange: (option: Option) => void;
  value: string;
};

const useStyles = makeStyles((theme) => ({
  chip: {
    width: "100%",
    borderRadius: 20,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: "center",
    color: WHITE,
    fontSize: ".8rem",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
  menuItem: {
    padding: theme.spacing(1.5),
    lineHeight: 1.75,
  },
  select: {
    width: "min-content",
    cursor: "pointer",
    transition: "box-shadow 0.3s ease",
  },
  activeSelect: {
    boxShadow: "0px 0px 0px 3px #645BFF4D",
  },
}));

export default function ChipSelect({ options, onChange, value }: Props) {
  const selectedOption = options.find((option) => option.value === value);
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(
    null
  );
  const classes = useStyles();

  const togglePopoverOpen = (e: SyntheticEvent) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleOptionClick = (option: Option) => {
    setAnchorEl(null);
    onChange(option);
  };

  return (
    <>
      <div
        className={clsx(
          [classes.chip, classes.select, selectedOption?.className],
          {
            [classes.activeSelect]: !!anchorEl,
          }
        )}
        onClick={togglePopoverOpen}
        data-intercom-target={"status-selector"}
      >
        {selectedOption?.label}
        {anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </div>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        {options.map((option) =>
          option.hideOption === true ? null : (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() => handleOptionClick(option)}
              className={classes.menuItem}
            >
              <span className={clsx([classes.chip, option.className])}>
                {option.label}
              </span>
            </MenuItem>
          )
        )}
      </Popover>
    </>
  );
}
