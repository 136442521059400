import { gql } from "@apollo/client";
import { findRecommendedPosition } from "@asmbl/shared/comp-recommendation";
import { contramap } from "@asmbl/shared/sort";
import { CondensedTableNewLadderCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { getLadderName } from "../../../../models/Employee";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { XLG_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};
export function CondensedTableNewLadderCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { availablePositions } = useTableData();
  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];
  const newPosition = findRecommendedPosition(recItems, availablePositions);
  const newLabel = newPosition?.ladder.name;
  const oldLabel = employee.subject.activeEmployment?.position?.ladder.name;
  const label = newLabel ?? oldLabel ?? "-";

  return (
    <AssembleTruncatedTypography lines={2} title={label}>
      {label}
    </AssembleTruncatedTypography>
  );
}

CondensedTableNewLadderCell2.fragments = {
  participant: gql`
    fragment CondensedTableNewLadderCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          position {
            id
            ladder {
              id
              name
            }
          }
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedPosition {
            id
          }
        }
      }
    }
  `,
  position: gql`
    fragment CondensedTableNewLadderCell_position on Position {
      id
      ladder {
        id
        name
      }
    }
  `,
};

CondensedTableNewLadderCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.NEW_LADDER
);
CondensedTableNewLadderCell2.id = ColumnIds.NEW_LADDER;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNewLadderCell2,
  Header: CondensedTableNewLadderCell2.Header,
  id: CondensedTableNewLadderCell2.id,
  width: XLG_COL_WIDTH,
};
CondensedTableNewLadderCell2.column = column;
CondensedTableNewLadderCell2.ordering = () =>
  contramap((e: Participant) => getLadderName(e.subject) ?? "");
