import { gql } from "@apollo/client";
import { makeStyles, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { format } from "timeago.js";
import {
  EquityImportManagement_employee as Employee,
  EquityImportManagement_organization as Organization,
} from "../../../__generated__/graphql";
import { useTrack } from "../../../analytics";
import EquityCsvIllustration from "../../../assets/svgs/illustrations/integrations/equity-csv.svg?react";
import {
  useImportEmployeeEquityGrants,
  useUpsertEquityGrantVestingEvents,
} from "../../../mutations/EquityGrants";
import { CardButton } from "../../Form/CardButton";
import { EquityCardButtonBanner } from "./EquityCardButtonBanner";
import { EmployeeEquityGrantRow } from "./EquityImportButton";
import { EquityGrantVestingEventRow } from "./EquityVestingImportButton";
import { EquityVestingImportDialog } from "./EquityVestingImportDialog";

const useStyles = makeStyles({
  cardButtonContainer: {
    maxWidth: "30rem",
    textAlign: "center",
    display: "flex",
    flexBasis: "100%",
  },
});

type Props = {
  employees: Employee[];
  orgId: number | null;
  orgData: Organization | undefined;
  onEquityUpload: () => Promise<unknown>;
  disabled: boolean;
};

export const EquityImportManagement = ({
  employees,
  orgId,
  orgData,
  onEquityUpload,
  disabled,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { trackEvent } = useTrack();
  const [isEquityVestingDialogOpen, setIsEquityVestingDialogOpen] =
    useState(false);

  const importEmployeeEquityGrants = useImportEmployeeEquityGrants();
  const upsertEquityVestingEvents = useUpsertEquityGrantVestingEvents();

  const handleCardButtonClick = () => {
    trackEvent({
      area: "Data Management",
      object: "Import Equity Grants and Vesting Button",
      action: "Clicked",
    });
    setIsEquityVestingDialogOpen(true);
  };

  const handleSubmitEquityGrants = (data: EmployeeEquityGrantRow[]) => {
    trackEvent({
      area: "Data Management",
      object: "Submit Employee Equity Grants Button",
      action: "Clicked",
    });
    void importEmployeeEquityGrants(data, employees).then(onEquityUpload);
  };

  const handleSubmitEquityVestingEvents = (
    data: EquityGrantVestingEventRow[]
  ) => {
    trackEvent({
      area: "Data Management",
      object: "Submit Employee Vesting Events Button",
      action: "Clicked",
    });

    void upsertEquityVestingEvents(data, employees).then(onEquityUpload);
  };

  const lastEquityUploadDate = orgData
    ? getLastEquityUploadDate(
        orgData.lastEquityGrantsUploadDate,
        orgData.lastEquityVestingEventsUploadDate
      )
    : null;

  return (
    <>
      <EquityVestingImportDialog
        employees={employees}
        open={isEquityVestingDialogOpen}
        onClose={() => setIsEquityVestingDialogOpen(false)}
        onVestingSubmit={handleSubmitEquityVestingEvents}
        onGrantSubmit={handleSubmitEquityGrants}
        orgData={orgData}
      />
      <Tooltip
        title={
          disabled
            ? "You can't manually upload cap table information if you have an active Carta integration. Please contact us if you want to change your integrations."
            : ""
        }
      >
        <div className={classes.cardButtonContainer}>
          <CardButton
            disabled={disabled}
            svg={<EquityCsvIllustration />}
            label="Equity Grants & Vesting"
            onClick={handleCardButtonClick}
            sublabel={
              lastEquityUploadDate !== null
                ? `Last imported ${format(new Date(lastEquityUploadDate))}`
                : "Never imported"
            }
            children={
              orgData?.lastEquityVestingEventsUploadDate === null &&
              orgData.lastEquityGrantsUploadDate !== null ? (
                <EquityCardButtonBanner
                  employees={employees}
                  handleChange={handleSubmitEquityVestingEvents}
                  lastUploadDate={orgData.lastEquityGrantsUploadDate}
                  disabled={false}
                />
              ) : undefined
            }
          />
        </div>
      </Tooltip>
    </>
  );
};

EquityImportManagement.fragments = {
  employee: gql`
    ${EquityVestingImportDialog.fragments.employee}
    fragment EquityImportManagement_employee on Employee {
      id
      ...EquityVestingImportDialog_employee
    }
  `,
  organization: gql`
    ${EquityVestingImportDialog.fragments.organization}
    fragment EquityImportManagement_organization on Organization {
      id
      ...EquityVestingImportDialog_organization
    }
  `,
};

function getLastEquityUploadDate(
  date1: GraphQL_DateTime | null,
  date2: GraphQL_DateTime | null
): Date | null {
  const formattedDate1 = date1 !== null ? new Date(String(date1)) : null;
  const formattedDate2 = date2 !== null ? new Date(String(date2)) : null;

  if (formattedDate1 !== null && formattedDate2 !== null) {
    return formattedDate1.getTime() > formattedDate2.getTime()
      ? formattedDate1
      : formattedDate2;
  }

  return formattedDate1 === null ? formattedDate2 : formattedDate1;
}
