import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { Noun, Verb } from "@asmbl/shared/permissions";
import { FormControlLabel, Switch, makeStyles } from "@material-ui/core";
import { ChangeEvent } from "react";
import { useLocation } from "react-router-dom";
import { AccessBoundary } from "src/components/AccessBoundary";
import { PreviewFeature } from "src/components/FeatureContext";
import { CashCompType, PayPeriodType } from "../../../__generated__/graphql";
import { AssembleTypography } from "../../../components/AssembleTypography";
import { useAuth } from "../../../components/Auth/AuthContext";
import { Paginator } from "../../../components/Pagination/Paginator";
import { SearchBar } from "../../../components/Search/SearchBar";
import { EquityDisplayType } from "../../../components/Table/EquityDisplayCell";
import { TableHeaderBar } from "../../../components/Table/TableHeaderBar";
import { theme } from "../../../theme";
import { ExportCSVButton } from "../ExportCSVButton";
import { PeopleTabParam } from "../PeopleTabParam";
import { OrganizationHierarchyModal } from "./OrganizationHierarchyModal";
import { PeopleSettingsContainer } from "./PeopleSettingsContainer";

const useStyles = makeStyles(() => ({
  searchBar: {
    width: "16rem",
  },
  toggleContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  settingsContainer: { paddingLeft: "10px" },
  levelingText: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  formControlLabel: {
    marginRight: 0,
    marginLeft: 0,
  },
}));

interface Props {
  searchTerm: string;
  onSearchChange: (value: string | null) => unknown;
  handleOnBlur: (value: string) => unknown;
  rowCount: number;
  compComponentSelection: Set<CashCompType>;
  onCompComponentChange: (event: ChangeEvent<HTMLInputElement>) => unknown;
  showEquityGrants: boolean;
  equityDisplayUnitType: EquityDisplayType;
  payTypeSelection: PayPeriodType;
  onEquityToggleChange: (value: EquityDisplayType | null) => unknown;
  onPayTypeToggleChange: (value: PayPeriodType) => unknown;
  onLevelingToggleChange: (showLeveling: boolean) => unknown;
  showLevelingColumn: boolean;
  canUserLevel: boolean;
  tabParam: PeopleTabParam;
  offset: number;
  setOffset: (offset: number) => void;
  limit: number;
  setLimit: (limit: number) => void;
  totalCount: number;
  prevSearchTerm: string;
}

export function ReportsTableHeaderBar({
  searchTerm,
  onSearchChange,
  handleOnBlur,
  rowCount,
  compComponentSelection,
  onCompComponentChange,
  showEquityGrants,
  equityDisplayUnitType,
  payTypeSelection,
  onEquityToggleChange,
  onPayTypeToggleChange,
  onLevelingToggleChange,
  showLevelingColumn,
  canUserLevel,
  tabParam,
  offset,
  setOffset,
  limit,
  setLimit,
  totalCount,
  prevSearchTerm,
}: Props): JSX.Element {
  const classes = useStyles();
  const { permissions } = useAuth();
  const { pathname } = useLocation();

  const canExport = [
    Noun.Employee,
    Noun.CashBand,
    Noun.EmployeeCashCompensation,
  ].every((noun) => permissions.canViewGlobal(noun));

  return (
    <TableHeaderBar
      slotLeft={
        <SearchBar
          searchTerm={searchTerm}
          handleSearchChange={onSearchChange}
          handleOnBlur={handleOnBlur}
          classes={{ input: classes.searchBar }}
          placeholder="Search employees..."
          useGoButton
          prevSearchTerm={prevSearchTerm}
        />
      }
      slotCenter={
        <Paginator
          page={offset / limit}
          setPage={(page) => setOffset(page * limit)}
          pageSize={limit}
          setPageSize={setLimit}
          items={totalCount}
        />
      }
      slotRight={
        <>
          {canUserLevel && pathname.includes("organization") && (
            <div className={classes.toggleContainer}>
              <FormControlLabel
                className={classes.formControlLabel}
                checked={showLevelingColumn}
                control={<Switch />}
                label={
                  <AssembleTypography
                    variant="productParagraphMedium"
                    className={classes.levelingText}
                  >
                    Level Employees
                  </AssembleTypography>
                }
                onChange={(_e, checked) => onLevelingToggleChange(checked)}
                disabled={tabParam === PeopleTabParam.INACTIVE}
                data-cy="leveling-switch"
              />
            </div>
          )}

          <PreviewFeature flag={FeatureFlag.OrganizationHierarchyModule}>
            <AccessBoundary
              every={[Noun.Employee]}
              scope="global"
              verb={Verb.View}
            >
              <OrganizationHierarchyModal />
            </AccessBoundary>
          </PreviewFeature>

          <div className={classes.settingsContainer}>
            <PeopleSettingsContainer
              onCompComponentChange={onCompComponentChange}
              compComponentSelection={compComponentSelection}
              showEquityGrants={showEquityGrants}
              payTypeSelection={payTypeSelection}
              onPayTypeToggleChange={onPayTypeToggleChange}
              equityDisplayUnitType={equityDisplayUnitType}
              onEquityToggleChange={onEquityToggleChange}
            />
            {canExport && <ExportCSVButton disabled={rowCount === 0} />}
          </div>
        </>
      }
      inContainer
    />
  );
}
