import { gql } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  EquityVestingImportDialog_employee as Employee,
  EquityVestingImportDialog_organization as Organization,
} from "../../../__generated__/graphql";
import EquityCsvIllustration from "../../../assets/svgs/illustrations/integrations/equity-csv.svg?react";
import { GRAY_6 } from "../../../theme";
import {
  EmployeeEquityGrantRow,
  EquityImportButton,
} from "./EquityImportButton";
import {
  EquityGrantVestingEventRow,
  EquityVestingImportButton,
} from "./EquityVestingImportButton";

interface Props {
  open: boolean;
  employees: Employee[];
  orgData: Organization | undefined;
  onGrantSubmit: (data: Array<EmployeeEquityGrantRow>) => unknown;
  onVestingSubmit: (data: Array<EquityGrantVestingEventRow>) => unknown;
  onClose: () => unknown;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  content: {
    display: "flex",
    border: `1px solid ${GRAY_6}`,
    borderRadius: theme.spacing(1),
    alignItems: "center",
  },
  illustration: {
    marginLeft: theme.spacing(2),
  },
  stepsContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

export function EquityVestingImportDialog({
  open,
  employees,
  orgData,
  onVestingSubmit,
  onGrantSubmit,
  onClose,
}: Props): JSX.Element {
  const classes = useStyles();

  const employeesHaveEquity = employees.some(
    (emp) => emp.equityHoldings.grants && emp.equityHoldings.grants.length > 0
  );

  return (
    <Dialog open={open} onClose={onClose} disableEnforceFocus maxWidth="lg">
      <DialogTitle>Add or Update Employee Equity Data</DialogTitle>
      <DialogContent className={classes.root}>
        <div className={classes.content}>
          <div className={classes.illustration}>
            <EquityCsvIllustration />
          </div>
          <div className={classes.stepsContainer}>
            <Typography variant="h6">
              Step 1. Add or Update Employee Equity Grants
            </Typography>
            <Typography variant="body2">
              This will add or update the equity grants for all of the employees
              in the CSV.
            </Typography>
            <div className={classes.button}>
              <EquityImportButton
                employees={employees}
                handleChange={onGrantSubmit}
                handleCancel={onClose}
                lastUploadDate={orgData?.lastEquityGrantsUploadDate}
                isDialogButton
              />
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <div className={classes.illustration}>
            <EquityCsvIllustration />
          </div>
          <div className={classes.stepsContainer}>
            <Typography variant="h6">
              Step 2. Add or Update Employee Equity Grant Vesting Details
            </Typography>
            <Typography variant="body2">
              This will add or update the equity grant vesting events for all of
              the employees in the CSV.
            </Typography>
            <div className={classes.button}>
              <EquityVestingImportButton
                employees={employees}
                handleChange={onVestingSubmit}
                disabled={!employeesHaveEquity}
                lastUploadDate={
                  orgData?.lastEquityVestingEventsUploadDate ?? null
                }
                isDialogButton
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EquityVestingImportDialog.fragments = {
  employee: gql`
    ${EquityVestingImportButton.fragments.employee}
    fragment EquityVestingImportDialog_employee on Employee {
      id
      ...EquityVestingImportButton_employee
    }
  `,
  organization: gql`
    ${EquityVestingImportButton.fragments.organization}
    fragment EquityVestingImportDialog_organization on Organization {
      id
      lastEquityGrantsUploadDate
      ...EquityVestingImportButton_organization
    }
  `,
};
