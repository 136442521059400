import { isEmptyString } from "@asmbl/shared/utils";
import { createContext, ReactNode, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FilterParam } from "src/models/FilterParams";
import { useURLSearchParams } from "src/models/URLSearchParams";
import { RelativeCompRecStatus } from "src/__generated__/graphql";

type StatusSelectType = {
  selectedStatus: string;
  setSelectedStatus: (status: string) => unknown;
};

export const StatusSelectContext = createContext<StatusSelectType>({
  selectedStatus: "ALL",
  setSelectedStatus: () => {
    // empty default
  },
});

export const StatusSelectProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const navigate = useNavigate();
  const urlSearchParams = useURLSearchParams();

  const selectedStatus = urlSearchParams.get(FilterParam.STATUS) ?? "ALL";

  useEffect(() => {
    if (!isValidUrlStatus(selectedStatus)) {
      navigate(
        { search: urlSearchParams.delete(FilterParam.STATUS).toString() },
        { replace: true }
      );
    }
  }, [navigate, selectedStatus, urlSearchParams]);

  const setSelectedStatus = (status: string) => {
    if (isEmptyString(status) || status === "ALL") {
      navigate({
        search: urlSearchParams.delete(FilterParam.STATUS).toString(),
      });
    } else {
      navigate({
        search: urlSearchParams.set(FilterParam.STATUS, status).toString(),
      });
    }
  };

  function isValidUrlStatus(status: string): boolean {
    return (
      Object.values(RelativeCompRecStatus).includes(
        status as RelativeCompRecStatus
      ) || status === "ALL"
    );
  }

  return (
    <StatusSelectContext.Provider value={{ selectedStatus, setSelectedStatus }}>
      {children}
    </StatusSelectContext.Provider>
  );
};

export const useStatusSelect = (): StatusSelectType => {
  return useContext(StatusSelectContext);
};
