import { Currency } from "@asmbl/shared/currency";
import { GRAY_4 } from "../../theme";

import { formatCurrency } from "@asmbl/shared/money";
import {
  ComposedChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { GRAY_6 } from "src/theme";
import { Range } from "src/utils";
import { computeInterval } from "./BandVisualization2";

interface BandVisualizationHeaderProps {
  totalCompRange: Range;
  marketDataRange: Range;
  height?: number;
  color?: string;
  selectedCurrency: Currency;
}

export default function BandVisualizationHeader2({
  totalCompRange,
  height,
  selectedCurrency,
  marketDataRange,
}: BandVisualizationHeaderProps): JSX.Element {
  if (
    !Number.isFinite(totalCompRange.min) ||
    !Number.isFinite(totalCompRange.max)
  ) {
    return <></>;
  }

  const min = Math.min(totalCompRange.min, marketDataRange.min);
  const max = Math.max(totalCompRange.max, marketDataRange.max);

  const totalCompSegment = (max - min) / 50;

  const interval = computeInterval(totalCompSegment);

  const domain = [
    (Math.floor(min / interval) - 3) * interval,
    (Math.ceil(max / interval) + 3) * interval,
  ];

  const dashedReferenceLines = new Array(
    Math.ceil((domain[1] - domain[0]) / (interval * 10))
  )
    .fill(0)
    .map((_, i) => domain[0] + i * interval * 10)
    .slice(1);

  return (
    <ResponsiveContainer
      width="100%"
      height={height}
      style={{
        overflow: "hidden",
      }}
    >
      <ComposedChart margin={{ top: 0, left: 1, right: 0, bottom: 0 }}>
        {dashedReferenceLines.map((x, i) => (
          <ReferenceLine
            key={`dashed-${i}`}
            strokeDasharray="3 3"
            strokeWidth={1}
            stroke={GRAY_4}
            segment={[
              {
                x,
                y: -1,
              },
              {
                x,
                y: 1,
              },
            ]}
          >
            <Label
              formatter={function (value: string) {
                return formatCurrency(
                  { value: Number(value), currency: selectedCurrency.code },
                  {
                    notation: "compact",
                    minimumSignificantDigits: 1,
                    maximumSignificantDigits: 3,
                  }
                );
              }}
              dx={-20}
              dy={-3}
              style={{
                fontSize: "10px",
                fontWeight: 700,
                color: GRAY_6,
              }}
            >
              {x}
            </Label>
          </ReferenceLine>
        ))}

        <XAxis
          type="number"
          dataKey="compBandPoint"
          xAxisId={0}
          domain={domain}
          hide
        />

        <YAxis dataKey={"y"} domain={[-1, 1]} hide />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
