import { gql } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { makeStyles, TableCell } from "@material-ui/core";
import {
  CondensedBandPlacementCell_band as CashBand,
  CondensedBandPlacementCell_cash as CashCompensation,
  CondensedBandPlacementCell_employee as Employee,
  PayPeriodType,
} from "../../__generated__/graphql";
import { isBandPointDefined } from "../../models/BandPoint";
import { nameToCashCompType, TotalCash } from "../../models/CashCompensation";
import { NullCompSlider } from "../CompSlider/NullCompSlider";
import { TotalCashCondensedSlider } from "../CompSlider/TotalCashCompensation/TotalCashCondensedSlider";
import { useCompStructure } from "../CompStructureContext";
import { useFeatureFlags } from "../FeatureContext";

interface Props {
  employee: Employee;
  cash: TotalCash<CashCompensation> | undefined;
  payTypeSelection: PayPeriodType;
  className?: string;
}

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "center",
  },
}));

export function CondensedBandPlacementCell({
  employee,
  cash,
  payTypeSelection,
  className,
}: Props): JSX.Element {
  const classes = useStyles();
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const bands = employee.adjustedCashBands;
  const isHourly =
    employee.activeEmployment?.payPeriod === PayPeriodType.HOURLY &&
    compStructure?.allowHourlyEmployees === true &&
    isEnabled(FeatureFlag.HourlyEmployees);
  if (cash === undefined || bands === null) return <NullCell />;

  const relevantBands = getRelevantBands(cash, bands);

  const bandPointsToDisplay = relevantBands.flatMap((b) =>
    b.bandPoints.filter(isBandPointDefined)
  );

  if (relevantBands.length === 0 || bandPointsToDisplay.length === 0) {
    return <NullCell />;
  }

  return (
    <TableCell className={className} align="center" aria-label="Band Placement">
      <div className={classes.container}>
        <TotalCashCondensedSlider
          cash={cash}
          bands={relevantBands}
          position={employee.activeEmployment?.position ?? undefined}
          isHourly={isHourly}
          payTypeSelection={payTypeSelection}
        />
      </div>
    </TableCell>
  );
}

CondensedBandPlacementCell.fragments = {
  employee: gql`
    ${TotalCashCondensedSlider.fragments.band}
    ${TotalCashCondensedSlider.fragments.cash}
    ${TotalCashCondensedSlider.fragments.position}
    fragment CondensedBandPlacementCell_employee on Employee2 {
      activeEmployment {
        id
        payPeriod
        position {
          ...TotalCashCondensedSlider_position
        }
      }
      adjustedCashBands {
        ...TotalCashCondensedSlider_band
      }
      activeCashCompensation {
        annualCashEquivalent
        hourlyCashEquivalent
        unit
        ...TotalCashCondensedSlider_cash
      }
    }
  `,
  // These two fragments below are just used to generate a type we can use
  cash: gql`
    ${TotalCashCondensedSlider.fragments.cash}
    fragment CondensedBandPlacementCell_cash on CashCompensation2 {
      ...TotalCashCondensedSlider_cash
    }
  `,
  band: gql`
    ${TotalCashCondensedSlider.fragments.band}
    fragment CondensedBandPlacementCell_band on AdjustedCashBand {
      ...TotalCashCondensedSlider_band
    }
  `,
};

/**
 * Imagine a position has two bands: Salary and Commission.
 * If an employee only has one CashCompensation, Salary, then we would still
 * want to show the commission band for their position.
 *
 * However, if the user has expressly de-selected "Commission" from the People
 * Page, we don't want to see the commission band.
 */
function getRelevantBands(
  cash: TotalCash<CashCompensation>,
  bands: CashBand[]
): CashBand[] {
  return bands.filter((b) =>
    cash.selectedTypes.has(nameToCashCompType(b.name))
  );
}

function NullCell(): JSX.Element {
  const classes = useStyles();
  return (
    <TableCell align="right" aria-label="Band Placement">
      <div className={classes.container}>
        <NullCompSlider variant="condensed" />
      </div>
    </TableCell>
  );
}
