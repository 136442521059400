import {
  Link,
  makeStyles,
  Tooltip,
  TooltipProps,
  Typography,
} from "@material-ui/core";
import { ReactNode } from "react";
import { useIntercom } from "react-use-intercom";
import { BLUE_3, GRAY_5, WHITE } from "../theme";
import { ArrowRightIcon } from "./AssembleIcons/Small/ArrowRightIcon";
import { AssembleLink } from "./AssembleLink";

export interface Props {
  title?: string;
  body: JSX.Element | string;
  ctaUrl?: string;
  ctaLabel?: string;
  width?: string;
  placement?: TooltipProps["placement"];
  disabled?: boolean;
  newTab?: boolean;
  openIntercom?: boolean;
  hideArrow?: boolean;
  enterDelay?: number;
  children: JSX.Element;
}

interface StyleProps {
  width: string;
}

const useStyles = makeStyles((theme) => ({
  tooltip: {
    padding: "20px 20px 20px 20px",
    minWidth: ({ width }: StyleProps) => width,
  },

  tooltipTitle: {
    color: GRAY_5,
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: 700,
  },
  tooltipText: {
    color: GRAY_5,
    whitespace: "pre-wrap",

    "& b": {
      color: WHITE,
      fontWeight: "inherit",
    },
  },
  learnMore: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "3px",
    paddingTop: "8px",
    "& a": {
      color: BLUE_3,
    },
  },
  ctaLink: {
    color: BLUE_3,
    fontWeight: 700,
    fontSize: "14px",

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: theme.spacing(0.5),
  },
  arrow: {
    "&::before": {
      boxShadow: "none",
    },
  },
}));

/*
  A tooltip with a title line and a moderately long text body.
  We have several of these tooltips across the app, explaining
  things like location mapping, primary roles, and leveling.

  Some tooltips have text highlighted in white in their body.
  To achieve this, use a `<b>` tag, and this component will
  style it accordingly.
 */
export function ExplanatoryTooltip({
  title,
  body,
  ctaUrl,
  ctaLabel = "Learn more",
  width = "100%",
  placement,
  disabled = false,
  newTab = false,
  openIntercom = false,
  hideArrow = false,
  enterDelay,
  children,
}: Props): JSX.Element {
  const classes = useStyles({ width });
  if (disabled) {
    return children;
  }

  const helpLink: ReactNode = openIntercom ? (
    <IntercomHelpLink ctaLabel={ctaLabel} width={width} />
  ) : ctaUrl !== undefined ? (
    <Typography
      className={classes.learnMore}
      variant="body2"
      onClick={(e) => e.stopPropagation()}
    >
      <AssembleLink to={ctaUrl} newTab={newTab} className={classes.ctaLink}>
        {ctaLabel} <ArrowRightIcon inline color={BLUE_3} />
      </AssembleLink>
    </Typography>
  ) : null;

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      title={
        <>
          {title !== undefined && (
            <Typography
              className={classes.tooltipTitle}
              variant="body2"
              paragraph
            >
              {title}
            </Typography>
          )}
          <Typography
            className={classes.tooltipText}
            variant="body2"
            component="span"
          >
            {body}
          </Typography>
          {helpLink}
        </>
      }
      placement={placement}
      arrow={!hideArrow}
      interactive
      enterDelay={enterDelay}
    >
      {children}
    </Tooltip>
  );
}

function IntercomHelpLink({
  ctaLabel,
  width,
}: {
  ctaLabel: string;
  width: string;
}): JSX.Element {
  const classes = useStyles({ width });
  const intercom = useIntercom();

  return (
    <Typography className={classes.learnMore} variant="body2">
      <Link onClick={intercom.show} className={classes.ctaLink}>
        {ctaLabel} <ArrowRightIcon inline color={BLUE_3} />
      </Link>
    </Typography>
  );
}
