import { Prisma } from "@prisma/client";

/**
 * Calculate the midRange of an array.
 * */
export const getMidRange = (values: number[]): number => {
  if (values.length === 0) return 0;
  if (values.length === 1) return values[0];

  const min = Math.min(...values);
  const max = Math.max(...values);

  return (min + max) / 2.0;
};

export function getPercentage(x: number, y: number): number {
  return (x / y) * 100;
}

export function asPercentage(x: number | undefined): string | undefined {
  return x !== undefined && !isNaN(x) && isFinite(x)
    ? (x * 100).toFixed(2)
    : undefined;
}

/**
 * Calculate the min, p25, mid, p75, and max of a sorted array (asc order)
 */
export const getFiveNumberSummary = (data: Prisma.Decimal[]): number[] => {
  if (data.length === 0) return Array<number>(5).fill(0);

  return [
    Prisma.Decimal.min(...data).toNumber(),
    getPercentile(data, 0.25).toNumber(),
    getPercentile(data, 0.5).toNumber(),
    getPercentile(data, 0.75).toNumber(),
    Prisma.Decimal.max(...data).toNumber(),
  ];
};

/**
 *  Calculate the percentile of a sorted array (asc order)
 * implementation from https://gist.github.com/IceCreamYou/6ffa1b18c4c8f6aeaad2
 */
export const getPercentile = (
  data: Prisma.Decimal[],
  percentile: number
): Prisma.Decimal => {
  if (data.length === 0) return new Prisma.Decimal(0);
  if (percentile <= 0) return data[0];
  if (percentile >= 1) return data[data.length - 1];

  const index = (data.length - 1) * percentile,
    lower = Math.floor(index),
    upper = lower + 1,
    weight = index % 1;

  if (upper >= data.length) return data[lower];

  return data[lower]
    .times(1 - weight)
    .plus(data[upper].times(new Prisma.Decimal(weight)));
};

export const getMedian = (data: number[]): number => {
  if (data.length === 0) {
    throw new Error("Cannot calculate median of an empty array");
  }

  const sorted = data.sort((a, b) => a - b);
  const half = Math.floor(sorted.length / 2);

  return sorted.length % 2 === 0
    ? (sorted[half - 1] + sorted[half]) / 2
    : sorted[half];
};

export const getAverage = (data: number[]): number => {
  return data.reduce((a, b) => a + b, 0) / data.length;
};
