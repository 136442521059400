import { CompCycleHierarchyConfiguration } from "src/pages/CompCycle/HierarchyConfiguration/CompCycleHierarchyConfiguration";
import CompCyclePerformanceRatings from "../PerformanceRatings/CompCyclePerformanceRatings";
import { CompCycleUploadRequestsLoadingBoundary } from "../UploadRequests/CompCycleUploadRequestsLoadingBoundary";
import { CompCycleSettings } from "./CompCycleSettings";
import { CompCycleData, CompCycleDataChangeHandler, Valuation } from "./types";

interface Props {
  step: number;
  data: CompCycleData;
  valuation: Valuation;
  handleChange: CompCycleDataChangeHandler;
  onSubmit: (data: CompCycleData) => Promise<unknown>;
  updateStep: (step: number) => unknown;
  hierarchyView: boolean;
  setHierarchyView: (renderSidebar: boolean) => void;
}

export default function CompCycleStep({
  step,
  data,
  valuation,
  handleChange,
  updateStep,
  onSubmit,
  hierarchyView,
  setHierarchyView,
}: Props): JSX.Element | null {
  const handleClosingHierarchyStep = () => setHierarchyView(false);
  const handleHierarchyView = () => setHierarchyView(true);

  switch (step) {
    case 0:
      return (
        <CompCycleSettings
          currentStep={step}
          data={data}
          valuation={valuation}
          handleChange={handleChange}
          onNext={() => updateStep(1)}
        />
      );
    case 1:
      return (
        <CompCyclePerformanceRatings
          mode="create"
          handleChange={handleChange}
          onBack={() => updateStep(0)}
          onNext={() => updateStep(2)}
        />
      );
    case 2:
      return (
        <CompCycleHierarchyConfiguration
          handleChange={handleChange}
          hierarchyView={hierarchyView}
          updateStep={updateStep}
          onHierarchyView={handleHierarchyView}
          onConfigureHome={handleClosingHierarchyStep}
          wizardData={data}
        />
      );
    case 3:
      return (
        <CompCycleUploadRequestsLoadingBoundary
          mode="create"
          compCycleData={data}
          handleChange={handleChange}
          onSubmit={onSubmit}
          onBack={() => updateStep(2)}
        />
      );
    default:
      return null;
  }
}
