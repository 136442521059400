import { useQuery } from "@apollo/client";
import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { BandIcon } from "src/components/AssembleIcons/Brand/BandIcon";
import { GRAY_4 } from "src/theme";
import {
  ContactUs,
  NoCompStructure,
} from "../../components/EmptyState/EmptyState";
import Loading from "../../components/Settings/Loading";
import { COMP_STRUCTURE_QUERY } from "../../queries";
import { NonNull } from "../../utils";
import { GetCompStructure } from "../../__generated__/graphql";
import { PageContainer } from "./PageContainer";

type CompStructure = NonNull<GetCompStructure["compStructure"]>;

const useStyles = makeStyles((theme) => ({
  bandPointsList: { paddingRight: 0 },
  bandIcon: { marginRight: theme.spacing(2) },
}));

export function BandPoints(): JSX.Element {
  const { data: compStructureData, loading: compStructureLoading } =
    useQuery<GetCompStructure>(COMP_STRUCTURE_QUERY);

  if (compStructureLoading) return <Loading />;

  return (
    <PageContainer
      header="Compensation Band Points"
      description={
        <>
          Each of your bands have the following points. Please {<ContactUs />}{" "}
          if you'd like to change them.
        </>
      }
      centerContent
    >
      {compStructureData?.compStructure ? (
        <BandPointsList compStructure={compStructureData.compStructure} />
      ) : (
        <NoCompStructure />
      )}
    </PageContainer>
  );
}

function BandPointsList({ compStructure }: { compStructure: CompStructure }) {
  const classes = useStyles();

  return (
    <>
      {compStructure.bandPointTypes.length > 0 ? (
        <List className={classes.bandPointsList}>
          {compStructure.bandPointTypes.map((bandPointType) => (
            <ListItem key={bandPointType}>
              <BandIcon
                className={classes.bandIcon}
                color={GRAY_4}
                width="24px"
                height="24px"
              />
              <ListItemText primary={bandPointType} />
            </ListItem>
          ))}
        </List>
      ) : (
        <NoCompStructure />
      )}
    </>
  );
}
