import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { NoSearchResults } from "../../../components/EmptyState/EmptyState";
import { SearchByName } from "../../../components/Search/SearchByName";
import { MarketsList_market } from "../../../__generated__/graphql";
import { MarketCard } from "./MarketCard";
import { MarketsActions } from "./MarketsActions";

type Props = {
  markets: MarketsList_market[];
};

const useStyles = makeStyles((theme) => ({
  list: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

export function MarketsList({ markets }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <SearchByName items={markets}>
      {(matchingMarkets, searchTerm, onSearchTermChange) => (
        <>
          <MarketsActions
            searchTerm={searchTerm}
            onSearchTermChange={onSearchTermChange}
          />
          <ul className={classes.list}>
            {matchingMarkets.map((market) => (
              <MarketCard
                key={market.id}
                market={market}
                isOnlyMarket={markets.length === 1}
              />
            ))}
          </ul>
          {searchTerm !== "" && matchingMarkets.length === 0 && (
            <NoSearchResults />
          )}
        </>
      )}
    </SearchByName>
  );
}

MarketsList.fragments = {
  market: gql`
    ${MarketCard.fragments.market}
    fragment MarketsList_market on Market {
      ...MarketCard_market
      id
    }
  `,
};
