import { gql } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { Checkbox, makeStyles, TableCell, TableRow } from "@material-ui/core";
import clsx from "clsx";
import { useTrack } from "src/analytics";
import { CalendarIcon } from "src/components/AssembleIcons/Brand/CalendarIcon";
import { ClockIcon } from "src/components/AssembleIcons/Brand/ClockIcon";
import { useCompStructure } from "src/components/CompStructureContext";
import { PreviewFeature, useFeatureFlags } from "src/components/FeatureContext";
import {
  LadderDetailTable_position,
  LadderDetailTableHeader_permissionSettings as OrganizationPermissionSettings,
} from "../../__generated__/graphql";
import { AssembleTypography } from "../../components/AssembleTypography";
import { Order, SortableTableHeaderCell } from "../../components/SortableTable";
import { isSupportedCompensationType } from "../../models/AnonymizedCompensation";
import { GRAY_1, GRAY_3, GRAY_6, GRAY_7, PURPLE_1, WHITE } from "../../theme";
import { AnonymizedCompensationSwitch } from "./AnonymizedCompensation/AnonymizedCompensationSwitch";
import { useExcludedBandNames } from "./ExcludedBandNameContext";

const useStyles = makeStyles(() => ({
  tableHeaderCell: { background: WHITE },
  bandHeaderCell: { borderLeft: `1px solid ${GRAY_6}` },
  hourlySelectCell: {
    borderLeft: `1px solid ${GRAY_6}`,
    borderTopRightRadius: "4px",
  },
  buttonsContainer: {
    display: "flex",
    height: "100%",
  },
  button: {
    flex: 1,
    border: "none",
    background: "none",
    padding: 0,
    cursor: "pointer",
    fontWeight: 500,
    lineHeight: "16px",
    fontSize: "12px",
    letterSpacing: "-0.25px",
    color: GRAY_3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    textTransform: "uppercase",
    "&:first-child": {
      borderRight: `1px solid ${GRAY_6}`,
    },
    "&:hover": {
      color: PURPLE_1,
    },
  },
  selected: {
    background: GRAY_7,
    color: GRAY_1,
  },
}));

type Key = keyof LadderDetailTable_position | "totalCompMax";

type Props = {
  selectedRows: Set<number>;
  totalPositions: number;
  isComparing: boolean;
  order: Order;
  orderBy: Key;
  bandNames: string[];
  handleSelectedAll: () => void;
  handleRequestSort: (property: Key) => void;
  anonymizedView: boolean;
  permissionSettings: OrganizationPermissionSettings;
  updateAnonymizedView: (viewAnonymized: boolean) => unknown;
  hourly: boolean;
  setHourly: React.Dispatch<React.SetStateAction<boolean>>;
};

export function LadderDetailTableHeader({
  selectedRows,
  totalPositions,
  isComparing,
  order,
  orderBy,
  bandNames,
  handleSelectedAll,
  handleRequestSort,
  anonymizedView,
  permissionSettings,
  updateAnonymizedView,
  hourly,
  setHourly,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const classes = useStyles();
  const { isEnabled } = useFeatureFlags();
  const { compStructure } = useCompStructure();
  const { excludedBandNames, toggleBandExclusion } = useExcludedBandNames();
  const allowsHourly =
    compStructure?.allowHourlyEmployees &&
    isEnabled(FeatureFlag.HourlyEmployees);
  // since the anonymized view only supports a few compensation types,
  // we will toggle the unsupported data types off when toggling between views
  const toggleAnonymizedView = () => {
    const enabled = !anonymizedView;
    updateAnonymizedView(enabled);

    bandNames.forEach((bandName) => {
      if (
        !isSupportedCompensationType(bandName) &&
        !excludedBandNames.has(bandName)
      ) {
        toggleBandExclusion(bandName);
      }
    });

    trackEvent({
      object: "Anonymized Comp",
      action: enabled ? "Shown" : "Hidden",
    });
  };

  return (
    <TableRow>
      <TableCell
        className={classes.tableHeaderCell}
        variant="head"
        width="5%"
        padding="checkbox"
        align="center"
      >
        {selectedRows.size > 0 ? (
          <Checkbox
            color="primary"
            size="small"
            checked={selectedRows.size > 0}
            indeterminate={
              selectedRows.size > 0 && selectedRows.size < totalPositions
            }
            onChange={handleSelectedAll}
            disabled={isComparing}
          />
        ) : null}
      </TableCell>
      <SortableTableHeaderCell
        className={classes.tableHeaderCell}
        cellTitle={`Position (${totalPositions})`}
        orderByField="name"
        order={order}
        isSelected={orderBy === "name"}
        handleRequestSort={handleRequestSort}
        width={anonymizedView ? "30%" : "35%"}
        noWrap
      />
      <SortableTableHeaderCell
        className={classes.tableHeaderCell}
        cellTitle="Level"
        orderByField="level"
        order={order}
        isSelected={orderBy === "level"}
        handleRequestSort={handleRequestSort}
        width="5%"
        noWrap
      />
      {anonymizedView && (
        <TableCell
          className={classes.tableHeaderCell}
          variant="head"
          width="5%"
        >
          <AssembleTypography variant="productTableHeader" noWrap>
            People
          </AssembleTypography>
        </TableCell>
      )}

      <PreviewFeature
        fallback={
          <SortableTableHeaderCell
            className={clsx(classes.tableHeaderCell, classes.bandHeaderCell)}
            cellTitle="Band"
            orderByField="totalCompMax"
            order={order}
            isSelected={orderBy === "totalCompMax"}
            handleRequestSort={handleRequestSort}
            width="55%"
            colSpan={2}
            noWrap
          >
            <AnonymizedCompensationSwitch
              organizationPermissionSettings={permissionSettings}
              anonymizedView={anonymizedView}
              toggleAnonymizedView={toggleAnonymizedView}
            />
          </SortableTableHeaderCell>
        }
        flag={FeatureFlag.HourlyEmployees}
      >
        <SortableTableHeaderCell
          className={clsx(classes.tableHeaderCell, classes.bandHeaderCell)}
          cellTitle="Band"
          orderByField="totalCompMax"
          order={order}
          isSelected={orderBy === "totalCompMax"}
          handleRequestSort={handleRequestSort}
          width="55%"
          colSpan={anonymizedView || !allowsHourly ? 2 : 1}
          noWrap
        >
          <AnonymizedCompensationSwitch
            organizationPermissionSettings={permissionSettings}
            anonymizedView={anonymizedView}
            toggleAnonymizedView={toggleAnonymizedView}
          />
        </SortableTableHeaderCell>
        {!anonymizedView && allowsHourly && (
          <TableCell
            className={clsx(classes.tableHeaderCell, classes.hourlySelectCell)}
            variant="head"
            width="100%"
            padding="none"
          >
            <div className={classes.buttonsContainer}>
              <button
                className={clsx(classes.button, !hourly && classes.selected)}
                onClick={() => setHourly(false)}
              >
                <CalendarIcon inherit />
                Annual
              </button>
              <button
                className={clsx(classes.button, hourly && classes.selected)}
                onClick={() => setHourly(true)}
              >
                <ClockIcon inherit />
                Hourly
              </button>
            </div>
          </TableCell>
        )}
      </PreviewFeature>
    </TableRow>
  );
}

LadderDetailTableHeader.fragments = {
  permissionSettings: gql`
    ${AnonymizedCompensationSwitch.fragments.permissionSettings}
    fragment LadderDetailTableHeader_permissionSettings on PermissionSettings {
      id
      ...AnonymizedCompensationSwitch_permissionSettings
    }
  `,
};
