import { isEmptyString } from "@asmbl/shared/utils";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { useState } from "react";
import { EditorStatus } from "../../../../constants";
import { useRevokeUserInvites } from "../../../../mutations/User";
import { GRAY_2 } from "../../../../theme";
import { AssembleTypography } from "../../../AssembleTypography";
import { ControlledSaveButton } from "../../../Form/SaveButton";

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(4) },
  context: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: "0px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    columnGap: theme.spacing(2),
  },
  cancelInviteButton: { width: theme.spacing(17) },
}));

type Props = {
  toggleUserInviteCancellationModal: () => void;
  email: string;
};

export function UserInviteCancellationModal({
  toggleUserInviteCancellationModal,
  email,
}: Props): JSX.Element {
  const classes = useStyles();
  const revokeInvite = useRevokeUserInvites();
  const [userInviteCancellationError, setUserInviteCancellationError] =
    useState("");
  const [editorStatus, setEditorState] = useState(EditorStatus.EDITING);

  const onCancelInvite = async () => {
    setEditorState(EditorStatus.SAVING);

    const result = await revokeInvite([email]);

    if (result == null || result.revokeUserInvites.length === 0) {
      setEditorState(EditorStatus.ERROR);
      setUserInviteCancellationError("System error, please try again");
      setTimeout(() => setEditorState(EditorStatus.EDITING), 3_000);
    } else {
      setEditorState(EditorStatus.EDITING);
      toggleUserInviteCancellationModal();
    }
  };

  return (
    <Dialog className={classes.root} open={true} maxWidth="sm">
      <DialogTitle>
        <AssembleTypography variant="h4">
          Cancel invite to Assemble
        </AssembleTypography>
      </DialogTitle>
      <DialogContent className={classes.context}>
        <AssembleTypography variant="productParagraphLarge" textColor={GRAY_2}>
          It will no longer be possible to use the invitation link sent to&nbsp;
          {email} to register with Assemble.
        </AssembleTypography>

        {!isEmptyString(userInviteCancellationError) && (
          <AssembleTypography variant="productParagraphLarge" color="error">
            {userInviteCancellationError}
          </AssembleTypography>
        )}

        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            onClick={toggleUserInviteCancellationModal}
          >
            Cancel
          </Button>
          <ControlledSaveButton
            className={classes.cancelInviteButton}
            onSave={onCancelInvite}
            hideEndIcon
            labels={{ default: "Cancel Invite" }}
            editorStatus={editorStatus}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
