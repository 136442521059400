import { gql, useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { PageContainer } from "../../../pages/Settings/PageContainer";
import { AccessControlQuery } from "../../../__generated__/graphql";
import Loading from "../Loading";
import { AccessControlSubheader } from "./AccessControlSubHeader";
import { AccessControlTable } from "./Table/AccessControlTable";
import { UserInviteBanner } from "./UserInvite/UserInviteBanner";
import { reshapeData } from "./utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
  },
}));

export function AccessControl(): JSX.Element | null {
  const classes = useStyles();

  const [latestUserInvitationId, setLatestUserInvitationId] = useState<
    null | number
  >(null);

  const { data, loading } = useQuery<AccessControlQuery>(AccessControl.query, {
    errorPolicy: "all",
    pollInterval: 10_000,
  });

  if (!data) {
    return loading ? <Loading /> : <>An error occurred</>;
  }

  const currentAndPendingUsers = reshapeData(
    data.userRecords,
    data.activeAssembleUserInvitations
  );

  return (
    <>
      {latestUserInvitationId != null && (
        <UserInviteBanner userInvitationId={latestUserInvitationId} />
      )}

      <PageContainer
        header="User Permissions"
        description="Customize user roles and access across the different areas in your organization."
      >
        <div className={classes.root}>
          <AccessControlSubheader
            users={data.userRecords}
            setLatestUserInvitationId={setLatestUserInvitationId}
          />
          <AccessControlTable
            users={currentAndPendingUsers}
            teamCompensationAccess={
              data.organization.permissionSettings.teamCompensationAccess
            }
          />
        </div>
      </PageContainer>
    </>
  );
}

AccessControl.query = gql`
  ${AccessControlSubheader.fragments.users}
  ${AccessControlTable.fragments.activeUserInvitations}
  ${AccessControlTable.fragments.users}
  ${AccessControlTable.fragments.permissionSettings}
  query AccessControlQuery {
    userRecords {
      id
      ...AccessControlSubheader_userRecord
      ...AccessControlTable_userRecords
    }
    activeAssembleUserInvitations {
      id
      ...AccessControlTable_activeUserInvitations
    }
    organization {
      id
      permissionSettings {
        id
        ...AccessControlTable_permissionSettings
      }
    }
  }
`;
