import { gql } from "@apollo/client";
import { makeStyles, Typography } from "@material-ui/core";
import { CashIcon } from "src/components/AssembleIcons/Small/CashIcon";
import { NumberIcon } from "src/components/AssembleIcons/Small/NumberIcon";
import { PercentageIcon } from "src/components/AssembleIcons/Small/PercentageIcon";
import { useAuth } from "../../../../components/Auth/AuthContext";
import {
  CompCycleDisplay,
  CompCycleGrouping,
} from "../../../../components/CompCycle/types";
import { ComplexToggle } from "../../../../components/ComplexToggle";
import { useCompStructure } from "../../../../components/CompStructureContext";
import { AdjustSettingsContainer } from "../../../../components/Table/AdjustSettingsContainer";
import { TableHeaderBar } from "../../../../components/Table/TableHeaderBar";
import { getToggleOptions } from "../../../../models/CompCycle";
import { GRAY_4, PURPLE_1 } from "../../../../theme";
import {
  BudgetsVsRequests_manager as Manager,
  EquityGrantMethod,
  TableHeader_compCycleData as CompCycleData,
} from "../../../../__generated__/graphql";
import { BackToMainButton } from "../../Budgets/TableHeader/BackToMainButton";
import { Equity } from "../BudgetsVsRequests";

const useStyles = makeStyles((theme) => ({
  settingsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: theme.spacing(1),
  },
  popOver: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(4),

    borderRadius: "5px",
    border: `2px solid ${PURPLE_1}`,
    boxSizing: "border-box",
    filter: "drop-shadow(0px 15px 25px rgba(10, 36, 64, 0.08))",
  },
  popOverSection: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
  },
  popOverText: {
    fontSize: "0.625rem",
    fontWeight: "bold",
    lineHeight: "0.875",
    color: GRAY_4,
    textTransform: "uppercase",
  },
  toggleOptions: {
    height: theme.spacing(4),
  },
}));

type Props = {
  compCycleId: number | null;
  manager: Manager | null;
  compCycleData: CompCycleData;
  subPage: CompCycleGrouping;
  onSubPageChange: (value: CompCycleGrouping) => unknown;
  varianceDisplay: CompCycleDisplay;
  setVarianceDisplay: (updatedVarianceDisplay: CompCycleDisplay) => void;
  equityDisplay: Equity;
  setEquityDisplay: (equityDisplay: Equity) => void;
};

export function TableHeader({
  compCycleId,
  manager,
  compCycleData,
  subPage,
  onSubPageChange,
  varianceDisplay,
  setVarianceDisplay,
  equityDisplay,
  setEquityDisplay,
}: Props): JSX.Element {
  const { employeeId } = useAuth();
  const { compStructure } = useCompStructure();

  const classes = useStyles();
  const toggleOptions = getToggleOptions(compCycleData);

  const shouldShowBackToMainButton =
    compCycleId !== null && manager !== null && employeeId !== manager.id;

  return (
    <TableHeaderBar
      slotLeft={
        <>
          {shouldShowBackToMainButton && (
            <BackToMainButton to={`/comp-cycles/${compCycleId}/compare`} />
          )}
        </>
      }
      slotCenter={
        <>
          {toggleOptions.length > 1 && (
            <ComplexToggle
              options={toggleOptions}
              selected={subPage}
              onChange={onSubPageChange}
            />
          )}
        </>
      }
      slotRight={
        <div className={classes.settingsContainer}>
          {equityDisplay === "cash" && (
            <ComplexToggle
              options={[
                ["absolute", <CashIcon key="cash" inherit />],
                ["percentage", <PercentageIcon key="percentage" inherit />],
              ]}
              selected={varianceDisplay}
              onChange={setVarianceDisplay}
            />
          )}
          {equityDisplay === "units" && (
            <ComplexToggle
              options={[
                ["absolute", <NumberIcon key="number" inherit />],
                ["percentage", <PercentageIcon key="percentage" inherit />],
              ]}
              selected={varianceDisplay}
              onChange={setVarianceDisplay}
            />
          )}

          {subPage === "equity" &&
            compStructure?.equityGrantMethod !== EquityGrantMethod.CASH && (
              <AdjustSettingsContainer
                context={
                  <div className={classes.popOver}>
                    <div className={classes.popOverSection}>
                      <Typography className={classes.popOverText}>
                        Show Equity
                      </Typography>
                      <div className={classes.toggleOptions}>
                        <ComplexToggle
                          options={[
                            ["cash", "Cash"],
                            ["units", "Units"],
                          ]}
                          selected={equityDisplay}
                          onChange={setEquityDisplay}
                        />
                      </div>
                    </div>
                  </div>
                }
              />
            )}
        </div>
      }
    />
  );
}

TableHeader.fragments = {
  compCycleData: gql`
    fragment TableHeader_compCycleData on CompCycle {
      allowSalary
      allowEquity
      allowBonus
      allowTargetCommission
      allowTargetRecurringBonus
      allowActualRecurringBonus
    }
  `,
};
