/// <reference types="vite/client" />
/// <reference types="segment-analytics" />

/**
 * We augment `window` with runtime configuration parameters.
 */
declare global {
  interface Window {
    /*
      While Vite will inject these variables directly for us, we still need to
      manually write them to the `window` object. This is because Vite writes
      the variables at *build* time, and we'd like to use the same image for PR
      Previews, ATeam, and production. In order to vary the values per
      environment, we construct one docker image and then write the vars in with
      an nginx script.
    */
    _env_?: {
      graphqlURL?: string;
      serverURL?: string;
      workOsAuthURL?: string;
      authProjectID?: string;
      authDomain?: string;
      authAPIKey?: string;
      canduClientToken?: string;
      canduPreAuthClientToken?: string;
      segmentWriteKey?: string;
      intercomAppId?: string;
      configcatKey?: string;
      flatFileKey?: string;
      exploKey?: string;
      sentryDSN?: string;
      envName?: string;
      buildRevision?: string;
    };
    analytics: SegmentAnalytics.AnalyticsJS;
    Candu?: {
      init(params: { clientToken: string; userId?: string }): unknown;
    };
  }

  interface ImportMetaEnv {
    readonly VITE_AUTH_API_KEY: string;
    readonly VITE_AUTH_DOMAIN: string;
    readonly VITE_AUTH_PROJECT_ID: string;
    readonly VITE_GRAPHQL_URL: string;
    readonly VITE_SERVER_URL: string;
    readonly VITE_WORK_OS_AUTH_URL: string;
    readonly VITE_CANDU_CLIENT_TOKEN?: string;
    readonly VITE_PREAUTH_CANDU_CLIENT_TOKEN?: string;
    readonly VITE_SEGMENT_WRITE_KEY: string;
    readonly VITE_INTERCOM_APP_ID: string;
    readonly VITE_CONFIGCAT_KEY: string;
    readonly VITE_FLAT_FILE_KEY: string;
    readonly VITE_EXPLO_KEY: string;
    readonly VITE_SENTRY_DSN?: string;
    readonly VITE_ENV_NAME?: string;
  }

  interface ImportMeta {
    readonly env: ImportMetaEnv;
  }
}

const isValidRuntimeValue = (
  runtimeValue: string | undefined
): runtimeValue is string =>
  runtimeValue !== undefined &&
  runtimeValue !== "" &&
  !runtimeValue.startsWith("$");

const getValue = (
  runtimeValue: string | undefined,
  fallback: string | undefined
): string => {
  if (isValidRuntimeValue(runtimeValue)) {
    return runtimeValue;
  }
  // Use the fallback from the build environment if provided.
  if (fallback !== undefined && fallback !== "") {
    return fallback;
  }
  throw Error(`Value not found`);
};

const env = import.meta.env;

export const authAPIKey = (): string =>
  getValue(window._env_?.authAPIKey, env.VITE_AUTH_API_KEY);
export const authDomain = (): string =>
  getValue(window._env_?.authDomain, env.VITE_AUTH_DOMAIN);
export const authProjectID = (): string =>
  getValue(window._env_?.authProjectID, env.VITE_AUTH_PROJECT_ID);
export const graphqlURL = (): string =>
  getValue(window._env_?.graphqlURL, env.VITE_GRAPHQL_URL);
export const serverURL = (): string =>
  getValue(window._env_?.serverURL, env.VITE_SERVER_URL);
export const workOsAuthURL = (): string =>
  getValue(window._env_?.workOsAuthURL, env.VITE_WORK_OS_AUTH_URL);
export const canduClientToken = (): string | undefined =>
  isValidRuntimeValue(window._env_?.canduClientToken)
    ? window._env_?.canduClientToken
    : undefined;
export const canduPreAuthClientToken = (): string | undefined =>
  isValidRuntimeValue(window._env_?.canduPreAuthClientToken)
    ? window._env_?.canduPreAuthClientToken
    : undefined;
export const segmentWriteKey = (): string =>
  getValue(window._env_?.segmentWriteKey, env.VITE_SEGMENT_WRITE_KEY);
export const intercomAppId = (): string =>
  getValue(window._env_?.intercomAppId, env.VITE_INTERCOM_APP_ID);
export const configcatKey = (): string =>
  getValue(window._env_?.configcatKey, env.VITE_CONFIGCAT_KEY);
export const flatFileKey = (): string =>
  getValue(window._env_?.flatFileKey, env.VITE_FLAT_FILE_KEY);
export const exploKey = (): string =>
  getValue(window._env_?.exploKey, env.VITE_EXPLO_KEY);
export const sentryDSN = (): string | undefined =>
  isValidRuntimeValue(window._env_?.sentryDSN)
    ? window._env_?.sentryDSN
    : undefined;
export const envName = (): string | undefined =>
  isValidRuntimeValue(window._env_?.envName)
    ? window._env_?.envName
    : undefined;
export const buildRevision = (): string =>
  getValue(window._env_?.buildRevision, "unknown");
