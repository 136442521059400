import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const CulpepperLogo = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_918_8011)">
        <rect width={width} height={height} rx="2" fill="#3178B9" />
        <g filter="url(#filter0_d_918_8011)">
          <path
            d="M8.07884 13.2266C4.9773 13.2266 2.93311 11.1931 2.93311 7.9733C2.93311 4.75352 4.86452 2.71997 8.07884 2.71997C10.6165 2.71997 12.6325 4.14628 12.999 6.68821H10.1512C9.88337 5.68556 9.10799 5.13481 8.07884 5.13481C6.59856 5.13481 5.86547 6.20807 5.86547 7.9733C5.86547 9.75266 6.59856 10.8118 8.07884 10.8118C9.16438 10.8118 9.98206 10.1904 10.1935 9.06069H13.0131C12.6889 11.758 10.6729 13.2266 8.07884 13.2266Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_918_8011"
          x="-0.0668945"
          y="1.71997"
          width="16.0801"
          height="16.5067"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_918_8011"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_918_8011"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_918_8011">
          <rect width={width} height={height} rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </ColorableSVG>
);
