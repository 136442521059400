import { gql } from "@apollo/client";
import { mapMaybe } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import { OrgChart } from "d3-org-chart";
import { ReactNode, useMemo } from "react";
import {
  ApprovalsOrganizationChartView_compCyclePhase,
  ApprovalsOrganizationChartView_employee as Employee,
} from "src/__generated__/graphql";
import {
  useCompCycleOrganizationChartSelectionData,
  useCompCycleOrganizationChartViewsData,
} from "src/components/CompCycleOrganizationChart/CompCycleOrganizationChartContext";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { useUpdateCompCyclePhases } from "src/mutations/CompCyclePhase";
import { SUBSETTINGS_SIDEBAR_START } from "src/theme";
import { pixelsToNumber, remToPixels } from "src/utils";
import {
  CompCycleDataChangeHandler,
  CompCyclePhaseData,
} from "../CompCycleWizard/types";
import { ApprovalSettingsChartButtonBar } from "./ApprovalSettingsChartButtonBar";
import {
  ApprovalSettingsSidebar,
  COMP_CYCLE_HIERARCHY_CONFIGURATION_SIDEBAR_WIDTH,
} from "./ApprovalSettingsSidebar";

type Props = {
  phases: ApprovalsOrganizationChartView_compCyclePhase[];
  compCycleId: number;
  children: (orgChart: OrgChart<Employee>) => ReactNode;
  phaseData: CompCyclePhaseData[];
  handleChange: CompCycleDataChangeHandler;
};

const useStyles = makeStyles(() => ({
  content: {
    position: "absolute",
    top: -10,
  },
  container: {
    width: "100%",
    height: "100%",
  },
}));

export function ApprovalsOrganizationChartView({
  phases,
  compCycleId,
  children,
  phaseData,
  handleChange,
}: Props): JSX.Element {
  const classes = useStyles();
  const { width, height } = useWindowDimensions();

  const { layerData } = useCompCycleOrganizationChartSelectionData();
  const { setPageEditState, chartLoaded } =
    useCompCycleOrganizationChartViewsData();
  const chart = useMemo(() => new OrgChart<Employee>(), []);

  const updateCompCyclePhases = useUpdateCompCyclePhases();

  const handleSubmit = async () => {
    return await updateCompCyclePhases(
      compCycleId,
      mapMaybe(phaseData, (phase) => {
        // all should have an id but since id is optional prop
        // we need to check for its existence
        if (phase.id !== undefined) {
          return { id: phase.id, startDate: phase.startDate };
        }
      })
    );
  };

  const handleBack = () => {
    handleChange("phasesData", formatPhaseData(phases, layerData));
    setPageEditState(false);
  };

  const sidebarWidth =
    remToPixels(COMP_CYCLE_HIERARCHY_CONFIGURATION_SIDEBAR_WIDTH) +
    pixelsToNumber(SUBSETTINGS_SIDEBAR_START);

  const chartWidth = width - sidebarWidth - 15;
  const chartHeight = height - 15;

  return (
    <>
      {chartLoaded === true && (
        <ApprovalSettingsSidebar
          phases={phaseData}
          handleChange={handleChange}
        />
      )}
      <div
        className={classes.content}
        style={{
          width: chartWidth,
          height: chartHeight,
          left: sidebarWidth,
        }}
      >
        <ApprovalSettingsChartButtonBar
          chart={chart}
          onSubmit={handleSubmit}
          onBack={handleBack}
        />
        {children(chart)}
      </div>
    </>
  );
}

ApprovalsOrganizationChartView.fragments = {
  employee: gql`
    fragment ApprovalsOrganizationChartView_employee on Employee2 {
      id
      parentId: managerId
      displayName
      _directSubordinates: directReportsCount
      _totalSubordinates: totalReportsCount
      activeEmployment {
        id
        jobTitle
      }
    }
  `,
  compCyclePhase: gql`
    fragment ApprovalsOrganizationChartView_compCyclePhase on CompCyclePhase2 {
      id
      phaseOrder
      startDate
      compCyclePhaseAssignments {
        id
        phaseId
        status
        assigneeId
      }
    }
  `,
};

export function formatPhaseData(
  phases: ApprovalsOrganizationChartView_compCyclePhase[],
  layerData: { [key: number]: number[] | undefined }
): CompCyclePhaseData[] {
  const layerEntries = Object.entries(layerData);
  return phases.map((phase) => {
    const assigneeIds = mapMaybe(
      phase.compCyclePhaseAssignments,
      (assignment) => assignment
    ).map((assignment) => assignment.assigneeId);
    return {
      ...phase,
      assigneeIds,
      layers: mapMaybe(layerEntries, ([layer, ids]) => {
        if (ids?.some((id) => assigneeIds.includes(id)) ?? false) {
          return parseInt(layer);
        }
      }),
    };
  });
}
