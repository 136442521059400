import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSsoCookie } from "../../authHooks";
import { useAuth } from "../../components/Auth/AuthContext";
import { useURLSearchParams } from "../../models/URLSearchParams";

export function SsoRedirect(): JSX.Element {
  const urlSearchParams = useURLSearchParams();
  const { setCookie } = useSsoCookie();
  const { refetch } = useAuth();

  const accessToken = urlSearchParams.get("cookie");

  useEffect(() => {
    if (accessToken !== null) {
      setCookie(accessToken);
      void (refetch && refetch());
    }
  }, [accessToken, setCookie, refetch]);

  return <Navigate to="/" replace />;
}
