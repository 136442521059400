import { Box, Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { MAX_BENEFITS_PER_PACKAGE } from "../../../models/Benefits";
import FormHeader from "../FormHeader";
import Transition from "../Transition";
import { BenefitsLoadingBoundary } from "./BenefitsLoadingBoundary";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    left: "50%",
    position: "absolute",
    transform: "translate(-50%)",
    width: theme.spacing(67),
  },
  addButton: {
    position: "absolute",
    right: "30px",
  },
}));

export function Benefits(): JSX.Element {
  const classes = useStyles();

  return (
    <Transition>
      <Box className={classes.formContainer} pb={4}>
        <FormHeader
          header="Benefits Packages"
          description={`Benefits Packages are used in your candidates’ Illustrative Offers. Add up to ${MAX_BENEFITS_PER_PACKAGE} different benefits per package.`}
        />
        <BenefitsLoadingBoundary />
      </Box>
      <Button
        component={Link}
        to="/settings/benefits/new"
        className={classes.addButton}
        variant="contained"
        color="primary"
      >
        Add Benefits Package
      </Button>
    </Transition>
  );
}
