import { makeStyles, TypographyProps } from "@material-ui/core";
import clsx from "clsx";
import { ReactNode } from "react";
import {
  AssembleTypography,
  AssembleTypographyProps,
} from "./AssembleTypography";

const useStyles = makeStyles(() => ({
  typographySmaller: {
    fontSize: "0.8125rem",
    letterSpacing: "-0.25px",
  },
  ellipsis: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  /**
   * `display:-webkit-box` truncates at whitespace-breaks, so if you have
   * content with no whitespace, like a long email, it will not be truncated.
   *
   * As such, if someone only wants to truncate after one line (the default),
   * then let's make sure that it doesn't need whitespace to do so.
   *
   * Multi-line truncation on a string with no white-space doesn't work
   * properly, but I imagine that no one wants a long, non-whitespace'd string
   * to get put on multiple lines.
   */
  singleLineEllipsis: {
    wordBreak: "break-all",
  },
}));

type Props = {
  children: ReactNode | null | undefined;
  className?: string;
  style?: React.CSSProperties;
  align?: TypographyProps["align"];
  title?: string;
  lines?: number;
  textColor?: string;
  variant?: AssembleTypographyProps["variant"];
};

/* Truncates text with a `...` if it exceeds its width, and adds a `title`
 * property that will show the full text if the user mouse-hovers over it.
 *
 * `lines` will determine how many lines the text will overflow to before
 * eventually truncating.
 */
export function AssembleTruncatedTypography({
  children,
  className = "",
  style = {},
  align,
  title,
  lines = 1,
  variant,
  textColor = "inherit",
}: Props): JSX.Element {
  const classes = useStyles();

  const displayedTitle =
    title !== undefined
      ? title
      : typeof children === "string" || typeof children === "number"
        ? children.toString()
        : "";

  return (
    <AssembleTypography
      variant={variant}
      title={displayedTitle}
      className={clsx(classes.ellipsis, classes.typographySmaller, className, {
        [classes.singleLineEllipsis]: lines === 1,
      })}
      style={{ ...style, WebkitLineClamp: lines, color: textColor }}
      align={align}
    >
      {children}
    </AssembleTypography>
  );
}
