import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const InfoIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Info"
      viewBox="0 0 14 14"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM5.5 6.5C5.5 5.94772 5.94772 5.5 6.5 5.5H7C7.55229 5.5 8 5.94772 8 6.5V9.13378C8.29891 9.30668 8.50001 9.62986 8.50001 10C8.50001 10.5523 8.05229 11 7.50001 11H7C6.44772 11 6 10.5523 6 10V7.36622C5.7011 7.19332 5.5 6.87014 5.5 6.5ZM8 4C8 4.55228 7.55228 5 7 5C6.44772 5 6 4.55228 6 4C6 3.44772 6.44772 3 7 3C7.55228 3 8 3.44772 8 4Z"
      />
    </svg>
  </ColorableSVG>
);
