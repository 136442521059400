import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { IconButton, makeStyles, Slide, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { DV_BAND_COLORS } from "../constants";
import { DV_ORANGE, GRAY_8 } from "../theme";
import { CloseCircleIcon } from "./AssembleIcons/Small/CloseCircleIcon";
import { useAuth } from "./Auth/AuthContext";
import { useFeatureFlags } from "./FeatureContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(1.5, 2),
    background: DV_ORANGE,
    zIndex: 1_000_000,
  },
  dismissButton: {
    position: "absolute",
    right: theme.spacing(2),
  },
}));

export function CustomerDataWarningBanner(): JSX.Element | null {
  const classes = useStyles();
  const featureFlags = useFeatureFlags();
  const { user, organization } = useAuth();
  const [showBanner, setShowBanner] = useState(false);

  // Add a short delay to animate the banner's entrance
  useEffect(() => {
    setTimeout(() => setShowBanner(true), 100);
  }, []);

  if (!user || !organization) return null;

  const isAssembleUser = user.email?.endsWith("assemble.inc") ?? false;
  const isDisabled =
    featureFlags.loading ||
    featureFlags.isEnabled(FeatureFlag.DisableCustomerDataWarningBanner);

  // Get a deterministically pseudo-random color per organization
  const bannerColor = DV_BAND_COLORS[organization.id % DV_BAND_COLORS.length];

  // Only show this banner for Assemble emails. Additional exceptions can be
  // configured via feature flag.
  return showBanner && isAssembleUser && !isDisabled ? (
    <Slide in={showBanner}>
      <div className={classes.root} style={{ backgroundColor: bannerColor }}>
        <Typography>
          ⚠️ You're on <strong>{organization.name}</strong>'s instance as{" "}
          {user.email}. Please be careful with customer data! ⚠️
        </Typography>
        <IconButton
          className={classes.dismissButton}
          size="small"
          onClick={() => setShowBanner(false)}
        >
          <CloseCircleIcon color={GRAY_8} />
        </IconButton>
      </div>
    </Slide>
  ) : null;
}
