import { gql } from "@apollo/client";
import { getFormattedDate } from "@asmbl/shared/time";
import { CalendarIcon } from "src/components/AssembleIcons/Brand/CalendarIcon";
import { StartDateBubble_employee } from "../../../__generated__/graphql";
import { BubbleInfo } from "./BubbleInfo";

type Props = {
  employee: StartDateBubble_employee;
};

export function StartDateBubble({ employee }: Props): JSX.Element {
  return (
    <BubbleInfo
      data-cy="start-date"
      label="Start Date"
      info={getFormattedDate(employee.activeAt) || "-"}
      icon={<CalendarIcon inherit />}
      color="blue"
    />
  );
}

StartDateBubble.fragments = {
  employee: gql`
    fragment StartDateBubble_employee on Employee {
      activeAt
    }
  `,
};
