import { gql } from "@apollo/client";
import { areArraysEqual } from "@asmbl/shared/utils";
import { Divider, Drawer, IconButton, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { PhaseSelectDrawer_employee as Employee } from "src/__generated__/graphql";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { CloseDrawerIcon } from "src/components/AssembleIcons/Brand/CloseDrawerIcon";
import { getManagementLayer } from "src/components/Settings/UserAccessControl/ManagerCard";
import { useHotKey } from "src/hooks/useHotKey";
import { GRAY_3, GRAY_4, GRAY_6, WARNING_TEXT, WHITE } from "src/theme";
import { AssembleTypography } from "../../AssembleTypography";
import {
  CompCyclePhaseData,
  usePhaseConfiguration,
} from "../usePhaseConfiguration";
import {
  getCurrentPhaseConfiguration,
  initPhaseData,
  isEmployeeAssignedToAnotherPhase,
} from "../utils";
import { EmployeeCard } from "./EmployeeCard/EmployeeCard";
import { PhaseManagerSearch } from "./PhaseManagerSearch";
import { SelectModeToggle } from "./SelectModeToggle";
import { usePhaseManagerSearch } from "./usePhaseManagerSearch";

const useStyles = makeStyles((theme) => ({
  drawer: { minWidth: "520px" },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",

    padding: theme.spacing(3),

    boxShadow: `0px 2px 6px 0px ${GRAY_3}17 0px 1px 0px 0px ${GRAY_6}`,
  },
  icon: {
    transform: "rotate(180deg)",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  subTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(6),
  },
  infoTextContainer: {
    maxWidth: "450px",
  },
  content: {
    height: "78%",

    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(2, 3),
    overflowY: "scroll",
  },
  footer: {
    borderTop: `1px solid ${GRAY_6}`,

    position: "absolute",
    bottom: 0,
    backgroundColor: WHITE,
    width: "100%",
    boxShadow: "0px -2px 9px 0px rgba(10, 36, 64, 0.08)",
    padding: theme.spacing(2, 3),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

type Props = {
  phaseOrder: number;
  handleSave: () => void;
  handleClose: () => void;
  employees: (Employee & { reports: number[] })[];
};

export function PhaseSelectDrawer({
  phaseOrder,
  handleSave,
  handleClose,
  employees,
}: Props): JSX.Element | null {
  const classes = useStyles();
  const { searchTerm } = usePhaseManagerSearch();
  const [isTreeSelected, setIsTreeSelection] = useState<boolean>(true);

  useHotKey({
    keyCombo: ["Alt"],
    onKeyDown: () => setIsTreeSelection((prev) => !prev),
    onKeyUp: () => setIsTreeSelection((prev) => !prev),
  });

  const { phaseConfiguration, setPhaseData } = usePhaseConfiguration();

  const initDraft =
    getCurrentPhaseConfiguration(phaseConfiguration, phaseOrder) ??
    initPhaseData(phaseOrder);

  const [draftPhaseConfiguration, setDraftPhaseConfiguration] =
    useState<CompCyclePhaseData>(initDraft);

  const selectedManagerIds = draftPhaseConfiguration.assigneeIds;

  const topLevelManagers =
    searchTerm === ""
      ? getManagementLayer(employees, null)
      : employees.filter((employees) =>
          employees.displayName.toLowerCase().includes(searchTerm.toLowerCase())
        );

  const toggleEmployeeSelected = (topLevelManagerId: number) => {
    const selectedManager = employees.find(
      (employee) => employee.id === topLevelManagerId
    );

    if (!selectedManager) return;

    if (!isTreeSelected) {
      const newSelectedManagerIds = selectedManagerIds.includes(
        topLevelManagerId
      )
        ? selectedManagerIds.filter((id) => id !== topLevelManagerId)
        : [...selectedManagerIds, topLevelManagerId];

      setDraftPhaseConfiguration({
        ...draftPhaseConfiguration,
        assigneeIds: newSelectedManagerIds,
      });

      return;
    }

    const allSelectedEmployeeIds: number[] = [topLevelManagerId];

    selectedManager.reports.map((reportId) => {
      if (employees.find((e) => e.id === reportId)) {
        allSelectedEmployeeIds.push(reportId);
      }
    });

    const allAvailableSelectedEmployeeIds = allSelectedEmployeeIds.filter(
      (id) =>
        !isEmployeeAssignedToAnotherPhase(phaseConfiguration, phaseOrder, {
          id,
        })
    );

    const currentPhaseSelectedEmployeeIds = draftPhaseConfiguration.assigneeIds;

    setDraftPhaseConfiguration({
      ...draftPhaseConfiguration,
      assigneeIds: [
        ...allAvailableSelectedEmployeeIds.filter(
          (id) => !currentPhaseSelectedEmployeeIds.includes(id)
        ),
        ...currentPhaseSelectedEmployeeIds.filter(
          (id) => !allAvailableSelectedEmployeeIds.includes(id)
        ),
      ],
    });
  };

  const handleSaveWrapper = () => {
    setPhaseData(phaseOrder, draftPhaseConfiguration);
    handleSave();
  };

  const handleCloseWrapper = (
    _event: React.SyntheticEvent,
    reason: "backdropClick" | "escapeKeyDown" | "button"
  ) => {
    if (reason === "backdropClick") return;

    const unsavedChanges = !areArraysEqual(
      draftPhaseConfiguration.assigneeIds,
      getCurrentPhaseConfiguration(phaseConfiguration, phaseOrder)
        ?.assigneeIds ?? []
    );

    if (unsavedChanges) {
      const response = window.confirm(
        "You have unsaved changes. Are you sure you want to leave?"
      );

      if (!response) return;
    }

    const initDraft =
      getCurrentPhaseConfiguration(phaseConfiguration, phaseOrder) ??
      initPhaseData(phaseOrder);

    setDraftPhaseConfiguration(initDraft);
    handleClose();
  };

  return (
    <Drawer
      classes={{ paper: classes.drawer }}
      anchor="left"
      open={true}
      onClose={handleCloseWrapper}
    >
      <div className={classes.header}>
        <div className={classes.title}>
          <IconButton
            className={classes.icon}
            onClick={(event) => handleCloseWrapper(event, "button")}
          >
            <CloseDrawerIcon inherit height="24px" width="24px" />
          </IconButton>
          <AssembleTypography variant="productSectionHeader">
            Phase Access
          </AssembleTypography>
        </div>
        <div className={classes.subTitle}>
          <AssembleTypography variant="productSmallerBold">
            Select who should be in this phase.
          </AssembleTypography>
          <AssembleTypography
            variant="productParagraphSmall"
            textColor={GRAY_4}
          >
            {selectedManagerIds.length} selected
          </AssembleTypography>
        </div>
      </div>
      <Divider />
      <div className={classes.content}>
        <div className={classes.headerActions}>
          <PhaseManagerSearch />
          <SelectModeToggle
            isTreeSelected={isTreeSelected}
            setIsTreeSelection={setIsTreeSelection}
          />
        </div>
        {(phaseConfiguration.length === 0 ||
          phaseOrder === phaseConfiguration.length) && (
          <div className={classes.infoTextContainer}>
            <AssembleTypography
              variant="productSmallSemiBold"
              textColor={WARNING_TEXT}
            >
              This will be your final review phase. Please select the senior
              most approver(s) to this phase. You can add others, if appropriate
            </AssembleTypography>
          </div>
        )}
        <div>
          {topLevelManagers.map((employee) => (
            <EmployeeCard
              key={`employee-${employee.id}`}
              indentation={[]}
              phaseOrder={phaseOrder}
              currentEmployee={employee}
              employees={employees}
              toggleEmployeeSelected={toggleEmployeeSelected}
              selectedEmployeeIds={selectedManagerIds}
              hideDropdown={searchTerm !== ""}
            />
          ))}
        </div>
      </div>
      <div className={classes.footer}>
        <AssembleButton
          variant="contained"
          size="medium"
          label="Add to Phase"
          disabled={draftPhaseConfiguration.assigneeIds.length === 0}
          onClick={handleSaveWrapper}
        />
      </div>
    </Drawer>
  );
}

PhaseSelectDrawer.fragments = {
  employee: gql`
    ${EmployeeCard.fragments.employee}
    fragment PhaseSelectDrawer_employee on Employee2 {
      id
      managerId
      displayName
      activeEmployment {
        id
        jobTitle
      }
      ...EmployeeCard_employee
    }
  `,
};
