import { caseInsensitiveComparator } from "@asmbl/shared/sort";

/**
 * Generate a key in the form of `state, country`.
 */
export function getStateCountryKey<
  T extends { state: string; country: string },
>(location: T): string {
  return [location.state, ` ${location.country}`]
    .filter((l) => l !== "")
    .join(",")
    .trim();
}

/**
 * Group locations by state and country key.
 */
export function groupLocations<
  T extends { city: string; state: string; country: string },
>(locations: T[]): Map<string, T[]> {
  const sortedLocations = sortLocations(locations);
  const map = new Map<string, T[]>();
  sortedLocations.forEach((location) => {
    const key = getStateCountryKey(location);
    const entry = map.get(key);
    return entry === undefined
      ? map.set(key, [location])
      : map.set(key, entry.concat(location));
  });
  return map;
}

/**
 * Sort locations by country, then state, then city.
 */
function sortLocations<
  T extends { city: string; state: string; country: string },
>(locations: T[]): T[] {
  return locations.slice().sort((a, b) => {
    const countryComparison = caseInsensitiveComparator(a.country, b.country);
    const stateComparison = caseInsensitiveComparator(a.state, b.state);
    const cityComparison = caseInsensitiveComparator(a.city, b.city);
    return countryComparison || stateComparison || cityComparison;
  });
}
