import { gql } from "@apollo/client";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { BioHeading } from "../../../components/Employee/BioHeading";
import { BubbleRow } from "../../../components/Employee/BubbleInfo/BubbleRow";
import { LocationBubble } from "../../../components/Employee/BubbleInfo/LocationBubble";
import { ManagerBubble } from "../../../components/Employee/BubbleInfo/ManagerBubble";
import { StartDateBubble } from "../../../components/Employee/BubbleInfo/StartDateBubble";
import { TenureBubble } from "../../../components/Employee/BubbleInfo/TenureBubble";
import { GRAY_2, GRAY_6, theme, WHITE } from "../../../theme";
import { EmployeeHeaderSection_employee as Employee } from "../../../__generated__/graphql";
import { EmployeeBanners } from "./EmployeeBanners";

interface Props {
  employee: Employee;
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: WHITE,
    boxShadow: `0px 1px 0px ${GRAY_6}`,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  perfBox: {
    flex: "1 0 20rem",
    position: "relative",
    marginBottom: theme.spacing(4),
    zIndex: 1,
  },
  perfBorder: {
    borderLeft: `1px solid ${GRAY_6}`,
    padding: theme.spacing(0, 2),
    height: "100%",
  },
  perfRating: {
    fontSize: "14px",
    fontWeight: 700,
    whiteSpace: "pre",
  },
  perfNote: {
    fontSize: "14px",
    color: GRAY_2,
  },
}));

export const EmployeeHeaderSection = ({ employee }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Box pb={3} px={4} className={classes.root}>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Box flex="0 0 20rem">
          <BioHeading employee={employee} />
        </Box>
        <Box className={classes.perfBox}>
          {employee.perfRating !== null && (
            <Box className={classes.perfBorder}>
              <Typography className={classes.perfRating} gutterBottom>
                {"Performance Rating  •  "}
                {employee.perfRating.perfRating}
              </Typography>
              <Typography className={classes.perfNote}>
                {employee.perfRating.note}
              </Typography>
            </Box>
          )}
          <Box position="absolute" top={0} right={0}>
            <EmployeeBanners employee={employee} />
          </Box>
        </Box>
      </Box>
      <BubbleRow>
        <ManagerBubble employee={employee} />
        <LocationBubble employee={employee} />
        <StartDateBubble employee={employee} />
        <TenureBubble employee={employee} />
      </BubbleRow>
    </Box>
  );
};

EmployeeHeaderSection.fragments = {
  employee: gql`
    ${BioHeading.fragments.employee}
    ${EmployeeBanners.fragments.employee}
    ${ManagerBubble.fragments.employee}
    ${LocationBubble.fragments.employee}
    ${StartDateBubble.fragments.employee}
    ${TenureBubble.fragments.employee}
    fragment EmployeeHeaderSection_employee on Employee {
      id
      perfRating(compCycleId: $compCycleId) {
        perfRating
        note
      }
      ...BioHeading_employee
      ...EmployeeBanners_employee
      ...ManagerBubble_employee
      ...LocationBubble_employee
      ...StartDateBubble_employee
      ...TenureBubble_employee
    }
  `,
};
