import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const PercentageIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Percentage"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M12.6668 3.33331L3.3335 12.6666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M5.5 6.33331C6.32843 6.33331 7 5.66174 7 4.83331C7 4.00489 6.32843 3.33331 5.5 3.33331C4.67157 3.33331 4 4.00489 4 4.83331C4 5.66174 4.67157 6.33331 5.5 6.33331Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M10.8335 12.3333C11.6619 12.3333 12.3335 11.6617 12.3335 10.8333C12.3335 10.0049 11.6619 9.33331 10.8335 9.33331C10.0051 9.33331 9.3335 10.0049 9.3335 10.8333C9.3335 11.6617 10.0051 12.3333 10.8335 12.3333Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
