import { InfoBanner } from "../../InfoBanner";

export function DisabledWarningBanner({
  userIsDisabled,
  userName,
}: {
  userIsDisabled: boolean;
  userName: string | null;
}): JSX.Element | null {
  return userIsDisabled ? (
    <InfoBanner level="warning">
      {userName ?? "This person"}'s Assemble account is currently{" "}
      <b>disabled</b>. They can't log in and can't view or edit anything. If you
      enable {userName ?? "this person"}'s account, they will have the access
      shown below.
    </InfoBanner>
  ) : null;
}
