import { gql, Reference, useMutation } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Money } from "@asmbl/shared/money";
import { useCallback } from "react";
import { BENEFITS_PACKAGE_FIELDS } from "../fragments";
import { getTotalValue } from "../models/Benefits";
import {
  BenefitsPackageFields as BenefitsPackage,
  CreateBenefitsPackage,
  DeleteBenefitsPackage,
  UpdateBenefitsPackage,
} from "../__generated__/graphql";

type BenefitInput = {
  name: string;
  value: Money | null;
  description: string;
  rank: string;
};

export type BenefitsPackageInput = {
  name: string;
  currencyCode: CurrencyCode;
  benefits: BenefitInput[];
};

const CREATE_BENEFITS_PACKAGE = gql`
  ${BENEFITS_PACKAGE_FIELDS}
  mutation CreateBenefitsPackage($benefitsPackage: BenefitsPackageInput!) {
    createOneBenefitsPackage(benefitsPackage: $benefitsPackage) {
      ...BenefitsPackageFields
    }
  }
`;

export function useCreateBenefitsPackage(): (
  benefitsPackage: BenefitsPackageInput
) => Promise<BenefitsPackage | undefined> {
  const [createBenefitsPackage] = useMutation<CreateBenefitsPackage>(
    CREATE_BENEFITS_PACKAGE,
    {
      update(cache, { data }) {
        if (data === null || data === undefined) {
          return;
        }

        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            benefitsPackages(existingPackages: Reference[]) {
              const newPackage = cache.writeFragment({
                fragment: BENEFITS_PACKAGE_FIELDS,
                fragmentName: "BenefitsPackageFields",
                data: data.createOneBenefitsPackage,
              });
              return [...existingPackages, newPackage];
            },
          },
        });
      },
    }
  );

  return useCallback(
    async (benefitsPackage: BenefitsPackageInput) => {
      const result = await createBenefitsPackage({
        variables: {
          benefitsPackage,
        },
        optimisticResponse: {
          createOneBenefitsPackage: {
            ...benefitsPackage,
            totalValue: getTotalValue(benefitsPackage),
            benefits: benefitsPackage.benefits.map((benefit, i) => ({
              ...benefit,
              benefitsPackageId: -1,
              id: -i,
              __typename: "Benefit",
            })),
            __typename: "BenefitsPackage",
            id: -1,
          },
        },
      });

      return result.data?.createOneBenefitsPackage;
    },
    [createBenefitsPackage]
  );
}

const UPDATE_BENEFITS_PACKAGE = gql`
  ${BENEFITS_PACKAGE_FIELDS}
  mutation UpdateBenefitsPackage(
    $id: Int!
    $benefitsPackage: BenefitsPackageInput!
  ) {
    updateOneBenefitsPackage(id: $id, benefitsPackage: $benefitsPackage) {
      ...BenefitsPackageFields
    }
  }
`;

export function useUpdateBenefitsPackage(): (
  id: number,
  benefitsPackage: BenefitsPackageInput
) => Promise<unknown> {
  const [updateBenefitsPackage] = useMutation<UpdateBenefitsPackage>(
    UPDATE_BENEFITS_PACKAGE
  );

  return useCallback(
    (id, benefitsPackage) =>
      updateBenefitsPackage({
        variables: {
          id,
          benefitsPackage,
        },
        optimisticResponse: {
          updateOneBenefitsPackage: {
            ...benefitsPackage,
            __typename: "BenefitsPackage",
            id,
            totalValue: getTotalValue(benefitsPackage),
            benefits: benefitsPackage.benefits.map((benefit, i) => ({
              ...benefit,
              benefitsPackageId: id,
              id: -i,
              __typename: "Benefit",
            })),
          },
        },
      }),
    [updateBenefitsPackage]
  );
}

const DELETE_BENEFITS_PACKAGE = gql`
  mutation DeleteBenefitsPackage($id: Int!) {
    deleteOneBenefitsPackage(id: $id) {
      id
    }
  }
`;

export function useDeleteBenefitsPackage(): (
  benefitsPackage: BenefitsPackage
) => Promise<unknown> {
  const [deleteBenefitsPackage] = useMutation<DeleteBenefitsPackage>(
    DELETE_BENEFITS_PACKAGE,
    {
      update(cache, { data }) {
        if (data === null || data === undefined) {
          return;
        }

        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            benefitsPackages(existingPackages: Reference[], { readField }) {
              return existingPackages.filter(
                (ref) =>
                  data.deleteOneBenefitsPackage.id !== readField("id", ref)
              );
            },
          },
        });
      },
    }
  );

  return useCallback(
    (benefitsPackage) =>
      deleteBenefitsPackage({
        variables: { id: benefitsPackage.id },
        optimisticResponse: {
          deleteOneBenefitsPackage: {
            __typename: "BenefitsPackage",
            id: benefitsPackage.id,
          },
        },
      }),
    [deleteBenefitsPackage]
  );
}
