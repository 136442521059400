import { Box, makeStyles, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { GRAY_6, theme, WHITE } from "../../../theme";
import { UserAvatar } from "../../UserAvatar";
import { DrawerTitle } from "../DrawerTitle";

const useStyles = makeStyles(() => ({
  container: {
    background: WHITE,
    height: "100%",
    width: "100%",
    padding: `${theme.spacing(3)}px`,
    borderRight: `1px solid ${GRAY_6}`,
  },
  overflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

interface Props {
  user: {
    name: string | null;
    email: string;
    photoURL?: string | null;
  };
}

export function UserProfilePane({ user }: Props): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={classes.container}>
      <DrawerTitle
        title="Team Member"
        onClose={() => navigate("/settings/permissions")}
      />
      <Box mt={6} />
      <UserAvatar
        photoURL={user.photoURL}
        displayName={user.name}
        size="medium"
      />
      <Box mt={3} />
      {user.name !== null && (
        <Typography variant="h6" className={classes.overflow}>
          {user.name}
        </Typography>
      )}
      <a href={`mailto:${user.email}`} style={{ textDecoration: "none" }}>
        <Typography
          variant="body2"
          color="primary"
          className={classes.overflow}
        >
          {user.email}
        </Typography>
      </a>
    </Box>
  );
}
