import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const StopHandIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4999 7.5V4.25C10.4999 3.91848 10.6316 3.60054 10.866 3.36612C11.1004 3.1317 11.4184 3 11.7499 3C12.0814 3 12.3994 3.1317 12.6338 3.36612C12.8682 3.60054 12.9999 3.91848 12.9999 4.25V9.5C12.9999 10.1566 12.8706 10.8068 12.6193 11.4134C12.368 12.02 11.9997 12.5712 11.5354 13.0355C11.0712 13.4998 10.52 13.8681 9.91333 14.1194C9.3067 14.3707 8.65652 14.5 7.99991 14.5C5.23849 14.5 3.99991 13 1.96438 8.70541C1.88214 8.56324 1.82872 8.40626 1.80718 8.24344C1.78564 8.08062 1.7964 7.91514 1.83884 7.75648C1.88129 7.59782 1.95458 7.44908 2.05454 7.31876C2.15449 7.18844 2.27915 7.07909 2.42139 6.99697C2.56362 6.91485 2.72065 6.86156 2.88349 6.84016C3.04633 6.81876 3.21179 6.82965 3.37042 6.87223C3.52904 6.9148 3.67772 6.98822 3.80796 7.08829C3.9382 7.18835 4.04744 7.3131 4.12944 7.45541L5.49991 9.82913V3.25C5.49991 2.91848 5.63161 2.60054 5.86603 2.36612C6.10045 2.1317 6.41839 2 6.74991 2C7.08143 2 7.39937 2.1317 7.6338 2.36612C7.86822 2.60054 7.99991 2.91848 7.99991 3.25V7.5"
        stroke="#425466"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 7.5V2.25C10.5 1.91848 10.3683 1.60054 10.1339 1.36612C9.89946 1.1317 9.58152 1 9.25 1C8.91848 1 8.60054 1.1317 8.36612 1.36612C8.1317 1.60054 8 1.91848 8 2.25V7.5"
        stroke="#425466"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
