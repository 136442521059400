import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const DepartmentIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Department"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M5.70551 3.5C3.81363 4.36961 2.5 6.2814 2.5 8.5C2.5 8.77431 2.52008 9.04393 2.55885 9.30747M10.2945 3.5C12.1864 4.36961 13.5 6.2814 13.5 8.5C13.5 8.77431 13.4799 9.04393 13.4411 9.30747M11.3032 12.898C10.3834 13.5899 9.23962 14 8 14C6.76038 14 5.61655 13.5899 4.69677 12.898"
        strokeWidth="1.5"
      />
      <circle className="stroke" cx="8" cy="3" r="2.25" strokeWidth="1.5" />
      <circle className="stroke" cx="13" cy="11.5" r="2.25" strokeWidth="1.5" />
      <circle className="stroke" cx="3" cy="11.5" r="2.25" strokeWidth="1.5" />
    </svg>
  </ColorableSVG>
);
