import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { GRAY_1, GRAY_4, GRAY_9 } from "../../../theme";
import { OfferData } from "../types";

const useStyles = makeStyles((theme) => ({
  headerText: {
    color: GRAY_1,
    fontWeight: 500,
    fontSize: "1.25rem",
    lineHeight: "155%",
    marginBottom: theme.spacing(3.0),
  },
  selectCandidateText: {
    color: GRAY_4,
    fontWeight: 100,
    fontSize: "0.875rem",
    marginBottom: theme.spacing(0.5),
  },
  nameText: {
    color: GRAY_1,
    fontSize: "0.875rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(0.5),
  },
  subText: {
    color: GRAY_1,
    fontWeight: 100,
    fontSize: "0.875rem",
    marginBottom: theme.spacing(0.5),
  },
  drawer: {
    background: GRAY_9,
    height: "100%",
    position: "fixed",
    right: 0,
    top: 0,
    transition: "all 300ms 500ms",
    width: theme.spacing(45),
  },
  button: {
    fontSize: "0.875rem",
    padding: "0px 3px",
    paddingLeft: "0px",
    paddingBottom: "2px",
  },
}));

export type Props = {
  data: OfferData;
  setManualEntry: (value: boolean) => void;
};
export function CandidateInfoSidebar({
  data,
  setManualEntry,
}: Props): JSX.Element {
  const classes = useStyles();
  const candidateSelected =
    data.firstName !== undefined ||
    data.lastName !== undefined ||
    data.candidateEmail !== undefined ||
    data.title !== undefined;

  return !candidateSelected ? (
    <Box px={4} pt={5} className={classes.drawer} style={{ right: -370 }}>
      <Typography className={classes.headerText}>ATS Candidate Info</Typography>
      <Typography className={classes.selectCandidateText}>
        Select a candidate from the menu to the left or
        <Button
          variant="text"
          color="primary"
          className={classes.button}
          onClick={() => setManualEntry(true)}
        >
          enter them in manually.
        </Button>
      </Typography>
    </Box>
  ) : (
    <Box px={4} pt={5} className={classes.drawer} style={{ right: -370 }}>
      <Typography className={classes.headerText}>ATS Candidate Info</Typography>
      <Typography className={classes.nameText}>
        {`${data.firstName ?? ""} ${data.lastName ?? ""}`}
      </Typography>
      <Typography className={classes.subText}>{data.candidateEmail}</Typography>
      <Typography className={classes.subText}>{data.title}</Typography>
    </Box>
  );
}
