import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { CompValue } from "@asmbl/shared/employee";
import { Money, add, calculateCompaRatio, zero } from "@asmbl/shared/money";
import {
  CashCompType,
  CompUnit,
  EmploymentStatus,
} from "../__generated__/graphql";
import { getBandPointCashEquivalent } from "./BandPoint";
import { getPayCashEquivalent, getSalaryCashComp } from "./CashCompensation";
import { CompRecommendation, getPayIncrease } from "./CompRecommendation";

export function isLeveled(employee: {
  employments: { positionId: number | null }[];
}): boolean {
  return (
    employee.employments.length > 0 &&
    employee.employments[0].positionId !== null
  );
}

export function shouldGetLeveled(employee: {
  employmentStatus: EmploymentStatus;
  activeEmployment: { id: number } | null;
}): boolean {
  return !isInactive(employee) && employee.activeEmployment !== null;
}

export function needsLeveling(employee: {
  employmentStatus: EmploymentStatus;
  activeEmployment: { id: number; positionId: number | null } | null;
}): boolean {
  return (
    shouldGetLeveled(employee) &&
    employee.activeEmployment !== null &&
    employee.activeEmployment.positionId === null
  );
}

export function isInactive(employee: {
  employmentStatus: EmploymentStatus;
}): boolean {
  return employee.employmentStatus === EmploymentStatus.INACTIVE;
}

export type CashCompensation = {
  type: CashCompType;
  annualCashEquivalent: Money;
  hourlyCashEquivalent: Money;
  unit: CompUnit;
};

export type AdjustedCashBand = {
  name: string;
  bandPoints: {
    value: {
      annualRate: Money | null;
      hourlyRate: Money | null;
      currencyCode: CurrencyCode;
    };
  }[];
};

export function getCompaRatioNew(
  cash: CashCompensation[] | null,
  bands: AdjustedCashBand[] | null
): number | undefined {
  if (cash == null || bands == null) return undefined;
  const salary = getSalaryCashComp(cash);
  const salaryBand = bands.find((b) => b.name === "Salary");

  if (salary === null || salary === undefined || salaryBand === undefined)
    return undefined;
  const isHourly = salary.unit === CompUnit.HOURLY_CASH;
  const salaryCash = getPayCashEquivalent(salary);
  const bandPoints = salaryBand.bandPoints.map((bp) =>
    getBandPointCashEquivalent(
      bp,
      salary.annualCashEquivalent.currency,
      isHourly
    )
  );

  return calculateCompaRatio(bandPoints, salaryCash) ?? undefined;
}

export function getNewRevisedSalary(
  draft: CompRecommendation | null,
  employee: { activeCashCompensation: CashCompensation[] | null },
  currencies: Map<CurrencyCode, Currency>,
  defaultCurrency: Currency
): Money | undefined {
  const currentSalary = getSalaryCashComp(employee.activeCashCompensation);

  if (!currentSalary) return undefined;

  const currentSalaryCurrency =
    currencies.get(currentSalary.annualCashEquivalent.currency) ??
    defaultCurrency;

  const items = [...(draft?.items.values() ?? [])];

  const salaryIncrease = getPayIncrease(items, defaultCurrency.code);
  const salaryIncreaseCurrency =
    currencies.get(salaryIncrease.currency) ?? defaultCurrency;

  const convertedSalaryIncrease = exchangeFromTo(
    salaryIncrease,
    salaryIncreaseCurrency,
    currentSalaryCurrency
  );

  return add(convertedSalaryIncrease, currentSalary.annualCashEquivalent);
}

export function getNewPay(
  employee: {
    activeCashCompensation: CashCompensation[] | null;
    compRecommendation: { latestSubmittedPayIncrease: CompValue } | null;
  },
  currencies: Map<CurrencyCode, Currency>,
  defaultCurrency: Currency
): Omit<CompValue, "__typename"> | undefined {
  const currentSalary = getSalaryCashComp(employee.activeCashCompensation);

  const { annualCashEquivalent, hourlyCashEquivalent, unitType } =
    employee.compRecommendation?.latestSubmittedPayIncrease ?? {};

  if (!currentSalary) return undefined;

  const salaryCurrency =
    currencies.get(currentSalary.annualCashEquivalent.currency) ??
    defaultCurrency;

  const salaryIncreaseCurrency =
    annualCashEquivalent?.currency != null
      ? currencies.get(annualCashEquivalent.currency) ?? salaryCurrency
      : salaryCurrency;

  const convertedAnnualIncrease = annualCashEquivalent
    ? exchangeFromTo(
        annualCashEquivalent,
        salaryIncreaseCurrency,
        salaryCurrency
      )
    : zero(salaryCurrency.code);

  const convertedHourlyIncrease = hourlyCashEquivalent
    ? exchangeFromTo(
        hourlyCashEquivalent,
        salaryIncreaseCurrency,
        salaryCurrency
      )
    : zero(salaryCurrency.code);

  return {
    annualCashEquivalent: add(
      convertedAnnualIncrease,
      currentSalary.annualCashEquivalent
    ),
    hourlyCashEquivalent: add(
      convertedHourlyIncrease,
      currentSalary.hourlyCashEquivalent
    ),
    unitType: unitType ?? CompUnit.CASH,
  };
}

export function getJobTitle(employee: {
  activeEmployment: {
    jobTitle: string | null;
  } | null;
}): string | undefined {
  return employee.activeEmployment?.jobTitle ?? undefined;
}

export function getDepartmentName(employee: {
  activeEmployment: {
    position: {
      ladder: {
        department: {
          name: string;
        };
      };
    } | null;
  } | null;
}): string | undefined {
  return employee.activeEmployment?.position?.ladder.department.name;
}

export function getLadderName(employee: {
  activeEmployment: {
    position: {
      ladder: {
        name: string;
      };
    } | null;
  } | null;
}): string | undefined {
  return employee.activeEmployment?.position?.ladder.name;
}

export function getPositionName(employee: {
  activeEmployment: {
    position: {
      name: string;
    } | null;
  } | null;
}): string | undefined {
  return employee.activeEmployment?.position?.name;
}

export function getLevel(employee: {
  activeEmployment: {
    position: {
      level: number;
    } | null;
  } | null;
}): number | undefined {
  return employee.activeEmployment?.position?.level;
}

export const canEmployeeReceiveInvite = (emp: {
  user: { id: number } | null;
  latestUserInvite: {
    deletedAt: GraphQL_DateTime | null;
    expiredAt: GraphQL_DateTime;
  } | null;
}) => {
  return (
    emp.user === null &&
    (emp.latestUserInvite === null ||
      emp.latestUserInvite.deletedAt !== null ||
      new Date(emp.latestUserInvite.expiredAt) < new Date())
  );
};

export const formatEmpData = (emp: {
  email: string;
  displayName: string;
  id: number;
}) => ({
  email: emp.email,
  name: emp.displayName,
  employeeId: emp.id,
});
