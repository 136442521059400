import { AssembleTypography } from "src/components/AssembleTypography";
import { StyledBlockButton } from "../StyledBlockButton/StyledBlockButton";

const BOLD = "BOLD";

export type BoldStyleProps = {
  editorState: Draft.EditorState;
  onClick: () => unknown;
};

export const BoldStyle = (props: BoldStyleProps): JSX.Element => {
  const { editorState, onClick } = props;
  const boldIsActive = editorState.getCurrentInlineStyle().has(BOLD);

  return (
    <StyledBlockButton active={boldIsActive} onClick={onClick}>
      <AssembleTypography style={{ fontSize: "18px", fontWeight: "bold" }}>
        B
      </AssembleTypography>
    </StyledBlockButton>
  );
};
