import { gql } from "@apollo/client";
import { formatCurrency } from "@asmbl/shared/money";
import { TableCell, TableCellProps, makeStyles } from "@material-ui/core";
import { useMemo } from "react";
import { PURPLE_1 } from "src/theme";
import {
  TotalCashCell_cash as CashCompensation,
  PayPeriodType,
} from "../../__generated__/graphql";
import { CashBandName } from "../../constants";
import { TotalCash, byCashCompType } from "../../models/CashCompensation";
import { AssembleTypography } from "../AssembleTypography";
import { useCompStructure } from "../CompStructureContext";
import {
  TotalCashCellTooltip,
  TotalWorkingHoursTooltip,
} from "./TotalCashCellTooltip";

const useStyles = makeStyles(() => ({
  hourContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

type Props = {
  payTypeSelection: PayPeriodType;
  isHourly: boolean;
  cash?: TotalCash<CashCompensation>;
  textColor?: string;
  className?: string;
  align?: TableCellProps["align"];
};

export function TotalCashCell({
  payTypeSelection,
  isHourly,
  cash,
  textColor = "inherit",
  className,
  align = "right",
}: Props): JSX.Element {
  const classes = useStyles();
  const { compStructure } = useCompStructure();

  const processedComponents = useMemo(
    () =>
      (cash ?? { subcomponents: [] }).subcomponents
        .slice()
        .sort(byCashCompType)
        .map((c) => ({
          name: CashBandName[c.type],
          annualValue: c.annualCashEquivalent,
          hourlyValue: c.hourlyCashEquivalent,
        })),
    [cash]
  );

  if (cash === undefined) {
    return (
      <TableCell
        aria-label="Employee Total Cash"
        align={align}
        className={className}
      >
        <AssembleTypography variant="productSmall" textColor={textColor}>
          -
        </AssembleTypography>
      </TableCell>
    );
  }
  const showHourly = isHourly && (compStructure?.allowHourlyEmployees ?? false);

  return (
    <TableCell
      aria-label="Employee Total Cash"
      align={align}
      className={className}
    >
      {showHourly &&
        (payTypeSelection === PayPeriodType.HOURLY ? (
          <TotalCashCellTooltip cash={processedComponents}>
            <AssembleTypography variant="productSmall" textColor={textColor}>
              {`${formatCurrency(cash.hourlyTotal, {
                maximumFractionDigits: 2,
              })}/hr`}
            </AssembleTypography>
          </TotalCashCellTooltip>
        ) : (
          <>
            <div className={classes.hourContainer}>
              <TotalCashCellTooltip cash={processedComponents}>
                <AssembleTypography
                  variant="productSmall"
                  textColor={textColor}
                >
                  {formatCurrency(cash.annualTotal, {
                    maximumFractionDigits: 0,
                  })}
                </AssembleTypography>
              </TotalCashCellTooltip>
              {compStructure?.workingHoursPerYear && (
                <TotalWorkingHoursTooltip
                  workingHours={compStructure.workingHoursPerYear}
                >
                  <AssembleTypography
                    variant="productSmall"
                    textColor={PURPLE_1}
                  >
                    *
                  </AssembleTypography>
                </TotalWorkingHoursTooltip>
              )}
            </div>
            <TotalCashCellTooltip cash={processedComponents}>
              <AssembleTypography variant="productSmall" textColor={textColor}>
                {`(${formatCurrency(cash.hourlyTotal, {
                  maximumFractionDigits: 2,
                })}/hr)`}
              </AssembleTypography>
            </TotalCashCellTooltip>
          </>
        ))}
      {!showHourly && (
        <TotalCashCellTooltip cash={processedComponents}>
          <AssembleTypography variant="productSmall" textColor={textColor}>
            {formatCurrency(cash.annualTotal, {
              maximumFractionDigits: 0,
            })}
          </AssembleTypography>
        </TotalCashCellTooltip>
      )}
    </TableCell>
  );
}

TotalCashCell.fragments = {
  cash: gql`
    fragment TotalCashCell_cash on CashCompensation2 {
      type
      annualCashEquivalent
      hourlyCashEquivalent
      unit
      percentOfSalary
    }
  `,
};
