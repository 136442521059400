import { Currency } from "@asmbl/shared/currency";
import { add, Money, multiply, zero } from "@asmbl/shared/money";
import { mapMaybe } from "@asmbl/shared/utils";
import { CurrencyCode } from "../__generated__/graphql";
import { formatUnitSharePrice, getIllustrativeUnitPrice } from "./Equity";

export type Grant = { sharePrice: Money | null };

export type VestEvent = {
  unitCount: number;
  vestingDate: Date | string;
  grant: Grant;
};

type EquityValueCount = {
  count: number;
  value: Money | null;
};

export function employeeCompanyHasEquitySetup(
  sharePriceTarget: number | null,
  illustrativeUnitPrice: Money | null
): boolean {
  return sharePriceTarget != null || illustrativeUnitPrice != null;
}

export function employeeHasEquity(equityHoldings: {
  grants: { __typename: string }[] | null;
}): boolean {
  return (equityHoldings.grants?.length ?? 0) > 0;
}

export function filterVestEventsForUnvested(
  vestEvents: VestEvent[],
  now = new Date()
): VestEvent[] {
  const refDate = new Date(now).setHours(0, 0, 0, 0);

  return vestEvents.filter(
    ({ vestingDate }) => new Date(vestingDate).getTime() > refDate
  );
}

export function filterVestEventsForVested(
  vestEvents: VestEvent[],
  now = new Date()
): VestEvent[] {
  const refDate = new Date(now).setHours(0, 0, 0, 0);

  return vestEvents.filter(
    ({ vestingDate }) => new Date(vestingDate).getTime() <= refDate
  );
}

export function filterVestEventsForNext12Months(
  vestEvents: VestEvent[],
  now = new Date()
): VestEvent[] {
  const yearFromNow = new Date(now);
  yearFromNow.setFullYear(yearFromNow.getFullYear() + 1);

  return vestEvents.filter(({ vestingDate }) => {
    const vestEventDate = new Date(vestingDate);
    return vestEventDate >= now && vestEventDate < yearFromNow;
  });
}

export function calculateEquityFromVestEvents(
  currencies: Map<CurrencyCode, Currency<CurrencyCode>>,
  sharePriceTarget: number | undefined,
  vestEvents: VestEvent[],
  valuationCurrency: Currency
): EquityValueCount {
  const values = mapMaybe(
    vestEvents,
    ({ grant: { sharePrice }, unitCount }) => ({
      value: formatUnitSharePrice(
        sharePriceTarget,
        getIllustrativeUnitPrice(
          currencies,
          [{ sharePrice }],
          valuationCurrency
        )
      ),
      unitCount,
    })
  );

  const filteredValues = values.map(({ unitCount, value }) =>
    value === null ? zero(valuationCurrency.code) : multiply(unitCount, value)
  );

  const sum = filteredValues.reduce(
    (a, b) => add(a, b),
    zero(valuationCurrency.code)
  );

  return {
    count: vestEvents
      .map(({ unitCount }) => unitCount)
      .reduce((a, b) => a + b, 0),
    value: sum,
  };
}

export function calculateEquityFromGrants(
  currencies: Map<CurrencyCode, Currency<CurrencyCode>>,
  sharePriceTarget: number | undefined,
  grants: (Grant & { unitCount: number })[],
  valuationCurrency: Currency
): EquityValueCount {
  const values = mapMaybe(grants, ({ sharePrice, unitCount }) => ({
    value: formatUnitSharePrice(
      sharePriceTarget,
      getIllustrativeUnitPrice(currencies, [{ sharePrice }], valuationCurrency)
    ),
    unitCount,
  }));

  const filteredValues = values.map(({ unitCount, value }) =>
    value === null ? zero(valuationCurrency.code) : multiply(unitCount, value)
  );

  const sum = filteredValues.reduce(
    (a, b) => add(a, b),
    zero(valuationCurrency.code)
  );

  return {
    count: grants.map(({ unitCount }) => unitCount).reduce((a, b) => a + b, 0),
    value: sum,
  };
}
