import { makeStyles } from "@material-ui/core";
import { OrgChart } from "d3-org-chart";
import {
  AssembleButton,
  getIconProps,
} from "src/components/AssembleButton/AssembleButton";
import { CenterViewIcon } from "src/components/AssembleIcons/Small/CenterViewIcon";
import { ZoomInIcon } from "src/components/AssembleIcons/Small/ZoomInIcon";
import { ZoomOutIcon } from "src/components/AssembleIcons/Small/ZoomOutIcon";
import { OrganizationHierarchy_employee as Employee } from "src/__generated__/graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: theme.spacing(2.5),
    right: theme.spacing(2.5),
  },
  right: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(1.25),
    alignItems: "center",
  },
}));

type Props = { chart: OrgChart<Employee> };

export function ChartButtonBar({ chart }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div />
      <div className={classes.right}>
        <AssembleButton
          title="Fit chart onto view"
          size="small"
          variant="outlined"
          onClick={() => chart.fit()}
          startIcon={<CenterViewIcon {...getIconProps({ size: "small" })} />}
        />
        <AssembleButton
          title="Zoom out"
          size="small"
          variant="outlined"
          onClick={() => chart.zoomOut()}
          startIcon={<ZoomOutIcon {...getIconProps({ size: "small" })} />}
        />
        <AssembleButton
          title="Zoom in"
          size="small"
          variant="outlined"
          startIcon={<ZoomInIcon {...getIconProps({ size: "small" })} />}
          onClick={() => chart.zoomIn()}
        />
      </div>
    </div>
  );
}
