import { ISettings } from "@flatfile/adapter";
import { INTEGER_VALIDATOR, Validator } from "./Validators";

export const EmployeeIdentifierFields = (
  options: { allowDuplicates: boolean } = { allowDuplicates: false }
): ISettings["fields"] => {
  const unique = (
    !options.allowDuplicates ? [{ validate: "unique" }] : []
  ) as Validator[];
  return [
    {
      // Name is not used to identify Employees but it is helpful for debugging
      label: "Employee Name",
      key: "name",
    },
    {
      label: "Employee ID",
      description:
        "The Employee's unique identifier from your HRIS system. ex: E00123 or 123",
      key: "employeeNumber",
      sizeHint: 0.75,
      validators: [
        ...unique,
        { validate: "required_without_all", fields: ["employeeId", "email"] },
      ],
    },
    {
      label: "Employee Email",
      description: "The Employee's work email address",
      key: "email",
      validators: [
        ...unique,
        {
          validate: "required_without_all",
          fields: ["employeeId", "employeeNumber"],
        },
      ],
    },
    {
      label: "Assemble Employee Key",
      description:
        "Assemble's unique identifier for this employee. You likely want to use Employee ID or Employee Email instead.",
      key: "employeeId",
      validators: [
        ...unique,
        {
          validate: "required_without_all",
          fields: ["employeeNumber", "email"],
        },
        {
          validate: "regex_matches",
          regex: INTEGER_VALIDATOR,
          error: "Assemble Employee ID must be a number",
        },
      ],
    },
  ];
};
