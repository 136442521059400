import { Button, makeStyles } from "@material-ui/core";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { GRAY_1, GRAY_6, GRAY_7, WHITE } from "../../theme";

const LEFT_URL = "/people/organization";
const RIGHT_URL = "/people/team";

const useStyles = makeStyles(() => ({
  root: {
    width: "16rem",
    height: "2.5rem",
    border: `1px solid ${GRAY_6}`,
    background: GRAY_7,
    boxSizing: "border-box",
    borderRadius: "5px",
    display: "flex",
    padding: "1px",
    margin: "auto",
  },
  label: {
    flex: "1 1",
    fontSize: "1rem",
    lineHeight: 1.5,
    letterSpacing: "-0.25px",
  },
  selected: {
    border: `1px solid ${GRAY_6}`,
    background: WHITE,
    boxSizing: "border-box",
    borderRadius: "3px",

    "& .MuiButton-label": {
      color: GRAY_1,
    },
  },
}));

export function NavSwitch(): JSX.Element {
  const location = useLocation();
  const classes = useStyles();

  // Treat the left side as the default, prevents flashing the right side on initial load
  const leftSelected = !location.pathname.includes(RIGHT_URL);

  return (
    <div className={classes.root}>
      <Button
        className={`${classes.label} ${leftSelected ? classes.selected : ""}`}
        variant="text"
        component={Link}
        to={{ pathname: LEFT_URL, search: location.search }}
        disabled={leftSelected}
      >
        Organization
      </Button>
      <Button
        className={`${classes.label} ${!leftSelected ? classes.selected : ""}`}
        variant="text"
        component={Link}
        to={{ pathname: RIGHT_URL, search: location.search }}
        disabled={!leftSelected}
      >
        Your Team
      </Button>
    </div>
  );
}
