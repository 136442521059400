import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const MakeRecommendationIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Make or Edit Recommendation"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_666_11651)">
        <path
          className="stroke"
          d="M13 9V14.3333C13 14.5101 12.9398 14.6797 12.8326 14.8047C12.7255 14.9298 12.5801 15 12.4286 15H1.57143C1.41988 15 1.27453 14.9298 1.16737 14.8047C1.06021 14.6797 1 14.5101 1 14.3333V1.66667C1 1.48986 1.06021 1.32029 1.16737 1.19526C1.27453 1.07024 1.41988 1 1.57143 1H8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="stroke"
          d="M6.9393 10.7322L4.85352 11.4393C4.72091 11.4393 4.59373 11.3866 4.49996 11.2929C4.40619 11.1991 4.35352 11.0719 4.35352 10.9393L5.06062 8.85352C5.06062 8.78786 5.07356 8.72284 5.09868 8.66218C5.12381 8.60151 5.16064 8.54639 5.20707 8.49996L12.7071 0.999962C12.8008 0.906194 12.928 0.853516 13.0606 0.853516C13.1932 0.853516 13.3204 0.906194 13.4142 0.999962L14.7929 2.37864C14.8866 2.47241 14.9393 2.59959 14.9393 2.7322C14.9393 2.8648 14.8866 2.99198 14.7929 3.08575L7.29286 10.5858C7.24643 10.6322 7.19131 10.669 7.13064 10.6941C7.06998 10.7193 7.00496 10.7322 6.9393 10.7322Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="stroke"
          d="M11.0605 2.64648L13.1463 4.73227"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="stroke"
          d="M5.31055 8.39648L7.39633 10.4823"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_666_11651">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </ColorableSVG>
);
