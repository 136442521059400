import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useState } from "react";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { useCompCycleOrganizationChartViewsData } from "src/components/CompCycleOrganizationChart/CompCycleOrganizationChartContext";

type Props = {
  onClick: () => void;
  isSettingsView?: boolean;
};

export const CancelConfigurationButton = ({
  onClick,
  isSettingsView = false,
}: Props): JSX.Element => {
  const { setPageEditState } = useCompCycleOrganizationChartViewsData();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleCancel = () => {
    onClick();
    setPageEditState(false);
    setModalOpen(false);
  };

  return (
    <>
      <Dialog open={isModalOpen} maxWidth="xs">
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isSettingsView
              ? "This will revert any changes you have made."
              : "You'll have to recreate any phases that have already been made."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <AssembleButton
            size="medium"
            variant="text"
            label="Cancel"
            onClick={() => setModalOpen(false)}
          />
          <AssembleButton
            size="medium"
            variant="danger"
            label={isSettingsView ? "Reset Changes" : "Return to Settings Menu"}
            onClick={handleCancel}
          />
        </DialogActions>
      </Dialog>
      <AssembleButton
        title="Cancel"
        size="small"
        variant="outlined"
        label="Back"
        onClick={() => setModalOpen(true)}
      />
    </>
  );
};
