import { gql } from "@apollo/client";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { groupLocations } from "../../../models/Location";
import { LocationsDisplayList_locations as Location } from "../../../__generated__/graphql";

interface Props {
  locations: Location[];
  locationGroupId?: number;
}

const useStyles = makeStyles((theme) => ({
  listContainer: {
    marginTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(4),
    breakInside: "avoid",
  },
  groupContainer: {
    breakInside: "avoid",
    pageBreakInside: "avoid",
  },
  groupHeading: {
    fontWeight: 600,
  },
  listItems: {
    fontWeight: "normal",
    fontSize: "16px",
  },
}));

export function LocationsDisplayList({
  locations,
  locationGroupId,
}: Props): JSX.Element {
  const classes = useStyles();
  const filteredLocations =
    locationGroupId !== undefined
      ? locations.filter(
          (location: Location) => location.locationGroup?.id === locationGroupId
        )
      : locations;
  const displayedLocations = groupLocations(filteredLocations);
  const displayedLocationKeys = Array.from(displayedLocations.keys());

  return (
    <>
      {displayedLocationKeys.map((group, index) => (
        <Box key={index} className={classes.groupContainer}>
          <Typography variant="subtitle1" className={classes.groupHeading}>
            {group}
          </Typography>
          <ul key={group} className={classes.listContainer}>
            {displayedLocations.get(group)?.map((location) => (
              <li key={location.id}>
                <Typography variant="body1" className={classes.listItems}>
                  {location.city}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
      ))}
    </>
  );
}

LocationsDisplayList.fragments = {
  locations: gql`
    fragment LocationsDisplayList_locations on Location {
      id
      city
      state
      country
      locationGroup {
        id
      }
    }
  `,
};
