import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import { GRAY_3, GRAY_5, GRAY_6, GRAY_7, WHITE } from "../../../../theme";
import { useConnectorStyles } from "./styles";

type Props = {
  text: string;
  first?: boolean;
  filtered: boolean;
};

const useStyles = makeStyles(() => ({
  filtered: {},
  row: { height: "19px" },
  spacingCell: {
    height: "19px",
    borderBottom: "none",
    padding: 0,
  },
  contentCell: {
    background: GRAY_7,
    padding: 0,
    paddingLeft: "2px",
    borderTop: `1px solid ${GRAY_6}`,
    borderBottom: "none",

    "$filtered &": {
      background: WHITE,
    },
  },
  text: {
    fontSize: "0.625rem",
    fontWeight: "bold",
    color: GRAY_3,
    textTransform: "uppercase",
    textAlign: "center",
    "$filtered &": {
      color: GRAY_5,
    },
  },
}));

export function HeaderSeparatorRow({
  text,
  filtered,
  first = false,
}: Props): JSX.Element {
  const classes = useStyles();
  const connectorStyle = useConnectorStyles();

  const connector = first
    ? connectorStyle.firstConnector
    : connectorStyle.lastConnector;

  const rowStyle = `${classes.row} ${filtered ? classes.filtered : ""}`;
  return (
    <TableRow aria-level={2} className={rowStyle}>
      <TableCell role="gridcell" className={classes.spacingCell}>
        <div className={connector} />
      </TableCell>
      <TableCell role="gridcell" colSpan={12} className={classes.contentCell}>
        <Typography className={classes.text}>{text}</Typography>
      </TableCell>
    </TableRow>
  );
}
