import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const ArrowRightIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Arrow Right"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM7.67156 5.17159C8.06208 4.78106 8.69525 4.78106 9.08577 5.17159L11.2021 7.28796C11.22 7.30558 11.2372 7.32388 11.2537 7.3428C11.3188 7.41725 11.3709 7.49904 11.4102 7.58526C11.4679 7.7116 11.5 7.85205 11.5 8C11.5 8.14531 11.469 8.28339 11.4133 8.40798C11.3708 8.50303 11.3128 8.59281 11.2392 8.67346C11.2277 8.68611 11.2158 8.69847 11.2037 8.71051L9.08577 10.8284C8.69525 11.219 8.06208 11.219 7.67156 10.8284C7.28103 10.4379 7.28103 9.80474 7.67155 9.41422L8.08577 9H5.5C4.94772 9 4.5 8.55228 4.5 8C4.5 7.44772 4.94772 7 5.5 7H8.08576L7.67155 6.5858C7.28103 6.19527 7.28103 5.56211 7.67156 5.17159Z"
      />
    </svg>
  </ColorableSVG>
);
