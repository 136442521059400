import { Radio, RadioGroup } from "@material-ui/core";
import { BusinessStructure } from "../../__generated__/graphql";
import FormBox from "../FormBox";

const BUSINESS_TYPES = [
  {
    display: "Sole Proprietorship",
    value: BusinessStructure.SOLE_PROPRIETORSHIP,
  },
  {
    display: "Limited Partnership (LP)",
    value: BusinessStructure.LP,
  },
  {
    display: "Limited Liability Partnership (LLP)",
    value: BusinessStructure.LLP,
  },
  {
    display: "Limited Liability Company (LLC)",
    value: BusinessStructure.LLC,
  },
  {
    display: "Corporation C Corp",
    value: BusinessStructure.C_CORP,
  },
  {
    display: "Corporation B Corp",
    value: BusinessStructure.B_CORP,
  },
  {
    display: "Corporation Nonprofit",
    value: BusinessStructure.NONPROFIT,
  },
] as const;

type StructureFormProps = {
  handleChange: (value: BusinessStructure) => unknown;
  data?: BusinessStructure;
};

export function StructureForm({
  handleChange,
  data,
}: StructureFormProps): JSX.Element {
  return (
    <RadioGroup
      onChange={(e) => handleChange(e.target.value as BusinessStructure)}
      value={data ?? ""}
    >
      {BUSINESS_TYPES.map((businessType) => (
        <FormBox
          key={businessType.value}
          control={<Radio />}
          label={businessType.display}
          value={businessType.value}
        />
      ))}
    </RadioGroup>
  );
}
