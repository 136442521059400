import { gql } from "@apollo/client";

export const BENEFIT_FIELDS = gql`
  fragment BenefitFields on Benefit {
    id
    benefitsPackageId
    name
    value
    description
    rank
  }
`;

export const BENEFITS_PACKAGE_FIELDS = gql`
  ${BENEFIT_FIELDS}
  fragment BenefitsPackageFields on BenefitsPackage {
    id
    name
    currencyCode
    totalValue
    benefits {
      ...BenefitFields
    }
  }
`;

export const ADJUSTED_CASH_BAND_POINT_FIELDS = gql`
  fragment AdjustedCashBandPointFields on AdjustedCashBandPoint {
    id
    name
    bandName
    value {
      __typename
      ... on CashValue {
        annualRate
        hourlyRate
        currencyCode
      }
    }
    annualCashEquivalent
    hourlyCashEquivalent
  }
`;

export const ADJUSTED_EQUITY_BAND_POINT_FIELDS = gql`
  fragment AdjustedEquityBandPointFields on AdjustedEquityBandPoint {
    id
    name
    bandName
    value {
      __typename
      ... on CashValue {
        annualRate
        currencyCode
      }
      ... on UnitValue {
        unitValue
      }
      ... on PercentValue {
        decimalValue
      }
    }
    annualCashEquivalent
    hourlyCashEquivalent
    totalGrossValue
    totalPercentOwnership {
      decimalValue
    }
    totalUnits
    vestingMonths
  }
`;

export const ADJUSTED_CASH_BAND_FIELDS = gql`
  ${ADJUSTED_CASH_BAND_POINT_FIELDS}
  fragment AdjustedCashBandFields on AdjustedCashBand {
    id
    name
    bandPoints {
      id
      ...AdjustedCashBandPointFields
    }
  }
`;

export const ADJUSTED_EQUITY_BAND_FIELDS = gql`
  ${ADJUSTED_EQUITY_BAND_POINT_FIELDS}
  fragment AdjustedEquityBandFields on AdjustedEquityBand {
    id
    name
    bandPoints {
      id
      ...AdjustedEquityBandPointFields
    }
  }
`;

export const POSITION_FIELDS_MINIMAL = gql`
  fragment PositionFieldsMinimal on Position {
    id
    name
    level
    type
    ladder {
      id
      name
      department {
        id
        name
      }
    }
  }
`;

export const OFFER_CONFIG_FIELDS = gql`
  fragment OfferConfigFields on OfferConfig {
    id
    exitOutcomes
    xAxisAnnotations
    defaultOutcomeDescription
    equityFootnote
    defaultWelcomeMessage
    defaultClosingMessage
    sharePriceLabel
    showEquityInformation
    showFdso
    showStage
    showCurrentEquityValue
    showSharePriceFootnote
    showAnnualizedEquity
    requireApproval
    brandColorPrimary
    primaryLogoUrlPath
    whiteLogoUrlPath
    greyLogoUrlPath
    backgroundUrlPath
    equityCashInValuationCurrency
    customFields {
      name
      description
      variant
      options
    }
  }
`;

export const VALUATION_FIELDS = gql`
  fragment ValuationFields on Valuation {
    id
    activeAt
    estimatedDilution
    financingStage
    fdso
    strikePrice
    valuation
  }
`;

export const COMP_STRUCTURE_FIELDS = gql`
  fragment CompStructureFields on CompStructure {
    id
    bandPointTypes
    cashBandTypes
    equityBandTypes
    allBandTypes
    availableCashBandTypes
    availableEquityBandTypes
    businessStructure
    companyDescription
    equityGrantTypes
    showPercentOwnership
    showValuation
    vestingCliff
    vestingMonths
    levels
    equityGrantMethod
    allowHourlyEmployees
    employmentHoursPerWeek
    employmentWeeksPerYear
  }
`;
