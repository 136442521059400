import { contramap } from "@asmbl/shared/sort";
import { isEmptyString } from "@asmbl/shared/utils";
import { LocationRow } from "../LocationsTable";

export function StateCell({ value }: { value: string | null }): JSX.Element {
  return <>{isEmptyString(value) ? "N/A" : value}</>;
}

Object.assign(StateCell, {
  Cell: StateCell,
  id: "state" as const,
  Header: "St./Pro." as const,
  accessor: "state" as const,
  width: 1.75,
  ordering: contramap((location: LocationRow) => location.state),
});
