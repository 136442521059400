import { isEmptyString } from "@asmbl/shared/utils";
import { Box, Button, TextField } from "@material-ui/core";
import { memo } from "react";
import { JobCodeModalButton } from "../../OfferApproval/JobCodeModalButton";
import Section from "../../Workflow/Section";
import { useStyles } from "../style";
import { ChangeHandler, OfferData } from "../types";
import { CustomFieldAnswers } from "./CustomFieldAnswers";

export type Props = {
  currentStep: number;
  data: OfferData;
  handleChange: ChangeHandler;
  onNext: () => unknown;
  onBack: () => unknown;
};

const OfferApproval = ({
  currentStep,
  data,
  handleChange,
  onNext,
  onBack,
}: Props) => {
  const classes = useStyles();

  const headerDescription =
    data.firstName !== undefined
      ? `Get approval on this offer for ${data.firstName}`
      : "Get approval on this offer.";

  return (
    <Box className={classes.content}>
      <Section
        id="Section-Approval"
        active={currentStep === 4}
        description={headerDescription}
        header="Enter the approval requirements."
      >
        <Box
          className={`${classes.fullWidth} ${classes.jobCodeContainer}`}
          mb={2}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <TextField
            fullWidth
            id="jobCode"
            label="Job Code"
            onChange={(e) => handleChange("jobCode", e.target.value)}
            variant="outlined"
            value={data.jobCode ?? ""}
          />
          <JobCodeModalButton />
        </Box>
        <Box className={classes.fullWidth} mb={2}>
          <TextField
            fullWidth
            id="managerName"
            label="Manager Name"
            onChange={(e) => handleChange("managerName", e.target.value)}
            variant="outlined"
            value={data.managerName ?? ""}
          />
        </Box>
        <Box className={classes.fullWidth} mb={2}>
          <TextField
            fullWidth
            id="decisionMakerName"
            label="Hiring Decision Maker Name"
            onChange={(e) => handleChange("decisionMakerName", e.target.value)}
            variant="outlined"
            value={data.decisionMakerName ?? ""}
          />
        </Box>
        <Box className={classes.fullWidth} mb={2}>
          <TextField
            fullWidth
            id="approverName"
            label="Approver Name"
            onChange={(e) => handleChange("approverName", e.target.value)}
            variant="outlined"
            value={data.approverName ?? ""}
          />
        </Box>
        <CustomFieldAnswers
          customFields={data.customFields ?? []}
          customFieldAnswers={data.customFieldAnswers ?? []}
          handleChange={handleChange}
        />
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Button color="secondary" onClick={onBack} variant="contained">
            Back
          </Button>
          <Button
            color="primary"
            disabled={
              isEmptyString(data.jobCode) ||
              isEmptyString(data.managerName) ||
              isEmptyString(data.decisionMakerName) ||
              isEmptyString(data.approverName) ||
              data.customFieldAnswers?.some(({ answer }) =>
                isEmptyString(answer)
              )
            }
            onClick={onNext}
            variant="contained"
          >
            Next
          </Button>
        </Box>
      </Section>
    </Box>
  );
};

export default memo(OfferApproval);
