import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { AssembleTypography } from "src/components/AssembleTypography";

type Props = {
  variant: "create" | "edit";
  isOpen: boolean;
  handleClose: () => void;
  handleSave: () => void;
};

export function PhaseSaveModal({
  variant,
  isOpen,
  handleClose,
  handleSave,
}: Props): JSX.Element {
  const handleSaveWrapper = () => {
    handleSave();

    if (variant === "edit") {
      handleClose();
    }
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth={"xs"}>
      <DialogTitle>
        <AssembleTypography variant="productHeader">
          We'll save your progress.
        </AssembleTypography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <AssembleTypography variant="productParagraphLarge">
            You can come back and edit your phase and approval settings anytime
            before launching your compensation cycle.
          </AssembleTypography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <AssembleButton
          size="medium"
          variant="text"
          label="Back to Setup"
          onClick={handleClose}
        />
        <AssembleButton
          size="medium"
          variant="contained"
          label={variant === "create" ? "Save and Exit" : "Save Changes"}
          onClick={handleSaveWrapper}
        />
      </DialogActions>
    </Dialog>
  );
}
