import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { SearchBar } from "../../../components/Search/SearchBar";
import { LocationGroupEditor } from "../../../components/Settings/Locations/LocationGroupEditor";
import {
  LocationsActions_locations,
  LocationsActions_markets,
} from "../../../__generated__/graphql";

const useStyles = makeStyles((theme) => ({
  bar: {
    display: "flex",
    justifyContent: "space-between",
    marginBlock: theme.spacing(2.5),
  },
}));

type Props = {
  searchTerm: string;
  onSearchTermChange: (value: string) => unknown;
  locations: LocationsActions_locations[];
  market: LocationsActions_markets;
};

export function LocationsActions({
  searchTerm,
  onSearchTermChange,
  locations,
  market,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.bar}>
      <SearchBar
        searchTerm={searchTerm}
        handleSearchChange={onSearchTermChange}
        placeholder="Search for a location group&hellip;"
        width="wide"
      />
      <LocationGroupEditor locations={locations} market={market} />
    </div>
  );
}

LocationsActions.fragments = {
  locations: gql`
    ${LocationGroupEditor.fragments.locations}
    fragment LocationsActions_locations on Location {
      ...LocationGroupEditor_locations
    }
  `,
  markets: gql`
    ${LocationGroupEditor.fragments.markets}
    fragment LocationsActions_markets on Market {
      ...LocationGroupEditor_markets
    }
  `,
};
