import { canduClientToken } from "./env";

export function initCandu(userId?: string): void {
  const clientToken = canduClientToken();
  if (clientToken != null) {
    // https://docs.candu.ai/en/articles/5908307-installing-candu
    (function (d, params) {
      const script = d.createElement("script");
      script.setAttribute(
        "src",
        "https://cdn.candu.ai/sdk/latest/candu.umd.js?token=" +
          params.clientToken
      );
      script.onload = () => window.Candu?.init(params);
      d.head.appendChild(script);
    })(document, {
      clientToken,
      userId,
    });
  }
}
