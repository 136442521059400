import { useParams } from "react-router-dom";
import { useAuth } from "../../../components/Auth/AuthContext";
import { ErrorView } from "../../../components/ErrorView";
import { ProgressPageLoadingBoundary } from "./ProgressPageLoadingBoundary";

export function ProgressPage() {
  const { organization } = useAuth();
  const { compCycleId } = useParams<{
    compCycleId: string;
  }>();
  const cycleId = compCycleId != null ? parseInt(compCycleId, 10) : NaN;

  if (!organization) {
    return <ErrorView text="Organization ID was not found." />;
  }

  if (isNaN(cycleId)) {
    return <ErrorView text="Comp Cycle ID was not found." />;
  }

  return <ProgressPageLoadingBoundary compCycleId={Number(compCycleId)} />;
}
