import { Money } from "@asmbl/shared/money";
import { DV_BAND_COLORS, SORTED_BAND_NAMES } from "./constants";
import { DV_GREEN, DV_RED, GRAY_2 } from "./theme";

// If you are using these, consider whether there is a simpler approach.
export type ArrayValue<T> = T extends Array<infer U> ? U : never;
export type NonNull<T> = Exclude<T, null>;
export type KeysOfUnion<T> = T extends T ? keyof T : never;

/** Safely access band colors. */
export function getBandColors(index: number): string {
  return DV_BAND_COLORS[index % DV_BAND_COLORS.length];
}

/** Given a hex string, return the contrasting text color (black or white). */
export function getContrastTextColor(hexColor: string): string {
  const splitString = hexColor.split("#");
  const parsedHexColor = splitString[splitString.length - 1];
  const r = parseInt(parsedHexColor.substr(0, 2), 16);
  const g = parseInt(parsedHexColor.substr(2, 2), 16);
  const b = parseInt(parsedHexColor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 180 ? "black" : "white";
}

/**
 * Returns a copy of the set with a given element removed if it previously
 * existed or added if previously non-existent.
 */
export function toggleSet<T>(existingSet: Set<T>, element: T): Set<T> {
  const newSet = new Set<T>(existingSet);
  existingSet.has(element) ? newSet.delete(element) : newSet.add(element);
  return newSet;
}

export type Range = {
  min: number;
  max: number;
};

/**
 * Returns the min and max comp range of a list of positions with some padding
 * on the min and max.
 */
export function getTotalCompRange(
  computedPositions: {
    totalCompMin: Money;
    totalCompMax: Money;
  }[]
): Range {
  let ladderMin = Infinity,
    ladderMax = 0;

  for (const position of computedPositions) {
    ladderMin = Math.min(position.totalCompMin.value, ladderMin);
    ladderMax = Math.max(position.totalCompMax.value, ladderMax);
  }

  const range = ladderMax - ladderMin;
  const padding = range * 0.05;

  const paddedMax = ladderMax + padding;
  const paddedMin = Math.max(0, ladderMin - padding);

  return {
    min: paddedMin,
    max: paddedMax,
  };
}

/**
 * Create a comparator for point objects based on the ordered set of points.
 */
export function createPointComparator(
  pointTypes: string[]
): (a: { name: string }, b: { name: string }) => number {
  return (a: { name: string }, b: { name: string }) => {
    return pointTypes.indexOf(a.name) - pointTypes.indexOf(b.name);
  };
}

/**
 * Sort band objects by their pre-defined name order.
 */
export function bandComparator(
  a: { name: string },
  b: { name: string }
): number {
  return (
    SORTED_BAND_NAMES.indexOf(a.name as (typeof SORTED_BAND_NAMES)[number]) -
    SORTED_BAND_NAMES.indexOf(b.name as (typeof SORTED_BAND_NAMES)[number])
  );
}

/**
 * Sort band names by their pre-defined name order.
 */
export function bandNameComparator(a: string, b: string): number {
  return (
    SORTED_BAND_NAMES.indexOf(a as (typeof SORTED_BAND_NAMES)[number]) -
    SORTED_BAND_NAMES.indexOf(b as (typeof SORTED_BAND_NAMES)[number])
  );
}

/**
 * Sort objects by level range (min then max).
 */
export function levelRangeComparator(
  a: { levelMin: number; levelMax: number },
  b: { levelMin: number; levelMax: number }
): number {
  return a.levelMin - b.levelMin || a.levelMax - b.levelMax;
}

/**
 * Given a FormData, return the string value for a given key (or null if not a
 * string).
 */
export function getStringFromForm(
  formData: FormData,
  key: string
): string | null {
  const formField = formData.get(key);
  return formField instanceof File ? null : formField;
}

export function arrayWithoutItem<T>(array: T[], index: number): T[] {
  return [...array.slice(0, index), ...array.slice(index + 1)];
}

/**
 * For an adjustment value, return the appropriate color based on positive, negative, or neutral value.
 */
export function getAdjustmentColor(adjustment: string | number): string {
  if (adjustment === "" || adjustment === 0) return GRAY_2;

  const adjustmentValue = Number(adjustment);
  if (adjustmentValue > 0) return DV_GREEN;
  if (adjustmentValue < 0) return DV_RED;
  return GRAY_2;
}

/**
 * Return just the domain portion of an email address.
 */
export function getDomainFromEmail(email?: string | null): string | null {
  return email?.split("@").pop() ?? null;
}

/**
 * If the value is zero or undefined, return an empty string. Otherwise, return
 * the original value.
 */
export function formatNonZeroOrEmptyString(
  value: number | undefined
): number | string {
  return value === undefined || value === 0 ? "" : value;
}

/**
 * Takes a sentence and splits it up into lines of width maxChar.
 * If a word is longer than maxChar, it will appear on its own line without
 * truncation.
 */
export function splitSentenceIntoLines(
  sentence: string,
  maxChar = 12
): string[] {
  const words = sentence.split(" ");
  const splitAnnotation: string[] = [];
  let line = words[0];

  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    if (line.length + word.length < maxChar) {
      line = `${line} ${word}`;
    } else {
      splitAnnotation.push(line);
      line = word;
    }
  }
  if (line !== "") {
    splitAnnotation.push(line);
  }
  return splitAnnotation;
}

export function formatDateString(date: Date): string {
  return new Date(date).toISOString().slice(0, 10);
}

/**
 * Extends a promise to take at least a certain amount of time to resolve.
 * This is useful for ensuring that loading animations are displayed long enough
 * for the user to see them.
 */
export function withMinWait<R>(fn: () => Promise<R>, ms: number): Promise<R> {
  const wait = new Promise((resolve) => setTimeout(resolve, ms));

  // Using 'all' instead of 'allSettled' assumes that errors can be reported without a wait
  return Promise.all([fn(), wait]).then(([result]) => result);
}

/** Given any list, it will return a new list with the item at `startIndex`
 * moved to `endIndex`
 */
export function reorder<T>(
  list: T[],
  startIndex: number,
  endIndex: number
): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function setCompCycleCompletedEmployees(
  id: number,
  array: number[]
): void {
  localStorage.setItem(
    "compCycleCompletedEmployees",
    JSON.stringify({ [id]: array })
  );
}

export function capitalize(s: string) {
  return s && s[0].toUpperCase() + s.slice(1);
}

export function capitalizeEachWord(s: string): string {
  return s
    .split(" ")
    .map((subStr) =>
      subStr
        .split("")
        .map((char, i) => (i === 0 ? char.toUpperCase() : char.toLowerCase()))
        .join("")
    )
    .join(" ");
}

export function remToPixels(rem: string): number {
  if (!rem.includes("rem")) {
    throw Error("Input must be in rem format: <number>rem");
  }

  const [value, _unit] = rem.split("rem");

  return Number.parseFloat(value) * 16;
}

export function pixelsToNumber(pixels: string): number {
  if (!pixels.includes("px")) {
    throw Error("Input must be in px format: <number>px");
  }

  const [value, _unit] = pixels.split("px");

  return Number.parseInt(value);
}
