import { gql, useQuery } from "@apollo/client";
import { LinearProgress, makeStyles } from "@material-ui/core";
import {
  CompCycleUploadRequestsQuery,
  CompCycleUploadRequestsQueryVariables,
} from "../../../__generated__/graphql";
import { ErrorView } from "../../ErrorView";
import {
  CompCycleData,
  CompCycleDataChangeHandler,
} from "../CompCycleWizard/types";
import CompCycleUploadRequests from "./CompCycleUploadRequests";

const useStyles = makeStyles(() => ({
  // need to use absolute positioning to avoid the loading bar being pushed
  // down due to being rendered as part of the page content
  loading: { position: "absolute", top: 0, width: "100%" },
}));

type Props =
  | {
      mode: "create";
      compCycleData: CompCycleData;
      handleChange: CompCycleDataChangeHandler;
      onSubmit: (data: CompCycleData) => Promise<unknown>;
      onBack: () => unknown;
    }
  | {
      mode: "update";
      compCycleId: number;
      compCycleData: Pick<CompCycleData, "compComponents">;
    };

export function CompCycleUploadRequestsLoadingBoundary(
  props: Props
): JSX.Element {
  const { data, loading } = useQuery<
    CompCycleUploadRequestsQuery,
    CompCycleUploadRequestsQueryVariables
  >(CompCycleUploadRequestsLoadingBoundary.query);
  const classes = useStyles();

  if (data == null) {
    return loading ? (
      <LinearProgress className={classes.loading} />
    ) : (
      <ErrorView text="Unable to retrieve data." />
    );
  }

  if (!data.valuation) {
    return <ErrorView text="Unable to load valuation data." />;
  }

  return (
    <CompCycleUploadRequests
      {...props}
      valuation={data.valuation}
      employees={data.employees}
      positions={data.positions.map((pos) => ({
        ...pos,
        name: pos.name.trim(),
      }))}
    />
  );
}

CompCycleUploadRequestsLoadingBoundary.query = gql`
  ${CompCycleUploadRequests.fragments.employee}
  ${CompCycleUploadRequests.fragments.valuation}
  ${CompCycleUploadRequests.fragments.position}
  query CompCycleUploadRequestsQuery {
    employees {
      id
      ...CompCycleUploadRequests_employee
    }
    valuation {
      id
      ...CompCycleUploadRequests_valuation
    }
    positions {
      id
      ...CompCycleUploadRequests_position
    }
  }
`;
