import { gql } from "@apollo/client";
import { createContext, useContext, useState } from "react";
import { noop } from "src/test-helpers";
import { initPhaseData } from "./utils";

export type CompCyclePhaseData = {
  id?: number;
  assigneeIds: number[];
  phaseOrder: number;
  startDate: Date | undefined;
};

export type PhaseConfigurationData = CompCyclePhaseData[];

export const PhaseConfigurationContext = createContext<{
  phaseConfiguration: PhaseConfigurationData;
  setPhaseData: (
    phaseOrder: number,
    data?: Partial<Omit<CompCyclePhaseData, "phaseOrder">>
  ) => void;
  deletePhase: (phaseOrder: number) => void;
  endDate: Date | undefined;
  setEndDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  phaseErrors: number[];
  validatePhases: () => boolean;
}>({
  phaseConfiguration: [],
  setPhaseData: () => noop,
  deletePhase: () => noop,
  endDate: undefined,
  setEndDate: () => undefined,
  phaseErrors: [],
  validatePhases: () => true,
});

export function usePhaseConfiguration() {
  return useContext(PhaseConfigurationContext);
}

function validatePhases(
  phaseConfiguration: PhaseConfigurationData,
  setPhaseError: React.Dispatch<React.SetStateAction<number[]>>
) {
  return () => {
    const errors = phaseConfiguration
      .filter((phase) => phase.startDate == null)
      .map((phase) => phase.phaseOrder);

    setPhaseError(errors);

    return errors.length === 0;
  };
}

function deletePhase(
  phaseConfiguration: PhaseConfigurationData,
  phaseErrors: number[],
  setPhaseConfiguration: React.Dispatch<
    React.SetStateAction<PhaseConfigurationData>
  >,
  setPhaseErrors: React.Dispatch<React.SetStateAction<number[]>>
) {
  return (phaseOrder: number) => {
    const phaseIndexToRemove = phaseConfiguration.findIndex(
      (phase) => phase.phaseOrder === phaseOrder
    );

    if (phaseIndexToRemove === -1) return;

    const newPhaseData = phaseConfiguration
      .filter((phase) => phase.phaseOrder !== phaseOrder)
      .map((phase) => {
        return phase.phaseOrder > phaseOrder
          ? { ...phase, phaseOrder: phase.phaseOrder - 1 }
          : phase;
      });

    if (phaseErrors.length > 0) {
      validatePhases(newPhaseData, setPhaseErrors)();
    }

    setPhaseConfiguration(newPhaseData);
  };
}

function setPhaseData(
  phaseErrors: number[],
  setPhaseConfiguration: React.Dispatch<
    React.SetStateAction<PhaseConfigurationData>
  >,
  setPhaseErrors: React.Dispatch<React.SetStateAction<number[]>>
) {
  return (
    phaseOrder: number,
    data?: Partial<Omit<CompCyclePhaseData, "phaseOrder">>
  ) => {
    setPhaseConfiguration((prevData) => {
      const newPhaseConfiguration = [...prevData];

      const phaseIndex = newPhaseConfiguration.findIndex(
        (phase) => phase.phaseOrder === phaseOrder
      );

      // if phaseIndex === -1, that means we are creating
      // a new phase, so we need to initialize the phase data
      // and then update the `phaseOrder`s of the current phases
      if (phaseIndex === -1) {
        newPhaseConfiguration.push({
          ...initPhaseData(phaseOrder),
          ...data,
        });

        newPhaseConfiguration.forEach((phase, idx) => {
          newPhaseConfiguration[idx] = {
            ...phase,
            phaseOrder: newPhaseConfiguration.length - idx,
          };
        });

        if (phaseErrors.length > 0) {
          validatePhases(newPhaseConfiguration, setPhaseErrors)();
        }

        return newPhaseConfiguration;
      }

      newPhaseConfiguration[phaseIndex] = {
        ...newPhaseConfiguration[phaseIndex],
        ...data,
      };

      if (phaseErrors.length > 0) {
        validatePhases(newPhaseConfiguration, setPhaseErrors)();
      }

      return newPhaseConfiguration;
    });
  };
}

export function PhaseConfigurationProvider({
  children,
  initValue,
}: {
  children: React.ReactNode;
  initValue?: { phaseData?: PhaseConfigurationData; endDate?: Date };
}): JSX.Element {
  const [phaseConfiguration, setPhaseConfiguration] =
    useState<PhaseConfigurationData>(initValue?.phaseData ?? []);
  const [endDate, setEndDate] = useState<Date | undefined>(initValue?.endDate);
  const [phaseErrors, setPhaseErrors] = useState<number[]>([]);

  return (
    <PhaseConfigurationContext.Provider
      value={{
        phaseConfiguration: phaseConfiguration,
        deletePhase: deletePhase(
          phaseConfiguration,
          phaseErrors,
          setPhaseConfiguration,
          setPhaseErrors
        ),
        setPhaseData: setPhaseData(
          phaseErrors,
          setPhaseConfiguration,
          setPhaseErrors
        ),
        endDate,
        setEndDate,
        phaseErrors,
        validatePhases: validatePhases(phaseConfiguration, setPhaseErrors),
      }}
    >
      {children}
    </PhaseConfigurationContext.Provider>
  );
}

PhaseConfigurationProvider.fragments = {
  phase: gql`
    fragment PhaseConfigurationProvider_phase on CompCyclePhase2 {
      id
      compCycleId
      phaseOrder
      startDate
      compCyclePhaseAssignments {
        id
        phaseId
        assigneeId
        status
      }
    }
  `,
};
