import { gql } from "@apollo/client";
import {
  ScalarDictionaryWithCustom as FlatFileRecord,
  IDataHookResponse,
} from "@flatfile/react";
import { Button } from "@material-ui/core";
import { useTrack } from "src/analytics";
import { AssembleFlatfileButton } from "src/components/AssembleFlatfileButton";
import { CurrencyField } from "src/models/csv/Fields/CurrencyField";
import { EmployeeIdentifierFields } from "src/models/csv/Fields/EmployeeIdentifierFields";
import { VariableCompIdentifierFields } from "src/models/csv/Fields/VariableCompIdentifierFields";
import {
  CompUnit,
  CurrencyCode,
  VariableCompImportButton_employee as Employee,
  ImportVariableCompInput,
  VariableCompType,
} from "../../../__generated__/graphql";
import { matchEmployee, validateDate } from "../../../models/csv/EmployeeCSV";
import { useImportVariableComp } from "../../../mutations/CashCompensation";
import { useCurrencies } from "../../CurrenciesContext";

type Props = {
  employees: Employee[];
  onCancel?: () => unknown;
  onUpload?: () => unknown;
};

type TypedRow = {
  activeAt: string;
  compUnit: string;
  currency: string;
  email: string;
  employeeId: string;
  employeeNumber: string;
  name: string;
  variableCompType: string;
  variableCompValue: string;
};

export function VariableCompImportButton({
  employees,
  onCancel,
  onUpload = () => {
    // noop
  },
}: Props): JSX.Element {
  const validateRecord = recordValidator(employees);
  const { currencies } = useCurrencies();
  const upload = useImportVariableComp();
  const { trackEvent } = useTrack();

  return (
    <AssembleFlatfileButton
      settings={{
        title: "Add your Variable Compensation Data",
        type: "variable_comp",
        fields: [
          ...EmployeeIdentifierFields({ allowDuplicates: true }),
          ...VariableCompIdentifierFields,
          ...CurrencyField(currencies),
        ],
      }}
      onRecordInit={validateRecord}
      onRecordChange={validateRecord}
      onData={async (results) => {
        await upload(parseData(results.validData, employees));

        trackEvent({
          object: "Employee Target Variable Comp",
          action: "Imported",
        });

        onUpload();
      }}
      onCancel={onCancel}
      render={(_, launch) => {
        const onClickWrapper = () => {
          trackEvent({
            object: "Import Employee Target Variable Comp",
            action: "Clicked",
          });

          launch();
        };

        return (
          <Button onClick={onClickWrapper} variant="contained" color="primary">
            Upload Target Variable Compensation
          </Button>
        );
      }}
    />
  );
}

function recordValidator(employees: Employee[]) {
  return function validateRecord(record: FlatFileRecord): IDataHookResponse {
    const [, employeeMatchingInfo] = matchEmployee(record, employees);

    return {
      ...employeeMatchingInfo,
      ...validateDate(record, "activeAt"),
      ...infoAboutPercentages(record),
    };
  };
}

/**
 * Warn the user that we don't concert decimals between 0 and 1 to a percentage.
 */
export function infoAboutPercentages(
  record: FlatFileRecord
): IDataHookResponse {
  const typedRecord = convertUntypedRow(record);
  const stringValue = typedRecord.variableCompValue;
  const value = parseFloat(stringValue);
  const isPercentage = record.compUnit === "Percent of Salary";

  if (!isPercentage || isNaN(value) || value >= 1) return {};

  if (value < 1 && value > 0) {
    const message =
      `Be aware that we do not convert decimals between 0 and ` +
      `1 to a percentage, e.g. '${stringValue}' does not equal ` +
      `${value * 100}%. Instead, use '${value * 100}' to represent that.`;

    return { variableCompValue: { info: [{ message, level: "warning" }] } };
  }
  return {};
}

function parseData(
  validData: unknown[],
  employees: Employee[]
): ImportVariableCompInput[] {
  return validData.map((untypedRow) => {
    const row = convertUntypedRow(untypedRow as FlatFileRecord);
    const [employee] = matchEmployee(row, employees);
    const employeeId = employee?.id;

    if (employeeId == null) {
      throw new Error("Employee Not Found");
    }

    const type = {
      Commission: VariableCompType.COMMISSION,
      "Recurring Bonus": VariableCompType.RECURRING_BONUS,
    }[row.variableCompType];

    if (type === undefined) {
      throw new Error("Variable Compensation Type Not Found");
    }

    const unit = {
      "Percent of Salary": CompUnit.PERCENT_OF_SALARY,
      "Cash Value": CompUnit.CASH,
    }[row.compUnit];
    if (unit === undefined) {
      throw new Error("Variable Compensation Unit Not Found");
    }

    return {
      employeeId,
      type,
      unit,
      activeAt: formatDate(new Date(row.activeAt)),
      value: parseFloat(row.variableCompValue),
      currency: row.currency as CurrencyCode,
    };
  });
}

/**
 * Returns a date formatted as YYYY-MM-DD
 */
function formatDate(date: Date): string {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1; // getUTCMonth is zero-indexed
  const day = date.getUTCDate();

  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  return [year, formattedMonth, formattedDay].join("-");
}

/**
 * Converts a FlatFileRecord to a strongly-typed object, throwing an error if
 * any of the fields are null or undefined.
 */
function convertUntypedRow(row: FlatFileRecord): TypedRow {
  const object = {
    activeAt: row.activeAt as string | undefined | null,
    compUnit: row.compUnit as string | undefined | null,
    currency: row.currency as string | undefined | null,
    email: row.email as string | undefined | null,
    employeeId: row.employeeId as string | undefined | null,
    employeeNumber: row.employeeNumber as string | undefined | null,
    name: row.name as string | undefined | null,
    variableCompType: row.variableCompType as string | undefined | null,
    variableCompValue: row.variableCompValue as string | undefined | null,
  };

  return object as TypedRow;
}

VariableCompImportButton.fragments = {
  employee: gql`
    fragment VariableCompImportButton_employee on Employee {
      id
      employeeNumber
      email
      personalEmail
    }
  `,
};
