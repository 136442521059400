import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const ColumnSettingsIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Column Settings"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M14 2.25H2C1.55002 2.25 1.21933 2.51154 1.03202 2.77911C0.843061 3.04906 0.75 3.38203 0.75 3.71429V12.2857C0.75 12.618 0.843061 12.9509 1.03202 13.2209C1.21933 13.4885 1.55002 13.75 2 13.75H14C14.45 13.75 14.7807 13.4885 14.968 13.2209C15.1569 12.9509 15.25 12.618 15.25 12.2857V3.71429C15.25 3.38203 15.1569 3.04906 14.968 2.77911C14.7807 2.51154 14.45 2.25 14 2.25Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M5.5 3V13"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        className="stroke"
        d="M10.5 3V13"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  </ColorableSVG>
);
