import { Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { CloseCircleIcon } from "src/components/AssembleIcons/Brand/CloseCircleIcon";
import { AssembleTypography } from "../../AssembleTypography";

const useStyles = makeStyles(() => ({
  button: {
    height: "2rem",
    width: "5rem",
  },
}));

export function HRISLocationsLinkClose(): JSX.Element {
  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      component={Link}
      variant="outlined"
      color="default"
      to="#"
      endIcon={<CloseCircleIcon />}
    >
      <AssembleTypography variant="productParagraphSmall">
        Close
      </AssembleTypography>
    </Button>
  );
}
