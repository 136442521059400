import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const CashIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Cash"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M8 2V12.6667"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M8 12.6667V14"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M5 12.6666H9.5C10.163 12.6666 10.7989 12.4208 11.2678 11.9832C11.7366 11.5456 12 10.9522 12 10.3333C12 9.71447 11.7366 9.12098 11.2678 8.6834C10.7989 8.24581 10.163 7.99998 9.5 7.99998H6.5C5.83696 7.99998 5.20107 7.75415 4.73223 7.31656C4.26339 6.87898 4 6.28549 4 5.66665C4 5.04781 4.26339 4.45432 4.73223 4.01673C5.20107 3.57915 5.83696 3.33331 6.5 3.33331H11"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
