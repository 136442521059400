import { makeStyles, Theme, Tooltip, Typography } from "@material-ui/core";
import { memo } from "react";
import {
  DV_ORANGE,
  DV_PINK,
  DV_YELLOW,
  GRAY_1,
  GRAY_3,
  GRAY_9,
  GREEN_2,
  RED,
  WHITE,
} from "../../theme";
import { CompCompaRatioSliderTooltip } from "./CompCompaRatioSliderPopover";
import { Position } from "./CompSliderPopover";
import { BandPoint } from "./LargeCompSlider";

const useStyles = makeStyles<Theme, { height?: string }>(() => ({
  container: {
    position: "relative",
    width: "100%",
  },
  band: {
    width: "100%",
    height: ({ height }) => height ?? "25px",
    background: `no-repeat linear-gradient(270deg, ${DV_PINK} 0%, ${DV_ORANGE} 78.13%, ${DV_YELLOW} 100%)`,
    borderRadius: "2px",
    display: "flex",
    flexDirection: "row",
    position: "relative",

    "&$outOfRange": {
      border: `1px solid ${WHITE}`,
      boxShadow: "0px 0px 0px 2px rgba(10, 36, 64, 0.4)",
    },
  },
  dot: {
    height: "9px",
    width: "9px",
    borderRadius: "50%",
    backgroundColor: WHITE,
    border: `1px solid ${DV_ORANGE}`,
    boxShadow: "0px 1px 2px rgba(10, 36, 64, 0.2)",
    position: "absolute",
    left: "0.5px",
    top: "50%",
    transform: "translate(-50%, -50%)",

    "$outOfRange &": {
      border: `1px solid ${WHITE}`,
    },
    "$belowRange &": {
      background: GREEN_2,
    },
    "$aboveRange &": {
      background: RED,
    },
  },
  bandPoint: {
    height: "9px",
    width: "9px",
    borderRadius: "50%",
    border: `1px solid ${WHITE}`,
    boxShadow: "0px 1px 2px rgba(10, 36, 64, 0.2)",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  outOfRange: {},
  belowRange: {},
  aboveRange: {},
  line: {
    background: GRAY_9,
    height: "100%",
    position: "absolute",
    top: 0,
    transition: "all 250ms ease",
    width: "1px",
  },
  label: {
    color: GRAY_3,
    fontSize: "10px",
    fontWeight: 700,
    textAlign: "center",
    textTransform: "uppercase",
    position: "absolute",
    top: "calc(100% + 2px)",
    width: "100%",
  },
  minValueLabel: {
    color: GRAY_1,
    fontSize: "12px",
    position: "absolute",
    top: "calc(100% + 2px)",
    left: 0,
  },
  maxValueLabel: {
    color: GRAY_1,
    fontSize: "12px",
    position: "absolute",
    top: "calc(100% + 2px)",
    right: 0,
  },
}));

export type Props = {
  bandPoints: BandPoint[];
  value: number | null;
  position: Position | null;
  height?: string;
  labeled?: boolean;
};

export const LargeCompaRatioSlider = memo(function LargeCompaRatioSlider({
  bandPoints,
  value,
  position,
  height,
  labeled = true,
}: Props) {
  const classes = useStyles({ height });

  const sortedValues = bandPoints.slice().sort((a, b) => a.value - b.value);
  const maxValue = sortedValues[sortedValues.length - 1];
  const minValue = sortedValues[0];

  const isBelowRange = value != null ? value < minValue.value : false;
  const isAboveRange = value != null ? value > maxValue.value : false;
  const isOutOfRange = isBelowRange || isAboveRange;

  const getOffset = (value: number): string => {
    if (value === minValue.value && value === maxValue.value) {
      return "50%";
    }

    const offset =
      ((value - minValue.value) / (maxValue.value - minValue.value)) * 100;
    if (offset > 100) return "calc(100% + 12px)";
    if (offset < 0) return "-12px";
    return `${offset}%`;
  };

  return (
    <ConditionalTooltip
      conditional={!labeled}
      bandPoints={bandPoints}
      value={value}
      position={position}
    >
      <div className={classes.container}>
        <div
          className={`
        ${classes.band} 
        ${isOutOfRange ? classes.outOfRange : ""}
        ${isBelowRange ? classes.belowRange : ""}
        ${isAboveRange ? classes.aboveRange : ""}
      `}
        >
          {value != null && (
            <Tooltip placement="top" title={`Compa Ratio · ${value}`}>
              <div className={classes.line} style={{ left: getOffset(value) }}>
                <div className={classes.dot} />
              </div>
            </Tooltip>
          )}
          {labeled &&
            // Display additional band points apart from the min and max
            sortedValues
              .slice(1, sortedValues.length - 1)
              .map(({ name, value }) => {
                return (
                  <Tooltip
                    key={value}
                    placement="top"
                    title={`${name} · ${value}`}
                  >
                    <div
                      style={{ left: getOffset(value) }}
                      className={classes.bandPoint}
                    />
                  </Tooltip>
                );
              })}
        </div>
        {labeled && (
          <>
            <Typography
              variant="caption"
              className={classes.minValueLabel}
              component="div"
            >
              {minValue.value}
            </Typography>
            <Typography
              variant="caption"
              className={classes.maxValueLabel}
              component="div"
            >
              {maxValue.value}
            </Typography>
          </>
        )}
        {isOutOfRange && (
          <Typography
            variant="caption"
            className={classes.label}
            component="div"
          >
            {isBelowRange ? "Below Band" : "Above Band"}
          </Typography>
        )}
      </div>
    </ConditionalTooltip>
  );
});

type ConditionalPopoverProps = {
  conditional: boolean;
  bandPoints: BandPoint[];
  value: number | null;
  position: Position | null;
  children: JSX.Element;
};

function ConditionalTooltip({
  conditional,
  bandPoints,
  value,
  position,
  children,
}: ConditionalPopoverProps): JSX.Element {
  if (conditional) {
    return (
      <CompCompaRatioSliderTooltip
        value={value}
        bandPoints={bandPoints}
        position={position}
      >
        {children}
      </CompCompaRatioSliderTooltip>
    );
  }
  return <>{children}</>;
}
