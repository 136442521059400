import { mapMaybe } from "@asmbl/shared/utils";
import { Box, makeStyles, Theme } from "@material-ui/core";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  cloneElement,
  createRef,
  ReactElement,
  RefObject,
  useRef,
  useState,
} from "react";
import { BLUE_2, WHITE } from "../../theme";
import { NonNull } from "../../utils";
import { GetOffer } from "../../__generated__/graphql";
import { AssembleButton } from "../AssembleButton/AssembleButton";
import { FrameProps } from "./Frame/Frame";

type OfferConfig = NonNull<GetOffer["offerConfig"]>;

const useStyles = makeStyles((theme: Theme) => ({
  printButton: {
    background: BLUE_2,
    border: "none",
    color: WHITE,
    filter: "drop-shadow(0px 4px 18px rgba(10, 36, 64, 0.15))",
    left: "50%",
    padding: "13px 18px",
    position: "fixed",
    top: theme.spacing(8),
    transform: "translateX(-50%)",
    transition: "all 300ms",
    zIndex: theme.zIndex.modal,

    "&:hover": {
      background: "#0162EC",
      color: WHITE,
    },
  },
  scroll: {
    marginLeft: "1px",
    zIndex: theme.zIndex.drawer,
  },
}));

interface Props {
  candidateName: string;
  offerConfig: OfferConfig;
  children: ReactElement<FrameProps>[];
}

export function PDFPrintableLayout({
  candidateName,
  offerConfig,
  children,
}: Props): JSX.Element {
  const classes = useStyles();
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const frameRefs = useRef<RefObject<HTMLDivElement>[]>(
    children.map(() => createRef())
  );

  const pdfDownload = async (e: React.MouseEvent) => {
    e.preventDefault();
    setPdfLoading(true);
    window.scrollTo(0, 0);

    // -- PDF gen config -----
    const pdf = new jsPDF("l", "mm", "a4", true);
    const filename = `${candidateName} Compensation.pdf`;

    // -- Determine slide background color -----
    const setBgColor = (i: number, slideCount: number) => {
      if (i === 0 || i === slideCount - 1) return offerConfig.brandColorPrimary;

      return WHITE;
    };

    const getCanvas = async (
      slide: HTMLElement,
      index: number,
      allSlides: HTMLElement[]
    ) => {
      slide.style.backgroundSize = "cover";
      const canvas = html2canvas(slide, {
        useCORS: true,
        allowTaint: true,
        backgroundColor: setBgColor(index, allSlides.length),
      });
      slide.style.backgroundSize = "0 0";

      return canvas;
    };

    const slides = mapMaybe(frameRefs.current, (ref) => ref.current);

    const canvases = await Promise.all(slides.map(getCanvas));

    canvases.forEach((canvas, index) => {
      if (index !== 0) pdf.addPage();
      pdf.setPage(index + 1);
      pdf.addImage(
        canvas.toDataURL("image/png"),
        "PNG",
        -10,
        0,
        316,
        210,
        "",
        "FAST"
      );
    });

    pdf.save(filename);
    setPdfLoading(false);
  };

  return (
    <Box id="scroll" className={classes.scroll}>
      <AssembleButton
        className={classes.printButton}
        onClick={pdfDownload}
        variant="contained"
        size="large"
        label={pdfLoading ? "Downloading PDF..." : "Download Offer"}
      />
      {children.map(
        (child, index) =>
          cloneElement(child, {
            page: index + 1,
            ref: frameRefs.current[index],
          } as Partial<FrameProps>) // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/40888
      )}
    </Box>
  );
}
