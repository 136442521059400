import { ISettings } from "@flatfile/adapter";
import { INTEGER_VALIDATOR } from "./Validators";

export const EquityFields: ISettings["fields"] = [
  {
    label: "New Equity Grant ($)",
    key: "equity",
    validators: [
      {
        validate: "regex_matches",
        regex: INTEGER_VALIDATOR,
        error: "Must be a whole number",
      },
    ],
  },
  {
    label: "New Equity Grant (Units)",
    key: "equityUnits",
    validators: [
      {
        validate: "regex_matches",
        regex: INTEGER_VALIDATOR,
        error: "Must be a whole number",
      },
    ],
  },
  {
    label: "Equity Grant Notes",
    key: "equityNotes",
  },
];
