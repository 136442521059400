import { useMutation } from "@apollo/client";
import { makeStyles, Switch, Theme } from "@material-ui/core";
import { formatNumber } from "accounting";
import { useState } from "react";
import { AssembleTypography } from "src/components/AssembleTypography";
import { NumberInput } from "src/components/Form/NumberInput";
import FormBox from "src/components/FormBox";
import { GRAY_2, GRAY_4 } from "src/theme";
import {
  GetCompStructure,
  UpsertCompStructure,
  UpsertCompStructureVariables,
} from "../../../__generated__/graphql";
import { UPSERT_COMP_STRUCTURE } from "../../../mutations";
import { COMP_STRUCTURE_QUERY } from "../../../queries";
import { NonNull } from "../../../utils";

type CompStructure = NonNull<GetCompStructure["compStructure"]>;

const useStyles = makeStyles((theme: Theme) => ({
  hourlyEmployees: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  hourlySwitch: {
    marginTop: theme.spacing(1),
  },
  description: {
    color: GRAY_2,
  },
  numberInputContainer: {
    display: "inline-flex",
    gap: theme.spacing(1.5),
    alignItems: "center",
    color: GRAY_4,
    marginTop: theme.spacing(2),
  },
  numberInput: {
    minWidth: theme.spacing(20.5),
    maxWidth: theme.spacing(20.5),
  },
}));

function HourlyEmployees({
  compStructure,
  disabled,
}: {
  compStructure: CompStructure;
  disabled: boolean;
}) {
  const classes = useStyles();

  const [employmentHoursPerWeek, setEmploymentHoursPerWeek] = useState<
    number | undefined
  >(compStructure.employmentHoursPerWeek);
  const [employmentWeeksPerYear, setEmploymentWeeksPerYear] = useState<
    number | undefined
  >(compStructure.employmentWeeksPerYear);

  const [upsertCompStructure] = useMutation<
    UpsertCompStructure,
    UpsertCompStructureVariables
  >(UPSERT_COMP_STRUCTURE, {
    refetchQueries: [{ query: COMP_STRUCTURE_QUERY }],
  });

  const isEmploymentHoursPerWeekInvalid =
    employmentHoursPerWeek === undefined ||
    employmentHoursPerWeek <= 0 ||
    employmentHoursPerWeek > 168;

  const isEmploymentWeeksPerYearInvalid =
    employmentWeeksPerYear === undefined ||
    employmentWeeksPerYear <= 0 ||
    employmentWeeksPerYear > 52;

  const handleOnBlur = () => {
    if (!isEmploymentHoursPerWeekInvalid && !isEmploymentWeeksPerYearInvalid) {
      void upsertCompStructure({
        variables: {
          data: {
            employmentHoursPerWeek,
            employmentWeeksPerYear,
          },
        },
      });
    }
  };

  const handleOnToggle = (val: boolean) => {
    void upsertCompStructure({
      variables: {
        data: {
          allowHourlyEmployees: val,
        },
      },
    });
  };

  return (
    <div className={classes.hourlyEmployees}>
      <AssembleTypography variant="h4">Hourly Employees</AssembleTypography>
      <AssembleTypography variant="body1" className={classes.description}>
        Below are additional compensation settings if you want to use Assemble
        for hourly employees.
      </AssembleTypography>
      <div className={classes.hourlySwitch} />
      <FormBox
        control={<Switch />}
        label="Include Hourly Employees"
        name="Include Hourly Employees"
        onChange={(e, val: boolean) => handleOnToggle(val)}
        checked={compStructure.allowHourlyEmployees}
        reverse
      />
      <AssembleTypography variant="h5">Hours in a Year</AssembleTypography>
      <AssembleTypography
        variant="productParagraphMedium"
        className={classes.description}
      >
        How many hours should we use to convert annualized salary? (Default =
        2,080)
      </AssembleTypography>
      <AssembleTypography variant="productMicrocopy" color="textSecondary">
        Note: Exclude overtime when calculating workweek hours.
      </AssembleTypography>
      <div className={classes.numberInputContainer}>
        <NumberInput
          placeholder="40"
          label="Hours in a Workweek"
          variant="outlined"
          value={employmentHoursPerWeek}
          onValueChange={({ floatValue }) =>
            setEmploymentHoursPerWeek(floatValue)
          }
          onBlur={handleOnBlur}
          disabled={disabled}
          error={isEmploymentHoursPerWeekInvalid}
          className={classes.numberInput}
        />
        <div>
          <AssembleTypography variant="productSectionHeader">
            x
          </AssembleTypography>
        </div>

        <NumberInput
          placeholder="52"
          label="Weeks in a Year"
          variant="outlined"
          value={employmentWeeksPerYear}
          onValueChange={({ floatValue }) =>
            setEmploymentWeeksPerYear(floatValue)
          }
          onBlur={handleOnBlur}
          disabled={disabled}
          error={isEmploymentWeeksPerYearInvalid}
          className={classes.numberInput}
        />

        <AssembleTypography variant="h4">=</AssembleTypography>
        <AssembleTypography variant="h2">
          {formatNumber(
            (employmentHoursPerWeek ?? 0) * (employmentWeeksPerYear ?? 0)
          )}
        </AssembleTypography>
      </div>
    </div>
  );
}

export default HourlyEmployees;
