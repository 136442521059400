import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const PerformanceRatingIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Performance Rating"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M8.23717 12.6705L11.7289 14.9036C12.1653 15.2013 12.7473 14.7547 12.6018 14.3081L11.5834 10.2885C11.5834 9.99078 11.5834 9.84191 11.7289 9.69304L14.7841 7.01332C15.2206 6.71558 14.9296 5.97121 14.4931 5.97121L10.4195 5.67346C10.1285 5.67346 9.98301 5.52459 9.98301 5.22685L8.52814 1.35615C8.38265 1.0584 8.09168 0.909531 7.80071 1.0584C7.65522 1.0584 7.50973 1.20728 7.50973 1.35615L6.05486 5.22685C5.90937 5.37572 5.76388 5.52459 5.6184 5.67346L1.54475 5.97121C0.962804 5.97121 0.817316 6.71558 1.25378 7.01332L4.16352 9.69304C4.30901 9.84191 4.4545 10.1397 4.30901 10.2885L3.43609 14.0104C3.2906 14.6058 3.87255 15.2013 4.4545 14.7547L7.65522 12.6705C7.94619 12.5216 8.09168 12.5216 8.23717 12.6705Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
