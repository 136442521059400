import { Box, Button, TextField } from "@material-ui/core";
import fastDeepEqual from "fast-deep-equal";
import { memo, useEffect, useMemo, useState } from "react";
import { NonNull } from "../../utils";
import { GetEquitySettings } from "../../__generated__/graphql";
import { IllustrativeOutcomesMultiInput } from "./IllustrativeOutcomes/IllustrativeOutcomesMultiInput";

type OfferConfig = NonNull<GetEquitySettings["offerConfig"]>;
type Valuation = NonNull<GetEquitySettings["valuation"]>;

export type IllustrativeOutcomesData = {
  createdAt?: string;
  defaultOutcomeDescription?: string;
  outcomes: OutcomeWithAnnotation[];
};

export type OutcomeWithAnnotation = {
  key: number;
  value: number;
  annotation: string;
};

export type ChangeHandler = <Field extends keyof IllustrativeOutcomesData>(
  id: Field,
  value: IllustrativeOutcomesData[Field]
) => unknown;

export type IllustrativeOutcomeFormProps = {
  offerConfig: OfferConfig | null;
  valuation: Valuation | null;
  onSave: (
    initialFormData: IllustrativeOutcomesData,
    newFormData: IllustrativeOutcomesData
  ) => Promise<unknown>;
  isSaving: boolean;
};

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
export const IllustrativeOutcomesForm = memo(function EquityForm({
  offerConfig,
  valuation,
  onSave,
  isSaving,
}: IllustrativeOutcomeFormProps): JSX.Element {
  const initialData = useMemo<IllustrativeOutcomesData>(
    () => ({
      ...offerConfig,
      outcomes: initializeOutcomes(offerConfig),
    }),
    [offerConfig]
  );

  const [formData, setFormData] =
    useState<IllustrativeOutcomesData>(initialData);

  useEffect(() => setFormData(initialData), [initialData]);

  const handleChange: ChangeHandler = (id, value) => {
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  return (
    <Box display="flex" flexDirection="column">
      <IllustrativeOutcomesMultiInput
        outcomes={formData.outcomes}
        valuation={valuation}
        onChangeOutcomes={(outcomes) => handleChange("outcomes", outcomes)}
      />
      <Box m={2} />
      <TextField
        name="defaultOutcomeDescription"
        variant="outlined"
        fullWidth
        inputProps={{ maxLength: 500 }}
        label="Illustrative Outcomes Description (Optional)"
        placeholder="Help explain these outcomes to your candidates."
        helperText="Recruiters can still edit this message when creating offers."
        multiline
        minRows={8}
        InputLabelProps={{ shrink: true }}
        onChange={(e) =>
          handleChange("defaultOutcomeDescription", e.target.value)
        }
        value={formData.defaultOutcomeDescription ?? ""}
      />
      <Box m={2} />
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          disabled={isSaving || fastDeepEqual(initialData, formData)}
          onClick={() => onSave(initialData, formData)}
          variant="contained"
        >
          {isSaving ? "Saving..." : "Save"}
        </Button>
      </Box>
    </Box>
  );
});

function initializeOutcomes(
  offerConfig: OfferConfig | null
): OutcomeWithAnnotation[] {
  if (offerConfig === null) return [];
  const existingOutcomes = offerConfig.exitOutcomes.map((outcome, index) => ({
    key: Math.random(),
    value: outcome,
    annotation: offerConfig.xAxisAnnotations[index] ?? "",
  }));
  const placeholderOutcomes = generatePlaceholderOutcomes(
    existingOutcomes.length
  );
  return existingOutcomes.concat(placeholderOutcomes);
}

function generatePlaceholderOutcomes(
  existingOutcomesLength: number
): OutcomeWithAnnotation[] {
  const missingOutcomesLength = Math.max(3 - existingOutcomesLength, 0);
  return Array(missingOutcomesLength)
    .fill(undefined)
    .map(() => ({
      key: Math.random(),
      value: 0,
      annotation: "",
    }));
}
