import { cx } from "@emotion/css";
import { makeStyles } from "@material-ui/core";
import { ReactNode } from "react";
import { GRAY_5 } from "../../../theme";

const useStyles = makeStyles((theme) => ({
  compComponentContainer: {
    marginBottom: theme.spacing(2),
    "&:not(:last-of-type)": {
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid ${GRAY_5}`,
    },
  },
  dashed: {
    "&:not(:last-of-type)": {
      borderBottomStyle: "dashed",
    },
  },
}));

export function CompComponentContainer({
  children,
  dashed = false,
}: {
  children: ReactNode;
  dashed?: boolean;
}): JSX.Element {
  const classes = useStyles();
  return (
    <div
      className={cx(classes.compComponentContainer, dashed && classes.dashed)}
    >
      {children}
    </div>
  );
}
