import { gql } from "@apollo/client";
import { getFormattedDate } from "@asmbl/shared/time";
import { makeStyles, TableBody, TableRow, Tooltip } from "@material-ui/core";
import { useMemo } from "react";
import { ParticipantsTable_employee } from "src/__generated__/graphql";
import EmptyNoReportsSvg from "src/assets/svgs/illustrations/empty-state-no-reports.svg?react";
import { EmptyTableView } from "src/components/EmptyTableView";
import { DoubleSortableTableHeaderCell } from "src/components/SortableTable";
import { DepartmentAndLadderCell } from "src/components/Table/DepartmentAndLadderCell";
import { DualContentCell } from "src/components/Table/DualContentCell";
import { WireTable } from "src/components/Table/WireTable/WireTable";
import { WireTableCell } from "src/components/Table/WireTable/WireTableCell";
import { WireTableHead } from "src/components/Table/WireTable/WireTableHead";
import {
  WireTableHeaderCell,
  WireTableUnsortableHeaderCell,
} from "src/components/Table/WireTable/WireTableHeaderCell";
import { BadgedUserAvatar } from "src/pages/People/BadgedUserAvatar";
import { usePagination } from "../Plan/Contexts/PaginationContext";

const useStyles = makeStyles(() => ({
  table: {
    tableLayout: "fixed",
    marginBottom: "3rem",
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
}));

type Employee = ParticipantsTable_employee & { perfRating: string | null };

type TableRow = {
  name: string;
  email: string;
  photo?: string | null;
  position?: string;
  department?: string;
  ladder?: string;
  manager?: string | null;
  managerPhoto?: string | null;
  perfRating?: string;
  startDate?: Date;
  lastCompChange?: Date;
};

export function ParticipantsTable({
  employees,
}: {
  employees: Employee[];
}): JSX.Element {
  const { sortBy, sortDir, setSortParams } = usePagination();

  const classes = useStyles();
  const rows: TableRow[] = useMemo(
    () =>
      employees.map((e) => ({
        name: e.displayName,
        email: e.email,
        photo: e.user?.photoURL,
        position: e.activeEmployment?.position?.name,
        department: e.activeEmployment?.position?.ladder.department.name,
        ladder: e.activeEmployment?.position?.ladder.name,
        manager: e.manager?.displayName,
        managerPhoto: e.manager?.user?.photoURL,
        perfRating: e.perfRating ?? undefined,
        startDate: e.activeAt !== null ? new Date(e.activeAt) : undefined,
        lastCompChange:
          e.lastCompChangeDate != null
            ? new Date(e.lastCompChangeDate)
            : undefined,
      })),
    [employees]
  );

  return (
    <WireTable className={classes.table}>
      <WireTableHead>
        <TableRow>
          <DoubleSortableTableHeaderCell
            titleA="Name"
            titleB="Email"
            orderByFieldA="name-column"
            orderByFieldB="email-column"
            order={sortDir}
            isSelectedA={sortBy === "name-column"}
            isSelectedB={sortBy === "email-column"}
            handleRequestSort={setSortParams}
            aria-label="Name / Email"
            align="left"
            width="20%"
          />
          <WireTableHeaderCell
            cellTitle="Position"
            orderByField="current-position-column"
            order={sortDir}
            isSelected={sortBy === "current-position-column"}
            handleRequestSort={setSortParams}
          />
          <DoubleSortableTableHeaderCell
            titleA={
              <Tooltip title="Department / Ladder" placement="top">
                <span>Dept</span>
              </Tooltip>
            }
            titleB={
              <Tooltip title="Department / Ladder" placement="top">
                <span>Ladder</span>
              </Tooltip>
            }
            orderByFieldA="current-department-column"
            orderByFieldB="current-ladder-column"
            order={sortDir}
            isSelectedA={sortBy === "current-department-column"}
            isSelectedB={sortBy === "current-ladder-column"}
            handleRequestSort={setSortParams}
            aria-label="Department / Ladder"
            align="left"
          />
          <WireTableHeaderCell
            cellTitle="Mgr"
            orderByField="manager-column"
            order={sortDir}
            isSelected={sortBy === "manager-column"}
            handleRequestSort={setSortParams}
            width="8%"
          />
          {/* Prisma does not support ordering by 'has many' relations, except by an aggregate count */}
          <WireTableUnsortableHeaderCell cellTitle="Performance" />
          <WireTableHeaderCell
            cellTitle="Start Date"
            orderByField="start-date-column"
            order={sortDir}
            isSelected={sortBy === "start-date-column"}
            handleRequestSort={setSortParams}
          />
          <WireTableHeaderCell
            cellTitle="Last Comp Change"
            orderByField="last-salary-change-date-column"
            order={sortDir}
            isSelected={sortBy === "last-salary-change-date-column"}
            handleRequestSort={setSortParams}
          />
        </TableRow>
      </WireTableHead>
      {rows.length === 0 && (
        <TableBody>
          <TableRow>
            <WireTableCell colSpan={6} rowSpan={2}>
              <EmptyTableView
                message="No employees found"
                svg={<EmptyNoReportsSvg />}
              />
            </WireTableCell>
          </TableRow>
        </TableBody>
      )}
      <TableBody>
        {rows.map((e, i) => (
          <TableRow key={i}>
            <DualContentCell
              width="20%"
              string1={e.name}
              string2={e.email}
              prefix={
                <BadgedUserAvatar
                  displayName={e.name}
                  photoURL={e.photo ?? null}
                  showTooltip
                />
              }
            />
            <WireTableCell>{e.position ?? "-"}</WireTableCell>
            <DepartmentAndLadderCell
              department={e.department}
              ladder={e.ladder}
            />
            <WireTableCell width="8%">
              {e.manager == null ? (
                "-"
              ) : (
                <BadgedUserAvatar
                  displayName={e.manager}
                  photoURL={e.managerPhoto ?? null}
                  showTooltip
                />
              )}
            </WireTableCell>
            <WireTableCell>{e.perfRating ?? "-"}</WireTableCell>
            <WireTableCell>{getFormattedDate(e.startDate)}</WireTableCell>
            <WireTableCell>{getFormattedDate(e.lastCompChange)}</WireTableCell>
          </TableRow>
        ))}
      </TableBody>
    </WireTable>
  );
}

ParticipantsTable.fragments = {
  employee: gql`
    fragment ParticipantsTable_employee on Employee2 {
      id
      lastCompChangeDate
      displayName
      email
      activeAt
      user {
        id
        photoURL
      }
      manager: minimalManager {
        id
        displayName
        user {
          id
          photoURL
        }
      }
      activeEmployment {
        id
        activeAt
        position {
          id
          name
          ladder {
            id
            name
            department {
              id
              name
            }
          }
        }
      }
    }
  `,
};
