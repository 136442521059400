import { MarketDataSet } from "src/__generated__/graphql";
import { CartaGrayscaleLogo } from "src/components/AssembleIcons/Logo/CartaGrayscaleLogo";

import { CartaLogo } from "src/components/AssembleIcons/Logo/CartaLogo";
import { CulpepperGrayscaleLogo } from "src/components/AssembleIcons/Logo/CulpepperGrayscaleLogo";
import { CulpepperLogo } from "src/components/AssembleIcons/Logo/CulpepperLogo";
import { EconomicResearchInstituteGrayscaleLogo } from "src/components/AssembleIcons/Logo/EconomicResearchInstituteGrayscaleLogo";
import { EconomicResearchInstituteLogo } from "src/components/AssembleIcons/Logo/EconomicResearchInstituteLogo";
import { MercerGrayscaleLogo } from "src/components/AssembleIcons/Logo/MercerGrayscaleLogo";
import { MercerLogo } from "src/components/AssembleIcons/Logo/MercerLogo";
import { OtherGrayscaleLogo } from "src/components/AssembleIcons/Logo/OtherGrayscaleLogo";
import { OtherLogo } from "src/components/AssembleIcons/Logo/OtherLogo";
import { PaveGrayscaleLogo } from "src/components/AssembleIcons/Logo/PaveGrayscaleLogo";
import { PaveLogo } from "src/components/AssembleIcons/Logo/PaveLogo";
import { RadfordAonGrayscaleLogo } from "src/components/AssembleIcons/Logo/RadfordAonGrayscaleLogo";
import { RadfordAonLogo } from "src/components/AssembleIcons/Logo/RadfordAonLogo";
import { WillisTowersWatsonGrayscaleLogo } from "src/components/AssembleIcons/Logo/WillisTowersWatsonGrayscaleLogo";
import { WillisTowersWatsonLogo } from "src/components/AssembleIcons/Logo/WillisTowersWatsonLogo";

type Provider = MarketDataSet["provider"];

type Props = {
  provider: Provider;
  active?: boolean;
};

const providerLogoMapping = {
  Radford: <RadfordAonLogo />,
  "OptionImpact/Pave": <PaveLogo />,
  Carta: <CartaLogo />,
  "Economic Research Institute": <EconomicResearchInstituteLogo />,
  Mercer: <MercerLogo />,
  Culpepper: <CulpepperLogo />,
  "Willis Towers Watson": <WillisTowersWatsonLogo />,
};

const inactiveProviderLogoMapping = {
  Radford: <RadfordAonGrayscaleLogo />,
  "OptionImpact/Pave": <PaveGrayscaleLogo />,
  Carta: <CartaGrayscaleLogo />,
  "Economic Research Institute": <EconomicResearchInstituteGrayscaleLogo />,
  Mercer: <MercerGrayscaleLogo />,
  Culpepper: <CulpepperGrayscaleLogo />,
  "Willis Towers Watson": <WillisTowersWatsonGrayscaleLogo />,
};

export default function MarketDataIcon({ provider, active = true }: Props) {
  if (provider in providerLogoMapping) {
    return active
      ? providerLogoMapping[provider as keyof typeof providerLogoMapping]
      : inactiveProviderLogoMapping[
          provider as keyof typeof providerLogoMapping
        ];
  }

  return active ? <OtherLogo /> : <OtherGrayscaleLogo />;
}
