import { Badge, BoxProps, makeStyles, withStyles } from "@material-ui/core";
import { forwardRef } from "react";
import { ExplanatoryTooltip } from "../../components/ExplanatoryTooltip";
import { UserAvatar } from "../../components/UserAvatar";
import { SUCCESS_BG, WHITE } from "../../theme";

const useStyles = makeStyles(() => ({ root: { display: "inline-block" } }));

const StyledBadge = withStyles(() => ({
  badge: {
    background: SUCCESS_BG,
    color: WHITE,
    transform: "scale(0.4) translate(-50%, -50%) translate(24px, 0px)",
    position: "absolute",
    fontWeight: 900,
    fontSize: "14px",
    lineHeight: "1.4",
    boxShadow: `${WHITE} 0px 0px 0px 4px`,
  },
}))(Badge);

interface Props {
  photoURL: string | null;
  displayName: string | null;
  showBadge?: boolean;
  showTooltip?: boolean;
  grayscale?: boolean;
  backgroundColor?: string;
}

export const BadgedUserAvatar = forwardRef<HTMLDivElement, Props>(
  function BadgedUserAvatarInner(
    {
      photoURL,
      displayName,
      showBadge = false,
      showTooltip,
      grayscale = false,
      backgroundColor,
      ...rest
    }: Props & BoxProps,
    ref
  ): JSX.Element {
    const classes = useStyles();
    return (
      <div className={classes.root} {...rest} ref={ref}>
        <StyledBadge
          badgeContent=""
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          invisible={!showBadge}
        >
          <UserAvatar
            photoURL={photoURL}
            displayName={displayName}
            size="small"
            showTooltip={showTooltip}
            grayscale={grayscale}
            backgroundColor={backgroundColor}
          />
        </StyledBadge>
      </div>
    );
  }
);

interface NeedsLevelingAvatarProps extends Props {
  photoURL: string | null;
  displayName: string | null;
  needsLeveling: boolean;
  canUserLevel: boolean;
  backgroundColor?: string;
}

export function NeedsLevelingAvatar({
  photoURL,
  displayName,
  needsLeveling,
  canUserLevel,
  grayscale = false,
  backgroundColor,
}: NeedsLevelingAvatarProps): JSX.Element {
  return (
    <ExplanatoryTooltip
      title="This person needs leveling"
      body={
        canUserLevel ? (
          <>To level them, map their HRIS Job Title to an Assemble Position.</>
        ) : (
          <>
            To level them, have your compensation manager map their HRIS Job
            Title to an Assemble Position.
          </>
        )
      }
      width="250px"
      placement="top"
      disabled={!needsLeveling}
    >
      <div>
        <BadgedUserAvatar
          photoURL={photoURL}
          displayName={displayName}
          showBadge={needsLeveling}
          grayscale={grayscale}
          backgroundColor={backgroundColor}
        />
      </div>
    </ExplanatoryTooltip>
  );
}
