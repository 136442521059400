import { Divider, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { GRAY_2, GRAY_6, GRAY_8, PURPLE_1, WHITE } from "src/theme";
import { AssembleTypography } from "../AssembleTypography";

const useStyles = makeStyles((theme) => ({
  cardAddContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  cardButton: {
    color: GRAY_2,
    background: GRAY_8,
    width: "100%",

    padding: theme.spacing(2, 4),

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    border: `1px solid ${GRAY_6}`,
    borderRight: `0px solid ${GRAY_6}`,

    "&:hover": {
      cursor: "pointer",
      color: PURPLE_1,
      background: WHITE,
      border: `1px solid ${PURPLE_1}`,
    },
  },

  disabled: {
    background: GRAY_8,

    "&:hover": {
      cursor: "not-allowed",
      background: GRAY_8,
    },
  },
}));

type Props = {
  variant: "create" | "edit";
  handleClick: (phaseType: "review" | "calibration") => void;
};

export function ButtonBar({ variant, handleClick }: Props): JSX.Element {
  const classes = useStyles();

  const handleReviewClick = () => {
    handleClick("review");
  };

  const handleCalibrationClick = () => {
    handleClick("calibration");
  };

  return (
    <div className={classes.cardAddContent}>
      <div
        className={clsx(classes.cardButton, {
          [classes.disabled]: variant === "edit",
        })}
        onClick={variant === "edit" ? undefined : handleReviewClick}
      >
        <AssembleTypography textColor={PURPLE_1} variant="productButtonMedium">
          Add new review phase
        </AssembleTypography>
        <AssembleTypography
          noWrap
          textColor="inherit"
          variant="productButtonSmall"
        >
          Assign people managers to submit recommendations
        </AssembleTypography>
      </div>
      <Divider orientation="vertical" flexItem />
      <div
        className={clsx(classes.cardButton, {
          [classes.disabled]: variant === "edit",
        })}
        onClick={variant === "edit" ? undefined : handleCalibrationClick}
      >
        <AssembleTypography textColor={PURPLE_1} variant="productButtonMedium">
          Add new calibration phase
        </AssembleTypography>
        <AssembleTypography textColor="inherit" variant="productButtonSmall">
          Allow Full Access users to review cycle
        </AssembleTypography>
      </div>
    </div>
  );
}
