import { Box, makeStyles, Typography } from "@material-ui/core";
import { GRAY_2 } from "../../theme";

const useStyles = makeStyles(() => ({
  root: {
    width: "300px",
  },
  text: {
    color: GRAY_2,
  },
}));

export function OutcomeDescription({
  description,
}: {
  description: string;
}): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {description.split("\n").map((item, key) => {
        return (
          <Typography
            key={key}
            className={classes.text}
            variant="body2"
            paragraph
          >
            {item}
          </Typography>
        );
      })}
    </Box>
  );
}
