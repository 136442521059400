import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  RipplingImportButton,
  RipplingImportRecord,
} from "./RipplingImportButton";

const useStyles = makeStyles(() => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

interface Props {
  open: boolean;
  onSubmit: (data: RipplingImportRecord[]) => unknown;
  onClose?: () => unknown;
}

export function ManualHrisImportDialog({
  open,
  onSubmit,
  onClose,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} disableEnforceFocus>
      <DialogTitle>Add or Update HRIS Employee Data</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body2">
          Choose one of the sources below. This will add or update data for all
          of the employees in the CSV.
        </Typography>
        <Box m={2} />
        <RipplingImportButton handleChange={onSubmit} handleCancel={onClose} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
