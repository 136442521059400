import { Money } from "@asmbl/shared/money";
import {
  makeStyles,
  TableCell,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { currencySymbol } from "../../../models/Currency";
import { GRAY_4, GRAY_6, GRAY_8, PURPLE_2 } from "../../../theme";
import { useCurrencies } from "../../CurrenciesContext";
import { DogEarWithTooltip } from "./DogEarWithTooltip";

interface Props {
  onChange: (Bonus: Money) => unknown;
  bonus: Money | null;
  hasUnpublishedChanges?: boolean;
}

const useStyles = makeStyles(() => ({
  inputCell: {
    position: "relative",
    "&$inputCell": {
      padding: 0,
    },
  },
  inputContainer: { display: "flex", justifyContent: "flex-end" },
  bonusRoot: { flex: "1 0 0" },
  fillerRoot: { flex: "1 0 0", borderTop: `1px solid ${GRAY_6}` },
  inputComponent: {
    borderRadius: 0,
    border: `1px solid ${GRAY_6}`,
    borderBottom: "none",
    boxShadow: "none",
    transition: "none",
    height: "2.5rem",
    fontSize: "0.8125rem",
    width: "calc(100% + 1px)", // 1px to account for the border

    "&:hover": {
      background: GRAY_8,
      borderColor: GRAY_6,
      borderBottom: "none",
      transition: "none",
    },

    "&:focus-within, &.Mui-focused": {
      borderColor: "transparent",
      borderBottom: "none",
      boxShadow: "none",
      outline: `2px solid ${PURPLE_2}`,
      outlineOffset: "-2px",
      transition: "none",

      "& $symbolAdornment": {
        color: PURPLE_2,
      },
    },
  },
  input: {
    paddingBlock: 0,
    textAlign: "right",
  },
  symbolAdornment: {
    color: GRAY_4,
    fontSize: "0.875rem",
  },
}));

export function BonusInputCell({
  bonus,
  onChange,
  hasUnpublishedChanges = false,
}: Props): JSX.Element {
  const classes = useStyles();
  const { defaultCurrencyCode } = useCurrencies();
  const currency = bonus?.currency ?? defaultCurrencyCode;

  const [input, setInput] = useState(bonus?.value ?? 0);
  const error = input % 1 !== 0;

  const handleAbsolute = ({ floatValue = 0 }: NumberFormatValues) => {
    setInput(floatValue);
    if (floatValue !== bonus?.value) {
      onChange({
        value: floatValue,
        currency,
      });
    }
  };

  return (
    <TableCell role="gridcell" className={classes.inputCell} colSpan={3}>
      <div className={classes.inputContainer}>
        {hasUnpublishedChanges && <DogEarWithTooltip />}
        <NumberFormat
          customInput={TextField}
          variant="outlined"
          onValueChange={handleAbsolute}
          value={input === 0 ? null : input}
          placeholder="0"
          error={error}
          className={classes.bonusRoot}
          InputProps={{
            className: classes.inputComponent,
            startAdornment: (
              <Typography className={classes.symbolAdornment}>
                {currencySymbol(currency)}
              </Typography>
            ),
          }}
          inputProps={{
            className: classes.input,
            "data-cy": "bonus-input-cell",
          }}
          thousandSeparator
          isAllowed={(value) => !value.value.includes(".")}
        />
        <div className={classes.fillerRoot} />
      </div>
    </TableCell>
  );
}
