import { gql } from "@apollo/client";
import {
  PositionChange_employment,
  PositionChange_recItem,
  RecItemType,
} from "../../../__generated__/graphql";
import { AssembleTypography } from "../../AssembleTypography";
import { CompComponentContainer } from "./CompComponentContainer";
import { CompValue } from "./CompValue";
import { LabelValue } from "./LabelValue";

type Props = {
  employment?: PositionChange_employment | null;
  recItems: PositionChange_recItem[];
};

export function PositionChange({
  employment,
  recItems,
}: Props): JSX.Element | null {
  const activePosition = employment?.position;
  const recommendedPosition = recItems.find(
    (item) => item.recommendationType === RecItemType.PROMOTION
  )?.recommendedPosition;

  if (!recommendedPosition) {
    return null;
  }

  return (
    <CompComponentContainer>
      <AssembleTypography variant="productEyebrowSmall" gutterBottom>
        Position Change
      </AssembleTypography>
      <LabelValue
        label="Department"
        compValue={
          <CompValue
            previousValue={activePosition?.ladder.department.name}
            value={recommendedPosition.ladder.department.name}
          />
        }
      />
      <LabelValue
        label="Ladder"
        compValue={
          <CompValue
            previousValue={activePosition?.ladder.name}
            value={recommendedPosition.ladder.name}
          />
        }
      />
      <LabelValue
        label="Level"
        compValue={
          <CompValue
            previousValue={activePosition?.level}
            value={recommendedPosition.level}
          />
        }
      />
      <LabelValue
        label="Position"
        compValue={
          <CompValue
            previousValue={activePosition?.name}
            value={recommendedPosition.name}
          />
        }
      />
    </CompComponentContainer>
  );
}

const positionFragment = gql`
  fragment PositionChange_position on Position {
    name
    level
    ladder {
      id
      name
      department {
        id
        name
      }
    }
  }
`;

PositionChange.fragments = {
  position: positionFragment,
  employment: gql`
    ${positionFragment}
    fragment PositionChange_employment on Employment {
      position {
        id
        ...PositionChange_position
      }
    }
  `,
  recItem: gql`
    ${positionFragment}
    fragment PositionChange_recItem on RecItem {
      recommendationType
      recommendedPosition {
        id
        ...PositionChange_position
      }
    }
  `,
};
