import { useCallback } from "react";
import { AssembleTypography } from "src/components/AssembleTypography";
import { StyledBlockButton } from "../StyledBlockButton/StyledBlockButton";

const HEADER_5 = "header-five";

export type H5BlockProps = {
  editorState: Draft.EditorState;
  onClick: (headline: string) => void;
};

export const H5Block = (props: H5BlockProps): JSX.Element => {
  const { editorState, onClick } = props;

  const startKey = editorState.getSelection().getStartKey();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(startKey)
    .getType();
  const active = blockType === HEADER_5;

  const onClickHeadline = useCallback(() => {
    onClick(HEADER_5);
  }, [onClick]);

  return (
    <StyledBlockButton active={active} onClick={onClickHeadline}>
      <AssembleTypography style={{ fontSize: "22px", fontWeight: "bold" }}>
        H5
      </AssembleTypography>
    </StyledBlockButton>
  );
};
