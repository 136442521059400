import { gql } from "@apollo/client";
import { zero } from "@asmbl/shared/money";
import { IconButton, makeStyles, TableRow } from "@material-ui/core";
import clsx from "clsx";
import { CorrectionCostToMoveEmployeesTableRow_employeeCostToMove as CorrectionEmployeeCostToMove } from "src/__generated__/graphql";
import { CheckCircleSolidIcon } from "src/components/AssembleIcons/Brand/CheckCircleSolidIcon";
import { useCurrencies } from "src/components/CurrenciesContext";
import { WireTableCell } from "src/components/Table/WireTable";
import { UserAvatar } from "src/components/UserAvatar";
import { getSimpleCashLabel } from "src/models/Currency";
import { GRAY_6, PURPLE_1 } from "src/theme";
import { EmployeeRecommendationMap } from "../CorrectionCostToMoveInnerPage";

const useStyles = makeStyles((theme) => ({
  avatarCell: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  selected: {
    backgroundColor: GRAY_6,
  },
  row: {
    cursor: "pointer",
  },
}));

type Props = {
  employeeCTM: CorrectionEmployeeCostToMove;
  cashCompComponents: string[];
  selectedEmployees: EmployeeRecommendationMap;
  setSelectedEmployees: (id: number) => void;
};

export const CorrectionCostToMoveEmployeesTableRow = ({
  employeeCTM,
  cashCompComponents,
  selectedEmployees,
  setSelectedEmployees,
}: Props) => {
  const classes = useStyles();
  const { defaultCurrencyCode } = useCurrencies();

  return (
    <TableRow
      key={employeeCTM.employee.id}
      className={clsx(classes.row, {
        [classes.selected]:
          selectedEmployees.get(employeeCTM.employee.id)?.selected ?? false,
      })}
      onClick={() => setSelectedEmployees(employeeCTM.employee.id)}
    >
      <WireTableCell>
        <div className={classes.avatarCell}>
          <IconButton>
            {selectedEmployees.get(employeeCTM.employee.id)?.selected ? (
              <CheckCircleSolidIcon height="24" width="24" color={PURPLE_1} />
            ) : (
              <UserAvatar
                photoURL={employeeCTM.employee.photoURL}
                displayName={employeeCTM.employee.displayName}
                size="mini"
              />
            )}
          </IconButton>
          {employeeCTM.employee.displayName}
        </div>
      </WireTableCell>
      <WireTableCell>{employeeCTM.employee.email}</WireTableCell>
      {cashCompComponents.map((comp, index) => {
        const employeeCTMComp = employeeCTM.costToMoveByComp.find(
          (ctmComp) => ctmComp.compType === comp
        );
        return (
          <>
            <WireTableCell align="right" key={comp}>
              {getSimpleCashLabel(
                employeeCTMComp?.spend ?? zero(defaultCurrencyCode)
              )}
            </WireTableCell>
            <WireTableCell align="right" key={`${comp}${index}`}>
              {getSimpleCashLabel(
                employeeCTMComp?.costToMove ?? zero(defaultCurrencyCode)
              )}
            </WireTableCell>
          </>
        );
      })}
      <WireTableCell align="right">
        {getSimpleCashLabel(employeeCTM.totalSpend)}
      </WireTableCell>
      <WireTableCell align="right">
        {employeeCTM.totalTargetBandPoint != null
          ? getSimpleCashLabel(employeeCTM.totalTargetBandPoint)
          : "-"}
      </WireTableCell>
      <WireTableCell align="right">
        {employeeCTM.totalCostToMove != null
          ? getSimpleCashLabel(employeeCTM.totalCostToMove)
          : getSimpleCashLabel(zero(defaultCurrencyCode))}
      </WireTableCell>
      <WireTableCell align="right">
        {employeeCTM.TCCComparatio != null
          ? `${employeeCTM.TCCComparatio}%`
          : "-"}
      </WireTableCell>
    </TableRow>
  );
};

CorrectionCostToMoveEmployeesTableRow.fragments = {
  employeeCostToMove: gql`
    fragment CorrectionCostToMoveEmployeesTableRow_employeeCostToMove on TotalEmployeeCostToMove {
      employee {
        id
        displayName
        email
        photoURL
      }
      costToMoveByComp {
        compType
        costToMove
        spend
        convertedSpend
        convertedCostToMove
      }
      totalSpend
      totalTargetBandPoint
      totalCostToMove
      TCCComparatio
    }
  `,
};
