import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const DownloadExportIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Download Export"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 11.3333V12.8333C2 12.9659 2.05268 13.0931 2.14645 13.1869C2.24021 13.2807 2.36739 13.3333 2.5 13.3333H13.5C13.5657 13.3333 13.6307 13.3204 13.6913 13.2953C13.752 13.2701 13.8071 13.2333 13.8536 13.1869C13.9 13.1405 13.9368 13.0853 13.9619 13.0247C13.9871 12.964 14 12.899 14 12.8333V11.3333"
        className="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 2.66667V10"
        className="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.66675 7.66667L8.03183 10.0318L10.3969 7.66667"
        className="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
