import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const DragHandleIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Drag Handle"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke fill"
        d="M9.625 5.625C9.625 6.17728 9.17728 6.625 8.625 6.625C8.07272 6.625 7.625 6.17728 7.625 5.625C7.625 5.07272 8.07272 4.625 8.625 4.625C9.17728 4.625 9.625 5.07272 9.625 5.625Z"
      />
      <path
        className="stroke fill"
        d="M16 5.625C16 6.17728 15.5523 6.625 15 6.625C14.4477 6.625 14 6.17728 14 5.625C14 5.07272 14.4477 4.625 15 4.625C15.5523 4.625 16 5.07272 16 5.625Z"
      />
      <path
        className="stroke fill"
        d="M9.625 12C9.625 12.5523 9.17728 13 8.625 13C8.07272 13 7.625 12.5523 7.625 12C7.625 11.4477 8.07272 11 8.625 11C9.17728 11 9.625 11.4477 9.625 12Z"
      />
      <path
        className="fill"
        d="M15 13.5C15.8284 13.5 16.5 12.8284 16.5 12C16.5 11.1716 15.8284 10.5 15 10.5C14.1716 10.5 13.5 11.1716 13.5 12C13.5 12.8284 14.1716 13.5 15 13.5Z"
      />
      <path
        className="fill"
        d="M8.625 19.875C9.45343 19.875 10.125 19.2034 10.125 18.375C10.125 17.5466 9.45343 16.875 8.625 16.875C7.79657 16.875 7.125 17.5466 7.125 18.375C7.125 19.2034 7.79657 19.875 8.625 19.875Z"
      />
      <path
        className="fill"
        d="M15 19.875C15.8284 19.875 16.5 19.2034 16.5 18.375C16.5 17.5466 15.8284 16.875 15 16.875C14.1716 16.875 13.5 17.5466 13.5 18.375C13.5 19.2034 14.1716 19.875 15 19.875Z"
      />
    </svg>
  </ColorableSVG>
);
