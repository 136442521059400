import { CurrencyCode } from "./constants";
import { add, Money, zero } from "./money";

type BandPoint = { name: string; value: number };
type AdjustedBandPoint = {
  name: string;
  value: {
    hourlyRate: Money | null;
    annualRate: Money | null;
    currencyCode: CurrencyCode;
  };
};

/**
 * Given a list of CashBands and a currency, it will return a new list of
 * cash bands with every bandPoint `value` multiplied by the currency's
 * exchangeRate
 */
export function currencyAdjustBands<
  CashBand extends { bandPoints: BandPoint[] },
  Currency extends { exchangeRate: number },
>(bands: CashBand[], fromCurrency: Currency, toCurrency: Currency): CashBand[] {
  return bands.map((band) => ({
    ...band,
    bandPoints: band.bandPoints.map((bp) => ({
      ...bp,
      value: bp.value * (toCurrency.exchangeRate / fromCurrency.exchangeRate),
    })),
  }));
}

export function collapseAdjustedBands<
  CashBand extends { bandPoints: AdjustedBandPoint[] },
>(bands: CashBand[]): AdjustedBandPoint[] {
  // Check if there is a single band in the list. If there is, get the bandPoint
  // names so we can group all bandpoints by their names.
  if (bands.at(0) === undefined || bands[0].bandPoints[0].value === null)
    return [];
  const bandPointNames = bands[0].bandPoints.map(({ name }) => name);

  const currencyCode = bands[0].bandPoints[0].value.currencyCode;

  const collapsedBand = [];
  for (const name of bandPointNames) {
    let annualSum = zero(currencyCode);
    let hourlySum = zero(currencyCode);
    for (const { bandPoints } of bands) {
      const point = bandPoints.find((bp) => bp.name === name);
      annualSum = add(annualSum, point?.value.annualRate ?? zero(currencyCode));
      hourlySum = add(hourlySum, point?.value.hourlyRate ?? zero(currencyCode));
    }
    collapsedBand.push({
      name,
      value: { annualRate: annualSum, hourlyRate: hourlySum, currencyCode },
    });
  }

  return collapsedBand;
}

export function collapseBands<CashBand extends { bandPoints: BandPoint[] }>(
  bands: CashBand[]
): BandPoint[] {
  // Get the bandPoint names so we can group all bandpoints by their names.
  const bandPointNames = bands
    .find((band) => band.bandPoints.length > 0)
    ?.bandPoints.map(({ name }) => name);

  if (!bandPointNames) return [];

  const collapsedBand = [];
  for (const name of bandPointNames) {
    let sum = 0;
    for (const { bandPoints } of bands) {
      const point = bandPoints.find((bp) => bp.name === name);
      sum += point?.value ?? 0;
    }
    collapsedBand.push({ name, value: sum });
  }
  return collapsedBand;
}
