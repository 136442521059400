import { gql } from "@apollo/client";
import { exchangeFromTo } from "@asmbl/shared/currency";
import { add, formatCurrency, isZero, Money } from "@asmbl/shared/money";
import { mapMaybe } from "@asmbl/shared/utils";
import { makeStyles, Typography } from "@material-ui/core";
import { useMemo } from "react";
import { Cell, Legend, Pie, PieChart } from "recharts";
import {
  TotalCompPie_cashCompensation as CashCompensation,
  CashCompType,
  CompComponent,
  TotalCompPie_equityHoldings as EquityHoldings,
  TotalCompPie_employee,
} from "../../../__generated__/graphql";
import {
  calculateEquityFromVestEvents,
  employeeHasEquity,
  filterVestEventsForNext12Months,
} from "../../../models/EquityVestEvent";
import {
  DV_BLUE,
  DV_BLUE_2,
  DV_GREEN,
  DV_PINK,
  DV_YELLOW_GREEN,
  GRAY_1,
  GRAY_2,
  PURPLE_1,
} from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";
import { Card } from "../../Cards/Card";
import { CardHeader } from "../../Cards/CardHeader";
import { useCurrencies } from "../../CurrenciesContext";
import { useTotalEquitySlider } from "../../TotalEquitySlider/TotalEquitySliderContext";

type Props = {
  employee: TotalCompPie_employee;
  equityHoldings: EquityHoldings;
  cashCompensation: CashCompensation[];
  className?: string;
};

type ChartCompTypes = CashCompType | CompComponent.EQUITY | "benefits";

const colors: Partial<Record<ChartCompTypes, string>> = {
  [CashCompType.SALARY]: DV_BLUE,
  [CashCompType.RECURRING_BONUS]: DV_BLUE_2,
  [CashCompType.COMMISSION]: DV_GREEN,
  [CompComponent.EQUITY]: DV_YELLOW_GREEN,
  benefits: GRAY_1,
};

const labels: Partial<Record<ChartCompTypes, string>> = {
  [CashCompType.SALARY]: "Base Salary",
  [CashCompType.RECURRING_BONUS]: "Target Bonus",
  [CashCompType.COMMISSION]: "Target Commission",
  [CompComponent.EQUITY]: "Equity",
  benefits: "Est. Benefits Value",
};

const useStyles = makeStyles((theme) => ({
  content: {
    flex: "1 0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(3),
  },
  chartWrapper: {
    position: "relative",
  },
  centerText: {
    position: "absolute",
    top: 0,
    left: 0,
    height: 180,
    width: 180,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  labelColor: { color: GRAY_2 },
}));

type CompType = {
  type: ChartCompTypes;
  annualCashEquivalent: Money;
};

export function TotalCompPie({
  employee,
  equityHoldings,
  cashCompensation,
  className,
}: Props): JSX.Element {
  const classes = useStyles();
  const { currencies } = useCurrencies();
  const equitySliderData = useTotalEquitySlider();

  const sharePriceTarget = equitySliderData?.value;

  const data: CompType[] = useMemo(() => {
    let normalizedData: CompType[] = [...cashCompensation];
    const hasEquity = employeeHasEquity(equityHoldings);

    const filteredVestEvents = filterVestEventsForNext12Months(
      equityHoldings.vestingInformation?.vestEvents ?? []
    );

    const equityForNext12Months = calculateEquityFromVestEvents(
      currencies,
      sharePriceTarget,
      filteredVestEvents,
      equityHoldings.valuationCurrency
    ).value;

    // If some cash comps are in different currencies,
    // we convert everything to the same cash comp currency
    // otherwise we use the valuation currency (which is the same as the org default)
    const toCurrency =
      (cashCompensation.length > 0 &&
        currencies.get(cashCompensation[0].annualCashEquivalent.currency)) ||
      equityHoldings.valuationCurrency;

    if (
      cashCompensation.some(
        (c) =>
          c.annualCashEquivalent.currency !==
          cashCompensation[0].annualCashEquivalent.currency
      )
    ) {
      normalizedData = mapMaybe(cashCompensation, (c) => {
        const currency = currencies.get(c.annualCashEquivalent.currency);
        return (
          currency && {
            ...c,
            annualCashEquivalent: exchangeFromTo(
              c.annualCashEquivalent,
              currency,
              toCurrency
            ),
          }
        );
      });
    }

    if (hasEquity && equityForNext12Months !== null) {
      normalizedData.push({
        type: CompComponent.EQUITY,
        annualCashEquivalent: exchangeFromTo(
          equityForNext12Months,
          equityHoldings.valuationCurrency,
          toCurrency
        ),
      });
    }

    const benefitsValue = employee.totalBenefitsValue;
    if (benefitsValue && !isZero(benefitsValue)) {
      normalizedData.push({
        type: "benefits",
        annualCashEquivalent: benefitsValue,
      });
    }

    return normalizedData;
  }, [
    cashCompensation,
    equityHoldings,
    currencies,
    sharePriceTarget,
    employee.totalBenefitsValue,
  ]);

  return (
    <Card className={className}>
      <CardHeader header="Distribution by Type" />
      <div className={classes.content}>
        <div className={classes.chartWrapper}>
          <PieChart width={380} height={180}>
            <Pie
              data={data}
              nameKey="type"
              dataKey={(d: CompType) => d.annualCashEquivalent.value}
              cx={90}
              cy="50%"
              outerRadius={90}
              innerRadius={65}
              endAngle={-360}
              fill={PURPLE_1}
              paddingAngle={0.8}
            >
              {data.map((entry) => (
                <Cell
                  key={`cell-${entry.type}`}
                  fill={colors[entry.type] ?? DV_PINK}
                />
              ))}
            </Pie>
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              width={150}
              iconSize={10}
              iconType="square"
              formatter={(value: CashCompType | CompComponent.EQUITY) => (
                <AssembleTypography
                  data-cy={`total-comp-pie-legend-label-${value}`}
                  variant="productSmall"
                  display="inline"
                  className={classes.labelColor}
                >
                  {labels[value]}
                </AssembleTypography>
              )}
            />
          </PieChart>
          <div className={classes.centerText}>
            <Typography
              data-cy="chart-center-value"
              component="div"
              variant="h2"
            >
              {data.length === 0
                ? "-"
                : formatCurrency(
                    data.map((d) => d.annualCashEquivalent).reduce(add),
                    {
                      notation: "compact",
                      minimumSignificantDigits: 2,
                      maximumSignificantDigits: 4,
                    }
                  )}
            </Typography>
            <AssembleTypography
              variant="productSmall"
              className={classes.labelColor}
            >
              Total Comp
            </AssembleTypography>
          </div>
        </div>
      </div>
    </Card>
  );
}

TotalCompPie.fragments = {
  employee: gql`
    fragment TotalCompPie_employee on Employee {
      totalBenefitsValue
    }
  `,
  cashCompensation: gql`
    fragment TotalCompPie_cashCompensation on CashCompensation {
      type
      annualCashEquivalent
    }
  `,
  equityHoldings: gql`
    fragment TotalCompPie_equityHoldings on EquityHoldings {
      grants {
        id
      }
      valuationCurrency {
        id
        code
        exchangeRate
      }
      vestingInformation {
        id
        vestEvents {
          id
          unitCount
          vestingDate
          grant {
            id
            sharePrice
          }
        }
      }
    }
  `,
};
