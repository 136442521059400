import { Currency } from "@asmbl/shared/currency";
import { formatCurrency } from "@asmbl/shared/money";
import { makeStyles } from "@material-ui/core";
import { ApexOptions } from "apexcharts";
import fastDeepEqual from "fast-deep-equal";
import { memo, useEffect, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { APP_FONTS, GRAY_3, GRAY_4 } from "../../theme";

const useStyles = makeStyles(() => ({
  container: {
    overflow: "hidden", // Fixes height issue with ApexChart in scrolling table
  },
}));

interface BandVisualizationHeaderProps {
  totalCompRange: { min: number; max: number };
  height?: number;
  color?: string;
  selectedCurrency: Currency;
}

export default function BandVisualizationHeader({
  totalCompRange,
  height,
  selectedCurrency,
}: BandVisualizationHeaderProps): JSX.Element {
  const chartOptions = useMemo<ApexOptions>(
    () => ({
      chart: {
        toolbar: { show: false },
        parentHeightOffset: 0,
        fontFamily: APP_FONTS,
        redrawOnWindowResize: true,
        redrawOnParentResize: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      xaxis: {
        labels: {
          offsetX: 18,
          offsetY: 2,
          formatter: function (value: string) {
            return formatCurrency(
              { value: Number(value), currency: selectedCurrency.code },
              {
                notation: "compact",
                minimumSignificantDigits: 1,
                maximumSignificantDigits: 3,
              }
            );
          },
          style: {
            colors: GRAY_3,
            fontSize: "10px",
            fontWeight: 700,
          },
        },
        min: totalCompRange.min,
        max: totalCompRange.max,
        position: "top",
        axisBorder: { show: false },
        axisTicks: { show: false },
        tickAmount: 6,
        floating: true,
      },
      yaxis: { show: false },
      grid: {
        show: true,
        strokeDashArray: 1,
        xaxis: {
          lines: { show: true },
        },
        yaxis: {
          lines: { show: false },
        },
        borderColor: GRAY_4,
        padding: {
          top: -24,
          right: -13,
          bottom: -27,
          left: -16,
        },
      },
    }),
    [totalCompRange, selectedCurrency]
  );

  return <MemoChart options={chartOptions} series={[]} height={height} />;
}

type ChartProps = {
  options: ApexOptions;
  series: ApexAxisChartSeries;
  height?: number;
};

const MemoChart = memo(function MemoChart({
  options,
  series,
  height = 14,
}: ChartProps) {
  const classes = useStyles();
  // Apex Charts doesn't render this correctly on first load or on refresh. It
  // corrects itself when you resize the page, so we can force this to happen
  // on load via this useEffect with no dependencies.
  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, []);

  return (
    <div className={classes.container}>
      <ReactApexChart
        options={options}
        series={series}
        type="rangeBar"
        height={height}
      />
    </div>
  );
}, chartPropsAreEqual);

function chartPropsAreEqual(
  prevProps: ChartProps,
  curProps: ChartProps
): boolean {
  return (
    fastDeepEqual(prevProps.series, curProps.series) &&
    fastDeepEqual(prevProps.options, curProps.options)
  );
}
