import { gql } from "@apollo/client";
import { PrimaryRoleName } from "@asmbl/shared/permissions";
import { mapMaybe } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  AutomatedManagerAccess_report as AutomatedManagerReport,
  UserAccessControl_organization as Organization,
  ReportsSection_reports as Report,
} from "../../../__generated__/graphql";
import { UserPermissions } from "../../../models/UserPermissions";
import {
  NOUN_SCOPES_FIELDS,
  useClearCompensationUserAccessGrant,
  useUpdateUserIsDisabled,
  useUpdateUserPrimaryAccessGrants,
} from "../../../mutations/User";
import {
  useClearCompensationUserInvitationAccessGrant,
  useUpdateUserInvitationAccessGrant,
} from "../../../mutations/UserInvitation";
import { useAuth } from "../../Auth/AuthContext";
import { UserInviteBanner } from "../AccessControl/UserInvite/UserInviteBanner";
import { UserInviteCancellationModal } from "../AccessControl/UserInvite/UserInviteCancellationModal";
import { AutomatedManagerAccess } from "./AutomatedManagerAccess";
import { CompensationBandSection } from "./CompensationBandSection";
import { PrimaryRoleForm } from "./PrimaryRoleForm";
import { ReportsSection } from "./ReportsSection";
import { SupportedManagersSectionLoadingBoundary } from "./SupportedManagers/SupportedManagersSectionLoadingBoundary";
import { ExistingUser, PendingUser } from "./UserAccessControlUtils";
import { UserProfilePane } from "./UserProfilePane";

const useStyles = makeStyles(() => ({
  container: { display: "flex", flex: 1 },
  userPane: { width: "250px" },
  main: { flexGrow: 1, flexBasis: 0 },
}));

type Props =
  | {
      type: "user-invite";
      targetId: number;
      organization: Organization;
      user: PendingUser;
      refreshUser: () => Promise<unknown>;
    }
  | {
      type: "user";
      targetId: number;
      organization: Organization;
      user: ExistingUser;
      refreshUser: () => Promise<unknown>;
    };

export function UserAccessControl({
  type,
  targetId,
  organization,
  user,
  refreshUser,
}: Props): JSX.Element | null {
  const auth = useAuth();
  const classes = useStyles();
  const location = useLocation();

  const fromPreviousState = location.state as {
    isUserInviteBannerOpen?: boolean;
  } | null;

  const [isUserInviteBannerOpen] = useState(
    fromPreviousState?.isUserInviteBannerOpen ?? false
  );

  const [
    isUserInviteCancellationBannerOpen,
    setIsUserInviteCancellationBannerOpen,
  ] = useState(false);
  const toggleUserInviteCancellationBanner = () =>
    setIsUserInviteCancellationBannerOpen(!isUserInviteCancellationBannerOpen);

  const updateUserPrimaryAccessGrants =
    useUpdateUserPrimaryAccessGrants(targetId);
  const updateUserInvitationPrimaryAccessGrants =
    useUpdateUserInvitationAccessGrant(targetId);

  const updatePrimaryAccessGrants =
    type === "user"
      ? updateUserPrimaryAccessGrants
      : updateUserInvitationPrimaryAccessGrants;

  const clearCompensationUserAccessGrant =
    useClearCompensationUserAccessGrant(targetId);

  const clearCompensationUserInvitationAccessGrant =
    useClearCompensationUserInvitationAccessGrant(targetId);

  const clearCompensationAccessGrant =
    type === "user"
      ? clearCompensationUserAccessGrant
      : clearCompensationUserInvitationAccessGrant;

  const updateUserIsDisabled = useUpdateUserIsDisabled();

  const updatePrimaryGrant = useCallback(
    async (roleName: PrimaryRoleName) => {
      await updatePrimaryAccessGrants(roleName);

      if (roleName === PrimaryRoleName.SYSTEM_ADMIN) {
        await clearCompensationAccessGrant();
      }

      if (user.email === auth.user?.email) {
        // Reload app after changing your own permissions.
        window.location.reload();
      }
    },
    [
      updatePrimaryAccessGrants,
      user.email,
      auth.user?.email,
      clearCompensationAccessGrant,
    ]
  );

  const isUserInvite = type === "user-invite";

  const setUserIsDisabled = (isDisabled: boolean) => {
    isUserInvite
      ? toggleUserInviteCancellationBanner()
      : void updateUserIsDisabled(targetId, isDisabled);
  };

  const roleName =
    type === "user"
      ? user.userAccessGrant?.roleName
      : user.userInvitationAccessGrant?.roleName;

  const nounScopes =
    type === "user"
      ? user.userAccessGrant?.nounScopes
      : user.userInvitationAccessGrant?.nounScopes;

  const userPermissions = new UserPermissions(
    roleName ?? null,
    nounScopes ?? null,
    false
  );

  const isDisabled =
    (type === "user" && user.isDisabled) ||
    (type === "user-invite" && user.deletedAt !== null);

  const automatedManagerReports = (user.employee?.reports ??
    []) as unknown as AutomatedManagerReport[];

  return (
    <div className={classes.container}>
      {isUserInviteCancellationBannerOpen && (
        <UserInviteCancellationModal
          toggleUserInviteCancellationModal={toggleUserInviteCancellationBanner}
          email={user.email}
        />
      )}

      {isUserInviteBannerOpen && <UserInviteBanner userInvitationId={null} />}

      <div className={classes.userPane}>
        <UserProfilePane user={user} />
      </div>
      <div className={classes.main}>
        <PrimaryRoleForm
          userName={user.name}
          primaryRoleName={userPermissions.roleName}
          onRoleChange={updatePrimaryGrant}
          onSetIsDisabled={setUserIsDisabled}
          isDisabled={isDisabled}
          isSelf={user.email === auth.user?.email}
          isUserInvite={isUserInvite}
        />
        <SupportedManagersSectionLoadingBoundary
          userPermissions={userPermissions}
          userId={user.id}
          userName={user.name ?? ""}
          supportedManagerIds={mapMaybe(
            userPermissions.nounScopes?.Employee.view
              ?.supportingManagerEmployeeIDs ?? [],
            (id) => id
          )}
          refreshUser={refreshUser}
          isUserInvite={isUserInvite}
          updateUserInvitationPrimaryAccessGrants={
            updateUserInvitationPrimaryAccessGrants
          }
        />
        <CompensationBandSection
          key={userPermissions.roleName}
          targetId={targetId}
          userName={user.name}
          userPermissions={userPermissions}
          isDisabled={isDisabled}
          type={type}
          reports={automatedManagerReports}
          organization={organization}
        />
        <ReportsSection
          userName={user.name}
          userPermissions={userPermissions}
          reports={user.employee?.reports as Report[] | undefined}
          organization={organization}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
}

UserAccessControl.fragments = {
  user: gql`
    ${NOUN_SCOPES_FIELDS}
    ${ReportsSection.fragments.reports}
    ${AutomatedManagerAccess.fragments.report}
    fragment UserAccessControl_user on User {
      id
      name
      email
      photoURL
      isDisabled
      createdAt
      userAccessGrant {
        id
        roleName
        nounScopes {
          ...NounScopesFields
        }
      }
      employee {
        id
        isManager
        reports {
          id
          ...ReportsSection_reports
          ...AutomatedManagerAccess_report
        }
      }
    }
  `,
  userInvitation: gql`
    ${NOUN_SCOPES_FIELDS}
    ${AutomatedManagerAccess.fragments.report}
    ${ReportsSection.fragments.reports}
    fragment UserAccessControl_userInvitation on UserInvitation {
      id
      name
      email
      createdAt
      deletedAt
      userInvitationAccessGrant {
        id
        roleName
        nounScopes {
          ...NounScopesFields
        }
      }
      employee {
        id
        isManager
        reports {
          id
          ...ReportsSection_reports
          ...AutomatedManagerAccess_report
        }
      }
    }
  `,
  organization: gql`
    ${ReportsSection.fragments.organization}
    ${CompensationBandSection.fragments.organization}
    fragment UserAccessControl_organization on Organization {
      id
      ...ReportsSection_organization
      ...CompensationBandSection_organization
    }
  `,
};
