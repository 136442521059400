import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const EconomicResearchInstituteGrayscaleLogo = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width} height={height} rx="2" fill="#BDD3E5" />
      <rect
        width="13.44"
        height="13.44"
        transform="translate(1.27979 1.28003)"
        fill="#BDD3E5"
      />
      <path
        d="M8.03547 3.1751L4.90723 6.30334L8.03547 9.43159L11.1637 6.30334L8.03547 3.1751Z"
        fill="white"
      />
      <path
        d="M11.4031 6.54016L8.2749 9.6684L11.4031 12.7966L14.5314 9.6684L11.4031 6.54016Z"
        fill="white"
      />
      <path
        d="M4.6673 6.51391L1.53906 9.64215L4.6673 12.7704L7.79554 9.64215L4.6673 6.51391Z"
        fill="white"
      />
      <path
        d="M11.4028 11.4611C12.3906 11.4611 13.1914 10.6603 13.1914 9.67253C13.1914 8.68473 12.3906 7.88397 11.4028 7.88397C10.415 7.88397 9.61426 8.68473 9.61426 9.67253C9.61426 10.6603 10.415 11.4611 11.4028 11.4611Z"
        fill="#BDD3E5"
      />
      <path
        d="M3.93224 8.87136C3.93224 8.42853 3.89347 8.41388 3.69273 8.38545L3.62467 8.3777C3.59537 8.3484 3.59796 8.27345 3.63414 8.25708C3.79611 8.26397 3.99771 8.26914 4.18639 8.26914H5.0014C5.27537 8.26914 5.51833 8.26397 5.57777 8.25708C5.59414 8.32686 5.62085 8.65856 5.64497 8.84034C5.6286 8.87394 5.54331 8.88083 5.50971 8.85671C5.44165 8.61462 5.37704 8.5121 5.24091 8.47677C5.12825 8.44874 5.01237 8.4357 4.8963 8.438H4.61199C4.42848 8.438 4.42848 8.44576 4.42848 8.68526V9.39173C4.42848 9.50114 4.42848 9.50545 4.52928 9.50545H4.73519C5.10824 9.50545 5.1806 9.50545 5.2099 9.32194L5.23919 9.17893C5.27279 9.14963 5.35464 9.14963 5.37445 9.1841C5.36928 9.29782 5.36239 9.44773 5.36239 9.60194C5.36239 9.75616 5.37187 9.90693 5.37445 10.043C5.35428 10.056 5.33081 10.0629 5.30682 10.0629C5.28283 10.0629 5.25936 10.056 5.23919 10.043L5.2099 9.87677C5.1806 9.6993 5.10824 9.6924 4.73519 9.6924H4.52928C4.4302 9.6924 4.42848 9.6993 4.42848 9.80613V10.3429C4.42848 10.5557 4.43796 10.7082 4.50085 10.7788C4.55168 10.8365 4.62405 10.8701 4.98245 10.8701C5.52436 10.8779 5.61913 10.7564 5.76904 10.4178C5.79045 10.404 5.81631 10.3988 5.84141 10.4033C5.86651 10.4077 5.88898 10.4216 5.9043 10.4419C5.86886 10.6508 5.80871 10.8547 5.7251 11.0493C5.53642 11.0424 5.17113 11.0373 4.66971 11.0373H4.1838C3.99513 11.0373 3.7358 11.0424 3.54196 11.0493C3.50836 11.033 3.50405 10.9571 3.53248 10.9287L3.69359 10.9115C3.89433 10.8899 3.9331 10.8779 3.9331 10.435V8.87136H3.93224Z"
        fill="#BDD3E5"
      />
      <path
        d="M7.10221 5.57074C7.10221 5.16926 7.10221 5.11843 6.89717 5.09603L6.78517 5.08397C6.77132 5.06745 6.76417 5.04634 6.76513 5.02481C6.76609 5.00328 6.77508 4.98289 6.79033 4.96766C7.05913 4.94354 7.42184 4.92889 7.77249 4.92889C8.10676 4.92889 8.3971 4.97025 8.5832 5.07191C8.6985 5.12654 8.79541 5.21355 8.86211 5.32232C8.92881 5.43109 8.96242 5.55692 8.95883 5.68446C8.95883 6.06871 8.65643 6.26256 8.4419 6.34526C8.4152 6.35732 8.42553 6.39092 8.4419 6.41505C8.78652 6.98108 8.99673 7.33689 9.19747 7.49972C9.2802 7.572 9.38297 7.61737 9.49212 7.62982C9.50224 7.64151 9.50863 7.65596 9.51047 7.67131C9.51231 7.68666 9.50952 7.70222 9.50246 7.71597C9.41123 7.7348 9.31816 7.74319 9.22504 7.74096C8.81581 7.74096 8.59353 7.57812 8.33163 7.18871C8.21532 7.0164 8.09729 6.78206 7.99563 6.62009C7.91809 6.49431 7.87243 6.47708 7.732 6.47708C7.59157 6.47708 7.59846 6.48225 7.59846 6.56926V7.10428C7.59846 7.54711 7.63723 7.56176 7.83796 7.58588L7.93273 7.59794C7.96203 7.62723 7.95944 7.70219 7.92326 7.71856C7.73458 7.71166 7.54332 7.70649 7.35464 7.70649C7.16596 7.70649 6.9747 7.71166 6.77827 7.71856C6.76116 7.70373 6.75057 7.68276 6.7488 7.66019C6.74703 7.63762 6.75421 7.61526 6.7688 7.59794L6.8627 7.58588C7.06344 7.56176 7.10221 7.54711 7.10221 7.10428V5.57074ZM7.59846 6.17382C7.59846 6.30736 7.59846 6.31683 7.80437 6.31683C8.22307 6.31683 8.41433 6.11609 8.41433 5.71892C8.41433 5.38723 8.19637 5.09948 7.82332 5.09948C7.60104 5.09948 7.59587 5.11154 7.59587 5.30539V6.17382H7.59846Z"
        fill="#BDD3E5"
      />
      <path
        d="M11.6301 10.4927C11.6301 10.827 11.649 10.8778 11.8162 10.8994L11.9023 10.914C11.9359 10.945 11.9316 11.0277 11.8929 11.0449C11.7111 11.0346 11.5422 11.0303 11.3604 11.0303C11.1985 11.0303 11.0287 11.0303 10.8435 11.0449C10.8047 11.0277 10.7996 10.945 10.834 10.914L10.9202 10.8994C11.0873 10.8778 11.1071 10.827 11.1071 10.4927V9.91892C11.1071 9.71646 11.1071 9.66046 10.9839 9.59498L10.9228 9.56397C10.9162 9.54785 10.9135 9.53043 10.9148 9.51308C10.9162 9.49573 10.9216 9.47894 10.9305 9.46403C11.1528 9.40701 11.3678 9.32492 11.5715 9.21935C11.5988 9.22206 11.624 9.23499 11.6422 9.25553C11.6318 9.40544 11.6275 9.62083 11.6275 9.80778V10.497L11.6301 10.4927ZM11.0494 8.68347C11.0467 8.62366 11.0619 8.5644 11.0931 8.51332C11.1243 8.46223 11.1701 8.42167 11.2246 8.39685C11.2791 8.37203 11.3398 8.36409 11.3988 8.37405C11.4578 8.38402 11.5126 8.41143 11.5559 8.45276C11.5992 8.49409 11.6291 8.54744 11.6419 8.60594C11.6546 8.66444 11.6495 8.72542 11.6273 8.78101C11.6051 8.8366 11.5667 8.88426 11.5171 8.91786C11.4676 8.95145 11.4091 8.96944 11.3492 8.9695C11.3101 8.97204 11.2708 8.96637 11.234 8.95286C11.1972 8.93935 11.1636 8.91829 11.1353 8.89104C11.1071 8.86379 11.0849 8.83094 11.0701 8.79461C11.0553 8.75827 11.0483 8.71924 11.0494 8.68003V8.68347Z"
        fill="white"
      />
    </svg>
  </ColorableSVG>
);
