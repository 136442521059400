import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { formatEmpData } from "../../../../../models/Employee";
import { useInviteUsersToEmployeePortal } from "../../../../../mutations/User";
import { TableInviteButton_employee } from "../../../../../__generated__/graphql";
import { PortalDialog } from "../../PortalAccess/PortalDialog";
import { BaseButton } from "./BaseButton";

type Props = {
  employee: TableInviteButton_employee;
};

const useStyles = makeStyles(() => ({
  button: {
    boxShadow: "0px 1px 3px rgba(65, 54, 241, 0.5) !important",
    border: "none",
  },
}));

export const TableInviteButton = ({ employee }: Props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const inviteUsersToEmployeePortal = useInviteUsersToEmployeePortal();

  const handleInviteClick = async () => {
    const data = await inviteUsersToEmployeePortal([formatEmpData(employee)]);
    // error occurred
    if (typeof data === "string") {
      enqueueSnackbar(data, { variant: "error" });
    }
  };

  return (
    <PortalDialog
      onDialogClick={handleInviteClick}
      actionButtonTitle="Invite"
      title={`Invite ${employee.displayName} to Assemble`}
      body="We'll send them an email to sign up for Assemble and see their Employee Portal."
    >
      {(onClick) => (
        <BaseButton
          onClick={onClick}
          props={{
            variant: "contained",
            color: "primary",
          }}
          additionalClassName={classes.button}
          label="Invite"
        />
      )}
    </PortalDialog>
  );
};

TableInviteButton.fragments = {
  employee: gql`
    fragment TableInviteButton_employee on Employee {
      id
      displayName
      email
    }
  `,
};
