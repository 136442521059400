import { gql, useQuery } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import {
  PromotionPositionQuery,
  PromotionPositionQueryVariables,
} from "../../../__generated__/graphql";
import { CashRows } from "./CashRows";
import { TargetVariablePayRows } from "./TargetVariablePayRows";

type Position = PromotionPositionQuery["promotedPosition"];

type Props = {
  promotedPositionId: number | null;
  currencyCode: CurrencyCode;
  marketId: number | null;
  locationGroupId?: number;
  children: (promotedPosition: Position | null) => JSX.Element;
};

export function PositionLoadingBoundary({
  promotedPositionId,
  currencyCode,
  marketId,
  locationGroupId,
  children,
}: Props): JSX.Element {
  const { data } = useQuery<
    PromotionPositionQuery,
    PromotionPositionQueryVariables
  >(PositionLoadingBoundary.query, {
    variables: {
      promotedPositionId: promotedPositionId ?? -1,
      currencyCode,
      marketId: marketId ?? -1,
      locationGroupId,
    },
    skip: promotedPositionId == null || marketId == null,
  });

  return children(
    promotedPositionId != null && marketId != null && data
      ? data.promotedPosition
      : null
  );
}

PositionLoadingBoundary.query = gql`
  ${CashRows.fragments.promotedPosition}
  ${TargetVariablePayRows.fragments.position}
  query PromotionPositionQuery(
    $promotedPositionId: Int!
    $currencyCode: CurrencyCode!
    $marketId: Int!
    $locationGroupId: Int
  ) {
    promotedPosition: position(id: $promotedPositionId) {
      id
      ...CashRows_promotedPosition
      ...TargetVariablePayRows_position
    }
  }
`;
