import { gql } from "@apollo/client";
import { Button, makeStyles, Theme } from "@material-ui/core";
import { useState } from "react";
import {
  UploadEquityBanner_employee as Employee,
  UploadEquityBanner_organization as Organization,
} from "../../../../__generated__/graphql";
import { useTrack } from "../../../../analytics";
import { AssembleTypography } from "../../../../components/AssembleTypography";
import { InfoBanner } from "../../../../components/InfoBanner";
import { EmployeeEquityGrantRow } from "../../../../components/Settings/Equity/EquityImportButton";
import { EquityGrantVestingEventRow } from "../../../../components/Settings/Equity/EquityVestingImportButton";
import { EquityVestingImportDialog } from "../../../../components/Settings/Equity/EquityVestingImportDialog";
import {
  useImportEmployeeEquityGrants,
  useUpsertEquityGrantVestingEvents,
} from "../../../../mutations/EquityGrants";
import { GRAY_5, WHITE } from "../../../../theme";

const useStyles = makeStyles((theme: Theme) => ({
  bannerButton: {
    borderRadius: "4px",
    boxShadow: "0px 1px 3px rgba(10, 36, 64, 0.1)",
    border: `1px solid ${GRAY_5}`,
    backgroundColor: WHITE,
    marginTop: theme.spacing(1.5),
    maxWidth: "100%",
    "&:hover": {
      backgroundColor: WHITE,
    },
  },
}));

type Props = {
  organization: Organization;
  employees: Employee[];
};

export function UploadEquityBanner({
  organization,
  employees,
}: Props): JSX.Element | null {
  const classes = useStyles();
  const { trackEvent } = useTrack();

  const [isEquityVestingDialogOpen, setIsEquityVestingDialogOpen] =
    useState(false);

  const importEmployeeEquityGrants = useImportEmployeeEquityGrants();
  const upsertEquityVestingEvents = useUpsertEquityGrantVestingEvents();

  const { hasAnyEquityVestEvents } = organization;

  const handleImportEquityButtonClick = () => {
    trackEvent({
      area: "Employee Portal",
      subArea: "Portal Access",
      object: "Import Equity Button",
      action: "Clicked",
      uploadType: "Import",
    });
    setIsEquityVestingDialogOpen(true);
  };

  const handleSubmitEquityGrants = (data: EmployeeEquityGrantRow[]) => {
    trackEvent({
      area: "Employee Portal",
      subArea: "Portal Access",
      object: "Import Employee Equity Grants Button",
      action: "Clicked",
      uploadType: "Import",
    });
    void importEmployeeEquityGrants(data, employees);
  };

  const handleSubmitEquityVestingEvents = (
    data: EquityGrantVestingEventRow[]
  ) => {
    trackEvent({
      area: "Employee Portal",
      subArea: "Portal Access",
      object: "Import Employee Equity Vesting Events Button",
      action: "Clicked",
    });

    void upsertEquityVestingEvents(data, employees);
  };

  return !hasAnyEquityVestEvents ? (
    <>
      <EquityVestingImportDialog
        employees={employees}
        open={isEquityVestingDialogOpen}
        onClose={() => setIsEquityVestingDialogOpen(false)}
        onVestingSubmit={handleSubmitEquityVestingEvents}
        onGrantSubmit={handleSubmitEquityGrants}
        orgData={organization}
      />
      <InfoBanner>
        <AssembleTypography variant="productParagraphLarge">
          Help your employees better visualize the value of their equity.
        </AssembleTypography>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleImportEquityButtonClick}
          className={classes.bannerButton}
        >
          Import Equity
        </Button>
      </InfoBanner>
    </>
  ) : null;
}

UploadEquityBanner.fragments = {
  employee: gql`
    ${EquityVestingImportDialog.fragments.employee}
    fragment UploadEquityBanner_employee on Employee {
      id
      ...EquityVestingImportDialog_employee
    }
  `,
  organization: gql`
    ${EquityVestingImportDialog.fragments.organization}
    fragment UploadEquityBanner_organization on Organization {
      id
      hasAnyEquityVestEvents
      ...EquityVestingImportDialog_organization
    }
  `,
};
