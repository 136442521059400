import { gql } from "@apollo/client";
import { Link, makeStyles, Tooltip } from "@material-ui/core";
import { CheckIcon } from "src/components/AssembleIcons/Small/CheckIcon";
import { DenyIcon } from "src/components/AssembleIcons/Small/DenyIcon";
import { PromotionIcon } from "src/components/AssembleIcons/Small/PromotionIcon";
import {
  BLUE_3,
  DV_GREEN,
  DV_RED,
  GRAY_1,
  GREEN_2,
  RED,
  SPEARMINT,
  WHITE,
} from "../../../theme";
import {
  ReviewRequestStatus,
  SubmittedCardHeader_author,
} from "../../../__generated__/graphql";
import { AssembleTypography } from "../../AssembleTypography";
import { TimeAgoWithTooltip } from "../../TimeAgoWithTooltip";
import { UserAvatar } from "../../UserAvatar";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1.5),
  },
  authorText: {
    margin: theme.spacing(0, 1),
    fontWeight: 500,
  },
  authorTooltip: {
    padding: theme.spacing(1, 0),
  },
  authorNameInTooltip: {
    marginTop: theme.spacing(1),
    fontWeight: 700,
  },
  authorEmailInTooltip: {
    color: BLUE_3,
  },
  reviewIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "24px",
    width: "24px",
    borderRadius: "50%",
  },
  assembleIcon: {
    height: "16px",
    width: "16px",
  },
  finalApproval: {
    background: "linear-gradient(148.74deg, #17BD54 27.64%, #089562 87.25%)",
  },
  finalDenial: {
    background: "linear-gradient(145.58deg, #FF385C 28.94%, #D91D4A 87.8%);",
  },
  nonFinalReview: {
    background: "linear-gradient(325.69deg, #49465E 23.51%, #C1CACF 91.1%);",
  },
  rightSide: {
    display: "flex",
    marginLeft: "auto",
  },
  totalCompText: {
    borderRadius: "2px",
    padding: "1px 4px",
    fontWeight: 500,
    marginLeft: theme.spacing(1),
  },
  increase: {
    color: SPEARMINT,
    border: `1px solid ${SPEARMINT}`,
    background: `${DV_GREEN}1A`,
  },
  decrease: {
    color: RED,
    border: `1px solid ${RED}`,
    background: `${DV_RED}1A`,
  },
}));

type Props = {
  author: SubmittedCardHeader_author;
  submittedAt: Date;
  status: ReviewRequestStatus;
  isFinalReviewer?: boolean;
  hasPromotion?: boolean;
};

export function SubmittedCardHeader({
  author,
  submittedAt,
  status,
  isFinalReviewer = false,
  hasPromotion = false,
}: Props): JSX.Element {
  const classes = useStyles();

  const reviewStatus = formatReviewStatus(status, isFinalReviewer);

  return (
    <div className={classes.header}>
      <ReviewIcon
        status={status}
        author={author}
        isFinalReviewer={isFinalReviewer}
      />
      <AssembleTypography
        display="inline"
        variant="productExtraSmall"
        className={classes.authorText}
        style={{ color: reviewStatus.color }}
      >
        <Tooltip
          title={
            <div className={classes.authorTooltip}>
              <UserAvatar
                displayName={author.name}
                photoURL={author.photoURL}
              />
              {author.name !== null && (
                <div className={classes.authorNameInTooltip}>{author.name}</div>
              )}
              <Link
                href={`mailto:${author.email}`}
                variant="body2"
                className={classes.authorEmailInTooltip}
                underline="none"
              >
                {author.email}
              </Link>
            </div>
          }
          placement="top"
          interactive
        >
          <span>{`${author.name ?? ""} `}</span>
        </Tooltip>
        {reviewStatus.text}.
      </AssembleTypography>
      <TimeAgoWithTooltip timestamp={submittedAt} />
      <div className={classes.rightSide}>
        {hasPromotion && <PromotionIcon />}
      </div>
    </div>
  );
}

function formatReviewStatus(
  status: ReviewRequestStatus,
  isFinalReviewer: boolean
) {
  const statusDisplayMap = {
    APPROVED: {
      text: "accepted changes",
      color: isFinalReviewer ? GREEN_2 : GRAY_1,
    },
    DENIED: {
      text: "rejected changes",
      color: isFinalReviewer ? RED : GRAY_1,
    },
    AWAITING_REVIEW: {
      text: "submitted changes",
      color: GRAY_1,
    },
    AWAITING_DEPENDENCY: {
      text: undefined,
      color: undefined,
    },
  } as const;

  return statusDisplayMap[status];
}

function ReviewIcon({
  status,
  author,
  isFinalReviewer,
}: {
  status: ReviewRequestStatus;
  author: SubmittedCardHeader_author;
  isFinalReviewer: boolean;
}) {
  const classes = useStyles();

  if (status === ReviewRequestStatus.AWAITING_REVIEW) {
    return (
      <UserAvatar
        displayName={author.name}
        photoURL={author.photoURL}
        size="mini"
      />
    );
  }
  if (status === ReviewRequestStatus.APPROVED) {
    return (
      <div
        className={`${classes.reviewIcon} ${
          isFinalReviewer ? classes.finalApproval : classes.nonFinalReview
        }`}
      >
        <CheckIcon color={WHITE} />
      </div>
    );
  }
  if (status === ReviewRequestStatus.DENIED) {
    return (
      <div
        className={`${classes.reviewIcon} ${
          isFinalReviewer ? classes.finalDenial : classes.nonFinalReview
        }`}
      >
        <DenyIcon color={WHITE} />
      </div>
    );
  }

  return <></>;
}

SubmittedCardHeader.fragments = {
  author: gql`
    fragment SubmittedCardHeader_author on User {
      name
      email
      photoURL
    }
  `,
};
