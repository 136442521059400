import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const CalendarIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Calendar"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M19.5 4.25H4.5C4.08579 4.25 3.75 4.58579 3.75 5V20C3.75 20.4142 4.08579 20.75 4.5 20.75H19.5C19.9142 20.75 20.25 20.4142 20.25 20V5C20.25 4.58579 19.9142 4.25 19.5 4.25Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M16.5 2.375V4.25"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M7.5 2.375V4.25"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M3.75 8.75H20.25"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
