import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const ActivityIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Activity"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill"
        d="M1 7.25a.75.75 0 000 1.5v-1.5zM4 8l.728-.182A.75.75 0 004 7.25V8zm1 4l-.728.182a.75.75 0 001.466-.048L5 12zM7 1l.728-.182a.75.75 0 00-1.466.048L7 1zm3.5 14l-.728.182a.75.75 0 001.468-.059L10.5 15zM12 6v-.75a.75.75 0 00-.74.627L12 6zm3 .75a.75.75 0 000-1.5v1.5zm-14 2h3v-1.5H1v1.5zm2.272-.568l1 4 1.456-.364-1-4-1.456.364zm2.466 3.952l2-11L6.262.866l-2 11 1.476.268zm.534-10.952l3.5 14 1.456-.364-3.5-14-1.456.364zm4.968 13.941l1.5-9-1.48-.246-1.5 9 1.48.246zM12 6.75h3v-1.5h-3v1.5z"
      />
    </svg>
  </ColorableSVG>
);
