import { InfoBanner } from "../../InfoBanner";

export function CancelledInviteBanner({
  userInviteCancelled,
  userName,
}: {
  userInviteCancelled: boolean;
  userName: string | null;
}): JSX.Element | null {
  return userInviteCancelled ? (
    <InfoBanner level="warning">
      {userName ?? "This person"}'s Assemble invitation has been{" "}
      <b>cancelled</b>. You can resend an invitation by going back to the User
      Permissions Settings Page.
    </InfoBanner>
  ) : null;
}
