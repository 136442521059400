import { gql, useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { AssembleHeader } from "src/components/AssembleHeader/AssembleHeader";
import {
  GetDepartments,
  CompensationBandSection_organization as Organization,
  PrimaryRoleName,
  AutomatedManagerAccess_report as Report,
} from "../../../__generated__/graphql";
import { UserPermissions } from "../../../models/UserPermissions";
import { DEPARTMENTS_QUERY } from "../../../queries";
import { AutomatedManagerAccess } from "./AutomatedManagerAccess";
import { CancelledInviteBanner } from "./CancelledInviteBanner";
import { CompensationBandEmptyState } from "./CompensationBandEmptyState";
import { CompensationBandSectionTooltip } from "./CompensationBandSectionTooltip";
import { DisabledWarningBanner } from "./DisabledWarningBanner";
import { MarketsSubsection } from "./MarketsSubsection";

const useStyles = makeStyles((theme) => ({
  section: {
    paddingInline: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3.75),
  },
  heading: { display: "flex", flexDirection: "row", alignItems: "center" },
  subsections: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(5),
  },
}));

type Props = {
  organization: Organization;
  targetId: number;
  userName: string | null;
  userPermissions: UserPermissions;
  isDisabled: boolean;
  type: "user" | "user-invite";
  reports: Report[];
};

export function CompensationBandSection({
  organization,
  targetId,
  userName,
  userPermissions,
  isDisabled,
  type,
  reports,
}: Props): JSX.Element {
  const classes = useStyles();
  const { data: jobStructure } = useQuery<GetDepartments>(DEPARTMENTS_QUERY);

  const isAutomatedManagerPermissionsFeatureOn =
    organization.permissionSettings.automatedManagerAccess !== "NONE";

  const canEditCompensationBandAccess =
    userPermissions.roleName === PrimaryRoleName.RECRUITER ||
    userPermissions.roleName === PrimaryRoleName.BASIC_VIEWER ||
    userPermissions.roleName === PrimaryRoleName.HRBP;

  const disabled =
    (!canEditCompensationBandAccess &&
      !isAutomatedManagerPermissionsFeatureOn) ||
    isDisabled;

  if (!jobStructure) {
    return <></>;
  }

  return (
    <div className={classes.section}>
      <div>
        <AssembleHeader
          title={
            <div className={classes.heading}>
              Compensation Band Access <CompensationBandSectionTooltip />
            </div>
          }
          description="Control granular access to which compensation bands this person can see."
          disabled={disabled}
        />
      </div>
      <AutomatedManagerAccess
        jobStructure={jobStructure}
        reports={reports}
        organization={organization}
      />
      <CancelledInviteBanner
        userInviteCancelled={isDisabled && type === "user-invite"}
        userName={userName}
      />
      <DisabledWarningBanner
        userIsDisabled={isDisabled && type === "user"}
        userName={userName}
      />
      {canEditCompensationBandAccess ? (
        <div className={classes.subsections}>
          <MarketsSubsection
            jobStructure={jobStructure}
            userId={targetId}
            userPermissions={userPermissions}
            type={type}
          />
        </div>
      ) : (
        <CompensationBandEmptyState
          primaryRoleName={userPermissions.roleName}
        />
      )}
    </div>
  );
}

CompensationBandSection.fragments = {
  report: gql`
    ${AutomatedManagerAccess.fragments.report}
    fragment CompensationBandSection_report on Employee {
      id
      ...AutomatedManagerAccess_report
    }
  `,
  organization: gql`
    ${AutomatedManagerAccess.fragments.organization}
    fragment CompensationBandSection_organization on Organization {
      id
      ...AutomatedManagerAccess_organization
    }
  `,
};
