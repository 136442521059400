import { formatCurrency } from "@asmbl/shared/money";
import { makeStyles, Tooltip } from "@material-ui/core";
import { CashCompType, CompUnit } from "../../__generated__/graphql";
import { calculateBandPosition } from "../../models/Band";
import {
  AdjustedBandPoint,
  annualizedCashCompToBandPoint,
  getBandPenetration,
} from "../../models/BandPoint";
import { CashCompensation, TotalCash } from "../../models/CashCompensation";
import { Range } from "../../utils";
import { AssembleTypography } from "../AssembleTypography";
import { NewCompSlider } from "./NewCompSlider";
import { AvatarPoint } from "./Points/AvatarPoint";

const useStyles = makeStyles(() => ({
  tooltip: {
    borderRadius: "8px",
  },
}));
interface Props {
  compRange: Range;
  band: AdjustedBandPoint[];
  empTotalCashComp: TotalCash<CashCompensation> | undefined;
  avatar?: {
    displayName: string;
    photoURL: string | null;
  };
  showEmptyBand?: boolean;
}

/**
 * @description This Slider adjusts its width and X position in the parent div
 * based on the totalCompRange and the list of bandpoints.
 * Ex: It is used in the Employee Portal for the Comp Bands Comparison table;
 * the adjustments align the start and end of the slider along the header values
 * and the employee's cash comps are displayed as user avatars instead of dots.
 * @param compRange the min and max range for the bands being displayed
 * @param band the adjustable band points for this specific band
 * @param empTotalCashComp the employee's total cash comp based on selected options and displayed band
 * @param avatar the employee info required to display a user avatar
 * @param showEmptyBand boolean to display the slider in grey or not
 * @returns NewCompSlider
 */
export const AdjustableCompSlider = ({
  band,
  empTotalCashComp,
  avatar,
  showEmptyBand,
  compRange,
}: Props) => {
  const classes = useStyles();
  const { bandMax, bandMin, barWidth, xOffset, totalSegment } =
    calculateBandPosition(band, compRange);

  const formatTooltip = (value: number): string =>
    formatCurrency(
      { value, currency: band[0].annualCashEquivalent.currency },
      { maximumFractionDigits: 0 }
    );

  const avatarOffset = empTotalCashComp?.annualTotal
    ? (empTotalCashComp.annualTotal.value - compRange.min) / totalSegment -
      xOffset
    : 0;

  const empAvatarBandPoint = empTotalCashComp
    ? annualizedCashCompToBandPoint({
        type: CashCompType.SALARY,
        annualCashEquivalent: empTotalCashComp.annualTotal,
        hourlyCashEquivalent: empTotalCashComp.hourlyTotal,
        unit: CompUnit.CASH,
        percentOfSalary: 100,
      })
    : null;

  const getPenetration = getBandPenetration(band);

  return (
    // outer div tells slider where to start & inner div determines its width
    <div
      style={{
        transform: `translateX(${showEmptyBand === true ? 0 : xOffset}%)`,
      }}
    >
      <Tooltip
        className={classes.tooltip}
        title={
          band.length === 0 ? (
            "" // hide the tooltip but not the Slider if there's no band
          ) : (
            <AssembleTypography variant="productSmallBold">
              {formatTooltip(bandMin)} - {formatTooltip(bandMax)}
            </AssembleTypography>
          )
        }
      >
        <div style={{ width: `${showEmptyBand === true ? 100 : barWidth}%` }}>
          <NewCompSlider
            band={band}
            values={empTotalCashComp?.subcomponents ?? []}
            showEmptyBand={showEmptyBand}
            hideRangeLabels
            hidePoints
          />
        </div>
      </Tooltip>
      {empTotalCashComp && empAvatarBandPoint && (
        <AvatarPoint
          point={empAvatarBandPoint}
          penetration={getPenetration(empAvatarBandPoint) ?? 0}
          offset={`calc(${avatarOffset}%)`}
          avatar={avatar}
        />
      )}
    </div>
  );
};
