import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const ClockIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Clock"
      viewBox="0 0 24 25"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill"
        d="M13 8.5C13 7.94772 12.5523 7.5 12 7.5C11.4477 7.5 11 7.94772 11 8.5H13ZM12 12.5H11C11 12.7652 11.1054 13.0196 11.2929 13.2071L12 12.5ZM14.2929 16.2071C14.6834 16.5976 15.3166 16.5976 15.7071 16.2071C16.0976 15.8166 16.0976 15.1834 15.7071 14.7929L14.2929 16.2071ZM12 3.5V4.5V3.5ZM11 8.5V12.5H13V8.5H11ZM11.2929 13.2071L14.2929 16.2071L15.7071 14.7929L12.7071 11.7929L11.2929 13.2071ZM20 12.5C20 13.5506 19.7931 14.5909 19.391 15.5615L21.2388 16.3268C21.7413 15.1136 22 13.8132 22 12.5H20ZM19.391 15.5615C18.989 16.5321 18.3997 17.414 17.6569 18.1569L19.0711 19.5711C19.9997 18.6425 20.7362 17.5401 21.2388 16.3268L19.391 15.5615ZM17.6569 18.1569C16.914 18.8997 16.0321 19.489 15.0615 19.891L15.8268 21.7388C17.0401 21.2362 18.1425 20.4997 19.0711 19.5711L17.6569 18.1569ZM15.0615 19.891C14.0909 20.2931 13.0506 20.5 12 20.5V22.5C13.3132 22.5 14.6136 22.2413 15.8268 21.7388L15.0615 19.891ZM12 20.5C10.9494 20.5 9.90914 20.2931 8.93853 19.891L8.17317 21.7388C9.38642 22.2413 10.6868 22.5 12 22.5V20.5ZM8.93853 19.891C7.96793 19.489 7.08601 18.8997 6.34315 18.1569L4.92893 19.5711C5.85752 20.4997 6.95991 21.2362 8.17317 21.7388L8.93853 19.891ZM6.34315 18.1569C5.60028 17.414 5.011 16.5321 4.60896 15.5615L2.7612 16.3268C3.26375 17.5401 4.00035 18.6425 4.92893 19.5711L6.34315 18.1569ZM4.60896 15.5615C4.20693 14.5909 4 13.5506 4 12.5H2C2 13.8132 2.25866 15.1136 2.7612 16.3268L4.60896 15.5615ZM4 12.5C4 10.3783 4.84285 8.34344 6.34315 6.84315L4.92893 5.42893C3.05357 7.3043 2 9.84784 2 12.5H4ZM6.34315 6.84315C7.84344 5.34285 9.87827 4.5 12 4.5V2.5C9.34784 2.5 6.8043 3.55357 4.92893 5.42893L6.34315 6.84315ZM12 4.5C14.1217 4.5 16.1566 5.34285 17.6569 6.84315L19.0711 5.42893C17.1957 3.55357 14.6522 2.5 12 2.5V4.5ZM17.6569 6.84315C19.1571 8.34344 20 10.3783 20 12.5H22C22 9.84784 20.9464 7.3043 19.0711 5.42893L17.6569 6.84315Z"
      />
    </svg>
  </ColorableSVG>
);
