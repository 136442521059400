import { gql } from "@apollo/client";
import { exchangeFromTo } from "@asmbl/shared/currency";
import { formatCurrency, Money, multiply } from "@asmbl/shared/money";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { InfoIcon } from "src/components/AssembleIcons/Brand/InfoIcon";
import {
  EquityGrantInfoSummary_equityGrant as Grant,
  EquityGrantInfoSummary_equityHoldings as EquityHoldings,
} from "../../../../__generated__/graphql";
import { AssembleTypography } from "../../../AssembleTypography";
import { useCurrencies } from "../../../CurrenciesContext";
import { EquityGrantInfoCell } from "./EquityGrantInfoCell";

const useStyles = makeStyles({
  grossValueContainer: {
    display: "flex",
    gap: "0.875rem",
    alignItems: "center",
    marginTop: "1.5rem",
  },
  grossValueText: {
    fontWeight: 500,
    fontSize: "1.375rem",
    lineHeight: "140%",
    letterSpacing: "-1px",
  },
});

type Props = {
  equityGrant: Grant;
  sharePrice: Money | undefined;
  valuationCurrency: EquityHoldings["valuationCurrency"];
};

export const EquityGrantInfoSummary = ({
  equityGrant,
  sharePrice,
  valuationCurrency,
}: Props) => {
  const classes = useStyles();
  const { currencies } = useCurrencies();

  const grantCurrency = currencies.get(equityGrant.price.currency);

  // sharePrice is already to the valuation currency, but grant price is not
  const convertedGrantPrice = grantCurrency
    ? exchangeFromTo(equityGrant.price, grantCurrency, valuationCurrency)
    : equityGrant.price;

  const costToExercise = multiply(convertedGrantPrice, equityGrant.unitCount);

  return (
    <div>
      <div className={classes.grossValueContainer}>
        <Typography className={classes.grossValueText}>
          {sharePrice !== undefined && equityGrant.grossValue !== null
            ? formatCurrency(multiply(sharePrice, equityGrant.unitCount))
            : "No Data"}
        </Typography>
        {equityGrant.fromCarta && (
          <Tooltip title="Imported from Carta" placement="top">
            <span>
              <InfoIcon />
            </span>
          </Tooltip>
        )}
      </div>
      <AssembleTypography variant="formDescription">
        Est. Grant Gross Value
      </AssembleTypography>
      <EquityGrantInfoCell title="Est. Unit Price" value={sharePrice} />
      <EquityGrantInfoCell title="Strike Price" value={convertedGrantPrice} />
      <EquityGrantInfoCell title="Cost to Exercise" value={costToExercise} />
      <EquityGrantInfoCell
        title="Total Number of Units"
        units={equityGrant.unitCount}
      />
    </div>
  );
};

EquityGrantInfoSummary.fragments = {
  equityGrant: gql`
    fragment EquityGrantInfoSummary_equityGrant on EquityGrant {
      id
      grossValue
      unitCount
      price
      fromCarta
      vestingInformation {
        vestedUnits
        unvestedUnits
      }
    }
  `,
  equityHoldings: gql`
    fragment EquityGrantInfoSummary_equityHoldings on EquityHoldings {
      id
      valuationCurrency {
        id
        code
        exchangeRate
      }
    }
  `,
};
