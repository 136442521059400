import { gql } from "@apollo/client";
import { Box } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { ArrowRightIcon } from "src/components/AssembleIcons/Brand/ArrowRightIcon";
import { GRAY_4 } from "../../../../theme";
import { EmploymentCarouselPanel_employment as Employment } from "../../../../__generated__/graphql";
import { EmploymentCarouselCard } from "./EmploymentCarouselCard";

interface Props {
  employment: Employment;
  selected: number | undefined;
  onSelect: (id: number) => unknown;
  itemId: string;
}

export const EmploymentCarouselPanel = ({
  employment,
  itemId,
  selected,
  onSelect,
}: Props): JSX.Element => {
  const { getItemById, scrollToItem, isLastItem, initComplete } =
    useContext(VisibilityContext);
  const [currentlyDisplayed, seCurrentlyDisplayed] = useState(-1);

  useEffect(() => {
    if (
      initComplete &&
      selected !== undefined &&
      selected !== currentlyDisplayed
    ) {
      seCurrentlyDisplayed(selected);
      const selectedEmployment = getItemById(selected.toString());
      scrollToItem(selectedEmployment?.entry.target, "smooth", "end");
    }
  }, [selected, getItemById, scrollToItem, currentlyDisplayed, initComplete]);

  return (
    <Box flexDirection="row" width="100%" key={employment.id} display="flex">
      <EmploymentCarouselCard
        employment={employment}
        selected={selected === employment.id}
        onSelect={() => {
          onSelect(employment.id);
        }}
      />
      <Box
        mx={2}
        alignItems="center"
        width="16px"
        key={`${employment.id} ${itemId}`}
        display="flex"
      >
        {initComplete && !isLastItem(itemId) && (
          <ArrowRightIcon inline color={GRAY_4} />
        )}
      </Box>
    </Box>
  );
};

EmploymentCarouselPanel.fragments = {
  employment: gql`
    ${EmploymentCarouselCard.fragments.employment}
    fragment EmploymentCarouselPanel_employment on Employment {
      ...EmploymentCarouselCard_employment
    }
  `,
};
