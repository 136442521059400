import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const NoResultsIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="No Results"
      viewBox="0 0 48 48"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M32.8857 32.8875L41.9982 42.0001"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M21.75 37.5C30.4485 37.5 37.5 30.4485 37.5 21.75C37.5 13.0515 30.4485 6 21.75 6C13.0515 6 6 13.0515 6 21.75C6 30.4485 13.0515 37.5 21.75 37.5Z"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M27.8656 27.1004C27.127 26.1908 26.1947 25.4574 25.1368 24.9536C24.0789 24.4499 22.9219 24.1885 21.7501 24.1885C20.5784 24.1885 19.4214 24.4499 18.3635 24.9537C17.3056 25.4574 16.3733 26.1909 15.6348 27.1005"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="fill"
        d="M16.5938 20.3438C18.0435 20.3438 19.2188 19.1685 19.2188 17.7188C19.2188 16.269 18.0435 15.0938 16.5938 15.0938C15.144 15.0938 13.9688 16.269 13.9688 17.7188C13.9688 19.1685 15.144 20.3438 16.5938 20.3438Z"
      />
      <path
        className="fill"
        d="M26.9062 20.3438C28.356 20.3438 29.5312 19.1685 29.5312 17.7188C29.5312 16.269 28.356 15.0938 26.9062 15.0938C25.4565 15.0938 24.2812 16.269 24.2812 17.7188C24.2812 19.1685 25.4565 20.3438 26.9062 20.3438Z"
      />
    </svg>
  </ColorableSVG>
);
