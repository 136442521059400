import { gql } from "@apollo/client";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { EyeOffIcon } from "src/components/AssembleIcons/Brand/EyeOff";
import { EyeOnIcon } from "src/components/AssembleIcons/Brand/EyeOnIcon";
import { POSITION_FIELDS_MINIMAL } from "../../../fragments";
import { GREEN_2, RED } from "../../../theme";
import {
  ReportsSectionTable_reports as Report,
  TeamCompensationAccessType,
} from "../../../__generated__/graphql";
import { AssembleTypography } from "../../AssembleTypography";
import { ExplanatoryTooltip } from "../../ExplanatoryTooltip";

type Props = {
  managerName: string | null;
  reports: Report[];
  teamCompensationAccess: TeamCompensationAccessType;
  hasFullAccess: boolean;
};

export function ReportsSectionTable({
  managerName,
  reports,
  teamCompensationAccess,
  hasFullAccess,
}: Props): JSX.Element {
  const hasEquityCompensationAccess =
    teamCompensationAccess ===
    TeamCompensationAccessType.INDIRECT_REPORTS_CASH_AND_EQUITY;
  const hasCashCompensationAccess =
    hasEquityCompensationAccess ||
    teamCompensationAccess === TeamCompensationAccessType.INDIRECT_REPORTS_CASH;
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell variant="head">
              <AssembleTypography variant="productTableHeader">
                Name ({reports.length})
              </AssembleTypography>
            </TableCell>
            <TableCell variant="head">
              <AssembleTypography variant="productTableHeader">
                Department
              </AssembleTypography>
            </TableCell>
            <TableCell variant="head">
              <AssembleTypography variant="productTableHeader">
                Position
              </AssembleTypography>
            </TableCell>
            <TableCell variant="head" align="center">
              <AssembleTypography variant="productTableHeader">
                Level
              </AssembleTypography>
            </TableCell>
            <TableCell variant="head" align="center">
              <AssembleTypography variant="productTableHeader">
                Cash
              </AssembleTypography>
            </TableCell>
            <TableCell variant="head" align="center">
              <AssembleTypography variant="productTableHeader">
                Equity
              </AssembleTypography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map(({ id, displayName, activeEmployment }) => (
            <TableRow key={id}>
              <TableCell>{displayName}</TableCell>
              <TableCell>
                {activeEmployment?.position?.ladder.department.name ?? "-"}
              </TableCell>
              <TableCell>{activeEmployment?.position?.name ?? "-"}</TableCell>
              <TableCell align="center">
                {activeEmployment?.position?.level ?? "-"}
              </TableCell>
              <TableCell align="center">
                <VisibilityIcon
                  managerName={managerName}
                  canSeeCompensation={
                    hasFullAccess || hasCashCompensationAccess
                  }
                />
              </TableCell>
              <TableCell align="center">
                <VisibilityIcon
                  managerName={managerName}
                  canSeeCompensation={
                    hasFullAccess || hasEquityCompensationAccess
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ReportsSectionTable.fragments = {
  reports: gql`
    ${POSITION_FIELDS_MINIMAL}
    fragment ReportsSectionTable_reports on Employee {
      id
      displayName
      activeEmployment {
        id
        position {
          ...PositionFieldsMinimal
        }
      }
    }
  `,
};

function VisibilityIcon({
  managerName,
  canSeeCompensation,
}: {
  managerName: string | null;
  canSeeCompensation: boolean;
}) {
  return (
    <ExplanatoryTooltip
      title={
        canSeeCompensation
          ? `${
              managerName ?? "This manager"
            } can see this report's compensation data`
          : "Only people with Full Access can view compensation data"
      }
      body={
        <>
          To change that, either update {managerName ?? "the manager"}'s role or
          change your Employee Compensation Access Policy.
        </>
      }
      ctaUrl="/settings/policies"
      ctaLabel="Go to Access Policies"
      width="440px"
    >
      <IconButton size="small">
        {canSeeCompensation ? (
          <EyeOnIcon color={GREEN_2} inline height="24px" width="24px" />
        ) : (
          <EyeOffIcon color={RED} inline height="24px" width="24px" />
        )}
      </IconButton>
    </ExplanatoryTooltip>
  );
}
