import { gql } from "@apollo/client";
import { Currency } from "@asmbl/shared/currency";
import { getNewPay } from "@asmbl/shared/employee";
import { contramap } from "@asmbl/shared/sort";
import { formatNumeral } from "@asmbl/shared/utils";
import { getSalaryCashComp } from "src/models/CashCompensation";
import {
  CurrencyCode,
  CondensedTableNewSalaryCompaRatioCell2_participant as Participant,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getCompaRatioNew } from "../../../../models/Employee";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableNewSalaryCompaRatioCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { currencies, defaultCurrency } = useCurrencies();

  const newCompaRatio = getNewCompaRatio(employee, currencies, defaultCurrency);

  return (
    <AssembleTruncatedTypography align="right">
      {newCompaRatio !== undefined
        ? formatNumeral(newCompaRatio, { style: "percent" })
        : "-"}
    </AssembleTruncatedTypography>
  );
}

function getNewCompaRatio(
  employee: Participant,
  currencies: Map<CurrencyCode, Currency>,
  defaultCurrency: Currency
): number | undefined {
  const currentSalaryComp = getSalaryCashComp(
    employee.subject.activeCashCompensation
  );

  if (!currentSalaryComp) return undefined;

  const newSalary = getNewPay(
    {
      activeCashCompensation: employee.subject.activeCashCompensation,
      compRecommendation: employee.compRecommendation,
    },
    currencies,
    defaultCurrency
  );

  if (!newSalary) return undefined;

  const newActiveCash = [
    {
      ...currentSalaryComp,
      annualCashEquivalent: newSalary.annualCashEquivalent,
      hourlyCashEquivalent: newSalary.hourlyCashEquivalent,
      unitType: newSalary.unitType,
    },
  ];

  const latestBands = employee.compRecommendation?.latestSubmittedItems.find(
    (item) => item.adjustedCashBands !== null
  );

  // if there is no comp rec band, the employee remains in their current role
  const cashBands = latestBands
    ? latestBands.adjustedCashBands
    : employee.subject.adjustedCashBands;

  return getCompaRatioNew(newActiveCash, cashBands);
}

CondensedTableNewSalaryCompaRatioCell2.ordering = ({
  currencies,
  defaultCurrency,
}: {
  currencies: Map<CurrencyCode, Currency>;
  defaultCurrency: Currency;
}) =>
  contramap(
    (e: Participant) => getNewCompaRatio(e, currencies, defaultCurrency) ?? 0
  );

CondensedTableNewSalaryCompaRatioCell2.fragments = {
  participant: gql`
    fragment CondensedTableNewSalaryCompaRatioCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          salary(currencyCode: $currencyCode)
        }
        adjustedCashBands(currencyCode: $currencyCode) {
          id
          name
          bandPoints {
            value {
              ... on CashValue {
                annualRate
                hourlyRate
                currencyCode
              }
            }
          }
        }
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          annualCashEquivalent
          hourlyCashEquivalent
          unit
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedPayIncrease(currencyCode: $currencyCode) {
          annualCashEquivalent
          hourlyCashEquivalent
          unitType
        }
        latestSubmittedItems {
          id
          adjustedCashBands(currencyCode: $currencyCode) {
            id
            name
            bandPoints {
              name
              value {
                ... on CashValue {
                  annualRate
                  hourlyRate
                  currencyCode
                }
              }
            }
          }
        }
      }
    }
  `,
};

CondensedTableNewSalaryCompaRatioCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.NEW_SALARY_COMPA_RATIO
);
CondensedTableNewSalaryCompaRatioCell2.id = ColumnIds.NEW_SALARY_COMPA_RATIO;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNewSalaryCompaRatioCell2,
  Header: CondensedTableNewSalaryCompaRatioCell2.Header,
  id: CondensedTableNewSalaryCompaRatioCell2.id,
  width: LARGE_COL_WIDTH,
  defaultCanSort: false,
};
CondensedTableNewSalaryCompaRatioCell2.column = column;
