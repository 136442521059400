import { ArrayValue, NonNull } from "../utils";
import { GetDepartment } from "../__generated__/graphql";

type Ladder = ArrayValue<NonNull<GetDepartment["department"]>["ladders"]>;

export class ComputedLadder {
  readonly id: number;
  readonly name: string;
  readonly description: string | null;
  readonly positionsCount: number;
  readonly levelMin: number;
  readonly levelMax: number;

  constructor(ladder: Ladder) {
    this.id = ladder.id;
    this.name = ladder.name;
    this.description = ladder.description;
    this.positionsCount = ladder.positions.length;

    const { min, max } = this.getLevelsRange(ladder);
    this.levelMin = min;
    this.levelMax = max;
  }

  private getLevelsRange(ladder: Ladder): {
    min: number;
    max: number;
  } {
    if (ladder.positions.length === 0) return { min: 0, max: 0 };

    const sortedLevels = ladder.positions
      .flatMap((p) => p.level)
      .sort((a, b) => a - b);
    const min = sortedLevels[0];
    const max = sortedLevels[sortedLevels.length - 1];
    return { min, max };
  }
}
