import { gql } from "@apollo/client";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { useState } from "react";
import { PlusCircleIcon } from "src/components/AssembleIcons/Brand/PlusCircleIcon";
import { useDeleteLocationGroup } from "../../../mutations/Location";
import {
  GRAY_3,
  GRAY_4,
  GRAY_6,
  GRAY_8,
  PURPLE_2,
  WHITE,
} from "../../../theme";
import {
  LocationGroupCard_compStructure as CompStructure,
  LocationGroupCard_departments as Department,
  LocationGroupCard_locationGroup as LocationGroup,
  LocationGroupCard_locations as Location,
  LocationGroupCard_markets as Market,
} from "../../../__generated__/graphql";
import AssembleDialog from "../../AssembleDialog";
import { AssembleTypography } from "../../AssembleTypography";
import { ExplanatoryTooltip } from "../../ExplanatoryTooltip";
import { DeleteButton } from "../../Form/DeleteButton";
import {
  AdjustmentEditorState,
  LocationAdjustmentEditor,
} from "./LocationAdjustmentEditor";
import { LocationAdjustmentTable } from "./LocationAdjustmentTable";
import { LocationGroupDetails } from "./LocationGroupDetails";
import { LocationGroupEditor } from "./LocationGroupEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    background: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px",
    transition: "opacity 125ms, border 125ms",
    "&:hover": {
      "& $buttonContainer": {
        opacity: 1,
      },
    },
    "&:focus-within": {
      "& $buttonContainer": {
        opacity: 1,
      },
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    alignItems: "center",
  },
  headerText: {
    maxWidth: "calc(100% - 9rem)",
  },
  subheader: {
    color: GRAY_3,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
  },
  addAdjustmentContainer: {
    height: "42px",
    display: "flex",
    paddingLeft: theme.spacing(6),
    background: GRAY_8,
    borderRadius: "0 0 5px 5px",
    borderTop: `1px solid ${GRAY_6}`,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    opacity: 0,
    transition: "opacity 300ms",
  },
  sourceCompTag: {
    padding: "1px 6px 0px",
    height: "0.9375rem",
    marginLeft: theme.spacing(2),
    background: GRAY_4,
    color: WHITE,
    borderRadius: "2px",
    fontSize: "10px",
  },
}));

type Props = {
  market: Market;
  locationGroup: LocationGroup;
  allLocationGroups: LocationGroup[];
  locations: Location[];
  departments: Department[];
  compStructure: CompStructure;
  draggingGroupId: number | null;
  onDragStart: () => unknown;
  onDragEnd: () => unknown;
};

export function LocationGroupCard({
  market,
  locationGroup,
  allLocationGroups,
  locations,
  departments,
  compStructure,
  draggingGroupId,
  onDragStart,
  onDragEnd,
}: Props): JSX.Element {
  const classes = useStyles();

  const [adjustmentEditorState, setAdjustmentEditorState] =
    useState<AdjustmentEditorState>({ mode: "closed" });
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const deleteLocationGroup = useDeleteLocationGroup(locationGroup.id);

  const addAdjustment = () => {
    setAdjustmentEditorState({ mode: "new" });
  };

  const openAdjustmentEditor = (
    adjustmentEditorState: AdjustmentEditorState
  ) => {
    setAdjustmentEditorState(adjustmentEditorState);
  };

  const handleCloseEditor = () => setIsCancelDialogOpen(true);
  const handleCloseEditorWithoutConfirmation = () => {
    setAdjustmentEditorState({ mode: "closed" });
  };
  const handleCancelCloseEditor = () => {
    setIsCancelDialogOpen(false);
  };
  const handleConfirmCloseEditor = () => {
    setIsCancelDialogOpen(false);
    setAdjustmentEditorState({ mode: "closed" });
  };

  return (
    <Box
      className={classes.root}
      style={getDraggingStyle(draggingGroupId, locationGroup.id)}
    >
      <Box className={classes.header}>
        <Box className={classes.headerText}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <AssembleTypography variant="h5">
              {locationGroup.name}
            </AssembleTypography>
            {locationGroup.isSourceComp && (
              <ExplanatoryTooltip
                title={"Source Compensation"}
                body={
                  <>
                    Source Compensation is the base adjustment within any market
                    from which we calculate adjustments or derivatives.
                  </>
                }
                placement="top"
                width="25rem"
              >
                <div className={classes.sourceCompTag}>
                  <AssembleTypography variant="smallTag">
                    {"Source Compensation"}
                  </AssembleTypography>
                </div>
              </ExplanatoryTooltip>
            )}
          </div>
          {locationGroup.locations.length > 0 && (
            <AssembleTypography
              variant="productSmall"
              className={classes.subheader}
            >
              {locationGroup.locations
                .map((location) => location.city)
                .join(", ")}
            </AssembleTypography>
          )}
        </Box>
        <Box className={classes.buttonContainer}>
          {!locationGroup.isSourceComp && (
            <Tooltip title="Add Location Adjustment" placement="top">
              <IconButton size="small" onClick={addAdjustment}>
                <PlusCircleIcon
                  color={GRAY_4}
                  hoverColor={PURPLE_2}
                  height="24px"
                  width="24px"
                />
              </IconButton>
            </Tooltip>
          )}
          <Box m={0.5} />
          <LocationGroupDetails
            market={market}
            locationGroup={locationGroup}
            locations={locations}
          />
          <Box m={0.5} />
          <LocationGroupEditor
            market={market}
            existingLocationGroup={locationGroup}
            locations={locations}
          />
          <Box m={0.5} />
          {!locationGroup.isSourceComp && (
            <DeleteButton
              buttonType="icon"
              size="small"
              objectName="Location Group"
              onDelete={deleteLocationGroup}
            />
          )}
        </Box>
      </Box>
      <LocationAdjustmentTable
        locationGroup={locationGroup}
        locationAdjustments={locationGroup.locationAdjustments}
        departments={departments}
        openAdjustmentEditor={openAdjustmentEditor}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      />
      {!locationGroup.isSourceComp && (
        <Box className={classes.addAdjustmentContainer}>
          <Button color="default" onClick={addAdjustment}>
            Add adjustment…
          </Button>
        </Box>
      )}
      <Drawer
        anchor="left"
        open={adjustmentEditorState.mode !== "closed"}
        onClose={handleCloseEditor}
      >
        <LocationAdjustmentEditor
          defaultLocationGroup={locationGroup}
          allLocationGroups={allLocationGroups}
          departments={departments}
          compStructure={compStructure}
          closeDrawer={handleCloseEditorWithoutConfirmation}
          editorState={adjustmentEditorState}
        />
      </Drawer>

      {isCancelDialogOpen && (
        <AssembleDialog
          onCancel={handleCancelCloseEditor}
          onConfirm={handleConfirmCloseEditor}
          title={`Are you sure you want to cancel?`}
          text="If you cancel now, all of your unsaved changes will be lost."
          cancelButtonText="Back to Editing"
          confirmButtonText="Yes, Cancel"
          confirmButtonVariant="negative"
        />
      )}
    </Box>
  );
}

LocationGroupCard.fragments = {
  compStructure: gql`
    ${LocationAdjustmentEditor.fragments.compStructure}
    fragment LocationGroupCard_compStructure on CompStructure {
      ...LocationAdjustmentEditor_compStructure
      id
    }
  `,
  locations: gql`
    ${LocationGroupDetails.fragments.locations}
    ${LocationGroupEditor.fragments.locations}
    fragment LocationGroupCard_locations on Location {
      ...LocationGroupDetails_locations
      ...LocationGroupEditor_locations
      id
    }
  `,
  locationGroup: gql`
    ${LocationGroupDetails.fragments.locationGroup}
    ${LocationGroupEditor.fragments.locationGroup}
    ${LocationAdjustmentEditor.fragments.locationGroup}
    ${LocationAdjustmentTable.fragments.locationGroup}
    ${LocationAdjustmentTable.fragments.locationAdjustment}
    fragment LocationGroupCard_locationGroup on LocationGroup {
      ...LocationGroupDetails_locationGroup
      ...LocationGroupEditor_locationGroup
      ...LocationAdjustmentTable_locationGroup
      ...LocationAdjustmentEditor_locationGroup

      id
      name
      locationAdjustments {
        ...LocationAdjustmentTable_locationAdjustment
        id
      }
      locations {
        id
        city
      }
    }
  `,
  departments: gql`
    ${LocationAdjustmentTable.fragments.departments}
    ${LocationAdjustmentEditor.fragments.departments}
    fragment LocationGroupCard_departments on Department {
      ...LocationAdjustmentTable_departments
      ...LocationAdjustmentEditor_departments
      id
    }
  `,
  markets: gql`
    ${LocationGroupEditor.fragments.markets}
    ${LocationGroupDetails.fragments.markets}
    fragment LocationGroupCard_markets on Market {
      ...LocationGroupEditor_markets
      ...LocationGroupDetails_markets
      id
    }
  `,
};

function getDraggingStyle(
  draggingGroupId: number | null,
  locationGroupId: number
): { opacity?: number; border?: string } {
  const opacity =
    draggingGroupId != null && draggingGroupId !== locationGroupId ? 0.3 : 1;
  const border =
    draggingGroupId === locationGroupId ? `1px solid ${GRAY_4}` : undefined;
  return { opacity, border };
}
