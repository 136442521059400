import { makeStyles, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { TableHeaderBar } from "src/components/Table/TableHeaderBar";
import { CorrectionCostToMoveExport } from "../CorrectionCostToMoveExport";

const useStyles = makeStyles(() => ({
  downloadButton: {
    display: "flex",
    alignItems: "center",
  },
}));

type Props = {
  filter: { [key: string]: number[] | null | undefined };
  targetBandPoint: string;
  selectAllHandler: (selected: boolean) => void;
};

export const CorrectionCostToMoveEmployeesTableHeader = ({
  filter,
  targetBandPoint,
  selectAllHandler,
}: Props) => {
  const classes = useStyles();

  const [selectAll, setSelectAll] = useState(true);

  return (
    <TableHeaderBar
      slotLeft={
        <AssembleButton
          size="small"
          variant="contained"
          label={selectAll ? "Select All" : "Deselect All"}
          onClick={() => {
            selectAllHandler(selectAll);
            setSelectAll(!selectAll);
          }}
          data-intercom-target="button-create-comp-cycle"
        />
      }
      slotCenter={<></>}
      slotRight={
        <Tooltip title="Download data as a CSV">
          <div className={classes.downloadButton}>
            <CorrectionCostToMoveExport
              filter={filter}
              targetBandPoint={targetBandPoint}
            />
          </div>
        </Tooltip>
      }
      inContainer
    />
  );
};
