import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import {
  PhaseConfigurationList_employee as Employee,
  PhaseConfigurationListElement_user as User,
} from "src/__generated__/graphql";
import { usePhaseConfiguration } from "../usePhaseConfiguration";
import { EmptyPhaseList } from "./EmptyPhaseList";
import { PhaseConfigurationListElement } from "./PhaseConfigurationListElement";

const useStyles = makeStyles(() => ({
  root: { display: "flex", flexDirection: "column", gap: "2px" },
}));

type Props = {
  variant: "create" | "edit";
  endDate: Date | null | undefined;
  users: User[];
  employees: Employee[];
  handleViewPhaseAccess: () => void;
  handleDeletePhase: (phaseOrder: number) => void;
  handleEditPhaseAccess: (phaseOrder: number) => void;
  handleEditPhaseDate: (phaseOrder: number) => void;
};

export function PhaseConfigurationList({
  variant,
  endDate,
  users,
  employees,
  handleDeletePhase,
  handleViewPhaseAccess,
  handleEditPhaseAccess,
  handleEditPhaseDate,
}: Props): JSX.Element {
  const classes = useStyles();
  const { phaseConfiguration, phaseErrors } = usePhaseConfiguration();

  if (phaseConfiguration.length === 0 || !endDate) return <EmptyPhaseList />;

  const data =
    variant === "create"
      ? phaseConfiguration.slice().reverse()
      : phaseConfiguration;

  return (
    <div className={classes.root}>
      {data.map((phaseConfig, index) => {
        return (
          <PhaseConfigurationListElement
            variant={variant}
            isError={phaseErrors.includes(phaseConfig.phaseOrder)}
            key={phaseConfig.phaseOrder}
            index={index}
            endDate={endDate}
            users={users}
            employees={employees}
            phaseConfig={phaseConfig}
            handleViewPhaseAccess={handleViewPhaseAccess}
            handleDeletePhase={handleDeletePhase}
            handleEditPhaseAccess={handleEditPhaseAccess}
            handleEditPhaseDate={handleEditPhaseDate}
          />
        );
      })}
    </div>
  );
}

PhaseConfigurationList.fragments = {
  employee: gql`
    ${PhaseConfigurationListElement.fragments.employee}
    fragment PhaseConfigurationList_employee on Employee2 {
      id
      ...PhaseConfigurationListElement_employee
    }
  `,
  user: gql`
    ${PhaseConfigurationListElement.fragments.user}
    fragment PhaseConfigurationList_user on User {
      id
      ...PhaseConfigurationListElement_user
    }
  `,
};
