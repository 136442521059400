import { gql } from "@apollo/client";
import { makeStyles, MuiThemeProvider } from "@material-ui/core";
import { select } from "d3";
import { OrgChart } from "d3-org-chart";
import { useEffect, useMemo, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import {
  ApprovalsOrganizationChart_compCyclePhase,
  ApprovalsOrganizationChart_employee as Employee,
} from "src/__generated__/graphql";
import { useAuth } from "src/components/Auth/AuthContext";
import {
  SelectionMode,
  useCompCycleOrganizationChartSelectionData,
  useCompCycleOrganizationChartViewsData,
} from "src/components/CompCycleOrganizationChart/CompCycleOrganizationChartContext";
import { EmployeeCard } from "src/components/CompCycleOrganizationChart/EmployeeCard";
import { EmployeeCardButton } from "src/components/CompCycleOrganizationChart/EmployeeCardButton";
import {
  EMPLOYEE_NODE_HEIGHT,
  EMPLOYEE_NODE_WIDTH,
} from "src/components/OrganizationHierarchy/shared";
import {
  getDeepestNode,
  sanitizeData,
} from "src/components/OrganizationHierarchy/shared/utils";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { GRAY_5, SUBSETTINGS_SIDEBAR_START, theme } from "src/theme";
import { pixelsToNumber, remToPixels } from "src/utils";
import {
  CompCycleDataChangeHandler,
  CompCyclePhaseData,
} from "../CompCycleWizard/types";
import { COMP_CYCLE_HIERARCHY_CONFIGURATION_SIDEBAR_WIDTH } from "./ApprovalSettingsSidebar";
import { formatPhaseData } from "./ApprovalsOrganizationChartView";

type Props<T extends Employee> = {
  data: T[];
  phases: ApprovalsOrganizationChart_compCyclePhase[];
  chart: OrgChart<Employee>;
  phaseData: CompCyclePhaseData[];
  handleChange: CompCycleDataChangeHandler;
};

const useStyles = makeStyles(() => ({
  content: {
    position: "absolute",
    top: -10,
  },
  container: {
    width: "100%",
    height: "100%",
  },
}));

export function ApprovalsOrganizationChart<T extends Employee>({
  data,
  phases,
  chart,
  phaseData,
  handleChange,
}: Props<T>): JSX.Element {
  const classes = useStyles();
  const { width, height } = useWindowDimensions();

  const {
    selectedLayers,
    setSelectedLayers,
    layerDepth,
    layerData,
    setLayerDepth,
    setLayerData,
    selectionMode,
  } = useCompCycleOrganizationChartSelectionData();
  const { chartState, setPageEditState, setChartLoadState, chartLoaded } =
    useCompCycleOrganizationChartViewsData();
  const { organization } = useAuth();
  const d3Container = useRef<null | HTMLDivElement>(null);

  // by default, the data that we are getting back from the graphQL query
  // is not immutable, therefore `Object.isExtensible` will return `false`
  // this is a problem with the charting library as it needs to be able to
  // modify the objects so that it can add the necessary properties to it
  // this is why we are creating a new array of objects that are extensible
  const extensibleData = useMemo(() => {
    const sanitizedData = sanitizeData(data).map((employee) => ({
      ...employee,
    }));

    const deepestNode = getDeepestNode(sanitizedData);

    const syntheticRoot = sanitizedData.find(
      ({ parentId }) => parentId == null
    );

    // the synthetic root is the root node that we create to ensure
    // an absolute node, we know it exists but we are just adding
    // this fallback check (mostly to please TS)
    if (syntheticRoot == null) {
      return sanitizedData;
    }

    const organizationHeader =
      organization?.name != null ? organization.name : "Organization";

    // we are then expanding the deepest node so that the chart will initially
    // render with all the layers exposed

    return [
      { ...syntheticRoot, displayName: organizationHeader } as T,
      ...sanitizedData
        .filter(({ parentId }) => parentId != null)
        .map((employee) => ({
          ...employee,
          _expanded: deepestNode.id === employee.id ? true : false,
        })),
    ];
  }, [data, organization]);

  const sidebarWidth =
    remToPixels(COMP_CYCLE_HIERARCHY_CONFIGURATION_SIDEBAR_WIDTH) +
    pixelsToNumber(SUBSETTINGS_SIDEBAR_START);

  const chartWidth = width - sidebarWidth - 15;

  const connectorColor = () => {
    // update connector line color
    select(d3Container.current).selectAll("path").style("stroke", GRAY_5);
  };

  useEffect(() => {
    if (d3Container.current !== null) {
      chart
        .container(d3Container.current as unknown as string)
        .data(extensibleData)
        .compact(false)
        .nodeWidth(() => EMPLOYEE_NODE_WIDTH)
        .nodeHeight(() => EMPLOYEE_NODE_HEIGHT)
        .svgHeight(height)
        .svgWidth(chartWidth)
        .buttonContent((params) => {
          return ReactDOMServer.renderToString(
            <MuiThemeProvider theme={theme}>
              <EmployeeCardButton {...params} />
            </MuiThemeProvider>
          );
        })
        .nodeContent((node) => {
          const {
            depth,
            data: { parentId, id },
          } = node;

          const isSyntheticNode = parentId == null;

          const isNodeAssignedToPhase = phaseData.some(({ assigneeIds }) =>
            assigneeIds.includes(id)
          );

          const isNodeAssigned =
            isNodeAssignedToPhase ||
            (node.parent?.children == null && !isSyntheticNode);

          const isNodeSelected =
            selectionMode === SelectionMode.Layers
              ? selectedLayers[depth] === true
              : isNodeAssigned;

          return ReactDOMServer.renderToStaticMarkup(
            <MuiThemeProvider theme={theme}>
              <EmployeeCard
                node={node}
                isNodeSelected={isNodeSelected}
                isNodeAssigned={isNodeAssigned}
                isSyntheticNode={isSyntheticNode}
              />
            </MuiThemeProvider>
          );
        })
        .linkUpdate(connectorColor)
        .render();
    }

    // set the depth of the chart (how many layers of management)
    // set the layer data of the chart (which employee is on which layer)
    // so other components can use this data and the sidebar can load
    if (!chartLoaded) {
      const state = chart.getChartState();
      setLayerDepth(Math.max(...state.allNodes.map((node) => node.depth)));

      const tempLayerData: { [key: number]: number[] | undefined } = {};
      const nodes = state.allNodes;

      nodes.forEach((node) => {
        const currLayer = tempLayerData[node.depth];

        if (currLayer) {
          tempLayerData[node.depth] = [...currLayer, node.data.id];
        } else {
          tempLayerData[node.depth] = [node.data.id];
        }
      });

      setLayerData(tempLayerData);
      // update the phase cards to reflect the assigned layers
      handleChange("phasesData", formatPhaseData(phases, tempLayerData));
      setPageEditState(false);
    }

    setChartLoadState(true);
  }, [
    chart,
    chartState,
    chartWidth,
    extensibleData,
    chartLoaded,
    height,
    layerDepth,
    selectedLayers,
    layerData,
    selectionMode,
    phases,
    phaseData,
    setLayerData,
    setLayerDepth,
    setSelectedLayers,
    setPageEditState,
    handleChange,
    setChartLoadState,
  ]);

  return <div className={classes.container} ref={d3Container} />;
}

ApprovalsOrganizationChart.fragments = {
  employee: gql`
    fragment ApprovalsOrganizationChart_employee on Employee2 {
      id
      parentId: managerId
      displayName
      _directSubordinates: directReportsCount
      _totalSubordinates: totalReportsCount
      activeEmployment {
        id
        jobTitle
      }
    }
  `,
  compCyclePhase: gql`
    fragment ApprovalsOrganizationChart_compCyclePhase on CompCyclePhase2 {
      id
      phaseOrder
      startDate
      compCyclePhaseAssignments {
        id
        phaseId
        status
        assigneeId
      }
    }
  `,
};
