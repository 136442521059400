import { getMonthsElapsed } from "@asmbl/shared/time";
import { CashCompensation, getSalaryCashComp } from "./compensation";
import { CurrencyCode } from "./constants";
import { Currency, exchangeFromTo } from "./currency";
import { add, Money, zero } from "./money";
import { CompUnit } from "./types";

/* Return the number of full months elapsed since the date */
export function getTenure(employee: {
  activeAt: string | Date | null;
}): number | undefined {
  if (employee.activeAt === null) {
    return undefined;
  }
  return getMonthsElapsed(employee.activeAt);
}

export type CompValue = {
  annualCashEquivalent: Money;
  hourlyCashEquivalent: Money;
  unitType: CompUnit | null;
};

export function getNewPay(
  employee: {
    activeCashCompensation: CashCompensation[] | null;
    compRecommendation: { latestSubmittedPayIncrease: CompValue } | null;
  },
  currencies: Map<CurrencyCode, Currency>,
  defaultCurrency: Currency
): CompValue | undefined {
  const currentSalary = getSalaryCashComp(employee.activeCashCompensation);

  const { annualCashEquivalent, hourlyCashEquivalent } =
    employee.compRecommendation?.latestSubmittedPayIncrease ?? {};

  if (!currentSalary) return undefined;

  const salaryCurrency =
    currencies.get(currentSalary.annualCashEquivalent.currency) ??
    defaultCurrency;

  const salaryIncreaseCurrency =
    annualCashEquivalent?.currency != null
      ? currencies.get(annualCashEquivalent.currency) ?? salaryCurrency
      : salaryCurrency;

  const convertedAnnualIncrease = annualCashEquivalent
    ? exchangeFromTo(
        annualCashEquivalent,
        salaryIncreaseCurrency,
        salaryCurrency
      )
    : zero(salaryCurrency.code);

  const convertedHourlyIncrease = hourlyCashEquivalent
    ? exchangeFromTo(
        hourlyCashEquivalent,
        salaryIncreaseCurrency,
        salaryCurrency
      )
    : zero(salaryCurrency.code);

  return {
    annualCashEquivalent: add(
      convertedAnnualIncrease,
      currentSalary.annualCashEquivalent
    ),
    hourlyCashEquivalent: add(
      convertedHourlyIncrease,
      currentSalary.hourlyCashEquivalent
    ),
    unitType: currentSalary.unit as CompUnit | null,
  };
}
