import { AssembleTypography } from "src/components/AssembleTypography";
import { MONOSPACED_FONT } from "../../../../theme";
import { StyledBlockButton } from "../StyledBlockButton/StyledBlockButton";

const MONO_STYLE = "CODE";

export type MonoStyleProps = {
  editorState: Draft.EditorState;
  onClick: () => unknown;
};

export const MonoStyle = (props: MonoStyleProps): JSX.Element => {
  const { editorState, onClick } = props;
  const active = editorState.getCurrentInlineStyle().has(MONO_STYLE);

  return (
    <StyledBlockButton active={active} onClick={onClick}>
      <AssembleTypography
        style={{
          fontSize: "18px",
          fontWeight: "lighter",
          fontFamily: MONOSPACED_FONT,
        }}
      >
        Mono
      </AssembleTypography>
    </StyledBlockButton>
  );
};
