/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/*
  The above is required because Recharts types aren't being picked up
  by the linter
*/

import { gql } from "@apollo/client";
import { Currency } from "@asmbl/shared/currency";
import { formatCurrency } from "@asmbl/shared/money";
import { Box, makeStyles } from "@material-ui/core";
import { useMemo, useState } from "react";
import {
  ComposedChart,
  Customized,
  Line,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { BandVisualization2_position } from "src/__generated__/graphql";
import {
  annualizeMarketData,
  convertMarketDataValues,
} from "src/models/MarketDataSet";
import { MARKET_DATA_COMP_TYPE_MAPPING } from "src/pages/Compare/Bands/CompareTable";
import {
  GetLadders_marketDataSet,
  SELECTED_COMP_TYPE_LABELS,
  SelectedCompType,
} from "src/pages/Compare/Bands/SelectedLaddersLoader";
import {
  CARTA_THEME,
  CULPEPPER_THEME,
  DV_GREEN,
  ERI_THEME,
  GRAY_4,
  GRAY_6,
  MERCER_THEME,
  PAVE_THEME,
  RADFORD_THEME,
  UNSPECIFIED_THEME,
  WHITE,
  WTW_THEME,
} from "src/theme";
import {
  ADJUSTED_CASH_BAND_FIELDS,
  ADJUSTED_EQUITY_BAND_FIELDS,
} from "../../fragments";
import {
  computeSeries,
  totalCompBand,
  totalEquityBand,
} from "../../models/Band";
import { useExcludedBandNames } from "../../pages/LadderDetail/ExcludedBandNameContext";
import { Range } from "../../utils";
import { useCurrencies } from "../CurrenciesContext";
import { CompensationEmptyWarning } from "../EmptyState/CompensationEmptyWarning";
import { BandVisualizationTooltip } from "./BandVisualizationTooltip";
import { MarketDataComparisonTooltip } from "./MarketDataComparisonTooltip";

const providerColorMapping = {
  Radford: RADFORD_THEME,
  "OptionImpact/Pave": PAVE_THEME,
  Carta: CARTA_THEME,
  "Economic Research Institute": ERI_THEME,
  Mercer: MERCER_THEME,
  Culpepper: CULPEPPER_THEME,
  "Willis Towers Watson": WTW_THEME,
} as const;

const useStyles = makeStyles((theme) => ({
  simpleTooltip: {
    padding: theme.spacing(1),
    backgroundColor: WHITE,
  },
}));

export interface BandVisualizationProps {
  position: BandVisualization2_position;
  totalCompRange: Range;
  marketDataRange: Range;
  selectedCurrency: Currency;
  height?: number;
  color?: string;
  marketDataSets: GetLadders_marketDataSet[];
  selectedCompType: SelectedCompType;
  isBottomOfPage: boolean;
}

export default function BandVisualization({
  position,
  totalCompRange,
  marketDataRange,
  height,
  selectedCurrency,
  marketDataSets,
  selectedCompType,
  color,
  isBottomOfPage,
}: BandVisualizationProps): JSX.Element {
  const classes = useStyles();
  const { currencies } = useCurrencies();
  let convertedMarketDataSets = convertMarketDataValues(
    marketDataSets,
    selectedCurrency,
    currencies
  );

  if (selectedCompType === "Annual Grant Value") {
    convertedMarketDataSets = annualizeMarketData(convertedMarketDataSets);
  }

  const [selectedPercentile, setSelectedPercentile] = useState<number | null>(
    null
  );
  const { excludedBandNames } = useExcludedBandNames();

  const hasCashBands = (position.adjustedCashBands?.length ?? 0) > 0;

  const min = Math.min(totalCompRange.min, marketDataRange.min ?? Infinity);
  const max = Math.max(totalCompRange.max, marketDataRange.max ?? -Infinity);

  const totalCompSegment = (max - min) / 50;

  const band =
    selectedCompType === SELECTED_COMP_TYPE_LABELS.TOTAL_GRANT_VALUE
      ? totalEquityBand(position)
      : totalCompBand(position, excludedBandNames);

  const series = computeSeries(band, totalCompSegment).map((point) => ({
    compBandPoint: point,
    y: 0,
  }));

  const marketDataCompType = MARKET_DATA_COMP_TYPE_MAPPING[selectedCompType];

  const marketDataPoints = useMemo(() => {
    if (!convertedMarketDataSets.length) return;

    const yValues = calculateYValues(convertedMarketDataSets.length);

    return convertedMarketDataSets.flatMap((marketDataSet, index) => {
      const marketDataSamples = marketDataSet.marketDataSamples
        .filter((s) => position.jobCodes.includes(s.jobCode))
        .filter((s) =>
          s.marketDataPoints.some((p) => p.value !== null && p.value > 0)
        )
        .find((s) => s.compType === marketDataCompType);

      if (!marketDataSamples) return [];

      const dataKey = `marketDataSet-${marketDataSet.id}`;

      return marketDataSamples.marketDataPoints.map((point) => ({
        dataKey,
        xAxisId: marketDataSet.id,
        [dataKey]: point.value,
        percentile: point.percentile,
        color:
          providerColorMapping[
            marketDataSet.provider as keyof typeof providerColorMapping
          ] ?? UNSPECIFIED_THEME,
        y: yValues[index],
      }));
    });
  }, [convertedMarketDataSets, position.jobCodes, marketDataCompType]);

  const interval = computeInterval(totalCompSegment);

  const domain = useMemo(() => {
    return [
      (Math.floor(min / interval) - 3) * interval,
      (Math.ceil(max / interval) + 3) * interval,
    ];
  }, [min, interval, max]);

  const dashedReferenceLines = useMemo(
    () =>
      new Array(Math.ceil((domain[1] - domain[0]) / (interval * 10)))
        .fill(0)
        .map((_, i) => domain[0] + i * interval * 10)
        .slice(1),
    [domain, interval]
  );

  const referenceLines = useMemo(
    () =>
      new Array(Math.ceil((domain[1] - domain[0]) / interval))
        .fill(0)
        .map((_, i) => domain[0] + i * interval)
        .filter((x) => !dashedReferenceLines.includes(x)),
    [dashedReferenceLines, domain, interval]
  );

  if (!hasCashBands) {
    return (
      <Box position="relative" height={height}>
        <CompensationEmptyWarning
          noAccess={
            position.adjustedCashBands === null &&
            position.adjustedEquityBands === null
          }
        />
      </Box>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={height} debounce={300}>
      <ComposedChart
        data={[...series, ...(marketDataPoints ?? [])]}
        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
      >
        {dashedReferenceLines.map((x, i) => (
          <ReferenceLine
            key={`dashed-${i}`}
            strokeDasharray="3 3"
            strokeWidth={1}
            stroke={GRAY_4}
            segment={[
              {
                x,
                y: -1,
              },
              {
                x,
                y: 1,
              },
            ]}
          />
        ))}

        {referenceLines.map((x, i) => (
          <ReferenceLine
            key={`solid-${i}`}
            strokeWidth={0.5}
            stroke={GRAY_6}
            segment={[
              {
                x,
                y: -1,
              },
              {
                x,
                y: 1,
              },
            ]}
          />
        ))}

        <XAxis
          type="number"
          dataKey="compBandPoint"
          xAxisId={0}
          domain={domain}
          hide
        />

        {marketDataPoints?.map((m, i) => {
          return (
            <XAxis
              key={`xAxis-${i}`}
              type="number"
              dataKey={m.dataKey}
              xAxisId={m.xAxisId}
              domain={domain}
              hide
            />
          );
        })}

        <YAxis dataKey={"y"} domain={[-1, 1]} hide />

        <Tooltip
          wrapperStyle={{
            zIndex: 1000,
            boxShadow: "0px 15px 25px 0px rgba(10, 36, 64, 0.08)",
            borderRadius: 4,
          }}
          animationDuration={300}
          allowEscapeViewBox={{ x: false, y: false }}
          content={() => {
            const firstPoint = series[0];
            const lastPoint = series[series.length - 1];

            const simpleTooltipText = `${formatCurrency({
              value: firstPoint.compBandPoint,
              currency: selectedCurrency.code,
            })} - ${formatCurrency({
              value: lastPoint.compBandPoint,
              currency: selectedCurrency.code,
            })}`;

            return selectedPercentile != null ? (
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <MarketDataComparisonTooltip
                  position={position}
                  marketDataSets={convertedMarketDataSets}
                  percentile={selectedPercentile}
                  compType={marketDataCompType}
                  color={color ?? DV_GREEN}
                  totalCompRange={{
                    min: firstPoint.compBandPoint,
                    max: lastPoint.compBandPoint,
                  }}
                />
              </div>
            ) : simpleTooltipText ? (
              <div className={classes.simpleTooltip}>{simpleTooltipText}</div>
            ) : null;
          }}
          position={
            selectedPercentile == null
              ? undefined
              : { y: isBottomOfPage ? -200 : undefined }
          }
        />

        <Line
          type="monotone"
          dataKey="y"
          strokeWidth={0}
          dot={false}
          activeDot={false}
        />

        <Customized
          component={(props: any) => {
            const item = props?.formattedGraphicalItems?.[0] as unknown as {
              props: {
                points: { x: number; y: number }[];
              };
            };

            const points = item.props.points.filter((point) => point.x);

            if (!points.length) {
              return null;
            }

            const x = points[0].x;
            const width = item.props.points[points.length - 1].x - x;
            const height = 30;
            const y = points[0].y - height / 2;

            const dotX = width / 2 + x;
            const dotY = points[0].y;

            return (
              <>
                <Rectangle
                  width={width}
                  height={height}
                  x={x}
                  y={y}
                  fill={convertedMarketDataSets.length ? "#DAEAF9" : color}
                  radius={2}
                  z={0}
                />
                {convertedMarketDataSets.length && (
                  <MidpointDot x={dotX} y={dotY} />
                )}
              </>
            );
          }}
        />

        {marketDataPoints?.map((m) => {
          return (
            <Line
              key={m.dataKey}
              dataKey="y"
              xAxisId={m.xAxisId}
              stroke={m.color}
              strokeWidth={1.5}
              animationDuration={500}
              dot={(props: any) => {
                if (
                  props.cx === null ||
                  props.cy === null ||
                  props.payload.dataKey !== m.dataKey
                ) {
                  return <></>;
                }
                return (
                  <circle
                    cx={props.cx}
                    cy={props.cy}
                    r={3}
                    fill={m.color}
                    color={m.color}
                    cursor="pointer"
                    onMouseEnter={() => {
                      const {
                        payload: { percentile },
                      } = props;
                      setSelectedPercentile(percentile as number);
                    }}
                    onMouseLeave={() => {
                      setSelectedPercentile(null);
                    }}
                    stroke="transparent"
                    strokeWidth="13px"
                  />
                );
              }}
            />
          );
        })}

        <Customized
          component={(props: any) => {
            const items = props?.formattedGraphicalItems?.filter(
              (item: any) =>
                item.props.points.filter((point: any) => point.x !== null)
                  .length === 4
            );

            return (
              <>
                {items.map((item: any) => {
                  const points = item.props.points
                    .filter((point: any) => point.x)
                    // This is necessary for when points are out of order
                    // i.e. item.prop.points[0] is not visually the first
                    // point on the line
                    .sort((a: { x: number }, b: { x: number }) => a.x - b.x);

                  if (points.length === 0) {
                    return null;
                  }

                  const firstPoint = points[0];
                  const lastPoint = points[points.length - 1];

                  return (
                    <g key={item.childIndex}>
                      <text
                        x={firstPoint.x}
                        y={firstPoint.y}
                        dx={-20}
                        dy={4}
                        fontSize={12}
                        fontWeight={700}
                        fill={item.item.props.stroke ?? "black"}
                      >
                        <tspan textAnchor="start">
                          {firstPoint.payload.percentile}
                        </tspan>
                      </text>
                      <text
                        x={lastPoint.x}
                        y={lastPoint.y}
                        dy={4}
                        dx={6}
                        fontSize={12}
                        fontWeight={700}
                        fill={item.item.props.stroke ?? "black"}
                      >
                        <tspan textAnchor="start">
                          {lastPoint.payload.percentile}
                        </tspan>
                      </text>
                    </g>
                  );
                })}
              </>
            );
          }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

BandVisualization.fragments = {
  position: gql`
    ${BandVisualizationTooltip.fragments.position}
    ${MarketDataComparisonTooltip.fragments.position}
    ${ADJUSTED_CASH_BAND_FIELDS}
    ${ADJUSTED_EQUITY_BAND_FIELDS}
    fragment BandVisualization2_position on Position {
      ...BandVisualizationTooltip_position
      ...MarketDataComparisonTooltip_position
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
      }
      adjustedEquityBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
      }
    }
  `,
};

function MidpointDot({ x, y }: any) {
  return (
    <>
      <g fill="white" filter="url(#filter0_d_597_31281)">
        <circle cx={x} cy={y} r="3.5" fill="white" />
        <circle cx={x} cy={y} r="4" stroke="#88A4BE" />
      </g>
      <defs>
        <filter
          id="filter0_d_597_31281"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0392157 0 0 0 0 0.141176 0 0 0 0 0.25098 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_597_31281"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_597_31281"
            result="shape"
          />
        </filter>
      </defs>
    </>
  );
}

function calculateYValues(numLines: number) {
  let yValues = [];

  if (numLines === 1) {
    yValues = [0];
  } else {
    // ln(16) ~= 2.77
    const closeness = Math.log(numLines) / 2.5;
    const step = closeness / (numLines - 1 || 1);

    yValues = Array.from(
      { length: numLines },
      (_, index) => index * step - closeness / 2
    ).reverse();
  }

  return yValues;
}

export function computeInterval(difference: number) {
  const log10 = Math.log10(difference);
  const base = Math.pow(10, Math.floor(log10));
  const remainder = log10 - Math.floor(log10);

  let multiplier;
  if (remainder < Math.log10(1.5)) {
    multiplier = 1;
  } else if (remainder < Math.log10(3.5)) {
    multiplier = 2.5;
  } else {
    multiplier = 5;
  }

  const interval = base * multiplier;

  // We divide by this number quite often. If it happens to be 0,
  // this causes math errors
  return interval === 0 ? 1 : interval;
}
