import { makeStyles, Tabs, TabsProps } from "@material-ui/core";
import { ChangeEvent, ReactNode } from "react";
import { BLUE_3 } from "../../theme";

type AssembleTabsProps<T> = Omit<TabsProps, "value" | "onChange"> & {
  value: T;
  onChange: (_: ChangeEvent<unknown>, newValue: T) => unknown;
  children: ReactNode;
};

const useStyles = makeStyles(() => ({
  flexContainer: {
    "& > *": {
      flexBasis: "10%",
      flexGrow: 1,
    },
  },
  tabIndicator: { backgroundColor: BLUE_3 },
}));

export function AssembleTabs<T>({
  value,
  onChange,
  children,
  ...rest
}: AssembleTabsProps<T>): JSX.Element {
  const classes = useStyles();

  return (
    <Tabs
      {...rest}
      variant="fullWidth"
      TabIndicatorProps={{ className: classes.tabIndicator }}
      classes={{ flexContainer: classes.flexContainer }}
      onChange={onChange}
      value={value}
    >
      {children}
    </Tabs>
  );
}
