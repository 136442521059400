import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { BioHeadingBanner_equityHoldings as EquityHoldings } from "../../__generated__/graphql";
import { DataAccuracyBanner } from "./DataAccuracyBanner";
import { EquityWarningBanner } from "./EquityWarningBanner";

type Props = { equityHoldings?: EquityHoldings };

const useStyles = makeStyles(() => ({
  root: { width: "20.625rem" },
}));

export function BioHeadingBanner({ equityHoldings }: Props): JSX.Element {
  const classes = useStyles();

  const isEquityBannerShowingStorage = localStorage.getItem(
    "is-equity-banner-showing"
  );

  const isDataBannerShowingStorage = localStorage.getItem(
    "is-data-banner-showing"
  );

  const hasNoEquity = (equityHoldings?.grants?.length ?? 0) === 0;

  const equityVestEventsTotalUnitCount =
    equityHoldings?.vestingInformation?.vestEvents
      .map(({ unitCount }) => unitCount)
      .reduce((a, b) => a + b, 0);

  const grantTotalUnitCount = equityHoldings?.totalUnitCount ?? 0;

  const hasIncompleteVestingData =
    equityVestEventsTotalUnitCount !== grantTotalUnitCount;

  const [isEquityBannerShowing, setIsEquityBannerShowing] = useState(
    isEquityBannerShowingStorage != null
      ? isEquityBannerShowingStorage === "true"
      : true
  );

  const closeEquityBanner = () => {
    setIsEquityBannerShowing(false);
    localStorage.setItem("is-equity-banner-showing", "false");
  };

  const [isDataBannerShowing, setIsDataBannerShowing] = useState(
    isDataBannerShowingStorage != null
      ? isDataBannerShowingStorage === "true"
      : true
  );

  const closeDataBanner = () => {
    setIsDataBannerShowing(false);
    localStorage.setItem("is-data-banner-showing", "false");
  };

  return (
    <div className={classes.root}>
      {hasIncompleteVestingData && isEquityBannerShowing && (
        <EquityWarningBanner onClose={closeEquityBanner} />
      )}
      {hasNoEquity && isDataBannerShowing && (
        <DataAccuracyBanner onClose={closeDataBanner} />
      )}
    </div>
  );
}

BioHeadingBanner.fragments = {
  equityHoldings: gql`
    fragment BioHeadingBanner_equityHoldings on EquityHoldings {
      id
      totalUnitCount
      grants {
        id
      }
      vestingInformation {
        id
        vestEvents {
          id
          unitCount
        }
      }
    }
  `,
};
