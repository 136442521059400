import { exerciseCost, formatCurrency, Money } from "@asmbl/shared/money";
import { formatNumeral, isValidString } from "@asmbl/shared/utils";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { ApexOptions } from "apexcharts";
import { memo } from "react";
import ReactApexChart from "react-apexcharts";
import { getSimpleCashLabel } from "src/models/Currency";
import { hasStrikePrice } from "src/models/Offer";
import { NonNull } from "src/utils";
import { EquityGrantTypes, GetOffer } from "../../../__generated__/graphql";
import {
  APP_FONTS,
  GRAY_1,
  GRAY_3,
  GRAY_4,
  GRAY_6,
  WHITE,
} from "../../../theme";
import Legend from "../Legend";
import { LegendItem } from "../Legend/Legend";
import { OutcomeDescription } from "../OutcomeDescription";

const useStyles = makeStyles(() => ({
  caption: {
    color: GRAY_4,
    fontStyle: "italic",
    lineHeight: 1.3,
  },
  chart: {
    flex: 1,
  },
  disclaimer: {
    backgroundColor: WHITE,
    height: "120px",
    width: "100%",
  },
}));

//  ----------------------------------------------------------------------------
//  Types
//  ----------------------------------------------------------------------------
type CompStructure = NonNull<GetOffer["compStructure"]>;
type Valuation = NonNull<GetOffer["valuation"]>;

export type VisualizationProps = {
  projections: Money[];
  xAxisAnnotations?: string[][];
  maxProjected: Money;
  seriesData: SeriesDataPoint[];
  outcomeDescription?: string | null;
  legendItems: LegendItem[];
  equityGrantTypes: EquityGrantTypes[];
  compStructure: CompStructure;
  valuation: Valuation;
  units: number | undefined;
};

export type SeriesDataPoint = {
  name: string;
  type: "line" | "bar";
  color: string;
  data: number[];
};

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
const Visualization = ({
  projections,
  maxProjected,
  seriesData,
  xAxisAnnotations,
  outcomeDescription,
  legendItems,
  equityGrantTypes,
  compStructure,
  valuation,
  units,
}: VisualizationProps) => {
  const classes = useStyles();
  const enabledOnSeries = [seriesData.length - 1];
  const showValuation = compStructure.showValuation;
  const dilution = valuation.estimatedDilution;
  const showExerciseCost = compStructure.equityGrantTypes.every(hasStrikePrice);

  const isLineGraph = seriesData[0].type === "line";
  const maxAnnotationLines =
    xAxisAnnotations?.reduce((max, anno) => Math.max(max, anno.length), 0) ?? 0;

  const hideGrossEquityFootnote = equityGrantTypes[0] === EquityGrantTypes.RSU;

  const OPTIONS: { series: ApexAxisChartSeries; options: ApexOptions } = {
    series: seriesData,
    options: {
      dataLabels: {
        background: {
          borderRadius: 12,
          enabled: true,
          foreColor: GRAY_1,
          padding: 10,
          dropShadow: {
            blur: 14,
            color: "rgba(10, 36, 64, 0.08)",
            left: 0,
            top: 4,
            enabled: true,
            opacity: 1,
          },
        },
        enabled: true,
        enabledOnSeries: enabledOnSeries,
        formatter: function (
          value,
          opts: {
            dataPointIndex: number;
            w: { globals: { columnSeries?: number[][] } };
          }
        ) {
          const { dataPointIndex, w } = opts;
          let displayValue: number;
          if (w.globals.columnSeries) {
            let total = 0;
            for (let i = 0; i < w.globals.columnSeries.length; i++) {
              total += w.globals.columnSeries[i][dataPointIndex];
            }
            displayValue = total;
          } else {
            displayValue = Number(value);
          }
          return formatCurrency(
            {
              value: displayValue,
              currency: maxProjected.currency,
            },
            {
              notation: "compact",
              minimumSignificantDigits: 3,
              maximumSignificantDigits: 3,
            }
          );
        },
        offsetY: -16,
        style: {
          fontSize: "14px",
          fontWeight: "normal",
          colors: [WHITE],
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            hideOverflowingLabels: false,
          },
          borderRadius: 0,
        },
      },
      tooltip: { enabled: false },
      chart: {
        fontFamily: APP_FONTS,
        stacked: true,
        toolbar: { show: false },
        zoom: { enabled: false },
        animations: { enabled: false },
        parentHeightOffset: 0,
      },
      legend: { show: false },
      markers: { size: 8 },
      stroke: { width: 2, colors: ["#fff"] },
      xaxis: {
        axisBorder: { show: false },
        axisTicks: { show: false },
        offsetY: 0,
        categories: projections.map((projection, i) => {
          const formattedProjection = formatCurrency(projection, {
            notation: "compact",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
          if (!showValuation) {
            return xAxisAnnotations ? xAxisAnnotations[i] : "";
          }
          return xAxisAnnotations
            ? [formattedProjection, ...xAxisAnnotations[i]]
            : formattedProjection;
        }),
        labels: {
          rotate: 0,
          style: {
            colors: GRAY_3,
            fontSize: "12px",
          },
          hideOverlappingLabels: false,
          trim: true,
        },
      },
      yaxis: {
        min: 0,
        max: maxProjected.value * 1.4,
        labels: {
          minWidth: 56,
          align: "left",
          offsetX: -8,
          formatter: (value) =>
            formatCurrency(
              { value, currency: maxProjected.currency },
              {
                notation: "compact",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                minimumSignificantDigits: 1,
                maximumSignificantDigits: 2,
              }
            ),
          style: {
            colors: GRAY_3,
            fontSize: "14px",
          },
        },
        opposite: true,
      },
      grid: {
        borderColor: GRAY_6,
        xaxis: { lines: { show: true } },
        yaxis: { lines: { show: true } },
        padding: {
          left: isLineGraph ? 52 : 0,
          right: isLineGraph ? 52 : 0,
          bottom: maxAnnotationLines * 8,
        },
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
    },
  };

  return (
    <>
      <Box display="flex" flex={1} alignItems="center">
        {isValidString(outcomeDescription) && (
          <OutcomeDescription description={outcomeDescription} />
        )}
        <Box className={classes.chart}>
          <ReactApexChart
            options={OPTIONS.options}
            series={OPTIONS.series}
            height="570px"
          />
          <Legend items={legendItems} />
        </Box>
      </Box>
      <Box
        className={classes.disclaimer}
        bottom={16}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        left={0}
        position="absolute"
      >
        <Box display="flex" flexDirection="column" ml={12}>
          <Typography className={classes.caption} variant="caption">
            This exhibit is for illustrative purposes only. The Company does not
            make any guarantee, promise, or other assurance on any of the
            potential outcomes shown.
          </Typography>
          <Typography className={classes.caption} variant="caption">
            Figures shown are pre-tax.
          </Typography>
          <Typography className={classes.caption} variant="caption">
            Figures do not show any cash or equity increases that may be earned
            throughout your employment.
          </Typography>
          {!hideGrossEquityFootnote && (
            <Typography className={classes.caption} variant="caption">
              "Gross" means it excludes the cost of exercising your equity, if
              any cost is associated.{" "}
              {showExerciseCost && units !== undefined
                ? `Estimated cost to exercise (${formatCurrency(
                    valuation.strikePrice
                  )}/option): ${getSimpleCashLabel(
                    exerciseCost(units, valuation.strikePrice)
                  )}.`
                : ""}
            </Typography>
          )}
          <Typography className={classes.caption} variant="caption">
            Dilution is common for companies that issue additional shares when
            raising money. These outcomes assume {formatNumeral(dilution * 100)}
            % dilution to today's price.
          </Typography>
          <Typography className={classes.caption} variant="caption">
            Any dilution shown here is for illustrative purposes only.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default memo(Visualization);
