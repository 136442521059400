import { gql } from "@apollo/client";
import { Fab, makeStyles, Tooltip } from "@material-ui/core";
import { PlusCircleIcon } from "src/components/AssembleIcons/Brand/PlusCircleIcon";
import { AssembleTypography } from "../../../../components/AssembleTypography";
import { BLUE_2, GRAY_2 } from "../../../../theme";
import { CurrencyCode } from "../../../../__generated__/graphql";
import { EquityOutcomesFormPair } from "./EquityOutcomesFormPair";
import { ExitOutcomeWithAnnotation } from "./PortalConfig";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "120%",
  },
  equityOutcomes: {
    color: GRAY_2,
  },
  textContainer: {
    margin: theme.spacing(3, 0),
    color: GRAY_2,
  },
  addButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: theme.spacing(6),
    width: "100%",
  },
  buttonsContainer: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  saveButton: {
    height: "100%",
  },
}));

export const LABEL_CHARACTER_LIMIT = 30;

type Props = {
  outcomes: ExitOutcomeWithAnnotation[];
  defaultExitOutcome: number | null;
  valuationCurrency: CurrencyCode;
  onChangeOutcomes: (outcomes: ExitOutcomeWithAnnotation[]) => unknown;
  onChangeDefault: (defaultValue: number | null) => unknown;
  onFormChange: (changed: boolean) => unknown;
};

export const EquityOutcomesForm = ({
  outcomes,
  defaultExitOutcome,
  valuationCurrency,
  onChangeOutcomes,
  onChangeDefault,
  onFormChange,
}: Props): JSX.Element => {
  const classes = useStyles();

  const canAddField = outcomes.length < 8;

  const handleAddField = () => {
    if (canAddField) {
      onFormChange(false);
      onChangeOutcomes([
        ...outcomes,
        {
          key: Math.random(),
          value: 0,
          annotation: "",
          placeholder: true,
        },
      ]);
    }
  };

  return (
    <>
      <div className={classes.textContainer}>
        <AssembleTypography
          variant="productParagraphLarge"
          className={classes.equityOutcomes}
        >
          Show your employees what their equity could be for 3-8 different
          outcomes. If you leave this blank, employees will only see the value
          of equity based on your current company valuation.
        </AssembleTypography>
      </div>
      <div className={classes.formContainer}>
        {outcomes.map((outcome, i) => (
          <EquityOutcomesFormPair
            key={outcome.key}
            index={i}
            outcomes={outcomes}
            defaultExitOutcome={defaultExitOutcome}
            valuationCurrency={valuationCurrency}
            onChangeOutcomes={onChangeOutcomes}
            onChangeDefault={onChangeDefault}
            onFormChange={() => onFormChange(true)}
          />
        ))}
        {canAddField && (
          <div className={classes.addButtonContainer}>
            <Tooltip title="Add Outcome" placement="top">
              <Fab
                centerRipple={true}
                onClick={handleAddField}
                size="medium"
                color="primary"
              >
                <PlusCircleIcon color={BLUE_2} width="24px" height="24px" />
              </Fab>
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );
};

EquityOutcomesForm.fragments = {
  portalConfig: gql`
    ${EquityOutcomesFormPair.fragments.portalConfig}
    fragment EquityOutcomesForm_portalConfig on PortalConfig {
      id
      exitOutcomes
      defaultExitOutcome
      ...EquityOutcomesFormPair_portalConfig
    }
  `,
};
