import { gql } from "@apollo/client";
import { contramap } from "@asmbl/shared/sort";
import {
  Checkbox,
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { clsx } from "clsx";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { EyeOnIcon } from "src/components/AssembleIcons/Brand/EyeOnIcon";
import { useTrack } from "../../../../analytics";
import {
  DoubleSortableTableHeaderCell,
  SortableTableHeaderCell,
  useSort,
} from "../../../../components/SortableTable";
import { UserAvatar } from "../../../../components/UserAvatar";
import { searchStringsForMatch } from "../../../../models/Search";
import { GRAY_2, GRAY_6, PURPLE_2, WHITE } from "../../../../theme";
import { PortalTable_employee as Employee } from "../../../../__generated__/graphql";
import { LittleGiftButton } from "../Benefits/LittleGiftButton";
import { PortalTableButton } from "./Buttons/PortalTableButton";
import { PortalTableHeaderBar } from "./PortalTableHeaderBar";

const useStyles = makeStyles((theme: Theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  nameAndEmailContainer: {
    padding: theme.spacing(1, 2, 1, 0),
  },
  nameAndEmailText: {
    paddingLeft: theme.spacing(1.5),
    display: "flex",
    flexDirection: "column",
  },
  cellIcons: {
    height: "20px",
    width: "20px",
  },
  iconDivider: {
    marginLeft: theme.spacing(1),
    color: GRAY_6,
    width: "1px",
    height: "20px",
  },
  switchContainer: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  tableHeaderCell: {
    background: WHITE,
    fontTransform: "uppercase",
  },
  tableBodyCell: {
    padding: theme.spacing(1, 2),
  },
  tableRow: {
    height: `${theme.spacing(7)}px !important`,
    maxHeight: `${theme.spacing(7)}px !important`,
  },
  checkboxCell: {
    maxWidth: "50px",
  },
  managerCell: {
    display: "flex",
    justifyContent: "center",
  },
}));

type Props = {
  employees: Employee[];
  handleChange: (id: number) => unknown;
  handleIndeterminateChange: (employees: number[]) => unknown;
  selectedEmployees: Map<number, Employee & { selected: boolean }>;
};

export interface EmployeeRow {
  employee: Employee;
  user: Employee["user"];
  id: number;
  name: string;
  email: string;
  jobTitle?: string;
  location?: string;
  level?: number;
  manager?: string;
}

export const PortalTable = ({
  employees,
  handleChange,
  handleIndeterminateChange,
  selectedEmployees,
}: Props) => {
  const classes = useStyles();
  const { trackEvent } = useTrack();

  const employeeRows: EmployeeRow[] = useMemo(() => {
    return createEmployeeRows(employees);
  }, [employees]);

  const {
    sortedArray: sortedRows,
    order,
    orderBy,
    handleRequestSort,
  } = useSort<EmployeeRow>(employeeRows, "name", "asc", {
    location: contramap((e) => e.location ?? "-"),
    jobTitle: contramap((e) => e.jobTitle ?? "-"),
    manager: contramap((e) => e.manager ?? "-"),
  });

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [searchInputValue, setSearchInputValue] = useState("");

  const handleSearchInputChange = (value: string | null) => {
    if (value !== null) {
      setSearchInputValue(value);
    } else {
      setSearchInputValue("");
    }
    setPage(0);
  };

  const handlePortalPreviewClick = (employeeId: number) => {
    trackEvent({
      area: "Employee Portal",
      subArea: "Portal Access",
      object: "Portal Preview Button",
      action: "Clicked",
      employeeId,
    });
  };

  const matchingRows: EmployeeRow[] = useMemo(() => {
    return searchInputValue.length === 0
      ? sortedRows
      : sortedRows.filter(
          ({ name, jobTitle }) =>
            searchStringsForMatch(name, searchInputValue) ||
            (jobTitle !== undefined &&
              searchStringsForMatch(jobTitle, searchInputValue))
        );
  }, [sortedRows, searchInputValue]);

  const pagedRows = useMemo(
    () => matchingRows.slice(pageSize * page, pageSize * (page + 1)),
    [page, pageSize, matchingRows]
  );

  const onIndeterminateChange = () => {
    const employeeIds = pagedRows.map((emp) => emp.employee.id);
    handleIndeterminateChange(employeeIds);
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      <PortalTableHeaderBar
        searchTerm={searchInputValue}
        onSearchChange={handleSearchInputChange}
        page={page}
        onPageChange={setPage}
        pageSize={pageSize}
        onSetPageSize={setPageSize}
        rowCount={matchingRows.length}
      />
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              className={clsx(classes.tableHeaderCell, classes.checkboxCell)}
              width="5%"
            >
              <Checkbox
                checked={[...selectedEmployees.values()].every(
                  (emp) => emp.selected
                )}
                indeterminate={
                  [...selectedEmployees.values()].some((emp) => emp.selected) &&
                  ![...selectedEmployees.values()].every((emp) => emp.selected)
                }
                onChange={onIndeterminateChange}
              />
            </TableCell>
            <DoubleSortableTableHeaderCell
              padding="none"
              titleA="Name"
              titleB="Email"
              orderByFieldA="name"
              orderByFieldB="email"
              align="left"
              aria-label="Name / Position"
              order={order}
              isSelectedA={orderBy === "name"}
              isSelectedB={orderBy === "email"}
              handleRequestSort={handleRequestSort}
              className={classes.tableHeaderCell}
              width="25%"
            />
            <SortableTableHeaderCell
              cellTitle="Job Title"
              orderByField="jobTitle"
              order={order}
              isSelected={orderBy === "jobTitle"}
              handleRequestSort={handleRequestSort}
              align="left"
              aria-label="Job Title"
              className={classes.tableHeaderCell}
              width="20%"
            />
            <SortableTableHeaderCell
              cellTitle="Manager"
              orderByField="manager"
              order={order}
              isSelected={orderBy === "manager"}
              handleRequestSort={handleRequestSort}
              align="center"
              aria-label="Manager"
              className={classes.tableHeaderCell}
              noWrap
              width="10%"
            />
            <SortableTableHeaderCell
              cellTitle="Location"
              orderByField="location"
              order={order}
              isSelected={orderBy === "location"}
              handleRequestSort={handleRequestSort}
              align="left"
              aria-label="Location"
              className={classes.tableHeaderCell}
              noWrap
              width="20%"
            />
            <TableCell className={classes.tableHeaderCell} width="20%">
              <Typography variant="subtitle2">
                Benefits / Portal Access
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pagedRows.map((employee) => {
            const selectedEmp = selectedEmployees.get(employee.id);
            return (
              <TableRow key={employee.id} className={classes.tableRow}>
                <TableCell
                  className={`${classes.tableBodyCell} ${classes.checkboxCell}`}
                >
                  <Checkbox
                    checked={selectedEmp ? selectedEmp.selected : false}
                    onChange={() => handleChange(employee.id)}
                  />
                </TableCell>
                <TableCell className={classes.nameAndEmailContainer}>
                  <div className={classes.flexContainer}>
                    <UserAvatar
                      displayName={employee.name}
                      photoURL={employee.user?.photoURL}
                    />
                    <div className={classes.nameAndEmailText}>
                      <div>{employee.name}</div>
                      <div>{employee.email}</div>
                    </div>
                  </div>
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {employee.jobTitle}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  <div className={classes.managerCell}>
                    <UserAvatar
                      displayName={employee.manager}
                      photoURL={employee.employee.manager?.user?.photoURL}
                      showTooltip
                    />
                  </div>
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {employee.location}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  <div className={classes.flexContainer}>
                    <LittleGiftButton employee={employee.employee} />
                    <Divider
                      orientation="vertical"
                      className={classes.iconDivider}
                    />
                    <div className={classes.switchContainer}>
                      <Tooltip
                        title="Preview this employee's portal in a new tab"
                        placement="top"
                      >
                        <IconButton
                          component={Link}
                          to={`/portal/${employee.employee.id}`}
                          onClick={() =>
                            handlePortalPreviewClick(employee.employee.id)
                          }
                          target="_blank"
                        >
                          <EyeOnIcon
                            color={GRAY_2}
                            hoverColor={PURPLE_2}
                            width="20px"
                            height="20px"
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <PortalTableButton employee={employee.employee} />
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PortalTable.fragments = {
  employee: gql`
    ${LittleGiftButton.fragments.employee}
    ${PortalTableButton.fragments.employee}
    fragment PortalTable_employee on Employee {
      ...LittleGiftButton_employee
      ...PortalTableButton_employee
      id
      displayName
      manager: minimalManager {
        id
        displayName
        user {
          id
          photoURL
        }
      }
      email
      activeEmployment {
        id
        jobTitle
      }
      homeLocation {
        id
        city
        state
        country
      }
      user {
        id
        photoURL
      }
    }
  `,
};

function createEmployeeRows(employees: Employee[]): EmployeeRow[] {
  return employees.map((emp) => ({
    employee: emp,
    id: emp.id,
    user: emp.user,
    name: emp.displayName,
    email: emp.email,
    jobTitle: emp.activeEmployment?.jobTitle ?? undefined,
    manager: emp.manager?.displayName,
    location:
      emp.homeLocation?.city.concat(
        `, ${
          emp.homeLocation.state.length > 0
            ? emp.homeLocation.state
            : emp.homeLocation.country
        }`
      ) ?? undefined,
  }));
}
