import { Box, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { DeleteIcon } from "src/components/AssembleIcons/Brand/DeleteIcon";
import { WarningAlertIcon } from "src/components/AssembleIcons/Brand/WarningAlertIcon";
import { CustomFieldStates } from "../../../models/Offer";
import { GRAY_4, PURPLE_2, RED } from "../../../theme";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    position: "absolute",
    height: "100%",
    top: 0,
    right: -theme.spacing(7),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  sidebarSpacing: {
    height: theme.spacing(6),
  },
}));

type Props = {
  onDelete: () => void;
  state: CustomFieldStates;
  deleteStyle?: string;
};

export function CustomFieldSidebar({
  onDelete,
  state,
  deleteStyle,
}: Props): JSX.Element {
  const classes = useStyles();
  const errorMessage =
    state === "NO_NAME"
      ? "Every custom field must have a name"
      : state === "SAME_NAME"
        ? "Custom fields must have unique names"
        : state === "NO_OPTIONS"
          ? "Every Select field must have at least one option"
          : "";
  return (
    <Box className={classes.sidebar}>
      <Box className={deleteStyle}>
        <Tooltip title="Delete Field" placement="top">
          <IconButton size="small" onClick={onDelete}>
            <DeleteIcon
              color={GRAY_4}
              hoverColor={PURPLE_2}
              height="24px"
              width="24px"
            />
          </IconButton>
        </Tooltip>
      </Box>
      {state !== "VALID" && (
        <Tooltip title={errorMessage} placement="top">
          <WarningAlertIcon color={RED} height="24px" width="24px" />
        </Tooltip>
      )}
      <div className={classes.sidebarSpacing} />
    </Box>
  );
}
