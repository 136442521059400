import { Input, makeStyles, TableCell } from "@material-ui/core";
import clsx from "clsx";
import { useRef } from "react";
import { MatrixEditButton } from "./MatrixEditButton";
import { CellLocation } from "./MeritAndBudgetMatrices";
import { useTableStyles } from "./tableStyles";

type Props = {
  cells: { label: string | number; id?: number }[][];
  onChange: (id: number, value: number) => void;
  onUpdatePerfRatingOption: (rowIdx: number, newName: string) => void;
  onDeletePerfRatingOption: (rowIdx: number) => void;
  matrixType: "budget" | "merit";
  setHoveredCell: ({ x, y, table }: CellLocation) => void;
  hoveredCell: CellLocation;
  focusedCell: CellLocation;
  setFocusedCell: ({ x, y, table }: CellLocation) => void;
  cellIdx: number;
  rowIdx: number;
  cellRowLength: number;
  cell: { label: string | number; id?: number };
};

const useStyles = makeStyles((theme) => ({
  editableCell: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
  },
  inputWithoutStepper: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  justifyLeft: {
    justifyContent: "flex-start",
  },
}));

export function FocusableInputCell({
  cells,
  onChange,
  onUpdatePerfRatingOption,
  onDeletePerfRatingOption,
  matrixType,
  setHoveredCell,
  hoveredCell,
  focusedCell,
  setFocusedCell,
  cellIdx,
  rowIdx,
  cellRowLength,
  cell,
}: Props) {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const inputRef = useRef(null);

  const handleCellFocus = () => {
    (inputRef.current as HTMLElement | null)?.focus();
  };

  return (
    <TableCell
      onMouseEnter={() =>
        setHoveredCell({ x: cellIdx, y: rowIdx, table: matrixType })
      }
      align={cellIdx === 0 ? "left" : "right"}
      className={clsx(
        [
          tableClasses.cell,
          tableClasses.muiCellOverride,
          tableClasses.cursorText,
        ],
        {
          [tableClasses.roundTopRight]:
            rowIdx === 0 && cellIdx === cellRowLength - 1,
          [tableClasses.roundBottomLeft]:
            rowIdx === cells.length - 1 && cellIdx === 0,
          [tableClasses.roundBottomRight]:
            rowIdx === cells.length - 1 && cellIdx === cellRowLength - 1,
          [tableClasses.withLeftBorder]: cellIdx === 0,
          [tableClasses.withTopBorder]: rowIdx === 0,
          [tableClasses.correspondingHovered]:
            hoveredCell.x === cellIdx && hoveredCell.y === rowIdx,
          [tableClasses.hovered]:
            hoveredCell.x === cellIdx &&
            hoveredCell.y === rowIdx &&
            hoveredCell.table === matrixType,
          [tableClasses.correspondingFocused]:
            focusedCell.x === cellIdx && focusedCell.y === rowIdx,
          [tableClasses.focused]:
            focusedCell.x === cellIdx &&
            focusedCell.y === rowIdx &&
            focusedCell.table === matrixType,
        }
      )}
      key={`cell-${rowIdx}-${cellIdx}`}
      onClick={handleCellFocus}
    >
      {cellIdx === 0 ? (
        <span className={clsx(classes.editableCell, classes.justifyLeft)}>
          {hoveredCell.y === rowIdx && (
            <MatrixEditButton
              position="left"
              actionType="remove"
              onClick={() => {
                onDeletePerfRatingOption(rowIdx);
              }}
            />
          )}
          <Input
            className={classes.inputWithoutStepper}
            defaultValue={cell.label}
            disableUnderline
            inputProps={{
              onFocus: () =>
                setFocusedCell({
                  x: cellIdx,
                  y: rowIdx,
                  table: matrixType,
                }),
            }}
            inputRef={inputRef}
            onBlur={(e) => {
              setFocusedCell({ x: null, y: null, table: null });
              if (cell.id != null && e.target.value !== cell.label) {
                onUpdatePerfRatingOption(rowIdx, e.target.value);
              }
            }}
            placeholder={
              cells.length === 1
                ? "All Performance Ratings"
                : "Performance Rating Name"
            }
            onFocus={(e) => e.target.select()}
            fullWidth
          />
        </span>
      ) : (
        <span className={classes.editableCell}>
          <Input
            className={classes.inputWithoutStepper}
            defaultValue={cell.label}
            disableUnderline
            inputProps={{
              onFocus: () =>
                setFocusedCell({
                  x: cellIdx,
                  y: rowIdx,
                  table: matrixType,
                }),
              style: { textAlign: "right", maxWidth: "5rem" },
            }}
            inputRef={inputRef}
            endAdornment="%"
            type="number"
            onBlur={(e) => {
              setFocusedCell({ x: null, y: null, table: null });
              if (cell.id != null && e.target.value !== cell.label) {
                const newPercent = parseFloat(e.target.value) / 100;
                onChange(cell.id, isNaN(newPercent) ? 0 : newPercent);
              }
            }}
            onFocus={(e) => e.target.select()}
          />
        </span>
      )}
    </TableCell>
  );
}
