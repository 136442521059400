import { gql } from "@apollo/client";
import { Noun } from "@asmbl/shared/permissions";
import {
  FormControlLabel,
  FormGroup,
  makeStyles,
  Switch,
} from "@material-ui/core";
import { AssembleTypography } from "../../../components/AssembleTypography";
import { useAuth } from "../../../components/Auth/AuthContext";
import { PURPLE_1, WHITE } from "../../../theme";
import {
  AnonymizedCompensationStatisticsAccessType,
  AnonymizedCompensationSwitch_permissionSettings as OrganizationPermissionSettings,
} from "../../../__generated__/graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(1),
  },
  switchRoot: {
    width: "28px",
    height: "16px",
  },
  switchThumb: {
    boxSizing: "border-box",
    width: "14px",
    height: "14px",
  },
  switchBase: {
    padding: 1,
    "&$switchChecked": {
      color: WHITE,
      transform: "translateX(12px)",
      "& + $switchTrack": {
        backgroundColor: `${PURPLE_1} !important`,
        opacity: 1,
        border: "none",
      },
    },
  },
  switchTrack: {
    width: "28px",
  },
  switchChecked: {},
}));

type Props = {
  anonymizedView: boolean;
  toggleAnonymizedView: () => void;
  organizationPermissionSettings: OrganizationPermissionSettings;
};

export function AnonymizedCompensationSwitch({
  anonymizedView,
  toggleAnonymizedView,
  organizationPermissionSettings,
}: Props): JSX.Element | null {
  const classes = useStyles();
  const { permissions } = useAuth();

  // if the feature is turned off for non-full access users
  if (
    organizationPermissionSettings.anonymizedCompensationStatisticsAccess ===
      AnonymizedCompensationStatisticsAccessType.FULL_ACCESS &&
    !permissions.canViewGlobal(Noun.CashBand)
  ) {
    return null;
  }

  return (
    <FormGroup>
      <FormControlLabel
        className={classes.root}
        control={
          <Switch
            classes={{
              root: classes.switchRoot,
              thumb: classes.switchThumb,
              switchBase: classes.switchBase,
              track: classes.switchTrack,
              checked: classes.switchChecked,
            }}
            checked={anonymizedView}
            onChange={toggleAnonymizedView}
          />
        }
        label={
          <AssembleTypography variant="productSmall">
            Show Employees
          </AssembleTypography>
        }
      />
    </FormGroup>
  );
}

AnonymizedCompensationSwitch.fragments = {
  permissionSettings: gql`
    fragment AnonymizedCompensationSwitch_permissionSettings on PermissionSettings {
      id
      anonymizedCompensationStatisticsAccess
    }
  `,
};
