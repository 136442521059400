import { ISettings } from "@flatfile/adapter";
import { DECIMAL_VALIDATOR, INTEGER_VALIDATOR } from "./Validators";

export const TargetCommissionFields: ISettings["fields"] = [
  {
    label: "Target Commission ($)",
    key: "targetCommission",
    validators: [
      {
        validate: "regex_matches",
        regex: INTEGER_VALIDATOR,
        error: "Must be a whole number",
      },
    ],
  },
  {
    label: "Target Commission (% of Salary)",
    key: "targetCommissionPercent",
    validators: [
      {
        validate: "regex_matches",
        regex: DECIMAL_VALIDATOR,
        error: "Must be a number",
      },
    ],
  },
  {
    label: "Target Commission Notes",
    key: "targetCommissionNotes",
  },
];
