import { CircularProgress, makeStyles, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { CheckIcon } from "src/components/AssembleIcons/Brand/CheckIcon";
import UnsavedChangesWarning from "../../../../components/UnsavedChangesWarning";
import { GRAY_4, GREEN_2 } from "../../../../theme";
import { OveragesSelector_budgetOverage as BudgetOverage } from "../../../../__generated__/graphql";
import { OveragesSelector } from "./OveragesSelector";

type Props = {
  pageEditCount: number;
  loading: boolean;
  compCycleId: number;
  overages: BudgetOverage[] | undefined;
};

const useStyles = makeStyles(() => ({
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export function SaveNotification({
  pageEditCount,
  loading,
  compCycleId,
  overages,
}: Props): JSX.Element {
  const [showTooltip, setShowTooltip] = useState(false);
  const [loadingAnimation, setLoadingAnimation] = useState(false);

  const pageEdited = pageEditCount > 0;

  /*
   * This hook is used to let the loading render animation show up for 500
   * miliseconds so the request can have more of a loading feel, instead of a
   * jitter between the checkmark and the loading animation.
   */
  React.useEffect(() => {
    if (loading) {
      setLoadingAnimation(true);
    } else {
      const timeout = setTimeout(() => {
        setLoadingAnimation(false);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [loading]);

  /*
   * This hook is to show the tooltip on the 'initial' draft auto-save, we don't
   * want to spam the user with the tooltip on each save (since each blur is a
   * save) so we only show it on the initial save.
   */
  React.useEffect(() => {
    if (pageEditCount === 1 && !loadingAnimation) {
      setShowTooltip(true);

      setTimeout(() => {
        setShowTooltip(false);
      }, 2_000);
    }
  }, [loadingAnimation, pageEditCount]);

  /*
   * This hook is to manage when the tooltip should be shown, since there are
   * two different states that dictate whether it should render or not.
   */
  React.useEffect(() => {
    if (pageEdited) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [pageEdited]);

  const classes = useStyles();

  return (
    <>
      <UnsavedChangesWarning pageEdited={pageEdited && loadingAnimation} />
      <div>
        {loadingAnimation ? (
          <div className={classes.iconContainer}>
            <CircularProgress size="1rem" />
          </div>
        ) : overages === undefined || overages.length === 0 ? (
          <div className={classes.iconContainer}>
            <Tooltip
              title="Changes saved but not published"
              placement="top"
              open={showTooltip}
            >
              <div>
                <CheckIcon
                  color={showTooltip ? GREEN_2 : GRAY_4}
                  hoverColor={GREEN_2}
                />
              </div>
            </Tooltip>
          </div>
        ) : (
          <div className={classes.iconContainer}>
            <OveragesSelector compCycleId={compCycleId} overages={overages} />
          </div>
        )}
      </div>
    </>
  );
}
