import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
  FetchCompCycleProgress,
  FetchCompCycleProgressVariables,
} from "src/__generated__/graphql";
import { ManagersTable } from "./ManagersTable";
import { ManagersTableHeader } from "./ManagersTableHeader";

type Props = {
  compCycleId: number;
};

export function ProgressPageLoadingBoundary({ compCycleId }: Props) {
  const { loading, data } = useQuery<
    FetchCompCycleProgress,
    FetchCompCycleProgressVariables
  >(ProgressPageLoadingBoundary.queries.fetchCompCycleProgress, {
    variables: { compCycleId },
  });

  const [showRollup, setShowRollup] = useState<boolean>(false);
  const [nameSearch, setNameSearch] = useState<string>("");
  const [completionFilter, setCompletionFilter] = useState<string | null>(
    "All"
  );
  const [filteredManagers, setFilteredManagers] = useState(
    data?.compCycle2?.reviewers ?? []
  );

  // the first index is reviews requested, second is completed,
  // third is whether or not they've been skipped
  const defaultStat = [0, 0, false];
  const reviewStats = data
    ? data.compCycle2?.participants.participants.reduce(
        (acc, curr) => {
          // count the employees that have had a review
          const managerId = curr.subject.managerId ?? "";
          const completedReviewCount: number =
            (acc[managerId] ?? defaultStat)[1] +
            (curr.compRecommendation?.latestSubmittedReviews.length ?? 0
              ? 1
              : 0);

          // check if the employee has received a skip phase review
          const hasSkip =
            (acc[managerId] ?? defaultStat)[2] ||
            ((curr.compRecommendation?.latestSubmittedReviews.length ?? 0) >
              0 &&
              curr.compRecommendation?.latestSubmittedReviews[0]?.authorId !==
                curr.subject.minimalManager?.user?.id);
          acc[managerId] = [
            (acc[managerId] ?? defaultStat)[0],
            completedReviewCount,
            hasSkip,
          ];
          return acc;
        },
        {} as {
          [key: string]: [number, number, boolean];
        }
      ) ?? {}
    : {};

  data?.compCycle2?.reviewers.forEach((p) => {
    // lookup the total number of reviews required for the manager
    if (p.id in reviewStats) {
      const requiredReviewCount =
        data.compCycle2?.phases.reduce((acc, curr) => {
          const assignment = curr.compCyclePhaseAssignments.find(
            (a) => a?.employee.id === p.id
          );
          return assignment
            ? acc + assignment.recommendees.length + assignment.reviewees.length
            : acc;
        }, 0) ?? 0;
      reviewStats[p.id] = [
        requiredReviewCount,
        reviewStats[p.id][1],
        reviewStats[p.id][2],
      ];
    }
  });

  useEffect(() => {
    if (data) {
      // Filter managers by name
      const searchTerm = nameSearch.toLowerCase().trim();
      const nameFiltered =
        data.compCycle2?.reviewers.filter((manager) => {
          const nameParts = manager.displayName.split(" ");
          return (
            manager.displayName.toLowerCase().startsWith(searchTerm) ||
            nameParts.some((part) => part.toLowerCase().startsWith(searchTerm))
          );
        }) ?? [];
      const filteredManagers = nameFiltered.filter((m) => {
        switch (completionFilter) {
          case "All":
            return true;
          case "Submitted":
            return reviewStats[m.id][1] === reviewStats[m.id][0];
          case "Incomplete":
            return reviewStats[m.id][1] !== reviewStats[m.id][0];
          case "Skipped":
            return reviewStats[m.id][2];
          default:
            return true;
        }
      });

      setFilteredManagers(filteredManagers);
    }
  }, [data, nameSearch, completionFilter]);

  return (
    <div>
      {loading || !data ? (
        <CircularProgress />
      ) : (
        <>
          <ManagersTableHeader
            setNameSearch={setNameSearch}
            setCompletionFilter={setCompletionFilter}
            completionFilter={completionFilter}
            setShowRollup={setShowRollup}
            showRollup={showRollup}
          />
          <ManagersTable
            phases={data.compCycle2?.phases ?? []}
            managers={filteredManagers ?? []}
            currentPhaseOrder={data.compCycle2?.currentPhase?.phaseOrder}
            showRollup={showRollup}
            reviewStats={reviewStats}
          />
        </>
      )}
    </div>
  );
}

ProgressPageLoadingBoundary.queries = {
  fetchCompCycleProgress: gql`
    ${ManagersTable.fragments.phases}
    ${ManagersTable.fragments.managers}
    query FetchCompCycleProgress($compCycleId: Int!) {
      compCycle2(id: $compCycleId) {
        id
        currentPhase {
          id
          phaseOrder
        }
        phases {
          ...ManagersTable_phase
        }
        reviewers {
          ...ManagersTable_manager
        }
        participants {
          participants {
            compCycleId
            subjectId
            compRecommendation {
              compCycleId
              subjectId
              reviewStatus
              latestSubmittedItems {
                recommendationType
              }
              latestSubmittedReviews {
                status
                authorId
              }
            }
            subject {
              id
              userId
              managerId
              minimalManager {
                user {
                  id
                }
              }
            }
          }
        }
      }
    }
  `,
};
