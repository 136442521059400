import { makeStyles, Theme, Typography } from "@material-ui/core";
import { ReactNode } from "react";
import { GRAY_4 } from "../theme";

interface Props {
  className?: string;
  message: ReactNode;
  svg?: ReactNode;
  width?: string;
}

const useStyles = makeStyles<Theme, { width?: string }>((theme: Theme) => ({
  description: {
    color: GRAY_4,
    marginTop: theme.spacing(4),
    width: ({ width }) => width ?? "100%",
    textAlign: "center",
  },
  view: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(8),
  },
}));

export function EmptyTableView({
  className = "",
  message,
  svg,
  width,
}: Props): JSX.Element {
  const classes = useStyles({ width });

  return (
    <div className={`${className} ${classes.view}`}>
      {svg}
      <Typography variant="body1" className={classes.description}>
        {message}
      </Typography>
    </div>
  );
}
