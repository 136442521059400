import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const InfoIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Info"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className="stroke" cx="8" cy="8" r="7.25" strokeWidth="1.5" />
      <path
        className="stroke"
        d="M7.5 7.5H8V11H8.50001"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="fill"
        d="M8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6Z"
      />
    </svg>
  </ColorableSVG>
);
