import {
  FormControl,
  Input,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

type Props = {
  options: readonly string[];
  selected: string;
  onChange(value: string): unknown;
};

const useStyles = makeStyles(() => ({
  select: {
    "&:focus": {
      backgroundColor: "unset",
    },
  },
}));

export function BorderlessSelect({
  options,
  selected,
  onChange,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <FormControl>
      <Select
        classes={{ select: classes.select }}
        value={selected}
        input={<Input disableUnderline />}
        onChange={(event) => onChange(event.target.value as string)}
        IconComponent={ExpandMore}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option} disableRipple>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
