import { makeStyles, Typography } from "@material-ui/core";
import { GRAY_6, WHITE } from "../../theme";

type Props = {
  label: string;
};

const useStyles = makeStyles((theme) => ({
  eyebrow: {
    fontSize: "10px",
    textTransform: "uppercase",
    fontWeight: "bold",
    background: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "50px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: "2px",
    paddingBottom: "2px",
    height: "20px",
  },
}));

export function Eyebrow({ label }: Props): JSX.Element {
  const classes = useStyles();
  return <Typography className={classes.eyebrow}>{label}</Typography>;
}
