import { makeStyles, Typography } from "@material-ui/core";
import { EquityVestingImportButton, Props } from "./EquityVestingImportButton";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 3),
    display: "flex",
    background: "rgba(255, 221, 40, 0.15)",
  },
  content: {
    justifyContent: "center",
    display: "flex",
    padding: theme.spacing(1),
  },
  text: {
    fontWeight: 450,
    lineHeight: "140%",
    letterSpacing: "-0.15px",
    fontSize: "0.875rem",
  },
}));

export function EquityCardButtonBanner(props: Props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography color="textPrimary" className={classes.text}>
          Equity grants require vesting information.
          <EquityVestingImportButton {...props} renderAsLink />
        </Typography>
      </div>
    </div>
  );
}
