import { gql } from "@apollo/client";
import { makeStyles, TableCell, TableRow } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  LevelingMethod,
  PayPeriodType,
  PositionFieldsMinimal as Position,
} from "../../../__generated__/graphql";
import { AssembleTypography } from "../../../components/AssembleTypography";
import { PositionAutocomplete } from "../../../components/Form/Autocomplete/PositionAutocomplete";
import { CompaRatioCell } from "../../../components/Table/CompaRatioCell";
import { CondensedBandPlacementCell } from "../../../components/Table/CondensedBandPlacementCell";
import { DepartmentAndLadderCell } from "../../../components/Table/DepartmentAndLadderCell";
import {
  EquityDisplayCell,
  EquityDisplayType,
} from "../../../components/Table/EquityDisplayCell";
import { NameAndPositionWithAvatarCell } from "../../../components/Table/NameAndPositionWithAvatarCell";
import { TotalCashCell } from "../../../components/Table/TotalCashCell";
import { isLeveled, needsLeveling } from "../../../models/Employee";
import { formatTenure } from "../../../models/Employment";
import { useURLSearchParams } from "../../../models/URLSearchParams";
import { GRAY_4, GRAY_8, PURPLE_1, theme, TRANSPARENT } from "../../../theme";
import { BadgedUserAvatar } from "../BadgedUserAvatar";
import { ReportRow } from "./ReportsTable";

const useStyles = makeStyles(() => ({
  row: {
    minHeight: theme.spacing(8.125),
    maxHeight: theme.spacing(8.125),
    height: theme.spacing(8.125),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: GRAY_8,
    },
    "& td": {
      // Invisible border to prevent the selected state from resizing the cell
      borderTop: `1px solid ${TRANSPARENT}`,
      "&:first-child": {
        borderLeft: `1px solid ${TRANSPARENT}`,
      },
      "&:last-child": {
        borderRight: `1px solid ${TRANSPARENT}`,
      },
    },
  },
  rowSelected: {
    "& td": {
      borderTop: `1px solid ${PURPLE_1}`,
      borderBottom: `1px solid ${PURPLE_1}`,
      "&:first-child": {
        borderLeft: `1px solid ${PURPLE_1}`,
      },
      "&:last-child": {
        borderRight: `1px solid ${PURPLE_1}`,
      },
    },
  },
  textField: {
    minWidth: "172px",
  },
  tableCell: {
    padding: theme.spacing(1.5),
  },
}));

type Props = {
  rowData: ReportRow;
  employeeDetailId: number | undefined;
  canUserLevel: boolean;
  showEquityGrants: boolean;
  showLevelingColumn: boolean;
  equityDisplay: EquityDisplayType;
  payTypeSelection: PayPeriodType;
  sortedPositions: Position[] | undefined;
  hasCompBandAccess: boolean;
  handleLevel: (
    employee: { activeEmployment: { id: number } | null },
    positionId: number | null
  ) => void;
};

export function ReportsTableRow({
  rowData,
  employeeDetailId,
  canUserLevel,
  showEquityGrants,
  showLevelingColumn,
  equityDisplay,
  payTypeSelection,
  sortedPositions,
  hasCompBandAccess,
  handleLevel,
}: Props): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();
  const urlSearchParams = useURLSearchParams();
  // this persists the selection after the callback (otherwise would show null)
  const [leveledPositionId, setLeveledPositionId] = useState<number | null>(
    rowData.employee.activeEmployment?.positionId ?? null
  );
  const [leveledPositionName, setLeveledPositionName] = useState<string | null>(
    rowData.employee.activeEmployment?.position?.name ?? null
  );

  const employeeLevelingState = getLevelingState(rowData.employee);
  const neverLeveled =
    employeeLevelingState === EmployeeLevelingState.NEVER_LEVELED;

  const isHourly =
    rowData.employee.activeEmployment?.payPeriod === PayPeriodType.HOURLY;
  const textColor = neverLeveled ? GRAY_4 : "inherit";

  useEffect(() => {
    // prevents previous emp's position from persisting onto next
    setLeveledPositionId(rowData.employee.activeEmployment?.positionId ?? null);
    setLeveledPositionName(
      rowData.employee.activeEmployment?.position?.name ??
        rowData.position ??
        null
    );
  }, [
    rowData.employee.activeEmployment?.position?.name,
    rowData.employee.activeEmployment?.positionId,
    rowData.employee.id,
    rowData.position,
  ]);

  return (
    <TableRow
      key={rowData.employee.id}
      className={`${classes.row} ${
        employeeDetailId === rowData.employee.id ? classes.rowSelected : ""
      }`}
      onClick={() =>
        navigate(
          `?${urlSearchParams
            .set("employee", rowData.employee.id.toString())
            .toString()}`
        )
      }
    >
      <NameAndPositionWithAvatarCell
        displayName={rowData.employee.displayName}
        photoURL={rowData.employee.user?.photoURL ?? null}
        employeeId={rowData.employee.id}
        position={leveledPositionName ?? "Unleveled"}
        needsLeveling={needsLeveling(rowData.employee)}
        canUserLevel={canUserLevel}
        useGrayscale={neverLeveled}
        className={classes.tableCell}
        isHourly={
          rowData.employee.activeEmployment?.payPeriod === PayPeriodType.HOURLY
        }
      />
      {showLevelingColumn ? (
        <TableCell
          align="left"
          className={classes.tableCell}
          aria-label="Job Title"
        >
          <AssembleTypography variant="productSmaller" textColor={textColor}>
            {rowData.jobTitle ?? "-"}
          </AssembleTypography>
        </TableCell>
      ) : (
        <DepartmentAndLadderCell
          department={rowData.department}
          ladder={rowData.ladder}
          textColor={textColor}
          className={classes.tableCell}
        />
      )}
      <TableCell
        align="center"
        className={classes.tableCell}
        aria-label="Employee Level"
      >
        <AssembleTypography variant="productSmaller" textColor={textColor}>
          {rowData.level ?? "-"}
        </AssembleTypography>
      </TableCell>
      <TableCell className={classes.tableCell} aria-label="Employee Tenure">
        <AssembleTypography variant="productSmaller" textColor={textColor}>
          {rowData.tenure === undefined ? "-" : formatTenure(rowData.tenure)}
        </AssembleTypography>
      </TableCell>
      <TableCell className={classes.tableCell} aria-label="Employee Location">
        <AssembleTypography variant="productSmaller" textColor={textColor}>
          {rowData.location ?? "-"}
        </AssembleTypography>
      </TableCell>
      <TableCell className={classes.tableCell} aria-label="Employee Manager">
        {rowData.employee.manager === null ? (
          "-"
        ) : (
          <BadgedUserAvatar
            displayName={rowData.employee.manager.displayName}
            photoURL={rowData.employee.manager.user?.photoURL ?? null}
            showTooltip
            grayscale
          />
        )}
      </TableCell>
      <TotalCashCell
        cash={rowData.totalCash}
        textColor={textColor}
        className={classes.tableCell}
        payTypeSelection={payTypeSelection}
        isHourly={isHourly}
      />
      {hasCompBandAccess && (
        <CondensedBandPlacementCell
          employee={rowData.employee}
          cash={rowData.totalCash}
          className={classes.tableCell}
          payTypeSelection={payTypeSelection}
        />
      )}
      {!showLevelingColumn && <CompaRatioCell employee={rowData.employee} />}
      {showEquityGrants && !showLevelingColumn ? (
        <EquityDisplayCell
          equityHoldings={rowData.employee.equityHoldings}
          mode={equityDisplay}
          className={classes.tableCell}
        />
      ) : null}
      {showLevelingColumn && (
        <PositionAutocomplete
          classes={{
            input: classes.textField,
          }}
          disabled={
            rowData.employee.activeEmployment?.levelingMethod ===
            LevelingMethod.AUTO_LEVEL
          }
          key={rowData.employee.id}
          value={
            sortedPositions?.find((p) => leveledPositionId === p.id) ?? null
          }
          label="Assemble Position"
          onChange={(position: Position | null) => {
            const posId = position?.id ?? null;
            setLeveledPositionId(posId);
            setLeveledPositionName(position?.name ?? null);
            handleLevel(rowData.employee, posId);
          }}
          positions={sortedPositions ?? []}
          variant="table"
          explanatoryTooltip={{
            title: "Auto-leveled EmpLoyee",
            placement: "top-end",
            body: "This person has been auto-leveled and their position can’t be overridden.",
            ctaLabel: "Learn More",
            newTab: true,
            ctaUrl:
              "//help.assemble.inc/en/articles/7979936-how-to-automatically-level",
            disabled:
              rowData.employee.activeEmployment?.levelingMethod !==
              LevelingMethod.AUTO_LEVEL,
          }}
        />
      )}
    </TableRow>
  );
}

export enum EmployeeLevelingState {
  NEVER_LEVELED = "never-leveled",
  LEVELED = "leveled",
}

function getLevelingState(
  employee: ReportRow["employee"]
): EmployeeLevelingState {
  return isLeveled(employee)
    ? EmployeeLevelingState.LEVELED
    : EmployeeLevelingState.NEVER_LEVELED;
}

ReportsTableRow.fragments = {
  employee: gql`
    fragment ReportsTableRow_employee on Employee2 {
      id
      displayName
      activeAt
      employmentStatus
      user {
        id
        photoURL
      }
      manager: minimalManager {
        id
        displayName
        user {
          id
          photoURL
        }
      }
      employments {
        id
        activeAt
        inactiveAt
        positionId
      }
      activeCashCompensation {
        employeeId
        type
        activeAt
        annualCashEquivalent
        percentOfSalary
      }
      activeEmployment {
        id
        payPeriod
        jobTitle
        positionId
        levelingCode
        levelingMethod
        position {
          id
          name
          level
          ladder {
            id
            name
            department {
              id
              name
            }
          }
        }
      }
      equityHoldings {
        id
        totalUnitCount
        totalGrossHoldingValue
        grants {
          id
          name
          issueDate
          vestingStartDate
          vestingScheduleDescription
          unitCount
          price
          equityType
          awardType
        }
      }
      adjustedCashBands {
        id
        name
        bandPoints {
          name
          annualCashEquivalent
        }
      }
    }
  `,
};
