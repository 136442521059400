import { gql } from "@apollo/client";
import { ReactNode, useState } from "react";
import { AssignBenefitsButton_employee } from "../../../../__generated__/graphql";
import { BenefitsDialog } from "./BenefitsDialog";

type Props = {
  employees: AssignBenefitsButton_employee[];
  children: (onClick: () => void) => ReactNode;
};

export function AssignBenefitsButton({
  employees,
  children,
}: Props): JSX.Element {
  const [open, setOpen] = useState(false);

  return (
    <>
      {
        /* It's important that we completely unmount this component when the
         * dialog is closed, so thatform state does not persist if you change
         * selections and re-open it. */
        open && (
          <BenefitsDialog
            onClose={() => setOpen(false)}
            employees={employees}
          />
        )
      }
      {children(() => setOpen(true))}
    </>
  );
}

AssignBenefitsButton.fragments = {
  employee: gql`
    ${BenefitsDialog.fragments.employee}
    fragment AssignBenefitsButton_employee on Employee {
      ...BenefitsDialog_employee
    }
  `,
};
