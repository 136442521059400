import { Fab, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { PlusCircleIcon } from "src/components/AssembleIcons/Brand/PlusCircleIcon";
import { AccessBoundary } from "../../components/AccessBoundary";
import { BLUE_2 } from "../../theme";
import { Noun } from "../../__generated__/graphql";
import { LadderForm } from "../LadderDetail/LadderForm";

type Props = { departmentId: number };

export function DepartmentDetailLadderCreator({
  departmentId,
}: Props): JSX.Element {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <AccessBoundary scope="global" verb="edit" noun={Noun.JobStructure}>
        <Tooltip title="Add Ladder" placement="top">
          <Fab
            centerRipple={true}
            color="primary"
            onClick={handleClickOpen}
            size="medium"
          >
            <PlusCircleIcon color={BLUE_2} width="24px" height="24px" />
          </Fab>
        </Tooltip>
      </AccessBoundary>
      {open && (
        <LadderForm
          mode="create"
          onCancel={handleClose}
          departmentId={departmentId}
        />
      )}
    </>
  );
}
