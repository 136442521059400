import { gql } from "@apollo/client";
import { basicComparator } from "@asmbl/shared/sort";
import { makeStyles, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { ActivityIcon } from "src/components/AssembleIcons/Small/ActivityIcon";
import { useUserView } from "src/contexts/UserViewContext";
import {
  ActivityLogView_compCycle,
  ActivityLogView_employee,
  PerfRatingCallout_perfRating,
  SubmittedRecommendationCard_submittedItem as SubmittedItem,
  SubmittedSummaryCard_submittedReview as SubmittedReview,
} from "../../../__generated__/graphql";
import { GRAY_5 } from "../../../theme";
import { DrawerHeader } from "../../DrawerHeader";
import { SubmittedRecommendationCard } from "./SubmittedRecommendationCard";
import { SubmittedSummaryCard } from "./SubmittedSummaryCard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  body: {
    flex: 1,
    overflowY: "auto",
    padding: theme.spacing(2, 3, 8, 3),
  },
  emptyStateContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(16),
  },
  emptyStateIcon: {
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  employee: ActivityLogView_employee | null;
  compCycle: ActivityLogView_compCycle;
  onClose: () => unknown;
};

export function ActivityLogView({
  employee,
  compCycle,
  onClose,
}: Props): JSX.Element {
  const { upsertUserView } = useUserView();

  useEffect(() => {
    if (employee != null) {
      upsertUserView({
        compCycle: {
          [compCycle.id]: {
            activityLog: { [employee.id]: new Date().getTime() },
          },
        },
      });
    }
    // we only want the mutation called when the view mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const classes = useStyles();

  if (employee === null) return <EmptyState />;

  const { compRecommendation, activeEmployment, allPerfRatings } = employee;

  const timestampItemsMap = mapSubmittedItemsByTimestamp(
    compRecommendation?.allSubmittedItems ?? []
  );
  const chronologicalTimestamps = Array.from(timestampItemsMap.keys()).sort(
    basicComparator
  );

  const timestampReviewsMap = mapSubmittedReviewsByTimestamp(
    chronologicalTimestamps,
    compRecommendation?.allSubmittedReviews ?? []
  );

  return (
    <div className={classes.root} id="review-activity-log-view">
      <DrawerHeader
        title={`Activity Log for ${employee.displayName}`}
        onClose={onClose}
      />
      <div className={classes.body}>
        {compRecommendation ? (
          chronologicalTimestamps.reverse().map((timestamp) => {
            const perfRating = getClosestPerfRating(timestamp, allPerfRatings);
            return (
              <SubmittedSummaryCard
                key={timestamp}
                submittedItems={timestampItemsMap.get(timestamp) ?? []}
                activeEmployment={activeEmployment}
                cashCompensations={employee.activeCashCompensation ?? []}
                submittedReviews={timestampReviewsMap.get(timestamp) ?? []}
                perfRating={perfRating}
                finalApproversIds={compCycle.finalApproversIds}
              />
            );
          })
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
}

function EmptyState() {
  const classes = useStyles();

  return (
    <div className={classes.emptyStateContainer}>
      <ActivityIcon
        className={classes.emptyStateIcon}
        color={GRAY_5}
        height={"42px"}
        width={"42px"}
      />
      <Typography color="textSecondary" align="center">
        This employee doesn't have any <br /> activity for this comp cycle.
      </Typography>
    </div>
  );
}

/**
 * Group all the submitted items by timestamp.
 */
function mapSubmittedItemsByTimestamp(
  submittedItems: SubmittedItem[]
): Map<number, SubmittedItem[]> {
  const timestampItemsMap = new Map<number, SubmittedItem[]>();
  submittedItems.forEach((item) => {
    const timestamp = new Date(item.submittedAt as string).getTime();
    const existingItem = timestampItemsMap.get(timestamp);
    if (existingItem === undefined) {
      timestampItemsMap.set(timestamp, [item]);
    } else {
      timestampItemsMap.set(timestamp, existingItem.concat(item));
    }
  });
  return timestampItemsMap;
}

/**
 * Group all the submitted reviews by the timestamp of their associated
 * recommendation items. For example, if three recommendation items share the
 * timestamp T, then we'll find all the reviews for that group of
 * recommendations and associate them with the same timestamp T.
 */
function mapSubmittedReviewsByTimestamp(
  sortedTimestamps: number[],
  submittedReviews: SubmittedReview[]
): Map<number, SubmittedReview[]> {
  const timestampReviewsMap = new Map<number, SubmittedReview[]>();
  sortedTimestamps.forEach((timestamp, index, array) => {
    timestampReviewsMap.set(
      timestamp,
      filterReviewsByTimeRange(submittedReviews, timestamp, array[index + 1])
    );
  });
  return timestampReviewsMap;
}

/**
 * Return only the reviews submitted within a given time range.
 * @param after: Filter by reviews submitted after this time.
 * @param before: Filter by reviews submitted before this time.
 */
function filterReviewsByTimeRange(
  reviews: SubmittedReview[],
  after: number,
  before: number | undefined
): SubmittedReview[] {
  return reviews.filter((review) => {
    const timeStamp = new Date(review.submittedAt ?? 0).getTime();
    if (before === undefined) {
      return timeStamp > after;
    }
    return timeStamp > after && timeStamp <= before;
  });
}

function getClosestPerfRating(
  timestamp: number,
  perfRatings: PerfRatingCallout_perfRating[] | null
): PerfRatingCallout_perfRating | null {
  if (perfRatings == null) return null;

  // Performance ratings should be sorted by date on the server
  for (let i = 0; i < perfRatings.length; i++) {
    if (
      i === perfRatings.length - 1 &&
      new Date(perfRatings[i].createdAt).getTime() < timestamp
    )
      return perfRatings[i];

    if (
      new Date(perfRatings[i].createdAt).getTime() < timestamp &&
      new Date(perfRatings[i + 1].createdAt).getTime() > timestamp
    ) {
      return perfRatings[i];
    }
  }

  return null;
}

ActivityLogView.fragments = {
  employee: gql`
    ${SubmittedRecommendationCard.fragments.activeEmployment}
    ${SubmittedRecommendationCard.fragments.submittedItem}
    ${SubmittedRecommendationCard.fragments.perfRating}
    ${SubmittedSummaryCard.fragments.submittedReview}
    ${SubmittedSummaryCard.fragments.cashCompensation}
    fragment ActivityLogView_employee on Employee {
      id
      displayName
      perfRating(compCycleId: $compCycleId) {
        id
        ...SubmittedRecommendationCard_perfRating
      }
      allPerfRatings(compCycleId: $compCycleId) {
        id
        ...SubmittedRecommendationCard_perfRating
      }
      activeEmployment {
        id
        ...SubmittedRecommendationCard_activeEmployment
      }
      activeCashCompensation {
        ...SubmittedSummaryCard_cashCompensation
      }
      compRecommendation(compCycleId: $compCycleId) {
        subjectId
        compCycleId
        allSubmittedReviews {
          id
          ...SubmittedSummaryCard_submittedReview
        }
        allSubmittedItems {
          id
          ...SubmittedRecommendationCard_submittedItem
        }
      }
    }
  `,
  compCycle: gql`
    fragment ActivityLogView_compCycle on CompCycle {
      id
      finalApproversIds
    }
  `,
};
