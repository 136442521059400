import { gql } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";
import { useState } from "react";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import {
  ComputedLocationAdjustment,
  LocationAdjustmentAdjustment,
  LocationAdjustmentCondition,
} from "../../../models/ComputedLocationAdjustment";
import {
  useCreateLocationAdjustment,
  useUpdateLocationAdjustment,
} from "../../../mutations/Location";
import { GRAY_6, WHITE } from "../../../theme";
import {
  bandNameComparator,
  getAdjustmentColor,
  toggleSet,
} from "../../../utils";
import {
  LocationAdjustmentEditor_compStructure as CompStructure,
  LocationAdjustmentEditor_departments as Department,
  LocationAdjustmentEditor_locationGroup as LocationGroup,
} from "../../../__generated__/graphql";
import { AssemblePopper } from "../../AssemblePopper";
import { DrawerTitle } from "../DrawerTitle";
import {
  AdjustmentConditionCreator,
  JobArchitectureCondition,
} from "./AdjustmentConditionCreator";

export type AdjustmentEditorState =
  | {
      mode: "new" | "closed";
      existingLocationAdjustment?: never;
    }
  | {
      mode: "duplicate" | "edit" | "closed";
      existingLocationAdjustment: ComputedLocationAdjustment;
    };

const useStyles = makeStyles((theme) => ({
  root: {
    width: "330px",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(10),
  },
  textSectionHeader: {
    fontWeight: 500,
  },
  adjustmentInput: {
    display: "flex",
    width: "102px",
  },
  adjustmentHelperText: {
    flex: 1,
  },
  checkbox: {
    marginRight: theme.spacing(0.5),
  },
  actionBar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "fixed",
    padding: theme.spacing(3),
    height: "90px",
    width: "330px",
    left: 0,
    bottom: 0,
    background: WHITE,
    boxShadow: `0px -2px 9px rgba(10, 36, 64, 0.08), inset 0px 1px 0px ${GRAY_6}`,
    zIndex: 10,
  },
}));

type LocationAdjustmentEditorProps = {
  defaultLocationGroup: LocationGroup;
  allLocationGroups: LocationGroup[];
  departments: Department[];
  compStructure: CompStructure;
  closeDrawer: () => unknown;
  editorState: AdjustmentEditorState;
};

export function LocationAdjustmentEditor({
  defaultLocationGroup,
  allLocationGroups,
  departments,
  compStructure,
  closeDrawer,
  editorState,
}: LocationAdjustmentEditorProps): JSX.Element {
  const classes = useStyles();

  const [locationGroup, setLocationGroup] =
    useState<LocationGroup>(defaultLocationGroup);
  const [adjustmentValue, setAdjustmentValue] = useState<string>(
    formatAdjustmentValue(
      editorState.existingLocationAdjustment?.adjustmentValue.toString() ?? ""
    )
  );
  const [jobArchitectureCondition, setJobArchitectureCondition] =
    useState<JobArchitectureCondition>({
      departments:
        editorState.existingLocationAdjustment?.departments.map((d) => d.id) ??
        [],
      ladders:
        editorState.existingLocationAdjustment?.ladders.map((l) => l.id) ?? [],
      levels: editorState.existingLocationAdjustment?.levels ?? [],
    });
  const [compComponents, setCompComponents] = useState(
    new Set<string>(editorState.existingLocationAdjustment?.compComponents)
  );
  const [description, setDescription] = useState(
    editorState.existingLocationAdjustment?.description ?? ""
  );

  const [saveLocationAdjustment, { loading: isSaveLocationAdjustmentLoading }] =
    useCreateLocationAdjustment();

  const [
    updateLocationAdjustment,
    { loading: isUpdateLocationAdjustmentLoading },
  ] = useUpdateLocationAdjustment();

  const allBandTypes = [
    ...compStructure.cashBandTypes,
    ...compStructure.equityBandTypes,
  ].sort(bandNameComparator);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const adjustment: LocationAdjustmentAdjustment = {
      value: parseFloat(adjustmentValue),
    };
    const condition: LocationAdjustmentCondition = {
      ...jobArchitectureCondition,
      compComponents: Array.from(compComponents),
    };

    if (editorState.mode === "edit") {
      await updateLocationAdjustment(
        editorState.existingLocationAdjustment.id,
        { condition, adjustment, description }
      );
    } else {
      await saveLocationAdjustment({
        locationGroupId: locationGroup.id,
        condition,
        adjustment,
        description,
      });
    }
    closeDrawer();
  };

  const handleChangeAdjustmentValue = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setAdjustmentValue(formatAdjustmentValue(event.target.value));
  };

  const handleAllCompComponentsChecked = (event: unknown, checked: boolean) => {
    if (checked) setCompComponents(new Set());
  };

  const isFormSubmitEnabled = (): boolean => {
    const isFormValid = Boolean(
      adjustmentValue && isAdjustmentValueValid(adjustmentValue)
    );
    return (
      isFormValid &&
      !(isSaveLocationAdjustmentLoading || isUpdateLocationAdjustmentLoading)
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} label="Group" variant="outlined" fullWidth />
  );

  const renderOption = (group: LocationGroup) => (
    <MenuItem key={group.id} value={group.id}>
      {group.name}
    </MenuItem>
  );

  return (
    <Box className={classes.root}>
      <DrawerTitle
        title={
          editorState.mode === "edit"
            ? "Editing Adjustment"
            : "Add an Adjustment"
        }
        onClose={closeDrawer}
      />
      <Box m={3.5} />

      <form onSubmit={handleSubmit}>
        <Autocomplete
          PopperComponent={AssemblePopper}
          popupIcon={<ChevronDownIcon inherit />}
          options={allLocationGroups}
          value={locationGroup}
          onChange={(_, value) => setLocationGroup(value)}
          renderInput={renderInput}
          renderOption={renderOption}
          disabled={editorState.mode === "edit"}
          disableClearable
          getOptionLabel={(option) => option.name}
          fullWidth
        />

        <Box m={3} />

        <Typography variant="body1" className={classes.textSectionHeader}>
          What’s the adjustment factor?
        </Typography>
        <Box m={2} />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TextField
            className={classes.adjustmentInput}
            variant="outlined"
            label="Adjustment"
            placeholder="+ or - n"
            required
            value={adjustmentValue}
            onChange={handleChangeAdjustmentValue}
            error={!isAdjustmentValueValid(adjustmentValue)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              style: {
                color: getAdjustmentColor(adjustmentValue),
              },
            }}
          />
          <Box m={1} />

          <Typography
            className={classes.adjustmentHelperText}
            variant="caption"
            color="textSecondary"
          >
            Note: 0% is base, so +5% would add 5% to that base.
          </Typography>
        </Box>
        <Box m={3.5} />

        <Typography variant="body1" className={classes.textSectionHeader}>
          Which positions does it apply to?
        </Typography>
        <Box m={2} />

        <AdjustmentConditionCreator
          departments={departments}
          compStructure={compStructure}
          onConditionChange={setJobArchitectureCondition}
          existingLocationAdjustment={editorState.existingLocationAdjustment}
        />
        <Box m={3} />

        <Typography variant="body1" className={classes.textSectionHeader}>
          Which compensation components?
        </Typography>

        <Box display="flex" flexDirection="column">
          <Box mt={1} mx={1}>
            <FormControlLabel
              label="All"
              control={
                <Checkbox
                  className={classes.checkbox}
                  color="primary"
                  checked={compComponents.size === 0}
                  onChange={handleAllCompComponentsChecked}
                />
              }
            />
          </Box>
          {allBandTypes.map((bandType) => (
            <Box key={bandType} mt={1} mx={1}>
              <FormControlLabel
                label={bandType}
                control={
                  <Checkbox
                    className={classes.checkbox}
                    color="primary"
                    checked={compComponents.has(bandType)}
                    onChange={() =>
                      setCompComponents(toggleSet(compComponents, bandType))
                    }
                  />
                }
              />
            </Box>
          ))}
        </Box>
        <Box m={4} />

        <TextField
          label="Justification"
          variant="outlined"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          fullWidth
          inputProps={{ maxLength: 500 }}
          multiline
          placeholder="Include a description for why you’re applying this factor…"
          rows={5}
          InputLabelProps={{ shrink: true }}
        />

        <Box className={classes.actionBar}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            disabled={!isFormSubmitEnabled()}
          >
            {editorState.mode === "edit" ? "Save" : "Add"}
          </Button>
        </Box>
      </form>
    </Box>
  );
}

LocationAdjustmentEditor.fragments = {
  compStructure: gql`
    ${AdjustmentConditionCreator.fragments.compStructure}
    fragment LocationAdjustmentEditor_compStructure on CompStructure {
      ...AdjustmentConditionCreator_compStructure
      cashBandTypes
      equityBandTypes
      id
    }
  `,
  departments: gql`
    ${AdjustmentConditionCreator.fragments.departments}
    fragment LocationAdjustmentEditor_departments on Department {
      ...AdjustmentConditionCreator_departments
      id
    }
  `,
  locationGroup: gql`
    fragment LocationAdjustmentEditor_locationGroup on LocationGroup {
      id
      name
    }
  `,
};

function isAdjustmentValueValid(adjustment: string) {
  if (!adjustment) return true;
  if (adjustment === "+" || adjustment === "-") return true;
  if (Number(adjustment) < -100) return false;
  return !isNaN(Number(adjustment));
}

function formatAdjustmentValue(adjustment: string) {
  const newValue =
    adjustment.charAt(0) === "+" ? adjustment.substring(1) : adjustment;
  return Number(adjustment) > 0 ? `+${newValue}` : newValue;
}
