import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import { useEffect } from "react";
import { GetGuidance, GetGuidanceVariables } from "src/__generated__/graphql";
import { useTrack } from "src/analytics";
import { ErrorView } from "src/components/ErrorView";
import {
  useApplyGuidanceToAll,
  useBuildEmptyMatrix,
} from "src/mutations/MeritGuidance";
import { MeritGuidance } from "./MeritGuidance";

type Props = {
  compCycleId: number;
};

export function MeritGuidanceLoadingBoundary({ compCycleId }: Props) {
  const { trackEvent } = useTrack();
  useEffect(() => {
    trackEvent({
      object: "Merit Guidance",
      action: "Viewed",
      compCycleId,
    });
  }, [trackEvent, compCycleId]);

  const buildEmptyMatrix = useBuildEmptyMatrix(compCycleId);
  const applyGuidanceToAll = useApplyGuidanceToAll(compCycleId);

  const { loading, data, refetch } = useQuery<
    GetGuidance,
    GetGuidanceVariables
  >(MeritGuidanceLoadingBoundary.query, {
    variables: {
      compCycleId,
    },
    onCompleted: (data) => {
      if (
        data.compCycle?.budgetGuides.length == null ||
        data.compCycle.budgetGuides.length === 0
      ) {
        buildEmptyMatrix()
          .then(() => refetch())
          .catch(console.error);
      }
    },
  });

  if (loading) return <CircularProgress />;
  if (data == null || data.compCycle == null)
    return (
      <ErrorView text="Something went wrong. Please refresh the page to try again" />
    );

  return (
    <MeritGuidance
      compCycle={data.compCycle}
      refreshMetrics={refetch}
      applyGuidanceToAll={applyGuidanceToAll}
    />
  );
}

const PERF_RATING_OPTION = gql`
  fragment MeritGuidance_perfRatingOption on PerfRatingOption {
    id
    name
    rank
  }
`;

const MERIT_ADJUSTMENT_RANGE = gql`
  fragment MeritGuidance_meritAdjustmentRange on MeritAdjustmentRange {
    id
    rangeStart
  }
`;

const MERIT_ADJUSTMENT_GUIDE = gql`
  ${PERF_RATING_OPTION}
  ${MERIT_ADJUSTMENT_RANGE}
  fragment MeritGuidance_meritAdjustmentGuide on MeritAdjustmentGuide {
    id
    perfRatingOptionId
    meritAdjustmentRangeId
    percent
    perfRatingOption {
      ...MeritGuidance_perfRatingOption
    }
    meritAdjustmentRange {
      ...MeritGuidance_meritAdjustmentRange
    }
  }
`;

const BUDGET_RANGE = gql`
  fragment BudgetGuidance_budgetRange on BudgetRange {
    id
    rangeStart
  }
`;

const BUDGET_PERF_RATING_OPTION = gql`
  fragment BudgetGuidance_budgetPerfRatingOption on BudgetPerfRatingOption {
    id
    name
    rank
  }
`;

const BUDGET_GUIDE = gql`
  ${BUDGET_RANGE}
  ${BUDGET_PERF_RATING_OPTION}
  fragment BudgetGuidance_budgetGuide on BudgetGuide {
    id
    percent
    budgetPerfRatingOptionId
    budgetRangeId
    budgetRange {
      ...BudgetGuidance_budgetRange
    }
    budgetPerfRatingOption {
      ...BudgetGuidance_budgetPerfRatingOption
    }
  }
`;

const COMP_CYCLE = gql`
  ${BUDGET_GUIDE}
  ${MERIT_ADJUSTMENT_GUIDE}
  fragment Guidance_compCycle on CompCycle {
    id
    estimatedSalaryBudget
    estimatedSalaryMerit
    recommendationsPreFill
    totalSpend
    budgetGuides {
      id
      ...BudgetGuidance_budgetGuide
    }
    meritAdjustmentGuides {
      id
      ...MeritGuidance_meritAdjustmentGuide
    }
  }
`;

MeritGuidanceLoadingBoundary.query = gql`
  ${COMP_CYCLE}
  query GetGuidance($compCycleId: Int!) {
    compCycle(id: $compCycleId) {
      ...Guidance_compCycle
    }
  }
`;
