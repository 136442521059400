import { useQuery } from "@apollo/client";
import EmptyMappingSvg from "../../assets/svgs/illustrations/empty-state-hris-mapping.svg?react";
import NoHrisSvg from "../../assets/svgs/illustrations/no-hris-integration.svg?react";
import { AssembleLink } from "../../components/AssembleLink";
import { EmptyTableView } from "../../components/EmptyTableView";
import Loading from "../../components/Settings/Loading";
import { GET_MERGE_CONFIGS } from "../../queries";
import { GetMergeConfigs } from "../../__generated__/graphql";

export function EmptyState(): JSX.Element {
  const { data, loading } = useQuery<GetMergeConfigs>(GET_MERGE_CONFIGS);

  if (loading) {
    return <Loading />;
  }

  if (data !== undefined && data.mergeConnectionConfigs.length > 0) {
    return (
      <EmptyTableView
        message="No active employees have been imported from your HRIS."
        svg={<EmptyMappingSvg width="15%" />}
      />
    );
  }

  return (
    <EmptyTableView
      message={
        <>
          Go to your{" "}
          <AssembleLink to="/settings/data">
            Data Management Settings
          </AssembleLink>{" "}
          and add your HRIS integration to import your employees.
        </>
      }
      svg={<NoHrisSvg />}
      width="25em"
    />
  );
}
