import { gql, useQuery } from "@apollo/client";
import { LinearProgress } from "@material-ui/core";
import {
  MainLoadingBoundaryQuery,
  PrimaryRoleName,
} from "src/__generated__/graphql";
import { ErrorView } from "../ErrorView";
import { Main } from "./Main";
import { PhaseComplexToggleProvider } from "./PhaseDrawer/usePhaseComplexToggle";
import { PhaseManagerSearchProvider } from "./PhaseDrawer/usePhaseManagerSearch";
import { attachReports } from "./utils";

type Employee = MainLoadingBoundaryQuery["employee2s"]["employees"][number];

type Props = {
  onPrevious: () => void;
  onNext: () => void;
  compCycleName: string;
};

export function MainLoadingBoundary({
  onPrevious,
  onNext,
  compCycleName,
}: Props): JSX.Element {
  const { data, loading } = useQuery<MainLoadingBoundaryQuery>(
    MainLoadingBoundary.query
  );

  if (!data) return loading ? <LinearProgress /> : <ErrorView />;

  const fullAccessUsers = data.organization.users.filter(
    (user) => user.userAccessGrant?.roleName === PrimaryRoleName.FULL_ACCESS
  );
  const employeeMap = attachReports(data.employee2s.employees);
  const managers = [...employeeMap.values()].filter(
    (employee: Employee & { reports: number[] }) => employee.reports.length > 0
  );

  return (
    <PhaseManagerSearchProvider>
      <PhaseComplexToggleProvider>
        <Main
          variant="create"
          compCycleName={compCycleName}
          employees={managers}
          users={fullAccessUsers}
          onPrevious={onPrevious}
          onNext={onNext}
        />
      </PhaseComplexToggleProvider>
    </PhaseManagerSearchProvider>
  );
}

MainLoadingBoundary.query = gql`
  ${Main.fragments.employee}
  ${Main.fragments.user}
  query MainLoadingBoundaryQuery {
    employee2s {
      employees {
        id
        ...Main_employee
      }
    }
    organization {
      id
      users {
        id
        userAccessGrant {
          id
          roleName
        }
        ...Main_user
      }
    }
  }
`;
