export const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

export function cleanEmail(email: string): string {
  return email.trim().toLocaleLowerCase();
}

export function isEmailValid(email: string): boolean {
  return EMAIL_REGEX.test(email);
}

export function getDomainFromEmail(email: string): string | undefined {
  return cleanEmail(email).split("@").pop();
}
