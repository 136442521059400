export type ChartData = {
  id: number;
  parentId: number | null;
  _totalSubordinates: number;
};

/**
 * we cannot safely assume that the organization's hierarchy will be properly
 * structured. This function will clean up the data to ensure that the org chart
 * has a proper root node
 * @param data employee data from the server
 * @returns cleaned employee data for the org chart
 */
export function sanitizeData<T extends ChartData>(data: T[]): T[] {
  const totalRootNodes = data.filter((employee) => employee.parentId === null);

  if (totalRootNodes.length === 0)
    return [{ id: 0, parentId: null, _totalSubordinates: 0 } as T];

  // find the most appropriate root node, in this case we will treat the node
  // that has the most nodes in it's hierarchy as the root node

  const syntheticRoot = {
    id: 0,
    parentId: null,
    _totalSubordinates: data.length,
  } as T;

  const modifiedRootNodes = totalRootNodes.map((employee) => ({
    ...employee,
    parentId: syntheticRoot.id,
  }));

  const updatedData = [
    syntheticRoot,
    ...modifiedRootNodes,
    ...data.filter((employee) => employee.parentId != null),
  ];

  // init a queue without the invalid root nodes & init a visited flag
  let queue = updatedData.map((employee) => ({ visited: false, ...employee }));

  while (queue.some(({ visited }) => !visited)) {
    const currentNode = queue.find(({ visited }) => !visited);

    if (currentNode == null) continue;

    // mark the node as visited
    queue = queue.map((employee) =>
      employee.id === currentNode.id
        ? { ...currentNode, visited: true }
        : employee
    );
  }

  return queue.map(({ visited: _, ...employee }) => ({
    ...employee,
  })) as unknown as T[];
}

export function getDeepestNode<T extends ChartData>(data: T[]): T {
  // since this data is sanitized, we can safely assume that there is
  // a root node and only one root node
  const rootNode = data.find((employee) => employee.parentId === null) as T;

  const result = [];

  const queue = [rootNode];

  while (queue.length > 0) {
    const currentNode = queue.shift();

    if (currentNode == null) continue;

    result.push(currentNode);

    const children = data.filter(
      (employee) => employee.parentId === currentNode.id
    );

    for (const child of children) {
      queue.push(child);
    }
  }

  return result[result.length - 1];
}

/**
 * we cannot safely assume that the organization's hierarchy will be properly
 * structured. This function makes sure each node has a valid parent node
 * @param data employee data from the server
 * @returns employee data with missing parent nodes
 */
export function findMissingNodes<T extends ChartData>(data: T[]): T[] {
  // include null to account for the root node
  const employeeIds = new Set([null, ...data.map((node) => node.id)]);

  const missingParentNodes = data.filter(
    (node) => !employeeIds.has(node.parentId)
  );

  return missingParentNodes;
}
