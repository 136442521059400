//  -- Components ---------------------
import { Box, makeStyles } from "@material-ui/core";
import { memo, useEffect, useState } from "react";

const useStyles = makeStyles(() => ({
  transition: {
    marginTop: (p: StyleProps) => (p.active ? "0px" : "40px"),
    opacity: (p: StyleProps) => (p.active ? 1 : 0),
    transition: "all 350ms",
  },
}));

//  ----------------------------------------------------------------------------
//  Types
//  ----------------------------------------------------------------------------
type TransitionProps = {
  children: React.ReactNode;
};

type StyleProps = {
  active: boolean;
};

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
const Transition = ({ children }: TransitionProps) => {
  // -- Toggle active state on mount -----
  const [active, setActive] = useState(false);
  useEffect(() => {
    setTimeout(() => setActive(true), 0);
  }, []);
  const classes = useStyles({ active });

  return <Box className={classes.transition}>{children}</Box>;
};

export default memo(Transition);
