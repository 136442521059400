import { Box, IconButton, makeStyles } from "@material-ui/core";
import { useCallback, useMemo, useRef } from "react";
import { GRAY_4, PURPLE_2, theme } from "../../theme";
import { CurrencyCode } from "../../__generated__/graphql";
import { DeleteIcon } from "../AssembleIcons/Brand/DeleteIcon";
import { NumberInput } from "../Form/NumberInput";
import { CurrencyField } from "./CurrencyField";

export interface CurrencyValue {
  id: number;
  code: CurrencyCode | null;
  exchangeRate: number | null;
}
interface Props {
  currencies: CurrencyValue[];
  disabledCurrency?: CurrencyCode;
  onChange: (id: number, value: CurrencyValue | null) => unknown;
}

const useStyles = makeStyles(() => ({
  row: {
    display: "flex",
    position: "relative",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(-6),
    justifyContent: "stretch",
    alignItems: "center",

    "&:hover $deleteButton, &:focus-within $deleteButton": {
      opacity: 1,
    },
  },

  currencyField: {
    flexGrow: 1,
  },

  conversionField: {
    marginLeft: theme.spacing(2),
    width: "110px",
  },
  conversionInput: {
    textAlign: "right",
  },

  deleteButton: {
    opacity: 0,
  },
}));

export function AdditionalCurrenciesInput({
  currencies,
  disabledCurrency,
  onChange,
}: Props): JSX.Element {
  const getCurrencySelected = useMemo(() => {
    const selected = new Set([
      disabledCurrency,
      ...currencies.map((c) => c.code),
    ]);
    return (currency: CurrencyCode) => selected.has(currency);
  }, [currencies, disabledCurrency]);

  return (
    <>
      {currencies.map((currency) => (
        <AdditionalCurrencyRow
          key={currency.id}
          currency={currency}
          onChange={onChange}
          getCurrencySelected={getCurrencySelected}
        />
      ))}
    </>
  );
}

function AdditionalCurrencyRow({
  onChange,
  currency,
  getCurrencySelected,
}: {
  currency: CurrencyValue;
  onChange: (id: number, value: CurrencyValue | null) => unknown;
  getCurrencySelected: (currency: CurrencyCode) => boolean;
}): JSX.Element {
  const { id, code, exchangeRate } = currency;

  const classes = useStyles();
  const conversionInputEl = useRef<HTMLInputElement>();

  const handleCurrencyChange = useCallback(
    (newValue: CurrencyCode | null) => {
      if (newValue !== null && conversionInputEl.current) {
        conversionInputEl.current.focus();
      }
      onChange(id, { id, code: newValue, exchangeRate });
    },
    [exchangeRate, id, onChange]
  );

  return (
    <Box className={classes.row}>
      <CurrencyField
        label="Additional Currency"
        value={code}
        onChange={handleCurrencyChange}
        getOptionDisabled={getCurrencySelected}
        className={classes.currencyField}
        autoFocus={code === null}
      />
      <NumberInput
        placeholder="1.00"
        label="Conversion"
        value={exchangeRate}
        variant="outlined"
        onValueChange={({ floatValue }) =>
          onChange(id, {
            id,
            code,
            exchangeRate: floatValue ?? null,
          })
        }
        className={classes.conversionField}
        inputProps={{
          ref: conversionInputEl,
          className: classes.conversionInput,
        }}
      />
      <IconButton
        className={classes.deleteButton}
        onClick={() => onChange(id, null)}
      >
        <DeleteIcon
          color={GRAY_4}
          hoverColor={PURPLE_2}
          height={"24px"}
          width={"24px"}
        />
      </IconButton>
    </Box>
  );
}
