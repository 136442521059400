import { Box, Grid, Typography } from "@material-ui/core";
import { memo } from "react";
import { globalStyles, textContentStyles } from "./style";

type TextContentProps = {
  candidateName: string;
  header: string;
  message?: string | null;
};

const Welcome = ({ candidateName, header, message }: TextContentProps) => {
  const classes = textContentStyles();
  const glClasses = globalStyles();
  const firstName = candidateName.split(" ")[0];

  return (
    <Box className={classes.container}>
      <Grid container spacing={4}>
        <Grid item xs={7}>
          <Typography className={classes.header} variant="h1">
            {header}
          </Typography>
          <Typography className={classes.name} variant="h3">
            {firstName}.
          </Typography>
        </Grid>
        <Grid item xs={5}>
          {message?.split("\n").map((val, index) => (
            <Box key={`${val}-${index}`} mb={2}>
              <Typography className={glClasses.content} variant="body1">
                {val}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(Welcome);
