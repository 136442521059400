import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { EmployeeCard_employee as Employee } from "src/__generated__/graphql";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { ChevronRightIcon } from "src/components/AssembleIcons/Brand/ChevronRightIcon";
import { AssembleTruncatedTypography } from "src/components/AssembleTruncatedTypography";
import { AssembleTypography } from "src/components/AssembleTypography";
import { ExplanatoryTooltip } from "src/components/ExplanatoryTooltip";
import { getManagementLayer } from "src/components/Settings/UserAccessControl/ManagerCard";
import { GRAY_4, GRAY_6, GRAY_7, GRAY_8, PURPLE_1, WHITE } from "src/theme";
import { AddToPhaseIcon } from "../../../../components/AssembleIcons/Brand/AddToPhaseIcon";
import { usePhaseConfiguration } from "../../usePhaseConfiguration";
import {
  getAssignedPhase,
  isEmployeeAssignedToAnotherPhase,
  isEmployeeManagementChainAssignedToAnotherPhase,
} from "../../utils";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    border: `solid 1px ${GRAY_6}`,
    borderRadius: 4,
    marginTop: theme.spacing(1.25),
    boxShadow: "0px 1px 3px rgba(10, 36, 64, 0.08)",
    width: 360,
    height: 60,
    "&:hover": {
      background: GRAY_8,
    },
  },
  cannotBeAssigned: {
    background: GRAY_7,
  },
  copy: {
    transition: "all 250ms",

    "&:hover": {
      color: PURPLE_1,
    },
  },
  selectedContainer: {
    borderColor: PURPLE_1,
  },
  expandButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: theme.spacing(3),
    borderRight: `solid 1px ${GRAY_6}`,
  },
  expandButtonDisabled: {
    display: "none",
  },
  rightContainer: {
    width: "100%",
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
  },
  decorator: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  managementLayer: {
    paddingLeft: theme.spacing(2.5),
  },
  phaseAssignment: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  truncateOverride: {
    fontSize: "0.6875rem",
  },
}));

type Props = {
  indentation: { isLastChild: boolean }[];
  employees: (Employee & { reports: number[] })[];
  phaseOrder: number;
  currentEmployee: Employee & { reports: number[] };
  toggleEmployeeSelected: (ids: number) => unknown;
  selectedEmployeeIds: number[];
  hideDropdown?: boolean;
};

export function EmployeeCard({
  indentation,
  employees,
  phaseOrder,
  currentEmployee,
  toggleEmployeeSelected,
  selectedEmployeeIds,
  hideDropdown = false,
}: Props): JSX.Element {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState<boolean>(
    indentation.length === 0
  );
  const { phaseConfiguration } = usePhaseConfiguration();

  const nextManagementLayer = getManagementLayer(employees, currentEmployee.id);
  const isSelected = selectedEmployeeIds.includes(currentEmployee.id);

  const isManagementChainAssignedToAnotherPhase =
    isEmployeeManagementChainAssignedToAnotherPhase(
      phaseConfiguration,
      phaseOrder,
      {
        ...currentEmployee,
        reports: currentEmployee.reports.map((report) => ({
          id: report,
        })),
      }
    );

  const isAssignedToAnotherPhase = isEmployeeAssignedToAnotherPhase(
    phaseConfiguration,
    phaseOrder,
    currentEmployee
  );

  return (
    <div>
      <ExplanatoryTooltip
        width="340px"
        title={
          isManagementChainAssignedToAnotherPhase
            ? "This person can't be selected for this phase"
            : isAssignedToAnotherPhase
              ? "This person is assigned to another phase"
              : undefined
        }
        body={
          isManagementChainAssignedToAnotherPhase
            ? `${currentEmployee.displayName} cannot be added to this phase 
              because someone in their reporting chain is assigned to a 
              participating phase before this phase.`
            : isAssignedToAnotherPhase
              ? `To add them to this phase, first select the phase they're
               assigned to and remove them from that phase.`
              : ""
        }
        placement="top"
        disabled={
          !isAssignedToAnotherPhase && !isManagementChainAssignedToAnotherPhase
        }
        enterDelay={1_000}
      >
        <div>
          <div
            className={clsx(classes.container, classes.copy, {
              [classes.selectedContainer]: isSelected,
              [classes.cannotBeAssigned]:
                isAssignedToAnotherPhase ||
                isManagementChainAssignedToAnotherPhase,
            })}
          >
            <div
              className={clsx(classes.expandButton, {
                [classes.expandButtonDisabled]:
                  nextManagementLayer.length === 0,
              })}
              onClick={
                nextManagementLayer.length === 0
                  ? undefined
                  : () => setIsExpanded(!isExpanded)
              }
            >
              {hideDropdown ? null : isExpanded ? (
                <ChevronDownIcon color={GRAY_4} />
              ) : (
                <ChevronRightIcon
                  color={nextManagementLayer.length === 0 ? WHITE : GRAY_4}
                />
              )}
            </div>
            <div
              className={classes.rightContainer}
              onClick={
                isAssignedToAnotherPhase ||
                isManagementChainAssignedToAnotherPhase
                  ? undefined
                  : () => toggleEmployeeSelected(currentEmployee.id)
              }
            >
              <AssembleTruncatedTypography
                className={classes.truncateOverride}
                variant="productEyebrowMedium"
                textColor={GRAY_4}
              >
                {currentEmployee.activeEmployment?.jobTitle}
              </AssembleTruncatedTypography>
              <span className={classes.decorator}>
                <AssembleTypography
                  variant="productParagraphMedium"
                  className={classes.copy}
                  noWrap
                >
                  {currentEmployee.displayName}
                </AssembleTypography>
                <div className={classes.phaseAssignment}>
                  <AddToPhaseIcon color={GRAY_4} />
                  <AssembleTypography
                    textColor={GRAY_4}
                    variant="productParagraphMedium"
                  >
                    {getAssignedPhase(phaseConfiguration, currentEmployee) ??
                      "-"}
                  </AssembleTypography>
                </div>
              </span>
            </div>
          </div>
        </div>
      </ExplanatoryTooltip>
      {isExpanded && nextManagementLayer.length > 0 ? (
        <div className={classes.managementLayer}>
          {nextManagementLayer.map((nextManager, c) => {
            const childIndentation = indentation.concat([
              { isLastChild: c === nextManagementLayer.length - 1 },
            ]);

            return (
              <EmployeeCard
                key={`employee-${currentEmployee.id}`}
                indentation={childIndentation}
                phaseOrder={phaseOrder}
                currentEmployee={nextManager}
                employees={employees}
                toggleEmployeeSelected={toggleEmployeeSelected}
                selectedEmployeeIds={selectedEmployeeIds}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

EmployeeCard.fragments = {
  employee: gql`
    fragment EmployeeCard_employee on Employee2 {
      id
      managerId
      displayName
      activeEmployment {
        id
        jobTitle
      }
    }
  `,
};
