import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { ImportActualVariableCompInput } from "../__generated__/graphql";

const IMPORT_ACTUAL_VARIABLE_COMP = gql`
  mutation ImportActualVariableComp($data: [ImportActualVariableCompInput!]!) {
    importActualVariableComp(data: $data) {
      valid {
        id
      }
      invalid
    }
  }
`;
export function useImportActualVariableComp() {
  const [actualVariableCompUpload] = useMutation(IMPORT_ACTUAL_VARIABLE_COMP);

  const upload = useCallback(
    async (data: ImportActualVariableCompInput[]) => {
      return (await actualVariableCompUpload({ variables: { data } })) as {
        data: {
          importActualVariableComp: { valid: unknown[]; invalid: string[] };
        };
      };
    },
    [actualVariableCompUpload]
  );

  return upload;
}
