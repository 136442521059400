import { useQuery } from "@apollo/client";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BenefitsPackageInput,
  useCreateBenefitsPackage,
  useUpdateBenefitsPackage,
} from "../../../mutations/BenefitsPackage";
import { GET_BENEFITS_PACKAGES } from "../../../queries";
import { GetBenefitsPackages } from "../../../__generated__/graphql";
import Loading from "../Loading";
import { BenefitsEditorFormState } from "./Editor/BenefitsEditorFormState";

interface Props {
  mode: "create" | "copy" | "edit";
}
export function EditBenefitsPackagePage({ mode }: Props): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { data, loading } = useQuery<GetBenefitsPackages>(
    GET_BENEFITS_PACKAGES
  );

  const benefitsPackage = data?.benefitsPackages.find(
    (p) => p.id.toString() === id
  );

  const createBenefitsPackage = useCreateBenefitsPackage();
  const updateBenefitsPackage = useUpdateBenefitsPackage();

  const handleCreate = useCallback(
    async (newBenefitsPackage: BenefitsPackageInput) => {
      const createdPackage = await createBenefitsPackage(newBenefitsPackage);

      if (createdPackage === undefined) {
        return false;
      }

      navigate(`/settings/benefits/${createdPackage.id}`);
      return true;
    },
    [createBenefitsPackage, navigate]
  );

  const handleUpdate = useCallback(
    async (newBenefitsPackage: BenefitsPackageInput) => {
      if (benefitsPackage === undefined) {
        return false;
      }
      await updateBenefitsPackage(benefitsPackage.id, newBenefitsPackage);
      return true;
    },
    [benefitsPackage, updateBenefitsPackage]
  );

  if (loading) {
    return <Loading />;
  }

  if (mode === "create") {
    return <BenefitsEditorFormState onSubmit={handleCreate} />;
  }

  if (benefitsPackage === undefined) {
    return <>An error occurred</>;
  }

  if (mode === "copy") {
    return (
      <BenefitsEditorFormState
        onSubmit={handleCreate}
        initialFormBenefitsPackage={{
          ...benefitsPackage,
          name: `Copy of ${benefitsPackage.name}`,
        }}
      />
    );
  } else {
    // mode === 'edit'
    return (
      <BenefitsEditorFormState
        onSubmit={handleUpdate}
        persistedBenefitsPackage={benefitsPackage}
        initialFormBenefitsPackage={benefitsPackage}
      />
    );
  }
}
