import { gql } from "@apollo/client";
import {
  FormControl,
  makeStyles,
  MenuItem,
  Switch,
  TextField,
} from "@material-ui/core";
import { AssembleTypography } from "../../../../components/AssembleTypography";
import FormBox from "../../../../components/FormBox";
import { GRAY_2 } from "../../../../theme";
import { EmployeeBandAccessType } from "../../../../__generated__/graphql";

const useStyles = makeStyles((theme) => ({
  text: {
    color: GRAY_2,
  },
  subHeader: {
    paddingBottom: theme.spacing(1.5),
  },
  form: {
    marginBottom: theme.spacing(4),
  },
}));

type Props = {
  employeeBandAccess: EmployeeBandAccessType;
  handleBandAccessChange: (newBandAccess: EmployeeBandAccessType) => unknown;
};

export const CompensationBandsForm = ({
  employeeBandAccess,
  handleBandAccessChange,
}: Props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.subHeader}>
        <AssembleTypography variant="h5" className={classes.text}>
          Compensation Bands
        </AssembleTypography>
        <AssembleTypography variant="productSmall" className={classes.text}>
          Control which compensation bands employees can see in their Employee
          Portal.
        </AssembleTypography>
      </div>
      <div className={classes.form}>
        <FormControl fullWidth>
          <FormBox
            key={"compensationBand"}
            control={<Switch />}
            label="Show compensation bands"
            name="Show compensation bands"
            onChange={() =>
              handleBandAccessChange(
                employeeBandAccess !== EmployeeBandAccessType.NONE
                  ? EmployeeBandAccessType.NONE
                  : EmployeeBandAccessType.CURRENT_BAND
              )
            }
            checked={employeeBandAccess !== EmployeeBandAccessType.NONE}
            reverse
          />
          {employeeBandAccess !== EmployeeBandAccessType.NONE && (
            <TextField
              select
              label="Show"
              variant="outlined"
              value={employeeBandAccess}
              onChange={(e) =>
                handleBandAccessChange(e.target.value as EmployeeBandAccessType)
              }
            >
              <MenuItem
                selected={
                  employeeBandAccess === EmployeeBandAccessType.CURRENT_BAND
                }
                value={EmployeeBandAccessType.CURRENT_BAND}
              >
                Employee's Current Band Only
              </MenuItem>
              <MenuItem
                selected={
                  employeeBandAccess ===
                  EmployeeBandAccessType.CURRENT_AND_NEXT_BAND
                }
                value={EmployeeBandAccessType.CURRENT_AND_NEXT_BAND}
              >
                Employee's Current and Next Band
              </MenuItem>
            </TextField>
          )}
        </FormControl>
      </div>
    </>
  );
};

CompensationBandsForm.fragments = {
  organization: gql`
    fragment CompensationBandsForm_permissionSettings on Organization {
      id
      permissionSettings {
        id
        employeeBandAccess
      }
    }
  `,
};
