import { gql } from "@apollo/client";
import { Noun } from "@asmbl/shared/permissions";
import { mapMaybe } from "@asmbl/shared/utils";
import { Navigate, useLocation } from "react-router-dom";
import { UserPermissions } from "src/models/UserPermissions";
import { ManagerSelfRedirect_actableManagers as Manager } from "../../../__generated__/graphql";
import { useAuth } from "../../../components/Auth/AuthContext";
import { FilterParam } from "../../../models/FilterParams";
import { useURLSearchParams } from "../../../models/URLSearchParams";

type Props = {
  children: JSX.Element;
  actableManagers: Manager[] | null;
};

// If you are a Comp Admin, HRBP, or already have a Manager Filter, render
// with that. Otherwise, redirect filtered to yourself.
export function ManagerSelfRedirect({
  children,
  actableManagers,
}: Props): JSX.Element {
  const { permissions, employeeId } = useAuth();
  const urlSearchParams = useURLSearchParams();
  const location = useLocation();
  const managerIdParam = urlSearchParams.get(FilterParam.MANAGER);

  const onlyManagerAccess =
    permissions.isManager() &&
    !permissions.canViewGlobal(Noun.CompCycle) &&
    !permissions.isHRBP();

  if (
    onlyManagerAccess &&
    employeeId != null &&
    (managerIdParam === "all" || managerIdParam == null)
  ) {
    // Redirect Managers of Managers to their own page by default
    return (
      <Navigate to={{ ...location, search: `manager=${employeeId}` }} replace />
    );
  }

  // if the manager param is all or null, then we essentially have
  // no manager filter
  const managerParamFilteringForAll =
    managerIdParam === "all" ||
    managerIdParam === "null" ||
    managerIdParam == null;

  // if we are an HRBP user, then we need to filter for a manager, it will
  // either be the manager that is already present in the URL param, the
  // current user's manager, or an arbitrary supporting manager, or a list
  // of managers

  if (permissions.isHRBP() && actableManagers != null) {
    // if we are filtering for everyone, then we have to redirect to
    // filter for a single manager
    if (managerParamFilteringForAll) {
      const managerId = fallbackManagerId(permissions, employeeId);

      return (
        <Navigate
          to={{ pathname: location.pathname, search: `manager=${managerId}` }}
          replace
        />
      );
    } else {
      // if we are not filtering for everyone we have to force a filter
      // to be a single manager and we have to make sure that the manager
      // is someone we are able to act on behalf of

      // managerIdParam is a list of  manager ids
      if (managerIdParam.includes(",")) {
        const managerIds = managerIdParam.split(",");

        const managerId =
          managerIds.find((id) =>
            actableManagers.map((manager) => manager.id).includes(parseInt(id))
          ) ?? fallbackManagerId(permissions, employeeId);

        return (
          <Navigate
            to={{
              pathname: location.pathname,
              search: `manager=${managerId}`,
            }}
            replace
          />
        );
      } else {
        // managerIdParam is a single manager id
        const managerId = parseInt(managerIdParam);

        if (!actableManagers.map((manager) => manager.id).includes(managerId)) {
          return (
            <Navigate
              to={{
                pathname: location.pathname,
                search: `manager=${fallbackManagerId(permissions, employeeId)}`,
              }}
              replace
            />
          );
        }
      }

      return children;
    }
  }

  return children;
}

// little helper function used to make sure we fallback to a manager that
// the current user has access to
function fallbackManagerId(
  permissions: UserPermissions,
  employeeId: number | null
): number {
  const supportingManagerIds = mapMaybe(
    permissions.nounScopes?.Employee.view?.supportingManagerEmployeeIDs ?? [],
    (id) => id
  );

  return employeeId ?? supportingManagerIds[0];
}

ManagerSelfRedirect.fragments = {
  actableManagers: gql`
    fragment ManagerSelfRedirect_actableManagers on MinimalEmployee2 {
      id
      displayName
    }
  `,
};
