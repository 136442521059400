import { useQuery } from "@apollo/client";
import { LinearProgress, Typography } from "@material-ui/core";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { NoOfferSettingsConfigured } from "../../components/EmptyState/EmptyState";
import { OfferFrames } from "../../components/OfferDisplay/OfferFrames";
import { GET_OFFER } from "../../queries";
import { GetOffer } from "../../__generated__/graphql";

export const OfferDisplay = memo(function OfferDisplay() {
  const { id } = useParams<{ id: string }>();
  const parsedId = id != null ? parseInt(id) : NaN;

  const { data, loading } = useQuery<GetOffer>(GET_OFFER, {
    variables: { offerId: parsedId },
    skip: isNaN(parsedId),
  });

  if (data === undefined) {
    return loading ? (
      <LinearProgress />
    ) : (
      <Typography variant="h5" color="textSecondary">
        Sorry we couldn't find this offer or an error has occurred.
      </Typography>
    );
  }

  const offer = data.offer;
  const offerConfig = data.offerConfig;
  const valuation = data.valuation;
  const compStructure = data.compStructure;

  if (valuation === null || compStructure === null || offerConfig === null) {
    return <NoOfferSettingsConfigured />;
  }

  return (
    <OfferFrames
      offer={offer}
      offerConfig={offerConfig}
      valuation={valuation}
      compStructure={compStructure}
    />
  );
});
