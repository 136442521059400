import { gql } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useState } from "react";
import { AssembleTypography } from "../../../components/AssembleTypography";
import { DangerButton } from "../../../components/Form/DangerButton";
import { useDeleteMarket } from "../../../mutations/Market";
import { MarketDeleter_market } from "../../../__generated__/graphql";

type Props = {
  market: MarketDeleter_market;
  onClose: () => unknown;
};

export function MarketDeleter({ market, onClose }: Props): JSX.Element {
  const deleteMarket = useDeleteMarket(market.id);
  const [confirmationName, setConfirmationName] = useState("");

  function handleDelete() {
    void deleteMarket();
  }

  return (
    <Dialog open onClose={onClose} fullWidth>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        {market.locationGroups.length > 0 ? (
          <>
            <AssembleTypography variant="productParagraphLarge" paragraph>
              Deleting this Market will delete {market.locationGroups.length}{" "}
              Location Groups and their compensation&nbsp;bands.
            </AssembleTypography>
            <AssembleTypography variant="productParagraphLarge" paragraph>
              <b>Locations in deleted Location Groups must be remapped.</b>{" "}
              While Employee data will not be deleted, Assemble cannot calculate
              pay equity nor compa-ratio statistics for Employees in unmapped
              Locations.
            </AssembleTypography>
          </>
        ) : (
          <AssembleTypography variant="productParagraphLarge" paragraph>
            This Market is empty and can be safely deleted.
          </AssembleTypography>
        )}
        <AssembleTypography variant="productParagraphLarge" paragraph>
          Type the name of the Market below to DELETE.
        </AssembleTypography>
        <TextField
          name="confirmation"
          variant="outlined"
          fullWidth
          label="Type the name of the Market to DELETE"
          placeholder={market.name}
          value={confirmationName}
          onChange={(e) => setConfirmationName(e.target.value)}
          InputLabelProps={{ shrink: true }}
          inputProps={{ required: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <DangerButton
          onClick={handleDelete}
          disabled={confirmationName !== market.name}
        >
          Delete Permanently
        </DangerButton>
      </DialogActions>
    </Dialog>
  );
}

MarketDeleter.fragments = {
  market: gql`
    fragment MarketDeleter_market on Market {
      id
      name
      locationGroups {
        id
      }
    }
  `,
};
