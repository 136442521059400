import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  GetPermissionSettings,
  PermissionSettingsInput,
  UpdateOrganizationPermissionSettings,
  UpdateOrganizationPermissionSettingsVariables,
} from "../__generated__/graphql";

export const UPDATE_ORGANIZATION_PERMISSION_SETTINGS = gql`
  mutation UpdateOrganizationPermissionSettings(
    $data: PermissionSettingsInput!
  ) {
    updatePermissionSettings(data: $data) {
      id
      canRecruitersViewAnonymizedStatistics
      teamCompensationAccess
      anonymizedCompensationStatisticsAccess
      anonymizedCompensationEmployeeThreshold
      automatedManagerAccess
    }
  }
`;

export type PermissionSettings =
  GetPermissionSettings["organization"]["permissionSettings"];

export function useUpdateOrganizationPermissionSettings(): (
  data: PermissionSettingsInput,
  current: PermissionSettings
) => Promise<void> {
  const [organizationPermissionSettingsUpdate] = useMutation<
    UpdateOrganizationPermissionSettings,
    UpdateOrganizationPermissionSettingsVariables
  >(UPDATE_ORGANIZATION_PERMISSION_SETTINGS);

  return useCallback(
    async (data: PermissionSettingsInput, current: PermissionSettings) => {
      await organizationPermissionSettingsUpdate({
        variables: { data },
        optimisticResponse: {
          updatePermissionSettings: {
            __typename: "PermissionSettings",
            id: current.id,
            teamCompensationAccess:
              data.teamCompensationAccess ?? current.teamCompensationAccess,
            canRecruitersViewAnonymizedStatistics:
              current.canRecruitersViewAnonymizedStatistics,
            anonymizedCompensationStatisticsAccess:
              data.anonymizedCompensationStatisticsAccess ??
              current.anonymizedCompensationStatisticsAccess,
            anonymizedCompensationEmployeeThreshold:
              data.anonymizedCompensationEmployeeThreshold ??
              current.anonymizedCompensationEmployeeThreshold,
            automatedManagerAccess:
              data.automatedManagerAccess ?? current.automatedManagerAccess,
          },
        },
      });
    },
    [organizationPermissionSettingsUpdate]
  );
}
