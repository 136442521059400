import { gql, useQuery } from "@apollo/client";
import { LinearProgress } from "@material-ui/core";
import { useURLSearchParams } from "../../../models/URLSearchParams";
import { EmployeeDetailLoadingBoundaryQuery } from "../../../__generated__/graphql";
import { EmployeeDetailView } from "./EmployeeDetailView";
import { EmptyEmployeeDetailView } from "src/components/EmptyState/EmptyState";

interface Props {
  id: number;
  compCycleId?: number;
}

export function EmployeeDetailLoadingBoundary({
  id,
  compCycleId,
}: Props): JSX.Element {
  const urlSearchParams = useURLSearchParams();
  const { data, loading, error } = useQuery<EmployeeDetailLoadingBoundaryQuery>(
    EmployeeDetailLoadingBoundary.query,
    { variables: { id, compCycleId } }
  );
  if (!data) {
    return loading ? (
      <LinearProgress />
    ) : (
      <EmptyEmployeeDetailView
        url={`?${urlSearchParams.delete("employee").toString()}`}
        errorMessage={error?.message}
      />
    );
  }

  return <EmployeeDetailView employee={data.employee} />;
}

EmployeeDetailLoadingBoundary.query = gql`
  ${EmployeeDetailView.fragments.employee}
  query EmployeeDetailLoadingBoundaryQuery($id: Int!, $compCycleId: Int) {
    employee(id: $id) {
      id
      ...EmployeeDetailView_employee
    }
  }
`;
