import { gql, useQuery } from "@apollo/client";
import {
  AutomatedManagerAccessSectionLoadingBoundaryQuery,
  PermissionSettingsInput,
} from "src/__generated__/graphql";
import { ErrorView } from "src/components/ErrorView";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { PermissionSettings } from "../../../mutations/PermissionSettings";
import { AutomatedManagerAccessSection } from "./AutomatedManagerAccessSection";

type Props = {
  current: PermissionSettings;
  handleAccessChange: (
    data: PermissionSettingsInput,
    current: PermissionSettings
  ) => Promise<void>;
};

export function AutomatedManagerAccessSectionLoadingBoundary(
  props: Props
): JSX.Element {
  const { data, loading } =
    useQuery<AutomatedManagerAccessSectionLoadingBoundaryQuery>(
      AutomatedManagerAccessSectionLoadingBoundary.query
    );

  if (!data) return loading ? <LoadingSpinner /> : <ErrorView />;

  return (
    <AutomatedManagerAccessSection
      {...props}
      mergeConnectionConfigs={data.mergeConnectionConfigs}
    />
  );
}

AutomatedManagerAccessSectionLoadingBoundary.query = gql`
  ${AutomatedManagerAccessSection.fragment.mergeConnectionConfig}
  query AutomatedManagerAccessSectionLoadingBoundaryQuery {
    mergeConnectionConfigs {
      id
      ...AutomatedManagerAccessSection_mergeConnectionConfig
    }
  }
`;
