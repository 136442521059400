import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import {
  PrimaryRoleName,
  SupportedManagersQuery,
  SupportedManagersQueryVariables,
} from "src/__generated__/graphql";
import { UserPermissions } from "src/models/UserPermissions";
import { useAddSupportedManagersToUserAccessGrant } from "src/mutations/UserAccessGrant";
import { SupportedManagersDrawer } from "./SupportedManagersDrawer";
import { SupportedManagersRow } from "./SupportedManagersRow";
import { SupportedManagersSection } from "./SupportedManagersSection";

type Props = {
  userId: number;
  userName: string;
  supportedManagerIds: number[];
  userPermissions: UserPermissions;
  refreshUser: () => Promise<unknown>;
  isUserInvite: boolean;
  updateUserInvitationPrimaryAccessGrants: (
    role: PrimaryRoleName,
    supportedManagerIds?: number[]
  ) => Promise<unknown>;
};

export function SupportedManagersSectionLoadingBoundary({
  userId,
  userName,
  supportedManagerIds,
  userPermissions,
  refreshUser,
  isUserInvite,
  updateUserInvitationPrimaryAccessGrants,
}: Props) {
  const { data, loading } = useQuery<
    SupportedManagersQuery,
    SupportedManagersQueryVariables
  >(SupportedManagersSectionLoadingBoundary.query, { fetchPolicy: "no-cache" });

  const addSupportingManagersToUserAccessGrant =
    useAddSupportedManagersToUserAccessGrant(userId);

  const handleSave = async (employeeIds: number[]) => {
    if (isUserInvite) {
      await updateUserInvitationPrimaryAccessGrants(
        PrimaryRoleName.HRBP,
        employeeIds
      );
    } else {
      await addSupportingManagersToUserAccessGrant(employeeIds);
      await refreshUser();
    }
  };

  if (data == null) {
    return loading ? (
      <CircularProgress />
    ) : (
      <div>Error loading supported managers data</div>
    );
  }

  return (
    <SupportedManagersSection
      userName={userName}
      supportedManagerIds={supportedManagerIds}
      managers={data.managers}
      handleSave={handleSave}
      userPermissions={userPermissions}
    />
  );
}

SupportedManagersSectionLoadingBoundary.query = gql`
  ${SupportedManagersDrawer.fragments.manager}
  ${SupportedManagersRow.fragments.manager}
  query SupportedManagersQuery {
    managers {
      ...SupportedManagersDrawer_manager
      ...SupportedManagersRow_manager
    }
  }
`;
