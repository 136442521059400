import { Box, makeStyles, Theme } from "@material-ui/core";
import { memo } from "react";
import { WARN, WHITE } from "../../theme";

const useStyles = makeStyles((theme: Theme) => ({
  children: {
    position: "relative",
  },
  band: {
    background: (p: StyleProps) =>
      p.light
        ? WHITE
        : "linear-gradient(148.88deg, #FAA01A 29.51%, #FFB628 85.88%)",
    borderRadius: "0px 0px 5px 5px",
    boxShadow: (p: StyleProps) =>
      p.light ? "0px 0px 2px rgba(10, 36, 64, 0.3)" : "none",
    color: (p: StyleProps) => (p.light ? WARN : WHITE),
    fontSize: "0.75rem",
    fontWeight: 700,
    marginTop: (p: StyleProps) => (p.light ? "0px" : "-5px"),
    marginLeft: "1px",
    marginRight: "1px",
    padding: (p: StyleProps) => {
      if (p.visible) {
        if (p.light) return "3px 10px";
        return theme.spacing(1);
      }
      return "0px";
    },
    transition: "all 200ms",
  },
}));

type StyleProps = {
  light: boolean;
  visible: boolean;
};

interface Props {
  light?: boolean;
  message: string;
  visible: boolean;
  children?: JSX.Element;
}

const ErrorWrapper = ({ children, light = false, message, visible }: Props) => {
  const classes = useStyles({ light, visible });

  return (
    <Box className={classes.children}>
      {children}
      <Box className={classes.band}>{message}</Box>
    </Box>
  );
};

export default memo(ErrorWrapper);
