import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { contramap } from "@asmbl/shared/sort";
import { CondensedTableActualRecurringBonusCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { getActualRecurringBonus } from "../../../../models/CompRecommendation";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableActualRecurringBonusCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();
  const bonus = getActualRecurringBonus(
    employee.compRecommendation?.latestSubmittedItems ?? [],
    employee.subject.activeEmployment?.salary?.currency ?? defaultCurrencyCode
  );

  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(bonus)}
    </AssembleTruncatedTypography>
  );
}

CondensedTableActualRecurringBonusCell2.fragments = {
  participant: gql`
    fragment CondensedTableActualRecurringBonusCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          salary(currencyCode: $currencyCode)
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
        }
      }
    }
  `,
};

CondensedTableActualRecurringBonusCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.ACTUAL_RECURRING_BONUS
);
CondensedTableActualRecurringBonusCell2.id = ColumnIds.ACTUAL_RECURRING_BONUS;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableActualRecurringBonusCell2,
  Header: CondensedTableActualRecurringBonusCell2.Header,
  id: CondensedTableActualRecurringBonusCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableActualRecurringBonusCell2.column = column;
CondensedTableActualRecurringBonusCell2.ordering = ({
  defaultCurrencyCode,
  currencies,
}: {
  defaultCurrencyCode: CurrencyCode;
  currencies: Map<CurrencyCode, Currency>;
}) =>
  contramap((e: Participant) => {
    const actualRecurringBonus = getActualRecurringBonus(
      e.compRecommendation?.latestSubmittedItems ?? [],
      defaultCurrencyCode
    );

    const currentCurrency = currencies.get(actualRecurringBonus.currency);
    const defaultCurrency = currencies.get(defaultCurrencyCode);

    if (currentCurrency == null || defaultCurrency == null) {
      return 0;
    }

    return exchangeFromTo(
      actualRecurringBonus,
      currentCurrency,
      defaultCurrency
    ).value;
  });
