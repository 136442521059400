import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { theme } from "../theme";
import { CurrencyPicker } from "./CurrencyPicker";
import { LocationPicker } from "./LocationPicker";
import { useLocations } from "./LocationsContext";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    position: "absolute",
    top: theme.spacing(3),
    marginLeft: theme.spacing(5),
    left: "50%",
    transform: "translateX(-50%)",
    gap: theme.spacing(1.5),

    "& > *": {
      width: "13rem",
    },
  },
}));

type Props = {
  locked?: boolean;
};

export function AdjustmentsBar({ locked = false }: Props): JSX.Element | null {
  const classes = useStyles();
  const { selectedLocation } = useLocations();

  return (
    <Box className={classes.root}>
      <LocationPicker
        lockedMarket={
          locked && selectedLocation ? selectedLocation[0] : undefined
        }
      />
      <CurrencyPicker
        lockedCurrency={
          // Select the current Market's local currency
          locked && selectedLocation
            ? selectedLocation[0].currencyCode
            : undefined
        }
      />
    </Box>
  );
}
