const MIN_CHAR_CODE = 48; // '0'
const MAX_CHAR_CODE = 126; // '~'
const STEP = 2;

const MIDDLE_CHAR = String.fromCharCode(
  Math.floor((MIN_CHAR_CODE + MAX_CHAR_CODE) / 2)
);

function rankBefore(b: string): string {
  if (b.charCodeAt(0) - STEP > MIN_CHAR_CODE) {
    return String.fromCharCode(b.charCodeAt(0) - STEP);
  }
  return rankBetween(String.fromCharCode(MIN_CHAR_CODE), b);
}

function rankAfter(a: string): string {
  if (a.charCodeAt(0) + STEP < MAX_CHAR_CODE) {
    return String.fromCharCode(a.charCodeAt(0) + STEP);
  }

  return rankBetween(a, String.fromCharCode(MAX_CHAR_CODE));
}

/*
  Generates a string that compares between the two bounding strings.
  Ex:  rankBetween('A', 'C') => 'B', because A < B < C
  Either string may be omitted, in which case a rank is generated
  before or after the given string. If both strings are omitted,
  a generic middle value is provided.
 */
export function rankBetween(a = "", b = ""): string {
  /* We use empty string instead of undefined here
   * to play nicely with recursive calls with `substring` */
  if (a === "" || b === "") {
    if (a !== "") {
      return rankAfter(a);
    }

    if (b !== "") {
      return rankBefore(b);
    }

    return MIDDLE_CHAR;
  }

  const aCode = a.charCodeAt(0);
  const bCode = b.charCodeAt(0);

  if (aCode === bCode) {
    return a[0] + rankBetween(a.substring(1), b.substring(1));
  }

  const targetCode = Math.floor((aCode + bCode) / 2);
  if (targetCode === aCode) {
    // We cannot return the same string we were given, so add an extra character
    return a[0] + rankBetween(a.substring(1));
  }

  return String.fromCharCode(targetCode);
}
