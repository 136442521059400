import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { SettingsIcon } from "src/components/AssembleIcons/Small/SettingsIcon";
import { PURPLE_1 } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";

export function HRISLocationsLinkOpen(): JSX.Element {
  return (
    <Button
      component={Link}
      color="secondary"
      to="#locations"
      endIcon={<SettingsIcon color={PURPLE_1} />}
    >
      <AssembleTypography variant="productParagraphSmall" textColor={PURPLE_1}>
        HRIS Locations
      </AssembleTypography>
    </Button>
  );
}
