import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { captureException } from "@sentry/react";
import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  EmployeeBandAccessType,
  Portal_employee,
  Portal_organization,
} from "../../__generated__/graphql";
import { useTrack } from "../../analytics";
import { AssembleTypography } from "../../components/AssembleTypography";
import { Card } from "../../components/Cards/Card";
import { CardHeader } from "../../components/Cards/CardHeader";
import { useCurrencies } from "../../components/CurrenciesContext";
import { BioHeading } from "../../components/Employee/BioHeading";
import { BubbleRow } from "../../components/Employee/BubbleInfo/BubbleRow";
import { LocationBubble } from "../../components/Employee/BubbleInfo/LocationBubble";
import { ManagerBubble } from "../../components/Employee/BubbleInfo/ManagerBubble";
import { StartDateBubble } from "../../components/Employee/BubbleInfo/StartDateBubble";
import { TenureBubble } from "../../components/Employee/BubbleInfo/TenureBubble";
import { CenteredContent } from "../../components/Layout/CenteredContent";
import { FullHeight } from "../../components/Layout/FullHeightPage";
import { IdAnchor } from "../../components/Layout/IdAnchor";
import { PageHeader, PageTitle } from "../../components/Layout/PageHeader";
import { CompBandTable } from "../../components/Portal/CompBand/CompBandTable";
import { CompensationTimelineChart } from "../../components/Portal/CompensationTimelineChart";
import { TotalEquityGrid } from "../../components/Portal/Equity/TotalEquityGrid";
import { TotalEquityIllustrativeUnitPrice } from "../../components/Portal/Equity/TotalEquityIllustrativeUnitPrice";
import { TotalEquitySliderV2 } from "../../components/Portal/Equity/TotalEquitySliderV2";
import { TotalEquityStickyHeader } from "../../components/Portal/Equity/TotalEquityStickyHeader";
import FormHeader from "../../components/Settings/FormHeader";
import { CompensationTimelineTable } from "../../components/Table/CompensationTimelineTable";
import { useTotalEquitySlider } from "../../components/TotalEquitySlider/TotalEquitySliderContext";
import {
  CashBandName,
  EquityBandName,
  SORTED_BAND_NAMES,
} from "../../constants";
import { getIllustrativeUnitPrice } from "../../models/Equity";
import {
  employeeCompanyHasEquitySetup,
  employeeHasEquity,
} from "../../models/EquityVestEvent";
import { GRAY_1 } from "../../theme";
import { ExcludedBandNamesProvider } from "../LadderDetail/ExcludedBandNameContext";
import { PortalBenefitsGrid } from "./PortalBenefitsGrid";
import { PortalTabs } from "./PortalTabs";
import { TotalCompGrid } from "./TotalCompGrid";

type Props = {
  employee: Portal_employee;
  organization: Portal_organization;
};

const useStyles = makeStyles((theme) => ({
  tabBar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    width: "100%",
    height: "100%",
    gap: theme.spacing(0.5),
  },
  bio: {
    padding: theme.spacing(5, 0),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  content: {
    marginBlock: theme.spacing(5),
  },
  compTable: {
    marginTop: theme.spacing(2),
    borderRadius: "8px",
  },
  logo: {
    height: "32px",
    width: "auto",
    marginBottom: theme.spacing(5),
  },
  pageTitleLink: {
    textDecoration: "none",
    color: GRAY_1,
    "&:visited": {
      textDecoration: "none",
      color: GRAY_1,
    },
  },
  spacer: { paddingBottom: theme.spacing(4) },
}));

export function Portal({ employee, organization }: Props): JSX.Element {
  const classes = useStyles();
  const { trackEvent } = useTrack();
  const equitySliderData = useTotalEquitySlider();
  const { currencies, currenciesList } = useCurrencies();

  const hasEquity =
    employeeHasEquity(employee.equityHoldings) &&
    employeeCompanyHasEquitySetup(
      equitySliderData?.value ?? null,
      getIllustrativeUnitPrice(
        currencies,
        employee.equityHoldings.grants,
        employee.equityHoldings.valuationCurrency
      )
    );

  const hasLogo =
    organization.logoUrlPath !== null && organization.logoUrlPath.length > 0;

  const hasCartaGrants =
    employee.equityHoldings.grants?.some((grant) => grant.fromCarta) ?? false;

  // this ensures only salary-related cash comps are displayed
  // in the band table (salary, recurring bonus, commission). SORTED_BAND_NAMES
  // includes EquityBandName, so the type is defined here despite there being only cash bands
  const includedBands: (CashBandName | EquityBandName)[] = [
    CashBandName.SALARY,
    CashBandName.COMMISSION,
    CashBandName.RECURRING_BONUS,
  ];

  const initialExcludedBands = new Set(
    SORTED_BAND_NAMES.filter((band) => !includedBands.includes(band))
  );

  const hasBandAccess =
    organization.permissionSettings.employeeBandAccess !==
      EmployeeBandAccessType.NONE &&
    employee.activeEmployment?.position != null;

  const employeeCurrency = hasBandAccess
    ? employee.activeEmployment?.salary?.currency
    : undefined;

  useEffect(() => {
    trackEvent({
      area: "Employee Portal",
      subArea: "Portal Home",
      object: "Employee Portal",
      action: "Viewed",
    });
  }, [trackEvent]);

  const handleHomeClick = () =>
    trackEvent({
      area: "Employee Portal",
      subArea: "Header Nav",
      object: "Portal Header Nav Item",
      navigationItem: "Home",
      action: "Clicked",
    });

  const headerOffset = hasEquity
    ? equitySliderData
      ? "large"
      : "small"
    : undefined;

  useEffect(() => {
    if (hasBandAccess && employeeCurrency == null) {
      captureException(
        new Error("Employee currency not found for band visualization"),
        {
          extra: {
            employeeId: employee.id,
            activeEmploymentId: employee.activeEmployment?.id,
            targetCurrency: employee.activeEmployment?.salary?.currency,
            viewableCurrencies: currenciesList,
          },
        }
      );
    }
  }, [currenciesList, employee, employeeCurrency, hasBandAccess]);

  return (
    <FullHeight>
      <PageHeader>
        <div className={classes.tabBar}>
          <RouterLink
            to="#info"
            className={classes.pageTitleLink}
            onClick={handleHomeClick}
          >
            <PageTitle>Employee Portal</PageTitle>
          </RouterLink>

          <PortalTabs
            hasEquity={hasEquity}
            hasBenefits={employee.benefitsPackage !== null}
            hasBands={hasBandAccess}
            currentBandOnly={
              organization.permissionSettings.employeeBandAccess ===
              EmployeeBandAccessType.CURRENT_BAND
            }
            totalRewardsStatementUrl={employee.totalRewardsStatementUrl}
          />
        </div>
      </PageHeader>
      <FullHeight sticky>
        <IdAnchor id="info" scrollOffset={headerOffset}>
          <CenteredContent variant="default" className={classes.bio}>
            {hasLogo && (
              <img
                className={classes.logo}
                src={organization.logoUrlPath ?? undefined}
                alt="Company logo"
              />
            )}
            <BioHeading
              employee={employee}
              equityHoldings={employee.equityHoldings}
              showBanner={true}
            />
            <BubbleRow>
              <ManagerBubble employee={employee} />
              <LocationBubble employee={employee} />
              <StartDateBubble employee={employee} />
              <TenureBubble employee={employee} />
            </BubbleRow>
          </CenteredContent>
        </IdAnchor>
        {hasEquity && (
          <TotalEquityStickyHeader
            height={equitySliderData ? "large" : "small"}
          >
            {equitySliderData ? (
              <TotalEquitySliderV2
                {...equitySliderData}
                valuationCurrency={
                  employee.equityHoldings.valuationCurrency.code
                }
              />
            ) : (
              <TotalEquityIllustrativeUnitPrice
                grants={employee.equityHoldings.grants}
                valuationCurrency={employee.equityHoldings.valuationCurrency}
              />
            )}
          </TotalEquityStickyHeader>
        )}
        <IdAnchor id="total-compensation" scrollOffset={headerOffset}>
          <CenteredContent className={classes.content}>
            <FormHeader
              header="Total Compensation"
              description={
                <AssembleTypography variant="productParagraphLarge">
                  This section shows the breakdown of your total compensation.
                </AssembleTypography>
              }
            />
            <TotalCompGrid employee={employee} />
          </CenteredContent>
        </IdAnchor>
        {hasBandAccess && employeeCurrency != null && (
          <IdAnchor id="comp-band" scrollOffset={headerOffset}>
            <CenteredContent className={classes.content}>
              {organization.permissionSettings.employeeBandAccess ===
              EmployeeBandAccessType.CURRENT_BAND ? (
                <FormHeader
                  header="Cash Compensation Band"
                  description={
                    <AssembleTypography variant="productParagraphLarge">
                      Your current Position is{" "}
                      {employee.activeEmployment?.position?.name}. The
                      compensation band below establishes a minimum and maximum
                      amount of pay for this Position.
                    </AssembleTypography>
                  }
                />
              ) : (
                <FormHeader
                  header="Cash Compensation Band Comparison"
                  description={
                    <AssembleTypography variant="productParagraphLarge">
                      Your current Position is{" "}
                      {employee.activeEmployment?.position?.name}. The
                      compensation band below establishes a minimum and maximum
                      amount of pay for this Position and the level above it.
                    </AssembleTypography>
                  }
                />
              )}
              <ExcludedBandNamesProvider
                initialExcludedBands={initialExcludedBands}
              >
                <CompBandTable
                  employee={employee}
                  selectedCurrencyCode={employeeCurrency}
                />
              </ExcludedBandNamesProvider>
            </CenteredContent>
          </IdAnchor>
        )}
        {hasEquity && (
          <IdAnchor id="equity" scrollOffset={headerOffset}>
            <CenteredContent className={classes.content}>
              <FormHeader
                header="Total Equity Summary"
                description={
                  <>
                    <AssembleTypography variant="productParagraphLarge">
                      The estimated value of your equity holdings include your
                      vested and unvested equity, and is based on the
                      illustrative equity unit price.
                    </AssembleTypography>
                    {hasCartaGrants && (
                      <AssembleTypography variant="productParagraphLarge">
                        If you'd like to take actions on your equity or view
                        more detail,{" "}
                        <a
                          href="https://www.carta.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          click here
                        </a>{" "}
                        to view it in Carta.
                      </AssembleTypography>
                    )}
                  </>
                }
              />
              <TotalEquityGrid equityHoldings={employee.equityHoldings} />
            </CenteredContent>
          </IdAnchor>
        )}

        <IdAnchor id="timeline" scrollOffset={headerOffset}>
          <CenteredContent className={classes.content}>
            <FormHeader
              header="Compensation Timeline"
              description={
                <AssembleTypography variant="productParagraphLarge">
                  This represents your compensation history throughout your
                  tenure at {organization.name}.
                </AssembleTypography>
              }
            />
            <Card>
              <CardHeader header="Total Cash Compensation Timeline" />
              <CompensationTimelineChart
                cashCompensations={employee.cashCompensation ?? []}
                valuationCurrency={
                  employee.equityHoldings.valuationCurrency.code
                }
              />
            </Card>
            <CompensationTimelineTable
              compChanges={employee.cashCompensation ?? []}
              className={classes.compTable}
            />
          </CenteredContent>
        </IdAnchor>

        {employee.benefitsPackage !== null && (
          <IdAnchor id="benefits" scrollOffset={headerOffset}>
            <CenteredContent>
              <FormHeader
                header="Benefits & Perks"
                description={
                  <AssembleTypography variant="productParagraphLarge">
                    Your benefits are the perks, programs, and opportunities
                    available to you as an employee of {organization.name}.
                  </AssembleTypography>
                }
              />
              <PortalBenefitsGrid benefitsPackage={employee.benefitsPackage} />
            </CenteredContent>
          </IdAnchor>
        )}
        <div className={classes.spacer}></div>
      </FullHeight>
    </FullHeight>
  );
}

Portal.fragments = {
  employee: gql`
    ${BioHeading.fragments.employee}
    ${ManagerBubble.fragments.employee}
    ${LocationBubble.fragments.employee}
    ${StartDateBubble.fragments.employee}
    ${TenureBubble.fragments.employee}
    ${TotalCompGrid.fragments.employee}
    ${CompBandTable.fragments.employee}
    ${PortalTabs.fragments.employee}
    ${TotalEquityGrid.fragments.equityHoldings}
    ${BioHeading.fragments.equityHoldings}
    ${TotalEquityIllustrativeUnitPrice.fragments.equityGrants}
    ${CompensationTimelineChart.fragments.cashCompensation}
    ${CompensationTimelineChart.fragments.valuationCurrency}
    ${CompensationTimelineTable.fragments.cashCompensation}
    ${PortalBenefitsGrid.fragments.benefitsPackage}
    fragment Portal_employee on Employee {
      ...BioHeading_employee
      ...ManagerBubble_employee
      ...LocationBubble_employee
      ...StartDateBubble_employee
      ...TenureBubble_employee
      ...TotalCompGrid_employee
      ...CompBandTable_employee
      ...PortalTabs_employee

      activeEmployment {
        id
        salary
        position {
          id
          name
        }
      }
      equityHoldings {
        id
        grants {
          id
          sharePrice
          fromCarta
          ...TotalEquityIllustrativeUnitPrice_equityGrants
        }
        valuationCurrency {
          id
          code
          exchangeRate
        }
        vestingInformation {
          vestEvents {
            grantName
            vestingDate
            unitCount
            grant {
              sharePrice
            }
          }
        }
        ...TotalEquityGrid_equityHoldings
        ...BioHeading_equityHoldings
        ...CompensationTimelineChart_valuationCurrency
      }

      cashCompensation {
        employeeId
        type
        activeAt
        annualCashEquivalent
        ...CompensationTimelineChart_cashCompensation
        ...CompensationTimelineTable_cashCompensation
      }

      benefitsPackage {
        id
        ...PortalBenefitsGrid_benefitsPackage
      }
    }
  `,
  organization: gql`
    fragment Portal_organization on Organization {
      id
      name
      logoUrlPath
      permissionSettings {
        id
        employeeBandAccess
      }
    }
  `,
};
