import { gql } from "@apollo/client";
import { CompCycleDisplay } from "../../../../components/CompCycle/types";
import {
  ReportingActualTier_compCycleBudget as CompCycleBudget,
  ReportingActualTier_compCycleData as CompCycleData,
  ReportingActualTier_manager as Manager,
  ReportingActualTier_reports as Report,
} from "../../../../__generated__/graphql";
import { IndentationCells } from "../../Budgets/Table/Connectors";
import { ActualRow } from "./ActualRow";
import { ActualSummarizedRow } from "./ActualSummarizedRow";

type Props = {
  manager: Manager | null;
  reports: Report[];
  rootBudget: CompCycleBudget | null;
  organizationName: string;
  varianceDisplay: CompCycleDisplay;
  compCycleData: CompCycleData;
  indentation: IndentationCells;
};

export function ReportingActualTier({
  manager,
  reports,
  rootBudget,
  organizationName,
  varianceDisplay,
  compCycleData,
  indentation,
}: Props): JSX.Element {
  return (
    <>
      <ActualSummarizedRow
        manager={manager}
        reports={reports}
        rootBudget={rootBudget}
        organizationName={organizationName}
        varianceDisplay={varianceDisplay}
        compCycleData={compCycleData}
        indentation={indentation}
      />

      {reports
        .filter(
          (employee) =>
            employee.indirectReports.length > 0 ||
            employee.directReports.length > 0
        )
        .map((employee, idx, filteredEmployees) => {
          return (
            <ActualRow
              key={idx}
              employee={employee}
              organizationName={organizationName}
              varianceDisplay={varianceDisplay}
              expandable={employee.indirectReports.length > 0}
              compCycleData={compCycleData}
              indentation={indentation}
              isLastRow={idx === filteredEmployees.length - 1}
            />
          );
        })}
    </>
  );
}

ReportingActualTier.fragments = {
  compCycleBudget: gql`
    ${ActualSummarizedRow.fragments.compCycleBudget}
    fragment ReportingActualTier_compCycleBudget on CompCycleBudget {
      compCycleId
      employeeId
      ...ActualSummarizedRow_compCycleBudget
    }
  `,
  manager: gql`
    ${ActualSummarizedRow.fragments.manager}
    fragment ReportingActualTier_manager on Employee {
      id
      ...ActualSummarizedRow_manager
    }
  `,
  reports: gql`
    ${ActualSummarizedRow.fragments.reports}
    ${ActualRow.fragments.employee}
    fragment ReportingActualTier_reports on Employee {
      id

      directReports {
        id
      }

      indirectReports {
        id
      }

      ...ActualRow_employee
      ...ActualSummarizedRow_reports
    }
  `,
  compCycleData: gql`
    ${ActualRow.fragments.compCycleData}
    ${ActualSummarizedRow.fragments.compCycleData}
    fragment ReportingActualTier_compCycleData on CompCycle {
      id
      ...ActualRow_compCycleData
      ...ActualSummarizedRow_compCycleData
    }
  `,
};
