import { gql, useQuery } from "@apollo/client";
import { ReactNode, createContext, useCallback, useContext } from "react";
import {
  UpsertUserView_userView as UserView,
  UserViewContextQuery,
  UserViewContextQueryVariables,
} from "src/__generated__/graphql";
import { useAuth } from "src/components/Auth/AuthContext";
import { USER_VIEW_FRAGMENT, useUpsertUserView } from "src/mutations/UserView";

export type UserViewJson = {
  [trackableObject: string]: {
    [compCycleId: string]: {
      [trackableKey: string]: {
        [employeeId: string]: number;
      };
    };
  };
};

type UserViewContextValue = {
  userView: UserView | null | undefined;
  upsertUserView: (data: UserViewJson) => void;
};

export const UserViewContext = createContext<UserViewContextValue>({
  userView: null,
  upsertUserView: () => new Object(),
});

export function useUserView() {
  return useContext(UserViewContext);
}

export const UserViewContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { data } = useQuery<
    UserViewContextQuery,
    UserViewContextQueryVariables
  >(UserViewContextProvider.query, { pollInterval: 30_000 });

  const upsertUserView = useUpsertUserView();
  const { userId } = useAuth();

  const handleUserViewChange = useCallback(
    async (json: UserViewJson) => {
      if (userId == null) return;

      await upsertUserView({
        json,
      });
    },
    [upsertUserView, userId]
  );

  return (
    <UserViewContext.Provider
      value={{
        userView: data?.whoami?.userView,
        upsertUserView: handleUserViewChange,
      }}
    >
      {children}
    </UserViewContext.Provider>
  );
};

UserViewContextProvider.query = gql`
  ${USER_VIEW_FRAGMENT}
  query UserViewContextQuery {
    whoami {
      userView {
        ...UpsertUserView_userView
      }
    }
  }
`;
