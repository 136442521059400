import { gql } from "@apollo/client";
import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { canEmployeeReceiveInvite } from "../../../../models/Employee";
import { USER_INVITE_FIELDS } from "../../../../mutations/User";
import { PortalBulkInvite_employee as Employee } from "../../../../__generated__/graphql";
import { PortalDialog } from "./PortalDialog";

type Props = {
  employees: Employee[];
  onInvite: () => unknown;
};

const useStyles = makeStyles(() => ({
  buttonShadow: {
    boxShadow: "0px 1px 3px rgba(65, 54, 241, 0.5) !important",
  },
}));

export const PortalBulkInvite = ({ employees, onInvite }: Props) => {
  const classes = useStyles();
  const newUserCount = getInvitedEmpCount(employees);
  const empNumber =
    newUserCount === 1 ? "1 employee" : `${newUserCount} employees`;

  return (
    <PortalDialog
      onDialogClick={onInvite}
      title={`Invite ${empNumber} to Assemble`}
      actionButtonTitle="Invite"
      body="We'll send employees without accounts or existing invites an email to sign up for Assemble and see their Employee Portal."
    >
      {(onClick) => (
        <Button
          variant="contained"
          color="primary"
          onClick={onClick}
          disabled={newUserCount === 0}
          className={clsx({
            [classes.buttonShadow]: newUserCount > 0,
          })}
        >
          Invite to Employee Portal
        </Button>
      )}
    </PortalDialog>
  );
};

const getInvitedEmpCount = (employees: Employee[]): number => {
  return employees.filter(canEmployeeReceiveInvite).length;
};

PortalBulkInvite.fragments = {
  employee: gql`
    ${USER_INVITE_FIELDS}
    fragment PortalBulkInvite_employee on Employee {
      id
      user {
        id
      }
      latestUserInvite {
        ...UserInviteFields
      }
    }
  `,
};
