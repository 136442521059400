import { Button } from "@material-ui/core";
import { useState } from "react";
import { MarketDataUploadModalContainer } from "./MarketDataUploadModalContainer";

type Props = {
  refreshData?: () => void;
};

export function MarketDataUploadButton({
  refreshData = () => {
    // noop
  },
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        size="medium"
        style={{ height: "100%" }}
        variant="contained"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        Add Market Data
      </Button>
      <MarketDataUploadModalContainer
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        refreshData={refreshData}
      />
    </>
  );
}
