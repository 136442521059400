import { contramap } from "@asmbl/shared/sort";
import { isEmptyString } from "@asmbl/shared/utils";
import { LocationRow } from "../LocationsTable";

export function MarketCell({
  value,
}: {
  value: { id: number; name: string } | null;
}): JSX.Element {
  return (
    <>{value === null || isEmptyString(value.name) ? "N/A" : value.name}</>
  );
}

Object.assign(MarketCell, {
  Cell: MarketCell,
  id: "market" as const,
  Header: "Market" as const,
  accessor: "market" as const,
  width: 1,
  ordering: contramap((location: LocationRow) => location.market?.name ?? ""),
});
