import { gql, useQuery } from "@apollo/client";
import { LinearProgress } from "@material-ui/core";
import {
  CostToMoveOuterPageQuery,
  CostToMoveOuterPageQueryVariables,
  Noun,
} from "src/__generated__/graphql";
import { NoCompStructure } from "src/components/EmptyState/EmptyState";
import { ErrorView } from "src/components/ErrorView";
import { UserPermissions } from "src/models/UserPermissions";
import { CostToMovePage } from "./CostToMovePage";

export function CostToMovePageLoadingBoundary(): JSX.Element {
  const { data, error } = useQuery<
    CostToMoveOuterPageQuery,
    CostToMoveOuterPageQueryVariables
  >(CostToMovePageLoadingBoundary.query);

  if (!data) {
    if (error) {
      return <ErrorView text={error.message} />;
    }
    return <LinearProgress />;
  }

  if (!data.compStructure) {
    return <NoCompStructure />;
  }

  return (
    <CostToMovePage
      compStructure={data.compStructure}
      managers={data.managers}
      departments={data.departments}
      markets={data.markets}
    />
  );
}

CostToMovePageLoadingBoundary.query = gql`
  ${CostToMovePage.fragments.compStructure}
  ${CostToMovePage.fragments.employee}
  ${CostToMovePage.fragments.department}
  ${CostToMovePage.fragments.market}
  query CostToMoveOuterPageQuery {
    compStructure {
      id
      ...CostToMovePage_compStructure
    }
    managers {
      id
      ...CostToMovePage_employee
    }
    departments {
      id
      ...CostToMovePage_department
    }
    markets {
      id
      ...CostToMovePage_market
    }
  }
`;

CostToMovePageLoadingBoundary.canAccess = (
  permissions: UserPermissions
): boolean => {
  return [
    Noun.Employee,
    Noun.EmployeeCashCompensation,
    Noun.CashBand,
    Noun.CompStructure,
  ].every((noun) => permissions.canViewGlobal(noun));
};

CostToMovePageLoadingBoundary.path = "band-placement";
