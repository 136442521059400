import { gql, useQuery } from "@apollo/client";
import { CompCycleOrganizationChartLoadingBoundaryQuery } from "src/__generated__/graphql";
import {
  CompCycleData,
  CompCycleDataChangeHandler,
} from "../CompCycle/CompCycleWizard/types";
import { ErrorView } from "../ErrorView";
import { findMissingNodes } from "../OrganizationHierarchy/shared/utils";
import { ChartButtonBar } from "./ChartButtonBar";
import { CompCycleOrganizationChart } from "./CompCycleOrganizationChart";
import { CompCycleOrganizationChartLoader } from "./CompCycleOrganizationChartLoader";
import { CompCycleOrganizationChartView } from "./CompCycleOrganizationChartView";
import { MissingNodesView } from "./MissingNodesView";

type Props = {
  onConfigureHome: () => void;
  handleChange: CompCycleDataChangeHandler;
  wizardData: CompCycleData;
  updateStep: (step: number) => unknown;
};

export function CompCycleOrganizationChartLoadingBoundary({
  onConfigureHome,
  handleChange,
  wizardData,
  updateStep,
}: Props): JSX.Element {
  const { data, loading, error } =
    useQuery<CompCycleOrganizationChartLoadingBoundaryQuery>(
      CompCycleOrganizationChartLoadingBoundary.query
    );

  if (!data) {
    return loading ? (
      <CompCycleOrganizationChartLoader />
    ) : (
      <ErrorView text={error?.message} />
    );
  }

  const inactiveManagers = findMissingNodes(data.employee2s.employees);

  if (inactiveManagers.length > 0) {
    return (
      <MissingNodesView
        inactiveManagers={inactiveManagers}
        onConfigureHome={onConfigureHome}
        updateStep={updateStep}
      />
    );
  }

  return (
    <CompCycleOrganizationChartView
      wizardData={wizardData}
      handleChange={handleChange}
      onConfigureHome={onConfigureHome}
    >
      {(chart) => (
        <>
          <ChartButtonBar
            chart={chart}
            onConfigureHome={onConfigureHome}
            updateStep={updateStep}
            handleChange={handleChange}
          />
          <CompCycleOrganizationChart
            wizardData={wizardData}
            data={data.employee2s.employees}
            chart={chart}
          />
        </>
      )}
    </CompCycleOrganizationChartView>
  );
}

CompCycleOrganizationChartLoadingBoundary.query = gql`
  ${CompCycleOrganizationChart.fragments.employee}
  query CompCycleOrganizationChartLoadingBoundaryQuery {
    employee2s {
      employees {
        id
        ...CompCycleOrganizationChart_employee
      }
    }
  }
`;
