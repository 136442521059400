import { gql, useQuery } from "@apollo/client";
import { LinearProgress } from "@material-ui/core";
import {
  ReportsEnum,
  SupportingManagerBannerLoadingBoundaryQuery,
  SupportingManagerBannerLoadingBoundaryQueryVariables,
} from "src/__generated__/graphql";
import { ErrorView } from "src/components/ErrorView";
import { useURLSearchParams } from "src/models/URLSearchParams";
import { SupportingManagerBanner } from "./SupportingManagerBanner";

export function SupportingManagerBannerLoadingBoundary(): JSX.Element | null {
  const urlSearchParams = useURLSearchParams();
  const managerId = urlSearchParams.get("manager");
  const parsedManagerId = managerId != null ? parseInt(managerId) : 0;

  const { data, loading } = useQuery<
    SupportingManagerBannerLoadingBoundaryQuery,
    SupportingManagerBannerLoadingBoundaryQueryVariables
  >(SupportingManagerBannerLoadingBoundary.query, {
    skip: managerId == null,
    variables: {
      managerId: parsedManagerId,
      filter: { reports: ReportsEnum.direct, managerIds: [parsedManagerId] },
    },
  });

  if (managerId == null) return null;
  if (!data) return loading ? <LinearProgress /> : <ErrorView />;

  const [arbitraryEmployee] = data.employee2s.employees;
  const managerDisplayName = arbitraryEmployee.minimalManager?.displayName;

  return <SupportingManagerBanner managerDisplayName={managerDisplayName} />;
}

/**
 * We are approaching this query in a very interesting way. Instead of directly
 * querying for the manager, we are querying for an arbitrary employee that has
 * the requested manager as their manager. This is because we cannot safely
 * assume that the HRBP will have access to the manager (employee) that they
 * are trying to view. BUT we do know that we will have access to the manager's
 * reports, and through the reports we can pull minimal manager information.
 * So by going through a report we can safely pull the manager's name
 */
SupportingManagerBannerLoadingBoundary.query = gql`
  query SupportingManagerBannerLoadingBoundaryQuery(
    $managerId: Int!
    $filter: Employee2sFilter!
  ) {
    employee2s(managerId: $managerId, filter: $filter, limit: 1) {
      employees {
        id
        minimalManager {
          id
          displayName
        }
      }
    }
  }
`;
