import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import clsx from "clsx";
import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AssembleTypography } from "../../components/AssembleTypography";
import { NoOffersCreated } from "../../components/EmptyState/EmptyState";
import {
  SortableTableHeaderCell,
  useSort,
} from "../../components/SortableTable";
import { WHITE } from "../../theme";
import { useTableStyles } from "../DepartmentsList/styles";
import { OfferActions } from "./OfferActions";
import { Offer } from "./OfferList";
import { OfferListTableHeaderBar } from "./OfferListTableHeaderBar";

const useStyles = makeStyles(() => ({
  headerCell: {
    backgroundColor: WHITE,
    height: "2rem",
  },
  noOffersCell: {
    marginBottom: "20vh",
  },
  row: {
    cursor: "pointer",
    transition: "all .2s",
  },
  tableCellOverride: {
    color: "inherit",
    transition: "all .2s",
    borderTop: "solid 1px transparent",
  },
}));

type OfferListTableProps = {
  computedOffers: Offer[];
  handleArchiveOffer: (id: number) => unknown;
  archiveOfferLoading: boolean;
};

const OfferListTable = ({
  computedOffers,
  handleArchiveOffer,
  archiveOfferLoading,
}: OfferListTableProps) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const navigate = useNavigate();

  const [displayedOffers, setDisplayedOffers] = useState(
    filterActiveOffers(computedOffers)
  );
  const {
    sortedArray: sortedOffers,
    order,
    orderBy,
    handleRequestSort,
  } = useSort(displayedOffers, "offeredAt", "desc", {});

  const handleArchive = (id: number) => {
    handleArchiveOffer(id);
    const activeOffers = displayedOffers.filter((offer) => offer.id !== id);
    setDisplayedOffers(activeOffers);
  };

  const pagedOffers = sortedOffers.slice(
    pageSize * page,
    pageSize * (page + 1)
  );

  return (
    <TableContainer component={Paper} elevation={0}>
      <OfferListTableHeaderBar
        offers={computedOffers}
        setDisplayedOffers={setDisplayedOffers}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numberOfDisplayedOffers={displayedOffers.length}
      />
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <SortableTableHeaderCell
              className={classes.headerCell}
              cellTitle="Offer Date"
              orderByField="offeredAt"
              order={order}
              isSelected={orderBy === "offeredAt"}
              handleRequestSort={handleRequestSort}
              width="12%"
              noWrap
            />
            <SortableTableHeaderCell
              className={classes.headerCell}
              cellTitle="Last Edited"
              orderByField="updatedAt"
              order={order}
              isSelected={orderBy === "updatedAt"}
              handleRequestSort={handleRequestSort}
              width="12%"
              noWrap
            />
            <SortableTableHeaderCell
              className={classes.headerCell}
              cellTitle="Candidate"
              orderByField="candidateName"
              order={order}
              isSelected={orderBy === "candidateName"}
              handleRequestSort={handleRequestSort}
              width="15%"
              noWrap
            />
            <SortableTableHeaderCell
              className={classes.headerCell}
              cellTitle="Job Title"
              orderByField="title"
              order={order}
              isSelected={orderBy === "title"}
              handleRequestSort={handleRequestSort}
              width="25%"
              noWrap
            />
            <SortableTableHeaderCell
              className={classes.headerCell}
              cellTitle="Position"
              orderByField="positionName"
              order={order}
              isSelected={orderBy === "positionName"}
              handleRequestSort={handleRequestSort}
              width="20%"
              noWrap
            />
            <SortableTableHeaderCell
              className={classes.headerCell}
              cellTitle="Level"
              orderByField="positionLevel"
              order={order}
              isSelected={orderBy === "positionLevel"}
              handleRequestSort={handleRequestSort}
              width="5%"
              noWrap
              align="center"
            />
            <TableCell
              variant="head"
              className={classes.headerCell}
              align="center"
              width="11%"
            >
              <AssembleTypography variant="productTableHeader">
                Actions
              </AssembleTypography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pagedOffers.length > 0 ? (
            pagedOffers.map((x: Offer, index: number) => (
              <TableRow
                key={`offer-row-${x.id}`}
                className={clsx(classes.row, tableClasses.row)}
                onClick={() => navigate(`/offers/${x.id}`)}
              >
                <TableCell className={classes.tableCellOverride} scope="row">
                  <AssembleTypography variant="productTableContent">
                    {x.offeredAt.toLocaleDateString("en-US", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    })}
                  </AssembleTypography>
                </TableCell>
                <TableCell className={classes.tableCellOverride} scope="row">
                  <AssembleTypography variant="productTableContent">
                    {x.updatedAt === null
                      ? "N/A"
                      : x.updatedAt.toLocaleDateString("en-US", {
                          month: "short",
                          day: "2-digit",
                          year: "numeric",
                        })}
                  </AssembleTypography>
                </TableCell>
                <TableCell className={classes.tableCellOverride} scope="row">
                  <AssembleTypography variant="productTableContent">
                    {x.candidateName}
                  </AssembleTypography>
                </TableCell>
                <TableCell className={classes.tableCellOverride} scope="row">
                  <AssembleTypography variant="productTableContent">
                    {x.title}
                  </AssembleTypography>
                </TableCell>
                <TableCell className={classes.tableCellOverride} scope="row">
                  <AssembleTypography variant="productTableContent">
                    {x.positionName}
                  </AssembleTypography>
                </TableCell>
                <TableCell
                  className={classes.tableCellOverride}
                  scope="row"
                  align="center"
                >
                  <AssembleTypography variant="productTableContent">
                    {x.positionLevel}
                  </AssembleTypography>
                </TableCell>
                <TableCell
                  className={classes.tableCellOverride}
                  scope="row"
                  align="center"
                >
                  <OfferActions
                    offer={x}
                    index={index}
                    handleArchive={handleArchive}
                    archiveOfferLoading={archiveOfferLoading}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow className={classes.noOffersCell}>
              <TableCell colSpan={6}>
                <NoOffersCreated inTableContainer />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(OfferListTable);

export function filterActiveOffers(offers: Offer[]): Offer[] {
  return offers.slice().filter((x) => x.expiredAt === null);
}
