import { IconButton } from "@material-ui/core";
import { InfoIcon } from "src/components/AssembleIcons/Brand/InfoIcon";
import { ExplanatoryTooltip } from "src/components/ExplanatoryTooltip";
import { GRAY_4, PURPLE_2 } from "../../../../theme";

export function SupportedManagersTooltip(): JSX.Element {
  return (
    <div>
      <ExplanatoryTooltip
        title="Supported Managers"
        body="These are managers this person has the ability to act on the behalf of."
        placement="top"
        width="400px"
      >
        <IconButton size="small">
          <InfoIcon color={GRAY_4} hoverColor={PURPLE_2} />
        </IconButton>
      </ExplanatoryTooltip>
    </div>
  );
}
