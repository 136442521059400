import { InfoIcon } from "src/components/AssembleIcons/Small/InfoIcon";
import { ExplanatoryTooltip } from "../../../../components/ExplanatoryTooltip";
import { GRAY_4 } from "../../../../theme";

type Props = {
  subject: { name: string; isOrg: boolean };
};

export function AllocateBudgetsTooltip({ subject }: Props): JSX.Element {
  const body = subject.isOrg
    ? "This is the total budget that's been allocated for this compensation cycle."
    : `This is what ${subject.name} has left to request changes for their direct reports.`;

  return (
    <ExplanatoryTooltip
      title="Remaining allocation"
      body={body}
      width="23.125rem"
      placement="top"
    >
      <div>
        <InfoIcon color={GRAY_4} />
      </div>
    </ExplanatoryTooltip>
  );
}
