import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { EligibleIneligibleTable_participant as Participant } from "../../../__generated__/graphql";
import { ComplexToggle } from "../../../components/ComplexToggle";
import { Paginator } from "../../../components/Pagination/Paginator";
import { SearchBar } from "../../../components/Search/SearchBar";
import { TableHeaderBar } from "../../../components/Table/TableHeaderBar";
import { GRAY_6 } from "../../../theme";
import { usePagination } from "../Plan/Contexts/PaginationContext";
import { ParticipantsTable } from "./ParticipantsTable";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  header: {
    borderLeft: `1px solid ${GRAY_6}`,
    borderRight: `1px solid ${GRAY_6}`,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

type PotentialParticipants = {
  eligible: Participant[];
  ineligible: Participant[];
  counts: { eligible: number; ineligible: number };
};

type Props = {
  people: PotentialParticipants | undefined;
  showEligible: boolean;
  setShowEligible: (showEligible: boolean) => unknown;
  searchTerm: string | null;
  setSearchTerm: (search: string | null) => unknown;
};

export function EligibleIneligibleTable({
  people,
  showEligible,
  setShowEligible,
  searchTerm,
  setSearchTerm,
}: Props): JSX.Element {
  const classes = useStyles();

  const {
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    totalCount,
    setTotalCount,
  } = usePagination();

  if (people === undefined) {
    return <div>Loading</div>;
  }

  const handleEligibilityToggle = (v: string) => {
    const updatedView = v === "eligible";
    setCurrentPage(0);
    setTotalCount(
      updatedView ? people.counts.eligible : people.counts.ineligible
    );
    setShowEligible(updatedView);
  };

  return (
    <div className={classes.root}>
      <>
        <TableHeaderBar
          className={classes.header}
          slotLeft={
            <SearchBar
              defaultValue={searchTerm}
              handleOnBlur={setSearchTerm}
              placeholder={"Type name and press enter"}
            />
          }
          slotRight={
            <Paginator
              page={currentPage}
              setPage={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              items={totalCount}
              compact
            />
          }
          slotCenter={
            <ComplexToggle
              options={[
                ["eligible", `Eligible (${people.counts.eligible})`],
                ["ineligible", `Ineligible (${people.counts.ineligible})`],
              ]}
              selected={showEligible ? "eligible" : "ineligible"}
              onChange={handleEligibilityToggle}
            />
          }
        />
        <ParticipantsTable
          employees={
            showEligible
              ? people.eligible.map((p) => ({
                  ...p.subject,
                  perfRating: p.perfRating,
                }))
              : people.ineligible.map((p) => ({
                  ...p.subject,
                  perfRating: p.perfRating,
                }))
          }
        />
      </>
    </div>
  );
}

EligibleIneligibleTable.fragments = {
  participant: gql`
    ${ParticipantsTable.fragments.employee}
    fragment EligibleIneligibleTable_participant on CompCycleParticipant {
      subjectId
      compCycleId
      perfRating
      subject {
        ...ParticipantsTable_employee
      }
    }
  `,
};
