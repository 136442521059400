import { makeStyles } from "@material-ui/core";
import { AssembleFlatfileButton } from "src/components/AssembleFlatfileButton";
import { AssembleButton } from "../../AssembleButton/AssembleButton";

export type EmployeePerfScoreRow = {
  email: string;
  note?: string;
  perfRating: string;
};

const useStyles = makeStyles(() => ({
  cardButton: {
    left: "50%",
    position: "absolute",
    top: "-52px",
    translate: "-50% 0",
  },
}));

interface Props {
  handleChange: (data: Array<EmployeePerfScoreRow>) => unknown;
  handleCancel?: () => unknown;
}

export function PerfImportButton({
  handleChange,
  handleCancel,
}: Props): JSX.Element {
  const classes = useStyles();
  return (
    <AssembleFlatfileButton
      settings={{
        title: "Add your performance scores",
        type: "EmployeePerfScoreRow",
        fields: [
          { label: "Name", key: "displayName" },
          {
            label: "Email",
            key: "email",
            validators: [{ validate: "required" }, { validate: "unique" }],
          },
          {
            label: "Perf. Score",
            key: "perfRating",
            validators: [{ validate: "required" }],
          },
          { label: "Note/Details", key: "note" },
        ],
        managed: true,
      }}
      onData={(results: { validData: EmployeePerfScoreRow[] }) => {
        handleChange(results.validData);
        return new Promise((resolve) => setTimeout(resolve, 2000));
      }}
      onCancel={handleCancel}
      render={(_, launch) => (
        <AssembleButton
          onClick={launch}
          variant="contained"
          label="Import Your Ratings"
          size="medium"
          className={classes.cardButton}
        />
      )}
    />
  );
}
