import { makeStyles, Paper } from "@material-ui/core";
import { GRAY_2, WHITE } from "../../theme";
import { AssembleTypography } from "../AssembleTypography";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "16rem",
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "8px",
    boxShadow: "0px 15px 25px rgba(10, 36, 64, 0.08)",
    background: GRAY_2,
  },
}));

type Props = { active?: boolean };

export function NotEnoughDataTooltip({ active }: Props): JSX.Element | null {
  const classes = useStyles();

  return active === true ? (
    <Paper className={classes.paper} elevation={0}>
      <AssembleTypography variant="productTooltip" textColor={WHITE}>
        Employees distribution is only shown for positions with enough
        employees.
      </AssembleTypography>
    </Paper>
  ) : null;
}
