import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { GRAY_6, WHITE } from "../../theme";

type Props = {
  slotLeft: JSX.Element;
  slotCenter: JSX.Element;
  slotRight: JSX.Element | null | false;
  inContainer?: boolean; // Already in a TableContainer?
  tall?: boolean;
  className?: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: theme.spacing(1),
    height: "3rem",
    position: "relative",
    padding: theme.spacing(1, 2),
    borderTop: `1px solid ${GRAY_6}`,
    background: WHITE,

    // Sometimes the center needs more than 33% space; give it a higher basis.
    "& $slotCenter": { flex: "0 0 auto" },
    "& $slotLeft, & $slotRight": { flex: "1 0 0" },
  },
  inContainer: {
    borderTop: "none",
    borderBottom: `1px solid ${GRAY_6}`,
    paddingInline: theme.spacing(1.5),
  },
  tall: {
    // This is too tall, but the search bar in ReportsTable demands space.
    height: "4.6875rem",
  },
  slotLeft: {
    display: "flex",
    justifyContent: "flex-start",
    height: "100%",
  },
  slotCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  slotRight: {
    display: "flex",
    justifyContent: "flex-end",
    height: "100%",
  },
}));

export function TableHeaderBar({
  slotLeft,
  slotCenter,
  slotRight,
  inContainer = false,
  tall = false,
  className = "",
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.inContainer]: inContainer,
        [classes.tall]: tall,
        [className]: className !== "",
      })}
    >
      <div className={classes.slotLeft}>{slotLeft}</div>
      <div className={classes.slotCenter}>{slotCenter}</div>
      <div className={classes.slotRight}>{slotRight}</div>
    </div>
  );
}
