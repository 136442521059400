import { AbbreviatedCashBandName, CashBandName } from "@asmbl/shared/constants";
import { makeStyles, TableCell, TableRow, Tooltip } from "@material-ui/core";
import { ReactNode } from "react";
import { AssembleTypography } from "../../../components/AssembleTypography";
import {
  DoubleSortableTableHeaderCell,
  Order,
  SortableTableHeaderCell,
} from "../../../components/SortableTable";
import { columnWidthGenerator } from "../../../components/Table/constants";
import { EquityDisplayType } from "../../../components/Table/EquityDisplayCell";
import { WHITE } from "../../../theme";
import { CashCompType } from "../../../__generated__/graphql";
import { SortBy, SortByEnum } from "../Context/TableContext";

const useStyles = makeStyles(() => ({
  headerTableCell: { background: WHITE },
}));

const ALL_CASH_COMP_OPTIONS = [
  CashCompType.SALARY,
  CashCompType.COMMISSION,
  CashCompType.RECURRING_BONUS,
];

type Props = {
  order: Order;
  orderBy: "manager" | "equity" | SortBy;
  showLevelingColumn: boolean;
  showEquityGrants: boolean;
  compComponentSelection: Set<CashCompType>;
  equityDisplay: EquityDisplayType;
  hasCompBandAccess: boolean;
  handleSort: (newSortBy: SortBy, newSortDir?: Order) => void;
};

export function ReportsTableHeader({
  order,
  orderBy,
  equityDisplay,
  showEquityGrants,
  showLevelingColumn,
  compComponentSelection,
  hasCompBandAccess,
  handleSort,
}: Props): JSX.Element {
  const classes = useStyles();
  const width = columnWidthGenerator(12);

  return (
    <TableRow>
      <DoubleSortableTableHeaderCell
        titleA="Name"
        titleB="Position"
        orderByFieldA={SortByEnum.displayName}
        orderByFieldB={SortByEnum.position}
        order={order}
        isSelectedA={orderBy === SortByEnum.displayName}
        isSelectedB={orderBy === SortByEnum.position}
        handleRequestSort={handleSort}
        aria-label="Name / Position"
        align="left"
        width={width(2)}
        className={classes.headerTableCell}
      />
      {showLevelingColumn ? (
        <SortableTableHeaderCell
          cellTitle="Job Title"
          orderByField={SortByEnum.jobTitle}
          order={order}
          isSelected={orderBy === SortByEnum.jobTitle}
          handleRequestSort={handleSort}
          align="left"
          aria-label="Job Title"
          width={width(2)}
          className={classes.headerTableCell}
          noWrap
        />
      ) : (
        <DoubleSortableTableHeaderCell
          titleA={
            <Tooltip title="Department / Ladder" placement="top">
              <span>Dept</span>
            </Tooltip>
          }
          titleB={
            <Tooltip title="Department / Ladder" placement="top">
              <span>Ladder</span>
            </Tooltip>
          }
          orderByFieldA={SortByEnum.department}
          orderByFieldB={SortByEnum.ladder}
          order={order}
          isSelectedA={orderBy === SortByEnum.department}
          isSelectedB={orderBy === SortByEnum.ladder}
          handleRequestSort={handleSort}
          aria-label="Department / Ladder"
          width={width(2)}
          className={classes.headerTableCell}
        />
      )}
      <SortableTableHeaderCell
        cellTitle={
          <Tooltip title="Level" placement="top">
            <span>Lvl</span>
          </Tooltip>
        }
        orderByField={SortByEnum.level}
        order={order}
        isSelected={orderBy === SortByEnum.level}
        handleRequestSort={handleSort}
        align="center"
        aria-label="Level"
        width={width(0.5)}
        className={classes.headerTableCell}
        noWrap
      />
      <SortableTableHeaderCell
        cellTitle="Tenure"
        orderByField={SortByEnum.tenure}
        order={order}
        isSelected={orderBy === SortByEnum.tenure}
        handleRequestSort={handleSort}
        aria-label="Tenure"
        width={width(1)}
        className={classes.headerTableCell}
        noWrap
      />
      <SortableTableHeaderCell
        cellTitle="Location"
        orderByField={SortByEnum.location}
        order={order}
        isSelected={orderBy === SortByEnum.location}
        handleRequestSort={handleSort}
        aria-label="Location"
        width={width(1)}
        className={classes.headerTableCell}
        noWrap
      />
      <SortableTableHeaderCell
        cellTitle={
          <Tooltip title="Manager" placement="top">
            <span>Mgr</span>
          </Tooltip>
        }
        orderByField={SortByEnum.manager}
        order={order}
        isSelected={orderBy === SortByEnum.manager}
        handleRequestSort={handleSort}
        aria-label="Manager"
        width={width(0.5)}
        className={classes.headerTableCell}
        noWrap
      />
      <SortableTableHeaderCell
        cellTitle={getTotalCashHeaderTitle(compComponentSelection)}
        orderByField={SortByEnum.totalCash}
        order={order}
        isSelected={orderBy === SortByEnum.totalCash}
        handleRequestSort={handleSort}
        aria-label="Total Annual Cash"
        align="right"
        width={width(1.5)}
        className={classes.headerTableCell}
        noWrap
      />
      {hasCompBandAccess && (
        <TableCell
          align="center"
          width={width(1.5)}
          className={classes.headerTableCell}
        >
          <AssembleTypography variant="subtitle2" noWrap>
            Band Placement
          </AssembleTypography>
        </TableCell>
      )}
      {!showLevelingColumn && (
        <SortableTableHeaderCell
          cellTitle="Compa-Ratio"
          orderByField={SortByEnum.compaRatio}
          order={order}
          isSelected={orderBy === SortByEnum.compaRatio}
          handleRequestSort={handleSort}
          align="right"
          aria-label="Compa-Ratio"
          width={width(1)}
          className={classes.headerTableCell}
          noWrap
        />
      )}

      {showEquityGrants && !showLevelingColumn ? (
        <SortableTableHeaderCell
          cellTitle={equityDisplay === "units" ? "Equity #" : "Equity $"}
          orderByField={SortByEnum.equityCount}
          order={order}
          isSelected={orderBy === SortByEnum.equityCount}
          handleRequestSort={handleSort}
          aria-label="equity"
          width={width(1)}
          align="right"
          className={classes.headerTableCell}
          noWrap
        />
      ) : null}

      {showLevelingColumn && (
        <TableCell width={width(2)} className={classes.headerTableCell}>
          <AssembleTypography variant="subtitle2" noWrap>
            Assemble Position
          </AssembleTypography>
        </TableCell>
      )}
    </TableRow>
  );
}

function getTotalCashHeaderTitle(selections: Set<CashCompType>): ReactNode {
  if (selections.size === ALL_CASH_COMP_OPTIONS.length) {
    return (
      <Tooltip title="Total Annual Cash" placement="top">
        <span>Total Cash</span>
      </Tooltip>
    );
  }

  if (selections.size === 0) {
    return "-";
  }

  // no abbreviations needed for single selection unless it is rec bonus
  if (selections.size === 1 && !selections.has(CashCompType.RECURRING_BONUS)) {
    return [...selections].map((s) => CashBandName[s]);
  }

  return (
    <Tooltip
      title={[...selections].map((s) => CashBandName[s]).join(" & ")}
      placement="top"
    >
      <span>
        {[...selections].map((s) => AbbreviatedCashBandName[s]).join(" & ")}
      </span>
    </Tooltip>
  );
}
