import { gql } from "@apollo/client";
import { Comparator } from "@asmbl/shared/sort";
import { mapMaybe } from "@asmbl/shared/utils";
import {
  makeStyles,
  TableBody,
  TableCellProps,
  TableRow,
} from "@material-ui/core";
import { useMemo } from "react";
import { Column, useTable } from "react-table";
import {
  LocationsTable_locations as Location,
  LocationsTable_mergeConfigs as MergeConfig,
} from "../../../../__generated__/graphql";
import { useSort } from "../../../SortableTable";
import { columnWidthGenerator } from "../../../Table/constants";
import { WireTable } from "../../../Table/WireTable/WireTable";
import { WireTableHead } from "../../../Table/WireTable/WireTableHead";
import { WireTableHeaderCell } from "../../../Table/WireTable/WireTableHeaderCell";
import { CityCell } from "./Cells/CityCell";
import { CountryCell } from "./Cells/CountryCell";
import { LocationAuthorCell } from "./Cells/LocationAuthorCell";
import { LocationGroupCell } from "./Cells/LocationGroupCell";
import { LocationMappedCell } from "./Cells/LocationMappedCell";
import { MarketCell } from "./Cells/MarketCell";
import { StateCell } from "./Cells/StateCell";
import { LocationsTableEmpty } from "./LocationsTableEmpty";
import { LocationsTableRow } from "./LocationsTableRow";

type Props = {
  mergeConfigs: MergeConfig[] | undefined;
  locations: Location[];
};

export type C = Column<LocationRow> & {
  id: keyof LocationRow;
  Header: string;
  accessor: string;
  width: number;
  ordering: Comparator<LocationRow>;
  align?: TableCellProps["align"];
};

export type LocationRow = {
  id: number;
  city: string;
  state: string;
  country: string;
  market: {
    id: number;
    name: string;
  } | null;
  locationGroup: {
    id: number;
    name: string;
  } | null;
  mapped: boolean;
  mappedBy: {
    id: number;
    name: string | null;
    photoURL: string | null;
  } | null;
};

const useStyles = makeStyles(() => ({
  table: {
    borderRadius: "8px",
  },
  headerCell: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
}));

export function LocationsTable({
  locations,
  mergeConfigs,
}: Props): JSX.Element {
  const classes = useStyles();

  const columns: C[] = useMemo(
    () =>
      mapMaybe(
        [
          CityCell,
          StateCell,
          CountryCell,
          MarketCell,
          LocationGroupCell,
          LocationMappedCell,
          LocationAuthorCell,
        ],
        (col) => col
      ) as unknown as C[],
    []
  );
  const getWidth = columnWidthGenerator(12);

  const customComparators = useMemo(() => {
    const comparators: Record<string, Comparator<LocationRow>> = {};

    for (const column of columns) {
      comparators[column.id] = column.ordering;
    }

    return comparators;
  }, [columns]);

  const {
    sortedArray: sortedRows,
    order,
    orderBy,
    handleRequestSort,
  } = useSort<LocationRow>(
    locations.map((location) => ({
      ...location,
      locationGroup: location.locationGroup ?? null,
      mapped: location.locationGroup != null,
      mappedBy: location.locationGroup?.author ?? null,
      market: location.locationGroup?.market ?? null,
    })),
    "mapped",
    "asc",
    customComparators
  );

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({ columns, data: sortedRows });

  return locations.length === 0 ? (
    <LocationsTableEmpty mergeConfigs={mergeConfigs} />
  ) : (
    <WireTable {...getTableProps()} className={classes.table}>
      <WireTableHead>
        <TableRow>
          {headers.map((header) => {
            const col = columns.find((c) => c.id === header.id) ?? columns[0];

            return (
              <WireTableHeaderCell<LocationRow>
                align={col.align}
                cellTitle={header.render("Header")}
                orderByField={col.id}
                order={order}
                isSelected={orderBy === header.id}
                handleRequestSort={handleRequestSort}
                {...header.getHeaderProps()}
                key={header.getHeaderProps().key}
                className={classes.headerCell}
                width={getWidth(col.width)}
              />
            );
          })}
        </TableRow>
      </WireTableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          const rowKey = row.getRowProps().key;

          return <LocationsTableRow key={rowKey} columns={columns} row={row} />;
        })}
      </TableBody>
    </WireTable>
  );
}

LocationsTable.fragments = {
  mergeConfigs: gql`
    ${LocationsTableEmpty.fragments.mergeConfigs}
    fragment LocationsTable_mergeConfigs on MergeConnectionConfig {
      ...LocationsTableEmpty_mergeConfigs
      id
    }
  `,
  locations: gql`
    fragment LocationsTable_locations on Location {
      id
      city
      state
      country
      locationGroup {
        id
        name
        market {
          id
          name
        }
        author {
          id
          name
          photoURL
        }
      }
    }
  `,
};
