import { makeStyles, Typography } from "@material-ui/core";
import { CSSProperties, forwardRef, ReactNode } from "react";
import { GRAY_6, WHITE } from "../../theme";

export const PAGE_HEADER_HEIGHT = "5rem";

const useStyles = makeStyles((theme) => ({
  header: {
    height: PAGE_HEADER_HEIGHT,
    minHeight: PAGE_HEADER_HEIGHT,
    background: WHITE,
    borderBottom: `1px solid ${GRAY_6}`,
    paddingInline: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media print": {
      display: "none",
    },
  },
}));

export function PageTitle({ children }: { children: string }): JSX.Element {
  return (
    <Typography component="h2" variant="h5">
      {children}
    </Typography>
  );
}

type PageHeaderProps = {
  children: ReactNode;
  style?: CSSProperties;
};
export const PageHeader = forwardRef<HTMLDivElement, PageHeaderProps>(
  function PageHeader({ children, style }, ref): JSX.Element {
    const classes = useStyles();
    return (
      <div ref={ref} className={classes.header} style={style}>
        {children}
      </div>
    );
  }
);
