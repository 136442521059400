import { makeStyles } from "@material-ui/core";
import { ReactNode } from "react";
import { GRAY_2, GRAY_4 } from "../../../../theme";
import { AssembleTypography } from "../../../AssembleTypography";
import RadioCard from "../../../Form/RadioCard";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
  },
  formCard: {
    height: "100%",
  },
  formCardLabel: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  accessCardTitleContainer: {
    display: "flex",
    flexDirection: "row",
  },
  accessCardList: {
    marginTop: 0,
    paddingLeft: 25,
    marginBottom: theme.spacing(1),

    "& >li": {
      marginTop: theme.spacing(0.5),
    },
  },
  accessCardSubText: {
    marginTop: 0,
    paddingLeft: 25,
    marginBottom: theme.spacing(0.5),
  },
}));

type Props<T> = {
  accessType: T;
  value: T;
  header: string | ReactNode;
  bulletPoints: JSX.Element[];
  subtext?: string;
  isDefault?: boolean;
};

export function PolicyCard<T>({
  accessType,
  value,
  header,
  bulletPoints,
  subtext,
  isDefault = false,
}: Props<T>): JSX.Element {
  const classes = useStyles();

  return (
    <RadioCard
      className={classes.formCard}
      classes={{ label: classes.formCardLabel }}
      label={
        <>
          <div className={classes.header}>
            <AssembleTypography variant="productHeader">
              {header}
            </AssembleTypography>
            {isDefault && (
              <AssembleTypography textColor={GRAY_4}>
                &nbsp;(Default)
              </AssembleTypography>
            )}
          </div>
          <ul className={classes.accessCardList}>
            {bulletPoints.map((element, idx) => (
              <li key={idx}>
                <AssembleTypography variant="body2" textColor={GRAY_2}>
                  {element}
                </AssembleTypography>
              </li>
            ))}
          </ul>
          {subtext != null && (
            <AssembleTypography
              variant="productExtraSmall"
              textColor={GRAY_4}
              className={classes.accessCardSubText}
            >
              {subtext}
            </AssembleTypography>
          )}
        </>
      }
      value={value}
      isSelected={accessType === value}
    />
  );
}
