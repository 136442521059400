import { gql } from "@apollo/client";
import { ReactNode, useState } from "react";
import { CompCycleSummaryContent2 } from "src/components/CompNoteModal/CompCycleSummaryContent2";
import { CompNoteModal2 } from "src/components/CompNoteModal/CompNoteModal2";
import { DraftNoteModalButton2_participant } from "../../../__generated__/graphql";

interface Props {
  employee: DraftNoteModalButton2_participant;
  reviewerUserId?: number;
  children: (onOpen: () => unknown) => ReactNode;
}

export function DraftNoteModalButton2({
  employee,
  reviewerUserId,
  children,
}: Props): JSX.Element {
  const [open, setOpen] = useState(false);
  return (
    <>
      {open && employee.compRecommendation && (
        <CompNoteModal2
          employee={employee.subject}
          onClose={() => setOpen(false)}
        >
          <CompCycleSummaryContent2
            compRecommendation={employee.compRecommendation}
            viewerId={reviewerUserId ?? employee.subject.managerId ?? undefined}
          />
        </CompNoteModal2>
      )}
      {children(() => setOpen(true))}
    </>
  );
}

DraftNoteModalButton2.fragments = {
  participant: gql`
    ${CompNoteModal2.fragments.employee}
    ${CompCycleSummaryContent2.fragments.compRecommendation}
    fragment DraftNoteModalButton2_participant on CompCycleParticipant {
      subjectId
      compCycleId
      subject {
        managerId
        ...CompNoteModal2_employee
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        ...CompCycleSummaryContent2_compRecommendation
      }
    }
  `,
};
