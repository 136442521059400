import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { add, Money, subtract, zero } from "@asmbl/shared/money";
import { mapMaybe } from "@asmbl/shared/utils";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import clsx from "clsx";
import { CompCycleDisplay } from "../../../../components/CompCycle/types";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { calculateRequestsFromReports } from "../../../../models/CompRecommendation";
import { DV_RED, GRAY_4, GRAY_8 } from "../../../../theme";
import {
  EquitySummarizedRow_compCycleBudget as CompCycleBudget,
  EquitySummarizedRow_manager as Manager,
  EquitySummarizedRow_reports as Report,
  EquitySummarizedRow_valuation as Valuation,
  RecItemType,
} from "../../../../__generated__/graphql";
import { IndentationCells } from "../../Budgets/Table/Connectors";
import { Equity } from "../BudgetsVsRequests";
import {
  renderEquityBudget,
  renderEquityRequest,
  renderEquityVariance,
} from "./EquityRow";
import { SummarizedNameCell } from "./SummarizedNameCell";

type Props = {
  rootBudget: CompCycleBudget | null;
  manager: Manager | null;
  reports: Report[];
  currentValuation: Valuation;
  organizationName: string;
  varianceDisplay: CompCycleDisplay;
  equityDisplay: Equity;
  indentation: IndentationCells;
};

const useStyles = makeStyles(() => ({
  root: {
    height: "1.5rem",

    "& tr": {
      height: "1.5rem",
    },

    "& td": {
      height: "24px",
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: GRAY_8,
    },
  },
  text: {
    color: GRAY_4,
    fontSize: "0.8125rem",
    lineHeight: "1.259375",
    fontWeight: 400,
    letterSpacing: "-0.25px",
  },
  alertText: { color: DV_RED },
}));

function getTopLevelBudget(
  rootBudget: CompCycleBudget | null,
  manager: Manager | null
): Money | null {
  // if this budget is an organization level budget
  if (manager === null && rootBudget && rootBudget.equity) {
    return rootBudget.equity;
  }

  // if this budget is attached to an employee
  if (manager !== null && manager.compCycleBudget?.equity) {
    return manager.compCycleBudget.equity;
  }

  return null;
}

function getReportsBudget(
  defaultCurrencyCode: CurrencyCode,
  reports: Report[]
): Money | null {
  const mappedBudget = mapMaybe(
    reports,
    (employee) => employee.compCycleBudget?.equity
  );

  if (mappedBudget.length === 0) return null;

  return mappedBudget.reduce(add, zero(defaultCurrencyCode));
}

export function EquitySummarizedRow({
  rootBudget,
  manager,
  reports,
  currentValuation,
  organizationName,
  varianceDisplay,
  equityDisplay,
  indentation,
}: Props): JSX.Element {
  const classes = useStyles();
  const { defaultCurrencyCode } = useCurrencies();

  return (
    <TableRow className={classes.root}>
      {/* Name & title cell + direct & indirect cells */}
      <SummarizedNameCell
        subjectName={manager ? manager.displayName : organizationName}
        indentation={indentation}
      />

      {/* Direct & indirect cells */}
      <TableCell colSpan={1} />
      <TableCell colSpan={1} />
      {[
        {
          budgetData: {
            top: getTopLevelBudget(rootBudget, manager),
            current: getReportsBudget(defaultCurrencyCode, reports),
          },
          requests: calculateRequestsFromReports(
            reports,
            RecItemType.EQUITY_GRANT
          ),
        },
      ].map(({ requests, budgetData }) => {
        const budget = budgetData.top
          ? subtract(
              budgetData.top,
              budgetData.current ?? zero(currentValuation.valuation.currency)
            )
          : null;

        const variance = budget && requests ? subtract(requests, budget) : null;

        return (
          <>
            {/* budget */}
            <TableCell align="right">
              <Typography className={classes.text}>
                {renderEquityBudget(
                  equityDisplay,
                  budget,
                  currentValuation.fdso,
                  currentValuation.valuation
                )}
              </Typography>
            </TableCell>

            {/* request */}
            <TableCell align="right">
              <Typography className={classes.text}>
                {renderEquityRequest(
                  equityDisplay,
                  requests,
                  currentValuation.fdso,
                  currentValuation.valuation
                )}
              </Typography>
            </TableCell>

            {/* variance */}
            <TableCell align="right">
              <Typography
                className={clsx(classes.text, {
                  [classes.alertText]: variance && variance.value > 0,
                })}
              >
                {renderEquityVariance(
                  equityDisplay,
                  varianceDisplay,
                  budget,
                  requests,
                  variance,
                  currentValuation.fdso,
                  currentValuation.valuation
                )}
              </Typography>
            </TableCell>
          </>
        );
      })}
    </TableRow>
  );
}

EquitySummarizedRow.fragments = {
  compCycleBudget: gql`
    fragment EquitySummarizedRow_compCycleBudget on CompCycleBudget {
      compCycleId
      employeeId
      equity
    }
  `,
  manager: gql`
    fragment EquitySummarizedRow_manager on Employee {
      id
      displayName

      compCycleBudget(compCycleId: $compCycleId) {
        compCycleId
        employeeId
        equity
      }
    }
  `,
  reports: gql`
    fragment EquitySummarizedRow_reports on Employee {
      id
      displayName

      compRecommendation(compCycleId: $compCycleId) {
        subjectId
        compCycleId
        reviewStatus
        latestSubmittedItems {
          id
          submittedAt
          recommendationType
          cashEquivalent(currencyCode: $currencyCode)
        }
      }

      compCycleBudget(compCycleId: $compCycleId) {
        compCycleId
        employeeId
        equity
      }
    }
  `,
  valuation: gql`
    fragment EquitySummarizedRow_valuation on Valuation {
      id
      valuation
      fdso
    }
  `,
};
