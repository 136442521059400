import { gql } from "@apollo/client";
import { EquityBandName } from "@asmbl/shared/constants";
import { exchangeMoney } from "@asmbl/shared/currency";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { Money, moneyComparator } from "@asmbl/shared/money";
import { Tooltip, makeStyles } from "@material-ui/core";
import {
  EquityRefreshCondensedSlider_equityBand as AdjustedEquityBand,
  EquityRefreshCondensedSlider_position,
  PositionType,
} from "src/__generated__/graphql";
import { useCompStructure } from "src/components/CompStructureContext";
import { useCurrencies } from "src/components/CurrenciesContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { WHITE } from "src/theme";
import { CondensedSlider } from "../CondensedSlider";
import { NullCompSlider } from "../NullCompSlider";
import { EquityRefreshPopover } from "./EquityRefreshPopover";

const useStyles = makeStyles({
  tooltip: {
    borderRadius: "10px",
    padding: "1rem 1.25rem",
    width: "320px",
    boxShadow: "0px 15px 25px rgba(10, 36, 64, 0.08)",
    background: WHITE,
  },
  sliderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: ({ narrow }: { narrow?: boolean }) =>
      narrow ? "3.75rem" : "7.5rem",
  },
});

type Props = {
  value: Money;
  bands?: AdjustedEquityBand[] | null;
  position?: EquityRefreshCondensedSlider_position | null;
  narrow?: boolean;
};

export function EquityRefreshCondensedSlider({
  value,
  bands,
  position,
  narrow,
}: Props) {
  const classes = useStyles({ narrow });
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();

  const { currencies, defaultCurrency } = useCurrencies();

  if (
    !compStructure?.equityBandTypes.includes(
      EquityBandName.EQUITY_REFRESH_GRANT
    )
  )
    return null;

  const localCurrency = currencies.get(value.currency) ?? defaultCurrency;

  const refreshBand = bands?.find(
    (band) => band.name === EquityBandName.EQUITY_REFRESH_GRANT
  );

  if (
    refreshBand == null ||
    refreshBand.bandPoints.length === 0 ||
    refreshBand.bandPoints.every((bp) => bp.annualCashEquivalent.value === 0)
  )
    return <NullCompSlider variant="condensed" />;

  const sortedBands = refreshBand.bandPoints
    .slice(0)
    .sort((a, b) =>
      moneyComparator(a.annualCashEquivalent, b.annualCashEquivalent)
    )
    .map((bandPoint) => ({
      ...bandPoint,
      annualCashEquivalent: exchangeMoney(
        bandPoint.annualCashEquivalent,
        localCurrency.code,
        { allCurrencies: currencies }
      ),
    }));

  const isHourly =
    position?.type === PositionType.HOURLY &&
    compStructure.allowHourlyEmployees === true &&
    isEnabled(FeatureFlag.HourlyEmployeesInCompCycles);

  return (
    <Tooltip
      title={
        <EquityRefreshPopover
          cashEquivalent={value}
          band={{ ...refreshBand, bandPoints: sortedBands }}
          position={position}
          isHourly={isHourly}
        />
      }
      classes={{ tooltip: classes.tooltip }}
      placement="top"
      interactive
    >
      <span className={classes.sliderContainer}>
        <CondensedSlider
          value={value.value ?? 0}
          min={sortedBands[0].annualCashEquivalent.value}
          max={sortedBands[sortedBands.length - 1].annualCashEquivalent.value}
          outOfRangeStyle="band"
        />
      </span>
    </Tooltip>
  );
}

EquityRefreshCondensedSlider.fragments = {
  equityBand: gql`
    fragment EquityRefreshCondensedSlider_equityBand on AdjustedEquityBand {
      id
      name
      bandPoints {
        bandName
        annualCashEquivalent
      }
    }
  `,
  position: gql`
    fragment EquityRefreshCondensedSlider_position on Position {
      id
      name
      level
      type
    }
  `,
};
