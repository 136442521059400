import { makeStyles } from "@material-ui/core";
import { ManagersTable_phase } from "src/__generated__/graphql";
import { PushPinIcon } from "src/components/AssembleIcons/Small/PushPinIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_2, GRAY_4, GRAY_6 } from "src/theme";

const useStyles = makeStyles({
  header: {
    padding: ".5rem 1rem",
    border: `solid 1px ${GRAY_6}`,
    display: "flex",
    justifyContent: "space-between",
  },
  headerText: {
    display: "flex",
    alignItems: "center",
  },
});

type Props = {
  phase: ManagersTable_phase;
  currentPhaseOrder: number;
};

export function ManagersTableHeaderCell({ phase, currentPhaseOrder }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <AssembleTypography
        variant="productEyebrowMedium"
        textColor={currentPhaseOrder === phase.phaseOrder ? GRAY_2 : GRAY_4}
        className={classes.headerText}
      >
        Phase {phase.phaseOrder}
        {currentPhaseOrder === phase.phaseOrder ? (
          <PushPinIcon color="#FF385C" height="12px" />
        ) : null}
      </AssembleTypography>
      <AssembleTypography
        variant="productEyebrowMedium"
        textColor={currentPhaseOrder === phase.phaseOrder ? GRAY_2 : GRAY_4}
      >
        {new Date(phase.startDate).toDateString().slice(4, 10)}
      </AssembleTypography>
    </div>
  );
}
