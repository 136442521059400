import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { LadderIcon } from "src/components/AssembleIcons/Brand/LadderIcon";
import { AssembleLink } from "../../components/AssembleLink";
import {
  SortableTableHeaderCell,
  useSort,
} from "../../components/SortableTable";
import { ComputedLadder } from "../../models/ComputedLadder";
import { GRAY_1, GRAY_4, PURPLE_1 } from "../../theme";
import { levelRangeComparator } from "../../utils";

const useStyles = makeStyles(() => ({
  tableContainer: {
    maxHeight: "calc(100vh - 260px)",
  },
  ladderRow: {
    "&:hover .MuiTypography-body1": {
      color: PURPLE_1,
    },
    "&:hover": {
      color: `${PURPLE_1} !important`,
      "& > .MuiTableCell-root": {
        borderBottomColor: `${PURPLE_1} !important`,
        borderTop: `solid 1px ${PURPLE_1}`,
      },
      "& > td:first-child": {
        borderLeft: `solid 1px ${PURPLE_1}`,
      },
      "& > td:last-child": {
        borderRight: `solid 1px ${PURPLE_1}`,
      },
    },
  },
  linkRowColor: {
    color: GRAY_1,
    "&:hover": {
      color: PURPLE_1,
    },
  },
}));

export function DepartmentDetailTable({
  ladders,
}: {
  ladders: ComputedLadder[];
}): JSX.Element {
  const classes = useStyles();

  const {
    sortedArray: sortedLadders,
    order,
    orderBy,
    handleRequestSort,
  } = useSort(ladders, "name", "asc", {
    levelMin: levelRangeComparator,
  });

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.tableContainer}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <SortableTableHeaderCell
              cellTitle="Ladder"
              orderByField="name"
              order={order}
              isSelected={orderBy === "name"}
              handleRequestSort={handleRequestSort}
              noWrap
            />
            <SortableTableHeaderCell
              cellTitle="Levels"
              orderByField="levelMin"
              order={order}
              isSelected={orderBy === "levelMin"}
              handleRequestSort={handleRequestSort}
              noWrap
            />
            <SortableTableHeaderCell
              cellTitle="Positions"
              orderByField="positionsCount"
              order={order}
              isSelected={orderBy === "positionsCount"}
              handleRequestSort={handleRequestSort}
              noWrap
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedLadders.map((ladder) => (
            <TableRow key={ladder.id} className={classes.ladderRow}>
              <TableCell scope="row">
                <Grid container spacing={2}>
                  <Grid item>
                    <LadderIcon color={GRAY_4} width="24px" height="24px" />
                  </Grid>
                  <Grid item>
                    <AssembleLink
                      to={`/ladders/${ladder.id}`}
                      underline="none"
                      className={classes.linkRowColor}
                    >
                      {ladder.name}
                    </AssembleLink>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell scope="row">
                <Typography>
                  {ladder.levelMin}–{ladder.levelMax}
                </Typography>
              </TableCell>
              <TableCell scope="row">
                <Typography>{ladder.positionsCount}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
