import { gql } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { contramap } from "@asmbl/shared/sort";
import { mapify } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import { ReactNode } from "react";
import { CheckCircleSolidIcon } from "src/components/AssembleIcons/Brand/CheckCircleSolidIcon";
import { CalendarIcon } from "src/components/AssembleIcons/Extra-Small/CalendarIcon";
import { ClockIcon } from "src/components/AssembleIcons/Extra-Small/ClockIcon";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import {
  PreviousEmployment_employment2 as Employment,
  CondensedTablePromotionCell2_participant as Participant,
  PayPeriodType,
  PositionType,
  RecItemType,
} from "../../../../__generated__/graphql";
import { AssembleTypography } from "../../../../components/AssembleTypography";
import { ExplanatoryTooltip } from "../../../../components/ExplanatoryTooltip";
import { GREEN_2, WHITE, theme } from "../../../../theme";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { MEDIUM_COL_WIDTH } from "./dimensions";

const useStyles = makeStyles(() => ({
  tooltipBody: {
    display: "flex",
    color: WHITE,
    gap: "1rem",
  },
  tooltipColumn: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  center: {
    display: "inline-flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
}));

type Props = {
  row: { original: Participant };
};

export function CondensedTablePromotionCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const classes = useStyles();
  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];

  const itemMap = new Map(
    recItems.map((item) => [item.recommendationType, item])
  );

  const promoItem = itemMap.get(RecItemType.PROMOTION);
  const positionChange =
    promoItem != null && promoItem.recommendedPosition?.id != null;

  return (
    <EmploymentChangeTooltip
      prevEmployment={employee.subject.activeEmployment}
      newEmploymentPosition={promoItem?.recommendedPosition}
    >
      <div className={classes.content}>
        {positionChange ? (
          <CheckCircleSolidIcon color={GREEN_2} width="24px" height="24px" />
        ) : (
          <AssembleTypography>-</AssembleTypography>
        )}
      </div>
    </EmploymentChangeTooltip>
  );
}

function EmploymentChangeTooltip({
  prevEmployment,
  newEmploymentPosition,
  children,
}: {
  prevEmployment: Employment | null;
  newEmploymentPosition: Employment["position"] | undefined;
  children: JSX.Element;
}): JSX.Element {
  const classes = useStyles();
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const prevEmploymentPosition = prevEmployment?.position;
  const prevDepartment = prevEmploymentPosition?.ladder.department.name;
  const prevLadder = prevEmploymentPosition?.ladder.name;
  const prevPosition = prevEmploymentPosition?.name;
  const prevPositionType = prevEmployment?.payPeriod;
  const prevLevel = prevEmploymentPosition?.level;
  const showHourly =
    compStructure?.allowHourlyEmployees === true &&
    isEnabled(FeatureFlag.HourlyEmployees);

  const prevPositionLabel = showHourly ? (
    <div className={classes.center}>
      {prevPositionType === PayPeriodType.ANNUAL && (
        <CalendarIcon color={WHITE} />
      )}
      {prevPositionType === PayPeriodType.HOURLY && <ClockIcon color={WHITE} />}
      {prevPosition}
    </div>
  ) : (
    prevPosition
  );

  const newDepartment = newEmploymentPosition?.ladder.department.name;
  const newLadder = newEmploymentPosition?.ladder.name;
  const newPosition = newEmploymentPosition?.name;
  const newPositionType = newEmploymentPosition?.type;
  const newLevel = newEmploymentPosition?.level;

  const newPositionLabel = showHourly ? (
    <div className={classes.center}>
      {newPositionType === PositionType.ANNUAL && (
        <CalendarIcon color={WHITE} />
      )}
      {newPositionType === PositionType.HOURLY && <ClockIcon color={WHITE} />}
      {newPosition}
    </div>
  ) : (
    newPosition
  );

  if (prevEmploymentPosition == null) return children;

  const DiffText = ({
    prevText,
    newText,
  }: {
    prevText: ReactNode;
    newText: ReactNode;
  }) => {
    return newText == null || prevText === newText ? (
      <AssembleTypography variant="body2">{prevText}</AssembleTypography>
    ) : (
      <div className={classes.center}>
        <AssembleTypography variant="body2">{prevText}</AssembleTypography> →{" "}
        <AssembleTypography variant="body2">{newText}</AssembleTypography>
      </div>
    );
  };

  return (
    <ExplanatoryTooltip
      title="Position"
      placement="top"
      body={
        <div className={classes.tooltipBody}>
          <div className={classes.tooltipColumn}>
            <AssembleTypography variant="body2" align="right">
              Department
            </AssembleTypography>
            <AssembleTypography variant="body2" align="right">
              Ladder
            </AssembleTypography>
            <AssembleTypography variant="body2" align="right">
              Position
            </AssembleTypography>
            <AssembleTypography variant="body2" align="right">
              Level
            </AssembleTypography>
          </div>
          <div className={classes.tooltipColumn}>
            <DiffText prevText={prevDepartment} newText={newDepartment} />
            <DiffText prevText={prevLadder} newText={newLadder} />
            <DiffText prevText={prevPositionLabel} newText={newPositionLabel} />
            <DiffText prevText={prevLevel} newText={newLevel} />
          </div>
        </div>
      }
      width="max-content"
      hideArrow
    >
      {children}
    </ExplanatoryTooltip>
  );
}

EmploymentChangeTooltip.fragments = {
  employment2: gql`
    fragment PreviousEmployment_employment2 on Employment2 {
      payPeriod
      position {
        id
        name
        level
        type
        ladder {
          id
          name
          department {
            id
            name
          }
        }
      }
    }
  `,
};

// FIXME: Using `activeEmployment` here is a poor way to get the "previous"
// employment. This will work when the compCycle is active, but could lead to
// inaccurate data if the compCycle is not active. We'll need to change the way
// Assemble views historical data to make this right.
CondensedTablePromotionCell2.fragments = {
  participant: gql`
    ${EmploymentChangeTooltip.fragments.employment2}
    fragment CondensedTablePromotionCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          ...PreviousEmployment_employment2
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
          recommendedPosition {
            id
            name
            level
            type
            ladder {
              id
              name
              department {
                id
                name
              }
            }
          }
        }
      }
    }
  `,
};

CondensedTablePromotionCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.PROMOTION
);
CondensedTablePromotionCell2.id = ColumnIds.PROMOTION;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTablePromotionCell2,
  Header: CondensedTablePromotionCell2.Header,
  id: CondensedTablePromotionCell2.id,
  width: MEDIUM_COL_WIDTH,
};
CondensedTablePromotionCell2.column = column;
CondensedTablePromotionCell2.ordering = () =>
  contramap((e: Participant) => {
    const recItems = e.compRecommendation?.latestSubmittedItems ?? [];

    const itemMap = mapify(recItems, "recommendationType");

    return itemMap.get(RecItemType.PROMOTION) !== undefined;
  });
