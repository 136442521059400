import { gql } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Dispatch, SetStateAction, useState } from "react";
import { Link } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { AssembleLink } from "../../../components/AssembleLink";
import { AssembleTypography } from "../../../components/AssembleTypography";
import { CompCycleCsvExportWrapper } from "../../../components/CsvExport/CompCycleExportWrapper";
import { GRAY_2 } from "../../../theme";

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: GRAY_2,
  },
  dialogPendingContent: {
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  button: { height: "2.5rem" },
}));

export function useCompleteReviewDialogState(
  endedAt: string | Date | null
): [
  AllCompleteReviewDialogState,
  Dispatch<SetStateAction<AllCompleteReviewDialogState>>,
] {
  return useState<AllCompleteReviewDialogState>(
    endedAt !== null ? "completed" : "closed"
  );
}

export type AllCompleteReviewDialogState =
  | CompleteReviewDialogStateNoOpen
  | "open";

export type CompleteReviewDialogStateNoOpen =
  | "closed"
  | "pending"
  | "success"
  | "error"
  | "completed";

type Props = {
  compCycle: { name: string; id: number };
  dialogState: CompleteReviewDialogStateNoOpen;
  setDialogState: (state: AllCompleteReviewDialogState) => void;
};

export function CompleteReviewDialog(props: Props): JSX.Element {
  const classes = useStyles();
  const intercom = useIntercom();

  const {
    compCycle: { name, id },
    dialogState,
    setDialogState,
  } = props;

  return (
    <Dialog
      open={dialogState !== "closed"}
      BackdropProps={{
        style: {
          backdropFilter: dialogState === "completed" ? "blur(5px)" : "none",
        },
      }}
    >
      {dialogState === "pending" && (
        <>
          <DialogTitle>
            Please give us a second while we apply your changes...
          </DialogTitle>
          <DialogContent className={classes.dialogPendingContent}>
            <CircularProgress />
          </DialogContent>
        </>
      )}
      {dialogState === "success" && (
        <>
          <DialogTitle>
            The comp cycle has been closed. Well done! 🎉
          </DialogTitle>
          <DialogContent>
            <AssembleTypography
              variant="productDialogBody"
              className={classes.dialogBody}
            >
              Please don't forget to download your CSV export.
            </AssembleTypography>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              variant="contained"
              onClick={() => setDialogState("closed")}
              component={Link}
              to="/comp-cycles"
            >
              Go to Comp Cycles
            </Button>
            <CompCycleCsvExportWrapper
              compCycleId={id}
              compCycleName={name}
              variant="button"
            />
          </DialogActions>
        </>
      )}
      {dialogState === "completed" && (
        <>
          <DialogTitle>{name} comp cycle has been completed.</DialogTitle>
          <DialogContent>
            <AssembleTypography
              variant="productDialogBody"
              className={classes.dialogBody}
            >
              If you still want to access {name} comp cycle information, you can
              export the data as CSV.
            </AssembleTypography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => setDialogState("closed")}
              component={Link}
              to="/comp-cycles"
            >
              Go to Comp Cycles
            </Button>
            <CompCycleCsvExportWrapper
              compCycleId={id}
              compCycleName={name}
              variant="button"
            />
          </DialogActions>
        </>
      )}
      {dialogState === "error" && (
        <>
          <DialogTitle>Something went wrong...</DialogTitle>
          <DialogContent>
            We were unable to mark this compensation cycle as complete. Please{" "}
            <AssembleLink to="#" onClick={intercom.show}>
              <Typography component="span">contact us</Typography>
            </AssembleLink>{" "}
            for support.
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setDialogState("closed")}
            >
              Okay
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

CompleteReviewDialog.fragments = {
  compCycleSettings: gql`
    fragment CompleteReviewDialog_compCycleSettings on CompCycle {
      id
      name
      endedAt
    }
  `,
};
