import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { UpsertUserViewInput } from "../__generated__/graphql";

export const USER_VIEW_FRAGMENT = gql`
  fragment UpsertUserView_userView on UserView {
    id
    json
  }
`;

const UPSERT_USER_VIEW = gql`
  ${USER_VIEW_FRAGMENT}
  mutation UpsertUserView($data: UpsertUserViewInput!) {
    upsertUserView(data: $data) {
      ...UpsertUserView_userView
    }
  }
`;

export function useUpsertUserView() {
  const [userViewUpload] = useMutation(UPSERT_USER_VIEW);

  return useCallback(
    async (data: UpsertUserViewInput) => {
      await userViewUpload({ variables: { data } });
    },
    [userViewUpload]
  );
}
