import { gql } from "@apollo/client";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { ComputedLocationAdjustment } from "../../../models/ComputedLocationAdjustment";
import { useUpdateLocationGroup } from "../../../mutations/Location";
import { reorder } from "../../../utils";
import {
  LocationAdjustmentTable_departments as Department,
  LocationAdjustmentTable_locationAdjustment as LocationAdjustment,
  LocationAdjustmentTable_locationGroup as LocationGroup,
} from "../../../__generated__/graphql";
import { AssembleTypography } from "../../AssembleTypography";
import { AdjustmentEditorState } from "./LocationAdjustmentEditor";
import {
  LocationAdjustmentRow,
  SourceLocationAdjustmentRow,
} from "./LocationAdjustmentRow";

const useStyles = makeStyles((theme) => ({
  listHeader: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 1),
    alignItems: "center",
  },
}));

function LocationAdjustmentTableHeader(): JSX.Element {
  const classes = useStyles();

  return (
    <Grid container className={classes.listHeader}>
      <Grid item xs={2}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Box mx={3} my={2} />
          <AssembleTypography
            variant="productTableHeader"
            color="textSecondary"
          >
            Adj. %
          </AssembleTypography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <AssembleTypography variant="productTableHeader" color="textSecondary">
          Departments
        </AssembleTypography>
      </Grid>
      <Grid item xs={2}>
        <AssembleTypography variant="productTableHeader" color="textSecondary">
          Ladders
        </AssembleTypography>
      </Grid>
      <Grid item xs={2}>
        <AssembleTypography variant="productTableHeader" color="textSecondary">
          Levels
        </AssembleTypography>
      </Grid>
      <Grid item xs={2}>
        <AssembleTypography variant="productTableHeader" color="textSecondary">
          Compensation
        </AssembleTypography>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
}

type LocationAdjustmentTableProps = {
  locationGroup: LocationGroup;
  locationAdjustments: LocationAdjustment[];
  departments: Department[];
  openAdjustmentEditor: (
    adjustmentEditorState: AdjustmentEditorState
  ) => unknown;
  onDragStart: () => unknown;
  onDragEnd: () => unknown;
};

export function SourceLocationAdjustmentTable({
  locationGroup,
}: {
  locationGroup: Omit<LocationGroup, "createdAt" | "updatedAt"> &
    Partial<Pick<LocationGroup, "createdAt" | "updatedAt">>;
}): JSX.Element {
  const computedLocationAdjustment = new ComputedLocationAdjustment(
    locationGroup.locationAdjustments[0],
    new Map(),
    new Map()
  );

  return (
    <>
      <LocationAdjustmentTableHeader />
      <Grid container>
        <SourceLocationAdjustmentRow
          locationAdjustment={computedLocationAdjustment}
        />
      </Grid>
    </>
  );
}

SourceLocationAdjustmentTable.fragments = {
  locationGroup: gql`
    fragment SourceLocationAdjustmentTable_locationGroup on LocationGroup {
      locationAdjustments {
        id
        condition
        adjustment
        description
      }
    }
  `,
};

export function LocationAdjustmentTable({
  locationGroup,
  locationAdjustments,
  departments,
  openAdjustmentEditor,
  onDragStart,
  onDragEnd,
}: LocationAdjustmentTableProps): JSX.Element {
  const computedLocationAdjustments = useCallback(() => {
    const departmentIdNameMap = mapIdToName(departments);
    const ladderIdNameMap = mapIdToName(departments.flatMap((d) => d.ladders));

    return locationAdjustments
      .map(
        (adjustment) =>
          new ComputedLocationAdjustment(
            adjustment,
            departmentIdNameMap,
            ladderIdNameMap
          )
      )
      .sort((a, b) => {
        return (
          locationGroup.rankedAdjustmentIds.indexOf(a.id) -
          locationGroup.rankedAdjustmentIds.indexOf(b.id)
        );
      });
  }, [locationGroup, locationAdjustments, departments]);

  const [orderedAdjustments, setOrderedAdjustments] = useState(
    computedLocationAdjustments()
  );

  useEffect(() => {
    // Update adjustments table if props update
    setOrderedAdjustments(computedLocationAdjustments());
  }, [computedLocationAdjustments]);

  const [updateLocationGroup] = useUpdateLocationGroup();

  const handleDragEnd = (result: DropResult) => {
    onDragEnd();
    if (!result.destination) return; // ignore if dropped outside the list

    const reorderedAdjustments = reorder(
      orderedAdjustments,
      result.source.index,
      result.destination.index
    );

    void updateLocationGroup(locationGroup.id, {
      rankedAdjustmentIds: reorderedAdjustments.map((adj) => adj.id),
    });

    setOrderedAdjustments(reorderedAdjustments);
  };

  return (
    <DragDropContext onBeforeDragStart={onDragStart} onDragEnd={handleDragEnd}>
      <LocationAdjustmentTableHeader />
      <Droppable droppableId="droppable">
        {(provided) => (
          <Grid
            container
            ref={(el: HTMLElement | null) => {
              provided.innerRef(el);
            }}
          >
            {orderedAdjustments.map((locationAdjustment, index) => (
              <LocationAdjustmentRow
                key={locationAdjustment.id}
                locationAdjustment={locationAdjustment}
                index={index}
                openAdjustmentEditor={openAdjustmentEditor}
              />
            ))}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
}

LocationAdjustmentTable.fragments = {
  locationGroup: gql`
    ${SourceLocationAdjustmentTable.fragments.locationGroup}
    fragment LocationAdjustmentTable_locationGroup on LocationGroup {
      ...SourceLocationAdjustmentTable_locationGroup
      id
      rankedAdjustmentIds
      createdAt
      updatedAt
    }
  `,
  locationAdjustment: gql`
    fragment LocationAdjustmentTable_locationAdjustment on LocationAdjustment {
      id
      description
      condition
      adjustment
    }
  `,
  departments: gql`
    fragment LocationAdjustmentTable_departments on Department {
      id
      name
      ladders {
        id
        name
      }
    }
  `,
};

function mapIdToName(records: { id: number; name: string }[]) {
  const idNameMap = new Map<number, string>();
  records.forEach((record) => idNameMap.set(record.id, record.name));
  return idNameMap;
}
