import { makeStyles, Paper, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { MinusCircleIcon } from "src/components/AssembleIcons/Brand/MinusCircleIcon";
import { PlusCircleIcon } from "src/components/AssembleIcons/Brand/PlusCircleIcon";
import { GRAY_5, PURPLE_1 } from "src/theme";
import { capitalize } from "src/utils";

type Props = {
  actionType: "add" | "remove";
  position: "left" | "right" | "top" | "bottom";
  onClick: () => unknown;
};

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1),
    position: "absolute",
    cursor: "pointer",
    border: `solid 1px ${GRAY_5}`,
  },
  right: {
    right: theme.spacing(-3),
    top: `calc(50% - ${theme.spacing(2)}px)`,
  },
  top: {
    top: theme.spacing(-4),
  },
  left: {
    left: theme.spacing(-3),
  },
  bottom: {
    bottom: theme.spacing(-3),
    left: "50%",
  },
}));

export function MatrixEditButton({ actionType, position, onClick }: Props) {
  const classes = useStyles();

  return (
    <Tooltip
      title={`${capitalize(actionType)} ${
        position === "top" || position === "right" ? "column" : "row"
      }`}
    >
      <Paper
        className={clsx(classes.buttonContainer, {
          [classes.bottom]: position === "bottom",
          [classes.top]: position === "top",
          [classes.left]: position === "left",
          [classes.right]: position === "right",
        })}
        onClick={onClick}
      >
        {actionType === "add" && <PlusCircleIcon color={PURPLE_1} />}
        {actionType === "remove" && <MinusCircleIcon color={PURPLE_1} />}
      </Paper>
    </Tooltip>
  );
}
