import { gql } from "@apollo/client";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { useTrack } from "../../analytics";
import { AssembleTypography } from "../../components/AssembleTypography";
import { useCurrencies } from "../../components/CurrenciesContext";
import { CurrencyField } from "../../components/Currency/CurrencyField";
import {
  CurrencyCode,
  CustomizeCurrencyModal_cashBand as CashBand,
  CustomizeCurrencyModal_equityBand as EquityBand,
} from "../../__generated__/graphql";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  checkbox: {
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  cashBands: CashBand[];
  equityBands: EquityBand[];
  onSave: (cashBands: CashBand[]) => unknown;
  onClose: () => unknown;
};

export function CustomizeCurrencyModal({
  cashBands: originalCashBands,
  equityBands,
  onSave,
  onClose,
}: Props): JSX.Element {
  const { currenciesList } = useCurrencies();
  const classes = useStyles();
  const { trackEvent } = useTrack();

  const [cashBands, setCashBands] = useState(originalCashBands);

  const [specifySeparately, setSpecifySeparately] = useState(
    // Default to setting them together, unless they are already different
    cashBands.some((b) => b.currencyCode !== cashBands[0].currencyCode)
  );

  function handleCancel() {
    trackEvent({ object: "Band Currency Modal", action: "Canceled" });
    onClose();
  }

  function handleSave() {
    trackEvent({ object: "Band Currency Modal", action: "Saved" });
    onSave(cashBands);
  }

  function onChangeCashCurrency(
    cashBand: CashBand,
    currencyCode: CurrencyCode
  ) {
    setCashBands((bands) =>
      bands.map((band) => {
        if (band.name === cashBand.name) {
          return { ...band, currencyCode };
        }
        return band;
      })
    );
  }

  function onChangeAllCashCurrency(currencyCode: CurrencyCode) {
    setCashBands((bands) => bands.map((band) => ({ ...band, currencyCode })));
  }

  return (
    <Dialog open fullWidth>
      <DialogTitle>Band Currency</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <AssembleTypography variant="body2">
          You can define cash compensation bands in any configured currency. For
          example, it may be convenient for a Position primarily staffed in the
          UK to have its bands defined in GBP. Assemble will use your configured
          exchange rates to allow bands to be viewed in any currency.
        </AssembleTypography>
        {specifySeparately ? (
          cashBands.map((band) => (
            <CurrencyField
              key={band.name}
              options={currenciesList.map((c) => c.code)}
              label={band.name}
              value={band.currencyCode}
              onChange={(value) => value && onChangeCashCurrency(band, value)}
            />
          ))
        ) : (
          <CurrencyField
            key={cashBands[0].name}
            options={currenciesList.map((c) => c.code)}
            label="Cash Comp Components"
            value={cashBands[0].currencyCode}
            onChange={(value) => value && onChangeAllCashCurrency(value)}
          />
        )}
        <Typography component="label">
          <Checkbox
            className={classes.checkbox}
            checked={specifySeparately}
            onChange={(_, checked) => {
              setSpecifySeparately(checked);
              if (!checked) {
                onChangeAllCashCurrency(cashBands[0].currencyCode);
              }
            }}
          />
          Specify comp components separately
        </Typography>
        <Divider />
        {equityBands.map((band) => (
          <CurrencyField
            key={band.name}
            options={currenciesList.map((c) => c.code)}
            label={band.name}
            value={band.currencyCode}
            onChange={() => {
              /* disabled */
            }}
            disabled
          />
        ))}
        <AssembleTypography variant="body2">
          Equity bands must be defined in the same currency as your company's
          valuation.
        </AssembleTypography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="contained">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CustomizeCurrencyModal.fragments = {
  cashBand: gql`
    fragment CustomizeCurrencyModal_cashBand on AdjustedCashBand {
      name
      currencyCode
    }
  `,
  equityBand: gql`
    fragment CustomizeCurrencyModal_equityBand on AdjustedEquityBand {
      name
      currencyCode
    }
  `,
};
