import { gql } from "@apollo/client";
import { zero } from "@asmbl/shared/money";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import {
  CompComponent,
  CompUnit,
  CurrencyCode,
  OneTimeBonusRow_employee,
  RecItemType,
} from "../../../../__generated__/graphql";
import { useTrack } from "../../../../analytics";
import { hasUnpublishedChanges } from "../../../../models/CompRecommendation";
import { useCurrencies } from "../../../CurrenciesContext";
import { BonusInputCell } from "../../Cells/BonusInputCell";
import { CompCycleLineItemRowButton } from "../CompCycleLineItemRowButton";
import { useConnectorStyles, useRecItemRowStyles } from "./styles";
import { CompCycleLineItemRowProps } from "./types";

interface Props extends CompCycleLineItemRowProps {
  currency: CurrencyCode | null;
  employee: OneTimeBonusRow_employee;
}
export function OneTimeBonusRow({
  employee,
  currency,
  submittedRecommendation,
  revisedRecommendation,
  onChangeRecommendationItem,
}: Props): JSX.Element {
  const { Track } = useTrack({
    compensationComponent: CompComponent.BONUS,
    employeeId: employee.id,
  });
  const classes = useRecItemRowStyles();
  const connectorStyle = useConnectorStyles();
  const { defaultCurrencyCode } = useCurrencies();
  const meritBonusItem = revisedRecommendation?.items.get(
    RecItemType.MERIT_BONUS
  );

  const submittedValue = submittedRecommendation?.items.get(
    RecItemType.MERIT_BONUS
  )?.recommendedCashValue?.value;

  const draftedValue = revisedRecommendation?.items.get(RecItemType.MERIT_BONUS)
    ?.recommendedCashValue?.value;

  return (
    <Track>
      <TableRow aria-level={3} className={classes.root}>
        <TableCell role="gridcell" padding="none">
          <div className={connectorStyle.line} />
        </TableCell>
        <TableCell
          role="gridcell"
          colSpan={5}
          padding="none"
          className={classes.textCell}
        >
          <Typography className={classes.rowLabel}>One-Time Bonus</Typography>
        </TableCell>
        <BonusInputCell
          bonus={
            meritBonusItem?.recommendedCashValue ??
            zero(currency ?? defaultCurrencyCode)
          }
          onChange={(newBonus) => {
            onChangeRecommendationItem({
              recommendationType: RecItemType.MERIT_BONUS,
              recommendedCashValue: newBonus,
              unitType: CompUnit.CASH,
              note: meritBonusItem?.note,
            });
          }}
          hasUnpublishedChanges={hasUnpublishedChanges(
            submittedValue,
            draftedValue
          )}
        />
        <TableCell role="gridcell" className={classes.noteTextCell} colSpan={4}>
          <CompCycleLineItemRowButton
            disabled={
              meritBonusItem?.recommendedCashValue === undefined &&
              meritBonusItem?.recommendedPercentValue === undefined
            }
            employee={employee}
            note={meritBonusItem?.note ?? null}
            onClick={(note: string) =>
              onChangeRecommendationItem({
                ...meritBonusItem,
                recommendationType: RecItemType.MERIT_BONUS,
                note: note,
              })
            }
          />
        </TableCell>
        <TableCell role="gridcell" />
      </TableRow>
    </Track>
  );
}

OneTimeBonusRow.fragments = {
  employee: gql`
    ${CompCycleLineItemRowButton.fragments.employee}
    fragment OneTimeBonusRow_employee on Employee2 {
      id
      ...CompCycleLineItemRowButton_employee
    }
  `,
};
