import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const DeleteIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Delete"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M20.2495 6.625H3.74951"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M9.75 10.75V16.75"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M14.25 10.75V16.75"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M15.7499 6.625V4.375C15.7499 3.97718 15.5918 3.59564 15.3105 3.31434C15.0292 3.03304 14.6477 2.875 14.2499 2.875H9.74988C9.35205 2.875 8.97052 3.03304 8.68922 3.31434C8.40791 3.59564 8.24988 3.97718 8.24988 4.375V6.625M18.7495 6.625V20.5C18.7495 20.6989 18.6705 20.8897 18.5298 21.0303C18.3892 21.171 18.1984 21.25 17.9995 21.25H5.99951C5.8006 21.25 5.60983 21.171 5.46918 21.0303C5.32853 20.8897 5.24951 20.6989 5.24951 20.5V6.625L18.7495 6.625Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
