import { Tooltip } from "@material-ui/core";
import { format } from "timeago.js";
import { AssembleTypography } from "./AssembleTypography";

type Props = {
  timestamp: Date;
};

export function TimeAgoWithTooltip({ timestamp }: Props): JSX.Element {
  const timeAgoDate = format(timestamp);
  const detailedDate = new Intl.DateTimeFormat("en-US", {
    dateStyle: "long",
    timeStyle: "medium",
  }).format(timestamp);

  return (
    <AssembleTypography variant="productExtraSmall" color="textSecondary">
      <Tooltip
        title={
          <AssembleTypography variant="productExtraSmall">
            {detailedDate}
          </AssembleTypography>
        }
        placement="top"
        arrow
        interactive
      >
        <span>{timeAgoDate}</span>
      </Tooltip>
    </AssembleTypography>
  );
}
