import { eq } from "@asmbl/shared/money";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import {
  AdjustedBandPoint,
  annualizedCashCompToBandPoint,
  formatPointValue,
  getBandPenetration,
} from "../../models/BandPoint";
import { CashCompensation } from "../../models/CashCompensation";
import { GRAY_1, GRAY_3, RED } from "../../theme";
import { WarningAlertIcon } from "../AssembleIcons/Brand/WarningAlertIcon";
import { AssembleTypography } from "../AssembleTypography";
import { Bar } from "./Bars/Bar";
import { EmptyBar } from "./Bars/EmptyBar";
import { BandPoint, HourlyBandPoint } from "./Points/BandPoint";
import { EmployeePoint } from "./Points/EmployeePoint";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "100%",
  },
  warningWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

type Props = {
  band: AdjustedBandPoint[];
  values: CashCompensation[];
  showEmptyBand?: boolean;
  hideRangeLabels?: boolean;
  hidePoints?: boolean;
  isHourly?: boolean;
};

export function NewCompSlider({
  band,
  values: valuesCash,
  showEmptyBand = false,
  hideRangeLabels = false,
  hidePoints = false,
  isHourly = false,
}: Props): JSX.Element {
  const classes = useStyles();

  const getPenetration = getBandPenetration(band);

  const values = valuesCash.map(annualizedCashCompToBandPoint);
  if (
    band.some((point) => getPenetration(point) == null) ||
    values.some((point) => getPenetration(point) == null)
  ) {
    // If we detect inconsistent units, we're in a pickle. This is either
    // corrupted data, or a developer mistake. We don't want to crash the
    // whole page, but we also cannot try to pass off incorrect data as OK.
    // So, display a clear error state instead.
    return (
      <div className={classes.warningWrapper}>
        <WarningAlertIcon inline color={RED} height={"24px"} width={"24px"} />
        <>Unable to show comparison</>
      </div>
    );
  }

  const bandPointsAreEqual = band.every((point) =>
    eq(point.annualCashEquivalent, band[0].annualCashEquivalent)
  );

  const showHourlyRangeLabels = isHourly && bandPointsAreEqual;
  const hideHourlyEmployeePoints =
    showHourlyRangeLabels && values.every((val) => getPenetration(val) === 1);

  return (
    <div className={classes.root}>
      {!bandPointsAreEqual && (showEmptyBand ? <EmptyBar /> : <Bar />)}
      {bandPointsAreEqual && isHourly ? (
        <HourlyBandPoint key={band[0].id} point={band[0]} />
      ) : (
        band
          .slice(1, -1)
          .map((bandPoint) => (
            <BandPoint
              key={bandPoint.id}
              point={bandPoint}
              offset={penetrationToOffset(
                getPenetration(bandPoint) ?? 0,
                isHourly
              )}
            />
          ))
      )}
      {!hidePoints &&
        !hideHourlyEmployeePoints &&
        values.map((valuePoint) => (
          <EmployeePoint
            key={valuePoint.name}
            point={valuePoint}
            penetration={getPenetration(valuePoint) ?? 0}
            offset={penetrationToOffset(
              getPenetration(valuePoint) ?? 0,
              isHourly
            )}
            isHourly={isHourly}
          />
        ))}
      {!hideRangeLabels && (
        <LabelRow
          band={band}
          penetration={
            values.length === 1 || bandPointsAreEqual
              ? getPenetration(values[0])
              : undefined
          }
          showHourlyRangeLabels={showHourlyRangeLabels}
          isHourly={isHourly}
        />
      )}
    </div>
  );
}

function penetrationToOffset(
  penetration: number,
  isHourly: boolean,
  offsetSize = 12
): string {
  if (penetration > 1) {
    return isHourly ? "60%" : `calc(100% + ${offsetSize}px)`;
  }
  if (penetration < 0) {
    return isHourly ? "40%" : `-${offsetSize}px`;
  }
  return `${penetration * 100}%`;
}

const useLabelRowStyles = makeStyles(() => ({
  pointLabel: {
    position: "absolute",
    top: "calc(100% + 2px)",
  },
  hourlyPointLabel: {
    position: "absolute",
    top: "calc(100% + 15px)",
    textAlign: "center",
    width: "100%",
  },
  minLabel: { left: 0 },
  maxLabel: { right: 0 },
  label: {
    textAlign: "center",
    position: "absolute",
    top: "calc(100% + 5px)",
    width: "100%",
  },
  hourlyLabel: {
    textAlign: "center",
    position: "absolute",
    top: "calc(100% + 30px)",
    width: "100%",
  },
}));

type LabelRowProps = {
  band: AdjustedBandPoint[];
  penetration?: number;
  showHourlyRangeLabels: boolean;
  isHourly: boolean;
};

export function LabelRow({
  band,
  penetration,
  showHourlyRangeLabels,
  isHourly,
}: LabelRowProps) {
  const classes = useLabelRowStyles();

  const isOutOfRange =
    penetration != null && (penetration < 0 || 1 < penetration);

  const [min, max] = [band.at(0), band.at(-1)];

  return (
    <>
      {min && !showHourlyRangeLabels && (
        <AssembleTypography
          variant="productExtraSmall"
          className={clsx(classes.pointLabel, classes.minLabel)}
          textColor={GRAY_1}
        >
          {formatPointValue(min, isHourly)}
        </AssembleTypography>
      )}
      {max && !showHourlyRangeLabels && (
        <AssembleTypography
          variant="productExtraSmall"
          className={clsx(classes.pointLabel, classes.maxLabel)}
          textColor={GRAY_1}
        >
          {formatPointValue(max, isHourly)}
        </AssembleTypography>
      )}
      {showHourlyRangeLabels && max && (
        <AssembleTypography
          className={classes.hourlyPointLabel}
          textColor={GRAY_1}
          variant="productExtraSmall"
        >
          {`${formatPointValue(max, isHourly)}/hr`}
        </AssembleTypography>
      )}
      {isOutOfRange && (
        <AssembleTypography
          variant="productEyebrowSmall"
          className={clsx({
            [classes.label]: !isHourly,
            [classes.hourlyLabel]: isHourly,
          })}
          textColor={GRAY_3}
        >
          {penetration < 0 ? "Below Band" : "Above Band"}
        </AssembleTypography>
      )}
    </>
  );
}
