import { formatTimestamp } from "@asmbl/shared/time";
import { makeStyles, Typography } from "@material-ui/core";
import { DV_BLUE, GRAY_3, WHITE } from "../../theme";

type Props = {
  note: string;
  timestamp?: string;
  children?: JSX.Element;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  content: {
    border: `1px solid ${DV_BLUE}`,
    borderRadius: "5px",
    margin: theme.spacing(1, 1, 0.5, 15),
    backgroundColor: DV_BLUE,
  },
  noteContentContainer: {
    display: "flex",
    alignContent: "center",
    justifyContent: "flex-start",
    margin: theme.spacing(3, 3, 3, 3),
  },
  noteContent: {
    fontSize: "13px",
    lineHeight: "140%",
    letterSpacing: "-0.25px",
    color: WHITE,
  },
  timestamp: {
    color: GRAY_3,
    fontSize: "12px",
    lineHeight: "140%",
    letterSpacing: "-0.15px",
    marginLeft: "12px",
  },
}));

export function CompNoteModalDialogAuthoredContent({
  children,
  timestamp,
  note,
}: Props): JSX.Element {
  const classes = useStyles();
  const noteTimestamp = formatTimestamp(timestamp);

  return (
    <>
      {noteTimestamp !== "" && (
        <div className={classes.container}>
          <Typography className={classes.timestamp}>{noteTimestamp}</Typography>
        </div>
      )}

      <div className={classes.content}>
        {children}
        <div className={classes.noteContentContainer}>
          <Typography className={classes.noteContent}>{note}</Typography>
        </div>
      </div>
    </>
  );
}
