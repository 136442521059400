import { Box, TextField } from "@material-ui/core";
import { useStyles } from "../style";
import { ChangeHandler, OfferData } from "../types";
import { AtsCandidateDropdown } from "./AtsCandidateDropdown";
import { AtsCandidate } from "./CandidateLoadingBoundary";
import { OfferDatePicker } from "./DatePicker";

export type Props = {
  data: OfferData;
  candidates: AtsCandidate[] | undefined;
  handleChange: ChangeHandler;
  defaultPositionName: string | undefined;
  manualEntry: boolean;
  setManualEntry: (value: boolean) => void;
};

export function CandidateInformation({
  data,
  candidates,
  handleChange,
  defaultPositionName,
  manualEntry,
  setManualEntry,
}: Props): JSX.Element {
  const classes = useStyles();

  const isValidEmail = data.candidateEmail?.match(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );

  // If we're editing an offer, the candidateId will be populated. If a user
  // changes the candidate information, we should wipe the candidateId and
  // create a new candidate.
  const onChange: ChangeHandler = (id, value) => {
    handleChange("candidateId", undefined);
    handleChange(id, value);
  };

  return !manualEntry ? (
    <>
      <AtsCandidateDropdown
        candidates={candidates}
        handleChange={handleChange}
        setManualEntry={setManualEntry}
      />
      <OfferDatePicker offeredAt={data.offeredAt} handleChange={handleChange} />
    </>
  ) : (
    <>
      <Box className={classes.fullWidth} mb={2}>
        <TextField
          fullWidth
          id="firstName"
          label="First Name"
          onChange={(e) => onChange("firstName", e.target.value)}
          variant="outlined"
          value={data.firstName ?? ""}
        />
      </Box>
      <Box className={classes.fullWidth} mb={2}>
        <TextField
          fullWidth
          id="lastName"
          label="Last Name"
          onChange={(e) => onChange("lastName", e.target.value)}
          variant="outlined"
          value={data.lastName ?? ""}
        />
      </Box>
      <Box className={classes.fullWidth} mb={2}>
        <TextField
          fullWidth
          error={data.candidateEmail !== undefined && !isValidEmail}
          id="candidateEmail"
          label="Candidate Email Address"
          onChange={(e) => onChange("candidateEmail", e.target.value)}
          variant="outlined"
          value={data.candidateEmail ?? ""}
        />
      </Box>
      <OfferDatePicker offeredAt={data.offeredAt} handleChange={handleChange} />
      <Box className={classes.fullWidth} mb={3}>
        <TextField
          fullWidth
          helperText="Add external-facing title if different from Position name selected"
          id="title"
          inputProps={{ maxLength: 50 }}
          label="Externally-facing Job Title"
          onChange={(e) => handleChange("title", e.target.value)}
          variant="outlined"
          value={data.title ?? defaultPositionName}
        />
      </Box>
    </>
  );
}
