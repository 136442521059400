import { gql } from "@apollo/client";
import { Money } from "@asmbl/shared/money";
import { TotalEquityTimelineChartTooltip_equityHoldings as EquityHoldings } from "../../../__generated__/graphql";
import { EquityTooltip } from "./EquityTooltip";

type Props = {
  equityHoldings: EquityHoldings;
  sharePrice: Money | undefined;
} & Partial<{
  // These properties are injected by the chart component:
  label: number; // The millisecond timestamp of the date
  payload: {
    payload: {
      currentUnitCount: number;
      grantName: string;
      unitCount: number;
      vestingDate: string;
    };
  }[];
}>;

export function TotalEquityTimelineChartTooltip({
  label,
  payload,
  equityHoldings,
  sharePrice,
}: Props): JSX.Element | null {
  const { totalUnitCount } = equityHoldings;

  return (
    <EquityTooltip
      sharePrice={sharePrice ?? null}
      totalUnitCount={totalUnitCount}
      label={label}
      payload={payload}
    />
  );
}

TotalEquityTimelineChartTooltip.fragments = {
  equityHoldings: gql`
    fragment TotalEquityTimelineChartTooltip_equityHoldings on EquityHoldings {
      id
      totalUnitCount
      grants {
        id
        sharePrice
      }
    }
  `,
};
