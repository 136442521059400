import { makeStyles } from "@material-ui/core";
import { WHITE } from "../../theme";
import { AssembleTypography } from "../AssembleTypography";
import { InfoBanner } from "../InfoBanner";

const useStyles = makeStyles(() => ({ copy: { color: WHITE } }));

type Props = { onClose: () => void };

export function EquityWarningBanner({ onClose }: Props): JSX.Element | null {
  const classes = useStyles();

  return (
    <InfoBanner onClose={onClose} level="warning">
      <AssembleTypography className={classes.copy} variant="productSmallBold">
        Your equity vesting data is not provided in full. Please contact your
        manager to ensure completeness and accuracy of your data.
      </AssembleTypography>
    </InfoBanner>
  );
}
