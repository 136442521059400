import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const BookmarkIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Bookmark"
      viewBox="0 0 8 11"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill"
        d="M0 1.62712C0 1.19558 0.168571 0.781716 0.468629 0.476572C0.768687 0.171428 1.17565 0 1.6 0H6.4C6.82435 0 7.23131 0.171428 7.53137 0.476572C7.83143 0.781716 8 1.19558 8 1.62712V10.1848C8 10.558 7.60601 10.7997 7.27338 10.6305L4 8.9661L0.726624 10.6305C0.393987 10.7997 0 10.558 0 10.1848V1.62712Z"
      />
    </svg>
  </ColorableSVG>
);
