/* eslint-disable @typescript-eslint/no-base-to-string */
import { ISettings } from "@flatfile/adapter";
import {
  ScalarDictionaryWithCustom as FlatFileRecord,
  IDataHookResponse,
} from "@flatfile/react";
import { ValidationResult, isFieldPresent } from "./common";

type Position = {
  id: number;
  name: string;
  level: number;
  ladder: {
    id: number;
    name: string;
    department: {
      id: number;
      name: string;
    };
  };
};

export function getPositionFields(
  positions: Set<string>,
  departments: Set<string>,
  ladders: Set<string>,
  levels: Set<number>
): ISettings["fields"] {
  return [
    {
      label: "Position ID",
      key: "positionId",
      validators: [
        {
          validate: "regex_matches",
          regex: "^[0-9]*$",
          error: "Must be a number",
        },
        {
          validate: "required_without_all",
          fields: [
            "departmentName",
            "ladderName",
            "levelNumber",
            "positionName",
          ],
        },
      ],
    },
    {
      label: "Department",
      key: "departmentName",
      type: "select",
      options: Array.from(departments.values()).map((name) => ({
        label: name,
        value: name,
      })),
      validators: [
        {
          validate: "required_with_all",
          fields: ["ladderName", "levelNumber", "positionName"],
        },
      ],
    },
    {
      label: "Ladder",
      key: "ladderName",
      type: "select",
      options: Array.from(ladders.values()).map((name) => ({
        label: name,
        value: name,
      })),
      validators: [
        {
          validate: "required_with_all",
          fields: ["departmentName", "levelNumber", "positionName"],
        },
      ],
    },
    {
      label: "Level",
      key: "levelNumber",
      type: "select",
      options: Array.from(levels).map((name) => ({
        label: name.toString(),
        value: name,
      })),
      validators: [
        {
          validate: "regex_matches",
          regex: "^[0-9]*$",
          error: "Must be a number",
        },
        {
          validate: "required_with_all",
          fields: ["departmentName", "ladderName", "positionName"],
        },
      ],
    },
    {
      label: "Position",
      key: "positionName",
      type: "select",
      options: Array.from(positions.values()).map((name) => ({
        label: name,
        value: name,
      })),
      validators: [
        {
          validate: "required_with_all",
          fields: ["departmentName", "ladderName", "levelNumber"],
        },
      ],
    },
  ];
}

const noMatchingPosition: IDataHookResponse[""]["info"] = [
  { message: "No matching Position found.", level: "error" },
];

const needAllMatchingFields: IDataHookResponse[""]["info"] = [
  {
    message:
      "Department, Ladder, Level, and Position are required if you are not using Position ID.",
    level: "error",
  },
];

function positionIdAndPositionByPartsPresent(
  field: string
): IDataHookResponse[""]["info"] {
  return [
    {
      message: `Position ID and ${field} are specified. This value will be ignored.`,
      level: "warning",
    },
  ];
}

export function matchPosition<P extends Position>(
  record: FlatFileRecord,
  positions: P[]
): ValidationResult<P> {
  const positionId = isFieldPresent(record.positionId)
    ? parseInt(record.positionId)
    : null;

  const departmentName = isFieldPresent(record.departmentName)
    ? record.departmentName
    : null;

  const ladderName = isFieldPresent(record.ladderName)
    ? record.ladderName
    : null;

  const levelNumber =
    record.levelNumber === "" || record.levelNumber == null
      ? null
      : parseInt(record.levelNumber.toString());

  const positionName = isFieldPresent(record.positionName)
    ? record.positionName
    : null;

  const positionByParts =
    departmentName != null &&
    ladderName != null &&
    levelNumber != null &&
    positionName != null;

  const anyPart =
    departmentName != null ||
    ladderName != null ||
    levelNumber != null ||
    positionName != null;

  if (positionId != null) {
    const positionById = positions.find(
      (position) => position.id === positionId
    );

    if (positionById && anyPart) {
      return [
        positionById,
        {
          positionId: {
            info: [
              {
                message: `Position matched: ${positionById.name}.`,
                level: "info",
              },
            ],
          },
          ...(departmentName != null
            ? {
                departmentName: {
                  info: positionIdAndPositionByPartsPresent("Department"),
                },
              }
            : {}),

          ...(ladderName != null
            ? {
                ladderName: {
                  info: positionIdAndPositionByPartsPresent("Ladder"),
                },
              }
            : {}),

          ...(levelNumber != null
            ? {
                levelNumber: {
                  info: positionIdAndPositionByPartsPresent("Level"),
                },
              }
            : {}),

          ...(positionName != null
            ? {
                positionName: {
                  info: positionIdAndPositionByPartsPresent("Position (name)"),
                },
              }
            : {}),
        },
      ];
    }

    if (positionById) {
      return [
        positionById,
        {
          positionId: {
            info: [
              {
                message: `Position matched: ${positionById.name}.`,
                level: "info",
              },
            ],
          },
        },
      ];
    }

    return [
      null,
      {
        positionId: {
          info: noMatchingPosition,
        },
      },
    ];
  }

  if (positionByParts) {
    // try to find a matching position with all the parts

    const matchingPosition = positions
      .filter(({ level }) => level === levelNumber)
      .filter(({ ladder }) => ladder.name === ladderName)
      .filter(({ ladder }) => ladder.department.name === departmentName)
      .find((position) => position.name === positionName);

    if (matchingPosition) {
      return [matchingPosition, null];
    } else {
      return [
        null,
        {
          departmentName: { info: noMatchingPosition },
          ladderName: { info: noMatchingPosition },
          levelNumber: { info: noMatchingPosition },
          positionName: { info: noMatchingPosition },
        },
      ];
    }
  } else if (anyPart) {
    return [
      null,
      {
        ...(departmentName == null
          ? {
              departmentName: {
                info: needAllMatchingFields,
              },
            }
          : {}),
        ...(ladderName == null
          ? {
              ladderName: {
                info: needAllMatchingFields,
              },
            }
          : {}),
        ...(levelNumber == null
          ? {
              levelNumber: {
                info: needAllMatchingFields,
              },
            }
          : {}),
        ...(positionName == null
          ? {
              positionName: {
                info: needAllMatchingFields,
              },
            }
          : {}),
      },
    ];
  }

  return [
    null,
    {
      positionId: { info: noMatchingPosition },
      departmentName: { info: noMatchingPosition },
      ladderName: { info: noMatchingPosition },
      levelNumber: { info: noMatchingPosition },
      positionName: { info: noMatchingPosition },
    },
  ];
}
