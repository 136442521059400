import { makeStyles } from "@material-ui/core";
import EmptyStateOffersBenefits from "../../../../assets/svgs/illustrations/empty-state-offers-benefits.svg?react";
import { EmptyTableView } from "../../../EmptyTableView";

const useEmptyStateStyles = makeStyles((theme) => ({
  emptyState: {
    marginTop: theme.spacing(15),
  },
}));

export function BenefitsPackageEmptyList(): JSX.Element {
  const classes = useEmptyStateStyles();
  return (
    <EmptyTableView
      className={classes.emptyState}
      message={<>Let's add your first Benefits Package!</>}
      svg={<EmptyStateOffersBenefits />}
      width="25em"
    />
  );
}
