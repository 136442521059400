import { GREEN_2, GREEN_3, RED, RED_2, YELLOW, YELLOW_2 } from "../../theme";

type Props = {
  color?: "green" | "yellow" | "red";
};

export function NotificationDot({ color = "green" }: Props): JSX.Element {
  const [color1, color2] =
    color === "green"
      ? [GREEN_2, GREEN_3]
      : color === "yellow"
        ? [YELLOW, YELLOW_2]
        : // color === red
          [RED, RED_2];

  // We need a random ID, or else multiple instances of this component on the
  // same page will override each other.
  const id = Math.floor(Math.random() * 1_000_000).toString();

  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none">
      <circle cx="4" cy="4" r="3.5" fill={`url(#paint0_linear_${id})`} />
      <defs>
        <linearGradient
          id={`paint0_linear_${id}`}
          x1="4.325"
          y1="5.2"
          x2="10.275"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color1} />
          <stop offset="29%" stopColor={color2} />
        </linearGradient>
      </defs>
    </svg>
  );
}
