import { gql } from "@apollo/client";
import {
  BonusCard_employee,
  CashCompType,
} from "../../../__generated__/graphql";
import { CompCard } from "../../Cards/CompCard";

type Props = {
  className?: string;
  employee: BonusCard_employee;
};

export function BonusCard({ className, employee }: Props): JSX.Element {
  const bonus = employee.activeCashCompensation?.find(
    ({ type }) => type === CashCompType.RECURRING_BONUS
  );

  if (!bonus) {
    return <></>;
  }
  return (
    <CompCard
      className={className}
      label="Target Bonus"
      value={bonus.annualCashEquivalent}
      description="Your Target Bonus"
    />
  );
}

BonusCard.fragments = {
  employee: gql`
    fragment BonusCard_employee on Employee {
      activeCashCompensation {
        employeeId
        type
        activeAt
        annualCashEquivalent
      }
    }
  `,
};
