import { useParams } from "react-router-dom";
import { useAuth } from "../../../components/Auth/AuthContext";
import { ErrorView } from "../../../components/ErrorView";
import { Dashboards, DashboardType } from "./ReportingDashboard";
import { ReportingLoadingBoundary } from "./ReportingLoadingBoundary";

export function ReportingPage() {
  const { organization } = useAuth();
  const { compCycleId, dashboard } = useParams<{
    compCycleId: string;
    dashboard: string;
  }>();
  const cycleId = compCycleId != null ? parseInt(compCycleId, 10) : NaN;

  if (!organization) {
    return <ErrorView text="Organization ID was not found." />;
  }

  if (isNaN(cycleId)) {
    return <ErrorView text="Comp Cycle ID was not found." />;
  }

  if (!isValidDashboard(dashboard)) {
    return (
      <ErrorView text="Please try selecting the 'Pay Equity Analysis' tab or selecting the 'Performance Analysis' tab to view the related dashboards." />
    );
  }

  return (
    <ReportingLoadingBoundary
      compCycleId={cycleId}
      organizationId={organization.id}
      dashboardType={dashboard}
    />
  );
}

const isValidDashboard = (
  dashboard: string | undefined
): dashboard is DashboardType => {
  return dashboard != null && Dashboards.includes(dashboard as DashboardType);
};
