import { makeStyles } from "@material-ui/core";
import Dot from "../../../../assets/svgs/timeline-connector-budgets/dot.svg?react";

const useStyles = makeStyles(() => ({
  connectorContainer: {
    position: "relative",
    display: "flex",
  },
  connectorLineFull: {
    width: "36px",
    background: "linear-gradient(#88A4BE, #88A4BE) no-repeat center/1px 100%",
  },
  connectorLineHalf: {
    height: "28px",
    width: "36px",
    background: "linear-gradient(#88A4BE, #88A4BE) no-repeat center/1px 100%",
  },
  connectorMiddleDot: {
    position: "absolute",
    top: "24px",
    right: "13px",
    height: "9px",
    width: "10px",
  },
  connectorEndDot: {
    position: "absolute",
    top: "24px",
    right: "13px",
    height: "9px",
    width: "10px",
  },
  lineContainer: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  connectorLine: {
    width: "36px",
    background: "linear-gradient(#88A4BE, #88A4BE) no-repeat center/1px 100%",
  },
}));

export type IndentationCells = ("hidden" | "visible")[];

type NameCellConnectorsProps = {
  indentation: IndentationCells;
  isLastRowCell: boolean;
  expanded: boolean;
  employeeName: string;
};

export const NameCellConnectors = ({
  indentation,
  isLastRowCell,
  employeeName,
}: NameCellConnectorsProps): JSX.Element => {
  const classes = useStyles();

  const centeredConnector = (
    <>
      <div className={classes.connectorLineFull} />
      <Dot className={classes.connectorMiddleDot} />
    </>
  );

  const endConnector = (
    <>
      <div className={classes.connectorLineHalf} />
      <Dot className={classes.connectorEndDot} />
    </>
  );

  const connectors = indentation.map((visibility, idx) => {
    if (idx !== indentation.length - 1) {
      return (
        <div
          key={`${employeeName}-connector-idx`}
          className={classes.connectorLineFull}
          style={{ visibility }}
        />
      );
    }
    return isLastRowCell ? endConnector : centeredConnector;
  });

  return <div className={classes.connectorContainer}>{connectors}</div>;
};

type ConnectorLinesProps = {
  indentation: IndentationCells;
  keyPrefix: string;
};

export const ConnectorLines = ({
  indentation,
  keyPrefix,
}: ConnectorLinesProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.lineContainer}>
      {indentation.map((visibility, idx) => (
        <div
          key={`${keyPrefix}-spacer-${idx}`}
          className={classes.connectorLine}
          style={{ visibility }}
        />
      ))}
    </div>
  );
};
