import { gql, useQuery } from "@apollo/client";
import { mapMaybe } from "@asmbl/shared/utils";
import { LinearProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import {
  EditLoadingBoundaryQuery,
  PrimaryRoleName,
} from "src/__generated__/graphql";
import { ErrorView } from "../ErrorView";
import { EditWrapper } from "./EditWrapper";
import { PhaseComplexToggleProvider } from "./PhaseDrawer/usePhaseComplexToggle";
import { PhaseManagerSearchProvider } from "./PhaseDrawer/usePhaseManagerSearch";
import {
  PhaseConfigurationData,
  PhaseConfigurationProvider,
} from "./usePhaseConfiguration";
import { attachReports } from "./utils";

type Employee = EditLoadingBoundaryQuery["employee2s"]["employees"][number];

export function EditLoadingBoundary(): JSX.Element {
  const { compCycleId } = useParams<{ compCycleId: string }>();
  const cycleId = compCycleId != null ? parseInt(compCycleId, 10) : NaN;

  const { data, loading } = useQuery<EditLoadingBoundaryQuery>(
    EditLoadingBoundary.query,
    { variables: { compCycleId: cycleId }, skip: isNaN(cycleId) }
  );

  if (!data) return loading ? <LinearProgress /> : <ErrorView />;
  if (!data.compCycle2) return <ErrorView />;

  const formattedPhaseData: PhaseConfigurationData = data.compCycle2.phases.map(
    (phase) => ({
      id: phase.id,
      startDate: new Date(phase.startDate),
      phaseOrder: phase.phaseOrder,
      assigneeIds: mapMaybe(
        phase.compCyclePhaseAssignments,
        (a) => a?.assigneeId
      ),
    })
  );

  const endDate =
    data.compCycle2.endDate != null
      ? new Date(data.compCycle2.endDate)
      : undefined;

  const fullAccessUsers = data.organization.users.filter(
    (user) => user.userAccessGrant?.roleName === PrimaryRoleName.FULL_ACCESS
  );
  const employeeMap = attachReports(data.employee2s.employees);
  const managers = [...employeeMap.values()].filter(
    (employee: Employee & { reports: number[] }) => employee.reports.length > 0
  );

  return (
    <PhaseConfigurationProvider
      initValue={{
        phaseData: formattedPhaseData,
        endDate,
      }}
    >
      <PhaseManagerSearchProvider>
        <PhaseComplexToggleProvider>
          <EditWrapper
            compCycle={{
              id: data.compCycle2.id,
              name: data.compCycle2.name,
              endDate,
            }}
            employees={managers}
            users={fullAccessUsers}
          />
        </PhaseComplexToggleProvider>
      </PhaseManagerSearchProvider>
    </PhaseConfigurationProvider>
  );
}

EditLoadingBoundary.query = gql`
  ${EditWrapper.fragments.employee}
  ${EditWrapper.fragments.user}
  ${PhaseConfigurationProvider.fragments.phase}
  query EditLoadingBoundaryQuery($compCycleId: Int!) {
    compCycle2(id: $compCycleId) {
      id
      name
      endDate
      phases {
        id
        ...PhaseConfigurationProvider_phase
      }
    }
    employee2s {
      employees {
        id
        ...EditWrapper_employee
      }
    }
    organization {
      id
      users {
        id
        userAccessGrant {
          id
          roleName
        }
        ...EditWrapper_user
      }
    }
  }
`;
