import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const ADD_SUPPORTED_MANAGERS_TO_USER_ACCESS_GRANT = gql`
  mutation AddSupportedManagersToUserAccessGrant(
    $data: AccessGrantSupportedManagerIdsInput!
  ) {
    updateUserAccessGrantSupportedManagerIds(data: $data) {
      id
    }
  }
`;
export function useAddSupportedManagersToUserAccessGrant(
  grantedUserId: number
): (employeeIds: number[]) => Promise<unknown> {
  const [addSupportedManagersToUserAccessGrant] = useMutation(
    ADD_SUPPORTED_MANAGERS_TO_USER_ACCESS_GRANT
  );

  return useCallback(
    (employeeIds: number[]) =>
      addSupportedManagersToUserAccessGrant({
        variables: { data: { grantedUserId, employeeIds } },
      }),
    [addSupportedManagersToUserAccessGrant, grantedUserId]
  );
}
