import { gql } from "@apollo/client";
import {
  CircularProgress,
  Drawer,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useState } from "react";
import { SupportedManagersDrawer_manager as Manager } from "src/__generated__/graphql";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { SearchIcon } from "src/components/AssembleIcons/Brand/SearchIcon";
import { GRAY_4, WHITE } from "src/theme";
import { DrawerTitle } from "../../DrawerTitle";
import { ManagerCard, getManagementLayer } from "../ManagerCard";

type Props = {
  userName: string;
  managers: Manager[];
  handleSave: (employeeIds: number[]) => Promise<unknown> | void;
  supportedManagerIds: number[];
};

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    position: "relative",
    width: "100%",
  },
  drawerContainer: {
    height: "100vh",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px 150px ${theme.spacing(
      4
    )}px`,
    overflowY: "scroll",
  },
  selectedManagersSection: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(3),
    padding: `${theme.spacing(1)}px 0`,
    maxWidth: 500,
  },
  managerCount: {
    color: GRAY_4,
  },
  searchIcon: {
    paddingRight: theme.spacing(1),
  },
  searchInput: {
    paddingTop: theme.spacing(3),
  },
  saveContainer: {
    position: "absolute",
    bottom: 0,
    backgroundColor: WHITE,
    width: "100%",
    boxShadow: "0px -2px 9px 0px rgba(10, 36, 64, 0.08)",
    padding: theme.spacing(4),
    display: "flex",
    justifyContent: "flex-end",
  },
  saveButton: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));

export function SupportedManagersDrawer({
  userName,
  managers,
  handleSave,
  supportedManagerIds,
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedManagerIds, setSelectedManagerIds] =
    useState<number[]>(supportedManagerIds);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const topLevelManagers =
    searchTerm === ""
      ? getManagementLayer(managers, null)
      : managers.filter((manager) =>
          manager.displayName.toLowerCase().includes(searchTerm.toLowerCase())
        );

  const classes = useStyles();

  const toggleManagerSelected = (id: number) => {
    if (selectedManagerIds.includes(id)) {
      setSelectedManagerIds(
        selectedManagerIds.filter((managerId) => managerId !== id)
      );
    } else {
      setSelectedManagerIds(selectedManagerIds.concat(id));
    }
  };

  const onSave = async () => {
    setLoading(true);
    await handleSave(selectedManagerIds);
    setIsOpen(false);
    setLoading(false);
  };

  return (
    <>
      <Drawer anchor="left" open={isOpen} onClose={() => setIsOpen(false)}>
        <div className={classes.outerContainer}>
          <div className={classes.drawerContainer}>
            <DrawerTitle
              title="Supported Managers"
              onClose={() => setIsOpen(false)}
            />
            <div className={classes.selectedManagersSection}>
              <Typography variant="h6">
                Select the managers {userName} should support:
              </Typography>
              <Typography className={classes.managerCount}>
                {selectedManagerIds.length} Managers Selected
              </Typography>
            </div>
            <TextField
              variant="outlined"
              name="name"
              className={classes.searchInput}
              fullWidth
              size="small"
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <span className={classes.searchIcon}>
                    <SearchIcon />
                  </span>
                ),
                placeholder: "Search for a manager...",
              }}
            />
            {topLevelManagers.map((manager) => (
              <ManagerCard
                key={`manager-${manager.id}`}
                manager={manager}
                managers={managers}
                toggleManagerSelected={toggleManagerSelected}
                selectedManagerIds={selectedManagerIds}
                hideDropdown={searchTerm !== ""}
              />
            ))}
          </div>
          <div className={classes.saveContainer}>
            {loading ? (
              <CircularProgress />
            ) : (
              <AssembleButton
                variant="contained"
                size="large"
                label="Save"
                className={classes.saveButton}
                disabled={selectedManagerIds.length === 0}
                onClick={onSave}
              />
            )}
          </div>
        </div>
      </Drawer>
      <AssembleButton
        variant="contained"
        size="medium"
        label="Edit Managers"
        onClick={() => setIsOpen(true)}
      />
    </>
  );
}

SupportedManagersDrawer.fragments = {
  manager: gql`
    fragment SupportedManagersDrawer_manager on Employee {
      id
      displayName
      managerId
      activeEmployment {
        id
        jobTitle
      }
      directReports {
        id
      }
      indirectReports {
        id
      }
      user {
        id
        userAccessGrant {
          id
          nounScopes {
            Employee {
              view {
                supportingManagerEmployeeIDs
              }
            }
          }
        }
      }
    }
  `,
};
