import { CurrencyCode } from "@asmbl/shared/constants";
import { money, Money } from "@asmbl/shared/money";
import { InputAdornment, makeStyles } from "@material-ui/core";
import { currencySymbol } from "../../../models/Currency";
import { AssembleLabel } from "../../Form/AssembleLabel";
import { NumberInput } from "../../Form/NumberInput";

type Props = {
  label: string;
  id: string;
  currencyCode: CurrencyCode;
  value: Money | null;
  onChange: (value: Money | null) => unknown;
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    columnGap: theme.spacing(1),
  },
}));

export function CashInput({
  label,
  id,
  currencyCode,
  value,
  onChange,
}: Props): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <AssembleLabel htmlFor={id}>{label}</AssembleLabel>
      <NumberInput
        id={id}
        thousandSeparator
        placeholder="0"
        value={value?.value ?? null}
        onValueChange={(e) =>
          onChange(
            e.floatValue === undefined
              ? null
              : money(e.floatValue, currencyCode)
          )
        }
        allowNegative={false}
        startAdornment={
          <InputAdornment position="start">
            {currencySymbol(value?.currency ?? currencyCode)}
          </InputAdornment>
        }
        fullWidth
      />
    </div>
  );
}
