import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { formatCurrency, money, Money } from "@asmbl/shared/money";
import { Box, makeStyles } from "@material-ui/core";
import { currencyName } from "../models/Currency";
import { GRAY_4, WHITE } from "../theme";
import { CurrencyCode } from "../__generated__/graphql";
import { AssembleTypography } from "./AssembleTypography";
import { useCurrencies } from "./CurrenciesContext";
import { AutocompleteSelect } from "./Form/AutocompleteSelect";
import { useLocations } from "./LocationsContext";

type CurrencyPickerProps = {
  lockedCurrency?: CurrencyCode;
  size?: "small" | "medium" | "large";
};

export function CurrencyPicker({
  lockedCurrency,
  size = "small",
}: CurrencyPickerProps): JSX.Element | null {
  const {
    currenciesList,
    currencies,
    selectedCurrency,
    selectCurrency,
    defaultCurrency,
  } = useCurrencies();
  const { selectedLocation } = useLocations();

  if (currenciesList.length === 1 || selectedLocation === null) {
    // Just the default currency, nothing to select
    return null;
  }

  const localCurrencyCode = selectedLocation[0].currencyCode;

  return (
    <AutocompleteSelect
      disabled={lockedCurrency != null}
      label="Currency"
      size={size}
      labelInfo={marketsCurrencyInfoText({
        selectedCurrency,
        localCurrency: currencies.get(localCurrencyCode) ?? defaultCurrency,
      })}
      onChange={(value: string) => {
        if (!Object.values(CurrencyCode).includes(value as CurrencyCode)) {
          return selectCurrency(currenciesList[0].code);
        }
        selectCurrency(value as CurrencyCode);
      }}
      value={lockedCurrency ?? selectedCurrency.code}
      options={currenciesList.map((currency) => ({
        value: currency.code,
        label: `${currencyName(currency.code)} (${currency.code})`,
      }))}
      renderOption={(option) => (
        <CurrencyLabel
          label={option.label}
          isLocal={option.value === localCurrencyCode}
        />
      )}
      intercomTarget="Currency Select"
    />
  );
}

const useLabelStyles = makeStyles((theme) => ({
  option: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: theme.spacing(1),
  },
  label: {
    flex: "1 1 auto",
  },
  tag: {
    flex: "0 0 content",
    color: WHITE,
    background: GRAY_4,
    textTransform: "uppercase",
    borderRadius: "2px",
    height: "0.9375rem",
    paddingInline: theme.spacing(0.75),
  },
}));

function CurrencyLabel({
  label,
  isLocal,
}: {
  label: string;
  isLocal: boolean;
}): JSX.Element {
  const classes = useLabelStyles();

  if (!isLocal) {
    return <>{label}</>;
  }

  return (
    <div className={classes.option}>
      <div className={classes.label}>{label}</div>
      <AssembleTypography variant="smallTag" className={classes.tag}>
        Local
      </AssembleTypography>
    </div>
  );
}

function marketsCurrencyInfoText({
  selectedCurrency,
  localCurrency,
}: {
  selectedCurrency: Currency;
  localCurrency: Currency;
}): { title: string; body: JSX.Element } {
  if (selectedCurrency.code === localCurrency.code) {
    return {
      title: "Local Currency",
      body: (
        <>
          The local currency is the currency specific to this market's
          compensation band set.
        </>
      ),
    };
  }

  const thousandLocal = money(1000, localCurrency.code);
  const thousandSelected = money(1000, selectedCurrency.code);

  return {
    title: "Converted Currency",
    body: (
      <>
        <Box>
          <b>
            {formatCurrencyInfo(thousandLocal)}
            {" = "}
            {formatCurrencyInfo(
              exchangeFromTo(thousandLocal, localCurrency, selectedCurrency)
            )}
          </b>
        </Box>
        <Box>
          <b>
            {formatCurrencyInfo(thousandSelected)}
            {" = "}
            {formatCurrencyInfo(
              exchangeFromTo(thousandSelected, selectedCurrency, localCurrency)
            )}
          </b>
        </Box>
        <div>
          Currency conversions are configured in your organization's settings.
        </div>
      </>
    ),
  };
}

function formatCurrencyInfo(money: Money): string {
  return formatCurrency(money, {
    maximumFractionDigits: 8,
    maximumSignificantDigits: 4,
    currencyDisplay: "code",
  });
}
