import { PrimaryRoleName } from "@asmbl/shared/permissions";
import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import BasicAndRecruiterSvg from "../../../assets/svgs/illustrations/compensation-band-access/basic-and-recruiter.svg?react";
import DefaultSvg from "../../../assets/svgs/illustrations/compensation-band-access/default.svg?react";
import FullAccessSvg from "../../../assets/svgs/illustrations/compensation-band-access/full-access.svg?react";
import SystemAdminSvg from "../../../assets/svgs/illustrations/compensation-band-access/system-admin.svg?react";
import { GRAY_4 } from "../../../theme";

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    color: GRAY_4,
    marginTop: theme.spacing(4),
    width: "30%",
    textAlign: "center",
  },
  view: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(6),
  },
}));

interface CompensationBandEmptyStateProps {
  primaryRoleName: PrimaryRoleName | null;
}

export function CompensationBandEmptyState({
  primaryRoleName,
}: CompensationBandEmptyStateProps): JSX.Element {
  const classes = useStyles();

  let image: JSX.Element = <DefaultSvg width="15%" />;
  let description =
    "To configure Compensation Band Access, start by selecting a Primary Access Role from above.";

  switch (primaryRoleName) {
    case PrimaryRoleName.FULL_ACCESS:
      image = <FullAccessSvg width="15%" />;
      description = `Full Access gives this person full view and edit access of all
          compensation bands.`;
      break;

    case PrimaryRoleName.RECRUITER:
      image = <BasicAndRecruiterSvg width="15%" />;
      description = `This person hasn't been granted access to view any compensation band information.`;
      break;

    case PrimaryRoleName.BASIC_VIEWER:
      image = <BasicAndRecruiterSvg width="15%" />;
      description = `This person hasn't been granted access to view any compensation band information.`;
      break;

    case PrimaryRoleName.HRBP:
      image = <BasicAndRecruiterSvg width="15%" />;
      description = `This person hasn't been granted access to view any compensation band information.`;
      break;

    case PrimaryRoleName.SYSTEM_ADMIN:
      image = <SystemAdminSvg width="15%" />;
      description =
        "System Admins are not allowed any access to compensation bands.";
      break;

    default:
      break;
  }

  return (
    <Box className={classes.view}>
      {image}
      <Typography variant="body1" className={classes.description}>
        {description}
      </Typography>
    </Box>
  );
}
