import { gql } from "@apollo/client";
import { Box } from "@material-ui/core";
import { Card } from "../../../../components/Cards/Card";
import { CardHeader } from "../../../../components/Cards/CardHeader";
import { CompensationTimelineChart } from "../../../../components/Portal/CompensationTimelineChart";
import { CompensationTimelineTable } from "../../../../components/Table/CompensationTimelineTable";
import {
  CompensationTimeline_cash as CashCompensation,
  CurrencyCode,
} from "../../../../__generated__/graphql";

interface Props {
  cash: CashCompensation[] | null;
  valuationCurrency: CurrencyCode;
}

export function CompensationTimeline({
  cash,
  valuationCurrency,
}: Props): JSX.Element | null {
  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Card>
        <CardHeader header="Annual Total Cash Compensation Timeline" />
        <CompensationTimelineChart
          cashCompensations={cash ?? []}
          valuationCurrency={valuationCurrency}
        />
      </Card>
      <CompensationTimelineTable compChanges={cash ?? []} />
    </Box>
  );
}

CompensationTimeline.fragments = {
  cash: gql`
    ${CompensationTimelineTable.fragments.cashCompensation}
    ${CompensationTimelineChart.fragments.cashCompensation}
    fragment CompensationTimeline_cash on CashCompensation {
      ...CompensationTimelineTable_cashCompensation
      ...CompensationTimelineChart_cashCompensation
    }
  `,
  valuationCurrency: gql`
    ${CompensationTimelineChart.fragments.valuationCurrency}
    fragment CompensationTimeline_valuationCurrency on EquityHoldings {
      id
      ...CompensationTimelineChart_valuationCurrency
    }
  `,
};
