import { makeStyles } from "@material-ui/core";
import { ComplexToggle } from "src/components/ComplexToggle";

export const phaseNumberOptions = (
  totalPhases: number
): [PhaseSelect, string | JSX.Element][] => {
  const options = Array.from({ length: totalPhases }, (_, i) => i + 1);
  return [
    ["all", "All"],
    ...options.map(
      (option) =>
        [option, option] as unknown as [PhaseSelect, string | JSX.Element]
    ),
    ["none", "No Phase"],
  ];
};

const useStyles = makeStyles(() => ({
  root: { height: "32px" },
}));

export type PhaseSelect = "all" | "none" | number;

type Props = {
  totalPhases: number;
  selectedPhaseNumber: PhaseSelect;
  setSelectedPhaseNumber: (newPhaseNumber: PhaseSelect) => void;
};

export function PhaseNumberComplexToggle({
  totalPhases,
  selectedPhaseNumber,
  setSelectedPhaseNumber,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ComplexToggle
        fullWidth
        selected={selectedPhaseNumber}
        onChange={setSelectedPhaseNumber}
        options={phaseNumberOptions(totalPhases)}
      />
    </div>
  );
}
