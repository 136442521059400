import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { CompRecAction } from "src/models/CompRecommendation";
import { CondensedTableView_compCycle as CompCycle } from "../../../../__generated__/graphql";
import { ActionBar } from "../../../../components/Layout/ActionBar";
import {
  FilterParam,
  getNumericListParam,
} from "../../../../models/FilterParams";
import { useURLSearchParams } from "../../../../models/URLSearchParams";
import CompCycleFilterBar from "../CompCycleFilterBar";
import { CompCycleSubmitChangesButtonLoadingBoundary } from "../CompCycleSubmitChangesButtonLoadingBoundary";
import { ColumnOrderProvider } from "../Contexts/ColumnOrderContext";
import { PaginationProvider } from "../Contexts/PaginationContext";
import { StatusSelectProvider } from "../Contexts/StatusSelectContext";
import { TableFilterProvider } from "../Contexts/TableFilterContext";
import { PhaseProgressBar } from "../PhaseProgressBar";
import { CurrencyCodeWithAll } from "./CondensedTableCurrencyPicker";
import { CondensedTableHeader2 } from "./CondensedTableHeader";
import { CondensedTablePeopleLoadingBoundary } from "./CondensedTablePeopleLoadingBoundary";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 5rem)", // Don't scroll, let the table do it
  },
}));

type Props = {
  compCycle: CompCycle;
  isAdmin: boolean;
};

export function CondensedTableView({ compCycle, isAdmin }: Props): JSX.Element {
  const classes = useStyles();
  const urlSearchParams = useURLSearchParams();

  const [nameSearch, setNameSearch] = useState<string | null>(null);
  const [actionToggle, setActionToggle] = useState<CompRecAction>(
    CompRecAction.ALL
  );
  const [selectedCurrency, setSelectedCurrency] =
    useState<CurrencyCodeWithAll>("all");

  const managersToEmpIds =
    getNumericListParam(urlSearchParams, FilterParam.MANAGER) ?? [];

  return (
    <TableFilterProvider>
      <PaginationProvider>
        <ColumnOrderProvider>
          <StatusSelectProvider>
            <div className={classes.root}>
              {compCycle.phases.length > 0 && (
                <PhaseProgressBar compCycle={compCycle} />
              )}
              <ActionBar
                slotLeft={<CompCycleFilterBar compCycleId={compCycle.id} />}
                slotRight={
                  <CompCycleSubmitChangesButtonLoadingBoundary
                    compCycleId={compCycle.id}
                    nameSearch={nameSearch}
                  />
                }
              />
              <CondensedTableHeader2
                onSearchTermChange={setNameSearch}
                selectedCurrency={selectedCurrency}
                onChangeSelectedCurrency={setSelectedCurrency}
                compCycle={compCycle}
                nameSearch={nameSearch}
                actionToggle={actionToggle}
                setActionToggle={setActionToggle}
              />
              <CondensedTablePeopleLoadingBoundary
                compCycleId={compCycle.id}
                employeeIds={managersToEmpIds}
                isAdmin={isAdmin}
                nameSearch={nameSearch}
                actionToggle={actionToggle}
                selectedCurrency={selectedCurrency}
              />
            </div>
          </StatusSelectProvider>
        </ColumnOrderProvider>
      </PaginationProvider>
    </TableFilterProvider>
  );
}

CondensedTableView.fragments = {
  compCycle: gql`
    ${PhaseProgressBar.fragments.compCycle}
    ${CondensedTableHeader2.fragments.compCycle2}
    fragment CondensedTableView_compCycle on CompCycle2 {
      id
      ...PhaseProgressBar_compCycle
      ...CondensedTableHeader_compCycle2
    }
  `,
};
