import { caseInsensitiveComparator } from "@asmbl/shared/sort";
import { makeStyles, MenuItem, Select } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { PURPLE_1 } from "../theme";

type PropTypes = {
  selectedId: number;
  allRecords: Record[];
  recordUrlPrefix: string;
  noSort?: boolean;
};

type Record = {
  id: number;
  name: string;
};

const useStyles = makeStyles((theme) => ({
  peerNavigation: {
    fontSize: theme.typography.h5.fontSize,
    color: PURPLE_1,
  },
}));

export function PeerNavigation({
  selectedId,
  allRecords,
  recordUrlPrefix,
  noSort = false,
}: PropTypes): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();

  const handlePeerNavigation = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (typeof event.target.value === "number") {
      navigate(`${recordUrlPrefix}/${event.target.value}`);
    }
  };

  const sortedRecords = noSort
    ? allRecords
    : allRecords
        .slice()
        .sort((a, b) => caseInsensitiveComparator(a.name, b.name));

  return (
    <Select
      value={selectedId}
      onChange={handlePeerNavigation}
      className={classes.peerNavigation}
      IconComponent={ExpandMore}
      disableUnderline
    >
      {sortedRecords.map((record) => (
        <MenuItem key={record.id} value={record.id}>
          {record.name}
        </MenuItem>
      ))}
    </Select>
  );
}
