import { ISettings } from "@flatfile/adapter";
import { DECIMAL_VALIDATOR, INTEGER_VALIDATOR } from "./Validators";

export const MeritAdjustmentFields: ISettings["fields"] = [
  {
    label: "Merit Adjustment ($)",
    key: "salaryMerit",
    validators: [
      {
        validate: "regex_matches",
        regex: INTEGER_VALIDATOR,
        error: "Must be a whole number",
      },
    ],
  },
  {
    label: "Merit Adjustment (%)",
    key: "salaryMeritPercent",
    validators: [
      {
        validate: "regex_matches",
        regex: DECIMAL_VALIDATOR,
        error: "Must be a number",
      },
    ],
  },
  {
    label: "Merit Notes",
    key: "salaryMeritNotes",
  },
];
