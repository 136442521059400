import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const BandIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Band"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M21 6.75H3C2.58579 6.75 2.25 7.08579 2.25 7.5V16.5C2.25 16.9142 2.58579 17.25 3 17.25H21C21.4142 17.25 21.75 16.9142 21.75 16.5V7.5C21.75 7.08579 21.4142 6.75 21 6.75Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M9 4V20"
        stroke="#88A4BE"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </ColorableSVG>
);
