import { makeStyles, ThemeProvider, Typography } from "@material-ui/core";
import { memo } from "react";
import ReactDOMServer from "react-dom/server";
import { MONOSPACED_FONT, theme } from "../../theme";
import { ArrayValue } from "../../utils";
import { GetEmployeeAnalyticsQuartiles } from "../../__generated__/graphql";
import { formatPercent } from "./utils";

type Quartile = ArrayValue<
  GetEmployeeAnalyticsQuartiles["employeeAnalytics"]["quartiles"]
>;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "180px",
  },
  tooltipRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  tooltipRowValue: {
    fontFamily: MONOSPACED_FONT,
    fontSize: "12px",
  },
  spacer: {
    margin: theme.spacing(1),
  },
}));

export function comparatioTooltipHtml(
  items: Quartile[],
  index: number
): string {
  return ReactDOMServer.renderToString(
    <ThemeProvider theme={theme}>
      <ComparatioTooltip items={items} index={index} />
    </ThemeProvider>
  );
}

const ComparatioTooltip = memo(function ComparatioTooltip({
  items,
  index,
}: {
  items: Quartile[];
  index: number;
}) {
  const classes = useStyles();
  const { label, values, sampleSize } = items[index];

  if (values.length !== 5) {
    return (
      <div className={classes.root}>
        <Typography variant="body2">
          <b>{label}</b>
        </Typography>
        <Typography variant="body2">Incomplete data</Typography>
      </div>
    );
  }

  const formattedValues = values.map(formatPercent);
  const [min, p25, median, p75, max] = formattedValues;
  return (
    <div className={classes.root}>
      <Typography variant="body2">
        <b>{label}</b>
      </Typography>
      <TooltipRow title="Population" value={sampleSize.toString()} />
      <div className={classes.spacer} />
      <TooltipRow title="Maximum" value={max} />
      <TooltipRow title="75th Percentile" value={p75} />
      <TooltipRow title="Median" value={median} />
      <TooltipRow title="25th Percentile" value={p25} />
      <TooltipRow title="Minimum" value={min} />
    </div>
  );
});

const TooltipRow = memo(function TooltipRow({
  title,
  value,
}: {
  title: string;
  value: string | undefined;
}): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.tooltipRow}>
      <Typography variant="body2">{title}:</Typography>
      <Typography className={classes.tooltipRowValue}>
        <b>{value}</b>
      </Typography>
    </div>
  );
});
