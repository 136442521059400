import { gql } from "@apollo/client";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  makeStyles,
  TableBody,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import clsx from "clsx";
import { IntegrationsIcon } from "src/components/AssembleIcons/Brand/IntegrationsIcon";
import { NoResultsIcon } from "src/components/AssembleIcons/Brand/NoResultsIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { format } from "timeago.js";
import {
  IntegrationsTable_cartaConnectionConfig as CartaConnectionConfig,
  GetMergeConfigs,
  MergeCategory,
} from "../../../__generated__/graphql";
import { DV_BLACK, GRAY_4, RED } from "../../../theme";
import { ArrayValue } from "../../../utils";
import {
  WireTable,
  WireTableCell,
  WireTableHead,
  WireTableUnsortableHeaderCell,
} from "../../Table/WireTable";
import { UserAvatar } from "../../UserAvatar";
import { DataMappingBanner } from "./DataMappingBanner";

type MergeConnectionConfig = ArrayValue<
  GetMergeConfigs["mergeConnectionConfigs"]
>;

const useStyles = makeStyles((theme) => ({
  integrationRow: {
    height: "70px",
  },
  integrationIcon: {
    height: "32px",
    width: "32px",
    marginRight: theme.spacing(2),
    border: "1px solid #BDD3E5",
    borderRadius: "16px",
  },
  editBtn: {
    marginLeft: -theme.spacing(1),
  },
  error: {
    color: RED,
  },
  cartaAvatar: {
    backgroundColor: DV_BLACK,
  },
  errorRow: {
    backgroundColor: "#FFEFEF",
  },
  centered: {
    display: "flex",
    justifyContent: "center",
  },
}));

type Props = {
  integrationConfigs: MergeConnectionConfig[];
  cartaConnectionConfig?: CartaConnectionConfig | null;
  editIntegration: (originId: string, category: MergeCategory) => unknown;
  createCartaIntegration: () => void;
};

export function IntegrationsTable({
  integrationConfigs,
  editIntegration,
  cartaConnectionConfig,
  createCartaIntegration,
}: Props): JSX.Element {
  const classes = useStyles();

  const cartaSyncText =
    cartaConnectionConfig && !cartaConnectionConfig.isActive
      ? "Error"
      : cartaConnectionConfig?.lastSyncAt == null
        ? "Never"
        : format(cartaConnectionConfig.lastSyncAt);

  return (
    <WireTable>
      <WireTableHead>
        <TableRow>
          <WireTableUnsortableHeaderCell
            variant="head"
            cellTitle="Integration"
          />
          <WireTableUnsortableHeaderCell variant="head" cellTitle="Origin ID" />
          <WireTableUnsortableHeaderCell variant="head" cellTitle="Type" />
          <WireTableUnsortableHeaderCell variant="head" cellTitle="Last Sync" />
          <WireTableUnsortableHeaderCell
            align="center"
            variant="head"
            cellTitle="Connected By"
          />
          <WireTableUnsortableHeaderCell variant="head" cellTitle="Edit" />
        </TableRow>
      </WireTableHead>
      <TableBody>
        {integrationConfigs.length > 0 || cartaConnectionConfig ? (
          <>
            {integrationConfigs.map((config) => (
              <TableRow key={config.id} className={classes.integrationRow}>
                <WireTableCell>
                  <Box display="flex" alignItems="center">
                    {config.imageUrl !== null ? (
                      <img
                        className={classes.integrationIcon}
                        src={config.imageUrl}
                      />
                    ) : (
                      <IntegrationsIcon width="32px" height="32px" />
                    )}
                    {config.integrationName}
                  </Box>
                </WireTableCell>
                <WireTableCell>{config.originId}</WireTableCell>
                <WireTableCell>
                  {config.integrationType.toUpperCase()}
                </WireTableCell>
                <WireTableCell>
                  {config.lastSyncAt === null
                    ? "Never"
                    : format(new Date(config.lastSyncAt))}
                </WireTableCell>
                <WireTableCell>
                  <div className={classes.centered}>
                    <UserAvatar
                      showTooltip
                      displayName={config.author.name}
                      photoURL={config.author.photoURL}
                    />
                  </div>
                </WireTableCell>
                <WireTableCell>
                  {config.originId !== null && (
                    <MergeEditButton
                      originId={config.originId}
                      category={config.integrationType as MergeCategory}
                      editIntegration={editIntegration}
                    />
                  )}
                </WireTableCell>
              </TableRow>
            ))}
            {cartaConnectionConfig && (
              <TableRow
                key={cartaConnectionConfig.id}
                className={
                  cartaConnectionConfig.isActive ? "" : classes.errorRow
                }
              >
                <WireTableCell>
                  <Box display="flex" alignItems="center">
                    <Avatar
                      alt="Carta"
                      classes={{
                        root: `${classes.integrationIcon} ${classes.cartaAvatar}`,
                      }}
                    >
                      C
                    </Avatar>
                    Carta
                  </Box>
                </WireTableCell>
                <WireTableCell>-</WireTableCell>
                <WireTableCell>Equity</WireTableCell>
                <WireTableCell
                  className={clsx({
                    [classes.error]: !cartaConnectionConfig.isActive,
                  })}
                >
                  {cartaSyncText}
                </WireTableCell>
                <WireTableCell>
                  <div className={classes.centered}>
                    <UserAvatar
                      showTooltip
                      displayName={cartaConnectionConfig.author.name}
                      photoURL={cartaConnectionConfig.author.photoURL}
                    />
                  </div>
                </WireTableCell>
                <WireTableCell>
                  {cartaConnectionConfig.isActive ? null : (
                    <Button onClick={createCartaIntegration} variant="outlined">
                      Reconnect
                    </Button>
                  )}
                </WireTableCell>
              </TableRow>
            )}
          </>
        ) : (
          <NoIntegrations />
        )}
        <DataMappingBanner />
      </TableBody>
    </WireTable>
  );
}

const MergeEditButton = ({
  originId,
  category,
  editIntegration,
}: {
  originId: string;
  category: MergeCategory;
  editIntegration: (originId: string, category: MergeCategory) => unknown;
}) => {
  const classes = useStyles();

  return (
    <Tooltip title="Edit Integration" placement="top">
      <span>
        <IconButton
          className={classes.editBtn}
          onClick={() => editIntegration(originId, category)}
        >
          <Edit fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  );
};

const NoIntegrations = () => (
  <TableRow>
    <WireTableCell colSpan={5} style={{ borderBottom: "none" }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={1}
      >
        <NoResultsIcon color={GRAY_4} height="48px" width="48px" />
        <AssembleTypography>
          You haven't installed any integrations yet. Add one by selecting one
          of the buttons above.
        </AssembleTypography>
      </Box>
    </WireTableCell>
  </TableRow>
);

IntegrationsTable.fragments = {
  cartaConnectionConfig: gql`
    fragment IntegrationsTable_cartaConnectionConfig on CartaConnectionConfig {
      id
      isActive
      lastSyncAt
      author {
        id
        name
        photoURL
      }
    }
  `,
};
