import { gql, useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";
import {
  CorrectionCostToMoveAnalysisQuery,
  CorrectionCostToMoveAnalysisQueryVariables,
} from "src/__generated__/graphql";
import { ErrorView } from "src/components/ErrorView";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { CorrectionCostToMoveInnerPage } from "./CorrectionCostToMoveInnerPage";

type Props = {
  filter: { [key: string]: number[] | null | undefined };
  targetBandPoint: string;
};
export function CorrectionCostToMoveLoadingBoundary({
  filter,
  targetBandPoint,
}: Props): JSX.Element | null {
  const { data, error } = useQuery<
    CorrectionCostToMoveAnalysisQuery,
    CorrectionCostToMoveAnalysisQueryVariables
  >(CorrectionCostToMoveLoadingBoundary.query, {
    variables: { filter, targetBandPoint },
  });

  if (!data) {
    if (error) {
      return <ErrorView text={error.message} />;
    }
    return (
      <Box pt={16}>
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <>
      <CorrectionCostToMoveInnerPage
        data={data.totalCostToMoveAnalysis}
        filter={filter}
        targetBandPoint={targetBandPoint}
      />
    </>
  );
}

CorrectionCostToMoveLoadingBoundary.query = gql`
  ${CorrectionCostToMoveInnerPage.fragments.costToMoveAnalysis}
  query CorrectionCostToMoveAnalysisQuery(
    $targetBandPoint: String!
    $filter: GetTotalEmployeesInput
  ) {
    totalCostToMoveAnalysis(target: $targetBandPoint, filter: $filter) {
      ...CorrectionCostToMoveInnerPage_costToMoveAnalysis
    }
  }
`;
