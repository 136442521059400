import { makeStyles } from "@material-ui/core";
import { GRAY_5, GRAY_6 } from "../../theme";
import { NullCompSliderTooltip } from "./NullCompSliderPopover";

type Props = {
  variant?: "default" | "condensed" | "condensedWide";
};

const useStyles = makeStyles(() => ({
  bar: {
    width: "100%",
    height: "100%",
    background: `linear-gradient(270deg, ${GRAY_6} 0%, ${GRAY_5} 100%)`,
    borderRadius: "2px",
    display: "flex",
    flexDirection: "row",
    position: "relative",
  },
  default: {
    width: "100%",
    height: "15px",
  },
  condensed: {
    width: "3.75rem",
    height: "8px",
  },
  condensedWide: {
    width: "7.5rem",
    height: "8px",
  },
}));

/* Resizable comp slider empty state for people with no band or comp info */
export function NullCompSlider({ variant = "default" }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <NullCompSliderTooltip>
      <div className={classes[variant]}>
        <div className={classes.bar} />
      </div>
    </NullCompSliderTooltip>
  );
}
