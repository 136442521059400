import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const BoldArrowRightIcon = ({
  height = "12px",
  width = "13px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.20831 6H10.2083"
        stroke="#88A4BE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08331 2.625L10.4583 6L7.08331 9.375"
        stroke="#88A4BE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
