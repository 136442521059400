import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { GRAY_6, WHITE } from "../../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "sticky",
    display: "flex",
    zIndex: 1,
    top: 0,
    backgroundColor: WHITE,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    borderBottom: `1px solid ${GRAY_6}`,
  },
  container: {
    width: "85%",
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(4),
    justifyContent: "center",
    alignItems: "center",
  },
  small: { height: theme.spacing(6.75) },
  large: { height: theme.spacing(9.5), paddingTop: theme.spacing(2) },
}));

type Props = { height: "small" | "large"; children: React.ReactNode };

export function TotalEquityStickyHeader({
  height,
  children,
}: Props): JSX.Element | null {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.small]: height === "small",
        [classes.large]: height === "large",
      })}
    >
      <div className={classes.container}>{children}</div>
    </div>
  );
}
