import { Box, makeStyles, Typography } from "@material-ui/core";
import { GRAY_4, GRAY_9, SIDEBAR_WIDTH, WHITE } from "../../theme";

const useStyles = makeStyles(() => ({
  loading: {
    alignItems: "center",
    backgroundColor: GRAY_9,
    height: "100%",
    justifyContent: "center",
    width: "calc(100% - 104px)",
  },
  loadingBox: {
    background: WHITE,
    borderRadius: "10px",
    boxShadow: "0px 4px 30px rgba(10, 36, 64, 0.1)",
    color: GRAY_4,
    height: SIDEBAR_WIDTH,
    width: "155px",
  },
}));

const Loading = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Box
      alignItems="flex-end"
      className={classes.loading}
      display="flex"
      justifyContent="flex-end"
      left={104}
      position="absolute"
    >
      <Box
        alignItems="center"
        className={classes.loadingBox}
        display="flex"
        justifyContent="center"
      >
        <Typography>Working...</Typography>
      </Box>
    </Box>
  );
};

export default Loading;
