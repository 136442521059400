import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { ErrorView } from "src/components/ErrorView";
import {
  CompCycleHomeBoundaryQuery,
  CompCycleHomeBoundaryQueryVariables,
} from "../../../__generated__/graphql";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { CompCycleReviewPhasesBoundary } from "./CompCycleReviewPhasesBoundary";
import { CompCycleReviewRequestsBoundary } from "./CompCycleReviewRequestsBoundary";

export function CompCycleHomeBoundary(): JSX.Element {
  const { compCycleId } = useParams<{ compCycleId: string }>();
  const cycleId = compCycleId != null ? parseInt(compCycleId, 10) : NaN;

  const { data, loading } = useQuery<
    CompCycleHomeBoundaryQuery,
    CompCycleHomeBoundaryQueryVariables
  >(CompCycleHomeBoundary.query, {
    variables: {
      compCycleId: cycleId,
    },
    skip: isNaN(cycleId),
  });

  if (!data?.compCycle2) {
    return loading ? (
      <LoadingSpinner />
    ) : (
      <ErrorView text="This comp cycle was deleted or does not exist. If you think something went wrong, please contact us for support." />
    );
  }

  return data.compCycle2.phases.length > 0 ? (
    <CompCycleReviewPhasesBoundary />
  ) : (
    <CompCycleReviewRequestsBoundary />
  );
}

CompCycleHomeBoundary.query = gql`
  query CompCycleHomeBoundaryQuery($compCycleId: Int!) {
    compCycle2(id: $compCycleId) {
      id
      phases {
        id
      }
    }
  }
`;
