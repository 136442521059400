import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { formatCurrency, money } from "@asmbl/shared/money";
import { mapMaybe } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { NewWindowIcon } from "src/components/AssembleIcons/Small/NewWindow";
import { AssembleLink } from "../../../components/AssembleLink";
import { AssembleTypography } from "../../../components/AssembleTypography";
import { useAuth } from "../../../components/Auth/AuthContext";
import { useLocations } from "../../../components/LocationsContext";
import {
  enoughCompData,
  getDataPointForChart,
} from "../../../models/AnonymizedCompensation";
import { DV_GREEN, GRAY_4, RED, WHITE } from "../../../theme";
import {
  AnonymizedCompensationInformationCell_anonymizedCompensation as AnonymizedCompensation,
  AnonymizedCompensationInformationCell_permissionSettings as PermissionSettings,
  AnonymizedCompensationInformationCell_position as Position,
  LiveLocationsProvider_locationGroup as LocationGroup,
} from "../../../__generated__/graphql";
import { canSeeOrganizationView } from "../../People/PeoplePageRouter";
import { useExcludedBandNames } from "../ExcludedBandNameContext";

const useStyles = makeStyles((theme) => ({
  root: { width: "200px" },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: theme.spacing(2),
  },
  header: { width: "100%" },
  bands: {
    width: "60%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  count: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  newTabIcon: {
    height: "16px",
    width: "16px",
  },
  dot: {
    height: "10px",
    width: "10px",
    borderRadius: "50%",

    border: `1.5px solid ${WHITE}`,
    boxShadow:
      "0px 0px 1px rgba(10, 36, 64, 0.15), 0px 1px 2px rgba(10, 36, 64, 0.2)",
  },
  green: { backgroundColor: DV_GREEN },
  gray: { backgroundColor: GRAY_4 },
  red: { backgroundColor: RED },
}));

type Props = {
  permissionSettings: PermissionSettings;
  position: Position;
  anonymizedCompensation: AnonymizedCompensation;
  compBand: number[];
  selectedCurrencyCode: CurrencyCode;
};

export function AnonymizedCompensationInformationCell({
  permissionSettings,
  position,
  anonymizedCompensation,
  compBand,
  selectedCurrencyCode,
}: Props): JSX.Element {
  const classes = useStyles();
  const { permissions } = useAuth();
  const { selectedLocation } = useLocations();
  const { excludedBandNames } = useExcludedBandNames();

  const selectedLocationGroup = selectedLocation?.at(1) as
    | LocationGroup
    | undefined;

  const departmentId = position.ladder.department.id;
  const ladderId = position.ladder.id;
  const level = position.level;

  const minBand = Math.min(...compBand);
  const maxBand = Math.max(...compBand);

  const hasEnoughCompData = enoughCompData(
    anonymizedCompensation,
    permissionSettings
  );

  const anonymizedCompensationData =
    anonymizedCompensation.positionAndLocationGroup.cashStatistics
      ?.anonymizedDataPoints ?? [];

  const dataPoints = mapMaybe(
    anonymizedCompensationData,
    (point) => getDataPointForChart(excludedBandNames, point)?.value
  );

  const belowBandCount = dataPoints.filter((point) => point < minBand).length;
  const aboveBandCount = dataPoints.filter((point) => point > maxBand).length;
  const inBandCount = dataPoints.length - (belowBandCount + aboveBandCount);

  const urlSearchParams = new URLSearchParams(
    selectedLocationGroup
      ? {
          department: departmentId.toString(),
          ladder: ladderId.toString(),
          level: level.toString(),
          location: selectedLocationGroup.id.toString(),
        }
      : {
          department: departmentId.toString(),
          ladder: ladderId.toString(),
          level: level.toString(),
        }
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.header}>
          <AssembleTypography variant="productHeader">
            Band&nbsp;
            {formatCurrency(money(minBand, selectedCurrencyCode), {
              maximumFractionDigits: 0,
              notation: "compact",
            })}
            &nbsp;-&nbsp;
            {formatCurrency(money(maxBand, selectedCurrencyCode), {
              maximumFractionDigits: 0,
              notation: "compact",
            })}
          </AssembleTypography>
        </div>
        {hasEnoughCompData && (
          <>
            <div className={classes.bands}>
              {/* below band */}
              <div className={classes.count}>
                <div className={clsx(classes.dot, classes.green)} />
                <AssembleTypography>{belowBandCount}</AssembleTypography>
              </div>

              {/*  in band*/}
              <div className={classes.count}>
                <div className={clsx(classes.dot, classes.gray)} />
                <AssembleTypography>{inBandCount}</AssembleTypography>
              </div>

              {/* above band */}
              <div className={classes.count}>
                <div className={clsx(classes.dot, classes.red)} />
                <AssembleTypography>{aboveBandCount}</AssembleTypography>
              </div>
            </div>

            {canSeeOrganizationView(permissions) && (
              <div>
                <AssembleLink
                  newTab
                  to={`/people/organization?${urlSearchParams.toString()}`}
                >
                  <span className={classes.link}>
                    View in People Page&nbsp;
                    <NewWindowIcon inherit />
                  </span>
                </AssembleLink>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

AnonymizedCompensationInformationCell.fragments = {
  position: gql`
    fragment AnonymizedCompensationInformationCell_position on Position {
      id
      level
      ladder {
        id
        department {
          id
        }
      }
    }
  `,
  anonymizedCompensation: gql`
    fragment AnonymizedCompensationInformationCell_anonymizedCompensation on AnonymizedCompensation {
      positionAndLocationGroup {
        cashStatistics {
          anonymizedDataPoints {
            salary
            commission
            bonus
          }
        }
        employeeStatistics {
          count
        }
      }
    }
  `,
  permissionSettings: gql`
    fragment AnonymizedCompensationInformationCell_permissionSettings on PermissionSettings {
      id
      anonymizedCompensationEmployeeThreshold
    }
  `,
};
