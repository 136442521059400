import { Breadcrumbs, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { GRAY_4 } from "../../theme";
import { ArrowRightIcon } from "../AssembleIcons/Brand/ArrowRightIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiBreadcrumbs-ol": {
      display: "flex",
    },
  },
  vertical: {
    "& .MuiBreadcrumbs-ol": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  horizontal: {
    "& .MuiBreadcrumbs-ol": {
      flexDirection: "row",
    },
  },
  horizontalSeparator: {
    marginTop: "1px",
    height: "12px",
    width: "12px",
  },
  verticalSeparator: {
    height: "12px",
    borderLeft: `1px dashed ${GRAY_4}`,
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
}));

type Props = {
  variant: "vertical" | "horizontal";
  children: React.ReactNode;
};

export function AssembleBreadCrumbs({ children, variant }: Props): JSX.Element {
  const classes = useStyles();

  const verticalSeparator = <div className={classes.verticalSeparator}></div>;
  const horizontalSeparator = <ArrowRightIcon height={"12px"} width={"12px"} />;

  return (
    <Breadcrumbs
      separator={
        variant === "vertical" ? verticalSeparator : horizontalSeparator
      }
      className={clsx(classes.root, {
        [classes.vertical]: variant === "vertical",
        [classes.horizontal]: variant === "horizontal",
      })}
    >
      {children}
    </Breadcrumbs>
  );
}
