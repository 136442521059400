import { EquityBandName } from "@asmbl/shared/constants";
import { Money } from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { Box } from "@material-ui/core";
import { BandUnit } from "../../../__generated__/graphql";
import { getSimpleCashLabel } from "../../../models/Currency";
import { OfferDataEquity } from "../../../models/Offer";
import { GRAY_3, theme } from "../../../theme";
import { bandNameComparator } from "../../../utils";
import { AssembleTypography } from "../../AssembleTypography";

type Props = {
  annualOfferedEquityComp: {
    inLocalCurrency: Money;
    inValuationCurrency: Money;
  };
  annualSalary: Money | undefined;
  equityBandTypes: EquityBandName[];
  equity: OfferDataEquity;
  showCurrentEquityValue: boolean;
  showEquityInValuationCurrency: boolean;
  grantEquityInUnits: boolean;
  pricePerUnit: Money;
};

export function EquitySection({
  annualSalary,
  equityBandTypes,
  equity,
  grantEquityInUnits,
  pricePerUnit,
  annualOfferedEquityComp,
  showEquityInValuationCurrency,
  showCurrentEquityValue,
}: Props): JSX.Element {
  return (
    <>
      {equityBandTypes
        .slice()
        .sort(bandNameComparator)
        .map((bandName) => {
          const currentDataPoint = equity[bandName];

          if (
            currentDataPoint?.mode === BandUnit.UNITS &&
            !showCurrentEquityValue
          ) {
            return (
              <Box key={bandName}>
                <AssembleTypography
                  variant="productEyebrowSmall"
                  textColor={GRAY_3}
                >
                  INITIAL EQUITY GRANT
                </AssembleTypography>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                >
                  <AssembleTypography variant="productParagraphMedium">
                    {formatNumeral(currentDataPoint.value ?? 0)} units
                  </AssembleTypography>
                </Box>
              </Box>
            );
          }

          return (
            <Box
              key={bandName}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gridRowGap={theme.spacing(0.5)}
            >
              <AssembleTypography
                variant="productEyebrowSmall"
                textColor={GRAY_3}
              >
                EQUITY VALUE (TODAY)
              </AssembleTypography>

              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-end"
                justifyContent="space-between"
              >
                <Box>
                  {grantEquityInUnits &&
                    currentDataPoint !== undefined &&
                    (currentDataPoint.mode === BandUnit.UNITS ||
                      currentDataPoint.mode === BandUnit.PERCENTAGE) && (
                      <AssembleTypography variant="productParagraphMedium">
                        {formatNumeral(
                          (showEquityInValuationCurrency
                            ? annualOfferedEquityComp.inValuationCurrency.value
                            : annualOfferedEquityComp.inLocalCurrency.value) /
                            pricePerUnit.value,
                          { maximumFractionDigits: 0 }
                        )}
                        &nbsp;units
                      </AssembleTypography>
                    )}
                  <AssembleTypography variant="productParagraphMedium">
                    {`${getSimpleCashLabel(
                      showEquityInValuationCurrency
                        ? annualOfferedEquityComp.inValuationCurrency
                        : annualOfferedEquityComp.inLocalCurrency
                    )} / year`}
                  </AssembleTypography>
                  {showEquityInValuationCurrency &&
                    annualOfferedEquityComp.inLocalCurrency.currency !==
                      annualOfferedEquityComp.inValuationCurrency.currency && (
                      <AssembleTypography variant="productParagraphMedium">
                        {`${getSimpleCashLabel(
                          annualOfferedEquityComp.inLocalCurrency
                        )} / year`}
                      </AssembleTypography>
                    )}
                </Box>
                <Box>
                  <AssembleTypography variant="productMicrocopy">
                    {currentDataPoint !== undefined &&
                      annualSalary !== undefined &&
                      `${formatNumeral(
                        annualOfferedEquityComp.inLocalCurrency.value /
                          annualSalary.value,
                        {
                          style: "percent",
                          maximumFractionDigits: 2,
                        }
                      )} of salary`}
                  </AssembleTypography>
                </Box>
              </Box>
            </Box>
          );
        })}
    </>
  );
}
