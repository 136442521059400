import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { ChangeEvent } from "react";
import { CompComponentSettings } from "src/pages/CompCycle/Settings/CompCycleSettings";
import {
  BudgetType,
  CompCycleSettings,
  CompSubComponentDisplay,
} from "../../../models/Budget";
import { GRAY_1 } from "../../../theme";
import { CompCycleDataChangeHandler } from "./types";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(1, 0),
    padding: 0,
  },
  list: {
    marginLeft: theme.spacing(2),
  },
  group: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(2),
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    color: GRAY_1,
  },
  checkbox: {
    minWidth: 0,
    marginRight: theme.spacing(2),
  },
}));

type Props = {
  compComponents: CompComponentSettings;
  handleChange:
    | CompCycleDataChangeHandler
    | ((
        string: "compComponents",
        updatedComponents: CompComponentSettings
      ) => void);
  mode?: "edit" | "create";
};

export const CompComponentsForm = ({
  compComponents,
  handleChange,
  mode = "create",
}: Props): JSX.Element => {
  const classes = useStyles();

  const selected = compComponents;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const compComponent = event.target.value as CompCycleSettings;
    const updatedSettings = processUpdatedComponents(compComponent, selected);

    handleChange("compComponents", updatedSettings);
  };

  return (
    <div className={classes.root}>
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.checkbox}>
            <Checkbox
              id="component-checkbox-salary"
              edge="start"
              onChange={onChange}
              checked={selected[CompCycleSettings.SALARY]}
              value={CompCycleSettings.SALARY}
              indeterminate={
                selected[CompCycleSettings.MARKET] ||
                selected[CompCycleSettings.MERIT] ||
                selected[CompCycleSettings.PROMOTION]
              }
              disabled={selected[CompCycleSettings.SALARY] && mode === "edit"}
            />
          </ListItemIcon>
          <ListItemText>
            <label htmlFor="component-checkbox-salary">
              {BudgetType.SALARY}
              {" (select at least 1)"}
            </label>
          </ListItemText>
        </ListItem>
        <div className={classes.group}>
          <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.checkbox}>
              <Checkbox
                id="component-checkbox-market"
                edge="start"
                onChange={onChange}
                checked={selected[CompCycleSettings.MARKET]}
                value={CompCycleSettings.MARKET}
                disabled={selected[CompCycleSettings.MARKET] && mode === "edit"}
              />
            </ListItemIcon>
            <ListItemText>
              <label htmlFor="component-checkbox-market">
                {CompSubComponentDisplay.salaryMarket}
              </label>
            </ListItemText>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.checkbox}>
              <Checkbox
                id="component-checkbox-merit"
                edge="start"
                onChange={onChange}
                checked={selected[CompCycleSettings.MERIT]}
                value={CompCycleSettings.MERIT}
                disabled={selected[CompCycleSettings.MERIT] && mode === "edit"}
              />
            </ListItemIcon>
            <ListItemText>
              <label htmlFor="component-checkbox-merit">
                {CompSubComponentDisplay.salaryMerit}
              </label>
            </ListItemText>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.checkbox}>
              <Checkbox
                id="component-checkbox-promotion"
                edge="start"
                onChange={onChange}
                checked={selected[CompCycleSettings.PROMOTION]}
                value={CompCycleSettings.PROMOTION}
                disabled={
                  selected[CompCycleSettings.PROMOTION] && mode === "edit"
                }
              />
            </ListItemIcon>
            <ListItemText>
              <label htmlFor="component-checkbox-promotion">
                {CompSubComponentDisplay.salaryPromotion}
              </label>
            </ListItemText>
          </ListItem>
        </div>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.checkbox}>
            <Checkbox
              id="component-checkbox-target-commission"
              edge="start"
              onChange={onChange}
              checked={selected[CompCycleSettings.TARGET_COMMISSION]}
              value={CompCycleSettings.TARGET_COMMISSION}
              disabled={
                selected[CompCycleSettings.TARGET_COMMISSION] && mode === "edit"
              }
            />
          </ListItemIcon>
          <ListItemText>
            <label htmlFor="component-checkbox-target-commission">
              {BudgetType.TARGET_COMMISSION}
            </label>
          </ListItemText>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.checkbox}>
            <Checkbox
              id="component-checkbox-target-recurring-bonus"
              edge="start"
              onChange={onChange}
              checked={selected[CompCycleSettings.TARGET_RECURRING_BONUS]}
              value={CompCycleSettings.TARGET_RECURRING_BONUS}
              disabled={
                selected[CompCycleSettings.TARGET_RECURRING_BONUS] &&
                mode === "edit"
              }
            />
          </ListItemIcon>
          <ListItemText>
            <label htmlFor="component-checkbox-target-recurring-bonus">
              {BudgetType.TARGET_RECURRING_BONUS}
            </label>
          </ListItemText>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.checkbox}>
            <Checkbox
              id="component-checkbox-actual-recurring-bonus"
              edge="start"
              onChange={onChange}
              checked={selected[CompCycleSettings.ACTUAL_RECURRING_BONUS]}
              value={CompCycleSettings.ACTUAL_RECURRING_BONUS}
              disabled={
                selected[CompCycleSettings.ACTUAL_RECURRING_BONUS] &&
                mode === "edit"
              }
            />
          </ListItemIcon>
          <ListItemText>
            <label htmlFor="component-checkbox-actual-recurring-bonus">
              {BudgetType.ACTUAL_RECURRING_BONUS}
            </label>
          </ListItemText>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.checkbox}>
            <Checkbox
              id="component-checkbox-bonus"
              edge="start"
              onChange={onChange}
              checked={selected[CompCycleSettings.BONUS]}
              value={CompCycleSettings.BONUS}
              disabled={selected[CompCycleSettings.BONUS] && mode === "edit"}
            />
          </ListItemIcon>
          <ListItemText>
            <label htmlFor="component-checkbox-bonus">
              {BudgetType.ONE_TIME_BONUS}
            </label>
          </ListItemText>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.checkbox}>
            <Checkbox
              id="component-checkbox-equity"
              edge="start"
              onChange={onChange}
              checked={selected[CompCycleSettings.EQUITY]}
              value={CompCycleSettings.EQUITY}
              disabled={selected[CompCycleSettings.EQUITY] && mode === "edit"}
            />
          </ListItemIcon>
          <ListItemText>
            <label htmlFor="component-checkbox-equity">
              {BudgetType.EQUITY}
            </label>
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );
};

const processUpdatedComponents = (
  component: CompCycleSettings,
  settings: CompComponentSettings
): CompComponentSettings => {
  const updated = { ...settings };

  if (component in settings) {
    const settingsComponent = component as keyof CompComponentSettings;
    updated[settingsComponent] = !settings[settingsComponent];
  }

  const isSalarySubComponent =
    component === CompCycleSettings.PROMOTION ||
    component === CompCycleSettings.MARKET ||
    component === CompCycleSettings.MERIT;
  const isAnySalarySubComponentEnabled =
    updated[CompCycleSettings.PROMOTION] ||
    updated[CompCycleSettings.MARKET] ||
    updated[CompCycleSettings.MERIT];

  if (component === CompCycleSettings.SALARY) {
    return {
      ...updated,
      [CompCycleSettings.MARKET]: updated[CompCycleSettings.SALARY],
      [CompCycleSettings.MERIT]: updated[CompCycleSettings.SALARY],
      [CompCycleSettings.PROMOTION]: updated[CompCycleSettings.SALARY],
    };
  } else if (isSalarySubComponent) {
    updated[CompCycleSettings.SALARY] = isAnySalarySubComponentEnabled;
  }

  return updated;
};
