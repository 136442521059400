import { gql } from "@apollo/client";
import { plainDateToUTCString } from "@asmbl/shared/time";
import { mapMaybe } from "@asmbl/shared/utils";
import {
  EditWrapper_employee as Employee,
  EditWrapper_user as User,
} from "src/__generated__/graphql";
import { useUpdateCompCycleEndDate } from "src/mutations/CompCycle";
import { useUpdateCompCyclePhases } from "src/mutations/CompCyclePhase";
import { Main } from "./Main";
import { usePhaseConfiguration } from "./usePhaseConfiguration";

type CompCycle = {
  id: number;
  name: string;
  endDate: Date | undefined;
};

type Props = {
  compCycle: CompCycle;
  employees: (Employee & { reports: number[] })[];
  users: User[];
};

export function EditWrapper({
  compCycle,
  employees,
  users,
}: Props): JSX.Element {
  const updateCompCyclePhases = useUpdateCompCyclePhases();
  const updateCompCycleEndDate = useUpdateCompCycleEndDate(compCycle.id);
  const { phaseConfiguration, endDate } = usePhaseConfiguration();

  const handleOnNext = async () => {
    // update end date if it changed
    if (endDate !== compCycle.endDate) {
      await updateCompCycleEndDate(endDate ?? null);
    }

    await updateCompCyclePhases(
      compCycle.id,
      mapMaybe(phaseConfiguration, (phase) => {
        if (phase.id == null || phase.startDate == null) return null;

        return {
          id: phase.id,
          startDate: plainDateToUTCString(phase.startDate),
        };
      })
    );
  };

  return (
    <Main
      variant="edit"
      compCycleName={compCycle.name}
      users={users}
      employees={employees}
      onNext={handleOnNext}
    />
  );
}

EditWrapper.fragments = {
  employee: gql`
    ${Main.fragments.employee}
    fragment EditWrapper_employee on Employee2 {
      id
      ...Main_employee
    }
  `,
  user: gql`
    ${Main.fragments.user}
    fragment EditWrapper_user on User {
      id
      ...Main_user
    }
  `,
};
