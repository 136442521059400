import { makeStyles, TableBody, TableCell, TableRow } from "@material-ui/core";
import clsx from "clsx";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_3, GRAY_6, WHITE } from "src/theme";
import { FocusableInputCell } from "./FocusableInputCell";
import { MatrixEditButton } from "./MatrixEditButton";
import { CellLocation } from "./MeritAndBudgetMatrices";
import { useTableStyles } from "./tableStyles";

type Props = {
  cells: { label: string | number; id?: number }[][];
  title: string;
  onChange: (id: number, value: number) => void;
  onUpdatePerfRatingOption: (rowIdx: number, newName: string) => void;
  onDeletePerfRatingOption: (rowIdx: number) => void;
  matrixType: "budget" | "merit";
  onAddGuidanceRange?: () => void;
  onAddPerfRatingOption?: () => void;
  setHoveredCell: ({ x, y, table }: CellLocation) => void;
  showAddColumn?: boolean;
  showAddRow?: boolean;
  hoveredCell: CellLocation;
  focusedCell: CellLocation;
  setFocusedCell: ({ x, y, table }: CellLocation) => void;
};

const useStyles = makeStyles((theme) => ({
  tableTitle: {
    color: GRAY_3,
    marginBottom: theme.spacing(1),
  },
  titleCell: {
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.25)}px 0`,
  },
  relative: {
    position: "relative",
  },
  tableHead: {
    backgroundColor: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px 5px 0 0",
    display: "block",
    height: "30px",
    marginTop: theme.spacing(5),
    marginBottom: "-1px",
    width: "100%",
  },
}));

export function Matrix({
  cells,
  title,
  onChange,
  onUpdatePerfRatingOption,
  onDeletePerfRatingOption,
  matrixType,
  onAddGuidanceRange = () => {},
  onAddPerfRatingOption = () => {},
  setHoveredCell,
  showAddRow = false,
  showAddColumn = false,
  hoveredCell,
  focusedCell,
  setFocusedCell,
}: Props) {
  const classes = useStyles();
  const tableClasses = useTableStyles();

  return (
    <>
      <TableRow className={classes.tableHead}>
        <TableCell
          colSpan={cells[0]?.length ?? 1}
          className={clsx([classes.titleCell, tableClasses.muiCellOverride])}
        >
          <AssembleTypography
            variant="productEyebrow"
            className={classes.tableTitle}
          >
            {title}
          </AssembleTypography>
        </TableCell>
      </TableRow>

      {cells.length ? (
        <TableBody className={classes.relative}>
          {cells.map((cellRow, rowIdx) => (
            <TableRow key={cellRow[0].label}>
              {cellRow.map((cell, cellIdx) => (
                <FocusableInputCell
                  key={`focusableInput-${rowIdx}-${cellIdx}`}
                  cells={cells}
                  onChange={onChange}
                  onUpdatePerfRatingOption={onUpdatePerfRatingOption}
                  onDeletePerfRatingOption={onDeletePerfRatingOption}
                  matrixType={matrixType}
                  setHoveredCell={setHoveredCell}
                  hoveredCell={hoveredCell}
                  focusedCell={focusedCell}
                  setFocusedCell={setFocusedCell}
                  cellIdx={cellIdx}
                  rowIdx={rowIdx}
                  cellRowLength={cellRow.length}
                  cell={cell}
                />
              ))}
            </TableRow>
          ))}
          {matrixType === "budget" && showAddRow ? (
            <MatrixEditButton
              position="bottom"
              actionType="add"
              onClick={onAddPerfRatingOption}
            />
          ) : null}
          {matrixType === "merit" && showAddColumn ? (
            <MatrixEditButton
              position="right"
              actionType="add"
              onClick={onAddGuidanceRange}
            />
          ) : null}
        </TableBody>
      ) : (
        "No data to display"
      )}
    </>
  );
}
