import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { AnonymizedCompensationCartesianGridProps } from "../../models/AnonymizedCompensation";
import { Range } from "../../utils";

const useStyles = makeStyles((theme) => ({
  container: { position: "relative" },
  skeleton: {
    position: "absolute",
    top: 55,
    left: theme.spacing(2),
    width: "95%",
    height: "1.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

type Props = { totalCompRange: Range };

export function AnonymizedCompensationBandVisualizationLoadingSkeleton({
  totalCompRange,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ResponsiveContainer width="100%" height={130}>
        <ComposedChart layout="vertical" data={[]}>
          <CartesianGrid {...AnonymizedCompensationCartesianGridProps} />
          <XAxis
            domain={[totalCompRange.min, totalCompRange.max]}
            type="number"
            scale="linear"
            hide
          />
          <YAxis type="category" hide domain={[]} />
        </ComposedChart>
      </ResponsiveContainer>

      <Skeleton className={classes.skeleton} variant="text" animation="wave" />
    </div>
  );
}
