import { Box, Tooltip, Typography } from "@material-ui/core";
import { GRAY_4 } from "../../theme";
import { LevelIcon } from "../AssembleIcons/Brand/LevelIcon";

type Props = {
  text: string;
  isPosition: boolean;
};

export function Hint({ text, isPosition }: Props): JSX.Element {
  return isPosition ? (
    <Tooltip title="Level" placement="top">
      <Typography variant="body2" color="textSecondary">
        <LevelIcon color={GRAY_4} inline />
        <Box component="span" mr={1} />
        {text}
      </Typography>
    </Tooltip>
  ) : (
    <Tooltip title="Positions" placement="top">
      <Typography variant="body2" color="textSecondary">
        {text}
      </Typography>
    </Tooltip>
  );
}
