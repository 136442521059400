import { ListOrderedIcon } from "src/components/AssembleIcons/Brand/ListOrderedIcon";
import { StyledBlockButton } from "../StyledBlockButton/StyledBlockButton";

const ORDERED_LIST = "ordered-list-item";

export type OrderedListBlockProps = {
  editorState: Draft.EditorState;
  onClick: () => unknown;
};

export const OrderedListBlock = (props: OrderedListBlockProps): JSX.Element => {
  const { editorState, onClick } = props;

  const startKey = editorState.getSelection().getStartKey();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(startKey)
    .getType();

  const active = blockType === ORDERED_LIST;

  return (
    <StyledBlockButton active={active} onClick={onClick}>
      <ListOrderedIcon width="24px" height="24px" inherit />
    </StyledBlockButton>
  );
};
