import { ReactNode } from "react";

/**
 * This component prevents any clicks within this component from propagating
 * to its ancestors. This allows certain UIs, such as a linked Card with
 * action buttons; clicking the action buttons perform their action, and
 * clicking anywhere else on the card navigates the link.
 */
export function ClickPropagationBoundary({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {children}
    </div>
  );
}
