import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { LabelValueTuple } from "src/__generated__/graphql";
import { APP_FONTS } from "../../theme";
import { createChartColors, sortCategories } from "./utils";

type Props = {
  items: LabelValueTuple[];
  height?: string;
  showPercentages?: boolean;
};

export function BarChart({
  items,
  height,
  showPercentages = false,
}: Props): JSX.Element {
  const containsOmittedCategory = items.some(
    (item) => item.label === "Omitted"
  );
  const sortedItems = sortCategories(items);
  const labels = sortedItems.map((i) => i.label);
  const values = sortedItems.map((i) => i.value);
  const colors = createChartColors(containsOmittedCategory, values.length);
  // Custom set the max to accommodate for data label placement
  const max = Math.max(0, ...values);
  // offset to add to max value for data label placement
  const labelOffset =
    max >= 100 ? Math.min(Math.round(max / 200) * 20, 500) : 15;
  // padding added to right of grid for data label
  const paddingLabelOffset = Math.min(Math.round(max / 100) * 10, 70);

  const options: ApexOptions = {
    labels,
    colors,
    series: [{ data: values }],
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#333"],
        fontSize: "12px",
        // hack to get legend formatter to refresh when user toggles
        fontWeight: showPercentages ? 400 : "400",
      },
      textAnchor: "start",
      offsetX: 16,
      formatter: function (value, opts: { dataPointIndex: number }) {
        const absoluteValue = values[opts.dataPointIndex];
        const seriesTotal = values.reduce((acc, cur) => acc + cur, 0);
        const percentValue = absoluteValue / seriesTotal;
        const formattedPercent = percentValue.toLocaleString("en-US", {
          style: "percent",
          maximumFractionDigits: 1,
        });
        return showPercentages
          ? `${formattedPercent} (${absoluteValue})`
          : `${absoluteValue} (${formattedPercent})`;
      },
    },
    legend: {
      show: false,
    },
    chart: {
      type: "bar",
      toolbar: { show: false },
      parentHeightOffset: 0,
      animations: {
        dynamicAnimation: {
          enabled: false,
        },
      },
      fontFamily: APP_FONTS,
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        horizontal: true,
        distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    xaxis: {
      labels: { show: false },
      axisBorder: { show: false },
      axisTicks: { show: false },
      max: max + labelOffset,
    },
    yaxis: {
      labels: {
        maxWidth: 275,
        style: {
          fontSize: "14px",
        },
        offsetY: 5,
      },
    },
    grid: {
      show: false,
      padding: {
        right: paddingLabelOffset,
      },
    },
    states: {
      hover: {
        filter: { type: "none" },
      },
      active: {
        filter: { type: "none" },
      },
    },
    tooltip: {
      x: {
        show: true,
      },
      y: {
        title: {
          formatter: () => "",
        },
      },
    },
  };

  return (
    <ReactApexChart
      height={height}
      options={options}
      series={options.series}
      type="bar"
    />
  );
}
