import { gql } from "@apollo/client";
import { useCallback, useState } from "react";
import { useUpsertOffer } from "../../mutations/Offer";
import {
  OfferNewCompStructure as CompStructure,
  OfferNewOfferConfig as OfferConfig,
  OfferNewValuation as Valuation,
} from "../../__generated__/graphql";
import { useCurrencies } from "../CurrenciesContext";
import { useLocations } from "../LocationsContext";
import { OfferGeneration } from "./OfferGeneration";
import { ChangeHandler, OfferData } from "./types";

type Props = {
  offerConfig: OfferConfig;
  valuation: Valuation | null;
  compStructure: CompStructure | null;
};

export function OfferNew({
  offerConfig,
  valuation,
  compStructure,
}: Props): JSX.Element {
  const { defaultCurrency } = useCurrencies();
  const { markets } = useLocations();

  const market = markets.length === 1 ? markets.at(0) : undefined;
  const locationGroup =
    market?.locationGroups.length === 1
      ? market.locationGroups.at(0)
      : undefined;

  const [data, setData] = useState<OfferData>({
    offeredAt: new Date(),
    currency: defaultCurrency,
    cash: {},
    equity: {},
    market,
    locationGroup,
    welcomeMessage: offerConfig.defaultWelcomeMessage ?? undefined,
    closingMessage: offerConfig.defaultClosingMessage ?? undefined,
    outcomeDescription: offerConfig.defaultOutcomeDescription,
    customFields: offerConfig.customFields,
    customFieldAnswers: offerConfig.customFields.map(({ name }) => ({
      name,
      answer: "",
    })),
  });

  const onChange: ChangeHandler = useCallback((id, value) => {
    setData((prevData) => ({ ...prevData, [id]: value }));
  }, []);

  const upsertOffer = useUpsertOffer(compStructure, valuation);

  const onSubmit = useCallback(() => upsertOffer(data), [upsertOffer, data]);

  const hasUnsavedChanges = [
    data.position,
    data.candidateEmail,
    data.firstName,
    data.lastName,
  ].some((x) => x === undefined);

  return (
    <OfferGeneration
      data={data}
      onChange={onChange}
      onSubmit={onSubmit}
      requireApproval={offerConfig.requireApproval}
      hasUnsavedChanges={hasUnsavedChanges}
    />
  );
}

OfferNew.fragments = {
  offerConfig: gql`
    fragment OfferNewOfferConfig on OfferConfig {
      defaultOutcomeDescription
      defaultWelcomeMessage
      defaultClosingMessage
      requireApproval
      customFields {
        name
        description
        variant
        options
      }
    }
  `,
  valuation: gql`
    fragment OfferNewValuation on Valuation {
      fdso
      valuation
    }
  `,
  compStructure: gql`
    fragment OfferNewCompStructure on CompStructure {
      id
      cashBandTypes
      equityBandTypes
      equityGrantMethod
    }
  `,
};
