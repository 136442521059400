import { makeStyles } from "@material-ui/core";
import {
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { CompensationEmptyWarning } from "../../../components/EmptyState/CompensationEmptyWarning";
import { AnonymizedCompensationCartesianGridProps } from "../../../models/AnonymizedCompensation";
import { Range } from "../../../utils";

const useStyles = makeStyles(() => ({ container: { position: "relative" } }));

type Props = { totalCompRange: Range; empty: boolean; noAccess: boolean };

export function NoAccessBandVisualization({
  totalCompRange,
  empty,
  noAccess,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ResponsiveContainer width="100%" height={130}>
        <ComposedChart layout="vertical" data={[]}>
          <CartesianGrid {...AnonymizedCompensationCartesianGridProps} />
          <XAxis
            domain={[totalCompRange.min, totalCompRange.max]}
            type="number"
            scale="linear"
            hide
          />
          <YAxis type="category" hide domain={[]} />
        </ComposedChart>
      </ResponsiveContainer>

      {empty && <CompensationEmptyWarning noAccess={noAccess} />}
    </div>
  );
}
