import {
  LinearProgress,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ReactNode } from "react";
import {
  DV_YELLOW,
  GRAY_1,
  GRAY_4,
  GRAY_6,
  GREEN_2,
  RED,
} from "../../../theme";
import { BudgetDetailTooltip } from "./BudgetDetailTooltip";

type Props = {
  budgetType: string;
  percentUsed: number | null; // 0-100, or null if no budget allocated
  title?: ReactNode;
  usedOfAllocated: ReactNode;
  hideBudgetBar?: boolean;
};

const useStyles = makeStyles((theme) => ({
  budgetContainer: {
    display: "flex",
    flexDirection: "column",
    width: "25%",
  },
  budgetTitle: {
    height: "2rem",
    paddingTop: theme.spacing(2),
    color: GRAY_4,
    textTransform: "uppercase",
    textAlign: "right",
    fontWeight: 700,
    fontSize: "0.625rem",
    lineHeight: 1.4,
  },
  "@media (max-width: 100rem)": {
    budgetTitle: {
      height: "3rem",
    },
  },
  budgetText: {
    color: GRAY_1,
    fontWeight: 400,
    fontSize: "1.125rem",
    letterSpacing: "-0.25px",
    lineHeight: 1.55,
    textAlign: "right",
  },
  linearProgress: {
    height: "0.5rem",
    borderRadius: "2px",
    marginTop: theme.spacing(0.5),
    backgroundColor: GRAY_6,
  },
  progressBar: {
    borderRadius: "2px",
    backgroundColor: GREEN_2,
  },
  errorProgressBar: {
    borderRadius: "2px",
    backgroundColor: RED,
  },
  midProgressBar: {
    borderRadius: "2px",
    backgroundColor: DV_YELLOW,
  },
}));

export function BudgetDetail({
  budgetType,
  percentUsed,
  title = budgetType,
  usedOfAllocated,
  hideBudgetBar = false,
}: Props): JSX.Element {
  const classes = useStyles();

  const progressBarClass =
    percentUsed === null || percentUsed < 65
      ? classes.progressBar
      : percentUsed < 100
        ? classes.midProgressBar
        : classes.errorProgressBar;

  return (
    <Tooltip
      title={
        <BudgetDetailTooltip
          budgetType={budgetType}
          percentUsed={percentUsed}
        />
      }
    >
      <div className={classes.budgetContainer}>
        <Typography variant="h6" className={classes.budgetTitle}>
          {title}
        </Typography>
        <Typography className={classes.budgetText}>
          {usedOfAllocated}
        </Typography>
        {percentUsed !== null && !hideBudgetBar && (
          <LinearProgress
            variant="determinate"
            value={
              // clamp the value to 0-100, so that it doesn't wrap around
              Math.max(0, Math.min(percentUsed, 100))
            }
            classes={{
              root: classes.linearProgress,
              bar: progressBarClass,
            }}
          />
        )}
      </div>
    </Tooltip>
  );
}
