import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { GRAY_6, GRAY_8, PURPLE_2, theme, WHITE } from "../../theme";
import { ChevronDownIcon } from "../AssembleIcons/Brand/ChevronDownIcon";

const useStyles = makeStyles(() => ({
  box: {
    background: WHITE,
    boxShadow: "0px 1px 3px rgba(10, 36, 64, 0.08)",
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px",
    display: "inline-flex",
    verticalAlign: "middle",
    position: "relative",
    transition: "all 125ms",
    width: "360px",
    height: "60px",

    "&:hover": {
      background: GRAY_8,
    },
  },

  selected: {
    border: `2px solid ${PURPLE_2}`,
  },
  indeterminate: {
    border: `2px dashed ${PURPLE_2}`,
  },

  data: {
    height: "100%",
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-start",
    padding: `0 0 0 ${theme.spacing(3)}px`,
    color: "inherit",
    fontWeight: "normal",

    "&$collapsible": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },

  toggle: {
    borderRight: `1px solid ${GRAY_6}`,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    height: "100%",
  },
  caret: {
    transition: "all 125ms",

    "$collapsed &": {
      transform: "rotate(-90deg)",
    },
  },
  collapsed: {},
  collapsible: {},
  categoryLabel: {
    lineHeight: "initial",
  },
}));

interface Props {
  selected: boolean;
  indeterminate: boolean;
  isCollapsible: boolean;
  collapsed: boolean;
  onClick: () => unknown;
  onCollapseExpand: () => unknown;
  label: string;
  category: string;
  hint: JSX.Element;
  decorator?: JSX.Element;
}

export function ChipCheckbox({
  selected,
  indeterminate,
  isCollapsible,
  collapsed,
  label,
  category,
  onClick,
  onCollapseExpand,
  hint,
  decorator,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.box} ${selected ? classes.selected : ""} ${
        indeterminate ? classes.indeterminate : ""
      }`}
    >
      {isCollapsible && (
        <Button
          className={`${classes.toggle} ${collapsed ? classes.collapsed : ""}`}
          onClick={onCollapseExpand}
          variant="text"
        >
          <ChevronDownIcon inherit className={classes.caret} />
        </Button>
      )}
      <Button
        variant="text"
        onClick={onClick}
        className={`${classes.data} ${
          isCollapsible ? classes.collapsible : ""
        }`}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          flex={1}
          mr={2}
        >
          <Typography
            variant="overline"
            color="textSecondary"
            className={classes.categoryLabel}
          >
            {category}
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            flex={1}
            width="100%"
          >
            <Typography variant="body2" align="left">
              {label}
            </Typography>
            {hint}
            {decorator}
          </Box>
        </Box>
      </Button>
    </Box>
  );
}
