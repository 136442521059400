import { gql } from "@apollo/client";
import { makeStyles, TextField } from "@material-ui/core";
import { InfoIcon } from "src/components/AssembleIcons/Small/InfoIcon";
import { AnonymizedDataSection_permissionSettings as PermissionSettings } from "../../../__generated__/graphql";
import { useUpdateOrganizationPermissionSettings } from "../../../mutations/PermissionSettings";
import { GRAY_4 } from "../../../theme";
import { AssembleLink } from "../../AssembleLink";
import { AssembleTypography } from "../../AssembleTypography";
import { ExplanatoryTooltip } from "../../ExplanatoryTooltip";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(0.5),
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
  },
  textInputContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(2),
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  textInput: { width: theme.spacing(30) },
  textInputDescription: { width: theme.spacing(18.5) },
}));

type Props = { permissionSettings: PermissionSettings };

export function AnonymizedDataSection({
  permissionSettings,
}: Props): JSX.Element {
  const classes = useStyles();

  const handlePermissionSettingsChange =
    useUpdateOrganizationPermissionSettings();

  const handleInput = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    void handlePermissionSettingsChange(
      { anonymizedCompensationEmployeeThreshold: parseInt(event.target.value) },
      permissionSettings
    );
  };

  return (
    <div>
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <AssembleTypography variant="productSectionHeader">
            Anonymized Data Threshold
          </AssembleTypography>
          <ExplanatoryTooltip
            title="What is the anonymized data threshold?"
            placement="top"
            body={
              <>
                Anonymized data threshold is the threshold for which we will
                show anonymized employee compensation data.
              </>
            }
            newTab
            ctaUrl="//help.assemble.inc/en/articles/5550372-changing-your-access-policies#h_11b3bbf9ad"
          >
            <div>
              <InfoIcon color={GRAY_4} />
            </div>
          </ExplanatoryTooltip>
        </div>
        <AssembleTypography variant="productParagraphSmall" display="inline">
          We will not show any employee data for groups fewer than the threshold
          you set. You can adjust whether or not to show anonymized data
          <AssembleLink
            to="/settings/offer-contents#anonymized-data"
            variant="productParagraphSmall"
            display="inline"
          >
            while generating Offers,
          </AssembleLink>
          or
          <AssembleLink
            to="/settings/policies#anonymized-employee-compensation"
            variant="productParagraphSmall"
            display="inline"
          >
            when viewing employee compensation bands
          </AssembleLink>
          in Foundations.
        </AssembleTypography>
      </div>
      <div className={classes.textInputContainer}>
        <TextField
          className={classes.textInput}
          label="Anonymized Data Threshold"
          variant="outlined"
          type="number"
          value={permissionSettings.anonymizedCompensationEmployeeThreshold}
          onChange={handleInput}
        />
        <AssembleTypography
          className={classes.textInputDescription}
          variant="productMicrocopy"
          textColor={GRAY_4}
        >
          Enter 0 if you don't want to set a threshold.
        </AssembleTypography>
      </div>
    </div>
  );
}

AnonymizedDataSection.fragments = {
  permissionSettings: gql`
    fragment AnonymizedDataSection_permissionSettings on PermissionSettings {
      id
      teamCompensationAccess
      canRecruitersViewAnonymizedStatistics
      anonymizedCompensationStatisticsAccess
      anonymizedCompensationEmployeeThreshold
      automatedManagerAccess
      employeeBandAccess
    }
  `,
};
