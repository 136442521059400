import { gql } from "@apollo/client";
import { makeStyles, Popper } from "@material-ui/core";
import clsx from "clsx";
import { AssembleTruncatedTypography } from "src/components/AssembleTruncatedTypography";
import { AssembleTypography } from "src/components/AssembleTypography";
import {
  GRAY_1,
  GRAY_2,
  GRAY_4,
  GRAY_5,
  GRAY_6,
  GRAY_8,
  WHITE,
} from "src/theme";
import {
  CompCyclePhaseAssignment2,
  CondensedTableStatusCellTooltip2_participant as Participant,
  PhaseTimelineStatus,
} from "src/__generated__/graphql";
import { useTableData } from "../Contexts/TableDataContext2";
import { CondensedTableStatusCellComponent } from "./CondensedTableStatusCellComponent";

const useStyles = makeStyles((theme) => ({
  popper: { minHeight: "0px" },
  header: {
    display: "flex",
    height: theme.spacing(3),
    alignItems: "center",
    padding: theme.spacing(1.5),
    backgroundColor: WHITE,
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    border: `1px solid ${GRAY_6}`,
  },
  root: {
    display: "flex",
    flexDirection: "row",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    borderTop: "none",
    border: `1px solid ${GRAY_6}`,
    backgroundColor: WHITE,
  },
  card: {
    padding: theme.spacing(1.5),

    width: theme.spacing(14.5),

    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1.5),
  },
  previousPhase: { backgroundColor: GRAY_8 },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardBorder: { borderRight: `1px solid ${GRAY_6}` },
  cardCopy: {
    display: "flex",
    flexDirection: "column",
  },
  nonParticipantText: {
    lineHeight: "120%",
    textAlign: "center",
  },
  noApprovers: {
    width: "100%",
  },
}));

type Props = {
  id: string | undefined;
  open: boolean;
  anchorEl: HTMLElement | null;
  employee: Participant;
};

export function CondensedTableStatusCellTooltip2({
  id,
  open,
  anchorEl,
  employee,
}: Props): JSX.Element | null {
  const classes = useStyles();

  const { currentPhase, compCyclePhases } = useTableData();
  const currentPhaseOrder = currentPhase?.phaseOrder ?? -1;

  if (compCyclePhases.length === 0) return null;

  const { phaseTimeline } = employee;

  // if phaseTimeline is an empty list, that means that this employee has no
  // approvers that will act on this employee.

  const hasNoApprovers = phaseTimeline.length === 0;

  const phaseTimelineWithAllPhases = compCyclePhases.flatMap((phase) => {
    const assignments = phaseTimeline.filter(
      (assignment) => assignment.phaseId === phase.id
    );
    return assignments.length > 0
      ? assignments
      : ({ id: 0, phase: phase } as CompCyclePhaseAssignment2);
  });

  return (
    <Popper
      className={classes.popper}
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="top"
    >
      <div className={classes.header}>
        <AssembleTypography variant="productButtonMedium" textColor={GRAY_4}>
          Assigned Approvers
        </AssembleTypography>
      </div>
      {hasNoApprovers && (
        <div className={clsx(classes.root, classes.card, classes.noApprovers)}>
          <AssembleTypography
            variant="productMicrocopy"
            textColor={GRAY_4}
            className={classes.nonParticipantText}
          >
            This employee has no assigned approvers.
          </AssembleTypography>
        </div>
      )}

      {!hasNoApprovers && (
        <div className={classes.root} data-cy="phase-timeline-tooltip">
          {phaseTimelineWithAllPhases.map((assignment, index) => {
            const phaseOrder = assignment.phase?.phaseOrder ?? 0;
            const isPreviousPhase = phaseOrder < currentPhaseOrder;
            const isCurrentPhase = phaseOrder === currentPhaseOrder;
            const statusToDisplay =
              assignment.status ?? PhaseTimelineStatus.NEEDS_REVIEW;

            return (
              <div
                key={`${assignment.id}-${index}`}
                // we need to add a border if the next person on the list
                // is assigned to a different phase
                className={clsx(classes.card, {
                  [classes.previousPhase]: isPreviousPhase,
                  [classes.cardBorder]:
                    phaseTimelineWithAllPhases.at(index + 1) != null &&
                    phaseOrder !==
                      phaseTimelineWithAllPhases.at(index + 1)?.phase
                        ?.phaseOrder,
                })}
              >
                <div
                  className={classes.cardHeader}
                  data-cy="tooltip-phase-header"
                >
                  <AssembleTypography
                    variant="productEyebrowSmall"
                    textColor={GRAY_4}
                  >
                    Phase {phaseOrder}
                  </AssembleTypography>
                </div>

                {assignment.status && (
                  <CondensedTableStatusCellComponent
                    statusToDisplay={statusToDisplay}
                  />
                )}

                <div className={classes.cardCopy}>
                  {assignment.status ? (
                    <>
                      <AssembleTruncatedTypography
                        variant="productExtraSmallSemiBold"
                        textColor={
                          isCurrentPhase
                            ? GRAY_1
                            : isPreviousPhase
                              ? GRAY_4
                              : GRAY_5
                        }
                      >
                        {assignment.employee?.displayName}
                      </AssembleTruncatedTypography>
                      <AssembleTruncatedTypography
                        variant="productExtraSmallSemiBold"
                        textColor={
                          isCurrentPhase
                            ? GRAY_2
                            : isPreviousPhase
                              ? GRAY_4
                              : GRAY_5
                        }
                      >
                        {assignment.employee?.activeEmployment?.jobTitle ?? "-"}
                      </AssembleTruncatedTypography>
                    </>
                  ) : (
                    <AssembleTypography
                      variant="productMicrocopy"
                      textColor={GRAY_4}
                      className={classes.nonParticipantText}
                    >
                      {isPreviousPhase
                        ? "Didn't participate in this phase."
                        : "Doesn't participate in this phase."}
                    </AssembleTypography>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Popper>
  );
}

CondensedTableStatusCellTooltip2.fragments = {
  compCycle2: gql`
    fragment CondensedTableStatusCellTooltip2_compCycle2 on CompCycle2 {
      id
      currentPhase {
        id
        phaseOrder
      }
    }
  `,
  participant: gql`
    fragment CondensedTableStatusCellTooltip2_participant on CompCycleParticipant {
      subjectId
      compCycleId
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        allSubmittedItems {
          id
          authorId
        }
        allSubmittedReviews {
          id
          authorId
        }
      }
      phaseTimeline {
        id
        status
        phaseId
        employee @include(if: $isAdmin) {
          id
          displayName
          activeEmployment {
            id
            jobTitle
          }
          user {
            id
          }
        }
        phase {
          id
          phaseOrder
          startDate
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        reviewStatus
      }
    }
  `,
};
