import { makeStyles } from "@material-ui/core";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { AssembleTypography } from "src/components/AssembleTypography";
import Section from "src/components/Workflow/Section";
import { SETTINGS_SIDEBAR_WIDTH } from "src/theme";
import { GuidanceDetailedView } from "./Content/GuidanceDetailedView";
import { ButtonBar } from "./Header/ButtonBar";
import { GuidancePopulationList } from "./Header/GuidancePopulationList";

const useStyles = makeStyles((theme) => ({
  root: { paddingLeft: SETTINGS_SIDEBAR_WIDTH },
  description: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: theme.spacing(5),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  buttonBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export function MultipleMeritMatrices(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Section
        active
        header="Merit Guidance & Budget"
        description={
          <div className={classes.description}>
            <AssembleTypography variant="productParagraphSmall">
              Customize salary merit and budget guidance in percentages based on
              performance ratings and compa-ratio for different populations in
              your organization.
            </AssembleTypography>
            <ButtonBar />
          </div>
        }
      >
        <div className={classes.content}>
          <GuidancePopulationList />
          <GuidanceDetailedView />
          <div className={classes.buttonBar}>
            <AssembleButton size="medium" variant="text" label="Back" />
            <AssembleButton size="medium" variant="contained" label="Save" />
          </div>
        </div>
      </Section>
    </div>
  );
}
