import { useMemo, useState } from "react";
import { searchStringsForMatch } from "../../models/Search";

type NamedItem = { name: string } | { displayName: string };
type Props<T extends NamedItem> = {
  items: T[];
  children: (
    matchingItems: T[],
    searchTerm: string,
    onSearchTermChange: (value: string | null) => unknown
  ) => JSX.Element;
};

/**
 * Headless helper component that handles all the state for searching through
 * a list of objects by name. Used in conjuction with components like
 * `SearchBar` @see SearchBar
 *
 * This component can be used with any named data type. If the data has a
 * `displayName` field, it will used. Otherwise, the `name` field will be used.
 */
export function SearchByName<T extends NamedItem>({
  items,
  children,
}: Props<T>): JSX.Element {
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const matchingItems = useMemo(() => {
    return searchTerm === null || searchTerm.length === 0
      ? items
      : items.filter((e) =>
          searchStringsForMatch(
            "displayName" in e ? e.displayName : e.name,
            searchTerm
          )
        );
  }, [items, searchTerm]);

  return children(matchingItems, searchTerm ?? "", setSearchTerm);
}
