import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { formatNumeral } from "@asmbl/shared/utils";
import { makeStyles, TableCell, TableRow } from "@material-ui/core";
import {
  AdjustedCashBand,
  CompBandRow_employee,
  CompBandTable_employee,
} from "../../../__generated__/graphql";
import { ADJUSTED_CASH_BAND_FIELDS } from "../../../fragments";
import { getBandPointNames } from "../../../models/Band";
import { getBandPointTotalValues } from "../../../models/BandPoint";
import { CashCompensation, TotalCash } from "../../../models/CashCompensation";
import { GRAY_6, GRAY_8 } from "../../../theme";
import { Range } from "../../../utils";
import { AssembleTypography } from "../../AssembleTypography";
import { AdjustableCompSlider } from "../../CompSlider/AdjustableCompSlider";

const useStyles = makeStyles(() => ({
  positionRow: {
    height: "130px",
    "&:hover .MuiButtonBase-root": {
      visibility: "visible",
    },
    "& .MuiButtonBase-root": {
      visibility: "hidden",
    },
  },
  positionRowTextContainer: {
    maxHeight: "6rem",
    overflow: "hidden",

    // Multiline ellipses for modern browsers
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 4,
  },
  bandVisualizationCell: {
    borderLeft: `1px solid ${GRAY_6}`,
    borderRight: `1px solid ${GRAY_6}`,
    padding: `24px`,
    background: GRAY_8,
    overflow: "hidden",
  },
}));

type Props = {
  employee: CompBandRow_employee;
  adjustedCashBands: CompBandTable_employee["adjustedCashBands"] | null;
  empTotalCashComp?: TotalCash<CashCompensation> | undefined;
  currency: CurrencyCode;
  position?: {
    name?: string;
    level?: number;
    description?: string;
  } | null;
  adjustedCompRange: Range;
};

export function CompBandRow({
  employee,
  adjustedCashBands,
  currency,
  position,
  empTotalCashComp,
  adjustedCompRange,
}: Props): JSX.Element {
  const classes = useStyles();

  const bandPointNames = getBandPointNames(adjustedCashBands ?? []);
  const totalCompBands = getBandPointTotalValues(
    bandPointNames,
    adjustedCashBands as AdjustedCashBand[],
    currency
  );

  const compaRatio =
    empTotalCashComp && employee.compaRatio !== null
      ? formatNumeral(employee.compaRatio, {
          style: "percent",
          maximumFractionDigits: 0,
        })
      : "N/A";

  return (
    <TableRow className={classes.positionRow}>
      <TableCell component="td" scope="row">
        <div className={classes.positionRowTextContainer}>
          <AssembleTypography variant="formSubheader" display="inline">
            {position?.description ?? "No Position Found"}
          </AssembleTypography>
        </div>
        <AssembleTypography variant="productSmallSemiBold" display="inline">
          {position?.name ?? "There are no subsequent levels in this ladder."}
        </AssembleTypography>
      </TableCell>
      <TableCell component="td" scope="row" align="center">
        <AssembleTypography variant="productSmallSemiBold">
          {position?.level ?? "N/A"}
        </AssembleTypography>
      </TableCell>
      <TableCell component="td" scope="row" align="center">
        <AssembleTypography variant="productSmallSemiBold">
          {compaRatio}
        </AssembleTypography>
      </TableCell>
      <TableCell className={classes.bandVisualizationCell} align="left">
        <AdjustableCompSlider
          band={totalCompBands}
          empTotalCashComp={empTotalCashComp}
          avatar={{
            displayName: employee.displayName,
            photoURL: employee.user?.photoURL ?? null,
          }}
          showEmptyBand={position?.level === undefined}
          compRange={adjustedCompRange}
        />
      </TableCell>
    </TableRow>
  );
}

CompBandRow.fragments = {
  employee: gql`
    ${ADJUSTED_CASH_BAND_FIELDS}
    fragment CompBandRow_employee on Employee {
      id
      displayName
      compaRatio
      user {
        id
        photoURL
      }
      adjustedCashBands {
        id
        ...AdjustedCashBandFields
      }
      activeCashCompensation {
        employeeId
        activeAt
        type
        annualCashEquivalent
        percentOfSalary
        unit
        hourlyCashEquivalent
      }
      activeEmployment {
        id
        position {
          id
          name
          level
        }
      }
    }
  `,
};
