import { cx } from "@emotion/css";
import { makeStyles, TableHead, TableHeadProps } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "none",
    "&:not(:last-child)": {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
}));

export function WireTableHead(props: TableHeadProps): JSX.Element {
  const classes = useStyles();
  return <TableHead {...props} className={cx(classes.root, props.className)} />;
}
