import { gql, useMutation } from "@apollo/client";
import { Money } from "@asmbl/shared/money";
import { useCallback } from "react";
import { BudgetLoadingBoundary } from "../pages/CompCycle/Budgets/BudgetLoadingBoundary";
import {
  DraftBudget,
  DraftBudgetFragment,
  DraftBudgetVariables,
  GenerateBudgetFromMatrix,
  GenerateBudgetFromMatrixVariables,
  PublishCompCycleBudgets,
  PublishCompCycleBudgetsVariables,
} from "../__generated__/graphql";

export type DraftBudgetInput = {
  salary?: Money | null;
  salaryMarket?: Money | null;
  salaryMerit?: Money | null;
  salaryPromotion?: Money | null;
  equity?: Money | null;
  bonus?: Money | null;
};
type DraftBudgetInputDefined = Required<DraftBudgetInput>;

// Note: The key fields for Apollo's cache are compCycleId and employeeId.
const DRAFT_BUDGET_FRAGMENT = gql`
  fragment DraftBudgetFragment on CompCycleBudgetDraft {
    compCycleId
    employeeId
    salary
    salaryMarket
    salaryMerit
    salaryPromotion
    equity
    bonus
  }
`;

export const DRAFT_BUDGET = gql`
  ${DRAFT_BUDGET_FRAGMENT}
  mutation DraftBudget($input: UpsertOneBudgetInput!) {
    upsertOneBudget(input: $input) {
      successful
      budget {
        ...DraftBudgetFragment
      }
    }
  }
`;

export function useDraftBudget(
  compCycleId: number
): (employee: number | null, input: DraftBudgetInput) => Promise<unknown> {
  const [draftBudget, { client }] = useMutation<
    DraftBudget,
    DraftBudgetVariables
  >(DRAFT_BUDGET, {
    refetchQueries: [
      { query: BudgetLoadingBoundary.overages, variables: { compCycleId } },
    ],
  });

  return useCallback(
    (employeeId: number | null, input: DraftBudgetInput) => {
      // Get the original draft item from cache, to use in the optimistic update
      const original = client.readFragment<DraftBudgetFragment>({
        id: client.cache.identify({
          __typename: "CompCycleBudgetDraft",
          compCycleId,
          employeeId,
        }),
        fragment: DRAFT_BUDGET_FRAGMENT,
      });

      // Dangerously hint to Typescript that fields will not be undefined if
      // they are present.  This would be safe (and unnecessary) if we had
      // `exactOptionalPropertyTypes` enabled.
      const inputForResponses = input as DraftBudgetInputDefined;

      // If a user has deleted the number from an BudgetNumberInput field, then
      // we want to unset field on the budget.
      const unset = Object.fromEntries(
        Object.entries(input)
          .filter(([, value]) => value === null)
          .map(([key]) => [key, true])
      );

      return draftBudget({
        variables: {
          input: {
            compCycleId,
            employeeId,
            patch: input,
            unset,
          },
        },
        optimisticResponse: {
          upsertOneBudget: {
            __typename: "UpsertOneBudgetPayload",
            successful: true,
            budget: {
              __typename: "CompCycleBudgetDraft",
              compCycleId,
              employeeId,
              ...original,
              ...inputForResponses,
            },
          },
        },
      });
    },
    [client, compCycleId, draftBudget]
  );
}

export const PUBLISH_COMP_CYCLE_BUDGETS = gql`
  mutation PublishCompCycleBudgets($data: PublishBudgetsInput!) {
    publishBudgets(data: $data) {
      draftsPublished
    }
  }
`;

export function usePublishBudgets(compCycleId: number): () => Promise<unknown> {
  const [publishBudgets] = useMutation<
    PublishCompCycleBudgets,
    PublishCompCycleBudgetsVariables
  >(PUBLISH_COMP_CYCLE_BUDGETS, {
    variables: { data: { compCycleId } },
  });

  return publishBudgets;
}

export const GENERATE_BUDGET_FROM_MATRIX = gql`
  ${DRAFT_BUDGET_FRAGMENT}
  mutation GenerateBudgetFromMatrix($compCycleId: Int!) {
    generateAutoBudget(compCycleId: $compCycleId) {
      ...DraftBudgetFragment
    }
  }
`;

export function useGenerateBudgetFromMatrix(
  compCycleId: number
): () => Promise<DraftBudget> {
  const [generateBudgetFromMatrix] = useMutation<
    GenerateBudgetFromMatrix,
    GenerateBudgetFromMatrixVariables
  >(GENERATE_BUDGET_FROM_MATRIX, {
    variables: { compCycleId },
  });

  return generateBudgetFromMatrix;
}
