import { gql, useQuery } from "@apollo/client";
import { contramap } from "@asmbl/shared/sort";
import { useParams } from "react-router-dom";
import { ErrorView } from "src/components/ErrorView";
import {
  CompCycleReviewRequests_employee,
  CompCycleReviewRequestsBoundary_participant,
  CompCycleReviewRequestsBoundaryQuery,
  CompCycleReviewRequestsBoundaryQueryVariables,
} from "../../../__generated__/graphql";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useCompleteCompCycle } from "../../../mutations/CompCycle";
import { CompCycleReviewRequests } from "./CompCycleReviewRequests";

export type CompCycleReviewer = CompCycleReviewRequests_employee & {
  assignees: CompCycleReviewRequestsBoundary_participant[];
};

export function CompCycleReviewRequestsBoundary(): JSX.Element {
  const { compCycleId } = useParams<{ compCycleId: string }>();
  const cycleId = compCycleId != null ? parseInt(compCycleId, 10) : NaN;

  const { data, loading } = useQuery<
    CompCycleReviewRequestsBoundaryQuery,
    CompCycleReviewRequestsBoundaryQueryVariables
  >(CompCycleReviewRequestsBoundary.query, {
    variables: {
      compCycleId: cycleId,
    },
    skip: isNaN(cycleId),
  });

  const completeCompCycle = useCompleteCompCycle(cycleId);

  if (!data?.compCycle2) {
    return loading ? (
      <LoadingSpinner />
    ) : (
      <ErrorView text="This comp cycle was deleted or does not exist. If you think something went wrong, please contact us for support." />
    );
  }

  const reviewerMap = new Map<number, CompCycleReviewer>();

  data.compCycle2.participants.participants.forEach((p) => {
    if (p.subject.managerIds != null) {
      p.subject.managerIds.forEach((mgrId) => {
        const reviewer = reviewerMap.get(mgrId);
        if (reviewer) {
          reviewer.assignees.push(p);
        } else {
          const rvwr = data.compCycle2?.reviewers.find((r) => r.id === mgrId);
          if (rvwr) {
            reviewerMap.set(mgrId, { assignees: [p], ...rvwr });
          }
        }
      });
    }
  });

  const sortedManagers = [...reviewerMap.values()].sort(
    contramap((m) => m.displayName)
  );

  return (
    <CompCycleReviewRequests
      authors={sortedManagers}
      compCycle={data.compCycle2}
      onComplete={completeCompCycle}
    />
  );
}

CompCycleReviewRequestsBoundary.fragments = {
  participant: gql`
    fragment CompCycleReviewRequestsBoundary_participant on CompCycleParticipant {
      subjectId
      compCycleId
      subject {
        id
        managerIds
        managerId
      }
      compRecommendation {
        subjectId
        compCycleId
        reviewStatus
        latestSubmittedItems {
          id
          submittedAt
          author {
            id
            name
          }
        }
        latestSubmittedReviews {
          id
          status
          authorId
        }
      }
    }
  `,
};

CompCycleReviewRequestsBoundary.query = gql`
  ${CompCycleReviewRequests.fragments.compCycle}
  ${CompCycleReviewRequests.fragments.reviewer}
  ${CompCycleReviewRequestsBoundary.fragments.participant}
  query CompCycleReviewRequestsBoundaryQuery($compCycleId: Int!) {
    compCycle2(id: $compCycleId) {
      id
      participants {
        participants {
          ...CompCycleReviewRequestsBoundary_participant
        }
      }
      reviewers {
        id
        ...CompCycleReviewRequests_employee
      }
      ...CompCycleReviewRequests_compCycle
    }
  }
`;
