import { gql } from "@apollo/client";
import { contramap } from "@asmbl/shared/sort";
import { formatNumeral } from "@asmbl/shared/utils";
import {
  CondensedTable_meritAdjustmentGuide as MeritAdjustmentGuide,
  CondensedTable_meritAdjustmentRange as MeritAdjustmentRange,
  CondensedTableSalaryMeritGuidanceCell2_participant as Participant,
  CondensedTable_perfRatingOption as PerfRatingOption,
} from "src/__generated__/graphql";
import { getGuidance } from "src/models/MeritGuidance";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { DefaultCell } from "./DefaultCell";

type Props = {
  row: { original: Participant };
};

export function CondensedTableSalaryMeritGuidanceCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const tableData = useTableData();
  const guidance = getGuidance(
    {
      ...employee.subject,
      perfRating: employee.perfRating,
    },
    tableData.meritGuidance.meritAdjustmentGuides,
    tableData.meritGuidance.meritAdjustmentRanges,
    tableData.meritGuidance.perfRatingOptions
  );

  return (
    <div>
      {guidance != null ? (
        <AssembleTruncatedTypography align="right">
          {guidance === 0
            ? "-"
            : formatNumeral(guidance, {
                style: "percent",
                maximumFractionDigits: 2,
              })}
        </AssembleTruncatedTypography>
      ) : (
        <DefaultCell />
      )}
    </div>
  );
}

CondensedTableSalaryMeritGuidanceCell2.fragments = {
  participant: gql`
    fragment CondensedTableSalaryMeritGuidanceCell2_participant on CompCycleParticipant {
      subject {
        activeCashCompensation(currencyCode: $currencyCode) {
          type
          annualCashEquivalent
          hourlyCashEquivalent
          unit
          employeeId
        }
        adjustedCashBands(currencyCode: $currencyCode) {
          id
          name
          bandPoints {
            id
            bandName
            name
            value {
              ... on CashValue {
                annualRate
                hourlyRate
                currencyCode
              }
            }
          }
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        allSubmittedItems {
          id
        }
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
          unitType
        }
      }

      perfRating
    }
  `,
};

CondensedTableSalaryMeritGuidanceCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.SALARY_MERIT_GUIDANCE
);
CondensedTableSalaryMeritGuidanceCell2.id = ColumnIds.SALARY_MERIT_GUIDANCE;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableSalaryMeritGuidanceCell2,
  Header: CondensedTableSalaryMeritGuidanceCell2.Header,
  id: CondensedTableSalaryMeritGuidanceCell2.id,
  defaultCanSort: false,
};

CondensedTableSalaryMeritGuidanceCell2.column = column;
CondensedTableSalaryMeritGuidanceCell2.ordering = ({
  meritGuidance,
}: {
  meritGuidance: {
    meritAdjustmentGuides: MeritAdjustmentGuide[];
    meritAdjustmentRanges: MeritAdjustmentRange[];
    perfRatingOptions: PerfRatingOption[];
  };
}) =>
  contramap(
    (employee: Participant) =>
      getGuidance(
        {
          ...employee.subject,
          perfRating: employee.perfRating,
        },
        meritGuidance.meritAdjustmentGuides,
        meritGuidance.meritAdjustmentRanges,
        meritGuidance.perfRatingOptions
      ) ?? 0
  );
