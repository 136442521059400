import { gql, useQuery } from "@apollo/client";
import { Button, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { WHITE } from "src/theme";
import { RefreshPromptQuery } from "../__generated__/graphql";
import { buildRevision, envName } from "../env";
import { WarningOctagonIcon } from "./AssembleIcons/Brand/WarningOctagonIcon";

const clientRevision = buildRevision();
// Don't show the refresh prompt in development (unless ENV_NAME explicitly set)
const enabled = envName() !== undefined;

const useStyles = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(145.58deg, #D91D4A 28.94%, #FF385C 87.8%)",
    boxShadow: "0px 5px 15px rgba(255, 56, 92, 0.2)",
    borderRadius: "5px",
    "& button, & button:hover": {
      color: theme.palette.error.contrastText,
    },
  },
  message: {
    marginInline: "1rem",
  },
}));

export function RefreshPrompt(): null {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useQuery<RefreshPromptQuery>(RefreshPrompt.query, {
    pollInterval: 45_000,
  });

  const excludedPages = ["comp-cycles/new"];

  useEffect(() => {
    if (!enabled || data?.buildRevision === "unknown") {
      return;
    }
    if (
      data &&
      data.buildRevision !== clientRevision &&
      !excludedPages.includes(window.location.pathname)
    ) {
      enqueueSnackbar(
        <>
          <WarningOctagonIcon color={WHITE} width={"24px"} height={"24px"} />
          <span className={classes.message}>
            A new version of Assemble is available.
          </span>
        </>,
        {
          key: "refreshPrompt",
          persist: true,
          preventDuplicate: true,
          className: classes.root,
          action: (
            <Button onClick={() => window.location.reload()}>
              Refresh to Update
            </Button>
          ),
        }
      );
    }
  }, [classes, data, enqueueSnackbar]);

  return null;
}

RefreshPrompt.query = gql`
  query RefreshPromptQuery {
    buildRevision
  }
`;
