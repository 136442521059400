import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const IntegrationsIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Integrations"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M5.99998 20.25C5.90149 20.25 5.80397 20.2306 5.71297 20.1929C5.62198 20.1552 5.5393 20.1 5.46965 20.0303C5.40001 19.9607 5.34476 19.878 5.30707 19.787C5.26938 19.696 5.24998 19.5985 5.24998 19.5L5.25003 15.4974C4.84993 15.6869 4.4084 15.7721 3.96652 15.7451C3.52463 15.7182 3.09674 15.5799 2.72265 15.3431C2.34856 15.1064 2.04043 14.7789 1.82691 14.391C1.61339 14.0032 1.50142 13.5677 1.50142 13.125C1.50142 12.6823 1.61339 12.2468 1.82691 11.859C2.04043 11.4711 2.34856 11.1436 2.72265 10.9069C3.09674 10.6701 3.52463 10.5318 3.96652 10.5049C4.4084 10.4779 4.84993 10.5631 5.25003 10.7526L5.24999 6.75001C5.24999 6.65151 5.26938 6.55399 5.30708 6.46299C5.34477 6.37199 5.40001 6.28931 5.46966 6.21967C5.5393 6.15002 5.62198 6.09478 5.71298 6.05709C5.80398 6.0194 5.90151 6 6 6L10.3776 6.00005C10.1881 5.59995 10.1029 5.15842 10.1299 4.71653C10.1568 4.27465 10.2951 3.84676 10.5319 3.47267C10.7686 3.09858 11.0961 2.79045 11.4839 2.57693C11.8717 2.36341 12.3073 2.25144 12.75 2.25144C13.1927 2.25144 13.6282 2.36341 14.016 2.57693C14.4038 2.79045 14.7313 3.09858 14.9681 3.47267C15.2048 3.84676 15.3431 4.27465 15.3701 4.71653C15.3971 5.15842 15.3118 5.59995 15.1223 6.00005L19.4999 6C19.6989 6 19.8896 6.07901 20.0303 6.21966C20.1709 6.36031 20.2499 6.55108 20.2499 6.74999L20.25 10.7526C19.8499 10.5631 19.4084 10.4779 18.9665 10.5049C18.5246 10.5318 18.0967 10.6701 17.7226 10.9069C17.3485 11.1436 17.0404 11.4711 16.8269 11.859C16.6134 12.2468 16.5014 12.6823 16.5014 13.125C16.5014 13.5677 16.6134 14.0032 16.8269 14.391C17.0404 14.7789 17.3485 15.1064 17.7226 15.3431C18.0967 15.5799 18.5246 15.7182 18.9665 15.7451C19.4084 15.7721 19.8499 15.6869 20.25 15.4974L20.2499 19.5C20.2499 19.6989 20.1709 19.8897 20.0303 20.0303C19.8896 20.171 19.6989 20.25 19.4999 20.25L5.99998 20.25Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M19.5002 9.75V6H15.7502"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
