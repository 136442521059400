import { gql } from "@apollo/client";
import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import { CircleArrowRightIcon } from "src/components/AssembleIcons/Brand/CircleArrowRightIcon";
import { AssembleLink } from "../../../components/AssembleLink";
import { useURLSearchParams } from "../../../models/URLSearchParams";
import { GRAY_1, GRAY_4, GRAY_9 } from "../../../theme";
import { EmployeeDetailView_employee as Employee } from "../../../__generated__/graphql";
import { EmployeeCompensation } from "./EmployeeCompensation/EmployeeCompensation";
import { EmployeeEmployment } from "./EmployeeEmployment/EmployeeEmployment";
import { EmployeeHeaderSection } from "./EmployeeHeaderSection";

interface Props {
  employee: Employee;
}

const useStyles = makeStyles(() => ({
  headerText: {
    color: GRAY_1,
  },
  content: {
    backgroundColor: GRAY_9,
  },
}));

export const EmployeeDetailView = ({ employee }: Props): JSX.Element => {
  const classes = useStyles();
  const urlSearchParams = useURLSearchParams();

  return (
    <>
      <Box
        pt={4}
        px={4}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" className={classes.headerText}>
          Team Member
        </Typography>
        <IconButton
          size="small"
          component={AssembleLink}
          to={`?${urlSearchParams.delete("employee").toString()}`}
        >
          <CircleArrowRightIcon
            color={GRAY_4}
            inline
            width="24px"
            height="24px"
          />
        </IconButton>
      </Box>
      <Box
        className={classes.content}
        display="flex"
        flexDirection="column"
        flexGrow={1}
      >
        <EmployeeHeaderSection employee={employee} />
        <EmployeeEmployment employee={employee} />
        <EmployeeCompensation employee={employee} />
      </Box>
    </>
  );
};

EmployeeDetailView.fragments = {
  employee: gql`
    ${EmployeeCompensation.fragments.employee}
    ${EmployeeHeaderSection.fragments.employee}
    ${EmployeeEmployment.fragments.employee}
    fragment EmployeeDetailView_employee on Employee {
      id
      ...EmployeeCompensation_employee
      ...EmployeeHeaderSection_employee
      ...EmployeeEmployment_employee
    }
  `,
};
