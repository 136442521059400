import { gql } from "@apollo/client";
import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { DeleteIcon } from "src/components/AssembleIcons/Brand/DeleteIcon";
import { FlagIcon } from "src/components/AssembleIcons/Brand/FlagIcon";
import { GRAY_4, PURPLE_2 } from "src/theme";
import { useTrack } from "../../../../analytics";
import { NumberInput } from "../../../../components/Form/NumberInput";
import { currencySymbol } from "../../../../models/Currency";
import {
  arrayWithoutItem,
  formatNonZeroOrEmptyString,
} from "../../../../utils";
import { CurrencyCode } from "../../../../__generated__/graphql";
import { LABEL_CHARACTER_LIMIT } from "./EquityOutcomesForm";
import {
  ExitOutcomeWithAnnotation,
  PLACEHOLDER_OUTCOMES,
} from "./PortalConfig";

const useStyles = makeStyles((theme) => ({
  illustrativeFormPair: {
    marginBottom: theme.spacing(2),
    "&:hover": {
      "& $formIcon": {
        opacity: 1,
      },
    },
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  formIcon: {
    opacity: 0,
    transition: "opacity 300ms",
  },
  numberInput: {
    marginRight: theme.spacing(2),
    display: "flex",
    width: theme.spacing(16.5),
  },
  labelInput: {
    marginRight: theme.spacing(1),
    display: "flex",
    width: "60%",
  },
  iconContainer: {
    display: "flex",
  },
}));

type FormProps = {
  outcomes: ExitOutcomeWithAnnotation[];
  onChangeOutcomes: (outcomes: ExitOutcomeWithAnnotation[]) => unknown;
  onChangeDefault: (defaultValue: number | null) => unknown;
  onFormChange: () => unknown;
};

type ModelProps = {
  index: number;
  defaultExitOutcome: number | null;
  valuationCurrency: CurrencyCode;
};

export function EquityOutcomesFormPair({
  index,
  outcomes,
  defaultExitOutcome,
  valuationCurrency,
  onChangeOutcomes,
  onChangeDefault,
  onFormChange,
}: FormProps & ModelProps) {
  const classes = useStyles();
  const { trackEvent } = useTrack();

  const valuationSymbol = currencySymbol(valuationCurrency);

  const outcome = outcomes[index];

  const deleteOutcome = () => {
    onChangeOutcomes(arrayWithoutItem(outcomes, index));
    if (defaultExitOutcome === outcome.value) {
      onChangeDefault(null);
    }
    onFormChange();
  };

  const changeDefaultValue = () => {
    trackEvent({
      area: "Employee Portal",
      subArea: "Portal Config",
      object: "Equity Outcomes Default",
      action: "Changed",
    });
    onChangeDefault(outcome.value);
    onFormChange();
  };

  const updateOutcome = ({
    value,
    annotation = outcome.annotation,
  }: {
    value?: number;
    annotation?: string;
    placeholder?: boolean;
  }) => {
    const newOutcomes = outcomes.slice();
    newOutcomes[index] = {
      key: outcome.key,
      value: value ?? 0,
      annotation,
      placeholder: value === undefined ? true : false,
    };
    if (defaultExitOutcome === outcome.value) {
      onChangeDefault(value ?? null);
    }
    onChangeOutcomes(newOutcomes);
    onFormChange();
  };

  const handleBlur = () =>
    trackEvent({
      area: "Employee Portal",
      subArea: "Portal Config",
      object: "Equity Outcomes",
      action: "Changed",
    });

  return (
    <div className={classes.illustrativeFormPair}>
      <div className={classes.numberInput}>
        <NumberInput
          id={`exitOutcomes-${index}`}
          fullWidth
          startAdornment={
            <InputAdornment position="start">{valuationSymbol}</InputAdornment>
          }
          onValueChange={({ floatValue }) => {
            updateOutcome({
              value: floatValue,
            });
          }}
          placeholder={outcome.value.toString()}
          label="Price per Share"
          value={
            outcome.placeholder
              ? undefined
              : formatNonZeroOrEmptyString(outcome.value)
          }
          allowNegative={false}
          error={
            (outcome.annotation && outcome.placeholder && !outcome.value) ===
            true
          }
          required
        />
      </div>
      <div className={classes.labelInput}>
        <TextField
          id={`annotations-${index}`}
          label="Milestone Label"
          onChange={(e) => {
            updateOutcome({
              value: outcome.placeholder ? undefined : outcome.value,
              annotation: e.target.value,
            });
          }}
          onBlur={handleBlur}
          error={
            outcome.annotation.length > LABEL_CHARACTER_LIMIT ||
            (!outcome.placeholder && outcome.value && !outcome.annotation) ===
              true
          }
          placeholder={
            PLACEHOLDER_OUTCOMES[index]?.placeholderText ?? "Ex: 5 Year Target"
          }
          value={outcome.annotation}
          variant="outlined"
          fullWidth
          required
          InputLabelProps={{ shrink: true }}
          InputProps={
            defaultExitOutcome !== null && defaultExitOutcome === outcome.value
              ? {
                  endAdornment: (
                    <InputAdornment position="end">Default</InputAdornment>
                  ),
                }
              : {}
          }
        />
      </div>
      <div className={classes.iconContainer}>
        {outcomes.length > 3 && (
          <Tooltip title="Delete Outcome" placement="top">
            <IconButton className={classes.formIcon} onClick={deleteOutcome}>
              <DeleteIcon
                color={GRAY_4}
                hoverColor={PURPLE_2}
                width="24px"
                height="24px"
              />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Set as Default" placement="top">
          <IconButton
            data-cy="equity-outcomes-form-pair-set-as-default"
            className={classes.formIcon}
            onClick={changeDefaultValue}
          >
            <FlagIcon
              color={GRAY_4}
              hoverColor={PURPLE_2}
              width="24px"
              height="24px"
            />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}

EquityOutcomesFormPair.fragments = {
  portalConfig: gql`
    fragment EquityOutcomesFormPair_portalConfig on PortalConfig {
      id
      defaultExitOutcome
      valuationCurrencyCode
    }
  `,
};
