import { gql } from "@apollo/client";
import { IconButton, makeStyles } from "@material-ui/core";
import { InfoIcon } from "src/components/AssembleIcons/Brand/InfoIcon";
import { GRAY_4 } from "../../../../theme";
import { TeamCompensationAccessType } from "../../../../__generated__/graphql";
import { ExplanatoryTooltip } from "../../../ExplanatoryTooltip";

const useStyles = makeStyles((theme) => ({
  tooltipList: { paddingInlineStart: theme.spacing(3) },
}));

export function CompDataTooltip({
  teamCompensationAccess,
}: {
  teamCompensationAccess: TeamCompensationAccessType;
}) {
  const classes = useStyles();
  const body = (
    <>
      Your current Access Policy for compensation data is:
      <ul className={classes.tooltipList}>
        <li>
          Only people assigned the Full Access role can see every employee’s
          compensation.
        </li>
        {teamCompensationAccess !== TeamCompensationAccessType.NONE && (
          <li>
            Supervisors can see the compensation of their direct and indirect
            reports.
          </li>
        )}
      </ul>
    </>
  );

  return (
    <ExplanatoryTooltip
      title="Who can view employee Compensation?"
      body={body}
      ctaLabel="Go to Access Policies"
      ctaUrl="/settings/policies"
      width="440px"
    >
      <IconButton size="small">
        <InfoIcon color={GRAY_4} inline />
      </IconButton>
    </ExplanatoryTooltip>
  );
}

CompDataTooltip.fragments = {
  permissionSettings: gql`
    fragment CompDataTooltip_permissionSettings on PermissionSettings {
      id
      teamCompensationAccess
    }
  `,
};
