import DateFnsUtils from "@date-io/date-fns";
import { Box } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ChangeHandler } from "../types";

type Props = {
  offeredAt: Date | undefined;
  handleChange: ChangeHandler;
};

export function OfferDatePicker({
  offeredAt,
  handleChange,
}: Props): JSX.Element {
  return (
    <Box style={{ width: "100%" }} mb={2}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          style={{ width: "100%" }}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          inputVariant="outlined"
          margin="normal"
          id="offeredAt"
          label="Offer Date"
          value={offeredAt}
          onChange={(date) => handleChange("offeredAt", date ?? undefined)}
          KeyboardButtonProps={{ "aria-label": "change date" }}
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
}
