import { gql } from "@apollo/client";
import { getCompCalculator } from "@asmbl/shared/compensation";
import { Currency } from "@asmbl/shared/currency";
import { contramap } from "@asmbl/shared/sort";
import { useCompStructure } from "src/components/CompStructureContext";
import { getTotalCashAfterRecommendation } from "src/models/CashCompensation";
import {
  CompStructureFields,
  CondensedTableNewTCCCompaRatioCell_participant as Participant,
  RecItemType,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableNewTCCCompaRatioCell({
  row: { original: employee },
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { defaultCurrency } = useCurrencies();

  const newTCCCompaRatio = getNewCompaRatio(
    compStructure,
    employee,
    defaultCurrency,
    compStructure?.workingHoursPerYear
  );

  return (
    <AssembleTruncatedTypography align="right">
      {newTCCCompaRatio != null
        ? `${Number(newTCCCompaRatio).toFixed()}%`
        : "-"}
    </AssembleTruncatedTypography>
  );
}

function getNewCompaRatio(
  compStructure: CompStructureFields | null,
  employee: Participant,
  defaultCurrency: Currency,
  workingHoursPerYear: number | undefined
): string | undefined {
  const cashComp = getTotalCashAfterRecommendation(
    defaultCurrency.code,
    employee.subject.activeCashCompensation,
    employee.compRecommendation?.latestSubmittedItems ?? [],
    workingHoursPerYear
  );

  if (!cashComp) {
    return undefined;
  }

  const promotionItem = employee.compRecommendation?.latestSubmittedItems.find(
    (item) => item.recommendationType === RecItemType.PROMOTION
  );

  const bands = promotionItem
    ? promotionItem.adjustedCashBands
    : employee.subject.adjustedCashBands;

  const newBands =
    bands &&
    bands.map((band) => ({
      name: band.name,
      bandPoints: band.bandPoints.map((bp) => ({
        name: bp.name,
        value: bp.annualCashEquivalent.value,
      })),
    }));

  const getPayData = getCompCalculator(
    newBands ?? undefined,
    cashComp.subcomponents,
    compStructure ?? { bandPointTypes: [] }
  );

  return getPayData("total").compaRatio;
}

CondensedTableNewTCCCompaRatioCell.ordering = ({
  defaultCurrency,
  workingHoursPerYear,
}: {
  defaultCurrency: Currency;
  workingHoursPerYear: number | undefined;
}) =>
  contramap((employee: Participant) => {
    const { compStructure } = useCompStructure();

    const newTCCCompaRatio = getNewCompaRatio(
      compStructure,
      employee,
      defaultCurrency,
      workingHoursPerYear
    );

    return Number(newTCCCompaRatio);
  });

CondensedTableNewTCCCompaRatioCell.fragments = {
  participant: gql`
    fragment CondensedTableNewTCCCompaRatioCell_participant on CompCycleParticipant {
      subject {
        id
        activeEmployment {
          id
          salary(currencyCode: $currencyCode)
        }
        adjustedCashBands(currencyCode: $currencyCode) {
          id
          name
          bandPoints {
            name
            annualCashEquivalent
          }
        }
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          annualCashEquivalent
          hourlyCashEquivalent
          unit
          percentOfSalary
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedPayIncrease(currencyCode: $currencyCode) {
          annualCashEquivalent
          hourlyCashEquivalent
          unitType
        }
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
          adjustedCashBands(currencyCode: $currencyCode) {
            id
            name
            bandPoints {
              name
              annualCashEquivalent
            }
          }
        }
      }
    }
  `,
};

CondensedTableNewTCCCompaRatioCell.Header = ColumnIdsToHeaders.get(
  ColumnIds.NEW_TCC_COMPA_RATIO
);
CondensedTableNewTCCCompaRatioCell.id = ColumnIds.NEW_TCC_COMPA_RATIO;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNewTCCCompaRatioCell,
  Header: CondensedTableNewTCCCompaRatioCell.Header,
  id: CondensedTableNewTCCCompaRatioCell.id,
  width: LARGE_COL_WIDTH,
  defaultCanSort: false,
};
CondensedTableNewTCCCompaRatioCell.column = column;
