import { Box, Divider } from "@material-ui/core";
import { ToggleType } from "./Demographics";
import { EmployeeCountChart } from "./EmployeeCountChart";
import { EthnicityRepresentationChart } from "./EthnicityRepresentationChart";
import { GenderRepresentationChart } from "./GenderRepresentationChart";
interface Props {
  timeUnit: string;
  toggle: ToggleType;
}

export function DemographicsCharts({ timeUnit, toggle }: Props): JSX.Element {
  return (
    <Box display="flex" flexDirection="row">
      <EmployeeCountChart timeUnit={timeUnit} />
      <Divider orientation="vertical" />
      <GenderRepresentationChart timeUnit={timeUnit} toggle={toggle} />
      <Divider orientation="vertical" />
      <EthnicityRepresentationChart timeUnit={timeUnit} toggle={toggle} />
    </Box>
  );
}
