import { CurrencyCode } from "@asmbl/shared/constants";
import { add, Money, zero } from "@asmbl/shared/money";

export const MIN_BENEFITS_PER_PACKAGE = 1;
export const MAX_BENEFITS_PER_PACKAGE = 18;

export type Benefit = {
  name: string;
  description: string;
  value: Money | null;
  rank: string;
};
export type BenefitsPackage = {
  name: string;
  currencyCode: CurrencyCode;
  benefits: Benefit[];
};

export function getTotalValue(benefitsPackage: BenefitsPackage): Money {
  const nil = zero(benefitsPackage.currencyCode);
  return benefitsPackage.benefits.reduce(
    (total, benefit) => add(total, benefit.value ?? nil),
    nil
  );
}
