import { gql } from "@apollo/client";
import { JobArchitectureIcon } from "src/components/AssembleIcons/Brand/JobArchitectureIcon";
import { ManagerBubble_employee } from "../../../__generated__/graphql";
import { BubbleInfo } from "./BubbleInfo";

type Props = {
  employee: ManagerBubble_employee;
};

export function ManagerBubble({ employee }: Props): JSX.Element {
  return (
    <BubbleInfo
      label="Manager"
      info={employee.manager?.displayName ?? "-"}
      icon={<JobArchitectureIcon inherit />}
      color="purple"
    />
  );
}

ManagerBubble.fragments = {
  employee: gql`
    fragment ManagerBubble_employee on Employee {
      id
      manager: minimalManager {
        id
        displayName
      }
    }
  `,
};
