import {
  FormControlLabel,
  makeStyles,
  Switch,
  Tooltip,
} from "@material-ui/core";
import { AssembleTypography } from "src/components/AssembleTypography";
import { useDetectOS } from "src/hooks/useDetectOS";
import { GRAY_4, WHITE } from "src/theme";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
  },
  tooltipCopy: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  keyboardButton: {
    display: "inline-block",
    border: `1px solid ${GRAY_4}`,
    borderRadius: "2px",
    padding: "2px",
  },
}));

type Props = {
  isTreeSelected: boolean;
  setIsTreeSelection: (isTreeSelected: boolean) => void;
};

export function SelectModeToggle({
  isTreeSelected,
  setIsTreeSelection,
}: Props): JSX.Element {
  const os = useDetectOS();
  const classes = useStyles();

  const handleOnChange = () => {
    setIsTreeSelection(!isTreeSelected);
  };

  return (
    <div>
      <Tooltip
        placement="top"
        title={
          <div className={classes.tooltipCopy}>
            <AssembleTypography
              variant="productParagraphSmall"
              textColor={WHITE}
            >
              To toggle hold
            </AssembleTypography>
            <AssembleTypography
              className={classes.keyboardButton}
              variant="productParagraphSmall"
              textColor={GRAY_4}
            >
              {os === "MacOS" ? "Option" : "Alt"}
            </AssembleTypography>
          </div>
        }
      >
        <FormControlLabel
          className={classes.formControl}
          checked={isTreeSelected}
          control={<Switch />}
          onChange={handleOnChange}
          label={
            <AssembleTypography
              noWrap
              variant="productParagraphSmall"
              textColor={GRAY_4}
            >
              Select Tree
            </AssembleTypography>
          }
        />
      </Tooltip>
    </div>
  );
}
