import { makeStyles } from "@material-ui/core";
import { GRAY_6, WHITE } from "../../../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    zIndex: 0,
  },
  header: {
    boxShadow: "0 2px 9px rgba(10, 36, 64, 0.08)",
    borderBottom: `1px solid ${GRAY_6}`,
    zIndex: 2,
  },

  main: {
    flexBasis: "100%",
    flexShrink: 1,
    overflowY: "auto",
    zIndex: 1,
  },

  mainContent: {
    width: "42rem",
    marginInline: "auto",
    display: "flex",
    flexDirection: "column",
    paddingBlock: theme.spacing(5),
    gap: theme.spacing(4),
  },

  footer: {
    padding: theme.spacing(3),
    boxShadow: "0 -2px 9px rgba(10, 36, 64, 0.08)",
    borderTop: `1px solid ${GRAY_6}`,
    background: WHITE,
    textAlign: "center",
    zIndex: 2,
  },
}));

interface Props {
  header: JSX.Element;
  main: JSX.Element;
  footer: JSX.Element;
}

export function EditorLayout({ header, main, footer }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>{header}</div>
      <div className={classes.main}>
        <div className={classes.mainContent}>{main}</div>
      </div>
      <div className={classes.footer}>{footer}</div>
    </div>
  );
}
