import { gql, useQuery } from "@apollo/client";
import {
  AnonymizedDataSectionLoadingBoundaryQuery,
  AnonymizedDataSectionLoadingBoundaryQueryVariables,
} from "../../../__generated__/graphql";
import Loading from "../Loading";
import { AnonymizedDataSection } from "./AnonymizedDataSection";

export function AnonymizedDataSectionLoadingBoundary(): JSX.Element {
  const { data, loading } = useQuery<
    AnonymizedDataSectionLoadingBoundaryQuery,
    AnonymizedDataSectionLoadingBoundaryQueryVariables
  >(AnonymizedDataSectionLoadingBoundary.query);

  if (!data) {
    return loading ? (
      <Loading />
    ) : (
      <>An error occurred while loading this page.</>
    );
  }

  return (
    <AnonymizedDataSection
      permissionSettings={data.organization.permissionSettings}
    />
  );
}

AnonymizedDataSectionLoadingBoundary.query = gql`
  ${AnonymizedDataSection.fragments.permissionSettings}
  query AnonymizedDataSectionLoadingBoundaryQuery {
    organization {
      id
      permissionSettings {
        id
        ...AnonymizedDataSection_permissionSettings
      }
    }
  }
`;
