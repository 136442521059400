import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const ArrowLeftIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Arrow Left"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M11.5 3L2.5 12L11.5 21"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M2.5 12C10 12 20 12 20 12"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </ColorableSVG>
);
