import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const PromotionIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Promotion"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M4 13C5.10457 13 6 12.1046 6 11C6 9.89543 5.10457 9 4 9C2.89543 9 2 9.89543 2 11C2 12.1046 2.89543 13 4 13Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="fill"
        d="M1 15C1.27732 14.3994 1.70507 13.8939 2.2351 13.5406C2.76513 13.1872 3.37637 13 4 13C4.62363 13 5.23487 13.1872 5.7649 13.5406C6.29493 13.8939 6.72268 14.3994 7 15"
      />
      <path
        className="stroke"
        d="M1 15C1.27732 14.3994 1.70507 13.8939 2.2351 13.5406C2.76513 13.1872 3.37637 13 4 13C4.62363 13 5.23487 13.1872 5.7649 13.5406C6.29493 13.8939 6.72268 14.3994 7 15"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M12 5C13.1046 5 14 4.10457 14 3C14 1.89543 13.1046 1 12 1C10.8954 1 10 1.89543 10 3C10 4.10457 10.8954 5 12 5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M9 7C9.27732 6.39937 9.70507 5.89392 10.2351 5.54057C10.7651 5.18722 11.3764 5 12 5C12.6236 5 13.2349 5.18722 13.7649 5.54057C14.2949 5.89392 14.7227 6.39937 15 7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M12 14.25V9.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M4 1L4 5.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M10 11.5L12 9.5L14 11.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M6 3.75L4 5.75L2 3.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
