import { makeStyles } from "@material-ui/core";
import { BenefitsPackageFields as BenefitsPackage } from "../../../../__generated__/graphql";
import { BenefitsPackageCard } from "./BenefitsPackageCard";
import { BenefitsPackageEmptyList } from "./BenefitsPackageEmptyList";

interface Props {
  benefitsPackages: BenefitsPackage[];
}

const useStyles = makeStyles(() => ({
  list: {
    margin: 0,
    padding: 0,
  },
}));

export function BenefitsPackageList({ benefitsPackages }: Props): JSX.Element {
  const classes = useStyles();

  if (benefitsPackages.length === 0) {
    return <BenefitsPackageEmptyList />;
  }

  return (
    <ul className={classes.list}>
      {benefitsPackages.map((benefitsPackage) => (
        <BenefitsPackageCard
          key={benefitsPackage.id}
          benefitsPackage={benefitsPackage}
        />
      ))}
    </ul>
  );
}
