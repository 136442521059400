import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const SearchIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Search"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M10.875 18.7499C15.2242 18.7499 18.7499 15.2242 18.7499 10.875C18.7499 6.52574 15.2242 3 10.875 3C6.52574 3 3 6.52574 3 10.875C3 15.2242 6.52574 18.7499 10.875 18.7499Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M16.4434 16.4434L20.9996 20.9997"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
