import { ErrorColorIcon } from "src/components/AssembleIcons/Small/ErrorColorIcon";
import { SuccessColorIcon } from "src/components/AssembleIcons/Small/SuccessColorIcon";
import { WarningColorIcon } from "src/components/AssembleIcons/Small/WarningColorIcon";

type Props = {
  requested: number;
  completed: number;
  isSkipped: boolean;
  isPastPhase: boolean;
};
export function CompletionStatus({
  requested,
  completed,
  isSkipped,
  isPastPhase,
}: Props) {
  if (isPastPhase) {
    if (isSkipped) {
      return <WarningColorIcon />;
    }
    if (requested === completed) {
      return <SuccessColorIcon />;
    }

    return <ErrorColorIcon />;
  }

  return (
    <>
      {completed}/{requested}
    </>
  );
}
