import { useParams } from "react-router-dom";
import { useTrack } from "../../../analytics";
import { CompCycleHomeBoundary } from "./CompCycleHomeBoundary";

export function CompCycleHome(): JSX.Element | null {
  const { compCycleId } = useParams<{ compCycleId: string }>();
  const cycleId = compCycleId != null ? parseInt(compCycleId, 10) : NaN;
  const { Track } = useTrack({
    subArea: "Comp Cycle Home",
    compCycleId: cycleId,
  });

  if (isNaN(cycleId)) {
    return null;
  }
  return (
    <Track>
      <CompCycleHomeBoundary />
    </Track>
  );
}
