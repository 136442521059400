import { Money } from "@asmbl/shared/money";
import { makeStyles, Typography } from "@material-ui/core";
import { memo } from "react";
import { getMinAndMaxBandPoints, sortBandPoints } from "../../models/BandPoint";
import {
  DV_ORANGE,
  DV_PINK,
  DV_YELLOW,
  GRAY_3,
  GRAY_9,
  GREEN_2,
  RED,
  WHITE,
} from "../../theme";
import { CompSliderTooltip, Position } from "./CompSliderPopover";
import { LargeCompSliderAdjustedBandPoint as BandPoint } from "./LargeCompSlider";

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",

    display: "flex",
    flex: 1,
  },
  band: {
    width: "100%",
    height: "15px",
    background: `linear-gradient(270deg, ${DV_PINK} 0%, ${DV_ORANGE} 78.13%, ${DV_YELLOW} 100%)`,
    borderRadius: "2px",
    display: "flex",
    flexDirection: "row",
    position: "relative",

    "&$outOfRange": {
      border: `1px solid ${WHITE}`,
      boxShadow: "0px 0px 0px 2px rgba(10, 36, 64, 0.4)",
    },
  },
  dot: {
    height: "9px",
    width: "9px",
    borderRadius: "50%",
    backgroundColor: WHITE,
    border: `1px solid ${DV_ORANGE}`,
    boxShadow: "0px 1px 2px rgba(10, 36, 64, 0.2)",
    position: "absolute",
    left: "0.5px",
    top: "50%",
    transform: "translate(-50%, -50%)",

    "$outOfRange &": {
      border: `1px solid ${WHITE}`,
    },
    "$belowRange &": {
      background: GREEN_2,
    },
    "$aboveRange &": {
      background: RED,
    },
  },
  outOfRange: {},
  belowRange: {},
  aboveRange: {},
  line: {
    background: GRAY_9,
    height: "100%",
    position: "absolute",
    top: 0,
    transition: "all 250ms ease",
    width: "1px",
  },
  label: {
    color: GRAY_3,
    fontSize: "10px",
    fontWeight: 700,
    textAlign: "center",
    textTransform: "uppercase",
    position: "absolute",
    top: "calc(100% + 2px)",
    width: "100%",
  },
}));

export type Props = {
  locationAdjustedBandPoints: BandPoint[];
  value: Money | null;
  position: Position | null;
};

export const MiniCompSlider = memo(function MiniCompSlider({
  locationAdjustedBandPoints: bandPoints,
  value: moneyValue,
  position,
}: Props) {
  const classes = useStyles();

  const sortedValues = sortBandPoints(bandPoints);
  const [minValue, maxValue] = getMinAndMaxBandPoints(sortedValues);

  const value = moneyValue?.value ?? null;

  const isBelowRange = value != null ? value < minValue.value : false;
  const isAboveRange = value != null ? value > maxValue.value : false;
  const isOutOfRange = isBelowRange || isAboveRange;

  const getOffset = (value: number): string => {
    if (value === minValue.value && value === maxValue.value) {
      return "50%";
    }

    const offset =
      ((value - minValue.value) / (maxValue.value - minValue.value)) * 100;
    if (offset > 100) return "calc(100% + 12px)";
    if (offset < 0) return "-12px";
    return `${offset}%`;
  };

  return (
    <CompSliderTooltip
      value={moneyValue}
      locationAdjustedBandPoints={sortedValues}
      position={position}
    >
      <div className={classes.container}>
        <div
          className={`
            ${classes.band} 
            ${isOutOfRange ? classes.outOfRange : ""}
            ${isBelowRange ? classes.belowRange : ""}
            ${isAboveRange ? classes.aboveRange : ""}
          `}
        >
          {value != null && (
            <div
              className={classes.line}
              style={{ left: `${getOffset(value)}` }}
            >
              <div className={classes.dot} />
            </div>
          )}
        </div>
        {isOutOfRange && (
          <Typography
            variant="caption"
            className={classes.label}
            component="div"
          >
            {isBelowRange ? "Below" : "Above"}
          </Typography>
        )}
      </div>
    </CompSliderTooltip>
  );
});
