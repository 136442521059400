import { gql } from "@apollo/client";
import {
  ButtonBase,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Popover,
  PopoverOrigin,
} from "@material-ui/core";
import { MouseEvent, useState } from "react";
import { EllipsisMoreIcon } from "src/components/AssembleIcons/Brand/EllipsisMoreIcon";
import { EditIcon } from "src/components/AssembleIcons/Small/EditIcon";
import { TrashIcon } from "src/components/AssembleIcons/Small/TrashIcon";
import { AssembleTypography } from "../../../components/AssembleTypography";
import { GRAY_4, PURPLE_1 } from "../../../theme";
import { MarketCardActions_market } from "../../../__generated__/graphql";
import { MarketDeleter } from "./MarketDeleter";
import { MarketEditor } from "./MarketEditor";

type Props = {
  market: MarketCardActions_market;
  isOnlyMarket: boolean;
};
const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "center",
};

const transformOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: "0px 15px 25px rgba(10, 36, 64, 0.1)",
    borderRadius: "4px",
  },
  menu: {
    minWidth: "10.5rem",
  },
  menuItem: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  menuIcon: {
    color: GRAY_4,
    "$menuItem:hover &, $menuItem:active &": {
      color: PURPLE_1,
    },
  },
}));

export function MarketCardActions({
  market,
  isOnlyMarket,
}: Props): JSX.Element {
  const classes = useStyles();

  /*
   * Opening and closing the popover menu
   */
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  /*
   * Handle the modals triggered by the menu buttons
   */
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  return (
    <>
      {editOpen && (
        <MarketEditor
          onClose={() => setEditOpen(false)}
          existingMarket={market}
        />
      )}
      {deleteOpen && (
        <MarketDeleter onClose={() => setDeleteOpen(false)} market={market} />
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handlePopoverClose}
        PaperProps={{
          className: classes.menuPaper,
        }}
      >
        <MenuList className={classes.menu}>
          <MenuItem
            className={classes.menuItem}
            component={ButtonBase}
            onClick={() => {
              handlePopoverClose();
              setEditOpen(true);
            }}
          >
            <AssembleTypography variant="productSmall">
              Edit Details
            </AssembleTypography>
            <EditIcon className={classes.menuIcon} inherit />
          </MenuItem>
          {!isOnlyMarket && (
            <MenuItem
              className={classes.menuItem}
              component={ButtonBase}
              onClick={() => {
                handlePopoverClose();
                setDeleteOpen(true);
              }}
            >
              <AssembleTypography variant="productSmall">
                Delete
              </AssembleTypography>
              <TrashIcon className={classes.menuIcon} inherit />
            </MenuItem>
          )}
        </MenuList>
      </Popover>
      <IconButton size="small" onClick={handlePopoverButtonClick}>
        <EllipsisMoreIcon inherit width="24px" height="24px" />
      </IconButton>
    </>
  );
}

MarketCardActions.fragments = {
  market: gql`
    ${MarketEditor.fragments.market}
    ${MarketDeleter.fragments.market}
    fragment MarketCardActions_market on Market {
      ...MarketEditor_market
      ...MarketDeleter_market
    }
  `,
};
