import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { PerfRatingCallout_perfRating } from "../../../__generated__/graphql";
import { DV_YELLOW } from "../../../theme";
import { CompValue } from "./CompValue";
import { LabelValue } from "./LabelValue";

const useStyles = makeStyles((theme) => ({
  perfRatingContainer: {
    background: `${DV_YELLOW}22`,
    borderRadius: "2px",
    padding: theme.spacing(0.5, 1),
    marginBottom: theme.spacing(2),
  },
}));

type Props = { perfRating: PerfRatingCallout_perfRating | null };

export function PerfRatingCallout({ perfRating }: Props): JSX.Element | null {
  const classes = useStyles();

  if (perfRating?.perfRating == null) {
    return null;
  }

  return (
    <div className={classes.perfRatingContainer}>
      <LabelValue
        label="Performance Rating"
        compValue={<CompValue value={perfRating.perfRating} />}
      />
    </div>
  );
}

PerfRatingCallout.fragments = {
  perfRating: gql`
    fragment PerfRatingCallout_perfRating on EmployeeCompCyclePerfRating {
      perfRating
      deletedAt
      createdAt
    }
  `,
};
