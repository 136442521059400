import { gql } from "@apollo/client";
import { getTenure } from "@asmbl/shared/employee";
import { Money } from "@asmbl/shared/money";
import { caseInsensitiveComparator } from "@asmbl/shared/sort";
import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  makeStyles,
} from "@material-ui/core";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { ALL_CASH_COMP_OPTIONS, TOTAL_CASH_OPTION } from "src/constants";
import {
  CashCompType,
  ReportsTable_employee as Employee,
  PayPeriodType,
  PositionFieldsMinimal as Position,
} from "../../../__generated__/graphql";
import { useTrack } from "../../../analytics";
import { useCurrencies } from "../../../components/CurrenciesContext";
import { CompaRatioCell } from "../../../components/Table/CompaRatioCell";
import { CondensedBandPlacementCell } from "../../../components/Table/CondensedBandPlacementCell";
import { EquityDisplayType } from "../../../components/Table/EquityDisplayCell";
import { TotalCashCell } from "../../../components/Table/TotalCashCell";
import { TotalCash, getTotalCash } from "../../../models/CashCompensation";
import {
  getCompaRatioNew,
  getDepartmentName,
  getJobTitle,
  getLadderName,
  getLevel,
  getPositionName,
  needsLeveling,
} from "../../../models/Employee";
import { getExchangedSalary } from "../../../models/Employment";
import { FilterParam } from "../../../models/FilterParams";
import { useURLSearchParams } from "../../../models/URLSearchParams";
import { ArrayValue } from "../../../utils";
import { TableCompType, useReportsTableContext } from "../Context/TableContext";
import { EmptySearchResults } from "../EmptySearchResults";
import { PeopleTabParam } from "../PeopleTabParam";
import { ReportsTableHeader } from "./ReportsTableHeader";
import { ReportsTableHeaderBar } from "./ReportsTableHeaderBar";
import { ReportsTableRow } from "./ReportsTableRow";

type CashCompensation = ArrayValue<Employee["activeCashCompensation"]>;

export interface ReportRow {
  employee: Employee;
  name: string;
  department?: string;
  ladder?: string;
  position?: string;
  level?: number;
  location?: string;
  tenure?: number;
  compaRatio?: number;
  totalCash?: TotalCash<CashCompensation>;
  totalCashValue?: Money;
  jobTitle?: string;
}

const useStyles = makeStyles(() => ({
  progressBar: {
    width: "100%",
  },
}));

interface Props {
  reports: Employee[];
  employeeDetailId: number | undefined;
  canUserLevel: boolean;
  hasCompBandAccess: boolean;
  positions?: Position[];
  onLevel?(
    employee: { activeEmployment: { id: number } | null },
    positionId: number | null
  ): unknown;
  totalCount: number;
  isLoading: boolean;
}

export function ReportsTable({
  reports,
  employeeDetailId,
  canUserLevel,
  hasCompBandAccess,
  positions,
  onLevel,
  totalCount,
  isLoading,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const { currencies, defaultCurrency } = useCurrencies();
  const urlSearchParams = useURLSearchParams();

  const [compComponentSelection, setCompComponentSelection] = useState(
    new Set<CashCompType>(ALL_CASH_COMP_OPTIONS)
  );
  const tabParam = (urlSearchParams.get(FilterParam.TAB) ??
    PeopleTabParam.ALL) as PeopleTabParam;
  const [showLevelingColumn, setShowLevelingColumn] = useState(false);
  const [employees, setEmployees] = useState(reports.filter(needsLeveling));

  // Ensure that the employees are updated when the reports change
  useEffect(() => {
    setEmployees(reports.filter(needsLeveling));
  }, [window.location.search, reports]);

  // if in leveling tab, this maintains the table's rows as the user levels
  const empsToDisplay =
    tabParam === PeopleTabParam.LEVELING ? employees : reports;

  const reportRows: ReportRow[] = useMemo(
    () =>
      empsToDisplay.map((report) => {
        const totalCash = getTotalCash(
          report.activeCashCompensation,
          compComponentSelection,
          currencies
        );
        return {
          employee: report,
          name: report.displayName,
          department: getDepartmentName(report),
          ladder: getLadderName(report),
          position: getPositionName(report),
          level: getLevel(report),
          location: report.location?.name,
          tenure: getTenure(report),
          compaRatio: getCompaRatioNew(
            report.activeCashCompensation,
            report.adjustedCashBands
          ),
          totalCash: totalCash,
          totalCashValue:
            totalCash !== undefined
              ? getExchangedSalary(
                  defaultCurrency,
                  { salary: totalCash.annualTotal },
                  currencies
                )
              : undefined,
          jobTitle: getJobTitle(report),
        };
      }),
    [currencies, compComponentSelection, defaultCurrency, empsToDisplay]
  );

  const {
    searchQuery,
    prevSearchQuery,
    setSearchQuery,
    setOffset,
    offset,
    limit,
    setLimit,
    sortDir,
    sortBy,
    handleSort,
    setCompType,
    compType,
    refetch,
  } = useReportsTableContext();

  const [searchInputValue, setSearchInputValue] = useState(searchQuery);
  const [equityDisplay, setEquityDisplay] =
    useState<EquityDisplayType>("units");
  const [payTypeSelection, setPayTypeSelection] = useState<PayPeriodType>(
    PayPeriodType.ANNUAL
  );

  const handleSearchInputChange = (value: string | null) => {
    if (value !== null) {
      setSearchInputValue(value);
    } else {
      setSearchInputValue("");
    }
  };

  const handleSearchOnBlur = (value: string) => {
    setSearchQuery(value);
    setOffset(0);
  };

  const handleEquityToggleChange = (value: EquityDisplayType | null) => {
    if (value !== null) {
      trackEvent({
        object: "Equity Display",
        action: "Changed",
        equityDisplayType: value,
      });
      setEquityDisplay(value);
    }
  };

  const handlePayTypeToggleChange = (value: PayPeriodType) => {
    trackEvent({
      object: "Pay Type Selection",
      action: "Changed",
      payType: value,
    });
    setPayTypeSelection(value);
  };

  const handleLevelingToggle = () => {
    trackEvent({
      object: "Level Employees Column Visibility",
      action: "Toggled",
      isVisible: !showLevelingColumn,
    });

    setShowLevelingColumn(!showLevelingColumn);

    // refetch if turning off
    if (!showLevelingColumn === false) {
      refetch();
    }
  };

  const sortedPositions = useMemo(() => {
    return positions?.slice().sort((a, b) => {
      const nameCompare = caseInsensitiveComparator(
        a.ladder.name,
        b.ladder.name
      );
      const levelCompare = a.level - b.level;

      return nameCompare || levelCompare;
    });
  }, [positions]);

  const handleLevel = useCallback(
    (
      employee: { activeEmployment: { id: number } | null },
      positionId: number | null
    ) => {
      if (onLevel) onLevel(employee, positionId);
    },
    [onLevel]
  );

  const showEquityGrants = true;

  const handleCompComponentChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;
    trackEvent({
      object: "Total Cash Comp Filter",
      action: "Changed",
      compensationComponent: value,
    });
    if (value === TOTAL_CASH_OPTION) {
      // For UI
      if (compComponentSelection.size < ALL_CASH_COMP_OPTIONS.length) {
        setCompComponentSelection(new Set<CashCompType>(ALL_CASH_COMP_OPTIONS));
      }
      if (compComponentSelection.size === ALL_CASH_COMP_OPTIONS.length) {
        setCompComponentSelection(new Set<CashCompType>([]));
      }

      // For query filter
      setCompType({
        [CashCompType.COMMISSION]: true,
        [CashCompType.SALARY]: true,
        [CashCompType.RECURRING_BONUS]: true,
      });
      setPayTypeSelection(PayPeriodType.ANNUAL);
    } else {
      const newValue = event.target.value as CashCompType;
      // For UI
      if (compComponentSelection.has(newValue)) {
        // we only allow hourly/annual pay type selection for salary
        // so if salary is deselected, we set pay type to annual
        if (newValue === CashCompType.SALARY) {
          setPayTypeSelection(PayPeriodType.ANNUAL);
        }
        compComponentSelection.delete(newValue);
        setCompComponentSelection(
          new Set<CashCompType>(compComponentSelection)
        );
      } else {
        compComponentSelection.add(newValue);
        // if bonus or commission is selected, we set pay type to annual
        if (
          newValue === CashCompType.COMMISSION ||
          newValue === CashCompType.RECURRING_BONUS
        ) {
          setPayTypeSelection(PayPeriodType.ANNUAL);
        }
        setCompComponentSelection(
          new Set<CashCompType>(compComponentSelection)
        );
      }

      // For query filter
      setCompType({
        ...compType,
        [newValue]: !compType[newValue as keyof TableCompType],
      });
    }
  };

  const classes = useStyles();
  if (isLoading)
    return (
      <LinearProgress variant="indeterminate" className={classes.progressBar} />
    );
  return (
    <TableContainer component={Paper} elevation={0}>
      <ReportsTableHeaderBar
        searchTerm={searchInputValue}
        onSearchChange={handleSearchInputChange}
        handleOnBlur={handleSearchOnBlur}
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        rowCount={reportRows.length}
        compComponentSelection={compComponentSelection}
        onCompComponentChange={handleCompComponentChange}
        showEquityGrants={showEquityGrants}
        equityDisplayUnitType={equityDisplay}
        onEquityToggleChange={handleEquityToggleChange}
        onPayTypeToggleChange={handlePayTypeToggleChange}
        payTypeSelection={payTypeSelection}
        onLevelingToggleChange={handleLevelingToggle}
        showLevelingColumn={
          showLevelingColumn || tabParam === PeopleTabParam.LEVELING
        }
        canUserLevel={canUserLevel}
        tabParam={tabParam}
        totalCount={totalCount}
        prevSearchTerm={prevSearchQuery}
      />
      <Table stickyHeader style={{ overflow: "hidden" }}>
        <TableHead>
          <ReportsTableHeader
            order={sortDir}
            orderBy={sortBy}
            showLevelingColumn={
              showLevelingColumn || tabParam === PeopleTabParam.LEVELING
            }
            showEquityGrants={showEquityGrants}
            compComponentSelection={compComponentSelection}
            equityDisplay={equityDisplay}
            hasCompBandAccess={hasCompBandAccess}
            handleSort={handleSort}
          />
        </TableHead>
        <TableBody>
          {reportRows.length > 0 ? (
            reportRows.map((row) => (
              <ReportsTableRow
                key={row.employee.id}
                rowData={row}
                employeeDetailId={employeeDetailId}
                equityDisplay={equityDisplay}
                showEquityGrants={showEquityGrants}
                showLevelingColumn={
                  showLevelingColumn || tabParam === PeopleTabParam.LEVELING
                }
                canUserLevel={canUserLevel}
                sortedPositions={sortedPositions}
                handleLevel={handleLevel}
                hasCompBandAccess={hasCompBandAccess}
                payTypeSelection={payTypeSelection}
              />
            ))
          ) : (
            <EmptySearchResults searchTerm={prevSearchQuery} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ReportsTable.fragments = {
  employee: gql`
    ${TotalCashCell.fragments.cash}
    ${CondensedBandPlacementCell.fragments.employee}
    ${CompaRatioCell.fragments.employee}
    ${ReportsTableRow.fragments.employee}
    fragment ReportsTable_employee on Employee2 {
      ...CondensedBandPlacementCell_employee
      ...CompaRatioCell_employee
      ...ReportsTableRow_employee
      id
      displayName
      activeAt
      activeEmployment {
        id
        salary
        jobTitle
        positionId
        levelingCode
        levelingMethod
        payPeriod
        position {
          id
          name
          level
          ladder {
            id
            name
            department {
              id
              name
            }
          }
        }
      }
      activeCashCompensation {
        activeAt
        employeeId
        type
        annualCashEquivalent
        hourlyCashEquivalent
        unit
        ...TotalCashCell_cash
      }
      location {
        id
        name
      }
    }
  `,
};
