import { Button } from "@material-ui/core";
import { ExplanatoryTooltip } from "../../../components/ExplanatoryTooltip";

export function ConnectIntegrationButton({
  category,
  createIntegration,
  disableButton,
  buttonStyle = "contained",
}: {
  category: "HRIS" | "ATS";
  createIntegration: () => unknown;
  disableButton?: boolean;
  buttonStyle?: "outlined" | "contained";
}): JSX.Element {
  const title = `You already have an ${category} integration.`;
  const body = `You can only integrate with one ${category} at a time. Let us know if you'd like to connect to a different one.`;

  return disableButton === true ? (
    <ExplanatoryTooltip
      title={title}
      width="8rem"
      ctaLabel="Contact Us"
      openIntercom
      body={body}
    >
      <span>
        <Button
          size="small"
          style={{ height: "100%" }}
          variant={buttonStyle}
          color="primary"
          disabled
        >
          Add {category}
        </Button>
      </span>
    </ExplanatoryTooltip>
  ) : (
    <Button
      size="small"
      variant={buttonStyle}
      color="primary"
      onClick={createIntegration}
    >
      Add {category}
    </Button>
  );
}
