import { plainDateToLocaleString, startOfDay } from "@asmbl/shared/time";
import DateFnsUtils from "@date-io/date-fns";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useState } from "react";
import { useIntercom } from "react-use-intercom";
import { useTrack } from "src/analytics";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { CalendarIcon } from "src/components/AssembleIcons/Small/CalendarIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_2, theme } from "src/theme";
import { CreatePhasesInput } from "src/__generated__/graphql";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minWidth: "400px",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(4),
  },
  dialogActions: {
    display: "flex",
    alignSelf: "flex-end",
    paddingTop: theme.spacing(2),
  },
  subtitleText: {
    color: GRAY_2,
  },
  subtitleContainer: {
    marginBottom: theme.spacing(0.5),
  },
  noteContainer: {
    marginBottom: theme.spacing(3),
  },
  noteText: {
    letterSpacing: "-0.15px",
    color: GRAY_2,
    textTransform: "none",
  },
  datePickerInput: {
    width: "100%",
    display: "flex",
    flexShrink: 0,
    "& .MuiOutlinedInput-input": {
      padding: theme.spacing(1.5, 2),
    },
  },
  dialogContent: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
}));

type Props = {
  handleModalCancel: () => void;
  handleModalClose: (date: string, phaseNumber: number) => void;
  date: string;
  open: boolean;
  currentPhase?: CreatePhasesInput;
};

export function PhaseModal({
  handleModalClose,
  handleModalCancel,
  open,
  date,
  currentPhase,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const intercom = useIntercom();
  const classes = useStyles();
  const phaseNumber =
    currentPhase?.phaseOrder !== undefined ? currentPhase.phaseOrder : 1;
  const [calendarDate, setCalendarDate] = useState(date);

  const onModalClose = () => {
    trackEvent({
      object: "Save Phases Button",
      action: "Clicked",
      phaseOrder: phaseNumber,
    });

    intercom.trackEvent("Save Phases Button Clicked", {
      phaseOrder: phaseNumber,
    });

    handleModalClose(calendarDate, phaseNumber);
  };

  const jsDateObject = new Date(calendarDate);
  const minDate = new Date(
    jsDateObject.getTime() + jsDateObject.getTimezoneOffset() * 60000
  );

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{
          className: classes.dialogPaper,
        }}
        data-intercom-target={"phase-date-selector"}
      >
        <DialogTitle>{`Timeframe - Phase ${phaseNumber}`}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.subtitleContainer}>
            <AssembleTypography
              variant="productParagraphLarge"
              className={classes.subtitleText}
            >
              {`Select when Phase ${phaseNumber} should start?`}
            </AssembleTypography>
          </DialogContentText>
          <DialogContentText className={classes.noteContainer}>
            <AssembleTypography
              variant="productEyebrow"
              className={classes.noteText}
            >
              (Note: phase will end when the subsequent phase starts)
            </AssembleTypography>
          </DialogContentText>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className={classes.datePickerInput}
              disablePast
              disableToolbar
              minDate={minDate}
              minDateMessage="Selected date already applies to another phase or is in the past."
              autoOk
              placeholder="Start date"
              label="Start date"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              orientation="landscape"
              value={minDate}
              onChange={(date) => {
                if (date !== null) {
                  setCalendarDate(plainDateToLocaleString(date));
                }
              }}
              keyboardIcon={<CalendarIcon inherit />}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <AssembleButton
            onClick={handleModalCancel}
            size="medium"
            variant="text"
            label="Cancel"
          />
          <AssembleButton
            autoFocus
            size="medium"
            variant="contained"
            onClick={onModalClose}
            label="Save Phase"
            disabled={
              new Date(calendarDate).getTime() <
              startOfDay(new Date(date)).getTime()
            }
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
