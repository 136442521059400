import { makeStyles, Typography } from "@material-ui/core";
import { GRAY_4 } from "../../../theme";
import { AtsCandidate } from "./CandidateLoadingBoundary";

const useStyles = makeStyles(() => ({
  name: {
    fontSize: "1rem",
  },
  jobTitle: {
    fontSize: "0.875rem",
    color: GRAY_4,
  },
}));

export function DropdownOption({
  candidate,
}: {
  candidate: AtsCandidate;
}): JSX.Element {
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.name}>
        {`${candidate.firstName} ${candidate.lastName}`}
      </Typography>
      <Typography className={classes.jobTitle}>{candidate.jobTitle}</Typography>
    </div>
  );
}
