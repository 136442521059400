import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const FlagFilledIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Flag Filled"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill"
        d="M 14.4 6 L 14 4 H 5 v 17 h 2 v -7 h 5.6 l 0.4 2 h 7 V 6 Z"
      />
    </svg>
  </ColorableSVG>
);
