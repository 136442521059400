import { JobStructureScope } from "@asmbl/shared/permissions";
import {
  Box,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useMemo } from "react";
import { LevelIcon } from "src/components/AssembleIcons/Brand/LevelIcon";
import { TreeNode } from "src/components/JobStructureSelect/TreeSelect";
import {
  JobStructure,
  JobStructureTreeNodeData,
  selectionToTree,
} from "../../../models/JobStructure";
import { GRAY_4, GRAY_6 } from "../../../theme";
import { NodeLabel } from "./NodeLabel";

const useStyles = makeStyles((theme) => ({
  borderCell: {
    borderRight: `1px solid ${GRAY_6}`,
  },
  cellAlignment: {
    verticalAlign: "top",
  },
  lastRow: {
    "& td": {
      borderTop: `4px solid ${theme.palette.divider}`,
      padding: 0,
    },
    "&:last-child td": {
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    },
  },
}));

type Props = {
  scope: JobStructureScope | null | undefined;
  inheritedScope?: JobStructureScope | null;
  jobStructure: JobStructure;
  spacerColumns?: number;
};
export function JobStructureSelectionRows({
  scope,
  inheritedScope,
  jobStructure,
  spacerColumns = 0,
}: Props) {
  const classes = useStyles();

  const tree = useMemo(
    () =>
      selectionToTree(
        jobStructure,
        scope ?? {
          global: false,
          departmentIDs: [],
          ladderIDs: [],
          positionIDs: [],
        },
        inheritedScope
      ),
    [inheritedScope, jobStructure, scope]
  );

  const departmentToLevelRangeMap: Map<number, number[]> = useMemo(() => {
    const map = new Map<number, number[]>();
    jobStructure.departments.forEach((department) => {
      const sortedLevels = department.ladders
        .flatMap((ladder) => ladder.positions)
        .map((position) => position.level)
        .sort((a, b) => a - b);
      map.set(department.id, [
        sortedLevels[0],
        sortedLevels[sortedLevels.length - 1],
      ]);
    });
    return map;
  }, [jobStructure.departments]);

  const ladderToLevelRangeMap: Map<number, number[]> = useMemo(() => {
    const map = new Map<number, number[]>();
    jobStructure.departments
      .flatMap((department) => department.ladders)
      .forEach((ladder) => {
        const sortedLevels = ladder.positions
          .map((position) => position.level)
          .sort((a, b) => a - b);
        map.set(ladder.id, [
          sortedLevels[0],
          sortedLevels[sortedLevels.length - 1],
        ]);
      });
    return map;
  }, [jobStructure.departments]);

  const positionToLevelMap: Map<number, number> = useMemo(() => {
    const map = new Map<number, number>();
    jobStructure.departments
      .flatMap((department) => department.ladders)
      .flatMap((ladder) => ladder.positions)
      .forEach((position) => map.set(position.id, position.level));
    return map;
  }, [jobStructure.departments]);

  const totalRows = useMemo(() => selectionRows(tree), [tree]);

  const isScopeGlobal = scope?.global ?? false;
  const isInheritedScopeGlobal = inheritedScope?.global ?? false;

  return (
    <>
      {isScopeGlobal || isInheritedScopeGlobal ? (
        <TableRow>
          <TableCell colSpan={spacerColumns} className={classes.borderCell} />
          <TableCell className={classes.borderCell}>
            <NodeLabel isInherited={isInheritedScopeGlobal && !isScopeGlobal}>
              <AllCounter node={tree} depth={0} />
            </NodeLabel>
          </TableCell>
          <TableCell className={classes.borderCell}>
            <NodeLabel isInherited={isInheritedScopeGlobal && !isScopeGlobal}>
              <AllCounter node={tree} depth={1} />
            </NodeLabel>
          </TableCell>
          <TableCell className={classes.borderCell}>
            <NodeLabel isInherited={isInheritedScopeGlobal && !isScopeGlobal}>
              <AllCounter node={tree} depth={2} />
            </NodeLabel>
          </TableCell>
        </TableRow>
      ) : (
        tree.children
          ?.filter((d) => d.selected || d.inherited)
          .map((department, i) => {
            const departmentCell = (
              <>
                {spacerColumns > 0 && i === 0 ? (
                  // The first row also gets the spacer cell,
                  // spanning all departments
                  <TableCell
                    colSpan={spacerColumns}
                    rowSpan={totalRows}
                    className={classes.borderCell}
                  />
                ) : null}
                <TableCell
                  rowSpan={selectionRows(department)}
                  className={`${classes.borderCell} ${classes.cellAlignment}`}
                >
                  <NodeLabel
                    isInherited={department.inherited && !department.selected}
                  >
                    {department.data.name}
                  </NodeLabel>
                </TableCell>
              </>
            );

            if (!department.indeterminate) {
              return (
                <TableRow key={department.data.id}>
                  {departmentCell}
                  <TableCell className={classes.borderCell}>
                    <NodeLabel
                      isInherited={department.inherited && !department.selected}
                    >
                      <AllCounter node={department} depth={0} />
                    </NodeLabel>
                  </TableCell>
                  <TableCell className={classes.borderCell}>
                    <Box display="flex" justifyContent="space-between">
                      <NodeLabel
                        isInherited={
                          department.inherited && !department.selected
                        }
                      >
                        <AllCounter node={department} depth={1} />
                      </NodeLabel>
                      <LevelIndicator
                        levels={departmentToLevelRangeMap.get(
                          department.data.id
                        )}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              );
            }
            return department.children
              ?.filter((l) => l.selected || l.inherited)
              .map((ladder, lIdx) => {
                const ladderCell = (
                  <TableCell
                    rowSpan={selectionRows(ladder)}
                    className={`${classes.borderCell} ${classes.cellAlignment}`}
                  >
                    <NodeLabel
                      isInherited={ladder.inherited && !ladder.selected}
                    >
                      {ladder.data.name}
                    </NodeLabel>
                  </TableCell>
                );
                if (!ladder.indeterminate) {
                  return (
                    <TableRow key={`l-${ladder.data.id}`}>
                      {lIdx === 0 ? departmentCell : null}
                      {ladderCell}
                      <TableCell className={classes.borderCell}>
                        <Box display="flex" justifyContent="space-between">
                          <NodeLabel
                            isInherited={ladder.inherited && !ladder.selected}
                          >
                            <AllCounter node={ladder} depth={0} />
                          </NodeLabel>
                          <LevelIndicator
                            levels={ladderToLevelRangeMap.get(ladder.data.id)}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                }

                return ladder.children
                  ?.filter((p) => p.selected || p.inherited)
                  .map((position, pIdx) => {
                    return (
                      <TableRow key={`p-${position.data.id}`}>
                        {lIdx === 0 && pIdx === 0 ? departmentCell : null}
                        {pIdx === 0 ? ladderCell : null}
                        <TableCell
                          className={`${classes.borderCell} ${classes.cellAlignment}`}
                        >
                          <Box display="flex" justifyContent="space-between">
                            <NodeLabel
                              isInherited={
                                position.inherited && !position.selected
                              }
                            >
                              {position.data.name}
                            </NodeLabel>
                            <LevelIndicator
                              level={positionToLevelMap.get(position.data.id)}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  });
              });
          })
      )}
      <tr className={classes.lastRow}>
        <td colSpan={3 + spacerColumns} />
      </tr>
    </>
  );
}

function AllCounter({
  node,
  depth,
}: {
  node: TreeNode<JobStructureTreeNodeData>;
  depth: number;
}) {
  return (
    <Box display="flex">
      <Typography variant="body2">All</Typography>
      <Box mr={0.5} />
      <Typography variant="body2" color="textSecondary">
        ({countChildren(node, depth)})
      </Typography>
    </Box>
  );
}
function LevelIndicator({
  level,
  levels = [],
}: {
  level?: number;
  levels?: number[];
}) {
  const formatLevelRange = () => {
    const [minLevel, maxLevel] = levels;
    return minLevel === maxLevel ? maxLevel : `${minLevel} - ${maxLevel}`;
  };

  return (
    <Box display="flex" whiteSpace="nowrap">
      <LevelIcon color={GRAY_4} height="18px" width="18px" />
      <Box mr={1} />
      <Typography variant="body2" color="textSecondary">
        {level !== undefined ? level : formatLevelRange()}
      </Typography>
    </Box>
  );
}

function selectionRows(
  root: Readonly<TreeNode<JobStructureTreeNodeData>>
): number {
  if (!root.selected && !root.inherited) {
    // Not selected, so this row won't show up in the table at all
    return 0;
  }
  if (!root.indeterminate) {
    // All of the children are selected, so it will be one row with 'All'
    return 1;
  }

  // Mixed. Need as many rows as our children need.
  return (root.children ?? [])
    .map((child) => selectionRows(child))
    .reduce((a, b) => a + b, 0);
}

function countChildren(
  node: Readonly<TreeNode<JobStructureTreeNodeData>>,
  depth: number
): number {
  if (node.children === undefined) return 0;
  if (depth <= 0) return node.children.length;
  return node.children.reduce(
    (acc, cur) => acc + countChildren(cur, depth - 1),
    0
  );
}
