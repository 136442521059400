import { Box, Grid, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { AssembleButton } from "../../components/AssembleButton/AssembleButton";
import AssembleDialog from "../../components/AssembleDialog";
import { ControlledSaveButton } from "../../components/Form/SaveButton";
import { EditorStatus } from "../../constants";

const useStyles = makeStyles((theme) => ({
  actionBar: {
    position: "fixed",
    top: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 1,
  },
}));

export function PhilosophyActionBar({
  editorStatus,
  handleSave,
  contentIsEdited,
  resetToInitialContent,
  openEditor,
}: {
  editorStatus: EditorStatus;
  handleSave: () => unknown;
  contentIsEdited: boolean;
  resetToInitialContent: () => unknown;
  openEditor: () => void;
}): JSX.Element {
  const classes = useStyles();

  const [isCancelDialogVisible, setIsCancelDialogVisible] = useState(false);

  const onClickCancel = () => {
    if (contentIsEdited) {
      setIsCancelDialogVisible(true);
    } else {
      onAcceptCancelDialog();
    }
  };
  const onBackCancelDialog = () => setIsCancelDialogVisible(false);
  const onAcceptCancelDialog = () => {
    setIsCancelDialogVisible(false);
    resetToInitialContent();
  };

  return (
    <Box className={classes.actionBar}>
      <Grid container justifyContent="flex-end" spacing={1}>
        {editorStatus === EditorStatus.VIEWING ? (
          <Grid item>
            <AssembleButton
              size="medium"
              variant="outlined"
              label="Edit"
              onClick={openEditor}
            />
          </Grid>
        ) : null}

        {editorStatus === EditorStatus.EDITING ? (
          <Grid item>
            <AssembleButton
              size="medium"
              variant="outlined"
              label="Cancel"
              onClick={onClickCancel}
            />
          </Grid>
        ) : null}

        <Grid item>
          <ControlledSaveButton
            editorStatus={editorStatus}
            disabled={!contentIsEdited}
            onSave={handleSave}
          />
        </Grid>

        {isCancelDialogVisible && (
          <AssembleDialog
            onCancel={onBackCancelDialog}
            onConfirm={onAcceptCancelDialog}
            title="Are you sure you want to cancel?"
            text="If you cancel now, you'll lose your unsaved changes."
            cancelButtonText="Back to Editing"
            confirmButtonText="Yes, Cancel"
            confirmButtonVariant="negative"
          />
        )}
      </Grid>
    </Box>
  );
}
