import { isEmptyString } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import { DV_BLUE, GRAY_2 } from "../../../theme";
import { AssembleLinkify } from "../../AssembleLinkify/AssembleLinkify";
import { AssembleTypography } from "../../AssembleTypography";

const useStyles = makeStyles((theme) => ({
  notesContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    background: `${DV_BLUE}1A`,
    borderRadius: "2px",
  },
  notesHeader: {
    textTransform: "uppercase",
  },
  singleNoteContainer: {
    marginBottom: theme.spacing(0.75),
    color: GRAY_2,
  },
  singleNoteTitle: {
    fontWeight: 700,
  },
  singleNoteText: {
    whiteSpace: "pre-wrap",
  },
}));

type Props = {
  notes: {
    title: string | null;
    text: string | null;
  }[];
  className?: string;
};

export function SubmittedCardNote({
  notes,
  className = "",
}: Props): JSX.Element {
  const classes = useStyles();

  const visibleNotes = notes.filter((n) => !isEmptyString(n.text));

  if (visibleNotes.length === 0) {
    return <></>;
  }

  return (
    <div className={`${className} ${classes.notesContainer}`}>
      <AssembleTypography
        variant="productEyebrowSmall"
        className={classes.notesHeader}
        color="textSecondary"
        gutterBottom
      >
        Notes
      </AssembleTypography>
      {visibleNotes.map((n) => (
        <div key={n.title} className={classes.singleNoteContainer}>
          {!isEmptyString(n.title) && (
            <AssembleTypography
              variant="productExtraSmall"
              className={classes.singleNoteTitle}
            >
              {n.title}
            </AssembleTypography>
          )}
          <AssembleLinkify newTab>
            <AssembleTypography
              variant="productExtraSmall"
              className={classes.singleNoteText}
            >
              {n.text}
            </AssembleTypography>
          </AssembleLinkify>
        </div>
      ))}
    </div>
  );
}
