import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import {
  formatUnitSharePrice,
  getIllustrativeUnitPrice,
} from "../../../models/Equity";
import { TotalEquityGrid_equityHoldings as EquityHoldings } from "../../../__generated__/graphql";
import { useCurrencies } from "../../CurrenciesContext";
import { useTotalEquitySlider } from "../../TotalEquitySlider/TotalEquitySliderContext";
import { TotalEquityTable } from "./EquityTable/TotalEquityTable";
import { TotalEquityTimeline } from "./TotalEquityTimeline";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(3),
  },
  equityCards: {
    display: "flex",
    flexDirection: "row",
    columnGap: theme.spacing(4),
    justifyContent: "space-between",
    alignItems: "stretch",
  },
}));

type Props = {
  equityHoldings: EquityHoldings;
};

export function TotalEquityGrid({ equityHoldings }: Props): JSX.Element {
  const classes = useStyles();
  const { currencies } = useCurrencies();

  const equitySliderData = useTotalEquitySlider();

  const sharePriceFallback = getIllustrativeUnitPrice(
    currencies,
    equityHoldings.grants,
    equityHoldings.valuationCurrency
  );

  const sharePrice =
    formatUnitSharePrice(equitySliderData?.value, sharePriceFallback) ??
    undefined;

  return (
    <div className={classes.root}>
      <TotalEquityTimeline
        equityHoldings={equityHoldings}
        sharePrice={sharePrice}
      />
      <TotalEquityTable
        grants={equityHoldings.grants ?? []}
        sharePrice={sharePrice}
        valuationCurrency={equityHoldings.valuationCurrency}
      />
    </div>
  );
}

TotalEquityGrid.fragments = {
  portalConfig: gql`
    fragment TotalEquityGrid_portalConfig on PortalConfig {
      defaultExitOutcome
    }
  `,
  equityHoldings: gql`
    ${TotalEquityTimeline.fragments.equityHoldings}
    ${TotalEquityTable.fragments.equityHoldings}
    ${TotalEquityTable.fragments.equityGrant}
    fragment TotalEquityGrid_equityHoldings on EquityHoldings {
      id
      valuationCurrency {
        id
        code
        exchangeRate
      }
      grants {
        id
        ...TotalEquityTable_equityGrant
      }
      ...TotalEquityTable_equityHoldings
      ...TotalEquityTimeline_equityHoldings
    }
  `,
};
