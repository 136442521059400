import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency, exchangeFromTo } from "@asmbl/shared/currency";
import { Money, zero } from "@asmbl/shared/money";
import { contramap } from "@asmbl/shared/sort";
import {
  CashCompType,
  CondensedTableCurrentTargetCommissionCell2_participant as Participant,
} from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { activeComp, cashValueOf } from "../../../../models/CashCompensation";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

function getCommission(
  employee: Participant,
  defaultCurrencyCode: CurrencyCode
): Money {
  const activeCashComp = activeComp(
    employee.subject.activeCashCompensation ?? [],
    new Date()
  );
  return (
    cashValueOf(CashCompType.COMMISSION, activeCashComp) ??
    zero(defaultCurrencyCode)
  );
}

export function CondensedTableCurrentTargetCommissionCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { defaultCurrencyCode } = useCurrencies();
  return (
    <AssembleTruncatedTypography align="right">
      {getSimpleCashLabel(
        getCommission(
          employee,
          (employee.subject.activeEmployment?.payCurrencyCode ??
            defaultCurrencyCode) as CurrencyCode
        )
      )}
    </AssembleTruncatedTypography>
  );
}

CondensedTableCurrentTargetCommissionCell2.fragments = {
  participant: gql`
    fragment CondensedTableCurrentTargetCommissionCell2_participant on CompCycleParticipant {
      subject {
        id
        activeEmployment {
          id
          payCurrencyCode
        }
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          type
          activeAt
          unit
          annualCashEquivalent
          hourlyCashEquivalent
          percentOfSalary
        }
      }
    }
  `,
};

CondensedTableCurrentTargetCommissionCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.CURRENT_TARGET_COMMISSION
);
CondensedTableCurrentTargetCommissionCell2.id =
  ColumnIds.CURRENT_TARGET_COMMISSION;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableCurrentTargetCommissionCell2,
  Header: CondensedTableCurrentTargetCommissionCell2.Header,
  id: CondensedTableCurrentTargetCommissionCell2.id,
  width: LARGE_COL_WIDTH,
};

CondensedTableCurrentTargetCommissionCell2.column = column;
CondensedTableCurrentTargetCommissionCell2.ordering = ({
  defaultCurrencyCode,
  currencies,
}: {
  defaultCurrencyCode: CurrencyCode;
  currencies: Map<CurrencyCode, Currency>;
}) =>
  contramap((e: Participant) => {
    const commission = getCommission(e, defaultCurrencyCode);

    const currentCurrency = currencies.get(commission.currency);
    const defaultCurrency = currencies.get(defaultCurrencyCode);

    if (currentCurrency == null || defaultCurrency == null) {
      return 0;
    }

    return exchangeFromTo(commission, currentCurrency, defaultCurrency).value;
  });
