import { gql } from "@apollo/client";
import { exchangeFromTo } from "@asmbl/shared/currency";
import { formatCurrency, moneyComparator, zero } from "@asmbl/shared/money";
import { contramap, nativeComparator } from "@asmbl/shared/sort";
import { getFormattedDate } from "@asmbl/shared/time";
import { TableBody, TableRow } from "@material-ui/core";
import { useCurrencies } from "src/components/CurrenciesContext";
import { useSort } from "src/components/SortableTable";
import {
  WireTable,
  WireTableCell,
  WireTableHead,
  WireTableHeaderCell,
} from "src/components/Table/WireTable";
import {
  ActualVariableCashComp_employee as Employee,
  ActualVariableCashComp_table as ActualVariableCashCompensation,
} from "src/__generated__/graphql";

type Props = {
  employee: Employee;
};

export function ActualVariableCashCompensationTable({
  employee,
}: Props): JSX.Element | null {
  const { currencies, defaultCurrency } = useCurrencies();

  const {
    sortedArray: sortedActualCashComps,
    orderBy,
    order,
    handleRequestSort,
  } = useSort<ActualVariableCashCompensation>(
    employee.actualVariableCashCompensations ?? [],
    "paymentDate",
    "desc",
    {
      paymentDate: contramap(
        (cashComp) => new Date(cashComp.paymentDate).getTime(),
        nativeComparator
      ),
      value: contramap((cashComp) => {
        const cashCompCurrency = currencies.get(cashComp.value.currency);
        return cashCompCurrency != null
          ? exchangeFromTo(cashComp.value, cashCompCurrency, defaultCurrency)
          : zero(defaultCurrency.code);
      }, moneyComparator),
      type: contramap((cashComp) => cashComp.type, nativeComparator),
      subType: contramap((cashComp) => cashComp.subType, nativeComparator),
    }
  );

  return (
    <WireTable data-cy="actual-variable-cash-compensation-table">
      <WireTableHead>
        <TableRow>
          <WireTableHeaderCell
            cellTitle="Payment Date"
            orderByField="paymentDate"
            order={order}
            isSelected={orderBy === "paymentDate"}
            handleRequestSort={handleRequestSort}
          />
          <WireTableHeaderCell
            cellTitle="Type"
            orderByField="type"
            order={order}
            isSelected={orderBy === "type"}
            handleRequestSort={handleRequestSort}
          />
          <WireTableHeaderCell
            cellTitle="Subtype"
            orderByField="subType"
            order={order}
            isSelected={orderBy === "subType"}
            handleRequestSort={handleRequestSort}
          />
          <WireTableHeaderCell
            cellTitle="Value"
            orderByField="value"
            order={order}
            isSelected={orderBy === "value"}
            handleRequestSort={handleRequestSort}
          />
        </TableRow>
      </WireTableHead>
      <TableBody>
        {sortedActualCashComps.map((cashComp) => {
          return (
            <TableRow key={`cash-comp-${cashComp.id}`}>
              <WireTableCell>
                {getFormattedDate(new Date(cashComp.paymentDate))}
              </WireTableCell>
              <WireTableCell>
                {cashComp.type === "COMMISSION" ? "Commission" : "Bonus"}
              </WireTableCell>
              <WireTableCell>{cashComp.subType}</WireTableCell>
              <WireTableCell>{formatCurrency(cashComp.value)}</WireTableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </WireTable>
  );
}

const CASH_COMP_FRAGMENT = gql`
  fragment ActualVariableCashComp_table on ActualVariableCashCompensation {
    id
    paymentDate
    value
    type
    subType
  }
`;

ActualVariableCashCompensationTable.fragments = {
  employee: gql`
    ${CASH_COMP_FRAGMENT}
    fragment ActualVariableCashComp_employee on Employee {
      actualVariableCashCompensations {
        id
        ...ActualVariableCashComp_table
      }
    }
  `,
};
