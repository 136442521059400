import { plainDateToLocaleString } from "@asmbl/shared/time";
import DateFnsUtils from "@date-io/date-fns";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ReactNode } from "react";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { CalendarIcon } from "src/components/AssembleIcons/Small/CalendarIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { CompCyclePhaseData } from "src/components/CompCycle/CompCycleWizard/types";
import { getCalendarStart } from "src/components/CompCycleOrganizationChart/PhaseSelectionButtonBar";
import { GRAY_4 } from "src/theme";
import { DeletePhaseModalButton } from "./DeletePhaseModalButton";
import { getCalendarMaxDate } from "./PhaseSidebarCard";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: "400px",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(4),
    gap: theme.spacing(3),
  },
  dialogActions: {
    display: "flex",
    alignSelf: "flex-end",
    width: "100%",
    justifyContent: "space-between",
    paddingTop: 0,
  },
  datePickerInput: {
    width: "100%",
    display: "flex",
    flexShrink: 0,
    "& .MuiOutlinedInput-input": {
      padding: theme.spacing(1.5, 2),
    },
  },
  actionsContainer: {
    display: "flex",
    gap: theme.spacing(2),
  },
  managerContent: {
    maxHeight: theme.spacing(40),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    width: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  listItem: { paddingLeft: 0 },
  listItemText: { paddingLeft: "5px" },
  listItemInner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  listItemAdditionalText: { fontStyle: "italic", color: GRAY_4 },
}));

type Props = {
  phase: CompCyclePhaseData;
  previousPhase: CompCyclePhaseData | undefined;
  nextPhase: CompCyclePhaseData | undefined;
  children: ReactNode;
  closeModal: () => void;
  handlePhaseDelete: () => unknown;
  handleSave: () => unknown;
  isSettingsView?: boolean;
  calendarDate: string;
  setCalendarDate: (date: string) => void;
};

export function EditPhaseDialog({
  phase,
  previousPhase,
  nextPhase,
  children,
  calendarDate,
  setCalendarDate,
  closeModal,
  handlePhaseDelete,
  handleSave,
  isSettingsView = false,
}: Props): JSX.Element {
  const classes = useStyles();

  const jsDateObject = new Date(calendarDate);
  const dateAsLocalObject = new Date(
    jsDateObject.getTime() + jsDateObject.getTimezoneOffset() * 60000
  );
  const minPhaseStartDate =
    previousPhase?.startDate !== undefined
      ? getCalendarStart(previousPhase.startDate, new Date())
      : new Date();

  const maxPhaseStartDate = getCalendarMaxDate(nextPhase?.startDate);

  return (
    <Dialog
      scroll="paper"
      maxWidth="md"
      open
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle>{`Edit Phase ${phase.phaseOrder} Details`}</DialogTitle>
      <DialogContent className={classes.managerContent}>
        {children}
      </DialogContent>
      <DialogContent className={classes.content}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            // force the component to remount if the phase date changes
            key={getCalendarStart(phase.startDate, new Date()).toDateString()}
            className={classes.datePickerInput}
            disablePast
            disableToolbar
            minDate={minPhaseStartDate}
            minDateMessage="Selected date already applies to another phase or is in the past."
            maxDate={maxPhaseStartDate}
            maxDateMessage="Phase dates cannot overlap."
            autoOk
            placeholder="Start date"
            label="Start date"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            orientation="landscape"
            value={dateAsLocalObject}
            onChange={(date) => {
              if (date !== null) {
                setCalendarDate(plainDateToLocaleString(date));
              }
            }}
            keyboardIcon={<CalendarIcon inherit />}
          />
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions className={classes.dialogActions} disableSpacing>
        {!isSettingsView && (
          <DeletePhaseModalButton handleChange={handlePhaseDelete} />
        )}
        <div className={classes.actionsContainer}>
          <AssembleButton
            size="medium"
            variant="text"
            label="Cancel"
            onClick={closeModal}
          />
          <AssembleButton
            size="medium"
            variant="contained"
            label="Save Settings"
            onClick={handleSave}
            disabled={
              maxPhaseStartDate &&
              dateAsLocalObject.getTime() >
                new Date(maxPhaseStartDate).getTime()
            }
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}

type ListItemProps = {
  key: number;
  isAssignedToAnotherPhase: boolean;
  isAssignedToThisPhase: boolean;
  isSettingsView: boolean;
  listItemName: string;
  assignedPhaseOrder: number | undefined;
  handleChange: () => void;
};

export function DialogListItem({
  key,
  isAssignedToAnotherPhase,
  isAssignedToThisPhase,
  isSettingsView,
  listItemName,
  assignedPhaseOrder,
  handleChange,
}: ListItemProps): JSX.Element {
  const classes = useStyles();
  return (
    <ListItem
      key={key}
      className={classes.listItem}
      disabled={isAssignedToAnotherPhase || isSettingsView}
    >
      <Checkbox
        id={`layer-${key}`}
        edge="start"
        defaultChecked={isAssignedToThisPhase}
        disabled={isAssignedToAnotherPhase || isSettingsView}
        onChange={handleChange}
      />
      <ListItemText className={classes.listItemText}>
        <label htmlFor={`layer-${key}`}>
          <div className={classes.listItemInner}>
            <AssembleTypography variant="productButtonMedium">
              {listItemName}
            </AssembleTypography>
            {isAssignedToThisPhase && (
              <AssembleTypography
                className={classes.listItemAdditionalText}
                variant="productButtonMedium"
              >
                Current Assignment
              </AssembleTypography>
            )}
            {isAssignedToAnotherPhase && (
              <AssembleTypography
                className={classes.listItemAdditionalText}
                variant="productButtonMedium"
              >
                Assigned to Phase {assignedPhaseOrder}
              </AssembleTypography>
            )}
          </div>
        </label>
      </ListItemText>
    </ListItem>
  );
}
