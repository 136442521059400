import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const LocationIcon = ({
  height = "16px",
  width = "16px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Location"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill"
        d="M8 9C9.38071 9 10.5 7.88071 10.5 6.5C10.5 5.11929 9.38071 4 8 4C6.61929 4 5.5 5.11929 5.5 6.5C5.5 7.88071 6.61929 9 8 9Z"
      />
      <path
        className="stroke"
        d="M13.5 5.97041C13.5 10.4438 8 15 8 15C8 15 2.5 10.4438 2.5 5.97041C2.5 4.65218 3.07946 3.38793 4.11091 2.4558C5.14236 1.52367 6.54131 1 8 1C9.45869 1 10.8576 1.52367 11.8891 2.4558C12.9205 3.38793 13.5 4.65218 13.5 5.97041Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
