import { gql } from "@apollo/client";
import { basicComparator, contramap } from "@asmbl/shared/sort";
import {
  makeStyles,
  Paper,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import { useState } from "react";
import { CorrectionCostToMoveEmployeesTable_employeeCostToMove as CorrectionEmployeeCostToMove } from "src/__generated__/graphql";
import {
  WireTable,
  WireTableHead,
  WireTableHeaderCell,
} from "src/components/Table/WireTable";
import { CashBandName } from "src/constants";
import { EmployeeRecommendationMap } from "../CorrectionCostToMoveInnerPage";
import { CorrectionCostToMoveEmployeesTableRow } from "./CorrectionCostToMoveEmployeesTableRow";
import { CorrectionCostToMoveEmployeesTableHeader } from "./CorrectionCostToMoveEmployeeTableHeader";
import { CorrectionCostToMoveEmptyTable } from "./CorrectionCostToMoveEmptyTableState";

const useStyles = makeStyles(() => ({
  tableRoot: { border: "none" },
}));

type Props = {
  employeeCTMs: CorrectionEmployeeCostToMove[];
  filter: { [key: string]: number[] | null | undefined };
  targetBandPoint: string;
  cashCompComponents: string[];
  selectedEmployees: EmployeeRecommendationMap;
  setSelectedEmployees: (id: number) => void;
  selectAllHandler: (selected: boolean) => void;
};

export const CorrectionCostToMoveEmployeesTable = ({
  employeeCTMs,
  filter,
  targetBandPoint,
  cashCompComponents,
  selectedEmployees,
  setSelectedEmployees,
  selectAllHandler,
}: Props) => {
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [sortBy, setSortBy] = useState<string>("convertedCTM");
  const classes = useStyles();

  const setSort = (field: string) => {
    if (sortBy === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortDirection("asc");
      setSortBy(field);
    }
  };

  let sortedEmployees = employeeCTMs.slice().sort(
    contramap((employeeCTM) => {
      if (sortBy === "CTM") {
        return employeeCTM.totalCostToMove?.value ?? 0;
      } else if (sortBy.includes("costToMove")) {
        const updatedSortBy = sortBy.slice().replace("costToMove", "");
        return (
          employeeCTM.costToMoveByComp.find(
            (ctm) => ctm.compType === updatedSortBy
          )?.convertedCostToMove.value ?? 0
        );
      } else if (sortBy === "targetBandPoint") {
        return employeeCTM.totalTargetBandPoint?.value ?? 0;
      } else if (cashCompComponents.includes(sortBy)) {
        return (
          employeeCTM.costToMoveByComp.find((ctm) => ctm.compType === sortBy)
            ?.convertedSpend.value ?? 0
        );
      } else if (sortBy === "total") {
        return employeeCTM.totalSpend.value;
      } else if (sortBy === "email") {
        return employeeCTM.employee.email;
      } else if (sortBy === "name") {
        return employeeCTM.employee.displayName;
      } else if (sortBy === "comparatio") {
        return employeeCTM.TCCComparatio;
      }

      return employeeCTM.totalCostToMove?.value ?? 0;
    }, basicComparator)
  );

  if (sortDirection === "desc") sortedEmployees = sortedEmployees.reverse();

  return (
    <TableContainer component={Paper} elevation={0}>
      <CorrectionCostToMoveEmployeesTableHeader
        filter={filter}
        targetBandPoint={targetBandPoint}
        selectAllHandler={selectAllHandler}
      />
      <WireTable className={classes.tableRoot}>
        <WireTableHead>
          <WireTableHeaderCell
            variant="head"
            cellTitle="Name"
            orderByField="name"
            order={sortDirection}
            isSelected={sortBy === "name"}
            handleRequestSort={setSort}
          />
          <WireTableHeaderCell
            variant="head"
            cellTitle="Email"
            orderByField="email"
            order={sortDirection}
            isSelected={sortBy === "email"}
            handleRequestSort={setSort}
          />
          {cashCompComponents.map((comp, index) => {
            const compType = CashBandName[comp as keyof typeof CashBandName];
            return (
              <>
                <WireTableHeaderCell
                  variant="head"
                  cellTitle={compType}
                  orderByField={comp}
                  order={sortDirection}
                  isSelected={sortBy === comp}
                  handleRequestSort={() => setSort(comp)}
                  key={comp}
                />
                <WireTableHeaderCell
                  variant="head"
                  cellTitle={`${compType} Cost`}
                  orderByField={"costToMove"}
                  order={sortDirection}
                  isSelected={sortBy === `${comp}costToMove`}
                  handleRequestSort={() => setSort(`${comp}costToMove`)}
                  key={`${comp}${index}`}
                />
              </>
            );
          })}
          <WireTableHeaderCell
            variant="head"
            cellTitle="Total Cash Comp"
            orderByField="total"
            order={sortDirection}
            isSelected={sortBy === "total"}
            handleRequestSort={setSort}
          />
          <WireTableHeaderCell
            variant="head"
            cellTitle="Total Bandpoint Value"
            orderByField="targetBandPoint"
            order={sortDirection}
            isSelected={sortBy === "targetBandPoint"}
            handleRequestSort={setSort}
          />
          <WireTableHeaderCell
            variant="head"
            cellTitle="Total Cost To Bandpoint"
            orderByField="CTM"
            order={sortDirection}
            isSelected={sortBy === "CTM"}
            handleRequestSort={setSort}
          />
          <WireTableHeaderCell
            variant="head"
            cellTitle="TCC Compa-ratio"
            orderByField="comparatio"
            order={sortDirection}
            isSelected={sortBy === "comparatio"}
            handleRequestSort={setSort}
          />
        </WireTableHead>
        {sortedEmployees.length === 0 && <CorrectionCostToMoveEmptyTable />}
        <TableBody>
          {sortedEmployees.map((employeeCTM) => (
            <CorrectionCostToMoveEmployeesTableRow
              key={employeeCTM.employee.id}
              employeeCTM={employeeCTM}
              cashCompComponents={cashCompComponents}
              selectedEmployees={selectedEmployees}
              setSelectedEmployees={setSelectedEmployees}
            />
          ))}
        </TableBody>
      </WireTable>
    </TableContainer>
  );
};

CorrectionCostToMoveEmployeesTable.fragments = {
  employeeCostToMove: gql`
    ${CorrectionCostToMoveEmployeesTableRow.fragments.employeeCostToMove}
    fragment CorrectionCostToMoveEmployeesTable_employeeCostToMove on TotalEmployeeCostToMove {
      employee {
        id
        displayName
        email
      }
      costToMoveByComp {
        compType
        costToMove
        spend
        convertedSpend
        convertedCostToMove
      }
      totalSpend
      totalTargetBandPoint
      totalCostToMove
      TCCComparatio
      ...CorrectionCostToMoveEmployeesTableRow_employeeCostToMove
    }
  `,
};
