import { CurrencyCode } from "@asmbl/shared/constants";
import { Money } from "@asmbl/shared/money";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import {
  Benefit,
  BenefitsPackage,
  MAX_BENEFITS_PER_PACKAGE,
} from "../../../../models/Benefits";
import { rankBetween } from "../../../../models/Rank";
import { GRAY_6, GRAY_8, WHITE } from "../../../../theme";
import { BenefitInputRow } from "./BenefitInputRow";
import { TotalValue } from "./TotalValue";

const useStyles = makeStyles((theme) => ({
  card: {
    background: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px",
  },

  cardHeader: {
    padding: theme.spacing(1.5),
    borderBottom: `1px solid ${GRAY_6}`,
    display: "flex",
    justifyContent: "space-between",
  },

  cardFooter: {
    background: GRAY_8,
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    padding: theme.spacing(1, 7),
    justifyContent: "flex-start",
    width: "100%",
  },
}));

interface Props {
  selectedCurrency: CurrencyCode;
  benefitsPackage: BenefitsPackage;
  onAddBenefit: () => unknown;
  onChangeName: (benefit: Benefit, name: string) => unknown;
  onChangeValue: (benefit: Benefit, value: Money | null) => unknown;
  onChangeDescription: (benefit: Benefit, description: string) => unknown;
  onChangeRank: (benefit: Benefit, rank: string) => unknown;
  onDelete: (benefit: Benefit) => unknown;
}

export function BenefitsEditorList({
  selectedCurrency,
  benefitsPackage,
  onAddBenefit,
  onChangeName,
  onChangeValue,
  onChangeDescription,
  onChangeRank,
  onDelete,
}: Props): JSX.Element {
  const classes = useStyles();
  const benefits = benefitsPackage.benefits;

  const onDragEnd = (result: DropResult) => {
    const destination = result.destination?.index;
    if (destination === undefined) {
      return;
    }

    const droppedBenefit = benefits[result.source.index];
    const remainingBenefits = benefits.filter((b) => b !== droppedBenefit);
    const benefitBefore =
      destination === 0 ? undefined : remainingBenefits[destination - 1];
    const benefitAfter =
      destination === remainingBenefits.length
        ? undefined
        : remainingBenefits[destination];

    const newRank = rankBetween(benefitBefore?.rank, benefitAfter?.rank);

    onChangeRank(droppedBenefit, newRank);
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.cardHeader}>
        <Typography variant="h6">Benefits</Typography>
        <TotalValue benefitsPackage={benefitsPackage} />
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              ref={(el: HTMLElement | null) => {
                provided.innerRef(el);
              }}
            >
              {benefits.map((benefit, i) => (
                <BenefitInputRow
                  key={benefit.rank}
                  index={i}
                  selectedCurrency={selectedCurrency}
                  benefit={benefit}
                  onChangeName={onChangeName}
                  onChangeValue={onChangeValue}
                  onChangeDescription={onChangeDescription}
                  onDelete={onDelete}
                  hideDelete={benefits.length <= 3}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {benefits.length < MAX_BENEFITS_PER_PACKAGE ? (
        <Button
          className={classes.cardFooter}
          variant="text"
          onClick={onAddBenefit}
        >
          Add benefit...
        </Button>
      ) : (
        <Box className={classes.cardFooter} />
      )}
    </Box>
  );
}
