import { makeStyles, TableCell, TableRow } from "@material-ui/core";
import { formatNumber } from "accounting";
import clsx from "clsx";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import {
  Allocation,
  AllocationUnit,
  BudgetAllocation,
  isCompSubComponent,
  liftBudget,
} from "../../../../models/Budget";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { GRAY_3, GRAY_6 } from "../../../../theme";
import { AllocateBudgetsTooltip } from "./AllocateBudgetsTooltip";
import { ConnectorLines, IndentationCells } from "./Connectors";

const useStyles = makeStyles((theme) => ({
  row: {
    height: "1.5rem",

    "& td": {
      padding: 0,
    },
  },
  mainCell: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "inherit",
    border: "none",
  },
  labelText: {
    lineHeight: "1.4",
    fontSize: "0.75rem",
    fontWeight: "normal",
    letterSpacing: "-0.15px",
    color: GRAY_3,
    paddingLeft: "8px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "12px",
    borderLeft: `1px solid ${GRAY_6}`,
    height: "100%",
    width: "100%",
    borderBottom: `1px solid ${GRAY_6}`,
  },
  contentText: {
    lineHeight: "1.7",
    fontSize: "0.75rem",
    fontWeight: "normal",
    letterSpacing: "-0.25px",
    textOverflow: "ellipsis",
    color: GRAY_3,
  },
  budgetCell: {
    lineHeight: "1.7",
    fontSize: "0.75rem",
    fontWeight: "normal",
    letterSpacing: "-0.25px",
    textOverflow: "ellipsis",
    color: GRAY_3,

    "td&": {
      paddingRight: theme.spacing(2),
    },
  },
  groupedCell: {
    "td&": {
      borderRight: "none",
    },
  },
}));

type Props = {
  managerAllocation: BudgetAllocation;
  reportAllocations: BudgetAllocation[];
  subject: { name: string; isOrg: boolean };
  indentation: IndentationCells;
};

export function RemainingAllocationRow({
  managerAllocation,
  reportAllocations,
  subject,
  indentation,
}: Props): JSX.Element | null {
  const classes = useStyles();

  const remainingAllocation: BudgetAllocation = reportAllocations.reduce(
    liftBudget((a, b) => a - b),
    managerAllocation
  );

  const text = subject.isOrg
    ? `Total budget to allocate for ${subject.name}`
    : `Left for ${subject.name}'s requests`;

  const subcomponents = remainingAllocation.allocations.filter((x) =>
    isCompSubComponent(x.compComponent)
  );
  const parentBudgets = remainingAllocation.allocations.filter(
    (x) => !isCompSubComponent(x.compComponent)
  );

  return (
    <TableRow className={classes.row}>
      <TableCell className={classes.mainCell}>
        <ConnectorLines indentation={indentation} keyPrefix={subject.name} />
        <div className={classes.contentContainer}>
          <AllocateBudgetsTooltip subject={subject} />
          <AssembleTruncatedTypography className={classes.labelText}>
            {text}
          </AssembleTruncatedTypography>
        </div>
      </TableCell>
      <TableCell colSpan={2} />
      {subcomponents.map((subcomponent, index) => (
        <TableCell
          key={subcomponent.compComponent}
          align="right"
          className={clsx(classes.budgetCell, {
            [classes.groupedCell]: index !== subcomponents.length - 1,
          })}
        >
          {formatAllocation(subcomponent)}
        </TableCell>
      ))}
      {parentBudgets.map((allocation) => (
        <TableCell
          key={allocation.compComponent}
          align="right"
          className={classes.budgetCell}
        >
          {formatAllocation(allocation)}
        </TableCell>
      ))}
    </TableRow>
  );
}

function formatAllocation(allocation: Allocation): string {
  return allocation.value === null
    ? ""
    : allocation.unit === AllocationUnit.CASH
      ? getSimpleCashLabel(allocation.value)
      : formatNumber(allocation.value);
}
