import { gql, useMutation } from "@apollo/client";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import Dropzone, { DropzoneState } from "react-dropzone";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import {
  ImportOrganizationCsv,
  ImportOrganizationCsvVariables,
} from "../__generated__/graphql";
import { GRAY_4, GRAY_6, WHITE } from "../theme";

const useStyles = makeStyles((theme) => ({
  baseStyle: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
    borderWidth: 2,
    borderRadius: theme.shape.borderRadius,
    borderColor: GRAY_6,
    borderStyle: "dashed",
    background: WHITE,
    color: GRAY_4,
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
  },
  activeStyle: {
    borderColor: "#2196f3",
  },
  acceptStyle: {
    borderColor: "#00e676",
  },
  rejectStyle: {
    borderColor: "#ff1744",
  },
}));

type Props = {
  orgName: string;
};

export function OrgCsvUploader({ orgName }: Props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [updateOrg] = useMutation<
    ImportOrganizationCsv,
    ImportOrganizationCsvVariables
  >(OrgCsvUploader.mutation);

  const onComplete = (data: ImportOrganizationCsv) => {
    const {
      importOrgJobStructureWithBandsCsv: { successful, reason },
    } = data;
    if (successful) {
      enqueueSnackbar("Upload was a success!", {
        variant: "success",
        persist: true,
        action: (
          <AssembleButton
            size="small"
            variant="outlined"
            label="Dismiss"
            onClick={() => closeSnackbar()}
          />
        ),
      });
    } else {
      enqueueSnackbar(`Upload failed because ${reason as string}`, {
        variant: "error",
        persist: true,
        action: (
          <AssembleButton
            size="small"
            variant="outlined"
            label="Dismiss"
            onClick={() => closeSnackbar()}
          />
        ),
      });
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async () => {
        enqueueSnackbar("Uploading CSV now...");
        await updateOrg({
          variables: { data: reader.result as string },
          onCompleted: onComplete,
          context: {
            headers: {
              "Content-Type": "application/json",
            },
          },
        });
      };
      reader.readAsText(file);
    });
  };

  const getClassName = ({
    isDragActive,
    isDragAccept,
    isDragReject,
  }: DropzoneState) => {
    return [
      classes.baseStyle,
      isDragActive ? classes.activeStyle : "",
      isDragAccept ? classes.acceptStyle : "",
      isDragReject ? classes.rejectStyle : "",
    ].join(" ");
  };

  return (
    <Box mt={2} mb={2}>
      <Dropzone onDrop={onDrop} accept=".csv" maxFiles={1}>
        {(dropzoneState) => (
          <section>
            <Box
              {...dropzoneState.getRootProps()}
              className={getClassName(dropzoneState)}
            >
              <input {...dropzoneState.getInputProps()} />
              <Typography>{`Upload ${orgName}'s CSV here for mass edits`}</Typography>
            </Box>
          </section>
        )}
      </Dropzone>
    </Box>
  );
}

OrgCsvUploader.mutation = gql`
  mutation ImportOrganizationCsv($data: String!) {
    importOrgJobStructureWithBandsCsv(data: $data) {
      successful
      reason
    }
  }
`;
