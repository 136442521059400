import { gql } from "@apollo/client";
import { collapseAdjustedBands } from "@asmbl/shared/band";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { Divider, makeStyles, Paper } from "@material-ui/core";
import { memo } from "react";
import { CalendarIcon } from "src/components/AssembleIcons/Extra-Small/CalendarIcon";
import { ClockIcon } from "src/components/AssembleIcons/Extra-Small/ClockIcon";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import {
  TotalCashPopover_band as CashBand,
  TotalCashPopover_cash as CashCompensation,
  PayPeriodType,
  TotalCashPopover_position as Position,
} from "../../../__generated__/graphql";
import {
  getBandPointCashEquivalent,
  sortBandPoints,
} from "../../../models/BandPoint";
import {
  byCashCompType,
  TotalCash,
  zip,
} from "../../../models/CashCompensation";
import { GRAY_2, GRAY_4, GRAY_6, WHITE } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";
import { useCurrencies } from "../../CurrenciesContext";
import { LargeCompSlider } from "../LargeCompSlider";
import { Subcomponent } from "./Subcomponent";

const useStyles = makeStyles((theme) => ({
  paper: {
    background: WHITE,
    borderRadius: "10px",
    width: "100%",
    height: "fit-content",
  },
  tooltip: {
    padding: theme.spacing(2),
    borderRadius: "10px",
    width: "100%",
    height: "fit-content",
    boxShadow: "0px 15px 25px rgba(10, 36, 64, 0.08)",
    background: WHITE,
  },
  title: {
    width: "100%",
    color: GRAY_2,
    marginBottom: "1rem",
  },
  mainSlider: {
    marginBottom: "2rem",
  },
  positionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  positionTitle: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    gap: "4px",
  },
  subcomponentContainer: {
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    width: "100%",
    color: GRAY_6,
    marginTop: "11px",
    marginBottom: "11px",
  },
}));

type Props = {
  cash: TotalCash<CashCompensation>;
  bands: CashBand[];
  payTypeSelection: PayPeriodType;
  position?: Position;
  showBreakdown?: boolean;
  isHourly: boolean;
};

const TotalCashPopoverMemo = memo(function TotalCashPopover({
  cash,
  bands,
  position,
  payTypeSelection,
  showBreakdown = true,
  isHourly,
}: Props): JSX.Element {
  const classes = useStyles();
  const { currencies, defaultCurrency } = useCurrencies();
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();

  const showHourly =
    compStructure?.allowHourlyEmployees === true &&
    isEnabled(FeatureFlag.HourlyEmployees);
  const displayCompAsHourly =
    isHourly && payTypeSelection === PayPeriodType.HOURLY;
  const localCurrency = currencies.get(cash.currency) ?? defaultCurrency;

  const cashAndBands = zip(cash.subcomponents, bands);

  // Make sure that Salary is always displayed first
  const sortedCashAndBands = cashAndBands.sort(byCashCompType);

  const bandPoints = collapseAdjustedBands(bands).map((bp) => {
    const bpValue = getBandPointCashEquivalent(
      bp,
      defaultCurrency.code,
      displayCompAsHourly
    );
    return {
      ...bp,
      value: bpValue,
      currencyCode: bp.value.currencyCode,
    };
  });

  const sortedBandPoints = sortBandPoints(bandPoints);

  return (
    <Paper elevation={0} className={classes.paper}>
      <div className={classes.title}>
        <AssembleTypography variant="h6">
          Total Cash {showBreakdown ? "Breakdown" : "Compensation"}
        </AssembleTypography>
      </div>
      <div className={classes.mainSlider}>
        {position !== undefined && (
          <div className={classes.positionContainer}>
            <div className={classes.positionTitle}>
              {showHourly &&
                (isHourly ? (
                  <ClockIcon color={GRAY_4} />
                ) : (
                  <CalendarIcon color={GRAY_4} />
                ))}
              <AssembleTypography variant="productEyebrow" textColor={GRAY_4}>
                {position.name}
              </AssembleTypography>
            </div>
            <AssembleTypography variant="productExtraSmall" textColor={GRAY_4}>
              {`Level ${position.level}`}
            </AssembleTypography>
          </div>
        )}
        <LargeCompSlider
          value={displayCompAsHourly ? cash.hourlyTotal : cash.annualTotal}
          isHourly={displayCompAsHourly}
          valueLabel="Total"
          locationAdjustedBandPoints={sortedBandPoints}
        />
      </div>
      {showBreakdown && (
        <div className={classes.subcomponentContainer}>
          {sortedCashAndBands
            .filter(
              ({ cash, band }) =>
                // If employee has no cash comp for this type
                // and the band does not expect any, skip it
                cash ||
                band?.bandPoints.some(
                  (bp) =>
                    getBandPointCashEquivalent(
                      bp,
                      defaultCurrency.code,
                      isHourly
                    ).value > 0
                )
            )
            .map(({ type, cash, band }, index, arr) => (
              <div key={type}>
                <Subcomponent
                  type={type}
                  cash={cash}
                  band={band}
                  currency={localCurrency.code}
                />
                {index !== arr.length - 1 && (
                  <Divider className={classes.divider} />
                )}
              </div>
            ))}
        </div>
      )}
    </Paper>
  );
});

export const TotalCashPopover = Object.assign(TotalCashPopoverMemo, {
  fragments: {
    cash: gql`
      ${Subcomponent.fragments.cash}
      fragment TotalCashPopover_cash on CashCompensation2 {
        type
        ...Subcomponent_cash
      }
    `,
    band: gql`
      ${Subcomponent.fragments.band}
      fragment TotalCashPopover_band on AdjustedCashBand {
        name
        ...Subcomponent_band
      }
    `,
    position: gql`
      fragment TotalCashPopover_position on Position {
        name
        level
      }
    `,
  },
});
