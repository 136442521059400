import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { SubmittedReviewCard_review } from "../../../__generated__/graphql";
import { SubmittedCardHeader } from "./SubmittedCardHeader";
import { SubmittedCardNote } from "./SubmittedCardNote";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
  },
  note: {
    marginLeft: theme.spacing(3),
  },
  indentedReview: {
    marginLeft: theme.spacing(4),
  },
}));

type Props = {
  submittedReview: SubmittedReviewCard_review;
  finalApproversIds: number[];
  isMostRecentReview: boolean;
};

export function SubmittedReviewCard({
  submittedReview,
  finalApproversIds,
  isMostRecentReview,
}: Props): JSX.Element {
  const classes = useStyles();

  const { author, note, status } = submittedReview;
  const isFinalReviewer = finalApproversIds.includes(submittedReview.author.id);

  return (
    <div
      className={`${classes.root} ${
        isMostRecentReview ? "" : classes.indentedReview
      }`}
    >
      <SubmittedCardHeader
        author={author}
        submittedAt={new Date(submittedReview.submittedAt as string)}
        status={status}
        isFinalReviewer={isFinalReviewer}
      />
      <SubmittedCardNote
        className={classes.note}
        notes={[{ title: null, text: note }]}
      />
    </div>
  );
}

SubmittedReviewCard.fragments = {
  review: gql`
    ${SubmittedCardHeader.fragments.author}
    fragment SubmittedReviewCard_review on RecReview {
      submittedAt
      status
      note
      author {
        id
        ...SubmittedCardHeader_author
      }
    }
  `,
};
