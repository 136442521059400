import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const GiftIcon = ({
  height = "24px",
  width = "24px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Refer Someone to Assemble"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.9167 11H4.08333C3.48502 11 3 11.4477 3 12V16C3 16.5523 3.48502 17 4.08333 17H27.9167C28.515 17 29 16.5523 29 16V12C29 11.4477 28.515 11 27.9167 11Z"
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 17V26.9C27 27.1917 26.8841 27.4715 26.6778 27.6778C26.4715 27.8841 26.1917 28 25.9 28H6.1C5.80826 28 5.52847 27.8841 5.32218 27.6778C5.11589 27.4715 5 27.1917 5 26.9V17"
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 11V28"
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.06 9.485C20.545 11 16 11 16 11C16 11 16 6.455 17.515 4.94001C18.1178 4.33787 18.9351 3.99976 19.7871 4C20.6392 4.00024 21.4562 4.33882 22.0587 4.9413C22.6612 5.54378 22.9998 6.36085 23 7.21288C23.0002 8.06492 22.6621 8.88218 22.06 9.485V9.485ZM9.94001 9.485C11.455 11 16 11 16 11C16 11 16 6.455 14.485 4.94001C13.8822 4.33787 13.0649 3.99976 12.2129 4C11.3608 4.00024 10.5438 4.33882 9.9413 4.9413C9.33882 5.54378 9.00024 6.36085 9 7.21288C8.99976 8.06492 9.33787 8.88218 9.94001 9.485V9.485Z"
        className="stroke"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
