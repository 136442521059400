import { gql, useMutation, useQuery } from "@apollo/client";
import { ReactNode, createContext, useContext } from "react";
import {
  GetTableOrder,
  GetTableOrderVariables,
  NexusTableNameType,
  UpsertTableOrder,
  UpsertTableOrderVariables,
} from "src/__generated__/graphql";

export enum ColumnIds {
  NAME = "name-column",
  ACTIONS = "actions-column",
  ACTIVITY = "activity-column",
  STATUS = "status-column",
  NEW_POSITION = "new-position-column",
  NEW_POSITION_IF_CHANGED = "new-position-if-changed-column",
  PROMOTION = "promotion-column",
  CURRENT_BAND = "current-band-column",
  NEW_BAND = "new-band-column",
  CURRENT_SALARY_COMPA_RATIO = "current-salary-compa-ratio-column",
  NEW_SALARY_COMPA_RATIO = "new-salary-compa-ratio-column",
  CURRENT_TCC_COMPA_RATIO = "current-tcc-compa-ratio-column",
  NEW_TCC_COMPA_RATIO = "new-tcc-compa-ratio-column",
  CURR_SALARY = "curr-salary-column",
  SALARY_DOLLAR_CHANGE = "salary-dollar-change-column",
  SALARY_PERCENT_CHANGE = "salary-percent-change-column",
  NEW_SALARY = "new-salary-column",
  LAST_SALARY_CHANGE_DATE = "last-salary-change-date-column",
  LAST_SALARY_CHANGE_AMOUNT = "last-salary-change-amount-column",
  CURRENT_DEPARTMENT = "current-department-column",
  NEW_DEPARTMENT = "new-department-column",
  CURRENT_LADDER = "current-ladder-column",
  NEW_LADDER = "new-ladder-column",
  CURRENT_POSITION = "current-position-column",
  CURRENT_LEVEL = "current-level-column",
  NEW_LEVEL = "new-level-column",
  MANAGER = "manager-column",
  PERF_RATING = "perf-rating-column",
  TENURE = "tenure-column",
  LOCATION_GROUP = "location-group-column",
  SALARY_MARKET = "salary-market-column",
  SALARY_MERIT = "salary-merit-column",
  SALARY_MERIT_PERCENT = "salary-merit-percent-column",
  SALARY_MERIT_GUIDANCE = "salary-merit-guidance-column",
  SALARY_MERIT_GUIDANCE_DIFF = "salary-merit-guidance-difference-column",
  SALARY_PROMO = "salary-promo-column",
  CURRENT_TARGET_CASH = "current-target-cash-column",
  NEW_TARGET_CASH = "new-target-cash-column",
  ACTUAL_RECURRING_BONUS = "actual-recurring-bonus-column",
  BONUS = "bonus-column",
  BONUS_PERCENT = "bonus-percent-change-column",
  EQUITY_VALUE = "new-equity-value-column",
  EQUITY_UNITS = "new-equity-units-column",
  EQUITY_BAND_POINT = "equity-band-point-column",
  CURRENT_EQUITY_UNITS = "current-equity-units-column",
  CURRENT_EQUITY_VALUE = "current-equity-value-column",
  CURRENT_TARGET_COMMISSION = "current-target-commission-column",
  CURRENT_TARGET_COMMISSION_PERCENT = "current-target-commission-percent-column",
  CURRENT_TARGET_RECURRING_BONUS = "current-target-recurring-bonus-column",
  CURRENT_TARGET_RECURRING_BONUS_PERCENT = "current-target-recurring-bonus-percent-column",
  NEW_TARGET_COMMISSION = "new-target-commission-column",
  NEW_TARGET_COMMISSION_PERCENT = "new-target-commission-percent-column",
  NEW_TARGET_RECURRING_BONUS = "new-target-recurring-bonus-column",
  NEW_TARGET_RECURRING_BONUS_PERCENT = "new-target-recurring-bonus-percent-column",
}

export const ColumnIdsToHeaders: Map<string, string> = new Map([
  [ColumnIds.NAME, "Name"],
  [ColumnIds.ACTIONS, "Actions"],
  [ColumnIds.ACTIVITY, "Activity"],
  [ColumnIds.STATUS, "Status & Activity"],
  [ColumnIds.NEW_POSITION_IF_CHANGED, "New Position (If Changed)"],
  [ColumnIds.PROMOTION, "Position Change"],
  [ColumnIds.CURRENT_BAND, "Current Band Position"],
  [ColumnIds.NEW_BAND, "New Band Position"],
  [ColumnIds.CURRENT_SALARY_COMPA_RATIO, "Current Salary Compa-Ratio"],
  [ColumnIds.NEW_SALARY_COMPA_RATIO, "New Salary Compa-Ratio"],
  [ColumnIds.CURRENT_TCC_COMPA_RATIO, "Current TCC Compa-Ratio"],
  [ColumnIds.NEW_TCC_COMPA_RATIO, "New TCC Compa-Ratio"],
  [ColumnIds.CURR_SALARY, "Current Salary"],
  [ColumnIds.SALARY_DOLLAR_CHANGE, "Salary Change ($)"],
  [ColumnIds.SALARY_PERCENT_CHANGE, "Salary Change (%)"],
  [ColumnIds.NEW_SALARY, "New Salary"],
  [ColumnIds.LAST_SALARY_CHANGE_DATE, "Last Salary Change Date"],
  [ColumnIds.LAST_SALARY_CHANGE_AMOUNT, "Last Salary Change Amount"],
  [ColumnIds.CURRENT_DEPARTMENT, "Department Pre-Cycle"],
  [ColumnIds.NEW_DEPARTMENT, "Department Post-Cycle"],
  [ColumnIds.CURRENT_LADDER, "Ladder Pre-Cycle"],
  [ColumnIds.NEW_LADDER, "Ladder Post-Cycle"],
  [ColumnIds.CURRENT_POSITION, "Position Pre-Cycle"],
  [ColumnIds.NEW_POSITION, "Position Post-Cycle"],
  [ColumnIds.CURRENT_LEVEL, "Level Pre-Cycle"],
  [ColumnIds.NEW_LEVEL, "Level Post-Cycle"],
  [ColumnIds.MANAGER, "Manager"],
  [ColumnIds.PERF_RATING, "Performance Rating"],
  [ColumnIds.TENURE, "Tenure"],
  [ColumnIds.LOCATION_GROUP, "Location Group"],
  [ColumnIds.SALARY_MARKET, "Market Adjustment ($)"],
  [ColumnIds.SALARY_MERIT, "Merit Adjustment ($)"],
  [ColumnIds.SALARY_MERIT_PERCENT, "Merit Adjustment (%)"],
  [ColumnIds.SALARY_MERIT_GUIDANCE, "Merit Guidance"],
  [ColumnIds.SALARY_MERIT_GUIDANCE_DIFF, "Merit Guidance Diff."],
  [ColumnIds.SALARY_PROMO, "Promotion Adjustment ($)"],
  [ColumnIds.CURRENT_TARGET_CASH, "Curr. Target Cash Compensation"],
  [ColumnIds.NEW_TARGET_CASH, "New Target Cash Compensation"],
  [ColumnIds.ACTUAL_RECURRING_BONUS, "Actual Recurring Bonus"],
  [ColumnIds.BONUS, "One-Time Bonus"],
  [ColumnIds.BONUS_PERCENT, "One-Time Bonus (% New Salary)"],
  [ColumnIds.EQUITY_VALUE, "New Equity ($)"],
  [ColumnIds.EQUITY_UNITS, "New Equity (#)"],
  [ColumnIds.EQUITY_BAND_POINT, "New Equity BP"],
  [ColumnIds.CURRENT_EQUITY_UNITS, "Current Equity (#)"],
  [ColumnIds.CURRENT_EQUITY_VALUE, "Current Equity ($)"],
  [ColumnIds.CURRENT_TARGET_COMMISSION, "Curr. Target Commission ($)"],
  [ColumnIds.CURRENT_TARGET_COMMISSION_PERCENT, "Curr. Target Commission (%)"],
  [
    ColumnIds.CURRENT_TARGET_RECURRING_BONUS,
    "Curr. Target Recurring Bonus ($)",
  ],
  [
    ColumnIds.CURRENT_TARGET_RECURRING_BONUS_PERCENT,
    "Curr. Target Recurring Bonus (%)",
  ],
  [ColumnIds.NEW_TARGET_COMMISSION, "New Target Commission ($)"],
  [ColumnIds.NEW_TARGET_COMMISSION_PERCENT, "New Target Commission (%)"],
  [ColumnIds.NEW_TARGET_RECURRING_BONUS, "New Target Recurring Bonus ($)"],
  [
    ColumnIds.NEW_TARGET_RECURRING_BONUS_PERCENT,
    "New Target Recurring Bonus (%)",
  ],
]);

const defaultHiddenColumns = [
  ColumnIds.CURRENT_EQUITY_VALUE,
  ColumnIds.CURRENT_EQUITY_UNITS,
  ColumnIds.CURRENT_TARGET_COMMISSION,
  ColumnIds.CURRENT_TARGET_COMMISSION_PERCENT,
  ColumnIds.CURRENT_TARGET_RECURRING_BONUS,
  ColumnIds.CURRENT_TARGET_RECURRING_BONUS_PERCENT,
  ColumnIds.NEW_TARGET_COMMISSION,
  ColumnIds.NEW_TARGET_COMMISSION_PERCENT,
  ColumnIds.NEW_TARGET_RECURRING_BONUS,
  ColumnIds.NEW_TARGET_RECURRING_BONUS_PERCENT,
  ColumnIds.NEW_POSITION_IF_CHANGED,
];

type ColumnOrderType = {
  columns: ColumnIds[];
  setColumns: (columns: ColumnIds[]) => unknown;
  hiddenColumns: ColumnIds[];
  setHiddenColumns: (column: ColumnIds[]) => unknown;
};

export const ColumnOrderContext = createContext<ColumnOrderType>({
  columns: Object.values(ColumnIds),
  setColumns: () => {
    // empty default
  },
  hiddenColumns: [],
  setHiddenColumns: () => {
    // empty default
  },
});

export const ColumnOrderProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { data } = useQuery<GetTableOrder, GetTableOrderVariables>(
    ColumnOrderProvider.query,
    {
      variables: {
        tableName: NexusTableNameType.COMP_CYCLE_PLAN,
      },
    }
  );

  const [upsertTableOrder] = useMutation<
    UpsertTableOrder,
    UpsertTableOrderVariables
  >(UPSERT_TABLE_ORDER, {
    update(cache, { data }) {
      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          tableOrder() {
            if (data == null) return;

            cache.writeFragment({
              data: data.upsertTableOrder,
              fragment: gql`
                fragment tableOrder on TableOrder {
                  id
                  columnOrder
                  hiddenColumns
                }
              `,
            });
          },
        },
      });
    },
  });

  const setColumns = async (newColumnOrder: ColumnIds[]) => {
    await upsertTableOrder({
      variables: {
        data: {
          id: data?.tableOrder?.id,
          hiddenColumns: data?.tableOrder ? data.tableOrder.hiddenColumns : [],
          columnOrder: newColumnOrder,
          tableName: NexusTableNameType.COMP_CYCLE_PLAN,
        },
      },
    });
  };

  const setHiddenColumns = async (newHiddenColumns: ColumnIds[]) => {
    await upsertTableOrder({
      variables: {
        data: {
          id: data?.tableOrder?.id,
          hiddenColumns: newHiddenColumns,
          columnOrder: data?.tableOrder
            ? data.tableOrder.columnOrder
            : Object.values(ColumnIds),
          tableName: NexusTableNameType.COMP_CYCLE_PLAN,
        },
      },
    });
  };

  const missingColumns = Object.values(ColumnIds).filter(
    (columnId) => !(data?.tableOrder?.columnOrder.includes(columnId) ?? false)
  );

  const tableOrderWithNewColumns = [
    ...(data?.tableOrder?.columnOrder ?? []),
    ...missingColumns,
  ] as ColumnIds[];

  const tableOrderWithoutDeprecatedColumns = tableOrderWithNewColumns.filter(
    (columnId) => Object.values(ColumnIds).includes(columnId)
  );

  const hiddenColumnsWithoutDeprecatedColumns =
    data?.tableOrder?.hiddenColumns.filter((columnId) =>
      Object.values(ColumnIds).includes(columnId as ColumnIds)
    ) as ColumnIds[] | null;

  return (
    <ColumnOrderContext.Provider
      value={{
        columns: tableOrderWithoutDeprecatedColumns,
        setColumns,
        hiddenColumns:
          hiddenColumnsWithoutDeprecatedColumns ?? defaultHiddenColumns,
        setHiddenColumns,
      }}
    >
      {children}
    </ColumnOrderContext.Provider>
  );
};

export const useColumnOrder = (): ColumnOrderType => {
  return useContext(ColumnOrderContext);
};

ColumnOrderProvider.query = gql`
  query GetTableOrder($tableName: NexusTableNameType!) {
    tableOrder(tableName: $tableName) {
      id
      columnOrder
      hiddenColumns
    }
  }
`;

export const UPSERT_TABLE_ORDER = gql`
  mutation UpsertTableOrder($data: TableOrderInput!) {
    upsertTableOrder(data: $data) {
      id
      columnOrder
      hiddenColumns
    }
  }
`;
