import {
  Box,
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { ReactChild } from "react";
import { GRAY_1, GRAY_2, GRAY_6, GRAY_8, theme, WHITE } from "../../../theme";
import { AssembleLink } from "../../AssembleLink";
import { AssembleTypography } from "../../AssembleTypography";

interface PoliciesRow {
  columnHeader: string;
  whoCanView: ReactChild;
  whoCanEdit: ReactChild;
}

const useStyles = makeStyles(() => ({
  tableHeader: {
    backgroundColor: GRAY_8,
    verticalAlign: "top",
  },
  tableColumnHeader: {
    color: GRAY_1,
    fontWeight: 500,
    marginTop: theme.spacing(2),
  },
  tableRow: {
    borderLeft: `1px solid ${GRAY_6}`,
    backgroundColor: WHITE,
    marginBottom: theme.spacing(1),
    color: GRAY_2,
    verticalAlign: "top",
  },
  tableLink: {
    fontWeight: 500,
    fontSize: "14px",
  },
  lowerParagraph: {
    marginTop: 0,
  },
}));

export function StandardPoliciesTableBody(): JSX.Element {
  const classes = useStyles();
  return (
    <TableBody>
      <StandardPoliciesTableRow
        columnHeader="Comp. Bands"
        whoCanView={
          <Box>
            <p>
              People with the <b>Full Access</b> role can see all compensation
              bands.
            </p>
            <p className={classes.lowerParagraph}>
              <AssembleLink
                to="/settings/permissions"
                className={classes.tableLink}
              >
                You can give other users access{" "}
              </AssembleLink>
              to the bands for specific departments, ladders or positions in the
              People Permissions view.
            </p>
          </Box>
        }
        whoCanEdit={
          <p>
            Only people with the <b>Full Access</b> role can change compensation
            bands.
          </p>
        }
      />
      <StandardPoliciesTableRow
        columnHeader="Job Architecture"
        whoCanView={
          <Box>
            <p>
              <b>Everyone</b> can view the description of each department,
              ladder, and level. This access doesn't include compensation bands.
            </p>
            <p className={classes.lowerParagraph}>
              Only people with the <b>Full Access</b> role can create, change or
              delete departments, ladders, or positions.
            </p>
          </Box>
        }
        whoCanEdit={
          <p>
            Only people with the <b>Full Access</b> role can create, change, or
            delete departments, ladders, or positions.
          </p>
        }
      />
      <StandardPoliciesTableRow
        columnHeader="Illustrative Offers"
        whoCanView={
          <p>
            People with the <b>Full Access</b> role can see every Illustrative
            Offer that's been created in Assemble. People with the{" "}
            <b>Recruiter</b> role can see every Illustrative Offer they created
            themselves.
          </p>
        }
        whoCanEdit={
          <p>
            Only people with the <b>Full Access</b> or <b>Recruiter</b> roles
            can create Illustrative Offers.
          </p>
        }
      />
      <StandardPoliciesTableRow
        columnHeader="Comp. Philosophy"
        whoCanView={
          <p>
            <b>Everyone</b> can view the Compensation Philosophy.
          </p>
        }
        whoCanEdit={
          <p>
            Only people with the <b>Full Access</b> role can edit the
            Compensation Philosophy.
          </p>
        }
      />
      <StandardPoliciesTableRow
        columnHeader="People Permissions"
        whoCanView={
          <p>
            Only people with the <b>Full Access</b> or <b>System Admin</b> roles
            can see users or change who has access to what.
          </p>
        }
        whoCanEdit={
          <p>
            Only people with the <b>Full Access</b> or <b>System Admin</b> roles
            can add users or change who has access to what.
          </p>
        }
      />
    </TableBody>
  );
}

function StandardPoliciesTableRow({
  columnHeader,
  whoCanEdit,
  whoCanView,
}: PoliciesRow): JSX.Element {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell variant="head" className={classes.tableHeader}>
        <AssembleTypography
          variant="productTableHeader"
          className={classes.tableColumnHeader}
        >
          {columnHeader}
        </AssembleTypography>
      </TableCell>
      <TableCell className={classes.tableRow}>
        <AssembleTypography variant="body2">{whoCanView}</AssembleTypography>
      </TableCell>
      <TableCell className={classes.tableRow}>
        <AssembleTypography variant="body2">{whoCanEdit}</AssembleTypography>
      </TableCell>
    </TableRow>
  );
}
