import { Currency } from "@asmbl/shared/currency";
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useExcludedBandNames } from "../../../pages/LadderDetail/ExcludedBandNameContext";
import { GRAY_6, PURPLE_1, WHITE } from "../../../theme";
import { Range } from "../../../utils";
import { AssembleTypography } from "../../AssembleTypography";
import BandVisualizationHeader from "../../BandVisualization/BandVisualizationHeader";
import { CompaRatioTooltip } from "./ComparatioTooltip";

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    background: WHITE,
    alignItems: "center",
  },
  bandHeaderCell: {
    borderLeft: `1px solid ${GRAY_6}`,
  },
  bandHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  checkBox: {
    color: PURPLE_1,
  },
  bandVisualizationHeaderCell: {
    top: "40px",
    padding: 0,
    height: "14px",
  },
  compaRatioCell: {
    display: "flex",
    alignItems: "center",
  },
}));

type Props = {
  bandNames: { name: string }[];
  selectedCurrency: Currency;
  totalCompRange: Range;
  toggleBandExclusion: (bandName: string) => unknown;
};

export function CompBandTableHeader({
  bandNames,
  selectedCurrency,
  totalCompRange,
  toggleBandExclusion,
}: Props): JSX.Element {
  const classes = useStyles();
  const { excludedBandNames } = useExcludedBandNames();

  const shouldDisableToggle =
    bandNames.filter((band) => !excludedBandNames.has(band.name)).length <= 1;

  return (
    <TableHead>
      <TableRow>
        <TableCell
          className={classes.tableHeaderCell}
          variant="head"
          width="28%"
        >
          <AssembleTypography variant="productTableHeader" noWrap>
            Position
          </AssembleTypography>
        </TableCell>
        <TableCell
          className={classes.tableHeaderCell}
          variant="head"
          width="5%"
        >
          <AssembleTypography variant="productTableHeader" noWrap>
            Level
          </AssembleTypography>
        </TableCell>
        <TableCell
          className={classes.tableHeaderCell}
          align="left"
          variant="head"
          width="12%"
        >
          <div className={classes.compaRatioCell}>
            <AssembleTypography variant="productTableHeader" noWrap>
              Compa-Ratio
            </AssembleTypography>
            <CompaRatioTooltip />
          </div>
        </TableCell>
        <TableCell
          className={clsx(classes.tableHeaderCell, classes.bandHeaderCell)}
          variant="head"
          width="55%"
          colSpan={2}
        >
          <div className={classes.bandHeader}>
            <AssembleTypography variant="productTableHeader" noWrap>
              Band
            </AssembleTypography>
            {bandNames.length > 1 && (
              <div>
                {bandNames.map(({ name }) => (
                  <FormControlLabel
                    key={name}
                    control={
                      <Checkbox
                        className={classes.checkBox}
                        size="small"
                        checked={!excludedBandNames.has(name)}
                        onChange={() => toggleBandExclusion(name)}
                        disabled={
                          // If this is the last one, cannot uncheck it
                          !excludedBandNames.has(name) && shouldDisableToggle
                        }
                      />
                    }
                    label={
                      <Typography variant="body2" noWrap>
                        {name}
                      </Typography>
                    }
                  />
                ))}
              </div>
            )}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          className={classes.bandVisualizationHeaderCell}
          colSpan={3}
        />
        <TableCell className={classes.bandVisualizationHeaderCell} colSpan={1}>
          <BandVisualizationHeader
            totalCompRange={totalCompRange}
            height={14}
            selectedCurrency={selectedCurrency}
          />
        </TableCell>
        <TableCell
          className={classes.bandVisualizationHeaderCell}
          colSpan={1}
        />
      </TableRow>
    </TableHead>
  );
}
