import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { capitalize } from "src/utils";
import SequentialApprovalsIcon from "../../../assets/svgs/illustrations/sequential-approvals-2.svg?react";
import { AssembleButton } from "../../AssembleButton/AssembleButton";
import { AssembleTypography } from "../../AssembleTypography";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
  actions: { padding: theme.spacing(0) },
}));

type CreateProps = {
  variant: "create";
  isOpen: boolean;
  onPrevious: () => void;
  onContinue: () => void;
};

type EditProps = {
  variant: "edit";
  isOpen: boolean;
  onContinue: () => void;
};

type Props = CreateProps | EditProps;

export function CreateOrEditPhasesModal({
  variant,
  isOpen,
  onContinue,
  ...props
}: Props): JSX.Element {
  const classes = useStyles();
  const onPrevious =
    variant === "create"
      ? ((props as CreateProps).onPrevious as () => void)
      : undefined;

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <AssembleTypography variant="productHeader">
          Let's {variant} your cycle approval phases.
        </AssembleTypography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <AssembleTypography variant="productParagraphMedium">
            {variant === "create"
              ? `Create phases and add the managers you want to participate in each phase.`
              : `Edit phases to include the managers you want to participate in each phase.`}
          </AssembleTypography>
        </DialogContentText>
        <div className={classes.content}>
          <SequentialApprovalsIcon />
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {variant === "create" && (
          <AssembleButton
            size="small"
            variant="outlined"
            label="Back"
            onClick={onPrevious}
          />
        )}
        <AssembleButton
          size="small"
          variant="contained"
          label={`${capitalize(variant)} Your Phases`}
          onClick={onContinue}
        />
      </DialogActions>
    </Dialog>
  );
}
