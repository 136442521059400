import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const PushPinIcon = ({
  height = "16px",
  width = "16px",
  color = "#000000",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Push Pin"
      viewBox="0 0 16 16"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill"
        d="M6.9873 14.7197C6.9873 15.1339 7.32309 15.4697 7.7373 15.4697C8.15152 15.4697 8.4873 15.1339 8.4873 14.7197L6.9873 14.7197ZM6.9873 8.71973L6.9873 14.7197L8.4873 14.7197L8.4873 8.71973L6.9873 8.71973Z"
      />
      <circle
        cx="7.7373"
        cy="4.80542"
        r="3.5"
        strokeWidth="1.5"
        stroke={color}
      />
      <path
        d="M6.7373 4.80542C6.7373 4.25314 7.18502 3.80542 7.7373 3.80542"
        strokeWidth="1.5"
        strokeLinecap="round"
        stroke={color}
      />
    </svg>
  </ColorableSVG>
);
