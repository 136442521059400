import { TextField, TextFieldProps } from "@material-ui/core";
import NumberFormat, { NumberFormatProps } from "react-number-format";

type Props = NumberFormatProps<Partial<TextFieldProps>> & {
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
};

/**
 * A numerical input component that merges the best of TextField and
 * NumberFormat.
 * Typical controlled usage is to use `onValueChange` and `value`.
 * For full documentation of supported number format types, see https://github.com/s-yadav/react-number-format
 *
 * @example
 * <NumberInput
 *  value={value}
 *  onValueChange={({fixedValue}) => setValue(fixedValue)}
 * />
 */
export function NumberInput({
  startAdornment,
  endAdornment,
  thousandSeparator = true,
  ...numberFormatProps
}: Props): JSX.Element {
  return (
    <NumberFormat<TextFieldProps>
      variant="outlined"
      {...numberFormatProps}
      customInput={TextField}
      InputProps={{
        ...numberFormatProps.InputProps,
        startAdornment,
        endAdornment,
      }}
      thousandSeparator={thousandSeparator}
    />
  );
}
