import { makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { AllocationUnit } from "../../../../models/Budget";
import { GRAY_1 } from "../../../../theme";
import {
  TextFieldCellWithDogEar,
  TextFieldCellWithDogEarProps,
} from "./TextFieldCellWithDogEar";

// GraphQL doesn't support sending numbers larger than 2^31, so we have to
// limit users from entering numbers larger than that.
const MAX_NUM = 2 ** 31 - 1;
const TOOLTIP_DELAY_MS = 500;

const useStyles = makeStyles(() => ({
  text: {
    color: GRAY_1,
    fontWeight: 400,
    fontSize: "0.8125rem",
    lineHeight: "1.55",
    letterSpacing: "-0.25px",
  },
  touched: { fontWeight: "bold" },
  tooltip: { margin: "-4px" },
}));

type Props = {
  defaultValue: number | null;
  onValueChange: (value: number | null) => unknown;
  hasUnpublishedChanges: boolean;
  testTag: string;
  inputVariant?: AllocationUnit;
};

export const BudgetNumberInput = ({
  defaultValue,
  onValueChange,
  hasUnpublishedChanges,
  testTag,
  inputVariant = AllocationUnit.CASH,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [formValue, setFormValue] = useState<number | null | undefined>(
    defaultValue
  );
  const { defaultCurrencyCode } = useCurrencies();

  const touched = formValue !== undefined && formValue !== defaultValue;

  return (
    <Tooltip
      title="Add Allocation"
      placement="top"
      enterDelay={TOOLTIP_DELAY_MS}
      enterNextDelay={TOOLTIP_DELAY_MS}
      classes={{ tooltip: classes.tooltip }}
    >
      <NumberFormat<TextFieldCellWithDogEarProps>
        data-cy={testTag}
        customInput={TextFieldCellWithDogEar}
        inputVariant={inputVariant}
        hasUnpublishedChanges={hasUnpublishedChanges}
        currency={defaultCurrencyCode}
        variant="outlined"
        thousandSeparator
        inputProps={{
          className: clsx(classes.text, {
            [classes.touched]: touched,
          }),
        }}
        // `null` represents an explicitly empty input, whereas `undefined`
        // defers to the default.
        value={formValue === undefined ? defaultValue : formValue}
        onValueChange={(values) => setFormValue(values.floatValue ?? null)}
        onBlur={() => {
          if (formValue !== undefined && defaultValue !== formValue) {
            onValueChange(formValue);
          }
          setFormValue(undefined);
        }}
        allowNegative={false}
        isAllowed={(n) => n.floatValue === undefined || n.floatValue <= MAX_NUM}
        decimalScale={0}
      />
    </Tooltip>
  );
};
