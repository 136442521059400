import { cx } from "@emotion/css";
import { makeStyles, Typography } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";
import {
  GRAY_1,
  GRAY_6,
  SPLASH_BLUE,
  SPLASH_BLUE_BG,
  SPLASH_CYAN,
  SPLASH_CYAN_BG,
  SPLASH_ORANGE,
  SPLASH_ORANGE_BG,
  SPLASH_PURPLE,
  SPLASH_PURPLE_BG,
} from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";

type Props = {
  icon: JSX.Element;
  label: string;
  info: string;
  color: Color;
  className?: string;
};

type Color = "purple" | "orange" | "blue" | "cyan" | "gray";

const COLORS: Record<Color, CSSProperties> = {
  purple: {
    color: SPLASH_PURPLE,
    background: SPLASH_PURPLE_BG,
  },
  orange: {
    color: SPLASH_ORANGE,
    background: SPLASH_ORANGE_BG,
  },
  blue: {
    color: SPLASH_BLUE,
    background: SPLASH_BLUE_BG,
  },
  cyan: {
    color: SPLASH_CYAN,
    background: SPLASH_CYAN_BG,
  },
  gray: {
    color: GRAY_1,
    background: GRAY_6,
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: "1 1 20%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "stretch",
  },
  info: {
    margin: theme.spacing(0, 5, 0, 2),
  },
  infoValue: {
    maxWidth: "14rem",
    textOverflow: "ellipsis",
  },
}));

/**
 * A component with a colorful icon, a label, and a simple line of text.
 * This generic version serves as the basis for other, more data-specific
 * components, such as those for an Employee's manager, location, tenure, etc.
 */
export function BubbleInfo({
  icon,
  label,
  info,
  color,
  className,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      <Bubble icon={icon} color={color} />
      <div className={classes.info}>
        <AssembleTypography variant="productEyebrow" color="textSecondary">
          {label}
        </AssembleTypography>
        <Typography
          data-cy={`${label}-bubble`}
          variant="body1"
          noWrap
          className={classes.infoValue}
        >
          {info}
        </Typography>
      </div>
    </div>
  );
}
const useBubbleStyles = makeStyles(() => ({
  bubble: {
    borderRadius: "50%",
    flex: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "2.5rem",
    width: "2.5rem",
  },
  small: {
    height: "1.5rem",
    width: "1.5rem",
  },
  ...COLORS,
}));

export function Bubble({
  icon,
  color,
  small,
}: {
  icon: JSX.Element;
  color: Color;
  small?: boolean;
}): JSX.Element {
  const classes = useBubbleStyles();

  return (
    <div
      className={cx(classes.bubble, classes[color], {
        [classes.small]: small === true,
      })}
    >
      {icon}
    </div>
  );
}
