import { UserAccessGrant, Prisma } from "@prisma/client";
import { Noun } from "./permissions";

export type CreateUserAccessGrantParams = Omit<
  UserAccessGrant,
  "id" | "updatedAt" | "createdAt" | "deletedAt" | "nounScopes"
> & {
  nounScopes: Prisma.JsonObject;
};

export function usesJobStructureScope(search: Noun): boolean {
  return search === Noun.JobStructure;
}

export function usesMarketJobStructureScope(search: Noun): boolean {
  return search === Noun.CashBand || search === Noun.EquityBand;
}

export function usesEmployeeScope(search: Noun): boolean {
  return [
    Noun.CompRecommendation,
    Noun.Employee,
    Noun.EmployeeCashCompensation,
    Noun.EmployeeEquityCompensation,
  ].some((noun) => noun === search);
}

export function usesOfferScope(search: Noun): boolean {
  return search === Noun.Offer;
}
