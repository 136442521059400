import { gql } from "@apollo/client";
import { Noun } from "@asmbl/shared/permissions";
import { contramap } from "@asmbl/shared/sort";
import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { WHITE } from "../../../../theme";
import { TeamCompensationAccessType } from "../../../../__generated__/graphql";
import { AssembleTypography } from "../../../AssembleTypography";
import { useAuth } from "../../../Auth/AuthContext";
import {
  DoubleSortableTableHeaderCell,
  SortableTableHeaderCell,
  useSort,
} from "../../../SortableTable";
import { UserOrPendingUser } from "../utils";
import { AccessControlTableRow } from "./AccessControlTableRow";
import { CompDataTooltip } from "./CompDataTooltip";

type AccessControlTableProps = {
  users: UserOrPendingUser[];
  teamCompensationAccess: TeamCompensationAccessType;
};

const useStyles = makeStyles(() => ({
  headerCell: { backgroundColor: WHITE },
}));

export function AccessControlTable({
  users,
  teamCompensationAccess,
}: AccessControlTableProps): JSX.Element | null {
  const classes = useStyles();
  const { permissions } = useAuth();
  const canEditAccessControl = permissions.canEditGlobal(Noun.AccessControl);

  const {
    sortedArray: sortedUsers,
    order,
    orderBy,
    handleRequestSort,
  } = useSort(users, "createdAt", "desc", {
    employee: contramap(
      (user: UserOrPendingUser) =>
        user.employee?.activeEmployment?.jobTitle ?? "-"
    ),
  });

  if (users.length === 0) return null;

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell variant="head" className={classes.headerCell} />
            <DoubleSortableTableHeaderCell
              className={classes.headerCell}
              titleA="Name"
              titleB="Email"
              aria-label="Name / Email"
              orderByFieldA="name"
              orderByFieldB="email"
              order={order}
              isSelectedA={orderBy === "name"}
              isSelectedB={orderBy === "email"}
              handleRequestSort={handleRequestSort}
            />
            <SortableTableHeaderCell
              className={classes.headerCell}
              cellTitle="Job Title"
              orderByField="employee"
              order={order}
              isSelected={orderBy === "employee"}
              handleRequestSort={handleRequestSort}
              noWrap
            />
            <TableCell variant="head" className={classes.headerCell}>
              <AssembleTypography variant="productTableHeader">
                Reports
              </AssembleTypography>
            </TableCell>
            <TableCell variant="head" className={classes.headerCell}>
              <Box display="flex" alignItems="center">
                <AssembleTypography variant="productTableHeader">
                  Comp. Access
                </AssembleTypography>
                <Box m={0.5} />
                <CompDataTooltip
                  teamCompensationAccess={teamCompensationAccess}
                />
              </Box>
            </TableCell>
            <SortableTableHeaderCell
              className={classes.headerCell}
              cellTitle="Date created"
              orderByField="createdAt"
              order={order}
              isSelected={orderBy === "createdAt"}
              handleRequestSort={handleRequestSort}
              noWrap
            />
            <TableCell variant="head" className={classes.headerCell}>
              <AssembleTypography variant="productTableHeader">
                Access
              </AssembleTypography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedUsers.map((user) => (
            <AccessControlTableRow
              key={user.id}
              user={user}
              canEditAccessControl={canEditAccessControl}
              teamCompensationAccess={teamCompensationAccess}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

AccessControlTable.fragments = {
  activeUserInvitations: gql`
    ${AccessControlTableRow.fragments.activeUserInvitations}
    fragment AccessControlTable_activeUserInvitations on UserInvitation {
      id
      createdAt
      ...AccessControlTableRow_activeUserInvitations
    }
  `,
  users: gql`
    ${AccessControlTableRow.fragments.users}
    fragment AccessControlTable_userRecords on User {
      id
      createdAt
      ...AccessControlTableRow_userRecords
    }
  `,
  permissionSettings: gql`
    ${AccessControlTableRow.fragments.permissionSettings}
    ${CompDataTooltip.fragments.permissionSettings}
    fragment AccessControlTable_permissionSettings on PermissionSettings {
      id
      ...CompDataTooltip_permissionSettings
      ...AccessControlTableRow_permissionSettings
    }
  `,
};
