import { List, makeStyles, Popper, Typography } from "@material-ui/core";
import { useTrack } from "../../../../analytics";
import { GRAY_3, GRAY_6, WHITE } from "../../../../theme";
import { OveragesSelector_budgetOverage as BudgetOverage } from "../../../../__generated__/graphql";
import { OverageListItem } from "./OverageListItem";

const useStyles = makeStyles(() => ({
  popper: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    width: "392px",
    borderRadius: "5px",
    boxShadow: "0px 3px 24px rgba(10, 36, 64, 0.05)",
    border: `1px solid ${GRAY_6}`,
    background: WHITE,
    maxWidth: "none",
  },
  popperHeader: {
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem 1.5rem 0 1.5rem",
  },
  headerTitle: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  headerSubtitle: {
    fontSize: "0.8125rem",
  },
  helpMessage: {
    padding: "0 1.5rem 0 1.5rem",
    marginTop: "1rem",
  },
  helpText: {
    fontSize: "0.75rem",
    color: GRAY_3,
  },
  list: {
    maxHeight: 200,
    overflow: "auto",
  },
}));

type Props = {
  compCycleId: number;
  overages: BudgetOverage[];
  anchorEl: HTMLElement | null;
  setAnchorEl: (el: HTMLElement | null) => unknown;
};

export function OveragesPopper({
  compCycleId,
  overages,
  anchorEl,
  setAnchorEl,
}: Props): JSX.Element {
  const { trackEvent } = useTrack();
  const classes = useStyles();
  const open = Boolean(anchorEl);

  const header =
    `${overages.length} ` +
    `Invalid ${overages.length === 1 ? "Budget" : "Budgets"}`;

  const handleItemClick = (managerId: number | "org") => {
    setAnchorEl(null);
    trackEvent({
      object: "Invalid Budget Warning Row",
      action: "Clicked",
      managerId,
    });
  };

  return (
    <Popper placement="bottom-end" open={open} anchorEl={anchorEl}>
      <div className={classes.popper}>
        <div className={classes.popperHeader}>
          <Typography className={classes.headerTitle}>{header}</Typography>
          <Typography className={classes.headerSubtitle}>
            Some budgets exceed their allocated amount. You’ll need to fix them
            before you can publish.
          </Typography>
        </div>
        <div className={classes.helpMessage}>
          <Typography className={classes.helpText}>
            Click an item to jump to an invalid budget
          </Typography>
        </div>
        <List className={classes.list}>
          {overages.map((option) => (
            <OverageListItem
              key={`${option.variant}-${option.employee?.id ?? "org"}`}
              overage={option}
              compCycleId={compCycleId}
              onClick={() => handleItemClick(option.employee?.id ?? "org")}
            />
          ))}
        </List>
      </div>
    </Popper>
  );
}
