import { useQuery } from "@apollo/client";
import { Noun } from "@asmbl/shared/permissions";
import { caseInsensitiveComparator, contramap } from "@asmbl/shared/sort";
import {
  Box,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { EditIcon } from "src/components/AssembleIcons/Brand/EditIcon";
import { GRAY_4, PURPLE_2 } from "src/theme";
import { GetDepartment } from "../../__generated__/graphql";
import { AccessBoundary } from "../../components/AccessBoundary";
import {
  AssembleBreadCrumb,
  AssembleBreadCrumbs,
  AssembleDropdownBreadCrumb,
} from "../../components/AssembleBreadCrumbs";
import { ErrorView } from "../../components/ErrorView";
import { ComputedLadder } from "../../models/ComputedLadder";
import { DEPARTMENT_QUERY } from "../../queries";
import { DepartmentDetailLadderCreator } from "./DepartmentDetailLadderCreator";
import { DepartmentDetailTable } from "./DepartmentDetailTable";
import { DepartmentForm } from "./DepartmentForm";

const useStyles = makeStyles((theme) => ({
  nameTextField: {
    fontSize: theme.typography.h5.fontSize,
    marginLeft: -theme.spacing(1),
    marginTop: "1px",
  },
  breadCrumbContainer: {
    "& .MuiBreadcrumbs-ol > li:last-child": {
      width: "100%",
    },
  },
  leafCrumb: {
    height: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export function DepartmentDetail(): JSX.Element {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [isEditingDepartment, setIsEditingDepartment] = useState(false);

  const { data, loading, error } = useQuery<GetDepartment>(DEPARTMENT_QUERY, {
    variables: { departmentId: Number(id) },
  });

  if (loading) return <LinearProgress />;
  if (error) return <ErrorView text={JSON.stringify(error)} />;
  if (!data || !data.department) return <ErrorView text="Not Found" />;

  const { department } = data;
  const departments = department.organization.departments
    .slice()
    .sort(contramap((d) => d.name, caseInsensitiveComparator));

  const computedLadders = department.ladders.map((d) => new ComputedLadder(d));

  return (
    <Box px={5} py={4}>
      {isEditingDepartment && (
        <DepartmentForm
          mode="edit"
          persistedDepartment={department}
          onCancel={() => setIsEditingDepartment(false)}
        />
      )}
      <div className={classes.breadCrumbContainer}>
        <AssembleBreadCrumbs variant="vertical">
          <AssembleBreadCrumb to="/" display={department.organization.name} />
          <div className={classes.leafCrumb}>
            <div className={classes.leftContainer}>
              <AssembleDropdownBreadCrumb
                options={departments.map(({ id, name }) => ({
                  id,
                  name,
                  to: `/departments/${id}`,
                }))}
                selectedOption={{
                  id: department.id,
                  name: department.name,
                  to: `/departments/${department.id}`,
                }}
              />

              <Box m={1} />

              <AccessBoundary
                scope="global"
                verb="edit"
                noun={Noun.JobStructure}
              >
                <Tooltip title="Edit Department" placement="top">
                  <IconButton
                    size="small"
                    onClick={() => setIsEditingDepartment(true)}
                  >
                    <EditIcon
                      color={GRAY_4}
                      hoverColor={PURPLE_2}
                      width="24px"
                      height="24px"
                    />
                  </IconButton>
                </Tooltip>
              </AccessBoundary>
            </div>
            <DepartmentDetailLadderCreator departmentId={department.id} />
          </div>
        </AssembleBreadCrumbs>
      </div>
      <Box m={2} />
      <Typography variant="subtitle1">
        {department.ladders.length} ladders
      </Typography>
      <Box m={2} />
      <DepartmentDetailTable ladders={computedLadders} />
    </Box>
  );
}
