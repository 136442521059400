import { cx } from "@emotion/css";
import { makeStyles, Tooltip } from "@material-ui/core";
import { AdjustedBandPoint, formatPointValue } from "../../../models/BandPoint";
import { UserAvatar } from "../../UserAvatar";

const useAvatarPointStyles = makeStyles(() => ({
  root: {
    height: "100%",
    position: "absolute",
    top: 0,
    transition: "all 250ms ease",
    width: "1px",
  },
  belowBand: {},
  inBand: {},
  aboveBand: {},
  avatar: {
    position: "absolute",
    left: "0.5px",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

type AvatarPointProps = {
  point: AdjustedBandPoint;
  penetration: number;
  offset: string;
  avatar?: {
    displayName: string;
    photoURL: string | null;
  };
};
export function AvatarPoint({
  point,
  penetration,
  offset,
  avatar,
}: AvatarPointProps): JSX.Element {
  const classes = useAvatarPointStyles();
  return (
    <Tooltip placement="top" title={`${formatPointValue(point)}`}>
      <div
        className={cx(classes.root, {
          [classes.belowBand]: penetration < 0,
          [classes.inBand]: 0 <= penetration && penetration <= 1,
          [classes.aboveBand]: 1 < penetration,
        })}
        style={{ left: offset }}
      >
        <div className={classes.avatar}>
          <UserAvatar
            displayName={avatar?.displayName}
            photoURL={avatar?.photoURL}
            size="mini"
            border
          />
        </div>
      </div>
    </Tooltip>
  );
}
