import { cx } from "@emotion/css";
import { makeStyles } from "@material-ui/core";
import { forwardRef, ReactNode, Ref } from "react";
import { PAGE_HEADER_HEIGHT } from "./PageHeader";

type Props = {
  children: ReactNode;
  sticky?: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflowY: "hidden",
    background: theme.palette.background.paper,
    "@media print": {
      height: "auto !important",
    },
  },
  sticky: {
    height: `calc(100vh - ${PAGE_HEADER_HEIGHT})`,
    overflowY: "auto",
    paddingBottom: "80px",
  },
}));

export const FullHeight = forwardRef(function FullHeight(
  { sticky = false, children }: Props,
  ref: Ref<HTMLDivElement>
): JSX.Element {
  const classes = useStyles();
  return (
    <div ref={ref} className={cx(classes.root, sticky && classes.sticky)}>
      {children}
    </div>
  );
});
