import { CashBandType, ScheduleType } from "@asmbl/shared/constants";
import { CashCompType } from "./__generated__/graphql";
import {
  DV_BLUE,
  DV_BLUE_2,
  DV_GREEN,
  DV_GREEN_2,
  DV_ORANGE,
  DV_PINK,
  DV_PURPLE,
  DV_RED,
  DV_YELLOW,
  DV_YELLOW_GREEN,
} from "./theme";

/**
 * A list of data visualization colors with their alpha channels set to FF
 * so that Apex won't adjust their transparency values.
 */
export const DV_BAND_COLORS = [
  `${DV_PINK}FF`,
  `${DV_GREEN}FF`,
  `${DV_BLUE_2}FF`,
  `${DV_RED}FF`,
  `${DV_BLUE}FF`,
  `${DV_YELLOW_GREEN}FF`,
  `${DV_GREEN_2}FF`,
  `${DV_PURPLE}FF`,
  `${DV_ORANGE}FF`,
  `${DV_YELLOW}FF`,
] as const;

export enum CashBandName {
  SALARY = "Salary",
  COMMISSION = "Commission",
  RECURRING_BONUS = "Recurring Bonus",
  SPOT_BONUS = "Spot Bonus",
  SIGNING_BONUS = "Signing Bonus",
  RELOCATION_BONUS_OFFICE = "Relocation Bonus (Office)",
  RELOCATION_BONUS_REMOTE = "Relocation Bonus (Remote)",
}

interface CashCompComponent {
  bandType: CashBandType;
  scheduleType: ScheduleType;
}

/*
  This is copied from the shared package. The shared package depends on Prisma
  client values, which are not able to be imported on the client.
 */
export const CASH_COMP_COMPONENTS: Record<CashBandName, CashCompComponent> = {
  Salary: {
    bandType: CashBandType.FIXED_CASH,
    scheduleType: ScheduleType.ANNUAL,
  },
  Commission: {
    bandType: CashBandType.VARIABLE_CASH,
    scheduleType: ScheduleType.ANNUAL,
  },
  "Recurring Bonus": {
    bandType: CashBandType.VARIABLE_CASH,
    scheduleType: ScheduleType.ANNUAL,
  },
  "Spot Bonus": {
    bandType: CashBandType.VARIABLE_CASH,
    scheduleType: ScheduleType.ONE_TIME,
  },
  "Signing Bonus": {
    bandType: CashBandType.FIXED_CASH,
    scheduleType: ScheduleType.ONE_TIME,
  },
  "Relocation Bonus (Office)": {
    bandType: CashBandType.FIXED_CASH,
    scheduleType: ScheduleType.ONE_TIME,
  },
  "Relocation Bonus (Remote)": {
    bandType: CashBandType.FIXED_CASH,
    scheduleType: ScheduleType.ONE_TIME,
  },
};

export enum EquityBandName {
  INITIAL_EQUITY_GRANT = "Initial Equity Grant",
  EQUITY_REFRESH_GRANT = "Equity Refresh Grant",
}

export const SORTED_BAND_NAMES = [
  CashBandName.SALARY,
  CashBandName.COMMISSION,
  CashBandName.RECURRING_BONUS,
  CashBandName.SPOT_BONUS,
  CashBandName.SIGNING_BONUS,
  CashBandName.RELOCATION_BONUS_OFFICE,
  CashBandName.RELOCATION_BONUS_REMOTE,
  EquityBandName.INITIAL_EQUITY_GRANT,
  EquityBandName.EQUITY_REFRESH_GRANT,
] as const;

export const CashBandCandidateDisplayName: Record<CashBandName, string> = {
  [CashBandName.SALARY]: CashBandName.SALARY,
  [CashBandName.COMMISSION]: CashBandName.COMMISSION,
  [CashBandName.RECURRING_BONUS]: CashBandName.RECURRING_BONUS,
  [CashBandName.SPOT_BONUS]: CashBandName.SPOT_BONUS,
  [CashBandName.SIGNING_BONUS]: CashBandName.SIGNING_BONUS,
  [CashBandName.RELOCATION_BONUS_OFFICE]: "Relocation Bonus",
  [CashBandName.RELOCATION_BONUS_REMOTE]: "Relocation Bonus",
};

export const COMP_TYPES_EXCLUDED_FROM_OFFERS = [
  EquityBandName.EQUITY_REFRESH_GRANT,
];

export enum EditorStatus {
  VIEWING,
  EDITING,
  SAVING,
  SAVED,
  ERROR,
}

// ISO or US -- YYYY-MM-DD or MM/DD/YYYY
export const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$|^\d{1,2}\/\d{1,2}\/\d{4}$/;

export const TOTAL_CASH_OPTION = "TOTAL_CASH";

export const ALL_CASH_COMP_OPTIONS = [
  CashCompType.SALARY,
  CashCompType.COMMISSION,
  CashCompType.RECURRING_BONUS,
];
