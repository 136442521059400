import { makeStyles } from "@material-ui/core";
import {
  PositionFieldsMinimal as Position,
  PositionType,
} from "../../../__generated__/graphql";
import { GRAY_6, theme } from "../../../theme";
import { PositionAutocomplete } from "../../Form/Autocomplete/PositionAutocomplete";

interface Props {
  onChange: (
    newPositionId: number | null,
    positionType: PositionType | undefined
  ) => unknown;
  positionId: number | null;
  positions: Position[];
  colSpan?: number;
}

const useStyles = makeStyles(() => ({
  positionAutocomplete: {
    borderTop: `1px solid ${GRAY_6} !important`,
    padding: theme.spacing(0.25, 0, 0.5, 0.5),
  },
  positionAutocompleteInput: {
    fontSize: "14px",
  },
}));

export function PositionInputCell({
  positions,
  positionId,
  onChange,
  colSpan,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <PositionAutocomplete
      classes={{
        input: classes.positionAutocompleteInput,
        tableCell: classes.positionAutocomplete,
      }}
      value={positions.find((p) => positionId === p.id) ?? null}
      label="Assemble Position"
      onChange={(position: Position | null) => {
        onChange(position?.id ?? null, position?.type);
      }}
      positions={positions}
      variant="table"
      colSpan={colSpan}
      disableClearable={false}
    />
  );
}
