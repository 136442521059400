import { createContext, useContext, useState } from "react";
import { noop } from "src/test-helpers";

const PhaseManagerSearchContext = createContext<{
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}>({
  searchTerm: "",
  setSearchTerm: () => noop,
});

export function usePhaseManagerSearch() {
  return useContext(PhaseManagerSearchContext);
}

export function PhaseManagerSearchProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [searchTerm, setSearchTerm] = useState<string>("");

  return (
    <PhaseManagerSearchContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
      }}
    >
      {children}
    </PhaseManagerSearchContext.Provider>
  );
}
