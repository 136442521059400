import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flex: 1,
    flexGrow: 1,
  },
}));

export function ErrorView({ text = "" }: { text?: string }): JSX.Element {
  const classes = useStyles();
  return <div className={classes.root}>An error occurred. {text}</div>;
}
