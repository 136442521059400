import { getFormattedMonthAndDay } from "@asmbl/shared/time";
import { isEmptyString } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { AssembleTypography } from "src/components/AssembleTypography";
import {
  CompCyclePhaseData,
  usePhaseConfiguration,
} from "../usePhaseConfiguration";
import { getParticipantCount, getPhaseEndDate } from "./utils";

const useStyles = makeStyles((theme) => ({
  phaseHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: "4rem",
  },
  phaseHeaderTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  phaseHeaderActions: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1.5),
    alignItems: "center",
  },
  phaseParticipantCount: {
    paddingRight: theme.spacing(1.5),
  },
}));

type Props = {
  variant: "create" | "edit";
  phaseConfig: CompCyclePhaseData;
  endDate: Date;
  viewPhaseAccess: () => void;
  editPhaseAccess: () => void;
  editPhaseDate: () => void;
};

export function PhaseRowHeader({
  variant,
  phaseConfig,
  endDate,
  viewPhaseAccess,
  editPhaseAccess,
  editPhaseDate,
}: Props): JSX.Element {
  const classes = useStyles();
  const { phaseConfiguration } = usePhaseConfiguration();

  const formattedStartDate = getFormattedMonthAndDay(
    phaseConfig.startDate,
    true
  );

  const formattedEndDate = getFormattedMonthAndDay(
    getPhaseEndDate(phaseConfiguration, phaseConfig, endDate),
    true
  );

  return (
    <div className={classes.phaseHeader}>
      <div className={classes.phaseHeaderTitle}>
        <AssembleTypography variant="productSummaryValue">
          Phase {phaseConfig.phaseOrder}
        </AssembleTypography>
        <AssembleTypography variant="productSummaryValue">
          &nbsp;|&nbsp;
        </AssembleTypography>
        <AssembleTypography variant="productParagraphMedium">
          {isEmptyString(formattedStartDate) ? (
            <em>Date missing</em>
          ) : (
            formattedStartDate
          )}
          &nbsp;-&nbsp;
          {isEmptyString(formattedEndDate) ? (
            <em>Date missing</em>
          ) : (
            formattedEndDate
          )}
        </AssembleTypography>
      </div>
      <div className={classes.phaseHeaderActions}>
        <AssembleButton
          className={classes.phaseParticipantCount}
          onClick={viewPhaseAccess}
          variant="text"
          size="medium"
          label={
            getParticipantCount(phaseConfig) === 0
              ? "No people"
              : getParticipantCount(phaseConfig) === 1
                ? "1 person"
                : `${getParticipantCount(phaseConfig)} people`
          }
        />
        <AssembleButton
          variant="outlined"
          size="medium"
          label="Edit People"
          onClick={editPhaseAccess}
          disabled={
            getParticipantCount(phaseConfig) === 0 || variant === "edit"
          }
        />
        <AssembleButton
          variant="outlined"
          size="medium"
          label="Edit Date"
          onClick={editPhaseDate}
        />
      </div>
    </div>
  );
}
