import { gql, Reference, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  CreateDepartment,
  CreateDepartmentVariables,
  DeleteDepartment,
  DeleteDepartmentVariables,
  UpdateDepartment,
  UpdateDepartmentVariables,
} from "../__generated__/graphql";

const DEPARTMENT_FIELDS = gql`
  fragment DepartmentFields on Department {
    id
    name
  }
`;

type CreateDepartmentInput = {
  name: string;
};

export const CREATE_DEPARTMENT = gql`
  ${DEPARTMENT_FIELDS}
  mutation CreateDepartment($departmentName: String!) {
    createOneDepartment(data: { name: $departmentName }) {
      ...DepartmentFields
      organization {
        id
        name
        departments {
          ...DepartmentFields
        }
      }
    }
  }
`;

export function useCreateDepartment(): (
  department: CreateDepartmentInput
) => Promise<unknown> {
  const [createDepartment] = useMutation<
    CreateDepartment,
    CreateDepartmentVariables
  >(CREATE_DEPARTMENT, {
    update(cache, { data }) {
      if (data == null) {
        return;
      }

      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          departments(existingDepartments: Reference[]) {
            const newDepartment = cache.writeFragment({
              fragment: DEPARTMENT_FIELDS,
              fragmentName: "DepartmentFields",
              data: data.createOneDepartment,
            });

            return [...existingDepartments, newDepartment];
          },
        },
      });
    },
  });

  return useCallback(
    (department: CreateDepartmentInput) =>
      createDepartment({
        variables: {
          departmentName: department.name,
        },
      }),
    [createDepartment]
  );
}

type UpdateDepartmentInput = { id: number; name: string };

const UPDATE_DEPARTMENT = gql`
  mutation UpdateDepartment($departmentId: Int!, $departmentName: String) {
    updateOneDepartment(id: $departmentId, data: { name: $departmentName }) {
      id
      name
    }
  }
`;

export function useUpdateDepartment(): (
  department: UpdateDepartmentInput
) => Promise<unknown> {
  const [updateDepartment] = useMutation<
    UpdateDepartment,
    UpdateDepartmentVariables
  >(UPDATE_DEPARTMENT);

  return useCallback(
    (department: UpdateDepartmentInput) =>
      updateDepartment({
        variables: {
          departmentId: department.id,
          departmentName: department.name,
        },
      }),
    [updateDepartment]
  );
}

export const DELETE_DEPARTMENT = gql`
  ${DEPARTMENT_FIELDS}

  mutation DeleteDepartment($departmentId: Int!) {
    deleteOneDepartment(id: $departmentId) {
      ...DepartmentFields
    }
  }
`;

export function useDeleteDepartment(): (
  departmentId: number
) => Promise<unknown> {
  const [deleteDepartment] = useMutation<
    DeleteDepartment,
    DeleteDepartmentVariables
  >(DELETE_DEPARTMENT, {
    update(cache, { data }) {
      if (data == null) {
        return;
      }

      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          departments(existingDepartments: Reference[], { readField }) {
            return existingDepartments.filter(
              (ref) => readField("id", ref) !== data.deleteOneDepartment.id
            );
          },
        },
      });
    },
  });

  return useCallback(
    (departmentId: number) =>
      deleteDepartment({
        variables: {
          departmentId,
        },
      }),
    [deleteDepartment]
  );
}
