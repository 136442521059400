import { CurrencyCode, MONTHS_IN_A_YEAR } from "./constants";
import { getMidRange } from "./math";
import { contramap } from "./sort";
import { formatNumeral } from "./utils";

export interface Money<C extends CurrencyCode = CurrencyCode> {
  value: number;
  currency: C;
}

export function isMoney(m: Money | number | undefined | null): m is Money {
  if (m === null || m === undefined) {
    return false;
  }
  return typeof m !== "number";
}

export function moneyComparator<C extends CurrencyCode>(
  a: Money<C>,
  b: Money<C>
): number {
  return a.value - b.value;
}

export function money<C extends CurrencyCode>(
  value: number,
  currency: C
): Money<C> {
  return { value, currency };
}

export function zero<C extends CurrencyCode>(currency: C): Money<C> {
  return { value: 0, currency };
}

export function isZero(m: Money): boolean {
  return m.value === 0;
}

export function dollars(value: number): Money<CurrencyCode.USD> {
  return { value, currency: CurrencyCode.USD as const };
}

export function eq(
  a: Money | null | undefined,
  b: Money | null | undefined
): boolean {
  return Boolean(
    a === b || (a && b && a.value === b.value && a.currency === b.currency)
  );
}

/*
 * Arithmetic operations
 */

export const liftMoney =
  (operator: (x: number, y: number) => number) =>
  <C extends CurrencyCode>(a: Money<C>, b: Money<C>): Money<C> => ({
    value: operator(a.value, b.value),
    currency: a.currency,
  });
export const add = liftMoney((a, b) => a + b);
export const subtract = liftMoney((a, b) => a - b);

export function multiply<C extends CurrencyCode>(
  a: Money<C>,
  b: number
): Money<C>;
export function multiply<C extends CurrencyCode>(
  a: number,
  b: Money<C>
): Money<C>;
export function multiply<C extends CurrencyCode>(
  a: Money<C> | number,
  b: Money<C> | number
): Money<C> {
  const money = isMoney(a) ? a : (b as Money<C>);
  const multiplier = isMoney(a) ? (b as number) : a;

  return {
    value: money.value * multiplier,
    currency: money.currency,
  };
}

export function divide<C extends CurrencyCode>(
  a: Money<C>,
  b: number
): Money<C> {
  return { value: a.value / b, currency: a.currency };
}

export function min<C extends CurrencyCode>(
  ...monies: Money<C>[]
): Money<C> | undefined {
  const first = monies.at(0);
  if (first === undefined) {
    // Math.min would return Infinity, but we don't know what CurrencyCode to use.
    return undefined;
  }
  return {
    value: Math.min(...monies.map((m) => m.value)),
    currency: first.currency,
  };
}

export function max<C extends CurrencyCode>(
  ...monies: Money<C>[]
): Money<C> | undefined {
  const first = monies.at(0);
  if (first === undefined) {
    // Math.max would return -Infinity, but we don't know what CurrencyCode to use
    return undefined;
  }
  return {
    value: Math.max(first.value, ...monies.map((m) => m.value)),
    currency: first.currency,
  };
}

export function median<C extends CurrencyCode>(
  ...monies: Money<C>[]
): Money<C> | undefined {
  const first = monies.at(0);
  if (first === undefined) {
    return undefined;
  }
  if (!monies.every((m) => m.currency === first.currency)) {
    throw new Error(
      "Cannot calculate median of values in different currencies"
    );
  }
  const sorted = [...monies].sort(moneyComparator);
  const mid = Math.floor(sorted.length / 2);
  return sorted.length % 2 === 0
    ? divide(add(sorted[mid - 1], sorted[mid]), 2)
    : sorted[mid];
}

export function mean<C extends CurrencyCode>(
  ...monies: Money<C>[]
): Money<C> | undefined {
  if (monies.length === 0) {
    return undefined;
  }
  if (!monies.every((m) => m.currency === monies[0].currency)) {
    throw new Error("Cannot calculate mean of values in different currencies");
  }
  const sumTotal = monies.reduce(add);
  return divide(sumTotal, monies.length);
}

export function ratio(numerator: Money, denominator: Money): number {
  if (numerator.currency !== denominator.currency) {
    throw new Error(
      `Cannot calculate ratio of values in different currencies (${numerator.currency}:${denominator.currency})`
    );
  }
  if (isZero(denominator)) {
    throw new Error("Cannot calculate ratio with a denominator of zero");
  }
  return numerator.value / denominator.value;
}

/**
 * Map a Money value to a new Money value by performing some math on its value
 * @param fn A function that transforms numbers to numbers
 * @param m A Money to be transformed
 * @returns A new Money with the form { value: fn(m.value), currency: m.currency }
 */
export function map<C extends CurrencyCode>(
  fn: (x: number) => number,
  m: Money<C>
): Money<C> {
  return { value: fn(m.value), currency: m.currency };
}

/*
 * Financial calculations
 */

export function preferredPrice<C extends CurrencyCode>(
  fdso: number,
  valuation: Money<C>
): Money<C> {
  return divide(valuation, fdso);
}

interface EstimatedPrice_valuation {
  fdso: number;
  estimatedDilution: number;
}

export function estimatedPrice<C extends CurrencyCode>(
  equityInfo: EstimatedPrice_valuation,
  valuation: Money<C>
): Money<C> {
  return multiply(
    preferredPrice(equityInfo.fdso, valuation),
    1 - equityInfo.estimatedDilution
  );
}

export function totalGrossEquityValue<C extends CurrencyCode>(
  fdso: number,
  valuation: Money<C>,
  units: number
): Money<C> {
  return multiply(preferredPrice(fdso, valuation), units);
}

export function unitsOfTotalGrossValue<C extends CurrencyCode>(
  fdso: number,
  valuation: Money<C>,
  totalGrossValue: Money<C>
): number {
  return ratio(totalGrossValue, preferredPrice(fdso, valuation));
}

export function annualGrossEquityValue<C extends CurrencyCode>(
  fdso: number,
  valuation: Money<C>,
  units: number,
  vestingMonths: number
): Money<C> {
  return divide(
    totalGrossEquityValue(fdso, valuation, units),
    vestingMonths / MONTHS_IN_A_YEAR
  );
}

export function estimatedAnnualGrossEquityValue<C extends CurrencyCode>(
  equityInfo: EstimatedPrice_valuation,
  valuation: Money<C>,
  units: number,
  vestingMonths: number
): Money<C> {
  return divide(
    multiply(
      totalGrossEquityValue(equityInfo.fdso, valuation, units),
      1 - equityInfo.estimatedDilution
    ),
    vestingMonths / MONTHS_IN_A_YEAR
  );
}

export function exerciseCost<C extends CurrencyCode>(
  units: number,
  strikePrice: Money<C>
): Money<C> {
  return multiply(strikePrice, units);
}

/* Given a cash compensation, return whether it is above, in, or below band.
 * A negative value indicates that the compensation is below the band, a
 * positive value indicates that the compensation is above the band, and a
 * value of 0 indicates that the compensation is in the band.
 * If the band or cash value is unknown, returns undefined.
 *
 * This function should be extended to handle other Unit types beyond
 * Cash, such as Percent of Salary.
 */
export function compareAgainstBand(
  cashCompensation:
    | {
        annualCashEquivalent: Money;
        adjustedCashBand: {
          bandPoints: {
            annualCashEquivalent: Money;
          }[];
        } | null;
      }
    | null
    | undefined
): number | undefined {
  if (
    cashCompensation == null ||
    cashCompensation.adjustedCashBand == null ||
    cashCompensation.adjustedCashBand.bandPoints.length === 0
  ) {
    return undefined;
  }

  const cashValue = cashCompensation.annualCashEquivalent.value;
  const sortedBandPoints = cashCompensation.adjustedCashBand.bandPoints
    .slice()
    .sort(contramap((x) => x.annualCashEquivalent.value));

  return cashValue < sortedBandPoints[0].annualCashEquivalent.value
    ? -1
    : cashValue >
        sortedBandPoints[sortedBandPoints.length - 1].annualCashEquivalent.value
      ? 1
      : 0;
}

export function percentageOfSalaryToCash(
  salary: Money,
  percentValue: number
): Money;
export function percentageOfSalaryToCash(
  salary: Money | undefined,
  percentValue: number
): Money | undefined;
export function percentageOfSalaryToCash(
  salary: Money | undefined,
  percentValue: number
): Money | undefined {
  if (salary === undefined) return undefined;

  return map(Math.round, divide(multiply(salary, percentValue), 100));
}

export function cashToPercentOfSalary(salary: Money, cashValue: Money): number;
export function cashToPercentOfSalary(
  salary: Money | undefined,
  cashValue: Money
): number | undefined {
  if (salary === undefined) return undefined;

  return (cashValue.value / salary.value) * 100;
}

export function numberOfUnitsToCash(
  pricePerUnit: Money,
  unitsValue: number
): Money {
  return multiply(pricePerUnit, unitsValue);
}

export function formatCurrency(
  money: Money,
  options?: Intl.NumberFormatOptions
): string {
  return formatNumeral(money.value, {
    style: "currency",
    currency: money.currency,
    ...options,
  });
}

/**
 *
 * @param bandPoints These are adjusted AND converted band points
 * therefore, they should be using the same currency and it should match
 * the currency of the `point` argument. These band points are assumed to
 * be pre-sorted
 * @param point This data point that we are going to use to calculate the
 * compa-ratio
 * @returns The compa-ratio, where 1.0 represents the midpoint of the band.
 */
export function calculateCompaRatio(
  bandPoints: Money[],
  point: Money
): number | null {
  const [min, max] = [bandPoints.at(0), bandPoints.at(-1)];

  if (!min || !max) {
    return null;
  }

  if (bandPoints.some((point) => point.currency !== min?.currency)) {
    return null;
  }

  if (min.currency !== point.currency) {
    return null;
  }

  const midRange = getMidRange([min, max].map((d) => d.value));

  if (midRange === 0) {
    return null;
  }

  return point.value / midRange;
}

/**
 * Calculates the band penetration of the given point within the band.
 * Band penetration less than 0 represents a point below the band. A value
 * over 1 represents a point above the band.
 *
 * This will return null only if there are no band points. For single point
 * bands, a special formula is used to avoid division by zero.
 *
 */
export function calculateBandPenetration(
  bandPoints: Money[],
  point: Money
): number | null {
  const [min, max] = [bandPoints.at(0), bandPoints.at(-1)];

  if (!min || !max) {
    return null;
  }

  // Single Point Band
  if (eq(min, max)) {
    // If the point is on the band, we return 1. Otherwise, we return an
    // arbitrary value <0 or >1, depending on the placement.
    return eq(min, point) ? 1 : point.value < min.value ? -1 : 2;
  }

  return (point.value - min.value) / (max.value - min.value);
}
/**
 *
 * @param money money to round
 * @param round value to round to
 * @returns
 */
export function roundMoney(money: Money, round: number): Money {
  return {
    currency: money.currency,
    value: Math.round(money.value / round) * round,
  };
}

export function calculatePercent(
  baseSalary?: Money | null,
  absolute?: number
): number {
  if (baseSalary == null || absolute == null) return 0;
  return baseSalary.value !== 0
    ? Math.round((absolute / baseSalary.value) * 10000) / 100
    : 0;
}
