import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency } from "@asmbl/shared/currency";
import { isZero, Money } from "@asmbl/shared/money";
import { isValidString } from "@asmbl/shared/utils";
import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import { formatNumber } from "accounting";
import { forwardRef } from "react";
import {
  BenefitsPackageFields as BenefitsPackage,
  GetAllOfferData,
  GetOrganization,
  ApprovalSheet_position as Position,
  PositionType,
} from "../../__generated__/graphql";
import {
  CashBandName,
  COMP_TYPES_EXCLUDED_FROM_OFFERS,
  EquityBandName,
} from "../../constants";
import { getSimpleCashLabel } from "../../models/Currency";
import { exchangeRate } from "../../models/Money";
import { ComputedOfferedComp, isCashCompensation } from "../../models/Offer";
import { BLUE_2, GRAY_4, GRAY_8, WHITE } from "../../theme";
import { bandNameComparator, NonNull } from "../../utils";
import { AssembleTypography } from "../AssembleTypography";
import { useCurrencies } from "../CurrenciesContext";
import { OfferData } from "../OfferGeneration/types";
import { BandLabelValue, LabelValue } from "./BandLabelValue";

type CompStructure = NonNull<GetAllOfferData["compStructure"]>;
type Organization = GetOrganization["organization"];

const useStyles = makeStyles((theme) => ({
  border: {
    filter: "drop-shadow(0px 1px 3px rgba(10, 36, 64, 0.16))",
  },
  sheet: {
    display: "flex",
    width: "891px",
    minHeight: "1153px",
    height: "100%",
    background: WHITE,
  },
  leftColumn: {
    flex: 1,
    padding: theme.spacing(5),
  },
  rightColumn: {
    width: "355px",
    padding: theme.spacing(5),
    backgroundColor: GRAY_8,
  },
  section: {
    display: "block",
  },
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  sectionHeaderIcon: {
    width: "8px",
    height: "16px",
    backgroundColor: BLUE_2,
    marginRight: theme.spacing(1),
  },
  sectionHeaderText: {
    width: "100%",
  },
  offerColumnHeader: {
    fontWeight: 700,
    textTransform: "uppercase",
    letterSpacing: "3px",
  },
  dateText: {
    color: GRAY_4,
  },
  totalCompDivider: {
    height: "5px",
  },
  totalCompText: {
    fontSize: "1.625em",
    fontWeight: 500,
  },
  commentsText: {
    whiteSpace: "pre-wrap",
  },
}));

type Props = {
  data: OfferData;
  position: Position | undefined;
  compStructure: CompStructure;
  benefitsPackages: BenefitsPackage[];
  organization: Organization;
  showCurrentEquityValue: boolean;
  showEquityInValuationCurrency: boolean;
  computedOfferedComp: ComputedOfferedComp;
  localCurrency: Currency<CurrencyCode>;
  valuationCurrency: Currency<CurrencyCode>;
  pricePerUnit: Money;
};

const ApprovalSheetWithRef = forwardRef<HTMLElement, Props>(
  function ApprovalSheet(
    {
      data,
      position,
      compStructure,
      benefitsPackages,
      organization,
      showCurrentEquityValue,
      showEquityInValuationCurrency,
      computedOfferedComp,
      localCurrency,
      valuationCurrency,
      pricePerUnit,
    }: Props,
    ref
  ) {
    const classes = useStyles();
    const { currencies } = useCurrencies();

    const equityCurrency = showEquityInValuationCurrency
      ? valuationCurrency
      : localCurrency;
    const benefitsCurrency =
      currencies.get(
        computedOfferedComp.annual.benefits.inBenefitsCurrency.currency
      ) ?? localCurrency;
    const foreignCurrencies = [equityCurrency, benefitsCurrency].filter(
      (c) => c.code !== localCurrency.code
    );

    return (
      <Box className={classes.border}>
        <Box ref={ref} className={classes.sheet}>
          <Box className={classes.leftColumn}>
            <Typography variant="h2">{organization.name}</Typography>
            <Box m={5} />
            <CandidateSection data={data} />
            <PositionSection data={data} />
            <ApprovalSection data={data} />
          </Box>
          <Box className={classes.rightColumn}>
            <Typography
              className={classes.offerColumnHeader}
              align="right"
              variant="body2"
            >
              Candidate Offer
            </Typography>
            <Typography
              className={classes.dateText}
              align="right"
              variant="body2"
            >
              {data.offeredAt?.toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </Typography>
            <Box m={5} />
            <SectionHeader label="Offer" />
            {compStructure.allBandTypes
              .slice()
              .filter(
                (bandType) =>
                  !COMP_TYPES_EXCLUDED_FROM_OFFERS.includes(
                    bandType as EquityBandName
                  )
              )
              .sort(bandNameComparator)
              .map((bandName: string) => {
                return (
                  <BandLabelValue
                    localCurrency={localCurrency}
                    valuationCurrency={valuationCurrency}
                    key={bandName}
                    salary={data.cash[CashBandName.SALARY]}
                    bandName={bandName}
                    compData={
                      isCashCompensation(bandName)
                        ? data.cash[bandName as CashBandName]
                        : data.equity[bandName as EquityBandName]
                    }
                    position={position}
                    compStructure={compStructure}
                    showCurrentEquityValue={showCurrentEquityValue}
                    showEquityInValuationCurrency={
                      showEquityInValuationCurrency
                    }
                    computedOfferedComp={computedOfferedComp}
                    pricePerUnit={pricePerUnit}
                  />
                );
              })}
            {benefitsPackages.length > 0 && (
              <LabelValue
                mt={3}
                label="Benefits Package"
                value={
                  <Box display="flex" justifyContent="space-between">
                    <span>
                      {data.benefitsPackage === null
                        ? "Don't show benefits"
                        : data.benefitsPackage?.name ?? "None selected"}
                    </span>
                    {data.benefitsPackage &&
                      !isZero(
                        computedOfferedComp.annual.benefits.inBenefitsCurrency
                      ) && (
                        <span>
                          {getSimpleCashLabel(
                            computedOfferedComp.annual.benefits
                              .inBenefitsCurrency
                          )}
                        </span>
                      )}
                  </Box>
                }
              />
            )}
            <Box m={3} />
            <Divider className={classes.totalCompDivider} />
            <LabelValue
              mt={3}
              classes={{ value: classes.totalCompText }}
              label="Annual On-Target Earnings | Hourly Rate"
              value={getSimpleCashLabel(computedOfferedComp.annual.cash)}
            />
            <LabelValue
              mt={3}
              classes={{ value: classes.totalCompText }}
              label="Annual Total Compensation | Hourly Rate"
              value={getSimpleCashLabel(computedOfferedComp.annual.total)}
            />
            <LabelValue
              mt={3}
              classes={{ value: classes.totalCompText }}
              label="Additional One-Time Compensation"
              value={getSimpleCashLabel(computedOfferedComp.oneTimeComp)}
            />
            {foreignCurrencies.length > 0 && (
              <LabelValue
                mt={3}
                label="Exchange Rate"
                value={
                  <Box display="flex" flexDirection="column" mt={1}>
                    {foreignCurrencies.map((foreignCurrency) => (
                      <AssembleTypography
                        variant="productMicrocopy"
                        key={foreignCurrency.code}
                      >
                        {exchangeRate(foreignCurrency, localCurrency)}
                      </AssembleTypography>
                    ))}
                  </Box>
                }
              />
            )}
            {position?.type === PositionType.HOURLY && (
              <>
                {" "}
                <Divider className={classes.totalCompDivider} />
                <Box m={3} />
                <AssembleTypography
                  variant="productMicrocopy"
                  color="textSecondary"
                >
                  *Annual is based on a{" "}
                  {formatNumber(
                    compStructure.employmentHoursPerWeek *
                      compStructure.employmentWeeksPerYear
                  )}{" "}
                  hour work-week.
                </AssembleTypography>
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
);

export const ApprovalSheet = Object.assign(ApprovalSheetWithRef, {
  fragments: {
    position: gql`
      ${BandLabelValue.fragments.position}
      fragment ApprovalSheet_position on Position {
        ...BandLabelValue_position
      }
    `,
  },
});

function SectionHeader({ label }: { label: string }) {
  const classes = useStyles();

  return (
    <div className={classes.sectionHeader}>
      <div className={classes.sectionHeaderIcon} />
      <Typography className={classes.sectionHeaderText} variant="h4">
        {label}
      </Typography>
    </div>
  );
}

function CandidateSection({ data }: { data: OfferData }): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <SectionHeader label="Candidate" />
      <LabelValue
        label="Name"
        value={`${data.firstName ?? ""} ${data.lastName ?? ""}`}
      />
    </div>
  );
}

function PositionSection({ data }: { data: OfferData }): JSX.Element {
  const classes = useStyles();
  const { locationGroup, position, title, market } = data;
  const [marketName, locationName] = [market?.name, locationGroup?.name];

  const locationHeader = "Market / Location";
  const locationValue =
    isValidString(marketName) && isValidString(locationName)
      ? `${marketName}, ${locationName}`
      : isValidString(marketName)
        ? `${marketName}`
        : isValidString(locationName)
          ? `${locationName}`
          : undefined;

  return (
    <div className={classes.section}>
      <SectionHeader label="Position" />
      <div style={{ display: "flex", gap: "2rem" }}>
        <div style={{ flex: "0 0 50%" }}>
          {locationValue !== undefined && (
            <LabelValue label={locationHeader} value={locationValue} />
          )}
          <LabelValue label="Job Title" value={title ?? position?.name} />
          {position && position.jobCodes.length > 0 && (
            <LabelValue
              label={position.jobCodes.length > 1 ? "Job Codes" : "Job Code"}
              value={position.jobCodes.join(", ")}
            />
          )}
        </div>
        {position && (
          <div style={{ flex: "0 0 50%" }}>
            <LabelValue
              label="Department"
              value={position.ladder.department.name}
            />
            <LabelValue label="Ladder" value={position.ladder.name} />
            <LabelValue label="Position" value={position.name} />
            <LabelValue label="Level" value={position.level} />
          </div>
        )}
      </div>
    </div>
  );
}

function ApprovalSection({ data }: { data: OfferData }): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <SectionHeader label="Approval" />
      <LabelValue label="Job Code" value={data.jobCode} />
      <LabelValue label="Manager" value={data.managerName} />
      <LabelValue
        label="Hiring Decision Maker"
        value={data.decisionMakerName}
      />
      <LabelValue label="Approver" value={data.approverName} />
      {data.customFieldAnswers?.map((answer) => (
        <LabelValue
          key={answer.name}
          label={answer.name}
          value={answer.answer}
        />
      ))}
    </div>
  );
}
