import { FetchResult, gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  ImportRipplingPayrollCsv,
  ImportRipplingPayrollCsvVariables,
  MergeCategory,
  UpsertCartaConnectionConfig,
  UpsertCartaConnectionConfigVariables,
  UpsertManualMergeConnectionConfig,
  UpsertMergeConnectionConfig,
  UpsertMergeConnectionConfigVariables,
} from "../__generated__/graphql";
import { RipplingImportRecord } from "../components/Settings/Integrations/ManualHris/RipplingImportButton";
import { GET_MERGE_CONFIGS } from "../queries";

export const UPSERT_MERGE_CONFIG = gql`
  mutation UpsertMergeConnectionConfig($data: MergeConnectionConfigInput!) {
    upsertOneMergeConnectionConfig(data: $data) {
      id
    }
  }
`;

const UPSERT_CARTA_CONNECTION_CONFIG = gql`
  mutation UpsertCartaConnectionConfig($cartaRefreshToken: String!) {
    upsertCartaConnectionConfig(refreshToken: $cartaRefreshToken) {
      id
    }
  }
`;

export const UPSERT_MANUAL_MERGE_CONFIG = gql`
  mutation UpsertManualMergeConnectionConfig {
    upsertOneManualMergeConnectionConfig {
      id
      integrationName
    }
  }
`;

export const IMPORT_RIPPLING_PAYROLL_CSV = gql`
  mutation ImportRipplingPayrollCsv($data: [RipplingPayrollCsvInput!]!) {
    importRipplingPayrollCsv(data: $data) {
      recordCount
      employeeCount
    }
  }
`;

export function useUpsertMergeConnectionConfig(): (
  originId: string,
  publicToken: string,
  category: MergeCategory
) => Promise<FetchResult<UpsertMergeConnectionConfig>> {
  const [upsertConfig] = useMutation<
    UpsertMergeConnectionConfig,
    UpsertMergeConnectionConfigVariables
  >(UPSERT_MERGE_CONFIG);

  return useCallback(
    (originId, publicToken, category) => {
      return upsertConfig({
        variables: {
          data: {
            originId,
            publicToken,
            category,
          },
        },
        refetchQueries: [
          {
            query: GET_MERGE_CONFIGS,
          },
        ],
      });
    },
    [upsertConfig]
  );
}

export function useUpsertCartaConnectionConfig(): (
  cartaRefreshToken: string
) => Promise<FetchResult<UpsertCartaConnectionConfig>> {
  const [upsertCartaConnectionConfig] = useMutation<
    UpsertCartaConnectionConfig,
    UpsertCartaConnectionConfigVariables
  >(UPSERT_CARTA_CONNECTION_CONFIG);

  return useCallback(
    (cartaRefreshToken) => {
      return upsertCartaConnectionConfig({
        variables: { cartaRefreshToken },
      });
    },
    [upsertCartaConnectionConfig]
  );
}

export function useUpsertManualMergeConnectionConfig(): () => Promise<
  FetchResult<UpsertManualMergeConnectionConfig>
> {
  const [upsertManualMergeConnectionConfig] =
    useMutation<UpsertManualMergeConnectionConfig>(UPSERT_MANUAL_MERGE_CONFIG);

  return upsertManualMergeConnectionConfig;
}

export function useImportRipplingPayrollCsv(): (
  records: RipplingImportRecord[]
) => Promise<FetchResult<ImportRipplingPayrollCsv>> {
  const [importRipplingPayrollCsv] = useMutation<
    ImportRipplingPayrollCsv,
    ImportRipplingPayrollCsvVariables
  >(IMPORT_RIPPLING_PAYROLL_CSV);

  return useCallback(
    (records: RipplingImportRecord[]) => {
      return importRipplingPayrollCsv({
        variables: {
          data: records,
        },
      });
    },
    [importRipplingPayrollCsv]
  );
}
