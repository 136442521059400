import { Fab, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { PlusCircleIcon } from "src/components/AssembleIcons/Brand/PlusCircleIcon";
import { AccessBoundary } from "../../components/AccessBoundary";
import { BLUE_2 } from "../../theme";
import { Noun } from "../../__generated__/graphql";
import { DepartmentForm } from "../DepartmentDetail/DepartmentForm";

export function DepartmentListDepartmentCreator(): JSX.Element {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <AccessBoundary scope="global" verb="edit" noun={Noun.JobStructure}>
        <Tooltip title="Add Department" placement="top">
          <Fab
            centerRipple={true}
            color="primary"
            onClick={handleClickOpen}
            size="medium"
          >
            <PlusCircleIcon color={BLUE_2} width="24px" height="24px" />
          </Fab>
        </Tooltip>
      </AccessBoundary>
      {open && <DepartmentForm mode="create" onCancel={handleClose} />}
    </>
  );
}
