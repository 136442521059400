import { gql, useLazyQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { useMemo } from "react";
import { DownloadExportIcon } from "src/components/AssembleIcons/Brand/DownloadExportIcon";
import {
  AssembleButton,
  getIconProps,
} from "../../components/AssembleButton/AssembleButton";
import { useAuth } from "../../components/Auth/AuthContext";
import { CsvDownloader } from "../../components/CsvExport/CsvDownloader";
import { ExplanatoryTooltip } from "../../components/ExplanatoryTooltip";
import { FilterParam, getNumericListParam } from "../../models/FilterParams";
import {
  ImmutableURLSearchParams,
  useURLSearchParams,
} from "../../models/URLSearchParams";
import { theme } from "../../theme";
import {
  ExportAllEmployeesInput,
  ExportAllEmployeesInputReports,
  ExportEmployeeLevelingStatus,
  GetAllEmployees,
} from "../../__generated__/graphql";

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: theme.spacing(1.5),
  },
}));

const employeesQuery = gql`
  query GetAllEmployees($input: ExportAllEmployeesInput) {
    exportAllEmployees(input: $input) {
      data
    }
  }
`;

export const ExportCSVButton = ({
  disabled = false,
}: {
  disabled: boolean;
}) => {
  const classes = useStyles();
  const { organization } = useAuth();
  const [getEmployees] = useLazyQuery<GetAllEmployees>(employeesQuery);
  const urlSearchParams = useURLSearchParams();

  const exportOptions = useMemo(
    () => getExportOptions(urlSearchParams),
    [urlSearchParams]
  );

  return (
    <ExplanatoryTooltip
      title="Export currently viewed employees"
      body="Export the filtered dataset of your employees and their positions, salaries, and compa-ratios."
    >
      <span className={classes.root}>
        <CsvDownloader
          fetch={() =>
            getEmployees({
              variables: { input: exportOptions },
            }).then((res) => res.data?.exportAllEmployees.data)
          }
          filename={`${organization?.name ?? "All"} Employees`}
        >
          {(onDownload) => (
            <AssembleButton
              onClick={onDownload}
              variant="outlined"
              size="small"
              disabled={disabled}
              startIcon={
                <DownloadExportIcon {...getIconProps({ size: "small" })} />
              }
            />
          )}
        </CsvDownloader>
      </span>
    </ExplanatoryTooltip>
  );
};

function getExportOptions(
  url: ImmutableURLSearchParams
): ExportAllEmployeesInput {
  return {
    managerIds: getNumericListParam(url, FilterParam.MANAGER),
    departmentIds: getNumericListParam(url, FilterParam.DEPARTMENT),
    ladderIds: getNumericListParam(url, FilterParam.LADDER),
    levels: getNumericListParam(url, FilterParam.LEVEL),
    locationGroupIds: getNumericListParam(url, FilterParam.LOCATION),
    reports: url.has(FilterParam.REPORTS)
      ? (url.get(FilterParam.REPORTS) as ExportAllEmployeesInputReports)
      : undefined,
    levelingStatus: url.has(FilterParam.TAB)
      ? (url
          .get(FilterParam.TAB)
          ?.toLowerCase() as ExportEmployeeLevelingStatus)
      : ExportEmployeeLevelingStatus.all,
  };
}
