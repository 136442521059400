import { gql } from "@apollo/client";
import { partition } from "@asmbl/shared/utils";
import { makeStyles, Typography } from "@material-ui/core";
import { ChevronRightIcon } from "src/components/AssembleIcons/Brand/ChevronRightIcon";
import { CompRecommendationStatus } from "../../../__generated__/graphql";
import { UserAvatar } from "../../../components/UserAvatar";
import { GRAY_4, GRAY_6, PURPLE_1, WHITE } from "../../../theme";
import { ApproverCardDetail } from "./ApproverCardDetail";
import { CompCycleReviewerWithStatus } from "./CompCycleReviewRequests";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: "88px",
    background: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px",
    padding: theme.spacing(3),
    position: "relative",

    "&:hover .MuiTypography-root, &:active .MuiTypography-root": {
      color: PURPLE_1,
    },
  },
  managerAvatar: {
    height: "48px",
    width: "48px",
  },
  managerPosition: {
    fontWeight: 400,
  },
  detailsContainer: {
    display: "flex",
    height: "100%",
    marginRight: theme.spacing(3),
  },
  verticalDivider: {
    width: "1px",
    height: theme.spacing(6),
    background: GRAY_6,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
  },
  warningIcon: {
    display: "flex",
    margin: theme.spacing(2),
  },
  card: {
    position: "relative",
    overflow: "hidden",
  },
  spacing: {
    margin: theme.spacing(1),
  },
  name: {
    flex: 1,
  },
  linearProgress: {
    height: "0.25rem",
  },
  lateTag: {
    position: "absolute",
    top: theme.spacing(0.5),
    left: theme.spacing(1),
  },
}));

type Props = {
  approver: CompCycleReviewerWithStatus;
};

export function ApproverCard({ approver }: Props): JSX.Element {
  const classes = useStyles();

  const [directReports, indirectReports] = partition(
    approver.assignees,
    (assignee) => assignee.subject.managerId === approver.id
  );

  const recs = directReports.filter(
    (assignee) =>
      assignee.compRecommendation !== null &&
      assignee.compRecommendation.reviewStatus !==
        CompRecommendationStatus.NEEDS_REVIEW &&
      assignee.compRecommendation.latestSubmittedItems.length !== 0
  );
  const recsWithReviews = indirectReports.filter(
    (assignee) =>
      // employee comp rec is not awaiting review, or
      assignee.compRecommendation !== null &&
      assignee.compRecommendation.latestSubmittedItems.at(0) !== undefined &&
      assignee.compRecommendation.reviewStatus !==
        CompRecommendationStatus.NEEDS_REVIEW
  );

  const isAwaitingReviews =
    approver.status === CompRecommendationStatus.NEEDS_REVIEW;

  return (
    <div className={classes.card}>
      <div className={classes.root}>
        <UserAvatar
          photoURL={approver.user?.photoURL}
          displayName={approver.displayName}
          size="mediumSmall"
        />

        <div className={classes.spacing} />
        <div className={classes.name}>
          <Typography
            variant="h6"
            color={isAwaitingReviews ? "textPrimary" : "textSecondary"}
          >
            {approver.displayName}
          </Typography>
          <Typography
            className={classes.managerPosition}
            variant="h6"
            color="textSecondary"
          >
            {approver.activeEmployment?.jobTitle ?? ""}
          </Typography>
        </div>
        <ApproverCardDetail
          label="Requested"
          recsTotal={recs.length}
          reportsTotal={directReports.length}
          isValueTextColorPrimary={isAwaitingReviews}
          tooltipBody="This is the # of compensation requests this person needs to make for the people who roll up to them."
        />
        <ApproverCardDetail
          label="Reviewed"
          recsTotal={recsWithReviews.length}
          reportsTotal={indirectReports.length}
          isValueTextColorPrimary={isAwaitingReviews}
          tooltipBody="These are the compensation requests of the indirect reports that have been submitted to this person to review."
        />
        <div className={classes.verticalDivider} />
        <ChevronRightIcon color={GRAY_4} height="24px" width="24px" />
      </div>
    </div>
  );
}

ApproverCard.fragments = {
  reviewer: gql`
    fragment ApproverCard_employee on Employee2 {
      id
      displayName
      activeEmployment {
        id
        jobTitle
      }
      location {
        id
        name
      }
      user {
        id
        photoURL
      }
    }
  `,
};
