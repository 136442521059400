import { ColorableSVG } from "../ColorableSVG";
import { AssembleIconProps } from "../types";

export const InsightsIcon = ({
  height = "24px",
  width = "24px",
  ...props
}: AssembleIconProps): JSX.Element => (
  <ColorableSVG {...props}>
    <svg
      aria-label="Insights"
      viewBox="0 0 32 32"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M12.0002 24.5H20.0002"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M16.0002 23.5L16.0002 16"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M16.0002 16L14.0002 13.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M16.0002 16L18.0002 13.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M13.0002 29H19.0002"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M9.38584 20.5001C7.86244 19.1565 6.78431 17.3809 6.29491 15.4096C5.8055 13.4382 5.92805 11.3645 6.64622 9.46448C7.3644 7.56445 8.64415 5.92819 10.3152 4.77341C11.9862 3.61864 13.9693 3.00012 16.0005 3.00016C18.0317 3.0002 20.0148 3.6188 21.6858 4.77364C23.3568 5.92848 24.6364 7.5648 25.3545 9.46485C26.0726 11.3649 26.1951 13.4386 25.7056 15.4099C25.2161 17.3813 24.1379 19.1569 22.6145 20.5003"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ColorableSVG>
);
