import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { CloseCircleIcon } from "src/components/AssembleIcons/Brand/CloseCircleIcon";
import { MAX_BENEFITS_PER_PACKAGE } from "../../../../models/Benefits";
import { WHITE } from "../../../../theme";

interface Props {
  headline: string;
  name?: string;
  onChangeName: (name: string) => unknown;
}

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(3),
    background: WHITE,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerLeft: {
    flexBasis: "33%",
    textAlign: "left",
  },
  headerCenter: {
    flexBasis: "33%",
    textAlign: "center",
  },
  headerRight: {
    flexBasis: "33%",
    textAlign: "right",
  },

  packageNameField: {
    width: "25rem",
  },

  backButton: {
    position: "fixed",
    top: theme.spacing(4),
    right: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: theme.spacing(5),
    minHeight: theme.spacing(4),
  },
}));

export function BenefitsEditorHeader({
  headline,
  name = "",
  onChangeName,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <Box className={classes.headerLeft}>
        <Typography variant="h5">{headline}</Typography>
        <Typography variant="subtitle1">
          Packages can contain up to {MAX_BENEFITS_PER_PACKAGE} benefits.
        </Typography>
      </Box>

      <Box className={classes.headerCenter}>
        <TextField
          className={classes.packageNameField}
          label="Package Name"
          placeholder="i.e. - Engineering Benefits, U.S. Employee Benefits"
          value={name}
          variant="outlined"
          onChange={(e) => onChangeName(e.target.value)}
        />
      </Box>

      <Box className={classes.headerRight}>
        <Button
          className={classes.backButton}
          component={Link}
          to="/settings/benefits"
          variant="outlined"
        >
          <CloseCircleIcon width="24px" height="24px" />
        </Button>
      </Box>
    </Box>
  );
}
