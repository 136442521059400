import { makeStyles, TableCell, TextField } from "@material-ui/core";
import { GRAY_1, GRAY_5, GRAY_6, PURPLE_1, WHITE } from "src/theme";

type Props = {
  newTitle: string | null;
  setNewTitle: (newTitle: string | null) => unknown;
  colSpan?: number;
};

const useStyles = makeStyles((theme) => ({
  tableCell: {
    borderTop: `1px solid ${GRAY_6} !important`,
    padding: theme.spacing(0.25, 0, 0.5, 1),
  },
  input: {
    cursor: "pointer",
    fontSize: "0.875rem",
    color: GRAY_1,
    padding: theme.spacing(1, 1.5),
  },
  inputComponent: {
    fontSize: "0.8125rem",
    borderRadius: "4px",
    border: `1px solid ${GRAY_5}`,
    boxShadow: "inset 0px 1px 4px rgba(54, 70, 87, 0.14)",
    transition: "none",
    "&:hover": {
      background: WHITE,
      border: `1 solid ${PURPLE_1}`,
      transition: "none",
      boxShadow: "inset 0px 1px 4px rgba(54, 70, 87, 0.14)",
    },

    "&:focus-within, &.Mui-focused": {
      border: `1px solid ${PURPLE_1}`,
      paddingLeft: "1px",
      boxShadow: `0px 0px 0px 3px rgba(100, 91, 255, 0.3), inset 0px 1px 4px rgba(54, 70, 87, 0.14)`,
      transition: "none",
      background: WHITE,
    },
  },
}));

export function NewTitleInputCell({
  newTitle,
  setNewTitle,
  colSpan,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <TableCell
      classes={{ root: classes.tableCell }}
      colSpan={colSpan}
      role="gridcell"
    >
      <TextField
        fullWidth
        title={newTitle ?? undefined}
        InputProps={{ className: classes.inputComponent }}
        inputProps={{ className: classes.input, "aria-label": "New title" }}
        InputLabelProps={{ shrink: true }}
        name="newTitle"
        label="New title"
        size="small"
        variant="outlined"
        value={newTitle}
        onChange={(e) => setNewTitle(e.target.value)}
      />
    </TableCell>
  );
}
