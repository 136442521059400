import { gql } from "@apollo/client";
import { makeStyles, MuiThemeProvider } from "@material-ui/core";
import { HierarchyNode, select } from "d3";
import { OrgChart } from "d3-org-chart";
import { useEffect, useMemo, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { useNavigate } from "react-router-dom";
import { OrganizationHierarchy_employee as Employee } from "src/__generated__/graphql";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { GRAY_5, theme } from "src/theme";
import { useAuth } from "../Auth/AuthContext";
import { ChartButtonBar } from "./components/ChartButtonBar";
import { EmployeeCard } from "./components/EmployeeCard";
import { EmployeeCardButton } from "./components/EmployeeCardButton";
import { EMPLOYEE_NODE_HEIGHT, EMPLOYEE_NODE_WIDTH } from "./shared";
import { sanitizeData } from "./shared/utils";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
}));

type Props<T extends Employee> = {
  data: T[];
};

export function OrganizationHierarchy<T extends Employee>({
  data,
}: Props<T>): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();
  const { organization } = useAuth();
  const { width, height } = useWindowDimensions();

  const d3Container = useRef<null | HTMLDivElement>(null);

  const chart = useMemo(() => new OrgChart<Employee>(), []);

  const extensibleData = useMemo(() => {
    const sanitizedData = sanitizeData(data).map((employee) => ({
      ...employee,
    }));

    const syntheticRoot = sanitizedData.find(
      ({ parentId }) => parentId == null
    );

    // the synthetic root is the root node that we create to ensure
    // an absolute node, we know it exists but we are just adding
    // this fallback check (mostly to please TS)
    if (syntheticRoot == null) {
      return sanitizedData;
    }

    const organizationHeader =
      organization?.name != null ? organization.name : "Organization";

    return [
      { ...syntheticRoot, displayName: organizationHeader } as T,
      ...sanitizedData.filter(({ parentId }) => parentId != null),
    ];
  }, [data, organization]);

  useEffect(() => {
    if (d3Container.current != null) {
      chart
        .container(d3Container.current as unknown as string)
        .data(extensibleData)
        .nodeWidth(() => EMPLOYEE_NODE_WIDTH)
        .nodeHeight(() => EMPLOYEE_NODE_HEIGHT)
        .svgHeight(height)
        .svgWidth(width)
        .buttonContent((params) => {
          return ReactDOMServer.renderToString(
            <MuiThemeProvider theme={theme}>
              <EmployeeCardButton {...params} />
            </MuiThemeProvider>
          );
        })
        .nodeContent((node) => {
          return ReactDOMServer.renderToString(
            <MuiThemeProvider theme={theme}>
              <EmployeeCard node={node} />
            </MuiThemeProvider>
          );
        })
        .linkUpdate(() =>
          select(d3Container.current).selectAll("path").style("stroke", GRAY_5)
        )
        .onNodeClick((node: HierarchyNode<Employee>) => {
          const nodeId = node.data.id;
          const state = chart.getChartState();
          const { allNodes } = state;

          const currentNode = allNodes.find(
            (node) => node.id?.toString() === nodeId.toString()
          );

          if (currentNode != null && currentNode.id != null) {
            navigate(`?employee=${currentNode.id}`);
          }
        })
        .render()
        .expandAll();
    }
  }, [chart, extensibleData, width, height, d3Container, navigate]);

  return (
    <div style={{ width, height }}>
      <ChartButtonBar chart={chart} />
      <div className={classes.container} ref={d3Container} />
    </div>
  );
}

OrganizationHierarchy.fragments = {
  employee: gql`
    fragment OrganizationHierarchy_employee on Employee2 {
      id
      parentId: managerId
      displayName
      employmentStatus
      _directSubordinates: directReportsCount
      _totalSubordinates: totalReportsCount
      activeEmployment {
        id
        jobTitle
      }
    }
  `,
};
