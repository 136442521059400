import { cx } from "@emotion/css";
import { makeStyles, TableCell, TableCellProps } from "@material-ui/core";
import { ReactNode } from "react";
import { AssembleTypography } from "../../AssembleTypography";
import {
  SortableTableHeaderCell,
  SortableTableHeaderCellProps,
} from "../../SortableTable";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "2rem",
    background: "none",
    "&:not(:last-child)": {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
}));

export function WireTableHeaderCell<T>(
  props: SortableTableHeaderCellProps<T>
): JSX.Element {
  const classes = useStyles();
  return (
    <SortableTableHeaderCell<T>
      {...props}
      className={cx(classes.root, props.className)}
      cellTitle={
        <AssembleTypography variant="productEyebrowMedium">
          {props.cellTitle}
        </AssembleTypography>
      }
    />
  );
}

export function WireTableUnsortableHeaderCell(
  props: { cellTitle?: ReactNode } & TableCellProps
): JSX.Element {
  const { cellTitle, className, ...rest } = props;

  const classes = useStyles();
  return (
    <TableCell variant="head" className={cx(classes.root, className)} {...rest}>
      <AssembleTypography variant="productTableHeader">
        {cellTitle}
      </AssembleTypography>
    </TableCell>
  );
}
