import { gql } from "@apollo/client";
import { JobStructureScope } from "@asmbl/shared/permissions";
import { mapMaybe } from "@asmbl/shared/utils";
import { cx } from "@emotion/css";
import {
  ButtonBase,
  IconButton,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { useMemo } from "react";
import {
  AutomatedManagerAccessType,
  GetDepartments,
  AutomatedManagerAccessMarketRow_organization as Organization,
  AutomatedManagerAccessTable_report as Report,
} from "src/__generated__/graphql";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { ClickPropagationBoundary } from "src/components/ClickPropagationBoundary";
import { WireTableCell } from "src/components/Table/WireTable";
import { PURPLE_1 } from "src/theme";
import { JobStructureSelectionRows } from "./JobStructureSelectionRows";
import { NodeLabel } from "./NodeLabel";

const useStyles = makeStyles((theme) => ({
  middleCell: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  row: {
    cursor: "pointer",
    position: "relative",
    "&:hover, &:focus, &:focus-within": {
      "& .MuiTableCell-body": {
        color: PURPLE_1,
        "& $nodeLabel": {
          color: PURPLE_1,
        },
      },
    },
  },
  marketCell: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  safariPositionFix: {
    position: "relative",
  },
  showDetailsButton: {
    transition: "transform 0.2s ease-in-out",
    transform: "rotate(-90deg)",
  },
  areDetailsShowing: {
    transform: "rotate(0deg)",
  },
  nodeLabel: {
    display: "inline",
  },
}));

type Props = {
  reports: Report[];
  showDetails: boolean;
  jobStructure: GetDepartments;
  organization: Organization;
  onToggleDetails: () => unknown;
};

export function AutomatedManagerAccessMarketRow({
  reports,
  jobStructure,
  showDetails,
  organization,
  onToggleDetails,
}: Props): JSX.Element {
  const classes = useStyles();

  const accessibleCurrentPositionIds = mapMaybe(
    reports,
    (report) => report.activeEmployment?.positionId
  );

  const accessibleNextPositionIds = reports.flatMap((report) =>
    report.nextPositions.map((p) => p.id)
  );

  const accessiblePositionIds = useMemo(
    () =>
      organization.permissionSettings.automatedManagerAccess ===
      AutomatedManagerAccessType.REPORTS_CURRENT_AND_NEXT_POSITIONS
        ? [...accessibleCurrentPositionIds, ...accessibleNextPositionIds]
        : accessibleCurrentPositionIds,
    [accessibleCurrentPositionIds, accessibleNextPositionIds, organization]
  );

  const hasNestedData = accessiblePositionIds.length > 0;

  const departments = useMemo(() => {
    const departments: GetDepartments["departments"] = [];

    jobStructure.departments.forEach((department) => {
      const isAccessible = department.ladders.some((ladder) =>
        ladder.positions.some((position) =>
          accessiblePositionIds.includes(position.id)
        )
      );

      if (isAccessible) {
        departments.push(department);
      }
    });

    return departments;
  }, [jobStructure, accessiblePositionIds]);

  const scope: JobStructureScope = {
    global: false,
    departmentIDs: [],
    ladderIDs: [],
    positionIDs: accessiblePositionIds,
  };

  return (
    <>
      <ButtonBase
        component={TableRow}
        className={classes.row}
        onClick={hasNestedData ? onToggleDetails : undefined}
      >
        <WireTableCell className={classes.middleCell}>
          <div className={classes.marketCell}>
            {hasNestedData ? (
              <ClickPropagationBoundary>
                <IconButton size="small" onClick={onToggleDetails}>
                  <ChevronDownIcon
                    inherit
                    className={cx(classes.showDetailsButton, {
                      [classes.areDetailsShowing]: showDetails,
                    })}
                  />
                </IconButton>
              </ClickPropagationBoundary>
            ) : null}
            <span>All Markets</span>
          </div>
        </WireTableCell>
        <WireTableCell className={classes.middleCell}>
          <div>
            {!hasNestedData && "No access policies"}
            {departments.map((d, i) => (
              <NodeLabel
                key={d.name}
                isInherited={!departments.includes(d)}
                className={classes.nodeLabel}
              >
                {d.name}
                {i < departments.length - 1 && ", "}
              </NodeLabel>
            ))}
          </div>
        </WireTableCell>
        <WireTableCell colSpan={2}>
          <div className={classes.safariPositionFix}>
            {hasNestedData ? "A subset of ladders and positions" : ""}
          </div>
        </WireTableCell>
      </ButtonBase>
      {showDetails && (
        <JobStructureSelectionRows
          scope={scope}
          inheritedScope={undefined}
          jobStructure={jobStructure}
          spacerColumns={1}
        />
      )}
    </>
  );
}

AutomatedManagerAccessMarketRow.fragments = {
  organization: gql`
    fragment AutomatedManagerAccessMarketRow_organization on Organization {
      id
      permissionSettings {
        id
        automatedManagerAccess
      }
    }
  `,
  report: gql`
    fragment AutomatedManagerAccessMarketRow_report on Employee {
      id
      activeEmployment {
        id
        positionId
      }
      nextPositions {
        id
      }
    }
  `,
};
