import { Fab, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { PlusCircleIcon } from "src/components/AssembleIcons/Brand/PlusCircleIcon";
import { BLUE_2 } from "src/theme";
import { AccessBoundary } from "../../components/AccessBoundary";
import { useCreatePosition } from "../../mutations/Position";
import { Noun } from "../../__generated__/graphql";
import { EditPositionForm } from "../PositionDetail/EditPositionForm";

type Props = { ladderId: number };

export function LadderPositionCreator({ ladderId }: Props): JSX.Element {
  const [open, setOpen] = useState(false);
  const createPosition = useCreatePosition(ladderId);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <AccessBoundary scope="global" verb="edit" noun={Noun.JobStructure}>
        <Tooltip title="Add Position" placement="top">
          <Fab color="primary" onClick={handleOpen} size="medium">
            <PlusCircleIcon color={BLUE_2} width="24px" height="24px" />
          </Fab>
        </Tooltip>
      </AccessBoundary>
      {open && (
        <EditPositionForm
          mode="create"
          onSubmit={createPosition}
          onCancel={handleClose}
        />
      )}
    </>
  );
}
