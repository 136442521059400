import { gql, useQuery } from "@apollo/client";
import { Box, LinearProgress } from "@material-ui/core";
import { useMemo } from "react";
import { FilterParam, getNumericListParam } from "src/models/FilterParams";
import { useURLSearchParams } from "src/models/URLSearchParams";
import {
  Employee2,
  Employee2sMeta,
  Position,
  ReportsLoadingBoundaryQuery,
} from "../../../__generated__/graphql";
import EmptyNoReportsSvg from "../../../assets/svgs/illustrations/empty-state-no-reports.svg?react";
import { EmptyTableView } from "../../../components/EmptyTableView";
import {
  compTypeToExclusion,
  useReportsTableContext,
} from "../Context/TableContext";
import { PeopleTabParam } from "../PeopleTabParam";
import { PeopleTabSwitch } from "../PeopleTabSwitch";
import { YourTeamView } from "./YourTeamView";

interface Props {
  managerId: number;
}
export function ReportsLoadingBoundary({ managerId }: Props): JSX.Element {
  const { offset, limit, sortBy, sortDir, searchQuery, compType } =
    useReportsTableContext();
  const urlSearchParams = useURLSearchParams();
  const isFilteringUnleveled = (
    urlSearchParams.get(FilterParam.LEVEL) ?? ""
  ).includes("null");
  const metaStatus = isFilteringUnleveled
    ? { metaStatus: PeopleTabParam.LEVELING }
    : {};
  const reportsParam =
    (urlSearchParams.get(FilterParam.REPORTS) ?? "").includes("all") ||
    (urlSearchParams.get(FilterParam.REPORTS) ?? "").includes(",")
      ? undefined
      : urlSearchParams.get(FilterParam.REPORTS);
  const payTypeParam =
    (urlSearchParams.get(FilterParam.PAY_TYPE) ?? "").includes("all") ||
    (urlSearchParams.get(FilterParam.PAY_TYPE) ?? "").includes(",")
      ? undefined
      : urlSearchParams.get(FilterParam.PAY_TYPE);

  const managerParamList = useMemo(
    () => getNumericListParam(urlSearchParams, FilterParam.MANAGER),
    [urlSearchParams]
  );
  const departmentParam = useMemo(
    () => getNumericListParam(urlSearchParams, FilterParam.DEPARTMENT),
    [urlSearchParams]
  );
  const ladderParam = useMemo(
    () => getNumericListParam(urlSearchParams, FilterParam.LADDER),
    [urlSearchParams]
  );
  const levelParam = useMemo(
    () => getNumericListParam(urlSearchParams, FilterParam.LEVEL),
    [urlSearchParams]
  );
  const locationParam = useMemo(
    () => getNumericListParam(urlSearchParams, FilterParam.LOCATION),
    [urlSearchParams]
  );
  const { data, loading, previousData } = useQuery<ReportsLoadingBoundaryQuery>(
    query,
    {
      variables: {
        managerId,
        offset,
        limit,
        sort: {
          sortBy,
          sortDir,
        },
        filter: {
          displayName: searchQuery,
          managerIds: managerParamList,
          reports: reportsParam,
          payType: payTypeParam,
          departmentIds: departmentParam,
          ladderIds: ladderParam,
          levelIds: levelParam,
          locationIds: locationParam,
          excludeCashComponents: compTypeToExclusion(compType),
          ...metaStatus,
        },
      },
    }
  );

  // Ensures that there's always usable data while loading the next query
  const relevantData = data ?? previousData;

  if (!relevantData) {
    return loading ? <LinearProgress /> : <ReportsEmptyState />;
  }

  return (
    <Box px={2} py={3}>
      <YourTeamView
        isLoading={loading}
        totalCount={relevantData.employee2s.meta.counts.filteredTotal}
        positions={relevantData.positions as Position[]}
        reports={relevantData.employee2s.employees as unknown as Employee2[]}
        dropdownOptions={
          (relevantData.employee2s.meta ?? {}) as unknown as Omit<
            Employee2sMeta,
            "counts"
          >
        }
      />
    </Box>
  );
}

export function ReportsEmptyState(): JSX.Element {
  return (
    <Box pt={10}>
      <EmptyTableView
        message={
          <>
            We couldn't find anyone that reports to you. Please contact your
            Assemble administrator if you feel something is awry.
          </>
        }
        svg={<EmptyNoReportsSvg />}
        width="30%"
      />
    </Box>
  );
}

const query = gql`
  ${PeopleTabSwitch.fragments.employee}
  query ReportsLoadingBoundaryQuery(
    $managerId: Int!
    $offset: Int
    $limit: Int
    $sort: Employee2sSort
    $filter: Employee2sFilter
  ) {
    employee2s(
      offset: $offset
      limit: $limit
      sort: $sort
      filter: $filter
      managerId: $managerId
    ) {
      meta {
        counts {
          filteredTotal
        }
        managers {
          id
          displayName
        }
        departments {
          organizationId
          id
          name
        }
        ladders {
          id
          name
        }
        locations {
          id
          name
        }
        levels
      }
      employees {
        ...PeopleTabSwitch_employee
        id
        displayName
        activeAt
        employmentStatus
        activeCashCompensation {
          activeAt
          employeeId
          type
          annualCashEquivalent
          hourlyCashEquivalent
          unit
          percentOfSalary
        }
        adjustedCashBands {
          id
          name
          bandPoints {
            name
            value {
              ... on CashValue {
                annualRate
                hourlyRate
                currencyCode
              }
            }
          }
        }
        equityHoldings {
          id
          grants {
            id
            name
            issueDate
            vestingStartDate
            vestingScheduleDescription
            unitCount
            price
            equityType
            optionType
            awardType
          }
          totalUnitCount
          totalGrossHoldingValue
        }
        activeEmployment {
          id
          positionId
          salary
          jobTitle
          levelingCode
          levelingMethod
          payPeriod
          position {
            id
            name
            level
            ladder {
              id
              name
              department {
                id
                name
              }
            }
          }
        }
        employments {
          id
          positionId
        }
        user {
          id
          photoURL
        }
        manager: minimalManager {
          id
          displayName
          user {
            id
            photoURL
          }
        }
        location {
          id
          name
        }
      }
    }

    positions {
      id
      name
      level
      ladder {
        id
        name
        department {
          id
          name
        }
      }
      jobCodes
    }
  }
`;
