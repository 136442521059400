import { SSO_AUTH_EXPIRATION_S, WORK_OS_COOKIE } from "@asmbl/shared/auth";
import { useCallback } from "react";
import { useCookies } from "react-cookie";

export function useSsoCookie(): {
  cookie: string | undefined;
  setCookie: (value: string) => void;
  removeCookie: () => void;
} {
  const [cookies, set, remove] = useCookies([WORK_OS_COOKIE]);

  const setCookie = useCallback(
    (value: string) =>
      set(WORK_OS_COOKIE, value, {
        path: "/",
        maxAge: SSO_AUTH_EXPIRATION_S,
        sameSite: "strict",
      }),
    [set]
  );

  const removeCookie = useCallback(
    () => remove(WORK_OS_COOKIE, { path: "/" }),
    [remove]
  );

  const cookie = cookies.workosToken as string | undefined;

  return { cookie, setCookie, removeCookie };
}
