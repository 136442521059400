import { round } from "@asmbl/shared/utils";
import uniqBy from "lodash/uniqBy";
import {
  BudgetGuidance_budgetGuide as BudgetGuide,
  MeritGuidance_meritAdjustmentGuide as MeritGuide,
} from "src/__generated__/graphql";

export const getOrderedRanges = (
  guides: BudgetGuide[]
): { id: number; rangeStart: number }[] =>
  uniqBy(
    guides.map((guide) => guide.budgetRange),
    "id"
  )
    .sort((a, b) => (a.rangeStart < b.rangeStart ? -1 : 1))
    .map((range) => ({ id: range.id, rangeStart: range.rangeStart }));

export const buildBudgetMatrix = (
  guides: BudgetGuide[]
): { label: string | number; id?: number }[][] => {
  const orderedPerfRatings = uniqBy(
    guides.map((guide) => guide.budgetPerfRatingOption),
    "id"
  ).sort((a, b) => (a.rank < b.rank ? -1 : 1));

  const orderedRanges = uniqBy(
    guides.map((guide) => guide.budgetRange),
    "id"
  ).sort((a, b) => (a.rangeStart < b.rangeStart ? -1 : 1));

  const budgetMatrix: { label: string | number; id?: number }[][] = [];

  orderedPerfRatings.forEach((rating, ratingIdx) => {
    budgetMatrix[ratingIdx] = [{ label: rating.name, id: rating.id }];
    orderedRanges.forEach((range) => {
      const guide = guides.find(
        (guide) =>
          guide.budgetPerfRatingOptionId === rating.id &&
          guide.budgetRangeId === range.id
      );
      if (guide != null) {
        budgetMatrix[ratingIdx].push({
          label: round(guide.percent * 100, 3),
          id: guide.id,
        });
      } else {
        budgetMatrix[ratingIdx].push({ label: "-" });
      }
    });
  });

  return budgetMatrix;
};

export const buildMeritMatrix = (
  guides: MeritGuide[]
): { label: string | number; id?: number }[][] => {
  const orderedPerfRatings = uniqBy(
    guides.map((guide) => guide.perfRatingOption),
    "id"
  ).sort((a, b) => (a.rank < b.rank ? -1 : 1));

  const orderedRanges = uniqBy(
    guides.map((guide) => guide.meritAdjustmentRange),
    "id"
  ).sort((a, b) => (a.rangeStart < b.rangeStart ? -1 : 1));

  const budgetMatrix: { label: string; id?: number }[][] = [];

  orderedPerfRatings.forEach((rating, ratingIdx) => {
    budgetMatrix[ratingIdx] = [{ label: rating.name, id: rating.id }];
    orderedRanges.forEach((range) => {
      const guide = guides.find(
        (guide) =>
          guide.perfRatingOptionId === rating.id &&
          guide.meritAdjustmentRangeId === range.id
      );
      if (guide != null) {
        budgetMatrix[ratingIdx].push({
          label: round(guide.percent * 100, 3),
          id: guide.id,
        });
      } else {
        budgetMatrix[ratingIdx].push({ label: "-" });
      }
    });
  });

  return budgetMatrix;
};
