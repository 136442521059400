import { makeStyles } from "@material-ui/core";
import { AssembleTypography } from "src/components/AssembleTypography";
import { useAuth } from "src/components/Auth/AuthContext";
import { useURLSearchParams } from "src/models/URLSearchParams";
import { BLUE_3, SPLASH_BLUE, WHITE } from "src/theme";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    height: "40px",
    background: `linear-gradient(148.74deg, ${BLUE_3} 27.64%, ${SPLASH_BLUE} 87.25%)`,
    boxShadow: "0px 5px 15px 0px #0FC4FA4D",
  },
}));

type Props = { managerDisplayName: string | null | undefined };

export function SupportingManagerBanner({
  managerDisplayName,
}: Props): JSX.Element | null {
  const urlSearchParams = useURLSearchParams();
  const { permissions } = useAuth();
  const classes = useStyles();

  const isFilteringForManager = urlSearchParams.get("manager") != null;
  const isUserHRBP = permissions.isHRBP();

  if (!isFilteringForManager || !isUserHRBP) return null;

  return (
    <div className={classes.root}>
      <AssembleTypography variant="productSmallSemiBold" textColor={WHITE}>
        You're currently editing a comp request on behalf of&nbsp;
        {managerDisplayName != null ? managerDisplayName : "[Manager Name]"}
      </AssembleTypography>
    </div>
  );
}
