import { ButtonBase, makeStyles, Typography } from "@material-ui/core";
import { useRef } from "react";
import CsvImportIcon from "../../../assets/svgs/illustrations/csv-import.svg?react";
import { BLUE_2, GRAY_4, GRAY_6, GRAY_7, WHITE } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";

const useStyles = makeStyles((theme) => ({
  root: {
    background: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px",
    padding: theme.spacing(5),
  },
  illustratedBox: {
    display: "flex",
    margin: theme.spacing(4, 0, 2, 0),
  },
  illustratedBoxText: {
    flex: "1 1 auto",
  },
  illustratedBoxIllustration: {
    marginRight: "-20px", // This illustration has a little overhang
    flex: "0 0 auto",
  },
  listHeader: {
    fontWeight: "bold",
    letterSpacing: "-0.25px",
  },
  list: {
    paddingLeft: theme.spacing(3),
    letterSpacing: "-0.25px",
  },

  fileFormatBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: GRAY_7,
    padding: theme.spacing(2),
  },
  fileFormatTypography: {
    color: GRAY_4,
    fontSize: "12px",
    fontWeight: "bold",
    fontFamily: "Circular",
    lineHeight: "17px",
    letterSpacing: "0em",
    textTransform: "uppercase",
  },
  fileFieldsTypography: {
    color: BLUE_2,
    paddingLeft: theme.spacing(2),
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "OverpassMono",
  },
  downloadButtonBox: {
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
  downloadExampleButton: {
    fontSize: "0.75rem",
    letterSpacing: "-0.15px",
    textDecoration: "underline",
    color: GRAY_4,
  },
  fileFormatTitle: {
    textTransform: "uppercase",
    margin: "2px 10px 0 0",
  },
  fileFormatDescription: {
    textAlign: "center",
    fontFamily: "OverpassMono",
    fontSize: "0.8125rem",
    fontWeight: 600,
    lineHeight: "1.4",
  },
}));

interface Props {
  className: string;
}
export function PerfScoreExplanation({ className }: Props): JSX.Element {
  const classes = useStyles();
  const aRef = useRef<HTMLAnchorElement>(null);

  const downloadExampleCsvFile = (): void => {
    const file = new Blob(
      [
        `id,name,email,performanceScore,createdAt,updatedAt
    1,Shaggy Rogers,shaggy@mystery.example.com,3,2021-02-17 17:37:35.205,2021-02-17 17:37:35.206
    2,Daphne Blake,daphne@mystery.example.com,4,2021-02-17 17:37:35.206,2021-02-17 17:37:35.206
    3,Scooby Doo,scooby@mystery.example.com,1,2021-02-17 17:37:35.206,2021-02-17 17:37:35.206`,
      ],
      {
        type: "text/csv",
      }
    );
    const element = aRef.current;
    if (element !== null) {
      element.href = URL.createObjectURL(file);
      element.download = "ExampleImport.csv";
      element.click();
    }
  };

  return (
    <div className={`${classes.root} ${className}`}>
      <Typography>
        Bulk import your performance scores, and we’ll apply them to the
        appropriate people in your organization.
      </Typography>

      <div className={classes.illustratedBox}>
        <div className={classes.illustratedBoxText}>
          <Typography variant="body2" className={classes.listHeader}>
            File setup:
          </Typography>
          <Typography component="ul" variant="body2" className={classes.list}>
            <li>Each line should describe only one person</li>
            <li>Each person must have a unique email address</li>
            <li>Performance scores can be numeric or text-based</li>
            <li>Notes are optional</li>
          </Typography>
        </div>
        <div className={classes.illustratedBoxIllustration}>
          <CsvImportIcon />
        </div>
      </div>
      <div className={classes.fileFormatBlock}>
        <AssembleTypography
          className={classes.fileFormatTitle}
          variant="productEyebrowSmall"
          textColor={GRAY_4}
        >
          File Format:
        </AssembleTypography>
        <AssembleTypography
          className={classes.fileFormatDescription}
          textColor={BLUE_2}
        >
          Name, Email, Performance Score, Notes
        </AssembleTypography>
      </div>

      <div className={classes.downloadButtonBox}>
        <a hidden ref={aRef} />
        <ButtonBase
          className={classes.downloadExampleButton}
          onClick={downloadExampleCsvFile}
        >
          Download an example file
        </ButtonBase>
      </div>
    </div>
  );
}
