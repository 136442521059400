import { gql } from "@apollo/client";

export const UPSERT_OFFER_CONFIG = gql`
  mutation UpsertOfferConfig($data: OfferConfigInput!) {
    upsertOfferConfig(data: $data) {
      id
      organization {
        id
      }
      exitOutcomes
      xAxisAnnotations
      defaultOutcomeDescription
      equityCashInValuationCurrency
      customFields {
        name
        description
        variant
        options
      }
    }
  }
`;

export const ARCHIVE_OFFER = gql`
  mutation ArchiveOneOffer($id: Int!) {
    archiveOneOffer(id: $id) {
      id
    }
  }
`;

export const CREATE_VALUATION = gql`
  mutation CreateValuation($data: ValuationInput!) {
    createOneValuation(data: $data) {
      id
    }
  }
`;

export const UPSERT_COMP_STRUCTURE = gql`
  mutation UpsertCompStructure($data: CompStructureInput!) {
    upsertCompStructure(data: $data) {
      id
      cashBandTypes
      equityBandTypes
      employmentHoursPerWeek
      employmentWeeksPerYear
      allowHourlyEmployees
    }
  }
`;

export const UPSERT_PHILOSOPHY = gql`
  mutation UpsertPhilosophy($compPhilosophy: Json!) {
    upsertPhilosophy(data: { json: $compPhilosophy }) {
      id
      json
      author {
        id
        name
        email
      }
      isActive
      createdAt
    }
  }
`;

export const EMPLACE_ALL_CURRENCIES = gql`
  mutation EmplaceAllCurrencies($currencies: [CurrencyInput!]!) {
    emplaceAllCurrencies(data: $currencies) {
      id
      code
      exchangeRate
    }
  }
`;

export const UPDATE_EMPLOYMENT = gql`
  mutation UpdateEmployment($id: Int!, $data: EmploymentUpdateInput!) {
    updateOneEmployment(id: $id, data: $data) {
      id
      positionId
    }
  }
`;

export const UPDATE_ORG_PERMISSION_SETTINGS = gql`
  mutation UpdatePermissionSettings($data: PermissionSettingsInput!) {
    updatePermissionSettings(data: $data) {
      id
      canRecruitersViewAnonymizedStatistics
      teamCompensationAccess
      employeeBandAccess
    }
  }
`;
