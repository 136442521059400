import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useMemo } from "react";
import { LevelIcon } from "src/components/AssembleIcons/Brand/LevelIcon";
import { GRAY_6, WHITE } from "../../../theme";
import { ArrayValue } from "../../../utils";
import { GetLadders } from "../../../__generated__/graphql";
import { ColoredFullLadder } from "./SelectedLaddersLoader";
import { ExpandMore } from "@material-ui/icons";

type Ladder = ArrayValue<GetLadders["ladders"]>;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    height: "47px",
    backgroundColor: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderBottom: "none",
    borderRadius: "4px 4px 0px 0px",
  },
  levelSelectContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  levelSelect: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  bandNameContainer: {
    display: "flex",
    overflowX: "auto",
    overflowY: "hidden",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  selectRoot: {
    paddingLeft: theme.spacing(1),
    borderRadius: "4px !important",
    fontSize: ".875rem",
  },
}));

type CompareToolbarProps = {
  ladders: ColoredFullLadder[];
  excludedLevels: number[];
  handleLevelsExclusion: (excludedLevels: number[]) => void;
};

export function CompareToolbar({
  ladders,
  excludedLevels,
  handleLevelsExclusion,
}: CompareToolbarProps): JSX.Element {
  const classes = useStyles();

  const levels = useMemo(
    () => getUniqueLevels(ladders.map((l) => l.ladder)),
    [ladders]
  );
  const selectedLevels = useMemo(
    () => getSelectedLevels(levels, excludedLevels),
    [levels, excludedLevels]
  );

  const levelMenuOptions = ["All", ...levels.map((level) => level.toString())];

  const handleLevelFilterChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newSelectedLevels = computeSelectedLevels(
      event.target.value as string[],
      selectedLevels
    );
    const excludedLevels = computeExcludedLevels(newSelectedLevels, levels);
    handleLevelsExclusion(excludedLevels);
  };

  const renderLevelsSelect = (selected: string[]) =>
    selected.length > 0
      ? `Levels: ${selected.sort((a, b) => Number(b) - Number(a)).join(", ")}`
      : "Levels: None";

  return (
    <Box
      className={classes.toolbar}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      flex={1}
    >
      <Box className={classes.levelSelectContainer}>
        <FormControl className={classes.levelSelect}>
          <Select
            multiple
            displayEmpty
            value={selectedLevels}
            variant="standard"
            disableUnderline
            onChange={handleLevelFilterChange}
            renderValue={(selected) => renderLevelsSelect(selected as string[])}
            startAdornment={<LevelIcon />}
            MenuProps={{
              getContentAnchorEl: null, //Fixes menu jumping around https://github.com/mui-org/material-ui/issues/19245
            }}
            IconComponent={ExpandMore}
            classes={{ root: classes.selectRoot }}
          >
            {levelMenuOptions.map((level) => (
              <MenuItem key={level} value={level} disableRipple>
                <Checkbox checked={selectedLevels.includes(level)} />
                <Box m={0.5} />
                <ListItemText primary={level} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Divider orientation="vertical" flexItem />
        <Box />
      </Box>
    </Box>
  );
}

function getUniqueLevels(ladders: Ladder[]) {
  return [
    ...new Set(
      ladders
        .flatMap((ladder) => ladder.positions)
        .flatMap((position) => Number(position.level))
        .sort((a, b) => b - a)
    ),
  ];
}

function getSelectedLevels(levels: number[], excludedLevels: number[]) {
  return excludedLevels.length === 0
    ? ["All"]
    : levels
        .filter((level) => !excludedLevels.includes(level))
        .map((level) => level.toString());
}

function computeSelectedLevels(
  newSelectedLevels: string[],
  oldSelectedLevels: string[]
) {
  if (newSelectedLevels.includes("All") && !oldSelectedLevels.includes("All")) {
    newSelectedLevels = ["All"];
  } else if (oldSelectedLevels.includes("All")) {
    newSelectedLevels = newSelectedLevels.filter((level) => level !== "All");
  }
  return newSelectedLevels;
}

function computeExcludedLevels(selectedLevels: string[], allLevels: number[]) {
  return selectedLevels.includes("All")
    ? []
    : allLevels.filter((level) => !selectedLevels.includes(level.toString()));
}
