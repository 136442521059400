import { caseInsensitiveComparator, contramap } from "@asmbl/shared/sort";
import { Box, Button, makeStyles, Theme } from "@material-ui/core";
import { memo, useMemo } from "react";
import { LiveLocationsProvider_market as Market } from "../../../__generated__/graphql";
import { AssembleButton } from "../../AssembleButton/AssembleButton";
import { useCurrencies } from "../../CurrenciesContext";
import { AssembleAutocomplete } from "../../Form/Autocomplete/AssembleAutocomplete";
import { PositionAutocomplete } from "../../Form/Autocomplete/PositionAutocomplete";
import Section from "../../Workflow/Section";
import { ChangeHandler, LocationGroup, OfferData, Position } from "../types";

export type PositionsProps = {
  currentStep: number;
  data: OfferData;
  handleChange: ChangeHandler;
  positions: Position[];
  markets: Market[];
  onNext: () => unknown;
};

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    left: "50%",
    paddingBottom: `${theme.spacing(6)}px`,
    position: "absolute",
    transform: "translate(-50%)",
    width: theme.spacing(67),
  },
  fullWidth: {
    width: "100%",
  },
}));

const Positions = ({
  currentStep,
  data,
  handleChange,
  positions,
  markets,
  onNext,
}: PositionsProps) => {
  const classes = useStyles();
  const { currencies } = useCurrencies();

  const setMarket = (market: Market | null) => {
    handleChange("market", market ?? undefined);
    // Reset the location group whenever your market changes
    selectLocationGroup(null);

    // Use the default currency of the market if it exists
    if (market) {
      const currency = currencies.get(market.currencyCode);
      if (currency) {
        handleChange("currency", currency);
      }
    }
  };

  const selectLocationGroup = (locationGroup: LocationGroup | null) =>
    handleChange("locationGroup", locationGroup ?? undefined);

  const selectPosition = (position: Position | null) =>
    handleChange("position", position ?? undefined);

  const buildFromScratch = () => {
    handleChange("position", undefined);
    onNext();
  };

  const sortedMarkets = markets.slice().sort(contramap((x) => x.name));

  const locationGroups =
    markets.length > 1
      ? data.market?.locationGroups ?? []
      : markets.at(0)?.locationGroups ?? [];

  const sortedLocationGroups = locationGroups
    .slice()
    .sort(contramap((x) => x.name));

  const showMarketsSelector = markets.length > 1;

  const showLocationGroupsSelector =
    locationGroups.length > 1 || showMarketsSelector;

  const sortedPositions = useMemo(() => {
    return positions.slice().sort((a, b) => {
      const nameCompare = caseInsensitiveComparator(
        a.ladder.name,
        b.ladder.name
      );
      const levelCompare = a.level - b.level;

      return nameCompare || levelCompare;
    });
  }, [positions]);

  return (
    <form className={classes.content}>
      <Section
        id="Section-Positions"
        active={currentStep === 1}
        description={
          <>
            Or, you can skip this step and
            <Button
              color="primary"
              onClick={buildFromScratch}
              variant="text"
              style={{ fontSize: "15px", padding: "0px 4px" }}
            >
              build the offer from scratch.
            </Button>
          </>
        }
        header="Select a position to use as a template."
      >
        {showMarketsSelector && (
          <Box mb={4}>
            <AssembleAutocomplete
              className={classes.fullWidth}
              options={sortedMarkets}
              getOptionLabel={(option) => option.name}
              onChange={(value: Market | null) => setMarket(value)}
              value={data.market ?? null}
              label="Market"
              placeholder="Market"
            />
          </Box>
        )}

        {showLocationGroupsSelector && (
          <Box mb={4}>
            <AssembleAutocomplete
              className={classes.fullWidth}
              options={sortedLocationGroups}
              getOptionLabel={(option) => option.name}
              onChange={(value: LocationGroup | null) =>
                selectLocationGroup(value)
              }
              value={data.locationGroup ?? null}
              label="Location Group"
              placeholder="Location Group"
            />
          </Box>
        )}

        <Box mb={4}>
          <PositionAutocomplete
            className={classes.fullWidth}
            positions={sortedPositions}
            value={data.position ?? null}
            onChange={selectPosition}
          />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <AssembleButton
            disabled={!data.position || !data.market}
            onClick={onNext}
            variant="contained"
            size="medium"
            label="Next"
          />
        </Box>
      </Section>
    </form>
  );
};

export default memo(Positions);
